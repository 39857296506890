import React from "react";
import windowSize from "react-window-size";
import { checkIfApiLoading } from "../../../../utils/helper";
import { connect } from "react-redux";
import MainPage from "../../../../components/MainPage/MainPage";
import MainPageHeader from "../../../../components/MainPageHeader/MainPageHeader";
import MainPageBody from "../../../../components/MainPageBody/MainPageBody";
import {
  getLineStatusOverviewByArea,
  getAllAreas,
} from "../../../../redux/actions/Lines/linesActions";
import { getSystemPreference } from "../../../../redux/actions/Preference/preferenceActions";
import { getTicketDetail } from "../../../../redux/actions/Tickets/ticketActions";
import { getMachineById } from "../../../../redux/actions/Machine/machineActions";
import { getAreas } from "../../../../redux/actions/Area/areaActions";
import MainTable from "../../../../components/MainTable/MainTable";
import { Link } from "react-router-dom";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import CloseIcon from "@material-ui/icons/Close";
import Carousel, { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import Station from "./Station";
import Drawer from "./Drawer";
import io from 'socket.io-client';
import { apiSettings } from '../../../../utils/api/CoreApi';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import "./LineStatusTvMode.scss";

const useStyles = makeStyles({
  noShadow: {
    boxShadow: 'none !important'
  },
  root: {
    // width: '100%',
    flexGrow: 1,
  },
  container: {
    maxHeight: 500,
    overflowX: 'hidden',
    overflowY: 'auto',
    display: 'inherit',
  },
  normalColumn: {
    height: 'auto',
    fontSize: 'large',
    verticalAlign: 'middle',
    margin: '0px 8px 0px 8px',
    color:'#000000',
    cursor: 'pointer',
    backgroundColor: '#F2F2F2',
    borderRadius: "0px",
    minWidth: 102,
    maxWidth: 108,
    // margin: "8px auto",
    padding: '3px',
  },
  overdueColumn: {
    height: 'auto',
    fontSize: 'large',
    verticalAlign: 'middle',
    padding: '3px',
    margin: '0px 8px 0px 8px',
    color:'#FFFFFF',
    cursor: 'pointer',
    backgroundColor: '#D9001B',
    minWidth: 102,
    maxWidth: 108,
    borderRadius: "0px"
  },
  clickedColumn: {
    height: 'auto',
    fontSize: 'large',
    verticalAlign: 'middle',
    padding: '3px',
    margin: '0px 8px 0px 8px',
    color:'#FFFFFF',
    cursor: 'pointer',
    backgroundColor: '#02A7F0',
    minWidth: 102,
    maxWidth: 108,
    borderRadius: "0px"
  },
  paper: {
    maxWidth: 100,
    margin: "auto",
    padding: "auto"
  },
});

class LineStatusTvMode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autoPlaySeconds: 10,
      areaId: null,
      counter: 0,
      initialLoad: false,
      showBottomDrawer: false,
      showCarousel: false,
      linePerSlide: 15,
      slideValue: 0,
      slides: [],
      slides2: [],
      slidesLength: 0,
      buttonTrigger: false,
      drawerDetail: "",
      linesMachines: [],
      linesTitle: "",
      areaHasBeenLoaded: false,
      linesHasBeenLoaded: "",
      linesNewData:false,
      slideIsReady: false,
      lines: [],
      dotsShow:true,
      filterDisabled:false,
      overdueTabs: [],
      ticketDetailHasBeenLoaded:false,
      assetDetail1:null,
      assetDetail2:null,
      assetDetail3:null,
      assetDetail4:null,
      lineId:null,
    };

    this.initializeSocket();
  }

  getByMilliseconds(seconds){
     return seconds * 1000;
  }

  checkIfApiLoading() {
    let flag = checkIfApiLoading([this.props.linesLoading]);
    return flag;
  }

  initializeSocket(){
    /* TICKET SOCKET */
    // let socket = io(apiSettings.apiURLTickets);
    // let userToken = JSON.parse(localStorage.getItem('userToken'));
    // socket.on('connect', function(){
    //    socket.emit('authentication', {
    //       token: userToken,
    //   });
    // });

    let socket = io(apiSettings.apiURLTicketsSocket, 
      {
          transports: ['websocket'],
          secure: true,
          query:`token=${JSON.parse(localStorage['userToken'])}`
      })
      socket.on('reconnect_attempt', () => {
        console.log('attempts')
        socket.io.opts.transports = ['websocket'];
    });
    
    socket.on('web_new_ticket', (data) => {
      this.setState({linesHasBeenLoaded: true})
    });
    socket.on('web_updated_ticket', (data) => {
      this.setState({linesHasBeenLoaded: true})
    });
    socket.on('disconnect', (data) => {
      socket.connect();
      this.setState({linesHasBeenLoaded: true})
    });

    
    /* CHECKIN CHECKOUT SOCKET */
    // let socketLine = io(apiSettings.apiURLLines);
    // socketLine.on('connect', function(){
    //    socketLine.emit('authentication', {
    //       token: userToken,
    //   });
    // });

    let socketLine = io(apiSettings.apiURLLinesSocket, 
      {
          transports: ['websocket'],
          secure: true,
          query:`token=${JSON.parse(localStorage['userToken'])}`
      })
      socketLine.on('reconnect_attempt', () => {
        console.log('attempts')
        socketLine.io.opts.transports = ['websocket'];
    });
    
    socketLine.on('checkin', (data) => {
      this.setState({linesHasBeenLoaded: true})
    });
    socketLine.on('checkout', (data) => {
      this.setState({linesHasBeenLoaded: true})
    });
    // socketLine.on('disconnect', (data) => {
    //   socketLine.connect();
    //   this.setState({linesHasBeenLoaded: true})
    // });
  }

  addOverdueTab = (tab) =>{
      let tabs = this.state.overdueTabs;
      tabs.push(tab);
      this.setState({overdueTabs: tabs},() => {
        this.renderSlides();
      });
  }

  removeOverdueTab = (tab) =>{
      let tabs = this.state.overdueTabs;
      let index = tabs.indexOf(tab);
      let tmpArray = [];
      if (index >= 0){
        tabs.splice(index,1);
        this.setState({overdueTabs:tabs},() => {
          this.renderSlides();
        });
      }
  }

  componentDidMount() {
    this.props.getSystemPreference();
    if (this.props.match.params.token){
        localStorage.setItem('paramUserToken',JSON.stringify(this.props.match.params.token));
    }
    this.props.getAllAreas();
    this.setLinePerSlide();
    if (this.props.areaLoading == false) {
      this.setState({
        areaHasBeenLoaded: true,
      });
    }
    this.props.getLineStatusOverviewByArea(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.ticketDetailLoading == false){
      this.setState({
          ticketDetailHasBeenLoaded:true,
      })
  }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  initializeAreaData(areaId){
    this.props.getLineStatusOverviewByArea(areaId);
      this.setState({
        linesHasBeenLoaded: false,
        linesNewData: true,
        areaId: areaId
      });
  }

  renderSlides(){
    let lineData = this.props.lines;
    let multiArrayLines = Object.keys(lineData);
    let slides = [];
    let slides2 = [];
    let linePerSlideCount = this.state.linePerSlide;
    let currentIndex = 0;
    let oldIndex = 0;
    let drawerDetail = this.state.drawerDetail;

    while (currentIndex < multiArrayLines.length) {
      let lines = multiArrayLines
        .slice(currentIndex, currentIndex + linePerSlideCount)
        .map((item, itemIndex) => {
          return (
            <div key={itemIndex} className="line">
                <Station
                  systemPreference={this.props.systemPreference}
                  addOverdueTab={this.addOverdueTab}
                  removeOverdueTab={this.removeOverdueTab}
                  key={itemIndex}
                  drawerOpen={false}
                  linesData={lineData[item]}
                  lineNo={item}
                  overdueTabs={this.state.overdueTabs}
                  openTicketDetails={this.openBottomDrawer}
                ></Station>
            </div>
          );
        });
        oldIndex = currentIndex;
        currentIndex += linePerSlideCount;
        let lines2 = multiArrayLines
        .slice(currentIndex, currentIndex + linePerSlideCount)
        .map((item, itemIndex) => {
          let data =lineData[item]
          let filteredMachines = data.machines.filter(x => x.hasOpenTicket == "true")
          let macLength = filteredMachines.length
          let customheight = macLength * 81
          return (
            <div key={itemIndex} className="line" style={{height: customheight}}>
              {
                <Station
                  addOverdueTab={this.addOverdueTab}
                  removeOverdueTab={this.removeOverdueTab}
                  key={itemIndex}
                  drawerOpen={true}
                  linesData={lineData[item]}
                  lineNo={item}
                  overdueTabs={this.state.overdueTabs}
                  openTicketDetails={this.openBottomDrawer}
                ></Station>
              }
            </div>
          );
        });
      slides.push(
        <div className="slide">
            <div className="tabs">
              {this.renderTabs(
                multiArrayLines.slice(
                  oldIndex,
                  oldIndex + linePerSlideCount
                ),lineData
              )}
            </div>
            <div className="lines">{lines}</div>
            <div className="tabs second-tab">
              {this.renderTabs(
                multiArrayLines.slice(
                  currentIndex,
                  currentIndex + linePerSlideCount
                ),lineData
              )}
            </div>
            <div className="lines">{lines2}</div>
        </div>
      );

      // slides2.push(
      //   <div className="slide">
      //     <div className="tabs">
      //       {this.renderTabs(
      //         multiArrayLines.slice(
      //           currentIndex,
      //           currentIndex + linePerSlideCount
      //         )
      //       )}
      //     </div>
      //     <div className="lines">{lines2}</div>
      //   </div>
      // );

      currentIndex += linePerSlideCount;
    }
    this.setState({ slideIsReady: false, slides: slides });
    // if (this.state.initialLoad === false){
    //     this.setState({slides:slides, initialLoad: true});
    // }
  }

  componentWillUpdate(nextProps, nextState) {

    if(this.state.ticketDetailHasBeenLoaded == true){
      let assets = nextProps.assetDetail
      let videos = []
      let images = []
      if(assets.length > 0){
          assets.map((item, itemsIndex) => {

              let checker = item.assetLink.substr(item.assetLink.length - 3);
              if(checker == 'mp4'){
                  videos.push(item)
              }else{
                  images.push(item)
              }
          })
          let sortedVideos = videos.sort((a,b) => { return a.createdDt < b.createdDt ? 1 : -1 })
          let sortedImages = images.sort((a,b) => { return a.createdDt < b.createdDt ? 1 : -1 })
          this.setState({
              assetDetail1: sortedImages[0] != undefined ? sortedImages[0].assetLink : null,
              assetDetail2: sortedImages[1] != undefined ? sortedImages[1].assetLink : null,
              assetDetail3: sortedImages[2] != undefined ? sortedImages[2].assetLink : null,
              assetDetail4: sortedVideos[0] != undefined ? sortedVideos[0].assetLink : null,
              ticketDetailHasBeenLoaded:false,
          })
      }else{
        this.setState({
          assetDetail1: null,
          assetDetail2: null,
          assetDetail3: null,
          assetDetail4: null,
          ticketDetailHasBeenLoaded:false,
        })
      }
  }

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.linesHasBeenLoaded === true) {
      this.initializeAreaData(this.state.areaId);
    }
    if (this.state.linesNewData == true) {
      if (this.checkIfApiLoading() === false){
        let lines = this.props.lines;
        if (lines.length != 0) {
          let lineTitle = this.state.linesTitle
          let data = []
          let machines = []
          if(lineTitle != ""){
            data = lines[lineTitle]
            machines = data.machines;
          }
          this.setState({
            counter: this.state.counter + 1,
            lines: lines,
            slideIsReady:true,
            linesNewData: false,
            linesMachines: machines,
          });
        }
      }
    }
    if (this.state.slideIsReady == true) {
        this.renderSlides();
    }
  }

  componentWillUnmount() {
  }

  setLinePerSlide() {
    let screenWidth = this.props.windowWidth;
    if (screenWidth >= 1350 && screenWidth <= 1429) {
      this.setState({ linePerSlide: 15 });
    }
    if (screenWidth >= 1430 && screenWidth <= 1529) {
      this.setState({ linePerSlide: 16 });
    }
    if (screenWidth >= 1530 && screenWidth <= 1629) {
      this.setState({ linePerSlide: 17 });
    }
    if (screenWidth >= 1630 && screenWidth <= 1729) {
      this.setState({ linePerSlide: 18 });
    }
    if (screenWidth >= 1730 && screenWidth <= 1829) {
      this.setState({ linePerSlide: 19 });
    }
    if (screenWidth >= 1830 && screenWidth <= 2099) {
      this.setState({ linePerSlide: 20 });
    }
    if (screenWidth > 2099) {
      this.setState({ linePerSlide: 25 });
    }
  }

  openBottomDrawer = (ticketId, machineId) => {
    this.props.getTicketDetail(ticketId);
    this.props.getMachineById(machineId);
    if (
      this.props.ticketDetailLoading == false ||
      this.props.machineDetailLoading == false
    ) {
      this.setState({
        buttonTrigger: !this.state.buttonTrigger,
        filterDisabled:true,
        dotsShow:false,
        drawerDetail: "station",
      });
    }
  };

  removeDrawerDetail = () => {
    this.setState({ drawerDetail: "",
        filterDisabled:false,
        dotsShow:true,
    });
  };

  handleLineDetail = (item) => {
    let data = this.state.lines[item];
    let machines = data.machines;
    this.setState({
      buttonTrigger: !this.state.buttonTrigger,
      drawerDetail: "lines",
      dotsShow:false,
      linesMachines: machines,
      linesTitle: item,
      filterDisabled:true,
    });
  };

  renderTabs(tabsData, lineData) {
    let overdueTabs = this.state.overdueTabs;
    let tabs = tabsData.map((item, itemIndex) => {
      let hasOpenTickets = lineData[item].machines.filter((item) => { return item.hasOpenTicket === 'true' });
      // const classes = useStyles();
      return (
        <div
          key={itemIndex}
          className={"tab " + ((overdueTabs.indexOf(item) >= 0 && hasOpenTickets.length > 0) ? 'highlight' : '')}
        >
          <Grid container wrap="nowrap">
            <Grid item xs zeroMinWidth>
              <Box style={{ overflowWrap: 'break-word'}} >
                {item}
              </Box>
            </Grid>
          </Grid>

        </div>
      );
    });
    return tabs;
  }

  handleSlideChange = (value) => {
    this.setState({ slideValue: value });
  };

  handleDrawerDetails = (item) => {
    this.setState({ drawerDetail: "lines" });
  };

  getLinesLength() {
    return this.state.drawerDetail == "" ? this.state.slides.length : this.state.slides2.length
  }

  loadLines() {

  }

  buttonTrigger = () => {
    this.setState({ buttonTrigger: !this.state.buttonTrigger });
  };
   loadLineOverview = (id) => {
    if (id != null) {
      this.setState({ linesHasBeenLoaded: true, areaId: id });
    }
  };
  render() {
    return (
      <div>
        <MainPage className="line-status-overview-tv">
          <MainPageHeader
            tvModeToken={ this.props.match.params.token ? true : false }
            areaId={this.props.match.params.id}
            lineStatusTvMode={true}
            title="Line Overview"
            filterArea={true}
            filterDisabled={this.state.filterDisabled}
            lineOverView={this.loadLineOverview.bind()}
            lines={this.props.lineArea ? this.props.lineArea : []}
          />
          <MainPageBody className="body-height">
            <div className="line-overview">
              <Carousel
                slidesPerPage={1}
                centered={false}
                value={this.state.slideValue}
                onChange={this.handleSlideChange}
                draggable={false}
                autoPlay={this.getByMilliseconds(this.state.autoPlaySeconds)}
              >
               {this.state.drawerDetail == "" ? this.state.slides : this.state.slides2}
              </Carousel>
            </div>
            {/* <div className={"bottom-drawer-container " + ( this.state.showBottomDrawer ? ' displayed' : '' )}>
                    <Grid className="bottom-drawer" container spacing={0}>
                        <div onClick={this.closeBottomDrawer} className="close">
                            <CloseIcon></CloseIcon>
                        </div>
                    </Grid>
                </div> */}
                {this.state.dotsShow ? (
                              <Dots
                              className="carousel-dots"
                              value={this.state.slideValue}
                              onChange={this.handleSlideChange}
                              number={this.getLinesLength()}
                            />
                ) : null
                }

          </MainPageBody>
        </MainPage>
        <Drawer
          showDrawer={this.state.buttonTrigger}
          ticketDetail={this.props.ticketDetail ? this.props.ticketDetail : []}
          machineDetail={
            this.props.machineDetail ? this.props.machineDetail : []
          }
          assetDetail1={this.state.assetDetail1}
          assetDetail2={this.state.assetDetail2}
          assetDetail3={this.state.assetDetail3}
          assetDetail4={this.state.assetDetail4}
          drawerDetail={this.state.drawerDetail}
          linesMachines={this.state.linesMachines}
          linesTitle={this.state.linesTitle}
          clearDrawerDetail={this.removeDrawerDetail}
        ></Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let apiLoading = {
    linesLoading: state.linesReducer.loading,
    areaLoading: state.linesReducer.areaLoading,
    ticketDetailLoading: state.ticketListReducer.loadingDetail,
    machineDetailLoading: state.machineListReducer.viewDetailLoading,
  };
  let data = {
    systemPreference: state.preferenceReducer.systemPreference,
    lines: state.linesReducer.lines,
    lineArea: state.linesReducer.lineArea,
    ticketDetail: state.ticketListReducer.ticketDetail,
    machineDetail: state.machineListReducer.machineDetail,
    assetDetail: state.ticketListReducer.assetDetail,
    error: state.linesReducer.error,
  };

  return {
    ...apiLoading,
    ...data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSystemPreference: () => dispatch(getSystemPreference()),
    getLineStatusOverviewByArea: (area) =>
      dispatch(getLineStatusOverviewByArea(area)),
    getTicketDetail: (id) => dispatch(getTicketDetail(id)),
    getMachineById: (id) => dispatch(getMachineById(id)),
    getAllAreas: () => dispatch(getAllAreas()),
  };
};

LineStatusTvMode = connect(mapStateToProps, mapDispatchToProps)(LineStatusTvMode);

export default windowSize(LineStatusTvMode);
