export const colors = [
  '#62014B',
  '#700100',
  '#912D18',
  '#9F471C',
  '#B2582E',
  '#BF7437',
  '#D28444',
  '#E2A04C',
  '#F3B05A',
  '#E0CA1F'
]