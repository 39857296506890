import React from 'react';
import { formatDate } from '../../../../../utils/date';
import { 
    TooltipContainerIndividual, 
    TooltipDividerIndividual, 
    TooltipListHeaderIndividual, 
    TooltipListIndividual, 
    TooltipListItemIndividual, 
    TooltipSubTitleIndividual, 
    TooltipTitleIndividual,
} from './styles';

export default function IndividualMechanicTooltip(props) {
    const date = `${formatDate(props.data.startDate, 'MMM D, Y', false)} ${'to'.translate()} ${formatDate(props.data.endDate, 'MMM D, Y', false)}`;
    return (
        <TooltipContainerIndividual>
            <TooltipTitleIndividual title={props.data.title} />
            <TooltipSubTitleIndividual title={date} />
            <TooltipListIndividual>
                <TooltipListHeaderIndividual 
                    title={props.data.header.title} 
                    total={props.data.header.total} 
                    color={props.data.header.color} />
                <TooltipDividerIndividual />
                {props.data.list.map((item) => (
                    <TooltipListItemIndividual 
                        title={item.title} 
                        total={item.total} 
                        color={item.color} />))}
            </TooltipListIndividual>
        </TooltipContainerIndividual>
    )
}