import { IconButton, withStyles } from "@material-ui/core";

export const CircleButton = withStyles({
    root: {
        boxShadow: 'none',
        borderRadius: '50% !important',
        width: 30,
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1689FA',
        '&:hover': {
            backgroundColor: '#1689FA',
        },
    },
})(IconButton);

export const CircleButtonDanger = withStyles({
    root: {
        boxShadow: 'none',
        borderRadius: '50% !important',
        width: 30,
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#e64747',
        '&:hover': {
            backgroundColor: '#e64747',
        },
    },
})(IconButton);