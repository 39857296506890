import React, { useState, useEffect } from 'react';
import DateMomentUtils from '@date-io/moment';
import { withStyles } from '@material-ui/core/styles';
import { Select, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CardPaper from '../components/CardPaper';
import InputLabel from '../components/InputLabel';
import FormControl from '../components/FormControl';
import Typography from '../components/Typography';
import TextField from '../components/TextField';
import KeyboardDatePicker from '../components/KeyboardDatePicker';
import { referenceClient } from '../../../../utils/api/CoreApi';
import { getFactoryId } from '../../../../utils/helper';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { createMuiTheme } from '@material-ui/core/styles';

const Container = withStyles({
  root: {
    height: "calc(100vh - 100px - 70px)", // 100px = header height, 70px = footer height
    '& *': {
      boxSizing: "initial", // fix text field placeholder position
    }
  }
})(CardPaper)

const asteriskTheme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131'
        },
      }
    }
  }
})

export default function InfoForm({ title, stickyBounday, data, onChange }){
  const [areas, setAreas] = useState([]);
  const [lines, setLines] = useState([]);
  const [types, setTypes] = useState([]);
  const [checklists, setChecklists] = useState([]);

  useEffect(()=>{
    const factoryId = getFactoryId();

    referenceClient.get("/areas", { params: { isActive: '1', pageSize: 100000 } }).then(({ data }) => {
      const { machines } = data; // tech debt backend return with prefix key machines;
      const { result } = machines;
      setAreas(result.map(({ id, desc1 }) => ({ id, name: desc1 })));
    });
    referenceClient.get("/lines", { params: { isActive: '1', pageSize: 100000 } }).then(({ data }) => {
      const { machines } = data; // tech debt backend return with prefix key machines;
      const { result } = machines;
      setLines(result.map(({ id, desc1, areaId }) => ({ id, areaId, name: desc1 })));
    });
    referenceClient.get("/changeovertypes", { params: { factoryId: factoryId } }).then(({ data }) => {
      setTypes(data.data.map(({ id, desc1 }) => ({ id, name: desc1 })));
    });
    referenceClient.get("/checklists", { params: { isActive: '1', brandId: 'all', macSubTypeId: 'all', searchString: 'all'  } }).then(({ data }) => {
      setChecklists(data.machines.filter((item) => item.checklistType === 'Changeover Checklist').map(({ id, name }) => ({ id, name })));
    });
  }, []);

  const hasSelectedArea = data.areaId !== undefined && data.areaId !== null && data.areaId !== '';
  const areaLines = lines.filter((line)=> line.areaId == data.areaId);

  return <Container ref={stickyBounday}>
    <Typography variant="h5">{title}</Typography>

    <MuiThemeProvider theme={asteriskTheme}>
      <MuiPickersUtilsProvider utils={DateMomentUtils}>
        <form>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="yyyy-MM-DD"
            margin="normal"
            required
            label={'Changeover Date'.translate()}
            value={data.changeoverDate || null}
            onChange={(date, value)=> onChange({ changeoverDate: value })}
          />

          <TextField
            label={'Style'.translate()}
            required
            value={data.style}
            onChange={(evt)=> onChange({ style: evt.target.value })}
          />

          <FormControl required>
            <InputLabel id="area-select-label">{'Area'.translate()}</InputLabel>
            <Select
              labelId="area-select-label"
              id="area-select"
              value={data.areaId}
              onChange={(evt)=> onChange({ areaId: evt.target.value })}
            >
              {
                areas.map(({ id, name })=> <MenuItem key={id} value={id}>{name}</MenuItem>)
              }
            </Select>
          </FormControl>

          <FormControl required>
            <InputLabel id="line-select-label">{'Line'.translate()}</InputLabel>
            <Select
              labelId="line-select-label"
              id="line-select"
              disabled={!hasSelectedArea}
              value={data.lineId}
              onChange={(evt)=> onChange({ lineId: evt.target.value })}
            >
              {
                areaLines.map(({ id, name })=> <MenuItem key={id} value={id}>{name}</MenuItem>)
              }
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="type-select-label">{'Changeover Type'.translate()}</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              value={data.changeoverTypeId}
              onChange={(evt)=> onChange({ changeoverTypeId: evt.target.value })}
            >
              {
                types.map(({ id, name })=> <MenuItem key={id} value={id}>{name}</MenuItem>)
              }
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="type-select-label">{'Changeover Checklist'.translate()}</InputLabel>
            <Select
              labelId="type-select-label"
              id="checklist-select"
              value={data.changeoverChecklistId}
              onChange={(evt)=> onChange({ changeoverChecklistId: evt.target.value })}
            >
              {
                checklists.map(({ id, name })=> <MenuItem key={id} value={id}>{name}</MenuItem>)
              }
            </Select>
          </FormControl>

          <TextField
            label={'Product Type'.translate()}
            value={data.productType}
            onChange={(evt)=> onChange({ productType: evt.target.value })}
          />

          <TextField
            label={'Remarks'.translate()}
            value={data.remarks}
            onChange={(evt)=> onChange({ remarks: evt.target.value })}
          />
        </form>
    </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </Container>
}