import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, Box, InputBase, Button, Tooltip, Checkbox, TablePagination, TableFooter, TableSortLabel, Radio } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, ArrowRight, Edit, Search, Add, Cancel, Close, Delete, KeyboardArrowRight, KeyboardArrowLeft, FirstPage, LastPage, HelpRounded } from '@material-ui/icons';
import { connect } from 'react-redux'
import moment from 'moment'
import dictionary from '../../redux/actions/Translation/dictionary'
import { apiSettings } from '../../utils/api/CoreApi'
import './CollapseTable.scss'
import { formatDate } from '../../utils/date';
import { displayDefaultImage } from '../../utils/image';


const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));
function createData(name, calories, fat, carbs, protein, price, id) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
        price,
        history: [
            { date: '2020-01-05', customerId: '11091700', amount: 3 },
            { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
        ],
        id
    };
}

Row.propTypes = {
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired,
    }).isRequired,
};

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
    createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
    createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
    createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
];
function TablePaginationActions(props) {

    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
            </IconButton>
        </div>
    );
}

function renderOverlayImage(e, link) {
    if (e == true) {
        document.querySelector('.tableContainer-overlay').style.display = 'block'
        document.querySelector('.tableContainer-overlay').children[0].children[0].src = link

    }
    else document.querySelector('.tableContainer-overlay').style.display = 'none'
}
function rowClicked({ target }, fn) {
    if (target?.tagName == 'TR' || target?.tagName == 'TD') {
        fn()
    }
}

function toolbarClick(fn, ev) {
    // console.log('event', ev, document.querySelector('.tableContainer').firstElementChild.scrollTop)
    localStorage['tableContainerCollapseScroll'] = document.querySelector('.tableContainer').firstElementChild.scrollTop
}
function setHighlight(value, isHighlight, searchValue) {
    if (isHighlight) {
        let style = {
            color: '#515151',
            fontWeight: 'Bold',
            backgroundColor: '#efdb07'
        }
        if (searchValue != '') {
            let regex = new RegExp(searchValue, 'gi')
            if (value?.match?.(regex)) {
                let returnString = value.replace(regex, <React.Fragment><span style={{ ...style }}>{searchValue}</span></React.Fragment>)
                let word = returnString.split('[object Object]')
                let constructed = []
                let caseSensitive = value.match(regex)
                word.forEach((x, index) => {
                    // constructed+= x + (caseSensitive[index] ?? '')
                    constructed.push(x)
                    if (caseSensitive[index] != undefined) constructed.push(<span style={{ ...style }}>{caseSensitive[index]}</span>)
                })

                // console.log('highlight', returnString, constructed, word, caseSensitive)
                return <>{constructed.map(x => x)}</>
            }
            else return value
        }
        else return value

    }
    else return value
}

function capitalize(value) {
    let sanitize = value ? value.charAt(0).toUpperCase() + value.substring(1) : null
    let trueValue = sanitize ? sanitize.charAt(sanitize.length - 1).match(/s/gi) ? sanitize.substr(0, sanitize.length - 1) : null : null
    return trueValue
}

function hoveredCloseIcon({ target }, action) {
    // if (action == false && target?.classList.contains('close')) {
    //     if (target?.tagName != 'BUTTON') {
    //         target.style.color = ''
    //     }
    //     else target.firstElementChild.firstElementChild.style.color = ''
    //     console.log('here', target)

    // }
    // if (target?.tagName == 'BUTTON') {
    //     if (action == true) target.firstElementChild.firstElementChild.style.color = 'red'
    //     else target.firstElementChild.firstElementChild.style.color = ''
    // }
}
function hoveredRow({ target: { parentElement: { lastElementChild: { firstElementChild: element } } }, target: { parentElement } }, action, enabled = false, collapsable = false, elementIndex, step = 2) {
    /**hover for row NEXT SPRINT*/

    if (parentElement?.tagName == 'TR' && enabled == true) {
        try {

            parentElement.style.cursor = 'pointer'
            if (action == 'hover') parentElement.style.backgroundColor = 'aliceblue'
            else {
                // parentElement.style.backgroundColor = ''
                let { childElementCount: items, children } = document.querySelector('.tableContainer').querySelector('table').lastElementChild
                for (let tr = 0; tr < items; collapsable == true ? tr += step : tr++) {
                    children[tr].style.backgroundColor = ''
                }
            }
        } catch (error) {
            //ignore
        }
    }

    // if (element?.tagName == 'DIV') {
    //     try {
    //         let { childElementCount, children } = element
    //         if (action == 'hover') children[childElementCount - 1].style.display = 'block'
    //         else children[childElementCount - 1].style.display = 'none'
    //         // console.log(children[childElementCount - 1],target)
    //     } catch (error) {
    //         //ignore
    //     }
    // }

}

// function renderDate(date) {
//     if (date !== null) {
//         let timezone = moment.parseZone(date).format('Z');
//         let dateTimeOnly = moment.parseZone(date).format('YYYY-MM-DD HH:mm:ss') + '+00:00';
//         return moment(dateTimeOnly).utcOffset(timezone).format('DD-MM-YYYY');
//     }
//     else {
//         return '-';
//     }
// }

function renderDate(date) {
    if (date !== null) {
        try {
            let newDate = date.substring(0, date.indexOf('T'));
            return moment.parseZone(newDate).format('DD-MM-YYYY');
        }
        catch (err) {
            let timezone = moment.parseZone(date).format('Z');
            let dateTimeOnly = moment.parseZone(date).format('YYYY-MM-DD HH:mm:ss') + '+00:00';
            return moment(dateTimeOnly).utcOffset(timezone).format('DD-MM-YYYY');
        }
    }
    else {
        return '-';
    }
}

function tableCellPopulate(isLast = '', ev, style = {}, isBold = false) {
    return <TableCell className={isBold ? "bordered text-bold" + isLast : "bordered" + isLast} style={{ ...style }}>{ev}</TableCell>
}

function toItalized(valueRow) {
    let combinations = ['status', 'isActive']
    let toItalic = false
    for (let x = 0; x < combinations.length; x++) {
        if ((sanitize(undefined, valueRow[combinations[x]], undefined) == 'Inactive' && (valueRow[combinations[x]] == 'Active' || valueRow[combinations[x]] == 'Inactive')) || (sanitize('boolean', valueRow[combinations[x]], undefined) == 'Inactive') && (valueRow[combinations[x]] == true || valueRow[combinations[x]] == false)) {
            toItalic = true
            break;
        }
    }
    return toItalic
}
function sanitize(type, value, custom, objects, caller, props, level, index, childLevelIndex, childIndex, prevFirstChildIndex, prevChildIndex, prevSecondChild) {
    // if(type == 'checkbox') console.log('values', props, props?.state)
    if (type == 'date' && value == '') value = undefined
    const renderCheckBox = () => {
        if (type == 'checkbox') {
            let { checkboxChildState = undefined, checkboxParentState = undefined, checkboxKey = undefined, checkBoxIsRead, checkBoxTouch } = props?.state
            let forChecked = (checkedValue) => {
                if (checkBoxIsRead == true) return value
                else return checkedValue
            }

            let whatCheckbox = (boolean) => {
                if (checkBoxIsRead == true) {
                    if (level != 'parent') return <Checkbox className="customIcon checkbox-blue disabled" indeterminate={boolean == 2 ? true : false} disabled={true} checked={true} size="small" />
                }
                else {
                    return <Checkbox className="customIcon checkbox-blue" indeterminate={boolean == 2 ? true : false} checked={(boolean == 1 || boolean == 2) ? true : false} size="small" onChange={({ target: { checked } }) => {
                        if (objects != undefined && caller != undefined) {
                            if (level == 'parent') props.setCheckBox(caller, level, checked, index)
                            else props.setCheckBox(caller, level, checked, index, childLevelIndex, childIndex, prevFirstChildIndex, prevChildIndex, prevSecondChild)
                            caller(objects, checked, true)
                        }
                    }} />

                }
            }

            if (level == 'parent') {
                if (checkBoxTouch == false) caller(objects, checkboxParentState?.[index] == 0 ? false : true)
                return whatCheckbox(forChecked(checkboxParentState?.[index]))
            }
            else {
                let childCheckedValue
                let childChecked
                if (childLevelIndex == 0) {
                    // console.log('building', checkboxChildState[index]?.[childLevelIndex]?.[childIndex], childLevelIndex, childIndex)
                    let cond = checkboxChildState?.[index]?.[childLevelIndex]?.[childIndex]
                    if (cond != undefined) {
                        childChecked = cond
                        childCheckedValue = checkboxChildState[index][childLevelIndex][childIndex]
                    }
                }
                if (childLevelIndex == 1) {
                    // console.log('floor', checkboxChildState?.[index]?.[childLevelIndex], checkboxChildState[index]?.[childLevelIndex]?.[prevFirstChildIndex]?.[childIndex], childLevelIndex, childIndex, prevFirstChildIndex, prevChildIndex)
                    let cond = checkboxChildState?.[index]?.[childLevelIndex]?.[prevFirstChildIndex]?.[childIndex]
                    if (cond != undefined) {
                        childChecked = cond
                        childCheckedValue = checkboxChildState[index]?.[childLevelIndex]?.[prevFirstChildIndex]?.[childIndex]
                    }
                }
                if (childLevelIndex == 2) {
                    // console.log('area', checkboxChildState?.[index]?.[childLevelIndex], checkboxChildState[index]?.[childLevelIndex]?.[prevFirstChildIndex]?.[prevChildIndex]?.[childIndex], childLevelIndex, childIndex, prevFirstChildIndex, prevChildIndex)
                    let cond = checkboxChildState?.[index]?.[childLevelIndex]?.[prevFirstChildIndex]?.[prevChildIndex]?.[childIndex]
                    if (cond != undefined) {
                        childChecked = cond
                        childCheckedValue = checkboxChildState[index]?.[childLevelIndex]?.[prevFirstChildIndex]?.[prevChildIndex]?.[childIndex]
                    }
                }
                if (childLevelIndex == 3) {
                    //  console.log('line', checkboxChildState?.[index]?.[childLevelIndex], checkboxChildState[index]?.[childLevelIndex]?.[prevFirstChildIndex]?.[prevSecondChild]?.[prevChildIndex]?.[childIndex], childLevelIndex, childIndex, prevFirstChildIndex, prevChildIndex, prevSecondChild)
                    let cond = checkboxChildState[index]?.[childLevelIndex]?.[prevFirstChildIndex]?.[prevSecondChild]?.[prevChildIndex]?.[childIndex]
                    if (cond != undefined) {
                        childChecked = cond
                        childCheckedValue = checkboxChildState[index]?.[childLevelIndex]?.[prevFirstChildIndex]?.[prevSecondChild]?.[prevChildIndex]?.[childIndex]
                    }
                }
                if (childChecked != undefined && checkBoxTouch == false) caller(objects, childChecked == 0 ? false : true)
                return whatCheckbox(forChecked(childCheckedValue))
            }
        }
    }
    const renderRadio = () => {
        if (type == 'radio') {
            let { radioInstance, radioArray, radioTouch, levelInstance: { parent, child } } = props?.state
            let valueRadio = custom == 'View Only' ? 'View' : custom
            let guide = (toFind) => {
                return radioArray.findIndex(x => x == toFind)
            }
            let isChecked = false
            const handleChange = ({ target: { value } }) => {
                if (objects != undefined && caller != undefined) {
                    props.setRadio(index, childIndex, value)
                    caller(objects, value.toUpperCase(), true)
                }
            }
            if ((childIndex != undefined) && (index != undefined)) {
                let currentRadio = radioInstance?.[index]?.[childIndex]
                if (currentRadio != undefined) {
                    isChecked = currentRadio[guide(valueRadio)]
                }
                if (isChecked == 1 && radioTouch == false) caller(objects, valueRadio.toUpperCase())

            }
            if (objects?.rowValue['pageCategory'] == 'Dashboard' || objects?.rowValue['pageCategory'] == props.state.translations.DASHBOARD) {
                if (isChecked == 1) {
                    return value ? <Radio className="customIcon radio-blue" value={valueRadio} checked={true} onChange={(ev) => handleChange(ev,)} /> : null
                }
                else return ''
            }
            else return value ? <Radio className="customIcon radio-blue" value={valueRadio} checked={isChecked == 1 ? true : false} onChange={(ev) => handleChange(ev,)} /> : null
        }
    }
    let boolean = ['Active', 'Inactive']
    if (Array.isArray(custom)) boolean = custom

    if(type === 'image') value = displayDefaultImage(value);

    return value != undefined ? {
        'date': type === 'date' ? formatDate(value) : value,
        'boolean': value == true ? boolean[0] : boolean[1],
        // 'boolean': value == true ? boolean[0].translate() : boolean[1].translate(),
        'radio': renderRadio(),
        'checkbox': renderCheckBox(),
        'image': <div className="table-image" onClick={() => renderOverlayImage(true, value)}><img className="image-src" src={value} /></div>
    }[type] ?? value : value
}
function Row(props) {
    const { key, row, index, keys, edit, remove, tb_add, row_click, searchValue, highlight, countInit = undefined, lastItemBordered, isLastIndex } = props;
    const classes = useRowStyles();
    const toolbar = [
        {
            icon: <Add className="customIcon blue" />,
            fn: tb_add
        },
        {
            icon: <Edit className="customIcon blue" />,
            fn: edit
        },
        {
            icon: <Delete className="customIcon blue" />,
            fn: remove
        },
    ]
    const clickEnabled = row_click ? true : false
    const renderEmptyCell = () => {
        let hasFn = 0
        toolbar.forEach(x => x['fn'] ? hasFn++ : null)
        return (hasFn - 1) >= 0 ? new Array(hasFn - 1).fill(<TableCell className={"bordered" + isLastData()}>&nbsp;</TableCell>) : null
    }

    const isLastData = () => {
        return ((isLastIndex == lastItemBordered) && (isLastIndex == true)) == true ? " last-item" : ''
    }
    return (
        <React.Fragment>
            <TableRow key={key} className={classes.root} onMouseOver={(e) => hoveredRow(e, 'hover', clickEnabled, true, null, 1)} onMouseLeave={(e) => hoveredRow(e, 'mouseOut', clickEnabled, true, null, 1)} onClick={(e) => rowClicked(e, () => row_click?.(row))}>
                <TableCell className={"bordered" + isLastData()}>{countInit ? countInit : index + 1}</TableCell>
                {keys.map(value => {
                    let style = {
                        width: value?.['width'] ? `${value['width']}%` : `unset`,
                        overflowWrap: 'break-word',
                        wordBreak: 'break-word'
                    }
                    let sanitizeValue = row[value['key']] ?? row[value['alt']]
                    let normal = tableCellPopulate(isLastData(), setHighlight(sanitize(value['type'], sanitizeValue, value['booleanKey'] ?? undefined), highlight, searchValue), style)
                    let italic = tableCellPopulate(isLastData(), setHighlight(sanitize(value['type'], sanitizeValue, value['booleanKey'] ?? undefined), highlight, searchValue), { ...style, fontStyle: 'italic', color: '#b5b5b5' })
                    return toItalized(row) ? italic : normal
                })}
                {
                    renderEmptyCell()
                }
                {renderEmptyCell() != null ?
                    <TableCell className={"bordered align-end" + isLastData()}>
                        {toolbar.map(instance => {
                            return instance['fn'] ? <IconButton className="toolbar-button" aria-label="expand row" size="small" onClick={() => instance['fn'](row)}>{instance['icon']}</IconButton> : null
                        })}
                    </TableCell> : null
                }
            </TableRow>
        </React.Fragment>
    );
}

function RowPopulate(props) {
    let { last, keys, isEmpty, collapsable, edit, remove, tb_add, row_click, parentCollapse } = props
    const limit = 10
    const classes = useRowStyles();
    const toolbar = [edit, remove, tb_add]
    const toolbarCount = () => {
        let count = 0
        toolbar.forEach(x => {
            if (x) count++
        })
        return count
    }
    const rowClass = isEmpty ? 'bordered fit-height' : 'bordered fixed-height'
    const renderEmptyRow = () => {
        let div = []
        for (let x = 0; x < keys.length + toolbarCount() - 1; x++) {
            div.push(<TableCell key={`empty-cell-${x}`} className={rowClass}>&nbsp;</TableCell>)
        }
        return div
    }
    const renderRow = () => {
        let div = []
        // let placeHolder = '...'
        let placeHolder = <>&nbsp;</>
        for (let x = 0; x < Math.abs(limit - last); x++) {
            div.push(
                <TableRow key={`empty-table-${x}`} className={classes.root}>
                    {collapsable ? <TableCell className={rowClass}>&nbsp;</TableCell> : <TableCell className={rowClass}>{(last + x) + 1}</TableCell>}
                    {parentCollapse == true ? <TableCell className={rowClass}></TableCell> : <TableCell className={rowClass}>{placeHolder}</TableCell>}
                    {renderEmptyRow()}
                </TableRow>
            )
        }
        return div
    }
    return (
        // <>{last < limit ? renderRow() : null}</>
        null
    )
}

export default class CollapsibleTable extends React.Component {

    constructor(props) {
        super(props)
        /**dummy sample format of the props*/
        let dummy = {
            keys: [
                {
                    name: 'Dessert (100g serving)',
                    key: 'name'
                },
                {
                    name: 'Calories',
                    key: 'calories'
                },
                {
                    name: 'Fat (g)',
                    key: 'fat'
                },
                {
                    name: 'Carbs (g)',
                    key: 'carbs'
                },
                {
                    name: 'Protein (g)',
                    key: 'protein'
                }
            ],
            collapsable: true,
            data: [
                createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99, 1),
                createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99, 2),
                createData('Eclair', 262, 16.0, 24, 6.0, 3.79, 3),
                createData('Cupcake', 305, 3.7, 67, 4.3, 2.5, 4),
                createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5, 5),
            ],
            edit: (ev) => {
                console.log('i am a creepy dummy', ev)
            },
            // search: true,
            // add: (ev) => {
            //     console.log('i am a creepy dummy', ev)
            // }
        }
        let {
            collapsable,
            data,
            edit,
            add,
            keys,
            remove,
            tb_add,
            row_click,
            child_remove,
            child_edit,
            child_add,
            child_tooltips,
            search,
            sorted,
            parentCollapse,
            parentExpand,
            levelInstance,
            customReturnKey,
            highlight,
            checkBox_tick,
            radio_tick,
            checkboxKey,
            searchInputValue,
            checkBoxIsRead,
            h_scroll,
            paginated,
            radioArray,
            searchFn,
            checkBox_init,
            root,
            widthStyle,
        } = props
        this.state = {
            lastItemBordered: true,
            searchValue: '',
            searchInputValue,
            collapsable: collapsable || false,
            data: data || [],
            edit: edit || undefined,
            remove: remove || undefined,
            child_remove: child_remove || undefined,
            child_edit: child_edit || undefined,
            child_add: child_add || undefined,
            child_tooltips: child_tooltips || undefined,
            checkBox_tick: checkBox_tick || undefined,
            checkBox_init: checkBox_init || undefined,
            checkBoxTouch: checkBox_tick ? false : undefined,
            radio_tick: radio_tick || undefined,
            add: add || dummy?.add || undefined,
            tb_add: tb_add || dummy?.tb_add || undefined,
            row_click: row_click || undefined,
            keys: keys || dummy?.keys,
            searchFn: searchFn || undefined,
            search: search || dummy?.search || undefined,
            timeStamp: undefined,
            levelInstance: collapsable ? levelInstance : undefined,
            parentCollapse: parentCollapse || undefined,
            parentExpand: parentExpand || undefined,
            customReturnKey: customReturnKey || undefined,
            highlight: highlight || undefined,
            widthStyle: widthStyle || undefined,
            checkboxChildState: levelInstance?.child?.length > 0 ? [] : undefined,
            checkboxParentState: levelInstance?.child?.length > 0 ? [] : undefined,
            radioInstance: radio_tick ? new Array(data.length).fill(new Array(1)) : undefined,
            radioTouch: radio_tick ? false : undefined,
            checkboxKey: checkboxKey || undefined,
            checkBoxIsRead: checkBoxIsRead || undefined,
            radioArray: radioArray || undefined,
            paginated: paginated || undefined,
            sorted: sorted || undefined,
            h_scroll: h_scroll || undefined,
            collapseInstance: {
                parent: data?.length > 0 ? new Array(data.length).fill(parentCollapse ? parentCollapse == true ? true : false : false) : [],
                child: data?.length > 0 ? new Array(data.length).fill(new Array(1)) : [], //a child array in which it grows deeper
            },
            translations: {
                ...this.convert()
            },
            paginationInstance: {
                rowsPerPage: 20,
                page: 0,
            },
            translationTimestamp: undefined,
            root: root || undefined
        }
    }
    /**handler */
    convert = () => {
        let { ADD, SEARCH, NO_RESULT, ROWS_PER_PAGE, All, DASHBOARD } = dictionary.GET()
        return dictionary.TRANSLATE({ ADD, SEARCH, NO_RESULT, ROWS_PER_PAGE, All, DASHBOARD })
    }
    populateRadioInstance = () => {
        let { radioInstance, data, levelInstance: { parent, child }, radioArray } = this.state
        if (Array.isArray(radioInstance)) {
            radioInstance = new Array(data.length).fill(new Array(1))
            data.forEach((dataInstance, index) => {
                let childLen = dataInstance[child[0]]?.length
                let fillUp = () => {
                    let willReturn = []
                    dataInstance[child[0]].forEach(childInstance => {
                        let populate = []
                        radioArray.forEach(radio => {
                            if (childInstance['access'] == radio.toUpperCase()) {
                                populate.push(1)
                            }
                            else populate.push(0)
                        })
                        willReturn.push(populate)
                    })
                    return willReturn
                }
                // new Array(childLen).fill([new Array(radioArray.length).fill(0)])
                radioInstance[index] = fillUp()
            })
            /**fill up */
            this.setState({
                radioInstance
            })

            console.log('after', radioInstance)
        }
    }
    populateChildInstance = (reinit = false) => {
        let { collapseInstance: { parent, child }, data, levelInstance: { parent: levelParent, child: levelChild }, parentCollapse, checkboxParentState, checkboxChildState, checkboxKey, checkBoxIsRead, checkBox_tick, checkBox_init, root } = this.state
        let clonedChild = child.slice()

        child = []
        // let boolean = false
        let checkboxKeyInstances = {}

        if (checkboxChildState != undefined) {
            checkboxChildState = []
            checkboxKeyInstances[root] = []
            levelChild.forEach(x => checkboxKeyInstances[x] = [])

        }
        let boolean = parentCollapse == true ? true : false
        let tempHolder = []
        let checkboxTemp = []
        let rootIndeterminate = [false, false, false]
        let currentIndex = 0

        /**compare if checkbox */
        if (checkBoxIsRead != true) {
            checkboxParentState = data.map((x, c_boxIndex) => {
                return x?.[levelParent]?.[checkboxKey] ?? 0
            })
        }

        if (tempHolder.length == 0 || reinit == true) {
            const parentToIndeterminate = (value) => {
                if (checkboxParentState?.[currentIndex] != 0) {
                    if (value == 0) rootIndeterminate[0] = true
                    if (value == 1) rootIndeterminate[1] = true
                    if (value == 2) rootIndeterminate[2] = true
                    return value

                }
                else return 0
            }
            const isIndeterminate = (arr) => {
                let _isIndeter = [false, false, false]
                arr.forEach(isIndeter => {
                    if (isIndeter == 0) _isIndeter[0] = true
                    if (isIndeter == 1) _isIndeter[1] = true
                    if (isIndeter == 2) _isIndeter[2] = true
                })
                return (_isIndeter[0] == _isIndeter[1] && _isIndeter[1] == true) == true ? true : false
            }

            const initCheckBoxValue = (value, level) => {
                let id = value?.id
                // console.log('checkstate', value[checkboxKey], levelChild[level], checkboxKeyInstances)
                if (value[checkboxKey] != 0 && value[checkboxKey] != undefined) {
                    let instanceKey = level != 'parent' ? levelChild[level] : root
                    let index = checkboxKeyInstances[instanceKey].findIndex(x => x == id)
                    if (index == -1) checkboxKeyInstances[instanceKey].push(id)
                }
            }
            const recursive = (instance, nextChild, dataState, prevFirstChildIndex, prevChildIndex, cloneInstance, cBoxState) => {
                const populateArray = (dec = 0) => {
                    let toArray = new Array(levelChild?.length - dec)
                    for (let x = 0; x < toArray.length; x++) {
                        toArray[x] = []
                    }
                    return toArray

                }
                let dataInstance = (dataState?.length ? dataState : undefined) || populateArray(1)
                let checkboxInstance
                if (checkboxChildState != undefined) checkboxInstance = (cBoxState?.length ? cBoxState : undefined) || populateArray()
                instance.forEach((rowInstance, rowIndex) => {
                    // console.log('instance', rowInstance)
                    // console.log('isDataInstace', dataInstance)
                    if (dataInstance[nextChild]) {
                        if (nextChild == 0) {
                            if (cloneInstance?.[nextChild]?.[rowIndex] != undefined && parentCollapse == true) dataInstance[nextChild].push(cloneInstance[nextChild][rowIndex])
                            else dataInstance[nextChild].push(boolean)
                            if (checkboxChildState != undefined && (checkBoxIsRead != true) && checkBox_tick != undefined) {
                                checkboxInstance[nextChild].push(parentToIndeterminate(rowInstance?.[levelParent]?.[checkboxKey] ?? 0))
                            }
                        }
                        else {
                            let prev = nextChild - 1
                            let prevInstance = dataInstance[prev]
                            let prevState = dataInstance[nextChild - 1][dataInstance[nextChild - 1].length - 1].length

                            /**for second child */
                            if (prevInstance?.length != dataInstance[nextChild]?.length) {
                                let diff = prevInstance.length - dataInstance[nextChild].length
                                for (let x = 0; x < diff; x++) {
                                    dataInstance[nextChild].push([])
                                    if (checkboxChildState != undefined && (checkBoxIsRead != true) && checkBox_tick != undefined) {
                                        checkboxInstance[nextChild].push([])
                                    }
                                } //a difference of 1

                            }
                            /** for third child */
                            if (Array.isArray(prevInstance[prevInstance.length - 1])) {
                                // console.log('what index for populate?', nextChild, instance, dataState[nextChild][prevFirstChildIndex].length, prevFirstChildIndex, prevChildIndex)
                                let prevChild = prevInstance[prevInstance.length - 1].length
                                let childLength = dataInstance[nextChild][dataInstance[nextChild].length - 1].length
                                // console.log('diff', prevChild,childLength )
                                if (prevChild != childLength) {
                                    for (let x = 0; x < Math.abs(prevChild - childLength); x++) {
                                        dataInstance[nextChild][dataInstance[nextChild].length - 1].push([])
                                        if (checkboxChildState != undefined && (checkBoxIsRead != true) && checkBox_tick != undefined) {
                                            checkboxInstance[nextChild][checkboxInstance[nextChild].length - 1].push([])
                                        }
                                    }

                                }
                                if (Array.isArray(dataInstance[nextChild][prevInstance.length - 1][dataInstance[nextChild][prevInstance.length - 1].length - 1])) {
                                    let childInstance = dataInstance[nextChild][prevInstance.length - 1]
                                    let lastChild = dataInstance[nextChild - 1][dataInstance[nextChild - 1].length - 1].length - 1

                                    if (childInstance[lastChild]) {
                                        if (cloneInstance?.[nextChild]?.[prevFirstChildIndex]?.[prevChildIndex]?.[rowIndex] != undefined && parentCollapse == true) childInstance[lastChild].push(cloneInstance?.[nextChild]?.[prevFirstChildIndex]?.[prevChildIndex]?.[rowIndex])
                                        else childInstance[lastChild].push(boolean)
                                        if (checkboxChildState != undefined && (checkBoxIsRead != true) && checkBox_tick != undefined) {
                                            if (isIndeterminate(instance.map(_instance => _instance[levelParent][checkboxKey]))) {
                                                checkboxInstance[1][prevFirstChildIndex][prevChildIndex] = 2
                                                checkboxInstance[0][prevFirstChildIndex] = 2 //0nd

                                            }
                                            checkboxInstance[nextChild][prevInstance.length - 1][lastChild].push(parentToIndeterminate(rowInstance?.[levelParent]?.[checkboxKey] ?? 0))
                                        }
                                    }
                                    // console.log('third root', levelChild[nextChild], childInstance, lastChild)
                                }
                            }
                            else {
                                if (cloneInstance?.[nextChild]?.[prevChildIndex]?.[rowIndex] != undefined && parentCollapse == true) dataInstance[nextChild][prevInstance.length - 1].push(cloneInstance?.[nextChild]?.[prevChildIndex]?.[rowIndex])
                                else dataInstance[nextChild][prevInstance.length - 1].push(boolean)
                                if (checkboxChildState != undefined && (checkBoxIsRead != true) && checkBox_tick != undefined) {
                                    if (isIndeterminate(instance.map(_instance => _instance[levelParent][checkboxKey]))) checkboxInstance[0][prevFirstChildIndex] = 2
                                    checkboxInstance[nextChild][prevInstance.length - 1].push(parentToIndeterminate(rowInstance?.[levelParent]?.[checkboxKey] ?? 0))
                                }

                            }
                            // console.table({
                            //     lvel: levelChild[nextChild] , nextChild, prevState, inL: instance.length-1, rowIndex, instance, dataInstance
                            // } )
                            tempHolder = dataInstance
                            if (checkboxChildState != undefined) checkboxTemp = checkboxInstance

                        }
                    }
                    else {
                        if (checkBoxIsRead != true && checkBox_tick != undefined) {
                            let lastIndex = checkboxTemp[nextChild - 1][prevFirstChildIndex]?.length - 1
                            let sLastChild = checkboxTemp[nextChild - 1][prevFirstChildIndex]
                            let prevChildLength = sLastChild[lastIndex]?.length
                            // console.table({ rowIndex, prevChildIndex, prevChildLength, current: checkboxTemp[nextChild - 1]?.[prevFirstChildIndex],currentChild: checkboxTemp[nextChild], prevFirstChildIndex, currentLength: checkboxTemp[nextChild - 1]?.length })

                            /**populate */

                            /**1d */
                            if (checkboxTemp[nextChild - 1]?.length != checkboxTemp[nextChild]?.length) {
                                checkboxTemp[nextChild].push([])
                            }

                            /**2d */
                            if (sLastChild?.length != checkboxTemp[nextChild]?.[prevFirstChildIndex]?.length) {
                                let diff = Math.abs(sLastChild?.length - (checkboxTemp[nextChild]?.[prevFirstChildIndex]?.length ?? 0))
                                for (let x = 0; x < diff; x++) {
                                    if (checkboxTemp?.[nextChild]?.[prevFirstChildIndex]) checkboxTemp[nextChild][prevFirstChildIndex].push([])

                                }
                            }

                            /**3d */
                            if (checkboxTemp[nextChild]?.[prevFirstChildIndex]?.[lastIndex]?.length != prevChildLength) {
                                let diff = Math.abs(prevChildLength - (checkboxTemp[nextChild]?.[prevFirstChildIndex]?.[lastIndex]?.length ?? 0))
                                for (let x = 0; x < diff; x++) {
                                    if (checkboxTemp?.[nextChild]?.[prevFirstChildIndex]?.[lastIndex]) checkboxTemp[nextChild][prevFirstChildIndex][lastIndex].push([])

                                }
                            }
                            let dLastIndex = checkboxTemp?.[nextChild]?.[prevFirstChildIndex]?.[lastIndex]?.length - 1
                            if (checkboxTemp?.[nextChild]?.[prevFirstChildIndex]?.[lastIndex][dLastIndex]) {
                                if (isIndeterminate(instance.map(_instance => _instance[checkboxKey]))) {
                                    checkboxInstance[2][prevFirstChildIndex][checkboxInstance[2][prevFirstChildIndex]?.length - 1][prevChildIndex] = 2 //2nd
                                    checkboxInstance[1][prevFirstChildIndex][checkboxInstance[1][prevFirstChildIndex]?.length - 1] = 2 //1nd
                                    checkboxInstance[0][prevFirstChildIndex] = 2 //0nd
                                }
                                checkboxTemp[nextChild][prevFirstChildIndex][lastIndex][dLastIndex].push(parentToIndeterminate(rowInstance?.[checkboxKey] ?? 0))
                            }
                        }
                    }
                    // console.log(levelChild[nextChild], rowInstance, nextChild, dataInstance, 'isNext', rowInstance[levelChild[nextChild + 1]])
                    if (rowInstance[levelChild[nextChild + 1]]) recursive(rowInstance[levelChild[nextChild + 1]], nextChild + 1, dataInstance, nextChild == 0 ? rowIndex : prevFirstChildIndex, rowIndex, cloneInstance, checkboxInstance)
                })

            }
            data.forEach((x, dataIndex) => {
                tempHolder = []
                currentIndex = dataIndex
                rootIndeterminate = [false, false]

                recursive(x[levelChild[0]], 0, dataIndex, null, null, clonedChild?.[dataIndex])

                child.push(tempHolder)

                if (checkboxChildState != undefined) {
                    let lastChildState = child[child.length > 0 ? child.length -1: 0]

                    if(lastChildState?.length > 0 ) checkboxChildState.push(checkboxTemp)
                    else checkboxChildState.push([])
                }
                // console.log('collapsableState', checkboxChildState, parent, child)


                if (((rootIndeterminate[0] == rootIndeterminate[1]) && rootIndeterminate[1] == true) && x[levelChild[0]]?.length > 0) checkboxParentState[dataIndex] = 2


                this.setState({
                    collapseInstance: {
                        parent,
                        child: child
                    },
                    checkboxChildState,
                    checkboxParentState
                })
            })

        }
    }

    onSearch = (value) => {
        if(value != '' && value.length < 2){
            return
        }

        let { data, keys, clonedData, collapsable, collapseInstance: { parent: p_collapseIndex, child: c_collapseIndex }, searchFn } = this.state

        if (searchFn != undefined) {
            this.setState({
                searchValue: value,
                paginationInstance: { ...this.state.paginationInstance, page: 0 }
            }, () => {
                if (value.match(/^\d{2}-\d{2}-\d{4}$/gi)) {
                    searchFn(value.replace(/^(\d{2})-(\d{2})-(\d{4})$/gi, '$3-$2-$1'))
                }
                else if (value.match(/^\d{2}-\d{2}$/gi)) {
                    searchFn(value.replace(/^(\d{2})-(\d{2})$/gi, '$2-$1'))
                }
                else searchFn(value)
            })
        }
        else {
            data = clonedData.slice()

            p_collapseIndex = []
            let c_newCollapseInstance = []

            if (value) {

                let searchItem = data.filter((x, rowIndex) => {
                    /**deep search */
                    if (this.state?.levelInstance?.child?.length > 1) {
                        this.populateChildInstance(true)

                        let { levelInstance: { parent: parentInstance, child: childInstance } } = this.state
                        let valueReg = new RegExp(value, 'gi')
                        let {
                            keys,
                            levelInstance: { parent, child }
                        } = this.state;

                        let parentMarkings = []
                        const searchCollapse = (nextIndex, row, prevFirstChildIndex, prevChildIndex, secondChildIndex, lastChildIndex) => {
                            // console.log('search', nextIndex, row, child[nextIndex], prevFirstChildIndex, prevChildIndex)
                            if (row.length > 0) {
                                row.forEach((rowValue, rowChildIndex) => {
                                    // console.log('rowValue', rowValue, rowIndex)
                                    /**search details */

                                    /**conditions */
                                    for (let keyIndex = 0; keyIndex < keys.length; keyIndex++) {
                                        let keyValue = keys[keyIndex]
                                        let value = rowValue?.[parent]?.[keyValue['key']] ?? rowValue?.[parent]?.[keyValue['childKey']] ?? rowValue?.[parent]?.[keyValue['alt']] ?? rowValue?.[keyValue['key']] ?? rowValue?.[keyValue['childKey']] ?? rowValue?.[keyValue['alt']]
                                        // console.log('before sanitize', value)
                                        if (value) {
                                            if (sanitize(keyValue['type'], value, keyValue['booleanKey'] ?? undefined).toString().match(valueReg)) {
                                                // console.log('keyValue', sanitize(keyValue['type'], value, keyValue['booleanKey'] ?? undefined))
                                                // console.log('child at', nextIndex, child[nextIndex], rowValue, rowIndex, c_collapseIndex[rowIndex][nextIndex], rowChildIndex)
                                                // console.log('what index?', nextIndex)
                                                if (nextIndex == 0) {
                                                    if (c_collapseIndex?.[rowIndex]?.[nextIndex]?.[rowChildIndex] != undefined) c_collapseIndex[rowIndex][nextIndex][rowChildIndex] = true
                                                }
                                                if (nextIndex == 1) {
                                                    if (c_collapseIndex?.[rowIndex]?.[nextIndex]?.[prevFirstChildIndex]?.[rowChildIndex] != undefined) {
                                                        c_collapseIndex[rowIndex][nextIndex - 1][prevFirstChildIndex] = true
                                                        c_collapseIndex[rowIndex][nextIndex][prevFirstChildIndex][rowChildIndex] = true
                                                    }
                                                }
                                                if (nextIndex == 2) {
                                                    if (c_collapseIndex?.[rowIndex]?.[nextIndex]?.[prevFirstChildIndex]?.[prevChildIndex]?.[rowChildIndex] != undefined) {
                                                        c_collapseIndex[rowIndex][0][prevFirstChildIndex] = true
                                                        c_collapseIndex[rowIndex][nextIndex - 1][prevFirstChildIndex][prevChildIndex] = true
                                                        c_collapseIndex[rowIndex][nextIndex][prevFirstChildIndex][prevChildIndex][rowChildIndex] = true
                                                    }
                                                }
                                                if (nextIndex == 3) {
                                                    if (c_collapseIndex?.[rowIndex]?.[nextIndex - 1]?.[prevFirstChildIndex]?.[secondChildIndex]?.[lastChildIndex] != undefined) {
                                                        c_collapseIndex[rowIndex][0][prevFirstChildIndex] = true
                                                        c_collapseIndex[rowIndex][1][prevFirstChildIndex][secondChildIndex] = true
                                                        c_collapseIndex[rowIndex][nextIndex - 1][prevFirstChildIndex][secondChildIndex][lastChildIndex] = true
                                                    }
                                                }
                                                parentMarkings.push(true)
                                                break
                                            }
                                        }
                                    }

                                    /**is recurs */
                                    if (rowValue[child[nextIndex + 1]]) searchCollapse(nextIndex + 1, rowValue[child[nextIndex + 1]], nextIndex == 0 ? rowChildIndex : prevFirstChildIndex, rowChildIndex, nextIndex == 1 ? rowChildIndex : secondChildIndex, nextIndex == 2 ? rowChildIndex : lastChildIndex)
                                })

                            }
                        }

                        /**return if find for root parent */
                        searchCollapse(0, x[childInstance[0]], 0, rowIndex)
                        if (parentMarkings.findIndex(x => x == true) != -1) {
                            // console.log('parentMarkings', parentMarkings, c_collapseIndex)


                            p_collapseIndex.push(true)

                            /**reinit new instance for c_collapse */
                            c_newCollapseInstance.push(c_collapseIndex[rowIndex])
                            return x
                        }
                        else {
                            /**root parent */
                            for (let keyIndex = 0; keyIndex < keys.length; keyIndex++) {
                                let keyValue = keys[keyIndex]
                                let rowValue = x
                                let value = rowValue?.[parent]?.[keyValue['key']] ?? rowValue?.[parent]?.[keyValue['childKey']] ?? rowValue?.[parent]?.[keyValue['alt']] ?? rowValue?.[keyValue['key']] ?? rowValue?.[keyValue['childKey']] ?? rowValue?.[keyValue['alt']]
                                if (value) {
                                    if (sanitize(keyValue['type'], value, keyValue['booleanKey'] ?? undefined).toString().match(valueReg)) {
                                        console.log('keyValue', sanitize(keyValue['type'], value, keyValue['booleanKey'] ?? undefined))
                                        p_collapseIndex.push(true)

                                        /**reinit new instance for c_collapse */
                                        c_newCollapseInstance.push(c_collapseIndex[rowIndex])
                                        return x
                                    }
                                }
                            }
                        }
                    }
                    else {
                        for (let index = 0; index < keys.length; index++) {
                            let valueReg = new RegExp(value, 'gi')
                            // console.log('value', x /**, x[child[0]][keys[index]['childKey']]  */)
                            if (collapsable) {
                                console.log('here')
                                let { levelInstance: { parent, child } } = this.state
                                let sanitizeValue = keys?.[index]?.['keyDefaultValue'] ? keys?.[index]?.['keyDefaultValue'] : x[parent][keys[index]['key']] ?? x[parent][keys[index]['alt']]
                                if (sanitize(keys[index]['type'], sanitizeValue, keys[index]['booleanKey'] ?? undefined)?.toString().match(valueReg)) {
                                    p_collapseIndex.push(true)
                                    // p_collapseIndex.push(false)
                                    return x
                                }
                                else if (Array.isArray(x[child[0]])) {
                                    for (let childIndex = 0; childIndex < x[child[0]].length; childIndex++) {
                                        for (let keyIndex = 0; keyIndex < keys.length; keyIndex++) {
                                            let value = keys?.[index]?.['childDefaultValue'] ? keys?.[index]?.['childDefaultValue'] : x?.[child[0]]?.[childIndex]?.[parent]?.[keys[keyIndex]['childKey']] ?? x[child[0]][childIndex][keys[keyIndex]['childKey']] ?? x[child[0]][childIndex][keys[keyIndex]['alt']]
                                            if (sanitize(keys[keyIndex]['type'], value, keys[keyIndex]['booleanKey'] ?? undefined, null, null, this)?.toString().match(valueReg)) {
                                                // console.log('find',sanitize(keys[keyIndex]['type'], x[child[0]][childIndex][keys[keyIndex]['childKey']], keys[keyIndex]['booleanKey'] ?? undefined)?.toString(),  x[child[0]][childIndex][keys[keyIndex]['childKey']])
                                                p_collapseIndex.push(true)
                                                return x
                                            }
                                        }
                                    }
                                }
                            }
                            else {
                                if (sanitize(keys[index]['type'], x[keys[index]['key']] ?? x[keys[index]['alt']])?.toString().match(valueReg)) {
                                    return x
                                }
                            }
                        }
                    }

                })
                console.log('after search', searchItem, p_collapseIndex, c_collapseIndex, c_newCollapseInstance)
                this.setState({
                    searchValue: value,
                    data: searchItem,
                    collapseInstance: {
                        parent: p_collapseIndex?.length > 0 ? p_collapseIndex : [],
                        child: c_newCollapseInstance
                    }
                }, () => {
                    // if (this.state.levelInstance?.child?.length > 1) this.populateChildInstance(true)
                })
            }
            else {
                this.setState({
                    searchValue: value,
                    data: [...clonedData],
                    collapseInstance: {
                        parent: data?.length > 0 ? new Array(data.length).fill(false) : [],
                        // child: []
                        child: c_collapseIndex
                    }
                }, () => {
                    if (this.state.levelInstance?.child?.length > 1) this.populateChildInstance(true)
                })
                localStorage['collapseInstance'] = JSON.stringify({ parent: new Array(data.length).fill(false), child: c_collapseIndex })

            }
        }
        document.querySelector('.tableContainer').firstElementChild.scrollTop = 0
    }

    deboundSearch = (value) => {
        if(this.delayFn){
            clearTimeout(this.delayFn)
        }
        this.delayFn = setTimeout(() => this.onSearch(value), 1000);
    }

    setCheckBox = (caller, level, checked, index, childLevelIndex, childIndex, prevFirstChildIndex, prevChildIndex, prevSecondChild) => {
        let { data, root, checkboxParentState, checkboxChildState, levelInstance: { parent, child }, checkBoxTouch } = this.state
        console.table({ level, checked, index, childLevelIndex, childIndex, prevFirstChildIndex, prevChildIndex, prevSecondChild, checkboxChildState })
        let isChecked = {
            true: 1,
            false: 0
        }[checked]
        let recursiveHolder = undefined
        let _Indeterminate = false
        let _lastIndeter = 0
        const recursiveCheckbox = (state, isIndeterminate = false) => {
            if (Array.isArray(state)) {
                state.forEach((rowState, rowIndex) => {
                    // console.log('rowState', rowState, Array.isArray(rowState?.[rowState.length - 1]))
                    if (Array.isArray(rowState?.[rowState.length - 1])) {
                        recursiveCheckbox(rowState, isIndeterminate)
                    }
                    else {
                        if (Array.isArray(rowState)) {
                            rowState.forEach((deepRow, deepRowIndex) => {
                                if (isIndeterminate) {
                                    // console.log('row', rowState[deepRowIndex], isChecked)
                                    if (rowState[deepRowIndex] != isChecked) _Indeterminate = true
                                    else _lastIndeter = rowState[deepRowIndex]
                                }
                                else rowState[deepRowIndex] = isChecked
                            })
                            // console.log('after rowState', rowState)
                        }
                    }
                    // console.log('state of recur', state)
                    if (!isIndeterminate) recursiveHolder = state
                })
            }
        }
        if (level == 'parent') {
            checkboxParentState[index] = isChecked
            recursiveCheckbox(checkboxChildState[index])
            if (recursiveHolder != undefined) checkboxChildState[index] = recursiveHolder
            this.setState({
                checkboxParentState,
                checkBoxTouch: false, // comment for now . the parent should trigger the tick outside the table
                checkboxChildState
            })
        }
        else {
            const ischildRecursIndeter = (arr) => {
                let _inValue = [false, false, false]
                /**check for indeterminate */
                arr.forEach(arrIn => {
                    if (arrIn == 0) _inValue[0] = true
                    if (arrIn == 1) _inValue[1] = true
                    if (arrIn == 2) _inValue[2] = true
                })
                if (_inValue[0] == _inValue[1]) return true
                else return false
            }
            if (childLevelIndex == 0) {
                checkboxChildState[index][childLevelIndex][childIndex] = isChecked
                if (checkboxChildState?.[index]?.[1]?.[childIndex]) checkboxChildState[index][1][childIndex].fill(isChecked)

                recursiveCheckbox(checkboxChildState[index][2][childIndex])
                recursiveCheckbox(checkboxChildState[index][3][childIndex])
            }
            if (childLevelIndex == 1) {
                checkboxChildState[index][childLevelIndex][prevFirstChildIndex][childIndex] = isChecked
                if (checkboxChildState?.[index]?.[2]?.[prevFirstChildIndex]?.[prevSecondChild]) checkboxChildState[index][2][prevFirstChildIndex][prevSecondChild].fill(isChecked)

                recursiveCheckbox(checkboxChildState[index][3][prevFirstChildIndex][prevSecondChild])

                /**check for indeterminate */
                if (ischildRecursIndeter(checkboxChildState[index][childLevelIndex][prevFirstChildIndex]) == true) {
                    checkboxChildState[index][0][prevFirstChildIndex] = 2
                }
                else checkboxChildState[index][0][prevFirstChildIndex] = isChecked

            }
            if (childLevelIndex == 2) {

                checkboxChildState[index][childLevelIndex][prevFirstChildIndex][prevChildIndex][childIndex] = isChecked
                if (checkboxChildState?.[index]?.[3]?.[prevFirstChildIndex]?.[prevSecondChild]?.[childIndex]) checkboxChildState[index][3][prevFirstChildIndex][prevSecondChild][childIndex].fill(isChecked)
                /**check for indeterminate */
                if (ischildRecursIndeter(checkboxChildState[index][childLevelIndex][prevFirstChildIndex][prevChildIndex]) == true) {
                    checkboxChildState[index][1][prevFirstChildIndex][prevSecondChild] = 2
                    checkboxChildState[index][0][prevFirstChildIndex] = checkboxChildState[index][1][prevFirstChildIndex][prevSecondChild]
                }
                else {
                    checkboxChildState[index][1][prevFirstChildIndex][prevSecondChild] = isChecked

                    /**prev second */
                    if (ischildRecursIndeter(checkboxChildState[index][1][prevFirstChildIndex]) == true) checkboxChildState[index][0][prevFirstChildIndex] = 2
                    else checkboxChildState[index][0][prevFirstChildIndex] = isChecked
                }
            }
            if (childLevelIndex == 3) {
                checkboxChildState[index][childLevelIndex][prevFirstChildIndex][prevSecondChild][prevChildIndex][childIndex] = isChecked

                /**check for indeterminate */
                if (ischildRecursIndeter(checkboxChildState[index][childLevelIndex][prevFirstChildIndex][prevSecondChild][prevChildIndex]) == true) {
                    checkboxChildState[index][2][prevFirstChildIndex][prevSecondChild][prevChildIndex] = 2
                    checkboxChildState[index][1][prevFirstChildIndex][prevSecondChild] = 2
                    checkboxChildState[index][0][prevFirstChildIndex] = 2

                }
                else {
                    checkboxChildState[index][2][prevFirstChildIndex][prevSecondChild][prevChildIndex] = isChecked

                    /**prev second */
                    if (ischildRecursIndeter(checkboxChildState[index][2][prevFirstChildIndex][prevSecondChild]) == true) {
                        checkboxChildState[index][1][prevFirstChildIndex][prevSecondChild] = 2
                        checkboxChildState[index][0][prevFirstChildIndex] = 2
                    }
                    else {
                        checkboxChildState[index][1][prevFirstChildIndex][prevSecondChild] = isChecked

                        /**prev first */
                        if (ischildRecursIndeter(checkboxChildState[index][1][prevFirstChildIndex]) == true) checkboxChildState[index][0][prevFirstChildIndex] = 2
                        else checkboxChildState[index][0][prevFirstChildIndex] = isChecked
                    }
                }
            }

            /**indeterminate */
            recursiveCheckbox(checkboxChildState[index], true)

            /**parent */
            if (_Indeterminate == true) {
                checkboxParentState[index] = 2
                caller({ rowValue: data[index][parent], from: root, prevId: undefined }, true, true)
            }
            else checkboxParentState[index] = _lastIndeter

            this.setState({
                checkboxChildState,
                // checkBoxTouch: true,
                checkboxParentState
            })
            // console.log('what child', child[childLevelIndex], childIndex)
        }
    }
    setRadio = (index, childIndex, value) => {
        let { radioInstance, radioArray, radioTouch } = this.state
        let guide = (toFind) => {
            return radioArray.findIndex(x => x == toFind)
        }
        let selected = guide(value)
        radioInstance[index][childIndex].forEach((radio, radioIndex) => {
            if (selected == radioIndex) radioInstance[index][childIndex][radioIndex] = 1
            else radioInstance[index][childIndex][radioIndex] = 0
        })
        this.setState({
            radioInstance,
            radioTouch: true
        })
    }
    setOpenCollapse = (boolean, index, levelInstance, childAt, childIndex, prevFirstChild, prevChildIndex) => {
        let { collapseInstance: { parent, child } } = this.state
        // console.log('collapse', boolean, index, levelInstance, childAt, childIndex, parent, child)

        if (levelInstance == 'parent') parent[index] = boolean
        else {
            // console.log('child', index, child[index][childAt], childAt, childIndex, prevFirstChild, prevChildIndex)
            /**first child */
            if (prevFirstChild == undefined && prevChildIndex == undefined) {
                // console.log('both', child[index][childAt][childIndex], childAt, prevFirstChild, childIndex)
                // child[childAt][prevFirstChild][childIndex] = boolean
                try {
                    console.error('before first child', child[index][childAt], child[index])
                    child[index][childAt][childIndex] = boolean
                } catch (error) {
                    console.error('first child', error)
                }
                // console.log('after', child[index])
            }
            else {
                /**third child */
                if (childAt == 2) {
                    // console.log('if child',index, child[index][childAt][prevFirstChild][prevChildIndex][childIndex], childAt, prevFirstChild, prevChildIndex, childIndex, boolean)
                    // console.log('before', child[index][childAt][prevFirstChild][prevChildIndex])
                    try {
                        child[index][childAt][prevFirstChild][prevChildIndex][childIndex] = boolean
                    } catch (error) {
                        console.log('third child', error)
                    }
                    // console.log('afer', child[index][childAt][prevFirstChild][prevChildIndex])

                }
                /**second child */
                else {
                    // console.log('else child',index, child[index][childAt][prevFirstChild][childIndex], childAt, prevFirstChild, prevChildIndex, childIndex, boolean)
                    // console.log('before', child[index][childAt][prevFirstChild], boolean)
                    try {
                        child[index][childAt][prevFirstChild][childIndex] = boolean
                    } catch (error) {
                        console.error('second child', error, child)
                    }
                    // console.log('after', child[index][childAt][prevFirstChild])

                }
            }
        }
        this.setState({
            parent,
            child
        }/**, () => console.log('after state', child) */)
        localStorage['collapseInstance'] = JSON.stringify({ parent, child })
    }
    CollapsableRow(props) {
        const {
            key,
            parentInstance,
            childInstance,
            searchValue,
            row,
            index,
            keys,
            edit,
            remove,
            tb_add,
            addToolTip,
            row_click,
            child_remove,
            child_add,
            child_edit,
            child_tooltips,
            parentCollapse,
            highlight,
            checkboxChildState,
            checkBox_tick,
            checkBoxIsRead,
            radio_tick,
            widthStyle,
            root,
            levelInstance: { parent, child },
            lastItemBordered,
            isLastIndex
        } = props;
        // let isCollapse = parentInstance[index] || false
        let tableCellFn = checkBox_tick ?? radio_tick ?? undefined
        let isCollapse = searchValue != '' ? child?.length > 0 ? parentInstance[index] == true ? true : false : false : parentInstance[index]
        const isLastData = () => {
            return ((isLastIndex == lastItemBordered) && (isLastIndex == true)) == true ? " last-item" : ''
        }
        // console.log('iscollapse', isCollapse,  parentInstance[index],  parentInstance, row[child], row, child)
        const toolbar = [
            {
                icon: <Tooltip /**open={true} */ title={addToolTip} placement="top" arrow classes={{ tooltip: 'custom-tooltip' }}><Add className="customIcon blue" /></Tooltip>,
                fn: tb_add
            },
            {
                icon: <Edit className="customIcon blue" />,
                fn: edit
            },
            {
                icon: <Delete className="customIcon blue" />,
                fn: remove
            },
        ]
        const renderEmptyCell = () => {
            let hasFn = 0
            toolbar.forEach(x => x['fn'] ? hasFn++ : null)
            return (hasFn - 1) >= 0 ? new Array(hasFn - 1).fill(<TableCell className={"bordered" + isLastData()}>&nbsp;</TableCell>) : null
        }
        const childToolbar = [
            {
                icon: (ev) => <Tooltip /**open={true} */ title={ev ? (child_tooltips?.add ? child_tooltips?.add + ev : undefined) ?? '' : child_tooltips?.add ?? ''} placement="top" arrow classes={{ tooltip: 'custom-tooltip' }}><Add className="customIcon blue" /></Tooltip>,
                fn: child_add
            },
            {
                icon: (ev) => <Tooltip /**open={true} */ title={ev ? (child_tooltips?.edit ? child_tooltips?.edit + ev : undefined) ?? '' : child_tooltips?.edit ?? ''} placement="top" arrow classes={{ tooltip: 'custom-tooltip' }}><Edit className="customIcon blue" /></Tooltip>,
                fn: child_edit
            },
            {
                icon: (ev) => <Tooltip /**open={true} */ title={ev ? (child_tooltips?.remove ? child_tooltips?.remove + ev : undefined) ?? '' : child_tooltips?.remove ?? ''} placement="top" arrow classes={{ tooltip: 'custom-tooltip' }}><Delete className="customIcon blue" /></Tooltip>,
                fn: child_remove
            },
        ]
        const renderChildEmptyCell = (isFirstLast = '') => {
            let hasFn = 0
            childToolbar.forEach(x => x['fn'] ? hasFn++ : null)
            return hasFn - 1 > 0 ? new Array(hasFn - 1).fill(<TableCell className={"bordered" + isFirstLast}>&nbsp;</TableCell>) : null
        }
        const logger = () => {
            console.log(row[child])
            return <></>
        }
        const renderToolbar = () => {
            let toolbar = [edit, remove, tb_add]
            return (
                toolbar.map((row, x) => {
                    return row ? <TableCell key={`empty-toolbar-${x}`} className="headerValue" /> : null
                })
            )

        }
        const clickEnabled = row_click ? true : false
        // console.log('collapsable', row[parent[keys]], row, parent, keys)
        const parametizeCollapse = (nextIndex, row, prevCollapse, prevId, prevFirstChildIndex, prevChildIndex, prevSecondChild) => {
            // console.log('child', nextIndex, child[nextIndex], row, prevCollapse)
            prevCollapse = prevCollapse == undefined ? false : prevCollapse
            // if (nextIndex == 1) console.log('prev', prevCollapse)
            // if (nextIndex == 2) console.log('prev 1', prevCollapse)
            // console.log('what prevCollapse ?', prevCollapse)
            return (
                row?.length > 0 ? <>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingRight: 0, paddingLeft: 0 }} colSpan={12}>
                            <Collapse in={prevCollapse} timeout="auto" unmountOnExit>

                                <Table aria-label="collapsible table">

                                    <TableBody>
                                        {row.map((rowValue, childIndex) => {

                                            /**next collapse instance */
                                            let childCollapse
                                            let rowValueObj = { rowValue: rowValue?.[parent] ?? rowValue, from: child[nextIndex], prevId }
                                            if (nextIndex == 1) {
                                                childCollapse = childInstance?.[index]?.[nextIndex]?.[prevFirstChildIndex]?.[childIndex]
                                                prevSecondChild = childIndex
                                            }
                                            if (nextIndex == 2) {
                                                childCollapse = childInstance?.[index]?.[nextIndex]?.[prevFirstChildIndex]?.[prevChildIndex]?.[childIndex]
                                            }
                                            return (
                                                <>
                                                    <TableRow>
                                                        {
                                                            /**if the child is greater than 0 then show collapsable icon */

                                                            rowValue[parent] != undefined && rowValue?.[child?.[nextIndex + 1]]?.length > 0 ?

                                                                <TableCell className="bordered" style={{ width: `${(nextIndex + 1) + 6}%`, textAlign: 'end' }}>
                                                                    <IconButton aria-label="expand row" size="small" onClick={() => this.setOpenCollapse(!childCollapse, index, 'child', nextIndex, childIndex, prevFirstChildIndex, prevChildIndex)}>
                                                                        {childCollapse == true ? <ArrowDropDown className="customIcon large" /> : <ArrowRight className="customIcon large" />}
                                                                    </IconButton>
                                                                </TableCell>
                                                                :
                                                                nextIndex == child?.length - 1 ? <TableCell className="bordered no-collapse" style={{ width: '10%' }}>&nbsp;</TableCell> : <TableCell className="bordered no-collapse" style={{ width: `${(nextIndex + 1) + 6}%` }}>&nbsp;</TableCell>

                                                        }

                                                        {keys.map(value => {
                                                            let style = {
                                                                width: value?.['childStyles']?.[nextIndex - 1] ? `${value['childStyles'][nextIndex - 1]}${widthStyle ? widthStyle : '%'}` : 'unset',
                                                                wordBreak: 'break-word'
                                                            }
                                                            let childSanitizeValue = value['childDefaultValue'] ? value['childDefaultValue'] : rowValue[value['childKey']] ?? rowValue[value['alt']] ?? rowValue?.[parent]?.[value['childKey']] ?? rowValue?.[parent]?.[value['alt']] ?? ''
                                                            let highlightValue = setHighlight(sanitize(value['type'], childSanitizeValue, value['booleanKey'] ?? undefined, rowValueObj, tableCellFn, this, 'child', index, nextIndex, childIndex, prevFirstChildIndex, prevChildIndex, prevSecondChild), highlight, searchValue)
                                                            let italic = tableCellPopulate(undefined, highlightValue, { ...style, fontStyle: 'italic', color: '#b5b5b5' })
                                                            let normal = tableCellPopulate(undefined, highlightValue, style)
                                                            let empty = tableCellPopulate(undefined, <>&nbsp;</>, style)
                                                            let hasValue = rowValue[value['childKey']] != undefined || value['childDefaultValue'] != undefined || rowValue?.[parent]?.[value['childKey']] != undefined
                                                            return hasValue != undefined ? toItalized(rowValue?.[parent] || rowValue) == true ? italic : normal : empty
                                                        })}
                                                        {
                                                            renderChildEmptyCell()
                                                        }

                                                        <TableCell className="bordered align-end">
                                                            {childToolbar.map((instance, x) => {
                                                                return instance['fn'] && ((child?.length - 1 == nextIndex && x == 0) == false) ? <IconButton className="edit-button child-button" aria-label="expand row" size="small" onClick={(ev) => toolbarClick(instance['fn'](rowValue, child[nextIndex], prevId), ev)}>{instance['icon'](` ${capitalize(child[nextIndex + 1])}`)}</IconButton> : null
                                                            })}
                                                        </TableCell>
                                                    </TableRow>

                                                    {parametizeCollapse(nextIndex + 1, row[childIndex][child[nextIndex + 1]], childCollapse, rowValue?.details?.id ?? rowValue?.id, prevFirstChildIndex, childIndex, prevSecondChild)}
                                                </>
                                            )
                                        })}
                                    </TableBody>
                                </Table>

                                {/* {logger()} */}

                            </Collapse>
                        </TableCell>
                    </TableRow>
                </> :
                    null
            )
        }
        const collapsableElement = () => {
            return (
                <React.Fragment>
                    <TableRow key={key} onMouseOver={(e) => hoveredRow(e, 'hover', clickEnabled, true)} onMouseLeave={(e) => hoveredRow(e, 'mouseOut', clickEnabled, true)} onClick={(e) => rowClicked(e, () => row_click?.(row))}>
                        {parentCollapse == true || row[child[0]].length == 0 ?
                            <TableCell className={"bordered parent-no-collapse" + isLastData()}>&nbsp;</TableCell>
                            :
                            <TableCell className={"bordered" + isLastData()} style={{ width: '5%', textAlign: 'center' }}>
                                <IconButton aria-label="expand row" size="small" onClick={() => this.setOpenCollapse(!isCollapse, index, 'parent')}>
                                    {isCollapse ? <ArrowDropDown className="customIcon large" /> : <ArrowRight className="customIcon large" />}
                                </IconButton>
                            </TableCell>
                        }
                        {keys.map((value, x) => {
                            let style = {
                                width: value['headerWidth'] ? `${value['headerWidth']}${widthStyle ? widthStyle : '%'}` : '',
                                wordBreak: 'break-word'
                            }
                            let sanitizeValue = value['keyDefaultValue'] ? value['keyDefaultValue'] : row[parent][value['key']] ?? row[parent][value['alt']] ?? ''
                            let highlightValue = setHighlight(sanitize(value['type'], sanitizeValue, value['booleanKey'] ?? undefined, { rowValue: row?.[parent] ?? row, from: root }, tableCellFn, this, 'parent', index), highlight, searchValue)
                            let italic = tableCellPopulate(isLastData(), highlightValue, { ...style, fontStyle: 'italic', color: '#b5b5b5' }, true)
                            let normal = tableCellPopulate(isLastData(), highlightValue, style, true)
                            let empty = tableCellPopulate(isLastData(), <>&nbsp;</>, style)
                            return toItalized(row[parent]) ? italic : normal
                        })}
                        {
                            renderEmptyCell()
                        }
                        {
                            renderEmptyCell() ? <TableCell className={"bordered align-end" + isLastData()}>
                                {toolbar.map((instance, x) => {
                                    return instance['fn'] ? <IconButton key={`instance-toolbar-${x}`} className={(renderChildEmptyCell()?.length == renderEmptyCell()?.length && x != toolbar?.length - 1) ? "toolbar-button child-button" : "toolbar-button"} aria-label="expand row" size="small" onClick={(ev) => toolbarClick(instance['fn'](row), ev)}>{instance['icon']}</IconButton> : null
                                })}
                            </TableCell> : null
                        }
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                            <Collapse in={isCollapse} timeout="auto" unmountOnExit>

                                <Table aria-label="collapsible table">

                                    <TableBody>
                                        {row[child[0]].map((rowValue, childIndex) => {
                                            let firstChildCollapse = childInstance?.[index]?.[0]?.[childIndex]
                                            let rowValueObj = { rowValue: rowValue?.[parent] ?? rowValue, from: child[0], prevId: row?.details?.id ?? row?.id }
                                            const isFirstLast = () => {
                                                return (childIndex == 0 && isLastIndex == lastItemBordered && isLastIndex == true) == true ? " border-top-none" : ''
                                            }
                                            return (
                                                <>
                                                    <TableRow>
                                                        {
                                                            /**if the child is greater than 0 then show collapsable icon */

                                                            rowValue[parent] != undefined && rowValue?.[child?.[1]]?.length > 0 ?
                                                                <TableCell className={"bordered" + isFirstLast()} style={{ width: '6%', textAlign: 'end' }}>
                                                                    <IconButton aria-label="expand row" size="small" onClick={() => this.setOpenCollapse(!firstChildCollapse, index, 'child', 0, childIndex)}>
                                                                        {firstChildCollapse ? <ArrowDropDown className="customIcon large" /> : <ArrowRight className="customIcon large" />}
                                                                    </IconButton>
                                                                </TableCell>
                                                                :
                                                                <TableCell className={"bordered no-collapse" + isFirstLast()}>&nbsp;</TableCell>

                                                        }

                                                        {keys.map(value => {
                                                            let style = {
                                                                width: `${value['width']}${widthStyle ? widthStyle : '%'}`,
                                                                wordBreak: 'break-word'

                                                            }
                                                            let childSanitizeValue = value['childDefaultValue'] ? value['childDefaultValue'] : rowValue[value['childKey']] ?? rowValue[value['alt']] ?? rowValue[parent]?.[value['childKey']] ?? rowValue[parent]?.[value['alt']] ?? ''
                                                            let highlightValue = setHighlight(sanitize(value['type'], childSanitizeValue, value['booleanKey'] ?? value?.['untouchedName'] ?? undefined, rowValueObj, tableCellFn, this, 'child', index, 0, childIndex), highlight, searchValue)
                                                            let italic = tableCellPopulate(isFirstLast(), highlightValue, { ...style, fontStyle: 'italic', color: '#b5b5b5' })
                                                            let normal = tableCellPopulate(isFirstLast(), highlightValue, style)
                                                            let empty = tableCellPopulate(isFirstLast(), <>&nbsp;</>, style)
                                                            let hasValue = childSanitizeValue != undefined && (rowValue[value['alt']] != undefined ? value['childKey'] != undefined ? true : false : true)
                                                            return hasValue ? toItalized(rowValue?.[parent] ?? rowValue) == true ? italic : normal : empty
                                                        })}
                                                        {
                                                            renderChildEmptyCell(isFirstLast())
                                                        }
                                                        <TableCell className={"bordered align-end" + isFirstLast()} >
                                                            {childToolbar.map((instance, x) => {
                                                                return instance['fn'] ? <IconButton className="edit-button child-button" aria-label="expand row" size="small" onClick={(ev) => toolbarClick(instance['fn'](rowValue, child[0], row?.details?.id ?? row?.id), ev)}>{instance['icon'](` ${capitalize(child[1])}`)}</IconButton> : null
                                                            })}
                                                        </TableCell>
                                                        {/* <TableCell className="bordered">&nbsp;</TableCell> */}
                                                    </TableRow>
                                                    {parametizeCollapse(1, row[child[0]][childIndex][child[1]], firstChildCollapse, rowValue?.details?.id ?? rowValue?.id, childIndex)}


                                                </>
                                            )
                                        })}
                                    </TableBody>
                                </Table>

                                {/* {logger()} */}

                            </Collapse>
                        </TableCell>
                    </TableRow>
                </React.Fragment>
            )
        }
        return (
            collapsableElement()
        );
    }
    componentWillMount() {
        // console.log('collapsableTable', this.props, this.state)

        let { location: { pathname } } = window
        if (pathname.match(/\/configuration\/machine-config\/machine-type/gi) || pathname.match(/\/configuration\/machine-config\/machine-sub-type/gi)) {
            //ignore
        }
        else {
            delete localStorage['collapseInstance']

        }
        window.onbeforeunload = function () {
            delete localStorage['collapseInstance']
        }
    }

    componentDidUpdate() {
        // console.log('collapsableTable update', this.props, this.state)
        let { timeStamp, data } = this.props
        let { timeStamp: sTime, levelInstance, parentCollapse, radio_tick, parentExpand } = this.state
        let collapseInstance = JSON.parse(localStorage['collapseInstance'] ?? null)
        if (timeStamp != sTime) {
            this.setState({
                ...this.props,
                clonedData: data.slice(),
                collapseInstance: {
                    parent: data?.length > 0 ? new Array(data.length).fill(parentCollapse ? parentCollapse == true ? true : false : parentExpand == true ? true : false) : [],
                    child: this.state?.collapseInstance?.child?.length > 0 ? this.state.collapseInstance.child : [],
                    ...collapseInstance
                }
            }, () => {
                console.log('after update', levelInstance?.child?.length > 1, this.state.levelInstance)
                if (levelInstance?.child?.length > 1) this.populateChildInstance(true)
                if (radio_tick != undefined) this.populateRadioInstance()
            })
        }
        if (localStorage?.['tableContainerCollapseScroll']) {
            document.querySelector('.tableContainer').firstElementChild.scrollTop = localStorage?.['tableContainerCollapseScroll']
            delete localStorage?.['tableContainerCollapseScroll']
        }
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                translations: Object.assign({}, { ...this.convert() }),
                data: data,
                keys: this.props.keys,
                clonedData: data.slice(),
            })
        }
        // if (levelInstance?.child?.length > 1) {
        //     this.populateChildInstance()
        // }

    }

    /**render */
    renderTable = () => {
        let { collapsable, searchValue = '', edit, remove, addToolTip = '', tb_add, row_click, child_remove, child_edit, child_add, child_tooltips, keys, data, levelInstance, collapseInstance: { parent, child }, parentCollapse, highlight, translations, checkboxChildState, checkBox_tick, radio_tick, root, checkBoxIsRead, paginated, paginationInstance: { rowsPerPage, page }, sorted, h_scroll, tableHeaderFixed, tableHeaderWidth, widthStyle, lastItemBordered } = this.state
        let rowProps = {
            keys,
            edit,
            remove,
            searchValue,
            tb_add,
            row_click,
            addToolTip,
            parentCollapse,
            highlight,
            checkboxChildState,
            checkBox_tick,
            widthStyle,
            radio_tick,
            lastItemBordered
        }
        let collapseProps = {
            levelInstance,
            child_tooltips,
            child_remove,
            child_edit,
            child_add,
            parentInstance: parent,
            childInstance: child,
            checkBoxIsRead,
            root,
        }
        const renderToolbar = () => {
            let toolbar = [edit, remove, tb_add]
            return (
                toolbar.map((row, x) => {
                    return row ? <TableCell key={`empty-toolbar-${x}`} className="headerValue" /> : null
                })
            )

        }

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
        const handleChangePage = (event, newPage) => {
            this.setState({
                paginationInstance: {
                    ...this.state.paginationInstance,
                    page: newPage
                }
            })
        };

        const renderTableRows = () => {
            if (this.props.superAdminMessage) {
                return (
                    <TableBody>
                        <TableRow><div style={{ width: '300px', left: '41%', marginLeft: '-75px', marginTop: '10px', position: 'absolute', fontWeight: 'bold' }}>{this.props.superAdminMessage}</div></TableRow>
                    </TableBody>
                )
            }
            else {
                return (
                    <TableBody>
                        {/* <CollapsableRow key={row[levelInstance['parent']['id']]} row={row} index={index} {...rowProps} isCollapse={isCollapse()} levelInstance={levelInstance} searchValue={searchValue} /> */}

                        {collapsable ? data.map((row, index) => (
                            this.CollapsableRow({
                                key: row[levelInstance['parent']['id']],
                                row,
                                index,
                                ...rowProps,
                                ...collapseProps,
                                isLastIndex: (data?.length - 1) == index
                            })
                        )) : rowsToDisplay.map((row, index) => (
                            <Row key={row['id']} row={row} index={index} {...rowProps} countInit={countInit ? countInit++ : countInit} isLastIndex={(rowsToDisplay?.length - 1) == index} />
                        ))
                        }
                        {data.length > 0 ? <RowPopulate last={data.length} {...rowProps} isEmpty={data?.length > 0 ? false : true} collapsable={collapsable} />
                            : <TableRow><div style={{ width: '150px', left: '50%', marginLeft: '-75px', marginTop: '10px', position: 'absolute', fontWeight: 'bold' }}>{translations.NO_RESULT}</div></TableRow>
                        }
                    </TableBody>
                )
            }
        }

        const handleChangeRowsPerPage = (event) => {
            let rowsPerPage = isNaN(parseInt(event.target.value, 10)) ? 0 : parseInt(event.target.value, 10)
            this.setState({
                paginationInstance: {
                    ...this.state.paginationInstance,
                    rowsPerPage,
                    page: 0
                }
            })
        };
        let rowsToDisplay = (rowsPerPage > 0 && paginated == true ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data)
        let countInit = rowsPerPage > 0 ? page * rowsPerPage + 1 : undefined
        return (
            <div className="tableContainer">
                <TableContainer component={Paper}>
                    <Table style={{ tableLayout: tableHeaderFixed ? 'fixed' : 'initial' }} aria-label="collapsible table">
                        <TableHead className="tableHeader">
                            <TableRow >
                                {collapsable ? <TableCell className="headerValue" /> : <TableCell style={{ width: tableHeaderFixed ? '2%' : '5%' }} className="headerValue">#</TableCell>}
                                {keys.map((row, index) => {
                                    return row?.['headerLink'] != undefined ? (
                                        <TableCell key={row.name} className={row?.['disable'] == true ? "headerValue disabled" : "headerValue link"} onClick={() => row['headerLink']()}>
                                            {row.name}
                                        </TableCell>

                                    ) :
                                        <TableCell style={{ width: row?.tableHeaderWidth ? row.tableHeaderWidth : null }} key={row.name} className="headerValue" >
                                            {
                                                row?.sortState ? <TableSortLabel
                                                    // active={orderBy === headCell.id}
                                                    direction={row?.sortState ? row.sortState : false}
                                                    onClick={() => sorted ? sorted(row['key'], index, row['sortState'], searchValue) : null}
                                                >
                                                    {row.name}
                                                </TableSortLabel>

                                                    :
                                                    row?.['linkToolTip'] ? <span>{row.name}<Tooltip title={row?.['linkToolTip']} placement="bottom-start" arrow classes={{ tooltip: 'custom-tooltip' }}><HelpRounded className="customIcon blue helper-tooltip" /></Tooltip></span> : row.name
                                            }
                                        </TableCell>
                                })}
                                {renderToolbar()}
                                {/* <TableCell className="headerValue" /> */}
                            </TableRow>
                        </TableHead>
                        {renderTableRows()}
                    </Table>
                </TableContainer>
                {
                    paginated == true ?
                        <TableFooter className="paginations-right">
                            <TableRow>
                                <TablePagination
                                    labelRowsPerPage={'Rows Per Page'.translate() + ':'}
                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} ` + 'of'.translate() +  ` ${count !== -1 ? count : to}`}
                                    rowsPerPageOptions={[20, 50, { label: translations.All, value: data.length }]}
                                    colSpan={3}
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': translations.ROWS_PER_PAGE },
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions/**({ count: data.length, page, rowsPerPage, onChangePage: handleChangePage },this) */}
                                />
                            </TableRow>
                        </TableFooter> : null
                }

            </div>
        )
    }

    renderSearchBar = () => {
        let { add, searchValue, customReturnKey, data, translations } = this.state
        // let returnId = data?.[0]?.[customReturnKey] ?? undefined
        let returnId = customReturnKey ? customReturnKey.value : undefined
        if (customReturnKey) {
            return (
                <div className="search-container">
                    <div className="search-bar">
                        <div className="search-icon"><Search /></div>
                        <InputBase 
                            className="search-input" 
                            placeholder={translations.SEARCH} 
                            defaultValue={this.state.searchInputValue}
                            onChange={(evt)=> this.deboundSearch(evt.target.value)}
                        />
                    </div>
                    {
                        add ? <div className="add-button"><Button variant="outlined" color="primary" startIcon={<Add />} onClick={() => add(returnId)}>{translations.ADD}</Button></div> :
                            <div className="add-button disabled"><Button variant="outlined" disabled color="primary" startIcon={<Add />}>{translations.ADD}</Button></div>
                    }
                </div>
            )
        }
        else {
            return (
                <div className="search-container">
                    <div className="search-bar">
                        <div className="search-icon"><Search /></div>
                        <InputBase 
                            className="search-input" 
                            placeholder={translations.SEARCH} 
                            onChange={(evt)=> this.deboundSearch(evt.target.value)} 
                            defaultValue={this.state.searchInputValue}
                        />
                    </div>
                    {
                        add ? <div className="add-button"><Button variant="outlined" color="primary" startIcon={<Add />} onClick={() => add(returnId)}>{translations.ADD}</Button></div> :
                            <div className="add-button disabled"><Button variant="outlined" disabled color="primary" startIcon={<Add />}>{translations.ADD}</Button></div>
                    }
                </div>

            )
        }
    }
    render() {
        return (
            <div>
                <div className="tableContainer-overlay">
                    <div>
                        <img /><IconButton className="close" size="medium" onClick={(ev) => {
                            renderOverlayImage(false)
                            hoveredCloseIcon(ev, false)
                        }} onMouseOver={(ev) => hoveredCloseIcon(ev, true)} onMouseLeave={(ev) => hoveredCloseIcon(ev, false)}><Cancel className="customIcon light-gray close" /></IconButton>
                    </div>
                </div>
                {this.state.search ? this.renderSearchBar() : null}
                {this.renderTable()}
            </div>
        )
    }
}
const stateToProps = state => {
    let { translationReducer } = state
    return {
        ...translationReducer
    }

}
CollapsibleTable = connect(stateToProps)(CollapsibleTable)
