import React from 'react';
import moment from 'moment';
import history from '../../../history';
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { checkIfApiLoading, checkPageActionAccess, checkPageAccess } from '../../../utils/helper';
import { setChecklistDetail,setChecklistTemplates,getChecklistTemplates, getChecklistDetail, putInActiveChecklist, getMachinelist, postAssignChecklistToMachine } from '../../../redux/actions/Checklist/checklistActions';
import { getChecklistTemplateStatus} from '../../../redux/actions/Status/StatusActions';
import { showModal, closeModal } from '../../../redux/actions/Modal/modalActions';
import { notificationSuccess, notificationError } from '../../../redux/actions/Notification/notificationActions';
import { connect } from "react-redux";
import MainPage from '../../../components/MainPage/MainPage';
import MainPageHeader from '../../../components/MainPageHeader/MainPageHeader';
import MainPageBody from '../../../components/MainPageBody/MainPageBody';
import MainTable from '../../../components/MainTable/MainTable';
import MaintenanceChecklist from '../../../components/Modal/MaintenanceChecklist/MaintenanceChecklist';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { apiSettings } from '../../../utils/api/CoreApi';
import ModalButton from './Modal/Modal';
import ChecklistDetail from './CheckListDetail'
import dictionary from '../../../redux/actions/Translation/dictionary'
import './Checklist.scss';
import { formatDate } from '../../../utils/date';

class Checklist extends React.Component {
    tickets = []
    filterType = ''
    filtered = false
    state = {}

    constructor(props) {
        super(props);

        this.state = {
            statusLoaded: false,
            translations: {
                ...this.convert()
            },
            tooltipTheme: createMuiTheme({
                overrides: {
                    MuiTooltip: {
                        tooltip: {
                            fontSize: "1rem",
                        }
                    }
                }
            }),
            tableHeader: [
                {
                    value: 'NO',
                    size: '80px'
                },
                {
                    value: 'NAME',
                    size: '80px'
                },
                {
                    value: 'TEMPLATE TYPE',
                    size: '120px'
                },
                {
                    value: 'BRAND',
                    size: '70px'
                },
                {
                    value: 'DATE CREATED',
                    size: '120px'
                },
                {
                    value: '# MACHINES',
                    size: '100px'
                },
                // {
                //     value: 'MAIN FREQ',
                //     size: '120px'
                // },
                {
                    value: '# STEPS',
                    size: '100px'
                },
                {
                    value: 'LAST USED',
                    size: '100px'
                },
                {
                    value: 'STATUS',
                    size: '100px'
                },
            ],
            hiddenColumns: [
                {
                    header: '# MACHINES',
                    value: 'totalMachines'
                },
                {
                    header: '# STEPS',
                    value: 'totalSteps'
                },
                {
                    header: 'LAST USED',
                    value: 'lastUsed'
                },
                {
                    header: 'STATUS',
                    value: 'status'
                },
            ],
            translationTimestamp: undefined,
            statusId:null,
            searchString:'all',
            currentPage:0,
            pageSize:10,
            checklistWillLoad:false,
            checklistTemplates:[],
            openSideDetail:false,
            selectedId:null,
            checkedId:null,
            selectedTemplateType:null,
            initialRender:true,
            rowLength:0,
            rowCount:10,
            checklistNo:'',
            checklistName:'',
            checklistType:'',
            machinesCount:null,
            stepsCount:null,
            brand:'',
            mainFreq:'',
            lastUsed:'',
            checklistStatus:'',
            checklistDetailWillLoad:false,
            checklistWillUpdate:false,
            checklistWillLoadFromUpdate:false,
            taskCount:[],
            tasksDetailed:[],
            machineListChecklist:[],
            machineListWillLoad:false,
            postAssignChecklistAction:false,
            historyPage:0,
        };
    }

    

    /**handler */
    convert = () => {
        let {
            NOTIFICATIONS: {
                are_you_sure_you_want_to_make_this_checklist_template_inactive
            },
            YES,NO,
            MACHINES, ALL, STATUS, STEPS, BRAND, LAST_USED,
        } = dictionary.GET()
        
        return dictionary.TRANSLATE({
            are_you_sure_you_want_to_make_this_checklist_template_inactive,
            YES,NO,
            MACHINES, ALL, STATUS, STEPS, BRAND, LAST_USED,
        })
    }

    componentWillUnmount(){
        localStorage.removeItem('statusId');
        localStorage.removeItem('noload');
    }

    resetRedirects() {
        localStorage.removeItem('redirectToMachineDetails');
    }

    componentDidMount() {
         this.resetRedirects();
         let history = localStorage.getItem('redirectToChecklist')
         let statusId = null;
         let searchString = null;
         let currentPage = null;
         let page = 1
         console.log('check history')
         console.log(history)
         if(history === 'true'){
            console.log('came from history BACK')
            localStorage.setItem('redirectToChecklist', false);
            statusId = localStorage.getItem('checklistStatusId') === null ? this.state.statusId : localStorage.getItem('checklistStatusId')
            searchString = localStorage.getItem('checklistSearchString') === null ? this.state.searchString : localStorage.getItem('checklistSearchString')
            currentPage = localStorage.getItem('checkListCurrentPage') === null ? this.state.currentPage : parseInt(localStorage.getItem('checkListCurrentPage'))

            
            console.log('check status id ' + statusId)
            console.log('check searchString id ' + searchString)
            console.log('check currentPage id ' + currentPage)
         }else{
            statusId = this.state.statusId
            searchString = this.state.searchString
            currentPage = this.state.currentPage
         }
         this.setState({
             statusId:statusId,
             searchString:searchString,
             historyPage:currentPage,
             checklistWillLoad:true,
         },() => {
            this.props.getChecklistTemplateStatus();
            this.props.setChecklistDetail(null);
         })
     }

     componentWillReceiveProps(nextProps) {
         if (nextProps.checklistTemplatesStatusList != undefined && this.state.statusLoaded === false){
             if (localStorage.getItem('checklistStatusId')){
                let statusId = localStorage.getItem('checklistStatusId');
                let searchString = this.props.searchString ? this.props.searchString : this.state.searchString;
                this.props.getChecklistTemplates(statusId, searchString, this.props.meta.currentPage, this.state.pageSize);
                this.setState({
                    statusId: statusId,
                    statusLoaded: true
                })
             }
             else{
                 let searchString = this.props.searchString ? this.props.searchString : this.state.searchString;
                 let activeStatus = nextProps.checklistTemplatesStatusList.filter((item) => { return item.status === 'ACTIVE' && item.statusId === '2' });
                 if (activeStatus.length > 0){
                        this.props.getChecklistTemplates(activeStatus[0].id, searchString, this.props.meta.currentPage, this.state.pageSize);
                        this.setState({
                            statusId: activeStatus[0].id,
                            statusLoaded: true
                        })
                 }
             }
         }
     }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.checklistTemplateLoading === false) {
            if(nextState.checklistWillLoad === true){
                let count = 10
                if(nextProps.rows < 10){
                    count = nextProps.rows
                }
                if(nextState.initialRender === true){      
                localStorage.setItem('checkListCurrentPage', 0)
                console.log('check data of checklist')    
                console.log(nextProps.checklistTemplates)     
                this.setState({
                    checklistTemplates:nextProps.checklistTemplates,
                    currentPage:nextState.historyPage,
                    rowLength:nextProps.rows,
                    checklistWillLoad:false,
                    initialRender:false,
                    rowCount:count,
                })
                }else{
                    console.log('not initial render')
                    localStorage.setItem('checkListCurrentPage', 0)
                    this.setState({
                        checklistTemplates:nextProps.checklistTemplates,
                        currentPage:0,
                        rowLength:nextProps.rows,
                        checklistWillLoad:false,
                        rowCount:count,
                    })
                }
                // localStorage.setItem('checklistStatusId', this.state.statusId);
                localStorage.setItem('checklistSearchString', this.state.searchString);
                localStorage.setItem('checkListCurrentPage', this.state.currentPage);
            }

            if(nextState.checklistWillLoadFromUpdate === true){
                console.log('new data will come')
                console.log(nextProps.checklistTemplates)
                this.setState({
                    checklistTemplates:nextProps.checklistTemplates,
                    checklistWillLoadFromUpdate:false,
                })
            }
        }
        //neil
        if (nextProps.checklistDetailLoading === false) {
            if(nextState.checklistDetailWillLoad === true){
                let taskCount = []
                if (nextProps.taskCount.length > 0){
                    if(nextProps.taskCount[0].task === ""){
                        taskCount = []
                    }else{
                        taskCount = nextProps.taskCount
                    }
                }
                this.setState({
                    taskCount:taskCount,
                    tasksDetailed:nextProps.tasksDetailed,
                    checklistDetailWillLoad:false,
                })
            }
        }
        //UPDATE
        if (nextProps.checklistUpdateLoading === false) {
            if(nextState.checklistWillUpdate === true){
                console.log('checklist will load from update')
                let page = this.props.meta.currentPage;
                this.props.getChecklistTemplates(this.state.statusId, this.state.searchString, page, this.state.pageSize)
                this.setState({
                    checklistWillUpdate:false,
                    checklistWillLoadFromUpdate:true,
                })
            }
        }

        //Machine List
        if (nextProps.machineListloading === false) {
            if(nextState.machineListWillLoad === true){
                this.setState({
                    machineListChecklist:nextProps.machineListChecklist,
                    machineListWillLoad:false,
                })
            }
        }

        //Assign Machine
        if (nextProps.postChecklistMachineLoading === false) {
            if(nextState.postAssignChecklistAction === true){
                console.log('done posting machine')
                let page = this.props.meta.currentPage;
                this.props.getChecklistTemplates(this.state.statusId, this.state.searchString, page, this.state.pageSize)
                this.setState({
                    postAssignChecklistAction:false,
                    checklistWillLoadFromUpdate:true,
                })
            }
        }

    }

    componentDidUpdate(){
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                translations: Object.assign({}, { ...this.convert() })
            })
        }
    }

    handleSortTableData(data) {
        let sortedData = data.sort((a, b) => {
            let date1 = new Date(a.createdDt)
            let date2 = new Date(b.createdDt)
            let result = date2 - date1
            if (result === 0) {
                result = a.selected.id - b.selected.id;
            }
            return result
        })
        return sortedData
    }

    postAssignChecklistToMachineLocal = (payload) => {
        payload = {
            ...payload,
            machineListPayload: {
                tempType:this.state.selectedTemplateType,
                id: this.state.selectedId
            }
        }
        this.props.postAssignChecklistToMachine(payload)
        this.setState({
            postAssignChecklistAction:true,
        })
    }

    
    handleFormChange = ({ target }) => {
        this.setState({ modalValue: target.value });
    };

    handleModalValue = (value) => {
        this.setState({ modalValue: value });
    };

    onChangePage = (value) => {
        // console.log('check page value ' + value)
        // let setter = value + 1
        // let divider = 10 * setter
        // let ten = 10
        // let remainder = 10
        // let rowLength = this.state.rowLength
        // if(divider > rowLength){
        //     let subtract = 0
        //     subtract = divider - rowLength
        //     remainder = ten - subtract
        // }
        // localStorage.setItem('checkListCurrentPage', value)
        // this.setState({
        //     currentPage:value,
        //     rowCount:remainder,
        // })
        this.props.getChecklistTemplates(this.state.statusId, this.state.searchString, value, this.state.pageSize);
    }

    renderDate(date) {
        if (date !== null) {
            let timezone = moment.parseZone(date).format('Z');
            let dateTimeOnly = moment.parseZone(date).format('YYYY-MM-DD HH:mm:ss') + '+00:00';
            return moment(dateTimeOnly).utcOffset(timezone).format('DD-MM-YYYY');
        }
        else {
            return '-';
        }
    }

    renderStatusClass(status) {
        if (status === 'ACTIVE') {
            let color = 'ticket-status text-align-right active'
            return color
        }else if(status === 'DRAFT'){
            let color = 'ticket-status text-align-right in-repair'
            return color
        }else if(status === 'INACTIVE'){
            let color = 'ticket-status text-align-right inactive'
            return color
        }

    }

    renderTime(date) {
        if (date !== null) {
            let timezone = moment.parseZone(date).format('Z');
            let dateTimeOnly = moment.parseZone(date).format('YYYY-MM-DD HH:mm:ss') + '+00:00';
            return moment(dateTimeOnly).utcOffset(timezone).format('HH:mm');
        }
        else {
            return '-';
        }
    }

    openChecklistModal = () => {
        this.props.showModal('maintenanceChecklist');
    }

    onTickCheckBox = (data, id) => {
        console.log('on checkbox')
        let checkedid = id
        let tmpArray = data.map((item) => {
            let row = null;
            if (item.selected.id === id){
                row = {
                    ...item,
                    selected: {
                        ...item.selected,
                        'value': !item.selected.value,
                    }
                }
                let selectedBoolean = !item.selected.value
                if(selectedBoolean === false){
                    checkedid = null
                }
            }
            else{
                row = {
                    ...item
                }
            }
            if (item.selected.id !== id){
                row = {
                    ...item,
                    selected: {
                        ...item.selected,
                        'value': false,
                    }
                }
            }
            return row;
        });
        this.setState({
            checklistTemplates:tmpArray,
            checkedId:checkedid,
        });
        this.props.setChecklistTemplates(tmpArray);
    }

    onHighLight = (data, id) => {
        console.log('on highlight')
        let templateType = null
        let checklistNo = ''
        let checklistName = ''
        let checklistType = ''
        let machinesCount = ''
        let stepsCount = ''
        let brand = ''
        let mainFreq = ''
        let lastUsed = ''
        let checklistStatus = ''
        data.map((item) => {
            if (item.selected.id === id){
                templateType = item.templateType
                this.props.getChecklistDetail(id);
                checklistNo = item.templateName.value
                checklistName = item.name
                checklistType = item.templateType
                machinesCount = item.totalMachines
                stepsCount = item.totalSteps
                brand = item.brand
                mainFreq = item.frequency
                lastUsed = item.lastUsed
                checklistStatus = item.status
            }
        });
        this.props.getMachinelist(templateType, id)
        this.setState({
            selectedId:id,
            selectedTemplateType:templateType,
            checklistNo:checklistNo,
            checklistName:checklistName,
            checklistType:checklistType,
            machinesCount:machinesCount,
            stepsCount:stepsCount,
            brand:brand,
            mainFreq:mainFreq,
            lastUsed:lastUsed,
            checklistStatus:checklistStatus,
            taskCount:[],
            tasksDetailed:[],
            checklistDetailWillLoad:true,
            machineListWillLoad:true,
        })
    }

    openSideDetail = (value, selectedid) => {
        console.log('side details ' + selectedid)
        let id = selectedid
        let checklistNo = this.state.checklistNo
        let checklistName = this.state.checklistName
        let checklistType = this.state.checklistType
        let machinesCount = this.state.machinesCount
        let stepsCount = this.state.stepsCount
        let brand = this.state.brand
        let mainFreq = this.state.mainFreq
        let lastUsed = this.state.lastUsed
        let checklistStatus = this.state.checklistStatus
        let checkedid = this.state.checkedId
        let data = this.state.checklistTemplates
        let tmpArray = []
        if(data.length === 0){
            value = false;
        }
        if(value === true){
            if(id === null){
                if(this.state.checklistTemplates.length > 0){       
                    console.log(this.state.checklistTemplates[0])
                    id = this.state.checklistTemplates[0].selected.id
                    checklistNo = this.state.checklistTemplates[0].templateName.value
                    checklistName = this.state.checklistTemplates[0].name
                    checklistType = this.state.checklistTemplates[0].templateType
                    machinesCount = this.state.checklistTemplates[0].totalMachines
                    stepsCount = this.state.checklistTemplates[0].totalSteps
                    brand = this.state.checklistTemplates[0].brand
                    mainFreq = this.state.checklistTemplates[0].frequency
                    lastUsed = this.state.checklistTemplates[0].lastUsed
                    checklistStatus = this.state.checklistTemplates[0].status
                    this.props.getChecklistDetail(id);
                }
            }
            tmpArray = this.state.checklistTemplates
        }else{
            id = null
            checkedid = null
            tmpArray = data.map((item) => {
                let row = null;
                    row = {
                        ...item,
                        selected: {
                            ...item.selected,
                            'value': false,
                        }
                    } 
                return row;
            });
        }
        this.props.getMachinelist(checklistType, id)
        this.setState({
            checklistTemplates:tmpArray,
            openSideDetail: value,
            selectedId:id,
            checkedId:checkedid,
            checklistNo:checklistNo,
            checklistName:checklistName,
            checklistType:checklistType,
            machinesCount:machinesCount,
            stepsCount:stepsCount,
            brand:brand,
            mainFreq:mainFreq,
            lastUsed:lastUsed,
            checklistStatus:checklistStatus,
            checklistDetailWillLoad:true,
            machineListWillLoad:true,
        })
    }

    renderText(field) {
        if (field === null || field === '') {
            return '-'
        }
        else {
            return field
        }
    }

    renderFilterItems() {
        let status = [];
        this.props.checklistTemplatesStatusList.map((item) => {
            status.push({
                id: item.id,
                value: item.status
            })
            return '';
        })
        let menuItems = status.map((item, itemIndex) => {
            return (
                <MenuItem key={itemIndex} value={item.id}>{item.value.translate()}</MenuItem>
            );
        })
        return menuItems;
    }

    openModal = () => {
        this.setState({ modalOpen: true });
    }

    closeModal = () => {
        this.setState({ modalOpen: false });
    }

    handleSearch = (data) => {
        console.log('test of search ' + data)
        let searchData = data
        let page = 1
        if(searchData === ""){
            searchData = "all"
        }
        this.props.getChecklistTemplates(this.state.statusId, searchData, page, this.state.pageSize)
        localStorage.setItem('checklistSearchString', searchData)
        this.setState({ 
            checklistWillLoad: true,
            searchString:searchData, 
        })
    }

    handleStatusFilterChange = (event) => {
        console.log('handle status filter change')
        let status = event.target.value
        let page = 1
        this.props.getChecklistTemplates(status, this.state.searchString, page, this.state.pageSize)
        localStorage.setItem('checklistStatusId', status)
        this.setState({
            checklistWillLoad: true,
            statusId:status,
        })
        console.log(event.target.value)
    }

    handleInactiveStatus = () => {
        let {translations} = this.state
        if(this.state.checkedId === null){
            this.props.notificationError('Please Select a Checklist')
        }else{
            if(this.state.checklistStatus === 'INACTIVE'){
                let data = this.state.checklistTemplates
                let tmpArray = []
                let id = null
                let checkedid = null
                tmpArray = data.map((item) => {
                    let row = null;
                        row = {
                            ...item,
                            selected: {
                                ...item.selected,
                                'value': false,
                            }
                        } 
                    return row;
                });
                this.setState({
                    checklistTemplates:tmpArray,
                    selectedId:id,
                    checkedId:checkedid,
                })
                this.props.notificationError('Checklist is already Inactive')
            }else{
                confirmAlert({
                    title:  translations.are_you_sure_you_want_to_make_this_checklist_template_inactive,
                    message: '',
                    buttons: [
                        {
                            label:  translations.YES,
                            onClick: () => {
                                let payload = {
                                    id:this.state.checkedId,
                                    status:'INACTIVE'
                                }
                                this.props.putInActiveChecklist(payload) 
                                this.setState({
                                    checklistWillUpdate:true,
                                    checklistStatus:'INACTIVE',
                                    checkedId:null,
                                    selectedId:null,
                                })
                            }
                        },
                        {
                            label:  translations.NO,
                        }
                    ]
                });
            }
        }

    }

    renderTableData() {
        let {translations} = this.state
        return <MainTable 
                hiddenColumns={this.state.hiddenColumns} 
                tableName="checklist" 
                height="100%" 
                onChangePage={this.onChangePage}
                highlight={this.props.searchString}
                onTickCheckBox={this.onTickCheckBox}
                onHighLight={this.onHighLight}
                onSelectRow={this.onSelectRow} 
                page={this.state.currentPage} 
                rowsPerPage={10} 
                header={this.state.tableHeader} 
                data={this.handleSortTableData(this.props.checklistTemplates)} 
                meta={this.props.meta} 
                openSideDetail={this.state.openSideDetail}
                selectedId={this.state.selectedId}
                checkedId={this.state.checkedId}
                link={"/template-detail"}>
            <MuiThemeProvider theme={this.state.tooltipTheme}>
            <div className="toggle-detail">
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Grid className="header-detail" container spacing={0}>
                                <Grid container spacing={0}>
                                    <Grid item xs={9}>
                                        <Tooltip placement="top-start" title={this.state.checklistName.translate()}>
                                            <div className="machineNo text-ellipsis title">{this.state.checklistName.translate()}</div>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {
                                       <div className={this.renderStatusClass(this.state.checklistStatus)}>{this.state.checklistStatus ? this.state.checklistStatus.translate() : this.state.checklistStatus}</div>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={9}>
                                            <div className="yti header-label">{this.state.checklistNo}</div>
                                            <div className="createdBy header-label">{this.state.checklistType}</div>
                                        </Grid>
                                        <Grid item xs={3}>
                                         <div>
                                             {
                                                //  this.props.machineListloading === false ? (
                                                    <ModalButton buttonDisabled={checkPageActionAccess(this.props.userAccess,{pageId:16,pageAction:'Assign to Machine'}) === false} padding={"13px 13px"} machineListChecklist={this.props.machineListChecklist} postAssignChecklistToMachine={this.postAssignChecklistToMachineLocal} checklistStatus={this.state.checklistStatus} checkedId={this.state.selectedId} selectedTemplateType={this.state.selectedTemplateType}/>
                                                //  ) : (<div></div>)
                                             }
                                            
                                         </div>
                                        </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className="body-detail" container spacing={0}>
                                    <Grid item xs={6}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label"># {translations.MACHINES}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                            <div className="body-value text-ellipsis">{this.state.machinesCount === null ? 0 : this.state.machinesCount}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label"># {translations.STEPS}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="body-value">{this.state.stepsCount === null ? 0 : this.state.stepsCount}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label">{translations.BRAND}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="body-value">{this.state.brand}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={0}>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container spacing={0}>
                                            {/* <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label">{"Maintenance Freq".translate()}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="body-value">{this.state.mainFreq}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid> */}
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label">{translations.LAST_USED}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="body-value">{formatDate(this.state.lastUsed)}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                        </Grid>
                    </Grid>
                    {
                    this.state.taskCount.length > 0 ?
                    this.state.taskCount.map((item, itemIndex) => (
                         <ChecklistDetail key={itemIndex} numberCount={itemIndex + 1} task={item.task} subSteps={item.subSteps} tasksDetailed={this.state.tasksDetailed[itemIndex].subtasks}/>
                    )) : null} 
                </div>
            </MuiThemeProvider>
        </MainTable>;
    }

    preventLinkRedirect = (e) => {
        e.preventDefault();
    }

    render() {
        let {translations} = this.state
        return (
            <MainPage className="checklist-overview">
                <MainPageHeader 
                    searchLabel="Templates" 
                    searchString={this.props.searchString} 
                    name="checklist" title="Checklist Templates" 
                    search={true} 
                    selectedId={this.state.selectedId}
                    rows={this.props.meta.count}
                    openSideDetail={this.openSideDetail}
                    showChecklistDetail={this.state.openSideDetail}
                    displayedRows={this.state.rowCount}
                    handleSearch={this.handleSearch} />
                <MainPageBody>
                    <Grid container spacing={0}>
                        <Grid className="actions" item xs={5}>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <div className={'action-item ' + (checkPageActionAccess(this.props.userAccess,{pageId:15,pageAction:'New'}) ? null : 'disabled')}>
                                        <Link onClick={(e) => checkPageActionAccess(this.props.userAccess,{pageId:15,pageAction:'New'}) === false ? this.preventLinkRedirect(e) : null} to={checkPageActionAccess(this.props.userAccess,{pageId:15,pageAction:'New'}) ? "/checklist/new"  : null }>
                                             <Button disabled={checkPageActionAccess(this.props.userAccess,{pageId:15,pageAction:'New'}) ? false : true} fullWidth variant="outlined" color="primary"><span>{ dictionary.PHRASES('NEW') }</span></Button>
                                        </Link>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className={'action-item '  + (checkPageActionAccess(this.props.userAccess,{pageId:15,pageAction:'Make Inactive'}) ? null : 'disabled')}>
                                        <Link>
                                            <Button onClick={checkPageActionAccess(this.props.userAccess,{pageId:15,pageAction:'Make Inactive'}) ? this.handleInactiveStatus : null} disabled={checkPageActionAccess(this.props.userAccess,{pageId:15,pageAction:'Make Inactive'}) ? false : true} fullWidth variant="outlined" color="primary"><span>{ dictionary.PHRASES('MAKE_INACTIVE') }</span></Button>
                                        </Link>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}/>
                        <Grid className="filters" item xs={5}>
                            <Grid container spacing={3}>
                                <Grid item xs={6} />
                                <Grid item xs={6}>
                                    <Grid container spacing={5}>
                                        <Grid className="filter-label" item xs={3}>{translations.STATUS}</Grid>
                                        <Grid className="filter-select" item xs={9}>
                                            <FormControl variant="outlined" fullWidth>
                                                <Select className="form-select" onChange={this.handleStatusFilterChange} value={this.state.statusId} placeholder="Status">
                                                    <MenuItem value="all">{translations.ALL}</MenuItem>
                                                    {this.renderFilterItems()}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {this.renderTableData()}
                </MainPageBody>
            </MainPage>
        );
    }
}

const mapStateToProps = state => {
    let { translationReducer } = state
    let apiLoading = {
        statusListLoading: state.statusListReducer.loading,
        checklistTemplateLoading: state.checklistReducer.templateLoading,
        checklistDetailLoading: state.checklistReducer.checklistDetailLoading,
        checklistUpdateLoading: state.checklistReducer.checklistUpdateLoading,
        machineListloading: state.checklistReducer.machineListChecklistLoading,
        postChecklistMachineLoading: state.checklistReducer.postChecklistMachineLoading,
    }
    let data = {
        userAccess: state.authReducer.access,
        checklistTemplatesStatusList: state.statusListReducer.checklistTemplatesStatusList,
        checklistTemplates: state.checklistReducer.checklistTemplates,
        rows: state.checklistReducer.rows,
        currentPage: state.checklistReducer.currentPage,
        searchString: state.checklistReducer.searchString,
        statusList: state.statusListReducer.checklistTemplatesStatusList,
        checklistFilter: state.checklistReducer.checklistFilter,
        taskCount: state.checklistReducer.taskCount,
        tasksDetailed: state.checklistReducer.tasksDetailed,
        machineListChecklist: state.checklistReducer.machineListChecklist,
        meta: state.checklistReducer.meta,
        error: state.checklistReducer.error,
    }

    return {
        ...apiLoading,
        ...data,
        ...translationReducer
    }
}

const mapDispatchToProps = dispatch => {
    return { 
        setChecklistTemplates: (checklist) => dispatch(setChecklistTemplates(checklist)),
        setChecklistDetail: (checklistDetail) => dispatch(setChecklistDetail(checklistDetail)),
        getChecklistTemplates: (statusId, searchString, currentPage, pageSize) => dispatch(getChecklistTemplates(statusId, searchString, currentPage, pageSize)),
        getChecklistTemplateStatus: () => dispatch(getChecklistTemplateStatus()),
        getChecklistDetail: (id) => dispatch(getChecklistDetail(id)),
        showModal: (modal) => dispatch(showModal(modal)),
        getMachinelist: (temptype, checklistId) => dispatch(getMachinelist(temptype, checklistId)),
        notificationSuccess: (message) => dispatch(notificationSuccess(message)),
        notificationError: (message) => dispatch(notificationError(message)),
        putInActiveChecklist: (payload) => dispatch(putInActiveChecklist(payload)),
        postAssignChecklistToMachine: (payload) => dispatch(postAssignChecklistToMachine(payload)),
    }
}

Checklist = connect(mapStateToProps, mapDispatchToProps)(Checklist);

export default Checklist;
