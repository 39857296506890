import React, { useState } from 'react';
import SearchInput from './SearchInput';
import {
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormGroup,
  FormControl
} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import FilterCheckBox from '../components/FilterCheckBox';
import IndeterminateCheckBox from '../components/IndeterminateCheckBox';

const MuiAccordion = withStyles({
  root: {
    border: 0,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion);

const MuiAccordionSummary = withStyles({
  root: {
    flexDirection: 'row-reverse',
    backgroundColor: 'rgba(0, 0, 0, .03)',
    minHeight: 30,
    '&$expanded': {
      minHeight: 30,
    },
  },
  expandIcon: {
    marginRight: 0,
    paddingLeft: 0,
    '&$expanded': {
      paddingRight: 0,
      paddingLeft: 12,
      transform: 'rotate(210deg)',
    },
  },
  content: {
    justifyContent: 'space-between',
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {
    minHeight: 30,
  },
})(AccordionSummary);

const useStyles = makeStyles({
  numberOfSelected: {
    lineHeight: 2,
    color: 'grey'
  },
  accordionDetails: {
    width: "100%"
  },
})

export default function Filter(props){
  const { setEntities } = props;
  const classes = useStyles();
  const [filter, setFilter] = useState("");

  const toggleCheck = (event, entity)=>{
    const index = props.entities.findIndex((e)=> e.id == entity.id)
    const checked = event.target.checked;
    let temp = props.entities;
    temp[index].isSelected = checked;
    setEntities([...temp]);
  }

  const toggleCheckAll = (event)=>{
    let checked = event.target.checked;
    setEntities(props.entities.map((e)=>({ ...e, isSelected: checked })))
  }

  const checkedAll = props.entities.every((e)=> e.isSelected);
  const entities = props.entities.filter((e)=> (e.name || "").toLowerCase().includes(filter.toLowerCase()))

  return <MuiAccordion defaultExpanded={false}>
    <MuiAccordionSummary
      aria-controls="panel1a-content"
      id="panel1a-header"
      expandIcon={<PlayArrowIcon />}
      classes={{
        root: classes.accordionSummaryRoot
      }}
    >
      <h4>{props.title}</h4>

      <Typography className={classes.numberOfSelected}>
        { checkedAll ? "All Selected".translate() : `${props.entities.filter((e)=> e.isSelected).length} Selected` }

      </Typography>
    </MuiAccordionSummary>
    <AccordionDetails>
      <div className={classes.accordionDetails}>
        <SearchInput
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          placeholder={"Search".translate()}
        />

        <FormControl fullWidth variant="outlined">
          <FormGroup row>
            <FormControlLabel
              control={<IndeterminateCheckBox
                checked={checkedAll}
                onChange={(e)=> toggleCheckAll(e)}
              />}
              label={"All".translate()}
            />
          </FormGroup>
          { entities.map((entity, index)=>(
            <FormGroup key={entity.id || `null-${index}`} row>
              <FormControlLabel
                control={<FilterCheckBox
                  checked={entity.isSelected}
                  disabled={entity.isDisabled}
                  onChange={(e)=> toggleCheck(e, entity)}
                />}
                label={entity.id ? entity.name : "N/A"}
              />
            </FormGroup>
          )) }
        </FormControl>
      </div>
    </AccordionDetails>
  </MuiAccordion>
}
