import React from 'react';
import { 
    TableBody as Body, 
    TableCell, 
    TableRow,
} from '@material-ui/core';
import { 
    BoxStatus, 
    MuiTableCellLink, 
    TableCellEllipsis,
} from '../styles';
import { numberWithCommas } from '../../../../../../../../utils/helper';
import { EmptyRecord } from '../../../../styles';
import { useSelector } from 'react-redux';
function TableBody({ data = [] }) {
    const { machinesPerformance } = useSelector(({machineSummaryReducer}) => machineSummaryReducer);
    const { pagination } = machinesPerformance;
    const { pageSize, page } = pagination;

    return (
        <Body>
            {data.length > 0 ? data.map((row, key) => (   
                <TableRow key={key}>
                    <TableCell>{((page - 1) * pageSize) + (key + 1)}</TableCell>
                    <MuiTableCellLink id={row.machineId} text={row.machineNo} />
                    <TableCellEllipsis>{row.machineSubTypeName}</TableCellEllipsis>
                    <TableCell align='right'>{numberWithCommas(row.countRepairTickets)}</TableCell>
                    <TableCell align='right'>{row.NPTTime ? numberWithCommas(row.NPTTime.toFixed(2)) : 0}</TableCell>
                    <TableCell align='right'>{numberWithCommas(row.countMaintenanceTickets)}</TableCell>
                    <TableCell align='right'>{row.maintenanceOverdue}%</TableCell>
                    <TableCell>{row.maintenanceFrequency}</TableCell>
                    <TableCell>{row.currentLineName || row.currentAreaName}</TableCell>
                    <TableCell align='right'>{numberWithCommas(row.inventoryDays)}</TableCell>
                    <TableCell align='right'>{numberWithCommas(row.machineCreatedDays)}</TableCell>
                    <TableCell align='right'>{numberWithCommas(row.workingDays)}</TableCell>
                    <TableCell><BoxStatus status={row.status.toLowerCase()} /></TableCell>
                    <TableCell>{row.machineUsage ? row.machineUsage.toFixed(2) : 0}%</TableCell>
                </TableRow>
            )): 
            <TableRow>
                <TableCell colSpan={13}>
                    <EmptyRecord text={'No records found'.translate()} />
                </TableCell>
            </TableRow>
            }
        </Body>
    );
}

export default TableBody;
