import {
    LINES_REQUEST,
    LINES_SUCCESS,
    LINE_AREA_REQUEST,
    LINE_AREA_SUCCESS,
} from '../../actions/actionTypes';

const initialState = {
    loading: '',
    areaLoading: false,
    actionLoading: false,
    lines: [],
    lineArea: [],
    error: ''
}

const linesReducer = (state = initialState, action) => {
    switch (action.type) {
        case LINES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case LINES_SUCCESS:
            return {
                ...state,
                loading: false,
                lines: action.payload.lines,
                error: ''
            }
            case LINE_AREA_REQUEST:
                return {
                    ...state,
                    areaLoading: true
                }
            case LINE_AREA_SUCCESS:
                return {
                    ...state,
                    areaLoading: false,
                    lineArea: action.payload.lines,
                    error: ''
                }
        default: 
            return state;
    }
}

export default linesReducer;