import React from 'react';
import { IconButton } from '@material-ui/core';
import { ExportIcon } from '../../../../styles';
import { 
    CustomerSelect, 
    FlexBox, 
    Header, 
    Legend, 
    Title, 
} from './styles';
import { legends, selectOptions } from './constants';

function MachineUsageHeader({
    title = '',
    viewBy = [],
    onChangeViewBy,
    onExport,
}) {
    return (
        <Header>
            <Title text={title} />
            <FlexBox>
                {legends?.map(({ title, color }) => (
                    <Legend color={color} text={title.translate()} />)
                )}
            </FlexBox>
            <FlexBox>
                <CustomerSelect
                    onChange={onChangeViewBy}
                    defaultValue={viewBy}
                    items={selectOptions} />
                <IconButton onClick={onExport} size='small'>
                    <ExportIcon />
                </IconButton>
            </FlexBox>
        </Header>
    );
}

export default MachineUsageHeader;