import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  makeStyles,
  DialogTitle,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import TableHeadCell from './Planning/TableHeadCell';
import TableCell from './Planning/TableCell';
import SelectedCell from './Planning/SelectedCell';
import TodayCell from './Planning/TodayCell';
import HolidayCell from './Planning/HolidayCell';
import ChangeoverCard from './Planning/ChangeoverCard';
import Filters from './Planning/Filters';
import Legend from './Planning/Legend';
import TotalSummary from './Planning/TotalSummary';
import { machineClient } from '../../../../utils/api/CoreApi';
import { deserializeList } from '../helpers';
import { CircleButtonDanger } from './Planning/CircleButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  indexCell: {
    borderRight: 0,
  },
  lineCell: {
    borderLeft: 0,
    borderRight: 0,
  },
  currentStyleCell: {
    borderLeft: 0,
    borderRightWidth: 4,
  },
  tableContainer: {
    flex: 1,
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const Modal = withStyles({
  paper: {
    borderRadius: 0,
    height: '90vh',
  },
})(Dialog);

const DISPLAY_DATES = 7;

export default function ChangeoverPlanning({ onClose }) {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedLines, setSelectedLines] = useState([]);
  const [displayLines, setDisplayLines] = useState([]);
  const [changeoversCalendar, setChangeoversCalendar] = useState([]);
  const [filteredStatuses, setFilteredStatuses] = useState([]);
  const [focusedChangeover, setFocusChangeover] = useState(null);

  useEffect(() => {
    const displayDates = [...Array(DISPLAY_DATES)].map((_, i) =>
      moment(selectedDate).add(i, 'd')
    );

    const params = {
      pageSize: 10000, // unlimited,
      fromDate: displayDates[0].format('YYYY-MM-DD'),
      toDate: displayDates[displayDates.length - 1].format('YYYY-MM-DD'),
      status: filteredStatuses.join(','),
    };

    machineClient
      .get('/co-requests', { params }) // temporary solution. Must filter by dates range after API completed
      .then(({ data: { result } }) => {
        const changeovers = deserializeList(result);

        const calendar = displayDates.map((date) => ({
          date,
          lines: selectedLines.map((line) => ({
            id: line.id,
            name: line.name,
            changeovers: changeovers.filter(
              (co) => co.coDate.isSame(date, 'day') && co.lineId == line.id // temporary compare line name, TO DO: compare line id when api response with ID
            ),
          })),
        }));

        setChangeoversCalendar(calendar);
      });
  }, [selectedDate, selectedLines, filteredStatuses]);

  useEffect(() => {
    machineClient
      .get('/co-requests/get/latest-closed-of-lines', { params: { lineIds: selectedLines.map(it => it.id).join(',') } }) // temporary solution. Must filter by dates range after API completed
      .then(({ data: { corequests } }) => {
        const changeovers = deserializeList(corequests);
        setDisplayLines(selectedLines.map(l => {
          l.currentStyle = changeovers.find(co => co.lineId === l.id) || {};

          return l;
        }));
      });
  }, [selectedLines]);

  const onLinesSelected = (selectedLines) => {
    const sortedLines = selectedLines.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    const normalizedLines = sortedLines.map(({ id, name }) => ({
      id,
      name,
    }));
    setSelectedLines(normalizedLines);
  };

  const hasStatusesFiltered = filteredStatuses && filteredStatuses.length > 0;

  return (
    <Modal
      fullWidth
      maxWidth="lg"
      open={true}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <DialogTitle>{'Changeover Planning'.translate()}</DialogTitle>
        <CircleButtonDanger style={{margin: '16px 24px'}} onClick={onClose}>
          <CloseIcon style={{ color: '#fff' }} />
        </CircleButtonDanger>
      </div>
      <DialogContent className={classes.dialog}>
        <Filters
          selectedDate={selectedDate}
          onDateChange={(date) => setSelectedDate(date)}
          onLocationChange={(filters) => onLinesSelected(filters.lines)}
          onStatusesChange={(filters) => setFilteredStatuses(filters.statuses)}
        />
        <Legend />
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader>
            <colgroup>
              <col style={{ width: '2%' }} />
              <col style={{ width: '8%' }} />

              <col style={{ width: '11.2%' }} />
              <col style={{ width: '11.2%' }} />
              <col style={{ width: '11.2%' }} />
              <col style={{ width: '11.2%' }} />
              <col style={{ width: '11.2%' }} />
              <col style={{ width: '11.2%' }} />
              <col style={{ width: '11.2%' }} />
              <col style={{ width: '11.2%' }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableHeadCell>#</TableHeadCell>
                <TableHeadCell>{'LINE'.translate()}</TableHeadCell>
                <TableHeadCell>{'CURRENT STYLE'.translate()}</TableHeadCell>
                {changeoversCalendar.map(({ date }) => {
                  const formatedDay = date.format('ddd, MMM DD, YYYY');
                  const key = date.format('L');

                  const isSelected = date.isSame(selectedDate, 'day');
                  if (isSelected)
                    return <SelectedCell key={key}>{formatedDay}</SelectedCell>;

                  const isToday = date.isSame(moment(), 'day');
                  if (isToday)
                    return <TodayCell key={key}>{formatedDay}</TodayCell>;

                  return <TableHeadCell key={key}>{formatedDay}</TableHeadCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayLines.map((displayLine, index) => (
                <TableRow key={displayLine.id}>
                  <TableCell className={classes.indexCell}>
                    {index + 1}
                  </TableCell>
                  <TableCell className={classes.lineCell}>
                    {displayLine.name}
                  </TableCell>
                  <TableCell className={classes.currentStyleCell}>
                    <ChangeoverCard
                      isCurrent={true}
                      isFocus={
                        focusedChangeover &&
                        focusedChangeover.id === displayLine.currentStyle.id &&
                        focusedChangeover.tag === '1'
                      }
                      infoPlacement="right"
                      onFocus={() =>
                        setFocusChangeover({
                          id: displayLine.currentStyle.id,
                          tag: '1',
                        })
                      }
                      onLostFocus={() => setFocusChangeover(null)}
                      changeover={displayLine.currentStyle}
                    />
                  </TableCell>
                  {changeoversCalendar.map(({ date, lines }, dateIndex) => {
                    const domKey = date.format('L');
                    const matchedLine = lines.find(
                      (l) => l.id == displayLine.id
                    );
                    if (!matchedLine) return <TableCell key={domKey} />;

                    const { changeovers } = matchedLine;
                    const isHoliday = moment(date).day() === 0;
                    const CurrentCell = isHoliday ? HolidayCell : TableCell;
                    const renderChangeovers = hasStatusesFiltered ? changeovers.filter((co) => filteredStatuses.includes(co.statusCode)) : changeovers;

                    return (
                      <CurrentCell key={domKey}>
                        {renderChangeovers.map((co, index) => (
                          <ChangeoverCard
                            key={index}
                            infoPlacement={dateIndex > 3 ? 'left' : 'right'}
                            isFocus={
                              focusedChangeover &&
                              focusedChangeover.id === co.id &&
                              focusedChangeover.tag === '2'
                            }
                            onFocus={() => setFocusChangeover({
                              id: co.id,
                              tag: '2',
                            })}
                            onLostFocus={() => setFocusChangeover(null)}
                            changeover={co}
                          />
                        ))}
                      </CurrentCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TotalSummary
          calendar={changeoversCalendar}
          currentStyles={displayLines.map((l) => l.currentStyle)}
        />
      </DialogContent>
    </Modal>
  );
}
