import React from "react";
import moment from "moment";
import history from "../../../history";
import {
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import { confirmAlert } from "react-confirm-alert";
import ExcelLoading from "../../../components/Modal/ExcelLoading/ExcelLoading";
import {
  checkIfApiLoading, getMaintenanceStatus,
} from "../../../utils/helper";
import {
  showModal,
  closeModal,
} from "../../../redux/actions/Modal/modalActions";
import {
  notificationSuccess,
  notificationError,
} from "../../../redux/actions/Notification/notificationActions";
import { getMachinesForTicketCreation } from "../../../redux/actions/Machine/machineActions";
import {
  getMaintenanceTicketStatus,
  getRepairTicketStatus,
} from "../../../redux/actions/Status/StatusActions";
import {
  getAllTicketsBySearch,
  getAllTicketsBySearchPagination,
  ticketListUpdatePage,
  putCancelTickets,
  uncheckTicketTableCheckboxById,
} from "../../../redux/actions/Tickets/ticketActions";
import { connect } from "react-redux";
import MainPage from "../../../components/MainPage/MainPage";
import MainPageHeader from "../../../components/MainPageHeader/MainPageHeader";
import MainPageBody from "../../../components/MainPageBody/MainPageBody";
import MainTable from "../../../components/MainTable/MainTable";
import MaintenanceChecklist from "../../../components/Modal/MaintenanceChecklist/MaintenanceChecklist";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import FormGroup from "@material-ui/core/FormGroup";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from "@material-ui/icons/Close";
import NativeSelects from "./utilities/select";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Media from "./TicketDetails/Media/Media";
import { apiSettings, tokenGet } from "../../../utils/api/CoreApi";
import dictionary from "../../../redux/actions/Translation/dictionary";
import "./Tickets.scss";
import { downloadExcel } from '../../../utils/excel';
import { displayMinute, formatDate } from "../../../utils/date";
import QueueState from '../../../components/QueueState';
import { th } from "date-fns/locale";

class Tickets extends React.Component {
  tickets = [];
  filterType = "";
  filtered = false;
  state = {};
  existTicketNo = [];

  constructor(props) {
    super(props);

    this.state = {
      forceUpdate: null,
      assetDetail1: null,
      assetDetail2: null,
      assetDetail3: null,
      assetDetail4: null,
      assetsAreNull: true,
      modalSettings: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      modalInput: {
        MuiOutlinedInput: {
          root: {
            padding: "1px",
          },
        },
      },
      modalDiv: {
        position: "relative",
      },
      modalClose: {
        position: "absolute",
        top: "5px",
        right: "5px",
        cursor: "pointer",
        fontSize: "1.5rem",
      },
      modalCloseButton: {
        fontSize: "2rem",
      },
      modalButton: {
        fontSize: "1.3rem",
        fontWeight: "bold",
        marginTop: "5px",
      },
      paperSettings: {
        color: "#fff",
        fontSize: "1.3rem",
        fontWeight: "bold",
        textAlign: "center",
        backgroundColor: "#1E3C50",
        padding: "15px",
        width: "300px",
        height: "130px",
      },
      modalOpen: false,
      modalValue: "",
      tooltipTheme: createMuiTheme({
        overrides: {
          MuiTooltip: {
            tooltip: {
              fontSize: "1rem",
            },
          },
        },
      }),
      data: "test",
      filterDate: "today",
      filterType: "all",
      filterStatus: "all",
      dateNow: moment(),
      filtered: false,
      resetExport: false,
      tableHeader: [
        {
          value: "#",
          size: "50px",
        },
        {
          value: "TICKET NO",
          size: "80px",
        },
        {
          value: "BUILDING",
          size: "70px",
        },
        {
          value: "LOCATION",
          size: "90px",
        },
        {
          value: "MACHINE NO",
          size: "100px",
        },
        {
          value: "TYPE",
          size: "50px",
        },
        {
          value: "SUB TYPE",
          size: "70px",
        },
        {
          value: "BRAND",
          size: "80px",
        },
        {
          value: "SERIAL NO",
          size: "100px",
        },
        {
          value: "DATE REPORTED",
          size: "130px",
        },
        {
          value: "GRABBED BY",
          size: "100px",
        },
        {
          value: "RPR/PM BY",
          size: "100px",
        },
        {
          value: "TOTAL MINUTES",
          size: "150px",
          // linkToolTip: TOTALDURATIONMARKEDASCLOSED
        },
        {
          value: "CLOSED BY",
          size: "100px",
        },
        {
          value: "TICKET STATUS",
          size: "140px",
        },
        {
          value: "TYPE",
          size: "100px",
        },
      ],
      hiddenColumns: [
        {
          header: "BRAND",
          value: "brand",
        },
        {
          header: "SERIAL NO",
          value: "serialNo",
        },
        {
          header: "GRABBED BY",
          value: "grabbedBy",
        },
        {
          header: "RPR/PM BY",
          value: "repairedBy",
        },
        {
          header: "TOTAL MINUTES",
          value: "elapsedDuration",
        },
        {
          header: "CLOSED BY",
          value: "closedBy",
        },
        {
          header: "REPAIR DURATION",
          value: "repairDuration",
        },
      ],
      dataSet1: [
        {
          name: "Johson",
          amount: 30000,
          sex: "M",
          is_married: true,
        },
        {
          name: "Monika",
          amount: 355000,
          sex: "F",
          is_married: false,
        },
        {
          name: "John",
          amount: 250000,
          sex: "M",
          is_married: false,
        },
        {
          name: "Josef",
          amount: 450500,
          sex: "M",
          is_married: true,
        },
      ],

      dataSet2: [
        {
          reportedDuration: "10/10/10",
        },
        {
          inRepairDuration: "11/10/10",
        },
        {
          closeDuration: "12/10/10",
        },
        {
          totalElapsedTime: "12/10/10",
        },
      ],
      tickets: ([] = this.props.tickets),
      languageProps: Object.assign({}, { ...this.changeLang() }),
      translationTimestamp: undefined,
      searchText: "",
      isExporting: false,
      pageSize: 10,
    };
    this.exportQueue = React.createRef();
  }

  convert = () => {
    let {
      TOOLTIPS: { TOTALDURATIONMARKEDASCLOSED },
    } = dictionary.GET();
    return dictionary.TRANSLATE({ TOTALDURATIONMARKEDASCLOSED });
  };
  // here1
  changeLang = () => {
    let {
      NOTIFICATIONS: {
        there_are,
        tickets_cannot_be_cancelled,
        this_ticket_cannot_be_cancelled,
        are_you_sure,
      },
      VIEW_CHECKLIST,
      EXPORT_TO_FILE,
      WORKING,
      AVAILABLE,
      ALL,
      ACTIVE,
      READY,
      REPAIR,
      MAINTENANCE,
      NOT_AVAIL,
      CHANGEOVER,
      IN_PROCESS,
      CLOSED,
      REPAIRED,
      IN_REPAIR,
      REPORTED,
      CANCELLED,
      SCHEDULED,
      IN_PROGRESS,
      COMPLETED,
      PLACE,
      YES,
      NO,
      PROBLEM,
      SOLUTION,
      REMARKS,
      MACHINE_NO,
      MACHINE_TYPE,
      MACHINE_SUBTYPE,
      GRABBED_BY,
      GRABBED_ON_TIME,
      BRAND,
      MODEL_NO,
      MOTOR_TYPE,
      TOTAL_REPAIR_TIME,
      REPAIRED_BY,
      REPAIRED_ON_TIME,
      REPORTED_BY,
      REPORTED_ON,
      REPORTED_ON_TIME,
      CLOSED_BY,
      CLOSED_ON,
      CLOSED_ON_TIME,
      CLOSED_DURATION,
      CHECKLIST_NO,
      MAINTENANCE_DATE,
      GRABBED_ON,
      TOTAL_REPORTED_TIME,
      TOTAL_CLOSED_TIME,
      TOTAL_DURATION,
      TOTAL_MINUTES,
      ACTION_TAKEN_BY,
      ACTION_TAKEN_ON,
      ACTION_TAKEN_ON_TIME,
      GENERATE_BY,
      GENERATE_ON_TIME,
      GENERATE_ON,
      SELECT_MACHINE,
      SELECT,
      REPAIRED_ON,
      TICKET_NO,
      SERIAL_NO,
      DATE_OF_PURCHASE,
      ASSET_NO,
      MAIN_FREQ,
      LPM,
      LPR,
      CONDITION,
      ATTACHMENT,
      RENTAL,
      Active,
      RFID,
      Supplier,
      TYPE,
      SUBTYPE,
      RPR_PM_BY,
      RPR_PM_ON_TIME,
      STATUS,
      TICKET_TYPE,
      TICKET_STATUS,
      BUILDING,
      LOCATION,
      LAST_MAINT,
      NEXT_MAINT_DATE,
      MAINTENANCE_STATUS,
      LAST_REPAIR,
      IN_REPAIR_DURATION,
      TOTAL_ELAPSED_TIME,
      REPORTED_DURATION,
      CANCELLED_BY,
      CANCELLED_TIME,
    } = dictionary.GET();
    return dictionary.TRANSLATE({
      there_are,
      tickets_cannot_be_cancelled,
      this_ticket_cannot_be_cancelled,
      are_you_sure,
      VIEW_CHECKLIST,
      EXPORT_TO_FILE,
      WORKING,
      AVAILABLE,
      ALL,
      ACTIVE,
      READY,
      REPAIR,
      MAINTENANCE,
      NOT_AVAIL,
      CHANGEOVER,
      IN_PROCESS,
      CLOSED,
      REPAIRED,
      IN_REPAIR,
      REPORTED,
      CANCELLED,
      SCHEDULED,
      IN_PROGRESS,
      COMPLETED,
      PLACE,
      YES,
      NO,
      PROBLEM,
      SOLUTION,
      REMARKS,
      MACHINE_NO,
      MACHINE_TYPE,
      MACHINE_SUBTYPE,
      GRABBED_BY,
      GRABBED_ON_TIME,
      BRAND,
      MODEL_NO,
      MOTOR_TYPE,
      TOTAL_REPORTED_TIME,
      TOTAL_REPAIR_TIME,
      REPAIRED_BY,
      REPAIRED_ON_TIME,
      REPORTED_BY,
      REPORTED_ON,
      REPORTED_ON_TIME,
      CLOSED_BY,
      CLOSED_ON,
      CLOSED_ON_TIME,
      CLOSED_DURATION,
      CHECKLIST_NO,
      MAINTENANCE_DATE,
      GRABBED_ON,
      TOTAL_CLOSED_TIME,
      TOTAL_DURATION,
      TOTAL_MINUTES,
      ACTION_TAKEN_BY,
      ACTION_TAKEN_ON,
      ACTION_TAKEN_ON_TIME,
      GENERATE_BY,
      GENERATE_ON_TIME,
      GENERATE_ON,
      SELECT_MACHINE,
      SELECT,
      REPAIRED_ON,
      TICKET_NO,
      SERIAL_NO,
      DATE_OF_PURCHASE,
      ASSET_NO,
      MAIN_FREQ,
      LPM,
      LPR,
      CONDITION,
      ATTACHMENT,
      RENTAL,
      Active,
      RFID,
      Supplier,
      TYPE,
      SUBTYPE,
      RPR_PM_BY,
      RPR_PM_ON_TIME,
      STATUS,
      TICKET_TYPE,
      TICKET_STATUS,
      BUILDING,
      LOCATION,
      LAST_MAINT,
      NEXT_MAINT_DATE,
      MAINTENANCE_STATUS,
      LAST_REPAIR,
      IN_REPAIR_DURATION,
      TOTAL_ELAPSED_TIME,
      REPORTED_DURATION,
      CANCELLED_BY,
      CANCELLED_TIME,
    });
  };

  sanitize = (value) => {
    let { languageProps } = this.state;
    return (
      {
        WORKING: languageProps.WORKING,
        AVAILABLE: languageProps.AVAILABLE,
        ALL: languageProps.ALL,
        ACTIVE: languageProps.ACTIVE,
        READY: languageProps.READY,
        REPAIR: languageProps.REPAIR,
        MAINTENANCE: languageProps.MAINTENANCE,
        INACTIVE: languageProps.NOT_AVAIL,
        CHANGEOVER: languageProps.CHANGEOVER,
        "IN-PROCESS": languageProps.IN_PROCESS,
        CLOSED: languageProps.CLOSED,
        REPAIRED: languageProps.REPAIRED,
        "IN-REPAIR": languageProps.IN_REPAIR,
        REPORTED: languageProps.REPORTED,
        CANCELLED: languageProps.CANCELLED,
        SCHEDULED: languageProps.SCHEDULED,
        "IN PROGRESS": languageProps.IN_PROGRESS,
        COMPLETED: languageProps.COMPLETED,
      }[value] ?? value
    );
  };
  handleFormChange = ({ target }) => {
    this.setState({ modalValue: target.value });
  };

  handleModalValue = (value) => {
    this.setState({ modalValue: value });
  };

  handleExportToExcel = (searchString, reportedDt, type, status) => {
    let title = reportedDt + "_TicketsList_" + (type === 'r' ? 'RepairTickets' : 'MaintenanceTickets');
    this.props.showModal('excelLoading');
    let url = apiSettings.apiURLTickets + `/api/tickets?${searchString ? 'searchString=' + searchString : ''}pageSize=1000&currentPage=1&reportedDt=${reportedDt}&ticketType=${type}&status=${status}`;
    tokenGet(url)
      .then((response) => {
          this.handleDownloadExcel(title, response.data.tickets.result);
      })
      .catch((error) => {
          console.log(error);
          this.props.closeModal();
      });
  }

  handleExportToExcelWithQueue = (searchString, reportedDt, ticketType, status) => {
    const fileName = `${reportedDt}__TicketsList_${ticketType === 'r' 
      ? 'RepairTickets' 
      : 'MaintenanceTickets'
    }`;
    const url = apiSettings.apiURLTickets + `/api/tickets`;
    const query = {
      reportedDt,
      ticketType,
      status,
      ...searchString ? {
        searchString
      } : '',
    };
    this.setState({
      isExporting: true,
    }, () => (
      this.exportQueue.current.getData(fileName, url,query)
    ));
  }

  handleDownloadExcel = (title, tickets) => {
    this.setState({ isExporting: false });
    let exportData = [];
    for (let i = 0; i < tickets.length; i++) {
      let item = tickets[i];
      if (!this.existTicketNo.includes(item.ticketNo)) {
        this.existTicketNo.push(item.ticketNo);
        let reportedDurations = displayMinute(item.reportedDuration);
        let inrepairDurations = displayMinute(item.inrepairDuration);
        let elapsedDurations = displayMinute(item.elapsedDuration);
        let closedDurations = displayMinute(item.closedDuration);
        let nextMaintDate = formatDate(item.nextMaintDate);
        const cancelledTime = this.renderDate(item.canceledDt, 'YYYY-MM-DD HH:MM:SS');
        exportData.push([
          this.renderText(item.ticketNo),
          this.renderText(item.buildingDesc),
          this.renderText(item.mfgLine || item.area),
          this.renderText(item.reportedPlace),
          this.renderText(item.place),
          this.renderText(item.machineNo),
          this.renderText(item.type),
          this.renderText(item.subType),
          this.renderText(item.brand),
          this.renderText(item.serialNo),
          this.renderText(item.modelNo),
          this.renderText(item.motorType),
          formatDate(item.aquisitionDt),
          this.renderText(item.assetNo),
          this.renderText(`${item.frequencyQty || 1} ${item.pmFrequency}`),
          formatDate(item.lpm_date, 'YYYY-MM-DD HH:mm:ss'),
          this.renderText(nextMaintDate),
          formatDate(item.lr_date, 'YYYY-MM-DD HH:mm:ss'),
          this.renderText(item.conditionId_desc),
          this.renderText(item.attachmentId_desc),
          this.renderCheckbox(item.isRental),
          this.renderCheckbox(item.isActive),
          this.renderText(item.rfid),
          this.renderText(item.supplierId_desc),
          this.renderText(item.reportedBy),
          formatDate(item.reportedDt, 'YYYY-MM-DD HH:mm:ss'), //REPORTED TIME
          this.renderText(item.status),
          this.renderText(reportedDurations), //REPORTED DURATION
          this.renderText(item.grabbedBy),
          formatDate(item.grabbedDt, 'YYYY-MM-DD HH:mm:ss'), //REPORTED ON TIME
          this.renderText(item.repairedBy),
          formatDate(item.repairedDt, 'YYYY-MM-DD HH:mm:ss'), //REPAIRED TIME
          this.renderText(inrepairDurations), //REPAIRED TIME
          this.renderText(item.closedBy),
          formatDate(item.closedDt, 'YYYY-MM-DD HH:mm:ss'), // CLOSE TIME
          this.renderText(closedDurations), // CLOSE DURATION
          this.sanitize(this.renderText(item.status)),
          this.renderText(elapsedDurations), //TOTAL ELAPSED TIME
          this.sanitize(
            this.renderText(item.ticketType).toUpperCase()
          ),
          this.renderText(item.canceledBy),
          cancelledTime,
          this.renderText(item.problemType),
          this.renderText(item.solutionType),
          this.renderText(item.remarks),
        ]);
      } 
    }

    downloadExcel(
      title,
      [
        this.state.languageProps.TICKET_NO,
        'Building',
        'Area/Line',
        'Reported Location',
        'Current Location',
        this.state.languageProps.MACHINE_NO,
        this.state.languageProps.TYPE,
        this.state.languageProps.SUBTYPE,
        this.state.languageProps.BRAND,
        this.state.languageProps.SERIAL_NO,
        this.state.languageProps.MODEL_NO,
        this.state.languageProps.MOTOR_TYPE,
        this.state.languageProps.DATE_OF_PURCHASE,
        this.state.languageProps.ASSET_NO,
        this.state.languageProps.MAIN_FREQ,
        this.state.languageProps.LAST_MAINT,
        this.state.languageProps.NEXT_MAINT_DATE,
        this.state.languageProps.LAST_REPAIR,
        this.state.languageProps.CONDITION,
        this.state.languageProps.ATTACHMENT,
        this.state.languageProps.RENTAL,
        this.state.languageProps.Active,
        this.state.languageProps.RFID,
        this.state.languageProps.Supplier,
        this.state.languageProps.REPORTED_BY,
        this.state.languageProps.REPORTED_ON_TIME,
        this.state.languageProps.MAINTENANCE_STATUS,
        this.state.languageProps.REPORTED_DURATION + ' (min)',
        this.state.languageProps.GRABBED_BY,
        this.state.languageProps.GRABBED_ON_TIME,
        this.state.languageProps.RPR_PM_BY,
        this.state.languageProps.RPR_PM_ON_TIME,
        this.state.languageProps.IN_REPAIR_DURATION + ' (min)',
        this.state.languageProps.CLOSED_BY,
        this.state.languageProps.CLOSED_ON_TIME,
        this.state.languageProps.CLOSED_DURATION + ' (min)',
        this.state.languageProps.TICKET_STATUS,
        this.state.languageProps.TOTAL_ELAPSED_TIME + ' (min)',
        this.state.languageProps.TICKET_TYPE,
        this.state.languageProps.CANCELLED_BY,
        this.state.languageProps.CANCELLED_TIME,
        this.state.languageProps.PROBLEM,
        this.state.languageProps.SOLUTION,
        this.state.languageProps.REMARKS,
      ], 
      exportData
    ).then(() => {
      this.props.notificationSuccess('File successfully exported');
      this.props.closeModal();
      this.existTicketNo = [];
    });
  }

  onChangePage = (value) => {
    // this.props.ticketListUpdatePage(value);
    let searchString = this.state.searchText
      ? this.state.searchText
      : "all";
    let filters = JSON.parse(localStorage.getItem("ticketFilters"));
    this.props.getAllTicketsBySearchPagination(
      this.state.pageSize,
      value,
      searchString,
      filters.date,
      filters.type,
      filters.status,
      this.props.excel
    );
  };

  renderDate(date, format = 'DD-MM-YYYY') {
    if (date !== null) {
      let timezone = moment.parseZone(date).format("Z");
      let dateTimeOnly =
        moment.parseZone(date).format("YYYY-MM-DD HH:mm:ss") + "+00:00";
      return moment(dateTimeOnly).utcOffset(timezone).format(format);
    } else {
      return "-";
    }
  }

  renderMachineDate(date) {
    try {
      if (date !== null) {
        let newDate = date.substring(0, date.indexOf("T"));
        return moment.parseZone(newDate).format("DD-MM-YYYY");
      } else {
        return "-";
      }
    } catch (err) {
      if (date !== null) {
        let timezone = moment.parseZone(date).format("Z");
        let dateTimeOnly =
          moment.parseZone(date).format("YYYY-MM-DD HH:mm:ss") + "+00:00";
        return moment(dateTimeOnly).utcOffset(timezone).format("DD-MM-YYYY");
      } else {
        return "-";
      }
    }
  }

  renderTime(date) {
    if (date !== null) {
      let timezone = moment.parseZone(date).format("Z");
      let dateTimeOnly =
        moment.parseZone(date).format("YYYY-MM-DD HH:mm:ss") + "+00:00";
      return moment(dateTimeOnly).utcOffset(timezone).format("HH:mm");
    } else {
      return "-";
    }
  }

  renderCheckbox(check) {
    if (check === 1 || check === true) {
      return this.state.languageProps.YES;
    } else {
      return this.state.languageProps.NO;
    }
  }

  renderStatus(field) {
    if (field === "REPORTED") {
      return "reported";
    }
    if (field === "IN-REPAIR" || field === "IN PROGRESS") {
      return "in-repair";
    } else {
      return "active";
    }
  }

  renderImageVideo(media1, link1) {
    let imgHeights = {};
    let img = new Image();
    img.src =
      "http://18.163.43.86/files/1588591413903-tmp2445693608142311211.jpg";
    return (
      <div>
        <Media media={media1} link={link1}></Media>
      </div>
    );
  }

  checkerOfImageOrVideo(media1) {
    if (media1 != undefined) {
      let check = media1.substr(media1.length - 3);
      if (check == "mp4") {
        return "video";
      } else {
        return "image";
      }
    }
  }

  openChecklistModal = () => {
    this.props.showModal("maintenanceChecklist");
  };

  renderTicketDetailByType() {
    if (this.props.ticketDetail) {
      if (this.props.ticketDetail.ticketType === "Repair") {
        return (
          <div>
            <Grid className="body-detail" container spacing={0}>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={3}>
                        <div className="body-label">
                          {this.state.languageProps.PROBLEM}
                        </div>
                      </Grid>
                      <Grid item xs={9}>
                        <Tooltip
                          placement="top-start"
                          title={
                            this.props.ticketDetail
                              ? this.renderText(
                                this.props.ticketDetail.problemType
                              )
                              : null
                          }
                        >
                          <div className="body-value text-ellipsis">
                            {this.props.ticketDetail
                              ? this.renderText(
                                this.props.ticketDetail.problemType
                              )
                              : null}
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={3}>
                        <div className="body-label">
                          {this.state.languageProps.SOLUTION}
                        </div>
                      </Grid>
                      <Grid item xs={9}>
                        <Tooltip
                          placement="top-start"
                          title={
                            this.props.ticketDetail
                              ? this.renderText(
                                this.props.ticketDetail.solutionType
                              )
                              : null
                          }
                        >
                          <div className="body-value text-ellipsis">
                            {this.props.ticketDetail
                              ? this.renderText(
                                this.props.ticketDetail.solutionType
                              )
                              : null}
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={3}>
                        <div className="body-label">
                          {this.state.languageProps.REMARKS}
                        </div>
                      </Grid>
                      <Grid item xs={9}>
                        <Tooltip
                          placement="top-start"
                          title={
                            this.props.ticketDetail
                              ? this.renderText(this.props.ticketDetail.remarks)
                              : null
                          }
                        >
                          <div className="body-value text-ellipsis">
                            {this.props.ticketDetail
                              ? this.renderText(this.props.ticketDetail.remarks)
                              : null}
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="body-detail" container spacing={0}>
              <Grid item xs={6}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.MACHINE_NO}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <Tooltip
                          placement="top-start"
                          title={
                            this.props.ticketDetail
                              ? this.renderText(
                                this.props.ticketDetail.machineNo
                              )
                              : null
                          }
                        >
                          <div className="body-values text-ellipsis">
                            {this.props.ticketDetail
                              ? this.renderText(
                                this.props.ticketDetail.machineNo
                              )
                              : null}
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.MACHINE_TYPE}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-values">
                          {this.props.machineDetail
                            ? this.renderTextWithCapitalization(
                              this.props.machineDetail.macTypeId_desc
                            )
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.MACHINE_SUBTYPE}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-values">
                          {this.props.machineDetail
                            ? this.renderText(
                              this.props.machineDetail.macSubTypeId_desc
                            )
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={0}></Grid>
              <Grid item xs={6}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.GRABBED_BY}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? this.renderText(this.props.ticketDetail.grabbedBy)
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.GRABBED_ON}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.machineDetail
                            ? formatDate(this.props.ticketDetail.grabbedDt)
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.GRABBED_ON_TIME}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.machineDetail
                            ? formatDate(this.props.ticketDetail.grabbedDt, 'HH:mm')
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.TOTAL_REPAIR_TIME}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? this.renderText(displayMinute(this.props.ticketDetail.inrepairDuration))
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="body-detail" container spacing={0}>
              <Grid item xs={6}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.BRAND}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.machineDetail
                            ? this.renderTextWithCapitalization(
                              this.props.machineDetail.brandId_desc
                            )
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.MODEL_NO}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <Tooltip
                          placement="top-start"
                          title={
                            this.props.ticketDetail
                              ? this.renderText(this.props.ticketDetail.modelNo)
                              : null
                          }
                        >
                          <div className="body-value text-ellipsis">
                            {this.props.ticketDetail
                              ? this.renderText(this.props.ticketDetail.modelNo)
                              : null}
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.MOTOR_TYPE}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.machineDetail
                            ? this.props.machineDetail.motorTypeId_desc
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.REPAIRED_BY}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? this.renderText(
                              this.props.ticketDetail.repairedBy
                            )
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.REPAIRED_ON}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? formatDate(
                              this.props.ticketDetail.repairedDt
                            )
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.REPAIRED_ON_TIME}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? formatDate(
                              this.props.ticketDetail.repairedDt, 'HH:mm'
                            )
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.TOTAL_CLOSED_TIME}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? this.renderText(displayMinute(this.props.ticketDetail.closedDuration))
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="body-detail" container spacing={0}>
              <Grid item xs={6}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.REPORTED_BY}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? this.renderText(
                              this.props.ticketDetail.reportedBy
                            )
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.REPORTED_ON}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? formatDate(
                              this.props.ticketDetail.reportedDt
                            )
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.REPORTED_ON_TIME}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? formatDate(
                              this.props.ticketDetail.reportedDt, 'HH:mm'
                            )
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.TOTAL_REPORTED_TIME}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? this.renderText(displayMinute(this.props.ticketDetail.reportedDuration))
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.CLOSED_BY}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? this.renderText(this.props.ticketDetail.closedBy)
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.CLOSED_ON}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? formatDate(this.props.ticketDetail.closedDt)
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.CLOSED_ON_TIME}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? formatDate(this.props.ticketDetail.closedDt, 'HH:mm')
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.TOTAL_MINUTES}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? this.renderText(displayMinute(this.props.ticketDetail.elapsedDuration))
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={10}>
                <FormGroup className="margin-top">
                  <Grid className="form-control" container spacing={1}>
                    {this.state.assetDetail1 != null ? (
                      <Grid item xs={3}>
                        {this.renderImageVideo(
                          this.checkerOfImageOrVideo(this.state.assetDetail1),
                          this.state.assetDetail1
                        )}
                      </Grid>
                    ) : null}

                    {this.state.assetDetail2 != null ? (
                      <Grid item xs={3}>
                        {this.renderImageVideo(
                          this.checkerOfImageOrVideo(this.state.assetDetail2),
                          this.state.assetDetail2
                        )}
                      </Grid>
                    ) : null}

                    {this.state.assetDetail3 != null ? (
                      <Grid item xs={3}>
                        {this.renderImageVideo(
                          this.checkerOfImageOrVideo(this.state.assetDetail3),
                          this.state.assetDetail3
                        )}
                      </Grid>
                    ) : null}

                    {this.state.assetDetail4 != null ? (
                      <Grid item xs={3}>
                        {this.renderImageVideo(
                          this.checkerOfImageOrVideo(this.state.assetDetail4),
                          this.state.assetDetail4
                        )}
                      </Grid>
                    ) : null}
                  </Grid>
                </FormGroup>
              </Grid>
            </Grid>
          </div>
        );
      }
      if (this.props.ticketDetail.ticketType === "Maintenance") {
        return (
          <div>
            <Grid className="body-detail" container spacing={0}>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div
                          onClick={this.openChecklistModal}
                          className="body-value clickable text-ellipsis"
                        >
                          {this.state.languageProps.VIEW_CHECKLIST}
                        </div>
                      </Grid>
                      <Grid item xs={6}></Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.CHECKLIST_NO}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <Tooltip
                          placement="top-start"
                          title={
                            this.props.ticketDetail
                              ? this.renderText(
                                this.props.ticketDetail.checkListNo
                              )
                              : null
                          }
                        >
                          <div className="body-value text-ellipsis">
                            {this.props.ticketDetail
                              ? this.renderText(
                                this.props.ticketDetail.checkListNo
                              )
                              : null}
                          </div>
                        </Tooltip>
                        {/* <Tooltip placement="top-start" title={'2342'}>
                                                    <div className="body-value text-ellipsis">{'2342'}</div>
                                                </Tooltip> */}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={3}>
                        <div className="body-label">
                          {this.state.languageProps.REMARKS}
                        </div>
                      </Grid>
                      <Grid item xs={9}>
                        <Tooltip
                          placement="top-start"
                          title={
                            this.props.ticketDetail
                              ? this.renderText(this.props.ticketDetail.remarks)
                              : null
                          }
                        >
                          <div className="body-value text-ellipsis">
                            {this.props.ticketDetail
                              ? this.renderText(this.props.ticketDetail.remarks)
                              : null}
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.MAINTENANCE_DATE}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        {/* <Tooltip placement="top-start" title={ this.props.ticketDetail ? this.renderText(this.props.ticketDetail.checklistNo) : null }>
                                                    <div className="body-value text-ellipsis">{ this.props.ticketDetail ? this.renderText(this.props.ticketDetail.checklistNo) : null }</div>
                                                </Tooltip> */}
                        <Tooltip
                          placement="top-start"
                          title={
                            this.props.ticketDetail
                              ? this.renderMachineDate(
                                this.props.ticketDetail.pmPlanDt
                              )
                              : null
                          }
                        >
                          <div className="body-value text-ellipsis">
                            {this.props.ticketDetail
                              ? this.renderMachineDate(
                                this.props.ticketDetail.pmPlanDt
                              )
                              : null}
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="body-detail" container spacing={0}>
              <Grid item xs={6}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.MACHINE_NO}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <Tooltip
                          placement="top-start"
                          title={
                            this.props.ticketDetail
                              ? this.renderText(
                                this.props.ticketDetail.machineNo
                              )
                              : null
                          }
                        >
                          <div className="body-value text-ellipsis">
                            {this.props.ticketDetail
                              ? this.renderText(
                                this.props.ticketDetail.machineNo
                              )
                              : null}
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.MACHINE_TYPE}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.machineDetail
                            ? this.renderTextWithCapitalization(
                              this.props.machineDetail.macTypeId_desc
                            )
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.MACHINE_SUBTYPE}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.machineDetail
                            ? this.renderText(
                              this.props.machineDetail.macSubTypeId_desc
                            )
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={0}></Grid>
              <Grid item xs={6}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.GRABBED_BY}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? this.renderText(this.props.ticketDetail.grabbedBy)
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.GRABBED_ON}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.machineDetail
                            ? formatDate(this.props.ticketDetail.grabbedDt)
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.GRABBED_ON_TIME}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.machineDetail
                            ? formatDate(this.props.ticketDetail.grabbedDt, 'HH:mm')
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="body-detail" container spacing={0}>
              <Grid item xs={6}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.BRAND}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.machineDetail
                            ? this.renderTextWithCapitalization(
                              this.props.machineDetail.brandId_desc
                            )
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.MODEL_NO}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <Tooltip
                          placement="top-start"
                          title={
                            this.props.ticketDetail
                              ? this.renderText(this.props.ticketDetail.modelNo)
                              : null
                          }
                        >
                          <div className="body-value text-ellipsis">
                            {this.props.ticketDetail
                              ? this.renderText(this.props.ticketDetail.modelNo)
                              : null}
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.MOTOR_TYPE}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.machineDetail
                            ? this.props.machineDetail.motorTypeId_desc
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.ACTION_TAKEN_BY}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? this.renderText(this.props.ticketDetail.closedBy)
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.ACTION_TAKEN_ON}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? formatDate(this.props.ticketDetail.closedDt)
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.ACTION_TAKEN_ON_TIME}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? formatDate(this.props.ticketDetail.closedDt, 'HH:mm')
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="body-detail" container spacing={0}>
              <Grid item xs={6}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.GENERATE_BY}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? this.renderText(
                              this.props.ticketDetail.reportedBy
                            )
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.GENERATE_ON}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? formatDate(
                              this.props.ticketDetail.reportedDt
                            )
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.GENERATE_ON_TIME}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? formatDate(
                              this.props.ticketDetail.reportedDt, 'HH:mm'
                            )
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.CLOSED_BY}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? this.renderText(null)
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.CLOSED_ON}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? formatDate(null)
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <div className="body-label">
                          {this.state.languageProps.CLOSED_ON_TIME}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="body-value">
                          {this.props.ticketDetail
                            ? formatDate(null)
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={10}>
                <FormGroup className="margin-top">
                  <Grid className="form-control" container spacing={1}>
                    {this.state.assetDetail1 != null ? (
                      <Grid item xs={3}>
                        {this.renderImageVideo(
                          this.checkerOfImageOrVideo(this.state.assetDetail1),
                          this.state.assetDetail1
                        )}
                      </Grid>
                    ) : null}

                    {this.state.assetDetail2 != null ? (
                      <Grid item xs={3}>
                        {this.renderImageVideo(
                          this.checkerOfImageOrVideo(this.state.assetDetail2),
                          this.state.assetDetail2
                        )}
                      </Grid>
                    ) : null}

                    {this.state.assetDetail3 != null ? (
                      <Grid item xs={3}>
                        {this.renderImageVideo(
                          this.checkerOfImageOrVideo(this.state.assetDetail3),
                          this.state.assetDetail3
                        )}
                      </Grid>
                    ) : null}

                    {this.state.assetDetail4 != null ? (
                      <Grid item xs={3}>
                        {this.renderImageVideo(
                          this.checkerOfImageOrVideo(this.state.assetDetail4),
                          this.state.assetDetail4
                        )}
                      </Grid>
                    ) : null}
                  </Grid>
                </FormGroup>
              </Grid>
            </Grid>
          </div>
        );
      }
    }
  }

  renderTableData() {
    return (
      <MainTable
        onPerPageChanged={(pageSize)=> this.setState({ pageSize }, () => this.onChangePage(1))}
        hiddenColumns={this.state.hiddenColumns}
        tableName="tickets"
        height="100%"
        onChangePage={this.onChangePage}
        rowsPerPageOptions={[10, 20, 50, 100]}
        page={this.props.currentPage}
        rowsPerPage={this.state.pageSize}
        header={this.state.tableHeader}
        data={this.props.tickets}
        meta={this.props.meta}
        link={"/ticket-detail"}
        highlight={this.state.searchText}
      >
        <MuiThemeProvider theme={this.state.tooltipTheme}>
          <div className="toggle-detail">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Grid className="header-detail" container spacing={0}>
                  <Grid container spacing={0}>
                    <Grid item xs={6}>
                      <Tooltip
                        placement="top-start"
                        title={
                          this.props.ticketDetail
                            ? this.renderText(this.props.ticketDetail.ticketNo)
                            : null
                        }
                      >
                        <div className="machineNo text-ellipsis title">
                          {this.props.ticketDetail
                            ? this.renderText(this.props.ticketDetail.ticketNo)
                            : null}
                        </div>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={3} />
                    <Grid item xs={3}>
                      {this.props.ticketDetail ? (
                        <div
                          className={
                            "ticket-status text-align-right " +
                            this.renderStatus(this.props.ticketDetail.status)
                          }
                        >
                          {this.props.ticketDetail.status == undefined
                            ? this.props.ticketDetail.status
                            : this.sanitize(this.props.ticketDetail.status)}
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={5}>
                      <Grid item xs={12}>
                        <div className="yti header-label">
                          {this.props.ticketDetail && this.props.machineDetail
                            ? this.renderText(
                              this.props.ticketDetail.place
                                ? this.props.ticketDetail.place
                                : this.props.machineDetail.areaId_desc
                            )
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={5}>
                      <Grid item xs={12}>
                        <div className="yti header-label text-align-right">
                          {this.props.ticketDetail
                            ? formatDate(
                              this.props.ticketDetail.reportedDt
                            )
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={5}>
                      <Grid item xs={12}>
                        <div className="createdBy header-label">
                          {this.props.ticketDetail
                            ? this.renderText(
                              this.props.ticketDetail.reportedBy
                            )
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={5}>
                      <Grid item xs={12}>
                        <div className="yti header-label text-align-right">
                          {this.props.ticketDetail
                            ? formatDate(
                              this.props.ticketDetail.reportedDt, 'HH:mm'
                            )
                            : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {this.renderTicketDetailByType()}
              </Grid>
            </Grid>
          </div>
        </MuiThemeProvider>
      </MainTable>
    );
  }

  handleCancel = () => {
    let { languageProps } = this.state;
    let validate = this.props.tickets.filter((item) => {
      return (
        item.selected.value === true &&
        item.status !== "REPORTED" &&
        item.status !== "IN-REPAIR" &&
        item.status !== "SCHEDULED"
      );
    });
    if (validate.length > 0) {
      if (validate.length === 1) {
        this.props.notificationError(
          this.state.languageProps.this_ticket_cannot_be_cancelled
        );
        let ids = [];
        ids.push(validate[0].selected.id);
        this.props.uncheckTicketTableCheckboxById(this.props.tickets, ids);
        this.setState({ forceUpdate: null });
      } else {
        this.props.notificationError(
          languageProps.there_are +
          validate.length +
          languageProps.tickets_cannot_be_cancelled
        );
        let ids = validate.map((item) => {
          return item.selected.id;
        });
        this.props.uncheckTicketTableCheckboxById(this.props.tickets, ids);
        this.setState({ forceUpdate: null });
      }
      return false;
    }
    confirmAlert({
      title: this.state.languageProps.are_you_sure,
      message: "",
      buttons: [
        {
          label: this.state.languageProps.YES,
          onClick: () => {
            let data = this.props.tickets
              .filter((item) => {
                return item.selected.value === true;
              })
              .map((item) => {
                return { no: item.selected.ticketNo };
              });
            let statusIdEnum = {
              cancelledRepair: this.props.statusList.filter((item) => {
                return item.status === "CANCELLED" && item.statusId === "4";
              }),
              cancelledMaintenance: this.props.statusList.filter((item) => {
                return item.status === "CANCELLED" && item.statusId === "3";
              }),
            };
            let filters = JSON.parse(localStorage.getItem("ticketFilters"));
            console.log("check filters status");
            console.log(filters.type.toUpperCase());
            let filterStatustype = filters.type.toUpperCase();
            let payload = {
              tickets: data,
              ticketType: filters.type.toUpperCase(),
              statusId:
                filterStatustype === "R"
                  ? statusIdEnum.cancelledRepair[0].id
                  : statusIdEnum.cancelledMaintenance[0].id,
              updatedDt: moment(),
            };
            this.props.putCancelTickets(this.state.searchText, payload);
          },
        },
        {
          label: this.state.languageProps.NO,
        },
      ],
    });
  };

  renderText(field) {
    if (field === null || field === "") {
      return "-";
    } else {
      return field;
    }
  }

  renderTextWithCapitalization(field) {
    if (field === null || field === "" || field === "null") {
      return "-";
    } else {
      let capitalized = field
        .split(" ")
        .map(
          (item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
        )
        .join(" ");
      return capitalized;
    }
  }

  handleStatusFilterChange = (event) => {
    this.setState({ filterStatusId: event.target.value });
    this.filterEverything();
  };
  handleDateFilterChange = (event) => {
    this.setState({ date: event.target.value });
    this.filterEverything();
  };
  handleTypeFilterChange = (value) => {
    let type = value;
    if (type == "all") {
      this.setState({ tickets: this.props.tickets });
    } else {
      this.filterType = type;
      this.filterEverything();
    }
    this.setState({ filterType: type });
  };

  handleTestChange = (event) => {
    let type = event.target.value;
    if (type == "all") {
      this.setState({ tickets: this.props.tickets });
    } else {
      this.filterType = type;
      this.filterEverything();
    }
    this.setState({ filterType: type });
  };

  handleStatusList = (type) => {
    if (type === "r") {
      this.props.getRepairTicketStatus();
    } else if (type === "m") {
      this.props.getMaintenanceTicketStatus();
    }
  };

  filterEverything = () => {
    this.filtered = true;
    let filtertype = this.filterType;

    let testtable = this.tickets
      .filter((item) => {
        return item.ticketType === filtertype;
      })
      .map((item) => {
        return item;
      });
    this.setState({ filtered: true });
    this.setState({ tickets: testtable });
  };

  checkIfChecked() {
    if (this.props.tickets) {
      let check = this.props.tickets.filter((item) => {
        return item.selected.value === true;
      });
      if (check.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  renderTypeItems() {
    let status = [];
    status.push(
      {
        id: 0,
        key: "all",
        value: this.state.languageProps.ALL,
      },
      {
        id: 1,
        key: "Repair",
        value: this.state.languageProps.REPAIR,
      },
      {
        id: 2,
        key: "Maintenance",
        value: this.state.languageProps.MAINTENANCE,
      }
    );

    let menuItems = status.map((item) => {
      return <MenuItem value={item.key}>{item.value}</MenuItem>;
    });
    return menuItems;
  }

  renderStatusItems() {
    let status = [];
    status.push(
      {
        key: "all",
        value: this.state.languageProps.ALL,
      },
      {
        key: "active",
        value: this.state.languageProps.ACTIVE,
      },
      {
        key: "inactive",
        value: this.state.languageProps.INACTIVE,
      }
    );

    let menuItems = status.map((item) => {
      return <MenuItem value={item.key}>{item.value}</MenuItem>;
    });
    return menuItems;
  }

  renderDateItems() {
    let status = [];
    status.push(
      {
        key: "today",
        value: this.state.languageProps.TODAY,
      },
      {
        key: "week",
        value: this.state.languageProps.THIS_WEEK,
      },
      {
        key: "month",
        value: this.state.languageProps.THIS_MONTH,
      },
      {
        key: "customize",
        value: this.state.languageProps.CUSTOMIZE_RANGE,
      }
    );

    let menuItems = status.map((item) => {
      return <MenuItem value={item.key}>{item.value}</MenuItem>;
    });
    return menuItems;
  }

  checkIfApiLoading() {
    let flag = checkIfApiLoading([
      this.props.ticketsLoading,
      this.props.statusListLoading,
    ]);
    return flag;
  }

  initializeFiltersAndSearch() {
    // if (localStorage.getItem('ticketFilters')){
    //     let filters = JSON.parse(localStorage.getItem('ticketFilters'));
    //     this.props.getAllTicketsBySearch(100000,1,filters.searchString,filters.date,filters.type,filters.status);
    // }
    // else{
    let filters = {
      search: "",
      date: "today",
      type: "r",
      status: "all",
    };
    localStorage.setItem("ticketFilters", JSON.stringify(filters));
    // }
  }

  resetRedirects() {
    localStorage.setItem("redirectToMaintenance", false);
    localStorage.setItem("redirectToTickets", false);
    localStorage.setItem("redirectToCalendar", false);
  }

  resetTicketDetailLoad() {
    localStorage.removeItem("loadDefaultTickets");
  }

  componentDidMount() {
    this.resetTicketDetailLoad();
    this.props.getMachinesForTicketCreation();
    if (localStorage.getItem("redirectToTickets") === "true") {
      this.resetRedirects();
      localStorage.setItem("createTickets", false);
    } else {
      this.initializeFiltersAndSearch();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentWillReceiveProps() {
    if (this.props.ticketDetailLoading == true) {
      this.setState({
        assetDetail1: null,
        assetDetail2: null,
        assetDetail3: null,
        assetDetail4: null,
        ticketDetailHasBeenLoaded: false,
      });
    } else {
      this.setState({
        ticketDetailHasBeenLoaded: true,
      });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.state.ticketDetailHasBeenLoaded == true) {
      let assets = nextProps.assetDetail;
      let videos = [];
      let images = [];
      if (assets.length > 0) {
        assets.map((item, itemsIndex) => {
          let checker = item.assetLink.substr(item.assetLink.length - 3);
          if (checker == "mp4") {
            videos.push(item);
          } else {
            images.push(item);
          }
        });

        let sortedVideos = videos.sort((a, b) => {
          return a.createdDt < b.createdDt ? 1 : -1;
        });
        let sortedImages = images.sort((a, b) => {
          return a.createdDt < b.createdDt ? 1 : -1;
        });
        this.setState({
          assetDetail1:
            sortedImages[0] != undefined ? sortedImages[0].assetLink : null,
          assetDetail2:
            sortedImages[1] != undefined ? sortedImages[1].assetLink : null,
          assetDetail3:
            sortedImages[2] != undefined ? sortedImages[2].assetLink : null,
          assetDetail4:
            sortedVideos[0] != undefined ? sortedVideos[0].assetLink : null,
          ticketDetailHasBeenLoaded: false,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("Component did update", prevProps, prevState);
    if (
      this.props.translation &&
      this.props.translation.translations.timeStamp !=
      this.state.translationTimestamp
    ) {
      this.setState({
        translationTimestamp: this.props.translation.translations.timeStamp,
        languageProps: Object.assign({}, { ...this.changeLang() }),
      });
    }
  }

  componentWillUnmount() {
    // console.log("Component will unmount");
  }

  getData() {
    if (this.filtered === false) {
      this.tickets = this.props.tickets;
    }
  }

  openModal = () => {
    this.setState({ modalOpen: true });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  handleCreateTicket = (machineCode) => {
    this.setState({ modalValue: machineCode });
    if (this.props.machineTicketCreation) {
      let check = this.props.machineTicketCreation.filter((item) => {
        return item.machine.toLowerCase() === machineCode.toLowerCase();
      });
      if (check.length > 0) {
        localStorage.setItem("redirectToTickets", "true");
        localStorage.setItem("createTickets", "true");
        history.push("/create-ticket/" + check[0].id);
      } else {
        this.props.notificationError("Cannot find Machine");
      }
    }
  };

  handleEnter = (e) => {
    if (e.key === "Enter") {
      this.handleCreateTicket();
    }
  };

  handleSearch = (searchText)=>{
    let filters = JSON.parse(localStorage.getItem("ticketFilters"));
    const page = 1;
    this.props.getAllTicketsBySearchPagination(
      this.state.pageSize,
      page,
      searchText,
      filters.date,
      filters.type,
      filters.status,
      this.props.excel
    );
    this.setState({ searchText });
  }

  render() {
    return (
      <MainPage className="tickets-overview">
        <QueueState
          ref={this.exportQueue}
          pageSize={1000}
          onCompleted={this.handleDownloadExcel}
        />
        <MainPageHeader
          onFilter={(searchText)=> this.handleSearch(searchText)}
          searchLabel="Tickets"
          searchString={this.state.searchText}
          name="tickets"
          title="Tickets"
          search={true}
          rows={this.props.meta.count}
          displayedRows={this.props.displayedRows}
        />
        <MainPageBody>
          <Grid container spacing={0}>
            <Grid className="actions" item xs={12}>
              <NativeSelects
                statusListLoading={this.props.statusListLoading}
                openModal={this.openModal}
                handleExportToExcel={this.handleExportToExcelWithQueue}
                isExporting={this.state.isExporting}
                handleCancel={this.handleCancel}
                checked={this.checkIfChecked()}
                tickets={this.props.tickets}
                checkIfChecked={this.checkIfChecked}
                statusList={this.props.statusList}
                handleStatusList={this.handleStatusList}
                searchString={this.state.searchText}
                getTickets={this.props.getAllTicketsBySearch}
                pageSize={this.state.pageSize}
                dataFromParent={this.handleTypeFilterChange}
                userAccess={this.props.userAccess}
              ></NativeSelects>
            </Grid>
          </Grid>
          {this.renderTableData()}
        </MainPageBody>
        <ExcelLoading />
        <Modal
          open={this.state.modalOpen === true}
          onClose={this.closeModal}
          style={this.state.modalSettings}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade
            style={this.state.paperSettings}
            in={this.state.modalOpen === true}
          >
            <div style={this.state.modalDiv} className="create-ticket-modal">
              <div onClick={this.closeModal} style={this.state.modalClose}>
                <CloseIcon style={this.state.modalCloseButton} />
              </div>
              <div className="title">
                {this.state.languageProps.SELECT_MACHINE}
              </div>
              <Autocomplete
                onChange={(event, value) => this.handleCreateTicket(value)}
                freeSolo
                options={this.props.machineTicketCreation.map(
                  (item) => item.machine
                )}
                renderInput={(params) => (
                  <TextField
                    className="create-ticket-modal-input"
                    style={this.state.modalInput}
                    {...params}
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />
              <Button
                onClick={this.handleCreateTicket}
                style={this.state.modalButton}
                variant="contained"
                color="primary"
              >
                {this.state.languageProps.SELECT}
              </Button>
            </div>
          </Fade>
        </Modal>
        <MaintenanceChecklist
          title={
            this.props.ticketDetail ? this.props.ticketDetail.checklistNo : null
          }
        />
      </MainPage>
    );
  }
}

const mapStateToProps = (state) => {
  let { translationReducer } = state;
  let apiLoading = {
    ticketsLoading: state.ticketListReducer.loading,
    ticketDetailLoading: state.ticketListReducer.loadingDetail,
    statusListLoading: state.statusListReducer.loading,
  };
  let data = {
    userAccess: state.authReducer.access,
    rows: state.ticketListReducer.rows,
    assetDetail: state.ticketListReducer.assetDetail,
    machineTicketCreation: state.machineListReducer.machineTicketCreation,
    machineDetail: state.machineListReducer.machineDetail,
    ticketDetail: state.ticketListReducer.ticketDetail,
    displayedRows: state.ticketListReducer.displayedRows,
    currentPage: state.ticketListReducer.currentPage,
    excel: state.ticketListReducer.excel,
    tickets: state.ticketListReducer.tickets,
    statusList: state.statusListReducer.ticketStatusList,
    meta: state.ticketListReducer.meta,
    error: state.ticketListReducer.error,
  };

  return {
    ...apiLoading,
    ...data,
    ...translationReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (modal) => dispatch(showModal(modal)),
    closeModal: () => dispatch(closeModal()),
    uncheckTicketTableCheckboxById: (tickets, ids) =>
      dispatch(uncheckTicketTableCheckboxById(tickets, ids)),
    getAllTicketsBySearch: (
      pageSize,
      currentPage,
      searchString,
      reportedDt,
      ticketType,
      status
    ) =>
      dispatch(
        getAllTicketsBySearch(
          pageSize,
          currentPage,
          searchString,
          reportedDt,
          ticketType,
          status
        )
      ),
    getAllTicketsBySearchPagination: (
      pageSize,
      currentPage,
      searchString,
      reportedDt,
      ticketType,
      status,
      excelData
    ) =>
      dispatch(
        getAllTicketsBySearchPagination(
          pageSize,
          currentPage,
          searchString,
          reportedDt,
          ticketType,
          status,
          excelData
        )
      ),
    ticketListUpdatePage: (page) => dispatch(ticketListUpdatePage(page)),
    notificationSuccess: (message) => dispatch(notificationSuccess(message)),
    notificationError: (message) => dispatch(notificationError(message)),
    getRepairTicketStatus: () => dispatch(getRepairTicketStatus()),
    getMaintenanceTicketStatus: () => dispatch(getMaintenanceTicketStatus()),
    putCancelTickets: (searchString, payload) =>
      dispatch(putCancelTickets(searchString, payload)),
    getMachinesForTicketCreation: () =>
      dispatch(getMachinesForTicketCreation()),
  };
};

Tickets = connect(mapStateToProps, mapDispatchToProps)(Tickets);

export default Tickets;
