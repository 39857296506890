import React, { useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Box,
} from '@material-ui/core';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from "react-beautiful-dnd";
import { withStyles } from '@material-ui/core/styles';
import TableHeaderCell from '../components/TableHeaderCell';
import CardPaper from '../../components/CardPaper';
import TableCardCell from '../components/TableCardCell';
import TableCardRow from '../components/TableCardRow';
import Actions from './MachinesTable/Actions';
import CriticalMark from '../../components/CriticalMark';
import Typography from '../../components/Typography';
import TableImageList from '../../components/TableImageList';
import NoRecords from './NoRecords';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

const TableRowWithActions = withStyles({
  root: {
    '& .drag-indicator': {
      borderLeft: '5px solid #1689FA',
      position: 'absolute',
      left: 0,
      top: 10,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      visibility: 'hidden',
    },
    '&:hover .drag-indicator': {
      visibility: 'visible',
    },
    '& .actions': {
      visibility: 'hidden',
    },
    '&:hover .actions': {
      visibility: 'visible',
    },
  }
})(TableCardRow);

const Container = withStyles({
  root: {
    paddingBottom: 80
  }
})(Box);

const WITHOUT_INDEX = -1;

export default function MachinesTable({ machines, onAdd, onEdit, onDelete, onChange, onCopy }){

  const handleDragEnd = (result, provided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    let temp = [...machines];
    const destination = temp[result.destination.index];
    const source = temp[result.source.index];
    const destinationStation = destination.station;
    const sourceStation = source.station;


    temp.forEach((machine, index)=>{
      if(machine.station == destinationStation){
        temp[index].station = sourceStation;
        return;
      }

      if(machine.station == sourceStation){
        temp[index].station = destinationStation;
        return;
      }
    })

    onChange(temp);
  };
  const hasMachines = machines.length > 0;

  return <Container>
    <CardPaper>
      {'MACHINES'.translate()}
    </CardPaper>

    <TableContainer>
      <Table stickyHeader >
        <TableHead>
          <TableRow>
            <TableHeaderCell>
              {'CRITICAL'.translate()}
            </TableHeaderCell>
            <TableHeaderCell>
              {'M/C'.translate()}
            </TableHeaderCell>
            <TableHeaderCell>
              {'NEEDLE TYPE'.translate()}
            </TableHeaderCell>
            <TableHeaderCell>
              {'ATTACHMENT'.translate()}
            </TableHeaderCell>
            <TableHeaderCell>
              {'OPERATION'.translate()}
            </TableHeaderCell>
            <TableHeaderCell>
              {'REMARKS'.translate()}
            </TableHeaderCell>
            <TableHeaderCell>
              { /* Action Colum */ }
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(droppableProvided) => (
              <TableBody
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {
                  machines.map((machine, index) => (
                    <Draggable
                      key={`${machine.id || machine.uuid}`}
                      draggableId={`${machine.id || machine.uuid}`}
                      index={index}
                    >
                      {(draggableProvided, snapshot) => (
                        <TableRowWithActions
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          style={{
                            ...draggableProvided.draggableProps.style
                          }}
                        >
                          <TableCardCell>
                            <div className="drag-indicator" {...draggableProvided.dragHandleProps}>
                              <DragIndicatorIcon/>
                            </div>
                            <CriticalMark isActive={machine.isCritical}/>
                          </TableCardCell>
                          <TableCardCell>
                            { machine.machineSubType }
                          </TableCardCell>
                          <TableCardCell>
                            { machine.needleType }
                          </TableCardCell>
                          <TableCardCell>
                            <TableImageList
                              images={machine.attachments || []}
                            />
                          </TableCardCell>
                          <TableCardCell>
                            { machine.operation }
                          </TableCardCell>
                          <TableCardCell>
                            { machine.remarks }
                          </TableCardCell>
                          <TableCardCell>
                            <Actions
                              onCopy={() => onCopy(index)}
                              onAdd={()=> onAdd(index)}
                              onEdit={()=> onEdit(index)}
                              onDelete={()=> onDelete(index)}
                            />
                          </TableCardCell>
                        </TableRowWithActions>
                      )}

                    </Draggable>

                  ))
                }
                {droppableProvided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>

      </Table>
    </TableContainer>
    {
      hasMachines ? (
        <Box mt={2}>
          <Typography variant='h6'>
            {'Total M/C'.translate()}: { machines.length }
          </Typography>
        </Box>
      ) : (
        <NoRecords onNewClicked={()=> onAdd(WITHOUT_INDEX)} />
      )
    }


  </Container>
}