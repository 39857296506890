import { colors } from './constants';
import {
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  eachMonthOfInterval,
  eachDayOfInterval,
  eachHourOfInterval,
  eachWeekOfInterval,
  format,
  getWeek,
  endOfWeek
} from 'date-fns';
import { numberWithCommas } from '../../../utils/helper';
import qs from 'qs';
import moment from 'moment';

export const kFormat = (num)=>{
  return Math.abs(num) > 999 ? (Math.sign(num) * (Math.abs(num)/1000).toFixed(1)) + 'K' : (Math.sign(num) * Math.abs(num))
}

export const sortRows = (rows, orderBy, order="asc")=>{
  return rows.sort((row1, row2)=>{
   let result = 0;
   if(row1[orderBy] < row2[orderBy]) { result = -1 }
   if(row1[orderBy] > row2[orderBy]) { result = 1 }
   if(order == "asc"){
     return result;
   }else{
     return -result;
   }
 })
}

export const splitRows = (rows)=>{
  if(rows.length <= 10){
    return rows.map((r, index)=>({ ...r, color: colors[index] }))
  }
  const otherColor = colors[9];
  const totalTime = rows.reduce((sum, row)=>(sum + row.totalTime), 0);
  const totalTickets = rows.reduce((sum, row)=>(sum + row.totalTickets), 0);
  const otherRows = rows.slice(9).map((r)=>({ ...r, color:otherColor }));
  const totalOtherTickets =  otherRows.reduce((sum, row)=>(sum + row.totalTickets), 0);
  const totalOtherTime  =  otherRows.reduce((sum, row)=>(sum + row.totalTime), 0);
  const totalOtherOverdueTickets  =  otherRows.reduce((sum, row)=>(sum + row.totalOverdueTickets), 0);
  const totalOtherOnScheduleTicket =  otherRows.reduce((sum, row)=>(sum + row.totalOnScheduleTickets), 0);
  const other = {
    name: 'Other'.translate(),
    totalTimeP: (totalOtherTime / totalTime * 100),
    totalTicketsP: (totalOtherTickets / totalTickets * 100),
    totalTickets: totalOtherTickets,
    totalOnScheduleTicket: totalOtherOnScheduleTicket,
    totalOverdueTickets: totalOtherOverdueTickets,
    totalOverdueTicketsP: (totalOtherOverdueTickets / totalTickets * 100),
    totalOnScheduleTicketP: (totalOtherOnScheduleTicket / totalTickets * 100),

    totalTime: totalOtherTime,
    rows: otherRows,
    color: otherColor,
    avgTime: parseInt(totalOtherTime / otherRows.length),
    isSelected: otherRows.every((r)=> r.isSelected)
  }

  return [
    ...rows.slice(0, 9).map((r, index)=>({ ...r, color: colors[index] })),
    other
  ]
}

export const eachInternal = ({ startDate, endDate, interval })=>{
  switch(interval) {
  case "monthly":
    return eachMonthOfInterval({
      start: startDate,
      end: endDate
    }).map((d)=> ({
      key: parseInt(format(d, "yyyyM")),
      label: format(d, "MMMM yyyy"),
    }));
  case "daily":
    return eachDayOfInterval({
      start: startDate,
      end: endDate
    }).map((d)=> ({
      key: parseInt(format(d, "d")),
      label: format(d, "MMMM dd"),
    }));
  case "weekly":
    const now = new Date();
    const dates = eachWeekOfInterval({
      start: startDate,
      end: endDate
    });

    return dates.map((d, index)=> {
      let sd = d;
      let ed = endOfWeek(d);
      if(index === 0) {
        sd = startDate;
      } else if (index === dates.length - 1) {
        ed = endDate;
      }

      return ({
        key: differenceInWeeks(now, d),
        label: `Week ${getWeek(d)}`,
        dateRange: `Week ${getWeek(d)} (${format(sd, "MMM dd, yyyy")} - ${format(ed, "MMM dd, yyyy")})`,
      })
    });
  case "hourly":
    return eachHourOfInterval({
      start: startDate,
      end: endDate
    }).map((d)=> ({
      key: parseInt(format(d, "H")),
      label: format(d, "HH:00"),
    }));
  default:
    throw("error")
  }
}

export const getAverageMachineDowntime = (data, ticks) => {
  const grouped = data.reduce((holder, item) => {
    const existMarked = holder.find((innerItem) => innerItem['marked'] === item.marked)
    if(!existMarked) {
      holder.push({
        marked: item.marked,
        ticketQty: item.ticketQty,
        totalTime: item.totalTime
      })
    } else {
      existMarked.ticketQty += item.ticketQty;
      existMarked.totalTime += item.totalTime;
    }

    return holder;
  }, []);

  return ticks.map(t => {
    const marked = grouped.find(m => m.marked === t.key);
    return marked ? (marked.totalTime / marked.ticketQty): 0;
  })
}

export const getMachineDowntimeData = (data = [], type = 'total') => {
  if(data.length === 0) return [];
  let result = [];
  const tmp = data.filter(({ type }) => type !== 'line').map((item) => item.data);
    tmp[0].forEach((col, colIndex) => {
      let totalCol = 0;
      tmp.forEach((row, rowIndex) => {
        let value = row[colIndex] ? row[colIndex] : 0;
        type === 'total' ? totalCol += value : totalCol += ( value / row.length);
      })
      result.push(totalCol.toFixed(1));
    });
    return result;
}

export const normalizeBarChart = (reportData, valueKey, tickKey, dictionary, dateRange, lines = [])=>{
  const ticks = eachInternal(dateRange);
  let datasets = dictionary.filter((e)=> e.isSelected).map((dic)=>{
    if(dic.rows){
      const problemTypes  = dic.rows.map((r)=> r.id);
      return {
        label: dic.name,
        data: ticks.map(({key})=> reportData.filter((a)=> problemTypes.includes(a.id) && a[tickKey] == key).reduce((sum, r) => sum + r[valueKey], 0)),
        backgroundColor: dic.color,
        stack: "bar"
      }
    }else{
      return {
        label: dic.name,
        data: ticks.map(({key})=>{
          const matched = reportData.find((a)=> a.id == dic.id && a[tickKey] == key)
          return matched ? matched[valueKey] : 0;
        }),
        backgroundColor: dic.color,
        stack: "bar"
      }
    }
  });
  
  const linesChart = lines.map(({ label, borderColor, key }) => (
    {
      type: "line",
      label,
      borderColor,
      borderWidth: 3,
      fill: false,
      pointBackgroundColor: "white",
      pointBorderWidth: 1.5,
      grid: { display: false },
      data: key === 'total' ? getMachineDowntimeData(datasets, key) : getAverageMachineDowntime(reportData, ticks),
      stack: 'line',
    }
  ))

  datasets = [
    ...datasets,
    ...linesChart
  ];

  const data = {
      labels: ticks.map(({label}) => label),
      datasets: datasets.map((d, index)=>({ ...d, barPercentage: 0.5 })).reverse()
    };
    
    return data;
  }


export const nornalizeProblemRows = (data)=>{
  const totalTime = data.reduce((sum, row)=>(sum + row.totalTime), 0);
  const totalTickets = data.reduce((sum, row)=>(sum  + row.totalTickets), 0);

  const rows = data.map((row, index)=>{
    return {
      ...row,
      problemType: row.name ? row.name : "N/A",
      totalTimeP: (row.totalTime / totalTime * 100),
      totalTicketsP: (row.totalTickets / totalTickets * 100),
      totalOverdueTicketsP: (row.totalOverdueTickets / totalTickets * 100),
      totalOnScheduleTicketP: (row.totalOnScheduleTickets / totalTickets * 100),
      isSelected: true
    }
  });

  return rows;
}

export const onlyUnique = (value, index, self)=>((self.indexOf(value) === index));

export const normalizeStructures = (structures)=>{
  const buildings = structures.map((b)=>({ ...b, isSelected: true }));
  const floors = buildings.map((b)=> b.floors.map((f)=>({ ...f, parentId: b.id, isSelected: true })) ).flat();
  const areas = floors.map((f)=> f.areas.map((a)=>({ ...a, parentId: f.id, isSelected: true })) ).flat();
  const lines = areas.map((a)=> a.lines.map((l)=>({ ...l, parentId: a.id, isSelected: true }))).flat();
  return { buildings, floors, areas, lines: sortRows(lines, 'name', 'asc') };
}

export const tagFilterDisabled = (structures, parents=[])=>{
  const tempStructures = structures.map((s)=> ({ ...s, isDisabled: false }))
  parents.filter((e)=> !e.isSelected || e.isDisabled).forEach((p)=>{
    tempStructures.filter((s)=> s.parentId == p.id).forEach((s)=>{
      const index = tempStructures.findIndex((t)=> t.id == s.id);
      tempStructures[index].isDisabled = true;
    });
  });
  return tempStructures;
}

export const normalizeFilter = (filters)=>{
  const isCheckAll = filters.every((f)=> f.isSelected);
  if(isCheckAll){ return null } // no filter send to the backend, and it means select all
  return filters.filter((f)=> f.isSelected).map((f)=> f.id);
}

export const compactObject = (obj)=>{
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj
}

export const dateRangeInterval = ({ startDate, endDate })=>{
  if(differenceInHours(endDate, startDate) <= 24){
    return "hourly"
  }

  if(differenceInDays(endDate, startDate) <= 30){
    return "daily"
  }

  if(differenceInWeeks(endDate, startDate) <= 30){
    return "weekly"
  }

  return "monthly"
}


export const uniqBy = (arr, key)=>{
  let keys = [];
  let newArr = [];
  arr.forEach((a)=>{
    if(keys.indexOf(a[key]) == -1){
      newArr.push(a);
      keys.push(a[key]);
    }
  });

  return newArr;
}

export const normalizeDataTooltip = (data, dateRange, tickKey, originalRows = [], viewBy = '') => {
  const ticks = eachInternal(dateRange);
  if(data.length <= 0 && originalRows.length <= 0) return [];
  const results = ticks.reduce((acc, { key, label }) => {
    const tmpData = data?.filter((item) => item[tickKey] == key).map((item) => {
      const row = originalRows.find(({ id }) => (id == item.id));
      const tick = ticks.find(({ key }) => item[tickKey] === key);
      const name = row?.name || 'N/A';
      return { 
        name,
        label: tick?.label,
        subLabel: tick?.dateRange,
        color: row?.color,
        totalMachines: row?.totalMachines || 0,
        ...item
      }
    });
    return acc.concat({
      label,
      data: tmpData,
      total: tmpData.reduce((sum, row) => (sum + row[viewBy]), 0),
    });
  }, []);
  return results;
};

export const getDateRangeQueryString = (params) => {
  return qs.stringify({
    ...params,
    dateRange: {
      startDate: moment(params.dateRange.startDate).format(),
      endDate: moment(params.dateRange.endDate).format(),
      interval: params.dateRange.interval,
    },
  });
}

export const getTotalAverage = (data, valueKey) => {
  const totalTicketQty = data.reduce((sum, row) => (sum + row['ticketQty']), 0);
  if(!totalTicketQty){
    const result = data.reduce((sum, row) => (sum + row[valueKey]), 0) / data.length;
   return result ? formatMinute(result) : 0;
  }
  const totalTime = getTotal(data, 'totalTime');
  const totalTimeConverted = totalTime 
      ? parseFloat(totalTime.replace(",", ""))
      : 0;
  const avgResponseTime = totalTimeConverted / totalTicketQty;
  return avgResponseTime ? formatMinute(avgResponseTime) : 0;
}

export const getTotal = (data, valueKey) => {
  const result = data.reduce((sum, row) => (sum + row[valueKey]), 0);
  return result ? formatMinute(result) : 0;
}


export const formatMinute = (minute) => minute ? numberWithCommas(minute.toFixed(2)) : 0;
