import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { closeModal } from '../../../redux/actions/Modal/modalActions'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import './AddMaintenanceTemplate.scss';

class AddMaintenanceTemplate extends React.Component {

  state = {
      modalName: 'addMaintenanceTemplate',
      modalSettings: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      paperSettings: {
        backgroundColor: '#ffffff',
        border: '2px solid #000',
        boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.3)',
        padding: '2px 4px 3px',
      },
      open: false,
      search: false
  }

  componentDidMount(){
  }

  render(){
    return (
        <div>
            <Modal 
                open={this.props.modalShown === this.state.modalName} 
                onClose={this.props.closeModal}
                style={this.state.modalSettings}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout:500
                }}
            > 
                <Fade style={this.state.paperSettings} in={this.props.modalShown === this.state.modalName}>
                    <div>
                        <h2>Add Maintenance Template Modal Here</h2>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
  }
}



const mapStateToProps = state => {
    let apiLoading = {
    }
    let data = {
      modalShown: state.modalReducer.modalShown
    }
  
    return {
      ...apiLoading,
      ...data
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      closeModal: () => dispatch(closeModal()),
    }
  }

AddMaintenanceTemplate = connect(mapStateToProps,mapDispatchToProps)(AddMaintenanceTemplate);

export default AddMaintenanceTemplate;
