import { 
    Button, 
    CircularProgress, 
    Menu, 
    MenuItem, 
    withStyles, 
} from '@material-ui/core';
import React from 'react';
import { PAGE_ENUMS } from '../../../../utils/constants';
import { 
    checkPageAccessField, 
    checkPageActionAccess,
} from '../../../../utils/helper';

function BulkAction({
    loading = false,
    disabled = false,
    userAccess = null,
    actionInactive,
    actionAvailable,
    actionMove,
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const permissionActionStatusMachine = () => {
        return checkPageActionAccess(userAccess, { pageId: 1, pageAction: 'Retire' });
    };

    const permissionActionMoveMachine = () => {
        return checkPageAccessField(userAccess, { page: PAGE_ENUMS.machines, pageAccess: 'FULL ACCESS' });
    };

    const handleActionInactive = () => {
        setAnchorEl(null);
        actionInactive();
    };

    const handleActionAvailable = () => {
        setAnchorEl(null);
        actionAvailable();
    };

    const handleActionMove = () => {
        setAnchorEl(null);
        actionMove();
    };

    return ( 
        <React.Fragment>
            <StyleedButton
                disabled={disabled || loading}
                aria-controls="select-action" 
                aria-haspopup="true" 
                onClick={handleClick}>
                {'Select A Bulk Action'}
            </StyleedButton>
            <StyledMenu
                id="select-action"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                elevation={0}
                getContentAnchorEl={null}
            >
                { permissionActionMoveMachine() && <StyledMenuItem onClick={handleActionMove}>{'Move Machine'}</StyledMenuItem>}
                { permissionActionStatusMachine() && <StyledMenuItem onClick={handleActionInactive}>{'Make Inactive'}</StyledMenuItem>}
                { permissionActionStatusMachine() && <StyledMenuItem onClick={handleActionAvailable}>{'Make Available'}</StyledMenuItem>}
            </StyledMenu>
        </React.Fragment>
    );
}

const StyleedButton = withStyles({
    root:{
        position: 'relative',
        minWidth: '200px !important',
        padding: '2px 15px',
        color: '#3f51b5',
        border: '1px solid rgba(63, 81, 181, 0.5)',
        '&:hover': {
            border: '1px solid #3f51b5',
            backgroundColor: 'rgba(63, 81, 181, 0.04)',
        },
        '& span': {
            textAlign: 'left',
            marginRight: 5,
            fontSize: '1.1rem',
            fontWeight: 'bold',
            fontFamily: 'Arial',
            textTransform: 'uppercase',
            color: '#0F75BC',
            cursor: 'pointer',
        },
        '&:after':{
            position: 'absolute',
            content: "'\u25bc'",
            position: 'absolute',
            top: 1,
            right: 5    ,
            fontSize: '10px',
            opacity: .5,
            transform: 'translateY(30%)',
        },
        '&:disabled':{
            border:'1px solid #999'
        },
        '&:disabled span':{
            color:'#999'
        }
    }
})(Button);
const StyledMenuItem = withStyles((theme) => ({
    root: {
        minWidth: 200,
        '&:focus': {
            backgroundColor: '#eee',
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  

export default BulkAction;