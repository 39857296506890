import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import IconFillter from './../../assets/icons/filter.svg';
import IconFillterBlue from './../../assets/icons/filter-blue.svg';
import { withStyles } from '@material-ui/core/styles';
import { Badge } from '@material-ui/core';

export const StyledBadge = withStyles((theme) => ({
    badge: {
        padding: '0 4px',
        minWidth: '30px',
        height: '30px',
        top: '20px',
        right: '20px',
        padding: '0 4px',
        borderRadius: '40px',
        backgroundColor: '#0F75BC',
        fontSize: '1rem',
        lineHeight: '1',
    },
}))(Badge);

export const FilterButton = withStyles({
    root: {
        color: '#555',
        height: '40px',
        border: '1px solid #ddd',
        borderRadius: '4px !important',
        backgroundImage: `url(${IconFillter})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '40px',
        backgroundPosition: 'left',
        '&:hover': {
            color: '#1E88E5',
            backgroundColor: '#fff !important',
            backgroundImage: `url(${IconFillterBlue})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '40px',
            backgroundPosition: 'left',
        },
    },
    label: {
        fontSize: '14px',
        paddingLeft: '25px',
        paddingRight: '25px',
        textTransform: 'uppercase',
    }
})((props) => {
    const { badge } = props;
    const number = badge || 0;
    return (
        <StyledBadge badgeContent={number} color='primary' showZero>
            <Button
                fontSize='large'
                aria-controls='customized-menu'
                aria-haspopup='true'
                variant='outlined'
                {...props}>
                {props.title || 'Filters'}
            </Button>
        </StyledBadge>
    )
});

export const FilterMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
    list: {
        width: '200px',
        FormLabel: {
            fontSize: '1.3rem',
            fontWeight: 700,
        },
        '& .formLabel': {
            fontSize: '1.3rem',
            padding: '10px 10px 0px 10px',
            fontWeight: '700',
        },
        '& .formLabel:nth-of-type(1)': {
            paddingBottom: '10px',
        },
        '& .MuiFormControlLabel-root': {
            margin: 0,
        },
        '& .MuiTypography-root': {
            fontSize: '1.2rem',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1.8rem',
        },
        '& .MuiButtonBase-root': {
            padding: '2px 8px',
        },
        '& .MuiCheckbox-colorPrimary.Mui-checked': {
            color: '#0F75BC',
        },
    },

})((props) => (
    <Menu
        id="customized-menu"
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
));