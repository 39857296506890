import React from 'react';
import { 
    Box, 
    withStyles, 
    Table, 
    TableCell,
    Typography,
    Tooltip,
} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

export const ExportIcon= withStyles({
    root:(props) => ({
        fontSize: 32, 
        color: '#1585F4',
        ...props,
    }),
})(CloudDownloadIcon);

export const BlackTooltip = withStyles({
    tooltip:(props) => ({
        color: props.color || 'white',
        backgroundColor: props.background || 'black',
        padding: '10px 17px',
        width: 300,
    }),
    arrow:(props) => ({
        color: props.background || 'black',
    }),
})(Tooltip);

export const TableStyle = withStyles({
    root: () => ({
        width: '100%',
        borderSpacing: '5px',
        borderCollapse: 'separate',
        marginTop: 10,
        marginBottom: 10,
        '& th': {
            background: '#EDF0F2 0% 0% no-repeat padding-box',
            color: '#000',
            fontSize: 14,
            fontWeight: 'bold',
        },
        '& th, td': { padding: 5, borderBottom: 'none' },
        '& td': { fontSize: 13 },
        '& tbody tr': { boxShadow: '0px 1px 0px 0px rgba(224, 224, 224, 1)' },
        '& tfoot td': { fontWeight: 'bold', color: '#000 !important' },
    }),
})(Table);

export const Col = withStyles({
    root: (props) => ({
        backgroundColor: props.background,
        fontWeight: props.fontWeight,
        textAlign: props.align,
        padding: props.padding + "!important",
    }),
})((props) => <TableCell {...props} />);

export const ColFilter = withStyles({
    root: () => ({
        display: 'flex', 
        alignContent: 'center', 
        gap: 5, 
        justifyContent: 'center', 
        cursor: 'pointer',
        '&:hover':{ opacity: .9},
        backgroundColor: '#1585F4 !important', 
        color: '#ffffff !important',
    }),
})((props) => <TableCell {...props} />);

export const LegendIndicator = withStyles({
    root: (props) => ({
        backgroundColor: props.color,
        width: 16,
        height: 16,
        borderRadius: 2,
        marginLeft: props.marginLeft || 4,
        marginRight: props.marginRight || 4,
    }),
})(Box);
  
export const IndicatorContainer = withStyles({
    root: (props) => ({
        display: 'flex',
        justifyContent: props.justifyContent || 'space-around',
        marginLeft: props.marginLeft || 10,
        marginRight: props.marginRight || 10,
    }),
})(Box);

export const FlexBox = withStyles({
    root: (props) => ({
        display: 'flex',
        justifyContent: props.justifyContent || 'space-between',
        alignItems: 'center',
        gap: props.gap,
    }),
})((props) => <Box {...props} />);

export const ProblemBoxContainer = withStyles({
    root: (props) => ({
        display:'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        alignItems: 'center',
        gap: '5px',
    }),
})(Box);

export const ProblemBox = withStyles({
    root: (props) => ({
        width: '100%',
        display:'grid',
        gridTemplateColumns: '15% 1fr',
        gap: '5px',
    }),
})(Box);

export const ProblemBoxItem = withStyles({
    root: (props) => ({
        background: props.color,
        padding: '4px 3px',
        textAlign: props.align,
        fontWeight: props.fontWeight,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        borderRadius: 3,
    }),
})(Typography);


export const TimeBoxContainer = withStyles({root: () => ({display: "flex", flexDirection: 'column', gap: '1px', transition: 'width 2s'})})(Box);
export const TimeBoxTitleContainer = withStyles({root: () => ({ height: '15px', borderTopLeftRadius: 3, borderTopRightRadius: 3, })})(Box);
export const TimeBoxTitle = withStyles({
    root: () => ({fontSize: '11px', lineHeight: '15px', fontWeight: 'bold', textAlign: 'left', marginLeft: '5px', whiteSpace: 'nowrap'})
})(Typography);
export const TimeBoxHeader = (props) => (
    <Box width="100%">
        <TimeBoxTitleContainer width={props.width} bgcolor={props.color}>
            <TimeBoxTitle width={10}>{props.title}</TimeBoxTitle>
        </TimeBoxTitleContainer>
    </Box>
);
export const TimeBoxContent = withStyles({root: () => ({ 
    display: 'flex', 
    gap: '1px', 
    '& > div:first-child':{
        borderBottomLeftRadius: 3,
    },
    '& > div:last-child':{
        borderBottomRightRadius: 3,
    },
})})(Box);
export const TimeBoxItem = withStyles({root: () => ({ height: '15px', transition: 'width 2s'})})(Box);
