import ExcelJS from 'exceljs';
import moment from 'moment';
import { viewsByFilters } from '../containers/Modules/Analytics/components/MachineBreakDown/constant';
import { getUsername } from './helper';

const backgroundFill = {
    type: 'pattern',
    pattern:'solid',
    fgColor:{argb:'d9d9d9'},
};

const formatNumber = number => {
    if(!number || number < 0.01) return Number(0.00);

    return Number(parseFloat(number).toFixed(2));
}

const getTitleByType = type => {
    switch(type) {
        case ANALYTIC_REPORT_TYPES.LineNpt:
            return 'Line NPT Analysis'.translate();
        case ANALYTIC_REPORT_TYPES.OverallMechanicPerformance:
            return 'Overall Mechanic Performance'.translate();
        case ANALYTIC_REPORT_TYPES.IndividualMechanicPerformance:
            return 'Individual Mechanic Performance'.translate();
        case ANALYTIC_REPORT_TYPES.MachineBreakdown:
            return 'Machine Breakdown Analysis'.translate();
        default: 
            return 'Line Analysis'.translate();
    }
}

const getDataFilter = () => {
    let dataFilter = {};
    const dataFilterStr = localStorage.getItem('analyticFilter');
    if(dataFilterStr) {
        dataFilter = JSON.parse(dataFilterStr);
    }
    
    let filter = getSelectedDataFilter({
        key: 'buildings',
        label: 'Buildings',
    }, dataFilter);
    filter += getSelectedDataFilter({
        key: 'floors',
        label: 'Floors',
    }, dataFilter);
    filter += getSelectedDataFilter({
        key: 'areas',
        label: 'Areas',
    }, dataFilter);
    filter += getSelectedDataFilter({
        key: 'lines',
        label: 'Line',
    }, dataFilter);
    filter += getSelectedDataFilter({
        key: 'mechanics',
        label: 'Mechanic',
    }, dataFilter);
    filter += getSelectedDataFilter({
        key: 'shifts',
        label: 'Shifts',
    }, dataFilter);
    filter += getSelectedDataFilter({
        key: 'machineTypes',
        label: 'Machine Type',
    }, dataFilter);
    filter += getSelectedDataFilter({
        key: 'machineSubTypes',
        label: 'Machine Sub-type',
    }, dataFilter);
    filter += getSelectedDataFilter({
        key: 'machineBrands',
        label: 'Machine Brand',
        isLast: true,
    }, dataFilter);
    

    return filter;
}

const getSelectedDataFilter = (config, dataFilter) => {
    const allLabel = ('All ' + config.label);
    const label = config.label;
    let items = dataFilter[config.key];
    if(items) {
        const selectedItems = items.filter(t => t.isSelected === true);
        if(selectedItems.length === 0) {
            return '';
        }
        return (selectedItems.length === items.length ? 
            allLabel.translate() : 
            (label.translate() + ': ' + items.map(t => t.name.translate()).join(', '))) + (config.isLast ? '' : '/');
    }

    return '';
}

const LineNpt = {
    setupSheet: (sheet, headerLength, data) => {
        const maxProblemType = LineNpt.renderBody(sheet, headerLength + 1, data);
        LineNpt.renderHeaders(sheet, headerLength + 1, maxProblemType);
    },
    renderHeaders: (sheet, startIndex, maxProblemType) => {
        let headers = [
            'Line Name'.translate(), 
            'Changeover Tickets'.translate(), 
            'Machine Prepared'.translate(), 
            'Closed Repair Tickets'.translate(), 
            'Total NPT (min)'.translate(),
        ];
        const headerRow = sheet.getRow(startIndex + 1);
        for(let i = 1; i <= maxProblemType; i++) {
            headers = [
                ...headers,
                `No.${i} ${'Problem Type'.translate()}`,
                `No.${i} ${'Ticket Qty'.translate()}`,
                `No.${i} ${'NPT (min)'.translate()}`,
            ];
        }
        sheet.insertRow(startIndex + 1, headers);
        headers.forEach((hd, index) => {
            headerRow.getCell(index + 1).fill = backgroundFill;
            sheet.getColumn(index + 1).width = 20;
        })
    },

    renderBody: (sheet, startIndex, data) => {
        let maxProblemsCount = 0;
        data.forEach((item, index) => {
    
            let columns = [
                item.lineName || 'N/A'.translate(),
                item.countChangeoverTickets,
                item.machinePrepared,
                item.countTickets,
                formatNumber(item.totalNptTime)
            ];
            item.problemTypes.forEach((pt, index) => {
                columns = [
                    ...columns,
                    pt.problemType,
                    parseInt(pt.count),
                    formatNumber(pt.nptTime),
                ];
            });
            
            // Find max problem counts to render headers
            if(item.problemTypes.length > maxProblemsCount) {
                maxProblemsCount = item.problemTypes.length;
            }
    
            sheet.insertRow(startIndex + 1 + index, columns);
        })
    
        return maxProblemsCount;
    },
};

const OverallMechanicPerformance = {
    setupSheet: (sheet, headerLength, data) => {
        OverallMechanicPerformance.renderHeaders(sheet, headerLength + 1);
        OverallMechanicPerformance.renderBody(sheet, headerLength + 2, data);
    },
    renderHeaders: (sheet, startIndex) => {
        let headers = [
            'Date'.translate(), 
            'Active Mechanic Qty'.translate(), 
            'Idle Mechanic Qty'.translate(), 
            'Closed CO Ticket Qty'.translate(),
            'Machine Prepared Qty'.translate(),
            'Completed Maintenance Ticket Qty'.translate(),
            'Maintenance Total Working Time (min)'.translate(),
            'Maintenance Average Working Time (min)'.translate(),
            'Closed Repair Ticket Qty'.translate(),
            'Repair Ticket Total Response Time (min)'.translate(),
            'Repair Ticket Average Response Time (min)'.translate(),
            'Repair Ticket Total Repair Time (min)'.translate(),
            'Repair Ticket Average Repair Time (min)'.translate(),
        ];
        sheet.insertRow(startIndex + 1, headers);
        const headerRow = sheet.getRow(startIndex + 1);
        headers.forEach((hd, index) => {
            headerRow.getCell(index + 1).fill = backgroundFill;
            sheet.getColumn(index + 1).width = 35;
        })
    },

    renderBody: (sheet, startIndex, data) => {
        data.forEach(({
            label,
            activeMechanics,
            idleMechanics,
            countCoReqTickets,
            machinePrepared,
            countMaintenanceTickets,
            totalWorkingTime,
            avgWorkingTime,
            countRepairTickets,
            avgRepairTicketResponseTime,
            totalRepairTicketRepairTime,
            totalRepairTicketResponseTime,
            avgRepairTicketRepairTime,
        }, index) => {
            let columns = [
                label,
                activeMechanics,
                idleMechanics,
                countCoReqTickets,
                machinePrepared,
                countMaintenanceTickets,
                formatNumber(totalWorkingTime),
                formatNumber(avgWorkingTime),
                countRepairTickets,
                formatNumber(totalRepairTicketResponseTime),
                formatNumber(avgRepairTicketResponseTime),
                formatNumber(totalRepairTicketRepairTime),
                formatNumber(avgRepairTicketRepairTime),
            ];
            sheet.insertRow(startIndex + 1 + index, columns);
        });
    },
};

const IndividualMechanicPerformance = {
    setupSheet: (sheet, headerLength, data) => {
        IndividualMechanicPerformance.renderHeaders(sheet, headerLength + 1);
        IndividualMechanicPerformance.renderBody(sheet, headerLength + 2, data);
    },
    renderHeaders: (sheet, startIndex) => {
        let headers = [
            'Mechanic Name'.translate(),
            'Total Ticket Qty'.translate(),
            'Total Ticket Handling Time (min)'.translate(),
            'Average Ticket Handling Time (min)'.translate(),
            'Closed Changeover Ticket Qty',
            'Total Changeover Handling Time (min)'.translate(),
            'Average Changeover Handling Time (min)'.translate(),
            'Completed Maintenance Ticket Qty'.translate(),
            'Total Maintenance Handling Time (min)'.translate(),
            'Average Maintenance Handling Time (min)'.translate(),
            'Closed Repair Ticket Qty'.translate(),
            'Re-opened Times'.translate(),
            'Closed Repair Ticket Re-open%'.translate(),
            'Total Repair Response Time (min)'.translate(),
            'Average Repair Response Time (min)'.translate(),
            'Total Repair Time (min)'.translate(),
            'Average Repair Time (min)'.translate(),
        ];
        sheet.insertRow(startIndex + 1, headers);
        const headerRow = sheet.getRow(startIndex + 1);
        headers.forEach((hd, index) => {
            headerRow.getCell(index + 1).fill = backgroundFill;
            sheet.getColumn(index + 1).width = 35;
        })
    },

    renderBody: (sheet, startIndex, data) => {
        data.forEach(({
            mechanic,
            totalTicketQty,
            totalTicketHandlingTime,
            averageTicketHandlingTime,
            closedChangeoverTickets,
            totalChangeoverHandlingTime,
            averageChangeoverHandlingTime,
            completedMaintenanceTickets,
            totalMaintenanceHandlingTime,
            averageMaintenanceHandlingTime,
            closedRepairTicketQty,
            reopenedTimes,
            closedRepairTicketReopenPercentage,
            totalRepairResponseTime,
            averageRepairResponseTime,
            totalRepairTime,
            averageRepairTime,
        }, index) => {
            let columns = [
                mechanic,
                totalTicketQty,
                formatNumber(totalTicketHandlingTime),
                formatNumber(averageTicketHandlingTime),
                closedChangeoverTickets,
                formatNumber(totalChangeoverHandlingTime),
                formatNumber(averageChangeoverHandlingTime),
                completedMaintenanceTickets,
                formatNumber(totalMaintenanceHandlingTime),
                formatNumber(averageMaintenanceHandlingTime),
                closedRepairTicketQty,
                reopenedTimes,
                closedRepairTicketReopenPercentage,
                formatNumber(totalRepairResponseTime),
                formatNumber(averageRepairResponseTime),
                formatNumber(totalRepairTime),
                formatNumber(averageRepairTime),
            ];
            sheet.insertRow(startIndex + 1 + index, columns);
        });
    },
};

const MachineBreakdown = {
    setupSheet: (sheet, headerLength, data) => {
        MachineBreakdown.renderHeaders(sheet, headerLength + 1, [data.name, data.code]);
        MachineBreakdown.renderBody(sheet, headerLength + 2, data.items);
    },
    renderHeaders: (sheet, startIndex, preHeaders) => {
        let headers = [
            ...preHeaders,
            'Total Closed Repair Ticket Qty'.translate(),
            'Total Closed Repair Ticket %'.translate(),
            'Maintenance Overdue Ticket Qty'.translate(),
            'Maintenance Overdue %'.translate(),
            'Average Machine Downtime (min)'.translate(),
            'Total Machine Downtime (min)'.translate(),
            'Total Machine Downtime %'.translate(),
        ];
        sheet.insertRow(startIndex + 1, headers);
        const headerRow = sheet.getRow(startIndex + 1);
        headers.forEach((hd, index) => {
            headerRow.getCell(index + 1).fill = backgroundFill;
            sheet.getColumn(index + 1).width = 35;
        })
    },

    renderBody: (sheet, startIndex, items) => {
        let overAllTotalMachineDownTime = 0;
        let overAllTotalTickets = 0;
        let overAllTotalTicketsPercentage = 0;
        let overAllMaintenanceOverdueTicketQty = 0;
        let overMaintenanceOverduePercentage = 0;
        let overAllTotalMachineDownTimeTotal = 0;
        let overAllTotalMachineDownTimePercentage = 0;

        const overallTicketQty = items.reduce((sum, value) => (sum += value.totalTickets), 0);
        items.forEach(({ totalMachine }) => overAllTotalMachineDownTime += totalMachine.reduce((sum, value) => (sum += value.totalTime), 0));

        items.forEach(({
            name,
            code,
            totalTickets,
            totalOverdueTickets,
            totalMachine,
        }, index) => {
            const totalMachineDownTime = totalMachine.reduce((sum, value) => (sum += value.totalTime), 0)

            overAllTotalTickets += totalTickets;
            overAllTotalTicketsPercentage += (totalTickets / overallTicketQty) * 100;
            overAllMaintenanceOverdueTicketQty += totalOverdueTickets;
            overMaintenanceOverduePercentage += (totalOverdueTickets / overallTicketQty) * 100;
            overAllTotalMachineDownTimeTotal += totalMachineDownTime;
            overAllTotalMachineDownTimePercentage += ((totalMachineDownTime / overAllTotalMachineDownTime) * 100);
            
            let columns = [
                name,
                code,
                totalTickets,
                `${formatNumber((totalTickets / overallTicketQty) * 100)}%`,
                totalOverdueTickets,
                `${formatNumber((totalOverdueTickets / overallTicketQty) * 100)}%`,
                formatNumber(totalMachineDownTime / totalTickets),
                formatNumber(totalMachineDownTime),
                `${formatNumber((totalMachineDownTime / overAllTotalMachineDownTime) * 100)}%`,
            ];
            sheet.insertRow(startIndex + 1 + index, columns);
        });
        sheet.insertRow(startIndex + items.length + 1, [
            'All Problem Types'.translate(),
            'N/A'.translate(),
            overAllTotalTickets,
            `${formatNumber(overAllTotalTicketsPercentage)}%`,
            overAllMaintenanceOverdueTicketQty,
            `${formatNumber(overMaintenanceOverduePercentage)}%`,
            formatNumber(overAllTotalMachineDownTimeTotal / overAllTotalTickets),
            formatNumber(overAllTotalMachineDownTimeTotal),
            `${formatNumber(overAllTotalMachineDownTimePercentage)}%`,
        ]);
    },
};

export const ANALYTIC_REPORT_TYPES = {
    LineNpt: 'Line Npt',
    OverallMechanicPerformance: 'Overall Mechanic Performance',
    IndividualMechanicPerformance: 'Individual Mechanic Performance',
    MachineBreakdown: 'Machine Breakdown Analysis',
}

export const downloadFile = async (title, workbook) => {
    // write to a stream
    const buffer = await workbook.xlsx.writeBuffer();
    
    let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const anchor = document.createElement('a');
    const url = URL.createObjectURL(blob);
    anchor.href = url;
    anchor.download = title + '.xlsx';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    URL.revokeObjectURL(url);
}

export const downloadExcel = async (title, headers, data) => {
    const workbook = new ExcelJS.Workbook();
    // create a sheet with red tab colour
    const sheet = workbook.addWorksheet('My Sheet');

    // Headers
    sheet.insertRow(1, headers);

    headers.forEach((hd, index) => {
        sheet.getColumn(index + 1).width = headers[index].length + 2;
    })

    // Body
    for(let i = 0; i < data.length; i++) {
        sheet.insertRow(i + 2, data[i]);
    }
    
    // write to a stream
    downloadFile(title, workbook);
}

export const downloadAnalyticReport = async (type, data) => {
    const workbook = new ExcelJS.Workbook();
    const reportName = getTitleByType(type);
    
    if(type === ANALYTIC_REPORT_TYPES.LineNpt) {
        LineNpt.setupSheet(
            getSheetByType(workbook, reportName, reportName), 
            5, 
            data
        );
    } else if(type === ANALYTIC_REPORT_TYPES.OverallMechanicPerformance) {
        OverallMechanicPerformance.setupSheet(
            getSheetByType(workbook, reportName, reportName), 
            5, 
            data
        );
    } else if(type === ANALYTIC_REPORT_TYPES.IndividualMechanicPerformance) {
        IndividualMechanicPerformance.setupSheet(
            getSheetByType(workbook, reportName, reportName), 
            5, 
            data
        );
    } else if(type === ANALYTIC_REPORT_TYPES.MachineBreakdown) {
        data.forEach((items, index) => {
            const type = viewsByFilters[index];
            const code = type + ' Code';
            const name = type + ' Name';
            MachineBreakdown.setupSheet(
                getSheetByType(workbook, reportName, type), 
                5, 
                {
                    code: code.translate(),
                    name: name.translate(),
                    items: items,
                }
            );
        });
    }
    
    // write to a stream
    downloadFile(
        moment().format('DD-MM-YYYY') + ' LTm - ' + reportName,
        workbook,
    );
}

const getSheetByType = (workbook, reportName, sheetName) => {
    const dateFilter = document.getElementById('input-date-picker');
    const dataFilter = getDataFilter();

    const headerTitles = [
        {
            tag: 'Report Name'.translate(),
            value: reportName,
        },
        {
            tag: 'Data Filter'.translate(),
            value: dataFilter,
        },
        {
            tag: 'Date Range'.translate(),
            value: dateFilter.value,
        },
        {
            tag: 'Exported Time'.translate(),
            value: moment().format('hh:mm'),
        },
        {
            tag: 'Exported by'.translate(),
            value: getUsername(),
        },
    ];

    const sheet = workbook.addWorksheet(sheetName);
    headerTitles.forEach((item, index) => {
        sheet.insertRow(index + 1, [item.tag, item.value]);
        sheet.getCell('A' + (index + 1)).fill = backgroundFill;
    });

    return sheet;
}