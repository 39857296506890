import {
    ROLE_LIST_REQUEST,
    ROLE_LIST_SUCCESS,
    ROLE_LIST_ERROR,
    ROLE_TREE_VIEW_REQUEST,
    ROLE_TREE_VIEW_SUCCESS,
    ROLE_TREE_VIEW_ERROR,
    ROLE_DETAIL_REQUEST,
    ROLE_DETAIL_SUCCESS,
    ROLE_DETAIL_ERROR,
    ROLE_NEW_REQUEST,
    ROLE_NEW_SUCCESS,
    ROLE_NEW_ERROR,
    ROLE_UPDATE_REQUEST,
    ROLE_UPDATE_SUCCESS,
    ROLE_UPDATE_ERROR,
    ROLE_TRIGGER_HIGHLIGHT,
    ROLE_LIST_FACTORIES,
    ROLE_LIST,
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    detailLoading: false,
    triggerHighlight: false,
    roleListTreeView: [],
    roleList: [],
    roleDetail: null,
    roleProfile: null,
    meta: [],
    error: ''
}

const roleReducer = (state = initialState, action) => {
    switch (action.type) {
        case ROLE_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ROLE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                roleList: action.payload.roleList,
                roleDetail: null,
                error: ''
            }
        case ROLE_TREE_VIEW_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ROLE_TREE_VIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                roleListTreeView: action.payload.roleListTreeView,
                roleListTreeViewTimeStamp: action.payload.roleListTreeViewTimeStamp,
                roleDetail: null,
                error: ''
            }
        case ROLE_LIST_ERROR:
            return {
                ...state,
                loading: false,
                roleList: [],
                roleDetail: null,
                meta: [],
                error: action.payload.message
            }
        case ROLE_DETAIL_REQUEST:
            return {
                ...state,
                detailLoading: true,
                roleDetail: null,
            }
        case ROLE_DETAIL_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                roleDetail: action.payload.roleDetail,
            }
        case ROLE_TRIGGER_HIGHLIGHT:
            return {
                ...state,
                triggerHighlight: action.payload
            }
        case ROLE_LIST_FACTORIES:
            return {
                ...state,
                ...action.payload
            }
        case ROLE_LIST:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}

export default roleReducer;