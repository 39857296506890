import React, { useState, useEffect } from 'react';
import CardAccordion from './components/CardAccordion';
import BarChart from './components/BarChart';
import ViewBySelect from './components/ViewBySelect';
import { 
  normalizeBarChart, 
  kFormat, 
  normalizeDataTooltip,
  getDateRangeQueryString, 
} from './helpers';
import { client } from './api';
import './styles/styles.scss';
import { tooltips } from './Tooltips';

const options = [
  { value: 'totalMachines', label: 'Total Machines' },
  { value: 'totalTime', label: 'Total Time' },
  { value: 'avgTime', label: 'Average Time' }
]

export default function TotalMachinesRepair(props){
  const [totalMachinesRepair, setTotalMachinesRepair] = useState(null);
  const { 
    originalRows, 
    dictionary, 
    dateRange, 
    filters, 
    viewType, 
    isCron,
  } = props;
  const [viewBy, setViewBy] = useState('totalMachines');

  useEffect(()=> {
    if(isCron) return;
    const params = { 
      filters: getDateRangeQueryString(filters), 
      viewBy, 
      viewType,
    };
    client.get("/analytics/total-machines-repair", { params }).then(({ data }) => {
      const result = data.filter(({ id }) => id !== null);
      setTotalMachinesRepair(result);
    })
  }, [filters, viewBy, viewType]);

  if(!totalMachinesRepair){ return null };
  const lineData = [
    {label: 'Total Machine Downtime', borderColor: '#6D93F7', key: 'total'},
    {label: 'Average Machine Downtime', borderColor: '#EA6FDC', key: 'avg'},
  ];
  const normalizedData = normalizeBarChart(
    totalMachinesRepair, 
    viewBy, 
    "marked", 
    dictionary, 
    dateRange,
    lineData,
  );
  const legends = [
    {title: 'Total Machine Downtime'.translate(), color: '#6D93F7', key: 'totalTime'},
    {title: 'Total Closed Repair Ticket Qty'.translate(), color: '#EA6FDC', key: 'totalMachines'},
    {title: 'Average Machine Downtime'.translate(), color: '#EA6FDC', key: 'avgTime'},
  ];

  const formatXTickValue = (value) => {
    return viewBy !== "totalMachines" ? `${kFormat(value)} min` : `${kFormat(value)}`
  }

  return <CardAccordion
    title={"Total Machine Repair".translate()}
    legends={legends}
    filterForm={
      <ViewBySelect value={viewBy} label={"View By".translate()} onChange={(e) => setViewBy(e.target.value)} options={options}/>
    }
  >
    <BarChart 
      xTickFormat={(value, index)=> formatXTickValue(value)}
      optionTooltip={tooltips(
        normalizeDataTooltip(
          totalMachinesRepair, 
          dateRange, 
          'marked', 
          originalRows, 
          viewBy,
        ), 
        legends, 
        viewBy, 
        viewType,
        filters.dateRange,
        'Total Machine Repair'.translate(),
      )}
      data={normalizedData}/>
  </CardAccordion>
}