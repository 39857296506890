import React from 'react';
import { TableBody as Body, TableRow } from '@material-ui/core';
import { 
    Col, 
    ProblemBox, 
    ProblemBoxContainer, 
    ProblemBoxItem, 
} from '../styles';

export default function TableBodyEmpty() {
return (
    <Body>
        <TableRow>
            <Col 
                align="center" 
                fontWeight="bold" 
                padding="30px" 
                background="#eee" 
                colSpan={7}>
                    {'No records found'.translate()}
            </Col>
        </TableRow>
    </Body>
)
}