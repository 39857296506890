import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { withRouter } from 'react-router-dom';
import { routerMiddleware } from 'react-router-redux';
import rootReducer from './reducers';

const enhancers = [];
const middleware = routerMiddleware(withRouter);

let composedEnhancers  = compose(
    applyMiddleware(thunk, middleware),
    ...enhancers
);

/* CREATE STORE */
const store = createStore(
    rootReducer,
    composedEnhancers
);

export default store;