export const tableHeaders = [
    { name: '#', orderBy: 'ordering', order: false },
    { name: 'Machine No', orderBy: 'machineNo', order: false },
    { name: 'M/C Sub-type', orderBy: 'machineSubType', order: false },
    { name: 'Repair TKT', orderBy: 'repairTk', order: false },
    { name: 'TTL NPT MIN', orderBy: 'TTL', order: false },
    { name: 'MAINT. TKT', orderBy: 'maintTkt', order: false },
    { name: 'MAINT. OD%', orderBy: 'mainOd', order: false },
    { name: 'MAINT. Freq.', orderBy: 'maintFreq', order: false },
    { name: 'CURRENT LOCATION', orderBy: 'currentLocation', order: false },
    { name: 'Inventory Days', orderBy: 'inventoryDays', order: false },
    { name: 'Machine Created Days', orderBy: 'machineCreatedDt', order: false},
    { name: 'WORKING DAYS', orderBy: 'workingDays', order: false },
    { name: 'STATUS', orderBy: 'status', order: false },
    { name: 'USAGE%', orderBy: 'usage', order: true },
];