import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from "react-redux";
import { postAssignChecklistToMachine } from '../../../../redux/actions/Checklist/checklistActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import MachineList from './List'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { checkIfApiLoading, checkPageActionAccess,checkPageAccess } from '../../../../utils/helper';
import './style.scss';

  const theme = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiDialog: {
        // Name of the rule
        paperWidthSm: {
          // Some CSS
          maxWidth: '816px',
          padding: '12px 14px',
          overflowX: 'hidden',
        },
        paper: {
          backgroundColor: '#1c3a50',
        }
      },
    },
  });

  const dialogContent = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiDialogContent: {
        // Name of the rule
        root: {
          // Some CSS
          padding: '0px',
        },
      },
    },
  });

  const dialogButtons = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiDialogActions: {
        // Name of the rule
        root: {
          // Some CSS
          display: 'block',
        },
      },
    },
  });

  const title = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiTypography: {
        // Name of the rule
        root: {
          // Some CSS
          color: 'white',
        },
        h6: {
          fontWeight: 'bold',
        },
      },
    },
  });

  const muiButtons = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiButton: {
        // Name of the rule
        root: {
          // Some CSS
          color: '#fafafa',
          fontWeight: 'bold',
          backgroundColor: '#0F75BC',
          fontSize: '1.175rem',
          '&:hover': {
            backgroundColor: '#0e598e',
            color: '#FFF'
        }
        },
      },
    },
  });

  const muiAlert = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiAlert: {
        // Name of the rule
        root: {
          // Some CSS
          fontSize:'1.3rem',
        },
      },
      MuiSvgIcon: {
        root: {
          fontSize:'1.3rem',
        }
      }
    },
  });

function areEqual(prevProps, nextProps) {
    return false
  }

  export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const[alertMessage, setAlertMessage] = React.useState('');
  const [machine, setMachine] = React.useState(null);
  const checkedId = props.checkedId
  const selectedTemplateType = props.selectedTemplateType
  const checklistStatus = props.checklistStatus
  const userAccess = JSON.parse(localStorage.getItem('userAccess'));
  const machineListChecklist = props.machineListChecklist

  const handleClickOpen = () => {
    console.log('check length of machine')
    console.log(machineListChecklist.length)
    if(checkedId === null){
      setAlertMessage('Please select a checklist')
      setAlertOpen(true)
    }else if (machineListChecklist.length < 1){
      setAlertMessage('No machines are available')
      setAlertOpen(true)
    }
    else{
      console.log('opening of modal')
      console.log(checkedId)
      console.log(selectedTemplateType)
      setMachine(null)
      setOpen(true);
    }
  };


 const handleAttachMachine = () => {
   if(machine === null){
    setAlertMessage('Please select a machine')
    setAlertOpen(true)
   }else{
    console.log('you selected a machine number ' + machine)
    let payload = {
      id:checkedId,
      machineId:machine
    }
    props.postAssignChecklistToMachine(payload)
    setOpen(false);
   }
  }

  const settingMachine = (machine) => {
    console.log('setting machine ' + machine)
    setMachine(machine)
  }

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div>
      <Button style={{color:checklistStatus === "DRAFT" || checklistStatus === 'INACTIVE' || props.buttonDisabled ? '#AAAAAA' : '#0F75BC', padding:props.padding}} disabled={checklistStatus === "DRAFT" || checklistStatus === 'INACTIVE' || props.buttonDisabled ? true: false} onClick={checklistStatus === "DRAFT" || checklistStatus === 'INACTIVE' || props.buttonDisabled ? null : handleClickOpen} fullWidth variant="outlined" color={checklistStatus === "DRAFT" || checklistStatus === 'INACTIVE' || props.buttonDisabled ? "disabled" : "primary"}><span style={{color: checklistStatus === "DRAFT" || checklistStatus === 'INACTIVE' || props.buttonDisabled ? '#AAAAAA' : '#0F75BC'}}>{'ASSIGN TO MACHINE'.translate()}</span></Button>
      <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <ThemeProvider theme={title}>
      <Grid container
      direction="row"
      justify="flex-end"
      alignItems="center" >
      <Grid item xs={10}>
      <DialogTitle id="form-dialog-title" style={{
        padding: '16px 65px',
      }} align="right">{'MACHINE LIST'.translate()}</DialogTitle>
      </Grid>
      <Grid item xs={2}>
        <Button style={{color: 'white',
          fontSize: 'large',
          fontWeight: 'bold',
          bottom: '14px',
          left: '10px',}}
          onClick={handleClose}>X</Button>
      </Grid>
      </Grid>
        </ThemeProvider>
        <ThemeProvider theme={dialogContent}>
        <DialogContent>
        <MachineList machineListChecklist={machineListChecklist} onSelectMachine={settingMachine} checkedId={checkedId} selectedTemplateType={selectedTemplateType}/>
        </DialogContent>
        </ThemeProvider>
        <ThemeProvider theme={dialogButtons}>
        <DialogActions align="center" >
        <ThemeProvider theme={muiButtons}>
          <Button onClick={handleAttachMachine}>
            {'SELECT'.translate()}
          </Button>
        </ThemeProvider>
        </DialogActions>
        </ThemeProvider>
        </Dialog>
        
        <ThemeProvider theme={muiAlert}>
          <Snackbar
            anchorOrigin={{vertical:'top',horizontal:'right'}}
            autoHideDuration={3000}
            open={ alertOpen }
            onClose={ (event,reason) => { if(reason !== 'clickaway'){ setAlertOpen(false) } } }
          >
            <Alert onClose={ (event,reason) => { if(reason !== 'clickaway'){ setAlertOpen(false) } } } variant="filled" severity={ 'error' }>
              { alertMessage.toString().translate() }
            </Alert>
          </Snackbar>
        </ThemeProvider>
      </ThemeProvider>
    </div>
  );
}
