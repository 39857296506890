import { TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  root: {
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    padding: "10px 5px",
    fontSize: 14,
    fontWeight: 'normal',
  },
}))(TableCell);
