import React from 'react';
import { Box, Typography, withStyles } from '@material-ui/core';
import LineChart from '../../../../assets/icons/LineChart';

export const IndicatorContainer = withStyles({
    root: {
        display: 'flex',
        justifyContent: 'space-around',
        marginLeft: 10,
        marginRight: 10,
        alignItems: 'center',
        gap: 10,
    },
})(Box);

export const FlexBox = withStyles({
    root: (props) => ({
        display: 'flex',
        justifyContent: props.justifyContent || 'space-between',
        alignItems: 'center',
        gap: props.gap,
    }),
})((props) => <Box {...props} />);

export const IndicatorChartLine = withStyles()((props) => (
    <FlexBox>
        {props.legends?.map(({title, color}) => (
            <IndicatorContainer>
                <LineChart color={color} />
                <Typography>{title}</Typography>
            </IndicatorContainer>
        ))}
    </FlexBox>
));