import React from 'react';
import moment from 'moment';
import { setters, checkPageAccess, checkPageActionAccess } from '../../../utils/helper';
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'
import ReactExport from 'react-export-excel';
import { checkIfApiLoading } from '../../../utils/helper';
import { notificationSuccess, notificationError } from '../../../redux/actions/Notification/notificationActions';
import { getMachineUpBySearch, ticketMachineUpUpdatePage, postMaintenanceTicket } from '../../../redux/actions/Tickets/ticketActions';
import { connect } from "react-redux";
import MainPage from '../../../components/MainPage/MainPage';
import MainPageHeader from '../../../components/MainPageHeader/MainPageHeader';
import MainPageBody from '../../../components/MainPageBody/MainPageBody';
import MainTable from '../../../components/MainTable/MainTable';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import './Maintenance.scss';
import Filters from './Filters';

class Maintenance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tooltipTheme: createMuiTheme({
                overrides: {
                    MuiTooltip: {
                        tooltip: {
                            fontSize: "1rem",
                        }
                    }
                }
            }),
            filterStatus: 0,
            dateNow: moment(),
            tableHeader: [
                {
                    value: 'MACHINE NO',
                    size: '110px'
                },
                {
                    value: 'TICKET NO',
                    size: '110px'
                },
                {
                    value: 'LOCATION',
                    size: '110px'
                },
                {
                    value: 'BUILDING',
                    size: '110px'
                },
                {
                    value: 'MODEL NO',
                    size: '70px'
                },
                {
                    value: 'TYPE',
                    size: '70px'
                },
                {
                    value: 'SUB TYPE',
                    size: '80px'
                },
                {
                    value: 'BRAND',
                    size: '100px'
                },
                {
                    value: 'MAIN FREQ',
                    size: '110px'
                },
                {
                    value: 'CHECKLIST NO.',
                    size: '150px'
                },
                {
                    value: 'PM PLANNED',
                    size: '100px'
                },
                {
                    value: 'LAST USED',
                    size: '100px'
                },
                {
                    value: 'STATUS',
                    size: '100px'
                },
            ],
            pmPlanDt: '',
            searchString: '',
            machineUpList: [],
            initialRender: true,
            originalCount: {
                overdue: 0,
                today: 0,
                week: 0,
                month: 0,
            },
            maintenanceWillLoad: false,
            localWillLoad: false,
            currentPage: 0,
            ticketSubmition: false,
            cameFromSearch: false,
            languageProps: {
                ...this.changeLang()
            },
            translationTimestamp: undefined,
        };

    }
    changeLang = () => {
        return {
            YES: 'Yes'.translate(),
            NO: 'No'.translate(),
            PROBLEM: 'Problem'.translate(),
            SOLUTION: 'Solution'.translate(),
            REMARKS: 'Remarks'.translate(),
            MACHINE_NO: 'Machine No'.translate(),
            MACHINE_TYPE: 'Machine Type'.translate(),
            MACHINE_SUBTYPE: 'Machine Subtype'.translate(),
            GRABBED_BY: 'Grabbed By'.translate(),
            GRABBED_ON: 'Grabbed on'.translate(),
            GRABBED_ON_TIME: 'Grabbed on time'.translate(),
            BRAND: 'Brand'.translate(),
            MODEL_NO: 'Model No'.translate(),
            MOTOR_TYPE: 'Motor Type'.translate(),
            REMARKS: 'Remarks'.translate(),
            REPAIRED_BY: 'Repaired By'.translate(),
            REPAIRED_ON: 'Repaired on'.translate(),
            REPAIRED_ON_TIME: 'Repaired on time'.translate(),
            REPORTED_BY: 'Reported By'.translate(),
            REPORTED_ON: 'Reported On'.translate(),
            REPORTED_ON_TIME: 'Reported on time'.translate(),
            CLOSED: 'Closed'.translate(),
            CLOSED_BY: 'Closed By'.translate(),
            CLOSED_ON: 'Closed on'.translate(),
            CLOSED_ON_TIME: 'Closed on time'.translate(),
            VIEW_CHECKLIST: 'View Checklist'.translate(),
            CHECKLIST_NO: 'Checklist No'.translate(),
            MAINTENANCE_DATE: 'Maintenance Date'.translate(),
            MAINTENANCE_TYPE: 'Maintenance Type'.translate(),
            MAINTENANCE_SUB_TYPE: 'Maintenance Sub Type'.translate(),
            ACTION_TAKEN_BY: 'Action taken by'.translate(),
            ACTION_TEKAN_ON: 'Action taken on'.translate(),
            ACTION_TAKEN_ON_TIME: 'Action taken on time'.translate(),
            GENERATE_BY: 'Generated by'.translate(),
            GENERATE_ON: 'Generated on'.translate(),
            GENERATE_ON_TIME: 'Generated on time'.translate(),
            SELECT_MACHINE: 'SELECT MACHINE'.translate(),
            SELECT: 'SELECT'.translate(),
            ALL: 'ALL'.translate(),
            REPAIR: 'REPAIR'.translate(),
            MAINTENANCE: 'MAINTENANCE'.translate(),
            ACTIVE: 'ACTIVE'.translate(),
            INACTIVE: 'INACTIVE'.translate(),
            TODAY: 'TODAY'.translate(),
            THIS_WEEK: 'THIS WEEK'.translate(),
            THIS_MONTH: 'THIS MONTH'.translate(),
            EXPORT_TO_EXCEL: 'EXPORT TO EXCEL'.translate(),
            CUSTOMIZE_RANGE: 'CUSTOMIZE RANGE'.translate(),
            NOTIFICATIONS: {
                this_ticket_cannot_be_cancelled: 'This ticket cannot be cancelled'.translate(),
                there_are: 'There are'.translate(),
                tickets_that_cannot_be_cancelled: ' tickets that cannot be cancelled'.translate(),
                are_you_sure: 'Are you sure you want to cancel ticket(s)'.translate(),
                confirming_would_cancel_selected_tickets: 'Confirming would cancel selected tickets'.translate(),
            },

            TYPE: 'Type'.translate(),
            CANCEL_TICKET: 'CANCEL TICKET'.translate(),
            START_REPAIR: 'START REPAIR'.translate(),
            REPAIRED: 'REPAIRED'.translate(),
            CLOSE: 'CLOSE'.translate(),
            SUPPLIER: 'SUPPLIER'.translate(),
            DATE_OF_PURCHASE: 'Date of Purchase'.translate(),
            RENTAL: 'Rental'.translate(),
            LOCATION: 'Location'.translate(),
            ATTACHMENT: 'Attachment'.translate(),
            SERIAL_NO: 'Serial No'.translate(),
            ASSET_NO: 'Asset No'.translate(),
            RFID: 'RFID'.translate(),
            CONDITION: 'Condition'.translate(),
            STATUS: 'Status'.translate(),
            MAINTENANCE_FREQ: 'Maintenance Freq'.translate(),
            FILE_SUCCESSFULLY_EXPORTED: 'File successfully exported'.translate(),
            ATTACHMENT: 'Attachment'.translate(),
            LAST_MAINTENANCE: 'Last Maintenance'.translate(),
            NEXT_MAINTENANCE: 'Next Maintenance'.translate(),
            MAINTENANCE_STATUS: 'Maintenance Status'.translate(),
            GENERATE_TICKET: 'GENERATE TICKET'.translate(),
            OVERDUE: 'OVERDUE'.translate()
        }
    }
    checkIfApiLoading() {
        let flag = checkIfApiLoading([
            this.props.loadingMachineUp,
        ]);
        return flag;
    }

    renderText(field) {
        if (field === null || field === '') {
            return '-'
        }
        else {
            return field != undefined ? field.toString().translate() : field
        }
    }

    resetRedirects() {
        localStorage.setItem('redirectToMaintenance', false);
        localStorage.setItem('redirectToTickets', false);
        localStorage.setItem('redirectToCalendar', false);
    }

    componentDidMount() {
        if (localStorage['fromCalendar']) {
            delete localStorage['fromCalendar']
            delete localStorage['dateInstance']
        }
        if (localStorage.getItem('redirectToMaintenance') === 'true') {
            this.resetRedirects();
            let value = this.props.machineUpFilter.pmPlanDt;
            if (value == undefined) {
                value = 'today'
            } else {
                value = this.props.machineUpFilter.pmPlanDt;
            }
            let searchString = this.state.searchString
            let currentPage = this.props.currentPage;
            this.props.getMachineUpBySearch(10, this.props.meta.currentPage, searchString, value);
            this.setState({ maintenanceWillLoad: true, initialRender: false, currentPage: currentPage })
        }
        else {
            let value = this.props.machineUpFilter.pmPlanDt;
            if (value == undefined) {
                value = 'today'
            } else {
                value = this.props.machineUpFilter.pmPlanDt;
            }
            this.props.getMachineUpBySearch(10, 1, 'all', value);
            this.setState({ maintenanceWillLoad: true })
        }
    }

    componentWillReceiveProps(nextProps) {
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    componentWillUpdate(nextProps, nextState) {
        console.log('component will update')
        console.log(nextProps)
        console.log(nextState)
        this.props.ticketMachineUpUpdatePage(nextState.currentPage);
        if (nextProps.loadingMachineUp === false) {
            if (nextState.initialRender === true) {
                this.setState({
                    originalCount: {
                        overdue: nextProps.machineUpCount.overdue,
                        today: nextProps.machineUpCount.today,
                        week: nextProps.machineUpCount.week,
                        month: nextProps.machineUpCount.month,
                    },
                    machineUpList: nextProps.machineUpList,
                    initialRender: false,
                })
            } else {
                if (nextState.maintenanceWillLoad === true) {
                    if (nextProps.machineUpList !== nextState.machineUpList) {
                        this.setState({
                            machineUpList: nextProps.machineUpList,
                            maintenanceWillLoad: false,
                        })
                    }
                }
                if (nextState.localWillLoad === true) {
                    this.setState({
                        currentPage: 0,
                        machineUpList: nextProps.machineUpList,
                        localWillLoad: false,
                    })
                }
                if (nextState.cameFromSearch === true) {
                    this.setState({
                        currentPage: 0,
                        machineUpList: nextProps.machineUpList,
                        cameFromSearch: false,
                    })
                }
            }
        }
        if (nextProps.machineUpList !== nextState.machineUpList) {
            this.setState({
                machineUpList: nextProps.machineUpList
            })
        }
        if (nextProps.postTicketLoading === false) {
            if (nextState.ticketSubmition === true) {
                let value = this.props.machineUpFilter.pmPlanDt;
                if (value == undefined) {
                    value = 'today'
                } else {
                    value = this.props.machineUpFilter.pmPlanDt;
                }
                this.props.getMachineUpBySearch(10, this.props.meta.currentPage, this.state.searchString, value);
                this.setState({
                    ticketSubmition: false,
                    maintenanceWillLoad: true,
                    currentPage: nextProps.currentPage,
                })
            }
        }

    }


    componentDidUpdate() {
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                languageProps: {
                    ...this.changeLang
                }
            })
        }
    }

    componentWillUnmount() {

    }

    renderDate(date) {
        if (date !== null) {
            let newDate = moment(date).format('DD-MM-YYYY');
            return newDate
        }
    }

    handleSortTableData(data) {
        let sortedData = data.sort((a, b) => {
            let date1 = new Date(a.pmPlanDt)
            let date2 = new Date(b.pmPlanDt)
            let result = date1 - date2
            if (result === 0) {
                result = a.selected.id - b.selected.id;
            }
            return result
        })
        return sortedData
    }

    renderTableData() {
        return (
            <MainTable 
                hiddenColumns={this.state.hiddenColumns} 
                tableName="maintenance" 
                height="100%" 
                onChangePage={this.onChangePage} 
                page={this.state.currentPage} 
                rowsPerPage={10} 
                header={this.state.tableHeader} 
                data={this.state.machineUpList} 
                meta={this.props.meta} 
                link={"/ticket-detail"}
                highlight={this.state.searchString} 
            >

            </MainTable>
        );
    }

    onChangePage = (value) => {
        // this.setState({ currentPage: value })
        // this.props.ticketMachineUpUpdatePage(value);
        this.props.getMachineUpBySearch(10, value, this.state.searchString,this.props.machineUpFilter.pmPlanDt);
    }

    handleFilterChange = (value) => {
        this.props.getMachineUpBySearch(10, 1, this.state.searchString, value);
        this.setState({ localWillLoad: true })
    }

    handleSearchChange = (searchString) => {
        this.props.getMachineUpBySearch(10, this.props.meta.currentPage, searchString, this.props.machineUpFilter.pmPlanDt || 'today');
        this.setState({ searchString });
    }

    submitTicket = () => {
        let data = this.state.machineUpList
        let checkedItems = data.filter(x => x.selected.value === true)
        let machines = { machines: [] }
        for (let i = 0; i < checkedItems.length; i++) {
            let item = checkedItems[i].ticketmachine.linkId
            let post = { id: item }
            machines.machines.push(post)
        }
        this.props.postMaintenanceTicket(machines, data)
        this.setState({ ticketSubmition: true })
    }

    handleSearch = (data) => {
        this.props.getMachineUpBySearch(10, 1, data, this.props.machineUpFilter.pmPlanDt);
        this.setState({ cameFromSearch: true })
    }

    render() {
        return (
            <MainPage className='maintenance-overview'>
                <MainPageHeader 
                    onFilter={(searchString)=> this.handleSearchChange(searchString )}
                    searchLabel="Machines" 
                    searchString={this.state.searchString}
                    name="maintenance" 
                    title="Machines Up for Maintenance" 
                    search={true}
                    originalCount={this.props.machineUpFilter.pmPlanDt === 'overdue' ? this.state.originalCount.overdue : this.props.machineUpFilter.pmPlanDt === 'week' ? this.state.originalCount.week : this.props.machineUpFilter.pmPlanDt === 'month' ? this.state.originalCount.month : this.state.originalCount.today}
                    rows={this.props.meta.count} 
                    displayedRows={this.props.displayedRows} 
                    handleSearch={this.handleSearch} 
                />
                <MainPageBody>
                    <Grid container spacing={2}>
                        <Grid className="actions" item xs={4}>
                            <Grid container spacing={0}>
                                <Grid item xs={5}>
                                    <div className={'action-item ' + (checkPageActionAccess(this.props.userAccess, { pageId: 9, pageAction: 'Generate Ticket' }) === false ? 'disabled' : '')}>
                                        <Link>
                                            <Button disabled={checkPageActionAccess(this.props.userAccess, { pageId: 9, pageAction: 'Generate Ticket' }) === false} fullWidth variant="outlined" onClick={checkPageActionAccess(this.props.userAccess, { pageId: 9, pageAction: 'Generate Ticket' }) ? this.submitTicket : null} color="primary"><span>{'GENERATE TICKET'.translate() || this.state.languageProps.GENERATE_TICKET}</span></Button>
                                        </Link>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid className="filters" item xs={7}>
                            <Filters 
                                machineUpFilter={this.props.machineUpFilter}
                                meta={this.props.meta}
                                machineUpCount={this.props.machineUpCount}
                                onChange={(value) => this.handleFilterChange(value)} 
                            />
                        </Grid>
                    </Grid>
                    {this.renderTableData()}
                </MainPageBody>
            </MainPage>
        );
    }
}

const mapStateToProps = state => {
    let { translationReducer } = state
    let apiLoading = {
        postTicketLoading: state.ticketListReducer.loading,
        loadingMachineUp: state.ticketListReducer.loadingMachineUp,
    }
    let data = {
        userAccess: state.authReducer.access,
        rows: state.ticketListReducer.rows,
        currentPage: state.ticketListReducer.currentPage,
        machineUpList: state.ticketListReducer.machineUpList,
        machineUpFilter: state.ticketListReducer.machineUpFilter,
        machineUpCount: state.ticketListReducer.machineUpCount,
        meta: state.ticketListReducer.meta,
        error: state.ticketListReducer.error,
        displayedRows: state.ticketListReducer.maintenanceDisplayedRows,
    }

    return {
        ...apiLoading,
        ...data,
        ...translationReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        postMaintenanceTicket: (payload, data) => dispatch(postMaintenanceTicket(payload, data)),
        getMachineUpBySearch: (pageSize, currentPage, searchString, pmPlanDt) => dispatch(getMachineUpBySearch(pageSize, currentPage, searchString, pmPlanDt)),
        ticketMachineUpUpdatePage: (page) => dispatch(ticketMachineUpUpdatePage(page)),
    }
}

Maintenance = connect(mapStateToProps, mapDispatchToProps)(Maintenance);

export default Maintenance;
