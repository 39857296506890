import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
} from '@material-ui/core';
import { styles, stepper } from './BulkUpload.style';
import axios from 'axios';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import PublishIcon from '@material-ui/icons/Publish';
import { apiSettings } from '../../../utils/api/CoreApi';

export default function BulkUpload(props) {
  const classes = styles();
  const stepperClass = stepper();
  const { handleClose, openBulkUpload, onFinish } = props;
  const [fileName, setFileName] = React.useState('');
  const [fileData, setFileData] = React.useState('');
  const [apiError, setAPIError] = React.useState('');
  const [validateResponseData, setValidateResponseData] = React.useState('');
  const [firstStepLoader, setFirstStepLoader] = React.useState(false);
  const [secondStepLoader, setSecondStepLoader] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [isTemplateLoading, setTemplateLoading] = React.useState(false);
  const [isMachineLoading, setMachineLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setActiveStep(0);
    setFileName('');
    setFileData('');
    setAPIError('');
    setValidateResponseData('');
    setSkipped(new Set());
  }, [openBulkUpload]);

  const uploadButtonRef = React.useRef(0);

  const droppedFiles = (acceptedFiles, rejectedFiles) => {
    const file = acceptedFiles[0];
    if (
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'text/csv' || 
      file.type === 'application/vnd.ms-excel'
    ) {
      setFileName(file.name);
      setFileData(file);
    }
  };

  const handleDownload = async (url, fileName) => {
    const res = await axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('userToken').replace(/^"(.+)"$/,'$1')}`,
        'language': localStorage.getItem('selectedLanguage'),
      },
      method: 'post',
      url: `${apiSettings.apiURLMachines}${url}`,
      responseType: 'blob'
    })
   
    const blob = new Blob([res.data], {
      type: res.headers['content-type'],
    })

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${fileName}`;
    link.click();
    setMachineLoading(false);
    setTemplateLoading(false);
  }

  const handleDownloadMachineList = async () => {
    handleDownload('/machines/download-machines-list', 'LTm-Latest-Machines-List.xlsx');
  };

  const downloadMachineList = () => {
    setMachineLoading(true);
    handleDownloadMachineList();
  };
  
  const handleDownloadTemplate = async () => {
    handleDownload('/machines/download-machines-template-file', 'LTm-Machines-Bulk-Upload-Template.xlsx');
  };

  const downloadTemplate = () => {
    setTemplateLoading(true);
    handleDownloadTemplate();
  };
  
  const resetInput = () => {
    setFileName('');
    setFileData('');
    handleClose();
  }

  const UploadFileContentStep = () => {
    return (
      <div>
        <div className={classes.downloadText}>
          <span onClick={downloadTemplate}>{'Download TEMPLATE FILE'} {isTemplateLoading && <span className={classes.downloading}>Downloading...</span>}</span>
        </div>
        <div className={classes.downloadText}>
          <span onClick={downloadMachineList}>{'Download Latest Machine list'} {isMachineLoading && <span className={classes.downloading}>Downloading...</span>}</span>
        </div>

        <div>
          <Dropzone onDrop={(acceptedFiles, rejectedFiles) => { droppedFiles(acceptedFiles, rejectedFiles) }} noClick>
            {({ getRootProps, getInputProps }) => (
              <div className={classes.dropImagesContainer} {...getRootProps()}>
                {fileName ? ( <span className={classes.fileStyle}>{fileName}</span> ) : (
                  <>
                    <div className={classes.dropIcon}>
                      <PublishIcon />
                    </div>
                    <div className={classes.dropDescription}>
                      {'Drag Drop Files'}
                    </div>
                    <div className={classes.orBox}>{'or'}</div>
                  </>
                )}
                <input {...getInputProps()} type='file' id='profil' style={{ display: 'none' }} ref={uploadButtonRef}
                  accept='text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  multiple
                />
                {fileName ? (
                  <>
                    {apiError !== '' && (
                      <div className={classes.apiValidateMessage}>
                        {apiError}
                      </div>
                    )}
                    <Button color='primary' size='large' variant='text' className={classes.reselectFile} onClick={() => { uploadButtonRef.current.click(); }}>
                      {'Reselect file'}
                    </Button>
                  </>
                ) : (
                  <Button color='primary' size='large' variant='contained' className={classes.browseButton} onClick={() => { uploadButtonRef.current.click() }}>
                    {'Browse'}
                  </Button>
                )}
              </div>
            )}
          </Dropzone>
          <div className={classes.imageNote}>
            {'Format: .xlsx / .xls or .csv'}
          </div>
          <div className={classes.saveContainer}>
            <Button variant='outlined' size='large' className={classes.cancelButton} onClick={resetInput}>
              {'CANCEL'}
            </Button>
            <Button color='primary' disabled={fileName.length === 0 || loading} size='large' type='submit' variant='contained' className={classes.saveButton} onClick={() => { handleValidateFile() }}>
              {loading ? 'Validating' : 'Validate'}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const ValidateFileContentStep = () => {
    return (
      <div>
        <div className={classes.fileName}>“{fileName}”</div>
        <div className={classes.validateMessage}> {'Will import'} {` ${validateResponseData.dataToInsert.length} `}{' '} {'Machines.'}
          <div>
            {'Including'} {`  ${validateResponseData.dataToUpdate.length} `}
            {'Existing Machines'}
          </div>
          <div>{'Above Information will be updated.'}</div>
        </div>

        <div className={classes.pleaseConfirmText}>{'Please Confirm'}</div>
        <div className={classes.saveContainer}>
          <Button variant='outlined' size='large' className={classes.cancelButton} disabled={!secondStepLoader ? false : true} onClick={() => { handleClose() }}>
            {'CANCEL'}
          </Button>
          <Button color='primary' size='large' type='submit' variant='contained' disabled={secondStepLoader} className={classes.saveButton} onClick={() => { handleBulkUpload() }}>
            {secondStepLoader ? 'Uploading' : 'ok'}
          </Button>
        </div>
      </div>
    );
  };

  const UploadSuccessFileContentStep = () => {
    return (
      <div>
        <div className={classes.filenameUpdated}>{fileName}</div>
        <div className={classes.listUpdated}>{'is uploaded successfully.'}</div>
        <div className={classes.saveContainer}>
          <Button color='primary' size='large' type='submit' variant='contained' className={classes.saveButton} onClick={() => { handleClose(); onFinish() }}>
            {'ok'}
          </Button>
        </div>
      </div>
    );
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <UploadFileContentStep />;
      case 1:
        return <ValidateFileContentStep />;
      case 2:
        return <UploadSuccessFileContentStep />;
      default:
        return <UploadSuccessFileContentStep />;
    }
  }

  const steps = ['Select File', 'Validate File', 'Upload Success'];

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  
  let dataUpload = new FormData();
  let uploadingData = fileData;
  dataUpload.append('machines', uploadingData);
  let dataToSend = dataUpload;

  const handleValidateFile = async () => {
    setLoading(true);
    setAPIError('');
    await axios
      .post(`${apiSettings.apiURLMachines}/machines/validate-bulk-upload`, dataToSend, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/csv',
          Authorization:`Bearer ${localStorage.getItem('userToken').replace(/^"(.+)"$/,'$1')}`,
        },
      })
      .then((res) => {
        setValidateResponseData(res.data.result);
        handleNext();
      })
      .catch((err) => {
        if (err.response.data.message != null || err.response.data.message != undefined){
          setAPIError(err.response.data.message);
        } else {
          setAPIError(err.response.data.errorType);
        }
      })
      .finally(() => { setLoading(false); });
  };

  const handleBulkUpload = async () => {
    setSecondStepLoader(true);
    await axios
      .post(`${apiSettings.apiURLMachines}/machines/bulk-upload`, dataToSend, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('userToken').replace(/^"(.+)"$/,'$1')}`,
        },
      })
      .then((res) => {
        handleNext();
      })
      .catch((err) => console.log(err))
      .finally(() => { setSecondStepLoader(false); });
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby='simple-dialog-title' open={openBulkUpload} fullWidth>
      <DialogContent>
        <Typography className={classes.textStyl}>{'Bulk Upload'}</Typography>
        <div className={classes.root}>
          <Stepper className={stepperClass.root} activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant='caption'></Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {getStepContent(activeStep)}
        </div>
      </DialogContent>
    </Dialog>
  );
}
