import { useState } from 'react';
import { CO_REQUEST_STATUSES } from '../../../constants/corequest';
import { machineClient } from '../../../utils/api/CoreApi';
import { getErrorResponseMessage } from '../../../utils/request';

function useChangeoverActions(props, confirmModalRef) {
  const [loadingAction, setLoading] = useState(false);

  async function confirmClose(selectedItems, onSuccess) {
    //Find corequest that not new
    const itemWithoutNew = selectedItems.find(request => request.statusCode == CO_REQUEST_STATUSES['NEW'] || request.statusCode == CO_REQUEST_STATUSES['IN PROGRESS']);
    if (itemWithoutNew) {
      props.notificationError('Cannot close New/In Progress status Changeover Request'.translate());
      return false;
    }

    confirmModalRef.current.show({
      title: 'Close Changeover Request?'.translate(),
      description: 'Selected Changeover Request is Ready. Are you sure you want to close it?'.translate(),
      mode: 'error',
      positiveButtonText: 'Close'.translate(),
      negativeButtonText: 'Confirm'.translate(),
      handlePositiveButtonClick: () => {
        setLoading(true);
        machineClient
          .post('/co-requests/update/to/close?ids=' + selectedItems.map(request => request.id).join(','))
          .then(({ data }) => {
            props.notificationSuccess(data.message);
            onSuccess();
          }).catch(err => {
            props.notificationError(getErrorResponseMessage(err))
          }).finally(() => setLoading(false));
      }
    });
  }

  async function confirmDelete(selectedItems, onSuccess) {
    //Find corequest that not new
    const itemWithoutNew = selectedItems.find(request => request.statusCode !== CO_REQUEST_STATUSES['NEW']);
    const itemWithIntegration = selectedItems.find(request => request.thirdPartySystem);
    if (itemWithoutNew) {
      props.notificationError('Cannot delete In Progress/Ready/Closed status Changeover Request');
      return false;
    }else if(itemWithIntegration) {
      props.notificationError('Ticket created by LTie system are not allow to delete.');
      return false;
    }

    confirmModalRef.current.show({
      title: 'Delete Changeover Request?'.translate(),
      description: 'The record cannot be recovered.'.translate(),
      mode: 'error',
      positiveButtonText: 'Delete'.translate(),
      negativeButtonText: 'Confirm'.translate(),
      handlePositiveButtonClick: () => {
        setLoading(true);
        machineClient
          .delete('/co-requests', {
            params: {
              ids: selectedItems.map(request => request.id).join(',')
            }
          }).then(({ data }) => {
            props.notificationSuccess(data.message);
            onSuccess();
          }).catch(err => {
            props.notificationError(getErrorResponseMessage(err))
          }).finally(() => setLoading(false));
      }
    });
  }

  return { loadingAction, confirmClose, confirmDelete };
}

export default useChangeoverActions;