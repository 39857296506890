import React, { useState } from 'react';
import { Modal, TextField, Button, Paper, FormControl, CssBaseline } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  actions: {
    paddingTop: 15,
    paddingBottom: 15,
    justifyContent: 'center',
    gap: 30,
    display: "flex",
    marginTop: 30,
  },
  paper: {
    position: 'absolute',
    width: 400,
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    padding: 30,
    '& label': {
      fontSize: '1.2rem!important',
    },
    '& h3': {
      fontSize: '1.8rem!important',
      marginBottom: 10,
    },
    '& p': {
      fontSize: '1.2rem!important',
    },
    '& button': {
      borderRadius: '4px !important',
      minWidth: '100px!important',
      '& span': {
        fontSize: '1.3rem',
        lineHeight: '1.75',
      },
    },
    '& .MuiButton-containedPrimary': {
      backgroundColor: 'rgb(33, 150, 243) !important',
    },
  }
});

const TextInput = withStyles({
  root: {
    width: "100%",
    "& label": {
      zIndex: 9
    }
  }
})(TextField);

export default function CreateTemplateModal(props) {
  const classes = useStyles();
  const { isOpen, onClose, onSubmit, templates } = props;
  const [name, setName] = useState(null);
  const [error, setError] = useState({error: false, message: ''});
  const [isLoading, setIsLoading] = useState(false);

  const onCreate = () => {
    const existName = templates.find(({ templateName }) => templateName === name);
    if(existName){
      setError({
        error: true,
        message: 'Name already exist, please use a new name'.translate(),
      });
      return false;
    }
    if(!name){
      setError({
        error: true,
        message: 'Please enter the template name'.translate(),
      });
      return false;
    }
    if(name.length > 35){
      setError({
        error: true,
        message: 'Maximum character limit is 35'.translate(),
      });
      return false;
    }
    setIsLoading(true);
    onSubmit(name, ()=>{
      setName(null);
      setIsLoading(false);
      onClose();
      setError({error: false, message: ''});
    });
  }

  return <Modal
    open={isOpen}
    onClose={onClose}
  >
    <Paper className={classes.paper}>
      { isLoading ? (
        <h3>Creating a template...</h3>
      ) : (
        <>
          <h3>{'Save As Template'.translate()}</h3>
          <TextInput
            label={"Template Name".translate()}
            id="name-required" 
            required 
            value={name}
            helperText={error.message}
            error={error.error}
            onChange={(e)=> setName(e.target.value)}
          />
          <div className={classes.actions}>
            <Button
              variant="outlined"
              onClick={()=> onClose()}
            >
              {"Cancel".translate()}
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={()=> onCreate()}
            >
              {"Save".translate()}
            </Button>
          </div>
        </>
      )}


    </Paper>
  </Modal>
}
