import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';

const FilterCheckBox = withStyles({
  root: {
    '&$checked': {
      color: "#1E88E5",
    },
    paddingTop: 2,
    paddingBottom: 2
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default FilterCheckBox;
