import {
    MS_GET_COUNT_TOTAL_SUCCESS,
    MS_GET_LATEST_MACHINE_DISTRIBUTION_SUCCESS,
    MS_GET_LATEST_MACHINE_USAGE_SUCCESS,
    MS_LOADING_TOGGLE,
    MS_DIALOG_TOGGLE,
    MS_QUERY,
    MS_SET_VIEW_BY_COUNT_TOTAL,
    MS_SET_VIEW_BY_LATEST_MACHINE_DISTRIBUTION,
    MS_SET_VIEW_BY_LATEST_MACHINE_USAGE,
    MS_SET_ORDER_BY_LATEST_MACHINE_USAGE,
    MS_SET_ORDER_LATEST_MACHINE_USAGE,
    MS_UPDATE_FETCH_DATE,
    MS_GET_MACHINE_PERFORMANCE_SUCCESS,
    MS_SET_ORDER_MACHINE_PERFORMANCE,
} from '../../actions/actionTypes';

const machineSummaryReducer = (
    state = initialState,
    { type, payload },
) => {
    switch (type) {
        case MS_GET_COUNT_TOTAL_SUCCESS:
            return { ...state, countTotal: payload }
        case MS_GET_LATEST_MACHINE_DISTRIBUTION_SUCCESS:
            return { ...state, latestMachineDistribution: payload }
        case MS_GET_LATEST_MACHINE_USAGE_SUCCESS:
            return { ...state, latestMachineUsage: payload }
        
        case MS_GET_MACHINE_PERFORMANCE_SUCCESS:
            return { ...state, machinesPerformance: payload }
        case MS_SET_ORDER_MACHINE_PERFORMANCE:
            return { ...state, machinesPerformanceOrder: payload }
            
        case MS_QUERY:
            return { ...state, query: payload}
        case MS_LOADING_TOGGLE:
            return { ...state, isLoading: payload }
        case MS_DIALOG_TOGGLE:
            return { ...state, openDialog: !state.openDialog }
            
        case MS_SET_VIEW_BY_COUNT_TOTAL:
            return { ...state, countTotalViewBy: payload }
        case MS_SET_VIEW_BY_LATEST_MACHINE_DISTRIBUTION:
            return { ...state, latestMachineDistributionViewBy: payload }
        case MS_SET_VIEW_BY_LATEST_MACHINE_USAGE:
            return { ...state, latestMachineUsageViewBy: payload }

        case MS_SET_ORDER_LATEST_MACHINE_USAGE:
            return { ...state, latestMachineUsageOrder: payload };
        case MS_SET_ORDER_BY_LATEST_MACHINE_USAGE:
            return { ...state, latestMachineUsageOrderBy: payload };
        case MS_UPDATE_FETCH_DATE:
            return { ...state, dateFetchedData: payload };

        default: return state;
    };
};

const initialState = {
    isLoading: false,
    openDialog: false,

    countTotal: [],
    latestMachineDistribution: [],
    latestMachineUsage: [],

    machinesPerformanceOrder: 'desc',
    machinesPerformanceOrderBy: 'usage',
    machinesPerformance: {
        result: [],
        pagination: {
            total: 0,
            pageSize: 10,
            page: 1,
        },
    },
    
    latestMachineDistributionViewBy: 1,
    countTotalViewBy: 1,
    latestMachineUsageViewBy: 1,

    latestMachineUsageOrder: 'desc',
    latestMachineUsageOrderBy: 'usage',

    dateFetchedData: '',
    query: { buildingIds: [], areaIds: [], lineIds: [] },
};

export default machineSummaryReducer;