import { TableSortLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const MuiTableSortLabel = withStyles((theme) => ({
  root: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 10,
    whiteSpace: "nowrap",
    justifyContent: "space-between",
    width: '100%',
    '&$active': {
      color: '#FFFFFF'
    },
    '&$icon': {
      color: '#FFFFFF',
      fontSize: 12,
    },
  },
  active: {
    color: '#FFFFFF'
  },
  icon: {
    color: '#FFFFFF !important',
    fontSize: 12,
  },
}))(TableSortLabel);


export default MuiTableSortLabel;
