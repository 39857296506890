import {
   NOTIFICATION_SUCCESS,
   NOTIFICATION_ERROR,
   BRAND_LIST,
   BRAND_LIST_REQUEST,
   BRAND_LIST_SUCCESS,
   BRAND_LIST_ERROR,
   BRAND_DETAIL_REQUEST,
   BRAND_DETAIL_SUCCESS,
   BRAND_DETAIL_ERROR,
   BRAND_NEW_REQUEST,
   BRAND_NEW_SUCCESS,
   BRAND_NEW_ERROR,
   BRAND_UPDATE_REQUEST,
   BRAND_UPDATE_SUCCESS,
   BRAND_UPDATE_ERROR,
   BRAND_DELETE_REQUEST,
   BRAND_DELETE_SUCCESS,
   BRAND_DELETE_ERROR,
   BRAND_TRIGGER_HIGHLIGHT,
 } from '../actionTypes';
 import { tokenGet, tokenPost, tokenPut,tokenDelete, apiSettings } from '../../../utils/api/CoreApi';
 import history from '../../../history';

 export const notificationSuccess = (message) => {
     return {
         type: NOTIFICATION_SUCCESS,
         payload: {
             message: message.translate()
         }
     }
 }
 
 export const notificationError = (message) => {
     return {
         type: NOTIFICATION_ERROR,
         payload: {
             message: message.translate()
         }
     }
 }
 
 export const brandRequest = () => {
     return {
         type:BRAND_LIST_REQUEST
     }
 }
 
 export const brandSuccess = brandList => {
     return {
         type:BRAND_LIST_SUCCESS,
         payload: brandList
     }
 }

 export const brandDetailRequest = () => {
    return {
        type:BRAND_DETAIL_REQUEST
    }
}

export const brandDetailSuccess = brandDetail => {
    return {
        type:BRAND_DETAIL_SUCCESS,
        payload: brandDetail
    }
}

 export const brandNewRequest = () => {
    return {
        type:BRAND_NEW_REQUEST
    }
}

export const brandNewSuccess = brand => {
    return {
        type:BRAND_NEW_SUCCESS,
        payload: brand
    }
}

export const brandUpdateRequest = () => {
    return {
        type:BRAND_UPDATE_REQUEST
    }
}

export const brandUpdateSuccess = brand => {
    return {
        type:BRAND_UPDATE_SUCCESS,
        payload: brand
    }
}

export const brandDeleteRequest = () => {
    return {
        type:BRAND_DELETE_REQUEST
    }
}

export const brandDeleteSuccess = brand => {
    return {
        type:BRAND_DELETE_SUCCESS,
        payload: brand
    }
}

export const brandTriggerHighlight = flag => {
    return {
        type:BRAND_TRIGGER_HIGHLIGHT,
        payload: flag
    }
}
 
 
 export const getBrands = (filtered= true,isActive = 'all', currentPage = 1, pageSize = 100000) =>{
     localStorage['filtered'] = filtered
     return function(dispatch){
         dispatch(brandRequest());
         tokenGet(apiSettings.apiURLReferences + '/brands?isActive='+ isActive +'&currentPage=' + currentPage +'&pageSize=' + pageSize).then((response) => {
             let data
             if( localStorage['filtered'] == true){
               data = response.data.data.result.map((item) => {
                    let itemRow = {
                        'id': item.id,
                        'desc1': item.desc1,
                        'isActive': item.isActive,
                    }
                    return itemRow;
                 })
             }
             else data =  response.data.data.result
             delete localStorage['filtered']
             dispatch(brandSuccess({
                 brandList: data,
                 meta: response.data.data.meta
             }));
         })
         .catch((error) => {
             console.log(error);
         });
     }
 }

 export const getBrandDetail = (id) =>{
    return function(dispatch){
        dispatch(brandDetailRequest());
        tokenGet(apiSettings.apiURLReferences + '/brands/' + id).then((response) => {
            let brandDetail = response.data.machine[0];
            let data = {
                code: brandDetail.brand,
                name: brandDetail.desc1,
                desc: brandDetail.desc2,
                website: brandDetail.webSite,
                company: brandDetail.companyName,
                isActive: brandDetail.isActive,
                createdBy: brandDetail.createdBy,
                createdDt: brandDetail.createdDt,
                updatedBy: brandDetail.updatedBy,
                updatedDt: brandDetail.updatedDt,
            }
            dispatch(brandDetailSuccess({
                brandDetail: data
            }));
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

 export const postNewBrand = (payload) => {
    return function(dispatch){
        dispatch(brandNewRequest());
        tokenPost(apiSettings.apiURLReferences + "/brands",payload).then((response) => {
            dispatch(getBrands(false));
            dispatch(notificationSuccess('Successfully added brand'));
            history.push('/configuration/machine-config/brands');
        })
        .catch((error) => {
            if (error.message.indexOf(500) >= 0){
                dispatch(notificationError('Brand code exists'));
                dispatch(brandTriggerHighlight(true));
            }
            console.log(error);
        });
    }
 }

 export const putUpdateBrand = (id,payload) => {
    return function(dispatch){
        dispatch(brandUpdateRequest());
        tokenPut(apiSettings.apiURLReferences + "/brands/" + id,payload).then((response) => {
            dispatch(getBrands(false));
            dispatch(notificationSuccess('Successfully updated brand'));
            history.push('/configuration/machine-config/brands');
        })
        .catch((error) => {
            console.log(error);
        });
    }
 }

 export const deleteBrand = (id) => {
    return function(dispatch){
        dispatch(brandDeleteRequest());
        tokenDelete(apiSettings.apiURLReferences + "/brands/" + id).then((response) => {
            dispatch(getBrands(false));
            dispatch(notificationSuccess('Successfully deleted brand'));
        })
        .catch((error) => {
            if (error.message.indexOf(500) >= 0){
                dispatch(notificationError('Cannot delete because it is currently referenced to another record'));
            }
        });
    }
 }