import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from "react-redux";
import { closeModal } from '../../../redux/actions/Modal/modalActions'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import './LanguageLoading.scss';
import { Hidden } from '@material-ui/core';

class LanguageLoading extends React.Component {

  state = {
      modalName: 'languageLoading',
      modalSettings: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      paperSettings: {
        position: 'relative',
        backgroundColor: 'transparent',
        padding: '2px 10px 3px 10px',
        width: '280px',
        overflow:'hidden',
        outline: 'none'
      },
      open: false,
      search: false
  }

  componentDidMount(){
  }

  render(){
    return (
        <div>
            <Modal 
                open={this.props.modalShown === this.state.modalName} 
                onClose={this.props.closeModal}
                style={this.state.modalSettings}
                closeAfterTransition
                disableBackdropClick
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout:500
                }}
            > 
                <Fade style={this.state.paperSettings} in={this.props.modalShown === this.state.modalName}>
                    <div className='language-loading-modal'>
                       {/* <div onClick={this.props.closeModal} className="close">
                           <CloseIcon />
                       </div> */}
                       {/* <div className="title">{ this.props.title }</div> */}
                       <div className="loader"></div>
                       <div className="title">Language Currently Loading</div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
  }
}

const mapStateToProps = state => {
    let apiLoading = {
    }
    let data = {
      modalShown: state.modalReducer.modalShown,
      ticketDetail: state.ticketListReducer.ticketDetail,
      checklistDetail: state.ticketListReducer.checklistDetail,
    }
  
    return {
      ...apiLoading,
      ...data
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      closeModal: () => dispatch(closeModal()),
    }
  }

LanguageLoading = connect(mapStateToProps,mapDispatchToProps)(LanguageLoading);

export default LanguageLoading;
