import React from 'react';
import { Box } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import { CO_REQUEST_STATUSES } from '../../../../../constants/corequest';

export default function Actions({ changeover, onClose }) {
  const history = useHistory();

  return <Box my={2}>
    <Button
      color="primary"
      startIcon={<EditIcon />}
      onClick={()=> history.push(`/changeover-edit/${changeover.id}`)}
    >
      {'EDIT'.translate()}
    </Button>

    <Button
      color="primary"
      startIcon={<FileCopyOutlinedIcon />}
    >
      {'COPY'.translate()}
    </Button>

    {changeover.statusCode != CO_REQUEST_STATUSES['CLOSED'] &&
      <Button 
        color="primary"
        onClick={() => onClose()}
      >
        {'CLOSE'.translate()}
      </Button>
    }
  </Box>
}