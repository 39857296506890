import React from 'react';
import { Box } from '@material-ui/core';
import StarRounded from '@material-ui/icons/StarRounded';
import ChangeoverTooltip from './ChangeoverTooltip';
import { withStyles } from '@material-ui/core/styles';

const CardBox = withStyles({
  root: {
    textAlign: 'center',
  },
})(Box);

const CardHeader = withStyles({
  root: (props) => ({
    border: '1px solid #363636',
    backgroundColor: props.statusColor,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
  }),
})(({ statusColor, ...props })=> <Box {...props} />);

const CardBody = withStyles({
  root: {
    borderColor: '#8E8E8E',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid',
    borderTop: 0,
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
})(Box);

const Divider = withStyles({
  root: {
    width: '1px',
    backgroundColor: '#363636',
    height: 12,
  },
})(Box);

const ContentBox = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    flexGrow: 1,
  },
})(Box);

const COLORS = {
  CURRENT: {
    bg: '#72B9FF',
    text: '#000000',
  },
  NEW: {
    bg: '#4CAF50',
    text: '#000000',
  },
  'IN PROGRESS': {
    bg: '#FF7A27',
    text: '#000000',
  },
  READY: {
    bg: '#9F05F0',
    text: '#fff',
  },
  CLOSED: {
    bg: '#AFAFAF',
    text: '#000000',
  },
  NA: {
    bg: '#FFFFFF',
    text: '#000000',
  }
};

export default function ChangeoverCard({
  isFocus,
  onFocus,
  onLostFocus,
  infoPlacement,
  isCurrent,
  changeover,
}) {
  const { status, code, style, machineQty, criticalQty } = changeover;
  const color = code ? (isCurrent ? COLORS['CURRENT'] : COLORS[status]) : COLORS.NA;
  const title = isCurrent ? style : code;

  return (
    <ChangeoverTooltip
      open={Boolean(isFocus)}
      onOpen={onFocus}
      onClose={onLostFocus}
      placement={infoPlacement}
      changeover={changeover}
    >
      <CardBox>
        <CardHeader statusColor={color.bg} color={color.text}>
          {title || 'N/A'}
        </CardHeader>
        <CardBody>
          <ContentBox className="content-box">{machineQty || 0} {'M/C'.translate()}</ContentBox>
          <Divider />
          <ContentBox className="content-box">
            <StarRounded style={{ color: '#FFB53E' }} />
            {criticalQty || 0}
          </ContentBox>
        </CardBody>
      </CardBox>
    </ChangeoverTooltip>
  );
}
