import React from 'react';
import CardPaper from '../CardPaper';
import Header from './header';
import TableHeader from './table/header';
import TableBody from './table/body';
import TableBodyEmpty from './table/bodyEmpty';
import { Button, TableContainer } from '@material-ui/core';
import { TableStyle } from './styles';
import { connect } from 'react-redux';
import { useState } from 'react';
import { colors } from './constant';
import { useEffect } from 'react';
import { deserializeList } from './helpers';
import { fetchIndividualMechanic } from '../../api';
import { closeModal, showModal } from '../../../../../redux/actions/Modal/modalActions';
import { ANALYTIC_REPORT_TYPES, downloadAnalyticReport } from '../../excel';
import { getDateRangeQueryString } from '../../helpers';

const IndividualMechanicPerformance = (props) => {
    const { 
        filters, 
        showModal, 
        closeModal, 
        isCron,
    } = props;
    const [data, setData] = useState([]);
    const [orderDirection, setOrderDirection] = useState('DESC');
    const handleOrder = () => {
        setOrderDirection(orderDirection === 'DESC' ? 'ASC' : 'DESC');
    };
    
    useEffect(() => {
        if(isCron) return;
        fetchIndividualMechanic({
            filters: getDateRangeQueryString(filters),
            orderBy: 'averageTicketHandlingTime',
            orderDirection: orderDirection,
        }).then(({ individualMechanic }) => {
            setData(deserializeList(individualMechanic.data));
        });
    }, [filters, orderDirection]);

    const handleExportExcel = () => {
        showModal('excelLoading');
        fetchIndividualMechanic({
            filters: getDateRangeQueryString(filters),
            orderBy: orderDirection,
        }).then(({ individualMechanic }) => {
            const data = individualMechanic.data.mechanicTicketDetails;
            downloadAnalyticReport(ANALYTIC_REPORT_TYPES.IndividualMechanicPerformance, data);
        }).finally(() => {
            closeModal();
        });
    }
    
    return (
        <CardPaper>
            <Header colors={colors} handleExport={handleExportExcel}/>
            <TableContainer>
                <TableStyle>
                    <TableHeader handleOrder={handleOrder} />
                    { data?.length > 0 
                        ? <TableBody
                            {...props}
                            data={data} 
                            colors={colors} /> 
                        : <TableBodyEmpty />
                    }
                </TableStyle>
            </TableContainer>
        </CardPaper>
    );
}

const mapStateToProps = state => ({ ...state.translationReducer });
const mapDispatchToProps = dispatch => {
    return {
        showModal: (modal) => dispatch(showModal(modal)),
        closeModal: () => dispatch(closeModal())
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(IndividualMechanicPerformance);