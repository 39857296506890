import React, { Component } from 'react';
import { Provider } from 'react-redux';
import Store from './redux/store';
import Routes from './routes';
import axios from 'axios';
import { apiSettings } from './utils/api/CoreApi';
import InventoryAuthorized from './containers/Modules/Login/InventoryAuthorized';

class LTmApp extends Component {

    state = {
        loaded: false
    }

    componentDidMount() {
        this.bootstrap();
    }

    bootstrap() {
        var urlParams = new URLSearchParams(window.location.search);
        let accessToken = urlParams.get('accessToken');
        let refreshToken = urlParams.get('refreshToken');
        let selectedLanguage = urlParams.get('language');
        let selectedFactory = urlParams.get('factory');
        let selectedFactoryId = urlParams.get('factoryId');
        let storageToken = localStorage.getItem('userToken');


        // Check if access from global admin
        if (accessToken) {
            if (selectedLanguage) localStorage.setItem('selectedLanguage', selectedLanguage);
            if (selectedFactory) localStorage.setItem('selectedFactory', selectedFactory);
            if (selectedFactoryId) localStorage.setItem('selectedFactoryId', selectedFactoryId);
            this.requestCheckUserAccess(accessToken, refreshToken);
        } else if (!storageToken) {
            window.location = process.env.REDIRECT_PAGE;
        } else {
            this.setState({ loaded: true });
        }
    }

    requestCheckUserAccess(accessToken, refreshToken) {
        const config = {
            headers: {
                Authorization: "Bearer " + accessToken
            }
        };

        axios
            .get(`${apiSettings.apiURLAuthGlobalAdmin}/useraccess?product=LTm`, config)
            .then(async ({ data }) => {
                await this.requestSetProduct(config);
                localStorage.setItem('refreshToken', `"${refreshToken}"`);
                localStorage.setItem('userToken', `"${accessToken}"`);
                localStorage.setItem('userRole', JSON.stringify(data.data.role));
                localStorage.setItem('userAccess', JSON.stringify(data.data.access));
            })
            .catch((error) => {
                localStorage.clear();
                window.location = '/';
            })
            .finally(() => {
                this.setState({ loaded: true });
            });
    }

    async requestSetProduct(config) {
        const payload = { "product": "LTm" };
        await axios.post(`${apiSettings.apiURLAuthGlobalAdmin}/user/setproduct`, payload, config);
    }

    render() {
        const { loaded } = this.state;
        return (
            <Provider store={Store}>
                {loaded ? <Routes /> : <InventoryAuthorized />}
            </Provider>
        );
    }
}

export default LTmApp;
