export const required = value => value !== null && value !== '' ? undefined : 'Required'
export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
export const maxLength50 = maxLength(50)
export const maxLength350 = maxLength(350)
export const noSpecialCharacters = value => value && !/^[A-Za-z0-9\-\s]+$/i.test(value.trim()) ? 'Must be letters only' : undefined
export const number = value => value && isNaN(Number(value)) && !/^[0-9]*$/i.test(value) ? 'Must be a number' : undefined
export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
export const minValue0 = minValue(0)
export const maxValue = max => value =>
  value && value > max ? `Max of ${max}` : undefined
export const maxValue80 = maxValue(80)
export const email = value => 
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Invalid email address' : undefined
export const alphanumeric = value => value && /[=><]/.test(value.trim()) ? 'Must be letters only' : undefined



export const validateFields = (form) => {
    let formValid = true;
    let errors = [];

    // if (required(form.ticketTypeId) !== undefined){
    //     formValid = false;
    //     errors.push({name:'ticketTypeId',message:'Please fill up all required fields'});
    // }

    if (required(form.problemTypeId) !== undefined){
        formValid = false;
        errors.push({name:'problemTypeId',message:'Please fill up all required fields'});
    }

    if (maxLength350(form.remarks) !== undefined){
        formValid = false;
        errors.push({name:'remarks',message:'Remarks is too long'});
    }

    // if (required(form.checkListId) !== undefined){
    //     formValid = false;
    //     errors.push({name:'checkListId',message:'Please fill up all required fields'});
    // }

    // if (required(form.ticketNo) !== undefined){
    //     formValid = false;
    //     errors.push({name:'ticketNo',message:'Please fill up all required fields'});
    // }

    // if (alphanumeric(form.ticketNo) !== undefined){
    //     formValid = false;
    //     errors.push({name:'ticketNo',message:'The following special characters are not allowed: = < >'});
    // }

    // if (maxLength50(form.ticketNo) !== undefined){
    //     formValid = false;
    //     errors.push({name:'ticketNo',message:'Remarks is too long'});
    // }

    return {
        valid: formValid,
        errors: errors
    }
}