import React from 'react';
import { useState } from 'react';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { dialogToggle } from '../../../../redux/actions/MachineSummary/machineSummaryAction';
import UserSummaryDialog from './Dialog';
import { ButtonSummary } from './styles';

export default function MachineSummary(props) {
    const dispatch = useDispatch();
    return ( 
        <Fragment>
            <ButtonSummary onClick={() => dispatch(dialogToggle())} marginRight={10} />
            <UserSummaryDialog />
        </Fragment>
    );
}