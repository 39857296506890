
import { TRANSLATION_ACTIONS as actionList } from '../../actions/actionTypes'

const translation = {
    availableCode: [{ code: 'en', language: 'English' }],
    translations: {}
}
const translationReducer = (state = { translation }, action) => {
    switch (action.type) {
        case actionList.FETCH_CODE_TRANS:
            return {
                translation: {
                    ...state.translation,
                    availableCode: action.payload.availableCode
                }
            }
        case actionList.FETCH_TRANS_BY_CODE:
            return {
                translation: {
                    ...state.translation,
                    translations: {...action.payload}
                }
            }
        default:
            return state
    }
}
export default translationReducer