import React, { useEffect, useRef, useState } from 'react';
import { Backdrop, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import MainPage from '../../../components/MainPage/MainPage';
import PageHeader from '../../../components/PageHeader/PageHeader';
import PageBody from '../../../components/PageBody/PageBody';
import ChangeoverInfo from './Detail/ChangeoverInfo';
import MachineSummary from './Detail/MachineSummary';
import LineSetup from './Detail/LineSetup';
import { useParams } from 'react-router-dom';
import { machineClient } from '../../../utils/api/CoreApi';
import { deserializeDetail } from './helpers';
import { connect } from 'react-redux';
import useChangeoverActions from './hook';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import { notificationSuccess, notificationError } from '../../../redux/actions/Notification/notificationActions'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1
  },
}));

function ChangeoverDetail(props) {
  const { id } = useParams();
  const classes = useStyles();
  const confirmModalRef = useRef();
  const [changeover, setChangeover] = useState(null);
  const [dependent, setIndependent] = useState(1);
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      text: 'Tickets',
    },
    {
      text: 'Changeover Requests',
      href: '/changeovers',
    },
  ]);
  const {
    loadingAction,
    confirmClose,
  } = useChangeoverActions(props, confirmModalRef);

  useEffect(()=>{
    machineClient.get(`/co-requests/${id}`).then(({ data })=>{
      const { corequest } = data;
      const normalizedData = deserializeDetail(corequest);
      setChangeover(normalizedData);
      setBreadcrumbs([
        ...breadcrumbs,
        {
          text: normalizedData.code
        }
      ]);
    });
  }, [id, dependent])

  if(!changeover) return null;

  const handleClose = () => {
    confirmClose([changeover], () => {
      setIndependent(dependent + 1);
    })
  }

  return (
    <MainPage>
      <PageHeader breadcrumbs={breadcrumbs} />
      <PageBody pt={1}>
        <Grid container spacing={1}>
          <Grid style={{ position: 'relative' }} item xs={3}>
            <ChangeoverInfo
              changeover={changeover}
              handleClose={handleClose}
            />
          </Grid>
          <Grid item xs={9}>
            <MachineSummary
              changeover={changeover}
            />
            <LineSetup
              changeover={changeover}
            />
          </Grid>
        </Grid>

      </PageBody>
      <Backdrop
        sx={{ color: '#fff' }}
        className={classes.backdrop}
        open={loadingAction}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <ConfirmModal ref={confirmModalRef} />
    </MainPage>
  );
}

const mapStateToProps = state => ({...state.translationReducer});
const mapDispatchToProps = {
  notificationSuccess,
  notificationError,
};
export default connect(mapStateToProps, mapDispatchToProps)(ChangeoverDetail);
