/* HELPER FUNCTIONS */
import moment from 'moment'
import decoder from 'jwt-decode';
import { formatDate } from './date';

let superAdminFactoryId = process.env.REACT_APP_API_ADMIN_FACTORY_ID;

export const getFactoryId = () => {
    let userToken = JSON.parse(localStorage.getItem('userToken'));
    if (userToken){
        let decodedToken = decoder(userToken);
        return decodedToken.factoryId;
    }
    else{
        return null;
    }
}

export const getUsername = () => {
    let userToken = JSON.parse(localStorage.getItem('userToken'));
    if (userToken){
        let decodedToken = decoder(userToken);
        return decodedToken.username;
    }
    else{
        return null;
    }
}


export const getCompanyCode = () => {
    let userToken = JSON.parse(localStorage.getItem('userToken'));
    if (userToken){
        let decodedToken = decoder(userToken);
        return decodedToken.companyCode;
    }
    else{
        return null;
    }
}


export const getRole = () => {
    let userRole = JSON.parse(localStorage.getItem('userRole'));
    if (userRole){
        return userRole;
    }
    else{
        return null;
    }
}

export const getUserIdDefault = () => {
    let userToken = JSON.parse(localStorage.getItem('userToken'));
    if (userToken){
        let decodedToken = decoder(userToken);
        return decodedToken.userId;
    }
    else{
        return null;
    }
}

export const isSuperAdmin = () => {
    let factoryId = getFactoryId();
    if (factoryId !== null){
        let flag = factoryId.toString() === superAdminFactoryId ? true : false;
        return flag
    }
    else{
        return false;
    }
}

export const checkIfSuperAdminFactoryExist = (factoriesAssigned) => {
    if (factoriesAssigned){
        let check = factoriesAssigned.filter((item) => { return item.factoryId.toString() === superAdminFactoryId  });
        if (check.length > 0){
            return true;
        }
        else{
            return false;
        }
    }
    else{
        return false
    }
}

export const checkIfSuperAdminFactoryExistInRoute = () => {
    if (localStorage.getItem('factoriesAssigned')){
        let factoriesAssigned = JSON.parse(localStorage.getItem('factoriesAssigned'));
        let check = factoriesAssigned.filter((item) => { return item.factoryId.toString() === superAdminFactoryId  });
        if (check.length > 0){
            return true;
        }
        else{
            return false;
        }
    }
    else{
        return false
    }
}

export const checkIfSuperAdmin = (id) => {
    if (id.toString() === superAdminFactoryId){
        return true;
    }
    else{
        return false;
    }
}

export const getUserToken = () => {
    let userToken = JSON.parse(localStorage.getItem('userToken'));
    return userToken;
}

export const getUserId = async () => {
    let userToken = JSON.parse(localStorage['userToken'] ?? null)
    try {
        let {userId} = await decoder(userToken)
        return userId
    } catch (error) {
        console.error('getUserId', error)
    }
}

export const checkIfApiLoading = (loadingArr) => {
    let result = false;
    loadingArr.map((loading) => {
        if (loading) {
            result = true;
        }
        return '';
    });
    return result;
}

export const capitalize = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
}


export const checkRoleAccess = (access, params) => {
    if (access === undefined || access === null) {
        return false
    }

    let check = access.filter((item) => { return item.role === params.role && (item.isActive === "1" || item.isActive === 'N/A') });
    if (check.length > 0) {
        return true;
    }
    else {
        return false;
    }
}

export const checkPageAccess = (access, params) => {
    if (access === undefined || access === null) {
        return false
    }
    let check = null;
    if(params.pageId){
      check = access.filter((item) => { return item.pageId === params.pageId && (item.isActive === "1" || item.isActive === 'N/A') });
    }else{
      check = access.filter((item) => { return item.page === params.page && (item.isActive === "1" || item.isActive === 'N/A') });
    }

    if (check.length > 0) {
        return true;
    }
    else {
        return false;
    }
}

export const checkPageActionAccess = (access, params) => {
    if (access === undefined || access === null) {
        return false
    }

    let check = null;
    if(params.pageId){
      check = access.filter((item) => { return item.pageId === params.pageId && item.Action === params.pageAction && (item.isActive === "1" || item.isActive === 1) });
    }else{
      check = access.filter((item) => { return item.page === params.page && item.Action === params.pageAction && (item.isActive === "1" || item.isActive === 1) });
    }

    if (check.length > 0) {
        return true;
    }
    else {
        return false;
    }
}

export const checkPageAccessField = (access, params) => {
    if (access === undefined || access === null) {
        return false
    }

    let check = null;
    if(params.pageId){
      check = access.filter((item) => { return item.pageId === params.pageId && item.pageAccess === params.pageAccess });
    }else{
      check = access.filter((item) => { return item.page === params.page && item.pageAccess === params.pageAccess });
    }

    if (check.length > 0) {
        return true;
    }
    else {
        return false;
    }
}

export const setters = () => {
    /**
     * Date setters for weekends
     * returns the 0 and 6 index of a week by the current date
     */
    Date.prototype.weekends = function () {
        const formatDate = (arg) => {
            return moment(arg).format('DD-MM-YYYY')
        }

        let arg = this
        let weekday = arg.getDay()
        let sunday = formatDate(new Date(arg.setDate(arg.getDate() - weekday)))
        let saturday = formatDate(new Date(arg.setDate(arg.getDate() + 6)))

        return { sunday, saturday }
    }
    /**setters for fullscreen */
    if (!Element.prototype.requestFullscreen) Element.prototype.requestFullscreen = Element.prototype.mozRequestFullscreen || Element.prototype.webkitRequestFullscreen || Element.prototype.msRequestFullScreen
    if (!document.exitFullscreen) document.exitFullscreen = document.mozCancelFullScreen || document.webkitExitFullscreen || document.msExitFullscreen
    if (!document.fullscreenElement) {
        Object.defineProperty(document, 'fullscreenElement', {
            get: function () {
                return document.mozFullScreenElement || document.msFullscreenElement || document.webkitFullscreenElement
            }
        })
    }
    if (!document.fullscreenEnabled) {
        Object.defineProperty(document, 'fullscreenEnabled', {
            get: function () {
                return document.mozFullScreenEnabled || document.msFullscreenEnabled || document.webkitFullscreenEnabled
            }
        })
    }
    /**setters for translations */
    /**stx_en */
    String.prototype.translate = function () {
        // console.log('translat', this)
        // return this
        let language = localStorage['selectedLanguage'] || 'en'
        if (language != 'en') {
            let concat
            let isToSanitize = this.match(/^#/gi) ? true : false

            if(isToSanitize) concat = '# '

            let factory = localStorage['selectedFactory'] || 'stx'

            let source = `${factory}_${language}`

            let index = localStorage[source] && Object.entries(JSON.parse(localStorage[source])).findIndex(x => x[0].replace(/\s*/gi, '').toLowerCase() == this.replace(/^#|\s*/gi, '').toLowerCase())

            let translations = localStorage[source] && Object.entries(JSON.parse(localStorage[source]))
            return index == -1 ? this : translations && `${concat ?? ''}${translations[index][1]}` || this
        }
        else return this
    }
}
/** transform languages to localStorage */
export const languageToStorage = (languages) => {
    if (Array.isArray(languages)) {
        languages.forEach(x => {
            localStorage[x.code] = JSON.stringify(x.data)
        })
    }
    else localStorage[languages.code] = JSON.stringify(languages.data)
}

export const getBuildingAreaQuery = (building, area) => {
    let query = '';
    if(building && building != "undefined") {
        query = `?buildingId=${building}`;
    }
    if(area && area != "undefined") {
        query += `${!query ? '?' : '&'}&areaId=${area}`;
    }

    return query;
};

export const fontResponsive = (className = '.ellipsisText') => {
    document.querySelectorAll(className).forEach((e) => {
        let fontSize = parseInt(getComputedStyle(e).fontSize);
        e.removeAttribute('style');
        while(e.scrollWidth > e.clientWidth){
            fontSize--;
            e.style.fontSize = fontSize + 'px';
        }
    });
};

export const maxinumumString = (string, limit = 35) => {
    if(string && string.toString().length > limit)
        return string.toString().substring(0, limit) + '...';
    return string;
}
export const numberWithCommas = (number) => {
    if(number)
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        
    return 0;
}

export const sortingProblemTypesNPT = (data) => {
    if(!data) return [];
    let tmpData = data;
    tmpData.reduce((__, item, i) => {
        let problemTypes = item.problemTypes.sort((a, b) => {
            if(b.count - a.count === 0) {
                return Number(b.nptTime) - Number(a.nptTime);
            }
            
            return b.count - a.count;
        });
        tmpData[i].problemTypes = problemTypes;
        return tmpData;
    }, []);
    return tmpData;
}

export const getMaintenanceStatus = (ticket) => {
    return moment(formatDate(ticket.reportedDt)).isSameOrBefore(moment(ticket.nextMaintDate)) ? 'ON SCHEDULE' : 'OVERDUE';
}

export const getDashboardQuery = () => {
    let query = {};
    const dashboardQuery = localStorage.getItem('dashboardQuery');
    if(dashboardQuery){
        const { areaIds, buildingIds, isAllBuildings, isAllAreas } = JSON.parse(dashboardQuery);
        if(!isAllBuildings && buildingIds) {
            query = { ...query, buildingIds }
        };
        if(!isAllAreas && areaIds) {
            query = { ...query, areaIds }
        };
    };
    return query;
};

export const removeUnusedElement = () => {
    const chartJs = document.getElementById('chartjs-4');
    const chartjsTooltip = document.getElementById('chartjs-tooltip');
    if(chartJs) chartJs.remove();
    if(chartjsTooltip) chartjsTooltip.remove();
}