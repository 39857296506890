import React, { useEffect, useState } from 'react';
import {
  Box,
  Select,
  MenuItem,
  withStyles,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { referenceClient } from '../../../../../../utils/api/CoreApi';
import FormControl from './FormControl';

const Container = withStyles({
  root: {
    width: '30%',
    display: 'flex',
    gap: 3,
    alignItems: 'center'
  },
})(Box);

const CustomSelect = withStyles({
  select: {
    paddingTop: 8,
    paddingBottom: 8,
  },
})(Select);

const CustomFormControl = withStyles({
  root: {
    marginBottom: 0,
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px !important',
    },
  },
})(FormControl);

const CustomMenuItem = withStyles({
  selected: {
    backgroundColor: '#fff !important',
  }
})(MenuItem);

const ALL_VALUE = 'all';

export default function LocationsFilter({ onChange }) {
  const [buildings, setBuildings] = useState([]);
  const [areas, setAreas] = useState([]);
  const [lines, setLines] = useState([]);

  const [selectedBuildings, setSelectedBuildings] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedLines, setSelectedLines] = useState([]);

  useEffect(() => {

    referenceClient.get("/lines", { params: { isActive: '1', pageSize: 100000 } }).then(({ data }) => {
      const { machines } = data;
      const { result } = machines;
      setLines(result.map(({ id, desc1, areaId }) => ({ id, areaId, name: desc1 })));

      referenceClient.get("/areas", { params: { isActive: '1', pageSize: 100000 } }).then(({ data }) => {
        const { machines } = data;
        const { result } = machines;
        setAreas(result.map(({ id, desc1, buildingId }) => ({ id, name: desc1, buildingId })));

        referenceClient.get("/buildings").then(({ data }) => {
          setBuildings(data.data.map(({ id, name }) => ({ id, name })));
          setSelectedBuildings(data.data.map(({ id }) => id));
        });
      });
    });
  }, [])

  useEffect(() => {
    setSelectedAllAreas();
  }, [JSON.stringify(selectedBuildings)]);

  useEffect(() => {
    setSelectedAllLines();
  }, [JSON.stringify(selectedAreas)]);

  useEffect(() => {
    const buildingIds = selectedBuildings;
    const areaIds = selectedAreas;
    const lineIds = selectedLines;
    onChange({
      buildingIds,
      areaIds,
      lineIds,
    })
  }, [selectedLines]);

  const setSelectedAllBuildings = () => {
    setSelectedBuildings(buildings.map(({ id }) => id));
  }

  const setSelectedAllAreas = () => {
    setSelectedAreas(areas.filter(ar => selectedBuildings.includes(ar.buildingId)).map(ar => ar.id));
  }

  const setSelectedAllLines = () => {
    setSelectedLines(lines.filter(li => selectedAreas.includes(li.areaId)).map(li => li.id));
  }

  const getLocationFilter = (
    isAllBuildingSelected,
    isAllAreasSelected,
    isAllLinesSelected,
  ) => {
    let str = '';
    if(isAllBuildingSelected) {
      str += 'All Buildings'
    } else {
      str += buildings
        .filter((building) => selectedBuildings.includes(building.id))
        .map((building) => building.name)
        .join(', ');
    }

    if(isAllAreasSelected) {
      str += '/All Areas'
    } else {
      str += '/' + areas
        .filter((area) => selectedAreas.includes(area.id))
        .map((area) => area.name)
        .join(', ');
    }

    if(isAllLinesSelected) {
      str += '/All Lines'
    } else {
      str += '/' + lines
        .filter((line) => selectedLines.includes(line.id))
        .map((line) => line.name)
        .join(', ');
    }

    return str;
  }

  const filteredAreas = areas.filter(ar => selectedBuildings.includes(ar.buildingId));
  const filteredLines = lines.filter(li => selectedAreas.includes(li.areaId));

  const isAllBuildingSelected = selectedBuildings.length === buildings.length;
  const isAllAreasSelected = selectedAreas.length === filteredAreas.length;
  const isAllLinesSelected = selectedLines.length === filteredLines.length;

  // For use in excel export
  localStorage.setItem('filterMachineSummary', getLocationFilter(
    isAllBuildingSelected,
    isAllAreasSelected,
    isAllLinesSelected,
  ));

  return (
    <Container>
      <CustomFormControl variant="outlined">
        <CustomSelect
          multiple
          value={selectedBuildings}
          onChange={(evt) => {
            const selected = evt.target.value;
            if(selected.includes('all')){
              isAllBuildingSelected 
                ? setSelectedBuildings([]) 
                : setSelectedAllBuildings();
            } else {
              setSelectedBuildings(selected);
            }
          }}
          renderValue={(_) => isAllBuildingSelected ? "All building are selected".translate() : "Multiple building are selected".translate()}
        >
          <CustomMenuItem value='all'>
            <Checkbox
              checked={isAllBuildingSelected}
              indeterminate={!isAllBuildingSelected && selectedBuildings.length > 0}
              color="primary"
            />
            <ListItemText primary={'All'.translate()} />
          </CustomMenuItem>

          {buildings.map(({ id, name }) => {
            return (
              <CustomMenuItem key={id} value={id}>
                <Checkbox
                  checked={selectedBuildings.includes(id)}
                  color="primary"
                />
                <ListItemText primary={name} />
              </CustomMenuItem>
            )
          })}
        </CustomSelect>
      </CustomFormControl>

      <CustomFormControl variant="outlined">
        <CustomSelect
          multiple
          value={selectedAreas}
          onChange={(evt) => {
            const selected = evt.target.value;
            if(selected.includes('all')){
              isAllAreasSelected 
                ? setSelectedAreas([])
                : setSelectedAllAreas();
            } else {
              setSelectedAreas(selected);
            }
          }}
          renderValue={(_) => isAllAreasSelected ? "All areas are selected".translate() : "Multiple areas are selected".translate()}
        >
          <CustomMenuItem value='all'>
            <Checkbox
              checked={isAllAreasSelected}
              indeterminate={!isAllAreasSelected && selectedAreas.length > 0}
              color="primary"
            />
            <ListItemText primary={'All'.translate()} />
          </CustomMenuItem>
          {
            filteredAreas.map(({ id, name, buildingId }) => {
              return (
                <CustomMenuItem key={id} value={id}>
                  <Checkbox
                    checked={selectedAreas.includes(id)}
                    color="primary"
                  />
                  <ListItemText primary={name} />
                </CustomMenuItem>
              )
            })
          }
        </CustomSelect>
      </CustomFormControl>

      <CustomFormControl variant="outlined">
        <CustomSelect
          multiple
          value={selectedLines}
          onChange={(evt) => {
            const selected = evt.target.value;
            if(selected.includes('all')){
              isAllLinesSelected 
                ? setSelectedLines([])
                : setSelectedAllLines();
            } else {
              setSelectedLines(selected);
            }
          }}
          renderValue={(_) => isAllLinesSelected ? "All lines are selected".translate() : "Multiple lines are selected".translate()}
        >
          <CustomMenuItem value='all'>
            <Checkbox
              checked={isAllLinesSelected}
              indeterminate={!isAllLinesSelected && selectedLines.length > 0}
              color="primary"
            />
            <ListItemText primary={'All'.translate()} />
          </CustomMenuItem>
          {
            filteredLines.map(({ id, areaId, name }) => {
              return <CustomMenuItem
                key={id}
                value={id}
              >
                <Checkbox
                  checked={selectedLines.includes(id)}
                  color="primary"
                />
                <ListItemText primary={name} />
              </CustomMenuItem>
            })
          }
        </CustomSelect>
      </CustomFormControl>
    </Container>
  );
}
