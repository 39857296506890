import { TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
const TOTAL_COLS = 7;

export default withStyles((theme) => ({
  root: {
    border: '1px solid #707070',
    padding: 0,
    textAlign: 'center',
    width: 100 / 7 + '%',
  },
}))(TableCell);
