import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { GridItem, GridTitle, LoadingBox } from './styles';
import { useSelector } from 'react-redux';

function MaintenanceTickets({ isTvMode }) {

    const data = useSelector((state) => state.dashboardListReducer.ticketsMaintenance);
    const items = [
        {label: 'Overdue', key: 'overdue'},
        {label: 'Scheduled Today', key: 'scheduledToday'},
        {label: 'In Progress', key: 'inProgress'},
        {label: 'Completed Today', key: 'completed'},
        {label: 'Cancelled Today', key: 'canceled'},
    ];

    return (
        <Paper style={{ position: 'relative' }} className={isTvMode ? 'card tvMode-height': 'card'}>
            <GridTitle title={'MAINTENANCE TICKETS'.translate()}  isTvMode={isTvMode}/>
            {!data && <LoadingBox />}
            <Grid justifyContent='center' container spacing={2} className={isTvMode ? 'repair-ticket' : 'inner-card repair-ticket'} >
                {items.map(({ label, key }) => (
                    <GridItem 
                        label={label.translate()} 
                        isTvMode={isTvMode} 
                        count={data ? data[key] || 0 : 0} 
                    />
                ))}
            </Grid>
        </Paper>
    );
}
export default MaintenanceTickets;