import { TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  root: {
    fontSize: 12,
    padding: 6,
    color: '#515151',
  },
}))(TableCell);
