import {
    NOTIFICATION_SUCCESS,
    NOTIFICATION_ERROR,
    BUILDING_LIST,
    BUILDING_LIST_REQUEST,
    BUILDING_LIST_SUCCESS,
    BUILDING_LIST_ERROR,
    BUILDING_DETAIL_REQUEST,
    BUILDING_DETAIL_SUCCESS,
    BUILDING_DETAIL_ERROR,
    BUILDING_NEW_REQUEST,
    BUILDING_NEW_SUCCESS,
    BUILDING_NEW_ERROR,
    BUILDING_UPDATE_REQUEST,
    BUILDING_UPDATE_SUCCESS,
    BUILDING_DELETE_REQUEST,
    BUILDING_DELETE_SUCCESS,
    BUILDING_UPDATE_ERROR,
    BUILDING_TRIGGER_HIGHLIGHT,
} from '../actionTypes';
import { tokenGet, tokenPost, tokenPut, tokenDelete, apiSettings } from '../../../utils/api/CoreApi';
import setters from '../../actions/Factory/factoryActions'
import history from '../../../history';

export const notificationSuccess = (message) => {
    return {
        type: NOTIFICATION_SUCCESS,
        payload: {
            message: message.translate()
        }
    }
}

export const notificationError = (message) => {
    return {
        type: NOTIFICATION_ERROR,
        payload: {
            message: message.translate()
        }
    }
}

export const buildingRequest = () => {
    return {
        type: BUILDING_LIST_REQUEST
    }
}

export const buildingSuccess = buildingList => {
    return {
        type: BUILDING_LIST_SUCCESS,
        payload: buildingList
    }
}

export const buildingDetailRequest = () => {
    return {
        type: BUILDING_DETAIL_REQUEST
    }
}

export const buildingDetailSuccess = buildingDetail => {
    return {
        type: BUILDING_DETAIL_SUCCESS,
        payload: buildingDetail
    }
}

export const buildingNewRequest = () => {
    return {
        type: BUILDING_NEW_REQUEST
    }
}

export const buildingNewSuccess = buildingList => {
    return {
        type: BUILDING_NEW_SUCCESS,
        payload: buildingList
    }
}

export const buildingUpdateRequest = () => {
    return {
        type: BUILDING_UPDATE_REQUEST
    }
}

export const buildingUpdateSuccess = buildingList => {
    return {
        type: BUILDING_UPDATE_SUCCESS,
        payload: buildingList
    }
}

export const buildingDeleteRequest = () => {
    return {
        type: BUILDING_DELETE_REQUEST
    }
}

export const buildingDeleteSuccess = buildingList => {
    return {
        type: BUILDING_DELETE_SUCCESS,
        payload: buildingList
    }
}


export const buildingTriggerHighlight = (flag) => {
    return {
        type: BUILDING_TRIGGER_HIGHLIGHT,
        payload: flag
    }
}   

export const getBuildingDetail = (id) => {
    return function (dispatch) {
        dispatch(buildingDetailRequest());
        tokenGet(apiSettings.apiURLReferences + '/buildings/' + id).then((response) => {    
            dispatch(buildingDetailSuccess({
                buildingDetail: response.data.data[0],
            }));
        })
            .catch((error) => {
                console.log(error);
            });
    }
}

export const postBuildingNew = (payload) => {
    let notif = notificationSuccess('Successfully added building')
    return function (dispatch) {
        dispatch(buildingNewRequest());
        tokenPost(apiSettings.apiURLReferences + '/buildings', payload).then(async (response) => {
            dispatch(buildingNewSuccess({
                building: response.data
            }));
            await setters().getFactoryTreeViewById(payload.factoryId, notif)
        })
        .catch((error) => {
            if (error.message.indexOf(500) >= 0){
                dispatch(notificationError('Building code exists'));
                dispatch(buildingTriggerHighlight(true));
            }
            console.log(error);
        });
    }
}

export const putBuildingUpdate = (id, payload) => {
    let notif = notificationSuccess('Successfully updated building')
    return function (dispatch) {
        dispatch(buildingUpdateRequest());
        tokenPut(apiSettings.apiURLReferences + '/buildings/' + id, payload).then(async (response) => {
            dispatch(buildingUpdateSuccess({
                building: response.data
            }));
            await setters().getFactoryTreeViewById(payload.factoryId, notif)
        })
            .catch((error) => {
                if (error.message.indexOf(500) >= 0){
                    dispatch(notificationError('Building code exists'));
                    dispatch(buildingTriggerHighlight(true));
                }
                else if (error.message.indexOf(422) >= 0){
                    dispatch(notificationError("Cannot deactivate. There are machine(s) currently attached to the line"));
                }
                console.log(error);
            });
    }
}

export const deleteBuilding = (id,factoryId) => {
    let notif = notificationSuccess('Successfully deleted building type')
    return function (dispatch) {
        dispatch(buildingDeleteRequest());
        tokenDelete(apiSettings.apiURLReferences + '/buildings/' + id).then(async (response) => {
            dispatch(buildingDeleteSuccess({
                building: response.data
            }));
            await setters().getFactoryTreeViewById(factoryId, notif)
        })
            .catch((error) => {
                if (error.message.indexOf(500) >= 0 || error.message.indexOf(422) >= 0){
                    dispatch(notificationError('Cannot delete because it is currently referenced to another record'));
                }
                console.log(error);
            });
    }
}

export const getBuildings = ()=>{
    return function (dispatch) {
        dispatch(buildingRequest());
        tokenGet(apiSettings.apiURLReferences + '/buildings').then((response) => {
            dispatch(buildingSuccess({
                buildingList: response.data.data
            }));
        })
            .catch((error) => {
                console.log(error);
            });
    }
}