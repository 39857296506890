import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Grid } from '@material-ui/core';
import MainPage from '../../../components/MainPage/MainPage';
import PageHeader from '../../../components/PageHeader/PageHeader';
import PageBody from '../../../components/PageBody/PageBody';
import NewRequestForm from './Form/InfoForm';
import LineSetup from './Form/LineSetup';
import PageActions from './Form/PageActions';
import { stickyTop } from './Form/utils/sticky-plugin';
import { useSticky } from "react-hook-sticky";
import validate from 'validate.js';
import { changeoverRules } from './Form/validations';
import { machineClient } from '../../../utils/api/CoreApi';
import { serializeForm } from './helpers';
import { connect } from 'react-redux';

export const breadcrumbs = [
  {
    text: 'Tickets',
  },
  {
    text: 'Changeovers Requests',
    href: '/changeovers',
  },
  {
    text: 'Add Changeover Request',
    href: '/changeover-add',
  },
];


const stickyConfig = {
  context: "sitcky-form",
  plugin: stickyTop,
};

function NewChangeover(props){
  const history = useHistory();
  const { createBoundary } = useSticky(stickyConfig);
  const [form, setForm] = useState({ machines: [] });
  const errors = validate(form, changeoverRules);
  const isValid = !errors;

  const cancel = () => {
    history.goBack();
  }

  const handleSubmit = () => {
    if(!isValid) return;
    const data = serializeForm(form);
    machineClient.post("/co-requests", data).then(()=>{
      history.push("/changeovers");
    });
  }

  return (
    <MainPage footer={false}>
      <PageHeader breadcrumbs={breadcrumbs} />
      <PageBody p={1}>
        <Grid container spacing={1}>
          <Grid style={{ position: 'relative' }} item xs={3} ref={createBoundary('outer')}>
            <NewRequestForm
              title={'Add Changeover Request'.translate()}
              stickyBounday={createBoundary('sticky')}
              data={form}
              onChange={(attributes) => setForm({ ...form, ...attributes })}
            />
          </Grid>
          <Grid item xs={9}>
            <LineSetup
              machines={form.machines}
              updateMachines={(machines) => setForm({ ...form, machines })}
            />
          </Grid>
        </Grid>
        <PageActions
          isValid={isValid}
          onSubmit={handleSubmit}
          onCancel={cancel}
        />
      </PageBody>
    </MainPage>
  );
}

const mapStateToProps = state => ({...state.translationReducer});
export default connect(mapStateToProps, null)(NewChangeover);