import { format, parseISO } from 'date-fns';
import { apiSettings } from '../../../utils/api/CoreApi';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { formatDate } from '../../../utils/date';

export const escapePhrase = (phrase, params) => {
  if (params.length === 0) return phrase;
  return params.reduce((carry, value, index) => {
    const tmp = carry.replaceAll(`%{${index}}`, value);
    return tmp;
  }, phrase);
}

export const serializeForm = (attributes)=>{
  return {
    coRequestDt: attributes.changeoverDate,
    style: attributes.style,
    areaId: attributes.areaId,
    mfgLineId: attributes.lineId,
    changeoverTypeId: attributes.changeoverTypeId,
    changeoverChecklistId: attributes.changeoverChecklistId,
    productType: attributes.productType,
    remarks: attributes.remarks,
    items: attributes.machines.map((machine)=> ({
      id: machine.id,
      number: machine.station,
      macTypeId: machine.machineTypeId,
      macSubTypeId: machine.machineSubTypeId,
      needleType: machine.needleType,
      operation: machine.operation,
      remarks: machine.remarks,
      isCritical: machine.isCritical || false,
      attachments: (machine.attachments || []).map(({ id })=> id),
    })),
  }
};

const parseDate = (value)=>{
  if(!value) return null

  return format(parseISO(value), "yyyy-MM-dd")
}

export const deserializeList = (data)=>{
  return data.map((changeover)=>({
    isSelected: false,
    id: changeover.id,
    code: changeover.coRequestNo,
    style: changeover.style,
    lineId: changeover.mfgLineId,
    line: changeover.mfgline,
    coDate: moment(changeover.coRequestDt),
    coDateDisplay: moment(changeover.coRequestDt).format('yyyy-MM-DD') || '-',
    coType: changeover.type,
    coChecklist: changeover.checklist,
    machineQty: changeover.mcQty,
    criticalQty: changeover.criticalMcQty,
    statusCode: parseInt(changeover.status),
    status: changeover.statusDesc,
    thirdPartySystem: changeover.thirdPartySystem,
    modifiedBy: changeover.updatedBy,
    modifiedDate: formatDate(changeover.updatedDt, 'yyyy-MM-DD'),
    createdBy: changeover.createdBy,
    createdDate: formatDate(changeover.createdDt, 'yyyy-MM-DD'),
  }))
};

const imageUrl = (imgLink)=>{
  if(!imgLink) return null;
  let token = JSON.parse(localStorage.getItem('userToken'));

  const imgUrl = apiSettings.apiURLFiles + "/api/files/by-name?path=" + imgLink + "&token=" + token + '&unlinked'
  return imgUrl;
}


const deserializeAttachment = ({ id, desc1, imgLink })=>{
  return {
      id,
      name: desc1,
      imgUrl: imageUrl(imgLink),
  }
}


export const deserializeDetail = (data)=>{
  return {
    id: data.id,
    code: data.coRequestNo,
    style: data.style,
    area: data.area,
    lineId: data.mfgLineId,
    line: data.mfgline,
    coDate: moment(data.coRequestDt),
    coDateDisplay: formatDate(data.coRequestDt),
    type: data.type,
    checklist: data.checklist,
    status: data.statusDesc,
    modifiedBy: data.updatedBy,
    statusCode: data.status,
    modifiedDate: formatDate(data.updatedDt, 'yyyy-MM-DD'),
    createdBy: data.createdBy,
    createdDate: formatDate(data.createdDt, 'yyyy-MM-DD'),
    productType: data.productType,
    remarks: data.remarks,
    countStations: countUniqueStations(data.items),
    machines: data.items.map((machine)=>({
      id: machine.id,
      station: machine.number,
      machineTypeId: machine.macTypeId,
      machineSubTypeId: machine.macSubTypeId,
      machineType: machine.macType,
      machineSubType: machine.subType,
      needleType: machine.needleType,
      operation: machine.operation,
      remarks: machine.remarks,
      isCritical: machine.isCritical,
      attachments: machine.attachments.map((a)=> deserializeAttachment(a)),
    })),
  }
}

export const deserializeEdit = (data)=>{
  return {
    id: data.id,
    style: data.style,
    areaId: data.areaId,
    lineId: data.mfgLineId,
    changeoverDate: formatDate(data.coRequestDt, 'yyyy-MM-DD'),
    changeoverTypeId: data.changeoverTypeId,
    changeoverChecklistId: data.changeoverChecklistId,
    productType: data.productType,
    remarks: data.remarks,
    machines: data.items.map((machine)=>({
      id: machine.id,
      station: machine.number,
      machineTypeId: machine.macTypeId,
      machineSubTypeId: machine.macSubTypeId,
      machineType: machine.macType,
      machineSubType: machine.subType,
      needleType: machine.needleType,
      operation: machine.operation,
      remarks: machine.remarks,
      isCritical: machine.isCritical,
      attachments: machine.attachments.map((a)=> deserializeAttachment(a)),
    })),
  }
}

const countUniqueStations = items => {
    return new Set(items.map(it => it.number)).size;
}