import {
    NOTIFICATION_SUCCESS,
    NOTIFICATION_ERROR,
    NOTIFICATION_CLOSE
 } from '../actionTypes';
 import { capitalize } from '../../../utils/helper';
 
 export const notificationSuccess = (message) => {
     let capitalizeMessage = capitalize(message);
     return {
         type:NOTIFICATION_SUCCESS,
         payload: {
             message: capitalizeMessage.translate()
         }
     }
 }

 export const notificationError = (message) => {
    let capitalizeMessage = capitalize(message);
    return {
        type:NOTIFICATION_ERROR,
        payload: {
            message: capitalizeMessage.translate()
        }
    }
}
 
 export const notificationClose = () => {
     return {
         type:NOTIFICATION_CLOSE
     }
 }