import {
    NOTIFICATION_SUCCESS,
    NOTIFICATION_ERROR,
    MACTYPE_LIST,
    MACTYPE_LIST_REQUEST,
    MACTYPE_LIST_SUCCESS,
    MACTYPE_LIST_ERROR,
    MACTYPE_DETAIL_REQUEST,
    MACTYPE_DETAIL_SUCCESS,
    MACTYPE_DETAIL_ERROR,
    MACTYPE_NEW_REQUEST,
    MACTYPE_NEW_SUCCESS,
    MACTYPE_NEW_ERROR,
    MACTYPE_UPDATE_REQUEST,
    MACTYPE_UPDATE_SUCCESS,
    MACTYPE_UPDATE_ERROR,
    MACTYPE_DELETE_REQUEST,
    MACTYPE_DELETE_SUCCESS,
    MACTYPE_DELETE_ERROR,
    MACTYPE_TRIGGER_HIGHLIGHT,
 } from '../actionTypes';
 import { tokenGet, tokenPost, tokenPut, tokenDelete, apiSettings } from '../../../utils/api/CoreApi';
 import { getAllMacSubTypesTreeView } from '../MacSubTypes/MacSubTypeActions';
 import history from '../../../history';

 export const notificationSuccess = (message) => {
    return {
        type: NOTIFICATION_SUCCESS,
        payload: {
            message: message.translate()
        }
    }
}

export const notificationError = (message) => {
    return {
        type: NOTIFICATION_ERROR,
        payload: {
            message: message.translate()
        }
    }
}
 
 export const macTypeRequest = () => {
     return {
         type: MACTYPE_LIST_REQUEST
     }
 }
 
 export const macTypeSuccess = statusList => {
     return {
         type: MACTYPE_LIST_SUCCESS,
         payload: statusList
     }
 }

 export const macTypeDetailRequest = () => {
    return {
        type: MACTYPE_DETAIL_REQUEST
    }
}

export const macTypeDetailSuccess = macType => {
    return {
        type: MACTYPE_DETAIL_SUCCESS,
        payload: macType
    }
}

 export const macTypeNewRequest = () => {
    return {
        type: MACTYPE_NEW_REQUEST
    }
}

export const macTypeNewSuccess = macType => {
    return {
        type: MACTYPE_NEW_SUCCESS,
        payload: macType
    }
}

export const macTypeUpdateRequest = () => {
    return {
        type: MACTYPE_UPDATE_REQUEST
    }
}

export const macTypeUpdateSuccess = macType => {
    return {
        type: MACTYPE_UPDATE_SUCCESS,
        payload: macType
    }
}

export const macTypeDeleteRequest = () => {
    return {
        type: MACTYPE_DELETE_REQUEST
    }
}

export const macTypeDeleteSuccess = macType => {
    return {
        type: MACTYPE_DELETE_SUCCESS,
        payload: macType
    }
}

export const macTypeTriggerHighlight = flag => {
    return {
        type: MACTYPE_TRIGGER_HIGHLIGHT,
        payload: flag
    }
}
 
 export const getMacTypes = (isActive = 1, currentPage = 1, pageSize = 100000) =>{
     return function(dispatch){
         dispatch(macTypeRequest());
         tokenGet(apiSettings.apiURLReferences + '/mactypes?isActive='+ isActive +'&currentPage=' + currentPage +'&pageSize=' + pageSize).then((response) => {
             let data = response.data.machines.result.map((item) => {
                let itemRow = {
                    'id': item.id,
                    'macType': item.macType,
                    'desc': item.desc1
                }
                return itemRow;
              });
             dispatch(macTypeSuccess({
                 macTypeList: data,
                 meta: response.data.machines.meta
             }));
         })
         .catch((error) => {
             console.log(error);
         });
     }
 }

 export const getMacTypeDetail = (id) =>{
    return function(dispatch){
        dispatch(macTypeDetailRequest());
        tokenGet(apiSettings.apiURLReferences + '/mactypes/' + id).then((response) => {
            dispatch(macTypeDetailSuccess({
                macTypeDetail: response.data.machine[0],
            }));
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

 export const postMacTypeNew = (payload) =>{
    let notif = notificationSuccess('Successfully added machine type.')
    return function(dispatch){
        dispatch(macTypeNewRequest());
        tokenPost(apiSettings.apiURLReferences + '/mactypes',payload).then((response) => {
            dispatch(getAllMacSubTypesTreeView(notif));
            history.push('/configuration/machine-config/machine-type');
            // dispatch(macTypeNewSuccess({
            //     macType: response.data,
            // }));
        })
        .catch((error) => {
            if (error.response.data.error.indexOf('Duplicate') >= 0){
                dispatch(notificationError('Machine type code exists'));
                dispatch(macTypeTriggerHighlight(true));
            }
            else{
                dispatch(notificationError(error.response.data.error));
            }
        });
    }
}

export const putMacTypeUpdate = (id,payload) =>{
    let notif = notificationSuccess('Successfully updated machine type.')
    return function(dispatch){
        dispatch(macTypeDeleteRequest());
        tokenPut(apiSettings.apiURLReferences + '/mactypes/' + id,payload).then((response) => {
            dispatch(getAllMacSubTypesTreeView(notif));
            history.push('/configuration/machine-config/machine-type');
        })
        .catch((error) => {
            if (error.response.data.error.indexOf('Duplicate') >= 0){
                dispatch(notificationError('Machine type code exists'));
                dispatch(macTypeTriggerHighlight(true));
            }
            else{
                dispatch(notificationError(error.response.data.error));
            }
        });
    }
}

export const deleteMacType = (id) =>{
    let notif = notificationSuccess('Successfully deleted machine type.')
    return function(dispatch){
        dispatch(macTypeDetailRequest());
        tokenDelete(apiSettings.apiURLReferences + '/mactypes/' + id).then((response) => {
            dispatch(getAllMacSubTypesTreeView(notif));
        })
        .catch((error) => {
            if (error.message.indexOf(500) >= 0){
                dispatch(notificationError('Cannot delete because it is currently referenced to another record'));
            }
            console.log(error);
        });
    }
}