import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { getUserToken, checkIfSuperAdmin, isSuperAdmin, checkPageActionAccess, checkPageAccessField } from '../../../../../utils/helper';
import { apiSettings } from '../../../../../utils/api/CoreApi';
import CameraIcon from '../../../../../assets/img/Icon metro-camera.svg';
import history from '../../../../../history';
import { Link } from 'react-router-dom';
import { showModal } from '../../../../../redux/actions/Modal/modalActions';
import Table from '../../../../../components/CollapsableTable/CollapseTable'
import { notificationError } from '../../../../../redux/actions/Notification/notificationActions'
import { getRoles } from '../../../../../redux/actions/Role/roleActions'
import { postNewUser, userChangeProfile, uploadUserProfile, userTriggerHighlight } from '../../../../../redux/actions/User/userActions'
import { getFactoryByUser, getUserDetail, putUpdateUser } from '../../../../../redux/actions/User/userActions'
import dictionary from '../../../../../redux/actions/Translation/dictionary'
import MainPage from '../../../../../components/MainPage/MainPage'
import MainPageHeader from '../../../../../components/MainPageHeader/MainPageHeader'
import MainPageBody from '../../../../../components/MainPageBody/MainPageBody';
import CropImage from '../../../../../components/Modal/CropImage/CropImage';
import ChangePassword from '../../../../../components/Modal/ChangePassword/ChangePassword';
import { validateFields } from './FormValidation';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import setters from '../../../../../redux/actions/Factory/factoryActions'
import { Paper, FormLabel, FormControlLabel, Radio, RadioGroup, Grid, FormControl, TextField, Select, MenuItem, Button, InputAdornment, IconButton, Input, InputLabel } from '@material-ui/core'
import './UserAccessProfile.scss';

class UserAccessProfile extends React.Component {
    constructor(props) {
        super(props)
        this.profileRef = React.createRef();
        this.state = {
            id: null,
            initialLoad:true,
            oldForm: null,
            trigger: false,
            superAdmin: false,
            form: {
                name: null,
                userName: null,
                password: null,
                confirmPassword: null,
                cardNo: "",
                email: "",
                roleId: null,
                avatar: null,
                provider: null,
                factory: [],
                building: [],
                floor: [],
                area: [],
                line: [],
                isActive: null,
            },
            loaded: false,
            uploadImage: null,
            cropSize: null,
            cropShape: null,
            cropName: null,
            cropTitle: null,
            passwordShown: null,
            confirmPasswordShown: null,
            formErrors: [],
            hasFormChanges: false,
            checkboxIsTicked:false,
            translations: {
                ...this.convert()
            },
            translationTimestamp: undefined,
            tableState: {
                search: false,
                // parentCollapse: true,
                widthStyle: 'vw',
                checkboxKey: 'hasAccess',
                root: 'factory',
                checkBox_tick: ({ rowValue = {}, from = '', prevId = undefined }, value, ev = false) => {
                    if (checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Add'}) || checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Edit'}) || isSuperAdmin()){
                        // console.log('tick', rowValue, from, prevId, value, this.state.form)
                        let id = rowValue?.id
                        let { form: { factory, building, floor, line, area } } = this.state
                        const pushNotExist = (id, arr) => {
                            if (Array.isArray(arr)) {
                                let index = arr.findIndex(x => x == id)
                                if(index == -1){
                                    if(value == true) arr.push(id)
                                }
                                else{
                                    if(value == false) arr.splice(index, 1)
                                }
                                return arr
                            }
                            else return []
                        }
                        if (id != undefined) {
                            let saveState = null;
                            switch (from) {
                                case 'factory':
                                    factory = pushNotExist(id, factory)
                                    this.setState({
                                        form: {
                                            ...this.state.form,
                                            factory
                                        },
                                        checkboxIsTicked:true,
                                        hasFormChanges: ev === true || this.state.hasFormChanges === true ? true : false,
                                    });
                                    break;
                                case 'buildings':
                                    building = pushNotExist(id, building)
                                    this.setState({
                                        form: {
                                            ...this.state.form,
                                            building
                                        },
                                        checkboxIsTicked:true,
                                        hasFormChanges: ev === true || this.state.hasFormChanges === true ? true : false,
                                    })
                                    break;
                                case 'floors':
                                    floor = pushNotExist(id, floor)
                                    this.setState({
                                        form: {
                                            ...this.state.form,
                                            floor
                                        },
                                        checkboxIsTicked:true,
                                        hasFormChanges: ev === true || this.state.hasFormChanges === true ? true : false,
                                    })
                                    break;
                                case 'areas':
                                    area = pushNotExist(id, area)
                                    this.setState({
                                        form: {
                                            ...this.state.form,
                                            area
                                        },
                                        checkboxIsTicked:true,
                                        hasFormChanges: ev === true || this.state.hasFormChanges === true ? true : false,
                                    })
                                    break;
                                case 'lines':
                                    line = pushNotExist(id, line)
                                    this.setState({
                                        form: {
                                            ...this.state.form,
                                            line
                                        },
                                        checkboxIsTicked:true,
                                        hasFormChanges: ev === true || this.state.hasFormChanges === true ? true : false,
                                    })
                                    break;
                            }
                        }
                    }

                },
                addToolTip: this.state?.translations?.ADD_BUILDING,
                keys: this.initTableHeader(),
                data: [],
                loading: false,
                collapsable: true,
                levelInstance: {
                    parent: 'details',
                    child: [
                        'buildings',
                        'floors',
                        'areas',
                        'lines'
                    ]
                },
                timeStamp: undefined,
                tableTitle:'#515151',
            }
        }
    }
    /**handler */
    initTableHeader = () => {
        let { STATUS, NAME, TYPE, CODE, DESCRIPTION, ACCESS } = this.convert()
        return [
            {
                name: NAME,
                childKey: 'name',
                key: 'name',
                headerWidth: '17',
                width: '15.9',
                childStyles: ['14.5', '13.8', '13.2']
            },
            {
                name: CODE,
                childKey: 'code',
                key: 'code',
                headerWidth: '6',
                width: '6',
                childStyles: ['6', '6', '6']
            },
            {
                name: TYPE,
                childKey: 'type',
                key: 'type',
                headerWidth: '6',
                width: '6',
                childStyles: ['6', '6', '6']
            },
            {
                name: DESCRIPTION,
                childKey: 'description',
                key: 'description',
                headerWidth: '13',
                width: '13',
                childStyles: ['13', '13', '13']
            },
            {
                name: STATUS,
                // type: 'boolean',
                key: 'status',
                childKey: 'status',
                headerWidth: '8',
                 width: '7.9',
                 childStyles: ['7.95', '7.95','7.9']
            },
            {
                name: ACCESS,
                type: 'checkbox',
                key: 'hasAccess',
                childKey: 'hasAccess',
                alt: 'hasUserAccess',
            },
        ]
    }
    convert = () => {
        let {Confirm_Password, Password, SAVE, ADD, YES, NO,CANCEL,
            FACTORY_CODE, MANAGE_FACTORY,
            USER_PROFILE, USER_ACCESS_RIGHT,
            NOTIFICATIONS: {username_exists, email_exist, card_id_exist, are_you_sure_you_want_to_cancel_changes},
            Active, Inactive,LAST_ACCESS_AT, UPDATED_BY, UPDATED_DATE, CREATED_BY, CREATED_DATE, 
            STATUS, NAME, TYPE, CODE, DESCRIPTION, ACCESS, CARD_ID, EMAIL, ROLE, LOGIN_ID, CHANGE_PASSWORD, SUPERADMIN_HAS_DEFAULT_ACCESS_TO_ALL_FACTORY, UPLOAD_USER_IMAGE, 
        } = dictionary.GET()
        return dictionary.TRANSLATE({
            Confirm_Password, Password, SAVE, ADD, YES, NO,CANCEL,
            FACTORY_CODE, MANAGE_FACTORY,
            USER_PROFILE, USER_ACCESS_RIGHT,
            username_exists, email_exist, card_id_exist, are_you_sure_you_want_to_cancel_changes,
            Active, Inactive,LAST_ACCESS_AT, UPDATED_BY, UPDATED_DATE, CREATED_BY, CREATED_DATE,
            STATUS, NAME, TYPE, CODE, DESCRIPTION, ACCESS, CARD_ID, EMAIL, ROLE, LOGIN_ID, CHANGE_PASSWORD, SUPERADMIN_HAS_DEFAULT_ACCESS_TO_ALL_FACTORY, UPLOAD_USER_IMAGE, 
        })
    }
    componentDidMount() {
        this.props.getRoles();
        if (this.props.match.params.id) {
            let id = this.props.match.params.id
            this.setState({ id: id });
            this.props.getFactoryByUser(id);
            this.props.getUserDetail(id);
        }
        else {
            this.props.getFactoryTreeView(undefined, true);
        }
        if(this.state.initialLoad === true){
            localStorage.setItem('webUrl', window.location.pathname) 
            this.setState({
                initialLoad:false,
            })
        }
    }

    componentDidUpdate() {
        // console.log('didUpdate', this.props, this.state)
        let { loading, userProfileFactoriesTree,userProfileFactoriesFiltered, userProfileFactoriesTreeTimeStamp, factoryListTree, factoryTimeStamp, userProfileDetailsTree } = this.props
        let { tableState: { loading: stateLoading, data, timeStamp, levelInstance: { parent, child } }, id = undefined } = this.state

        let assignedFactories = []

        if (Array.isArray(userProfileFactoriesTree) && Array.isArray(userProfileDetailsTree) && (isSuperAdmin() == false)) {
            if (localStorage['assignedFactories'] != undefined) assignedFactories = localStorage['assignedFactories'].split(',')

            let returnFactory = []
            assignedFactories.forEach(x => {
                for (let s = 0; s < userProfileFactoriesTree.length; s++) {
                    if (userProfileFactoriesTree[s][parent]['code'] == x.replace(/\s/gi, '')) returnFactory.push(userProfileFactoriesTree[s])
                }
            })
            userProfileFactoriesTree = returnFactory

        }

        /**edit user access factory */
        if (userProfileFactoriesFiltered?.length != data?.length && userProfileFactoriesFiltered != undefined || userProfileFactoriesTreeTimeStamp != timeStamp) {
            if (Array.isArray(userProfileFactoriesFiltered) && id != undefined) {
                this.setState({
                    tableState: {
                        ...this.state.tableState,
                        data: [...userProfileFactoriesFiltered],
                        loading,
                        timeStamp: userProfileFactoriesTreeTimeStamp
                    }
                }, () => {
                    console.log('data', userProfileFactoriesTree);
                    let factoryCheck = userProfileFactoriesTree.filter((item) => { return item.details.code === '000' && item.details.hasAccess === 1 });
                    if (factoryCheck.length > 0){
                        if (checkIfSuperAdmin(factoryCheck[0].details.id)){
                            this.setState({
                                superAdmin:true,
                                tableState: {
                                    ...this.state.tableState,
                                    superAdminMessage: this.convert().SUPERADMIN_HAS_DEFAULT_ACCESS_TO_ALL_FACTORY
                                }
                            });
                        }
                    }
                    else{
                        let tableState = this.state.tableState;
                        if (checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Add'}) === false && checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Edit'}) === false){
                            tableState = {
                                ...tableState,
                                // checkBoxIsRead: true
                            }
                        }
                        this.setState({
                            superAdmin:false,
                            tableState: {
                                ...tableState,
                                superAdminMessage: null
                            }
                        })
                    };
                })
            }
        }
        if (loading != stateLoading || factoryListTree?.length != data?.length && factoryListTree != undefined || factoryTimeStamp != timeStamp) {
            if (Array.isArray(factoryListTree) && (id == undefined || data.length <= 0)) {
                this.setState({
                    tableState: {
                        ...this.state.tableState,
                        data: [...factoryListTree],
                        loading,
                        timeStamp: factoryTimeStamp
                    }
                })
            }

        }
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                translations: Object.assign({}, { ...this.convert() }),
                tableState: {
                    ...this.state.tableState,
                    keys: this.initTableHeader(),
                    timeStamp: new Date()
                }
            })
        }



    }

    componentWillUpdate(nextProps, nextState) {
        console.log('component will update')
        if(nextState.form.area.length > 0 || nextState.form.building.length > 0 || nextState.form.factory.length > 0 || nextState.form.floor.length > 0 || nextState.form.line.length > 0){
            if(this.state.hasFormChanges === true){
                if(nextState.checkboxIsTicked === true){
                // console.log('nagticked yung checkbox')
                let payload = {
                    ...nextState.form,
                };
                let formValidation = validateFields(payload);
                let tableTitleColor = this.tableHasErrors(formValidation.errors)
                this.setState({tableTitle:tableTitleColor, checkboxIsTicked:false });
            }
        }
        }

    }

    sanitizeEmail(email){
        if (email === null || email === 'null'){
            return null;
        }
        else{
            return email;
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.userDetail != null && this.props.match.params.id && this.state.loaded === false) {
            let tmpForm = {
                ...this.state.form,
                name: nextProps.userDetail.name,
                userName: nextProps.userDetail.userName,
                password: nextProps.userDetail.password,
                confirmPassword: nextProps.userDetail.password,
                cardNo: nextProps.userDetail.cardNo,
                email: this.sanitizeEmail(nextProps.userDetail.email),
                roleId: nextProps.userDetail.roleId,
                avatar: nextProps.userDetail.avatar,
                provider: nextProps.userDetail.provider,
                isActive: this.renderBooleanAsInteger(nextProps.userDetail.status),
            };
            let oldData = {
                ...tmpForm
            }
            this.setState({ form: tmpForm, oldForm: oldData, loaded: true });
        }
    }

    handleProfileUpload = () => {
        this.resetCrop();
        this.profileRef.current.click();
    }

    handleProfileFileChange = ({ target }) => {
        let {translations} = this.state
        let filename = target.files[0].name.toLowerCase();
        if (filename.indexOf('.jpg') >= 0 || filename.indexOf('.jpeg') >= 0 || filename.indexOf('.png') >= 0) {
            let base64Image = this.toBase64(target.files[0]).then((result) => { return result });
            base64Image.then((result) => {
                let state = {
                    uploadImage: result,
                    cropName: "user-profile",
                    cropTitle: translations.UPLOAD_USER_IMAGE,
                    cropShape: "round",
                    cropSize: { height: 291, width: 291 }
                }
                this.setState(state, () => {
                    this.props.showModal('cropImage');
                });
            })
        }
        else {
            this.props.notificationError('File not supported');
        }
    }

    openModal = (modalName, parentId = null, id = null) => {
        this.setState({
            modalOpen: {
                name: modalName,
                id: id,
                parentId: parentId,
                saveLabel: id ? this.state?.translations?.ADD : this.state?.translations?.SAVE,
            }
        });
    }

    closeModal = () => {
        this.setState({
            modalOpen: {
                name: null,
                id: null,
                saveLabel: null,
            }
        });
    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    resetCrop() {
        this.setState({
            uploadImage: null,
            cropSize: null,
            cropShape: null,
            cropName: null,
            cropTitle: null,
        });
        this.profileRef.current.value = null;
    }


    componentWillUnmount() {
    }

    handleFormChange = ({ target }) => {
        let tmpForm = this.state.form;
        tmpForm[target.name] = target.value;
        this.setState({ form: tmpForm, hasFormChanges: true });
        this.removeErrorOnChange(target.name);
    };

    handleCodeFormChange = ({ target }) => {
        let value = null;
        if (target.value.indexOf(" ") >= 0) {
            value = target.value.replace(" ", "");
        }
        else {
            value = target.value;
        }
        let tmpForm = this.state.form;
        tmpForm[target.name] = value;
        this.setState({ form: tmpForm, hasFormChanges: true });
        this.removeErrorOnChange(target.name);
    };

    handleRadioButtonChange = ({ target }) => {
        let tmpForm = this.state.form;
        tmpForm[target.name] = parseInt(target.value);
        this.setState({ form: tmpForm, hasFormChanges: true });
        this.removeErrorOnChange(target.name);
    };


    renderBooleanAsInteger(bool) {
        if (bool === true || bool === "True" || bool === 'Active') {
            return 1
        }
        else {
            return 0
        }
    }

    renderText(field) {
        if (field === null || field === '') {
            return '-'
        }
        else {
            //   return field != undefined ? field.toString().translate() : field
            return field
        }
    }

    renderDate(date) {
        if (date !== null) {
            let timezone = moment.parseZone(date).format('Z');
            let dateTimeOnly = moment.parseZone(date).format('YYYY-MM-DD HH:mm:ss') + '+00:00';
            return moment(dateTimeOnly).utcOffset(timezone).format('DD-MM-YYYY');
        }
        else {
            return '-';
        }
    }

    renderDateTime(date) {
        if (date !== null) {
            let timezone = moment.parseZone(date).format('Z');
            let dateTimeOnly = moment.parseZone(date).format('YYYY-MM-DD HH:mm:ss') + '+00:00';
            return moment(dateTimeOnly).utcOffset(timezone).format('DD-MM-YYYY HH:mm');
        }
        else {
            return '-';
        }
    }

    handleChangePassword = () => {
        this.props.showModal('changePassword');
    }

    handleTogglePassword = () => {
        this.setState({ passwordShown: !this.state.passwordShown });
    }

    handleToggleConfirmPassword = () => {
        this.setState({ confirmPasswordShown: !this.state.confirmPasswordShown });
    }

    getImageLink(link) {
        let userToken = getUserToken();
        let fileLink = apiSettings.apiURLFiles + btoa("/api/files/by-name?path=" + link + "&token=" + userToken + '&unlinked');
        return fileLink;
    }

    renderProfileImage() {
        if (this.state.form.avatar) {
            return this.getImageLink(this.state.form.avatar);
        }
        else {
            return null
        }
    }

    renderStatusForm() {
        let {translations} = this.state
        return (
            <div>
                <Paper className="status-form end">
                    <div className="form-input">
                        <FormControl className="control-row" fullWidth>
                            <Grid container spacing={0}>
                                <Grid item xs={5}>
                                    <FormLabel className="status" component="legend">{translations.STATUS}</FormLabel>
                                </Grid>
                                <Grid item xs={7}>
                                    <RadioGroup onChange={this.handleRadioButtonChange} name="isActive" className="radioButton" row value={this.state.form.isActive}>
                                        <FormControlLabel value={1} control={<Radio disabled={this.state.superAdmin || checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Edit'}) === false} color="primary" />} label={translations.Active} />
                                        <FormControlLabel value={0} control={<Radio disabled={this.state.superAdmin || checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Edit'}) === false} color="primary" />} label={translations.Inactive} />
                                    </RadioGroup>
                                </Grid>
                                <Grid className="statusDetails" item xs={12}>
                                    <FormLabel component="legend">{translations.LAST_ACCESS_AT}: {this.props.userDetail ? this.renderDateTime(this.props.userDetail.lastAccessDt) : null}</FormLabel>
                                </Grid>
                                <Grid className="statusDetails" item xs={12}>
                                    <FormLabel component="legend">{translations.UPDATED_BY}: {this.props.userDetail ? this.renderText(this.props.userDetail.updatedBy) : null}</FormLabel>
                                </Grid>
                                <Grid className="statusDetails" item xs={12}>
                                    <FormLabel component="legend">{translations.UPDATED_DATE}: {this.props.userDetail ? this.renderDate(this.props.userDetail.updatedDt) : null}</FormLabel>
                                </Grid>
                                <Grid className="statusDetails" item xs={12}>
                                    <FormLabel component="legend">{translations.CREATED_BY}: {this.props.userDetail ? this.renderText(this.props.userDetail.createdBy) : null}</FormLabel>
                                </Grid>
                                <Grid className="statusDetails" item xs={12}>
                                    <FormLabel component="legend">{translations.CREATED_DATE}: {this.props.userDetail ? this.renderDate(this.props.userDetail.createdDt) : null}</FormLabel>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </div>
                </Paper>
            </div>
        )
    }

    removeErrorOnChange(field) {
        let errors = this.state.formErrors.filter((item) => { return item.name !== field });
        this.setState({ formErrors: errors });
    }

    hasErrors = (field) => {
        let checkField = this.state.formErrors.filter((item) => { return item.name === field });
        if (checkField.length > 0) {
            return true
        }
        else {
            return false;
        }
    }

    tableHasErrors = (formErrors) => {
        let checkField = formErrors.filter((item) => { return item.name === 'factory' ||
        item.name === 'building' ||
        item.name === 'floor' ||
        item.name === 'area' ||
        item.name === 'line' });
        if (checkField.length > 0) {
            return '#f44336';
        }
        else {
            return '#4CAF50';
        }
    }

    getErrors = (field) => {
        let checkField = this.state.formErrors.filter((item) => { return item.name === field });
        let errorString = null;
        checkField.map((item) => {
            errorString += item.message + '. ';
            return '';
        })
        return errorString;
    }



    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    resetChanges(form) {
        this.setState({
            hasFormChanges: false,
            trigger: false,
            // form: form
        });
    }

    submitForm = () => {
        let payload = {
            ...this.state.form,
        };

        let formValidation = validateFields(payload);
        let tableTitleColor = this.tableHasErrors(formValidation.errors)
        this.setState({ formErrors: formValidation.errors, tableTitle:tableTitleColor });
        if (formValidation.valid) {
            this.setState({ disablePrompt: true, trigger: false }, () => {
                if (this.props.userProfile) {

                    let fd = new FormData();
                    let blobImage = this.dataURLtoFile(this.props.userProfile, 'sample.jpeg');
                    fd.append('file', blobImage);
                    fd.append('str', 'stronly');

                    if (this.state.id === null) {
                        payload = {
                            ...payload,
                            isActive: 1,
                        }
                        this.props.uploadUserProfile(payload, fd);
                    }
                    else {
                        payload = {
                            ...payload,
                        }
                        this.props.uploadUserProfile(payload, fd, this.state.id);
                        setTimeout(() => {
                            this.resetChanges(payload);
                        },700);
                    }
                }
                else {
                    if (this.state.id === null) {
                        payload = {
                            ...payload,
                        }
                        this.props.postNewUser(payload);
                    }
                    else {
                        payload = {
                            ...payload,
                        }
                        this.props.putUpdateUser(this.state.id, payload);
                        this.resetChanges(payload);
                    }
                }
            });
        }
        else {
            this.props.notificationError(formValidation.errors[0].message);
        }
    }

    renderRoles() {
        if (this.props.roleList) {
            console.log(this.props.roleList);
            let roles = this.props.roleList.filter((item) => {return item.isActive === true}).map((item) => {
                let itemRow = (
                    <MenuItem value={item.id}>
                        {item.role}
                    </MenuItem>
                )
                return itemRow;
            });
            return roles;
        }
        else {
            return null;
        }
    }

    renderUserInfo() {
        let {translations} = this.state
        if (this.state.id) {
            return (
                <div className="user-info">
                    <div className="small-label">{translations.LOGIN_ID}</div>
                    <div className="loginId">{this.props.userDetail ? this.props.userDetail.userName : null}</div>
                    <Link className="change-password" onClick={this.handleChangePassword}>{translations.CHANGE_PASSWORD}</Link>
                </div>
            )
        }
    }

     renderImageInfo() {
        let image_url;
        if (this.state.form.avatar) {
            image_url =  this.getImageLink(this.state.form.avatar);
        }
        else {
            image_url = null
        }
        let str = this.state.form.name
        let initialnames = ''
        if (str) {
            initialnames = str.split(' ').map(i => i.charAt(0))
        }
        console.log('check if image exists ' + this.imageExists(image_url))
        let hasPicture =  this.imageExists(image_url)
        
        if(this.props.userProfile){
            return(
                <img alt="" src={this.props.userProfile ? this.props.userProfile : this.renderProfileImage()} />
            )
        }
        else if(this.state.form.avatar !== 'null' && this.state.form.avatar !== null){
            if (this.state.form.avatar !== 'null' && this.state.form.avatar !== null){
                return(
                    <img alt="" src={this.renderProfileImage()} />
                )
            }
        }
        else{
            return (
                <div className="profilePicture">{initialnames}</div>
            )
        }
    }

    imageExists(image_url){
        
        var http = new XMLHttpRequest();
    
        http.open('HEAD', image_url, false);
        http.send();
    
        return http.status != 404;
    
    }

    renderProfileBody() {
        let {translations} = this.state
        if (this.state.id) {
            return (

                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <FormControl className="control-row" fullWidth>
                            <TextField disabled={this.state.superAdmin || (checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Add'}) === false && checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Edit'}) === false)} className={this.state.form.name ? 'label-green' : null} required error={this.hasErrors('name')} value={this.state.form.name} onChange={this.handleFormChange} name="name" label={translations.NAME} InputLabelProps={{ shrink: true }} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className="control-row" fullWidth>
                            <TextField disabled={this.state.superAdmin || (checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Add'}) === false && checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Edit'}) === false)} className={this.state.form.cardNo ? 'label-green' : null} error={this.hasErrors('cardNo')} value={this.state.form.cardNo} onChange={this.handleFormChange} name="cardNo" label={translations.CARD_ID} InputLabelProps={{ shrink: true }} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className="control-row" fullWidth>
                            <TextField disabled={this.state.superAdmin || (checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Add'}) === false && checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Edit'}) === false)} className={this.state.form.email ? 'label-green' : null} error={this.hasErrors('email')} value={this.state.form.email} onChange={this.handleFormChange} name="email" label={translations.EMAIL} InputLabelProps={{ shrink: true }} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel className={this.state.form.roleId ? 'label-green' : null} required shrink htmlFor="role">{translations.ROLE}</InputLabel>
                        <FormControl id="role" className="control-row" fullWidth>
                            <Select disabled={this.state.superAdmin || (checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Add'}) === false && checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Edit'}) === false)} error={this.hasErrors('roleId')} value={this.state.form.roleId} onChange={this.handleFormChange} name="roleId">
                                {this.props.roleList ? this.renderRoles() : null}
                                {/* <MenuItem value="2">Administrator</MenuItem>
                                <MenuItem value="3">Line Leader</MenuItem>
                                <MenuItem value="4">Mechanic</MenuItem>
                                <MenuItem value="8">TESTROLE1</MenuItem> */}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            )
        }
        else {
            return (
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <FormControl className="control-row" fullWidth>
                            <TextField disabled={checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Add'}) === false && checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Edit'}) === false} className={this.state.form.name ? 'label-green' : null} required error={this.hasErrors('name')} value={this.state.form.name} onChange={this.handleFormChange} name="name" label={translations.NAME} InputLabelProps={{ shrink: true }} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className="control-row" fullWidth>
                            <TextField disabled={checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Add'}) === false && checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Edit'}) === false} className={this.state.form.userName ? 'label-green' : null} required error={this.hasErrors('userName')} value={this.state.form.userName} onChange={this.handleCodeFormChange} name="userName" label={translations.LOGIN_ID} InputLabelProps={{ shrink: true }} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className="control-row" fullWidth>
                            <InputLabel className={this.state.form.password === this.state.form.confirmPassword && this.state.form.confirmPassword !== null ? 'label-green' : null}  error={this.hasErrors('password')} required shrink htmlFor="password">{translations.Password}</InputLabel>
                            <Input disabled={checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Add'}) === false && checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Edit'}) === false} id="password" type={this.state.passwordShown ? 'text' : 'password'} required error={this.hasErrors('password')} value={this.state.form.password} onChange={this.handleFormChange} name="password" label="Password"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.handleTogglePassword}
                                        >
                                            {this.state.passwordShown ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className="control-row" fullWidth>
                            <InputLabel className={this.state.form.password === this.state.form.confirmPassword && this.state.form.confirmPassword !== null ? 'label-green' : null}  error={this.hasErrors('confirmPassword')} required shrink htmlFor="confirmPassword">{translations.Confirm_Password}</InputLabel>
                            <Input disabled={checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Add'}) === false && checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Edit'}) === false} id="confirmPassword" type={this.state.confirmPasswordShown ? 'text' : 'password'}  required error={this.hasErrors('confirmPassword')} value={this.state.form.confirmPassword} onChange={this.handleFormChange} name="confirmPassword" label={translations.Password}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.handleToggleConfirmPassword}
                                        >
                                            {this.state.confirmPasswordShown ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className="control-row" fullWidth>
                            <TextField disabled={checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Add'}) === false && checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Edit'}) === false} className={this.state.form.cardNo ? 'label-green' : null} error={this.hasErrors('cardNo')} value={this.state.form.cardNo} onChange={this.handleFormChange} name="cardNo" label={translations.CARD_ID} InputLabelProps={{ shrink: true }} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className="control-row" fullWidth>
                            <TextField disabled={checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Add'}) === false && checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Edit'}) === false} className={this.state.form.email ? 'label-green' : null} error={this.hasErrors('email')} value={this.state.form.email} onChange={this.handleFormChange} name="email" label={translations.EMAIL} InputLabelProps={{ shrink: true }} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel className={this.state.form.roleId ? 'label-green' : null} error={this.hasErrors('roleId')} required shrink htmlFor="roleId">{translations.ROLE}</InputLabel>
                        <FormControl id="roleId" className="control-row" fullWidth>
                            <Select disabled={checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Add'}) === false && checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Edit'}) === false} error={this.hasErrors('roleId')} value={this.state.form.roleId} onChange={this.handleFormChange} name="roleId">
                                {this.props.roleList ? this.renderRoles() : null}
                                {/* <MenuItem value="2">Administrator</MenuItem>
                                <MenuItem value="3">Line Leader</MenuItem>
                                <MenuItem value="4">Mechanic</MenuItem>
                                <MenuItem value="8">TESTROLE1</MenuItem> */}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            )
        }
    }

    cancelRedirect = () => {
        history.push('/configuration/user-manage/users');
    }

    revertChanges = () => {
        let {translations} = this.state
        confirmAlert({
            title: translations.are_you_sure_you_want_to_cancel_changes,
            message: '',
            buttons: [
                {
                    label:  translations.YES,
                    onClick: () => {
                        let { id } = this.state
                        let tmpForm = {
                            ...this.state.oldForm
                             }
                            this.setState({ form: tmpForm, hasFormChanges: null, tableTitle:'#515151', });
                            this.props.getFactoryByUser(id);
                            console.log(this.state.form);
                    }
                },
                {
                    label:  translations.NO,
                }
            ]
        });
    }

    getBackLink() {
        return '/configuration/user-manage/users'
    }

    triggerFormChange(flag) {
        if (flag === true) {
            this.setState({ hasFormChanges: true, trigger: true });
        }
        else {
            this.setState({ hasFormChanges: false, trigger: false });
        }
    }

    renderCameraIcon(){
        if (checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Add'}) || checkPageActionAccess(this.props.userAccess,{pageId:25,pageAction:'Edit'}) || isSuperAdmin()){
            return (
                <div onClick={this.handleProfileUpload} className="profile-icon camera-icon">
                    <img src={CameraIcon}></img>
                </div>
            )
        }
    }

    checkUserTriggerHighlight(){
        let {translations} = this.state
        if (this.props.triggerHighlight === 'username'){
            let errors = [];
            let formValid = false;
            errors.push({name:'userName',message: translations.username_exists});

            let tmpFormError = {
                valid: formValid,
                errors: errors
            }

            this.setState({formErrors: tmpFormError.errors},() => {
                this.props.userTriggerHighlight(null);
            });
        }
        else if (this.props.triggerHighlight === 'email'){
            let errors = [];
            let formValid = false;
            errors.push({name:'email',message: translations.email_exist});

            let tmpFormError = {
                valid: formValid,
                errors: errors
            }

            this.setState({formErrors: tmpFormError.errors},() => {
                this.props.userTriggerHighlight(null);
            });
        }
        
        else if (this.props.triggerHighlight === 'cardNo'){
            let errors = [];
            let formValid = false;
            errors.push({name:'cardNo',message: translations.card_id_exist});

            let tmpFormError = {
                valid: formValid,
                errors: errors
            }

            this.setState({formErrors: tmpFormError.errors},() => {
                this.props.userTriggerHighlight(null);
            });
        }
    }

    /**render */
    render() {
        let {translations} = this.state
        return (
            <MainPage className="user-profile-config">
                { this.checkUserTriggerHighlight() }
                {this.props.hasFormChanges === true && this.state.trigger === false ? this.triggerFormChange(true) : null}
                <MainPageHeader title="User Management" search={false} back={this.getBackLink()} />
                <MainPageBody>
                    <div className="factory-sublist-body">
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Paper>
                                    <div className="user-profile-form">
                                        <div className="profile-header">
                                            <Grid container spacing={0}>
                                                <Grid item xs={8}>
                                                    <div className="title">{translations.USER_PROFILE}</div>
                                                    {this.renderUserInfo()}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <div className="profile-image">

                                                        <div className="user-image">
                                                            <input onChange={this.handleProfileFileChange} className="hidden-file" ref={this.profileRef} type="file" />
                                                            {this.renderImageInfo()}
                                                        </div>
                                                        { this.renderCameraIcon() }
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className="profile-body">
                                            {this.renderProfileBody()}
                                        </div>
                                    </div>
                                </Paper>
                                {this.state.id ? this.renderStatusForm() : null}
                            </Grid>
                            <Grid item xs={9}>
                                <Paper className="fullHeight">
                                    <div className="factory-table" style={{color:this.state.tableTitle}}>
                                       {translations.USER_ACCESS_RIGHT}
                                    </div>
                                    <Table {...this.state.tableState} />
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={"footer-actions " + (this.state.hasFormChanges ? "show" : null)}>
                        <Grid container spacing={1}>
                            <Grid item xs={5} />
                            <Grid item xs={1}>
                                {/* <Link to="/configuration/machine-config/brands"> */}
                                <Button onClick={this.state.id ? this.revertChanges : this.cancelRedirect} className="cancel" fullWidth variant="outlined">{translations.CANCEL}</Button>
                                {/* </Link> */}
                            </Grid>
                            <Grid item xs={1}>
                                <Button onClick={this.submitForm} className="save" fullWidth color="primary" variant="contained">{translations.SAVE}</Button>
                            </Grid>
                            <Grid item xs={5} />
                        </Grid>
                    </div>
                    {this.state.uploadImage ? <CropImage superAdmin={this.state.superAdmin} username={this.state.form.userName} reset={this.resetCrop} size={this.state.cropSize} cropShape={this.state.cropShape} id={this.props.match.params.id} image={this.state.uploadImage} name={this.state.cropName} title={this.state.cropTitle} /> : null}
                    {this.state.id ? <ChangePassword id={this.state.id} /> : null}
                </MainPageBody>
            </MainPage>
        );
    }
}

const mapStateToProps = state => {
    let { translationReducer, factoryListReducer, userReducer } = state
    let apiLoading = {
    }
    let data = {
        userAccess: state.authReducer.access,
        roleList: state.roleReducer.roleList
    }

    return {
        ...apiLoading,
        ...data,
        ...translationReducer,
        ...factoryListReducer,
        ...userReducer,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showModal: (modal) => dispatch(showModal(modal)),
        notificationError: (message) => dispatch(notificationError(message)),
        getRoles: () => dispatch(getRoles()),
        userTriggerHighlight: (trigger) => dispatch(userTriggerHighlight(trigger)),
        userChangeProfile: (image) => dispatch(userChangeProfile(image)),
        uploadUserProfile: (payload, file, id) => dispatch(uploadUserProfile(payload, file, id)),
        postNewUser: (payload) => dispatch(postNewUser(payload)),
        putUpdateUser: (id, payload) => dispatch(putUpdateUser(id, payload)),
        getUserDetail: (id) => dispatch(getUserDetail(id)),
        getFactoryByUser: (id) => dispatch(getFactoryByUser(id, true)),
        getFactoryTreeView: async (ev, isActive) => await setters().getFactoryTreeView(ev,isActive)
    }
}

UserAccessProfile = connect(mapStateToProps, mapDispatchToProps)(UserAccessProfile);

export default UserAccessProfile;
