import React from 'react';
import { 
    Box, 
    MenuItem, 
    Select, 
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import BootstrapInput from '@src/components/BootstrapInput';

export const Header = withStyles({
    root: {
        display: 'flex',
        gap: 5,
        justifyContent: 'space-between',
        alignContent: 'center',
        alignItems: 'center',
    },
})(Box);

export const Title = withStyles({
    root: {
        fontSize: 16,
        fontWeight: 'bold',
    },
})((props) => <Typography {...props}>{props.text}</Typography>);

export const FlexBox = withStyles({
    root: (props) => ({
        display: 'flex',
        gap: 5,
        justifyContent: props.justifyContent || 'flex-start',
        alignContent: 'center',
        alignItems: 'center',
        marginTop: 5,
    }),
})((props) => <Box {...props} />);

export const Legend = ({ 
    color = '#87DD00', 
    text = 'Legend',
    textColor = '#212121',
}) => (
    <IndicatorContainer>
        <LegendIndicator color={color} />
        <Typography color={textColor}>{text}</Typography>
    </IndicatorContainer>
);

export const IndicatorContainer = withStyles({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: 10,
        marginRight: 10,
        alignItems: 'center',
        alignContent: 'center',
        gap: 5,
    },
})(Box);

export const LegendIndicator = withStyles({
    root: (props) => ({
        backgroundColor: props.color,
        width: 16,
        height: 16,
        borderRadius: 2,
        marginLeft: 4,
        marginRight: 4,
    }),
})(Box);

export const CustomerSelect = ({ 
    items = [], 
    defaultValue = 0,
    label = 'View By'.translate(), 
    onChange, 
}) => (
    <FlexBox>
        <Typography fontSize={12}>{label}</Typography>
        <Select value={defaultValue} onChange={onChange} input={<BootstrapInput />}>
            {items.map((option, index) => 
                <MenuItem key={index} value={index + 1}>
                    {option.translate()}
                </MenuItem>)
            }
        </Select>
    </FlexBox>
);