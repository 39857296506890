import React from 'react';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

export function AverageFooterToday({
    title,
    hours,
    mins,
    isTvMode,
    tvModeTransition,
}) {
    return (
        <Paper className={
            tvModeTransition
            ? 'footer footer-tvmode'
            : 'footer' 
        }>
            <Grid container spacing={2} className='footer-text'>
                <Grid item xs={8} className={[isTvMode('footer-average', ''), 'ellipsisText']}>{title}</Grid>
                <Grid item xs={2} className={isTvMode('footer-time tb-footer ellipsisText', 'tb-footer ellipsisText')} align='center'>
                    <span className='time'>{hours}</span>
                </Grid>
                <Grid item xs={2} className={isTvMode('footer-time tb-footer ellipsisText', 'tb-footer ellipsisText')} align='center'>
                    <span className='time'>{mins}</span>
                </Grid>
            </Grid>
        </Paper>
    )
}