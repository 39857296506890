import React from "react";
import "./Dashboard.scss";
import {
    checkPageAccess,
    checkPageActionAccess,
    checkRoleAccess,
    checkPageAccessField,
    getRole,
    checkIfSuperAdminFactoryExist,
    getFactoryId,
    removeUnusedElement,
} from "../../../utils/helper";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import {
    MenuItem,
    FormGroup,
    Select,
    Menu,
    InputLabel,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import NativeSelect from "@material-ui/core/NativeSelect";
import { Link } from "react-router-dom";
import { Fade, Grow } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import machineIcon from "../../../assets/img/icon-machine.png";
import Carousel, { Dots } from "@brainhubeu/react-carousel";
import { connect } from "react-redux";

import setters from "../../../redux/actions/Dashboard/dashboardActions";

import UserBlueHeader from "../../../components/UserBlueHeader/UserBlueHeader";
import "@brainhubeu/react-carousel/lib/style.css";
import translations from "../../../redux/actions/Translation/translationActions";
import moment from "moment";
import RepairTicketCard from './RepairTicketCard';
import { AverageFooterToday } from './components/ReportedTickets/AverageFooterToday';
import RowReported from "./components/ReportedTickets/RowReported";
import LegendReported from "./components/ReportedTickets/LegendReported";
import LegendInRepair from "./components/InRepairTickets/LegendInRepair";
import { apiSettings, tokenGet } from "../../../utils/api/CoreApi";
import MaintenanceTickets from './components/MaintenanceTickets';
import { DashboardHeader } from "./components/DashboardHeader";

class Dashboard extends React.Component {
    formattedDate = moment().format("MMM DD, YYYY");
    paginateSize = 10;
    widescreen;
    language;
    timer;
    /**
     * fieldname for array callbacks
     */
    fieldNameConfig = {
        top5ticket: {
            line: "mfgLine",
            station: "mfgLineId",
            time: "totalNpt",
        },
        ticketList: {
            line: "mfgLine",
            area: "area",
            station: "station",
            time: "duration",
            start: "reportedDt",
            end: "grabbedDt",
        },
    };
    constructor(props) {
        super(props);
        this.widescreen =
            window &&
                new URL(window && window.location.href).searchParams.get("widescreen") !=
                null
                ? true
                : false;
        props.match.params &&
            props.match.params.token &&
            localStorage.setItem(
                "paramUserToken",
                JSON.stringify(props.match.params.token)
            );
        props.match.params && props.match.params.action && (this.widescreen = true);

        this.state = {
            // boardTitle: this.widescreen ? 'MECHANIC DASHBOARD' : 'MECHANIC',
            boardTvTitle: this.convertLang().BOARD_TV_TITLE,
            tvMode: this.widescreen,
            tvModeTransition: this.widescreen,
            transition: true,
            timeout: 500,
            isEmptyList: false,
            slideSpeed: 15000,
            slideValue: 0,
            ticketsElement: [],
            tickets: { ...this.props.tickets },
            repairTicketsStatus: { ...this.props.repairTicketsStatus },
            dashboardBuilding: { ...this.props.dashboardBuilding },
            buildingId: { ...this.props.buildingId },
            dashboardArea: [],
            areaId: { ...this.props.areaId },
            languageProps: {
                ...this.convertLang(),
            },
            translationTimestamp: undefined,
            avgTimeStamp: undefined,
            // isResponseToolTipOpen: false,     commented on rel-5.09.00, because it's just the UI with static data which cannot be pushed to production.
            // isRepairToolTipOpen: false,       commented on rel-5.09.00, because it's just the UI with static data which cannot be pushed to production.
            reportedDuration: 0,
            inRepairedDuration: 0,
        };
    }

    getConfigDuration = () => {
        tokenGet(apiSettings.apiURLReferences + '/productconfig').then((response) => {
            const userFactoryId = getFactoryId();
            const systemPreference = response.data.data.filter((item) => { return item.factoryId === userFactoryId});
            const reportedDuration = systemPreference.filter((item) => (item.config === 'RT1Dur'));
            const inRepairedDuration = systemPreference.filter((item) => (item.config === 'RT2Dur'));
            this.setState({
                reportedDuration: reportedDuration[0]['value'],
                inRepairedDuration: inRepairedDuration[0]['value']
            });
        });
    }

    convertLang = () => {
        return {
            MIN: "min".translate(),
            // ROLE: this.renderRoleLabel().translate(),
            BOARD_TV_TITLE: "TV Mode".translate(),
            BOARD_TV_TITLE_ON: "Exit TV Mode".translate(),
            REPAIR_TICKETS: "REPAIR TICKETS".translate(),
            REPAIR_REPORTED: 'Reported'.translate(),
            REPAIR_REOPEN: 'Re-opened'.translate(),
            REPAIR_INREPAIR: 'In-repair'.translate(),
            REPAIR_REPAIR: 'Repaired'.translate(),
            REPAIR_CLOSED: 'Closed Today'.translate(),
            REPAIR_CANCELLED: 'Cancelled Today'.translate(),
            TOPLINESNPT: "TOP 5 LINES NPT".translate(),
            MAINTENANCE_TICKETS: "MAINTENANCE TICKETS".translate(),
            MAINTENANCE_TICKETS_SCHED: "scheduled".translate(),
            MAINTENANCE_TICKETS_IN_PROGRESS: "in progress".translate(),
            MAINTENANCE_TICKETS_COMPLETE: "complete".translate(),
            CHANGE_OVER: "CHANGE OVER TICKETS".translate(),
            CHANGE_OVER_OPEN: "open".translate(),
            CHANGE_OVER_IN_PROCESS: "in process".translate(),
            CHANGE_OVER_COMPLETE: "complete".translate(),
            CHANGE_OVER_NEW: "New".translate(),
            CHANGE_OVER_IN_PROGRESS: "In Progress".translate(),
            CHANGE_OVER_READY: "Ready".translate(),
            CHANGE_OVER_READY_CO_TODAY: "Ready CO Today".translate(),
            REPORTED_TICKETS: "REPORTED TICKETS".translate(),
            AVG_RESPONSE: "average response time today".translate(),
            IN_REPAIR_TICKETS: "IN REPAIR TICKETS".translate(),
            AVG_REPAIR: "average repair time today".translate(),
            LINE_OVERVIEW: "LINE OVERVIEW".translate(),
            MACHINES: "MACHINES".translate(),
            TICKET_MANAGEMENT: "TICKET MANAGEMENT".translate(),
            MAINTENANCE: "MAINTENANCE".translate(),
        };
    };
    componentWillReceiveProps() {
        this.setState({
            tickets: this.props.tickets,
            repairTicketsStatus: this.props.tickets.repairTicketsStatus,
        });
    }
    componentWillUnmount() {
        console.log("Dashboard UnMounted");
        clearInterval(this.timer);
        this.props.socketOff();
    }
    componentDidUpdate() {
        if (this.props.tickets.timeStamp != this.state.tickets.timeStamp) {
            this.setState({
                tickets: this.props.tickets,
                repairTicketsStatus: this.props.tickets.repairTicketsStatus,
            });
        }
        if (this.props.avgTime?.avgTimeStamp != this.state.avgTimeStamp) {
            this.setState({
                avgTimeStamp: this.props.avgTime.avgTimeStamp,
                tickets: {
                    ...this.props.tickets,
                    reportedList: {
                        ...this.state.tickets.reportedList,
                        avgTime: this.props.avgTime.responseAvg,
                    },
                    repairList: {
                        ...this.state.tickets.repairList,
                        avgTime: this.props.avgTime.repairAvg,
                    },
                },
            });
        }
        if (
            this.props.translation &&
            this.props.translation.translations.timeStamp !=
            this.state.translationTimestamp
        ) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                languageProps: {
                    ...this.convertLang(),
                },
                boardTvTitle: this.convertLang().BOARD_TV_TITLE,
            });
        }
    }
    // static getDerivedStateFromProps(nextProps, prevState){
    //     console.log(nextProps, prevState)
    //     return null
    //     // if(){

    //     // }else{
    //     //     return null
    //     // }
    // }
    setAccess() {
        let access = JSON.parse(localStorage.getItem("userAccess"));
        this.setState({ access: access });
    }

    preventLinkRedirect = (e) => {
        e.preventDefault();
    };

    renderRoleLabel() {
        // if (checkRoleAccess(this.state.access,{role:'Mechanic'}) || checkRoleAccess(this.state.access,{role:'Administrator'})){
        //     if (this.state.tvMode){
        //         return 'MECHANIC DASHBOARD';
        //     }
        //     else{
        //         return 'MECHANIC';
        //     }
        // }
        // else if (checkRoleAccess(this.state.access,{role:'Line Leader'})){
        //     if (this.state.tvMode){
        //         return 'LINE LEADER DASHBOARD';
        //     }
        //     else{
        //         return 'LINE LEADER';
        //     }
        // }
        try {
            let role = getRole(this.state.access);
            if (role) {
                if (this.state.tvMode) {
                    return role.toUpperCase() + " DASHBOARD";
                } else {
                    return role.toUpperCase();
                }
            } else {
                if (this.state.tvMode) {
                    return "DASHBOARD";
                }
            }
        } catch (err) {
            if (this.state.tvMode) {
                return "DASHBOARD";
            }
        }
    }

    async componentDidMount() {
        removeUnusedElement();
        localStorage.removeItem("getLineVal");
        delete localStorage["fromCalendar"];
        this.setAccess();
        this.addTimeToList();
        this.getConfigDuration();
        this.setState({
            ticketsElement: this.paginateArray(this.state.tickets.repairList.list),
        });
        this.props.socketOn();
        this.setState({
            tickets: this.props.tickets,
            repairTicketsStatus: this.props.tickets.repairTicketsStatus,
        });
        await this.props.getProductLTM();
        await this.props.postProductLTM();
    }

    changeBuilding = async (e) => {
        const value = e.target.value;
        localStorage.setItem("buildID", e.target.value);
        localStorage.setItem("getLineVal", e.target.value);
        localStorage.setItem("areaID", undefined);
        localStorage.setItem("getLineAreaVal", undefined);
        await this.props.getDashboardList();
        this.props.getArea(value);
        this.props.updateSelectedBuilding(value);
    };
    changeArea = (e) => {
        // e.target.value
        // alert("----" + e.target.value);
        localStorage.setItem("areaID", e.target.value);
        localStorage.setItem("getLineAreaVal", e.target.value);
        this.props.getDashboardList();
        // if ((e.target.value != "undefined") || (e.target.value != undefined)){
        //     window.location.reload();
        // }
    };

    paginateArray = (arg) => {
        let returnRows = (index, size) => {
            let rowSize = this.state.tickets.repairList.list.length;
            let rowEnd = (index + 1) * this.paginateSize;
            let row = this.state.tickets.repairList.list;

            let rowArray = [];

            for (let x = rowEnd - this.paginateSize; x < rowEnd; x++) {
                if (row[x] == undefined) break;
                rowArray.push(
                    <TableRow key={x}>
                        <TableCell className="machine-no">{x + 1}</TableCell>
                        <TableCell className="machine-name">{row[""]}</TableCell>
                        <TableCell className="reported-time">{row[x].time}</TableCell>
                    </TableRow>
                );
            }
            return rowArray;
        };
        let page = new Array(
            Number.parseInt(arg.length / this.paginateSize) + 1
        ).fill();
        return page.map((pages, index) => {
            return (
                <TableContainer
                    className={this.isTvMode(
                        "dashboard-list big-list tvMode",
                        "dashboard-list big-list"
                    )}
                >
                    <Table className={this.isTvMode("big-list tvMode", "big-list")}>
                        <TableBody>{returnRows(index, page.length)}</TableBody>
                    </Table>
                </TableContainer>
            );
        });
    };
    twoDigitText = (number) => {
        return number.toString().replace(/(^\d{1})$/gi, "0$1");
    };
    isTvMode = (isTrue, isFalse) => {
        return this.state.tvModeTransition ? isTrue : isFalse;
    };
    renderLoader = (size, animation, height, uneven, rowHeight) => {
        let style = {
            background: "gray",
            height: rowHeight + "vh",
        };
        let skeletonStyle = {
            variant: "text",
            animation: animation,
        };
        let rootStyle = {
            minHeight: height + "vh",
            marginTop: '6vh',
        };
        rootStyle.height == null && delete rootStyle["height"];
        skeletonStyle.animation == null && delete skeletonStyle["animation"];
        let toReturn = [];
        for (let x = 0; x < size; x++) {
            toReturn.push(
                uneven ? (
                    (x / 2) % 1 == 0 ? (
                        <>
                            <Grid item xs={3}>
                                <Skeleton {...skeletonStyle} style={{ ...style }} />
                            </Grid>
                            <Grid item xs={9}>
                                <Skeleton {...skeletonStyle} style={{ ...style }} />
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={5}>
                                <Skeleton {...skeletonStyle} style={{ ...style }} />
                            </Grid>
                            <Grid item xs={7}>
                                <Skeleton {...skeletonStyle} style={{ ...style }} />
                            </Grid>
                        </>
                    )
                ) : (
                    <Grid item xs={12}>
                        <Skeleton {...skeletonStyle} style={{ ...style }} />
                    </Grid>
                )
            );
        }
        return (
            <Grid style={{ ...rootStyle }} container spacing={1}>
                {toReturn}
            </Grid>
        );
    };
    convertHourMin = (stringTime) => {
        return `${stringTime.replace(/\:\d{2}\:\d{2}$/gi, "")}:${stringTime
            .replace(/^\d+\:/gi, "")
            .replace(/\:\d{2}$/gi, "")}`;
    };
    extractTime = (stringTime) => {
        return {
            hour: Number.parseInt(
                stringTime.toString().replace(/\:\d{2}\:\d{2}$/gi, "")
            ),
            min: Number.parseInt(
                stringTime
                    .toString()
                    .replace(/^\d+\:/gi, "")
                    .replace(/\:\d{2}$/gi, "")
            ),
            sec: Number.parseInt(
                stringTime.toString().replace(/^\d+\:\d{2}\:/gi, "")
            ),
        };
    };
    addTimeToList = (minutes = 1) => {
        let seconds = minutes * 60 * 1000;
        seconds = 1000; // a test
        this.timer = setInterval(() => {
            let { reportedList, repairList } = this.state.tickets;
            let top5 = this.state.tickets["5npt"];
            let computeNewTime = ({ hour, min, sec }, seconds) => {
                sec += seconds;
                let isMinUpdated = false;
                if (sec >= 60) {
                    isMinUpdated = true;
                    min += 1;
                    sec = 0;
                    if (min >= 60) {
                        hour += 1;
                        min = 0;
                    }
                }
                return {
                    time: `${this.twoDigitText(hour)}:${this.twoDigitText(
                        min
                    )}:${this.twoDigitText(sec)}`,
                    isMinUpdated,
                };
            };
            let populateAddedTime = ({ list, avgTime }, args = "ticketList") => {
                list.filter((x, i) => {
                    let { time, isMinUpdated } = computeNewTime(
                        this.extractTime(x[this.fieldNameConfig[args].time]),
                        1
                    );
                    x[this.fieldNameConfig[args].time] = time;
                    if (isMinUpdated == true && args == "ticketList") {
                        // console.log('new updates', avgTime)
                        // let {time} = computeNewTime(this.extractTime(avgTime),60)
                        // avgTime = time
                        this.props.getAvgTime();
                    }
                    return time;
                });

                let callback = {
                    avgTime,
                    list,
                };

                if (args != "ticketList") delete callback["avgTimeStamp"];
                return callback;
            };
            reportedList = populateAddedTime(reportedList);
            repairList = populateAddedTime(repairList);
            top5 = populateAddedTime(top5, "top5ticket");
            this.setState({
                tickets: {
                    ...this.state.tickets,
                    reportedList: { ...this.state.tickets.reportedList, ...reportedList },
                    repairList: { ...this.state.tickets.repairList, ...repairList },
                    "5npt": { ...this.state.tickets["5npt"], ...top5 },
                },
            });
        }, seconds);
    };

    headerTicket = (headerType = "npt") => {
        return (
            <Grid container spacing={2} className="header-text">
                {headerType != "npt" && (
                    <Grid item xs={2} className="table-headers" align="center">
                        #
                    </Grid>
                )}
                {
                    headerType != "npt" ? 
                        <Grid item xs={6} className="table-headers">
                            Location
                        </Grid>
                    : <Grid item xs={8} className="table-headers pl-20">
                            LINE
                        </Grid>
                }
                <Grid item xs={2} className="table-headers tb-footer">
                    HRS
                </Grid>
                <Grid item xs={2} className="table-headers tb-footer">
                    MINS
                </Grid>
            </Grid>
        )
    }

    renderTicketsList = (
        args,
        size = 10,
        listType = "big-list",
        ticketListType = "ticketList",
        animation = null,
        height = 53,
        uneven = false,
        rowHeight = 3,
        test = false
    ) => {
        return this.state.tickets[args].list.length > 0 && test == false ? (
            <TableContainer
                className={
                    listType == "big-list"
                        ? this.isTvMode(
                            "dashboard-list big-list tvMode",
                            "dashboard-list big-list"
                        )
                        : this.isTvMode(
                            "dashboard-list small-list tvMode-small",
                            "dashboard-list small-list"
                        )
                }
            >
                <Table
                    className={
                        listType == "big-list"
                            ? this.isTvMode("big-list tvMode", "big-list")
                            : ""
                    }
                >
                    <TableBody>
                        {this.state.tickets[args].list.map((row, i) => {
                            let time = this.convertHourMin(
                                row[this.fieldNameConfig[ticketListType].time]
                            );
                            let timeHour = time != null && time != "" ? time.split(":")[0] : "00";
                            let timeMin = time != null && time != "" ? time.split(":")[1] : "00";
                            if(row.status === 'RE-OPENED'){
                                return (
                                    <RowReported
                                        timeMin={timeMin}
                                        timeHour={timeHour} 
                                        index={i} 
                                        row={row} 
                                        tickets={this.state.tickets}
                                        args={args}
                                        listType={listType}
                                        ticketListType={ticketListType}
                                        fieldNameConfig={this.fieldNameConfig}
                                        twoDigitText={this.twoDigitText}/>
                                )
                            }
                            
                            return (
                                <TableRow
                                    className={
                                        (row[this.fieldNameConfig.ticketList.time] &&
                                            Number.parseInt(
                                                row[this.fieldNameConfig.ticketList.time]
                                                    .toString()
                                                    .replace(/^\d+\:/gi, "")
                                                    .replace(/\:\d{2}$/gi, "")
                                            ) >= this.state.tickets[args].limit) ||
                                            (row[this.fieldNameConfig.ticketList.time] &&
                                                Number.parseInt(
                                                    row[this.fieldNameConfig.ticketList.time]
                                                        .toString()
                                                        .replace(/\:\d{2}\:\d{2}$/gi, "")
                                                ) > 0)
                                            ? "warning"
                                            : ""
                                    }
                                >
                                    {listType == "big-list" && (
                                        <TableCell className="machine-no">
                                            {this.twoDigitText(i + 1)}
                                        </TableCell>
                                    )}
                                        <TableCell className="machine-name">
                                        {`${row[this.fieldNameConfig[ticketListType].line] == null
                                            ? row[this.fieldNameConfig[ticketListType].area]
                                            : row[this.fieldNameConfig[ticketListType].line]
                                        } ${listType == "big-list"
                                            ? row[this.fieldNameConfig[ticketListType].line] == null
                                                ? ""
                                                : "-"
                                            : ""
                                        } ${listType == "big-list" &&
                                            row[this.fieldNameConfig[ticketListType].station] != 0
                                            ? row[this.fieldNameConfig[ticketListType].station]
                                            : ""
                                        }`}
                                        </TableCell>
                                        <TableCell className={
                                                listType == "big-list" ? "machine-no reported-time" : "machine-no npt"
                                            } align="center">
                                            <span className="time">{timeHour}</span>
                                        </TableCell>
                                        <TableCell className={
                                                listType == "big-list" ? "machine-no reported-time" : "machine-no npt"
                                            } align="center">
                                            <span className="time">{timeMin}</span>
                                        </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        ) : (
            (setTimeout(() => {
                // 0 == 0 && this.state.isEmptyList == false && (
                this.state.tickets[args].list.length == 0 &&
                    this.state.isEmptyList == false &&
                    this.setState({
                        isEmptyList: true,
                    });
            }, 10000),
                // this.renderLoader(size, false, height, uneven, rowHeight)
                this.state.isEmptyList ? (
                    <TableContainer
                        className={
                            listType == "big-list"
                                ? this.isTvMode(
                                    "dashboard-list big-list tvMode",
                                    "dashboard-list big-list"
                                )
                                : this.isTvMode(
                                    "dashboard-list small-list tvMode-small",
                                    "dashboard-list small-list"
                                )
                        }
                    >
                        <Table
                            className={
                                listType == "big-list"
                                    ? this.isTvMode("big-list tvMode", "big-list")
                                    : ""
                            }
                        >
                            <TableBody></TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    this.renderLoader(size, animation, height, uneven, rowHeight)
                ))
        );
    };
    toggleFullScreen = () => {
        let after = 600;
        if (document.fullscreenElement) {
            this.setState({
                transition: !this.state.transition,
            });
            setTimeout(() => {
                this.setState({
                    // boardTitle: 'MECHANIC',
                    boardTvTitle: this.state.languageProps.BOARD_TV_TITLE,
                    tvMode: !this.state.tvMode,
                    transition: !this.state.transition,
                    tvModeTransition: !this.state.tvModeTransition,
                });
                document.exitFullscreen();
            }, this.state.timeout + after);
        } else {
            this.setState({
                transition: !this.state.transition,
                tvMode: !this.state.tvMode,
            });
            setTimeout(() => {
                this.setState({
                    // boardTitle: 'MECHANIC DASHBOARD',
                    boardTvTitle: this.state.languageProps.BOARD_TV_TITLE_ON,
                    transition: !this.state.transition,
                    tvModeTransition: !this.state.tvModeTransition,
                });
                document.documentElement.requestFullscreen();
            }, this.state.timeout + after);
        }
    };
    handleSlideChange = (value) => {
        this.setState({ slideValue: value });
    };

    render() {
        const { dashboardArea } = this.props;
        
        return (
            <div className="dashboard">
                <div className={this.isTvMode("blue-header hidden", "blue-header")}>
                    <UserBlueHeader />
                </div>
                <DashboardHeader
                    toggleFullScreen={this.toggleFullScreen}
                    boardTvTitle={this.state.boardTvTitle}
                    renderRoleLabel={this.renderRoleLabel()?.toString()?.translate() || ''}
                    date={this.formattedDate}
                    getDashboardList={() => this.props.getDashboardList()}
                    isLoading={this.props.isLoading}
                />
                <div
                    className={this.isTvMode(
                        "dashboard-body body-tvMode",
                        "dashboard-body"
                    )}
                >
                    <Grow
                        in={this.state.transition}
                        timeout={this.state.timeout + 100}
                        className="body-container"
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Grid container spacing={2} className="column">
                                    <Grid item xs={6}> 
                                        <RepairTicketCard
                                            repairTicketsStatus={this.state.repairTicketsStatus}
                                            isTvMode={this.isTvMode}
                                            title={this.state.languageProps.REPAIR_TICKETS}
                                            textReported={this.state.languageProps.REPAIR_REPORTED}
                                            textReOpened={this.state.languageProps.REPAIR_REOPEN}
                                            textInRepair={this.state.languageProps.REPAIR_INREPAIR}
                                            textRepaired={this.state.languageProps.REPAIR_REPAIR}
                                            textClosedToday={this.state.languageProps.REPAIR_CLOSED}
                                            textCancelledToday={this.state.languageProps.REPAIR_CANCELLED}
                                        /> 
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Paper
                                            className={this.isTvMode("card card-npt tvMode-height", "card card-npt")}
                                        >
                                            <div className="title ellipsisText">
                                            {this.state.languageProps.TOPLINESNPT}
                                            </div>
                                            {this.headerTicket("npt")}
                                            {this.renderTicketsList(
                                                "5npt",
                                                5,
                                                "small",
                                                "top5ticket",
                                                null,
                                                null,
                                                true
                                            )}
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <MaintenanceTickets
                                            data={this.state.tickets.maintenanceDashboard}
                                            isTvMode={this.state.tvModeTransition}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Paper
                                            className={this.isTvMode("card tvMode-height", "card")}
                                        >
                                            <div className={this.isTvMode('title tvMode', 'title repair-ticket-title')}>
                                                {this.state.languageProps.CHANGE_OVER}
                                            </div>
                                            <Grid
                                                container
                                                spacing={2}
                                                className={this.isTvMode("", "inner-card")}
                                            >
                                                <Grid
                                                    item
                                                    xs={4}
                                                    className={this.isTvMode(
                                                        "ticket-count-container"
                                                    )}
                                                >
                                                    <div className={this.isTvMode('ticket-count tvMode', 'ticket-count repair-ticket-count')}>
                                                        {this.state.tickets.changeOver.new || 0}
                                                    </div>
                                                    <div
                                                        className={this.isTvMode(
                                                            "ticket-status tvMode repair-ticket",
                                                            "ticket-status repair-ticket-status"
                                                        )}
                                                    >
                                                        {this.state.languageProps.CHANGE_OVER_NEW}
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={4}
                                                    className={this.isTvMode(
                                                        "ticket-count-container"
                                                    )}
                                                >
                                                    <div className={this.isTvMode('ticket-count tvMode', 'ticket-count repair-ticket-count')}>
                                                        {this.state.tickets.changeOver.inProgress || 0}
                                                    </div>
                                                    <div
                                                        className={this.isTvMode(
                                                            "ticket-status tvMode repair-ticket",
                                                            "ticket-status repair-ticket-status"
                                                        )}
                                                    >
                                                        {this.state.languageProps.CHANGE_OVER_IN_PROGRESS}
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={4}
                                                    className={this.isTvMode(
                                                        "ticket-count-container"
                                                    )}
                                                >
                                                    <div className={this.isTvMode('ticket-count tvMode', 'ticket-count repair-ticket-count')}>
                                                        {this.state.tickets.changeOver.ready || 0}
                                                    </div>
                                                    <div
                                                        className={this.isTvMode(
                                                            "ticket-status tvMode repair-ticket",
                                                            "ticket-status repair-ticket-status"
                                                        )}
                                                    >
                                                        {this.state.languageProps.CHANGE_OVER_READY}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={2}></Grid>
                                                <Grid
                                                    item
                                                    xs={4}
                                                    className={this.isTvMode(
                                                        "ticket-count-container"
                                                    )}
                                                >
                                                    <div className={this.isTvMode('ticket-count tvMode', 'ticket-count repair-ticket-count')}>
                                                        {this.state.tickets.changeOver.readyToday || 0}
                                                    </div>
                                                    <div
                                                        className={this.isTvMode(
                                                            "ticket-status tvMode repair-ticket",
                                                            "ticket-status repair-ticket-status"
                                                        )}
                                                    >
                                                        {this.state.languageProps.CHANGE_OVER_READY_CO_TODAY}
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={4}
                                                    className={this.isTvMode(
                                                        "ticket-count-container"
                                                    )}
                                                >
                                                    <div className={this.isTvMode('ticket-count tvMode', 'ticket-count repair-ticket-count')}>
                                                        {this.state.tickets.changeOver.closedToday || 0}
                                                    </div>
                                                    <div
                                                        className={this.isTvMode(
                                                            "ticket-status tvMode repair-ticket",
                                                            "ticket-status repair-ticket-status"
                                                        )}
                                                    >
                                                        {this.state.languageProps.REPAIR_CLOSED}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={2}></Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={2} justify="flex-start">
                                    <Grid item xs={6}>
                                        <Paper
                                            className={this.isTvMode(
                                                "card two-rows tvMode",
                                                "card two-rows"
                                            )}
                                        >
                                            <div className="title">
                                                {this.state.languageProps.REPORTED_TICKETS}{" "}
                                                <span>
                                                    ({this.state.tickets.reportedList.list.length})
                                                </span>
                                            </div>
                                            {this.headerTicket("reported")}
                                            {this.renderTicketsList("reportedList")}
                                            {/* commented on rel-5.09.00, because it's just UI with static data which cannot be pushed to production. rel-5.09.00 will be released to production */}
                                            {/* <Paper
                                                onMouseOver={() => {
                                                    this.setState({ isResponseToolTipOpen: true })
                                                }}
                                                onMouseOut={() => {
                                                    this.setState({ isResponseToolTipOpen: false })
                                                }}
                                                className={
                                                    this.state.tvModeTransition
                                                        ? "footer footer-tvmode"
                                                        : "footer"
                                                }
                                            >
                                                <DashboardTooltip
                                                    open={Boolean(this.state.isResponseToolTipOpen)}
                                                    placement='right'
                                                >
                                                    <Grid container spacing={2} className="footer-text">
                                                        <Grid
                                                            item
                                                            xs={8}
                                                            className={this.isTvMode("footer-average", "") }
                                                        >
                                                            {this.state.languageProps.AVG_RESPONSE}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={2}
                                                            className={this.isTvMode("footer-time tb-footer", "tb-footer")}
                                                            align="center"
                                                        >
                                                            <span className="time">
                                                                {
                                                                    this.state.tickets.reportedList.avgTime != null && this.state.tickets.reportedList.avgTime != "" ? this.state.tickets.reportedList.avgTime.split(":")[0] : "00"
                                                                }
                                                            </span>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={2}
                                                            className={this.isTvMode("footer-time tb-footer", "tb-footer")}
                                                            align="center"
                                                        >
                                                            <span className="time">
                                                                {
                                                                    this.state.tickets.reportedList.avgTime != null && this.state.tickets.reportedList.avgTime != "" ? this.state.tickets.reportedList.avgTime.split(":")[1] : "00"
                                                                }
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </DashboardTooltip>
                                            </Paper> */}
                                            <LegendReported 
                                                duration={this.state.reportedDuration}
                                                isTvMode={this.state.tvModeTransition} />
                                            <AverageFooterToday 
                                                title={this.state.languageProps.AVG_RESPONSE}
                                                hours={this.state.tickets.reportedList.avgTime != null && this.state.tickets.reportedList.avgTime != "" ? this.state.tickets.reportedList.avgTime.split(":")[0] : "00"}
                                                mins={this.state.tickets.reportedList.avgTime != null && this.state.tickets.reportedList.avgTime != "" ? this.state.tickets.reportedList.avgTime.split(":")[1] : "00"}
                                                isTvMode={this.isTvMode}
                                                tvModeTransition={this.state.tvModeTransition}
                                            />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Paper
                                            className={this.isTvMode(
                                                "card two-rows tvMode",
                                                "card two-rows"
                                            )}
                                        >
                                            <div className="title">
                                                {this.state.languageProps.IN_REPAIR_TICKETS}{" "}
                                                <span>
                                                    ({this.state.tickets.repairList.list.length})
                                                </span>
                                            </div>
                                            {this.headerTicket("repaired")}
                                            {this.renderTicketsList("repairList")}
                                            {/* commented on rel-5.09.00, because it's just the UI with static data which cannot be pushed to production.  */}
                                            {/* <Paper
                                                onMouseOver={() => {
                                                    this.setState({ isRepairToolTipOpen: true })
                                                }}
                                                onMouseOut={() => {
                                                    this.setState({ isRepairToolTipOpen: false })
                                                }}
                                                className={
                                                    this.state.tvModeTransition
                                                        ? "footer footer-tvmode"
                                                        : "footer"
                                                }
                                            >
                                                 <DashboardTooltip
                                                    open={Boolean(this.state.isRepairToolTipOpen)}
                                                    placement='left'
                                                >
                                                    <Grid container spacing={2} className="footer-text">
                                                        <Grid
                                                            item
                                                            xs={8}
                                                            className={this.isTvMode("footer-average", "")}
                                                        >
                                                            {this.state.languageProps.AVG_REPAIR}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={2}
                                                            className={this.isTvMode("footer-time tb-footer", "tb-footer")}
                                                            align="center"
                                                        >
                                                            <span className="time">
                                                                {
                                                                    this.state.tickets.repairList.avgTime != null && this.state.tickets.repairList.avgTime != "" ? this.state.tickets.repairList.avgTime.split(":")[0] : "00"
                                                                }
                                                            </span>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={2}
                                                            className={this.isTvMode("footer-time tb-footer", "tb-footer")}
                                                            align="center"
                                                        >
                                                            <span className="time">
                                                                {
                                                                    this.state.tickets.repairList.avgTime != null && this.state.tickets.repairList.avgTime != "" ? this.state.tickets.repairList.avgTime.split(":")[1] : "00"
                                                                }
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </DashboardTooltip>
                                            </Paper> */}
                                            <LegendInRepair 
                                                duration={this.state.inRepairedDuration}
                                                isTvMode={this.state.tvModeTransition} />
                                            <AverageFooterToday 
                                                title={this.state.languageProps.AVG_REPAIR}
                                                hours={this.state.tickets.repairList.avgTime != null && this.state.tickets.repairList.avgTime != "" ? this.state.tickets.repairList.avgTime.split(":")[0] : "00"}
                                                mins={this.state.tickets.repairList.avgTime != null && this.state.tickets.repairList.avgTime != "" ? this.state.tickets.repairList.avgTime.split(":")[1] : "00"}
                                                isTvMode={this.isTvMode}
                                                tvModeTransition={this.state.tvModeTransition}
                                            />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grow>
                    <Fade in={!this.state.tvMode} timeout={this.state.timeout + 200}>
                        <Grid
                            container
                            className={
                                this.state.tvMode ? "dashboard-menu-hidden" : "dashboard-menu"
                            }
                            spacing={2}
                        >
                            <Grid item xs={3}>
                                <Link
                                    onClick={
                                        checkPageAccessField(this.props.access, {
                                            pageId: 8,
                                            pageAccess: "NONE",
                                        }) === false &&
                                            !checkIfSuperAdminFactoryExist(this.props.factoryAssigned)
                                            ? null
                                            : this.preventLinkRedirect
                                    }
                                    to="/line-status"
                                >
                                    <Paper
                                        className={
                                            "menu-item " +
                                            (checkPageAccessField(this.props.access, {
                                                pageId: 8,
                                                pageAccess: "NONE",
                                            }) === false &&
                                                !checkIfSuperAdminFactoryExist(this.props.factoryAssigned)
                                                ? ""
                                                : "disabled")
                                        }
                                    >
                                        <div>{this.state.languageProps.LINE_OVERVIEW}</div>
                                    </Paper>
                                </Link>
                            </Grid>
                            <Grid item xs={3}>
                                <Link
                                    onClick={
                                        checkPageAccessField(this.props.access, {
                                            pageId: 1,
                                            pageAccess: "NONE",
                                        }) === false &&
                                            !checkIfSuperAdminFactoryExist(this.props.factoryAssigned)
                                            ? null
                                            : this.preventLinkRedirect
                                    }
                                    to="/machines"
                                >
                                    <Paper
                                        className={
                                            "menu-item " +
                                            (checkPageAccessField(this.props.access, {
                                                pageId: 1,
                                                pageAccess: "NONE",
                                            }) === false &&
                                                !checkIfSuperAdminFactoryExist(this.props.factoryAssigned)
                                                ? ""
                                                : "disabled")
                                        }
                                    >
                                        <img src={machineIcon} className="menu-icon"></img>
                                        {this.state.languageProps.MACHINES}
                                    </Paper>
                                </Link>
                            </Grid>
                            <Grid item xs={3}>
                                <Link
                                    onClick={
                                        checkPageAccessField(this.props.access, {
                                            pageId: 6,
                                            pageAccess: "NONE",
                                        }) === false &&
                                            !checkIfSuperAdminFactoryExist(this.props.factoryAssigned)
                                            ? null
                                            : this.preventLinkRedirect
                                    }
                                    to="/tickets"
                                >
                                    <Paper
                                        className={
                                            "menu-item " +
                                            (checkPageAccessField(this.props.access, {
                                                pageId: 6,
                                                pageAccess: "NONE",
                                            }) === false &&
                                                !checkIfSuperAdminFactoryExist(this.props.factoryAssigned)
                                                ? ""
                                                : "disabled")
                                        }
                                    >
                                        <div>{this.state.languageProps.TICKET_MANAGEMENT}</div>
                                    </Paper>
                                </Link>
                            </Grid>
                            <Grid item xs={3}>
                                <Link
                                    onClick={
                                        checkPageAccessField(this.props.access, {
                                            pageId: 9,
                                            pageAccess: "NONE",
                                        }) === false &&
                                            !checkIfSuperAdminFactoryExist(this.props.factoryAssigned)
                                            ? null
                                            : this.preventLinkRedirect
                                    }
                                    to="/maintenance"
                                >
                                    <Paper
                                        className={
                                            "menu-item " +
                                            (checkPageAccessField(this.props.access, {
                                                pageId: 9,
                                                pageAccess: "NONE",
                                            }) === false &&
                                                !checkIfSuperAdminFactoryExist(this.props.factoryAssigned)
                                                ? ""
                                                : "disabled")
                                        }
                                    >
                                        <div>{this.state.languageProps.MAINTENANCE}</div>
                                    </Paper>
                                </Link>
                            </Grid>
                        </Grid>
                    </Fade>
                </div>
                {/* <Carousel className="carousel-dots" autoPlay={this.state.slideSpeed} inifinite slides={this.state.ticketsElement} value={this.state.slideValue} onChange={this.handleSlideChange} />
                                            <Grid container className={this.tvModeTransition ? "dots-tvMode" : "dots-bottom"} justify="center">
                                                <Dots className="carousel-dots" value={this.state.slideValue} onChange={this.handleSlideChange} number={this.state.ticketsElement.length} />
                                            </Grid> */}
            </div>
        );
    }
}
const stateToProps = (state) => {
    let {
        dashboardListReducer,
        translationReducer,
        authReducer,
        factoryListReducer,
    } = state;
    const { dashboardArea } = dashboardListReducer;
    return {
        ...authReducer,
        ...dashboardListReducer,
        ...translationReducer,
        ...factoryListReducer,
        dashboardArea: dashboardArea || [],
    };
};

Dashboard = connect(stateToProps, setters)(Dashboard);
export default Dashboard;
