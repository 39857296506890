import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { 
    FlexBox, 
    ExportIcon, 
    IndicatorContainer, 
    LegendIndicator,
} from './styles';
import { legends } from './constant';
import { BetaIcon } from '../../styles/styles';

export default function Header(props){
    return (
        <FlexBox>
            <FlexBox>
                <Typography style={{ whiteSpace: 'nowrap' }} variant='h4'>{'Indiv. Mechanic Performance'.translate()}</Typography>
                <BetaIcon />
            </FlexBox>
            <FlexBox gap={2}>
                {legends.map(({title, color}) => (
                    <IndicatorContainer marginLeft={2} marginRight={2}>
                        <LegendIndicator color={color} />
                        <Typography style={{ whiteSpace: 'nowrap' }}>{title.translate()}</Typography>
                    </IndicatorContainer>
                ))}
            </FlexBox>
            <IconButton onClick={props.handleExport} size='small'>
                <ExportIcon />
            </IconButton>
        </FlexBox>
    )
}   