import React, { 
  useState, 
  useRef, 
  useEffect,
} from 'react';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import MiddleLabel from './plugins/MiddleLabel';
import Filter from './plugins/Filter';
import { 
  datasetKeyProvider, 
  getChartData, 
  getOptions,
} from './helpers';

function DoughnutMachineBreakDown({ data }) {
    const mounted = useRef(false);
    const [options, setOptions] = useState(null);
    const [dataChart, setDataChart] = useState(null);

    useEffect(() => {
      mounted.current = setTimeout(()=>{
        setOptions(getOptions(data));
        setDataChart(getChartData(data));
      }, 500);
      return ()=> clearTimeout(mounted.current);
    }, [data]);

    return dataChart !== null && ( 
      <Doughnut
          height={250}
          data={dataChart}
          options={options}
          datasetKeyProvider={datasetKeyProvider}
          plugins={[ChartDataLabels, MiddleLabel, Filter]} />
    );
}

export default DoughnutMachineBreakDown;