import React, { useEffect, useMemo, useState } from 'react';
import { apiSettings, tokenGet } from '../../../utils/api/CoreApi';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import { getFactoryId } from '../../../utils/helper';
import {
  handleSetLinePerSlide,
  renderSlides,
  socketConfig,
  sortingLines,
} from './LineStatusNormalMode/actions';
import './LineStatus.scss';
import '../../../components/MainPage/MainPage.scss';
import UserBlueHeader from '../../../components/UserBlueHeader/UserBlueHeader';
import RenderHeader from './LineStatusNormalMode/Header';
export default function LineStatus() {
  const [areas, setAreas] = useState([]);
  const [areaId, setAreaId] = useState(null);
  const [totalLines, setTotalLines] = useState(0);
  const [buildings, setBuildings] = useState([]);
  const [buildingId, setBuildingId] = useState(null);

  const [lines, setLines] = useState([]);

  const [slides, setSlides] = useState([]);
  const [slideValue, setSlideValue] = useState(0);
  const [slideIsReady, setSlideIsReady] = useState(false);
  const [filterDisabled, setFilterDisabled] = useState(false);
  const [dotsShow, setDotsShow] = useState(true);

  const [socketTrigger, setSocketTrigger] = useState(false);
  const [systemPreference, setSystemPreference] = useState([]);
  const [socketTicket, setSocketTicket] = useState(null);
  const [socketLine, setSocketLine] = useState(null);

  const getSystemPreference = () => {
    tokenGet(apiSettings.apiURLReferences + '/productconfig').then(
      (response) => {
        let userFactoryId = getFactoryId();
        let systemPreference = response.data.data.filter((item) => {
          return item.factoryId === userFactoryId;
        });
        setSocketTrigger(!socketTrigger);
        setSystemPreference(systemPreference);
      }
    );
  };

  const disabler = (value) => {
    setFilterDisabled(value);
    setDotsShow(!value);
  };

  const getBuildings = () => {
    tokenGet(apiSettings.apiURLReferences + '/buildings').then(({ data }) => {
      if (data.success) {
        setBuildings(data.data);
        setBuildingId(data.data[0].id);
        getAreas(data.data[0].id);
      }
    });
  };

  const getAreas = (buildingId) => {
    const url = `/api/areas/line-counts-per-area?buildingId=${buildingId}`;
    tokenGet(apiSettings.apiURLLines + url).then(({ data }) => {
      if (data.areas.length > 0) {
        setAreas(data.areas || []);
        setAreaId(data.areas ? data.areas[0].areaId : '');
        setTotalLines(data.areas ? data.areas[0].totalLines : 0);
      } else {
        setSlides([]);
        setAreas([]);
        setAreaId('');
        setTotalLines(0);
      }
    });
  };

  const getLines = () => {
    let url = `/api/areas/${areaId}/line-overview?${buildingId}`;
    tokenGet(`${apiSettings.apiURLLines}${url}`).then((response) => {
      const data = response.data.lines;
      if (data) {
        setLines(sortingLines(data));
        handleSetLinePerSlide((linePerSlide) => renderSlides(
          sortingLines(data),
          linePerSlide,
          systemPreference,
          socketTrigger,
          disabler,
          (slides, isReady) => {
            setSlides(slides);
            setSlideIsReady(isReady);
          }
        ));
        
      } else {
        setLines([]);
      }
    });
  };

  useEffect(() => {
    getBuildings();
    getSystemPreference();
  }, []);
  
  useEffect(() => {
    if(!areaId) return;
    const socketTicket = socketConfig(apiSettings.apiURLTicketsSocket);
    socketTicket.on( 'reconnect_attempt', () => (socketTicket.io.opts.transports = ['websocket']));
    socketTicket.on('web_new_ticket', () => getLines());
    socketTicket.on('web_updated_ticket', () => getLines());
    return () => { socketTicket.disconnect() };
  }, [areaId]);

  useEffect(() => {
    if(!areaId) return;
    const socketLine = socketConfig(apiSettings.apiURLLinesSocket);
    socketLine.on('reconnect_attempt', () => (socketLine.io.opts.transports = ['websocket']));
    socketLine.on('checkin', () => getLines());
    socketLine.on('checkout', () => getLines());
    return () => { socketLine.disconnect() };
  }, [areaId]);

  const handleChangeArea = (event) => {
    const id = event.target.value;
    const totalLines = areas.find(({ areaId }) => areaId == id).totalLines;
    setTotalLines(totalLines);
    setAreaId(id);
  }

  useEffect(() => {
    areaId && getLines();
  }, [buildingId, areaId]);

  return (
    <div className='main-page line-status-overview'>
      <div className='main-page-header'>
        <UserBlueHeader />
        <RenderHeader
          title='LINE OVERVIEW'
          buildings={buildings}
          buildingId={buildingId}
          areas={areas}
          areaId={areaId}
          totalLines={totalLines}
          filterDisabled={filterDisabled}
          handleChangeBuilding={(e) => {
            setBuildingId(e.target.value);
            getAreas(e.target.value);
          }}
          handleChangeArea={handleChangeArea}
        />
      </div>
      <div className='main-page-body-lineOverview'>
        <div className='line-overview'>
          <Carousel
            slidesPerPage={1}
            centered={true}
            value={slideValue}
            slides={slides}
            onChange={(value) => setSlideValue(value)}
            draggable={false}
          />
        </div>
        <div className='gray-area'></div>
        {dotsShow && (
          <Dots
            className='carousel-dots'
            value={slideValue}
            onChange={(value) => setSlideValue(value)}
            number={slides.length}
          />
        )}
      </div>
      <div className='footer'>
        {'ALL RIGHTS RESERVED 2020@LTLABS'.translate()}
      </div>
    </div>
  );
}
