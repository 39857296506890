import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox, SvgIcon } from '@material-ui/core';
const IndeterminateCheckBox = withStyles({
  root: {
    color: "#1E88E5",
    paddingTop: 2,
    paddingBottom: 2
  },
  checked: {},
})((props) => <Checkbox
  color="default"
  icon={
    <SvgIcon>
      <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM7 11h10v2H7z"/>
    </SvgIcon>
  }
  {...props} />
);

export default IndeterminateCheckBox;
