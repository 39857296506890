import React from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export default withStyles({
  root: {
    marginBottom: '1rem',
  }
})(({ children, error, ...props }) => {
  const hasError = Boolean(error);
  return <FormControl 
    fullWidth 
    error={hasError}
    {...props}
  >
    { children }
    { hasError && <FormHelperText>{ error }</FormHelperText> }
  </FormControl>
});