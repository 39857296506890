import React, { useState } from 'react';
import BarChart from '../BarChart';
import CardPaper from '../CardPaper';
import { Box } from '@material-ui/core';
import Header from './header';
import { RotationText } from './styles';
import { options } from './options';
import { colors } from './colors';
import './styles.scss';
import { useEffect } from 'react';
import { fetchMechanicOverview } from '../../api';
import { connect } from 'react-redux';
import { ANALYTIC_REPORT_TYPES, downloadAnalyticReport } from '../../excel';
import { closeModal, showModal } from '../../../../../redux/actions/Modal/modalActions';
import { eachInternal, getDateRangeQueryString } from '../../helpers';

const OverallMachanicCard = (props) => {
  const [graphData, setGraphData] = useState({});
  const [mechanicOverviewData, setMechanicOverviewData] = useState([]);
  const { filters, isCron } = props;

  const handleExport = () => {
    props.showModal('excelLoading');
    fetchMechanicOverview({
      filters: getDateRangeQueryString(filters),
      interval: filters.dateRange.interval,
    }).then(({ mechanicOverview }) => {
      const items = prepareIntervalData(mechanicOverview.data);
      downloadAnalyticReport(ANALYTIC_REPORT_TYPES.OverallMechanicPerformance, items);
    }).finally(() => {
      props.closeModal();
    });
  };

  useEffect(() => {
    if(isCron) return;
    fetchMechanicOverview({
      filters: getDateRangeQueryString(filters),
      interval: filters.dateRange.interval,
    }).then(({ mechanicOverview: response }) => {
        setMechanicOverviewData(response.data);
        setGraphData(normalizeData(response.data));
    });
  }, [filters]);

  const increaseWeek = (week) => {
    const arr = week.split(' ');
    return arr[0] + ' ' + (Number(arr[1]) + 1);
  }

  const prepareIntervalData = data => {
    const ticks = eachInternal(filters.dateRange);
    const items = ticks.map(tick => {
      const found = data.find((m) => {
        // if (filters.dateRange.interval == 'weekly') {
        //   console.log("ok", m.label)
        //   return increaseWeek(m.label) === tick.label;
        // }
        return m.label === tick.label;
      });

      return {
        label: tick.label,
        interval: filters.dateRange.interval,
        dateRange: tick.dateRange,
        countCoReqTickets: found ? found.countCoReqTickets : 0,
        countMaintenanceTickets: found ? found.countMaintenanceTickets : 0,
        countRepairTickets: found ? found.countRepairTickets : 0,
        idleMechanics: found ? found.idleMechanics : 0,
        countActiveMechanics: found ? found.countActiveMechanics : 0,
        avgRepairTicketRepairTime: found ? found.avgRepairTicketRepairTime : 0,
        avgRepairTicketResponseTime: found ? found.avgRepairTicketResponseTime : 0,
        avgWorkingTime: found ? found.avgWorkingTime : 0,
        date: found ? found.date : 0,
        machinePrepared: found ? found.machinePrepared : 0,
        totalRepairTicketRepairTime: found ? found.totalRepairTicketRepairTime : 0,
        totalRepairTicketResponseTime: found ? found.totalRepairTicketResponseTime : 0,
        totalWorkingTime: found ? found.totalWorkingTime : 0,
      }
    });

    return items;
  }

  const normalizeData = (mechanicOverview) => {
    const items = prepareIntervalData(mechanicOverview);

    let result = {
      labels: items.map(item => {
        if(!item.dateRange) return item.label;
        
        return item.label + '__' + item.dateRange;
      }),
    };

    if(mechanicOverview.length > 0) {
      result = {
        ...result,
        datasets: [
          {
            type: "line",
            label: "Closed Changeover Tickets",
            borderColor: "#C400FF",
            borderWidth: 3,
            fill: false,
            pointBackgroundColor: "white",
            pointBorderWidth: 1.5,
            data: items.map((item) => item.countCoReqTickets),
            grid: { display: false }
          },
          {
            type: "line",
            label: "MAINT.Ticket",
            borderColor: "rgb(45, 153, 237)",
            borderWidth: 3,
            fill: false,
            pointBackgroundColor: "white",
            pointBorderWidth: 1.5,
            data: items.map((item) => item.countMaintenanceTickets),
            grid: { display: false }
          },
          {
            type: "line",
            label: "Repair Ticket",
            borderColor: "rgb(230, 203, 0)",
            borderWidth: 3,
            fill: false,
            pointBackgroundColor: "white",
            pointBorderWidth: 1.5,
            grid: { display: false },
            data: items.map((item) => item.countRepairTickets),
          },
          {
            type: "bar",
            label: "IDle Mechanic",
            backgroundColor: "rgb(155, 155, 155)",
            data: items.map((item) => item.idleMechanics),
            stack: "Stack 0"
          },
          {
            type: "bar",
            label: "Active Mechanic",
            backgroundColor: "rgb(83, 209, 140)",
            data: items.map((item) => item.countActiveMechanics),
            borderColor: "white",
            stack: "Stack 0"
          }
        ]
      }
    }

    return result;
  }

  return (
    <CardPaper>
      <Header
        {...props}
        handleExport={handleExport}
        colors={colors} />
      <Box display="flex" alignItems="flex-end">
        <Box width="2%"><RotationText>{'Mechanic/Ticket Qty'.translate()}</RotationText></Box>
        <Box flex={1}>
          <BarChart
            height={250}
            data={graphData}
            options={options(mechanicOverviewData)} />
        </Box>
      </Box>
    </CardPaper>
  )
}

const mapStateToProps = state => ({ ...state.translationReducer });

const mapDispatchToProps = dispatch => {
  return {
    showModal: (modal) => dispatch(showModal(modal)),
    closeModal: () => dispatch(closeModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverallMachanicCard);