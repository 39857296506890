import React from 'react'
import { Box } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  imageResponsive: {
    maxHeight: '100%',
    maxWidth: "100%",
    display: 'block',
    boxSizing: 'border-box',
  },
  imageWrapper: {
    padding: 5
  }
});

const Container = withStyles({
  root: {
    display: 'flex',
    maxWidth: "100%",
    height: '100%',
    width: 'auto',
    gap: 5,
  }
})(Box);

const Image = ({ src, name, ...props }) => {
  const classes = useStyles();

  return <img src={src} className={classes.imageResponsive}/>
}

export default function TableImageList({ images }){
  return (
    <Container>
      {
        images.map((image)=>(
          <Image src={image.imgUrl}/>
        ))
      }
    </Container>
  )
}
