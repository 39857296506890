import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import TopMechanics from './TopMechanics';
import TopLines from './TopLines';
import {
  fetchTopResources,
} from './api';
import { getDateRangeQueryString } from './helpers';

export default function TopResources(props){
  const { filters, isCron } = props;
  const [topResources, setTopResources] = useState({
    machines: [],
    lines: []
  });

  useEffect(()=>{
    if(isCron) return;
    fetchTopResources({ 
      filters: getDateRangeQueryString(filters),
    })
    .then((result)=>{
      setTopResources({
        machines: result.top.data.machines,
        lines: result.top.data.lines
      });
    })
  }, [filters])

  return <Grid container spacing={1}>
    <Grid item xs={6}>
      <TopMechanics
        rows={topResources.machines}
      />
    </Grid>
    <Grid item xs={6}>
      <TopLines
        rows={topResources.lines}
      />
    </Grid>
  </Grid>
}
