import React, { useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { getTicketDetail } from "../../../redux/actions/Tickets/ticketActions";
import { getMachineById } from "../../../redux/actions/Machine/machineActions";
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import './LineStatus.scss';
import Media from './Media/Media';
import { formatDate } from '../../../utils/date';
import { socketConfig } from './LineStatusNormalMode/actions';
import { apiSettings } from '../../../utils/api/CoreApi';

const useStyles = makeStyles((theme) => ({
  drawerTitle: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingTop: '16px',
    paddingLeft: '16px',
    fontWeight: '500',
    fontSize: '2.3rem',
    fontFamily: 'Segoe UI',
  },
  drawerStatusRepaired: {
    display: 'flex',
    fontWeight: '500',
    fontFamily: 'Segoe UI',
    fontSize: '2.3rem',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    color: '#00DC07',
  },
  drawerStatusInRepaired: {
    display: 'flex',
    fontWeight: '500',
    fontFamily: 'Segoe UI',
    fontSize: '2.3rem',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    color: '#F59A23',
  },
  drawerStatusReported: {
    display: 'flex',
    fontWeight: '500',
    fontFamily: 'Segoe UI',
    fontSize: '2.3rem',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    color: '#D9001B',
  },
  paragQuestion: {
    display: 'flex',
    fontFamily: 'Segoe UI',
    fontSize: '1.2rem',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    color: '#aaa',
  },
  paragAnswers: {
    display: 'flex',
    fontFamily: 'Segoe UI',
    fontSize: '1.2rem',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    color: '#000',
  },
  topParag: {
    display: 'flex',
    fontFamily: 'Segoe UI',
    fontSize: '1.2rem',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingTop: '10px',
    paddingLeft: '16px',
    color: '#aaa',
  },
  topParagAnswers: {
    display: 'flex',
    fontFamily: 'Segoe UI',
    fontSize: '1.2rem',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingTop: '10px',
    paddingLeft: '16px',
    color: '#000',
  },
  drawerClose: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'flex-end',
  },
  attachment: {
    position: 'absolute',
    right: '34px',
    bottom: '9px',
  },
}));

function areEqual(prevProps, nextProps) {
  return false
}

function StationDetail(props) {
  const localTrans = {
    PROBLEM: 'Problem'.translate(),
    SOLUTION: 'Solution'.translate(),
    REMARKS: 'Remarks'.translate(),
    MACHINE_NO: 'Machine No.'.translate(),
    REPORTED_BY: 'Reported By'.translate(),
    REPAIRED_BY: 'Repaired By'.translate(),
    MACHINE_TYPE: 'Machine Type'.translate(),
    REPORTED_ON: 'Reported On'.translate(),
    REPAIRED_ON: 'Repaired On'.translate(),
    MACHINE_SUB_TYPE: 'Machine Sub Type'.translate(),
    REPORTED_ON_TIME: 'Reported on Time'.translate(),
    REPAIRED_ON_TIME: 'Repaired on Time'.translate(),
    BRAND: 'Brand'.translate(),
    GRABBED_BY: 'Grabbed By'.translate(),
    CLOSED_BY: 'Closed By'.translate(),
    MODEL_NO: 'Model No.'.translate(),
    GRABBED_ON: 'Grabbed On'.translate(),
    CLOSED_ON: 'Closed On'.translate(),
    MOTOR_TYPE: 'Motor Type'.translate(),
    GRABBED_ON_TIME: 'Grabbed on Time'.translate(),
    CLOSED_ON_TIME: 'Closed on Time'.translate()
  }
  const classes = useStyles();
  const trigger = props.trigger
  const ticketId = props.ticketId
  const machineId = props.machineId
  const ticketDetail = props.ticketDetail
  const machineDetail = props.machineDetail
  const assetDetail = props.assetDetail
  const socketTrigger = props.socketTrigger
  const [assetDetail1, setAssetDetail1] = React.useState(null);
  const [assetDetail2, setAssetDetail2] = React.useState(null);
  const [assetDetail3, setAssetDetail3] = React.useState(null);
  const [assetDetail4, setAssetDetail4] = React.useState(null);

  React.useEffect(() => {
    handleUpdateTicketDetail();
  }, [props.ticketId]);
  
  const handleUpdateTicketDetail = async () => {
    const socket = socketConfig(apiSettings.apiURLTicketsSocket);
    socket.on('web_updated_ticket', (event) => {
      try {
        const { ticketId: updatedTicketId } = JSON.parse(event);

        if(updatedTicketId === props.ticketId.toString()){
          props.getTicketDetail(props.ticketId);
        }
      } catch (error) {
        return false;
      }
    });
    return () => {
      socket.disconnect();
    };
  }

  useEffect(() => {
    if(ticketDetail.status === 'CLOSED' || ticketDetail.status == 'CANCELLED'){
      handleDrawerClose();
    }
  }, [ticketDetail]);

  useEffect(() => {
    props.getTicketDetail(ticketId);
    props.getMachineById(machineId);
  }, [ticketId, machineId]);


  useEffect(() => {
    let assets = assetDetail
    let videos = []
    let images = []
    if (assets.length > 0) {
      assets.map((item, itemsIndex) => {

        let checker = item.assetLink.substr(item.assetLink.length - 3);
        if (checker == 'mp4') {
          videos.push(item)
        } else {
          images.push(item)
        }
      })
      let sortedVideos = videos.sort((a, b) => { return a.createdDt < b.createdDt ? 1 : -1 })
      let sortedImages = images.sort((a, b) => { return a.createdDt < b.createdDt ? 1 : -1 })
      let asset1 = sortedImages[0] != undefined ? sortedImages[0].assetLink : null;
      let asset2 = sortedImages[1] != undefined ? sortedImages[1].assetLink : null;
      let asset3 = sortedImages[2] != undefined ? sortedImages[2].assetLink : null;
      let asset4 = sortedVideos[0] != undefined ? sortedVideos[0].assetLink : null;
      setAssetDetail1(asset1)
      setAssetDetail2(asset2)
      setAssetDetail3(asset3)
      setAssetDetail4(asset4)
    } else {
      setAssetDetail1(null)
      setAssetDetail2(null)
      setAssetDetail3(null)
      setAssetDetail4(null)
    }
  }, [assetDetail]);

  const getMechanic = () => {
    if (ticketDetail.canceledBy != null) {
      return ticketDetail.canceledBy
    } else if (ticketDetail.reportedBy != null) {
      return ticketDetail.reportedBy
    } else if (ticketDetail.grabbedBy != null) {
      return ticketDetail.grabbedBy
    } else if (ticketDetail.repairedBy != null) {
      return ticketDetail.repairedBy
    } else {
      return ticketDetail.closedBy
    }
  };

  const renderDate = (date) => {
    if (date !== null) {
      let timezone = moment.parseZone(date).format('Z');
      let dateTimeOnly = moment.parseZone(date).format('YYYY-MM-DD HH:mm:ss') + '+00:00';
      return moment(dateTimeOnly).utcOffset(timezone).format('DD-MM-YYYY');
    }
    else {
      return '-';
    }
  }

  const renderTime = (date) => {
    if (date !== null) {
      let timezone = moment.parseZone(date).format('Z');
      let dateTimeOnly = moment.parseZone(date).format('YYYY-MM-DD HH:mm:ss') + '+00:00';
      return moment(dateTimeOnly).utcOffset(timezone).format('HH:mm');
    }
    else {
      return '-';
    }
  }

  const renderText = (field) => {
    if (field === null || field === '') {
      return '-'
    }
    else {
      return field != undefined ? field.toString() : field
    }
  }

  const renderImageVideo = (media1, link1) => {
    return (
      <div>
        <Media media={media1} link={link1}></Media>
      </div>
    )
  }

  const checkerOfImageOrVideo = (media1) => {
    if (media1 != undefined) {
      let check = media1.substr(media1.length - 3);
      if (check == 'mp4') {
        return "video"
      } else {
        return "image"
      }
    }
  }

  const handleDrawerClose = () => {
    props.handleDrawerClose()
  }
  return (

    <div>
      <Grid container spacing={0}>
        <Grid container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          item xs={9}>
          <Grid item xs={12} >
            <p className={classes.drawerTitle}>{ticketDetail ? ticketDetail.place : null}</p>
          </Grid>
          <Grid item xs={4} >
            <p className={classes.paragAnswers}>{ticketDetail ? ticketDetail.ticketNo : null}</p>
          </Grid>
          <Grid item xs={4} >
            <p className={classes.paragAnswers}>{formatDate(ticketDetail ? ticketDetail.reportedDt : null)}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={ticketDetail ? ticketDetail.status == 'CANCELLED' ? classes.drawerStatusRepaired : ticketDetail.status == 'CLOSED' ? classes.drawerStatusRepaired : ticketDetail.status == 'REPAIRED' ? classes.drawerStatusRepaired : ticketDetail.status == 'REPORTED' ? classes.drawerStatusReported : classes.drawerStatusInRepaired : null}>{ticketDetail ? ticketDetail.status == undefined ? ticketDetail.status : ticketDetail.status.translate() : null}</p>
          </Grid>
          <Grid item xs={4} >
            <p className={classes.paragAnswers}>{getMechanic()}</p>
          </Grid>
          <Grid item xs={8} >
            <p className={classes.paragAnswers} style={{ fontWeight: 'bold', paddingLeft: '55px' }}>{formatDate(ticketDetail ? ticketDetail.reportedDt : null, 'HH:mm')}</p>
          </Grid>
        </Grid>
        <Grid container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          item xs={3}>
          <Grid item xs={11} />
          <Grid item xs={1} className={classes.drawerClose}>
            <IconButton onClick={() => handleDrawerClose()}> <CloseIcon></CloseIcon></IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          item xs={9}>
          <Grid item xs={2} >
            <p className={classes.topParag}>{localTrans.PROBLEM}</p>
          </Grid>
          <Grid item xs={10} >
            <p className={classes.topParagAnswers}>{renderText(ticketDetail ? ticketDetail.problemType : null)}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragQuestion}>{localTrans.SOLUTION}</p>
          </Grid>
          <Grid item xs={10} >
            <p className={classes.paragAnswers}>{renderText(ticketDetail ? ticketDetail.solutionType : null)}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragQuestion}>{localTrans.REMARKS}</p>
          </Grid>
          <Grid item xs={10} >
            <p className={classes.paragAnswers}>{renderText(ticketDetail ? ticketDetail.remarks : null)}</p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          item xs={9}>
          <Grid item xs={2} >
            <p className={classes.topParag}>{'Machine No'.translate() || localTrans.MACHINE_NO}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.topParagAnswers}>{ticketDetail ? ticketDetail.machineNo : null}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.topParag}>{'Reported By'.translate() || localTrans.REPORTED_BY}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.topParagAnswers}>{renderText(ticketDetail ? ticketDetail.reportedBy : null)}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.topParag}>{'Repaired By'.translate() || localTrans.REPAIRED_BY}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.topParagAnswers}>{renderText(ticketDetail ? ticketDetail.repairedBy : null)}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragQuestion}>{'Machine Type'.translate() || localTrans.MACHINE_TYPE}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragAnswers}>{machineDetail ? renderText(machineDetail.macTypeId_desc) : ''}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragQuestion}>{'Reported On'.translate() || localTrans.REPORTED_ON}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragAnswers}>{formatDate(ticketDetail ? ticketDetail.reportedDt : null)}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragQuestion}>{'Repaired On'.translate() || localTrans.REPAIRED_ON}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragAnswers}>{formatDate(ticketDetail ? ticketDetail.repairedDt : null)}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragQuestion}>{'Machine Sub Type'.translate() || localTrans.MACHINE_SUB_TYPE}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragAnswers}>{machineDetail ? renderText(machineDetail.macSubTypeId_desc) : ''}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragQuestion}>{'Reported on Time'.translate() || localTrans.REPORTED_ON_TIME}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragAnswers}>{formatDate(ticketDetail ? ticketDetail.reportedDt : null, 'HH:mm')}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragQuestion}>{'Repaired on Time'.translate() || localTrans.REPAIRED_ON_TIME}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragAnswers}>{formatDate(ticketDetail ? ticketDetail.repairedDt : null, 'HH:mm')}</p>
          </Grid>

        </Grid>
        <Grid container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          item xs={9}>
          <Grid item xs={2} >
            <p className={classes.topParag}>{'Brand'.translate() || localTrans.BRAND}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.topParagAnswers}>{machineDetail ? renderText(machineDetail.brandId_desc) : ''}</p>
          </Grid>                        <Grid item xs={2} >
            <p className={classes.topParag}>{'Grabbed By'.translate() || localTrans.GRABBED_BY}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.topParagAnswers}>{renderText(ticketDetail ? ticketDetail.grabbedBy : null)}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.topParag}>{'Closed By'.translate() || localTrans.CLOSED_BY}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.topParagAnswers}>{renderText(ticketDetail ? ticketDetail.closedBy : null)}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragQuestion}>{'Model No.'.translate() || localTrans.MODEL_NO}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragAnswers}>{renderText(ticketDetail ? ticketDetail.modelNo : null)}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragQuestion}>{'Grabbed On'.translate() || localTrans.GRABBED_ON}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragAnswers}>{formatDate(ticketDetail ? ticketDetail.grabbedDt : null)}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragQuestion}>{'Closed On'.translate() || localTrans.CLOSED_ON}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragAnswers}>{formatDate(ticketDetail ? ticketDetail.closedDt : null)}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragQuestion}>{'Motor Type'.translate() || localTrans.MOTOR_TYPE}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragAnswers}>{machineDetail ? renderText(machineDetail.motorTypeId_desc) : ''}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragQuestion}>{'Grabbed on Time'.translate() || localTrans.GRABBED_ON_TIME}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragAnswers}>{formatDate(ticketDetail ? ticketDetail.grabbedDt : null, 'HH:mm')}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragQuestion}>{'Closed on Time'.translate() || localTrans.CLOSED_ON_TIME}</p>
          </Grid>
          <Grid item xs={2} >
            <p className={classes.paragAnswers}>{formatDate(ticketDetail ? ticketDetail.closedDt : null, 'HH:mm')}</p>
          </Grid>
        </Grid>
        <Grid container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={classes.attachment}
          item xs={3}>
          <Grid className="form-control" container spacing={1}>
            {assetDetail1 != null ?
              (<Grid item xs={3}>
                {renderImageVideo(checkerOfImageOrVideo(assetDetail1), assetDetail1)}
              </Grid>)
              : null}

            {assetDetail2 != null ?
              (<Grid item xs={3}>
                {renderImageVideo(checkerOfImageOrVideo(assetDetail2), assetDetail2)}
              </Grid>)
              : null}

            {assetDetail3 != null ?
              (<Grid item xs={3}>
                {renderImageVideo(checkerOfImageOrVideo(assetDetail3), assetDetail3)}
              </Grid>)
              : null}

            {assetDetail4 != null ?
              (<Grid item xs={3}>
                {renderImageVideo(checkerOfImageOrVideo(assetDetail4), assetDetail4)}
              </Grid>)
              : null}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  let {translationReducer} = state
    let apiLoading = {
      ticketDetailLoading: state.ticketListReducer.loadingDetail,
      assetLoading: state.ticketListReducer.assetLoading,
      machineDetailLoading: state.machineListReducer.viewDetailLoading,
    };
  let data = {
    ticketDetail: state.ticketListReducer.ticketDetail,
    machineDetail: state.machineListReducer.machineDetail,
    assetDetail: state.ticketListReducer.assetDetail,
  };

  return {
    ...apiLoading,
    ...data,
    ...translationReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTicketDetail: (id) => dispatch(getTicketDetail(id)),
    getMachineById: (id) => dispatch(getMachineById(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StationDetail, areEqual);
