import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { TableCell } from '@material-ui/core';

const useStyles = makeStyles({
  active: {
    backgroundColor: '#1585F4 !important',
  },
})

const TableHeaderCell = withStyles({
  root: {
    padding: '5px 5px 5px 16px',
    background: '#EDF0F2 0% 0% no-repeat padding-box'
  },
  head: {
    borderRight: '2px solid white',
    borderBottom: 'none'
  }
})((props) => {
  const classes = useStyles();
  const activeClass = [props.className, props.active ? classes.active : null]
  return <TableCell {...props} className={activeClass} />
});

export default TableHeaderCell;
