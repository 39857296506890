import {
    MACTYPE_LIST_REQUEST,
    MACTYPE_LIST_SUCCESS,
    MACTYPE_LIST_ERROR,
    MACTYPE_DETAIL_REQUEST,
    MACTYPE_DETAIL_SUCCESS,
    MACTYPE_DETAIL_ERROR,
    MACTYPE_NEW_REQUEST,
    MACTYPE_NEW_SUCCESS,
    MACTYPE_NEW_ERROR,
    MACTYPE_UPDATE_REQUEST,
    MACTYPE_UPDATE_SUCCESS,
    MACTYPE_UPDATE_ERROR,
    MACTYPE_TRIGGER_HIGHLIGHT
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    detailLoading: false,
    triggerHighlight: false,
    macTypeList:[],
    macTypeDetail: null,
    meta: [],
    error: ''
}

const macTypeListReducer = (state = initialState, action) => {
    switch (action.type) {
        case MACTYPE_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case MACTYPE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                macTypeList: action.payload.macTypeList,
                meta: action.payload.meta,
                error: ''
            }
        case MACTYPE_LIST_ERROR:
            return {
                ...state,
                loading: false,
                macTypeList: [],
                meta: [],
                error: action.payload.message
            }
        case MACTYPE_DETAIL_REQUEST:
            return {
                ...state,
                detailLoading: true,
                macTypeDetail: null
            }
        case MACTYPE_DETAIL_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                macTypeDetail: action.payload.macTypeDetail
            }
        case MACTYPE_TRIGGER_HIGHLIGHT:
            return {
                ...state,
                triggerHighlight: action.payload
            }
        default: 
            return state;
    }
}

export default macTypeListReducer;