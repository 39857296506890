import React from 'react';
import {
    Dialog,
    DialogContent,
    TextField,
    Button,
    Box,
    makeStyles,
    Typography,
    FormLabel,
} from '@material-ui/core';
import { escapePhrase } from '../../../ChangeOvers/helpers';

const ConfirmCancel = ({ 
    open = false, 
    onClose = null,
    onSubmit = null,
    ticketNo = '-',
}) => {
    const [remarks, setRemarks] = React.useState(null);
    const [error, setError] = React.useState(false);
    const classes = useStyle();

    const handleOnClose = () => {
        onClose();
        setRemarks(null);
        setError(false);
    }

    const handleOnSubmit = () => {
        if(error) return;
        onSubmit(remarks);
        onClose();
        setRemarks(null);
    }

    const handleOnChangeRemark = ({ target: { value }}) => {
        setRemarks(value);
        value.length > 250 
            ? setError(true) 
            : setError(false);
    }

    return (
        <Dialog 
            open={open} 
            onClose={handleOnClose} 
            aria-labelledby='confirm-cancel-ticket-title'>
            <DialogContent className={classes.container}>
                <Box className={classes.title}>
                    <Typography>
                        {'Are you sure to cancel this ticket ?'.translate()}
                    </Typography>
                </Box>
                <Typography variant='subtitle1'>
                    {escapePhrase('Ticket %{0} will be cancelled.'.translate(), [ticketNo])}
                </Typography>
                <FormLabel className={classes.label}>
                    {'Remarks'.translate()}
                </FormLabel>
                <TextField 
                    className={classes.textarea} 
                    multiline 
                    onChange={handleOnChangeRemark} 
                    value={remarks}
                    name="remarks" 
                    minRows={5}
                    maxRows={5}
                    fullWidth
                    error={error}
                    helperText={error && 'Maximum 250 characters'.translate()}
                    variant="outlined" />
                <Box className={classes.btnGroup}>
                    <Button 
                        onClick={handleOnClose} 
                        variant="contained">
                            {'Cancel'.translate()}
                    </Button>
                    <Button 
                        onClick={handleOnSubmit} 
                        variant="contained" 
                        color="primary">
                            {'Confirm'.translate()}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

const useStyle = makeStyles(() => ({
    container: { width: 400 },
    label: { fontSize: 12, marginBottom: 5 },
    textarea: { '& .MuiInputBase-root': { padding: 8 }, '& > p': { fontSize: 12, margin: '0' } },
    title: { 
        display: 'flex', gap: 5, marginBottom: 5,
        '& > p': { fontSize: '1.5rem',  color: '#545454',  fontWeight: 'bold', lineHeight: '1.8' },
        '& > svg': { fontSize: '2.5rem',  color: '#545454' }
    },
    btnGroup: {
        marginTop: '20px',
        marginBottom: '20px',
        textAlign: 'right',
        '& .MuiButtonBase-root': {
            boxShadow: 'none',
            fontSize: '1rem',
            '&:hover': { boxShadow: 'none' },
            '& .MuiButton-contained': { 
                fontSize: '1.5rem', color: '', marginRight: '15px', paddingRight: '60px', paddingLeft: '60px',
                '&:last-child': { marginRight: '0px' }
            }
        },
    }
}));

export default ConfirmCancel;