import {
   FREQUENCY_LIST,
   FREQUENCY_LIST_REQUEST,
   FREQUENCY_LIST_SUCCESS,
   FREQUENCY_LIST_ERROR
 } from '../actionTypes';
 import { tokenGet, apiSettings } from '../../../utils/api/CoreApi';
 
 export const frequencyRequest = () => {
     return {
         type:FREQUENCY_LIST_REQUEST
     }
 }
 
 export const frequencySuccess = frequencyList => {
     return {
         type:FREQUENCY_LIST_SUCCESS,
         payload: frequencyList
     }
 }
 
 export const getFrequencies = (isActive = 1, currentPage = 1, pageSize = 100000) =>{
     return function(dispatch){
         dispatch(frequencyRequest());
         tokenGet(apiSettings.apiURLReferences + '/frequencies?isActive='+ isActive +'&currentPage=' + currentPage +'&pageSize=' + pageSize).then((response) => {
             let data = response.data.machines.result.map((item) => {
                let itemRow = {
                    'id': item.id,
                    'frequency': item.desc1
                }
                return itemRow;
             });
             dispatch(frequencySuccess({
                 frequencyList: data,
                 meta: response.data.machines.meta
             }));
         })
         .catch((error) => {
             console.log(error);
         });
     }
 }