import React, { useEffect, useState } from 'react';
import BarChart from './components/BarChart';
import CardAccordion from './components/CardAccordion';
import ViewBySelect from './components/ViewBySelect';
import { 
  normalizeBarChart, 
  kFormat, 
  normalizeDataTooltip, 
  getDateRangeQueryString,
} from './helpers';

import { client } from './api';
import { tooltips } from './Tooltips';

const options = [
  { value: 'totalTime', label: 'Total Time' },
  { value: 'avgTime', label: 'Average Time' }
]

export default function ReportedTimeBeforeRepair(props){
  const { 
    originalRows, 
    dictionary, 
    dateRange, 
    filters, 
    viewType, 
    isCron,
  } = props;
  const [reportedTimeBeforeRepair, setReportedTimeBeforeRepair] = useState(null);
  const [viewBy, setViewBy] = useState('totalTime');

  useEffect(()=> {
    if(isCron) return;
    const params = { 
      filters: getDateRangeQueryString(filters), 
      viewBy, 
      viewType,
    };
    client.get("/analytics/reported-time-before-repair", { params }).then(({ data }) => {
      const result = data.filter(({ id }) => id !== null);
      setReportedTimeBeforeRepair(result);
    })
  }, [filters, viewBy, viewType]);

  if(!reportedTimeBeforeRepair){ return null };
  const lineData = [
    {label: 'Total Response Time', borderColor: '#6D93F7', key: 'total'},
    {label: 'Average Response Time', borderColor: '#EA6FDC', key: 'avg'},
  ];
  const normalizedData = normalizeBarChart(
    reportedTimeBeforeRepair, 
    viewBy, 
    "marked", 
    dictionary, 
    dateRange,
    lineData,
  );
  const legends = [
    {title: 'Total Response Time'.translate(), color: '#6D93F7', key: 'totalTime'},
    {title: 'Average Response Time'.translate(), color: '#EA6FDC', key: 'avgTime'},
  ];

  return <CardAccordion
    title={"Reported Time Before Repair".translate()}
    legends={legends}
    filterForm={
      <ViewBySelect value={viewBy} label={"View By".translate()} onChange={(e) => setViewBy(e.target.value)} options={options}/>
    }
  >
    <BarChart
      optionTooltip={tooltips(
        normalizeDataTooltip(
          reportedTimeBeforeRepair, 
          dateRange, 
          'marked', 
          originalRows, 
          viewBy,
        ), 
        legends, 
        viewBy, 
        viewType,
        filters.dateRange,
        'Reported Time Before Repair'.translate(),
      )}
      xTickFormat={(value, index)=> `${kFormat(value)} ${'min'.translate()}`} 
      data={normalizedData}/>
  </CardAccordion>
}
