import {
   NOTIFICATION_ERROR,
   NOTIFICATION_SUCCESS,
   CHECKLIST_REQUEST,
   CHECKLIST_SUCCESS,
   CHECKLIST_ERROR,
   CHECKLIST_TEMPLATE_REQUEST,
   CHECKLIST_TEMPLATE_SUCCESS,
   MACHINE_LIST_CHECKLIST_REQUEST,
   MACHINE_LIST_CHECKLIST_SUCCESS,
   MACHINE_NEW_CHECKLIST_REQUEST,
   MACHINE_NEW_CHECKLIST_SUCCESS,
   CHECKLIST_TEMPLATE_ERROR,
   CHECKLIST_TEMPLATE_NEW_REQUEST,
   CHECKLIST_TEMPLATE_NEW_SUCCESS,
   CHECKLIST_TEMPLATE_NEW_ERROR,
   CHECKLIST_TEMPLATE_DETAIL_REQUEST,
   CHECKLIST_TEMPLATE_DETAIL_SUCCESS,
   CHECKLIST_TEMPLATE_DETAIL_ERROR,
   CHECKLIST_TEMPLATE_CHECKBOX_TOGGLE,
   CHECKLIST_TEMPLATE_SHOW_TOGGLE,
   CHECKLIST_TEMPLATE_CLOSE_TOGGLE,
   CHECKLIST_TEMPLATE_UPLOAD_REQUEST,
   CHECKLIST_TEMPLATE_UPLOAD_SUCCESS,
   CHECKLIST_TEMPLATE_UPLOAD_ERROR,
   CHECKLIST_SET,
   CHECKLIST_DETAIL_SET,
   CHECKLIST_DETAIL_REQUEST,
   CHECKLIST_DETAIL_SUCCESS,
   UPDATE_CHECKLIST_REQUEST,
   UPDATE_CHECKLIST_SUCCESS,
   POST_ASSIGN_CHECKLIST_REQUEST,
   POST_ASSIGN_CHECKLIST_SUCCESS,
   POST_SAVE_CHECKLIST,
   POST_SAVE_CHECKLIST_SUCCESS,
 } from '../actionTypes';
 import { tokenGet, tokenPost, tokenPut, tokenPostUpload, apiSettings } from '../../../utils/api/CoreApi';
 import history from '../../../history.js';
 import fileSaver from 'file-saver';

 export const notificationSuccess = (message) => {
    return {
        type: NOTIFICATION_SUCCESS,
        payload: {
            message: message.toString().translate()
        }
    }
}

export const notificationError = (message) => {
    return {
        type: NOTIFICATION_ERROR,
        payload: {
            message: message.toString().translate()
        }
    }
}

export const machineNewChecklistRequest = () => {
    return {
        type: MACHINE_NEW_CHECKLIST_REQUEST,
    }
}

export const machineNewChecklistSuccess = (payload) => {
    return {
        type: MACHINE_NEW_CHECKLIST_SUCCESS,
        payload: payload
    }
}
 
 export const checklistRequest = () => {
     return {
         type:CHECKLIST_REQUEST
     }
 }
 
 export const checklistSuccess = checklist => {
     return {
         type:CHECKLIST_SUCCESS,
         payload: checklist
     }
 }

 export const checklistTemplateRequest = () => {
    return {
        type:CHECKLIST_TEMPLATE_REQUEST
    }
}

export const checklistTemplateSuccess = checklistTemplates => {
    return {
        type:CHECKLIST_TEMPLATE_SUCCESS,
        payload: checklistTemplates
    }
}

export const machineListChecklistRequest = () => {
    return {
        type:MACHINE_LIST_CHECKLIST_REQUEST
    }
}

export const machineListChecklistSuccess = machineListChecklist => {
    return {
        type:MACHINE_LIST_CHECKLIST_SUCCESS,
        payload: machineListChecklist
    }
}

export const updateChecklistRequest = () => {
    return {
        type:UPDATE_CHECKLIST_REQUEST
    }
}

export const updateChecklistSuccess = () => {
    return {
        type:UPDATE_CHECKLIST_SUCCESS,
    }
}

export const postAssignChecklistToMachineRequest = () => {
    return {
        type:POST_ASSIGN_CHECKLIST_REQUEST
    }
}

export const postAssignChecklistToMachineSuccesss = () => {
    return {
        type:POST_ASSIGN_CHECKLIST_SUCCESS,
    }
}

export const checklistDetailRequest = () => {
    return {
        type:CHECKLIST_DETAIL_REQUEST
}
}

export const checklistDetailSuccess = checklistDetail => {
    return {
        type:CHECKLIST_DETAIL_SUCCESS,
        payload: checklistDetail
    }
}

export const toggleCheckboxSuccess = tickets => {
    return {
        type: CHECKLIST_TEMPLATE_CHECKBOX_TOGGLE,
        payload: tickets
    }
}

export const checklistShowToggle = (id,flag) => {
    return {
        type: CHECKLIST_TEMPLATE_SHOW_TOGGLE,
        payload: {
            id: id,
            flag: flag
        }
    }
}

export const postSaveChecklist = () => ({
    type: POST_SAVE_CHECKLIST
})

export const postSaveChecklistSuccess = () => ({
    type: POST_SAVE_CHECKLIST_SUCCESS
})

export const checklistCloseToggle = () => {
    return {
        type: CHECKLIST_TEMPLATE_CLOSE_TOGGLE,
    }
}

const renderTextWithCapitalization = (field) => {
    if (field === null || field === '' || field === 'null') {
        return '-'
    }
    else {
        let capitalized = field.split(' ').map(item =>
            item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(' ');
        return capitalized
    }
}

//API CALLS BELOW
 
 export const getChecklist = (userId = 1, isActive = 1, brandId = 'all', macSubTypeId = 'all', searchString = 'all', currentPage = 1, pageSize = 100000) =>{
     return function(dispatch){
         dispatch(checklistRequest());
         tokenGet(apiSettings.apiURLReferences + '/checklists?userId=' + userId + '&isActive='+ isActive + '&brandId=' + brandId + '&macSubTypeId=' + macSubTypeId + '&searchString=' + searchString +'&currentPage=' + currentPage +'&pageSize=' + pageSize).then((response) => {
             let data = response.data.machines.result.map((item) => {
                let itemRow = {
                    'id': item.id,
                    'checklist': item.desc1
                }
                return itemRow;
             });
             dispatch(checklistSuccess({
                 checklist: data,
                 meta: response.data.machines.meta
             }));
         })
         .catch((error) => {
             console.log(error);
         });
     }
 }

 export const toggleChecklistTableCheckboxById = (checklist, id) => {
    return function(dispatch){
        let data = checklist;
        let tmpArray = data.map((item) => {
        let row = null;
            if (item.selected.id === id){
                row = {
                    ...item,
                    selected: {
                        ...item.selected,
                        'value': !item.selected.value,
                    }
                }
            }
            else{
                row = {
                    ...item,
                    'value': false,
                }
            }
            return row;
        });
        dispatch(toggleCheckboxSuccess(tmpArray));
    }
}

export const setChecklistTemplates = (checklist) => {
    return {
        type: CHECKLIST_SET,
        payload: {
            checklistTemplates: checklist
        }
    }
}

export const setChecklistDetail = (checklistDetail) => {
    return {
        type: CHECKLIST_DETAIL_SET,
        payload: {
            checklistDetail: checklistDetail
        }
    }
}

 export const getChecklistTemplates = (statusId = 'all', searchString = 'all', currentPage = 1, pageSize = 100000) =>{
    return function(dispatch){
        dispatch(checklistTemplateRequest());
        tokenGet(apiSettings.apiURLReferences + '/checklists/overview?statusId=' + statusId + '&searchString='+ searchString + '&currentPage=' + currentPage + '&pageSize=' + pageSize).then((response) => {
            let data = response.data.checklists.result.map((item) => {
                let itemRow = {
                   'selected': {
                       'id' : item.id,
                       'value' : false,
                   },
                   'templateName': { 
                       'link': '/checklist-detail/' + item.id,
                       'linkId': item.id,
                       'value': item.checkListNo
                   },
                   'name': item.name,
                   'templateType': item.templateType,
                   'brand': renderTextWithCapitalization(item.brand),
                   'createdDt': item.createdDt,
                   'totalMachines': item.machines,
                //    'frequency': item.frequency,
                   'totalSteps': item.totalSteps,
                   'lastUsed': item.lastUsed,
                   'status': item.status,
               }
               return itemRow;
            });
            dispatch(checklistTemplateSuccess({
                checklistTemplates: data,
                searchString: searchString,
                rows: data.length,
                meta: response.data.checklists.meta
            }));
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const postChecklistTemplate = (payload) =>{
    return function(dispatch){
        dispatch(postSaveChecklist());
        tokenPost(apiSettings.apiURLReferences + '/checklists', payload)
            .then((response) => {
                dispatch(notificationSuccess('Successfully added new checklist template'));
                if (localStorage.getItem('checklistStatusId')){
                    let statusId = localStorage.getItem('checklistStatusId');
                    dispatch(getChecklistTemplates(statusId));
                }
                else{
                    dispatch(getChecklistTemplates());
                }
                history.push('/checklist');
            })
            .catch((err) => {
                if (Array.isArray(err.response.data.errors)){
                    dispatch(notificationError(err.response.data.errors[0]));
                }
                else{
                    dispatch(notificationError(err.response.data.error));
                }
            })
            .finally(() => {
                dispatch(postSaveChecklistSuccess());
            });
    }
}

export const putChecklistTemplate = (payload) =>{
    return function(dispatch){
        tokenPut(apiSettings.apiURLReferences + '/checklists', payload).then((response) => {
            dispatch(notificationSuccess('Successfully updated checklist template'));
            dispatch(getChecklistDetail(payload.id));
            if (localStorage.getItem('checklistStatusId')){
                let statusId = localStorage.getItem('checklistStatusId');
                dispatch(getChecklistTemplates(statusId));
            }
            else{
                dispatch(getChecklistTemplates());
            }
            history.push('/checklist-detail/' + payload.id);
        }).catch((err) => {
            if (Array.isArray(err.response.data.errors)){
                dispatch(notificationError(err.response.data.errors[0]));
            }
            else{
                dispatch(notificationError(err.response.data.error));
            }
        });
    }
}

export const postAssignChecklistToMachine = (payload) =>{
    console.log('post is running')
    console.log(payload)
    return function(dispatch){
        dispatch(postAssignChecklistToMachineRequest());
        tokenPost(apiSettings.apiURLReferences + '/checklists/assign-machine', payload).then((response) => {
            dispatch(postAssignChecklistToMachineSuccesss());
            dispatch(getMachinelist(payload.machineListPayload.tempType,payload.machineListPayload.id))
            dispatch(notificationSuccess('Successfully added checklist to a machine'));
        })
        .catch((error) => {
            console.log('lets check error')
            dispatch(notificationError(error));
            console.log(error);
        });
    }
}

export const postUploadTemplate = (payload,files, method) =>{
    return function(dispatch){
        tokenPostUpload(apiSettings.apiURLFiles + '/api/files', files).then((response) => {
             let mutatedPayload = {
                 ...payload,
                 instructionLink: response.data.files[0].path
             }
             if (method === 'new'){
                dispatch(postChecklistTemplate(mutatedPayload));
             }
             else if (method === 'edit'){
                dispatch(putChecklistTemplate(mutatedPayload));
             }
        });
    }
}

export const getChecklistDetail = (id) =>{
    return function(dispatch){
        dispatch(checklistDetailRequest());
        tokenGet(apiSettings.apiURLReferences + '/checklists/' + id).then((response) => {
            let data = response.data
            let keys = Object.keys(data)
            console.log(data.tasksCount)
            console.log(data.tasksDetailed)
            //neil
            let dataTaskCount = response.data.tasksCount.map((item) => {
                let itemRow = {
                   'task': item.task,
                   'subSteps': item.subSteps,
               }
               return itemRow;
            });
            let dataTasksDetailed = response.data.tasksDetailed.map((item) => {
                let itemRow = {
                   'subtasks': item.subtasks,
               }
               return itemRow;
            });
            dispatch(checklistDetailSuccess({
                taskCount: dataTaskCount,
                tasksDetailed:dataTasksDetailed,
                checklistDetail: data
            }));
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const getMachinelist = (temptype, checklistId) =>{
    return function(dispatch){
        dispatch(machineListChecklistRequest());
        if (temptype === 'Repair Checklist' || temptype === 'CL'){
            temptype = 'CL';
        } 
        else if (temptype === 'Changeover Checklist' || temptype === 'C0') {
            temptype = 'C0';
        }
        else{
            temptype = 'PM';
        }
        tokenGet(apiSettings.apiURLReferences + '/checklists/assign-machine-list?temptype=' + temptype + '&checklistId='+ checklistId).then((response) => {
            let data = response.data.machines.map((item) => {
                let itemRow = {
                   'id': item.id,
                   'machine': item.machine,
               }
               return itemRow;
            });
            dispatch(machineListChecklistSuccess({
                machineListChecklist: data,
            }));
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const getChecklistInstructions = (link) =>{
    return function(dispatch){
        let token = JSON.parse(localStorage.getItem('userToken'));
        let fileName = link.replace('/files/','');
        let fileLink = apiSettings.apiURLFiles + btoa("/api/files/by-name?path=" + link + "&token=" + token);
        tokenGet(fileLink).then((response) => {
            if (link.indexOf('.txt') >= 0){
                var txtData = new Blob([response.data], {type: "text/plain;charset=utf-8"});
                fileSaver.saveAs(txtData, fileName);
            }
            else if (link.indexOf('.pdf') >= 0){
                fileSaver.saveAs(fileLink, fileName);
            }
        }).catch((error) => {
            dispatch(notificationError(error));
            console.log(error)
        })
    }
}

export const putInActiveChecklist = (payload) => {
    return function(dispatch){
        dispatch(updateChecklistRequest());
        tokenPut(apiSettings.apiURLReferences + '/checklists/status', payload).then((response) => {
            dispatch(updateChecklistSuccess());
            dispatch(notificationSuccess('Successfully updated Checklist'));
        }).catch((error) => {
            dispatch(notificationError(error));
            console.log(error)
        })
    }
}

export const putInActiveChecklistDetail = (payload, loadStatusId = null) => {
    return function(dispatch){
        dispatch(updateChecklistRequest());
        tokenPut(apiSettings.apiURLReferences + '/checklists/status', payload).then((response) => {
            dispatch(updateChecklistSuccess());
            dispatch(getChecklistDetail(payload.id));
            dispatch(notificationSuccess('Successfully updated Checklist'));
            if (loadStatusId !== null){
                dispatch(getChecklistTemplates(loadStatusId));
            }
        }).catch((error) => {
            dispatch(notificationError(error));
            console.log(error)
        })
    }
}