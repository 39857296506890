import { required } from "../containers/Modules/Configuration/MachineConfig/AttachmentForm/FormValidation";
import { apiSettings } from "./api/CoreApi";

export const displayDefaultImage = (imgLink) => {
    if(!imgLink) {
        return require('../assets/img/no-image.png');
    }

    try {
        let { apiURLFiles } = apiSettings
        let token = JSON.parse(localStorage['userToken'] ?? null)
        let formatLink = `/api/files/by-name?path=${imgLink}&token=${token}&unlinked`
        let btoaLink = `${apiURLFiles}${formatLink} `
        return btoaLink
    }
    catch (err) {
        return required('../assets/img/no-image.png');
    }
}