import React from 'react';
import { connect } from 'react-redux';
import { deleteMotorType } from '../../../../../redux/actions/MotorTypes/motorTypeActions';
import { confirmAlert } from 'react-confirm-alert';
import history from '../../../../../history';
import Table from '../../../../../components/CollapsableTable/CollapseTable'
import { getMotorTypes } from '../../../../../redux/actions/MotorTypes/motorTypeActions'
import dictionary from '../../../../../redux/actions/Translation/dictionary'
import { checkPageAccessField } from '../../../../../utils/helper';
import './MotorType.scss';

class MotorType extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            translations: {
                ...this.convert()
            },
            translationTimestamp: undefined,
            tableState: {
                add: () => {
                    history.push('/configuration/machine-config/motor-type/new')
                },
                edit: ({ id }) => {
                    history.push(`/configuration/machine-config/motor-type/edit/${id}`)
                },
                remove: ({ id }) => {
                    confirmAlert({
                        title: this.state?.translations?.are_you_sure_delete_motory_type,
                        message: '',
                        buttons: [
                            {
                                label: this.state?.translations?.YES,
                                onClick: () => {
                                    this.props.deleteMotorType(id);
                                }
                            },
                            {
                                label: this.state?.translations?.NO,
                            }
                        ]
                    });
                },
                search: true,
                highlight: true,
                keys: this.initTableHeader(),
                data: [],
                loading: false
            }
        }
    }
    /**handler */
    initTableHeader = () => {
        let { STATUS, MOTOR_TYPE_CODE, DISPLAY_NAME, DESCRIPTION, UPDATED_DATE } = this.convert()
        return [
            {
                name: MOTOR_TYPE_CODE,
                key: 'motorType',
                tableHeaderWidth: '12%'

            },
            {
                name: DISPLAY_NAME,
                key: 'desc1',
                tableHeaderWidth: '15%'

            },
            {
                name: DESCRIPTION,
                key: 'desc2',
                tableHeaderWidth: '20%'

            },
            {
                name: STATUS,
                type: 'boolean',
                key: 'isActive',
                tableHeaderWidth: '10%'

            },
            {
                name: UPDATED_DATE,
                type: 'date',
                key: 'updatedDt',
                alt: 'createdDt',
                tableHeaderWidth: '10%'

            },
        ]
    }
    convert = () => {
        let { YES, NO, NOTIFICATIONS: { are_you_sure_delete_motory_type }, STATUS, MOTOR_TYPE_CODE, DISPLAY_NAME, DESCRIPTION, UPDATED_DATE } = dictionary.GET()
        return dictionary.TRANSLATE({ YES, NO, are_you_sure_delete_motory_type, STATUS, MOTOR_TYPE_CODE, DISPLAY_NAME, DESCRIPTION, UPDATED_DATE })
    }
    componentDidMount() {
        // console.log(this.props)
        this.props.getMotorTypes(false, 'all');
    }

    componentDidUpdate() {
        // console.log('didUpdate', this.props, this.state)
        let { loading, motorTypeList } = this.props
        let { loading: stateLoading, data } = this.state.tableState
        if (loading != stateLoading || motorTypeList?.length != data?.length) {
            if(Array.isArray(motorTypeList)){
                let tableState = this.state.tableState;
                if (checkPageAccessField(this.props.userAccess,{pageId:21,pageAccess: 'VIEW'})){
                    tableState = {
                        add: null,
                        edit: null,
                        remove: null
                    }
                }
                this.setState({
                    tableState: {
                        ...tableState,
                        data: [...motorTypeList],
                        loading,
                        timeStamp: new Date()
                    }
                })
            }
        }
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                translations: Object.assign({}, { ...this.convert() }),
                tableState: {
                    ...this.state.tableState,
                    keys: this.initTableHeader(),
                    timeStamp: new Date()
                }
            })
        }
    }

    componentWillUnmount() {
    }

    /**render */
    render() {
        return (
            <div className="motor-type-body-overview">
                <Table {...this.state.tableState} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    let { motorTypeListReducer, translationReducer } = state
    let apiLoading = {
    }
    let data = {
        userAccess: state.authReducer.access,
    }

    return {
        ...apiLoading,
        ...data,
        ...motorTypeListReducer,
        ...translationReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getMotorTypes: (ev, scope) => dispatch(getMotorTypes(ev, scope)),
        deleteMotorType: (id) => dispatch(deleteMotorType(id))
    }
}

MotorType = connect(mapStateToProps, mapDispatchToProps)(MotorType);

export default MotorType;
