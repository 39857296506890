import React from 'react';
import {
  TableCell,
  Box,
  Hidden,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const Paper = withStyles({
  root: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
    paddingBottom: 12,
    height: 56,
    alignItems: 'center',
    display: 'flex',
    boxSizing: 'border-box',
  }
})(Box);


const StyledTableCell = withStyles({
  root: {
    padding: 0,
    paddingTop: 10,
    maxWidth: 150,
  }
})(TableCell)

export default function TableCardCell({ children, ...props }){
  return <StyledTableCell {...props}>
    <Paper>
      {children}
    </Paper>
  </StyledTableCell>
}