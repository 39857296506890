import React from 'react';
import moment from 'moment';
import Station from '../Station';
import io from 'socket.io-client';

export const socketConfig = (url) => {
  return io(url, {
    transports: ['websocket'],
    secure: true,
    query: `token=${JSON.parse(localStorage['userToken'])}`,
  })
}

export const sortingLines = (data) => {
  try {
    const sortedObj = {};
    const keysWithMachines = [];
    const keysWithoutMachines = [];
    for (const key in data) {
      if (data[key].machines.length === 0) {
        keysWithoutMachines.push(key);
      } else {
        keysWithMachines.push(key);
      }
    }
    keysWithMachines.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    keysWithoutMachines.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    for (const key of keysWithMachines) {
      sortedObj[key] = data[key];
    }
    for (const key of keysWithoutMachines) {
      sortedObj[key] = data[key];
    }
    return sortedObj;
  } catch (error) {
    return {};
  }
}

export const renderSlides =(
    lines,
    linePerSlide,
    systemPreference,
    socketTrigger,
    disabler,
    callback,
) => {
    let sortedData = {};
    let lineData = lines;
    let multiArrayLines = Object.keys(lineData);
    let slides = [];
    let linePerSlideCount = linePerSlide;
    let currentIndex = 0;
    let remainderGetter = multiArrayLines.length % linePerSlideCount
    let getQuotient = Math.floor(multiArrayLines.length / linePerSlideCount)
    let slideLength = getQuotient
    if(remainderGetter > 0){
     slideLength = slideLength + 1
    }
   while (currentIndex < slideLength) {
     let endIndex = (linePerSlideCount * (currentIndex + 1))
     let startIndex = linePerSlideCount * currentIndex
     let columnLines = multiArrayLines.slice(startIndex, endIndex);
     let rowsMachines = []
     let highestTicketDate = []
     let columns = []
     let index = currentIndex + 1
     for(let i = 0; i < columnLines.length; i++){
       let data = renderMachines(lineData[columnLines[i]])
       let machines = data[0]
       rowsMachines.push(machines)
       highestTicketDate.push({ date:data[1], status: data[2] })
       columns.push({
         label:columnLines[i]
       })
     }
     
     let line1 = (
       <Station
         systemPreference={systemPreference}
         key={index}
         columnLines={columns}
         rowsMachines={rowsMachines}
         socketTrigger={socketTrigger}
         highestTicketDate={highestTicketDate}
         disabler={disabler}
       >
       </Station>
     )
     currentIndex += 1;
      slides.push(line1)
   }
   callback(slides, false);
}

export const renderMachines = (linesData) => {
  let machines = linesData.machines.slice();
  let list = [];
  let count = 0;
  let sameNumber = '';
  let durations = 0;
  let highestTicketDate = '';
  let highestTicketDateStatus = '';
  for (let i = 0; i < machines.length; i++) {
    list.push(parseInt(machines[i].station.replace(/\D/g, '')));
    let sortedNumber = parseInt(machines[i].station.replace(/\D/g, ''));
    if (sortedNumber == sameNumber) {
      sortedNumber = sortedNumber + 0.01;
    }
    sameNumber = sortedNumber;
    machines[i]['sortNumber'] = sortedNumber;
    machines[i]['emptyStation'] = false;
    if (i == machines.length - 1) {
      count = parseInt(machines[i].station.replace(/\D/g, ''));
    }
    let ticketDate = null;
    if (machines[i].ticketStatusId_desc === 'REPORTED') {
      ticketDate = machines[i].reportedDt;
    } else if (machines[i].ticketStatusId_desc === 'IN-REPAIR') {
      ticketDate = machines[i].grabbedDt;
    } else if (machines[i].ticketStatusId_desc === 'REPAIRED') {
      ticketDate = machines[i].repairedDt;
    } else {
      ticketDate = machines[i].reportedDt;
    }
    let timezone = moment.parseZone(ticketDate).format('Z');
    let dateTimeOnly =
      moment.parseZone(ticketDate).format('YYYY-MM-DD HH:mm:ss') + '+00:00';
    let dateParsed = moment
      .parseZone(dateTimeOnly)
      .seconds(0)
      .milliseconds(0)
      .utcOffset(timezone);
    let currentDate = moment().seconds(0).milliseconds(0).utcOffset(timezone);
    let downtime = currentDate.diff(dateParsed);
    let duration = moment.duration(downtime);
    let timer = duration.asMinutes();
    if (timer >= 0) {
      if (!isNaN(timer)) {
        if (timer >= durations) {
          durations = timer;
          highestTicketDate = ticketDate;
          highestTicketDateStatus = machines[i].ticketStatusId_desc;
        }
      }
    }
    machines[i]['timezone'] = timezone;
    machines[i]['dateParsed'] = dateParsed;
  }
  for (let i = 1; i <= count; i++) {
    if (list.indexOf(i) == -1) {
      machines.push({
        sortNumber: i,
        emptyStation: true,
        hasOpenTicket: 'false',
      });
    } else {
      let checkData = machines.filter((item) => {
        if (item.station != undefined) {
          return item.station.indexOf(i + '-A') >= 0;
        }
      });
      if (checkData.length > 0) {
        let count = duplicateCount(list, i);
        if (count < 2) {
          machines.push({
            sortNumber: i,
            emptyStation: true,
            hasOpenTicket: 'false',
          });
        }
      }
    }
  }
  let fullData = [];
  let sortedMachines = machines.sort((a, b) =>
    a.sortNumber > b.sortNumber ? 1 : -1
  );
  fullData.push(sortedMachines, highestTicketDate, highestTicketDateStatus);
  return fullData;
};

export const handleChangeArea = (event, areas, callback) => {
  const areaId = event.target.value;
  const totalLines = areas.find(
    (item) => item.areaId == event.target.value
  ).totalLines;
  callback(areaId, totalLines);
}

export const handleSetLinePerSlide = (callback) => {
  let screenWidth = window.innerWidth;
  if (screenWidth >= 1350 && screenWidth <= 1429) callback(5);
  if (screenWidth >= 1430 && screenWidth <= 1529) callback(8);
  if (screenWidth >= 1530 && screenWidth <= 1629) callback(10);
  if (screenWidth >= 1630 && screenWidth <= 1729) callback(10);
  if (screenWidth >= 1730 && screenWidth <= 1829) callback(10);
  if (screenWidth >= 1830 && screenWidth <= 1920) callback(15);
};

export function duplicateCount(arr, value) {
  let filteredData = arr.filter((item) => {
    return item === value;
  });
  if (filteredData.length > 0) {
    return filteredData.length;
  } else {
    return 0;
  }
}
