import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Row } from './row';
import IndeterminateCheckBox from '../../IndeterminateCheckBox';
import TableHeaderCell from '../../Table/TableHeaderCell';
import MuiTable from '../../Table/MuiTable';
import MuiTableSortLabel from '../../Table/MuiTableSortLabel';
import { viewsByFilters } from '../constant';

export default function TableMachineBreakDown(props){
  const { rows, toggleRows, order, orderBy, handleRequestSort, viewBy } = props;
  const checkedAll = rows.every((r)=> r.isSelected);

  const handleChange = (event, row)=>{
    const rows = row.rows ? row.rows : [row]
    if(event.target.checked){
      toggleRows(rows, true)
    }else{
      toggleRows(rows, false)
    }
  }

  const toggleCheckAll = (event)=>{
    const flattenRows = rows.map((r)=> r.rows ? r.rows : r).flat();
    if(event.target.checked){
      toggleRows(flattenRows, true);
    }else{
      toggleRows(flattenRows, false);
    }
  }

  const totalTimes = rows.reduce((sum, row)=> sum + row.totalTime, 0);
  const totalTickets = rows.reduce((sum, row)=> sum + row.totalTickets, 0);
  const avgTimes = rows.reduce((sum, row)=> sum + row.totalTime, 0) / totalTickets;
  const totalOverdueTicketsP = rows.filter((row) => row.isSelected)
    .reduce((sum, row)=> sum + row.totalOverdueTicketsP , 0);
  
  return <TableContainer>
    <MuiTable>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: 30, borderBottom: 'none', padding: '0 16px' }}>
            <IndeterminateCheckBox
              checked={checkedAll}
              onChange={(e)=> toggleCheckAll(e)}
            />
          </TableCell>
          <TableHeaderCell active={orderBy == "name"}>
            <MuiTableSortLabel
              active={orderBy == "name"}
              direction={orderBy == "name" ? order : 'asc'}
              onClick={()=> handleRequestSort("name")}
            >
              {viewsByFilters[viewBy-1].translate()}
            </MuiTableSortLabel>
          </TableHeaderCell>
          <TableHeaderCell active={orderBy == "totalOverdueTicketsP"}>
            <MuiTableSortLabel
              active={orderBy == "totalOverdueTicketsP"}
              direction={orderBy == "totalOverdueTicketsP" ? order : 'asc'}
              onClick={()=> handleRequestSort("totalOverdueTicketsP")}
            >
              {'MAINT. OD%'.translate()}
            </MuiTableSortLabel>
          </TableHeaderCell>
          <TableHeaderCell active={orderBy == "avgTime"}>
            <MuiTableSortLabel
              active={orderBy == "avgTime"}
              direction={orderBy == "avgTime" ? order : 'asc'}
              onClick={()=> handleRequestSort("avgTime")}
            >
              {'AVG. MIN'.translate()}
            </MuiTableSortLabel>
          </TableHeaderCell>
          <TableHeaderCell active={orderBy == "totalTimeP"}>
            <MuiTableSortLabel
              active={orderBy == "totalTimeP"}
              direction={orderBy == "totalTimeP" ? order : 'asc'}
              onClick={()=> handleRequestSort("totalTimeP")}
            >
              {'TTL TIME%'.translate()}
            </MuiTableSortLabel>
          </TableHeaderCell>
          <TableHeaderCell active={orderBy == "totalTime"}>
            <MuiTableSortLabel
              active={orderBy == "totalTime"}
              direction={orderBy == "totalTime" ? order : 'asc'}
              onClick={()=> handleRequestSort("totalTime")}
            >
              {'TTL. MIN'.translate()}
            </MuiTableSortLabel>
          </TableHeaderCell>
          <TableHeaderCell active={orderBy == "totalTicketsP"}>
            <MuiTableSortLabel
              active={orderBy == "totalTicketsP"}
              direction={orderBy == "totalTicketsP" ? order : 'asc'}
              onClick={()=> handleRequestSort("totalTicketsP")}
            >
              {'TTL TKTS%'.translate()}
            </MuiTableSortLabel>
          </TableHeaderCell>
          
          <TableHeaderCell active={orderBy == "totalTickets"}>
            <MuiTableSortLabel
              active={orderBy == "totalTickets"}
              direction={orderBy == "totalTickets" ? order : 'asc'}
              onClick={()=> handleRequestSort("totalTickets")}
            >
              {'TTL TKTS'.translate()}
            </MuiTableSortLabel>
          </TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => <Row key={index} handleChange={handleChange} row={row}/>)}
        <TableRow>
          <TableCell size="small">
          </TableCell>
          <TableCell size="small"><b>{'Total'.translate()}</b></TableCell>
          <TableCell size="small"><b>{totalOverdueTicketsP.toFixed(1)}%</b></TableCell>
          <TableCell size="small"><b>{avgTimes.toFixed(2)}</b></TableCell>
          <TableCell size="small"><b>100.0%</b></TableCell>
          <TableCell size="small"><b>{
             totalTimes 
              ? totalTimes.toFixed(2).toLocaleString()
              : 0
          }</b></TableCell>
          <TableCell size="small"><b>100.0%</b></TableCell>
          <TableCell size="small"><b>{totalTickets}</b></TableCell>
        </TableRow>
      </TableBody>
    </MuiTable>
  </TableContainer>
}
