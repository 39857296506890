import {
    MENU_DRAWER_OPEN,
    MENU_DRAWER_CLOSE
} from '../actionTypes';
import { capitalize } from '../../../utils/helper';

export const menuDrawerOpen = () => {
    return {
        type: MENU_DRAWER_OPEN,
        payload: {
            open: true
        }
    }
}

export const menuDrawerClose = () => {
    return {
        type: MENU_DRAWER_CLOSE,
        payload: {
            open: false
        }
    }
}