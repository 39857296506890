import {
   NOTIFICATION_SUCCESS,
   NOTIFICATION_ERROR,
   MOTOR_TYPE_LIST,
   MOTOR_TYPE_LIST_REQUEST,
   MOTOR_TYPE_LIST_SUCCESS,
   MOTOR_TYPE_LIST_ERROR,
   MOTOR_TYPE_DETAIL_REQUEST,
   MOTOR_TYPE_DETAIL_SUCCESS,
   MOTOR_TYPE_DETAIL_ERROR,
   MOTOR_TYPE_NEW_REQUEST,
   MOTOR_TYPE_NEW_SUCCESS,
   MOTOR_TYPE_NEW_ERROR,
   MOTOR_TYPE_UPDATE_REQUEST,
   MOTOR_TYPE_UPDATE_SUCCESS,
   MOTOR_TYPE_UPDATE_ERROR,
   MOTOR_TYPE_DELETE_REQUEST,
   MOTOR_TYPE_DELETE_SUCCESS,
   MOTOR_TYPE_DELETE_ERROR,
   MOTOR_TYPE_TRIGGER_HIGHLIGHT,
 } from '../actionTypes';
 import { tokenGet, tokenPost, tokenPut,tokenDelete, apiSettings } from '../../../utils/api/CoreApi';
 import history from '../../../history';

 export const notificationSuccess = (message) => {
    return {
        type: NOTIFICATION_SUCCESS,
        payload: {
            message: message.translate()
        }
    }
}

export const notificationError = (message) => {
    return {
        type: NOTIFICATION_ERROR,
        payload: {
            message: message.translate()
        }
    }
}
 
 export const motorTypeRequest = () => {
     return {
         type:MOTOR_TYPE_LIST_REQUEST
     }
 }
 
 export const motorTypeSuccess = motorTypeList => {
     return {
         type:MOTOR_TYPE_LIST_SUCCESS,
         payload: motorTypeList
     }
 }

 export const motorTypeDetailRequest = () => {
    return {
        type:MOTOR_TYPE_DETAIL_REQUEST
    }
}

export const motorTypeDetailSuccess = motorType => {
    return {
        type:MOTOR_TYPE_DETAIL_SUCCESS,
        payload: motorType
    }
}

 export const motorTypeNewRequest = () => {
    return {
        type:MOTOR_TYPE_NEW_REQUEST
    }
}

export const motorTypeNewSuccess = motorType => {
    return {
        type:MOTOR_TYPE_NEW_SUCCESS,
        payload: motorType
    }
}


export const motorTypeUpdateRequest = () => {
    return {
        type:MOTOR_TYPE_UPDATE_REQUEST
    }
}

export const motorTypeUpdateSuccess = motorType => {
    return {
        type:MOTOR_TYPE_UPDATE_SUCCESS,
        payload: motorType
    }
}

export const motorTypeDeleteRequest = () => {
    return {
        type:MOTOR_TYPE_DELETE_REQUEST
    }
}

export const motorTypeDeleteSuccess = motorType => {
    return {
        type:MOTOR_TYPE_DELETE_SUCCESS,
        payload: motorType
    }
}

export const motorTypeTriggerHighlight = flag => {
    return {
        type:MOTOR_TYPE_TRIGGER_HIGHLIGHT,
        payload: flag
    }
}
 
 export const getMotorTypes = (filtered = true,isActive = 1, currentPage = 1, pageSize = 100000) =>{
    localStorage['filtered'] = filtered
    return function(dispatch){
         dispatch(motorTypeRequest());
         tokenGet(apiSettings.apiURLReferences + '/motortypes?isActive='+ isActive +'&currentPage=' + currentPage +'&pageSize=' + pageSize).then((response) => {
             let data
             if(localStorage['filtered'] == true){
                data =  response.data.data.result.map((item) => {
                    let itemRow = {
                        'id': item.id,
                        'desc1': item.desc1
                    }
                    return itemRow;
                 })
             }
             else data =  response.data.data.result
             delete localStorage['filtered']
             dispatch(motorTypeSuccess({
                 motorTypeList: data,
                 meta: response.data.data.meta
             }));
         })
         .catch((error) => {
             console.log(error);
         });
     }
 }

 export const getMotorTypeDetail = (id) =>{
    return function(dispatch){
         dispatch(motorTypeDetailRequest());
         tokenGet(apiSettings.apiURLReferences + '/motortypes/' + id).then((response) => {
             dispatch(motorTypeDetailSuccess({
                 motorTypeDetail: response.data.machine[0],
             }));
         })
         .catch((error) => {
             console.log(error);
         });
     }
 }

 export const postMotorTypeNew = (payload) => {
    return function(dispatch){
        dispatch(motorTypeNewRequest());
        tokenPost(apiSettings.apiURLReferences + "/motortypes",payload).then((response) => {
            dispatch(getMotorTypes(false,'all'));
            dispatch(notificationSuccess('Successfully added motor type'));
            history.push('/configuration/machine-config/motor-type');
        })
        .catch((error) => {
            if (error.message.indexOf(500) >= 0){
                dispatch(notificationError('Motor type code exists'));
                dispatch(motorTypeTriggerHighlight(true));
            }
            console.log(error);
        });
    }
 }

 export const putMotorTypeUpdate = (id,payload) => {
    return function(dispatch){
        dispatch(motorTypeUpdateRequest());
        tokenPut(apiSettings.apiURLReferences + "/motortypes/" + id,payload).then((response) => {
            dispatch(getMotorTypes(false,'all'));
            dispatch(notificationSuccess('Successfully updated motor type'));
            history.push('/configuration/machine-config/motor-type');
        })
        .catch((error) => {
            console.log(error);
        });
    }
 }

 export const deleteMotorType = (id) => {
    return function(dispatch){
        dispatch(motorTypeDeleteRequest());
        tokenDelete(apiSettings.apiURLReferences + "/motortypes/" + id).then((response) => {
            dispatch(getMotorTypes(false,'all'));
            dispatch(notificationSuccess('Successfully deleted motor type'));
        })
        .catch((error) => {
            if (error.message.indexOf(500) >= 0){
                dispatch(notificationError('Cannot delete because it is currently referenced to another record.'));
            }
            console.log(error);
        });
    }
 }