import { 
    AppBar, 
    Box, 
    IconButton, 
    Toolbar, 
    Typography,
} from '@material-ui/core';
import React from 'react';
import CloseIcon from '../../../../../../assets/icons/CloseIcon';
import { BetaIcon } from '../../../../ChangeOvers/Detail/MachineSummary/BetaIcon';
import { useStyles } from "../../styles";

export default function Header(props) {
    const classes = useStyles();
    return (
        <AppBar className={classes.appBar}>
            <Toolbar style={{ justifyContent: 'space-between' }}>
                <Box display='flex' alignItems='center'>
                    <Typography variant="h6" className={classes.title}>
                        {'Machine Summary'.translate()}
                    </Typography>
                    <BetaIcon />
                </Box>
                <IconButton edge="start" onClick={props.onClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
}