export const PAGE_ENUMS = {
  machines: "machines",
  machineDetail: "macdet",
  tickets: "tickets",
  ticketDetail: "ticdet",
  lineOverview: "lineoverview",
  machinesUpForMaintenance: "macupmaint",
  checklistTemplates: "chklsttmp",
  checklistDetail: "chklstdet",
  brands: "brand",
  motorTypes: "motortype",
  machineTypes: "mactype",
  machineSubTypes: "macsubtype",
  attachments: "attachment",
  repairTypes: "reptype",
  systemPreference: "syspref",
  analytics: "analytics",
  supplier: "supplier"
}
