import React from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import { Scrollbars } from 'react-custom-scrollbars';
import { checkIfApiLoading, checkPageActionAccess,checkPageAccess, checkPageAccessField } from '../../utils/helper';
import { getTicketDetail,ticketUpdateDisplayedRows, toggleTicketTableCheckboxById, toggleTicketTableCheckboxAll,ticketListShowToggle, toggleMaintenanceTableCheckboxAll, toggleMaintenanceTableCheckboxById, maintenanceUpdateDisplayedRows,  maintenanceListShowToggle, maintenanceListCloseToggle} from '../../redux/actions/Tickets/ticketActions';
import { getMachineById,machineListUpdateTotal,machineUpdateDisplayedRows, machineListShowToggle,toggleMachineTableCheckboxAll,toggleMachineTableCheckboxById } from '../../redux/actions/Machine/machineActions';
import { getStatus } from '../../redux/actions/Status/StatusActions';
import { getMacTypes } from '../../redux/actions/MacTypes/MacTypeActions';
import { getBrands } from '../../redux/actions/Brand/brandActions';
import { getMotorTypes } from '../../redux/actions/MotorTypes/motorTypeActions';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import Tooltip from '@material-ui/core/Tooltip';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import './MainTable.scss';
import { formatDate } from '../../utils/date';
import { IndeterminateCheckBoxOutlined } from '@material-ui/icons';
import { 
  Box, 
  CircularProgress, 
} from '@material-ui/core';

class MainTable extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      languageProps: {
        NORESULT: 'No results found'.translate()
      },
        rowsPerPage: 5,
        tooltipTheme: createMuiTheme({
          overrides: {
            MuiTooltip: {
              tooltip: {
                fontSize: "1rem",
              }
            }
          }
        }),
      translationTimestamp: undefined


    }
  }

  renderMachineType(id){
      let machine = this.props.macTypeList.filter((item) => item.id === id);
      if (machine.length > 0){
        return machine[0].macType
      }
      else{
        return '';
      }
  }

  renderBrand(id){
    let brand = this.props.brandList.filter((item) => item.id === id);
    if (brand[0].desc1 === null || brand[0].desc1 === '' || brand[0].desc1 === 'null'){
        return ''
    }
    else{
        let capitalized = brand[0].desc1.split(' ').map(item =>
            item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(' ');
            return capitalized
    }
  }

  renderStatus(id){
    let status = this.props.statusList.filter((item) => item.id === id);
    if (status.length > 0){
      return status[0].status.translate();
    }
    else{
        return ''
    }
  }

  renderMotorType(id){
    let motorType = this.props.motorTypeList.filter((item) => item.id === id);
    if (motorType.length > 0){
      return motorType[0].desc1
    }
    else{
        return ''
    }
  }

  renderText(field){
    if (field === null || field === ''){
      return '-'
    }else{
      return this.renderHighlight(field)
    }
  }

  renderTicketLink(field){
    if (field === null || field === ''){
        return ''
    }
    else{
        return this.renderHighlight(field)
    }
  }

  renderHighlight(value){
    const { highlight } = this.props;
    if(typeof value != 'string' || highlight == 'all' || highlight == undefined || highlight == null || highlight == ''){ 
      return value 
    }
    const regex = new RegExp(highlight, 'i');

    if(!regex.test(value)){
      return value
    }

    return <span
      dangerouslySetInnerHTML={{
        __html: value.replace(regex, "<span class='highlight'>$&</span>")
      }}
    />
  }

  checkIfApiLoading(){
      let flag = checkIfApiLoading([
        this.props.statusListLoading,
        this.props.macTypeListLoading,
        this.props.brandListLoading,
        this.props.motorTypeListLoading,
      ]);
      return flag;
  }

  componentDidMount(){
    this.props.getStatus();
    this.props.getMacTypes();
    this.props.getBrands();
    this.props.getMotorTypes();
    this.setState({rowsPerPage:this.props.rowsPerPage, page:1});
  }

  componentDidUpdate(){
    if(this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp){
        this.setState({
            translationTimestamp: this.props.translation.translations.timeStamp,
        })
    }
}

  jsonToArray(jsonData){
    var result = [];
    for (var i in jsonData){
        result.push([i,jsonData[i]]);
    }
    return result;
  }

  renderTableHeader(){
    let headers = this.props.header.map((item, itemIndex) => {
        if (this.checkIfHiddenHeader(item.value)){
          return false;
        }
        return <TableCell style={{wordWrap: 'break-word',width:item.size,maxWidth:item.size,minWidth:item.size}} key={itemIndex}>{ item.value.toString().translate() }</TableCell>;
    });
    return headers;
  }

  handleCheckboxTick = (id) =>{

    if (this.props.tableName === 'machines'){
      this.props.toggleMachineTableCheckboxById(this.props.data,id);
    }
    else if (this.props.tableName === 'tickets'){
      this.props.toggleTicketTableCheckboxById(this.props.data,id);
    }
    else if (this.props.tableName === 'maintenance'){
      this.props.toggleMaintenanceTableCheckboxById(this.props.data,id);
    }
    else if (this.props.tableName === 'checklist'){
      this.props.onTickCheckBox(this.props.data, id)
    }
  }

  handleCheckboxTickAll = () =>{
    if (this.props.tableName === 'machines'){
      this.props.toggleMachineTableCheckboxAll(this.props.data);
    }
    else if (this.props.tableName === 'tickets'){
      this.props.toggleTicketTableCheckboxAll(this.props.data);
    }
    else if (this.props.tableName === 'maintenance'){
      this.props.toggleMaintenanceTableCheckboxAll(this.props.data);
    }
  }

  checkIfCheckboxTickedAll = () =>{
    let check = this.props.data.filter((item) => { return item.selected.value === false });
    if (check.length > 0){
      return false
    }
    else{
      return true;
    }
  }

  checkIfCheckboxTickedAllMaintenance = () => {
    let check = this.props.data.filter(x => x.selected.value === false && x.selected.hasSchedule === "false")
    if (check.length > 0){
      return false
    }
    else{
      return true;
    }
  }


  handleLinkClick = (column,value) => {
    if (this.props.tableName === 'tickets'){
        if (column === 'ticketmachine' || column === 'ticketNo'){
            localStorage.setItem('redirectToTickets','true');
        }
    }
    else if (this.props.tableName === 'maintenance'){
      if (column === 'ticketmachine' || column === 'ticketNo'){
        localStorage.setItem('redirectToMaintenance','true');
      }
    }
  }

  checkIfHidden(field){
      if (this.props.tableName === 'machines'){
          if (this.props.machineSelectedRow.showToggle === true){
            let check = this.props.hiddenColumns.filter((item) => { return item.value.toUpperCase() === field.toUpperCase() });
            if (check.length > 0){
              return true;
            }
            else{
              return false;
            }
          }
          else{
              return false
          }
      }
      else if (this.props.tableName === 'tickets'){
        if (this.props.ticketSelectedRow.showToggle === true){
          let check = this.props.hiddenColumns.filter((item) => { return item.value.toUpperCase() === field.toUpperCase() });
          if (check.length > 0){
            return true;
          }
          else{
            return false;
          }
        }
        else{
            return false
        }
      }
      else if (this.props.tableName === 'checklist'){
        if (this.props.openSideDetail === true){
          let check = this.props.hiddenColumns.filter((item) => { return item.value.toUpperCase() === field.toUpperCase() });
          if (check.length > 0){
            return true;
          }
          else{
            return false;
          }
        }
        else{
            return false
        }
      }
      else if (this.props.tableName === 'maintenance'){
        if (this.props.maintenanceSelectedRow.showToggle === true){
          let check = this.props.hiddenColumns.filter((item) => { return item.value.toUpperCase() === field.toUpperCase() });
          if (check.length > 0){
            return true;
          }
          else{
            return false;
          }
        }
        else{
            return false
        }
      }
      else{
        return false
      }
  }

  checkIfHiddenHeader(field){
      if (this.props.tableName === 'machines'){
          if (this.props.machineSelectedRow.showToggle === true){
            let check = this.props.hiddenColumns.filter((item) => { return item.header.toUpperCase() === field.toUpperCase() });
            if (check.length > 0){
              return true;
            }
            else{
              return false;
            }
          }
          else{
              return false
          }
      }
      else if (this.props.tableName === 'tickets'){
        if (this.props.ticketSelectedRow.showToggle === true){
          let check = this.props.hiddenColumns.filter((item) => { return item.header.toUpperCase() === field.toUpperCase() });
          if (check.length > 0){
            return true;
          }
          else{
            return false;
          }
        }
        else{
            return false
        }
    }
    else if (this.props.tableName === 'checklist'){
      if (this.props.openSideDetail === true){
        let check = this.props.hiddenColumns.filter((item) => { return item.header.toUpperCase() === field.toUpperCase() });
        if (check.length > 0){
          return true;
        }
        else{
          return false;
        }
      }
      else{
          return false
      }
  }
    else if (this.props.tableName === 'maintenance'){
      if (this.props.maintenanceSelectedRow.showToggle === true){
        let check = this.props.hiddenColumns.filter((item) => { return item.header.toUpperCase() === field.toUpperCase() });
        if (check.length > 0){
          return true;
        }
        else{
          return false;
        }
      }
      else{
          return false
      }
  }
    else{
        return false
    }
  }

  preventLinkRedirect = (e) => {
      e.preventDefault();
  }

  renderTableCells(row){
      let tableRow = this.jsonToArray(row);
      let cells = tableRow.map((cell,cellIndex) => {
        if (this.checkIfHidden(cell[0]) || cell[0] === 'machineId'){
            return false;
        }
        if(cell[0].toUpperCase() === 'CHECKLISTS') {
          const checkList = cell[1].find(({ tempType }) => tempType === 'PM');
          return (
            <TableCell>
              {checkList 
                ? <Link className='link' to={`/checklist-detail/${checkList.checkListId}`}>
                    {checkList.checklistNo}
                  </Link> 
                : 'N/A'
              }
            </TableCell>
          )
        }
        if (cell[0].toUpperCase() === 'SELECTED'){
            return (
                <TableCell key={cellIndex}>
                    <Checkbox onClick={() => this.handleCheckboxTick(cell[1].id)} checked={cell[1].value} disabled={cell[1].hasSchedule === "true" ? true : false} color='primary'></Checkbox>
                </TableCell>
            )
        }
        if (cell[0].toUpperCase() === 'MACHINE' || cell[0].toUpperCase() === 'TICKETMACHINE'){
            return (<TableCell key={cellIndex}>
                    <Tooltip className="table-tooltip" placement="top-start" title={cell[1].value}>
                      {cell[1].value == null ? (<span>{ this.renderText(cell[1].value) }</span>) :(
                      <Link onClick={(e) => checkPageAccessField(this.props.userAccess,{pageId:2,pageAccess:'VIEW'}) || checkPageAccess(this.props.userAccess,{pageId:2}) ? this.handleLinkClick(cell[0],cell[1].value) : this.preventLinkRedirect(e)} className={"link " + (checkPageAccessField(this.props.userAccess,{pageId:2,pageAccess:'VIEW'}) || checkPageActionAccess(this.props.userAccess,{pageId:2,pageAction: 'Add'}) || checkPageActionAccess(this.props.userAccess,{pageId:2,pageAction: 'Edit'}) ? null : 'disabled')} to={cell[1].link == null || (checkPageAccess(this.props.userAccess,{pageId:2}) === false && checkPageAccessField(this.props.userAccess,{pageId:2,pageAccess:'VIEW'}) === false) ? null : cell[1].link}>
                      {this.renderTicketLink(cell[1].value)}
                    </Link>)}
                    </Tooltip>
            </TableCell>);
        }
        if (cell[0].toUpperCase() === 'TICKETNO'){
            return (<TableCell key={cellIndex}>
                    <Tooltip className="table-tooltip" placement="top-start" title={cell[1].value}>
                      {cell[1].value == null ? (<span>{ this.renderText(cell[1].value) }</span>) :(
                      <Link onClick={(e) => checkPageAccessField(this.props.userAccess,{pageId:7,pageAccess:'VIEW'}) || checkPageAccess(this.props.userAccess,{pageId:7}) ? this.handleLinkClick(cell[0],cell[1].value) : this.preventLinkRedirect(e)} className={"link " + ( checkPageAccessField(this.props.userAccess,{pageId:7,pageAccess:'VIEW'}) || checkPageAccess(this.props.userAccess,{pageId:7}) ? null : 'disabled' )} to={cell[1].link == null || (checkPageAccess(this.props.userAccess,{pageId:7}) === false && checkPageAccessField(this.props.userAccess,{pageId:7,pageAccess:'VIEW'}) === false) ? null : cell[1].link}>
                      {this.renderTicketLink(cell[1].value)}
                    </Link>)}
                    </Tooltip>
            </TableCell>);
        }
        if (cell[0].toUpperCase() === 'TEMPLATENAME'){
            return (<TableCell key={cellIndex}>
                    <Tooltip className="table-tooltip" placement="top-start" title={cell[1].value}>
                      {cell[1].value == null ? (<span>{ this.renderText(cell[1].value) }</span>) :(
                      <Link onClick={(e) => checkPageAccessField(this.props.userAccess,{pageId:16,pageAccess:'VIEW'}) || checkPageAccess(this.props.userAccess,{pageId:16}) ? this.handleLinkClick(cell[0],cell[1].value) : this.preventLinkRedirect(e)} className={"link " + (checkPageAccessField(this.props.userAccess,{pageId:16,pageAccess:'VIEW'}) || checkPageAccess(this.props.userAccess,{pageId:16}) ? null : 'disabled')} to={cell[1].link == null || (checkPageAccessField(this.props.userAccess,{pageId:16,pageAccess:'VIEW'}) === false && checkPageAccess(this.props.userAccess,{pageId:16}) === false) ? null : cell[1].link}>
                      {this.renderTicketLink(cell[1].value)}
                    </Link>)}
                    </Tooltip>
            </TableCell>);
        }
        else if (cell[1] === null){
            return <TableCell key={cellIndex}>
                <Tooltip placement="top-start" title="-">
                    <span>
                      -
                    </span>
                </Tooltip>
            </TableCell>;
        }
        else if (cell[0] === 'macTypeId'){
            return <TableCell key={cellIndex}>
                <Tooltip placement="top-start" title={ this.renderMachineType(cell[1]) }>
                    <span>
                      { this.renderMachineType(cell[1]) }
                    </span>
                </Tooltip>
            </TableCell>;
        }
        else if (cell[0] === 'brandId'){
            return <TableCell key={cellIndex}>
              <Tooltip placement="top-start" title={ this.renderBrand(cell[1]) }>
                    <span>
                      { this.renderBrand(cell[1]) }
                    </span>
                </Tooltip>
            </TableCell>;
        }
        else if (cell[0] === 'motorTypeId'){
            return <TableCell key={cellIndex}>
                <Tooltip placement="top-start" title={ this.renderMotorType(cell[1]) }>
                    <span>
                      { this.renderMotorType(cell[1]) }
                    </span>
                </Tooltip>
            </TableCell>;
        }
        else if (cell[0] === 'statusId'){
           if (cell[1] === 6){
             return <TableCell className="status not-working" key={cellIndex}>
                <Tooltip placement="top-start" title={ this.renderStatus(cell[1]) }>
                    <span>
                      { this.renderStatus(cell[1]) }
                    </span>
                </Tooltip>
             </TableCell>;
           }
           else if (cell[1] === 4){
              return <TableCell className="status in-repair" key={cellIndex}>
                <Tooltip placement="top-start" title={ this.renderStatus(cell[1]) }>
                    <span>
                      { this.renderStatus(cell[1]) }
                    </span>
                </Tooltip>
              </TableCell>;
           }
           else if (cell[1] === 5){
               return <TableCell className="status maintenance" key={cellIndex}>
                 <Tooltip placement="top-start" title={ this.renderStatus(cell[1]) }>
                     <span>
                       { this.renderStatus(cell[1]) }
                     </span>
                 </Tooltip>
               </TableCell>;
           }
           else{
            return <TableCell className="status active" key={cellIndex}>
              <Tooltip placement="top-start" title={ this.renderStatus(cell[1]) }>
                  <span>
                    { this.renderStatus(cell[1]) }
                  </span>
              </Tooltip>
            </TableCell>;
           }
        }
        else if (cell[0] === 'status'){
           if (cell[1] === 'REPORTED' || cell[1] === 'INACTIVE'){
             return <TableCell className={this.props.tableName === 'checklist' ? "status not-working-gray" : "status not-working"} key={cellIndex}>
               <Tooltip placement="top-start" title={ cell[1].translate() }>
                  <span>
                    { cell[1].translate() }
                  </span>
               </Tooltip>
             </TableCell>;
           }
           if (cell[1] === 'TOTALDURATIONMARKEDASCLOSED'){
            return <TableRow className="status maintenance" key={cellIndex}>
               <Tooltip placement="top-start" title={ cell[1].translate() }>
                   <span>
                     { cell[1].translate() }
                   </span>
               </Tooltip>
            </TableRow>;
          }
           if (cell[1] === 'MAINTENANCE'){
             return <TableCell className="status maintenance" key={cellIndex}>
                <Tooltip placement="top-start" title={ cell[1].translate() }>
                    <span>
                      { cell[1].translate() }
                    </span>
                </Tooltip>
             </TableCell>;
           }
           if (cell[1] ===  'IN-REPAIR' || cell[1] ===  'IN PROGRESS'){
              return <TableCell className="status in-repair" key={cellIndex}>
                  <Tooltip placement="top-start" title={ cell[1].translate() }>
                    <span>
                      { cell[1].translate() }
                    </span>
                  </Tooltip>
              </TableCell>;
           }
           if (cell[1] ===  'REPAIR' || cell[1] === 'DRAFT'){
            return <TableCell className="status repair" key={cellIndex}>
                <Tooltip placement="top-start" title={ cell[1].translate() }>
                  <span>
                    { cell[1].translate() }
                  </span>
                </Tooltip>
            </TableCell>;
          } 
          if (cell[1] ===  'RE-OPENED' || cell[1] === 'DRAFT'){
            return <TableCell className="status re-opened" key={cellIndex}>
                <Tooltip placement="top-start" title={ cell[1].translate() }>
                  <span>
                    { cell[1].translate() }
                  </span>
                </Tooltip>
            </TableCell>;
          }
           else{
            return <TableCell className="status active" key={cellIndex}>
                <Tooltip placement="top-start" title={ cell[1].translate() }>
                  <span>
                    { cell[1].translate() }
                  </span>
                </Tooltip>
            </TableCell>;
           }
        }
        else if (cell[0] === 'ticketType'){
           if (cell[1] ===  'Maintenance'){
              return <TableCell className="status maintenance" key={cellIndex}>
                  <Tooltip placement="top-start" title={ cell[1].toUpperCase().translate() }>
                    <span>
                      { cell[1].toUpperCase().translate() }
                    </span>
                  </Tooltip>
              </TableCell>;
           }
           else{
            return <TableCell className="status in-repair" key={cellIndex}>
                  <Tooltip placement="top-start" title={ cell[1].toUpperCase().translate() }>
                    <span>
                      { cell[1].toUpperCase().translate() }
                    </span>
                  </Tooltip>
            </TableCell>;
           }
        }
        else if (cell[0].toUpperCase() === 'AQUISITIONDT'){
            return <TableCell key={cellIndex}>
                      <Tooltip placement="top-start" title={formatDate(cell[1])}>
                        <span>{formatDate(cell[1])}</span>
                      </Tooltip>
            </TableCell>;
        }
        else if (
            cell[0].toUpperCase() === 'LASTPMDT' 
            || cell[0].toUpperCase() === 'LPM_DATE' 
            || cell[0].toUpperCase() === 'LR_DATE' 
            || cell[0].toUpperCase() === 'LPRORLPM' 
            || cell[0].toUpperCase() === 'PMPLANDT' 
            || cell[0].toUpperCase() === 'CREATEDDT' 
            || cell[0].toUpperCase() === 'LASTUSED' 
            || cell[0].toUpperCase() === 'LASTREPORTEDDT' 
            ||  cell[0].toUpperCase() === 'LASTREPAIREDDT'
          ) {
            let cellDate = formatDate(cell[1]);
            if(this.props.tableName == 'maintenance'){
              cell[1] = cell[1].substring(0, cell[1].indexOf('T'));
            }
            return <TableCell key={cellIndex}>
                      <Tooltip placement="top-start" title={cellDate}>
                        <span>
                            {cellDate}
                        </span>
                      </Tooltip>
            </TableCell>;
        }
        else{
            return <TableCell key={cellIndex}>
              <Tooltip placement="top-start" title={ this.renderText(cell[1]) }>
                  <span>
                    { this.renderText(cell[1]) }
                  </span>
              </Tooltip>
            </TableCell>;
        }
      });

      return cells;
  }

  updatedDisplayedRows(data){
    if (data.length > 0){
      if (this.props.tableName === 'machines'){
        this.props.machineUpdateDisplayedRows(data.length);
      }
      else if (this.props.tableName === 'tickets'){
        this.props.ticketUpdateDisplayedRows(data.length);
      }
      else if (this.props.tableName === 'maintenance'){
        this.props.maintenanceUpdateDisplayedRows(data.length);
      }
    }
    else{
      if (this.props.tableName === 'machines'){
        this.props.machineUpdateDisplayedRows(data.length);
      }
      else if (this.props.tableName === 'tickets'){
        this.props.ticketUpdateDisplayedRows(data.length);
      }
      else if (this.props.tableName === 'maintenance'){
        this.props.maintenanceUpdateDisplayedRows(data.length);
      }
    }
  }

  handlePerChangeChange(event){
    const perPage = parseInt(event.target.value, 0);
    this.setState({
      rowsPerPage: perPage,
      page: 1
    })
    if(this.props.onPerPageChanged){
      this.props.onPerPageChanged(perPage)
    }

  }

  renderTableData(){
    let rows = (this.state.rowsPerPage > 0
        ? this.props.data.slice(this.props.page * this.state.rowsPerPage, this.props.page * this.state.rowsPerPage + this.state.rowsPerPage)
        : this.props.data
    );
    let data = rows.map((item, itemIndex) => {
        return (
          <MuiThemeProvider key={itemIndex} theme={this.state.tooltipTheme}>
            <TableRow className={ this.renderRowClass(item,itemIndex) } onClick={() => this.toggleDetail(item, itemIndex)} key={itemIndex}>
                { this.renderTableCells(item) }
            </TableRow>
          </MuiThemeProvider>
        );
    });
    this.updatedDisplayedRows(data);
    return data;
  }

  renderRowClass(row,index){
      if (this.props.tableName === 'machines'){
         if (row.machine.linkId === this.props.machineSelectedRow.id){
           return row.machineCondition === 'Inactive' 
            ? 'selected-row inactive-row'
            : 'selected-row';
         } else {
          if (row.machineCondition === 'Inactive'){
            return 'inactive-row';
           }
         }
      }
      else if (this.props.tableName === 'tickets'){
         if (row.ticketNo.linkId === this.props.ticketSelectedRow.id){
           return 'selected-row'
         }
      }
      else if (this.props.tableName === 'maintenance'){
        if (row.selected.id === this.props.ticketSelectedRow.id){
          return 'selected-row'
        }else{
          return ''
        }
     }
      else if (this.props.tableName === 'checklist'){
        if (row.selected.id === this.props.selectedId){
          return 'selected-row'
        }
     }
  }


  /* OLD PAGINATIN FUNCTION */
  // TablePaginationActions = (props) => {
  //   // const theme = useTheme();

  //   let count = props.count;
  //   let page = this.props.page;

  //   const handleFirstPageButtonClick = (event) => {
  //     this.props.onChangePage(0);
  //   };

  //   const handleBackButtonClick = (event) => {
  //     this.props.onChangePage(page - 1);
  //   };

  //   const handleNextButtonClick = (event) => {
  //     this.props.onChangePage(page + 1);
  //   };

  //   const handleLastPageButtonClick = (event) => {
  //     this.props.onChangePage(Math.max(0, Math.ceil(count / this.state.rowsPerPage) - 1));
  //   };

  //   return (
  //     <div className="table-pagination">
  //       <Grid container spacing={0}>
  //           <Grid item xs={3}>
  //               <IconButton
  //                 onClick={handleFirstPageButtonClick}
  //                 disabled={page === 0}
  //                 aria-label="first page"
  //               >
  //                 {false ? <LastPageIcon /> : <FirstPageIcon />}
  //               </IconButton>
  //           </Grid>
  //           <Grid item xs={3}>
  //             <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
  //               {false ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
  //             </IconButton>
  //           </Grid>
  //           <Grid item xs={3}>
  //               <IconButton
  //                 onClick={handleNextButtonClick}
  //                 disabled={page >= Math.ceil(count / this.state.rowsPerPage) - 1}
  //                 aria-label="next page"
  //               >
  //                 {false ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
  //               </IconButton>
  //           </Grid>
  //           <Grid item xs={3}>
  //               <IconButton
  //                 onClick={handleLastPageButtonClick}
  //                 disabled={page >= Math.ceil(count / this.state.rowsPerPage) - 1}
  //                 aria-label="last page"
  //               >
  //                 {false ? <FirstPageIcon /> : <LastPageIcon />}
  //               </IconButton>
  //           </Grid>
  //       </Grid>
  //     </div>
  //   );
  // }

  renderDisplayedRows = ({ from, to, count }) => {
    return (
      <Box
        gridGap='10px'
        display='flex' 
        justifyContent='center' 
        alignItems='center'>
        { this.handleIsLoading() && <CircularProgress size={16} /> }
        {`${from}-${to === -1 ? count : to} ` + 'of'.translate() +  ` ${count !== -1 ? count : to}`}
      </Box>
    )
  }

  handleIsLoading = () => {
    return (
      this.props.ticketsLoading ||
      this.props.machinesLoading ||
      this.props.loadingMachineUp ||
      this.props.checklistTemplateLoading
    );
  }

  TablePaginationActions = (props) => {

    if (this.props.meta){
      let count = props.count;
      let page = parseInt(this.props.meta.currentPage);
      let pageLength = parseInt(this.props.meta.pageCount);
      console.log(this.props.meta);

      const handleFirstPageButtonClick = (event) => {
        this.props.onChangePage(1);
      };

      const handleBackButtonClick = (event) => {
        this.props.onChangePage(page - 1);
      };

      const handleNextButtonClick = (event) => {
        this.props.onChangePage(page + 1);
      };

      const handleLastPageButtonClick = (event) => {
        this.props.onChangePage(pageLength);
      };

      return (
        <div className="table-pagination">
          <Grid container spacing={0}>
              <Grid item xs={3}>
                  <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={
                      page === 1 
                      || isNaN(page) 
                      || this.handleIsLoading()
                    }
                    aria-label="first page"
                  >
                    {false ? <LastPageIcon /> : <FirstPageIcon />}
                  </IconButton>
              </Grid>
              <Grid item xs={3}>
                <IconButton onClick={handleBackButtonClick} disabled={
                    page === 1 
                    || isNaN(page) 
                    || this.handleIsLoading()
                  } aria-label="previous page">
                  {false ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
              </Grid>
              <Grid item xs={3}>
                  <IconButton
                    onClick={handleNextButtonClick}
                    disabled={
                      page === pageLength 
                      || isNaN(page) 
                      || this.handleIsLoading()
                      || pageLength <= 1
                    }
                    aria-label="next page"
                  >
                    {false ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                  </IconButton>
              </Grid>
              <Grid item xs={3}>
                  <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={
                      page === pageLength 
                      || isNaN(page) 
                      || this.handleIsLoading() 
                      || pageLength <= 1
                    }
                    aria-label="last page"
                  >
                    {false ? <FirstPageIcon /> : <LastPageIcon />}
                  </IconButton>
              </Grid>
          </Grid>
        </div>
      );
    }
    else{
      return null
    }
  }

  getShowToggleByName(){
    if (this.props.tableName === 'machines'){
        return this.props.machineSelectedRow ? this.props.machineSelectedRow.showToggle : null;
    }
    else if (this.props.tableName === 'tickets'){
        return this.props.ticketSelectedRow ? this.props.ticketSelectedRow.showToggle : null;
    }
    else if (this.props.tableName === 'maintenance'){
      return this.props.maintenanceSelectedRow ? this.props.maintenanceSelectedRow.showToggle : null;
    }
    else if (this.props.tableName === 'checklist'){
      return this.props.openSideDetail ? true : false;
    }
  }

  toggleDetail = (row,index) =>{
    if (this.props.tableName === 'machines'){
        if (this.props.machineSelectedRow.showToggle === true){
          this.props.machineListShowToggle(row.machine.linkId,true);
          this.props.getMachineById(row.machine.linkId);
        }
        else{
          this.props.machineListShowToggle(row.machine.linkId,false);
          this.props.getMachineById(row.machine.linkId);
        }
    }
    else if (this.props.tableName === 'tickets'){
      if (this.props.ticketSelectedRow.showToggle === true){
        this.props.ticketListShowToggle(row.ticketNo.linkId,true);
        this.props.getTicketDetail(row.ticketNo.linkId);
        this.props.getMachineById(row.machineId);
      }
      else{
        this.props.ticketListShowToggle(row.ticketNo.linkId,false);
        this.props.getTicketDetail(row.ticketNo.linkId);
        this.props.getMachineById(row.machineId);
      }
    }
    else if (this.props.tableName === 'maintenance'){
      if (this.props.ticketSelectedRow.showToggle === true){
        this.props.ticketListShowToggle(row.selected.id,true);
      }
      else{
        this.props.ticketListShowToggle(row.selected.id,false);
      }
    }
    else if (this.props.tableName === 'checklist'){
      let id = row.selected.id
      this.props.onHighLight(this.props.data, id)
    }
  }

  content(){
        return (
                <TableBody render={this.state.isRender}>
                    { this.renderTableData() }
                </TableBody>
        )
  }

  renderThumbHorizontal(){
    return (
        <div style={{display:'none !important'}} />
    );
  }

  render(){
    const selectedRow = this.props.data?.filter(item => item.selected.value);
    const { data } = this.props;
    return (
        <div className={"main-table "}>
           <Grid container spacing={0}>
              <Grid className={'table-border-right' + (this.getShowToggleByName() ? ' toggle-padding' : null)} item xs={this.getShowToggleByName() ? 6 : 12}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <TableContainer className="scroll">
                              {/* <Scrollbars autoHide autoHideTimeout={500} style={{ width: '99%', height: '400px' }} className="scrollable"> */}
                                    <Table aria-label="custom sticky pagination table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{width:'10px',minWidth:'10px'}}>
                                                    <Checkbox 
                                                      indeterminate={
                                                        selectedRow.length > 0 
                                                        && selectedRow.length !== data.length
                                                      }
                                                      indeterminateIcon={<IndeterminateCheckBoxOutlined />}
                                                      onClick={() => this.handleCheckboxTickAll()} 
                                                      checked={
                                                        this.props.tableName == 'maintenance' 
                                                        ? this.checkIfCheckboxTickedAllMaintenance() 
                                                        : this.checkIfCheckboxTickedAll()
                                                      } 
                                                      color='primary' 
                                                      style={{display: 
                                                        this.props.tableName === 'checklist' 
                                                        ? 'none' 
                                                        : 'block'
                                                      }} 
                                                    />
                                                </TableCell>
                                                { this.renderTableHeader() }
                                            </TableRow>
                                        </TableHead>
                                        { this.props.data.length > 0 && this.checkIfApiLoading() === false ? this.content() : this.updatedDisplayedRows(this.props.data) }
                                    </Table>
                                    { this.props.data.length <= 0 ? <div className="no-result">{'No results found'.translate()}</div> : null }
                              {/* </Scrollbars> */}
                          </TableContainer>
                    </Grid>
                    <Grid item xs={12}>
                      <TablePagination
                          className="table-paginate-ticket"
                          labelDisplayedRows={this.renderDisplayedRows}
                          rowsPerPageOptions={this.props.rowsPerPageOptions || []}
                          rowsPerPage={this.props.rowsPerPage}
                          component="div"
                          count={this.props.meta.count}
                          page={this.props.meta.currentPage-1}
                          ActionsComponent={this.TablePaginationActions}
                          onRowsPerPageChange={this.handlePerChangeChange.bind(this)}
                      />
                    </Grid>
                </Grid>
              </Grid>
              <Slide timeout={{enter: 500, exit: 0}} direction="left" in={this.getShowToggleByName()} mountOnEnter unmountOnExit>
                <Grid item xs={6}>
                    { this.props.children }
                </Grid>
              </Slide>
           </Grid>
        </div>
    );
  }
}

const mapStateToProps = state => {
  let {translationReducer} = state
  let apiLoading = {
    checklistTemplateLoading: state.checklistReducer.templateLoading,
    machinesLoading: state.machineListReducer.loading,
    loadingMachineUp: state.ticketListReducer.loadingMachineUp,
    ticketsLoading: state.ticketListReducer.loading,
    statusListLoading: state.statusListReducer.loading,
    macTypeListLoading: state.macTypeListReducer.loading,
    macSubTypeListLoading: state.macSubTypeListReducer.loading,
    brandListLoading: state.brandListReducer.loading,
    motorTypeListLoading: state.motorTypeListReducer.loading,
  }
  let data = {
    userAccess: state.authReducer.access,
    factoryId: state.authReducer.factoryId,
    machineSelectedRow: state.machineListReducer.selectedRow,
    ticketSelectedRow: state.ticketListReducer.selectedRow,
    maintenanceSelectedRow: state.ticketListReducer.maintenanceSelectedRow,
    statusList: state.statusListReducer.statusList,
    macTypeList: state.macTypeListReducer.macTypeList,
    macSubTypeList: state.macSubTypeListReducer.macSubTypeList,
    brandList: state.brandListReducer.brandList,
    motorTypeList: state.motorTypeListReducer.motorTypeList,
    error: state.statusListReducer.error
  }

  return {
    ...apiLoading,
    ...data,
    ...translationReducer
  }
}

const mapDispatchToProps = dispatch => {
  return {
    machineListUpdateTotal: (rows) => {
      dispatch(machineListUpdateTotal({rows:rows}));
    },
    machineUpdateDisplayedRows: (rows) => dispatch(machineUpdateDisplayedRows(rows)),
    ticketUpdateDisplayedRows: (rows) => dispatch(ticketUpdateDisplayedRows(rows)),
    machineListShowToggle: (id,flag) => dispatch(machineListShowToggle(id,flag)),
    ticketListShowToggle: (id,flag) => dispatch(ticketListShowToggle(id,flag)),
    toggleMachineTableCheckboxAll: (machines) => dispatch(toggleMachineTableCheckboxAll(machines)),
    toggleMachineTableCheckboxById: (machines,id) => dispatch(toggleMachineTableCheckboxById(machines,id)),
    toggleTicketTableCheckboxAll: (tickets) => dispatch(toggleTicketTableCheckboxAll(tickets)),
    toggleTicketTableCheckboxById: (tickets,id) => dispatch(toggleTicketTableCheckboxById(tickets,id)),
    maintenanceUpdateDisplayedRows: (rows) => dispatch(maintenanceUpdateDisplayedRows(rows)),
    toggleMaintenanceTableCheckboxAll: (maintenance) => dispatch(toggleMaintenanceTableCheckboxAll(maintenance)),
    toggleMaintenanceTableCheckboxById: (maintenance,id) => dispatch(toggleMaintenanceTableCheckboxById(maintenance,id)),
    maintenanceListShowToggle: (id,flag) => dispatch(maintenanceListShowToggle(id,flag)),
    getMachineById: (id) => dispatch(getMachineById(id)),
    getTicketDetail: (id) => dispatch(getTicketDetail(id)),
    getStatus: () => dispatch(getStatus()),
    getMacTypes: () => dispatch(getMacTypes()),
    getBrands: () => dispatch(getBrands()),
    getMotorTypes: () => dispatch(getMotorTypes()),
  }
}

MainTable = connect(mapStateToProps,mapDispatchToProps)(MainTable);

export default MainTable;
