import {
    BRAND_LIST_REQUEST,
    BRAND_LIST_SUCCESS,
    BRAND_LIST_ERROR,
    BRAND_DETAIL_REQUEST,
    BRAND_DETAIL_SUCCESS,
    BRAND_DETAIL_ERROR,
    BRAND_NEW_REQUEST,
    BRAND_NEW_SUCCESS,
    BRAND_NEW_ERROR,
    BRAND_UPDATE_REQUEST,
    BRAND_UPDATE_SUCCESS,
    BRAND_UPDATE_ERROR,
    BRAND_TRIGGER_HIGHLIGHT
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    detailLoading: false,
    triggerHighlight: false,
    brandList:[],
    brandDetail: null,
    meta: [],
    error: ''
}

const brandListReducer = (state = initialState, action) => {
    switch (action.type) {
        case BRAND_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case BRAND_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                brandList: action.payload.brandList,
                brandDetail: null,
                meta: action.payload.meta,
                error: ''
            }
        case BRAND_LIST_ERROR:
            return {
                ...state,
                loading: false,
                brandList: [],
                brandDetail: null,
                meta: [],
                error: action.payload.message
            }
        case BRAND_DETAIL_REQUEST:
            return {
                ...state,
                detailLoading: true,
                brandDetail: null,
            }
        case BRAND_DETAIL_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                brandDetail: action.payload.brandDetail,
            }
        case BRAND_TRIGGER_HIGHLIGHT:
            return {
                ...state,
                triggerHighlight: action.payload
            }
        default: 
            return state;
    }
}

export default brandListReducer;