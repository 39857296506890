import React from 'react';
import { 
  TableFooter as Footer, 
  TablePagination, 
  TableRow,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getMachinePerformance } from '../../../../../../../../redux/actions/MachineSummary/machineSummaryAction';

function TableFooter() {
  const dispatch = useDispatch();
  const {
    isLoading,
    machinesPerformance, 
    latestMachineUsageOrder,
    query,
  } = useSelector(({machineSummaryReducer}) => machineSummaryReducer);

  const handlePageChange = (__, page) => {
    dispatch(
      getMachinePerformance(
        query, 
        latestMachineUsageOrder, 
        page + 1, 
        machinesPerformance.pagination.pageSize
      )
    );
  }

  const handleOnRowsPerPageChange = (event) => {
    dispatch(
      getMachinePerformance(
        query, 
        latestMachineUsageOrder, 
        1, 
        event.target.value,
      )
    );
  }

  console.log("page",  machinesPerformance.pagination.page)

    return ( 
        <Footer>
          <TableRow>
            <TablePagination
              colSpan={13}
              count={machinesPerformance.pagination.total}
              rowsPerPage={machinesPerformance.pagination.pageSize}
              page={machinesPerformance.pagination.page - 1}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleOnRowsPerPageChange}
              labelRowsPerPage={'Rows per page'.translate()}
              nextIconButtonProps={{ disabled: isLoading }}
              backIconButtonProps ={machinesPerformance.pagination.page > 1 ? { disabled: isLoading } : { disabled: true }}
            />
          </TableRow>
        </Footer>
     );
}

export default TableFooter;