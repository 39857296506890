import React from 'react';
import './MainPageBody.scss';

class MainPageBody extends React.Component {

  componentDidMount(){
      console.log('Main Page Body Mounted');
  }

  render(){
    return (
        <div className={this.props.lineOverview ? "main-page-body-lineOverview" : "main-page-body"}>
            { this.props.children }
        </div>
    );
  }
}

export default MainPageBody;
