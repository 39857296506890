import React, { Fragment, useState } from 'react';
import {
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from '@material-ui/core';
import MuiTable from '../components/Table/MuiTable';
import TableHeaderCell from '../components/Table/TableHeaderCell';
import TableCell from '../components/Table/TableCell';
import TablePagination from '../components/Table/Pagination';
import Chip from '../components/Chip';
import { formatDate } from '../../../../utils/date';
import { Link } from 'react-router-dom';
import { IndeterminateCheckBoxOutlined } from '@material-ui/icons';

export default function ListTable({
  rows,
  onRowsChange,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  sorting,
  setSorting,
  totalRows,
  search,
  ...props
}) {
  const isSelectedAll = rows.every((row) => row.isSelected);
  const selectedRow = rows.filter((row) => row.isSelected);
  
  const toggleCheck = (index) => {
    const newRows = [...rows];
    newRows[index].isSelected = !newRows[index].isSelected;
    onRowsChange(newRows);
  };

  const toggleCheckAll = () => {
    const newRows = rows.map((row) => ({ ...row, isSelected: !isSelectedAll }));
    onRowsChange(newRows);
  };

  const sortingProps = (columnName) => ({
    sortable: true,
    isSorting: Boolean(sorting[columnName]),
    direction: sorting[columnName],
    onSort: (direction) => {
      setSorting({
        [columnName]: direction,
      });
    },
  });

  const renderHighlight = (value) => {
    if(typeof value != 'string' || search == 'all' || !search){ 
      return value 
    }
    const regex = new RegExp(search, 'i');

    if(!regex.test(value)){
      return value
    }

    return <span
      dangerouslySetInnerHTML={{
        __html: value.replace(regex, "<span class='highlight'>$&</span>")
      }}
    />
  }

  return (
    <Fragment>
      <TableContainer>
        <MuiTable>
          <TableHead>
            <TableRow>
              <TableHeaderCell padding="checkbox">
                <Checkbox
                  onChange={() => toggleCheckAll()}
                  checked={isSelectedAll}
                  indeterminate={
                    selectedRow.length > 0 
                    && selectedRow.length < rows.length
                  }
                 indeterminateIcon={<IndeterminateCheckBoxOutlined />}
                />
              </TableHeaderCell>
              <TableHeaderCell>#</TableHeaderCell>
              <TableHeaderCell {...sortingProps('coRequestNo')}>
                {'CO REQUEST NO.'.translate()}
              </TableHeaderCell>
              <TableHeaderCell {...sortingProps('style')}>
                {'STYLE'.translate()}
              </TableHeaderCell>
              <TableHeaderCell {...sortingProps('mfgline')}>{'LINE'.translate()}</TableHeaderCell>
              <TableHeaderCell {...sortingProps('coRequestDt')}>
                {'CO DATE'.translate()}
              </TableHeaderCell>
              <TableHeaderCell {...sortingProps('type')}>
                {'CO TYPE'.translate()}
              </TableHeaderCell>
              <TableHeaderCell {...sortingProps('mcQty')}>
                {'MACHINE QTY'.translate()}
              </TableHeaderCell>
              <TableHeaderCell {...sortingProps('criticalMcQty')}>
                {'CRITICAL M/C'.translate()}
              </TableHeaderCell>
              <TableHeaderCell {...sortingProps('status')}>
                {'STATUS'.translate()}
              </TableHeaderCell>
              <TableHeaderCell {...sortingProps('updatedBy')}>
                {'MODIFIED BY'.translate()}
              </TableHeaderCell>
              <TableHeaderCell {...sortingProps('updatedDt')}>
                {'DATE MODIFIED'.translate()}
              </TableHeaderCell>
              <TableHeaderCell {...sortingProps('createdBy')}>
                {'CREATED BY'.translate()}
              </TableHeaderCell>
              <TableHeaderCell {...sortingProps('createdDt')}>
                {'DATE CREATED'.translate()}
              </TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell padding="checkbox">
                  <Checkbox
                    onChange={() => toggleCheck(index)}
                    checked={row.isSelected}
                  />
                </TableCell>
                <TableCell size="small">{((page) * rowsPerPage) + (index + 1)}</TableCell>
                <TableCell size="small">
                  <Link to={`/changeover-detail/${row.id}`}>
                    {renderHighlight(row.code)}
                  </Link>
                </TableCell>
                <TableCell size="small">{renderHighlight(row.style)}</TableCell>
                <TableCell size="small">{renderHighlight(row.line)}</TableCell>
                <TableCell size="small">{row.coDateDisplay}</TableCell>
                <TableCell size="small">{renderHighlight(row.coType)}</TableCell>
                <TableCell size="small" align="center">
                  {row.machineQty}
                </TableCell>
                <TableCell size="small" align="center">
                  {row.criticalQty}
                </TableCell>
                <TableCell size="small">
                  {row.status && (
                    <Chip status={row.status} label={row.status.translate()} />
                  )}
                </TableCell>
                <TableCell size="small">{renderHighlight(row.modifiedBy)}</TableCell>
                <TableCell size="small">{renderHighlight(row.modifiedDate)}</TableCell>
                <TableCell size="small">{renderHighlight(row.createdBy)}</TableCell>
                <TableCell size="small">{renderHighlight(row.createdDate)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
      <TablePagination
        labelRowsPerPage={'Rows per page'.translate()}
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(__, page) => setPage(page)}
        onChangeRowsPerPage={(evt) => setRowsPerPage(evt.target.value)}
      />
    </Fragment>
  );
}
