import { Box, Typography, withStyles } from '@material-ui/core';

export const Container = withStyles(({
    root: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        alignContent: 'center',
        marginTop: '4vh',
        marginBottom: '20px',
    }
}))(Box);

export const TVModeButton = withStyles(({
    root: {
        fontSize: '2em',
        color: '#03b615',
        textTransform: 'uppercase',
        fontWeight: 500,
        cursor: 'pointer',
        textDecoration: 'none',
        textAlign: 'right',
    }   
}))(Typography);

export const RoleName = withStyles(({
    root: {
        fontSize: '2.5em',
        textTransform: 'uppercase',
        textAlign: 'center',
    }
}))(Typography);

export const DisplayDate = withStyles(({
    root: {
        fontSize: '2em',
    }
}))(Typography);

export const ContainerOptions = withStyles(({
    root: {
        display: 'flex',
        gap: 10,
    }
}))(Box);

