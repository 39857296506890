import {
    CONDITION_LIST_REQUEST,
    CONDITION_LIST_SUCCESS,
    CONDITION_LIST_ERROR
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    conditions:[],
    meta: [],
    error: ''
}

const conditionListReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONDITION_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CONDITION_LIST_SUCCESS:
            return {
                loading: false,
                conditions: action.payload.conditions,
                meta: action.payload.meta,
                error: ''
            }
        case CONDITION_LIST_ERROR:
            return {
                loading: false,
                conditions: [],
                meta: [],
                error: action.payload.message
            }
        default: 
            return state;
    }
}

export default conditionListReducer;