import { numberWithCommas } from "../../../../../../../../utils/helper";
import CustomTooltips from "./plugins/CustomTooltips";
import store from "../../../../../../../../redux/store";

export const stringToColour = (str) => {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}
export const datasetKeyProvider = () => btoa(Math.random()).substring(0,12);

export const getDatasets = (data) => ({
  datasets: [{
        data: data?.map(({ countTotal }) => countTotal),
        backgroundColor: data?.map(({ id, name }) => stringToColour(`${name}-${id}`)),
        borderWidth: 5,
        weight: 5,
        labels: data?.map(({ name }) => name),
    }, {
        data: [
            data?.reduce((sum, { countActive }) => (sum + countActive), 0),
            data?.reduce((sum, { countInactive }) => (sum + countInactive), 0)
        ],
        data2: [
            data?.map(({ countActive }) => countActive),
            data?.map(({ countInactive }) => countInactive),
        ],
        backgroundColor: ['#6DDDA5', '#A8A8A8'],
        borderWidth: 2,
        weight: 3,
        labels: ['Active Machines'.translate(), 'Inactive Machines'.translate()],
    },
  ],
});

export const getOptions = (totalMachines) => ({
    middleLabels: [
        numberWithCommas(totalMachines), 
        'TOTAL'.translate(), 
        'MACHINES'.translate(),
    ],
    responsive: true,
    maintainAspectRatio: true,
    cutoutPercentage: 50,
    tooltips: {
        enabled: false,
        custom: CustomTooltips,
        callbacks: {
            footer: (ChartTooltipItem, data) => ({
                index: ChartTooltipItem[0].index,
                datasetIndex: ChartTooltipItem[0].datasetIndex,
                datasets: data.datasets,
                date: store.getState().machineSummaryReducer.dateFetchedData,
            }),
        },
    },

    plugins: {
        legend: false,
        tooltip: false,
        datalabels: {
            color: '#000000',
            align: 'end',
            offset: 0,
            padding: 0,
            font: { size: 12, weight: 'bold' },
            formatter: (value, { datasetIndex }) => (
                !datasetIndex
                    ? parseFloat(value / totalMachines * 100).toFixed(1) + '%'
                    : ''
            ),
        },
    },
});