import React from 'react';
import { TableContainer } from "@material-ui/core";
import { MuiTable } from "../styles";
import TableBody from "./TableBody";
import TableFooter from "./TableFooter";
import TableHeader from "./TableHeader";

function TableData({
    data = [],
    pageSize = 10,
    page = 1,
    total = 0,
}) {
    return ( 
        <TableContainer>
            <MuiTable>
                <TableHeader />
                <TableBody data={data} />
                <TableFooter
                    total={total}
                    pageSize={pageSize}
                    page={page}
                />
            </MuiTable>
        </TableContainer>
    );
}

export default TableData;