import React, { Fragment, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Tooltip,
  IconButton,
  TableCell,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CardPaper from '../../components/CardPaper';
import Checkbox from '../components/Checkbox';
import TableHeaderCell from '../components/TableHeaderCell';
import TableCardCell from '../components/TableCardCell';
import TableCardRow from '../components/TableCardRow';
import MergeIcon from './ActionTable/MergeIcon';
import SplitIcon from './ActionTable/SplitIcon';

const CustomIconButton = withStyles({
  root: {
    padding: 0,
  }
})(IconButton);

const FakeHeaderCell = withStyles({
  root: {
    padding: 0,
  }
})(TableCell);


const FakeCell = withStyles({
  root: {
    padding: 0,
    height: 67,
  }
})(TableCell);


function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export default function ActionTable({ machines, onChange }){
  const [selectedStations, setSelectedStations] = useState([]);
  const stations = machines.map((machine) => machine.station);
  const uniqueStations = stations.filter(onlyUnique);
  const isSelectedAll = selectedStations.length > 0 && selectedStations.length === uniqueStations.length;

  const toggleSelect = (station)=>{
    const isSelected = selectedStations.includes(station);
    const tempStations = isSelected ? selectedStations.filter(s => s !== station) : [...selectedStations, station];
    setSelectedStations(tempStations);
  }

  const handleMerge = ()=>{
    const lowestStation = Math.min.apply(Math, selectedStations);
    const selectedMachines = machines.filter(m => selectedStations.includes(m.station));
    const unselectedMachines = machines.filter(m => !selectedStations.includes(m.station));
    const splittedMachines = selectedMachines.map((m, index) => ({...m, station: lowestStation}));
    const unorderMachines = unselectedMachines.filter(m => m.station < lowestStation);
    const needReorderedStationMachines = unselectedMachines.filter(m => m.station > lowestStation)
    const reorderedStationMachines = needReorderedStationMachines.map((m, index) => ({...m, station: lowestStation + index + 1}));

    setSelectedStations([lowestStation]);
    onChange([
      ...unorderMachines,
      ...splittedMachines,
      ...reorderedStationMachines,
    ]);
  }

  const handleSplit = ()=>{
    const lowestStation = Math.min.apply(Math, selectedStations);
    const selectedMachines = machines.filter(m => selectedStations.includes(m.station));
    const unselectedMachines = machines.filter(m => !selectedStations.includes(m.station));
    const splittedMachines = selectedMachines.map((m, index) => ({...m, station: lowestStation + index}));
    const unorderMachines = unselectedMachines.filter(m => m.station < lowestStation);
    const needReorderedStationMachines = unselectedMachines.filter(m => m.station > lowestStation)
    const highestStation = Math.max.apply(Math, splittedMachines.map(m => m.station));
    const reorderedStationMachines = needReorderedStationMachines.map((m, index) => ({...m, station: highestStation + index + 1}));

    setSelectedStations(splittedMachines.map((m) => m.station));
    onChange([
      ...unorderMachines,
      ...splittedMachines,
      ...reorderedStationMachines,
    ]);
  }

  const toggleSelectAll = ()=>{
    if(isSelectedAll){
      setSelectedStations([]);
    }else{
      setSelectedStations(uniqueStations);
    }
  }

  return <Fragment>
    <CardPaper
      display="flex"
      justifyContent="space-between"
      alignItems='center'
    >
      STN
      <Tooltip title={'Merge'.translate()} arrow>
        <CustomIconButton onClick={handleMerge}>
          <MergeIcon/>
        </CustomIconButton>
      </Tooltip>
      <Tooltip title={'Split'.translate()} arrow>
        <CustomIconButton onClick={handleSplit} >
          <SplitIcon/>
        </CustomIconButton>
      </Tooltip>
    </CardPaper>
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableHeaderCell>
              <Checkbox
                checked={isSelectedAll}
                onChange={()=> toggleSelectAll()}
              />
            </TableHeaderCell>
            <TableHeaderCell>
              #
            </TableHeaderCell>
            <FakeHeaderCell width="0"/>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            uniqueStations.map((station) => {
              const machinesInStation = machines.filter((machine) => machine.station == station);
              const isSelected = selectedStations.includes(station);
              return machinesInStation.map((machine, index) => {
                return <TableCardRow key={machine.uuid}>
                  {
                    index == 0 && (
                      <Fragment>
                        <TableCardCell rowSpan={machinesInStation.length}>
                          <Checkbox
                            checked={isSelected}
                            onChange={()=> toggleSelect(station)}
                          />
                        </TableCardCell>
                        <TableCardCell rowSpan={machinesInStation.length}>
                          {station}
                        </TableCardCell>
                      </Fragment>
                    )
                  }
                  <FakeCell width="0"/>

                </TableCardRow>
              })

            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  </Fragment>
}