import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getChangeoverTypes,
  deleteChangeoverType,
  setUpdatedToFalse } from '../../../../../redux/actions/ChangeoverTypes/changeoverTypeAction';
import { confirmAlert } from 'react-confirm-alert';
import Add from './New'
import Edit from './Edit'
import Table from '../../../../../components/CollapsableTable/CollapseTable';
import dictionary from '../../../../../redux/actions/Translation/dictionary';
import { checkPageAccessField } from '../../../../../utils/helper';

class ChangeoverTypes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isCreating: false,
      updated: false,
      isEditing: false,
      editingChangeoverType: [],
      translations: {
          ...this.convert(),
      },
      translationTimestamp: undefined,
      tableState: {
        add: () => {
          this.setState({ isCreating: true });
        },
        edit: (props) => {
          this.setState({editingChangeoverType : props})
          this.setState({ isEditing: true });
          this.props.getChangeoverTypes(false);
        },
        remove: ({ id }) => {
          confirmAlert({
            title: this.state?.translations?.are_you_sure_delete_changeover_type,
            message: '',
            buttons: [
              {
                label: this.state?.translations?.YES,
                  onClick: () => {
                      this.props.deleteChangeoverType(id);
                  }
                },
                {
                  label: this.state?.translations?.NO,
                }
              ]
            });
            this.props.getChangeoverTypes(false);
        },
        highlight: true,
        search: true,
        keys: this.initTableHeader(),
        data: [],
        loading: false,
      }
    }
  }

  initTableHeader = () => {
    let {
      STATUS,
      CHANGEOVER_TYPE_CODE,
      MODIFIED_BY, 
      DISPLAY_NAME,
      DESCRIPTION,
      DATE_MODIFIED,
      CREATED_BY,
      DATE_CREATED } = this.convert();
    return  [
      {
          name: CHANGEOVER_TYPE_CODE,
          key: 'changeoverType',
          tableHeaderWidth: '10%',
      },
      {
          name: DISPLAY_NAME,
          key: 'desc1',
          tableHeaderWidth: '10%',
      },
      {
          name: DESCRIPTION,
          key: 'desc2',
          tableHeaderWidth: '15%',
      },
      {
          name: STATUS,
          type: 'boolean',
          key: 'isActive',
          tableHeaderWidth: '10%',
      },
      {
          name: MODIFIED_BY,
          key: 'updatedBy',
          tableHeaderWidth: '10%',
      },
      {
          name: DATE_MODIFIED,
          type: 'date',
          key: 'updatedDt',
          alt: 'createdDt',
          tableHeaderWidth: '10%',
      },
      {
          name: CREATED_BY,
          key: 'createdBy',
          tableHeaderWidth: '10%',
      },
      {
          name: DATE_CREATED,
          type: 'date',
          key: 'createdDt',
          tableHeaderWidth: '10%',
      },
    ]
  }

  convert = () => {
    let {
      YES,
      NO,
      NOTIFICATIONS: {are_you_sure_delete_changeover_type},
      STATUS,
      CHANGEOVER_TYPE_CODE,
      MODIFIED_BY,
      DISPLAY_NAME,
      DESCRIPTION,
      CREATED_BY,
      DATE_CREATED,
      DATE_MODIFIED } = dictionary.GET();
    return dictionary.TRANSLATE({
      YES,
      NO,
      are_you_sure_delete_changeover_type,
      STATUS,
      CHANGEOVER_TYPE_CODE,
      MODIFIED_BY,
      DISPLAY_NAME,
      DESCRIPTION,
      DATE_CREATED,
      CREATED_BY,
      DATE_MODIFIED});
  }

  componentDidMount() {
    this.props.getChangeoverTypes();
  }

  componentDidUpdate() {
    const { loading, changeoverTypeList, translation } = this.props;
    const { translations } = translation;
    const { timeStamp } = translations;
    const { tableState, translationTimestamp } = this.state;
    const { data } = tableState;
    const localStateLoading = tableState.loading;
    if ( loading != localStateLoading || changeoverTypeList?.length != data?.length) {
      if( Array.isArray(changeoverTypeList)){
        let localTableState = tableState;
        if ( checkPageAccessField(this.props.userAccess,{pageId:18,pageAccess: 'VIEW'})){
          localTableState = {
            add: null,
            edit: null,
            remove: null
          }
        }
        this.setState({
          tableState: {
            ...localTableState,
            data: [...changeoverTypeList],
            loading,
            timeStamp: new Date()
          },
        })
      }
    }
    if (translation && timeStamp != translationTimestamp) {
      this.setState({
        translationTimestamp: timeStamp,
        translations: Object.assign({}, { ...this.convert() }),
        tableState: {
            ...tableState,
            keys: this.initTableHeader(),
            timeStamp: new Date()
        }
      })
    }
  }

  render() {
    return (
      <div className="brand-body-overview">
        { this.state.isCreating &&
          <Add
            open={this.state.isCreating}
            onClose={() => this.setState({isCreating: false})} />
        }
        { this.state.isEditing &&
          <Edit
            open={this.state.isEditing}
            changeoverType={ this.state.editingChangeoverType }
            onClose={() => this.setState({isEditing: false, isEdited: true})} />
        }
        <Table list={this.props.changeoverTypeList} {...this.state.tableState} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  let { changeoverTypeReducer, translationReducer } = state;
  let apiLoading = {
    loading: true,
  }
  let data = {
      userAccess: state.authReducer.access,
  }
  return {
      ...apiLoading,
      ...data,
      ...changeoverTypeReducer,
      ...translationReducer
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUpdatedToFalse: () => dispatch(setUpdatedToFalse()),
    getChangeoverTypes: (ev) => dispatch(getChangeoverTypes(ev)),
    deleteChangeoverType: (id, payload) => dispatch(deleteChangeoverType(id, payload)),
  }
}

ChangeoverTypes = connect(mapStateToProps, mapDispatchToProps)(ChangeoverTypes);

export default ChangeoverTypes;
