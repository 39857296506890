export default {
  id: "middle-label",
  beforeDraw: function(chart) {
    var width = chart.chart.width,
        height = chart.chart.height,
        ctx = chart.chart.ctx;

    ctx.restore();
    var fontSize = (height / 114).toFixed(2);
    ctx.font = fontSize / 2 + "em sans-serif";
    ctx.textBaseline = "middle";

    const texts = chart.options.label.split("\n");
    texts.forEach((text, index)=>{
      const metrics = ctx.measureText(text);
      const fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
      const actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
      const textY = Math.round(height / 2 + index * (actualHeight + 10));
      const textX = Math.round((width - metrics.width) / 2);
      ctx.fillText(text, textX, textY);
    })


    ctx.save();
  }
}
