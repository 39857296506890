import {
   NOTIFICATION_SUCCESS,
   NOTIFICATION_ERROR,
   ATTACHMENT_LIST,
   ATTACHMENT_LIST_REQUEST,
   ATTACHMENT_LIST_SUCCESS,
   ATTACHMENT_LIST_ERROR,
   ATTACHMENT_DETAIL_REQUEST,
   ATTACHMENT_DETAIL_SUCCESS,
   ATTACHMENT_DETAIL_ERROR,
   ATTACHMENT_NEW_REQUEST,
   ATTACHMENT_NEW_SUCCESS,
   ATTACHMENT_NEW_ERROR,
   ATTACHMENT_UPDATE_REQUEST,
   ATTACHMENT_UPDATE_SUCCESS,
   ATTACHMENT_UPDATE_ERROR,
   ATTACHMENT_DELETE_REQUEST,
   ATTACHMENT_DELETE_SUCCESS,
   ATTACHMENT_DELETE_ERROR,
   ATTACHMENT_TRIGGER_HIGHLIGHT,
 } from '../actionTypes';
 import { tokenGet, tokenPost,tokenPut,tokenPostUpload,tokenDelete, apiSettings } from '../../../utils/api/CoreApi';
 import history from '../../../history';

 export const notificationSuccess = (message) => {
    return {
        type: NOTIFICATION_SUCCESS,
        payload: {
            message: message.translate()
        }
    }
}

export const notificationError = (message) => {
    return {
        type: NOTIFICATION_ERROR,
        payload: {
            message: message.translate()
        }
    }
}
 
 export const attachmentRequest = () => {
     return {
         type:ATTACHMENT_LIST_REQUEST
     }
 }
 
 export const attachmentSuccess = attachmentList => {
     return {
         type:ATTACHMENT_LIST_SUCCESS,
         payload: attachmentList
     }
 }

 export const attachmentDetailRequest = () => {
    return {
        type:ATTACHMENT_DETAIL_REQUEST
    }
}

export const attachmentDetailSuccess = attachmentDetail => {
    return {
        type:ATTACHMENT_DETAIL_SUCCESS,
        payload: attachmentDetail
    }
}

 export const attachmentNewRequest = () => {
    return {
        type:ATTACHMENT_NEW_REQUEST
    }
}

export const attachmentNewSuccess = attachmentList => {
    return {
        type:ATTACHMENT_NEW_SUCCESS,
        payload: attachmentList
    }
}

export const attachmentUpdateRequest = () => {
    return {
        type:ATTACHMENT_UPDATE_REQUEST
    }
}

export const attachmentUpdateSuccess = attachmentList => {
    return {
        type:ATTACHMENT_UPDATE_SUCCESS,
        payload: attachmentList
    }
}

export const attachmentDeleteRequest = () => {
    return {
        type:ATTACHMENT_DELETE_REQUEST
    }
}

export const attachmentDeleteSuccess = attachmentList => {
    return {
        type:ATTACHMENT_DELETE_SUCCESS,
        payload: attachmentList
    }
}

export const attachmentTriggerHighlight = flag => {
    return {
        type:ATTACHMENT_TRIGGER_HIGHLIGHT,
        payload: flag
    }
}
 
 export const getAttachments = (filtered = true,isActive = 1, currentPage = 1, pageSize = 100000) =>{
    localStorage['filtered'] = filtered 
    return function(dispatch){
         dispatch(attachmentRequest());
         tokenGet(apiSettings.apiURLReferences + '/attachments?isActive='+ isActive +'&currentPage=' + currentPage +'&pageSize=' + pageSize).then((response) => {
             let data
             if(localStorage['filtered'] == true){
                data = response.data.data.result.map((item) => {
                    let itemRow = {
                        'id': item.id,
                        'desc1': item.desc1
                    }
                    return itemRow;
                 })
             }
             else data = response?.data?.data?.result || []
             delete localStorage['filtered']
             dispatch(attachmentSuccess({
                 attachmentList: data,
                 meta: response.data.data.meta
             }));
         })
         .catch((error) => {
             console.log(error);
         });
     }
 }

 export const getAttachmentDetail = (id) =>{
    return function(dispatch){
         dispatch(attachmentDetailRequest());
         tokenGet(apiSettings.apiURLReferences + '/attachments/' + id).then((response) => {
             dispatch(attachmentDetailSuccess({
                 attachmentDetail: response.data.machine[0]
             }));
         })
         .catch((error) => {
             console.log(error);
         });
     }
 }

 export const postAttachmentNew = (payload) =>{
    return function(dispatch){
        dispatch(attachmentNewRequest());
        tokenPost(apiSettings.apiURLReferences + '/attachments',payload).then((response) => {
            dispatch(getAttachments(false, 'all'));
            dispatch(attachmentNewSuccess());
            dispatch(notificationSuccess('Successfully added attachment.'));
            history.push('/configuration/machine-config/attachment');
        })
        .catch((error) => {
            if (error.message.indexOf(500) >= 0){
                dispatch(notificationError('Attachment code exists'));
                dispatch(attachmentTriggerHighlight(true));
            }
            console.log(error);
        });
    }
}

export const putAttachmentUpdate = (id,payload) =>{
    return function(dispatch){
        dispatch(attachmentUpdateRequest());
        tokenPut(apiSettings.apiURLReferences + '/attachments/' + id,payload).then((response) => {
            dispatch(getAttachments(false, 'all'));
            dispatch(notificationSuccess('Successfully updated attachment.'));
            history.push('/configuration/machine-config/attachment');
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const postUploadAttachment = (payload,files, method, id = null) =>{
    return function(dispatch){
        tokenPostUpload(apiSettings.apiURLFiles + '/api/files', files).then((response) => {
             let mutatedPayload = {
                 ...payload,
                 imgLink: response.data.files[0].path
             }
             if (method === 'new'){
                dispatch(postAttachmentNew(mutatedPayload));
             }
             else if (method === 'edit'){
                let editPayload = {
                    ...mutatedPayload,
                    id: null
                }
                dispatch(putAttachmentUpdate(id,editPayload));
             }
        });
    }
}

export const deleteAttachment = (id) =>{
    return function(dispatch){
        dispatch(attachmentUpdateRequest());
        tokenDelete(apiSettings.apiURLReferences + '/attachments/' + id).then((response) => {
            dispatch(getAttachments(false, 'all'));
            dispatch(notificationSuccess('Successfully deleted attachment.'));
        })
        .catch((error) => {
            if (error.message.indexOf(500) >= 0){
                dispatch(notificationError('Cannot delete because it is currently referenced to another record'));
            }
            console.log(error);
        });
    }
}