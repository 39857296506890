import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { fetchProblemTypes, fetchTotalMachinesRepair } from '../../api';
import CardPaper from '../CardPaper';
import DoughnutMachineBreakDown from './chart';
import Header from './header';
import TableMachineBreakDown from './table';
import { closeModal, showModal } from '../../../../../redux/actions/Modal/modalActions';
import { ANALYTIC_REPORT_TYPES, downloadAnalyticReport } from '../../excel';
import { FlexBox, IndicatorContainer, LegendIndicator } from './styles';
import { getDateRangeQueryString } from '../../helpers';

function MachineBreakDown({ 
    data, 
    toggleRows,
    handleRequestSort,
    handleViewBy,
    order,
    orderBy,
    viewBy,
    viewType,
    showModal,
    closeModal,
    params,
}) {
  const handleExportMachineBreakdown = async () => {
    showModal('excelLoading');
    Promise.all([
      fetchProblemTypes({ filters: getDateRangeQueryString(params), viewBy: 1 }),
      fetchProblemTypes({ filters: getDateRangeQueryString(params), viewBy: 2 }),
      fetchProblemTypes({ filters: getDateRangeQueryString(params), viewBy: 3 }),
      fetchProblemTypes({ filters: getDateRangeQueryString(params), viewBy: 4 }),
    ])
    .then((problemTypeResult) => {
      Promise.all([
        fetchTotalMachinesRepair({ filters: getDateRangeQueryString(params), viewBy, viewType: 1 }),
        fetchTotalMachinesRepair({ filters: getDateRangeQueryString(params), viewBy, viewType: 2 }),
        fetchTotalMachinesRepair({ filters: getDateRangeQueryString(params), viewBy, viewType: 3 }),
        fetchTotalMachinesRepair({ filters: getDateRangeQueryString(params), viewBy, viewType: 4 }),
      ])
      .then((machineDownTimeResult) => {
        const result = problemTypeResult.map(({ problemTypes }, index) => {
            const problemTypesData = problemTypes.data;
            const machineDownTime = machineDownTimeResult[index].totalMachines.data;

            return problemTypesData.map((item) => {
              const problemTypeTotalMachine = machineDownTime.filter(({ id }) => item.id === id);

              return {
                ...item,
                totalMachine: problemTypeTotalMachine,
              }
            })
        });

        downloadAnalyticReport(ANALYTIC_REPORT_TYPES.MachineBreakdown, result.map((item) => item));
      })
    })
    .catch(err => {
      console.log('Error', err);
    })
    .finally(() => {
      closeModal();
    })
  }

    return ( 
        <CardPaper>
            <Header viewBy={viewBy} handleExport={handleExportMachineBreakdown} handleViewBy={handleViewBy} />
            <Grid container spacing={1}>
              <Grid item xs={4}>
                { data.length > 0 && <DoughnutMachineBreakDown data={data} /> }
                <Typography style={{ padding: '20px 0px ' }} variant="h6" align='center'>{'Percentage by Tickets'.translate()}</Typography>
                <FlexBox justifyContent='center'>
                    <IndicatorContainer>
                        <LegendIndicator color='#6DDDA5' />
                        <Typography>{'On Schedule'.translate()}</Typography>
                    </IndicatorContainer>
                    <IndicatorContainer>
                        <LegendIndicator color='#FF7E7E' />
                        <Typography>{'Overdue'.translate()}</Typography>
                    </IndicatorContainer>
                </FlexBox>
              </Grid>
              <Grid item xs={8}>
                {data.length > 0 && 
                  <TableMachineBreakDown
                      viewBy={viewBy}
                      rows={data}
                      toggleRows={toggleRows}
                      handleRequestSort={handleRequestSort}
                      order={order}
                      orderBy={orderBy}
                  />}
              </Grid>
            </Grid>
          </CardPaper>
     );
};

const mapStateToProps = state => ({ ...state.translationReducer });

const mapDispatchToProps = dispatch => {
  return {
      showModal: (modal) => dispatch(showModal(modal)),
      closeModal: () => dispatch(closeModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MachineBreakDown);