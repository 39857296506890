import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { FormControl, OutlinedInput, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import BootstrapInput from '@src/components/BootstrapInput';

const useStyles = makeStyles({
  formControl: {
    marginBottom: 10
  },
  adornedStart: {
    borderRadius: '5px !important'
  },
  inputAdornedStart: {
    padding: 5
  },
  input: {
    paddingTop: 3,
    paddingBottom: 3
  }
})

export default function CustomizedInputBase(props) {
  const classes = useStyles();
  return (
    <FormControl fullWidth variant="outlined" className={classes.formControl}>
      <OutlinedInput
        value={props.value}
        onChange={(e) => props.onChange(e)}
        startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
        labelWidth={0}
        placeholder={props.placeholder}
        className={classes.input}
        classes={{
          inputAdornedStart: classes.inputAdornedStart,
          adornedStart: classes.adornedStart
        }}
      />
    </FormControl>
  );
}
