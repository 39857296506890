import React from 'react';
import CardPaper from '../components/CardPaper';
import Typography from '../components/Typography';
import AttributesInfo from './ChangeoverInfo/AttributesInfo';
import Actions from './ChangeoverInfo/Actions';
import ModificationInfo from './ChangeoverInfo/ModificationInfo';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const Container = withStyles({
  root: {
    display: 'flex',
    flex: 1,
    height: '100%',
  }
})(CardPaper);

export default function ChangeoverInfo({ changeover, handleClose }) {
  return <Container>
    <Box>
      <Typography variant="h5">
        { changeover.code }
      </Typography>
      <Actions
        changeover={changeover}
        onClose={() => handleClose()}
      />
      <AttributesInfo
        changeover={changeover}
      />
      <ModificationInfo
        changeover={changeover}
      />
    </Box>
  </Container>
}