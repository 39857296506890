import React from 'react';
import moment from 'moment';
import DescriptionIcon from '@material-ui/icons/Description';
import CloseIcon from '@material-ui/icons/Close';
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import history from '../../../../history';
import { checkIfApiLoading, checkPageActionAccess, checkPageAccess } from '../../../../utils/helper';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getChecklistTemplateStatus } from '../../../../redux/actions/Status/StatusActions';
import { notificationError } from '../../../../redux/actions/Notification/notificationActions';
import { postChecklistTemplate, putChecklistTemplate, postUploadTemplate, getChecklistDetail } from '../../../../redux/actions/Checklist/checklistActions';
import { getMacTypes } from '../../../../redux/actions/MacTypes/MacTypeActions';
import { getMacSubTypes, getAllMacSubTypes, getAllMacSubTypesTreeView } from '../../../../redux/actions/MacSubTypes/MacSubTypeActions';
import { getFrequencies } from '../../../../redux/actions/Frequency/frequencyActions';
import { getBrands } from '../../../../redux/actions/Brand/brandActions';
import MainPage from '../../../../components/MainPage/MainPage';
import MainPageHeader from '../../../../components/MainPageHeader/MainPageHeader';
import MainPageBody from '../../../../components/MainPageBody/MainPageBody';
import TemplateControl from '../../../../components/TemplateControl/TemplateControl';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import translation from '../../../../redux/actions/Translation/translationActions'
import { validateFields } from './FormValidation';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import './ChecklistForm.scss';
import RefreshIcon from '@material-ui/icons/Refresh';
import { apiSettings, tokenGet } from '../../../../utils/api/CoreApi';

class ChecklistForm extends React.Component {
    initialized = false;

    constructor(props) {
        super(props)
        this.state = {
            templateTypes: [],
            tooltipTheme: createMuiTheme({
                overrides: {
                    MuiTooltip: {
                        tooltip: {
                            fontSize: "1rem",
                        }
                    }
                }
            }),
            currentFile: null,
            upload: {
                file: null
            },
            detailLoaded: false,
            form: {
                checklistNo: null,
                brandId: null,
                macsubtypeId: null,
                frequencyId: null,
                mactypeId: null,
                motortypeId: null,
                desc1: null,
                temptype: null,
                statusId: null,
                status_desc: null,
                instructionLink: null,
                tasks: [
                    {
                        seq:"1",
                        task:"",
                        subtasks:[]
                    }
                ]
            },
            formErrors: [],
            id: null,
            translationTimestamp: undefined
        }
    }

    async getTemplateTypes(){
        try {
            const { data } = await tokenGet(`${apiSettings.apiURLReferences}/checklists/template-type`);
            this.setState({ templateTypes: data.details });
        } catch (error) {
           console.log(error); 
        }
    }

    deleteCurrentFile = () =>{
        let tmpForm = this.state.form;
        this.setState({
            currentFile: null,
            upload: {
                file: null
            },
            form: {
                ...tmpForm,
                instructionLink: null,
            }
        });
    }

    removeErrorOnChange(field){
      let errors = this.state.formErrors.filter((item) => {return item.name !== field});
      this.setState({ formErrors: errors });
    }

    renderMacTypesItems(){
        let macSubTypeListTree = this.props.macSubTypeListTree.map((item) => {
            return <MenuItem key={item.details.id} value={item.details.id}>{ item.details.macType }</MenuItem>
        });
        return macSubTypeListTree;
    }
      
    renderMacSubTypesItems(){
        let macSubTypeList = this.props.macSubTypeList.map((item) => {
            return <MenuItem key={item.id} value={item.id}>{ item.desc1 }</MenuItem>
        });
        return macSubTypeList;
    }
      
    renderBrandItems(){
        let brandList = this.props.brandList.filter((item) => { return item.isActive === true}).map((item) => {
            return <MenuItem key={item.id} value={item.id}>{ item.desc1 }</MenuItem>
        });
        return brandList;
    }

    renderFrequencyItems(){
        let frequencyList = this.props.frequencyList.map((item) => {
            return <MenuItem key={item.id} value={item.id}>{ item.frequency }</MenuItem>
        });
        return frequencyList;
    }

    renderSubTasks(subTaskList, taskSequence){
        let subtasks = subTaskList.map((item) => {
            return (
                <div className="subtask">
                    <Grid container spacing={0}>
                        <Grid item xs={5}>
                            <Grid container spacing={0}>
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={11}>
                                    <Grid className="subtask-title">
                                        <FormControl fullWidth>
                                            <TextField 
                                                onChange={this.handleSubTaskChange} 
                                                name={ taskSequence + "-" + item.seq } 
                                                value={item.subtask} 
                                                variant="outlined" 
                                                error={this.hasErrors(taskSequence + "-" + item.seq)}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <a href="javascript:void(0)" onClick={() => this.deleteSubTask(taskSequence,item.seq)} className="subtask-action">
                                { "Delete".translate() }
                            </a>
                        </Grid>
                    </Grid>
                </div>
            )
        });
        return subtasks;
    }

    renderTasks(){
        let tasks = this.state.form.tasks.map((item) => {
            return (
                <div className="item-group">
                    <div className="task">
                        <Grid container spacing={0}>
                            <Grid item xs={5}>
                                <Grid container spacing={0}>
                                    <Grid item xs={1}>
                                        <div className="task-sequence">{ this.addedZero(item.seq) }</div>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Grid className="task-title">
                                            <FormControl fullWidth>
                                                <TextField 
                                                    onChange={ this.handleTaskChange } 
                                                    name={"task" + item.seq} 
                                                    value={item.task} 
                                                    variant="outlined"
                                                    error={ this.hasErrors(`task${item.seq}`) }
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={6}>
                                <Grid container spacing={0}>
                                    <Grid item xs={5}>
                                        <a href="javascript:void(0)" onClick={() => this.addSubTask(item.seq)} className="task-action">
                                            { "ADD SUBTASK".translate() }
                                        </a>
                                    </Grid>
                                    <Grid item xs>
                                        <a href="javascript:void(0)" onClick={() => this.makeSubtask(item.seq)} className="task-action">
                                            { "MAKE SUBTASK".translate() }
                                        </a>
                                    </Grid>
                                    <Grid item xs>
                                        <a href="javascript:void(0)" onClick={() => this.deleteTask(item.seq)} className="task-action">
                                            { "DELETE".translate() }
                                        </a>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Link className="task-action">
                                            <ArrowDropDownIcon className="dropdown-icon" />
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="subtask-group">
                        { this.renderSubTasks(item.subtasks, item.seq) }
                    </div>
                </div>
            )
        });
        return tasks;
    }

    cleanTasks(tasks, method){
        let tasksData = null
        if (method === 'save'){
            tasksData = tasks.filter((item) => { return item.task !== "" && item.task !== null }).map((item,itemIndex) => {
                let subtasks = item.subtasks.filter((itemSubtask) => { return itemSubtask.subtask !== "" && itemSubtask.subtask !== null }).map((subtask,subtaskIndex) => {
                    let subtaskData = {
                        ...subtask,
                        seq: subtaskIndex + 1
                    }
                    return subtaskData;
                });
                let itemData = {
                    ...item,
                    seq: itemIndex + 1,
                    subtasks: subtasks
                }
                return itemData;
            });
        }
        else{
            tasksData = tasks.map((item,itemIndex) => {
                let subtasks = item.subtasks.map((subtask,subtaskIndex) => {
                    let subtaskData = {
                        ...subtask,
                        seq: subtaskIndex + 1
                    }
                    return subtaskData;
                });
                let itemData = {
                    ...item,
                    seq: itemIndex + 1,
                    subtasks: subtasks
                }
                return itemData;
            });
        }
        return tasksData;
    }

    submitForm = (status) => {
        let statusId = null;
        if (this.props.checklistTemplatesStatusList){
            if (status === 'ACTIVE'){
                statusId = this.props.checklistTemplatesStatusList.filter((item) => { return item.status === 'ACTIVE' && item.statusId === '2' })
            }
            else if (status === 'DRAFT'){
                statusId = this.props.checklistTemplatesStatusList.filter((item) => { return item.status === 'DRAFT' })
            }
        }
        let payload = {
            ...this.state.form,
            tasks: this.cleanTasks(this.state.form.tasks,'save'),
            statusId: statusId[0].id
        };
        let formValidation = validateFields(payload);
        this.setState({formErrors:formValidation.errors});
        if (formValidation.valid){
            if (this.state.upload.file){
                if (this.state.id === null){
                    this.props.postUploadTemplate(payload,this.state.upload.file, 'new');
                }
                else{
                    payload = {
                        ...payload,
                        id: this.state.id
                    }
                    this.props.postUploadTemplate(payload,this.state.upload.file, 'edit');
                }
            }
            else{
                if (this.state.id === null){
                    this.props.postChecklistTemplate(payload);
                }
                else{
                    payload = {
                        ...payload,
                        id: this.state.id
                    }
                    this.props.putChecklistTemplate(payload);
                }
            }
        }
        else{
            this.props.notificationError(formValidation.errors[0].message);
        }
    }

  hasErrors = (field) => {
        let checkField = this.state.formErrors.filter((item) => { return item.name === field });
        if (checkField.length > 0){
            return true
        }
        else{
            return false;
        }
    }

    getErrors = (field) => {
    let checkField = this.state.formErrors.filter((item) => { return item.name === field });
    let errorString = null;
    checkField.map((item) => {
        errorString += item.message + '. ';
        return '';
    })
    return errorString;
    }

    handleFormChange = ({ target }) => {
       let tmpForm = this.state.form;
       tmpForm[target.name] = target.value;
       this.setState({ form: tmpForm });
       this.removeErrorOnChange(target.name);
    };

    handleMacTypeChange = ({target}) => {
        this.handleFormChange({target});
    
        // reset macsubtypeId   
        let tmpForm = {
            ...this.state.form,
            macsubtypeId: null
        };
        this.setState({ form: tmpForm }, () => {
            this.props.getMacSubTypes(1,target.value);
        });
      }

    loadMacSubTypes = (macSubTypeId) => {
        this.props.getMacSubTypes(1,macSubTypeId);
    }

    handleTaskChange = ({ target }) => {
       let tmpForm = this.state.form;
       tmpForm.tasks = tmpForm.tasks.map((item) => {
           if (item.seq.toString() === target.name.replace("task","").toString()){
               item.task = target.value;
           }
           return item;
       })
       this.setState({ form: tmpForm });
       this.removeErrorOnChange(target.name);
    };

    addedZero(value){
        return ("0" + value).slice(-2);
    }

    handleSubTaskChange = ({ target }) => {
        let tmpForm = this.state.form;
        let subTaskData = target.name.split("-"); // [0] is task seq, [1] is subtask seq
        tmpForm.tasks = tmpForm.tasks.map((item) => {
            if (item.seq.toString() === subTaskData[0].toString()){
                item.subtasks = item.subtasks.map((subtaskItem) => {
                    if (subtaskItem.seq.toString() === subTaskData[1].toString()){
                        subtaskItem.subtask = target.value;
                    }
                    return subtaskItem;
                })
            }
            return item;
        })
        this.setState({ form: tmpForm });
        this.removeErrorOnChange(target.name);
     };

    deleteTask = (taskSequence) => {
        if (this.state.form.tasks.length <= 1){
            this.props.notificationError("Cannot delete task");
        }
        else{
            let tmpForm = this.state.form;

            // rearranged task sequence
            let counter = 1;
            tmpForm.tasks = tmpForm.tasks.filter((item) => { return item.seq.toString() !== taskSequence.toString() }).map((item) => {
                item.seq = counter.toString();
                counter += 1;
                return item;
            });

            let payloadForm = {
                ...tmpForm,
                tasks: this.cleanTasks(tmpForm.tasks,'delete')
            }
            this.setState({ form: payloadForm });
        }
    }

    deleteSubTask = (taskSequence,subtaskSequence) => {
        let tmpForm = this.state.form;

        // rearranged subtask sequence
        let counter = 1;
        tmpForm.tasks = tmpForm.tasks.map((item) => {
            if (taskSequence === item.seq){
                let subtasks = item.subtasks.filter((subtaskItem) => { return subtaskItem.seq !== subtaskSequence}).map((item) => {
                    item.seq = counter.toString();
                    counter += 1;
                    return item;
                });
                item.subtasks = subtasks;
            }
            return item;
        })

        this.setState({ form: tmpForm });
    }

    checkIfApiLoading() {
        let flag = checkIfApiLoading([
            this.props.macTypeListLoading,
            this.props.brandListLoading,
            this.props.frequencyListLoading,
        ]);
        return flag;
    }

    getLastItem(array){
        if (array.length > 0){
            return array[array.length-1];
        }
        else{
            return null;
        }
    }

    makeSubtask = (taskSequence) => {
        if (taskSequence === '1' || taskSequence === 1){
            this.props.notificationError('Cannot make to subtask'.translate());
        }
        else{
            let tmpForm = this.state.form;

            let filteredTask = this.state.form.tasks.filter((item) => { return item.seq.toString() === taskSequence.toString()});
            if (filteredTask.length > 0){
                let lastTaskSequence = parseInt(filteredTask[0].seq) - 1;
                tmpForm.tasks = tmpForm.tasks.map((item) => {

                    if (item.seq.toString() === lastTaskSequence.toString()){
                        let lastSubtask = this.getLastItem(item.subtasks);
                        let counter = null;
                        if (lastSubtask){
                            counter  = parseInt(lastSubtask.seq) + 1;
                        }
                        else{
                            counter = 1;
                        }
                        let newSubtask = {
                            seq: counter.toString(),
                            subtask: filteredTask[0].task
                        }
                        item.subtasks.push(newSubtask);
                    }
                    return item;
                });
                this.setState({form:tmpForm}, () => {
                    this.deleteTask(taskSequence);
                });
            }
        }
    }

    addTask = () => {
        let tmpForm = this.state.form;
        let lastItem = this.getLastItem(this.state.form.tasks);
        let counter = parseInt(lastItem.seq) + 1;
        let newItem = {
            seq:counter.toString(),
            task:"",
            subtasks:[]
        }
        tmpForm.tasks.push(newItem);
        this.setState({form: tmpForm});
    }

    addSubTask = (taskSequence) => {
        let tmpForm = this.state.form;
        let filteredTask = this.state.form.tasks.filter((item) => { return item.seq === taskSequence });
        if (filteredTask.length > 0){
            let counter = null;
            if (filteredTask[0].subtasks.length > 0){
                let lastItem = this.getLastItem(filteredTask[0].subtasks);
                counter = parseInt(lastItem.seq) + 1;
            }
            else{
                counter = 1;
            }
            let newItem = {
                seq:counter.toString(),
                subtask:""
            }

            if (counter <= 10) {
                tmpForm.tasks = tmpForm.tasks.map((item) => {
                    if (item.seq === taskSequence){
                        item.subtasks.push(newItem);
                    }
                    return item;
                })
                this.setState({form: tmpForm});
            }
        }

    }

    getBackLink() {
        if (localStorage.getItem('redirectToTickets') === 'true') {
            return '/tickets';
        }
        else if (localStorage.getItem('redirectToMaintenance') === 'true') {
            return '/maintenance';
        }
        else if (this.props.match.params.id){
            return '/checklist-detail/' + this.props.match.params.id;
        }
        else {
            console.log('check setter neil')
            localStorage.setItem('redirectToChecklist', true);
            return '/checklist';
        }
    }

    componentWillReceiveProps(){
        if (this.state.detailLoaded === false && this.props.match.params.action === 'edit' && this.props.match.params.id !== undefined && this.props.checklistDetail !== undefined && this.props.checklistDetail !== null){
            let mutatedTask = this.props.checklistDetail.tasksDetailed.map((item) => {
                let task = {
                    ...item.task,
                    subtasks: item.subtasks
                }
                return task;
            })
            if (mutatedTask.length <= 0){
                mutatedTask = [
                    {
                        seq:"1",
                        task:"",
                        subtasks:[]
                    }
                ];
            }
            let details = this.props.checklistDetail.details[0];
            let updatedForm = {
                motortypeId: null,
                checklistNo: details.no,
                brandId: details.brandId,
                frequencyId: details.frequencyId,
                mactypeId: details.macTypeId,
                macsubtypeId: details.macSubTypeId,
                desc1: details.name,
                temptype: details.tempType,
                statusId: details.templateStatusId,
                status_desc: details.templateStatus,
                instructionLink: details.instructionLink,
                tasks: mutatedTask
            }
            let currentFileData = this.getFilenameAndFormatByLink(details.instructionLink);
            this.loadMacSubTypes(details.macTypeId);
            this.setState({form: updatedForm, detailLoaded: true,currentFile:currentFileData});
        }
    }

    componentDidMount() {
        this.props.getAllMacSubTypesTreeView();
        this.props.getBrands();
        this.props.getFrequencies();
        this.props.getChecklistTemplateStatus()
        this.getTemplateTypes();
        if (this.props.match.params.action === 'edit' && this.props.match.params.id !== undefined){
            let id = this.props.match.params.id;
            this.props.getChecklistDetail(id);
            this.setState({id: id});
        }
        else if (this.props.match.params.action === 'new'){

        }
        else{
            history.push('/dashboard');
        }
    }

    renderText(field) {
        if (field === null || field === '') {
            return '-'
        }
        else {
            //   return field != undefined ? field.toString().translate() : field
            return field
        }
    }

    renderCheckbox(check) {
        if (check === 1 || check === true) {
            return 'Yes'.translate() || this.state.languageProps.YES
        }
        else {
            return 'No'.translate() || this.state.languageProps.NO
        }
    }

    renderDate(date) {
        if (date !== null) {
            let newDate = date.substring(0, date.indexOf('T'));
            return moment(newDate).format('DD-MM-YYYY');
        }
        else {
            return '-';
        }
    }

    renderDateWithMinHour(date) {
        if (date !== null) {
            return moment(date).format('YYYY-MM-DD HH:mm:ss.sss');
        }
        else {
            return null;
        }
    }

    renderCreatedDate() {
        return moment().format('YYYY-MM-DD HH:mm:ss.sss');
    }

    renderBoolean(boolean) {
        if (boolean) {
            return 1
        } else {
            return 0
        }
    }

    preventLinkRedirect = (e) => {
        e.preventDefault();
    }

    getFilenameAndFormat(file){
        let fileData = file.name.split('.');
    
        let currentFileData = {
            name: fileData[0],
            format: fileData[1]
        }

        return currentFileData
    }

    getFilenameAndFormatByLink(link){
        if (link !== null){
            let fileData = link.replace('/files/','').split('.');
    
            let currentFileData = {
                name: fileData[0],
                format: fileData[1]
            }

            return currentFileData
        }
        else{
            let currentFileData = {
                name: null,
                format: null
            }
        }
    }

    handleInstructionUpload = () => {
        document.getElementById("instructionLink").value = '';
        document.getElementById("instructionLink").click();
    }

    handleInstructionUploadChange = ({target}) => {
        let file = target.files;
        let flag = null;

        if (file[0].name.indexOf('.pdf') >= 0 || file[0].name.indexOf('.txt') >= 0){
            flag = true
        }
        else{
            flag = false;
        }

        if (flag === true){
            let fd = new FormData()
            Object.entries(file).forEach((x : any) => {
                fd.append('file',x[1])
            })
            fd.append('str', 'stronly');
    
            let upload = {
                file: fd
            }
            
            let currentFileData = this.getFilenameAndFormat(file[0]);
    
            this.setState({upload:upload, currentFile: currentFileData});
        }
        else{
            this.props.notificationError('Invalid file type');
        }
    }

    handleCancel = () => {
        if (this.state.id){
            history.push('/checklist-detail/' + this.state.id);
        }
        else{
            history.push('/checklist');
        }
    }

    renderFileName(){
        return (
            <Grid item xs={6}>
                 <MuiThemeProvider theme={this.state.tooltipTheme}>
                    <div className="file-upload">
                        <Grid container spacing={0}>
                            <Grid item xs={3}>
                                <div className="file-icon">
                                    <DescriptionIcon className="icon" />
                                </div>
                            </Grid>
                            <Grid item xs={8}>
                                <div className="file-info">
                                    <div className="file-format">{ this.state.currentFile.format.toUpperCase() }</div>
                                    <Tooltip placement="top-start" title={this.state.currentFile.name}>
                                        
                                    <div className="file-name">{ this.state.currentFile.name }</div>
                                    </Tooltip>
                                </div>
                            </Grid>
                            <Grid item xs={1}>
                                <CloseIcon onClick={this.deleteCurrentFile} className="file-close" />
                            </Grid>
                        </Grid>
                    </div>
                </MuiThemeProvider>
            </Grid>
        )
    }

    renderDraft(){
        if (this.state.id === null){
            return (
                <Button onClick={() => this.submitForm('DRAFT')} variant="contained">{'Save as Draft'.translate()}</Button>
            )
        }
    }

    renderChecklistNo(){
        if (this.state.id !== null){
            return (
                <Grid className="form-control" container spacing={1}>
                    <Grid item xs={5}>
                        <span className="form-label">{'Checklist No'.translate()}</span>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <div className="form-label extra-margin">{this.state.form.checklistNo}</div>
                        </FormControl>
                    </Grid>
                </Grid>
            )
        }
    }

    renderStatus(status){
        if (status === 'ACTIVE'){
            return (
                <div className="status active">
                    { status.translate() }
                </div>
            )
        }
        else if (status === 'DRAFT'){
            return (
                <div className="status in-repair">
                    { status.translate() }
                </div>
            )
        }
        else if (status === 'INACTIVE'){
            return (
                <div className="status not-working-gray">
                    { status.translate() }
                </div>
            )
        }
    }

    renderStatusLabel(){
        if (this.state.id !== null){
            return (
                <Grid className="form-control" container spacing={1}>
                    <Grid item xs={6}>
                        <span className="form-label">{'Status'.translate()}</span>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            { this.checkIfApiLoading() === false ? this.renderStatus(this.state.form.status_desc) : null }
                        </FormControl>
                    </Grid>
                </Grid>
            )
        }
    }

    content() {
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid className="actions" item xs={4}>
                        <Grid container spacing={1}>
                            {/* <Grid item>
                                <div className={"action-item " }>
                                    <Link to={'/checklist/edit/' + this.state.id}>
                                        <Button fullWidth variant="outlined" color="primary"><span>{'EDIT'.translate()}</span></Button>
                                    </Link>
                                </div>
                            </Grid>
                            <Grid item>
                                <div className={"action-item " }>
                                    <Link>
                                        <Button fullWidth variant="outlined" color="primary"><span>{'MAKE INACTIVE'.translate()}</span></Button>
                                    </Link>
                                </div>
                            </Grid>
                            <Grid item>
                                <div className={"action-item " }>
                                    <Link>
                                        <Button fullWidth variant="outlined" color="primary"><span>{'ASSIGN MACHINE'.translate()}</span></Button>
                                    </Link>
                                </div>
                            </Grid> */}
                            <Grid item xs={6}>

                                <div className={"action-item " }>
                                    <Link>
                                        <Button style={{padding: '14px 15px'}} onClick={this.handleInstructionUpload} fullWidth variant="outlined" color="primary"><span>{'UPLOAD INSTRUCTION'.translate()}</span></Button>
                                    </Link>
                                </div>
                            </Grid>
                            { this.state.currentFile ?  this.renderFileName() : null }
                            {/* <Grid item>
                                <div className={"action-item " + (this.state.form.instructionLink === null ? 'disabled' : '')}>
                                    <Link>
                                        <Button disabled={this.state.form.instructionLink === null ? true : false} fullWidth variant="outlined" color="primary"><span>{'VIEW INSTRUCTION'.translate()}</span></Button>
                                    </Link>
                                </div>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid className="form-component" container spacing={1}>
                    <MuiThemeProvider theme={this.state.tooltipTheme}>
                        <FormControl fullWidth component="fieldset">
                            <Input inputProps={{accept:".txt,.pdf"}} onChange={this.handleInstructionUploadChange} id="instructionLink" className="file-hidden" type="file" name="instructionLink" />
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormGroup>
                                        { this.renderChecklistNo() }
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={5}>
                                                <span className="form-label required">{'Checklist Name'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <TextField error={ this.hasErrors('desc1') } onChange={this.handleFormChange} name="desc1" value={this.state.form.desc1} variant="outlined" />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={5}>
                                                <span className="form-label required">{'Template Type'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <Select disabled={this.state.id ? true : false} error={ this.hasErrors('temptype') } onChange={this.handleFormChange} name="temptype" value={this.state.form.temptype} variant="outlined" className="form-select">
                                                        {this.state.templateTypes?.map(({ desc1, tempType }) => (
                                                            <MenuItem value={tempType}>{desc1.translate()}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormGroup>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={5}>
                                                <span className="form-label">{'Machine Type'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <Select error={ this.hasErrors('mactypeId') } onChange={this.handleMacTypeChange} name="mactypeId" value={this.state.form.mactypeId} variant="outlined" className="form-select">
                                                        { this.checkIfApiLoading() === false ? this.renderMacTypesItems() : null }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={5}>
                                                <span className="form-label">{'Machine Sub Type'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <Select disabled={ this.props.macSubTypeList.length <= 0 } error={ this.hasErrors('macsubtypeId') } onChange={this.handleFormChange} name="macsubtypeId" value={this.state.form.macsubtypeId} variant="outlined" className="form-select">
                                                        { this.checkIfApiLoading() === false ? this.renderMacSubTypesItems() : null }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={5}>
                                                <span className="form-label">{'Brand'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <Select error={ this.hasErrors('brandId') } onChange={this.handleFormChange} name="brandId" value={this.state.form.brandId} variant="outlined" className="form-select">
                                                        { this.checkIfApiLoading() === false ? this.renderBrandItems() : null }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormGroup>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{'Maintenance Freq'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <Select error={ this.hasErrors('frequencyId') } onChange={this.handleFormChange} name="frequencyId" value={this.state.form.frequencyId} variant="outlined" className="form-select">
                                                        { this.checkIfApiLoading() === false ? this.renderFrequencyItems() : null }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        { this.renderStatusLabel() }
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </MuiThemeProvider>
                </Grid>
                <div className="task-group">
                    { this.renderTasks() }
                    <div className="add-task">
                        <Button onClick={this.addTask} variant="outlined">+ { "ADD TASK".translate() }</Button>
                    </div>
                </div>
                <div className="button-group">
                    <Button onClick={this.handleCancel} variant="contained">{'Cancel'.translate()}</Button>
                    { this.renderDraft() }
                    <Button onClick={() => this.submitForm('ACTIVE')} variant="contained" color="primary" disabled={ this.props.loadingPostChecklist }>
                        { this.props.loadingPostChecklist && <span> <RefreshIcon /> { 'Saving...'.translate() } </span> }
                        { !this.props.loadingPostChecklist && <span> { 'Save & Activate'.translate() || this.state.languageProps.SAVE } </span> }
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <MainPage className="checklist-form">
                <MainPageHeader title="Checklist Templates" search={false} back={this.getBackLink()} />
                <MainPageBody>
                    {this.content()}
                </MainPageBody>
            </MainPage>
        );
    }
}
const mapStateToProps = state => {
    let { translationReducer } = state
    let apiLoading = {
        macTypeListLoading: state.macTypeListReducer.loading,
        macSubTypeListLoading: state.macSubTypeListReducer.loading,
        brandListLoading: state.brandListReducer.loading,
        frequencyListLoading: state.frequencyListReducer.loading,
    }
    let data = {
        userAccess: state.authReducer.access,
        checklistTemplatesStatusList: state.statusListReducer.checklistTemplatesStatusList,
        checklistDetail: state.checklistReducer.checklistDetail,
        loadingPostChecklist: state.checklistReducer.loadingPostChecklist,
        macTypeList: state.macTypeListReducer.macTypeList,
        macSubTypeListTree: state.macSubTypeListReducer.macSubTypeListTree,
        macSubTypeList: state.macSubTypeListReducer.macSubTypeList,
        brandList: state.brandListReducer.brandList,
        frequencyList: state.frequencyListReducer.frequencyList,
    }
    return {
        ...apiLoading,
        ...data,
        ...translationReducer
    }

}

const mapDispatchToProps = dispatch => {
    return {
        notificationError: (message) => dispatch(notificationError(message)),
        getChecklistTemplateStatus: () => dispatch(getChecklistTemplateStatus()),
        getMacTypes: () => dispatch(getMacTypes()),
        getAllMacSubTypesTreeView: () => dispatch(getAllMacSubTypesTreeView()),
        getMacSubTypes: (isActive,macTypeId) => dispatch(getMacSubTypes(isActive,macTypeId)),
        getAllMacSubTypes: () => dispatch(getAllMacSubTypes()),
        getBrands: () => dispatch(getBrands()),
        getFrequencies: () => dispatch(getFrequencies()),
        getChecklistDetail: (id) => dispatch(getChecklistDetail(id)),
        postChecklistTemplate: (payload) => dispatch(postChecklistTemplate(payload)),
        putChecklistTemplate: (payload) => dispatch(putChecklistTemplate(payload)),
        postUploadTemplate: (payload,file,method) => dispatch(postUploadTemplate(payload,file,method)),
    }
}

ChecklistForm = connect(mapStateToProps, mapDispatchToProps)(ChecklistForm);

export default ChecklistForm;
