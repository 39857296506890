import React from 'react';
import { 
  Box, 
  Tooltip, 
  CircularProgress 
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '../components/Button';

const Container = withStyles({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    paddingTop: 16,
    paddingBottom: 16,
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
})(Box);

const DisabledButton = withStyles({ // because disabled button can not show tooltip
  root: {
    boxShadow: 'none !important',
    backgroundColor: '#E0E0E0 !important',
    color: '#A6A6A6 !important'
  }
})(Button);

export default function PageActions({ 
  isLoading = false,
  isValid, 
  onCancel, 
  onSubmit,
}) {
  return <Container>      
    <Button onClick={onCancel}>
      {'Cancel'.translate()}
    </Button>
    {
      isValid ? (
        <Button disabled={isLoading} onClick={onSubmit} variant='contained' color="primary">
          {isLoading ? <CircularProgress size={24} /> : 'Save'.translate()}
        </Button>
      ) : (
        <Tooltip title={'Cannot save if line setup information is blan.'.translate()} arrow>
          <DisabledButton variant='contained'>
            {'Save'.translate()}
          </DisabledButton>
        </Tooltip>
        
      )
    }
    
  </Container>
}