import React from 'react';
import { connect } from 'react-redux';
import { getUsers, deleteUser } from '../../../../../redux/actions/User/userActions';
import { confirmAlert } from 'react-confirm-alert';
import history from '../../../../../history';
import Table from '../../../../../components/CollapsableTable/CollapseTable'
import { checkPageAccess, checkPageActionAccess, checkPageAccessField, isSuperAdmin } from '../../../../../utils/helper'
import dictionary from '../../../../../redux/actions/Translation/dictionary'
import './UserAccess.scss';

class UserAccess extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            initialLoad: true,
            sortInstance: {
                column: undefined,
                sort: undefined,
                nameSort: 'asc',
                dateSort: 'asc',
                searchString: undefined,
            },
            tableState: {
                add: () => {
                    history.push('/configuration/user-profile/')
                },
                // edit: ({userId}) => {
                // },
                // remove: ({id}) => {
                // confirmAlert({
                //     title:  'Are you sure you want delete brand?',
                //     message: '',
                //     buttons: [
                //         {
                //             label:  'YES',
                //             onClick: () => {
                //                 this.props.deleteUser(id)
                //             }
                //         },
                //         {
                //             label:  'NO',
                //         }
                //     ]
                // });
                // },
                row_click: ({ userId, assigned }) => {
                    localStorage['assignedFactories'] = assigned
                    history.push('/configuration/user-profile/' + userId);
                },
                /**use for api search function */
                searchFn: (searchString) => {
                    let sortInstance = {
                        ...this.state.sortInstance,
                        searchString
                    }
                    this.setState({
                        sortInstance,
                    }, () => this.props.getUsers(false, this.state.sortInstance))
                },
                sorted: (ev, index, sortState, searchString) => {
                    let sortInst = sortState == 'asc' ? 'desc' : 'asc'
                    let { sortInstance: { column, sort, nameSort, dateSort }, tableState: { keys } } = this.state
                    let sortInstance
                    // if (((ev != 'name') && (ev != 'updatedDt')) == true) {
                    if (ev != 'name') {
                        sortInstance = {
                            ...this.state.sortInstance,
                            column: ev,
                            sort: sortState,
                            nameSort: undefined,
                            dateSort: undefined,
                            searchString
                        }
                    }
                    else {
                        if (ev == 'name') {
                            sortInstance = {
                                ...this.state.sortInstance,
                                nameSort: sortState,
                                column: undefined,
                                sort: undefined,
                                searchString
                            }
                        }
                        else sortInstance = {
                            ...this.state.sortInstance,
                            dateSort: sortState,
                            column: undefined,
                            sort: undefined,
                            searchString
                        }
                    }

                    keys[index] = Object.assign({}, { ...keys[index], sortState: sortInst })
                    console.log('sorted', sortInstance)
                    this.setState({
                        sortInstance,
                        tableState: {
                            ...this.state.tableState,
                            keys
                        }
                    }, () => this.props.getUsers(false, this.state.sortInstance))

                },
                search: true,
                searchInputValue: '',
                paginated: true,
                tableHeaderFixed: true,
                highlight: true,
                keys: this.initTableHeader(),
                data: [],
                loading: false
            }
        }
    }

    /**handler */
    initTableHeader = () => {
        let { STATUS, NAME, LOGIN_ID, ROLE, ASSIGNED, CARD_ID, EMAIL, LAST_ACCESS_DATE, UPDATED_DATE } = this.convert()
        return [
            {
                name: NAME,
                sortState: 'desc',
                key: 'name',
                tableHeaderWidth: '10%'
            },
            {
                name: LOGIN_ID,
                sortState: 'asc',
                key: 'loginId',
                tableHeaderWidth: '10%',
            },
            {
                name: ROLE,
                sortState: 'asc',
                key: 'role',
                tableHeaderWidth: '10%'
            },
            {
                name: ASSIGNED,
                sortState: 'asc',
                key: 'assigned',
                tableHeaderWidth: '13%'
            },
            {
                name: CARD_ID,
                sortState: 'asc',
                key: 'cardNo',
                tableHeaderWidth: '10%',
                whiteSpace: 'nowrap'
            },
            {
                name: EMAIL,
                sortState: 'asc',
                key: 'email',
                tableHeaderWidth: '10%'
            },
            {
                name: STATUS,
                sortState: 'asc',
                key: 'status',
                tableHeaderWidth: '7%'
            },
            {
                name: LAST_ACCESS_DATE,
                sortState: 'asc',
                type: 'date',
                key: 'lastAccessDt',
                tableHeaderWidth: '15%',
            },
            {
                name: UPDATED_DATE,
                sortState: 'desc',
                type: 'date',
                key: 'updatedDt',
                alt: 'createdDt',
                tableHeaderWidth: '15%',
            },
        ]
    }

    convert = () => {
        let { STATUS, NAME, LOGIN_ID, ROLE, ASSIGNED, CARD_ID, EMAIL, LAST_ACCESS_DATE, UPDATED_DATE } = dictionary.GET()
        return dictionary.TRANSLATE({STATUS, NAME, LOGIN_ID, ROLE, ASSIGNED, CARD_ID, EMAIL, LAST_ACCESS_DATE, UPDATED_DATE })
    }

    componentDidMount() {
        this.props.getUsers(false, this.state.sortInstance)
    }

    componentDidUpdate() {
        // console.log('didUpdate', this.props, this.state)
        let { loading, userList } = this.props
        let { loading: stateLoading, data } = this.state.tableState
        if (loading != stateLoading || userList?.length != data?.length) {
            let tableState = this.state.tableState;
            if (checkPageActionAccess(this.props.userAccess, { pageId: 25, pageAction: 'Add' }) === false && !isSuperAdmin()) {
                tableState = {
                    ...tableState,
                    add: null
                }
            }
            if (checkPageActionAccess(this.props.userAccess, { pageId: 25, pageAction: 'Edit' }) === false && checkPageAccessField(this.props.UserAccess, { pageId: 25, pageAccess: 'VIEW' }) === false && !isSuperAdmin) {
                tableState = {
                    ...tableState,
                    row_click: null
                }
            }
            this.setState({
                tableState: {
                    ...tableState,
                    data: [...userList],
                    loading,
                    timeStamp: new Date()
                }
            })
        }
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                translations: Object.assign({}, { ...this.convert() }),
                tableState: {
                    ...this.state.tableState,
                    keys: this.initTableHeader(),
                    timeStamp: new Date()
                }
            })
        }

    }

    componentWillUnmount() {
    }

    /**render */
    render() {
        return (
            <div className="brand-body-overview">
                <Table {...this.state.tableState} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    let { userReducer, translationReducer } = state
    let apiLoading = {
    }
    let data = {
        userAccess: state.authReducer.access,
    }

    return {
        ...apiLoading,
        ...data,
        ...userReducer,
        ...translationReducer

    }
}

const mapDispatchToProps = dispatch => {
    return {
        // getUsers
        getUsers: (ev, sortInstance) => dispatch(getUsers(ev, undefined, undefined, undefined, sortInstance)),
        deleteUser: (id, payload) => dispatch(deleteUser(id, payload)),
    }
}

UserAccess = connect(mapStateToProps, mapDispatchToProps)(UserAccess);

export default UserAccess;
