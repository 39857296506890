import {
   AREA_LIST,
   AREA_LIST_REQUEST,
   AREA_LIST_SUCCESS,
   AREA_LIST_ERROR
 } from '../actionTypes';
 import { tokenGet, apiSettings } from '../../../utils/api/CoreApi';
 
 export const areaRequest = () => {
     return {
         type:AREA_LIST_REQUEST
     }
 }
 
 export const areaSuccess = areaList => {
     return {
         type:AREA_LIST_SUCCESS,
         payload: areaList
     }
 }
 
 export const getAreas = (isActive = 1, currentPage = 1, pageSize = 100000) =>{
     return function(dispatch){
         dispatch(areaRequest());
         tokenGet(apiSettings.apiURLReferences + '/areas?isActive='+ isActive +'&currentPage=' + currentPage +'&pageSize=' + pageSize).then((response) => {
             let data = response.data.machines.result.map((item) => {
                let itemRow = {
                    'id': item.id,
                    'area': item.desc1,
                    'areaTypeId': item.areaTypeId,
                    'isLineReq': item.isLineReq,
                    buildingId: item.buildingId,
                }
                return itemRow;
             });
             dispatch(areaSuccess({
                 areaList: data,
                 meta: response.data.machines.meta
             }));
         })
         .catch((error) => {
             console.log(error);
         });
     }
 }