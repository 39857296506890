import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, ListItemText } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { 
    ButtonMultipleSelect, 
    ListItemIconMultipleSelect, 
    MenuItemMultipleSelect, 
    MenuMultipleSelect,
} from './styles';

function MultipleSelection({ 
    data, 
    label, 
    selectAllLabel, 
    onChange, 
    defaultValue,
    isLoading = false,
}) {
    const [selectedOptions, setSelectedOptions] = React.useState([]);
    const [isAllSelected, setIsAllSelected] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleOnAllSelection = () => {
        const isAll = defaultValue.length === data.length;
        const options = data.map(({ value }) => value);
        setSelectedOptions(isAll ? [] : options);
        setIsAllSelected(!isAll);
        onChange(isAll ? [] : options);
    };
    
    const handleOnSelection = (value) => {
        let result = [];
        if (selectedOptions.includes(value)) {
            result = selectedOptions.filter((item) => item !== value);
        } else {
            result = [...selectedOptions, value];
        }
        setSelectedOptions(result);
        onChange(result);
        setIsAllSelected(result.length === 0);
    };
    
    const handleDisplayLabel = (isAllSelected) => {
        if(isAllSelected) {
            return selectAllLabel || 'All Selection';
        }
        return `${selectedOptions.length} Selected ${label}s`.translate();
    };
    
    useEffect(() => {
        setSelectedOptions(defaultValue);
        setIsAllSelected(data.length === defaultValue.length);
    }, [data, defaultValue]);

    return (
        <React.Fragment>
            <ButtonMultipleSelect
                disabled={isLoading}
                endIcon={Boolean(anchorEl) ? <ArrowDropUp /> : <ArrowDropDown />}
                variant='contained'
                color='primary'
                onClick={(event) => setAnchorEl(event.currentTarget)}>
                {handleDisplayLabel(isAllSelected)}
            </ButtonMultipleSelect>
            <MenuMultipleSelect
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted={true}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                elevation={5}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: -10,
                    horizontal: 'center',
                }}
            >
                <MenuItemMultipleSelect disabled={isLoading} onClick={() => handleOnAllSelection()} key='all-selection'>
                    <ListItemIconMultipleSelect>
                        <Checkbox color='primary' 
                            indeterminate={!isAllSelected} 
                            checked={isAllSelected} />
                    </ListItemIconMultipleSelect>
                    <ListItemText primary={selectAllLabel || 'All Selection'} />
                </MenuItemMultipleSelect>
                {data.map(({ label, value }) => (
                    <MenuItemMultipleSelect disabled={isLoading} onClick={() => handleOnSelection(value)} key={value} value={label}>
                        <ListItemIconMultipleSelect>
                            <Checkbox color='primary' checked={selectedOptions.indexOf(value) > -1} />
                        </ListItemIconMultipleSelect>
                        <ListItemText primary={label} />
                    </MenuItemMultipleSelect>
                ))}
            </MenuMultipleSelect>
        </React.Fragment>
    );
};

MultipleSelection.propTypes = {
    data: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    selectAllLabel: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.array,
};

export default MultipleSelection;
