import React from 'react';
import './MainPage.scss';

class MainPage extends React.Component {

  componentDidMount(){
      console.log('Main Page Mounted');
  }

  renderFooter(){
    if (this.props.footer !== false){
      return (
        <div className="footer">{'ALL RIGHTS RESERVED 2020@LTLABS'.translate()}</div>
      )
    }
  }

  render(){
    return (
        <div className={'main-page ' + this.props.className}>
          { this.props.children }
          { this.renderFooter() }
        </div>
    );
  }
}

export default MainPage;
