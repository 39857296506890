export default   {
  id: "filter",
  afterDatasetDraw: function(chart, args, options) {
    const elements = args.meta.data;
    const ctx = chart.ctx;
    const width = chart.width;
    const height = chart.height;
    const { selected } = options;
    const filteredElements = elements.filter((e, index)=> !selected[index]);
    if(filteredElements.length == 0 || args.meta.index === 1){ return };
    ctx.save();
    ctx.beginPath();
    ctx.lineWidth = 0;
    ctx.strokeStyle = "transparent";

    filteredElements.forEach((e, index)=>{
      const model = e._model;
      ctx.moveTo(model.x, model.y);
      ctx.arc(model.x, model.y, model.innerRadius, model.startAngle, model.endAngle, false);
      ctx.moveTo(model.x, model.y);
      ctx.arc(model.x, model.y, model.outerRadius, model.startAngle, model.endAngle, false);
    });
    ctx.stroke();
    ctx.clip("evenodd");

    ctx.lineWidth = 0.8;
    ctx.strokeStyle = "rgba(255,255,255,0.5)";

    let x = width;
    let y = height;
    while (x > 0 && y > 0) {
      ctx.moveTo(0, y);
      ctx.lineTo(x, 0);
      ctx.stroke();
      x = x - 15;
      y = y - 15;
    }

    x = 0;
    y = 0;
    while (x < width || y < height) {
      ctx.moveTo(x, height);
      ctx.lineTo(width, y);
      ctx.stroke();
      x = x + 15;
      y = y + 15;
    }

    ctx.closePath();
    ctx.restore();
  }
}
