import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  CircularProgress,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LoopIcon from '@material-ui/icons/Loop';
import DeleteIcon from '@material-ui/icons/Delete';
import CardPaper from './components/CardPaper';
import BootstrapInput from '@src/components/BootstrapInput';
import DateRangeInput from '@src/components/DateRangeInput/index.js';
import { format } from 'date-fns';
import { client } from './api';

const useStyles = makeStyles({
  textRight: {
    textAlign: 'right'
  },
  updateTime: {
    color: '#1689FA',
    fontWeight: 'bold'
  },
  formControl: {
    minWidth: 250,
    marginRight: 15
  },
  loading: {
    marginLeft: 10,
    marginRight: 10
  }
})

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    '& .MuiListItemIcon-root': {
      visibility: "hidden"
    },
    '& .MuiListItemText-root': {
      marginTop: 4,
      marginBottom: 4
    },
    '&:hover': {
      '& .MuiListItemIcon-root': {
        visibility: "visible",
        display: 'inline-flex'
      },
    },
  },
}))(MenuItem);

const StyledListItemIcon = withStyles((theme) => ({
  root: {
    visibility: "hidden",
    justifyContent: "flex-end",
    display: 'none'
  },
}))(ListItemIcon);

const StyledListItemText = withStyles((theme) => ({
  root: {
    display: "inline-flex",
    marginTop: 0,
    marginBottom: 0
  },
}))(ListItemText);


export default function DateRange(props){
  const classes = useStyles();
  const { 
    templates, 
    onDeleteTemplate, 
    setDateRange, 
    dateRange, 
    lastUpdatedAt, 
    isLoading, 
    onRefresh, 
    onTemplateSelected,
    onSelectAllData,
  } = props;
  const [value, setvalue] = React.useState('all-data');

  const handleTemplateSelected = (templateId)=>{
    setvalue(templateId);
    if(templateId === 'all-data') {
      onSelectAllData();
      return false;
    }
    client.get(`/templates/${templateId}`).then((res)=>{
      onTemplateSelected(res.data);
    });
  };

  const handleTemplateDeleted = (e, template)=>{
    e.stopPropagation();
    onDeleteTemplate(template)
  };

  return (
    <CardPaper>
      <Grid container spacing={1} direction="row">
        <Grid item xs={8}>
          <Typography gutterBottom variant="h4">
            {"Analytics & Reporting".translate()}
          </Typography>
          <FormControl className={classes.formControl}>
            <label>{"Saved Filter Template".translate()}</label>
            <Select
              value={value}
              input={<BootstrapInput />}
              onChange={(e)=> handleTemplateSelected(e.target.value)}
            >
              <StyledMenuItem key='all-data' value='all-data'>
                <StyledListItemText primary={'All Data'.translate()} />
              </StyledMenuItem>
              {
                templates.map((template)=>(
                  <StyledMenuItem key={template.id} value={template.id}>
                    <StyledListItemText primary={template.templateName} />
                    <StyledListItemIcon>
                      <DeleteIcon onClick={(e)=> handleTemplateDeleted(e, template) } fontSize="medium" style={{ color: 'red' }}/>
                    </StyledListItemIcon>
                  </StyledMenuItem>
                ))
              }
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <label>{"Select the date".translate()}</label>
            <DateRangeInput
              dateRange={dateRange}
              onChange={setDateRange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} className={classes.textRight}>
          { lastUpdatedAt && 
            <span>
              <span className={classes.updateTime}>{"Updated at".translate()} {format(lastUpdatedAt, "H:mm")}</span>
              <span>, {format(lastUpdatedAt, "PPPP")}</span>
            </span>
          }
          {isLoading ? (
            <span className={classes.loading}>
              <CircularProgress size={13} />
            </span>
          ) : (
            <IconButton onClick={() => onRefresh()}>
              <LoopIcon />
            </IconButton>
          )}


        </Grid>
      </Grid>
    </CardPaper>
  );
}
