import React from 'react';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import { numberWithCommas } from './../../../../../utils/helper';

export const GridItem = ({ label, count, isTvMode }) => (
    <Grid className={isTvMode ? 'ticket-count-container' : ''} item xs={4}>
        <Box className={isTvMode ? 'ticket-count tvMode' : 'ticket-count repair-ticket-count'}>
            {numberWithCommas(count)}
        </Box>
        <Box className={isTvMode ? 'ticket-status tvMode repair-ticket' : 'ticket-status repair-ticket-status'}>
            {label}
        </Box>
    </Grid>
);

export const GridTitle = ({ title, isTvMode }) => (
    <Box className={isTvMode ? 'title tvMode' : 'title repair-ticket-title'}> 
        {title}
    </Box>
);

export const LoadingBox = () => (
    <Box style={{ position: 'absolute', right: 10, top: 10 }}>
        <CircularProgress color='inherit' size={25} />
    </Box>
);