import { Grid } from "@material-ui/core";
import React from "react";
import RenderFilter from "./Filter";

export default function RenderHeader({
  title,
  buildings,
  buildingId,
  areas,
  areaId,
  totalLines,
  filterDisabled,
  handleChangeArea,
  handleChangeBuilding,
}) {
  return (
    <Grid container spacing={0}>
      <Grid item xs={5}>
        <Grid container spacing={0}>
          <Grid item xs>
            <div className="header-title">
              {title.toUpperCase().translate()}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <RenderFilter
        buildings={buildings}
        buildingId={buildingId}
        areas={areas}
        areaId={areaId}
        totalLines={totalLines}
        filterDisabled={filterDisabled}
        handleChangeBuilding={handleChangeBuilding}
        handleChangeArea={handleChangeArea}
      />
    </Grid>
  );
}
