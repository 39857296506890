import TableCell from './TableCell';
import { withStyles } from '@material-ui/styles';

export default withStyles({
  root: {
    backgroundColor: '#EDF0F2',
    border: 'none',
    fontSize: 12,
    textTransform: "uppercase",
    textAlign: 'center',
    letterSpacing: 0.08,
    fontWeight: 500,
  },
})(TableCell);
