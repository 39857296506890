import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { 
    Button, 
    ListItemIcon, 
    Menu, 
    MenuItem,
} from '@material-ui/core';

export const ButtonMultipleSelect = withStyles(({
    root: {
        'position': 'relative',
        'background': '#366a90 0 0 no-repeat padding-box!important',
        'boxShadow': '0 3px 6px rgba(0,0,0,.1607843137254902)',
        'borderRadius': '4px!important',
        'height': '40px',
        'minWidth': '210px !important',
        'padding': '0 10px',
        'color': '#fff',
        'lineHeight': '2rem',
        'paddingBottom': '0',
        'justifyContent': 'flex-start',
        'fontSize': '1.2rem',
        'fontWeight': 'normal',
    },
    label: {
        'justifyContent': 'space-between',
        'textTransform': 'capitalize',
    }
}))(Button);

export const ListItemIconMultipleSelect = withStyles(({
    root: {
        minWidth: 0,
    },
}))(ListItemIcon);

export const MenuItemMultipleSelect = withStyles(({
    root: {
        whiteSpace: 'normal',
    },
}))(MenuItem);

export const MenuMultipleSelect = withStyles(({
    root: {
        '& span': {
            fontSize: '1.2rem !important',
        },
        '& svg': {
            height: '1.2em !important',
            width: '1.2em !important',
        }
    },
    paper: {
        padding: 0,
        width: 210,
        maxHeight: 300,
        borderRadius: '0px',
        '& span': {
            fontSize: '1.2rem !important',
        },
        '& svg': {
            height: '1.2em !important',
            width: '1.2em !important',
        },
        '&::-webkit-scrollbar': {
            width: '0.4em',
            borderRadius: 10
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#707070',
        },
        '& ul': {
            padding: 0,
        },
    },
}))(Menu);