import React from 'react';
import moment from 'moment';
import { required, validateFields } from './FormValidation';
import history from '../../../../history'
import { checkIfApiLoading } from '../../../../utils/helper';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { notificationError } from '../../../../redux/actions/Notification/notificationActions';
import { getMachineById, getTemplates } from '../../../../redux/actions/Machine/machineActions';
import { postNewTicket, getTicketTypes } from '../../../../redux/actions/Tickets/ticketActions';
import { getProblems, getTicketProblems } from '../../../../redux/actions/Problem/problemActions';
import { getSolutionDetail, getSolutionsByProblem } from '../../../../redux/actions/Solution/solutionActions';
import MainPage from '../../../../components/MainPage/MainPage';
import MainPageHeader from '../../../../components/MainPageHeader/MainPageHeader';
import MainPageBody from '../../../../components/MainPageBody/MainPageBody';
import TemplateControl from '../../../../components/TemplateControl/TemplateControl';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import EnhancedTable from './Templates/MaintenanceTemplate'
import dictionary from '../../../../redux/actions/Translation/dictionary'
import './TicketForm.scss';
import CircularProgress from '@material-ui/core/CircularProgress';

class TicketForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            id: null,
            formErrors: [],
            form: {
                problemTypeId: null,
                remarks: null,
                solutionTypeId: null,
            },
            rows: [],
            languageProps: Object.assign({}, { ...this.changeLang() }),
            translationTimestamp: undefined,
        }
    }

    changeLang = () => {
        let { NOTIFICATIONS: { status,cannot_create_ticket_for_machine_with},
            PROBLEM,SOLUTION,REMARKS,MACHINE_NO,MODEL_NO,MACHINE_TYPE,Supplier,DATE_OF_PURCHASE,
            RENTAL,MACHINE_SUBTYPE,LOCATION,BRAND,MOTOR_TYPE,SERIAL_NO, ASSET_NO,RFID,ATTACHMENT,
            MAINTENANCE_FREQ,LAST_MAINTENANCE,NEXT_MAINTENANCE,MAINTENANCE_STATUS,STATION,Active,STATUS,
            CANCEL, SAVE,
        } = dictionary.GET()
        return dictionary.TRANSLATE( {
            status,cannot_create_ticket_for_machine_with,
            PROBLEM,SOLUTION,REMARKS,MACHINE_NO,MODEL_NO,MACHINE_TYPE,Supplier,DATE_OF_PURCHASE,
            RENTAL,MACHINE_SUBTYPE,LOCATION,BRAND,MOTOR_TYPE,SERIAL_NO, ASSET_NO,RFID,ATTACHMENT,
            MAINTENANCE_FREQ,LAST_MAINTENANCE,NEXT_MAINTENANCE,MAINTENANCE_STATUS,STATION,Active,STATUS,
            CANCEL, SAVE,
        })
    }
    checkIfApiLoading() {
        let flag = checkIfApiLoading([
            this.props.machinesDetailLoading,
            this.props.problemLoading,
            this.props.ticketTypeLoading
        ]);
        return flag;
    }

    resetForm() {
        let resetForm = {
            problemTypeId: null,
            solutionTypeId: null,
            remarks: null,
            ticketNo: null
        }
        this.setState({ form: resetForm })
    }

    handleCancel = () => {
        this.resetForm();
        history.push(this.getBackLink());
    }



    submitForm = () => {
        let {languageProps} = this.state
        if (this.props.machineDetail.statusId_desc === 'INACTIVE' || this.props.machineDetail.statusId_desc === 'NOT AVAIL/RETIRED' || this.props.machineDetail.statusId_desc === 'RETIRED') {
            this.props.notificationError(languageProps.cannot_create_ticket_for_machine_with + ' ' + this.props.machineDetail.statusId_desc + languageProps.status);
            return false;
        }
        let payload = {
            ...this.state.form,
            machineId: this.state.id,
            reportedDt: moment(),
        };
        let formValidation = validateFields(payload);
        this.setState({ formErrors: formValidation.errors });
        if (formValidation.valid) {
            this.props.postNewTicket(payload);
        }
        else {
            this.props.notificationError(formValidation.errors[0].message);
        }
    }

    renderProblemItems() {
        let problemList = this.props.problemList.map((item) => {
            return <MenuItem key={item.id} value={item.id}>{item.desc}</MenuItem>
        });
        return problemList;
    }

    renderSolutionItems(){
        let solutionList = this.props.solutionList.map((item) => {
            return <MenuItem key={item.id} value={item.id}>{item.desc1}</MenuItem>
        })
        return solutionList;
    }

    renderTicketTypeItems() {
        if (this.props.ticketTypeLoading === false) {
            let ticketTypeList = this.props.ticketTypeList.map((item) => {
                return <MenuItem key={item.id} value={item.id}>{item.desc}</MenuItem>
            });
            return ticketTypeList;
        }
    }

    renderChecklistItems() {
        if (this.props.machineDetail) {
            let checklist = this.props.machineChecklist.map((item) => {
                return <MenuItem key={item.id} value={item.checkListId}>{item.templateName}</MenuItem>
            });
            return checklist;
        }
    }

    renderText(field) {
        if (field === null || field === '') {
            return '-'
        }
        else {
            return field
        }
    }

    renderArea(field) {
        if (this.props.machineDetail){
            if (this.props.machineDetail.mfgLineId_desc){
                return this.renderText(this.props.machineDetail.mfgLineId_desc + ' - ' + this.props.machineDetail.station);
            }
            else{
                return this.renderText(this.props.machineDetail.areaId_desc);
            }
        }
        else{
            return this.renderText(null);
        }
    }

    renderTextWithCapitalization(field) {
        if (field === null || field === '' || field === 'null') {
            return '-'
        }
        else {
            let capitalized = field.split(' ').map(item =>
                item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(' ');
            return capitalized
        }
    }

    renderMaintenFreq(field) {
        if (field === null || field === '') {
            return '-'
        }
        else if (field === 'yearly') {
            return 'Yearly'
        } else if (field === 'MO') {
            return 'MO'
        } else {
            return field
        }
    }

    renderCheckbox(check) {
        if (check === 1 || check === true) {
            return 'Yes'
        }
        else {
            return 'No'
        }
    }

    renderDate(date) {
        if (date !== null) {
            return moment(date).format('DD-MM-YYYY');
        }
        else {
            return '-';
        }
    }

    handleFormChange = ({ target }) => {
        let tmpForm = this.state.form;
        tmpForm[target.name] = target.value;
        this.setState({ form: tmpForm }, () => {
            this.props.getSolutionsByProblem(this.state.form.problemTypeId);
        });
        this.removeErrorOnChange(target.name);
    };

    removeErrorOnChange(field) {
        let errors = this.state.formErrors.filter((item) => { return item.name !== field });
        this.setState({ formErrors: errors });
    }

    hasErrors = (field) => {
        let checkField = this.state.formErrors.filter((item) => { return item.name === field });
        if (checkField.length > 0) {
            return true
        }
        else {
            return false;
        }
    }

    getErrors = (field) => {
        let checkField = this.state.formErrors.filter((item) => { return item.name === field });
        let errorString = null;
        checkField.map((item) => {
            errorString += item.message + '. ';
            return '';
        })
        return errorString;
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.setState({ id: this.props.match.params.id });
            this.props.getMachineById(this.props.match.params.id);
            this.props.getTicketProblems(this.props.match.params.id);
            this.props.getTicketTypes();
        }
    }

    componentDidUpdate() {
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                languageProps: Object.assign({}, { ...this.changeLang() })
            })
        }
    }
    content() {
        let { languageProps } = this.state
        if (this.checkIfApiLoading() === false) {
            return (
                <div>
                    <Grid className="form-component" container spacing={1}>
                        <FormControl fullWidth component="form">
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormGroup>
                                        {/* <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={6}>
                                            <span className="form-label required">Ticket Type</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <Select error={ this.hasErrors('ticketTypeId') } onChange={this.handleFormChange} name="ticketTypeId" value={this.state.form.ticketTypeId} variant="outlined" className="form-select">
                                                    { this.checkIfApiLoading() === false ? this.renderTicketTypeItems() : null }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid> */}
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={3}>
                                                <span className="form-label required">{languageProps.PROBLEM}</span>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth>
                                                    <Select disabled={this.props.problemList.length <= 0} error={this.hasErrors('problemTypeId')} onChange={this.handleFormChange} name="problemTypeId" value={this.state.form.problemTypeId} variant="outlined" className="form-select">
                                                        <MenuItem value="null">Problem unknown</MenuItem>
                                                        {this.checkIfApiLoading() === false ? this.renderProblemItems() : null}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={3}>
                                                <span className="form-label">{languageProps.SOLUTION}</span>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth>
                                                    <Select disabled={this.props.solutionList.length <= 0} error={this.hasErrors('solutionTypeId')} onChange={this.handleFormChange} name="solutionTypeId" value={this.state.form.solutionTypeId} variant="outlined" className="form-select">
                                                        {this.checkIfApiLoading() === false ? this.renderSolutionItems() : null}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        {/* <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={3}>
                                            <span className="form-label required">{'Solution'.translate()}</span>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth>
                                                <Select disabled={ true } error={ this.hasErrors('solutionId') } onChange={this.handleFormChange} name="solutionId" variant="outlined" className="form-select">
                                                    
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid> */}
                                        {/* <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={6}>
                                            <span className="form-label required">Checklist</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <Select error={ this.hasErrors('checkListId') } onChange={this.handleFormChange} name="checkListId" value={this.state.form.checkListId} variant="outlined" className="form-select">
                                                    { this.checkIfApiLoading() === false ? this.renderChecklistItems() : null }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid> */}
                                        {/* <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={6}>
                                            <span className="form-label required">Ticket No</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField error={ this.hasErrors('ticketNo') } onChange={this.handleFormChange} name="ticketNo" value={this.state.form.ticketNo} fullWidth variant="outlined" />
                                        </Grid>
                                    </Grid> */}
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={3}>
                                                <span className="form-label">{languageProps.REMARKS}</span>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField className="textarea" multiline rows={2} rowsMax={2} error={this.hasErrors('remarks')} onChange={this.handleFormChange} name="remarks" value={this.state.form.remarks} fullWidth variant="outlined" />
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>

                    <Grid className="form-component" container spacing={1}>
                        <FormControl fullWidth component="fieldset">
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormGroup>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs>
                                                <span className="form-label">{languageProps.MACHINE_NO}</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span className="value-label"><strong>{this.props.machineDetail ? this.renderText(this.props.machineDetail.machine) : null}</strong></span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs>
                                                <span className="form-label">{languageProps.MODEL_NO}</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span className="value-label">{this.props.machineDetail ? this.renderText(this.props.machineDetail.model) : null}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs>
                                                <span className="form-label">{languageProps.MACHINE_TYPE}</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span className="value-label">{this.props.machineDetail ? this.renderTextWithCapitalization(this.props.machineDetail.macTypeId_desc) : null}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs>
                                                <span className="form-label">{languageProps.Supplier}</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span className="value-label">{this.props.machineDetail ? this.renderText(this.props.machineDetail.supplierId_desc) : null}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs>
                                                <span className="form-label">{languageProps.DATE_OF_PURCHASE}</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span className="value-label">{this.props.machineDetail ? this.renderDate(this.props.machineDetail.aquisitionDt) : null}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs>
                                                <span className="form-label">{languageProps.RENTAL}</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span className="value-label">{this.props.machineDetail ? this.renderCheckbox(this.props.machineDetail.isRental) : null}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs>
                                                <span className="form-label">{languageProps.MACHINE_SUBTYPE}</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span className="value-label">{this.props.machineDetail ? this.renderText(this.props.machineDetail.macSubTypeId_desc) : null}</span>
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormGroup>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs>
                                                <span className="form-label">{languageProps.LOCATION}</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span className="value-label"><strong>{this.props.machineDetail ? this.renderArea() : null}</strong></span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs>
                                                <span className="form-label">{languageProps.BRAND}</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span className="value-label">{this.props.machineDetail ? this.renderTextWithCapitalization(this.props.machineDetail.brandId_desc) : null}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs>
                                                <span className="form-label">{languageProps.MOTOR_TYPE}</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span className="value-label">{this.props.machineDetail ? this.renderText(this.props.machineDetail.motorTypeId_desc) : null}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs>
                                                <span className="form-label">{languageProps.SERIAL_NO}</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span className="value-label">{this.props.machineDetail ? this.renderText(this.props.machineDetail.serialNo) : null}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs>
                                                <span className="form-label">{languageProps.ASSET_NO}</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span className="value-label">{this.props.machineDetail ? this.renderText(this.props.machineDetail.assetNo) : null}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs>
                                                <span className="form-label">{languageProps.RFID}</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span className="value-label">{this.props.machineDetail ? this.renderText(this.props.machineDetail.rfid) : null}</span>
                                            </Grid>
                                        </Grid>

                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs>
                                                <span className="form-label">{languageProps.ATTACHMENT}</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span className="value-label">{this.props.machineDetail ? this.renderText(this.props.machineDetail.attachmentId_desc) : null}</span>
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormGroup>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs>
                                                <span className="form-label">{languageProps.MAINTENANCE_FREQ}</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span className="value-label"><strong>{this.props.machineDetail ? this.renderMaintenFreq(this.props.machineDetail.frequencyId_desc) : null}</strong></span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs>
                                                <span className="form-label">{languageProps.LAST_MAINTENANCE}</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span className="value-label">{this.props.machineDetail ? this.renderDate(this.props.machineDetail.lastPMDt) : null}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs>
                                                <span className="form-label">{languageProps.NEXT_MAINTENANCE}</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span className="value-label">{this.props.machineDetail ? this.renderDate(this.props.machineDetail.pmPlanDt) : null}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs>
                                                <span className="form-label">{languageProps.STATION}</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span className="value-label">{this.props.machineDetail ? this.renderText(this.props.machineDetail.station) : null}</span>
                                            </Grid>
                                        </Grid>
                                        {/* <Grid className="form-control" container spacing={1}>
                                            <Grid item xs>
                                                <span className="form-label">{languageProps.Active}</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span className="value-label">{this.props.machineDetail ? this.renderCheckbox(this.props.machineDetail.isActive) : null}</span>
                                            </Grid>
                                        </Grid> */}
                                        {/* <Grid className="form-control" container spacing={1}>
                                        <Grid item xs>
                                            <span className="form-label">{'Condition'.translate()}</span>
                                        </Grid>
                                        <Grid item xs>
                                            <span className="value-label">{ this.props.machineDetail ? this.renderText(this.props.machineDetail.conditionId_desc) : null }</span>
                                        </Grid>
                                    </Grid> */}
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs>
                                                <span className="form-label">{languageProps.STATUS}</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span className="value-label">{this.props.machineDetail ? this.renderText(this.props.machineDetail.statusId_desc) : null}</span>
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                    <div className="button-group">
                        <Button onClick={this.handleCancel} variant="contained">{languageProps.CANCEL}</Button>
                        <Button
                            onClick={this.submitForm}
                            disabled={this.props.createRepairTicketLoading}
                            variant="contained"
                            color="primary">
                            {this.props.createRepairTicketLoading ? <CircularProgress size={32} style={{ color: 'grey' }}/> : languageProps.SAVE }
                        </Button>
                    </div>
                </div>
            );
        }
        else {
            return null;
        }
    }

    getBackLink() {
        if (localStorage.getItem('redirectToTickets') === 'true') {
            return '/tickets';
        }
        else {
            return "/machine-detail/" + this.state.id;
        }
    }

    render() {
        return (
            <MainPage className="ticket-form">
                <MainPageHeader title="Tickets" search={false} back={this.getBackLink()} />
                <MainPageBody>
                    {this.content()}
                </MainPageBody>
            </MainPage>
        );
    }
}
const mapStateToProps = state => {
    let { translationReducer } = state
    let apiLoading = {
        machinesDetailLoading: state.machineListReducer.viewDetailLoading,
        machinesLoading: state.machineListReducer.loading,
        problemLoading: state.problemListReducer.loading,
        solutionLoading: state.solutionListReducer.loading,
        ticketTypeLoading: state.ticketListReducer.loadingType,
        createRepairTicketLoading: state.ticketListReducer.loading,
    }
    let data = {
        machineDetail: state.machineListReducer.machineDetail,
        machineChecklist: state.machineListReducer.machineChecklist,
        machineTemplate: state.machineListReducer.machineTemplate,
        ticketTypeList: state.ticketListReducer.ticketTypes,
        problemList: state.problemListReducer.problemList,
        solutionList: state.solutionListReducer.solutionList,
    }
    return {
        ...apiLoading,
        ...data,
        ...translationReducer
    }

}

const mapDispatchToProps = dispatch => {
    return {
        getMachineById: (id) => dispatch(getMachineById(id)),
        getProblems: () => dispatch(getProblems()),
        getSolutionDetail: (id) => dispatch(getSolutionDetail(id)),
        getSolutionsByProblem: (id) => dispatch(getSolutionsByProblem(id)),
        getTicketProblems: (id) => dispatch(getTicketProblems(id)),
        getTicketTypes: () => dispatch(getTicketTypes()),
        postNewTicket: (payload) => dispatch(postNewTicket(payload)),
        notificationError: (message) => dispatch(notificationError(message)),
    }
}

TicketForm = connect(mapStateToProps, mapDispatchToProps)(TicketForm);

export default TicketForm;
