import React, { useEffect, useState } from 'react';
import {
  Modal,
  Typography,
  Paper,
  InputLabel,
  FormGroup,
  Select,
  MenuItem,
  Button
} from '@material-ui/core';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { referenceClient, machineClient, globalAdminClient } from '../../../utils/api/CoreApi';
const useStyles = makeStyles({
  paper: {
    position: 'absolute',
    width: 400,
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    padding: 30
  },
  formGroup: {
    marginTop: "1rem",
    marginBottom: "1rem"
  },
  actions: {
    paddingTop: 15,
    paddingBottom: 15,
    justifyContent: 'space-around',
    display: "flex"
  },
  errorMessage: {
    color: "#D33131"
  }
});

const ALL_RECORDS = 1000;

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export default function MovingModal(props){
  const classes = useStyles();
  const { open, onClose, machines, onSuccess } = props;
  const [lines, setLines] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedLine, setSelectedLine] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const selectedMachines = machines.filter((item) => item.selected.value === true);
  const hasSelectedMachines = selectedMachines.length > 0;
  const isMuliLine = selectedMachines.map((item)=> item.machine.mfgLineId).filter(onlyUnique).length >= 2;
  const isMovingLine = !isMuliLine && selectedMachines.every((item)=> item.machine.mfgLineId)
  const hasLines = lines.length > 0;
  const isMovingArea = !isMovingLine;


  useEffect(()=>{
    globalAdminClient.get("ref/areas").then(({ data })=>{
      setAreas(data.data);
    });
  }, []);

  useEffect(()=>{
    setErrorMessage(null);
    setSelectedLine(null);
    if(selectedArea){
      globalAdminClient.get(`ref/areas/${selectedArea}/lines`).then(({ data })=>{
        setLines(data.data);
      });
    }
  }, [selectedArea])

  const handleSubmit = ()=>{
    const isLineSelected = Boolean(selectedLine);
    if(isMovingLine && hasLines && !isLineSelected){
      setErrorMessage("Must select line")
      return
    }
    machineClient.put("/machines/move-to", {
      machineIds: selectedMachines.map((m)=> m.machine.id),
      lineId: selectedLine,
      areaId: selectedArea
    })
    .then(()=>{
      onSuccess();
      onClose();
    })
    .catch((err)=>{
      const { data } = err.response;
      setErrorMessage(data.error);
    })
  }

  return <Modal
    open={open}
    onClose={onClose}
  >
    <Paper data-cy="moving-modal" className={classes.paper}>
      {
        hasSelectedMachines ? (
          <React.Fragment>
            <h2>
              Move Machine
            </h2>
            <FormGroup className={classes.formGroup}>
              <InputLabel>Current Location</InputLabel>
              <Typography>
                { selectedMachines.map((m)=> isMovingArea ?  m.machine.areaId_desc : m.machine.mfgLineId_desc ).filter((l)=> l).filter(onlyUnique).join(", ") }
              </Typography>
            </FormGroup>

            <FormGroup className={classes.formGroup}>
              <InputLabel>{"Move To Area".translate()}</InputLabel>
              <Select
                data-testid="area-select"
                value={selectedArea}
                onChange={(e)=> setSelectedArea(e.target.value)}
              >
                {
                  areas.map((area)=>(
                    <MenuItem key={area.id} value={area.id}>
                      {area.desc1}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormGroup>

            {
              isMovingLine ? (
                <FormGroup className={classes.formGroup}>
                  <InputLabel>{"Move To Line".translate()}</InputLabel>
                  <Select
                    value={hasLines ? selectedLine : "no-line"}
                    onChange={(e)=> setSelectedLine(e.target.value)}
                    disabled={!hasLines}
                  >
                    {
                      hasLines ? (
                        lines.map((line)=>(
                          <MenuItem key={line.id} value={line.id}>
                            {line.desc1}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value={"no-line"}>
                          - No Line Available -
                        </MenuItem>
                      )

                    }
                  </Select>
                </FormGroup>
              ) : null
            }
            {
              errorMessage ? (
                <div className={classes.errorMessage}>{errorMessage}</div>
              ) : null
            }
            <div className={classes.actions}>
              <Button
                variant="outlined"
                color="primary"
                onClick={()=> onClose()}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={()=> handleSubmit()}
              >
                Move
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h2>
              Please select some machines
            </h2>
            <div className={classes.actions}>
              <Button
                variant="outlined"
                color="primary"
                onClick={()=> onClose()}
              >
                Close
              </Button>
            </div>
          </React.Fragment>
        )
      }

    </Paper>
  </Modal>
}
