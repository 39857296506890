import React from 'react';
import { connect } from 'react-redux';
import { deleteMacType } from '../../../../../redux/actions/MacTypes/MacTypeActions'
import { confirmAlert } from 'react-confirm-alert';
import history from '../../../../../history';
import Table from '../../../../../components/CollapsableTable/CollapseTable'
import { getAllMacSubTypes,getAllMacSubTypesTreeView, getAllMacSubTypesTreeViewSubtype, deleteMacSubType } from '../../../../../redux/actions/MacSubTypes/MacSubTypeActions'
import dictionary from '../../../../../redux/actions/Translation/dictionary'
import { checkPageAccessField } from '../../../../../utils/helper';
import './SubTypes.scss';

class SubTypes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            translations: {
                ...this.convert()
            },
            translationTimestamp: undefined,
            tableState: {
                add: (macTypeId) => {
                    localStorage.setItem('redirectConfigSubType', 'true');
                    history.push(`/configuration/machine-config/machine-sub-type/new/${macTypeId}`)
                },
                edit: ({ id, macTypeId }) => {
                    localStorage.setItem('redirectConfigSubType', 'true');
                    history.push(`/configuration/machine-config/machine-sub-type/edit/${macTypeId}/${id}`)
                },
                remove: ({ id, macTypeId }) => {
                    confirmAlert({
                        title: this.state?.translations?.are_you_sure_delete_machine_subtype,
                        message: '',
                        buttons: [
                            {
                                label: this.state?.translations?.YES,
                                onClick: () => {
                                    this.props.deleteMacSubType(id);
                                }
                            },
                            {
                                label: this.state?.translations?.NO,
                            }
                        ]
                    });
                },
                search: true,
                keys: this.initTableHeader(),
                data: [],
                highlight: true,
                loading: false,
                timeStamp: undefined
            },
            defaultLoaded: false
        }
    }

    resetRedirect() {
        localStorage.removeItem('redirectConfigSubType');
    }

    getDefaultMactype(){
        if (this.props.macSubTypeListTree !== [] && this.props.macSubTypeListTree !== undefined){
            if (this.props.macSubTypeListTree.length > 0){
                let defaultMacType = this.props.macSubTypeListTree.filter((item) => { return item.details.default === true });
                if (defaultMacType.length > 0){
                    return defaultMacType[0].details.id;
                }
                else{
                    return null
                }
            }
            else{
                return null;
            }
        }
        else{
            return null;
        }
    }

    /**handler */
    initTableHeader = () => {
        let { STATUS, MACHINE_SUBTYPE_CODE, DISPLAY_NAME, DESCRIPTION, UPDATED_DATE } = this.convert()
        return [
            {
                name: MACHINE_SUBTYPE_CODE,
                key: 'subType',
                tableHeaderWidth: '16%'

            },
            {
                name: DISPLAY_NAME,
                key: 'desc1',
                tableHeaderWidth: '15%'

            },
            {
                name: DESCRIPTION,
                key: 'desc2',
                tableHeaderWidth: '20%'

            },
            {
                name: STATUS,
                type: 'boolean',
                key: 'isActive',
                tableHeaderWidth: '10%'

            },
            {
                name: UPDATED_DATE,
                type: 'date',
                key: 'updatedDt',
                alt: 'createdDt',
                tableHeaderWidth: '10%'

            },
        ]
    }
    convert = () => {
        let { YES, NO, NOTIFICATIONS: { are_you_sure_delete_machine_subtype },STATUS, MACHINE_SUBTYPE_CODE, DISPLAY_NAME, DESCRIPTION, UPDATED_DATE } = dictionary.GET()
        return dictionary.TRANSLATE({ YES, NO, are_you_sure_delete_machine_subtype,STATUS, MACHINE_SUBTYPE_CODE, DISPLAY_NAME, DESCRIPTION, UPDATED_DATE  })
    }
    componentDidMount() {
        this.props.getAllMacSubTypesTreeViewSubtype();
        this.resetRedirect();
    }

    componentDidUpdate() {
        // console.log('didUpdate', this.props, this.state)
        let { loading, macSubTypeListTreeDefault, macSubTypeListTree, macSubTypeListTreeDefaultTimeStamp } = this.props
        let { loading: stateLoading, data, timeStamp } = this.state.tableState
        if (loading != stateLoading || macSubTypeListTreeDefault?.length != data?.length || macSubTypeListTreeDefaultTimeStamp != timeStamp) {
            if(Array.isArray(macSubTypeListTreeDefault)){
                let tableState = this.state.tableState;
                if (checkPageAccessField(this.props.userAccess,{pageId:20,pageAccess: 'VIEW'})){
                    tableState = {
                        add: null,
                        edit: null,
                        remove: null
                    }
                }
                this.setState({
                    tableState: {
                        ...tableState,
                        data: [...macSubTypeListTreeDefault],
                        loading,
                        timeStamp: macSubTypeListTreeDefaultTimeStamp
                    }
                })
            }
        }
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                translations: Object.assign({}, { ...this.convert() }),
                tableState: {
                    ...this.state.tableState,
                    keys: this.initTableHeader(),
                    timeStamp: new Date()
                }
            })
        }
        if (macSubTypeListTree.length > 0 && this.state.defaultLoaded === false) {
            if(Array.isArray(macSubTypeListTree)){
                let tableState = this.state.tableState;
                if (this.getDefaultMactype() !== undefined && this.getDefaultMactype() !== null){
                    this.setState({
                        tableState: {
                            ...tableState,
                            customReturnKey: {
                                key:'macTypeId',
                                value: this.getDefaultMactype()
                            },
                        },
                        defaultLoaded: true
                    })
                }
                else{
                    this.setState({
                        tableState: {
                            ...tableState,
                            add: null
                        },
                    })
                }
            }
        }
    }

    componentWillUnmount() {
    }

    /**render */
    render() {
        return (
            <div className="subtype-body-overview">
                <Table {...this.state.tableState} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    let { macSubTypeListReducer, translationReducer } = state
    let apiLoading = {
    }
    let data = {
        userAccess: state.authReducer.access,
        macSubTypeListTree: state.macSubTypeListReducer.macSubTypeListTree
    }

    return {
        ...apiLoading,
        ...data,
        ...macSubTypeListReducer,
        ...translationReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllMacSubTypesTreeViewSubtype: (ev) => dispatch(getAllMacSubTypesTreeViewSubtype(ev)),
        deleteMacType: (id) => dispatch(deleteMacType(id)),
        deleteMacSubType: (id) => dispatch(deleteMacSubType(id))
    }
}

SubTypes = connect(mapStateToProps, mapDispatchToProps)(SubTypes);

export default SubTypes;
