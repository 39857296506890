import {
    MENU_DRAWER_OPEN,
    MENU_DRAWER_CLOSE
} from '../../actions/actionTypes';

const initialState = {
    open: false,
}

const menuDrawerReducer = (state = initialState, action) => {
    switch (action.type) {
        case MENU_DRAWER_OPEN:
            return {
                ...state,
                open: action.payload.open,
            }
        case MENU_DRAWER_CLOSE:
            return {
                ...state,
                open: action.payload.open,
            }
        default: 
            return state;
    }
}

export default menuDrawerReducer;