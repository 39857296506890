import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import MatricCard from './components/MatricCard';
import { getDateRangeQueryString } from './helpers';
import qs from "qs";
import {
  client,
  fetchStatistics
} from './api';
import { minuteToTime } from '../../../utils/date';

export default function StatisticCard(props){
  const { filters, isCron } = props;
  const [statistics, setStatistics] = useState({
    avgRepairTime: 0,
    avgResponseTime: 0,
    ticketTypesCount: [],
    closeTicketTypesCount: [],
    completedTicketMaintenanceTime: {},
    totalNPT: 0,
    countImpactedLines: 0,
  });

  useEffect(()=>{
    if(isCron) return;
    fetchStatistics({ 
      filters: getDateRangeQueryString(filters) 
    }).then((result)=>{
      setStatistics(result.statistics.data)
    })
  }, [filters])

  const {
    ticketTypesCount,
    closeTicketTypesCount,
    avgRepairTime,
    avgResponseTime,
    totalNPT,
    countImpactedLines,
    completedTicketMaintenanceTime,
  } = statistics;

  const coTypeCount = ticketTypesCount.find((type)=> type.ticketType == 'Changeover') || {};
  const closedTicketTypeCountMaintenance = closeTicketTypesCount.find((type)=> type.ticketType == 'Maintenance') || {};
  const closedTicketTypeCountRepair = closeTicketTypesCount.find((type)=> type.ticketType == 'Repair') || {};
  
  return <Grid container spacing={1}>
    <Grid item xs={2}>
      <MatricCard
        name={'Changeover Tickets'.translate()}
        value={parseInt(coTypeCount.totalTickets || 0)}
        subValue={`${parseInt(coTypeCount.machinePrepared || 0)} ${'Machine Prepared'.translate()}`}
      />
    </Grid>

    <Grid item xs={2}>
      <MatricCard
        name={'Completed Maint. Tickets'.translate()}
        value={`${completedTicketMaintenanceTime?.totalTickets || 0}`}
        subValue={parseInt(Math.ceil(completedTicketMaintenanceTime?.totalTime)) || '0'}
        subUnit={"Working min".translate()}
      />
    </Grid>

    <Grid item xs={2}>
      <MatricCard
        name={'Closed Repair Tickets'.translate()}
        value={parseInt(closedTicketTypeCountRepair.totalTickets) || '0'}
        subValue={parseInt(Math.ceil(closedTicketTypeCountRepair.totalTime)) || '0'}
        subUnit={"min".translate()}
      />
    </Grid>

    <Grid item xs={2}>
      <MatricCard
        name={"AVG. Response Time".translate()}
        value={parseInt((Math.ceil(avgResponseTime)) || '0')}
        unitType={`${'min'.translate()}/${'Repair Ticket'.translate()}`}
      />
    </Grid>

    <Grid item xs={2}>
      <MatricCard
        name={"AVG. Repair Time".translate()}
        value={parseInt((Math.ceil(avgRepairTime)) || '0')}
        unitType={`${'min'.translate()}/${'Repair Ticket'.translate()}`}
      />
    </Grid>
    <Grid item xs={2}>
      <MatricCard
        type="danger"
        name={"Total NPT".translate()}
        value={minuteToTime(totalNPT || '0')}
        subValue={`${countImpactedLines} ${'Impacted Lines'.translate()}`}
      />
    </Grid>
  </Grid>


}
