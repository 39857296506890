import { Table } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const MuiTable = withStyles((theme) => ({
  root: {
    borderCollapse: 'separate',
    borderSpacing: '0',
  },
}))(Table);

export default MuiTable;
