import {
    BUILDING_LIST_REQUEST,
    BUILDING_LIST_SUCCESS,
    BUILDING_LIST_ERROR,
    BUILDING_DETAIL_REQUEST,
    BUILDING_DETAIL_SUCCESS,
    BUILDING_DETAIL_ERROR,
    BUILDING_NEW_REQUEST,
    BUILDING_NEW_SUCCESS,
    BUILDING_NEW_ERROR,
    BUILDING_UPDATE_REQUEST,
    BUILDING_UPDATE_SUCCESS,
    BUILDING_UPDATE_ERROR,
    BUILDING_TRIGGER_HIGHLIGHT,
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    detailLoading: false,
    triggerHighlight: false,
    closeModal: false,
    buildingList:[],
    buildingDetail: null,
    meta: [],
    error: ''
}

const buildingListReducer = (state = initialState, action) => {
    switch (action.type) {
        case BUILDING_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case BUILDING_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                buildingList: action.payload.buildingList,
                meta: action.payload.meta,
                error: ''
            }
        case BUILDING_LIST_ERROR:
            return {
                ...state,
                loading: false,
                buildingList: [],
                meta: [],
                error: action.payload.message
            }
        case BUILDING_DETAIL_REQUEST:
            return {
                ...state,
                detailLoading: true,
            }
        case BUILDING_DETAIL_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                buildingDetail: action.payload.buildingDetail
            }
        case BUILDING_NEW_REQUEST:
            return {
                ...state,
                detailLoading: true,
                closeModal: false
            }
        case BUILDING_NEW_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                closeModal: true
            }
        case BUILDING_UPDATE_REQUEST:
            return {
                ...state,
                detailLoading: true,
                closeModal: false
            }
        case BUILDING_UPDATE_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                closeModal: true
            }
        case BUILDING_TRIGGER_HIGHLIGHT:
            return {
                ...state,
                triggerHighlight: action.payload,
                closeModal: false,
            }
        default: 
            return state;
    }
}

export default buildingListReducer;