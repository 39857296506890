import {
    NOTIFICATION_SUCCESS,
    NOTIFICATION_ERROR,
    AREA_LIST,
    AREA_LIST_REQUEST,
    AREA_LIST_SUCCESS,
    AREA_LIST_ERROR,
    AREA_DETAIL_REQUEST,
    AREA_DETAIL_SUCCESS,
    AREA_DETAIL_ERROR,
    AREA_NEW_REQUEST,
    AREA_NEW_SUCCESS,
    AREA_NEW_ERROR,
    AREA_UPDATE_REQUEST,
    AREA_UPDATE_SUCCESS,
    AREA_DELETE_REQUEST,
    AREA_DELETE_SUCCESS,
    AREA_UPDATE_ERROR,
    AREA_TRIGGER_HIGHLIGHT,
} from '../actionTypes';
import { tokenGet, tokenPost, tokenPut, tokenDelete, apiSettings } from '../../../utils/api/CoreApi';
import setters from '../../actions/Factory/factoryActions'
import history from '../../../history';

export const notificationSuccess = (message) => {
    return {
        type: NOTIFICATION_SUCCESS,
        payload: {
            message: message.translate()
        }
    }
}

export const notificationError = (message) => {
    return {
        type: NOTIFICATION_ERROR,
        payload: {
            message: message.translate()
        }
    }
}

export const areaRequest = () => {
    return {
        type: AREA_LIST_REQUEST
    }
}

export const areaSuccess = areaList => {
    return {
        type: AREA_LIST_SUCCESS,
        payload: areaList
    }
}

export const areaDetailRequest = () => {
    return {
        type: AREA_DETAIL_REQUEST
    }
}

export const areaDetailSuccess = areaDetail => {
    return {
        type: AREA_DETAIL_SUCCESS,
        payload: areaDetail
    }
}

export const areaNewRequest = () => {
    return {
        type: AREA_NEW_REQUEST
    }
}

export const areaNewSuccess = areaList => {
    return {
        type: AREA_NEW_SUCCESS,
        payload: areaList
    }
}

export const areaUpdateRequest = () => {
    return {
        type: AREA_UPDATE_REQUEST
    }
}

export const areaUpdateSuccess = areaList => {
    return {
        type: AREA_UPDATE_SUCCESS,
        payload: areaList
    }
}

export const areaDeleteRequest = () => {
    return {
        type: AREA_DELETE_REQUEST
    }
}

export const areaDeleteSuccess = areaList => {
    return {
        type: AREA_DELETE_SUCCESS,
        payload: areaList
    }
}


export const areaTriggerHighlight = (flag) => {
    return {
        type: AREA_TRIGGER_HIGHLIGHT,
        payload: flag
    }
}   

export const getAreaDetail = (id) => {
    return function (dispatch) {
        dispatch(areaDetailRequest());
        tokenGet(apiSettings.apiURLReferences + '/areas/' + id).then((response) => {    
            dispatch(areaDetailSuccess({
                areaDetail: response.data.machine[0],
            }));
        })
            .catch((error) => {
                console.log(error);
            });
    }
}

export const postAreaNew = (payload,factoryId) => {
    let notif = notificationSuccess('Successfully added area')
    return function (dispatch) {
        dispatch(areaNewRequest());
        tokenPost(apiSettings.apiURLReferences + '/areas', payload).then(async (response) => {
            dispatch(areaNewSuccess({
                area: response.data
            }));
            await setters().getFactoryTreeViewById(payload.factoryId, notif)
        })
        .catch((error) => {
            if (error.message.indexOf(500) >= 0){
                dispatch(notificationError('Area code exists'));
                dispatch(areaTriggerHighlight(true));
            }
            console.log(error);
        });
    }
}

export const putAreaUpdate = (id, payload,factoryId) => {
    let notif = notificationSuccess('Successfully updated area')
    return function (dispatch) {
        dispatch(areaUpdateRequest());
        tokenPut(apiSettings.apiURLReferences + '/areas/' + id, payload).then(async (response) => {
            dispatch(areaUpdateSuccess({
                area: response.data
            }));
            await setters().getFactoryTreeViewById(payload.factoryId, notif)
        })
            .catch((error) => {
                console.log(error);
                if (error.message.indexOf(500) >= 0){
                    dispatch(notificationError('Area code exists'));
                    dispatch(areaTriggerHighlight(true));
                }
                else if (error.message.indexOf(422) >= 0){
                    dispatch(notificationError("Cannot deactivate. There are machine(s) currently attached to the line"));
                }
            });
    }
}

export const deleteArea = (id,factoryId) => {
    let notif = notificationSuccess('Successfully deleted area type')
    return function (dispatch) {
        dispatch(areaDeleteRequest());
        tokenDelete(apiSettings.apiURLReferences + '/areas/' + id).then(async (response) => {
            dispatch(areaDeleteSuccess({
                area: response.data
            }));
            await setters().getFactoryTreeViewById(factoryId, notif);
        })
            .catch((error) => {
                if (error.message.indexOf(500) >= 0 || error.message.indexOf(422) >= 0){
                    dispatch(notificationError('Cannot delete because it is currently referenced to another record'));
                }
                console.log(error);
            });
    }
}