import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  barRow:{
    cursor:'pointer',
    backgroundColor: '#F2F2F2',
  },
  arrow:{
    fontSize: '2.5rem',
    color: '#0F75BC',
  },
  title:{
    fontWeight:'800',
    paddingLeft: '20px',
  }
}));

const listTitle = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiTypography: {
      // Name of the rule
      displayBlock: {
        // Some CSS
        fontWeight: '800',
        paddingLeft: '10px',
      },
    },
  },
});

const listItems = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiTypography: {
      // Name of the rule
      displayBlock: {
        // Some CSS
        paddingLeft: '57px',
      },
    },
  },
});

export default function ListDividers(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const numberCount = props.numberCount
  const task = '    ' + props.task
  const subSteps = props.subSteps
  const tasksDetailed = props.tasksDetailed
  console.log('check count ' + numberCount)
  console.log('check name ' + task)
  console.log(subSteps)
  console.log(tasksDetailed)

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const lessTenChecker = (value) => {
    if(value < 10){
      return '0' + value
    }else{
      return value
    }
  };

  const zeroChecker = (value) => {
    if(value > 0){
      return task + ' (' + subSteps + ')'
    }else{
      return task
    }
  };

  const renderDropdown = (checked, tasksDetailed) => {
      if (tasksDetailed.length > 0){
         return (
          checked ? (<ArrowDropUpIcon className={classes.arrow}></ArrowDropUpIcon>
            ): (<ArrowDropDownIcon className={classes.arrow}></ArrowDropDownIcon>)
         )
      }
  }

  return (
    <List component="nav" className={classes.root} aria-label="mailbox folders">
      <ListItem className={classes.barRow} onClick={handleChange}>
      <ThemeProvider theme={listTitle}>
        <span className={classes.title}>{lessTenChecker(numberCount)}</span>
        <span className={classes.title}>{zeroChecker(subSteps)}</span>
        <ListItemText primary={''} />
        </ThemeProvider>
        { renderDropdown(checked,tasksDetailed) }
      </ListItem>
      <Divider />
      <Collapse in={checked}>
      {tasksDetailed.map((item, itemIndex) => (
                              <ThemeProvider theme={listItems}>
                               <ListItem key={itemIndex}>
                               <ListItemText style={{fontSize: '14px'}} primary={item.subtask} />
                             </ListItem>
                             </ThemeProvider>
                    ))} 

      </Collapse>
    </List>
  );
}