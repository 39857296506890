
import { FACTORY_ACTIONS as actionList } from '../../actions/actionTypes'
const factory = {
    factoryAssigned: [],
    factoryProfile: null,
    factoryShift: [],
    factoryRowDetail: null,
    closeModal: false,
    factoryTimezones: [],
}
const factoryListReducer = (state = { factory}, action) => {
    switch (action.type) {
        case actionList.FACTORY_TREE_VIEW:
            return {
                ...state,
                ...action.payload
            }
        case actionList.FACTORY_PROFILE:
            return {
                ...state,
                factoryProfile: action.payload.factoryProfile,
                factoryShift: action.payload.factoryShift  
            }
        case actionList.FACTORY_ROW_DETAIL:
            return {
                ...state,
                factoryRowDetail: action.payload.factoryRowDetail    
            }
        case actionList.FACTORY_TREE_VIEW_BY_ID:
            return {
                ...state,
                ...action.payload
            }
        case actionList.FACTORY_NEW_REQUEST:
            return {
                ...state,
                closeModal: false
            }
        case actionList.FACTORY_NEW:
            return {
                ...state,
                closeModal: true
            }
        case actionList.FACTORY_UPDATE_REQUEST:
            return {
                ...state,
                closeModal: false
            }
        case actionList.FACTORY_UPDATE:
            return {
                ...state,
                closeModal: true
            }
        case actionList.FACTORY_TIMEZONE:
            return {
                ...state,
                factoryTimezones: action.payload
            }
        case actionList.FACTORY_ASSIGNED:
            return {
                ...state,
                factoryAssigned: action.payload
            }
        default:
            return state
    }
}
export default factoryListReducer