import React from 'react';
import { 
    Box, 
    Typography, 
    withStyles, 
} from "@material-ui/core";

function LegendInRepair({ isTvMode, duration = 0 }) {
    return (
        <Container style={{ bottom: isTvMode ? '85px' : '27px' }}>
            <FlexBox>
                <IndicatorContainer>
                    <LegendIndicator color='#D9001B' />
                    <Label>{`In-repair Over ${duration}min`}</Label>
                </IndicatorContainer>
            </FlexBox>
        </Container>
    );
}

const Container = withStyles({
    root: {
        position: 'absolute',
        width: '100%',
        left: 0,
        background: '#264963',
        padding: '10px 0'
    }
})(Box);

const Label = withStyles({
    root: {
        fontSize: 11,
        marginBottom: '5px',
        whiteSpace: 'nowrap',
    }
})(Typography);

const LegendIndicator = withStyles({
    root: (props) => ({
        backgroundColor: props.color,
        width: 16,
        height: 16,
        borderRadius: 2,
        marginLeft: props.marginLeft || 4,
        marginRight: props.marginRight || 4,
    }),
})(Box);

const IndicatorContainer = withStyles({
    root: (props) => ({
        display: 'flex',
        justifyContent: props.justifyContent || 'space-around',
        marginLeft: props.marginLeft || 10,
        marginRight: props.marginRight || 20,
    }),
})(Box);

const FlexBox = withStyles({
    root: (props) => ({
        display: 'flex',
        justifyContent: props.justifyContent || 'space-between',
        alignItems: 'center',
        gap: props.gap,
    }),
})((props) => <Box {...props} />);


export default LegendInRepair;