import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox, SvgIcon } from '@material-ui/core';

const RainBowCheckBox = (color)=>{
  return withStyles({
    root: {
      '&$checked': {
        color: color,
      },
      color: color,
      paddingTop: 2,
      paddingBottom: 2
    },
    checked: {},
  })((props) => <Checkbox
    icon={
      <SvgIcon>
        <path d="M 19 3 H 5 c -1.11 0 -2 0.9 -2 2 v 14 c 0 1.1 0.89 2 2 2 h 14 c 1.11 0 2 -0.9 2 -2 V 5 c 0 -1.1 -0.89 -2 -2 -2 z z"/>
      </SvgIcon>
    }
    color="default"
    {...props}
  />);
};

export default RainBowCheckBox;
