// this component build for new UI 
// it will replace MainPageHeader component
import React, { Fragment } from 'react';
import UserBlueHeader from '../UserBlueHeader/UserBlueHeader';
import PageBreadcrumbs from './PageBreadcrumbs';

export default function PageHeader({ breadcrumbs , ...props }) {
  return (
    <Fragment>
      <UserBlueHeader/>
      {
        breadcrumbs && (
          <PageBreadcrumbs breadcrumbs={breadcrumbs}/>
        )
      }
    </Fragment>
  );
}