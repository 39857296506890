import {
    MACSUBTYPE_LIST_REQUEST,
    MACSUBTYPE_LIST_SUCCESS,
    MACSUBTYPE_LIST_ERROR,
    MACSUBTYPE_ALL_LIST_REQUEST,
    MACSUBTYPE_ALL_LIST_SUCCESS,
    MACSUBTYPE_DETAIL_REQUEST,
    MACSUBTYPE_DETAIL_SUCCESS,
    MACSUBTYPE_LIST_TREE_VIEW,
    MACSUBTYPE_TRIGGER_HIGHLIGHT,
    MACSUBTYPE_LIST_TREE_VIEW_DEFAULT,
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    triggerHighlight: false,
    macSubTypeList: [],
    macSubTypeDetail: null,
    macSubTypeListTree: [],
    allMacSubTypeList: [],
    macSubTypeListTreeDefault: [],
    meta: [],
    error: ''
}

const macSubTypeListReducer = (state = initialState, action) => {
    switch (action.type) {
        case MACSUBTYPE_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case MACSUBTYPE_LIST_SUCCESS:
            console.log('SOLO mac subtype has been called')
            return {
                ...state,
                loading: false,
                macSubTypeList: action.payload.macSubTypeList,
                meta: action.payload.meta,
                error: ''
            }
        case MACSUBTYPE_ALL_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case MACSUBTYPE_ALL_LIST_SUCCESS:
            console.log('ALL mac subtype has been called')
            return {
                ...state,
                loading: false,
                allMacSubTypeList: action.payload.macSubTypeList,
                meta: action.payload.meta,
                error: ''
            }
        case MACSUBTYPE_LIST_ERROR:
            return {
                loading: false,
                macSubTypeList: [],
                meta: [],
                error: action.payload.message
            }
        case MACSUBTYPE_LIST_TREE_VIEW:
            return {
                ...state,
                loading: false,
                macSubTypeListTree: action.payload.macSubTypeListTree,
                macSubTypeListTreeTimeStamp: action.payload.macSubTypeListTreeTimeStamp
            }
        case MACSUBTYPE_LIST_TREE_VIEW_DEFAULT:
            return {
                ...state,
                loading: false,
                macSubTypeListTreeDefault: action.payload.macSubTypeListTreeDefault,
                macSubTypeListTreeDefaultTimeStamp: action.payload.macSubTypeListTreeDefaultTimeStamp
            }
        case MACSUBTYPE_TRIGGER_HIGHLIGHT:
            return {
                ...state,
                triggerHighlight: action.payload
            }
        case MACSUBTYPE_DETAIL_SUCCESS:
            return {
                ...state,
                macSubTypeDetail: action.payload.macSubTypeDetail
            }
        default:
            return state;
    }
}

export default macSubTypeListReducer;