import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import GlobalTemplate from './globalTemplate'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { checkIfApiLoading, checkPageActionAccess,checkPageAccess } from '../../../../../utils/helper';
import './style.scss';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    disabled: {
      color:'#8e9498',
      padding:'13px',
    },
    attachButton: {
      padding:'13px',
    },
  });

  
  const theme = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiDialog: {
        // Name of the rule
        paperWidthSm: {
          // Some CSS
          maxWidth: '816px',
        },
        paper: {
          backgroundColor: '#1c3a50',
        }
      },
    },
  });

  const dialogButtons = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiDialogActions: {
        // Name of the rule
        root: {
          // Some CSS
          display: 'block',
        },
      },
    },
  });

  const title = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiTypography: {
        // Name of the rule
        root: {
          // Some CSS
          color: 'white',
          
        },
        h6: {
          fontWeight: 'bold',
        },
      },
    },
  });

  const muiButtons = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiButton: {
        // Name of the rule
        root: {
          // Some CSS
          color: '#fafafa',
          fontWeight: 'bold',
          backgroundColor: '#0F75BC',
          fontSize: '1.175rem',
          '&:hover': {
            backgroundColor: '#0e598e',
            color: '#FFF'
        }
        },
      },
    },
  });

  const muiAlert = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiAlert: {
        // Name of the rule
        root: {
          // Some CSS
          fontSize:'1.3rem',
        },
      },
      MuiSvgIcon: {
        root: {
          fontSize:'1.3rem',
        }
      }
    },
  });


  const headCells = [
    { id: 'templateName', numeric: false, disablePadding: true, label: 'TEMPLATE NAME' },
    { id: 'checkListNo', numeric: false, disablePadding: true, label: 'TEMPLATE NO' },
    { id: 'tasksSteps', numeric: true, disablePadding: true, label: 'STEPS' },
    { id: 'subTasksSteps', numeric: true, disablePadding: false, label: 'SUB TYPE' },
    { id: 'macType', numeric: false, disablePadding: true, label: 'MACHINE TYPE' },
    { id: 'brand', numeric: false, disablePadding: true, label: 'BRAND' },
    { id: 'createdDt', numeric: false, disablePadding: true, label: 'PURCHASE DATE' },
    { id: 'addedDt', numeric: false, disablePadding: true, label: 'PURCHASE DATE' },
    { id: 'isActive', numeric: false, disablePadding: true, label: 'STATUS' },
  ];

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [attachData, setAttachData] = React.useState([]);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const[alertMessage, setAlertMessage] = React.useState('');
  const classes = useStyles();
  const templatesData = props.templates
  const machineChecklistData = props.machineChecklist
  const machineDetailData = props.machineDetail
  const macTempRowLength = props.macTempRL
  const brandsData = props.brands;
  const macSubTypesData = props.macSubTypes;
  const userAccess = JSON.parse(localStorage.getItem('userAccess'));

  let attaches = []
  let tempId = []
  let maclength = 0
  let selectedTemplate = []
  const handleClickOpen = () => {
    if (macTempRowLength >= 2){
        setAlertMessage('Cannot attach more than 2 templates'.translate())
        setAlertOpen(true);
        return false;
    }
    setOpen(true);
    props.unChecked(true)
  };

  const handleAttachData = (data, gettempId, getMaclength,  templatesData, machineChecklistData, getSelectedTemplate) => {
    attaches = data
    tempId = gettempId
    maclength = getMaclength
    selectedTemplate = getSelectedTemplate
  }

 const handleAttachDataNew = () => {
    if(attaches.length == 0){
      setAlertMessage('Please select a template'.translate())
      setAlertOpen(true);
      return false;
    }
    props.attachData(attaches, tempId, maclength, templatesData, machineChecklistData, selectedTemplate)
    setOpen(false);
  }

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Link
        component="button"
        variant="body2"
        disabled={checkPageActionAccess(userAccess,{pageId:3,pageAction:'Attach'}) === false}
        className={classes.attachButton + ' ' + (checkPageActionAccess(userAccess,{pageId:3,pageAction:"Attach"}) ? '' : classes.disabled)}
        onClick={handleClickOpen}>
        {'ATTACH'.translate()}
      </Link>
      <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <ThemeProvider theme={title}>
        <DialogTitle id="form-dialog-title" align="center">{'ADD CHECKLIST TEMPLATE'.translate()}</DialogTitle>
        </ThemeProvider>
        
        
        <DialogContent>
        <DialogContentText>
          </DialogContentText>
          <GlobalTemplate
          handleFilterCheckList={(value) => props.handleFilterCheckList(value)}
          macTempRL={macTempRowLength} 
          machineDetail={machineDetailData}
          machineChecklist={machineChecklistData} 
          templates={templatesData}
          attachData={handleAttachData} 
          brands={brandsData}
          macSubTypes={macSubTypesData}>
          </GlobalTemplate>
        </DialogContent>
        <ThemeProvider theme={dialogButtons}>
        <DialogActions align="center" >
        <ThemeProvider theme={muiButtons}>
          <Button onClick={handleClose}>
            {'Cancel'.translate()}
          </Button>
          <Button onClick={handleAttachDataNew}>
            {'SELECT'.translate()}
          </Button>
        </ThemeProvider>
        </DialogActions>
        </ThemeProvider>
        </Dialog>
        
        <ThemeProvider theme={muiAlert}>
          <Snackbar
            anchorOrigin={{vertical:'top',horizontal:'right'}}
            autoHideDuration={3000}
            open={ alertOpen }
            onClose={ (event,reason) => { if(reason !== 'clickaway'){ setAlertOpen(false) } } }
          >
            <Alert onClose={ (event,reason) => { if(reason !== 'clickaway'){ setAlertOpen(false) } } } variant="filled" severity={ 'error' }>
              { alertMessage }
            </Alert>
          </Snackbar>
        </ThemeProvider>
      </ThemeProvider>
    </div>
  );
}
