import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from "react-redux";
import { closeModal } from '../../../redux/actions/Modal/modalActions'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import './MaintenanceChecklist.scss';
import { Hidden } from '@material-ui/core';

class MaintenanceChecklist extends React.Component {

  state = {
      modalName: 'maintenanceChecklist',
      modalSettings: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      paperSettings: {
        position: 'relative',
        backgroundColor: '#1E3C50',
        border: '2px solid #000',
        boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.3)',
        padding: '2px 10px 3px 10px',
        width: '400px',
        overflow:'hidden',
      },
      open: false,
      search: false
  }

  renderSubTasks(subTaskList){
      let subtasks = subTaskList.map((item) => {
          return (
            <div className="subtask">
                <FormControl fullWidth>
                    <Grid container spacing={0}>
                        <Grid item xs={1}>
                            <Checkbox checked={item.isComplete} color="primary" />
                        </Grid>
                        <Grid item xs={7}>
                            <div className="subtask-title">{ item.subtask }</div>
                        </Grid>
                    </Grid>
                </FormControl>
            </div>
          )
      });

      return subtasks;
  }

  renderTasks(){
      if (this.props.checklistDetail){
          let tasks = this.props.checklistDetail.map((item) => {
              return (
                <div className='task-container'>
                    <div className="task">
                        <FormControl fullWidth>
                            <Grid container spacing={0}>
                                <Grid item xs={1}>
                                    <Checkbox checked={item.task.isComplete} color="primary" />
                                </Grid>
                                <Grid item xs={7}>
                                    <div className="task-title">{ item.task.task + ' (' + item.ticketSubTasks.length + ')' }</div>
                                </Grid>
                                <Grid item xs={3} />
                                <Grid item xs={1} >
                                    <ArrowDropUpIcon className="arrow" />
                                </Grid>
                            </Grid>
                        </FormControl>
                    </div>
                    { this.renderSubTasks(item.ticketSubTasks) }
                    
                </div>
              )
          });

          return tasks;
      }
  }

  componentDidMount(){
  }

  render(){
    return (
        <div>
            <Modal 
                open={this.props.modalShown === this.state.modalName} 
                onClose={this.props.closeModal}
                style={this.state.modalSettings}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout:500
                }}
            > 
                <Fade style={this.state.paperSettings} in={this.props.modalShown === this.state.modalName}>
                    <div className='maintenance-checklist-modal'>
                       <div onClick={this.props.closeModal} className="close">
                           <CloseIcon />
                       </div>
                       {/* <div className="title">{ this.props.title }</div> */}
                       <div className="title">{ "Checklist".translate() }</div>
                       <Scrollbars autoHide autoHideTimeout={500} style={{ width: '99%', height: '450px' }} className="scrollable">
                          <div className="checklist">
                              <div className="label">{ "Checklist".translate() }</div>
                              { this.renderTasks() }
                          </div>
                       </Scrollbars>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
  }
}

const mapStateToProps = state => {
    let apiLoading = {
    }
    let data = {
      modalShown: state.modalReducer.modalShown,
      ticketDetail: state.ticketListReducer.ticketDetail,
      checklistDetail: state.ticketListReducer.checklistDetail,
    }
  
    return {
      ...apiLoading,
      ...data
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      closeModal: () => dispatch(closeModal()),
    }
  }

MaintenanceChecklist = connect(mapStateToProps,mapDispatchToProps)(MaintenanceChecklist);

export default MaintenanceChecklist;
