import React from "react";

export default function CloseIcon({
    color = '#fff',
    background = '#d32f2f',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      data-name="Component 171 – 2"
      viewBox="0 0 30 30"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill={color}
            d="M18 9a1.063 1.063 0 01-1.125 1.125h-6.75v6.75A1.063 1.063 0 019 18a1.063 1.063 0 01-1.125-1.125v-6.75h-6.75A1.063 1.063 0 010 9a1.063 1.063 0 011.125-1.125h6.75v-6.75A1.063 1.063 0 019 0a1.063 1.063 0 011.125 1.125v6.75h6.75A1.063 1.063 0 0118 9z"
          ></path>
        </clipPath>
      </defs>
      <circle
        cx="15"
        cy="15"
        r="15"
        fill={background}
        data-name="Ellipse 387"
      ></circle>
      <g data-name="Component 138 – 1">
        <g data-name="icon / plus" transform="rotate(45 4.757 19.243)">
          <g data-name="Group 181">
            <path
              fill={color}
              d="M18 9a1.063 1.063 0 01-1.125 1.125h-6.75v6.75A1.063 1.063 0 019 18a1.063 1.063 0 01-1.125-1.125v-6.75h-6.75A1.063 1.063 0 010 9a1.063 1.063 0 011.125-1.125h6.75v-6.75A1.063 1.063 0 019 0a1.063 1.063 0 011.125 1.125v6.75h6.75A1.063 1.063 0 0118 9z"
              data-name="Mask"
            ></path>
            <g clipPath="url(#clip-path)" data-name="Mask Group 181">
              <g data-name="Icon Color" transform="translate(-4.5 -4.5)">
                <path fill={color} d="M0 0H27V27H0z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
