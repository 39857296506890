import {
    FLOOR_LIST_REQUEST,
    FLOOR_LIST_SUCCESS,
    FLOOR_LIST_ERROR,
    FLOOR_DETAIL_REQUEST,
    FLOOR_DETAIL_SUCCESS,
    FLOOR_DETAIL_ERROR,
    FLOOR_NEW_REQUEST,
    FLOOR_NEW_SUCCESS,
    FLOOR_NEW_ERROR,
    FLOOR_UPDATE_REQUEST,
    FLOOR_UPDATE_SUCCESS,
    FLOOR_UPDATE_ERROR,
    FLOOR_TRIGGER_HIGHLIGHT,
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    detailLoading: false,
    triggerHighlight: false,
    closeModal: false,
    floorList:[],
    floorDetail: null,
    meta: [],
    error: ''
}

const floorListReducer = (state = initialState, action) => {
    switch (action.type) {
        case FLOOR_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FLOOR_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                floorList: action.payload.floorList,
                meta: action.payload.meta,
                error: ''
            }
        case FLOOR_LIST_ERROR:
            return {
                ...state,
                loading: false,
                floorList: [],
                meta: [],
                error: action.payload.message
            }
        case FLOOR_DETAIL_REQUEST:
            return {
                ...state,
                detailLoading: true,
            }
        case FLOOR_DETAIL_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                floorDetail: action.payload.floorDetail
            }
        case FLOOR_NEW_REQUEST:
            return {
                ...state,
                detailLoading: true,
                closeModal: false
            }
        case FLOOR_NEW_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                closeModal: true
            }
        case FLOOR_UPDATE_REQUEST:
            return {
                ...state,
                detailLoading: true,
                closeModal: false
            }
        case FLOOR_UPDATE_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                closeModal: true
            }
        case FLOOR_TRIGGER_HIGHLIGHT:
            return {
                ...state,
                triggerHighlight: action.payload,
                closeModal: false,
            }
        default: 
            return state;
    }
}

export default floorListReducer;