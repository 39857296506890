import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Link } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { checkPageAccess } from '../../../../../utils/helper';
import useHistoryTableStyle from './historyTable.style';
import useToolbarStyles from './toolbar.style';
import MachineHistory from './MachineHistory';
import { stableSort, getComparator } from '../../../../../utils/array';
import { formatDate } from '../../../../../utils/date';

const headCellsMaintenance = [
  { id: 'ticketNo', className:'ticketNoMaintenance', numeric: false, disablePadding: true, label: 'TICKET NO' },
  { id: 'mechanic', className:'mechanicMaintenance', numeric: false, disablePadding: true, label: 'MECHANIC' },
  { id: 'checkListNo', className:'checkListNo', numeric: true, disablePadding: true, label: 'CHECKLIST NO.' },
  { id: 'lpm_date', className:'lpm_date', numeric: false, disablePadding: true, label: 'LPM' },
  { id: 'reportedDt', className:'reportedDtMaintenance', numeric: false, disablePadding: true, label: 'DATE TIME GENERATED' },
  { id: 'grabbedDt', className:'grabbedDtMaintenance', numeric: false, disablePadding: true, label: 'DATE TIME STARTED' },
  { id: 'closedDt', className:'closedDtMaintenance', numeric: false, disablePadding: true, label: 'DATE TIME COMPLETED' },
  { id: 'downtime', className:'downtime', numeric: false, disablePadding: true, label: 'DOWN TIME' },
  { id: 'status', className:'status', numeric: false, disablePadding: true, label: 'STATUS' },
];

const headCellsRepair = [
  { id: 'ticketNo', className:'ticketNoRepair', numeric: false, disablePadding: true, label: 'TICKET NO' },
  { id: 'mechanic', className:'mechanicRepair', numeric: false, disablePadding: true, label: 'MECHANIC' },
  { id: 'place', className:'mechanicRepair', numeric: false, disablePadding: true, label: 'REPORTED LINE' },
  { id: 'problemType', className:'problemType', numeric: true, disablePadding: true, label: 'REPORTED PROBLEM' },
  { id: 'solutionType', className:'solutionType', numeric: false, disablePadding: true, label: 'SOLUTION' },
  { id: 'reportedDt', className:'reportedDtRepair', numeric: false, disablePadding: true, label: 'DATE TIME REPORTED' },
  { id: 'grabbedDt', className:'grabbedDtRepair', numeric: false, disablePadding: true, label: 'DATE TIME START REPAIR' },
  { id: 'closedDt', className:'closedDtRepair', numeric: false, disablePadding: true, label: 'DATE TIME CLOSED' },
  { id: 'downtime', className:'downtime', numeric: false, disablePadding: true, label: 'DOWN TIME' },
  { id: 'status', className:'status', numeric: false, disablePadding: true, label: 'STATUS' },
];

function EnhancedTableHeadMaintenance(props) {
  const { classes, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const classes1 = useToolbarStyles();

  return (
    <TableHead>
      <TableRow>
        {headCellsMaintenance.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            padding='default'
            className={classes1[headCell.className]}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label.translate()}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableHeadRepair(props) {
  const { classes, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const classes1 = useToolbarStyles();

  return (
    <TableHead>
      <TableRow>
        {headCellsRepair.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            padding='default'
            className={classes1[headCell.className]}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label.translate()}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeadMaintenance.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

EnhancedTableHeadRepair.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const [arrowMaintenance, setArrowMaintenance] = React.useState(props.maintenanceArrow);
  const [arrowRepair, setArrowRepair] = React.useState(props.repairArrow);
  const title = props.title;
  const userAccess = JSON.parse(localStorage.getItem('userAccess'));
  
  const handleMaintenanceArrow = () => {
    setArrowMaintenance((prev) => !prev);
    props.collapseMaintenance(true)
    setArrowRepair(false)
  };
  const handleRepairArrow = () => {
    setArrowRepair((prev) => !prev);
    props.collapseRepair(true)
    setArrowMaintenance(false);
  };

  useEffect(() => {
    setArrowMaintenance(false)
  }, [arrowRepair]);

  useEffect(() => {
    setArrowRepair(false);
  }, [arrowMaintenance]);

  return (
    <Toolbar
      className={clsx(classes.root, {
      })}
    >
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {title.translate()}
        </Typography>

        {
        title == "MAINTENANCE HISTORY".translate() ?
        props.maintenanceArrow ? (
            <ArrowDropUpIcon className={classes.arrow} onClick={checkPageAccess(userAccess,{pageId:4}) ? handleMaintenanceArrow : null}></ArrowDropUpIcon>
      ) : (
            <ArrowDropDownIcon className={classes.arrow} onClick={checkPageAccess(userAccess,{pageId:4}) ? handleMaintenanceArrow : null}></ArrowDropDownIcon>
      ) :
      props.repairArrow ? (
        <ArrowDropUpIcon className={classes.arrow} onClick={checkPageAccess(userAccess,{pageId:5}) ? handleRepairArrow : null}></ArrowDropUpIcon>
  ) : (
        <ArrowDropDownIcon className={classes.arrow} onClick={checkPageAccess(userAccess,{pageId:5}) ? handleRepairArrow : null}></ArrowDropDownIcon>
  )
      }

    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


  const tableTheme = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiTableCell: {
        // Name of the rule
        root: {
          fontSize: '0.975rem',
        },
        sizeSmall: {
          // Some CSS
          padding: '6px 0px 6px 16px',
        },
        head: {
          fontWeight: '800',
        },
      },
    },
  });


export default function EnhancedTable(props) {
  const classes = useHistoryTableStyle();
  const checker = props.checkedSetter;
  const templateIsOpened = props.templateIsOpened;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [maintenanceChecked, setMaintenanceChecked] = React.useState(false);
  const [repairChecked, setRepairChecked] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const maintenanceHistory = props.maintenanceHistory.sort((a,b) => moment(b.reportedDt) - moment(a.reportedDt))
  const repairHistory = props.repairHistory.sort((a,b) => moment(b.reportedDt) - moment(a.reportedDt));
  const userAccess = JSON.parse(localStorage.getItem('userAccess'));
  console.log('let check maintenance history')
  console.log(maintenanceHistory)
  useEffect(() => {
    if(maintenanceChecked == false && repairChecked == false){
      props.templateCollapseSetter(true)
    }else{
      props.templateCollapseSetter(false)
    }
    
  }, [maintenanceChecked, repairChecked]);

  useEffect(() => {
    if(templateIsOpened){
    setMaintenanceChecked(false);
    setRepairChecked(false);
  }
  }, [checker]);

  const handleMaintenanceCollapse = (collapseboolean) => {
    if(collapseboolean == true){
    setRepairChecked(false);
    setMaintenanceChecked(prev => !prev);
    return maintenanceChecked
    }
  };

  const handleRepairCollapse = (collapseboolean) => {
    if(collapseboolean == true){
    setMaintenanceChecked(false);
    setRepairChecked(prev => !prev);
    return repairChecked
    }
  };

  const maintenanceGetter = () => {
    return maintenanceChecked
  };

  const repairGetter = () => {
    return repairChecked
    
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleChecked = () => {
    setChecked(true)
  }


const renderDate = (date) => {
  if (date != '-'){
    let timezone = moment.parseZone(date).format('Z');
    let dateTimeOnly = moment.parseZone(date).format('DD-MM-YYYY HH:mm:ss') + '+00:00';
    return moment(dateTimeOnly).utcOffset(timezone).format('DD-MM-YYYY HH:mm');
  }
  else{
      return '-';
  }
}

const renderDateLPM = (date) => {
  if (date != '-'){
    let timezone = moment.parseZone(date).format('Z');
    let dateTimeOnly = moment.parseZone(date).format('DD-MM-YYYY HH:mm:ss') + '+00:00';
    return moment(dateTimeOnly).utcOffset(timezone).format('DD-MM-YYYY');
  }
  else{
      return '-';
  }
}

const renderText = (field) => {
  if (field === null || field === ''){
      return '-'
  }
  else{
      return field != undefined ? field.toString().translate() : field
  }
}

const renderMechanic = (field) => {
  if (field === null || field === ''){
      return '-'
  }
  else{
      if(field == 'REPORTED' || field == 'SCHEDULED'){
        return 'reportedBy'
      }else if(field == 'CANCELLED'){
        return 'canceledBy'
      }else if(field == 'IN-REPAIR' || field == 'IN PROGRESS'){
        return 'grabbedBy'
      }else if(field == 'REPAIRED'){
        return 'repairedBy'
      }else if(field == 'CLOSED'  || field == 'COMPLETED'){
        return 'closedBy'
      }
  }
}

const renderStatusClass = (field) => {
  if (field === 'REPORTED'){
    return 'reported'
  }
  else if(field ===  'IN-REPAIR'){
    return 'inRepair'
  }
  else if(field ===  'CANCELLED'){
    return 'cancelled'
  }else{
    return 'closed'
  }
}

const saveRedirect = () => {
    localStorage.setItem('redirectToMachineDetails','true');
}

  return (
    
    <div className={classes.root}>
      <MachineHistory 
        machineHistories={props.machineHistories}
        onMachineHistoryOpened={() => {
          setRepairChecked(false);
          setMaintenanceChecked(false);
        }}
        dependCollapses={{maintenanceChecked, repairChecked}}
      />
      <Paper className={classes.paper + ' ' + (checkPageAccess(userAccess,{pageId:4}) === false ? classes.paperDisabled : null)}>
        <EnhancedTableToolbar 
        checked={handleChecked} 
        title={"MAINTENANCE HISTORY".translate()}
        repairArrow={repairChecked}
        maintenanceArrow={maintenanceChecked}
        collapseMaintenance={handleMaintenanceCollapse}/>
        
        <TableContainer className={classes.container}>
        <Collapse in={maintenanceChecked}>
          <Table
            stickyHeader 
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHeadMaintenance
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={maintenanceHistory.length}
            />
            
            <TableBody>
              {stableSort(maintenanceHistory, getComparator(order, orderBy))
                .map((row, index) => {

                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <ThemeProvider theme={tableTheme}>
                    <TableRow
                      hover
                      role="checkbox"
                      classes={{ hover: classes.hover}}
                      className={classes.tableRow}
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell className={classes.blue} component="th" align="left" id={labelId} scope="row" padding="none">
                      <Link onClick={saveRedirect} className={classes.blueLink} to={"/ticket-detail/" + row.id}>{row.ticketNo}</Link>
                      </TableCell>
                      <TableCell className={classes.mechanic} align="left">{row[renderMechanic(row.status)]}</TableCell>
                      <TableCell className={classes.checkListNo} align="left">{renderText(row.checkListNo)}</TableCell>
                      <TableCell className={classes.lpm_date} align="left">{formatDate(row.lpm_date)}</TableCell>
                      <TableCell className={classes.reportedDt} align="left">{formatDate(row.reportedDt, 'DD-MM-YYYY HH:mm')}</TableCell>
                      <TableCell className={classes.grabbedDt} align="left">{formatDate(row.grabbedDt, 'DD-MM-YYYY HH:mm')}</TableCell>
                      <TableCell className={classes.closedDt} align="left">{formatDate(row.closedDt, 'DD-MM-YYYY HH:mm')}</TableCell>
                      <TableCell className={classes.downtime} align="left">{ row.downtime }</TableCell>
                      <TableCell className={classes[renderStatusClass(row.status)]} align="left">{row.status.translate()}</TableCell>
                    </TableRow>
                    </ThemeProvider>
                  );
                })}
                {maintenanceHistory.length == 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={9} className={classes.notFound}>{'No records found'.translate()}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          </Collapse>
        </TableContainer>
      </Paper>
      
      <Paper className={classes.paper + ' ' + (checkPageAccess(userAccess,{pageId:5}) === false ? classes.paperDisabled : null)}>
        <EnhancedTableToolbar 
        checked={handleChecked} 
        title={"REPAIR HISTORY".translate()}
        repairArrow={repairChecked}
        maintenanceArrow={maintenanceChecked}
        collapseRepair={handleRepairCollapse}/>
        
        <TableContainer  className={classes.container}>
        <Collapse in={repairChecked}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHeadRepair
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={repairHistory.length}
            />
            
            <TableBody>
              {stableSort(repairHistory, getComparator(order, orderBy))
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <ThemeProvider theme={tableTheme}>
                    <TableRow
                      hover
                      role="checkbox"
                      classes={{ hover: classes.hover}}
                      className={classes.tableRow}
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell className={classes.blue} component="th" align="left" id={labelId} scope="row" padding="none">
                       <Link onClick={saveRedirect} className={classes.blueLink} to={"/ticket-detail/" + row.id}>{row.ticketNo}</Link>
                      </TableCell>
                      <TableCell className={classes.mechanic} align="left">{row[renderMechanic(row.status)]}</TableCell>
                      <TableCell className={classes.place} align="left">{renderText(row.place)}</TableCell>
                      <TableCell className={classes.problemType} align="left">{renderText(row.problemType)}</TableCell>
                      <TableCell className={classes.solutionType} align="left">{renderText(row.solutionType)}</TableCell>
                      <TableCell className={classes.reportedDt} align="left">{formatDate(row.reportedDt, 'DD-MM-YYYY HH:mm')}</TableCell>
                      <TableCell className={classes.grabbedDt} align="left">{formatDate(row.grabbedDt, 'DD-MM-YYYY HH:mm')}</TableCell>
                      <TableCell className={classes.closedDt} align="left">{formatDate(row.closedDt, 'DD-MM-YYYY HH:mm')}</TableCell>
                      <TableCell className={classes.downtime} align="left">{ row.downtime }</TableCell>
                      <TableCell className={classes[renderStatusClass(row.status)]} align="left">{row.status.translate()}</TableCell>
                    </TableRow>
                    </ThemeProvider>
                  );
                })}
                {repairHistory.length == 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={9} className={classes.notFound}>{'No records found'.translate()}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          </Collapse>
        </TableContainer>
      </Paper>
    </div>


  );
}