import React from 'react';
import { TableBody as Body, TableRow } from '@material-ui/core';
import { numberWithCommas } from '../../../../../../utils/helper';
import { colors } from '../constant';
import IndividualMechanicTooltip from '../../Tooltips/IndividualMechanicTooltip';
import { minuteToTime } from '../../../../../../utils/date';
import {
    getPercent,
    serializeToolTipForAverageTime,
    serializeToolTipForTotalTime,
} from '../helpers';
import {
    BlackTooltip,
    Col,
    TimeBoxContainer,
    TimeBoxContent,
    TimeBoxHeader,
    TimeBoxItem,
} from '../styles';

export default function TableBody(props) {
    const { data, filters } = props;
    const { startDate, endDate } = filters.dateRange;
    const lastRow = (i, length) => i + 1 === length;
    const maxTotalOverallHandlingTime = Math.max(...data.map(item => item.totalOverallHandlingTime));
    const maxAverageOverallHandlingTime = Math.max(...data.map(item => item.averageOverallHandlingTime));

    return (<Body>
        {data.map((row, i) => (
        <TableRow>
            <Col align='left' fontWeight={lastRow(i, data.length) ? 'bold' : ''}>{row.mechanicName}</Col>
            <Col align='right' fontWeight={lastRow(i, data.length) ? 'bold' : ''}>{numberWithCommas(row.changeover.ticketCount)}</Col>
            <Col align='right' fontWeight={lastRow(i, data.length) ? 'bold' : ''}>{numberWithCommas(row.maintenance.ticketCount)}</Col>
            <Col align='right' fontWeight={lastRow(i, data.length) ? 'bold' : ''}>{numberWithCommas(row.repair.ticketCount)}</Col>
            <Col align='right' fontWeight={lastRow(i, data.length) ? 'bold' : ''}>{row.reOpen.toFixed(1)} %</Col>
            <Col align='right'>
                <BlackTooltip
                    placement='left'
                    background="rgba(0, 0 , 0, .8)"
                    title={<IndividualMechanicTooltip data={serializeToolTipForTotalTime({ ...row, startDate, endDate })} />}
                    arrow >
                    <TimeBoxContainer>
                        <TimeBoxHeader 
                            color={colors[0]} 
                            title={minuteToTime(row.totalOverallHandlingTime)} 
                            width={`${getPercent(row.totalOverallHandlingTime, maxTotalOverallHandlingTime)}%`} />
                        <TimeBoxContent width={`${getPercent(row.totalOverallHandlingTime, maxTotalOverallHandlingTime)}%`}>
                            <TimeBoxItem 
                                bgcolor={colors[2]} 
                                width={`${getPercent(row.changeover.totalHandlingTime, row.totalOverallHandlingTime)}%`} />
                            <TimeBoxItem 
                                bgcolor={colors[3]} 
                                width={`${getPercent(row.maintenance.totalHandlingTime, row.totalOverallHandlingTime)}%`} />
                            <TimeBoxItem 
                                bgcolor={colors[4]} 
                                width={`${getPercent(row.responseTime.totalHandlingTime, row.totalOverallHandlingTime)}%`} />
                            <TimeBoxItem 
                                bgcolor={colors[5]} 
                                width={`${getPercent(row.repair.totalHandlingTime, row.totalOverallHandlingTime)}%`} />
                        </TimeBoxContent>
                    </TimeBoxContainer>
                </BlackTooltip>
            </Col>
            <Col align='right'>
                <BlackTooltip
                    placement='left'
                    background="rgba(0, 0 , 0, .8)"
                    title={<IndividualMechanicTooltip data={serializeToolTipForAverageTime({ ...row, startDate, endDate })} />}
                    arrow>
                    <TimeBoxContainer>
                        <TimeBoxHeader 
                            color={colors[1]} 
                            title={minuteToTime(row.averageOverallHandlingTime)} 
                            width={`${getPercent(row.averageOverallHandlingTime, maxAverageOverallHandlingTime)}%`} />
                        <TimeBoxContent width={`${getPercent(row.averageOverallHandlingTime, maxAverageOverallHandlingTime)}%`}>
                            <TimeBoxItem 
                                bgcolor={colors[2]} 
                                width={`${getPercent(row.changeover.averageHandlingTime, row.averageOverallHandlingTime)}%`} />
                            <TimeBoxItem 
                                bgcolor={colors[3]} 
                                width={`${getPercent(row.maintenance.averageHandlingTime, row.averageOverallHandlingTime)}%`} />
                            <TimeBoxItem 
                                bgcolor={colors[4]} 
                                width={`${getPercent(row.responseTime.averageHandlingTime, row.averageOverallHandlingTime)}%`} />
                            <TimeBoxItem 
                                bgcolor={colors[5]} 
                                width={`${getPercent(row.repair.averageHandlingTime, row.averageOverallHandlingTime)}%`} />
                        </TimeBoxContent>
                    </TimeBoxContainer>
                </BlackTooltip>
            </Col>
        </TableRow>
        ))}
    </Body>
    )
}