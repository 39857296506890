import {
    STATUS_LIST_REQUEST,
    STATUS_LIST_MACHINE_REQUEST,
    STATUS_LIST_SUCCESS,
    STATUS_LIST_MACHINE_SUCCESS,
    STATUS_LIST_TICKET_SUCCESS,
    STATUS_LIST_CHECKLIST_TEMPLATE_SUCCESS,
    STATUS_LIST_ERROR
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    machineStatusLoading: false,
    machineStatusList: [],
    statusList:[],
    ticketStatusList: [],
    checklistTemplatesStatusList: [],
    meta: [],
    error: ''
}

const statusListReducer = (state = initialState, action) => {
    switch (action.type) {
        case STATUS_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case STATUS_LIST_MACHINE_REQUEST:
            return {
                ...state,
                machineStatusLoading: true
            }
        case STATUS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                statusList: action.payload.statusList,
                meta: action.payload.meta,
                error: ''
            }
        case STATUS_LIST_MACHINE_SUCCESS:
            return {
                ...state,
                machineStatusLoading: false,
                machineStatusList: action.payload.machineStatusList,
                error: ''
            }
        case STATUS_LIST_TICKET_SUCCESS:
            return {
                ...state,
                loading: false,
                ticketStatusList: action.payload.ticketStatusList,
                error: ''
            }
        case STATUS_LIST_CHECKLIST_TEMPLATE_SUCCESS:
            console.log('reducer of statlist')
            console.log(action.payload.checklistTemplatesStatusList)
            return {
                ...state,
                loading: false,
                checklistTemplatesStatusList: action.payload.checklistTemplatesStatusList,
                error: ''
            }
        case STATUS_LIST_ERROR:
            return {
                ...state,
                loading: false,
                machineStatusList: [],
                statusList: [],
                meta: [],
                error: action.payload.message
            }
        default: 
            return state;
    }
}

export default statusListReducer;