import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import videoIcon from './playbutton.png'
import spinner from './spinner2.gif'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import VideoPlayer from 'simple-react-video-thumbnail'
import VideoThumbnail from 'react-video-thumbnail';
import Container from '@material-ui/core/Container';
import { apiSettings } from '../../../../../utils/api/CoreApi';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './style.scss';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const dialogTheme = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiDialog: {
        // Name of the rule
        paperScrollPaper: {
            display: 'contents',
        },
      },
    },
  });

  const dialogContentTheme = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiDialogContent: {
        // Name of the rule
        root: {
            flex: 'none',
        },
      },
    },
  });

  const imageContentTheme = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiCardMedia: {
        // Name of the rule
        img: {
            objectFit: 'fill',
        },
      },
    },
  });


export default function MediaCard(props) {
  const useStyles = makeStyles({
    root: {
    },
    image: {
      height: 80,
      width: 80,
      backgroundSize: 'cover',
    },
    video: {
      maxWidth: '500px',
      maxHeight: '605px',
      width: 'auto',
      height: 'auto',
      backgroundSize: 'contain',
    },
    videoImage: {
      height: 80,
      width: 80,
      backgroundSize: 'cover',
    },
    imageDialog:{
      maxWidth: '1000px',
      maxHeight: '605px',
      width: 'auto',
      height: 'auto',
      backgroundSize: 'contain',
    },
    videoIcon:{
      height: '82px',
      zIndex: '2',
      position: 'absolute',
      left: '46%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    loading:{
      height: '139px',
      width: '156px',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    }
  });

  const token = JSON.parse(localStorage.getItem('userToken'));
    const classes = useStyles();
    const media = props.media
    const btoa1 = btoa('/api/files/by-name?path=' + props.link + "&token=" + token);
    const link = apiSettings.apiURLFiles + btoa1
    const [openVideo, setOpenVideo] = React.useState(false);
    const [openImage, setOpenImage] = React.useState(false);
    const [url, setUrl] = React.useState(false);

    useEffect(() => {
      const xhr = new XMLHttpRequest(),
      method = "GET",
      url = link
  
  xhr.open(method, url, true);
  xhr.send();
  xhr.onreadystatechange = function(){
  setUrl(false)
  if(xhr.readyState == 4){
    setUrl(true)
  }
  }

    }, [link]);
    const handleVideoOpen = () => {
        setOpenVideo(true);
    };
  
    const handleVideoClose = () => {
        setOpenVideo(false);
    };

    const handleImageOpen = () => {
        setOpenImage(true);
    };
  
    const handleImageClose = () => {
        setOpenImage(false);
    };

    const image = () => {
        return (
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.image}
                  onClick={handleImageOpen}
                  image={link}
                />
              </CardActionArea>
            </Card>
          );
    }

    const video = () => {
        return (
            <Card className={classes.root}>
              
              <CardActionArea onClick={handleVideoOpen} className={classes.videoImage}>
              <img className={classes.videoIcon} src={videoIcon}></img>
              <VideoThumbnail
                videoUrl={link}
                thumbnailHandler={(thumbnail) => console.log()}
                 width={1100}
                  height={1000} 
                />
              </CardActionArea>
            </Card>
          );
    }

    const videoPlayer = () => {
      return (
        <VideoPlayer className={classes.video} videoUrl={link} snapshotAt={2} />
        );
  }

  const loading = () => {
    return (
      <Card className={classes.root}>
              
      <CardActionArea onClick={handleVideoOpen} className={classes.videoImage}>
      <img className={classes.loading} src={spinner} />
      </CardActionArea>
    </Card>
      
      );
}

  return (
      <div>
          {
            url == false ? loading() : media == "video" ? video() : image()
          //url == false ? loading() : loading()
          }
<ThemeProvider theme={dialogTheme}>
    <Dialog
        open={openVideo}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleVideoClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
          <ThemeProvider theme={dialogContentTheme}>
        <DialogContent>
            <Container >{
            url == false ? null : videoPlayer()}
            </Container>
        </DialogContent>
        </ThemeProvider>
      </Dialog>
      </ThemeProvider>
      <ThemeProvider theme={dialogTheme}>
      <Dialog
        open={openImage}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleImageClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
          <ThemeProvider theme={dialogContentTheme}>
        <DialogContent>
            <ThemeProvider theme={imageContentTheme}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  className={classes.imageDialog}
                  image={link}
                />
              </CardActionArea>
            </Card>
            </ThemeProvider>
        </DialogContent>
        </ThemeProvider>
      </Dialog>
      </ThemeProvider>
      </div>

  );
}
