import { 
    CHANGEOVER_TYPE_LIST_SUCCESS,
    CHANGEOVER_TYPE_LIST_REQUEST,
    CHANGEOVER_TYPE_LIST_ERROR,
    CHANGEOVER_TYPE_UPDATE_SUCCESS,
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    detailLoading: false,
    triggerHighlight: false,
    changeoverTypeList:[],
    changeoverTypeDetail: null,
    meta: [],
    error: ''
}

const changeoverTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGEOVER_TYPE_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CHANGEOVER_TYPE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                changeoverTypeList: action.payload.changeoverTypeList,
                changeoverTypeDetail: null,
                meta: action.payload.meta,
                error: ''
            }
        case CHANGEOVER_TYPE_LIST_ERROR:
            return {
                ...state,
                loading: false,
                changeoverTypeList: [],
                changeoverTypeDetail: null,
                meta: [],
                error: action.payload.message
            }
        case CHANGEOVER_TYPE_UPDATE_SUCCESS:
            return {
                ...state,
                updated: action.payload.updated,
            }
        default: 
            return state;
    }
}

export default changeoverTypeReducer;