import React from 'react';
import { Bar } from 'react-chartjs-2';
import { kFormat } from '../helpers';

export default function BarChart(props){
  const { optionTooltip } = props;
  const options = {
    legend: false,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: props.xTickFormat ? props.xTickFormat : (value)=> kFormat(value)
          },
          gridLines: {
            borderDash: [8, 4]
          }
        },
      ],
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display:false
          },
          drawTicks: false,
        },
      ],
    },
    ...optionTooltip,
  };

  return <Bar height={props.height || 350} data={props.data} options={props.options || options} />
}
