import {
    LINES,
    LINES_REQUEST,
    LINES_SUCCESS,
    LINE_AREA_REQUEST,
    LINE_AREA_SUCCESS,
    LINES_ERROR
  } from '../actionTypes';
  import { tokenGet, apiSettings } from '../../../utils/api/CoreApi';
  
  export const linesRequest = () => {
      return {
          type:LINES_REQUEST
      }
  }
  
  export const linesSuccess = lines => {
      return {
          type:LINES_SUCCESS,
          payload: lines
      }
  }

  export const lineAreaRequest = () => {
    return {
        type:LINE_AREA_REQUEST
    }
}

export const lineAreaSuccess = lineArea => {
    return {
        type:LINE_AREA_SUCCESS,
        payload: lineArea
    }
}
  
  export const getLineStatusOverviewByArea = (area = 1, currentPage = 1, pageSize = 100000) =>{
      
      return function(dispatch){
          dispatch(linesRequest());
          tokenGet(apiSettings.apiURLLines + '/api/areas/' + area + '/line-overview?currentPage=' + currentPage +'&pageSize=' + pageSize).then((response) => {
            let data = response.data.lines
              dispatch(linesSuccess({
                  lines: data,
              }));
          })
          .catch((error) => {
              console.log(error);
          });
      }
  }

  export const getAllAreas = () =>{
    return function(dispatch){
        dispatch(lineAreaRequest());
        tokenGet(apiSettings.apiURLLines + '/api/areas/line-counts-per-area').then((response) => {
          let data = response.data.areas
            dispatch(lineAreaSuccess({
                lines: data,
            }));
        })
        .catch((error) => {
            console.log(error);
        });
    }
}