import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IndicatorChartLine } from './styles';

const CardAccordionSummary = withStyles({
  root: {
    flexDirection: 'row-reverse',
    minHeight: 30,
    '&$expanded': {
      minHeight: 30
    },
  },
  expandIcon: {
    marginRight: 0,
    '&$expanded': {
      paddingRight: 0,
    },
  },
  content: {
    justifyContent: 'space-between',
  }
})(AccordionSummary);

const MuiAccordion = withStyles({
  root: {
    padding: 15,
    marginBottom: 15,
    marginTop: 0,
    boxShadow: "0px 3px 6px #00000029",
    '&:before': {
      display: "none",
    },
  },
})(Accordion);

export default function CardAccordion(props){
  return <MuiAccordion defaultExpanded={true}>
    <CardAccordionSummary
      expandIcon={<ExpandMoreIcon />}
    >
      <h3>{props.title}</h3>
      <IndicatorChartLine legends={props.legends} />
      {props.filterForm}
    </CardAccordionSummary>

    <AccordionDetails>
      {props.children}
    </AccordionDetails>
  </MuiAccordion>
}
