import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import validate from 'validate.js';
import { getFactoryId } from '../../../../../utils/helper.js';
import {
  postNewChangeoverType,
  putUpdateChangeoverType,
} from '../../../../../redux/actions/ChangeoverTypes/changeoverTypeAction';
import { connect } from 'react-redux';
import InputTranslation from './InputTranslation.js';
import { notificationSuccess } from '../../../../../redux/actions/ChangeoverTypes/changeoverTypeAction';
import { notificationError } from '../../../../../redux/actions/ChangeoverTypes/changeoverTypeAction';
import dictionary from '../../../../../redux/actions/Translation/dictionary';
import { useStyles  } from './style.js';
import { formatDate } from '../../../../../utils/date.js';

const ChangeoverTypeForm = ({
  changeoverType,
  translations,
  onClose,
  postNewChangeoverTypes,
  putUpdateChangeoverTypes,
  isDisabled }) => {
  let factoryId = getFactoryId();
  const classes = useStyles();
  const id = changeoverType ? changeoverType.id : '';
  const isEditing = Boolean(changeoverType)
  const [code, setCode] = useState(isEditing ? changeoverType.changeoverType : '');
  const [name, setName] = useState(isEditing ? changeoverType.desc1 : '');
  const [description, setDescription] = useState(isEditing ? changeoverType.desc2 : '');
  const [isActive, setIsActive] = useState(isEditing ? changeoverType.isActive : true);
  const [updateTranslations, setUpdateTranslations] = useState(translations);
  const [errors, setErrors] = useState([]);
  const convert = () => {
    let { 
      ADD, 
      SAVE, 
      CANCEL, 
      MAXIMUM_CHAR,
      SPACE_NOT_ALLOW,
    } = dictionary.GET();
    return dictionary.TRANSLATE({
       SAVE, 
       ADD, 
       CANCEL, 
       MAXIMUM_CHAR,
       SPACE_NOT_ALLOW,
    });
  }
  const {
    SAVE, 
    CANCEL, 
    MAXIMUM_CHAR,
    SPACE_NOT_ALLOW,
  } = convert();

  const handleSubmit = async (evt)=>{
    evt.preventDefault();
    const values = {
      id: id,
      factoryId: factoryId,
      code: code,
      name: name,
      desc: description,
      isActive: isEditing ? isActive: 1,
      translations: updateTranslations,
    }
    const rules = {
      name: {length: {maximum: 35, message: `${MAXIMUM_CHAR} 35`}},
      desc: {length: {maximum: 250, message: `${MAXIMUM_CHAR} 250`}},
      code: {
        length: {maximum: 30, message: `${MAXIMUM_CHAR} 30`},
        format: { pattern: /^\S*$/, message: SPACE_NOT_ALLOW},
      },
    }
    const validation = validate(values, rules, { fullMessages: false });
    if(!validation){
      if(changeoverType){
        putUpdateChangeoverTypes(values);
      } else {
        postNewChangeoverTypes(values);
      }
      onClose();
    } else {
      setErrors(validation);
    }
  }

  const ChangeoverTypeInfo = ({ changeoverType }) => {
    if(!changeoverType){ return null }
    const { updatedBy, updatedDt, createdBy, createdDt } = changeoverType;
    return <div style={{ marginTop: "1rem", color: "gray" }}>
      { 
        updatedBy && (      
          <Typography>
            Modified by: {updatedBy}
          </Typography>
        )
      }
  
      { 
        updatedDt && (      
          <Typography>
            Date Modified : {formatDate(updatedDt)}
          </Typography>
        )
      }
  
      { 
        createdBy && (      
          <Typography>
            Created By: {createdBy}
          </Typography>
        )
      }
  
      { 
        createdDt && (      
          <Typography>
            Date Created: {formatDate(createdDt)}
          </Typography>
        )
      }
    </div>
  
  }

  return (
    <form onSubmit={handleSubmit} className={classes.FormEdit} >
      <TextField
        className={classes.TextField}
        disabled={isDisabled}
        label='Changeover Type Code'
        type="text"
        value={code}
        onChange={(e) => setCode(e.target.value)}
        required
        fullWidth
        error={errors.code ? true : false}
        helperText={errors?.code?.map((error) => <p>{error}</p>)}
        InputLabelProps={{ classes: { asterisk: classes.asterisk }}}
        FormHelperTextProps={{ classes: { root: classes.helperText}}}
      />
      <TextField
        className={classes.TextField}
        label='Changeover Type Name'
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
        fullWidth
        error={errors.name ? true : false}
        helperText={errors?.name?.map((error) => <p>{error}</p>)}
        InputLabelProps={{ classes: { asterisk: classes.asterisk }}}
        FormHelperTextProps={{ classes: { root: classes.helperText}}}
      />
      {translations && translations.map(( language, key ) => <>
        <InputTranslation
          name={name}
          key={key}
          language={language}
          value={`${language.translatedDesc}`}
          label={`Display Name (${language.desc1})`} 
          onChangeText={(text) => { 
            translations[key].translatedDesc = text;
            setUpdateTranslations([...translations]); 
          }} />
        </>)}
        <TextField
          className={classes.TextField}
          label='Description'
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          error={errors.desc ? true : false}
          helperText={errors?.desc?.map((error) => <p>{error}</p>)}
          FormHelperTextProps={{ classes: { root: classes.helperText} }}
        />
      <ChangeoverTypeInfo changeoverType={changeoverType} />

      { isEditing &&
        <FormControl className={classes.radioFormControl}>
          <FormLabel className={classes.formLabel}>Status</FormLabel>
          <RadioGroup
            value={isActive}
            onChange={(evt) =>{
              setIsActive(evt.target.value == 1 ? 1 : 0)
            }
            }
            className={classes.radioGroup}
          >
            <FormControlLabel 
              value={1} 
              control={<Radio className={classes.radio} />} 
              label='Active' 
            />
            <FormControlLabel
              value={0}
              control={<Radio className={classes.radio} />}
              label='Inactive'
            />
          </RadioGroup>
          </FormControl>
        }
      
      <div className={classes.formAction}>
        <Button onClick={onClose} variant="outlined" className={classes.ButtonWhite}>
          {CANCEL}
        </Button>
        <Button type="submit" className={classes.ButtonBlue} variant="contained">
          {SAVE}
        </Button>
      </div>
    </form>
  )
}

const mapDispatchToProps = {
  postNewChangeoverTypes: (payload) => postNewChangeoverType(payload),
  putUpdateChangeoverTypes: (payload) => putUpdateChangeoverType(payload),
  showNotificationSuccess: (message) => notificationSuccess(message),
  showNotificationError: (message) => notificationError(message)
}

export default connect(null, mapDispatchToProps)(ChangeoverTypeForm);