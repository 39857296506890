import React from 'react';
import { EmptyRecord, useStyles } from '../../../../styles';
import MachineUsageHeader from './Header';
import { connect, useDispatch, useSelector } from 'react-redux';
import { 
    getLatestMachineUsage,  
    setViewByLatestMachineUsage 
} from '../../../../../../../../redux/actions/MachineSummary/machineSummaryAction';
import TableData from './TableData';
import { legends, selectOptions } from './constants';
import { closeModal, showModal } from '../../../../../../../../redux/actions/Modal/modalActions';
import { fetchLatestMachineUsage } from '../../../api';
import { downloadMachineSummaryReport, MACHINE_SUMMARY_REPORT_TYPES } from '../../excel';

const MachineUsage = (props) => {
    const { data } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const title = 'Latest Machine Usage'.translate();
    const { query, latestMachineUsageViewBy, latestMachineUsageOrder } 
        = useSelector(({ machineSummaryReducer }) => machineSummaryReducer);
    
    const handleExport = () => {
        props.showModal('excelLoading');
        Promise.all([
            fetchLatestMachineUsage(query, 1, latestMachineUsageOrder),
            fetchLatestMachineUsage(query, 2, latestMachineUsageOrder),
            fetchLatestMachineUsage(query, 3, latestMachineUsageOrder),
          ])
          .then((result) => {
            const data = result.map(({ latestMachineUsage }) => latestMachineUsage.data);
            downloadMachineSummaryReport(MACHINE_SUMMARY_REPORT_TYPES.LatestMachineUsage, data);
          })
          .finally(() => {
            props.closeModal();
          })
    };

    const handleOnChangeViewBy = (e) => {
        const value = e.target.value;
        dispatch(getLatestMachineUsage(
            query, 
            value, 
            latestMachineUsageOrder,
        ));
        dispatch(setViewByLatestMachineUsage(value));
    };

    return (
        <div className={[classes.card, classes.card260].join(' ')}>
            <MachineUsageHeader
                title={title}
                viewBy={latestMachineUsageViewBy}
                onChangeViewBy={handleOnChangeViewBy}
                onExport={handleExport}
                legends={legends} />

            {data.length > 0
                ? <TableData selectOptions={selectOptions} />
                : <EmptyRecord text={'No records found'.translate()} />}
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        showModal: (modal) => dispatch(showModal(modal)),
        closeModal: () => dispatch(closeModal())
    }
}

export default connect(null, mapDispatchToProps)(MachineUsage);