import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, OutlinedInput, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles({
  formControl: {
    marginRight: 5,
  },
  adornedStart: {
    borderRadius: '5px !important',
    color: '#515151',
  },
  inputAdornedStart: {
    padding: 4
  },
  input: {
    paddingTop: 8,
    paddingBottom: 8,
    '&::-webkit-input-placeholder': {
      color: '#515151'
    }
  }
})

export default function SearchInput(props) {
  const classes = useStyles();

  return (
    <FormControl
      fullWidth
      variant="outlined"
      className={classes.formControl}
    >
      <OutlinedInput
        {...props}
        startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
        labelWidth={0}
        placeholder={props.placeholder || 'Search'}
        className={classes.input}
        classes={{
          inputAdornedStart: classes.inputAdornedStart,
          adornedStart: classes.adornedStart
        }}
      />
    </FormControl>
  );
}
