import React from 'react';
import { Router } from 'react-router-dom';
import { connect } from "react-redux";
import { postLogout } from '../../../redux/actions/Auth/authActions';
import history from '../../../history';

class Logout extends React.Component {

  constructor(props){
      super(props)
      this.state = {
    }
  }

componentDidMount(){
    this.props.postLogout();
  }

  render(){
    return (
      <div className="logout">
      </div>
    );
  }
}

const mapStateToProps = state => {
    let apiLoading = {
        loginLoading: state.authReducer.loading,
    }
    let data = {
    }
    
    return {
        ...apiLoading,
        ...data,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        postLogout: (credentials) => dispatch(postLogout(credentials)),
    }
}

Logout = connect(mapStateToProps,mapDispatchToProps)(Logout);

export default Logout;
