import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import StarRounded from '@material-ui/icons/StarRounded';

const Container = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
})(Box);

const LegendIndicator = withStyles({
  root: (props) => ({
    backgroundColor: props.color,
    width: 16,
    height: 16,
    borderRadius: 2,
    marginLeft: 4,
    marginRight: 4,
  }),
})(Box);

const IndicatorContainer = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    marginLeft: 10,
    marginRight: 10,
  },
})(Box);

export default function Legend(props) {
  return (
    <Container>
      <IndicatorContainer>
        <LegendIndicator color="#72B9FF" />
        <Typography>{'CURRENT'.translate()}</Typography>
      </IndicatorContainer>
      <IndicatorContainer>
        <LegendIndicator color="#4CAF50" />
        <Typography>{'NEW'.translate()}</Typography>
      </IndicatorContainer>
      <IndicatorContainer>
        <LegendIndicator color="#FF7A27" />
        <Typography>{'IN PROGRESS'.translate()}</Typography>
      </IndicatorContainer>
      <IndicatorContainer>
        <LegendIndicator color="#9F05F0" />
        <Typography>{'READY'.translate()}</Typography>
      </IndicatorContainer>
      <IndicatorContainer>
        <LegendIndicator color="#AFAFAF" />
        <Typography>{'CLOSED'.translate()}</Typography>
      </IndicatorContainer>
      <IndicatorContainer>
        <StarRounded style={{ color: '#FFB53E' }} />
        <Typography>{'CRITICAL MACHINE'.translate()}</Typography>
      </IndicatorContainer>
    </Container>
  );
}
