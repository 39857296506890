import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import history from '../../../../..//history';
import { Link } from 'react-router-dom';
import { validateFields } from './FormValidation';
import { getFactoryId } from '../../../../../utils/helper.js';
import { notificationError } from '../../../../../redux/actions/Notification/notificationActions';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { postMotorTypeNew, getMotorTypeDetail, putMotorTypeUpdate, motorTypeTriggerHighlight } from '../../../../../redux/actions/MotorTypes/motorTypeActions';
import MainPage from '../../../../../components/MainPage/MainPage';
import MainPageHeader from '../../../../../components/MainPageHeader/MainPageHeader';
import MainPageBody from '../../../../../components/MainPageBody/MainPageBody';
import dictionary from '../../../../../redux/actions/Translation/dictionary'
import './MotorTypeForm.scss';
import { formatDate } from '../../../../../utils/date';

class MotorTypeForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            translations: {
                ...this.convert()
            },
            translationTimestamp: undefined,
            title: null,
            saveLabel: null,
            factoryId: null,
            id: null,
            hasFormChanges: false,
            disablePrompt: false,
            form: {
                code: null,
                name: null,
                desc: null,
                isActive: null
            },
            formErrors: []
        }
    }
    convert = () => {
        let { NOTIFICATIONS: { are_you_sure_to_leave_this_page, motor_type_code_exists }, EDIT_MOTOR_TYPE, ADD_MOTOR_TYPE, MACHINE_TYPE_CODE, MOTOR_TYPE_CODE, SAVE, ADD, CANCEL, STATUS, Active, Inactive, UPDATED_BY, UPDATED_DATE, CREATED_BY, CREATED_DATE,
        BRAND_CODE,DISPLAY_NAME,COMPANY_NAME, WEBSITE,DESCRIPTION, SHORT_NAME_FOR_SYSTEM_DISPLAY
        } = dictionary.GET()
        return dictionary.TRANSLATE({
            are_you_sure_to_leave_this_page, motor_type_code_exists,
            EDIT_MOTOR_TYPE, ADD_MOTOR_TYPE, MACHINE_TYPE_CODE, MOTOR_TYPE_CODE, SAVE, ADD, CANCEL, STATUS, Active, Inactive, UPDATED_BY, UPDATED_DATE, CREATED_BY, CREATED_DATE,
            BRAND_CODE,DISPLAY_NAME,COMPANY_NAME, WEBSITE,DESCRIPTION, SHORT_NAME_FOR_SYSTEM_DISPLAY
        })
        
    }
    handleFormChange = ({ target }) => {
        let tmpForm = this.state.form;
        tmpForm[target.name] = target.value;
        this.setState({ form: tmpForm, hasFormChanges: true });
        this.removeErrorOnChange(target.name);
    };

    handleCodeFormChange = ({ target }) => {
        let value = null;
        if (target.value.indexOf(" ") >= 0) {
            value = target.value.replace(" ", "");
        }
        else {
            value = target.value;
        }
        let tmpForm = this.state.form;
        tmpForm[target.name] = value;
        this.setState({ form: tmpForm, hasFormChanges: true });
        this.removeErrorOnChange(target.name);
    };

    handleRadioButtonChange = ({ target }) => {
        let tmpForm = this.state.form;
        tmpForm[target.name] = parseInt(target.value);
        this.setState({ form: tmpForm, hasFormChanges: true });
        this.removeErrorOnChange(target.name);
    };

    checkMotorTypeTrigger() {
        let {translations} = this.state
        if (this.props.triggerHighlight) {
            let errors = [];
            let formValid = false;
            errors.push({ name: 'code', message: translations.motor_type_code_exists});

            let tmpFormError = {
                valid: formValid,
                errors: errors
            }

            this.setState({ formErrors: tmpFormError.errors }, () => {
                this.props.motorTypeTriggerHighlight(false);
            });
        }
    }

    componentDidMount() {
        let {translations} = this.state
        document.body.style.backgroundColor = "#F7F7FB";
        let factoryId = getFactoryId();
        if (this.props.match.params.id) {
            this.props.getMotorTypeDetail(this.props.match.params.id);
            this.setState({ title: translations.EDIT_MOTOR_TYPE, saveLabel: translations.SAVE, id: this.props.match.params.id, factoryId: factoryId });
        }
        else {
            this.setState({ title: translations.ADD_MOTOR_TYPE, saveLabel: translations.ADD, factoryId: factoryId });
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.motorTypeDetail !== null && nextProps.motorTypeDetail !== undefined && this.props.match.params.id !== null && this.props.match.params.id !== undefined) {
            let tmpForm = {
                code: nextProps.motorTypeDetail.motorType,
                name: nextProps.motorTypeDetail.desc1,
                desc: nextProps.motorTypeDetail.desc2,
                isActive: this.renderBooleanAsInteger(nextProps.motorTypeDetail.isActive)
            }
            this.setState({ form: tmpForm })
        }
    }

    renderBooleanAsInteger(bool) {
        if (bool === true) {
            return 1
        }
        else {
            return 0
        }
    }

    renderText(field) {
        if (field === null || field === '') {
            return '-'
        }
        else {
            //   return field != undefined ? field.toString().translate() : field
            return field
        }
    }

    renderDate(date) {
        if (date !== null) {
            let timezone = moment.parseZone(date).format('Z');
            let dateTimeOnly = moment.parseZone(date).format('YYYY-MM-DD HH:mm:ss') + '+00:00';
            return moment(dateTimeOnly).utcOffset(timezone).format('DD-MM-YYYY');
        }
        else {
            return '-';
        }
    }

    submitForm = () => {
        let payload = {
            ...this.state.form,
            factoryId: this.state.factoryId
        };
        let formValidation = validateFields(payload);
        this.setState({ formErrors: formValidation.errors });
        if (formValidation.valid) {
            this.setState({ disablePrompt: true }, () => {
                if (this.state.id === null) {
                    delete payload.isActive;
                    this.props.postMotorTypeNew(payload);
                }
                else {
                    this.props.putMotorTypeUpdate(this.state.id, payload);
                }
            })
        }
        else {
            this.props.notificationError(formValidation.errors[0].message);
        }
    }

    removeErrorOnChange(field) {
        let errors = this.state.formErrors.filter((item) => { return item.name !== field });
        this.setState({ formErrors: errors });
    }

    hasErrors = (field) => {
        let checkField = this.state.formErrors.filter((item) => { return item.name === field });
        if (checkField.length > 0) {
            return true
        }
        else {
            return false;
        }
    }

    getErrors = (field) => {
        let checkField = this.state.formErrors.filter((item) => { return item.name === field });
        let errorString = null;
        checkField.map((item) => {
            errorString += item.message + '. ';
            return '';
        })
        return errorString;
    }

    addForm() {
        let {translations} = this.state
        return (
            <div className="form-container">
                <Paper className="body">
                    <div className="title">{this.state.title}</div>
                    <div className="form-input">
                        <form autoComplete="off">
                            <FormControl className="control-row" fullWidth>
                                <TextField className={this.state.form.code ? 'label-green' : null} error={this.hasErrors('code')} value={this.state.form.code} onChange={this.handleCodeFormChange} name="code" required placeholder={translations.SHORT_NAME_FOR_SYSTEM_DISPLAY} label={translations.MOTOR_TYPE_CODE} InputLabelProps={{ shrink: true }} />
                            </FormControl>
                            <FormControl className="control-row" fullWidth>
                                <TextField className={this.state.form.name ? 'label-green' : null} error={this.hasErrors('name')} value={this.state.form.name} onChange={this.handleFormChange} name="name" required placeholder={translations.SHORT_NAME_FOR_SYSTEM_DISPLAY} label={translations.DISPLAY_NAME} InputLabelProps={{ shrink: true }} />
                            </FormControl>
                            <FormControl className="control-row" fullWidth>
                                <TextField className={this.state.form.desc ? 'label-green' : null} error={this.hasErrors('desc')} value={this.state.form.desc} onChange={this.handleFormChange} name="desc" label={translations.DESCRIPTION} InputLabelProps={{ shrink: true }} />
                            </FormControl>
                        </form>
                    </div>
                </Paper>
            </div>
        );
    }

    editForm() {
        let {translations} = this.state
        return (
            <div className="form-container">
                <form autoComplete="off">
                    <Paper className="body-edit">
                        <div className="title">{this.state.title}</div>
                        <div className="form-input">
                            <FormControl className="control-row" fullWidth>
                                <TextField disabled={this.state.id} value={this.state.form.code} onChange={this.handleFormChange} name="code" required placeholder={translations.SHORT_NAME_FOR_SYSTEM_DISPLAY} label={translations.MOTOR_TYPE_CODE} InputLabelProps={{ shrink: true }} />
                            </FormControl>
                            <FormControl className="control-row" fullWidth>
                                <TextField value={this.state.form.name} onChange={this.handleFormChange} name="name" required placeholder={translations.SHORT_NAME_FOR_SYSTEM_DISPLAY} label={translations.DISPLAY_NAME} InputLabelProps={{ shrink: true }} />
                            </FormControl>
                            <FormControl className="control-row" fullWidth>
                                <TextField value={this.state.form.desc} onChange={this.handleFormChange} name="desc" label={translations.DESCRIPTION} InputLabelProps={{ shrink: true }} />
                            </FormControl>
                        </div>
                    </Paper>
                    <div className="space"></div>
                    <Paper className="body-details end">
                        <div className="form-input">
                            <FormControl className="control-row" fullWidth>
                                <Grid container spacing={0}>
                                    <Grid item xs={5}>
                                        <FormLabel className="status" component="legend">{translations.STATUS}</FormLabel>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <RadioGroup onChange={this.handleRadioButtonChange} name="isActive" className="radioButton" row value={this.state.form.isActive}>
                                            <FormControlLabel value={1} control={<Radio color="primary" />} label={translations.Active} />
                                            <FormControlLabel value={0} control={<Radio color="primary" />} label={translations.Inactive} />
                                        </RadioGroup>
                                    </Grid>
                                    <Grid className="statusDetails" item xs={12}>
                                        <FormLabel component="legend">{translations.UPDATED_BY}: {this.props.motorTypeDetail ? this.renderText(this.props.motorTypeDetail.updatedBy) : null}</FormLabel>
                                    </Grid>
                                    <Grid className="statusDetails" item xs={12}>
                                        <FormLabel component="legend">{translations.UPDATED_DATE}: {this.props.motorTypeDetail ? formatDate(this.props.motorTypeDetail.updatedDt) : null}</FormLabel>
                                    </Grid>
                                    <Grid className="statusDetails" item xs={12}>
                                        <FormLabel component="legend">{translations.CREATED_BY}: {this.props.motorTypeDetail ? this.renderText(this.props.motorTypeDetail.createdBy) : null}</FormLabel>
                                    </Grid>
                                    <Grid className="statusDetails" item xs={12}>
                                        <FormLabel component="legend">{translations.CREATED_DATE}: {this.props.motorTypeDetail ? formatDate(this.props.motorTypeDetail.createdDt) : null}</FormLabel>
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </div>
                    </Paper>
                </form>
            </div>
        );
    }

    footer() {
        let {translations} = this.state
        return (
            <div className="footer-actions">
                <Grid container spacing={1}>
                    <Grid item xs={5} />
                    <Grid item xs={1}>
                        {/* <Link to="/configuration/machine-config/motor-type"> */}
                        <Button onClick={() => this.handleLinkClick('/configuration/machine-config/motor-type')} className="cancel" fullWidth variant="outlined">{translations.CANCEL}</Button>
                        {/* </Link> */}
                    </Grid>
                    <Grid item xs={1}>
                        <Button onClick={this.submitForm} className="save" fullWidth color="primary" variant="contained">{this.state.saveLabel}</Button>
                    </Grid>
                    <Grid item xs={5} />
                </Grid>
            </div>
        )
    }



    resetForm() {
        let tmpForm = {
            code: null,
            name: null,
            desc: null,
            isActive: null
        }
        this.setState({ form: tmpForm });
    }

    content() {
        if (this.state.title === 'Add Motor Type') {
            console.log('title is add motor type')
            return this.addForm();
        }
        else {
            return this.editForm();
        }
    }

    componentDidUpdate = () => {
        let { translations } = this.state
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                translations: Object.assign({}, { ...this.convert() }),
                title: this.props.match.params.id ? this.convert().EDIT_MOTOR_TYPE : this.convert().ADD_MOTOR_TYPE,
                saveLabel: this.props.match.params.id ? this.convert().SAVE : this.convert().ADD
            })
        }
        if (this.state.hasFormChanges) {
            window.onbeforeunload = () => { return translations.are_you_sure_to_leave_this_page }
        }
        else {
            window.onbeforeunload = undefined
        }
    }



    componentWillUnmount() {
        document.body.style.backgroundColor = null;
        this.resetForm();
        if (this.state.hasFormChanges) {
            window.onbeforeunload = undefined
        }
    }

    handleLinkClick = (url) => {
        if (this.state.id) {
            history.push(url);
        }
        else {
            this.setState({ disablePrompt: true }, () => {
                history.push(url);
            })
        }
    }

    render() {
        let {translations} = this.state
        return (
            <MainPage footer={false} className='motor-type-form'>
                <Prompt
                    when={this.state.hasFormChanges && this.state.disablePrompt === false}
                    message={translations.are_you_sure_to_leave_this_page}
                />
                { this.checkMotorTypeTrigger()}
                <MainPageHeader title="Motor Type" search={false} />
                <MainPageBody>
                    {this.content()}
                    {this.footer()}
                </MainPageBody>
            </MainPage>
        );
    }
}

const mapStateToProps = state => {
    let { translationReducer } = state
    let apiLoading = {
        detailLoading: state.motorTypeListReducer.detailLoading
    }
    let data = {
        motorTypeDetail: state.motorTypeListReducer.motorTypeDetail,
        triggerHighlight: state.motorTypeListReducer.triggerHighlight
    }

    return {
        ...apiLoading,
        ...data,
        ...translationReducer

    }
}

const mapDispatchToProps = dispatch => {
    return {
        notificationError: (message) => dispatch(notificationError(message)),
        motorTypeTriggerHighlight: (flag) => dispatch(motorTypeTriggerHighlight(flag)),
        getMotorTypeDetail: (id) => dispatch(getMotorTypeDetail(id)),
        postMotorTypeNew: (payload) => dispatch(postMotorTypeNew(payload)),
        putMotorTypeUpdate: (id, payload) => dispatch(putMotorTypeUpdate(id, payload))
    }
}

MotorTypeForm = connect(mapStateToProps, mapDispatchToProps)(MotorTypeForm);

export default MotorTypeForm;
