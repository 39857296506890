import React from 'react';
import { 
    Box, 
    Button, 
    IconButton, 
    Typography,
} from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import { useHistory } from 'react-router-dom';
import { numberWithCommas } from '../../../../utils/helper';

const Filters = ({ 
    machineUpFilter, 
    meta, 
    machineUpCount, 
    onChange,
}) => {

    const history = useHistory();
    const buttons = [
        { name: 'OVERDUE', key: 'overdue' },
        { name: 'TODAY', key: 'today'},
        { name: 'THIS WEEK', key: 'week'},
        { name: 'THIS MONTH', key: 'month' },
        { name: 'All', key: 'all' },
    ];
    return (
        <Box display='flex' gridGap={5} justifyContent='flex-end' alignItems='center'>
            <Typography style={{ marginRight: 5 }} variant='h6'>{'DUE'.translate()}</Typography>
            {buttons.map(({ name, key }) => (
                <Button 
                    className={machineUpFilter.pmPlanDt === key ? 'active' : ''} 
                    onClick={() => onChange(key)} 
                    fullWidth 
                    variant="outlined" 
                    color="primary">
                    {name.translate()}
                    ({machineUpFilter.pmPlanDt === key 
                        ? meta.count 
                        : numberWithCommas(machineUpCount[key])
                    })
                </Button>
            ))}
            <IconButton onClick={() => history.push('maintenance-calendar')}>
                <TodayIcon style={{ color: '#0f75bc', fontSize: 24 }} />
            </IconButton>
        </Box>
    );
}

export default Filters;