import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { 
    FlexBox, 
    ExportIcon, 
    IndicatorContainer, 
    LegendIndicator,
} from './styles';
import { BetaIcon } from '../styles/styles';

export default function Header(props){
    return (
        <FlexBox>
            <FlexBox>
                <Typography variant='h4'>{'Line NPT Analysis'.translate()}</Typography>
                <BetaIcon />
            </FlexBox>
            <FlexBox>
                <IndicatorContainer>
                    <LegendIndicator color={props.colors[0]} />
                    <Typography>{'No.1 Problem Type'.translate()}</Typography>
                </IndicatorContainer>
                <IndicatorContainer>
                    <LegendIndicator color={props.colors[1]} />
                    <Typography>{'No.2 Problem Type'.translate()}</Typography>
                </IndicatorContainer>
                <IndicatorContainer>
                    <LegendIndicator color={props.colors[2]} />
                    <Typography>{'No.3 Problem Type'.translate()}</Typography>
                </IndicatorContainer>
            </FlexBox>
            <IconButton onClick={props.handleExport} size='small'>
                <ExportIcon />
            </IconButton>
        </FlexBox>
    )
}   