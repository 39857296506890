
import { CALENDAR_ACTIONS as actionList } from '../../actions/actionTypes'

const tickets = {
}
const calendarReducer = (state = { tickets }, action) => {
    switch (action.type) {
        case actionList.FETCH_BY_MONTH:
            return {
                ...state,
                ...action.payload
            }
        case actionList.FETCH_BY_DATE:
            return {
                ...state,
                ...action.payload
            }
        case actionList.GENERATE_MACHINE:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}
export default calendarReducer