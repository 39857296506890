import React from 'react';
import { TableHead, TableRow } from "@material-ui/core";
import { 
    MuiTableSortLabel, 
    TableHeaderCell,
} from "../styles";
import { tableHeaders } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import { 
    getMachinePerformance, 
    setOrderMachinePerformance, 
} from '../../../../../../../../redux/actions/MachineSummary/machineSummaryAction';

function TableHeader() {
    const dispatch = useDispatch();
    const { 
        machinesPerformance, 
        machinesPerformanceOrder,
        machinesPerformanceOrderBy,
        query,
      } = useSelector(({machineSummaryReducer}) => machineSummaryReducer);

    const handleRequestSort = (property) => {
        const isAsc = machinesPerformanceOrderBy == property && machinesPerformanceOrder == 'asc';
        dispatch(
            setOrderMachinePerformance(
                isAsc ? 'desc' : 'asc'
            )
        );
        dispatch(
            getMachinePerformance(
                query, 
                isAsc ? 'desc' : 'asc', 
                machinesPerformance.pagination.page, 
                machinesPerformance.pagination.pageSize,
            )
        );
    };
    return (
        <TableHead>
            <TableRow key='machinePerformanceHeaderTable'>
                {tableHeaders?.map((row) => (
                <TableHeaderCell active={row.orderBy == machinesPerformanceOrderBy}>
                        <MuiTableSortLabel
                        active={row.orderBy == machinesPerformanceOrderBy}
                        direction={row.orderBy == machinesPerformanceOrderBy ? machinesPerformanceOrder : 'asc'}
                        onClick={() => row.order && handleRequestSort(row.orderBy)}
                        >
                        {row.name}
                        </MuiTableSortLabel>
                    </TableHeaderCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default TableHeader;