import React from 'react';
import {
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Box,
} from '@material-ui/core';
import CardPaper from '../components/CardPaper';
import Typography from '../components/Typography';
import CriticalMark from '../components/CriticalMark';
import Table from './LineSetup/Table';
import TableHeaderCell from './LineSetup/TableHeaderCell';
import TableCell from './LineSetup/TableCell';
import StationCell from './LineSetup/StationCell';
import IndicatorCell from './LineSetup/IndicatorCell';
import DoubleDividers from './LineSetup/DoubleDividers';
import Totals from './LineSetup/Totals';
import TableImageList from '../components/TableImageList';

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export default function LineSetup({ changeover }) {
  const { machines } = changeover;
  const stations = machines.map((machine) => machine.station);
  const uniqueStations = stations.filter(onlyUnique).sort((a, b) => a - b);

  return (
    <CardPaper my={1}>
      <Typography variant="h5">
        {'LINE SETUP INFORMATION'.translate()}
      </Typography>
      <Box mt={1}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell>
                  {'STATION'.translate()}
                </TableHeaderCell>
                <TableHeaderCell>
                  {'CRITICAL'.translate()}
                </TableHeaderCell>
                <TableHeaderCell>
                  {'M/C'.translate()}
                </TableHeaderCell>
                <TableHeaderCell>
                  {'NEEDLE TYPE'.translate()}
                </TableHeaderCell>
                <TableHeaderCell>
                  {'ATTACHMENT'.translate()}
                </TableHeaderCell>
                <TableHeaderCell>
                  {'DESCRIPTION'.translate()}
                </TableHeaderCell>
                <TableHeaderCell>
                  {'REMARKS'.translate()}
                </TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {
              uniqueStations.map((station) => {
                const machinesInStation = machines.filter((machine) => machine.station == station);
                return machinesInStation.map((machine, index) => {
                  return <TableRow key={machine.id}>
                    {
                      index == 0 && (
                        <StationCell rowSpan={machinesInStation.length}  align='center'>
                          { machine.station }
                        </StationCell>
                      )
                    }
                    <IndicatorCell align='center'>
                      <CriticalMark isActive={machine.isCritical} />
                    </IndicatorCell>
                    <TableCell>
                      { machine.machineSubType }
                    </TableCell>
                    <TableCell>
                      { machine.needleType }
                    </TableCell>
                    <TableCell>
                      <Box style={{ height: 30 }}>
                        <TableImageList
                          images={machine.attachments}
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      { machine.description }
                    </TableCell>
                    <TableCell>
                      { machine.remarks }
                    </TableCell>
                  </TableRow>
                })

              })
            }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <DoubleDividers />

      <Totals
        changeover={changeover}
      />
    </CardPaper>
  )
}