import {
    SOLUTION_LIST_REQUEST,
    SOLUTION_LIST_SUCCESS,
    SOLUTION_LIST_ERROR,
    SOLUTION_DETAIL_REQUEST,
    SOLUTION_DETAIL_SUCCESS,
    SOLUTION_DETAIL_ERROR,
    SOLUTION_NEW_REQUEST,
    SOLUTION_NEW_SUCCESS,
    SOLUTION_NEW_ERROR,
    SOLUTION_UPDATE_REQUEST,
    SOLUTION_UPDATE_SUCCESS,
    SOLUTION_UPDATE_ERROR,
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    detailLoading: false,
    closeModal: false,
    solutionList:[],
    solutionDetail: null,
    meta: [],
    error: ''
}

const solutionListReducer = (state = initialState, action) => {
    switch (action.type) {
        case SOLUTION_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SOLUTION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                solutionList: action.payload.solutionList,
                meta: action.payload.meta,
                error: ''
            }
        case SOLUTION_LIST_ERROR:
            return {
                ...state,
                loading: false,
                solutionList: [],
                meta: [],
                error: action.payload.message
            }
        case SOLUTION_DETAIL_REQUEST:
            return {
                ...state,
                detailLoading: true,
            }
        case SOLUTION_DETAIL_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                solutionDetail: action.payload.solutionDetail
            }
        case SOLUTION_NEW_REQUEST:
            return {
                ...state,
                detailLoading: true,
                closeModal: false
            }
        case SOLUTION_NEW_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                closeModal: true
            }
        case SOLUTION_UPDATE_REQUEST:
            return {
                ...state,
                detailLoading: true,
                closeModal: false
            }
        case SOLUTION_UPDATE_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                closeModal: true
            }
        default: 
            return state;
    }
}

export default solutionListReducer;