export const hex2rgba = (hex, alpha = 1)=>{
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export const datasetKeyProvider = () => btoa(Math.random()).substring(0,12);

export const getTotalTickets = (data) => data.reduce((sum, d)=>(sum + d.totalTickets), 0);

export const getOptions = (data) => {
  const totalTickets = getTotalTickets(data);
  return {
    label: `${totalTickets}\n${'Total Repair'.translate()}\n${'Tickets'.translate()}`,
    responsive: true,
    maintainAspectRatio: true,
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var index = tooltipItem.index;
          var dataSetIndex = tooltipItem.datasetIndex;
          var percentage = dataSetIndex === 1 ? '%' : '';
          return dataset.labels[index] + ": " + dataset.data[index] + percentage;
        }
      }
    },
    plugins: {
      legend: false,
      tooltip: false,
      datalabels: {
        color: '#ffffff',
        formatter: (value, { datasetIndex }) => !datasetIndex ? parseFloat(value / totalTickets * 100).toFixed(1) + '%' : '',
      },
      filter: {
        selected: data.map((d)=> d.isSelected)
      }
    }
  };
};

export const getChartData = (data) => {
  
  const totalOnScheduleTicketsP = data.filter((row) => row.isSelected)
    .reduce((sum, row) => sum + row.totalOnScheduleTicketP, 0);

  const totalOverdueTicketsP = data.filter((row) => row.isSelected)
    .reduce((sum, row) => sum + row.totalOverdueTicketsP, 0);

  return {
    labels: data?.map((d)=> d.name),
    datasets: [
      {
        data: data?.map((d)=> d.totalTickets),
        backgroundColor: data?.map((d)=> d.isSelected ? hex2rgba(d.color, 1) : hex2rgba(d.color, 0.5)),
        borderWidth: 5,
        weight:5,
        labels: data?.map((d)=> d.name),
      },
      {
        data: [parseFloat(totalOnScheduleTicketsP).toFixed(1), parseFloat(totalOverdueTicketsP).toFixed(1)],
        backgroundColor: ['#6DDDA5', '#FF7E7E'],
        borderWidth: 5,
        weight:3,
        labels: ['On Schedule', 'Overdue'],
      }
    ],
  };
};