import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Transition, useStyles } from '../styles';
import { Box, DialogContent } from '@material-ui/core';
import ButtonSyncData from './Components/ButtonSyncData';
import Header from './Components/Header';
import CardTotal from './Components/CardTotal';
import { connect } from 'react-redux';
import { 
  getCountTotal, 
  getLatestMachineDistribution, 
  getLatestMachineUsage,
  dialogToggle,
  setQuery,
  getMachinePerformance,
} 
from '../../../../../redux/actions/MachineSummary/machineSummaryAction';
import MachineDistribution from './Components/CardLatest/MachineDistribution';
import MachineUsage from './Components/CardLatest/MachineUsage';
import LocationsFilter from './Filters/LocationsFilter';
import MachinePerformance from './Components/CardMachinePerformance';

function UserSummaryDialog({
  openDialog,
  isLoading,
  countTotal,
  
  getLatestMachineUsage,
  latestMachineUsage,
  latestMachineUsageViewBy,
  latestMachineUsageOrder,

  getLatestMachineDistribution,
  latestMachineDistribution,
  latestMachineDistributionViewBy,

  getMachinePerformance,
  machinesPerformance,
  machinesPerformanceCurrentPage,
  machinesPerformanceTotal,
  machinesPerformancePageSize,
  machinesPerformanceOrder,
  machinesPerformanceOrderBy,

  query,
  getCountTotal,
  dialogToggle,
  setQuery,
  countTotalViewBy,
}) {
  const classes = useStyles();

  React.useEffect(() => {
    openDialog && handleFetchAllData();
  }, [JSON.stringify(query)]);

  const handleFetchAllData = () => {
    getCountTotal(query, countTotalViewBy);
    getLatestMachineDistribution(query, latestMachineDistributionViewBy);
    getLatestMachineUsage(query, latestMachineUsageViewBy, latestMachineUsageOrder);
    getMachinePerformance(query);
  };

  return (
    <Dialog fullScreen open={openDialog} onClose={() => dialogToggle()} TransitionComponent={Transition}>
      <Header onClose={dialogToggle} />
      <DialogContent className={classes.content}>
        <Box display='flex' justifyContent='space-between' alignContent='center'>
            <LocationsFilter onChange={(query)=> setQuery(query)}/>
            <ButtonSyncData isLoading={isLoading} onClick={() => handleFetchAllData()} />
        </Box>
        <CardTotal data={countTotal} />
        <div className={[classes.grid, classes.grid2].join(' ')}>
          <MachineDistribution data={latestMachineDistribution} />
          <MachineUsage data={latestMachineUsage} />
        </div>
        <MachinePerformance
          data={machinesPerformance}
          total={machinesPerformanceTotal}
          pageSize={machinesPerformancePageSize}
          page={machinesPerformanceCurrentPage}
          order={machinesPerformanceOrder}
          orderBy={machinesPerformanceOrderBy}
          query={query}
        />
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = ({ machineSummaryReducer, translationReducer }) => ({
  openDialog: machineSummaryReducer.openDialog,

  isLoading: machineSummaryReducer.isLoading,
  countTotal: machineSummaryReducer.countTotal,

  latestMachineDistribution: machineSummaryReducer.latestMachineDistribution,
  latestMachineDistributionViewBy: machineSummaryReducer.latestMachineDistributionViewBy,

  latestMachineUsage: machineSummaryReducer.latestMachineUsage,
  latestMachineUsageViewBy: machineSummaryReducer.latestMachineUsageViewBy,
  
  machinesPerformance: machineSummaryReducer.machinesPerformance.data,
  machinesPerformanceCurrentPage: machineSummaryReducer.machinesPerformance.pagination.page,
  machinesPerformanceTotal: machineSummaryReducer.machinesPerformance.pagination.total,
  machinesPerformancePageSize: machineSummaryReducer.machinesPerformance.pagination.pageSize,

  query: machineSummaryReducer.query,
  countTotalViewBy: machineSummaryReducer.countTotalViewBy,
  ...translationReducer
});
const mapDispatchToProps = dispatch => {
  return {
      getCountTotal: (query, viewBy) => dispatch(getCountTotal(query, viewBy)),
      getLatestMachineDistribution: (query, viewBy) => dispatch(getLatestMachineDistribution(query, viewBy)),
      getLatestMachineUsage: (query, viewBy) => dispatch(getLatestMachineUsage(query, viewBy)),
      getMachinePerformance: (query, page, pageSize, orderBy) => 
        dispatch(getMachinePerformance(query, page, pageSize, orderBy)),
      dialogToggle: (query, viewBy) => dispatch(dialogToggle(query, viewBy)),
      setQuery: (payload) => dispatch(setQuery(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserSummaryDialog);
