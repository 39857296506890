import React, { useEffect, useState } from 'react';
import { TableContainer } from '@material-ui/core';
import CardPaper from '../components/CardPaper';
import { TableStyle } from './styles';
import TableHeader from './table/header';
import TableBody from './table/body';
import Header from './header';
import { fetchLineNPT } from '../api';
import TableBodyEmpty from './table/bodyEmpty';
import { connect } from 'react-redux';
import { closeModal, showModal } from '../../../../redux/actions/Modal/modalActions';
import { ANALYTIC_REPORT_TYPES, downloadAnalyticReport } from '../excel';
import { sortingProblemTypesNPT } from '../../../../utils/helper';
import { getDateRangeQueryString } from '../helpers';

const LineNPTCard = (props) => {
    const { filters, isCron } = props;
    const colors = ['#f8666a', '#f09784', '#efe17b'];
    const [data, setData] = useState([]);
    const [orderDirection, setOrderDirection] = useState('desc');
    const handleOrder = () => {
        setOrderDirection(orderDirection === 'desc' ? 'asc' : 'desc');
    };
    const handleExport = () => {
        props.showModal('excelLoading');
        fetchLineNPT({ 
            filters: getDateRangeQueryString(filters),
            orderDirection,
            orderBy: 'totalNptTime',
            mode: 'all',
        }).then(({ linenpt }) => {
            downloadAnalyticReport(ANALYTIC_REPORT_TYPES.LineNpt, sortingProblemTypesNPT(linenpt.data));
        }).finally(() => {
            props.closeModal();
        });
    };
    useEffect(()=> {
        if(isCron) return;
        fetchLineNPT({ 
            filters: getDateRangeQueryString(filters),
            orderDirection,
            orderBy: 'totalNptTime',
            mode: 'summary',
        }).then(({ linenpt }) => setData(sortingProblemTypesNPT(linenpt.data)));
    }, [props.filters, orderDirection]);

    return (
        <CardPaper>
            <Header handleExport={handleExport} colors={colors} />
            <TableContainer>
                <TableStyle>
                    <TableHeader handleOrder={handleOrder} />
                    { data.length > 0 
                        ? <TableBody 
                            dateRange={props.dateRange} 
                            data={data} 
                            colors={colors} /> 
                        : <TableBodyEmpty />
                    }
                </TableStyle>
            </TableContainer>
        </CardPaper>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        showModal: (modal) => dispatch(showModal(modal)),
        closeModal: () => dispatch(closeModal())
    }
}
const mapStateToProps = state => ({...state.translationReducer});

export default connect(mapStateToProps, mapDispatchToProps)(LineNPTCard);