import React, { useEffect } from 'react';
import { checkPageActionAccess, checkPageAccessField } from '../../../../utils/helper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Calendar from 'react-calendar';
import DateRangeInput from '@src/components/DateRangeInput/index.js';
import { addDays, endOfDay, startOfDay } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    padding: '2px',
    width: '100%',
  },
  select: {
    paddingLeft: '5px',
    background: '#F2F2F2'
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
  },
  root: {
    flexGrow: 1,
  },
  label: {
    position: 'relative',
    top: '3px',
    display: 'block',
    textAlign: 'center',
    fontSize: '1.1rem'
  },
  firefoxDateLabel: {
    left: '-11px'
  },
  labelType: {
    left: '-5px'
  },
  dateRangeInput: {
    '& input': {
      background: '#F2F2F2',
      borderRadius: 0,
      color: '#0066ff',
      fontWeight: '700',
      fontSize: '1.2rem',
    },
    '& input:hover': {
      background: '#F2F2F2',
      borderRadius: 0
    },
    '& input:hover': {
      background: '#F2F2F2',
      borderRadius: 0
    }

  }
}));

const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiSelect: {
      // Name of the rule
      root: {
        padding: '5px',
        fontSize: '1.2rem',
        background: '#F2F2F2',
      },
      icon: {
        // Some CSS
        color: '#0066ff',
      },
      select: {
        color: '#0066ff',
        fontWeight: '700'
      }
    },
  },
});


const returnDateForAPI = (date) => {
  return moment(date).format('YYYY-MM-DD').toString();
}


export default function NativeSelects(props) {
  const languageProps = {
    STATUS: String.prototype.translate && 'STATUS'.translate(),
    REPAIR: String.prototype.translate && 'REPAIR'.translate(),
    MAINTENANCE: String.prototype.translate && 'MAINTENANCE'.translate(),
    TYPE: String.prototype.translate && 'TYPE'.translate(),
    TODAY: String.prototype.translate && 'TODAY'.translate(),
    ALL: String.prototype.translate && 'ALL'.translate(),
    THIS_WEEK: String.prototype.translate && 'THIS WEEK'.translate(),
    THIS_MONTH: String.prototype.translate && 'THIS MONTH'.translate(),
    FROM: String.prototype.translate && 'From'.translate(),
    TO: String.prototype.translate && 'To'.translate(),
    CANCEL: String.prototype.translate && 'CANCEL'.translate(),
    APPLY: String.prototype.translate && 'APPLY'.translate(),
    CREATE_TICKET: String.prototype.translate && 'CREATE TICKET'.translate(),
    CANCEL_TICKET: String.prototype.translate && 'CANCEL TICKET'.translate(),
    DATE: String.prototype.translate && 'DATE'.translate(),
    EXPORT_TO_FILE: String.prototype.translate && 'EXPORT TO FILE'.translate(),
  }

  const isFirefox = typeof InstallTrigger !== 'undefined';
  const { userAccess } = props;
  const classes = useStyles();
  const [load, setLoad] = React.useState(false);
  const localDateRange = localStorage['customDate']
  ? JSON.parse(localStorage['customDate'])
  : '';
  const initializeDateRange = {
    startDate: localDateRange.startDate 
      ? new Date(localDateRange.startDate) 
      : null,
    endDate: localDateRange.endDate 
      ? new Date(localDateRange.endDate) 
      : null,
    interval: "daily",
  };
  const [dateRange, setDateRange] = React.useState(initializeDateRange);

  const getDateFilter = myDateRange => {
    return myDateRange.startDate && myDateRange.endDate 
      ? returnDateForAPI(myDateRange.startDate) + '~' + returnDateForAPI(myDateRange.endDate) 
      : 'all';
  }
  let localFilter = localStorage['ticketFilters'];
  if(localFilter) localFilter = JSON.parse(localFilter);
  const [state, setState] = React.useState({
    searchString: null,
    date: getDateFilter(dateRange),
    type: localStorage['fromCalendar'] ? 'm' : 'r',
    status: props.statusList.find(it => it.status === 'REPORTED')?.id || 'all',
  });
  delete localStorage['fromCalendar']

  // Set default filter status to reported
  useEffect(() => {
    if (props.statusList) {
      const reportedStatus = props.statusList.find(it => it.status === 'REPORTED');
      const status = reportedStatus && state.type === 'r' ? reportedStatus.id : 'all';
      const saveState = {
        ...state,
        status
      };
      setState({...saveState});
      localStorage.setItem('ticketFilters', JSON.stringify({...saveState}));
    }
  }, [props.statusList]);

  // Refetching data when change filter options
  useEffect(() => {
    if (props.statusList && props.statusList.length > 0 && !props.statusListLoading)
      props.getTickets(props.pageSize, 1, state.searchString, state.date, state.type, state.status);
  }, [state.searchString, state.date, state.type, state.status])

  const onLoad = () => {
    if (localStorage.getItem('redirectToTickets') === 'true' && load === false) {
      let filters = JSON.parse(localStorage.getItem('ticketFilters'));
      let customDate = JSON.parse(localStorage.getItem('customDate'));
      props.handleStatusList(filters.type);
      setState(filters);
      if (customDate) {
        if (customDate.applied) { // migrate from old filter
          let custom = {
            ...customDate,
            from: new Date(customDate.from),
            to: new Date(customDate.to),
          }
          const range = {
            startDate: custom.from,
            endDate: custom.to
          };
          setDateRange(range);
          localStorage.setItem('customDate', JSON.stringify(range))
        } else {
          setDateRange({
            startDate: customDate.startDate 
                ? new Date(customDate.startDate) 
                : null,
            endDate: customDate.startDate 
                ? new Date(customDate.endDate) 
                : null,
          });
        }
      }
      setLoad(true);
    }
    else if (load === false) {
      props.handleStatusList('r');
      setLoad(true);
    }
  }

  onLoad();

  const handleTypeChange = (event) => {
    const name = event.target.name;
    let saveState = {
      ...state,
      [name]: event.target.value,
      searchString: props.searchString,
    }
    setState(saveState);
    localStorage.setItem('ticketFilters', JSON.stringify(saveState));
    localStorage.setItem('customDate', JSON.stringify(dateRange));

    props.handleStatusList(event.target.value);
    // handleCustomDateCancel();
  };

  const handleStatusChange = (event) => {
    const name = event.target.name;
    let saveState = {
      ...state,
      [name]: event.target.value,
      searchString: props.searchString,
    }
    setState(saveState);
    localStorage.setItem('ticketFilters', JSON.stringify(saveState));
    localStorage.setItem('customDate', JSON.stringify(dateRange));
    // handleCustomDateCancel();
  };

  const renderStatusList = () => {
    let statusList = [];
    statusList.push(<option value="all">{languageProps.ALL}</option>);
    props.statusList.map((item) => {
      statusList.push(<option value={item.id}>{item.status.translate()}</option>);
      return '';
    })
    return statusList
  };


  const handleDateRangeChanged = ({ startDate, endDate }) => {
    const range = {
      startDate,
      endDate,
    }
    setDateRange(range);
    const date = getDateFilter(range);
    let saveState = {
      ...state,
      date
    }
    setState(saveState);
    console.log("range", JSON.stringify(range));
    localStorage.setItem('ticketFilters', JSON.stringify(saveState));
    localStorage.setItem('customDate', JSON.stringify(range));
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid style={{ paddingRight: '40px' }} item xs={5}>
            <Grid container spacing={1}>
              <Grid item xs>
                <Button disabled={checkPageActionAccess(userAccess, { pageId: 6, pageAction: "Create Ticket" }) === false} className={"blue-text action-item " + (checkPageActionAccess(userAccess, { pageId: 6, pageAction: "Create Ticket" }) === false ? 'disabled' : null)} fullWidth variant="outlined" size="small" color="primary" onClick={props.openModal}>{languageProps.CREATE_TICKET}</Button>
              </Grid>
              {
                checkPageAccessField(userAccess, { page: 'cancel_ticket', pageAccess: 'FULL ACCESS' }) ? (
                  <Grid item xs>
                    <Button onClick={props.handleCancel} disabled={props.checked === false} className={"blue-text action-item " + (props.checked === false ? ' disabled' : null)} fullWidth variant="outlined" size="small" color="primary">{languageProps.CANCEL_TICKET}</Button>
                  </Grid>
                ) : null
              }
              <Grid item xs>
                <Button
                  disabled={props.isExporting || false}
                  onClick={() =>
                    checkPageActionAccess(userAccess, {
                      pageId: 6,
                      pageAction: "Export to File",
                    })
                      ? props.handleExportToExcel(state.searchString, state.date, state.type, state.status)
                      : null
                  }
                  className={
                    "blue-text action-item " +
                    (checkPageActionAccess(userAccess, {
                      pageId: 6,
                      pageAction: "Export to File",
                    }) === false
                      ? "disabled"
                      : "")
                  }
                  fullWidth
                  variant="outlined"
                  size="small"
                  color="primary"
                >
                  {languageProps.EXPORT_TO_FILE}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <label className={classes.label}>Date Reported</label>
                  </Grid>
                  <Grid item xs={8}>
                    <DateRangeInput
                      showFilterAll={true}
                      inputClass={classes.dateRangeInput}
                      dateRange={dateRange}
                      onChange={handleDateRangeChanged}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container spacing={1}>
                  <Grid item xs={2}>
                    <label className={classes.label}>{languageProps.TYPE}</label>
                  </Grid>
                  <Grid item xs={10}>
                    <FormControl variant='outlined' className={classes.formControl}>
                      <Select
                        native
                        name='type'
                        defaultValue={state.type}
                        onChange={handleTypeChange}
                      >
                        <option value={'m'}>{languageProps.MAINTENANCE}</option>
                        <option value={'r'}>{languageProps.REPAIR}</option>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <label className={classes.label}>{languageProps.STATUS}</label>
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl variant='outlined' className={classes.formControl}>
                      <Select
                        native
                        name="status"
                        value={state.status}
                        onChange={handleStatusChange}
                      >
                        {renderStatusList()}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </div>
    </ThemeProvider>
  );
}
