import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Button from '@material-ui/core/Button';
import Card from './Card'
import StationDetail from './StationDetail'
import moment from 'moment';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import './LineStatus.scss';
import Media from './Media/Media'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginBottom: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: '97%',
    height: '68%',
  },
  drawerTitle: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingTop: '16px',
    paddingLeft: '16px',
    fontWeight: '500',
    fontSize: '2.3rem',
    fontFamily: 'Segoe UI',
  },
  drawerStatusRepaired: {
    display: 'flex',
    fontWeight: '500',
    fontFamily: 'Segoe UI',
    fontSize: '2.3rem',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    color: '#00DC07',
  },
  drawerStatusInRepaired: {
    display: 'flex',
    fontWeight: '500',
    fontFamily: 'Segoe UI',
    fontSize: '2.3rem',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    color: '#F59A23',
  },
  drawerStatusReported: {
    display: 'flex',
    fontWeight: '500',
    fontFamily: 'Segoe UI',
    fontSize: '2.3rem',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    color: '#D9001B',
  },
  paragQuestion: {
    display: 'flex',
    fontFamily: 'Segoe UI',
    fontSize: '1.2rem',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    color: '#aaa',
  },
  paragAnswers: {
    display: 'flex',
    fontFamily: 'Segoe UI',
    fontSize: '1.2rem',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    color: '#000',
  },
  topParag: {
    display: 'flex',
    fontFamily: 'Segoe UI',
    fontSize: '1.2rem',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingTop: '10px',
    paddingLeft: '16px',
    color: '#aaa',
  },
  topParagAnswers: {
    display: 'flex',
    fontFamily: 'Segoe UI',
    fontSize: '1.2rem',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingTop: '10px',
    paddingLeft: '16px',
    color: '#000',
  },
  drawerClose: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'flex-end',
  },

  attachment:{
    position: 'absolute',
    right: '34px',
    bottom: '9px',
  },
  lineCard:{
    display:'inline-block',
    paddingLeft: '16px',
    paddingBottom: '68px',
  },
}));

const drawerTheme = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiDrawer: {
        // Name of the rule
        paperAnchorDockedBottom: {
            border: '1px solid rgba(0, 0, 0, 0.12)',
        },
        paperAnchorBottom: {
          top: 'auto',
          left: '0%',
          margin: '9px',
          position:'absolute',
          right: '50%',
          bottom: '0%',
          height: 'auto',
          maxHeight: '100%',
        },
        head: {
          fontWeight: '800',
        },
      },
    },
  });

export default function PersistentDrawerLeft(props) {
  const classes = useStyles();
  const trigger = props.showDrawer
  const drawerTrigger2 = props.drawerTrigger2
  const initialRender = useRef(true);
  const machineId = props.machineId
  const ticketId = props.ticketId
  const [open, setOpen] = React.useState(false);
  const [mechanic, setMechanic] = React.useState('');
  const linesMachines = props.linesMachines
  const linesTitle = props.linesTitle
  const drawerDetail = props.drawerDetail
  useEffect(() => {
    if(initialRender.current) {
        initialRender.current = false;
    } else {
        setOpen(true)
    }
  }, [trigger]);

  const handleDrawerClose = () => {
    setOpen(false);
    props.clearDrawerDetail();
  };



const renderStationDetail = () => {
  return (
    <StationDetail
    updatedTicketId={props.updatedTicketId}
    ticketId={ticketId}
    socketTrigger={props.socketTrigger}
    machineId={machineId}
    trigger={drawerTrigger2}
    handleDrawerClose={handleDrawerClose}
    {...props} />
  )
}

const renderLineDetail = () => {
  return (
    <div>
      <Grid container spacing={0}>
                    <Grid container>
                        <Grid item xs={11} >
                        <h2 className={classes.drawerTitle}>{linesTitle}</h2>
                        </Grid>
                        <Grid item xs={1} className={classes.drawerClose}>
                        <IconButton onClick={() => handleDrawerClose()}> <CloseIcon></CloseIcon></IconButton>
                        </Grid>
                    </Grid> 
                    </Grid>
                    {linesMachines.map((row, itemIndex) => {
            return (
              <div key={itemIndex} className={classes.lineCard}>
              <Card
              row={row}
              mechanic={row.hasOpenTicket == "true" ? row.repairedBy != null ? row.repairedBy : row.grabbedBy != null ? row.grabbedBy : row.reportedBy : ''}
              lineDetail={true}></Card>
              </div>
          )
        
        }
          
          )}
    </div>
  )
}
  return (
    
    <div className={classes.root}>
      
      <CssBaseline />
        {/* <Button onClick={handleDrawerOpen}>TEST</Button> */}
        <ThemeProvider theme={drawerTheme}>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="bottom"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {
        drawerDetail == 'station' ? renderStationDetail() : renderLineDetail()
        }
                
      </Drawer>
      </ThemeProvider>
      
    </div>
  );
}
