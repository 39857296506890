import React, { useEffect, useState } from 'react';
import {
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import InputTranslation from './InputTranslation';

export default function CustomFieldsForm({
  form,
  reference,
  refValidationError,
  handleRefChange,
  updateReferenceTranslations,
  translations,
  updateTranslationErrors,
}) {
  const [referenceTranslations, setReferenceTranslations] = useState({});
  const [translationErrors, setTranslationErrors] = useState({});
  useEffect(() => {
    if (reference && reference.length) {
      const existingTranslations = {};
      reference.forEach((referenceItem) => {
        if (referenceItem.translations && referenceItem.translations.length) {
          const translations = referenceItem.translations.map((translation) => ({
            id: translation.languageId,
            translatedDesc: translation.translatedDesc,
          }));
    
          existingTranslations[referenceItem.constant] = translations;
        }
      });
      setReferenceTranslations(existingTranslations)
    }
  }, [reference])

  const handleTranslationChange = (referenceField, languageId, text) => {
    const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\|'"=]/;
    if (referenceTranslations[referenceField]) {
      const existsTranslationIndex = referenceTranslations[referenceField].findIndex(
        (lang) => lang.id === languageId
      );
      if (existsTranslationIndex > -1) {
        referenceTranslations[referenceField][existsTranslationIndex].translatedDesc = text;
      } else {
        const matchTranslateIndex = translations.findIndex((lang) => lang.id === languageId);
        referenceTranslations[referenceField] = [
          ...referenceTranslations[referenceField],
          { ...translations[matchTranslateIndex], translatedDesc: text }
        ];
      }
      if (specialCharacterRegex.test(text)) {
        setTranslationErrors((prevErrors) => ({
          ...prevErrors,
          [referenceField]: 'Translation cannot contain special characters'.translate(),
        }));
        updateTranslationErrors({ ...translationErrors, [referenceField]: 'Translation cannot contain special characters'.translate() });
      } else {
        setTranslationErrors((prevErrors) => ({
          ...prevErrors,
          [referenceField]: '',
        }));
        updateTranslationErrors({ ...translationErrors, [referenceField]: '' });
      }
      
    } else {
      const matchTranslateIndex = translations.findIndex((lang) => lang.id === languageId);
      referenceTranslations[referenceField] = [{ ...translations[matchTranslateIndex], translatedDesc: text }];
    }
    setReferenceTranslations({ ...referenceTranslations });

    updateReferenceTranslations(referenceTranslations);
  };

  const getTranslatedValue = (referenceField, languageId) => {
    if (referenceTranslations[referenceField]) {
      const existsTranslation = referenceTranslations[referenceField].find(
        (lang) => lang.id === languageId
      );
      if (existsTranslation) {
        return existsTranslation.translatedDesc;
      }
    }

    return '';
  }

  
  return (
    <div>
      {reference.map((item, index) => (
        <FormControl key={index} fullWidth>
          <span className='helper'>{`Original Field`}</span>
          <Grid container spacing={0}>
            <div className="form-label">{item.constant}</div>
            <Grid item xs={12}>
              <TextField
                onChange={(event) => handleRefChange(item.constant, event)}
                className="text-field"
                type="text"
                name={item.name}
                value={form.reference ? form.reference[item.constant] : ''}
                fullWidth
              />
              {refValidationError[item.constant] && (
                <div className="error-message">
                  {refValidationError[item.constant]}
                </div>
              )}
                {Array.isArray(translations) ? (
                   translations.map((language, key) => {
                    return (
                      <div key={`${item.constant}_${language.id}`}>
                        <InputTranslation
                          name={item.name}
                          language={language}
                          constant={item.constant}
                          originalValue={form.reference ? form.reference[item.constant] : ''}
                          value={getTranslatedValue(item.constant, language.id)}
                          label={`Display Name (${language.desc1})`}
                          onChangeText={(text) => {
                            handleTranslationChange(item.constant, language.id, text);
                          }}
                        />
                        {translationErrors[item.constant] && (
                            <div className="error-message">
                              {translationErrors[item.constant]}
                            </div>
                          )}
                      </div>
                )})
              ) : (
                <Typography variant="body2">
                  Translations are not available.
                </Typography>
              )}
            </Grid>
          </Grid>
        </FormControl>
      ))}
    </div>
  );
}
