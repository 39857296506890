import { TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export default withStyles({
  root: {
    padding: 8,
    fontSize: 14,
    fontWeight: 400,
    border: '1px solid #E0E0E0',
  },
})(TableCell);
