import React from 'react';
import moment from 'moment';
import history from '../../../../history'
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'
import { checkIfApiLoading, checkPageActionAccess } from '../../../../utils/helper';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { notificationError, notificationSuccess } from '../../../../redux/actions/Notification/notificationActions';
import { getMachineById, putUpdateMachine, getTemplates, putRetireMachine, putUnretireMachine, getMachineHistories } from '../../../../redux/actions/Machine/machineActions';
import { getBrands } from '../../../../redux/actions/Brand/brandActions';
import { getMacSubTypes } from '../../../../redux/actions/MacSubTypes/MacSubTypeActions';
import MainPage from '../../../../components/MainPage/MainPage';
import MainPageHeader from '../../../../components/MainPageHeader/MainPageHeader';
import { getAllTickets, getMaintenanceHistory, getRepairHistory } from '../../../../redux/actions/Tickets/ticketActions';
import MainPageBody from '../../../../components/MainPageBody/MainPageBody';
// import TemplateControl from '../../../../components/TemplateControl/TemplateControl';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import MaintenanceTemplate from './Templates/MaintenanceTemplate'
// import translation from '../../../../redux/actions/Translation/translationActions';
import HistoryTable from './HistoryTable/HistoryTable'
import './MachineDetail.scss';
import { formatDate } from '../../../../utils/date';
import { apiSettings, tokenGet, tokenPut } from '../../../../utils/api/CoreApi';

class MachineDetail extends React.Component {
    initialized = false;

    constructor(props) {
        super(props)
        this.state = {
            tooltipTheme: createMuiTheme({
                overrides: {
                    MuiTooltip: {
                        tooltip: {
                            fontSize: "1rem",
                        }
                    }
                }
            }),
            isMachineHasActiveRepaire: false,
            id: null,
            machineDetail: [],
            templates: [],
            machineChecklist: [],
            brands: [],
            macSubTypes: [],
            reference: [],
            tableHeaders: [
                'TEMPLATE NAME',
                'TEMPLATE NO',
                'STEPS',
                'SUB STEPS',
                'MACHINE TYPE',
                'BRAND',
                'DATE CREATED',
                'DATE ADDED',
                'STATUS',
            ],
            tableHeadersHistory: [
                'TICKET NO',
                'MECHANIC',
                'REPORTED PROBLEM',
                'SOLUTION',
                'DATE TIME REPORTED',
                'DATE TIME START REPAIR',
                'DATE TIME CLOSED',
                'DOWN TIME',
                'STATUS',
            ],
            maintenanceHistory: [],
            repairHistory: [],
            templateCollapse: false,
            historyCollapse: false,
            historyIsOpened: false,
            templateIsOpened: false,
            languageProps: {
                ...this.changeLang()
            },
            translationTimestamp: undefined
        }
    }

    resetRedirects() {
        localStorage.removeItem('redirectToMachineDetails');
    }


    changeLang = () => {
        return {
            YES: 'Yes'.translate(),
            NO: 'No'.translate(),
            PROBLEM: 'Problem'.translate(),
            SOLUTION: 'Solution'.translate(),
            REMARKS: 'Remarks'.translate(),
            MACHINE_NO: 'Machine No'.translate(),
            MACHINE_TYPE: 'Machine Type'.translate(),
            MACHINE_SUBTYPE: 'Machine Subtype'.translate(),
            GRABBED_BY: 'Grabbed By'.translate(),
            GRABBED_ON: 'Grabbed on'.translate(),
            GRABBED_ON_TIME: 'Grabbed on time'.translate(),
            BRAND: 'Brand'.translate(),
            MODEL_NO: 'Model No'.translate(),
            MOTOR_TYPE: 'Motor Type'.translate(),
            REMARKS: 'Remarks'.translate(),
            REPAIRED_BY: 'Repaired By'.translate(),
            REPAIRED_ON: 'Repaired on'.translate(),
            REPAIRED_ON_TIME: 'Repaired on time'.translate(),
            REPORTED_BY: 'Reported By'.translate(),
            REPORTED_ON: 'Reported On'.translate(),
            REPORTED_ON_TIME: 'Reported on time'.translate(),
            CLOSED: 'Closed'.translate(),
            CLOSED_BY: 'Closed By'.translate(),
            CLOSED_ON: 'Closed on'.translate(),
            CLOSED_ON_TIME: 'Closed on time'.translate(),
            VIEW_CHECKLIST: 'View Checklist'.translate(),
            CHECKLIST_NO: 'Checklist No'.translate(),
            MAINTENANCE_DATE: 'Maintenance Date'.translate(),
            MAINTENANCE_TYPE: 'Maintenance Type'.translate(),
            MAINTENANCE_SUB_TYPE: 'Maintenance Sub Type'.translate(),
            ACTION_TAKEN_BY: 'Action taken by'.translate(),
            ACTION_TEKAN_ON: 'Action taken on'.translate(),
            ACTION_TAKEN_ON_TIME: 'Action taken on time'.translate(),
            GENERATE_BY: 'Generated by'.translate(),
            GENERATE_ON: 'Generated on'.translate(),
            GENERATE_ON_TIME: 'Generated on time'.translate(),
            SELECT_MACHINE: 'SELECT MACHINE'.translate(),
            SELECT: 'SELECT'.translate(),
            ALL: 'ALL'.translate(),
            REPAIR: 'REPAIR'.translate(),
            MAINTENANCE: 'MAINTENANCE'.translate(),
            ACTIVE: 'ACTIVE'.translate(),
            INACTIVE: 'INACTIVE'.translate(),
            TODAY: 'TODAY'.translate(),
            THIS_WEEK: 'THIS WEEK'.translate(),
            THIS_MONTH: 'THIS MONTH'.translate(),
            EXPORT_TO_EXCEL: 'EXPORT TO EXCEL'.translate(),
            CUSTOMIZE_RANGE: 'CUSTOMIZE RANGE'.translate(),
            NOTIFICATIONS: {
                this_ticket_cannot_be_cancelled: 'This ticket cannot be cancelled'.translate(),
                there_are: 'There are'.translate(),
                tickets_that_cannot_be_cancelled: ' tickets that cannot be cancelled'.translate(),
                are_you_sure: 'Are you sure you want to cancel ticket(s)'.translate(),
                confirming_would_cancel_selected_tickets: 'Confirming would cancel selected tickets'.translate(),
            },

            TYPE: 'Type'.translate(),
            CANCEL_TICKET: 'CANCEL TICKET'.translate(),
            START_REPAIR: 'START REPAIR'.translate(),
            REPAIRED: 'REPAIRED'.translate(),
            CLOSE: 'CLOSE'.translate(),
            SUPPLIER: 'SUPPLIER'.translate(),
            DATE_OF_PURCHASE: 'Date of Purchase'.translate(),
            RENTAL: 'Rental'.translate(),
            LOCATION: 'Location'.translate(),
            ATTACHMENT: 'Attachment'.translate(),
            SERIAL_NO: 'Serial No'.translate(),
            ASSET_NO: 'Asset No'.translate(),
            RFID: 'RFID'.translate(),
            CONDITION: 'Condition'.translate(),
            STATUS: 'Status'.translate(),
            MAINTENANCE_FREQ: 'Maintenance Freq'.translate(),
            FILE_SUCCESSFULLY_EXPORTED: 'File successfully exported'.translate(),
            ATTACHMENT: 'Attachment'.translate(),
            LAST_MAINTENANCE: 'Last Maintenance'.translate(),
            NEXT_MAINTENANCE: 'Next Maintenance'.translate(),
            MAINTENANCE_STATUS: 'Maintenance Status'.translate(),
            RETIRE_MESSAGE: 'Next Maintenance'.translate(),
            REMARKS: 'Remarks'.translate()
        }
    }

    checkIfApiLoading() {
        let flag = checkIfApiLoading([
            this.props.machinesLoading,
            this.props.historyLoading,
            this.props.machineDetailLoading,
            this.props.ticketLoading
        ]);
        return flag;
    }

    getBackLink() {
        if (localStorage.getItem('redirectToTickets') === 'true') {
            return '/tickets';
        }
        else if (localStorage.getItem('redirectToMaintenance') === 'true') {
            return '/maintenance';
        }
        else if(localStorage.getItem('redirectToCalendar')  === 'true'){
            return '/maintenance-calendar'
        }
        else {
            return '/machines';
        }
    }

    checkStatusIfRetire(statusId) {
        if (statusId === 4 || statusId === 5 || statusId === 6) {
            return false
        }
        else {
            return true;
        }
    }

    machineActiveTickets(machineId, callback) {
        tokenGet(apiSettings.apiURLMachines + '/machines/has-active-tk/' + machineId).then((response) => {
            callback(response);
        });
    }

    machineCancelTickets(machineId, ticketIds) {
        tokenPut(apiSettings.apiURLMachines + '/machines/cancel-ticket/' + machineId, ticketIds).then(response => {
            this.props.notificationSuccess('Successfully inactive machine(s)');
            this.props.getMachineById(this.props.match.params.id);
        })
    }

    handleRetire = () => {
        
        if (this.props.machineDetail){
            if (!this.props.machineDetail.isActive || this.props.machineDetail.statusId_desc === 'INACTIVE' || this.props.machineDetail.statusId_desc === 'NOT AVAIL/RETIRED' || this.props.machineDetail.statusId_desc === 'RETIRED'){
                confirmAlert({
                    title: 'Are you sure you want to make this machine available'.translate() + '?',
                    message: '', 
                    buttons: [
                        {
                            label: 'Yes'.translate(),
                            onClick: () => {let data = [];
                                data.push(this.state.id);
                                this.props.putUnretireMachine({ ids: data, updatedDt: moment() }, this.state.id, this.props.meta.currentPage);
                            }
                        },
                        {
                            label: 'No'.translate(),
                        }
                    ]
                });
            }
            else{
                if (this.props.machineDetail) {
                    if (this.props.machineDetail.isLineReq === true) {
                        this.props.notificationError('Cannot Inactivate machines that are still in Production Area'.translate());
                        return false;
                    }
                    else if (this.checkStatusIfRetire(this.props.machineDetail.statusId) === false) {
                        this.props.notificationError('Cannot inactive machine with ' + this.props.machineDetail.statusId_desc + ' status');
                        return false;
                    }
                    else if (!this.props.machineDetail.isActive || this.props.machineDetail.statusId_desc === 'INACTIVE' || this.props.machineDetail.statusId_desc === 'NOT AVAIL/RETIRED' || this.props.machineDetail.statusId_desc === 'RETIRED') {
                        this.props.notificationError('This machine is already retired'.translate());
                        return false;
                    }

                    let ids = [];
                    ids.push(this.state.id);

                    this.machineActiveTickets(ids, ({ data }) => {
                        if (data.machineHasTicket){
                            let ticketIds = [];
            
                            if (data.maintenanceTicket) {
                                data.maintenanceTicket.map(item => {
                                    ticketIds.push(item.ticketId);
                                })
                            }
            
                            if (data.repairTicket) {
                                data.repairTicket.map(item => {
                                    ticketIds.push(item.ticketId);
                                })
                            }
            
                            confirmAlert({
                                title: 'Are you sure you want to make the machine inactive? The related open repair ticket and maintenance ticket will be cancelled automatically'.translate() + '?',
                                message: '',
                                buttons: [
                                    {
                                        label: this.state.languageProps.YES,
                                        onClick: () => {
                                            this.machineCancelTickets(ids, { ticketIds });
                                        }
                                    },
                                    {
                                        label: this.state.languageProps.NO,
                                    }
                                ]
                            });
                        } else {
                            confirmAlert({
                                title: 'Are you sure you want to make this machine inactive'.translate() + '?',
                                message: '', //Confirming would retire selected machines
                                buttons: [
                                    {
                                        label: this.state.languageProps.YES,
                                        onClick: () => {
                                            this.props.putRetireMachine({ ids: ids, updatedDt: moment() }, this.state.id, this.props.meta.currentPage);
                                        }
                                    },
                                    {
                                        label: this.state.languageProps.NO,
                                    }
                                ]
                            });
                        }
                    });
                }
            }
        }
    }

    async componentDidMount() {
        if (this.props.match.params.id) {
            this.resetRedirects();
            this.setState({ id: this.props.match.params.id });
            this.checkIfMachineHasActiveRepairTicket(this.props.match.params.id);
            await this.props.getMachineById(this.props.match.params.id);
            await this.props.getMaintenanceHistory(this.props.match.params.id);
            await this.props.getRepairHistory(this.props.match.params.id);
            // await this.props.getAllTickets();
            await this.props.getTemplates();
            await this.props.getMacSubTypes();
            await this.props.getBrands();
        }
    }
    componentDidUpdate() {
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                languageProps: {
                    ...this.changeLang
                }
            })
        }
    }

    renderText(field) {
        if (field === null || field === '') {
            return '-'
        }
        else {
            //   return field != undefined ? field.toString().translate() : field
            return field
        }
    }

    renderArea(field) {
        if (this.props.machineDetail){
            if (this.props.machineDetail.mfgLineId_desc){
                return this.renderText(this.props.machineDetail.mfgLineId_desc + ' - ' + this.props.machineDetail.station);
            }
            else{
                return this.renderText(this.props.machineDetail.areaId_desc);
            }
        }
        else{
            return this.renderText(null);
        }
    }

    renderMaintenFreq(machine) {
        try {
            const { frequencyQty: Unit, frequencyId_desc: Frequency } = machine;
            return `${Unit} ${Frequency}`;
        } catch (error) {
            return '-';
        }
    }


    renderTextWithCapitalization(field) {
        if (field === null || field === '' || field === 'null') {
            return '-'
        }
        else {
            let capitalized = field.split(' ').map(item =>
                item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(' ');
            return capitalized
        }
    }

    renderCheckbox(check) {
        if (check === 1 || check === true) {
            return 'Yes'.translate() || this.state.languageProps.YES
        }
        else {
            return 'No'.translate() || this.state.languageProps.NO
        }
    }

    renderDate(date) {
        if (date !== null) {
            let newDate = date.substring(0, date.indexOf('T'));
            return moment.parseZone(newDate).format('DD-MM-YYYY');
        }
        else {
            return '-';
        }
    }

    renderDateWithMinHour(date) {
        if (date !== null) {
            return moment(date).format('YYYY-MM-DD HH:mm:ss.sss');
        }
        else {
            return null;
        }
    }

    renderCreatedDate() {
        return moment().format('YYYY-MM-DD HH:mm:ss.sss');
    }

    renderBoolean(boolean) {
        if (boolean) {
            return 1
        } else {
            return 0
        }
    }

    templateCollapseSetter = (boolean) => {
        this.setState({
            templateCollapse: !this.state.templateCollapse
        })
        if (boolean) {
            this.setState({ historyIsOpened: false })
        } else {
            this.setState({ historyIsOpened: true })
        }
    }

    historyCollapseSetter = (boolean) => {
        this.setState({
            historyCollapse: !this.state.historyCollapse
        })
        if (boolean) {
            this.setState({ templateIsOpened: false })
        } else {
            this.setState({ templateIsOpened: true })
        }
    }

    renderMaintenanceDateForEdit = (date) => {
        if (date) {
            return moment(date).format('YYYY-MM-DD');
        }
        else{
            return null
        }
    }

    detacher = async (rows, fulldata) => {
        let filteredChecklist = []
        const checklist = []
        if (rows.length == 1 && fulldata.length == 2) {
            filteredChecklist = fulldata.filter(x => x.checkListId != rows[0])
            checklist.push({ id: filteredChecklist[0].checkListId, tempTypeId: 0, createdDt: this.renderCreatedDate(filteredChecklist[0].createdDt) })
        }
        const fullest = {
            factoryId: this.state.machineDetail.factoryId,
            areaId: this.state.machineDetail.areaId,
            machine: this.state.machineDetail.machine,
            mfgLineId: this.state.machineDetail.mfgLineId,
            model: this.state.machineDetail.model,
            macTypeId: this.state.machineDetail.macTypeId,
            macSubTypeId: this.state.machineDetail.macSubTypeId,
            assetNo: this.state.machineDetail.assetNo,
            brandId: this.state.machineDetail.brandId,
            motorTypeId: this.state.machineDetail.motorTypeId,
            serialNo: this.state.machineDetail.serialNo,
            supplierId: this.state.machineDetail.supplierId,
            isRental: this.renderBoolean(this.state.machineDetail.isRental),
            aquisitionDt: this.renderMaintenanceDateForEdit(this.state.machineDetail.aquisitionDt),
            // pmFrequency: this.state.machineDetail.pmFrequency,
            frequencyId: this.state.machineDetail.frequencyId,
            pmPlanDt: this.renderMaintenanceDateForEdit(this.state.machineDetail.pmPlanDt),
            moduleId: this.state.machineDetail.moduleId,
            statusId: this.state.machineDetail.statusId,
            conditionId: this.state.machineDetail.conditionId,
            attachmentId: this.state.machineDetail.attachmentId,
            rfid: this.state.machineDetail.rfid,
            isActive: this.renderBoolean(this.state.machineDetail.isActive),
            lastPMDt: this.renderMaintenanceDateForEdit(this.state.machineDetail.lastPMDt),
            updatedDt: moment(),
            checklists: checklist,
            remarks: this.state.machineDetail.remarks
        }

        await this.props.putUpdateMachine(this.state.id, fullest);
        await this.props.getMachineById(this.state.id)
        this.setState({ machineChecklist: this.props.machineChecklist })
    }



    attacher = (rows, gettempid, maclength, templatesData, machineChecklistData, machineDetailData) => {
        const lengthOfChklist = maclength
        const checklist = []
        console.log('machinechecklist data')
        console.log(machineChecklistData)
        if (lengthOfChklist == 1) {
            const checklistname = machineChecklistData[0].templateName;
            const checkTempId = templatesData.filter(x => x.name == checklistname)
            checklist.push({ id: machineChecklistData[0].checkListId, tempTypeId: machineChecklistData[0].tempTypeId, createdDt: formatDate(machineChecklistData[0].createdDt, 'YYYY-MM-DD HH:mm:ss', null) })
        }

        for (let i = 0; i < rows.length; i++) {
            const filteredTemplates = templatesData.filter(x => x.id == rows[i])
            checklist.push({ id: filteredTemplates[0].id, tempTypeId: gettempid[i], createdDt: this.renderCreatedDate() })
        }
        const fullest = {
            factoryId: machineDetailData.factoryId,
            areaId: machineDetailData.areaId,
            machine: machineDetailData.machine,
            mfgLineId: machineDetailData.mfgLineId,
            model: machineDetailData.model,
            macTypeId: machineDetailData.macTypeId,
            macSubTypeId: machineDetailData.macSubTypeId,
            assetNo: machineDetailData.assetNo,
            brandId: machineDetailData.brandId,
            motorTypeId: machineDetailData.motorTypeId,
            serialNo: machineDetailData.serialNo,
            supplierId: machineDetailData.supplierId,
            isRental: this.renderBoolean(machineDetailData.isRental),
            aquisitionDt: this.renderMaintenanceDateForEdit(this.state.machineDetail.aquisitionDt),
            // pmFrequency: machineDetailData.pmFrequency,
            frequencyId: machineDetailData.frequencyId,
            pmPlanDt: this.renderMaintenanceDateForEdit(this.state.machineDetail.pmPlanDt),
            moduleId: machineDetailData.moduleId,
            statusId: machineDetailData.statusId,
            conditionId: machineDetailData.conditionId,
            attachmentId: machineDetailData.attachmentId,
            rfid: machineDetailData.rfid,
            isActive: this.renderBoolean(machineDetailData.isActive),
            lastPMDt: this.renderMaintenanceDateForEdit(this.state.machineDetail.lastPMDt),
            updatedDt: moment(),
            checklists: checklist,
            remarks: this.state.machineDetail.remarks
        }

        this.props.putUpdateMachine(this.state.id, fullest);
        this.props.getMachineById(this.state.id)
        this.setState({ machineChecklist: this.props.machineChecklist })
    }

    componentWillReceiveProps() {
        if (this.props.machineDetail != undefined) {
            this.setState({ machineDetail: this.props.machineDetail })
        }
        if (this.props.templates != undefined) {
            this.setState({ templates: this.props.templates })
        }
        if (this.props.maintenanceHistory != undefined) {
            this.setState({ maintenanceHistory: this.props.maintenanceHistory })
        }
        if (this.props.repairHistory != undefined) {
            this.setState({ repairHistory: this.props.repairHistory })
        }
        if (this.props.machineChecklist != undefined) {
            this.setState({ machineChecklist: this.props.machineChecklist })
        }
        if (this.props.macSubTypes != undefined) {
            this.setState({ macSubTypes: this.props.macSubTypes })
        }
        if (this.props.brands != undefined) {
            this.setState({ brands: this.props.brands })
        }
    }

    filterBrands(brand){
        if (brand.length > 0){
            let tmpBrands = brand.filter((item) => {return item.isActive === true});
            return tmpBrands;
        }
    }

    redirectToTickets = () => {
        if (
            !this.props.machineDetail.isActive || 
            this.props.machineDetail.statusId_desc === 'INACTIVE' || 
            this.props.machineDetail.statusId_desc === 'NOT AVAIL/RETIRED' || 
            this.props.machineDetail.statusId_desc === 'RETIRED'
        ) {
            this.props.notificationError('Cannot create ticket with inactive Machine'.translate());
        }
        else{
            let link = this.returnCreateTicketLink();
            if (link !== false){
                history.push(link);
            }
        }
    }

    returnCreateTicketLink = () => {
        if (this.props.machineDetail) {
            return '/create-ticket/' + this.state.id
        }
    }

    preventLinkRedirect = (e) => {
        e.preventDefault();
    }

    renderRetireLabel(){
        if (this.props.machineDetail){
            if (!this.props.machineDetail.isActive || this.props.machineDetail.statusId_desc === 'INACTIVE' || this.props.machineDetail.statusId_desc === 'NOT AVAIL/RETIRED' || this.props.machineDetail.statusId_desc === 'RETIRED'){
                return 'Make Available'.translate();
            }
            else{
                return 'Make Inactive'.translate();
            }
        }
    }

    checkIfMachineHasActiveRepairTicket = (machineId) => {
        tokenGet(apiSettings.apiURLMachines + '/machines/active-repair/' + machineId).then((response) => {
            if (response.data.machine.length > 0) this.setState({ isMachineHasActiveRepaire: true })
        });
    }

    content() {
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className="title-blue">{this.props.machineDetail ? this.props.machineDetail.machine : this.state.machine}</div>
                        </Grid>
                    </Grid>
                    <Grid className="actions" item xs={4}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <div className={"action-item " + (checkPageActionAccess(this.props.userAccess, { pageId: 2, pageAction: 'Edit' }) === false ? 'disabled' : '')}>
                                    <Link onClick={checkPageActionAccess(this.props.userAccess, { pageId: 2, pageAction: 'Edit' }) === false ? this.preventLinkRedirect : null} to={'/machine-edit/' + this.state.id}>
                                        <Button disabled={checkPageActionAccess(this.props.userAccess, { pageId: 2, pageAction: 'Edit' }) ? false : true} fullWidth variant="outlined" color="primary"><span>{'EDIT'.translate()}</span></Button>
                                    </Link>
                                </div>
                            </Grid>
                            <Grid item>
                                <div className={"action-item " + (checkPageActionAccess(this.props.userAccess, { pageId: 2, pageAction: 'Retire' }) === false ? 'disabled' : '')}>
                                    <Link onClick={checkPageActionAccess(this.props.userAccess, { pageId: 2, pageAction: 'Retire' }) === false ? this.preventLinkRedirect : this.handleRetire}>
                                        <Button disabled={checkPageActionAccess(this.props.userAccess, { pageId: 2, pageAction: 'Retire' }) ? false : true} fullWidth variant="outlined" color="primary"><span>{this.props.machineDetail ? this.renderRetireLabel() : null}</span></Button>
                                    </Link>
                                </div>
                            </Grid>
                            <Grid item>
                                <div className={"action-item " + (checkPageActionAccess(this.props.userAccess, { pageId: 2, pageAction: 'Create Ticket' }) ? (this.state.isMachineHasActiveRepaire ? 'disabled' : '') : '')}>
                                    <Link onClick={checkPageActionAccess(this.props.userAccess, { pageId: 2, pageAction: 'Create Ticket' }) ? (this.state.isMachineHasActiveRepaire ? this.preventLinkRedirect : this.redirectToTickets) : this.redirectToTickets}>
                                        <Button disabled={checkPageActionAccess(this.props.userAccess, { pageId: 2, pageAction: 'Create Ticket' }) ? (this.state.isMachineHasActiveRepaire ? true : false) : true} fullWidth variant="outlined" color="primary"><span>{'CREATE TICKET'.translate()}</span></Button>
                                    </Link>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid className="form-component" container spacing={1}>
                    <MuiThemeProvider theme={this.state.tooltipTheme}>
                        <FormControl fullWidth component="fieldset">
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormGroup>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{'Machine No'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Tooltip placement="top-start" title={this.props.machineDetail ? this.renderText(this.props.machineDetail.machine) : this.state.machine}>
                                                    <span className="value-label text-ellipsis"><strong>{this.props.machineDetail ? this.renderText(this.props.machineDetail.machine) : this.state.machine}</strong></span>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{'Model No'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Tooltip placement="top-start" title={this.props.machineDetail ? this.renderText(this.props.machineDetail.model) : this.state.model}>
                                                    <span className="value-label text-ellipsis">{this.props.machineDetail ? this.renderText(this.props.machineDetail.model) : this.state.model}</span>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{'Machine Type'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span className="value-label">{this.props.machineDetail ? this.renderTextWithCapitalization(this.props.machineDetail.macTypeId_desc) : this.state.macTypeId_desc}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{'Machine Sub Type'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span className="value-label">{this.props.machineDetail ? this.renderText(this.props.machineDetail.macSubTypeId_desc) : this.state.macSubTypeId_desc}</span>
                                            </Grid>
                                        </Grid>
                                         <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{'Supplier'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span className="value-label">{this.props.machineDetail ? this.renderText(this.props.machineDetail.supplier_desc) : this.state.supplier_desc}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{'Date of Purchase'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span className="value-label">{this.props.machineDetail ? formatDate(this.props.machineDetail.aquisitionDt) : this.state.aquisitionDt}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{'Rental'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span className="value-label">{this.props.machineDetail ? this.renderCheckbox(this.props.machineDetail.isRental) : this.state.isRental}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            {this.props.machineDetail && this.props.machineDetail.reference ? (
                                                <React.Fragment>
                                                {[0, 2, 4, 6].map((index) => (
                                                    <React.Fragment key={index}>
                                                    <Grid item xs={6}>
                                                        <span className="form-label" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                                                            {this.props.machineDetail.reference[index].name ? 
                                                                this.props.machineDetail.reference[index].name.translate() :
                                                                this.props.machineDetail.reference[index].constant.translate()
                                                            }
                                                        </span>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <span className="value-label" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                                                            {this.renderText(this.props.machineDetail.reference[index].value)}
                                                        </span>
                                                    </Grid>
                                                    </React.Fragment>
                                                ))}
                                                </React.Fragment>
                                            ) : (
                                                <Grid item xs={12}>
                                                <span>No reference data available</span>
                                                </Grid>
                                            )}
                                        </Grid>

                                    </FormGroup>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormGroup>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{'Location'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span className="value-label"><strong>{this.props.machineDetail ? this.renderArea() : this.state.areaId_desc}</strong></span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{'Brand'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span className="value-label">{this.props.machineDetail ? this.renderTextWithCapitalization(this.props.machineDetail.brandId_desc) : this.state.brandId_desc}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{'Motor Type'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span className="value-label">{this.props.machineDetail ? this.renderText(this.props.machineDetail.motorTypeId_desc) : this.state.motorTypeId_desc}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{'Serial No'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Tooltip placement="top-start" title={this.props.machineDetail ? this.renderText(this.props.machineDetail.serialNo) : this.state.serialNo}>
                                                    <span className="value-label text-ellipsis">{this.props.machineDetail ? this.renderText(this.props.machineDetail.serialNo) : this.state.serialNo}</span>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{'Asset No'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Tooltip placement="top-start" title={this.props.machineDetail ? this.renderText(this.props.machineDetail.assetNo) : this.state.assetNo}>
                                                    <span className="value-label text-ellipsis">{this.props.machineDetail ? this.renderText(this.props.machineDetail.assetNo) : this.state.assetNo}</span>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{'RFID'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Tooltip placement="top-start" title={this.props.machineDetail ? this.renderText(this.props.machineDetail.rfid) : this.state.rfid}>
                                                    <span className="value-label text-ellipsis">{this.props.machineDetail ? this.renderText(this.props.machineDetail.rfid) : this.state.rfid}</span>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>

                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{'Attachment'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span className="value-label">{this.props.machineDetail ? this.renderText(this.props.machineDetail.attachmentId_desc) : this.state.attachmentId_desc}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            {this.props.machineDetail && this.props.machineDetail.reference ? (
                                                <React.Fragment>
                                                {[1, 3, 5, 7].map((index) => (
                                                    <React.Fragment key={index}>
                                                    <Grid item xs={6}>
                                                        <span className="form-label" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                                                            {this.props.machineDetail.reference[index].name ? 
                                                                this.props.machineDetail.reference[index].name.translate() :
                                                                this.props.machineDetail.reference[index].constant.translate()
                                                            }
                                                        </span>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <span className="value-label" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                                                            {this.renderText(this.props.machineDetail.reference[index].value)}
                                                        </span>
                                                    </Grid>
                                                    </React.Fragment>
                                                ))}
                                                </React.Fragment>
                                            ) : (
                                                <Grid item xs={12}>
                                                <span>No reference data available</span>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormGroup>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{'Maintenance Freq'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span className="value-label">
                                                    <strong>
                                                        {this.renderMaintenFreq(this.props.machineDetail)}
                                                    </strong>
                                                </span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{'Last Maintenance'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span className="value-label">{this.props.machineDetail ? formatDate(this.props.machineDetail.lastPMDt) : this.state.lastPMDt}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{'Next Maintenance'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span className="value-label">{this.props.machineDetail ? formatDate(this.props.machineDetail.pmPlanDt) : this.state.pmPlanDt}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{'Station'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span className="value-label">{this.props.machineDetail ? this.renderText(this.props.machineDetail.station) : this.state.station}</span>
                                            </Grid>
                                        </Grid>
                                        {/* <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{'Active'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span className="value-label">{this.props.machineDetail ? this.renderCheckbox(this.props.machineDetail.isActive) : this.state.isActive}</span>
                                            </Grid>
                                        </Grid> */}
                                        {/* <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{'Condition'.translate()}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span className="value-label">{this.props.machineDetail ? this.renderText(this.props.machineDetail.conditionId_desc) : this.state.conditionId_desc}</span>
                                            </Grid>
                                        </Grid> */}
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs>
                                                <span className="form-label">{'Status'.translate()}</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span className="value-label">{this.props.machineDetail ? this.renderText(this.props.machineDetail.statusId_desc) : this.state.statusId_desc}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs>
                                                <span className="form-label">{'Remarks'.translate()}</span>
                                            </Grid>
                                            <Grid item xs>
                                                <span className="value-label">{this.props.machineDetail ? this.renderText(this.props.machineDetail.remarks) : this.state.remarks}</span>
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </MuiThemeProvider>
                </Grid>
            </div>
        );
    }

    render() {
        return (
            <MainPage className="machine-detail">
                <MainPageHeader title="Machines" search={false} back={this.getBackLink()} />
                <MainPageBody>
                    {this.content()}

                    <MaintenanceTemplate
                        machineId={this.state.id}
                        detachData={this.detacher}
                        attachData={this.attacher.bind(this)}
                        checkedSetter={this.state.templateCollapse}
                        historyCollapseSetter={this.historyCollapseSetter}
                        historyIsOpened={this.state.historyIsOpened}
                        machineDetail={this.props.machineDetail ? this.props.machineDetail : this.state.machineDetail}
                        templates={this.props.templates ? this.props.templates : this.state.templates}
                        machineChecklist={this.props.machineChecklist ? this.props.machineChecklist : this.state.machineChecklist}
                        brands={this.props.brands ? this.filterBrands(this.props.brands) : this.filterBrands(this.state.brands)}
                        macSubTypes={this.props.macSubTypes ? this.props.macSubTypes : this.state.macSubTypes}
                    ></MaintenanceTemplate>
                    {/* <TemplateControl noResultText="No maintenance history found" data={this.props.maintenanceHistory} header={this.state.tableHeadersHistory} link="/ticket-detail" name="maintenanceHistory" title="Maintenance History"></TemplateControl>
            <TemplateControl noResultText="No repair history found" data={this.props.repairHistory} header={this.state.tableHeadersHistory} link="/ticket-detail" name="repairHistory" title="Repair History"></TemplateControl> */}
                    <HistoryTable
                        checkedSetter={this.state.historyCollapse}
                        templateCollapseSetter={this.templateCollapseSetter}
                        templateIsOpened={this.state.templateIsOpened}
                        maintenanceHistory={this.props.maintenanceHistory}
                        repairHistory={this.props.repairHistory}
                        machineHistories={this.props.machineHistories}
                        machineChecklist={this.props.machineChecklist ? this.props.machineChecklist : this.state.machineChecklist}
                    ></HistoryTable>
                </MainPageBody>
            </MainPage>
        );
    }
}
const mapStateToProps = state => {
    let { translationReducer } = state
    let apiLoading = {
        machinesLoading: state.machineListReducer.loading,
        machineDetailLoading: state.machineListReducer.viewDetailLoading,
        historyLoading: state.ticketListReducer.loadingHistory,
        ticketLoading: state.ticketListReducer.loading,
        brandsLoading: state.brandListReducer.loading,
        macSubtypesLoading: state.macSubTypeListReducer.loading,
    }
    let data = {
        userAccess: state.authReducer.access,
        machineDetail: state.machineListReducer.machineDetail,
        machineChecklist: state.machineListReducer.machineChecklist,
        templates: state.machineListReducer.templates,
        tickets: state.ticketListReducer.tickets,
        ticketTypeList: state.ticketListReducer.ticketTypes,
        maintenanceHistory: state.ticketListReducer.maintenanceHistory,
        repairHistory: state.ticketListReducer.repairHistory,
        macSubTypes: state.macSubTypeListReducer.macSubTypeList,
        meta: state.machineListReducer.meta,
        brands: state.brandListReducer.brandList,
        machineHistories: state.machineListReducer.machineHistories,
    }
    return {
        ...apiLoading,
        ...data,
        ...translationReducer
    }

}

const mapDispatchToProps = dispatch => {
    return {
        getMachineById: (id) => dispatch(getMachineById(id)),
        getAllTickets: () => dispatch(getAllTickets()),
        getMaintenanceHistory: (id) => dispatch(getMaintenanceHistory(id)),
        getRepairHistory: (id) => dispatch(getRepairHistory(id)),
        getTemplates: () => dispatch(getTemplates()),
        getMacSubTypes: () => dispatch(getMacSubTypes()),
        getBrands: () => dispatch(getBrands()),
        putRetireMachine: (payload, id, currentPage) => dispatch(putRetireMachine(payload, id, currentPage)),
        putUnretireMachine: (payload, id, currentPage) => dispatch(putUnretireMachine(payload, id, currentPage)),
        putUpdateMachine: (id, payload) => dispatch(putUpdateMachine(id, payload)),
        notificationSuccess: (message) => dispatch(notificationSuccess(message)),
        notificationError: (message) => dispatch(notificationError(message)),
    }
}

MachineDetail = connect(mapStateToProps, mapDispatchToProps)(MachineDetail);

export default MachineDetail;
