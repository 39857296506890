import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MainPage from '../../../../components/MainPage/MainPage';
import MainPageHeader from '../../../../components/MainPageHeader/MainPageHeader';
import MainPageBody from '../../../../components/MainPageBody/MainPageBody';
import history from '../../../../history';
import Brands from './Brands/Brands';
import MotorType from './MotorType/MotorType';
import Machine from './Machine/Machine'
import SubTypes from './SubTypes/SubTypes';
import Attachment from './Attachment/Attachment';
import ChangeoverType from './ChangeoverType/ChangeoverType';
import Supplier from './Supplier/Suppiler';
import dictionary from '../../../../redux/actions/Translation/dictionary';
import { checkPageAccess, checkPageAccessField } from '../../../../utils/helper';
import { getAllMacSubTypesTreeView, getAllMacSubTypesTreeViewSubtype } from '../../../../redux/actions/MacSubTypes/MacSubTypeActions'
import './MachineConfig.scss';

class MachineConfig extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tabEnum: [
                {
                    name: "brand",
                    value: 0
                },
                {
                    name: "machine-type",
                    value: 1
                },
                {
                    name: "supplier",
                    value: 2
                },
                {
                    name: "sub-types",
                    value: 3
                },
                {
                    name: "motor-type",
                    value: 4
                },
                {
                    name: "attachment",
                    value: 5
                },
                {
                    name: "changeover-type",
                    value: 6
                },
            ],
            shownTab: 0,
            configType: null,
            translations: {
                ...this.convert()
            },
            translationTimestamp: undefined
        }
    }

    /**handler */
    convert = () => {
        let { BRAND,
            MACHINE_TYPE,
            MACHINE_SUBTYPE,
            MOTOR_TYPE,
            ATTACHMENT,
            MACHINE_MASTER_DATA,
            SUPPLIER,
            CHANGEOVER_TYPE } = dictionary.GET();
        return dictionary.TRANSLATE({
            BRAND,
            MACHINE_TYPE,
            MACHINE_SUBTYPE,
            MOTOR_TYPE,
            ATTACHMENT,
            MACHINE_MASTER_DATA,
            CHANGEOVER_TYPE,
            SUPPLIER
        });
    }
    handleTabChange = (type) => {
        history.push('/configuration/machine-config/' + type);
        let indexValue = this.state.tabEnum.filter((item) => { return item.name === type });
        if (indexValue.length > 0) {
            this.setState({ shownTab: indexValue[0].value, configType: type })
        }
    }

    renderTabPanel() {
        if (this.state.shownTab === 0) {
            return (
                <Brands />
            )
        }
        else if (this.state.shownTab === 1) {
            return (
                <Machine />
            )
        }
        else if (this.state.shownTab === 2) {
            return (
                <Supplier />
            )
        }
        else if (this.state.shownTab === 3) {
            return (
                <SubTypes />
            )
        }
        else if (this.state.shownTab === 4) {
            return (
                <MotorType />
            )
        }
        else if (this.state.shownTab === 5) {
            return (
                <Attachment />
            )
        }
        else if (this.state.shownTab === 6) {
            return (
                <ChangeoverType />
            )
        }
    }

    initializeTab() {
        let index = 0;
        if (this.props.match.params.config === 'brand') {
            index = 0
        }
        else if (this.props.match.params.config === 'machine-type') {
            index = 1
        }
        else if (this.props.match.params.config === 'supplier'){
            index = 2
        }
        else if (this.props.match.params.config === 'sub-types') {
            index = 3
        }
        else if (this.props.match.params.config === 'motor-type') {
            index = 4
        }
        else if (this.props.match.params.config === 'attachment') {
            index = 5
        }
        else if (this.props.match.params.config === 'changeover-type'){
            index = 6
        }
        this.setState({ shownTab: index });
    }

    componentDidMount() {
        this.initializeTab();
        this.props.getAllMacSubTypesTreeView();
    }

    componentDidUpdate() {
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                translations: Object.assign({}, { ...this.convert() })
            })
        }
    }

    render() {
        let { translations } = this.state
        return (
            <MainPage className='machine-config'>
                <MainPageHeader title={translations.MACHINE_MASTER_DATA} search={false} />
                <MainPageBody>
                    <div className="machine-config-body">
                        <div className="title">{translations.MACHINE_MASTER_DATA}</div>
                        <Tabs color="primary" value={this.state.shownTab} aria-label="simple tabs example">
                            <Tab disabled={checkPageAccess(this.props.userAccess,{ pageId: 18 }) || checkPageAccessField(this.props.userAccess,{ pageId: 18, pageAccess: 'VIEW' }) ? false : true} onClick={() => this.handleTabChange('brand')} label={translations.BRAND} />
                            <Tab disabled={checkPageAccess(this.props.userAccess,{ pageId: 19 }) || checkPageAccessField(this.props.userAccess,{ pageId: 19, pageAccess: 'VIEW' }) ? false : true} onClick={() => this.handleTabChange('machine-type')} label={translations.MACHINE_TYPE} />
                            <Tab disabled={checkPageAccess(this.props.userAccess,{ pageId: 28 }) || checkPageAccessField(this.props.userAccess,{ pageId: 28, pageAccess: 'VIEW' }) ? false : true} onClick={() => this.handleTabChange('supplier')} label={translations.SUPPLIER} />
                            {/* <Tab disabled={checkPageAccess(this.props.userAccess,{ pageId: 20 }) || checkPageAccessField(this.props.userAccess,{ pageId: 20, pageAccess: 'VIEW' }) ? false : true} onClick={() => this.handleTabChange('sub-types')} label={translations.MACHINE_SUBTYPE} /> */}
                            <Tab style={{minWidth: "0px", border: "none", padding: 0, margin: 0}} disabled={checkPageAccess(this.props.userAccess,{ pageId: 20 }) || checkPageAccessField(this.props.userAccess,{ pageId: 20, pageAccess: 'VIEW' }) ? false : true} onClick={() => this.handleTabChange('sub-types')}/>
                            {/* <Tab  style={{minWidth: "0px", border: "none", padding: 0, margin: 0}}/> */}
                            <Tab disabled={checkPageAccess(this.props.userAccess,{ pageId: 21 }) || checkPageAccessField(this.props.userAccess,{ pageId: 21, pageAccess: 'VIEW' }) ? false : true} onClick={() => this.handleTabChange('motor-type')} label={translations.MOTOR_TYPE} />
                            <Tab disabled={checkPageAccess(this.props.userAccess,{ pageId: 22 }) || checkPageAccessField(this.props.userAccess,{ pageId: 22, pageAccess: 'VIEW' }) ? false : true} onClick={() => this.handleTabChange('attachment')} label={translations.ATTACHMENT} />
                            <Tab disabled={checkPageAccess(this.props.userAccess,{ pageId: 23 }) || checkPageAccessField(this.props.userAccess,{ pageId: 23, pageAccess: 'VIEW' }) ? false : true} onClick={() => this.handleTabChange('changeover-type')} label={translations.CHANGEOVER_TYPE} />
                        </Tabs>
                        {this.renderTabPanel()}
                    </div>
                </MainPageBody>
            </MainPage>
        );
    }
}

const mapStateToProps = state => {
    let { translationReducer } = state
    let apiLoading = {
    }
    let data = {
        userAccess: state.authReducer.access
    }

    return {
        ...apiLoading,
        ...data,
        ...translationReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllMacSubTypesTreeView: () => dispatch(getAllMacSubTypesTreeView()),
    }
}

MachineConfig = connect(mapStateToProps, mapDispatchToProps)(MachineConfig);

export default MachineConfig;
