import { tokenGet, tokenPost, apiSettings, tokenPut } from '../../../utils/api/CoreApi';
import { 
    getFactoryId, 
    getCompanyCode, 
    fontResponsive,
} from '../../../utils/helper';
import store from '../../store'
import { TRANSLATION_ACTIONS as actions } from '../actionTypes'
const queryActions = {}
const componentActions = {}
let { dispatch } = store
let { apiURLFiles, apiURLAuthGlobalAdmin } = apiSettings

queryActions.getAvailableTranslation = async () => {
    try {
        let { data: { language }, status } = await tokenGet(`${apiURLAuthGlobalAdmin}/languages?factoryId=${getFactoryId()}&app=LTm&companyCode=${getCompanyCode()}`);
        const languageCode = language.find(lang => typeof lang.code !== 'undefined');
        localStorage.setItem("languageCode", languageCode.code);

        dispatch({
            type: actions.FETCH_CODE_TRANS,
            payload: {
              availableCode: language,
            }
        })
    } catch (error) {
        console.error('getAvailableTranslation', error)
    }
}

queryActions.getTranslations = async (code, factory ='STX') => {
    try {
        // let factory = localStorage['selectedFactory'] || 'STX'
        factory = factory.toLowerCase()
        code = code.toLowerCase()
        let version = 'new';
        let { data, status } = await tokenGet(`${apiURLFiles}/api/languages/translation?language=${code}&factory=${factory}&version=${version}`)
        // console.log('here', data)
        if (data['new']) {
            localStorage[`${factory}_${code}_version`] = data['version']
            localStorage[`${factory}_${code}`] = JSON.stringify(data['data'])
        }
        localStorage['selectedLanguage'] = code
        localStorage['selectedFactory'] = factory
        dispatch({
            type: actions.FETCH_TRANS_BY_CODE,
            payload: {
                // [`${factory.toLowerCase()}_${code.toLowerCase()}`]: data,
                timeStamp: new Date()
            }
        })
    } catch (error) {
        console.error('getTranslations', error)
        dispatch({
            type: actions.FETCH_TRANS_BY_CODE,
            payload: {
                // [`${factory.toLowerCase()}_${code.toLowerCase()}`]: data,
                timeStamp: new Date()
            }
        })
    }
}

queryActions.getTranslationsNew = async (code, factory ='STX', refresh = false) => {
    try {
        let factory = localStorage['selectedFactory'] || 'STX'
        factory = factory.toLowerCase()
        code = code.toLowerCase()
        let version = 'new';
        // languages/translation?companyCode=stx&product=ltm&language=en&version=new&compact=1
        let { data, status } = await tokenGet(`${apiURLAuthGlobalAdmin}/languages/translation?companyCode=${getCompanyCode()}&product=ltm&language=${code}&version=${version}&compact=1`)
        console.log('---here---', data)
        if (data['new']) {
            localStorage[`${factory}_${code}_version`] = data['version']
            localStorage[`${factory}_${code}`] = JSON.stringify(data['data'])
        }
        localStorage['selectedLanguage'] = code
        localStorage['selectedFactory'] = factory
        dispatch({
            type: actions.FETCH_TRANS_BY_CODE,
            payload: {
                // [`${factory.toLowerCase()}_${code.toLowerCase()}`]: data,
                timeStamp: new Date()
            }
        })
        fontResponsive();
        if(refresh) setTimeout(() => window.location.reload(), 1000);

    } catch (error) {
        console.error('---getTranslations---', error)
        dispatch({
            type: actions.FETCH_TRANS_BY_CODE,
            payload: {
                // [`${factory.toLowerCase()}_${code.toLowerCase()}`]: data,
                timeStamp: new Date()
            }
        })
    }
}

export default () => {

    return {
        ...queryActions
    }
}
