import {
    INPUT_FOCUS,
    INPUT_FOCUS_SUCCESS
} from '../../actions/actionTypes';

const initialState = {
    toFocus: false,
    fieldName: null,
}

const areaListReducer = (state = initialState, action) => {
    switch (action.type) {
        case INPUT_FOCUS:
            return {
                toFocus: true,
                fieldName: action.payload
            }
        case INPUT_FOCUS_SUCCESS:
            return {
                toFocus: false,
                fieldName: null,
            }
        default: 
            return state;
    }
}

export default areaListReducer;