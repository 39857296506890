import React from 'react';
import moment from 'moment';
import { connect } from "react-redux";
import { menuDrawerOpen } from '../../redux/actions/MenuDrawer/menuDrawerActions';
import { getAllTicketsBySearch, getTicketDetail, ticketListShowToggle, ticketListCloseToggle, getMachineUpBySearch } from '../../redux/actions/Tickets/ticketActions';
import { machineListCloseToggle, machineListShowToggle, getMachineById, getMachines } from '../../redux/actions/Machine/machineActions'
import { checklistShowToggle, checklistCloseToggle, } from '../../redux/actions/Checklist/checklistActions'
import { notificationError } from '../../redux/actions/Notification/notificationActions';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LineAreaFilter from './LineComponents/LineAreaFilter';
import UserBlueHeader from '../UserBlueHeader/UserBlueHeader';
import translations from '../../redux/actions/Translation/translationActions'
import history from '../../history'
import './MainPageHeader.scss';
import { 
    CircularProgress, 
    InputAdornment,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

class MainPageHeader extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            search: false,
            filterArea: false,
            areaName: null,
            translationTimestamp: undefined
        }
    }

    componentDidMount() {
        this.setState({ search: this.props.search });
        this.setState({ filterArea: this.props.filterArea });

    }

    componentWillReceiveProps() {
        try {
            if (this.props.lines) {
                let check = this.props.lines.filter((item) => { return item.areaId.toString() === this.props.areaId.toString() });
                if (check.length > 0) {
                    this.setState({ areaName: check[0].areaName });
                }
            }
        }
        catch (err) {
        }
    }

    componentDidUpdate() {
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                // languageProps: {
                //     ...this.convertLang()
                // }
            })
        }
    }

    redirectBack() {
        history.push(this.props.back);
    }

    getToggleStatusByName() {
        if (this.props.name === 'machines') {
            return this.props.machineSelectedRow.showToggle;
        }
        else if (this.props.name === 'tickets') {
            return this.props.ticketSelectedRow.showToggle;
        }
        else if (this.props.name === 'checklist') {
            return this.props.showChecklistDetail;
        }
    }

    handleToggle = () => {
        if (this.props.name === 'machines') {
            let id = null
            if (this.props.machineSelectedRow.id === null) {
                id = this.props.machineList[0].machine.linkId;
            }
            else {
                id = this.props.machineSelectedRow.id;
            }
            this.props.getMachineById(id);
            this.props.machineListShowToggle(id, true);
        }
        else if (this.props.name === 'tickets') {
            let id = null
            if (this.props.ticketSelectedRow.id === null) {
                id = this.props.tickets[0].ticketNo.linkId;
            }
            else {
                id = this.props.ticketSelectedRow.id;
            }
            this.props.getTicketDetail(id);
            this.props.ticketListShowToggle(id, true);
            return this.props.ticketSelectedRow.showToggle;
        }
        else if (this.props.name === 'checklist') {
            let id = this.props.selectedId
            this.props.openSideDetail(true, id)
        }
    }

    handleSearch = (value) => {
        if(value != '' && value.length < 2){
            return
        }

        if(this.props.onFilter){
            return this.props.onFilter(value)
        }
        if (this.props.name === 'machines') {
            this.props.getFilteredMachines(this.props.filters.statusId, value);
            this.props.resetCurrentPage();
        }
        if (this.props.name === 'tickets') {
            let filters = JSON.parse(localStorage.getItem('ticketFilters'));
            this.props.getAllTicketsBySearch(10, 1, value, filters.date, filters.type, filters.status);
            let saveState = {
                ...filters,
                searchString: value,
            }
            localStorage.setItem('ticketFilters', JSON.stringify(saveState));
        }
        if (this.props.name === 'maintenance' || this.props.name === 'checklist') {
            let input = value;
            let data = this.dateChecker(input)
            this.props.handleSearch(data);
        }
    }

    deboundSearch = (value) => {
        if(this.delayFn){
            clearTimeout(this.delayFn)
        }
        this.delayFn = setTimeout(() => this.handleSearch(value), 1000);
    }

    dateChecker = (input) => {
        if (moment(input, "DD-MM-YYYY", true).isValid()) {
            let day = input.substring(0, 2)
            let month = input.substring(3, 5)
            let year = input.substring(6, 10)
            let newDate = year + '-' + month + '-' + day
            return newDate
        } else if (moment(input, "MM-YYYY", true).isValid()) {
            let year = input.substring(3, 7)
            let month = input.substring(0, 2)
            let newDate = year + '-' + month
            return newDate
        } else if (moment(input, "DD-MM", true).isValid()) {
            let day = input.substring(0, 2)
            let month = input.substring(3, 5)
            let newDate = month + '-' + day
            return newDate
        } else {
            return input
        }

    }

    handleCloseToggle = () => {
        if (this.props.name === 'machines') {
            this.props.machineListCloseToggle();
        }
        else if (this.props.name === 'tickets') {
            this.props.ticketListCloseToggle();
        }
        else if (this.props.name === 'checklist') {
            this.props.openSideDetail(false);
        }
    }

    renderToggle() {
        return (
            <Grid item xs={2}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Button onClick={this.handleCloseToggle} className={'icon icon-overview ' + (this.getToggleStatusByName() === false ? 'active' : null)}></Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={this.handleToggle} className={'icon icon-two-column ' + (this.getToggleStatusByName() === true ? 'active' : null)}></Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    checkIfThereIsToggle() {
        if (this.props.name === 'machines' || this.props.name === 'tickets' || this.props.name === 'checklist') {
            return true
        }
        else {
            return false
        }
    }

    handleIsLoading = () => {
        return (
        this.props.ticketsLoading ||
        this.props.machinesLoading ||
        this.props.loadingMachineUp ||
        this.props.checklistTemplateLoading
        );
    }

    renderSearch() {
        if (this.state.search) {
            return (
                <Grid item xs>
                    <Grid container spacing={0}>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={8}>
                            <Grid className="search-container" container spacing={1}>
                                <Grid className="search-results" item xs={4}>
                                    <span className="total-rows">{this.props.displayedRows}</span>
                                    {
                                        this.props.name === 'maintenance' ?
                                            (<span> {'of'.translate()} {this.props.rows} {this.props.searchLabel.toUpperCase().translate()}</span>) :
                                            (<span> {'of'.translate()} {this.props.rows} {this.props.searchLabel.translate()}</span>)
                                    }
                                </Grid>
                                <Grid item xs={this.checkIfThereIsToggle() ? 6 : 7}>
                                    <TextField 
                                        fullWidth
                                        defaultValue={this.props.searchString == 'all' ? '' : this.props.searchString}
                                        onChange={(evt)=> this.deboundSearch(evt.target.value)}
                                        className="search-input" 
                                        id="outlined-search"
                                        placeholder={"Search".translate()} 
                                        type="text" 
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    { this.handleIsLoading() && <CircularProgress style={{ marginRight: 10 }} size={12} /> }
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                {
                                    this.checkIfThereIsToggle() ? this.renderToggle() : ''
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
    }

    renderFilterArea(hidden) {
        if (this.state.filterArea) {
            return (
                <Grid item xs>
                    <Grid container spacing={0}>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={12}>
                            <Grid className="search-container search-no-padding" container spacing={0}>
                                {!this.props.lineStatusTvMode && <Grid item xs={5}></Grid>}
                                <Grid item xs={7}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            {this.props.lineStatusTvMode && (
                                                <Link
                                                    style={{ padding:0, margin: 0 }}
                                                    className={'line-status-tv-mode'} 
                                                    to={`/line-status`}>
                                                    {'EXIT TV MODE'.translate()}
                                                </Link>
                                            )}
                                            <LineAreaFilter 
                                                lineStatusTvMode={this.props.lineStatusTvMode} 
                                                hidden={hidden} 
                                                areaId={this.props.areaId} 
                                                lineOverView={this.props.lineOverView} 
                                                filterDisabled={this.props.filterDisabled} 
                                                lines={this.props.lines} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
    }

    renderHamburgerIcon() {
        return (
            <Button onClick={this.props.menuDrawerOpen} fullWidth className="hamburger white header-navigate">
                <i className="hamburger-icon"></i>
            </Button>
        )
    }

    renderHeader() {
        if (this.props.lineStatusTvMode) {
            return (
                <div>
                    {this.props.tvModeToken || this.props.lineStatusTvMode ? null : <UserBlueHeader />}
                    <Grid container spacing={0}>
                        <Grid item xs={10}>
                            <Grid container spacing={0}>
                                {/* <Grid item xs={1}> */}
                                {/* {this.props.tvModeToken ? null : this.renderHamburgerIcon()} */}
                                {/* </Grid> */}
                                <Grid item xs={6}>
                                    <div className="header-title area-title">{this.state.areaName ? this.state.areaName.toUpperCase().translate() : ''}</div>
                                </Grid>
                                {/* <Grid item xs={1}>

                                </Grid> */}
                                {
                                    this.props.title && (
                                        <Grid item xs={4}>
                                            <div className="header-title">
                                                {this.props.title.toUpperCase().translate()}
                                            </div>
                                        </Grid>
                                    )
                                }

                            </Grid>
                        </Grid>
                        {this.renderFilterArea(true)}
                    </Grid>
                </div>
            )
        }
        else {
            return (
                <div>
                    <UserBlueHeader />
                    <Grid container spacing={0}>
                        <Grid item xs={5}>
                            <Grid container spacing={0}>
                                {/* <Grid item xs={2}>
                                    <Button onClick={this.props.menuDrawerOpen} fullWidth className="hamburger header-navigate">
                                        <i className="hamburger-icon"></i>
                                    </Button>
                                </Grid> */}
                                {
                                    this.props.back ? (
                                        <Grid item xs={2}>
                                            <Button onClick={() => this.redirectBack()} fullWidth className="arrow-back header-navigate">
                                                <ArrowBackIcon className="arrow-back-icon" />
                                            </Button>
                                        </Grid>
                                    ) : ''
                                }
                                {
                                    this.props.title && (
                                        <Grid item xs>
                                            <div className="header-title">{this.props.title.toUpperCase().translate()}</div>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                        {this.renderSearch()}
                        {this.renderFilterArea(false)}
                    </Grid>
                </div>
            )
        }
    }

    render() {
        return (
            <div className={'main-page-header ' + (this.props.lineStatusTvMode === true ? 'white-header' : '')}>
                {this.renderHeader()}
            </div>
        );
    }
}



const mapStateToProps = state => {
    let { translationReducer } = state
    let apiLoading = {
        checklistTemplateLoading: state.checklistReducer.templateLoading,
        machinesLoading: state.machineListReducer.loading,
        loadingMachineUp: state.ticketListReducer.loadingMachineUp,
        ticketsLoading: state.ticketListReducer.loading,
    }
    let data = {
        machineSelectedRow: state.machineListReducer.selectedRow,
        machineList: state.machineListReducer.machines,
        tickets: state.ticketListReducer.tickets,
        ticketSelectedRow: state.ticketListReducer.selectedRow,
        ticketSearch: state.ticketListReducer.searchString,
        machineUpFilter: state.ticketListReducer.machineUpFilter,
    }

    return {
        ...apiLoading,
        ...data,
        ...translationReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        menuDrawerOpen: () => dispatch(menuDrawerOpen()),
        getFilteredMachines: (statusId, searchString) => dispatch(getMachines(1, 1, 10, statusId, searchString)),
        getMachineById: (id) => { dispatch(getMachineById(id)) },
        getTicketDetail: (id) => { dispatch(getTicketDetail(id)) },
        machineListShowToggle: (id, flag) => { dispatch(machineListShowToggle(id, flag)) },
        machineListCloseToggle: () => { dispatch(machineListCloseToggle()) },
        ticketListShowToggle: (id, flag) => { dispatch(ticketListShowToggle(id, flag)) },
        ticketListCloseToggle: (id, flag) => { dispatch(ticketListCloseToggle(id, flag)) },
        checklistShowToggle: (id, flag) => { dispatch(checklistShowToggle(id, flag)) },
        checklistCloseToggle: () => { dispatch(checklistCloseToggle()) },
        getAllTicketsBySearch: (pageSize, currentPage, searchString, reportedDt, ticketType, status) => { dispatch(getAllTicketsBySearch(pageSize, currentPage, searchString, reportedDt, ticketType, status)) },
        getMachineUpBySearch: (pageSize, currentPage, searchString, pmPlanDt) => dispatch(getMachineUpBySearch(pageSize, currentPage, searchString, pmPlanDt)),
        notificationError: (message) => dispatch(notificationError(message)),
    }
}

MainPageHeader = connect(mapStateToProps, mapDispatchToProps)(MainPageHeader);

export default MainPageHeader;
