import {
    MACHINE_UPDATE_DATA,
    MACHINE_LIST,
    MACHINE_LIST_UPDATE_TOTAL,
    MACHINE_LIST_UPDATE_PAGE,
    MACHINE_LIST_UPDATE_DISPLAYED_ROW,
    MACHINE_LIST_REQUEST,
    MACHINE_LIST_SUCCESS,
    MACHINE_LIST_ERROR,
    MACHINE_LIST_SHOW_TOGGLE,
    MACHINE_LIST_CLOSE_TOGGLE,
    MACHINE_LIST_CHECKBOX_TOGGLE,
    MACHINE_LIST_TICKET_CREATION,
    MACHINE_NEW_ITEM_REQUEST,
    MACHINE_NEW_ITEM_SUCCESS,
    MACHINE_NEW_ITEM_ERROR,
    MACHINE_UPDATE_REQUEST,
    MACHINE_UPDATE_SUCCESS,
    MACHINE_UPDATE_ERROR,
    MACHINE_RETIRE_REQUEST,
    MACHINE_RETIRE_SUCCESS,
    MACHINE_RETIRE_ERROR,
    MACHINE_UNRETIRE_REQUEST,
    MACHINE_UNRETIRE_SUCCESS,
    MACHINE_UNRETIRE_ERROR,
    MACHINE_VIEW_DETAIL_REQUEST,
    MACHINE_VIEW_DETAIL_SUCCESS,
    MACHINE_VIEW_DETAIL_ERROR,
    MACHINE_VIEW_TEMPLATES_SUCCESS,
    MACHINE_VIEW_TEMPLATES_REQUEST,
    TEMPLATES_LIST_REQUEST,
    TEMPLATES_LIST_SUCCESS,
    MACHINE_HISTORIES_REQUEST,
    MACHINE_HISTORIES_SUCCESS,
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    actionLoading: false,
    selectedRow: {
        id: null,
        showToggle: false
    },
    searchString: '',
    viewDetailLoading: false,
    machineTicketCreation: [],
    machineDetail: null,
    machineChecklist: [],
    machineTemplate:[],
    rows: 0,
    currentPage: 0,
    displayedRows: 0,
    machines:[],
    templates:[],
    machineHistories: [],
    meta: [],
    error: ''
}

const machineListReducer = (state = initialState, action) => {
    switch (action.type) {
        case MACHINE_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case MACHINE_LIST_SUCCESS:
            if (action.payload.reloadCurrentPage === false){
                return {
                    ...state,
                    loading: false,
                    rows: action.payload.machines.length,
                    machines: action.payload.machines,
                    searchString: action.payload.searchString,
                    meta: action.payload.meta,
                    error: ''
                }
            }
            else{
                return {
                    ...state,
                    loading: false,
                    rows: action.payload.machines.length,
                    currentPage: 0,
                    machines: action.payload.machines,
                    searchString: action.payload.searchString,
                    meta: action.payload.meta,
                    error: ''
                }
            }
        case MACHINE_LIST_ERROR:
            return {
                loading: false,
                rows: 0,
                currentPage: 0,
                machines: [],
                meta: [],
                error: action.payload.message
            }
        case MACHINE_LIST_SHOW_TOGGLE:
            return {
                ...state,
                selectedRow: {
                    id: action.payload.id,
                    showToggle: action.payload.flag
                }
            }
        case MACHINE_LIST_CLOSE_TOGGLE:
            return {
                ...state,
                selectedRow: {
                    id: null,
                    showToggle:false
                }
            }
        case MACHINE_LIST_UPDATE_TOTAL:
            return {
                ...state,
                rows: action.payload.rows,
            }
        case MACHINE_LIST_UPDATE_PAGE:
            return {
                ...state,
                currentPage: action.payload,
                meta: {
                    ...state.meta,
                    currentPage: action.payload
                },
            }
        case MACHINE_LIST_UPDATE_DISPLAYED_ROW:
            return {
                ...state,
                displayedRows: action.payload,
            }
        case MACHINE_LIST_CHECKBOX_TOGGLE:
            return {
                ...state,
                machines: action.payload,
            }
        case MACHINE_NEW_ITEM_REQUEST:
            return {
                ...state,
                loading: true
            }
        case MACHINE_NEW_ITEM_SUCCESS:
            const machines = [...action.payload, ...state.machines].map((item, index)=> ({ ...item, seq: index + 1 }));
            return {
                ...state,
                machines: machines,
                loading: false,
            }
        case MACHINE_UPDATE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case MACHINE_UPDATE_SUCCESS:
            let machineRow = action.machine;
            let data = state.machines.map((item) => {
                if (item.machine.linkId === machineRow.machine.linkId){
                    return machineRow;
                }
                else{
                    return item;
                }
            });

            return {
                ...state,
                machines: data,
                loading: false,
            }
        case MACHINE_RETIRE_REQUEST:
            return {
                ...state,
                actionLoading: true
            }
        case MACHINE_RETIRE_SUCCESS:
            let retireMachines = state.machines.map((item) => {
                let itemRow = item;
                if (itemRow.machine.linkId.toString() === action.payload.toString()){
                    itemRow.statusId = 6;
                    return itemRow;
                }
                else{
                    return itemRow;
                }
            })
            return {
                ...state,
                actionLoading: false,
                machines: retireMachines
            }
        
        case MACHINE_UNRETIRE_REQUEST:
            return {
                ...state,
                actionLoading: true
            }
        case MACHINE_UNRETIRE_SUCCESS:
            let unretireMachines = state.machines.map((item) => {
                let itemRow = item;
                if (itemRow.machine.linkId.toString() === action.payload.toString()){
                    itemRow.statusId = 6;
                    return itemRow;
                }
                else{
                    return itemRow;
                }
            })
            return {
                ...state,
                actionLoading: false,
                machines: unretireMachines
            }
        case MACHINE_VIEW_DETAIL_REQUEST:
            return {
                ...state,
                viewDetailLoading: true
            }
        case MACHINE_VIEW_DETAIL_SUCCESS:
            return {
                ...state,
                machineDetail: action.payload.machine[0],
                machineChecklist: action.payload.checklist,
                viewDetailLoading: false,
            }
        case MACHINE_VIEW_TEMPLATES_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case MACHINE_VIEW_TEMPLATES_SUCCESS:
            return {
                ...state,
                machineTemplate: action.payload.machine,
                loading: false,
            }
        case TEMPLATES_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case TEMPLATES_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                templates: action.payload.templates,
                error: ''
            }
        case MACHINE_LIST_TICKET_CREATION:
            return {
                ...state,
                machineTicketCreation: action.payload
            }
        case MACHINE_UPDATE_DATA:
            let updateRow = action.payload;
            let updateData = state.machines.map((item) => {
                if (item.machine.linkId === updateRow.machine.linkId){
                    return updateRow;
                }
                else{
                    return item;
                }
            });
            return {
                ...state,
                machines: updateData
            }
        case MACHINE_HISTORIES_SUCCESS:
            return {
                ...state,
                machineHistories: action.payload
            }
        default: 
            return state;
    }
}

export default machineListReducer;