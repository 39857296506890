import React from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  paper: {
    padding: 15,
    marginBottom: 15,
    boxShadow: "0px 3px 6px #00000029"
  }
})

export default function CardPaper(props){
  const { className } = props;
  const classes = useStyles();
  return <Paper elevation={2} className={[classes.paper, className]}>
    {props.children}
  </Paper>
}
