import React from 'react';
import { Box, withStyles } from '@material-ui/core';

export const BetaIcon = withStyles({
    root: {
        background: '#9D00FF',
        borderRadius: 3,
        fontSize: 11,
        color: '#fff',
        padding: '0px 5px',
        marginLeft: 5,
        fontWeight: 'bold',
    }
})((props) => (<Box {...props}>{'BETA'.translate()}</Box>))