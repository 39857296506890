import React from 'react';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'
import ChecklistTasksDetails from '../../../../components/ChecklistTasksDetails/ChecklistTasksDetails';
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { checkIfApiLoading, checkPageActionAccess, checkPageAccess } from '../../../../utils/helper';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getChecklistTemplateStatus} from '../../../../redux/actions/Status/StatusActions';
import { notificationError } from '../../../../redux/actions/Notification/notificationActions';
import { getChecklistInstructions,postChecklistTemplate, putChecklistTemplate, postUploadTemplate, getChecklistDetail, putInActiveChecklistDetail,postAssignChecklistToMachine,getMachinelist } from '../../../../redux/actions/Checklist/checklistActions';
import { getMacTypes } from '../../../../redux/actions/MacTypes/MacTypeActions';
import { getMacSubTypes, getAllMacSubTypes } from '../../../../redux/actions/MacSubTypes/MacSubTypeActions';
import { getFrequencies } from '../../../../redux/actions/Frequency/frequencyActions';
import { getBrands } from '../../../../redux/actions/Brand/brandActions';
import MainPage from '../../../../components/MainPage/MainPage';
import MainPageHeader from '../../../../components/MainPageHeader/MainPageHeader';
import MainPageBody from '../../../../components/MainPageBody/MainPageBody';
import TemplateControl from '../../../../components/TemplateControl/TemplateControl';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import dictionary from '../../../../redux/actions/Translation/dictionary'
import ModalButton from '../Modal/Modal';
import './ChecklistDetail.scss';

class ChecklistDetail extends React.Component {
    initialized = false;

    constructor(props) {
        super(props)
        this.state = {
            tooltipTheme: createMuiTheme({
                overrides: {
                    MuiTooltip: {
                        tooltip: {
                            fontSize: "1rem",
                        }
                    }
                }
            }),
            upload: {
                file: null
            },
            detailLoaded: false,
            form: {
                checklistNo: null,
                brandId: null,
                macsubtypeId: null,
                frequencyId: null,
                mactypeId: null,
                motortypeId: null,
                desc1: null,
                temptype: null,
                statusId: null,
                status_desc: null,
                instructionLink: null,
                tasks: []
            },
            formErrors: [],
            id: null,
            translations: {
                ...this.convert()
            },
            translationTimestamp: undefined,
        }
    }

    /**handler */
    convert = () => {
        let {
            NOTIFICATIONS: {
                are_you_sure_you_want_to_make_this_checklist_template_inactive, are_you_sure_you_want_to_make_this_checklist_template_active,
            },
            MAKE_ACTIVE, MAKE_INACTIVE, YES, NO, EDIT, VIEW_INSTRUCTION, CHECKLIST_NO, CHECKLIST_NAME, TEMPLATE_TYPE, MACHINE_TYPE, MACHINE_SUB_TYPE,BRAND, MAINTENANCE_FREQ,STATUS,
        } = dictionary.GET()
        return dictionary.TRANSLATE({
            are_you_sure_you_want_to_make_this_checklist_template_inactive, are_you_sure_you_want_to_make_this_checklist_template_active,
            MAKE_ACTIVE, MAKE_INACTIVE, YES, NO, EDIT, VIEW_INSTRUCTION, CHECKLIST_NO, CHECKLIST_NAME, TEMPLATE_TYPE, MACHINE_TYPE, MACHINE_SUB_TYPE,BRAND, MAINTENANCE_FREQ,STATUS, 
        })
    }

    checkIfApiLoading() {
        let flag = checkIfApiLoading([
            this.props.checklistDetailLoading,
            this.props.macTypeListLoading,
            this.props.brandListLoading,
            this.props.frequencyListLoading,
        ]);
        return flag;
    }

    renderMakeInactiveLabel(){
        let {translations} = this.state
        if (this.props.checklistDetail){
            if (this.props.checklistDetail.details[0].templateStatus !== 'INACTIVE'){
                return translations.MAKE_INACTIVE;
            }
            else{
                return translations.MAKE_ACTIVE;
            }
        }
    }

    handleMakeInactive = () => {
        let {translations} = this.state
        
        if (this.props.checklistDetail){
            if (this.props.checklistDetail.details[0].templateStatus !== 'INACTIVE'){
                // let loadStatusId = this.props.checklistTemplatesStatusList.filter((item) => { return item.status === 'ACTIVE'});
                let loadStatusId = null;
                if (localStorage.getItem('checklistStatusId')){
                    let tmpId = localStorage.getItem('checklistStatusId');
                    loadStatusId = tmpId;
                }
                else{
                    let tmpId = this.props.checklistTemplatesStatusList.filter((item) => { return item.status === 'ACTIVE'});
                    loadStatusId = tmpId[0].id;
                }
                confirmAlert({
                    title: translations.are_you_sure_you_want_to_make_this_checklist_template_inactive,
                    message: '',
                    buttons: [
                        {
                            label:  translations.YES,
                            onClick: () => {
                                let payload = {
                                    id:this.state.id,
                                    status:'INACTIVE'
                                }
                                this.props.putInActiveChecklistDetail(payload,loadStatusId);
                            }
                        },
                        {
                            label: translations.NO,
                        }
                    ]
                });
            }
            else if (this.props.checklistDetail.details[0].templateStatus === 'INACTIVE'){
                // let loadStatusId = this.props.checklistTemplatesStatusList.filter((item) => { return item.status === 'ACTIVE'});
                let loadStatusId = localStorage.getItem('checklistStatusId') ? localStorage.getItem('checklistStatusId') : this.props.checklistTemplatesStatusList.filter((item) => { return item.status === 'ACTIVE'});
                confirmAlert({
                    title: translations.are_you_sure_you_want_to_make_this_checklist_template_active,
                    message: '',
                    buttons: [
                        {
                            label:  translations.YES,
                            onClick: () => {
                                let payload = {
                                    id:this.state.id,
                                    status:'ACTIVE'
                                }
                                this.props.putInActiveChecklistDetail(payload,loadStatusId);
                            }
                        },
                        {
                            label:  translations.NO,
                        }
                    ]
                });
            }
        }
    }

    removeErrorOnChange(field){
      let errors = this.state.formErrors.filter((item) => {return item.name !== field});
      this.setState({ formErrors: errors });
    }

    renderMacTypesItems(){
        let macTypeList = this.props.macTypeList.map((item) => {
            return <MenuItem key={item.id} value={item.id}>{ item.desc }</MenuItem>
        });
        return macTypeList;
    }
      
    renderMacSubTypesItems(){
        let macSubTypeList = this.props.macSubTypeList.map((item) => {
            return <MenuItem key={item.id} value={item.id}>{ item.desc }</MenuItem>
        });
        return macSubTypeList;
    }
      
    renderBrandItems(){
        let brandList = this.props.brandList.map((item) => {
            return <MenuItem key={item.id} value={item.id}>{ item.brand }</MenuItem>
        });
        return brandList;
    }

    renderFrequencyItems(){
        let frequencyList = this.props.frequencyList.map((item) => {
            return <MenuItem key={item.id} value={item.id}>{ item.frequency }</MenuItem>
        });
        return frequencyList;
    }

  hasErrors = (field) => {
        let checkField = this.state.formErrors.filter((item) => { return item.name === field });
        if (checkField.length > 0){
            return true
        }
        else{
            return false;
        }
    }

    getErrors = (field) => {
    let checkField = this.state.formErrors.filter((item) => { return item.name === field });
    let errorString = null;
    checkField.map((item) => {
        errorString += item.message + '. ';
        return '';
    })
    return errorString;
    }

    handleFormChange = ({ target }) => {
       let tmpForm = this.state.form;
       tmpForm[target.name] = target.value;
       this.setState({ form: tmpForm });
       this.removeErrorOnChange(target.name);
       console.log(tmpForm);
    };

    handleMacTypeChange = ({target}) => {
        this.handleFormChange({target});
    
        // reset macsubtypeId   
        let tmpForm = this.state.form;
        tmpForm['macsubtypeId'] = null;
        this.setState({ form: tmpForm }, () => {
            this.props.getMacSubTypes(1,target.value);
        });
      }

    loadMacSubTypes = (macSubTypeId) => {
        this.props.getMacSubTypes(1,macSubTypeId);
    }

    handleTaskChange = ({ target }) => {
       let tmpForm = this.state.form;
       tmpForm.tasks = tmpForm.tasks.map((item) => {
           if (item.seq.toString() === target.name.replace("task","").toString()){
               item.task = target.value;
           }
           return item;
       })
       this.setState({ form: tmpForm });
    };

    handleSubTaskChange = ({ target }) => {
        let tmpForm = this.state.form;
        let subTaskData = target.name.split("-"); // [0] is task seq, [1] is subtask seq
        tmpForm.tasks = tmpForm.tasks.map((item) => {
            if (item.seq.toString() === subTaskData[0].toString()){
                item.subtasks = item.subtasks.map((subtaskItem) => {
                    if (subtaskItem.seq === subTaskData[1]){
                        subtaskItem.subtask = target.value;
                    }
                    return subtaskItem;
                })
            }
            return item;
        })
        this.setState({ form: tmpForm });
     };

    deleteTask = (taskSequence) => {
        if (this.state.form.tasks.length <= 1){
            this.props.notificationError("Cannot delete task");
        }
        else{
            let tmpForm = this.state.form;

            // rearranged task sequence
            let counter = 1;
            tmpForm.tasks = tmpForm.tasks.filter((item) => { return item.seq !== taskSequence }).map((item) => {
                item.seq = counter.toString();
                counter += 1;
                return item;
            });

            this.setState({ form: tmpForm });
        }
    }

    deleteSubTask = (taskSequence,subtaskSequence) => {
        let tmpForm = this.state.form;

        // rearranged subtask sequence
        let counter = 1;
        tmpForm.tasks = tmpForm.tasks.map((item) => {
            if (taskSequence === item.seq){
                let subtasks = item.subtasks.filter((subtaskItem) => { return subtaskItem.seq !== subtaskSequence}).map((item) => {
                    item.seq = counter.toString();
                    counter += 1;
                    return item;
                });
                item.subtasks = subtasks;
            }
            return item;
        })

        this.setState({ form: tmpForm });
    }

    getLastItem(array){
        if (array.length > 0){
            return array[array.length-1];
        }
        else{
            return null;
        }
    }

    makeSubtask = (taskSequence) => {
        if (taskSequence === '1'){
            this.props.notificationError('Cannot make to subtask'.translate());
        }
        else{
            let tmpForm = this.state.form;

            let filteredTask = this.state.form.tasks.filter((item) => { return item.seq === taskSequence});
            if (filteredTask.length > 0){
                let lastTaskSequence = parseInt(filteredTask[0].seq) - 1;

                tmpForm.tasks = tmpForm.tasks.map((item) => {
                    if (item.seq.toString() === lastTaskSequence.toString()){
                        let lastSubtask = this.getLastItem(item.subtasks);
                        let counter = null;
                        if (lastSubtask){
                            counter  = parseInt(lastSubtask.seq) + 1;
                        }
                        else{
                            counter = 1;
                        }
                        let newSubtask = {
                            seq: counter.toString(),
                            subtask: filteredTask[0].task
                        }
                        item.subtasks.push(newSubtask);
                    }
                    return item;
                });
                this.setState({form:tmpForm}, () => {
                    this.deleteTask(taskSequence);
                });
            }
        }
    }

    addTask = () => {
        let tmpForm = this.state.form;
        let lastItem = this.getLastItem(this.state.form.tasks);
        let counter = parseInt(lastItem.seq) + 1;
        let newItem = {
            seq:counter.toString(),
            task:"",
            subtasks:[]
        }
        tmpForm.tasks.push(newItem);
        this.setState({form: tmpForm});
    }

    addSubTask = (taskSequence) => {
        let tmpForm = this.state.form;
        let filteredTask = this.state.form.tasks.filter((item) => { return item.seq === taskSequence });
        if (filteredTask.length > 0){
            let counter = null;
            if (filteredTask[0].subtasks.length > 0){
                let lastItem = this.getLastItem(filteredTask[0].subtasks);
                counter = parseInt(lastItem.seq) + 1;
            }
            else{
                counter = 1;
            }
            let newItem = {
                seq:counter.toString(),
                subtask:""
            }

            tmpForm.tasks = tmpForm.tasks.map((item) => {
                if (item.seq === taskSequence){
                    item.subtasks.push(newItem);
                }
                return item;
            })
            this.setState({form: tmpForm});
        }

    }

    getBackLink() {
        if (localStorage.getItem('redirectToTickets') === 'true') {
            return '/tickets';
        }
        else if (localStorage.getItem('redirectToMaintenance') === 'true') {
            return '/maintenance';
        }
        else if (localStorage.getItem('redirectToMachineDetails') === 'true') {
            let machineId = localStorage.getItem('redirectToMachineDetailsId');
            return '/machine-detail/' + machineId ;
        }
        else {
            console.log('check setter neil')
            localStorage.setItem('redirectToChecklist', true);
            return '/checklist';
        }
    }

    componentWillReceiveProps(){
        if (this.state.detailLoaded === false && this.props.match.params.id !== undefined && this.props.checklistDetail !== undefined && this.props.checklistDetail !== null){
            
            this.loadMacSubTypes(this.props.checklistDetail.details[0].macTypeId);
            this.props.getMachinelist(this.props.checklistDetail.details[0].tempType,this.props.match.params.id);
            this.setState({detailLoaded: true});
        }
    }

    componentDidMount() {
        this.props.getMacTypes()
        this.props.getBrands();
        this.props.getFrequencies();

        if (this.props.match.params.id !== undefined){
            let id = this.props.match.params.id;
            this.props.getChecklistDetail(id);
            this.setState({id: id});
        }
        else if (this.props.match.params.action === 'new'){

        }
        else{
            // history.push('/dashboard');
        }
        this.props.getChecklistTemplateStatus();
    }

    componentDidUpdate(){
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                translations: Object.assign({}, { ...this.convert() }),
            })
        }
    }

    renderText(field) {
        if (field === null || field === '') {
            return '-'
        }
        else {
            //   return field != undefined ? field.toString().translate() : field
            return field
        }
    }

    renderTempType(field) {
        if (field === null || field === '') {
            return '-'
        }
        else if (field === 'CL') {
            return 'Repair Checklist'
        }
        else if (field === 'PM') {
            return 'Preventive Maintenance'
        }
        else {
            return field
        }
    }

    renderCheckbox(check) {
        let {translations} = this.state
        if (check === 1 || check === true) {
            return translations.YES
        }
        else {
            return translations.NO
        }
    }

    renderDate(date) {
        if (date !== null) {
            let newDate = date.substring(0, date.indexOf('T'));
            return moment(newDate).format('DD-MM-YYYY');
        }
        else {
            return '-';
        }
    }

    renderDateWithMinHour(date) {
        if (date !== null) {
            return moment(date).format('YYYY-MM-DD HH:mm:ss.sss');
        }
        else {
            return null;
        }
    }

    renderCreatedDate() {
        return moment().format('YYYY-MM-DD HH:mm:ss.sss');
    }

    renderBoolean(boolean) {
        if (boolean) {
            return 1
        } else {
            return 0
        }
    }

    preventLinkRedirect = (e) => {
        e.preventDefault();
    }

    handleInstructionUpload = () => {
        document.getElementById("instructionLink").click();
    }

    handleInstructionUploadChange = ({target}) => {
        let file = target.files
        
        let fd = new FormData()
        Object.entries(file).forEach((x : any) => {
            fd.append('file',x[1])
        })
        fd.append('str', 'stronly');

        let upload = {
            file: fd
        }

        this.setState({upload:upload});
    }

    checkInstructionLink(){
        if (this.props.checklistDetail){
            if (this.props.checklistDetail.details[0].instructionLink === null){
               return false 
            }
            else{
                return true;
            }
        }
        else{
            return false
        }
    }

    handleViewInstruction = () => {
        if (this.props.checklistDetail){
            this.props.getChecklistInstructions(this.props.checklistDetail.details[0].instructionLink);
        }
    }

    checkIfDraft(){
        if (this.props.checklistDetail){
            if (this.props.checklistDetail.details[0].templateStatusId === 10){
                return true;
            }
            else{
                return false;
            }
        }
    }

    checkIfInactive(status, flag){
        if (status === 'INACTIVE'){
            return true;
        }
        else if (checkPageActionAccess(this.props.userAccess,{pageId:16,pageAction:'Edit'}) === false && flag === 'Edit'){
            return true;
        }
        else if (checkPageActionAccess(this.props.userAccess,{pageId:16,pageAction:'View Instruction'}) === false && flag === 'View Instruction'){
            return true;
        }
        else{
            return false;
        }
    }

    checkIfInactiveClass(flag = null){
        if (this.props.checklistDetail){
            return this.checkIfInactive(this.props.checklistDetail.details[0].templateStatus,flag);
        }
        else{
            return false;
        }
    }

    

    postAssignChecklistToMachineLocal = (payload) => {
        payload = {
            ...payload,
            machineListPayload: {
                tempType:this.props.checklistDetail.details[0].tempType,
                id: this.state.id
            }
        }
        this.props.postAssignChecklistToMachine(payload);
    }

    renderStatus(status){
        if (status === 'ACTIVE'){
            return (
                <span className="status active">
                    { status.translate() }
                </span>
            )
        }
        else if (status === 'DRAFT'){
            return (
                <span className="status in-repair">
                    { status.translate() }
                </span>
            )
        }
        else if (status === 'INACTIVE'){
            return (
                <span className="status not-working-gray">
                    { status.translate() }
                </span>
            )
        }
    }

    preventLinkRedirect = (e) => {
        e.preventDefault();
    }

    titleCase = (str) => {
        if (str){
            var splitStr = str.toLowerCase().split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                // You do not need to check if i is larger than splitStr length, as your for does that for you
                // Assign it back to the array
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
            }
            // Directly return the joined string
            return splitStr.join(' '); 
        }
        else{
            return '';
        }
     }

    content() {
        let {translations} = this.state
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid className="actions" item xs={8}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <div className={"action-item " + (!this.checkIfInactiveClass('Edit') ? '' : 'disabled')}>
                                    <Link onClick={(e) => this.checkIfInactiveClass('Edit') ? this.preventLinkRedirect(e) : null } to={"/checklist/edit/" + this.state.id}>
                                        <Button disabled={this.props.checklistDetail ? this.checkIfInactive(this.props.checklistDetail.details[0].templateStatus, 'Edit') : null}  fullWidth variant="outlined" color="primary"><span>{translations.EDIT}</span></Button>
                                    </Link>
                                </div>
                            </Grid>
                            <Grid item>
                                <div className={"action-item " + (checkPageActionAccess(this.props.userAccess,{pageId:16,pageAction:'Make Inactive'}) ? null : 'disabled')}>
                                    <Link>
                                        <Button disabled={checkPageActionAccess(this.props.userAccess,{pageId:16,pageAction:'Make Inactive'}) === false} onClick={this.handleMakeInactive} fullWidth variant="outlined" color="primary"><span>{this.renderMakeInactiveLabel()}</span></Button>
                                    </Link>
                                </div>
                            </Grid>
                            <Grid item>
                                <div className={"action-item " + (this.checkIfDraft() === false && !this.checkIfInactiveClass() ? '' : 'disabled')}>
                                    <Link>
                                        <ModalButton buttonDisabled={checkPageActionAccess(this.props.userAccess,{pageId:16,pageAction:'Assign to Machine'}) === false} postAssignChecklistToMachine={this.postAssignChecklistToMachineLocal} machineListChecklist={this.props.machineListChecklist} checklistStatus={this.props.checklistDetail ? this.props.checklistDetail.details[0].templateStatus : null} padding={"5px 13px"} checkedId={this.state.id} selectedTemplateType={this.props.checklistDetail ? this.props.checklistDetail.details[0].tempType : null}/>
                                    </Link>
                                </div>
                            </Grid>
                            <Grid item>
                                <div className={"action-item " + (this.checkInstructionLink() && !this.checkIfInactiveClass('View Instruction') ? '' : 'disabled')}>
                                    <Link>
                                        <Button disabled={this.props.checklistDetail ? this.checkIfInactive(this.props.checklistDetail.details[0].templateStatusId, 'View Instruction') || !this.checkInstructionLink() : null} onClick={this.checkInstructionLink() ? this.handleViewInstruction : null} fullWidth variant="outlined" color="primary"><span>{translations.VIEW_INSTRUCTION}</span></Button>
                                    </Link>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid className="form-component" container spacing={1}>
                    <MuiThemeProvider theme={this.state.tooltipTheme}>
                        <FormControl fullWidth component="fieldset">
                            <Input onChange={this.handleInstructionUploadChange} id="instructionLink" className="file-hidden" type="file" name="instructionLink" />
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormGroup>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={5}>
                                                <span className="form-label">{translations.CHECKLIST_NO}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <span className="form-label">{ this.props.checklistDetail ? this.renderText(this.props.checklistDetail.details[0].no) : null }</span>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={5}>
                                                <span className="form-label">{translations.CHECKLIST_NAME}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <span className="value-label">{ this.props.checklistDetail ? this.renderText(this.props.checklistDetail.details[0].name) : null }</span>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={5}>
                                                <span className="form-label">{translations.TEMPLATE_TYPE}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <span className="value-label">{ this.props.checklistDetail ? this.renderTempType(this.props.checklistDetail.details[0].tempType) : null }</span>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormGroup>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={5}>
                                                <span className="form-label">{translations.MACHINE_TYPE}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <span className="value-label">{ this.props.checklistDetail ? this.renderText(this.titleCase(this.props.checklistDetail.details[0].macType_desc)) : null }</span>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={5}>
                                                <span className="form-label">{translations.MACHINE_SUB_TYPE}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <span className="value-label">{ this.props.checklistDetail ? this.renderText(this.props.checklistDetail.details[0].macsubtype_desc) : null }</span>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={5}>
                                                <span className="form-label">{translations.BRAND}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <span className="value-label">{ this.props.checklistDetail ? this.renderText(this.props.checklistDetail.details[0].brand) : null }</span>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormGroup>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{translations.MAINTENANCE_FREQ}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <span className="value-label">{ this.props.checklistDetail ? this.renderText(this.props.checklistDetail.details[0].frequencyId_desc) : null }</span>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid className="form-control" container spacing={1}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{translations.STATUS}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    { this.renderStatus(this.props.checklistDetail ? this.renderText(this.props.checklistDetail.details[0].templateStatus) : null) }
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </MuiThemeProvider>
                </Grid>
                { this.props.checklistDetail ? <ChecklistTasksDetails tasks={this.props.checklistDetail.tasksDetailed} /> : null }
            </div>
        );
    }

    render() {
        return (
            <MainPage className="checklist-form">
                <MainPageHeader title="Checklist Templates" search={false} back={this.getBackLink()} />
                <MainPageBody>
                    { this.checkIfApiLoading() === false ?  this.content() : null}
                </MainPageBody>
            </MainPage>
        );
    }
}
const mapStateToProps = state => {
    let { translationReducer } = state
    let apiLoading = {
        checklistDetailLoading: state.checklistReducer.loading,
        macTypeListLoading: state.macTypeListReducer.loading,
        macSubTypeListLoading: state.macSubTypeListReducer.loading,
        brandListLoading: state.brandListReducer.loading,
        frequencyListLoading: state.frequencyListReducer.loading,
    }
    let data = {
        userAccess: state.authReducer.access,
        checklistTemplatesStatusList: state.statusListReducer.checklistTemplatesStatusList,
        machineListChecklist: state.checklistReducer.machineListChecklist,
        checklistDetail: state.checklistReducer.checklistDetail,
        macTypeList: state.macTypeListReducer.macTypeList,
        macSubTypeList: state.macSubTypeListReducer.macSubTypeList,
        brandList: state.brandListReducer.brandList,
        frequencyList: state.frequencyListReducer.frequencyList,
    }
    return {
        ...apiLoading,
        ...data,
        ...translationReducer
    }

}

const mapDispatchToProps = dispatch => {
    return {
        notificationError: (message) => dispatch(notificationError(message)),
        getMacTypes: () => dispatch(getMacTypes()),
        getMacSubTypes: (isActive,macTypeId) => dispatch(getMacSubTypes(isActive,macTypeId)),
        getAllMacSubTypes: () => dispatch(getAllMacSubTypes()),
        getBrands: () => dispatch(getBrands()),
        getFrequencies: () => dispatch(getFrequencies()),
        getChecklistDetail: (id) => dispatch(getChecklistDetail(id)),
        postChecklistTemplate: (payload) => dispatch(postChecklistTemplate(payload)),
        putChecklistTemplate: (payload) => dispatch(putChecklistTemplate(payload)),
        postUploadTemplate: (payload,file) => dispatch(postUploadTemplate(payload,file)),
        putInActiveChecklistDetail: (payload,loadStatusId) => dispatch(putInActiveChecklistDetail(payload,loadStatusId)),
        getChecklistInstructions: (link) => dispatch(getChecklistInstructions(link)),
        getMachinelist: (temptype, checklistId) => dispatch(getMachinelist(temptype, checklistId)),
        postAssignChecklistToMachine: (payload) => dispatch(postAssignChecklistToMachine(payload)),
        getChecklistTemplateStatus: () => dispatch(getChecklistTemplateStatus()),
    }
}

ChecklistDetail = connect(mapStateToProps, mapDispatchToProps)(ChecklistDetail);

export default ChecklistDetail;
