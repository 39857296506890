import { tokenGet, tokenPost, tokenPostUpload, apiSettings, tokenPut, tokenDelete } from '../../../utils/api/CoreApi';
import store from '../../store'
import { FACTORY_ACTIONS as actions, NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from '../actionTypes'
import { deleteBuilding } from '../Building/buildingActions';
import { deleteFloor } from '../Floor/floorActions';
import { deleteArea } from '../AreaConfig/areaConfigActions';
import { deleteLine } from '../LineConfig/lineConfigActions';
import { showModal, closeModal } from '../Modal/modalActions';
const queryActions = {}
const componentActions = {}
let { dispatch } = store
let { apiURLReferences } = apiSettings;

export const notificationSuccess = (message) => {
    return {
        type: NOTIFICATION_SUCCESS,
        payload: {
            message: message.translate()
        }
    }
}

export const notificationError = (message) => {
    return {
        type: NOTIFICATION_ERROR,
        payload: {
            message: message.translate()
        }
    }
}

queryActions.notificationError = async (message) => {
    try {
        dispatch(notificationError(message));
    } catch (error) {
        console.error('factoryActions', error)
    }
}

queryActions.getFactoryTreeView = async (ev = undefined, isActive = 'all') => {
    try {
        let { data : {factories: data}, status } = await tokenGet(`${apiURLReferences}/factories`)
        if (isActive === 'all'){
            data = data.filter((item) => { return item.details.code !== '000'});
        }
        else if (isActive === true){
             data = data.filter((item) => { return item.details.code !== '000' && item.details.status === 'Active'});
        }
        dispatch({
            type: actions.FACTORY_TREE_VIEW,
            payload: {
                factoryListTree: data,
                factoryTimeStamp: new Date(),
                status
            }
        })
        if(ev) dispatch(ev)
    } catch (error) {
        console.error('factoryActions', error)
    }
}

queryActions.getFactoryTreeViewById = async (id, ev = undefined) => {
    try {
        let { data : {factories: data}, status } = await tokenGet(`${apiURLReferences}/factories?id=${id}`)
        dispatch({
            type: actions.FACTORY_TREE_VIEW_BY_ID,
            payload: {
                factoryListTreeById: data,
                factoryTimeStampById: new Date(),
                status
            }
        })
        if(ev) dispatch(ev)
    } catch (error) {
        console.error('factoryActions', error)
    }
}

queryActions.getFactoryProfile = async (id) => {
    try {
        tokenGet(`${apiURLReferences}/factories/` + id).then((response) => {
            dispatch({
                type: actions.FACTORY_PROFILE,
                payload: {
                    factoryProfile: response.data.data[0],
                    factoryShift: response.data.shifts,
                }
            });
        })
    } catch (error) {
        console.error('factoryActions', error)
    }
}

queryActions.getFactoryRowDetail = async (id) => {
    try {
        dispatch(getFactoryRowDetail(id));
    } catch (error) {
        console.error('factoryActions', error)
    }
}

queryActions.showModal = async (name) => {
    try {
        dispatch(showModal(name));
    } catch (error) {
        console.error('factoryActions', error)
    }
}

queryActions.closeModal = async () => {
    try {
        dispatch(closeModal());
    } catch (error) {
        console.error('factoryActions', error)
    }
}

queryActions.deleteBuilding = async (id,factoryId) => {
    try {
        dispatch(deleteBuilding(id,factoryId));
    } catch (error) {
        console.error('factoryActions', error)
    }
}


queryActions.deleteFloor = async (id,factoryId) => {
    try {
        dispatch(deleteFloor(id,factoryId));
    } catch (error) {
        console.error('factoryActions', error)
    }
}

queryActions.deleteArea = async (id,factoryId) => {
    try {
        dispatch(deleteArea(id,factoryId));
    } catch (error) {
        console.error('factoryActions', error)
    }
}


queryActions.deleteLine = async (id,factoryId) => {
    try {
        dispatch(deleteLine(id,factoryId));
    } catch (error) {
        console.error('factoryActions', error)
    }
}

export const factoryNewRequest = () => {
    return {
        type: actions.FACTORY_NEW_REQUEST,
    }
}

export const factoryNewSuccess = (data) => {
    return {
        type: actions.FACTORY_NEW,
        payload: data
    }
}

export const factoryUpdateRequest = () => {
    return {
        type: actions.FACTORY_UPDATE_REQUEST,
    }
}

export const factoryUpdateSuccess = (data) => {
    return {
        type: actions.FACTORY_UPDATE,
        payload: data
    }
}

export const factoryTimezoneSuccess = (data) => {
    return {
        type: actions.FACTORY_TIMEZONE,
        payload: data
    }
}

export const factoryAssignedSuccess = (data) => {
    return {
        type: actions.FACTORY_ASSIGNED,
        payload: data
    }
}

export const factoryNewShiftSuccess = (data) => {
    return {
        type: actions.FACTORY_SHIFT_NEW,
        payload: data
    }
}


export const factoryUpdateShiftSuccess = (data) => {
    return {
        type: actions.FACTORY_SHIFT_UPDATE,
        payload: data
    }
}


export const factoryDeleteShiftSuccess = (data) => {
    return {
        type: actions.FACTORY_SHIFT_DELETE,
        payload: data
    }
}

export const getFactoryTreeView = async () => {
    try {
        let { data : {factories: data}, status } = await tokenGet(`${apiURLReferences}/factories`)
        data = data.filter((item) => { return item.details.code !== '000'});
        dispatch({
            type: actions.FACTORY_TREE_VIEW,
            payload: {
                factoryListTree: data,
                factoryTimeStamp: new Date(),
                status
            }
        })
    } catch (error) {
        console.error('factoryActions', error)
    }
}

export const getFactoryTimezone = () => {
    return function(dispatch){
        try {
            tokenGet(`${apiURLReferences}/factories/timezones`).then((response) => {
                dispatch(factoryTimezoneSuccess(response.data.data));
            })
        } catch (error) {
            console.error('factoryActions', error)
        }
    }
}

export const getFactoryAssigned = () => {
    return function(dispatch){
        try {
            tokenGet(`${apiURLReferences}/factories/assigned`).then((response) => {
                localStorage.setItem('factoriesAssigned',JSON.stringify(response.data.data));
                dispatch(factoryAssignedSuccess(response.data.data));
            })
        } catch (error) {
            console.error('factoryActions', error)
        }
    }
}

export const getFactoryProfile = (id) => {
    return function(dispatch){
        try {
            tokenGet(`${apiURLReferences}/factories/` + id).then((response) => {
                dispatch({
                    type: actions.FACTORY_PROFILE,
                    payload: {
                        factoryProfile: response.data.data[0],
                        factoryShift: response.data.shifts,
                    }
                });
            })
        } catch (error) {
            console.error('factoryActions', error)
        }
    }
}

export const getFactoryRowDetail = (id) => {
    return function(dispatch){
        try {
            tokenGet(`${apiURLReferences}/factories/` + id).then((response) => {
                dispatch({
                    type: actions.FACTORY_ROW_DETAIL,
                    payload: {
                        factoryRowDetail: response.data.data[0]
                    }
                });
            })
        } catch (error) {
            console.error('factoryActions', error)
        }
    }
}

export const postNewFactory = (payload) => {
    let notif = notificationSuccess('Successfully added factory')
    return function(dispatch){
        try {
            dispatch(factoryNewRequest());
            tokenPost(`${apiURLReferences}/factories`,payload).then((response) => {
                dispatch(getFactoryAssigned());
                dispatch(factoryNewSuccess(null));
                dispatch(queryActions.getFactoryTreeView(notif))
            })
            .catch((err) => {
                try{
                    if (Array.isArray(err.response.data.errors)){
                        let errors = err.response.data.errors;
                        if (errors[0].code){
                            dispatch(notificationError('Factory code exists'));
                        }
                        else if (errors[0].cardNo){
                            dispatch(notificationError('Card Id exists'));
                        }
                    }
                    else{
                        dispatch(notificationError(err.response.data.error));
                    }
                }
                catch(error){

                }
            });
        } catch (err) {
            console.error('factoryActions', err)
        }
    }
}

export const putUpdateFactory = (id,payload) => {
    let notif = notificationSuccess('Successfully updated factory')
    return function(dispatch){
        try {
            dispatch(factoryUpdateRequest());
            tokenPut(`${apiURLReferences}/factories/` + id,payload).then((response) => {
                dispatch(getFactoryAssigned());
                dispatch(getFactoryRowDetail(id));
                dispatch(factoryUpdateSuccess(null));
                queryActions.getFactoryTreeView(notif);
                queryActions.getFactoryTreeViewById(id,notif);

            })
            .catch((err) => {
                console.log('error here');
                console.log(err);
                if (Array.isArray(err.response.data.errors)){
                    let errors = err.response.data.errors;
                    if (errors[0].code){
                        dispatch(notificationError('Factory code exists'));
                    }
                    else if (errors[0].isActive){
                        dispatch(notificationError("Cannot deactivate. There are machine(s) currently attached to the line"));
                    }
                }
                else{
                    dispatch(notificationError(err.response.data.error));
                }
            });
        } catch (error) {
            if (error.message.indexOf(422) >= 0){
                dispatch(notificationError("Cannot deactivate. There are machine(s) currently attached to the line"));
            }
            console.error('factoryActions', error)
        }
    }
}

export const putUpdateFactoryImage = (id,payload,method) => {
    return function(dispatch){
        try {
            tokenPut(`${apiURLReferences}/factories/` + id + '/images',payload).then((response) => {
                if (payload.method === 'factory-profile'){
                    dispatch(getFactoryProfile(id));
                    dispatch(notificationSuccess('Successfully updated factory profile image'));
                    dispatch(closeModal());
                 }
                 else  if (payload.method === 'factory-banner'){
                    dispatch(getFactoryProfile(id));
                    dispatch(notificationSuccess('Successfully updated factory profile banner'));
                    dispatch(closeModal());
                 }
            })
        } catch (error) {
            console.error('factoryActions', error)
        }
    }
}

export const uploadFactoryProfile = (id,payload, files) => {
    return function(dispatch){
        try {
            tokenPostUpload(apiSettings.apiURLFiles + '/api/files', files).then((response) => {
                 let mutatedPayload = null;
                 if (payload.method === 'factory-profile'){
                    mutatedPayload = {
                        ...payload,
                        profileImgLink: response.data.files[0].path
                    }
                 }
                 else  if (payload.method === 'factory-banner'){
                    mutatedPayload = {
                        ...payload,
                        bannerImgLink: response.data.files[0].path
                    }
                 }
                 dispatch(putUpdateFactoryImage(id,mutatedPayload));
                //  dispatch(queryActions.getFactoryTreeView())
            });
        } catch (error) {
            console.error('factoryActions', error)
        }
    }
}



export const postNewFactoryShift = (id,payload) => {
    return function(dispatch){
        try {
            dispatch(factoryNewRequest());
            tokenPost(`${apiURLReferences}/shifts/`,payload).then((response) => {
                dispatch(factoryNewShiftSuccess(null));
                dispatch(notificationSuccess('Successfully added shift'))
                dispatch(queryActions.getFactoryProfile(payload.factoryId));
            })
            .catch((error) => {
                if (error.message.indexOf(500) >= 0){
                    dispatch(notificationError('Factory code exists'));
                }
                console.log(error);
            });
        } catch (error) {
            console.error('factoryActions', error)
        }
    }
}

export const putUpdateFactoryShift = (id,payload) => {
    return function(dispatch){
        try {
            dispatch(factoryUpdateRequest());
            tokenPut(`${apiURLReferences}/shifts/` + id,payload).then((response) => {
                dispatch(factoryUpdateSuccess(null));
                dispatch(notificationSuccess('Successfully updated shift'));
                dispatch(queryActions.getFactoryProfile(payload.factoryId));
            })
            .catch((error) => {
                if (error.message.indexOf(500) >= 0){
                    dispatch(notificationError('Factory code exists'));
                }
                else if (error.message.indexOf(422) >= 0){
                    dispatch(notificationError("Cannot deactivate. There are machine(s) currently attached to the line"));
                }
                console.log(error);
            });
        } catch (error) {
            if (error.message.indexOf(422) >= 0){
                dispatch(notificationError("Cannot deactivate. There are machine(s) currently attached to the line"));
            }
            console.error('factoryActions', error)
        }
    }
}

export const deleteFactoryShift = (id,factoryId) => {
    let notif = notificationSuccess('Successfully deleted factory')
    return function(dispatch){
        try {
            tokenDelete(`${apiURLReferences}/shifts/` + id).then((response) => {
                dispatch(notificationSuccess('Successfully delete shift'));
                dispatch(queryActions.getFactoryProfile(factoryId));
                // dispatch(queryActions.getFactoryTreeViewById(factoryId))
            })
            .catch(error => {
                if (error.message.indexOf(500) >= 0 || error.message.indexOf(422) >= 0){
                    dispatch(notificationError('Cannot delete because it is currently referenced to another record'));
                }
            })
        } catch (error) {
            console.error('factoryActions', error)
        }
    }
}

export default () => {

    return {
        ...queryActions
    }
}
