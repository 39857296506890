import { Badge, Box, TableCell, TableRow } from '@material-ui/core';
import React from 'react';

function RowReported({
    index,
    row,
    twoDigitText,
    tickets,
    fieldNameConfig,
    args,
    timeMin,
    timeHour,
    listType,
    ticketListType,
}) {

    return (
        <TableRow
            className={
                (row[fieldNameConfig.ticketList.time] &&
                    Number.parseInt(
                        row[fieldNameConfig.ticketList.time]
                            .toString()
                            .replace(/^\d+\:/gi, "")
                            .replace(/\:\d{2}$/gi, "")
                    ) >= tickets[args].limit) ||
                    (row[fieldNameConfig.ticketList.time] &&
                        Number.parseInt(
                            row[fieldNameConfig.ticketList.time]
                                .toString()
                                .replace(/\:\d{2}\:\d{2}$/gi, "")
                        ) > 0)
                    ? "reopen-row yellow-background"
                    : "reopen-row"
            }
        >
            <TableCell className="machine-no">{twoDigitText(index + 1)}</TableCell>
            <TableCell className="machine-name">
                <Box className='cellReopened'>
                    <Box>
                        {`${row[fieldNameConfig[ticketListType].line] == null
                        ? row[fieldNameConfig[ticketListType].area]
                        : row[fieldNameConfig[ticketListType].line]
                        } ${listType == "big-list"
                            ? row[fieldNameConfig[ticketListType].line] == null
                                ? ""
                                : "-"
                            : ""
                        } ${listType == "big-list" &&
                            row[fieldNameConfig[ticketListType].station] != 0
                            ? row[fieldNameConfig[ticketListType].station]
                            : ""
                    }`}
                    </Box>
                    <Box className='badgeReopen'>{row.status}</Box>
                </Box>
            </TableCell>
            <TableCell className={
                listType == "big-list" ? "machine-no reported-time" : "machine-no npt"
            } align="center">
                <span className="time">{timeHour}</span>
            </TableCell>
            <TableCell className={
                listType == "big-list" ? "machine-no reported-time" : "machine-no npt"
            } align="center">
                <span className="time">{timeMin}</span>
            </TableCell>
        </TableRow>
    );
}

export default RowReported;