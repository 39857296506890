import {
    INPUT_FOCUS,
    INPUT_FOCUS_SUCCESS
 } from '../actionTypes';
 import { tokenGet, apiSettings } from '../../../utils/api/CoreApi';
 
 export const inputFocus = (field) => {
     return {
         type:INPUT_FOCUS,
         payload: field
     }
 }
 
 export const inputFocusSuccess = () => {
     return {
         type:INPUT_FOCUS_SUCCESS,
     }
 }