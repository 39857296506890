import {
    NOTIFICATION_SUCCESS,
    NOTIFICATION_ERROR,
    TICKET_LIST,
    TICKET_LIST_UPDATE_TOTAL,
    TICKET_LIST_UPDATE_PAGE,
    TICKET_LIST_UPDATE_DISPLAYED_ROW,
    MAINTENANCE_LIST_UPDATE_DISPLAYED_ROW,
    TICKET_LIST_REQUEST,
    TICKET_LIST_SUCCESS,
    TICKET_LIST_SEARCH_REQUEST,
    TICKET_LIST_SEARCH_SUCCESS,
    TICKET_LIST_CHECKBOX_TOGGLE,
    MAINTENANCE_LIST_CHECKBOX_TOGGLE,
    TICKET_LIST_CHECKBOX_ALL,
    TICKET_LIST_ERROR,
    TICKET_LIST_SHOW_TOGGLE,
    TICKET_LIST_CLOSE_TOGGLE,
    MAINTENANCE_LIST_SHOW_TOGGLE,
    MAINTENANCE_LIST_CLOSE_TOGGLE,
    TICKET_TYPE_LIST_REQUEST,
    TICKET_TYPE_LIST_SUCCESS,
    TICKET_TYPE_LIST_ERROR,
    TICKET_DETAIL_REQUEST,
    TICKET_DETAIL_SUCCESS,
    TICKET_DETAIL_ERROR,
    TICKET_NEW_ITEM_REQUEST,
    TICKET_NEW_ITEM_SUCCESS,
    TICKET_MAINTENANCE_ITEM_REQUEST,
    TICKET_MAINTENANCE_ITEM_SUCCESS,
    TICKET_NEW_ITEM_ERROR,
    TICKET_MAINTENANCE_HISTORY_REQUEST,
    TICKET_MAINTENANCE_HISTORY_SUCCESS,
    TICKET_MAINTENANCE_HISTORY_ERROR,
    TICKET_REPAIR_HISTORY_REQUEST,
    TICKET_REPAIR_HISTORY_SUCCESS,
    TICKET_REPAIR_HISTORY_ERROR,
    TICKET_UPDATE_REQUEST,
    TICKET_UPDATE_SUCCESS,
    TICKET_CANCEL_REQUEST,
    TICKET_CANCEL_SUCCESS,
    TICKET_MACHINE_UP,
    TICKET_MACHINE_UP_UPDATE_TOTAL,
    TICKET_MACHINE_UP_UPDATE_PAGE,
    TICKET_MACHINE_UP_UPDATE_DISPLAYED_ROW,
    TICKET_MACHINE_UP_REQUEST,
    TICKET_MACHINE_UP_SUCCESS,
    ASSET_DETAIL_REQUEST,
    ASSET_DETAIL_SUCCESS,
} from '../actionTypes';
import { capitalize } from '../../../utils/helper';
import moment from 'moment';

import history from '../../../history';
import { getMachineById, getMachinesForTicketCreation } from '../../actions/Machine/machineActions';
import { tokenGet,tokenPost, apiSettings, tokenPut } from '../../../utils/api/CoreApi';
import { formatDate, renderDownTime, displayMinute } from '../../../utils/date';

export const notificationSuccess = (message) => {
    return {
        type: NOTIFICATION_SUCCESS,
        payload: {
            message: message.translate()
        }
    }
}

export const notificationError = (message) => {
    return {
        type: NOTIFICATION_ERROR,
        payload: {
            message: message.translate()
        }
    }
}

export const ticketRequest = () => {
    return {
        type: TICKET_LIST_REQUEST
    }
}

export const ticketListSuccess = tickets => {
    return {
        type: TICKET_LIST_SUCCESS,
        payload: tickets
    }
}

export const ticketDetailRequest = () => {
    return {
        type: TICKET_DETAIL_REQUEST
    }
}

export const ticketDetailSuccess = data => {
    return {
        type: TICKET_DETAIL_SUCCESS,
        payload: data
    }
}

export const assetDetailRequest = asset => {
    return {
        type: ASSET_DETAIL_REQUEST,
        payload: asset
    }
}

export const assetDetailSuccess = asset => {
    return {
        type: ASSET_DETAIL_SUCCESS,
        payload: asset
    }
}

export const ticketListSearchRequest = () => {
    return {
        type: TICKET_LIST_SEARCH_REQUEST
    }
}

export const ticketListSearchSuccess = (tickets) => {
    return {
        type: TICKET_LIST_SEARCH_SUCCESS,
        payload: tickets
    }
}

export const ticketListUpdateTotal = payload => {
    return {
        type: TICKET_LIST_UPDATE_TOTAL,
        payload: payload
    }
}

export const ticketListUpdatePage = page => {
    return {
        type: TICKET_LIST_UPDATE_PAGE,
        payload: page
    }
}

export const ticketNewItemRequest = () => {
    return {
        type: TICKET_NEW_ITEM_REQUEST,
    }
}

export const ticketNewItemSuccess = (payload) => {
    return {
        type: TICKET_NEW_ITEM_SUCCESS,
        payload: payload
    }
}

export const ticketMaintenanceItemRequest = () => {
    return {
        type: TICKET_MAINTENANCE_ITEM_REQUEST,
    }
}

export const ticketMaintenanceItemSuccess = (payload) => {
    return {
        type: TICKET_MAINTENANCE_ITEM_SUCCESS,
        payload: payload
    }
}


export const ticketUpdateDisplayedRows = (rows) => {
    return {
        type: TICKET_LIST_UPDATE_DISPLAYED_ROW,
        payload: rows
    }
}

export const maintenanceUpdateDisplayedRows = (rows) => {
    return {
        type: MAINTENANCE_LIST_UPDATE_DISPLAYED_ROW,
        payload: rows
    }
}

export const ticketMaintenanceHistoryRequest = () => {
    return {
        type: TICKET_MAINTENANCE_HISTORY_REQUEST,
    }
}

export const ticketMaintenanceHistorySuccess = (tickets) => {
    return {
        type: TICKET_MAINTENANCE_HISTORY_SUCCESS,
        payload: tickets
    }
}

export const ticketRepairHistoryRequest = () => {
    return {
        type: TICKET_REPAIR_HISTORY_REQUEST,
    }
}

export const ticketRepairHistorySuccess = (tickets) => {
    return {
        type: TICKET_REPAIR_HISTORY_SUCCESS,
        payload: tickets
    }
}

export const ticketTypeRequest = () => {
    return {
        type: TICKET_TYPE_LIST_REQUEST
    }
}

export const ticketTypeSuccess = ticketTypes => {
    return {
        type: TICKET_TYPE_LIST_SUCCESS,
        payload: ticketTypes
    }
}

export const ticketUpdateRequest = () => {
    return {
        type: TICKET_UPDATE_REQUEST
    }
}

export const ticketUpdateSuccess = (payload) => {
    return {
        type: TICKET_UPDATE_SUCCESS,
        payload: payload,
    }
}

export const ticketCancelRequest = () => {
    return {
        type: TICKET_CANCEL_REQUEST
    }
}

export const ticketCancelSuccess = (ticketNo) => {
    return {
        type: TICKET_CANCEL_SUCCESS,
        ticketNo: ticketNo,
    }
}

export const ticketListShowToggle = (id,flag) => {
    return {
        type: TICKET_LIST_SHOW_TOGGLE,
        payload: {
            id: id,
            flag: flag
        }
    }
}


export const ticketListCloseToggle = () => {
    return {
        type: TICKET_LIST_CLOSE_TOGGLE,
    }
}

export const maintenanceListShowToggle = (id,flag) => {
    return {
        type: MAINTENANCE_LIST_SHOW_TOGGLE,
        payload: {
            id: id,
            flag: flag
        }
    }
}


export const maintenanceListCloseToggle = () => {
    return {
        type: MAINTENANCE_LIST_CLOSE_TOGGLE,
    }
}

export const toggleCheckboxSuccess = tickets => {
    return {
        type: TICKET_LIST_CHECKBOX_TOGGLE,
        payload: tickets
    }
}

export const toggleCheckboxMaintenanceSuccess = maintenance => {
    return {
        type: MAINTENANCE_LIST_CHECKBOX_TOGGLE,
        payload: maintenance
    }
}

export const delay = (ms) => new Promise(resolve =>
    setTimeout(resolve, ms)
);

export const renderLocation = (field) => {
    if (field === null || field === '' || field === 'null'){
        return ''
    }
    else{
        if(field.indexOf('-') >= 0){
            return field
        }else{
            let capitalized = field.split(' ').map(item => 
                item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(' ');
                return capitalized
        }

    }
}

export const toggleTicketTableCheckboxAll = (tickets) => {
    return function(dispatch){
        let data = tickets;
        let check = data.filter((item) => item.selected.value === false);
        if (check.length > 0){
            let tmpArray = data.map((item) => {
                let row = {
                    ...item,
                    selected: {
                        ...item.selected,
                        'value': true
                    }
                }
                return row;
            });
            dispatch(toggleCheckboxSuccess(tmpArray));
        }
        else{
            let tmpArray = data.map((item) => {
                let row = {
                    ...item,
                    selected: {
                        ...item.selected,
                        'value': false,
                    }
                }
                return row;
            });
            dispatch(toggleCheckboxSuccess(tmpArray));
        }
    }
}

export const toggleTicketTableCheckboxById = (tickets, id) => {
    return function(dispatch){
        let data = tickets;
        let tmpArray = data.map((item) => {
            let row = null;
            if (item.selected.id === id){
                row = {
                    ...item,
                    selected: {
                        ...item.selected,
                        'value': !item.selected.value,
                    }
                }
            }
            else{
                row = {
                    ...item,
                }
            }
            return row;
        });
        dispatch(toggleCheckboxSuccess(tmpArray));
    }
}

export const uncheckTicketTableCheckboxById = (tickets, ids) => {
    return function(dispatch){
        let data = tickets;
        ids.map((id) => {
            let rowPosition = data.map((item) => {return item.selected.id}).indexOf(id);
            if (rowPosition >= 0){
                data[rowPosition] = {
                    ...data[rowPosition],
                    selected: {
                        ...data[rowPosition].selected,
                        'value': false,
                    }
                }
            }
        })
        dispatch(toggleCheckboxSuccess(data));
    }
}

//MAINTENANCE
export const toggleMaintenanceTableCheckboxAll = (maintenance) => {
    return function(dispatch){
        let data = maintenance;
        let check = data.filter(x => x.selected.value === false && x.selected.hasSchedule === "false")
        if (check.length > 0){
            let tmpArray = data.map((item) => {
                let row = {
                    ...item,
                    selected: {
                        ...item.selected,
                        'value': item.selected.hasSchedule === "true" ? false : true,
                    }
                }
                return row;
            });
            dispatch(toggleCheckboxMaintenanceSuccess(tmpArray));
        }
        else{
            let tmpArray = data.map((item) => {
                let row = {
                    ...item,
                    selected: {
                        ...item.selected,
                        'value': false,
                    }
                }
                return row;
            });
            dispatch(toggleCheckboxMaintenanceSuccess(tmpArray));
        }
    }
}

export const uncheckMaintenanceTableCheckboxAll = (maintenance) => {
    
    return function(dispatch){
        let data = maintenance;
            let tmpArray = data.map((item) => {
                let row = {
                    ...item,
                    selected: {
                        ...item.selected,
                        'value': false,
                    }
                }
                return row;
            });
            dispatch(toggleCheckboxMaintenanceSuccess(tmpArray));
        
    }
}

export const toggleMaintenanceTableCheckboxById = (maintenance, id) => {
    return function(dispatch){
        let data = maintenance;
        let tmpArray = data.map((item) => {
            let row = null;
            if (item.selected.id === id){
                row = {
                    ...item,
                    selected: {
                        ...item.selected,
                        'value': !item.selected.value,
                    }
                }
            }
            else{
                row = {
                    ...item,
                }
            }
            return row;
        });
        dispatch(toggleCheckboxMaintenanceSuccess(tmpArray));
    }
}

export const renderTextWithCapitalization = (field) => {
    if (field === null || field === '' || field === 'null'){
        return ''
    }
    else{
        let capitalized = field.split(' ').map(item => 
            item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(' ');
            return capitalized
    }
}

export const ticketMachineUpRequest = () => {
    return {
        type: TICKET_MACHINE_UP_REQUEST
    }
}

export const ticketMachineUpSuccess = machines => {
    return {
        type: TICKET_MACHINE_UP_SUCCESS,
        payload: machines
    }
}

export const ticketMachineUpUpdateTotal = payload => {
    return {
        type: TICKET_MACHINE_UP_UPDATE_TOTAL,
        payload: payload
    }
}

export const ticketMachineUpUpdatePage = page => {
    return {
        type: TICKET_MACHINE_UP_UPDATE_PAGE,
        payload: page
    }
}

export const ticketMachineUpDisplayedRows = (rows) => {
    return {
        type: TICKET_MACHINE_UP_UPDATE_DISPLAYED_ROW,
        payload: rows
    }
}

export const getAllTickets = (pageSize = 10) => {
    return function(dispatch){
        dispatch(ticketRequest());
            tokenGet(apiSettings.apiURLTickets + '/api/tickets?pageSize=' + pageSize).then((response) => {
                let data = [];
                data = response.data.tickets.result.map((item) => {
                    let itemRow = {
                        'selected': {
                            id: item.id,
                            value: false
                        },
                        'ticketNo': {
                            link: "/ticket-detail/" + item.id,
                            linkId: item.id,
                            value: item.ticketNo
                        },
                        'building': item.buildingDesc,
                        'place': renderLocation(item.place),
                        'machine': {
                            link:'/machine-detail/' + item.machineId,
                            linkId:item.machineId,
                            value:item.machineNo
                        },
                        'type': item.type,
                        'subType': item.subType,
                        'brand': renderTextWithCapitalization(item.brand),
                        'serialNo': item.serialNo,
                        'grabbedBy': item.grabbedBy,
                        'repairedBy': item.repairedBy,
                        'elapsedDuration': displayMinute(item.elapsedDuration),
                        'reportedDuration': displayMinute(item.reportedDuration),
                        'inrepairDuration': displayMinute(item.inrepairDuration),
                        'closedDuration': displayMinute(item.closedDuration),
                        'closedBy': item.closedBy,
                        'status': item.status,
                        'ticketType': item.ticketType
                    }
                    return itemRow
                });
                dispatch(ticketListSuccess({
                    tickets: data,
                    meta: response.data.tickets.meta
                }));
                dispatch(ticketListUpdateTotal({
                    rows: data.length,
                    meta: response.data.tickets.meta
                }))
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

export const getAllTicketsBySearchPagination = (pageSize = 10, currentPage = 1, searchString = 'all', reportedDt = 'all', ticketType = 'all', status = 'all', excelData = []) => {
    return function(dispatch){
            dispatch(ticketRequest());
            let apiString = null;
            if (searchString === null || searchString === ''){
                apiString = apiSettings.apiURLTickets + '/api/tickets?pageSize=' + pageSize + '&currentPage=' + currentPage + '&reportedDt=' + reportedDt + '&ticketType=' + ticketType + '&status=' + status;
            }
            else{
                apiString = apiSettings.apiURLTickets + '/api/tickets?searchString=' + searchString +  '&pageSize=' + pageSize + '&currentPage=' + currentPage+ '&reportedDt=' + reportedDt + '&ticketType=' + ticketType + '&status=' + status;
            }            
            tokenGet(apiString).then((response) => {
                let data = [];
                data = response.data.tickets.result.map((item, index) => {
                    let itemRow = null;
                    if (ticketType === 'r'){
                        itemRow = {
                            'selected': {
                                id: item.id,
                                value: false,
                                ticketNo: item.ticketNo,
                            },
                            'index': ((currentPage - 1) * pageSize + 1) + index,
                            'ticketNo': {
                                link: "/ticket-detail/" + item.id,
                                linkId: item.id,
                                value: item.ticketNo
                            },
                            'building': item.buildingDesc,
                            'place': renderLocation(item.place),
                            'ticketmachine': {
                                link:'/machine-detail/' + item.machineId,
                                linkId:item.machineId,
                                value:item.machineNo
                            },
                            'type': item.type,
                            'subType': item.subType,
                            'brand': renderTextWithCapitalization(item.brand),
                            'serialNo': item.serialNo,
                            'reportedDt': formatDate(item.reportedDt, 'YYYY-MM-DD', false),
                            'grabbedBy': item.grabbedBy,
                            'repairedBy': item.repairedBy,
                            'elapsedDuration': displayMinute(item.elapsedDuration),
                            // 'reportedDuration': displayMinute(item.reportedDuration),
                            // 'inrepairDuration': displayMinute(item.inrepairDuration),
                            // 'closedDuration': displayMinute(item.closedDuration),
                            'closedBy': item.closedBy,
                            'status': item.status,
                            'ticketType': item.ticketType
                        }
                    }
                    else if (ticketType === 'm'){
                        itemRow = {
                            'selected': {
                                id: item.id,
                                value: false,
                                ticketNo: item.ticketNo,
                            },
                            'index': ((currentPage - 1) * pageSize + 1) + index,
                            'ticketNo': {
                                link: "/ticket-detail/" + item.id,
                                linkId: item.id,
                                value: item.ticketNo
                            },
                            'building': item.buildingDesc,
                            'place': renderLocation(item.place),
                            'ticketmachine': {
                                link:'/machine-detail/' + item.machineId,
                                linkId:item.machineId,
                                value:item.machineNo
                            },
                            'type': item.type,
                            'subType': item.subType,
                            'brand': renderTextWithCapitalization(item.brand),
                            'serialNo': item.serialNo,
                            'reportedDt': formatDate(item.reportedDt, 'YYYY-MM-DD', false),
                            'grabbedBy': item.grabbedBy,
                            'repairedBy': item.closedBy,
                            'elapsedDuration': displayMinute(item.elapsedDuration),
                            // 'reportedDuration': displayMinute(item.reportedDuration),
                            // 'inrepairDuration': displayMinute(item.inrepairDuration),
                            // 'closedDuration': displayMinute(item.closedDuration),
                            'closedBy': null,
                            'status': item.status,
                            'ticketType': item.ticketType,
                            'machineId': item.machineId
                        }
                    }
                    return itemRow
                });
                dispatch(ticketListSearchSuccess({
                    tickets: data,
                    excel: excelData,
                    searchString: searchString,
                    meta: response.data.tickets.meta
                }));
                dispatch(ticketListUpdateTotal({
                    rows: data.length,
                    meta: response.data.tickets.meta
                }))
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

export const getAllTicketsBySearch = (pageSize = 10, currentPage = 1, searchString = 'all', reportedDt = 'all', ticketType = 'all', status = 'all') => {
    return function(dispatch){
            dispatch(ticketRequest());
            let apiString = null;
            if (searchString === null || searchString === ''){
                apiString = apiSettings.apiURLTickets + '/api/tickets?pageSize=' + pageSize + '&currentPage=' + currentPage + '&reportedDt=' + reportedDt + '&ticketType=' + ticketType + '&status=' + status;
            } else {
                apiString = apiSettings.apiURLTickets + '/api/tickets?searchString=' + searchString +  '&pageSize=' + pageSize + '&currentPage=' + currentPage+ '&reportedDt=' + reportedDt + '&ticketType=' + ticketType + '&status=' + status;
            }            
            tokenGet(apiString).then((response) => {
                let data = [];
                data = response.data.tickets.result.map((item, index) => {
                    let itemRow = null;
                    if (ticketType === 'r'){
                        itemRow = {
                            'selected': {
                                id: item.id,
                                value: false,
                                ticketNo: item.ticketNo,
                            },
                            'index': index + 1,
                            'ticketNo': {
                                link: "/ticket-detail/" + item.id,
                                linkId: item.id,
                                value: item.ticketNo
                            },
                            'building': item.buildingDesc,
                            'place': renderLocation(item.place),
                            'ticketmachine': {
                                link:'/machine-detail/' + item.machineId,
                                linkId:item.machineId,
                                value:item.machineNo
                            },
                            'type': item.type,
                            'subType': item.subType,
                            'brand': renderTextWithCapitalization(item.brand),
                            'serialNo': item.serialNo,
                            'reportedDt': formatDate(item.reportedDt, 'YYYY-MM-DD', false),
                            'grabbedBy': item.grabbedBy,
                            'repairedBy': item.repairedBy,
                            'elapsedDuration': !item.elapsedDuration ? "0" : displayMinute(item.elapsedDuration),
                            // 'elapsedDuration': (item.inrepairDuration == "" || item.inrepairDuration == null) ? "0" : displayMinute(item.elapsedDuration),
                            'closedBy': item.closedBy,
                            'status': item.status,
                            'ticketType': item.ticketType,
                            'machineId': item.machineId
                        }
                    }
                    else if (ticketType === 'm'){
                        itemRow = {
                            'selected': {
                                id: item.id,
                                value: false,
                                ticketNo: item.ticketNo,
                            },
                            'index': index + 1,
                            'ticketNo': {
                                link: "/ticket-detail/" + item.id,
                                linkId: item.id,
                                value: item.ticketNo
                            },
                            'building': item.buildingDesc,
                            'place': renderLocation(item.place),
                            'ticketmachine': {
                                link:'/machine-detail/' + item.machineId,
                                linkId:item.machineId,
                                value:item.machineNo
                            },
                            'type': item.type,
                            'subType': item.subType,
                            'brand': renderTextWithCapitalization(item.brand),
                            'serialNo': item.serialNo,
                            'reportedDt': formatDate(item.reportedDt, 'YYYY-MM-DD', false),
                            'grabbedBy': item.grabbedBy,
                            'repairedBy': item.closedBy,
                            'elapsedDuration': !item.elapsedDuration ? "0" : displayMinute(item.elapsedDuration),
                            'closedBy': null,
                            'status': item.status,
                            'ticketType': item.ticketType,
                            'machineId': item.machineId
                        }
                    }
                    return itemRow
                });
                dispatch(ticketListSearchSuccess({
                    tickets: data,
                    searchString: searchString,
                    meta: response.data.tickets.meta
                }));
                dispatch(ticketListUpdateTotal({
                    rows: data.length,
                    meta: response.data.tickets.meta
                }))
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

export const getMaintenanceHistory = (id, pageSize = 10000) => {
    return function(dispatch){
        dispatch(ticketMaintenanceHistoryRequest());
        tokenGet(apiSettings.apiURLTickets + '/api/tickets/maintenance-history?machineId=' + id + '&pageSize=' + pageSize).then((response) =>{
            let data = [];
            data = response.data.tickets.result.map((item) => {
                let itemRow = {
                    'id':item.id,
                    'ticketNo':item.ticketNo,
                    'reportedBy': item.reportedBy != null ? item.reportedBy : '-',
                    'grabbedBy': item.grabbedBy != null ? item.grabbedBy : '-',
                    'repairedBy': item.repairedBy != null ? item.repairedBy : '-',
                    'elapsedDuration': !item.elapsedDuration ? "0" : displayMinute(item.elapsedDuration),
                    // 'elapsedDuration': (item.inrepairDuration == "" || item.inrepairDuration == null) ? "0" : displayMinute(item.elapsedDuration),

                    // 'reportedDuration': item.reportedDuration != null ? displayMinute(item.reportedDuration) : '-',
                    // 'inrepairDuration': item.inrepairDuration != null ? displayMinute(item.inrepairDuration) : '-',
                    // 'closedDuration': item.closedDuration != null ? displayMinute(item.closedDuration) : '-',
                    'closedBy': item.closedBy != null ? item.closedBy : '-',
                    'canceledBy': item.canceledBy != null ? item.canceledBy : '-',
                    'problemType': item.problemType != null? item.problemType : '-',
                    'lpm_date': item.lpm_date != null ? formatDate(item.lpm_date, 'YYYY-MM-DD') : '-',
                    'reportedDt': item.reportedDt,
                    'grabbedDt': item.grabbedDt,
                    'repairedDt': item.repairedDt,
                    'checkListNo': item.checkListNo != null ? item.checkListNo : '-',
                    'closedDt': item.closedDt,
                    'downtime': item.reportedDt !== null && item.closedDt != null 
                        ? renderDownTime(item.grabbedDt, item.closedDt) 
                        : '-',
                    'status': item.status != null ? item.status : '-',
                }
                return itemRow
            });
            dispatch(ticketMaintenanceHistorySuccess(data));
        }).catch((error) => {
            console.log(error);
        })
    }
}

export const getRepairHistory = (id, pageSize = 10000) => {
    return function(dispatch){
        dispatch(ticketRepairHistoryRequest());
        tokenGet(apiSettings.apiURLTickets + '/api/tickets/repair-history?machineId=' + id + '&pageSize=' + pageSize).then((response) =>{
            let data = [];
            data = response.data.tickets.result.map((item) => {
                let itemRow = {
                    'id':item.id,
                    'ticketNo':item.ticketNo,
                    'reportedBy': item.reportedBy != null ? item.reportedBy : '-',
                    'grabbedBy': item.grabbedBy != null ? item.grabbedBy : '-',
                    'repairedBy': item.repairedBy != null ? item.repairedBy : '-',
                    // 'elapsedDuration': item.elapsedDuration != null ? displayMinute(item.elapsedDuration) : '-',
                    'elapsedDuration': !item.elapsedDuration ? "-" : displayMinute(item.elapsedDuration),
                    // 'reportedDuration': item.reportedDuration != null ? displayMinute(item.reportedDuration) : '-',
                    // 'inrepairDuration': item.inrepairDuration != null ? displayMinute(item.inrepairDuration) : '-',
                    // 'closedDuration': item.closedDuration != null ? displayMinute(item.closedDuration) : '-',
                    'closedBy': item.closedBy != null ? item.closedBy : '-',
                    'canceledBy': item.canceledBy != null ? item.canceledBy : '-',
                    'problemType': item.problemType != null? item.problemType : '-',
                    'solutionType': item.solutionType != null ? item.solutionType: '-',
                    'reportedDt': item.reportedDt,
                    'grabbedDt': item.grabbedDt,
                    'repairedDt': item.repairedDt,
                    'closedDt': item.closedDt,
                    'downtime': item.reportedDt !== null && item.closedDt != null ? renderDownTime(item.reportedDt, item.closedDt) : '-',
                    'status': item.status != null ? item.status : '-',
                    'place': item.place != null ? item.place : '-',
                }
                return itemRow
            });
            dispatch(ticketRepairHistorySuccess(data));
        }).catch((error) => {
            console.log(error);
        })
    }
}

export const getTicketTypes = (isActive = 1, currentPage = 1, pageSize = 100000) =>{
    return function(dispatch){
        dispatch(ticketTypeRequest());
        tokenGet(apiSettings.apiURLReferences + '/tickettypes?&isActive='+ isActive +'&currentPage=' + currentPage +'&pageSize=' + pageSize).then((response) => {
            let data = response.data.tickets.result.map((item) => {
               let itemRow = {
                   'id': item.id,
                   'ticketType': item.ticketType,
                   'desc': item.desc1,
               }
               return itemRow;
            });
            dispatch(ticketTypeSuccess({
                ticketTypeList: data,
                meta: response.data.tickets.meta
            }));
        })
        .catch((error) => {
            console.log(error);
        });
    }
}


export const getTicketDetail = (ticketId = null) =>{
    return function(dispatch){
        dispatch(ticketDetailRequest());
        dispatch(assetDetailRequest());
        tokenGet(apiSettings.apiURLTickets + '/api/tickets/' + ticketId).then((response) => {
            dispatch(ticketDetailSuccess(response.data));
            dispatch(assetDetailSuccess(response.data.assets));
            dispatch(getMachineById(response.data.tickets[0].machineId));
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const postNewTicket = (payload) => {
    return function(dispatch){
        dispatch(ticketNewItemRequest());
        tokenPost(apiSettings.apiURLTickets + '/api/tickets',payload).then((response) =>{
            dispatch(ticketNewItemSuccess());
            dispatch(notificationSuccess('Successfully created ticket'));
            dispatch(getMaintenanceHistory(payload.machineId));
            dispatch(getRepairHistory(payload.machineId));
            if (localStorage.getItem('redirectToTickets') === 'true'){
                localStorage.setItem('ticketFilters',false);
                localStorage.setItem('redirectToTickets',false);
                history.push('/tickets');
            }
            else{
                localStorage.setItem('createdTicket','true');
                history.push('/machine-detail/' + payload.machineId);
            }
        }).catch((error) => {
            let data = error.response.data;
            if (data){
                if (Array.isArray(data.errors)){
                    dispatch(notificationError(data.errors[0].machineId))
                }
                else{
                    dispatch(notificationError(data.error));
                }
                dispatch(ticketNewItemSuccess());
            }
        })
    }
}

export const putTicketDetail = (ticketId,payload) => {
    return function(dispatch){
        dispatch(ticketUpdateRequest());
        tokenPut(apiSettings.apiURLTickets + '/api/tickets/update-status', payload).then((response) => {
            dispatch(getMachinesForTicketCreation());
            dispatch(getTicketDetail(ticketId));

            return delay(1000).then(() => {
                dispatch(ticketUpdateSuccess({data: payload.tickets, statusId: payload.statusId}));
                // dispatch(getAllTickets());
                dispatch(notificationSuccess('Successfully updated ticket(s)'));
                localStorage.removeItem('noload')
            });
        }).catch((error) => {
            const errorMsg = error.response;
            if (errorMsg != undefined){
                let capitalizedError = capitalize(errorMsg.data.errors[0]['tickets[0].no']);
                dispatch(notificationError(capitalizedError));
            }
        })
    }
}

export const putCancelTickets = (searchString, payload) => {
    return function(dispatch){
        dispatch(ticketCancelRequest());
        tokenPut(apiSettings.apiURLTickets + '/api/tickets/update-status', payload).then((response) => {
            dispatch(getMachinesForTicketCreation());
            dispatch(ticketCancelSuccess(payload.tickets));
            let filters = JSON.parse(localStorage.getItem('ticketFilters'));
            dispatch(getAllTicketsBySearchPagination(
                10,
                1,
                searchString,
                filters.date,
                filters.type,
                filters.status,
            ));
            dispatch(notificationSuccess('Successfully cancelled ticket(s)'));
        }).catch((error) => {
            let capitalizedError = capitalize('Cannot cancel ticket(s)');
            dispatch(notificationError(capitalizedError));
        })
    }
}

export const getMachineUpBySearch = (pageSize = 10, currentPage = 1, searchString = 'all', pmPlanDt = 'all') => {
    return function(dispatch){
            dispatch(ticketMachineUpRequest());
            let apiString = null;
            if (searchString === null || searchString === ''){
                apiString = apiSettings.apiURLTickets + '/api/tickets/machines-up-for-maintenance?pageSize=' + pageSize + '&currentPage=' + currentPage + '&pmPlanDt=' + pmPlanDt;
            }
            else{
                apiString = apiSettings.apiURLTickets + '/api/tickets/machines-up-for-maintenance?searchString=' + searchString +  '&pageSize=' + pageSize + '&currentPage=' + currentPage+ '&pmPlanDt=' + pmPlanDt;
            }            
            tokenGet(apiString).then((response) => {
                let data = [];
                data = response.data.machines.result.map((item) => {
                    let itemRow = {
                        'selected': {
                            id: item.machineId,
                            value: false,
                            hasSchedule: item.hasSchedule,
                        },
                        'ticketmachine': {
                            link:'/machine-detail/' + item.machineId,
                            linkId:item.machineId,
                            value:item.machineNo
                        },
                        'ticketNo': {
                            link: "/ticket-detail/" + item.ticketId,
                            linkId: item.ticketId,
                            value: item.ticketNo
                        },
                        'place': item.place,
                        'building': item.buildingDesc1,
                        'model': item.modelNo,
                        'type': item.type,
                        'subType': item.subType,
                        'brand': item.brand,
                        'pmFrequency': item.pmFrequency,
                        'checklists': item.checklists 
                            ? item.checklists 
                            : [],
                        'pmPlanDt': item.pmPlanDt,
                        'lastPMDt': item.lastPMDt,
                        'status': item.status, 
                        'machineId': item.machineId,
                    }
                    return itemRow
                });
                dispatch(ticketMachineUpSuccess({
                    machineUpList: data,
                    searchString: searchString,
                    machineUpFilter: {
                        pmPlanDt: pmPlanDt
                    },
                    machineUpCount: {
                        all: response.data.all,
                        week: response.data.week,
                        month: response.data.month,
                        today: response.data.today,
                        overdue: response.data.overdue,
                    },
                    meta: response.data.machines.meta
                }));
                dispatch(ticketMachineUpUpdateTotal({
                    rows: data.length,
                    meta: response.data.machines.meta
                }))
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

export const postMaintenanceTicket = (payload, data) => {
        console.log('post method starts here')
        return function(dispatch){
            if(payload.machines.length == 0){
                dispatch(notificationError('Please select a machine'))    
            }else{
                let successMessage = 'Successfully created ticket'
                if(payload.machines.length > 1){
                    successMessage = 'Successfully created ticket(s)'
                }
            dispatch(ticketMaintenanceItemRequest());
            tokenPost(apiSettings.apiURLTickets + '/api/tickets/pm',payload).then((response) =>{
                console.log('success here')
                dispatch(ticketMaintenanceItemSuccess());
                dispatch(notificationSuccess(successMessage));
                dispatch(uncheckMaintenanceTableCheckboxAll(data))
            }).catch((error) => {
                console.log('catch error here')
                let errors = error.response.data.errors
                let message = errors[0]['machines[0].id']
               console.log(message)
                if(errors.length >= 1){
                    message = 'Some machines are not available for ticket generation'
                } 
                dispatch(notificationError(message))
                dispatch(uncheckMaintenanceTableCheckboxAll(data))    
            })
        }
        }
        
}