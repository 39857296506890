import { 
  withStyles, 
  Box, 
  Typography, 
} from '@material-ui/core';

export const Container = withStyles({
    root: {
        background: '#eee',
        padding: 10,
        boxShadow: '0px 2px 5px #ccc',
        position: 'fixed',
        width: 350,
        bottom: 15,
        left: 15,
        zIndex: 100,
    }
})(Box);

export const CancelButton = withStyles({
  root: {
    color: '#1E88E5',
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 'bold',
    cursor: 'pointer'
  }
})(Typography);

export const FlexBox = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
  }
})(Box);

export const FileNameTypography = withStyles({
  root: {
    fontSize: 16,
    color: '#000',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
})(Typography);

export const CompletedTypography = withStyles({
  root: {
    fontSize: 12,
    color: '#666',
  }
})(Typography);

export const PercentTypography = withStyles({
  root: {
    fontSize: 12,
    color: '#000',
    fontWeight: 'bold',
  }
})(Typography);
