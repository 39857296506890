import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Link } from 'react-router-dom';
import './ChecklistTasksDetails.scss';

class ChecklistTasksDetails extends React.Component {

  state = {
      showTaskSeq: [],
      data: null,
      gridSize: null,
      formControls: []
  }

  checkIfShown(seq){
      let check = this.state.showTaskSeq.filter((item) => { return item === seq});
      if (check.length > 0){
          return true;
      }
      else{
          return false;
      }
  }

  handleShowSubtask = (seq) => {
      let tasks = this.state.showTaskSeq;
      tasks.push(seq);
      this.setState({showTaskSeq: tasks});
  }

  handleHideSubtask = (seq) => {
    let tasks = this.state.showTaskSeq.filter((item) => { return item !== seq });
    this.setState({showTaskSeq: tasks});
  }

  renderDropdownIcon = (seq,subtasks) =>{
      
    if (subtasks.length > 0){
        if (this.checkIfShown(seq)){
            return (
                <ArrowDropUpIcon onClick={() => this.handleHideSubtask(seq)} className="dropdown-icon" />
            )
          }
          else{
            return (
                <ArrowDropDownIcon onClick={() => this.handleShowSubtask(seq)} className="dropdown-icon" />
            )
          }
    }
    else{
        return null;
    }
  }

  addedZero(value){
      return ("0" + value).slice(-2);
  }

  renderTasks(){
    if (this.props.tasks){
        if (false){
            return null
        }
        else{
            let mutatedTask = this.props.tasks.map((item) => {
                let task = {
                    ...item.task,
                    subtasks: item.subtasks
                }
                return task;
            });
            let tasks = mutatedTask.map((item) => {
                return (
                    <div className="item-group">
                        <div className="task">
                            <Grid container spacing={0}>
                                <Grid item xs={5}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={1}>
                                            <div className="task-sequence">{this.addedZero(item.seq) }</div>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Grid className="task-title">
                                                <FormControl fullWidth>
                                                    <span>{item.task + (item.subtasks.length ? " (" + item.subtasks.length + ")" : '')}</span>
                                                    {/* <TextField onChange={ this.handleTaskChange } name={"task" + item.seq} value={item.task} variant="outlined" /> */}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={5}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={11}>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Link className="task-action">
                                                 { this.renderDropdownIcon(item.seq, item.subtasks) }
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        <div className={"subtask-group " + ( this.checkIfShown(item.seq) ? 'subtask-show' : '')}>
                            { this.renderSubTasks(item.subtasks, item.seq) }
                        </div>
                    </div>
                )
            });
            return tasks;

        }
    }
}



renderSubTasks(subTaskList, taskSequence){
        let subtasks = subTaskList.map((item) => {
            return (
                <div className="subtask">
                    <Grid container spacing={0}>
                        <Grid item xs={5}>
                            <Grid container spacing={0}>
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={11}>
                                    <Grid className="subtask-title">
                                        <FormControl fullWidth>
                                            <span>{item.subtask}</span>
                                            {/* <TextField onChange={this.handleSubTaskChange} name={ taskSequence + "-" + item.seq } value={item.subtask} variant="outlined" /> */}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            )
        });
        return subtasks;
    }
  
  render(){
    return (
        <div className="checklist-tasks-details">
            <div className="task-group">
                { this.renderTasks() }
            </div>
        </div>
    )
  }
}

export default ChecklistTasksDetails;
