import {
    NOTIFICATION_SUCCESS,
    NOTIFICATION_ERROR,
    MACSUBTYPE_LIST,
    MACSUBTYPE_LIST_REQUEST,
    MACSUBTYPE_LIST_SUCCESS,
    MACSUBTYPE_ALL_LIST_REQUEST,
    MACSUBTYPE_ALL_LIST_SUCCESS,
    MACSUBTYPE_LIST_TREE_VIEW,
    MACSUBTYPE_LIST_ERROR,
    MACSUBTYPE_DETAIL_REQUEST,
    MACSUBTYPE_DETAIL_SUCCESS,
    MACSUBTYPE_DETAIL_ERROR,
    MACSUBTYPE_NEW_REQUEST,
    MACSUBTYPE_NEW_SUCCESS,
    MACSUBTYPE_NEW_ERROR,
    MACSUBTYPE_UPDATE_REQUEST,
    MACSUBTYPE_UPDATE_SUCCESS,
    MACSUBTYPE_UPDATE_ERROR,
    MACSUBTYPE_DELETE_REQUEST,
    MACSUBTYPE_DELETE_SUCCESS,
    MACSUBTYPE_DELETE_ERROR,
    MACSUBTYPE_TRIGGER_HIGHLIGHT,
    MACSUBTYPE_LIST_TREE_VIEW_DEFAULT
} from '../actionTypes';
import { tokenGet,tokenPost, tokenPut, tokenDelete, apiSettings } from '../../../utils/api/CoreApi';
import history from '../../../history';
import { macTypeTriggerHighlight } from '../MacTypes/MacTypeActions';

export const notificationSuccess = (message) => {
   return {
       type: NOTIFICATION_SUCCESS,
       payload: {
           message: message.translate()
       }
   }
}

export const notificationError = (message) => {
   return {
       type: NOTIFICATION_ERROR,
       payload: {
           message: message.translate()
       }
   }
}

export const macSubTypeRequest = () => {
    return {
        type: MACSUBTYPE_LIST_REQUEST
    }
}

export const macSubTypeSuccess = statusList => {
    return {
        type: MACSUBTYPE_LIST_SUCCESS,
        payload: statusList
    }
}

export const allMacSubTypeRequest = () => {
    return {
        type: MACSUBTYPE_ALL_LIST_REQUEST
    }
}

export const allMacSubTypeSuccess = statusList => {
    return {
        type: MACSUBTYPE_ALL_LIST_SUCCESS,
        payload: statusList
    }
}

export const macSubTypeTreeView = statusList => {
    return {
        type: MACSUBTYPE_LIST_TREE_VIEW,
        payload: statusList
    }
}

export const macSubTypeTreeViewDefault = statusList => {
    return {
        type: MACSUBTYPE_LIST_TREE_VIEW_DEFAULT,
        payload: statusList
    }
}



export const macSubTypeDetailRequest = () => {
    return {
        type: MACSUBTYPE_DETAIL_REQUEST
    }
}

export const macSubTypeDetailSuccess = macSubType => {
    return {
        type: MACSUBTYPE_DETAIL_SUCCESS,
        payload: macSubType
    }
}

 export const macSubTypeNewRequest = () => {
    return {
        type: MACSUBTYPE_NEW_REQUEST
    }
}

export const macSubTypeNewSuccess = macSubType => {
    return {
        type: MACSUBTYPE_NEW_SUCCESS,
        payload: macSubType
    }
}

export const macSubTypeUpdateRequest = () => {
    return {
        type: MACSUBTYPE_UPDATE_REQUEST
    }
}

export const macSubTypeUpdateSuccess = macSubType => {
    return {
        type: MACSUBTYPE_UPDATE_SUCCESS,
        payload: macSubType
    }
}

export const macSubTypeDeleteRequest = () => {
    return {
        type: MACSUBTYPE_DELETE_REQUEST
    }
}

export const macSubTypeDeleteSuccess = macSubType => {
    return {
        type: MACSUBTYPE_DELETE_SUCCESS,
        payload: macSubType
    }
}

export const macSubTypeTriggerHighlight = flag => {
    return {
        type: MACSUBTYPE_TRIGGER_HIGHLIGHT,
        payload: flag
    }
}

export const getMacSubTypes = (isActive = 'all', macType = 'all', currentPage = 1, pageSize = 100000) => {
    return function (dispatch) {
        dispatch(macSubTypeRequest());
        tokenGet(apiSettings.apiURLReferences + '/macsubtypes?isActive=' + isActive + '&macType=' + macType + '&currentPage=' + currentPage + '&pageSize=' + pageSize).then((response) => {
            let data = response.data.data.result.map((item) => {
                let itemRow = {
                    'id': item.id,
                    'subType': item.subType,
                    'desc1': item.desc1,
                    macTypeId: item.macTypeId,
                }
                return itemRow;
            });
            dispatch(macSubTypeSuccess({
                macSubTypeList: data,
                meta: response.data.data.meta
            }));
        })
            .catch((error) => {
                console.log(error);
            });

    }
}

export const getAllMacSubTypes = (filtered = true, isActive = 'all', macType = 'all', currentPage = 1, pageSize = 100000) => {
    localStorage['filtered'] = filtered
    return function (dispatch) {
        dispatch(allMacSubTypeRequest());
        tokenGet(apiSettings.apiURLReferences + '/macsubtypes?isActive=' + isActive + '&macType=' + macType + '&currentPage=' + currentPage + '&pageSize=' + pageSize).then((response) => {
            let data
            if (localStorage['filtered'] == true) {
                data = response.data.data.result.map((item) => {
                    let itemRow = {
                        'id': item.id,
                        'subType': item.subType,
                        'desc1': item.desc1
                    }
                    return itemRow;
                })
            }
            else data = response.data.data.result
            delete localStorage['filtered']
            dispatch(allMacSubTypeSuccess({
                macSubTypeList: data,
                meta: response.data.data.meta
            }));
        })
            .catch((error) => {
                console.log(error);
            });

    }
}

export const getAllMacSubTypesTreeView = (ev = undefined) => {
    return function (dispatch) {
            // dispatch(allMacSubTypeRequest());
        tokenGet(apiSettings.apiURLReferences + '/mactypes/tv').then((response) => {            
            let {data} = response.data
            data = data.sort((a,b) => (a.details.default === b.details.default)? 0 : a.details.default? -1 : 1);
            // console.log(data)
            dispatch(macSubTypeTreeView({
                macSubTypeListTree: data,
                macSubTypeListTreeTimeStamp: new Date()
            }));
            if(ev) dispatch(ev)
        })
            .catch((error) => {
                console.log(error);
            });

    }
}

export const getAllMacSubTypesTreeViewSubtype = () => {
    return function (dispatch) {
            // dispatch(allMacSubTypeRequest());
        tokenGet(apiSettings.apiURLReferences + `/macsubtypes/default?isActive=all`).then((response) => {            
            let {data} = response.data
            dispatch(macSubTypeTreeViewDefault({
                macSubTypeListTreeDefault: data ,
                macSubTypeListTreeDefaultTimeStamp: new Date()
            }));
        })
            .catch((error) => {
                console.log(error);
            });

    }
}

export const getMacSubTypeDetail = (id) => {
    return function (dispatch) {
            dispatch(macSubTypeDetailRequest());
        tokenGet(apiSettings.apiURLReferences + `/macsubtypes/` + id).then((response) => {            
            dispatch(macSubTypeDetailSuccess({
                macSubTypeDetail: response.data.machine[0]
            }))
        })
        .catch((error) => {
            console.log(error);
        });

    }
}

export const postMacSubTypeNew = (payload) =>{
    return function(dispatch){
        dispatch(macSubTypeNewRequest());
        tokenPost(apiSettings.apiURLReferences + '/macsubtypes',payload).then((response) => {
            dispatch(getAllMacSubTypesTreeView());
            dispatch(getAllMacSubTypesTreeViewSubtype());
            dispatch(notificationSuccess('Successfully added machine sub type.'));
            if (localStorage.getItem('redirectConfigSubType') === 'true'){
                history.push('/configuration/machine-config/sub-types');
            }
            else{
                history.push('/configuration/machine-config/machine-type');
            }
        })
        .catch((error) => {
            
            if (error.response.data.error.indexOf('Duplicate') >= 0){
                dispatch(notificationError('Machine subtype code exists'));
                dispatch(macSubTypeTriggerHighlight(true));
            }
            else{
                dispatch(notificationError(error.response.data.error));
            }
            console.log(error);
        });
    }
}



export const putMacSubTypeUpdate = (id,payload) =>{
    return function(dispatch){
        dispatch(macSubTypeUpdateRequest());
        tokenPut(apiSettings.apiURLReferences + '/macsubtypes/' + id,payload).then((response) => {
            dispatch(notificationSuccess('Successfully updated machine sub type.'));
            if (localStorage.getItem('redirectConfigSubType') === 'true'){
                // history.push('/configuration/machine-config/sub-types');
                history.push('/configuration/machine-config/machine-type');
            }
            else{
                history.push('/configuration/machine-config/machine-type');
            }
        })
        .catch((error) => {
            if (error.message.indexOf(500) >= 0){
                dispatch(notificationError('Machine subtype code exists'));
                dispatch(macSubTypeTriggerHighlight(true));
            }
            console.log(error);
        });
    }
}

export const deleteMacSubType = (id) =>{
    return function(dispatch){
        dispatch(macSubTypeDeleteRequest());
        tokenDelete(apiSettings.apiURLReferences + '/macsubtypes/' + id).then((response) => {
            dispatch(getAllMacSubTypesTreeView());
            dispatch(getAllMacSubTypesTreeViewSubtype());
            dispatch(notificationSuccess('Successfully deleted machine sub type.'));
        })
        .catch((error) => {
            if (error.message.indexOf(500) >= 0){
                dispatch(notificationError('Cannot delete because it is currently referenced to another record'));
            }
            console.log(error);
        });
    }
}