import React, { useState, useEffect } from "react";
import axios from "axios";
import "flag-icon-css/css/flag-icon.min.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
// import { Router } from 'react-router-dom';
import { connect } from "react-redux";
import { postLogin } from "../../../redux/actions/Auth/authActions";
// import history from '../../../history'
import Logo from "../../../assets/img/ltlabs-logo.png";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import UserBlueHeader from "../../../components/UserBlueHeader/UserBlueHeader";
import translations from "../../../redux/actions/Translation/translationActions";
import { dictionary } from "../../../redux/actions/Translation/dictionary";
import "./Login.scss";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const NetworkStatus = () => {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        var handle = setInterval(getNetwork, 5000);
        // userRoleAccess();

        return () => {
            clearInterval(handle);
        };
    }, []);
    const getNetwork = async () => {
        await axios
            .get(window.location.origin)
            .then((res) => {
                const network = res.data;
                // console.log(network)
                setOpen(false);
            })
            .catch(function (error) {
                console.log("No Network", error);
                setOpen(true);
            });
    };
    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                autoHideDuration={5000}
            >
                <Alert variant="filled" severity="error">
                    {"No internet connection".translate()}
                </Alert>
            </Snackbar>
        </div>
    );
};

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                username: null,
                password: null,
            },
            removeInput: false,
            translationsCode: [...props.translation.availableCode], //en, km ,etc
            translations: {
                ...this.convert(),
            },
            translationTimestamp: undefined,
            selectedLanguage: localStorage["selectedLanguage"] || "en",
            languageProps: {
                WELCOMETO: "Welcome to".translate(),
                LTMECHANIC: "LTMECHANIC".translate(),
                USERNAME: "Username".translate(),
                PASSWORD: "Password".translate(),
                LOGIN: "LOGIN".translate(),
                LANGUAGE: "Language".translate(),
            },
        };
    }
    /**handler */
    convert = () => {
        let {
            NOTIFICATIONS: { your_account_is_in_use_on_another_device },
            OKAY,
        } = dictionary.GET();
        return dictionary.TRANSLATE({
            your_account_is_in_use_on_another_device,
            OKAY,
        });
    };
    handleFormChange = ({ target }) => {
        let tmpForm = this.state.form;
        tmpForm[target.name] = target.value;
        this.setState({ form: tmpForm });
    };

    checkIfRemoveInput() {
        if (this.state.removeInput === true && this.props.loginLoading === false) {
            this.setState({
                form: {
                    username: "",
                    password: "",
                },
                removeInput: false,
            });
        }
    }

    handleLogin = () => {
        this.props.postLogin(this.state.form);
        this.setState({ removeInput: true });
    };

    handleEnter = (e) => {
        if (e.key === "Enter") {
            this.handleLogin();
        }
    };
    handleChangeLanguage = ({ target: { value } }) => {
        let factory =
            this.state.translationsCode[
            this.state.translationsCode.findIndex(
                (x) => x["code"].toLowerCase() == value
            )
            ]["factory"];
        this.setState({
            selectedLanguage: value,
        });
        this.props.getTranslations(value, factory);
    };

    componentDidMount() {
        let { translations } = this.state;
        this.props.getAvailableTranslation();
        //  this.props.getTranslations(localStorage['selectedLanguage'] || 'en') //test for now
        console.log(this.state);
        if (
            localStorage["selectedFactory"] != undefined &&
            localStorage["selectedLanguage"] != undefined
        ) {
            this.props.getTranslations(
                localStorage["selectedLanguage"],
                localStorage["selectedFactory"]
            );
        }

        if (localStorage.getItem("forcedLogoutSession") === "true") {
            localStorage.removeItem("forcedLogoutSession");
            confirmAlert({
                title: translations.your_account_is_in_use_on_another_device,
                message: "",
                buttons: [
                    {
                        label: translations.OKAY,
                        onClick: () => { },
                    },
                ],
            });
        }
        if (localStorage.getItem("forcedLogout") === "true") {
            localStorage.removeItem("forcedLogout");
        }
    }

    componentDidUpdate() {
        if (
            this.props.translation &&
            this.props.translation.availableCode.length !=
            this.state.translationsCode.length
        ) {
            this.setState({
                translationsCode: this.props.translation.availableCode,
            });
        }
        if (
            this.props.translation &&
            this.props.translation.translations.timeStamp !=
            this.state.translationTimestamp
        ) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                translations: Object.assign({}, { ...this.convert() }),
                languageProps: {
                    WELCOMETO: "Welcome to".translate(),
                    LTMECHANIC: "LTMECHANIC" /**.translate() remove */,
                    USERNAME: "Username".translate(),
                    PASSWORD: "Password".translate(),
                    LOGIN: "LOGIN".translate(),
                    LANGUAGE: "Language".translate(),
                },
            });
        }
    }

    renderLanguageChoices() {
        if (this.state.translationsCode) {
            console.log("this.state.translationsCode", this.state.translationsCode);
            let languages = this.state.translationsCode.map((row) => {
                let code = row.code.toLowerCase();
                return (
                    <MenuItem key={code} value={code}>
                        <Grid container spacing={0}>
                            <Grid xs={2}>
                                <span
                                    className={
                                        "flag-dropdown flag-icon " +
                                        this.renderFlag(code, row.country)
                                    }
                                ></span>
                            </Grid>
                            <Grid xs={10}>
                                <span className="language-name" style={{ marginLeft: "10px" }}>
                                    {row.language}
                                </span>
                            </Grid>
                        </Grid>
                    </MenuItem>
                );
            });
            return languages;
        } else {
            return null;
        }
    }

    renderFlag(code, country) {
        if (code === "en") {
            return "flag-icon-us";
        } else {
            // let flagCode = country.toLowerCase();
            let flagCode = country !== null && country.toLowerCase();
            return "flag-icon-" + flagCode;
        }
    }

    render() {
        return (
            <div className="login">
                {this.checkIfRemoveInput()}
                <UserBlueHeader showUserInfo={false} />
                <div className="login-container">
                    <div className="login-header">
                        <div className="label">{this.state.languageProps.WELCOMETO}</div>
                        <div className="label">
                            <strong>{this.state.languageProps.LTMECHANIC}</strong>
                        </div>
                    </div>
                    <div className="login-form">
                        <FormControl component="form" fullWidth>
                            <FormGroup className="form-group">
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <div className="form-label">
                                            {this.state.languageProps.USERNAME}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            value={this.state.form.username}
                                            onKeyDown={this.handleEnter}
                                            onChange={this.handleFormChange}
                                            className="form-input"
                                            fullWidth
                                            name="username"
                                        />
                                    </Grid>
                                </Grid>
                            </FormGroup>
                            <FormGroup className="form-group">
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <div className="form-label">
                                            {this.state.languageProps.PASSWORD}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            value={this.state.form.password}
                                            onKeyDown={this.handleEnter}
                                            onChange={this.handleFormChange}
                                            className="form-input"
                                            type="password"
                                            fullWidth
                                            name="password"
                                        />
                                    </Grid>
                                </Grid>
                            </FormGroup>
                            <Button
                                disabled={this.props.loginLoading}
                                onClick={this.handleLogin}
                                variant="contained"
                                color="primary"
                            >
                                {this.state.languageProps.LOGIN}
                            </Button>
                            <div className="ltlabs-logo">
                                <img alt="LTLABS" src={Logo} />
                            </div>
                            <div className="language-login">
                                <Grid container spacing={0}>
                                    <Grid item xs={5}>
                                        <div className="language-label">
                                            {this.state.languageProps.LANGUAGE}
                                        </div>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <FormGroup fullWidth>
                                            <Select
                                                value={this.state.selectedLanguage}
                                                onChange={this.handleChangeLanguage}
                                            >
                                                {this.renderLanguageChoices()}
                                            </Select>
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </div>
                            <NetworkStatus />
                        </FormControl>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let { translationReducer } = state;
    let apiLoading = {
        loginLoading: state.authReducer.loading,
    };
    let data = {};

    return {
        ...apiLoading,
        ...data,
        ...translationReducer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        postLogin: (credentials) => dispatch(postLogin(credentials)),
        ...translations(),
    };
};

Login = connect(mapStateToProps, mapDispatchToProps)(Login);

export default Login;
