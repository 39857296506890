import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: 'inline-block',
    minWidth: 120,
  },
  selectEmpty: {
    paddingRight: '24px',
    fontWeight: '600',
    paddingLeft: '5px',
  },
  lineText: {
    paddingRight: '12px',
    position: 'relative',
    top: '1px',
    fontWeight: '600',
  }
}));

const filterTheme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiNativeSelect: {
      // Name of the rule
      select: {
        paddingRight: '24px',
        fontWeight: '600',
        paddingLeft: '5px',
        color: '#0F75BC',
      },
      icon: {
        top: 'calc(50% - 14px)',
        color: '#0F75BC',
        fontSize: 'x-large',
      },

    },
  },
});

export default function NativeSelects(props) {
  const localTrans = {
      TVMODE: 'TV MODE'.translate(),
  }
  const classes = useStyles();
  const areaList = props.lines;
  const areaId = props.areaId;
  const componentHidden = props.hidden;
  const firstTotal = areaList[0] != undefined ? areaList[0].totalLines : 0
  const filterDisabled = props.filterDisabled
  const lineStatusTvMode = props.lineStatusTvMode
  const [state, setState] = React.useState(areaList);
  const [totalLines, setTotalLines] = React.useState(0);
  const [tvModeId, setTvModeId] = React.useState(null);

  const handleChange = (event) => {
    let totalLines = event.target.value;
    let words = totalLines.split(" ");
    setTotalLines(words[1]);
    setTvModeId(words[0]);
    props.lineOverView(words[0]);
  };

  useEffect(() => {
        let id = null;
        if (areaId !== undefined){
          id = areaId != undefined ? areaId : null
        }
        else{
          id = areaList[0] != undefined ? areaList[0].areaId : null;
        }
        props.lineOverView(id);
        setTvModeId(id);
  }, [firstTotal]);

  const renderTvModeLink = () => {
    if (lineStatusTvMode !== true){
    return <Link className={"line-status-tv-mode"} to={"/line-status/tv-mode/" + tvModeId}>{ localTrans.TVMODE }</Link>;
    }
  }

  return (
    <div>
      <FormControl style={{visibility: componentHidden ? 'hidden' : 'visible'}} className={classes.formControl}>
          <span className={classes.lineText}>{totalLines == 0 ? firstTotal : totalLines}  {'LINES'.translate()}</span>
          <ThemeProvider theme={filterTheme}>
        <NativeSelect
          name="age"
          value={state.areaId}
          onChange={handleChange}
          disabled={filterDisabled}
          inputProps={{ 'aria-label': 'age' }}
        >
          {areaList.map((item, itemIndex) => {
            return (
              <option key={itemIndex} value={[item.areaId + ' ' + item.totalLines]}>{item.areaName.translate()}</option>
            )
          })
          }
        </NativeSelect>
        </ThemeProvider>
      </FormControl>
      { renderTvModeLink() }
    </div>
  );
}
