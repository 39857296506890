import React, { useState, Fragment, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import CardPaper from '../components/CardPaper';
import Typography from '../components/Typography';
import StationForm from './LineSetup/StationForm';
import ActionTable from './LineSetup/ActionTable';
import MachinesTable from './LineSetup/MachinesTable';
import { referenceClient } from '../../../../utils/api/CoreApi';
import { getFactoryId } from '../../../../utils/helper';
import { v4 as uuidv4 } from 'uuid';

export default function LineSetup({ machines, updateMachines }) {
  const [newMachineAt, setNewMachineAt] = useState(null);
  const [editingMachineAt, setEditingMachineAt] = useState(null);
  const [machineTypes, setMachineTypes] = useState([]);
  const [machineSubTypes, setMachineSubTypes] = useState([]);
  const isNew = newMachineAt !== null;
  const isEditing = editingMachineAt !== null;

  useEffect(()=>{
    const factoryId = getFactoryId();

    referenceClient.get("/mactypes", { params: { isActive: '1', pageSize: 100000 } }).then(({ data }) => {
      const { machines } = data; // tech debt backend return with prefix key machines;
      const { result } = machines;
      setMachineTypes(result.map(({ id, macType }) => ({ id, name: macType })));
    });

    referenceClient.get("/macsubtypes", { params: { isActive: '1', pageSize: 100000 } }).then(({ data }) => {
      const { result } = data.data;
      setMachineSubTypes(result.map(({ id, subType, macTypeId }) => ({ id, name: subType, machineTypeId: macTypeId })));
    });

  }, []);

  const closeMachineModal = () => {
    setNewMachineAt(null);
    setEditingMachineAt(null);
  }

  const handleCopyMachine = (currentMachineIndex) => {
    /**
     * Append copy new machine under original.
     */
    const currentMachine = machines[currentMachineIndex];
    const currentStationIndex = currentMachine.station;
    const currentStationMachines = machines.filter(({ station }) => station == currentStationIndex);
    const newMachine = {
      ...currentMachine,
      uuid: uuidv4(),
      station: (currentStationIndex + 1),
    }
    newMachine.id && delete newMachine.id;
    const otherMachinesUpper = machines.filter((otherMachineUpper, index) => index < currentMachineIndex && otherMachineUpper.station != currentStationIndex);
    const otherMachinesBelow = machines
      .filter((otherMachineBelow, index) => index >= currentMachineIndex && otherMachineBelow.station != currentStationIndex)
      .map((machine) => ({
        ...machine,
        station: machine.station == currentStationIndex ? currentStationIndex : (machine.station + 1),
      }));
    handleMachinesUpdate([
      ...otherMachinesUpper,
      ...currentStationMachines, 
      newMachine, 
      ...otherMachinesBelow,
    ]);
  };

  const handleAddMachine = (machine)=>{
    const appendIndex = newMachineAt + 1;
    const stationMachine = machines[appendIndex];
    const newMachine = {
      ...machine,
      uuid: uuidv4(),
      station: stationMachine ? stationMachine.station : appendIndex + 1,
    }
    handleMachinesUpdate([
      ...machines.slice(0, appendIndex),
      newMachine,
      ...machines.slice(appendIndex).map((m)=>({ ...m, station: m.station + 1 })),
    ]);
    closeMachineModal();
  }

  const handleMachineDelete = (removeIndex) => {
    
    const machine = machines[removeIndex];
    const stationHaveManyMachines =
      machines.filter(({ station }) => station === machine.station).length > 1;

    let removedMachines = machines.filter(
      (__, stationKey) => stationKey !== removeIndex
    );

    if (!stationHaveManyMachines) {
      removedMachines = removedMachines.map((machine, index) => ({
        ...machine,
        station: getNewStationIndex(machine.station, removeIndex, index)
      }));
    }

    handleMachinesUpdate(removedMachines);
  }

  function getNewStationIndex(stationIndex, removeIndex, index) {
    return index >= removeIndex 
      ? stationIndex - 1 
      : stationIndex;
  }

  const handleUpdateMachine = (machine, index)=>{
    let updatedMachines = [...machines];
    updatedMachines[index] = machine;
    handleMachinesUpdate(updatedMachines);
    closeMachineModal();
  }

  const handleMachinesUpdate = (machines) =>{
    const orderedMachines = machines.sort((a, b)=>a.station - b.station)
    updateMachines(orderedMachines);
  }


  return (
    <Fragment>
      <CardPaper mb={1}>
        <Typography variant="h5" required>{'Line Setup Information'.translate()} <span style={{color: 'red'}}>*</span></Typography>
      </CardPaper>

      <Grid container spacing={1}>
        <Grid item xs={2}>
          <ActionTable
            machines={machines}
            onChange={(ms)=> handleMachinesUpdate(ms)}
          />
        </Grid>

        <Grid item xs={10}>
          <MachinesTable
            machines={machines}
            onCopy={(index) => handleCopyMachine(index)}
            onAdd={(index)=> setNewMachineAt(index)}
            onEdit={(index)=> setEditingMachineAt(index)}
            onDelete={(index)=> handleMachineDelete(index)}
            onChange={(machines)=> handleMachinesUpdate(machines)}
          />
        </Grid>
      </Grid>

      {
        isNew && <StationForm
          machineTypes={machineTypes}
          machineSubTypes={machineSubTypes}
          onClose={()=> closeMachineModal()}
          onSubmit={(newMachine)=> handleAddMachine(newMachine)}
        />
      }

      {
        isEditing && <StationForm
          value={machines[editingMachineAt]}
          machineTypes={machineTypes}
          machineSubTypes={machineSubTypes}
          onClose={()=> closeMachineModal()}
          onSubmit={(newMachine)=> handleUpdateMachine(newMachine, editingMachineAt)}
        />
      }
    </Fragment>
  );
}