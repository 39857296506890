import ExcelJS from 'exceljs';
import moment from 'moment';
import { downloadFile } from '../../../utils/excel';
import { getUsername } from '../../../utils/helper';
import { viewsByFilters } from './components/MachineBreakDown/constant';

const backgroundFill = {
    type: 'pattern',
    pattern:'solid',
    fgColor:{argb:'d9d9d9'},
};

const formatNumber = number => {
    if(!number || number < 0.01) return Number(0.00);

    return Number(parseFloat(number).toFixed(2));
}

const getTitleByType = type => {
    switch(type) {
        case ANALYTIC_REPORT_TYPES.LineNpt:
            return 'Line NPT Analysis';
        case ANALYTIC_REPORT_TYPES.OverallMechanicPerformance:
            return 'Overall Mechanic Performance';
        case ANALYTIC_REPORT_TYPES.IndividualMechanicPerformance:
            return 'Individual Mechanic Performance';
        case ANALYTIC_REPORT_TYPES.MachineBreakdown:
            return 'Machine Breakdown Analysis';
        default: 
            return 'Line Analysis';
    }
}

const getDataFilter = () => {
    let dataFilter = {};
    const dataFilterStr = localStorage.getItem('analyticFilter');
    if(dataFilterStr) {
        dataFilter = JSON.parse(dataFilterStr);
    }
    
    let filter = getSelectedDataFilter({
        key: 'buildings',
        label: 'Buildings',
    }, dataFilter);
    filter += getSelectedDataFilter({
        key: 'floors',
        label: 'Floors',
    }, dataFilter);
    filter += getSelectedDataFilter({
        key: 'areas',
        label: 'Areas',
    }, dataFilter);
    filter += getSelectedDataFilter({
        key: 'lines',
        label: 'Line',
    }, dataFilter);
    filter += getSelectedDataFilter({
        key: 'mechanics',
        label: 'Mechanic',
    }, dataFilter);
    filter += getSelectedDataFilter({
        key: 'shifts',
        label: 'Shifts',
    }, dataFilter);
    filter += getSelectedDataFilter({
        key: 'machineTypes',
        label: 'Machine Type',
    }, dataFilter);
    filter += getSelectedDataFilter({
        key: 'machineSubTypes',
        label: 'Machine Sub-type',
    }, dataFilter);
    filter += getSelectedDataFilter({
        key: 'machineBrands',
        label: 'Machine Brand',
        isLast: true,
    }, dataFilter);
    

    return filter;
}

const getSelectedDataFilter = (config, dataFilter) => {

    let items = dataFilter[config.key];
    if(items) {
        const selectedItems = items.filter(t => t.isSelected === true);
        if(selectedItems.length === 0) {
            return '';
        }
        return (selectedItems.length === items.length ? 
            ('All ' + config.label) : 
            (config.label + ': ' + items.map(t => t.name).join(', '))) + (config.isLast ? '' : '/');
    }

    return '';
}

const LineNpt = {
    setupSheet: (sheet, headerLength, data) => {
        const maxProblemType = LineNpt.renderBody(sheet, headerLength + 1, data);
        LineNpt.renderHeaders(sheet, headerLength + 1, maxProblemType);
    },
    renderHeaders: (sheet, startIndex, maxProblemType) => {
        let headers = ['Line Name', 'Changeover Tickets', 'Machine Prepared', 'Closed Repair Tickets', 'Total NPT (min)'];
        const headerRow = sheet.getRow(startIndex + 1);
        for(let i = 1; i <= maxProblemType; i++) {
            headers = [
                ...headers,
                `No.${i} Problem Type`,
                `No.${i} Ticket Qty`,
                `No.${i} NPT (min)`,
            ];
        }
        sheet.insertRow(startIndex + 1, headers);
        headers.forEach((hd, index) => {
            headerRow.getCell(index + 1).fill = backgroundFill;
            sheet.getColumn(index + 1).width = 20;
        })
    },

    renderBody: (sheet, startIndex, data) => {
        let maxProblemsCount = 0;
        data.forEach((item, index) => {
    
            let columns = [
                item.lineName || 'N/A',
                item.countChangeoverTickets,
                item.machinePrepared,
                item.countTickets,
                formatNumber(item.totalNptTime)
            ];
            item.problemTypes.forEach((pt, index) => {
                columns = [
                    ...columns,
                    pt.problemType,
                    parseInt(pt.count),
                    formatNumber(pt.nptTime),
                ];
            });
            
            // Find max problem counts to render headers
            if(item.problemTypes.length > maxProblemsCount) {
                maxProblemsCount = item.problemTypes.length;
            }
    
            sheet.insertRow(startIndex + 1 + index, columns);
        })
    
        return maxProblemsCount;
    },
};

const OverallMechanicPerformance = {
    setupSheet: (sheet, headerLength, data) => {
        OverallMechanicPerformance.renderHeaders(sheet, headerLength + 1);
        OverallMechanicPerformance.renderBody(sheet, headerLength + 2, data);
    },
    renderHeaders: (sheet, startIndex) => {
        let headers = [
            'Date', 
            'Active Mechanic Qty', 
            'Idle Mechanic Qty', 
            'Closed CO Ticket Qty',
            'Machine Prepared Qty',
            'Completed Maintenance Ticket Qty',
            'Maintenance Total Working Time (min)',
            'Maintenance Average Working Time (min)',
            'Closed Repair Ticket Qty',
            'Repair Ticket Total Response Time (min)',
            'Repair Ticket Average Response Time (min)',
            'Repair Ticket Total Repair Time (min)',
            'Repair Ticket Average Repair Time (min)',
        ];
        sheet.insertRow(startIndex + 1, headers);
        const headerRow = sheet.getRow(startIndex + 1);
        headers.forEach((hd, index) => {
            headerRow.getCell(index + 1).fill = backgroundFill;
            sheet.getColumn(index + 1).width = 35;
        })
    },

    renderBody: (sheet, startIndex, data) => {
        data.forEach((item, index) => {
            const label = item.interval === 'weekly' ? item.dateRange : item.label;
            const {
                countActiveMechanics,
                idleMechanics,
                countCoReqTickets,
                machinePrepared,
                countMaintenanceTickets,
                totalWorkingTime,
                avgWorkingTime,
                countRepairTickets,
                avgRepairTicketResponseTime,
                totalRepairTicketRepairTime,
                totalRepairTicketResponseTime,
                avgRepairTicketRepairTime,
            } = item;

            let columns = [
                label,
                countActiveMechanics,
                idleMechanics,
                countCoReqTickets,
                machinePrepared,
                countMaintenanceTickets,
                formatNumber(totalWorkingTime),
                formatNumber(avgWorkingTime),
                countRepairTickets,
                formatNumber(totalRepairTicketResponseTime),
                formatNumber(avgRepairTicketResponseTime),
                formatNumber(totalRepairTicketRepairTime),
                formatNumber(avgRepairTicketRepairTime),
            ];
            sheet.insertRow(startIndex + 1 + index, columns);
        });
    },
};

const IndividualMechanicPerformance = {
    setupSheet: (sheet, headerLength, data) => {
        IndividualMechanicPerformance.renderHeaders(sheet, headerLength + 1);
        IndividualMechanicPerformance.renderBody(sheet, headerLength + 2, data);
    },
    renderHeaders: (sheet, startIndex) => {
        let headers = [
            'Mechanic Name',
            'Total Ticket Qty',
            'Total Ticket Handling Time (min)',
            'Average Ticket Handling Time (min)',
            'Closed Changeover Ticket Qty',
            'Total Changeover Handling Time (min)',
            'Average Changeover Handling Time (min)',
            'Completed Maintenance Ticket Qty',
            'Total Maintenance Handling Time (min)',
            'Average Maintenance Handling Time (min)',
            'Closed Repair Ticket Qty',
            'Re-opened Times',
            'Closed Repair Ticket Re-open%',
            'Total Repair Response Time (min)',
            'Average Repair Response Time (min)',
            'Total Repair Time (min)',
            'Average Repair Time (min)',
        ];
        sheet.insertRow(startIndex + 1, headers);
        const headerRow = sheet.getRow(startIndex + 1);
        headers.forEach((hd, index) => {
            headerRow.getCell(index + 1).fill = backgroundFill;
            sheet.getColumn(index + 1).width = 35;
        })
    },

    renderBody: (sheet, startIndex, data) => {
        data.forEach(({
            mechanic,
            totalTicketQty,
            totalTicketHandlingTime,
            averageTicketHandlingTime,
            closedChangeoverTickets,
            totalChangeoverHandlingTime,
            averageChangeoverHandlingTime,
            completedMaintenanceTickets,
            totalMaintenanceHandlingTime,
            averageMaintenanceHandlingTime,
            closedRepairTicketQty,
            reopenedTimes,
            closedRepairTicketReopenPercentage,
            totalRepairResponseTime,
            averageRepairResponseTime,
            totalRepairTime,
            averageRepairTime,
        }, index) => {
            let columns = [
                mechanic,
                totalTicketQty,
                formatNumber(totalTicketHandlingTime),
                formatNumber(averageTicketHandlingTime),
                closedChangeoverTickets,
                formatNumber(totalChangeoverHandlingTime),
                formatNumber(averageChangeoverHandlingTime),
                completedMaintenanceTickets,
                formatNumber(totalMaintenanceHandlingTime),
                formatNumber(averageMaintenanceHandlingTime),
                closedRepairTicketQty,
                reopenedTimes,
                closedRepairTicketReopenPercentage,
                formatNumber(totalRepairResponseTime),
                formatNumber(averageRepairResponseTime),
                formatNumber(totalRepairTime),
                formatNumber(averageRepairTime),
            ];
            sheet.insertRow(startIndex + 1 + index, columns);
        });
    },
};

const MachineBreakdown = {
    setupSheet: (sheet, headerLength, data, type) => {
        MachineBreakdown.renderHeaders(sheet, headerLength + 1, [data.name, data.code], type);
        MachineBreakdown.renderBody(sheet, headerLength + 2, data.items, type);
    },
    renderHeaders: (sheet, startIndex, preHeaders, type) => {
        let headers = [
            ...preHeaders,
            'Total Closed Repair Ticket Qty',
            'Total Closed Repair Ticket %',
            'Maintenance Overdue Ticket Qty',
            'Maintenance Overdue %',
            'Average Machine Downtime (min)',
            'Total Machine Downtime (min)',
            'Total Machine Downtime %',
        ];
        sheet.insertRow(startIndex + 1, headers);
        const headerRow = sheet.getRow(startIndex + 1);
        headers.forEach((hd, index) => {
            headerRow.getCell(index + 1).fill = backgroundFill;
            sheet.getColumn(index + 1).width = 35;
        })
    },

    renderBody: (sheet, startIndex, items, type) => {
        let overAllTotalMachineDownTime = 0;
        let overAllTotalTickets = 0;
        let overAllTotalTicketsPercentage = 0;
        let overAllMaintenanceOverdueTicketQty = 0;
        let overMaintenanceOverduePercentage = 0;
        let overAllTotalMachineDownTimeTotal = 0;
        let overAllTotalMachineDownTimePercentage = 0;

        const overallTicketQty = items.reduce((sum, value) => (sum += value.totalTickets), 0);
        items.forEach(({ totalMachine }) => overAllTotalMachineDownTime += totalMachine.reduce((sum, value) => (sum += value.totalTime), 0));

        items.forEach(({
            name,
            code,
            totalTickets,
            totalOverdueTickets,
            totalMachine,
        }, index) => {
            const totalMachineDownTime = totalMachine.reduce((sum, value) => (sum += value.totalTime), 0)

            overAllTotalTickets += totalTickets;
            overAllTotalTicketsPercentage += (totalTickets / overallTicketQty) * 100;
            overAllMaintenanceOverdueTicketQty += totalOverdueTickets;
            overMaintenanceOverduePercentage += (totalOverdueTickets / overallTicketQty) * 100;
            overAllTotalMachineDownTimeTotal += totalMachineDownTime;
            overAllTotalMachineDownTimePercentage += ((totalMachineDownTime / overAllTotalMachineDownTime) * 100);
            
            let columns = [
                name,
                code,
                totalTickets,
                `${formatNumber((totalTickets / overallTicketQty) * 100)}%`,
                totalOverdueTickets,
                `${formatNumber((totalOverdueTickets / overallTicketQty) * 100)}%`,
                formatNumber(totalMachineDownTime / totalTickets),
                formatNumber(totalMachineDownTime),
                `${formatNumber((totalMachineDownTime / overAllTotalMachineDownTime) * 100)}%`,
            ];
            sheet.insertRow(startIndex + 1 + index, columns);
        });
        sheet.insertRow(startIndex + items.length + 1, [
            'All ' + type + 's',
            'N/A',
            overAllTotalTickets,
            `${formatNumber(overAllTotalTicketsPercentage)}%`,
            overAllMaintenanceOverdueTicketQty,
            `${formatNumber(overMaintenanceOverduePercentage)}%`,
            formatNumber(overAllTotalMachineDownTimeTotal / overAllTotalTickets),
            formatNumber(overAllTotalMachineDownTimeTotal),
            `${formatNumber(overAllTotalMachineDownTimePercentage)}%`,
        ]);
    },
};

export const ANALYTIC_REPORT_TYPES = {
    LineNpt: 'Line Npt',
    OverallMechanicPerformance: 'Overall Mechanic Performance',
    IndividualMechanicPerformance: 'Individual Mechanic Performance',
    MachineBreakdown: 'Machine Breakdown Analysis',
}

export const downloadAnalyticReport = async (type, data) => {
    const workbook = new ExcelJS.Workbook();
    const reportName = getTitleByType(type);
    
    if(type === ANALYTIC_REPORT_TYPES.LineNpt) {
        LineNpt.setupSheet(
            getSheetByType(workbook, reportName, reportName), 
            6, 
            data
        );
    } else if(type === ANALYTIC_REPORT_TYPES.OverallMechanicPerformance) {
        OverallMechanicPerformance.setupSheet(
            getSheetByType(workbook, reportName, reportName), 
            6, 
            data
        );
    } else if(type === ANALYTIC_REPORT_TYPES.IndividualMechanicPerformance) {
        IndividualMechanicPerformance.setupSheet(
            getSheetByType(workbook, reportName, reportName), 
            6, 
            data
        );
    } else if(type === ANALYTIC_REPORT_TYPES.MachineBreakdown) {
        data.forEach((items, index) => {
            const type = viewsByFilters[index];
            MachineBreakdown.setupSheet(
                getSheetByType(workbook, reportName + ' - by ' + type , type), 
                5, 
                {
                    code: type + ' Code',
                    name: type + ' Name',
                    items: items,
                },
                type,
            );
        });
    }
    
    // write to a stream
    downloadFile(
        moment().format('DD-MM-YYYY') + ' LTm - ' + reportName,
        workbook,
    );
}

const getSheetByType = (workbook, reportName, sheetName) => {
    const dateFilter = document.getElementById('input-date-picker');
    const dataFilter = getDataFilter();

    const headerTitles = [
        {
            tag: 'Report Name',
            value: reportName,
        },
        {
            tag: 'Data Filter',
            value: dataFilter,
        },
        {
            tag: 'Date Range',
            value: dateFilter.value,
        },
        {
            tag: 'Exported Date',
            value: moment().format('YYYY-MM-DD'),
        },
        {
            tag: 'Exported Time',
            value: moment().format('hh:mm'),
        },
        {
            tag: 'Exported by',
            value: getUsername(),
        },
    ];

    const sheet = workbook.addWorksheet(sheetName);
    headerTitles.forEach((item, index) => {
        sheet.insertRow(index + 1, [item.tag, item.value]);
        sheet.getCell('A' + (index + 1)).fill = backgroundFill;
    });

    return sheet;
}