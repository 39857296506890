export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';
export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_UPDATE_ACCESS = 'AUTH_UPDATE_ACCESS';

export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';

export const MENU_DRAWER_OPEN = 'MENU_DRAWER_OPEN';
export const MENU_DRAWER_CLOSE = 'MENU_DRAWER_CLOSE';

export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS';
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR';
export const NOTIFICATION_CLOSE = 'NOTIFICATION_CLOSE';

export const INPUT_FOCUS = 'INPUT_FOCUS';
export const INPUT_FOCUS_SUCCESS = 'INPUT_FOCUS_SUCCESS';

export const MODAL_SHOW = 'MODAL_SHOW';
export const MODAL_CLOSE = 'MODAL_CLOSE';

export const MACHINE_LIST = 'MACHINE_LIST';
export const MACHINE_LIST_UPDATE_TOTAL = 'MACHINE_LIST_UPDATE_TOTAL';
export const MACHINE_LIST_UPDATE_PAGE = 'MACHINE_LIST_UPDATE_PAGE';
export const MACHINE_LIST_UPDATE_DISPLAYED_ROW = 'MACHINE_LIST_UPDATE_DISPLAYED_ROW';
export const MACHINE_LIST_REQUEST = 'MACHINE_LIST_REQUEST';
export const MACHINE_LIST_SUCCESS = 'MACHINE_LIST_SUCCESS';
export const MACHINE_LIST_ERROR = 'MACHINE_LIST_ERROR';
export const MACHINE_LIST_SHOW_TOGGLE = 'MACHINE_LIST_SHOW_TOGGLE';
export const MACHINE_LIST_CLOSE_TOGGLE = 'MACHINE_LIST_CLOSE_TOGGLE';
export const MACHINE_LIST_CHECKBOX_TOGGLE = 'MACHINE_LIST_CHECKBOX_TOGGLE';
export const MACHINE_LIST_CHECKBOX_ALL = 'MACHINE_LIST_CHECKBOX_ALL';
export const MACHINE_LIST_TICKET_CREATION = 'MACHINE_LIST_TICKET_CREATION';

export const MACHINE_NEW_ITEM_REQUEST = 'MACHINE_NEW_ITEM_REQUEST';
export const MACHINE_NEW_ITEM_SUCCESS = 'MACHINE_NEW_ITEM_SUCCESS';
export const MACHINE_NEW_ITEM_ERROR = 'MACHINE_NEW_ITEM_ERROR';

export const MACHINE_UPDATE_REQUEST = 'MACHINE_UPDATE_REQUEST';
export const MACHINE_UPDATE_SUCCESS = 'MACHINE_UPDATE_SUCCESS';
export const MACHINE_UPDATE_ERROR = 'MACHINE_UPDATE_ERROR';

export const MACHINE_RETIRE_REQUEST = 'MACHINE_RETIRE_REQUEST';
export const MACHINE_RETIRE_SUCCESS = 'MACHINE_RETIRE_SUCCESS';
export const MACHINE_RETIRE_ERROR = 'MACHINE_RETIRE_ERROR';

export const MACHINE_UNRETIRE_REQUEST = 'MACHINE_UNRETIRE_REQUEST';
export const MACHINE_UNRETIRE_SUCCESS = 'MACHINE_UNRETIRE_SUCCESS';
export const MACHINE_UNRETIRE_ERROR = 'MACHINE_UNRETIRE_ERROR';

export const MACHINE_VIEW_DETAIL_REQUEST = 'MACHINE_VIEW_DETAIL_REQUEST';
export const MACHINE_VIEW_DETAIL_SUCCESS = 'MACHINE_VIEW_DETAIL_SUCCESS';
export const MACHINE_VIEW_DETAIL_ERROR = 'MACHINE_VIEW_DETAIL_ERROR';

export const MACHINE_VIEW_TEMPLATES_REQUEST = 'MACHINE_VIEW_TEMPLATES_REQUEST';
export const MACHINE_VIEW_TEMPLATES_SUCCESS = 'MACHINE_VIEW_TEMPLATES_SUCCESS';
export const MACHINE_VIEW_TEMPLATES_ERROR = 'MACHINE_VIEW_TEMPLATES_ERROR';

export const MACHINE_UPDATE_DATA = 'MACHINE_UPDATE_DATA';

export const CONDITION_LIST = 'CONDITION_LIST';
export const CONDITION_LIST_REQUEST = 'CONDITION_LIST_REQUEST';
export const CONDITION_LIST_SUCCESS = 'CONDITION_LIST_SUCCESS';
export const CONDITION_LIST_ERROR = 'CONDITION_LIST_ERROR';

export const STATUS_LIST = 'STATUS_LIST';
export const STATUS_LIST_REQUEST = 'STATUS_LIST_REQUEST';
export const STATUS_LIST_MACHINE_REQUEST = 'STATUS_LIST_MACHINE_REQUEST';
export const STATUS_LIST_TICKET_REQUEST = 'STATUS_LIST_TICKET_REQUEST';
export const STATUS_LIST_CHECKLIST_TEMPLATE_REQUEST = 'STATUS_LIST_CHECKLIST_TEMPLATE_REQUEST';
export const STATUS_LIST_SUCCESS = 'STATUS_LIST_SUCCESS';
export const STATUS_LIST_MACHINE_SUCCESS = 'STATUS_LIST_MACHINE_SUCCESS';
export const STATUS_LIST_TICKET_SUCCESS = 'STATUS_LIST_TICKET_SUCCESS';
export const STATUS_LIST_CHECKLIST_TEMPLATE_SUCCESS = 'STATUS_LIST_CHECKLIST_TEMPLATE_SUCCESS';
export const STATUS_LIST_ERROR = 'STATUS_LIST_ERROR';

export const MACTYPE_LIST = 'MACTYPE_LIST';
export const MACTYPE_LIST_REQUEST = 'MACTYPE_LIST_REQUEST';
export const MACTYPE_LIST_SUCCESS = 'MACTYPE_LIST_SUCCESS';
export const MACTYPE_LIST_ERROR = 'MACTYPE_LIST_ERROR';

export const MACTYPE_DETAIL_REQUEST = 'MACTYPE_DETAIL_REQUEST';
export const MACTYPE_DETAIL_SUCCESS = 'MACTYPE_DETAIL_SUCCESS';
export const MACTYPE_DETAIL_ERROR = 'MACTYPE_DETAIL_ERROR';

export const MACTYPE_NEW_REQUEST = 'MACTYPE_NEW_REQUEST';
export const MACTYPE_NEW_SUCCESS = 'MACTYPE_NEW_SUCCESS';
export const MACTYPE_NEW_ERROR = 'MACTYPE_NEW_ERROR';

export const MACTYPE_UPDATE_REQUEST = 'MACTYPE_UPDATE_REQUEST';
export const MACTYPE_UPDATE_SUCCESS = 'MACTYPE_UPDATE_SUCCESS';
export const MACTYPE_UPDATE_ERROR = 'MACTYPE_UPDATE_ERROR';

export const MACTYPE_DELETE_REQUEST = 'MACTYPE_DELETE_REQUEST';
export const MACTYPE_DELETE_SUCCESS = 'MACTYPE_DELETE_SUCCESS';
export const MACTYPE_DELETE_ERROR = 'MACTYPE_DELETE_ERROR';

export const MACTYPE_TRIGGER_HIGHLIGHT = 'MACTYPE_TRIGGER_HIGHLIGHT';

export const MACSUBTYPE_LIST = 'MACSUBTYPE_LIST';
export const MACSUBTYPE_LIST_REQUEST = 'MACSUBTYPE_LIST_REQUEST';
export const MACSUBTYPE_LIST_SUCCESS = 'MACSUBTYPE_LIST_SUCCESS';
export const MACSUBTYPE_ALL_LIST_REQUEST = 'MACSUBTYPE_ALL_LIST_REQUEST';
export const MACSUBTYPE_ALL_LIST_SUCCESS = 'MACSUBTYPE_ALL_LIST_SUCCESS';
export const MACSUBTYPE_LIST_ERROR = 'MACSUBTYPE_LIST_ERROR';
export const MACSUBTYPE_LIST_TREE_VIEW = "MACSUBTYPE_LIST_TREE_VIEW"
export const MACSUBTYPE_LIST_TREE_VIEW_DEFAULT = "MACSUBTYPE_LIST_TREE_VIEW_DEFAULT"



export const MACSUBTYPE_DETAIL_REQUEST = 'MACSUBTYPE_DETAIL_REQUEST';
export const MACSUBTYPE_DETAIL_SUCCESS = 'MACSUBTYPE_DETAIL_SUCCESS';
export const MACSUBTYPE_DETAIL_ERROR = 'MACSUBTYPE_DETAIL_ERROR';

export const MACSUBTYPE_NEW_REQUEST = 'MACSUBTYPE_NEW_REQUEST';
export const MACSUBTYPE_NEW_SUCCESS = 'MACSUBTYPE_NEW_SUCCESS';
export const MACSUBTYPE_NEW_ERROR = 'MACSUBTYPE_NEW_ERROR';

export const MACSUBTYPE_UPDATE_REQUEST = 'MACSUBTYPE_UPDATE_REQUEST';
export const MACSUBTYPE_UPDATE_SUCCESS = 'MACSUBTYPE_UPDATE_SUCCESS';
export const MACSUBTYPE_UPDATE_ERROR = 'MACSUBTYPE_UPDATE_ERROR';

export const MACSUBTYPE_DELETE_REQUEST = 'MACSUBTYPE_DELETE_REQUEST';
export const MACSUBTYPE_DELETE_SUCCESS = 'MACSUBTYPE_DELETE_SUCCESS';
export const MACSUBTYPE_DELETE_ERROR = 'MACSUBTYPE_DELETE_ERROR';

export const MACSUBTYPE_TRIGGER_HIGHLIGHT = 'MACSUBTYPE_TRIGGER_HIGHLIGHT';

export const BRAND_LIST = 'BRAND_LIST';
export const BRAND_LIST_REQUEST = 'BRAND_LIST_REQUEST';
export const BRAND_LIST_SUCCESS = 'BRAND_LIST_SUCCESS';
export const BRAND_LIST_ERROR = 'BRAND_LIST_ERROR';

export const BRAND_DETAIL_REQUEST = 'BRAND_DETAIL_REQUEST';
export const BRAND_DETAIL_SUCCESS = 'BRAND_DETAIL_SUCCESS';
export const BRAND_DETAIL_ERROR = 'BRAND_NEW_ERROR';

export const BRAND_NEW_REQUEST = 'BRAND_NEW_REQUEST';
export const BRAND_NEW_SUCCESS = 'BRAND_NEW_SUCCESS';
export const BRAND_NEW_ERROR = 'BRAND_NEW_ERROR';

export const BRAND_UPDATE_REQUEST = 'BRAND_UPDATE_REQUEST';
export const BRAND_UPDATE_SUCCESS = 'BRAND_UPDATE_SUCCESS';
export const BRAND_UPDATE_ERROR = 'BRAND_UPDATE_ERROR';

export const BRAND_TRIGGER_HIGHLIGHT = 'BRAND_TRIGGER_HIGHLIGHT';

export const BRAND_DELETE_REQUEST = 'BRAND_DELETE_REQUEST';
export const BRAND_DELETE_SUCCESS = 'BRAND_DELETE_SUCCESS';
export const BRAND_DELETE_ERROR = 'BRAND_DELETE_ERROR';

export const CHANGEOVER_TYPE_NEW_REQUEST = 'CHANGEOVER_TYPE_NEW_REQUEST';
export const CHANGEOVER_TYPE_NEW_SUCCESS = 'CHANGEOVER_TYPE_NEW_SUCCESS';
export const CHANGEOVER_TYPE_NEW_ERROR = 'CHANGEOVER_TYPE_NEW_ERROR';

export const CHANGEOVER_TYPE_UPDATE_REQUEST = 'CHANGEOVER_TYPE_UPDATE_REQUEST';
export const CHANGEOVER_TYPE_UPDATE_SUCCESS = 'CHANGEOVER_TYPE_UPDATE_SUCCESS';
export const CHANGEOVER_TYPE_SUCCESS = 'CHANGEOVER_TYPE_UPDATE_SUCCESS';
export const CHANGEOVER_TYPE_UPDATE_ERROR = 'CHANGEOVER_TYPE_UPDATE_ERROR';

export const CHANGEOVER_TYPE_LIST= 'CHANGEOVER_TYPE_LIST'; 
export const CHANGEOVER_TYPE_LIST_SUCCESS = 'CHANGEOVER_TYPE_LIST_SUCCESS';
export const CHANGEOVER_TYPE_LIST_REQUEST= 'CHANGEOVER_TYPE_LIST_REQUEST'; 
export const CHANGEOVER_TYPE_LIST_ERROR = 'CHANGEOVER_TYPE_LIST_ERROR';

export const CHANGEOVER_TYPE_TRIGGER_REQUEST = 'CHANGEOVER_TYPE_TRIGGER_HIGHLIGHT';

export const CHANGEOVER_TYPE_DELETE_REQUEST = 'CHANGEOVER_TYPE_DELETE_REQUEST';
export const CHANGEOVER_TYPE_DELETE_SUCCESS = 'CHANGEOVER_TYPE_DELETE_SUCCESS';
export const CHANGEOVER_TYPE_DELETE_ERROR = 'CHANGEOVER_TYPE_DELETE_ERROR';

export const COMPANY_LIST = 'COMPANY_LIST';
export const COMPANY_LIST_REQUEST = 'COMPANY_LIST_REQUEST';
export const COMPANY_LIST_SUCCESS = 'COMPANY_LIST_SUCCESS';
export const COMPANY_LIST_ERROR = 'COMPANY_LIST_ERROR';

export const COMPANY_DETAIL_REQUEST = 'COMPANY_DETAIL_REQUEST';
export const COMPANY_DETAIL_SUCCESS = 'COMPANY_DETAIL_SUCCESS';
export const COMPANY_DETAIL_ERROR = 'COMPANY_NEW_ERROR';

export const COMPANY_NEW_REQUEST = 'COMPANY_NEW_REQUEST';
export const COMPANY_NEW_SUCCESS = 'COMPANY_NEW_SUCCESS';
export const COMPANY_NEW_ERROR = 'COMPANY_NEW_ERROR';

export const COMPANY_UPDATE_REQUEST = 'COMPANY_UPDATE_REQUEST';
export const COMPANY_UPDATE_SUCCESS = 'COMPANY_UPDATE_SUCCESS';
export const COMPANY_UPDATE_ERROR = 'COMPANY_UPDATE_ERROR';

export const COMPANY_TRIGGER_HIGHLIGHT = 'COMPANY_TRIGGER_HIGHLIGHT';

export const COMPANY_DELETE_REQUEST = 'COMPANY_DELETE_REQUEST';
export const COMPANY_DELETE_SUCCESS = 'COMPANY_DELETE_SUCCESS';
export const COMPANY_DELETE_ERROR = 'COMPANY_DELETE_ERROR';

export const GROUP_LIST = 'GROUP_LIST';
export const GROUP_LIST_REQUEST = 'GROUP_LIST_REQUEST';
export const GROUP_LIST_SUCCESS = 'GROUP_LIST_SUCCESS';
export const GROUP_LIST_ERROR = 'GROUP_LIST_ERROR';

export const GROUP_DETAIL_REQUEST = 'GROUP_DETAIL_REQUEST';
export const GROUP_DETAIL_SUCCESS = 'GROUP_DETAIL_SUCCESS';
export const GROUP_DETAIL_ERROR = 'GROUP_NEW_ERROR';

export const GROUP_NEW_REQUEST = 'GROUP_NEW_REQUEST';
export const GROUP_NEW_SUCCESS = 'GROUP_NEW_SUCCESS';
export const GROUP_NEW_ERROR = 'GROUP_NEW_ERROR';

export const GROUP_UPDATE_REQUEST = 'GROUP_UPDATE_REQUEST';
export const GROUP_UPDATE_SUCCESS = 'GROUP_UPDATE_SUCCESS';
export const GROUP_UPDATE_ERROR = 'GROUP_UPDATE_ERROR';

export const GROUP_TRIGGER_HIGHLIGHT = 'GROUP_TRIGGER_HIGHLIGHT';

export const GROUP_DELETE_REQUEST = 'GROUP_DELETE_REQUEST';
export const GROUP_DELETE_SUCCESS = 'GROUP_DELETE_SUCCESS';
export const GROUP_DELETE_ERROR = 'GROUP_DELETE_ERROR';

export const MOTOR_TYPE_LIST = 'MOTOR_TYPE_LIST';
export const MOTOR_TYPE_LIST_REQUEST = 'MOTOR_TYPE_LIST_REQUEST';
export const MOTOR_TYPE_LIST_SUCCESS = 'MOTOR_TYPE_LIST_SUCCESS';
export const MOTOR_TYPE_LIST_ERROR = 'MOTOR_TYPE_LIST_ERROR';

export const MOTOR_TYPE_DETAIL_REQUEST = 'MOTOR_TYPE_DETAIL_REQUEST';
export const MOTOR_TYPE_DETAIL_SUCCESS = 'MOTOR_TYPE_DETAIL_SUCCESS';
export const MOTOR_TYPE_DETAIL_ERROR = 'MOTOR_TYPE_DETAIL_ERROR';

export const MOTOR_TYPE_NEW_REQUEST = 'MOTOR_TYPE_NEW_REQUEST';
export const MOTOR_TYPE_NEW_SUCCESS = 'MOTOR_TYPE_NEW_SUCCESS';
export const MOTOR_TYPE_NEW_ERROR = 'MOTOR_TYPE_NEW_ERROR';

export const MOTOR_TYPE_UPDATE_REQUEST = 'MOTOR_TYPE_UPDATE_REQUEST';
export const MOTOR_TYPE_UPDATE_SUCCESS = 'MOTOR_TYPE_UPDATE_SUCCESS';
export const MOTOR_TYPE_UPDATE_ERROR = 'MOTOR_TYPE_UPDATE_ERROR';

export const MOTOR_TYPE_DELETE_REQUEST = 'MOTOR_TYPE_DELETE_REQUEST';
export const MOTOR_TYPE_DELETE_SUCCESS = 'MOTOR_TYPE_DELETE_SUCCESS';
export const MOTOR_TYPE_DELETE_ERROR = 'MOTOR_TYPE_DELETE_ERROR';

export const MOTOR_TYPE_TRIGGER_HIGHLIGHT = 'MOTOR_TYPE_TRIGGER_HIGHLIGHT';

export const SUPPLIER_LIST = 'SUPPLIER_LIST';
export const SUPPLIER_LIST_REQUEST = 'SUPPLIER_LIST_REQUEST';
export const SUPPLIER_LIST_SUCCESS = 'SUPPLIER_LIST_SUCCESS';
export const SUPPLIER_LIST_ERROR = 'SUPPLIER_LIST_ERROR';

export const SUPPLIER_NEW_REQUEST = 'SUPPLIER_NEW_REQUEST';
export const SUPPLIER_NEW_SUCCESS = 'SUPPLIER_NEW_SUCCESS';
export const SUPPLIER_TRIGGER_HIGHLIGHT = 'SUPPLIER_TRIGGER_HIGHLIGHT';
export const SUPPLIER_DETAIL_SUCCESS = 'SUPPLIER_DETAIL_SUCCESS';
export const SUPPLIER_DETAIL_REQUEST = 'SUPPLIER_DETAIL_REQUEST';

export const SUPPLIER_UPDATE_SUCCESS = 'SUPPLIER_UPDATE_SUCCESS'
export const SUPPLIER_DELETE_REQUEST = 'SUPPLIER_DELETE_REQUEST'

export const FREQUENCY_LIST = 'FREQUENCY_LIST';
export const FREQUENCY_LIST_REQUEST = 'FREQUENCY_LIST_REQUEST';
export const FREQUENCY_LIST_SUCCESS = 'FREQUENCY_LIST_SUCCESS';
export const FREQUENCY_LIST_ERROR = 'FREQUENCY_LIST_ERROR';

export const AREA_LIST = 'AREA_LIST';
export const AREA_LIST_REQUEST = 'AREA_LIST_REQUEST';
export const AREA_LIST_SUCCESS = 'AREA_LIST_SUCCESS';
export const AREA_LIST_ERROR = 'AREA_LIST_ERROR';

export const AREA_DETAIL_REQUEST = 'AREA_DETAIL_REQUEST';
export const AREA_DETAIL_SUCCESS = 'AREA_DETAIL_SUCCESS';
export const AREA_DETAIL_ERROR = 'AREA_DETAIL_ERROR';

export const AREA_NEW_REQUEST = 'AREA_NEW_REQUEST';
export const AREA_NEW_SUCCESS = 'AREA_NEW_SUCCESS';
export const AREA_NEW_ERROR = 'AREA_NEW_ERROR';

export const AREA_UPDATE_REQUEST = 'AREA_UPDATE_REQUEST';
export const AREA_UPDATE_SUCCESS = 'AREA_UPDATE_SUCCESS';
export const AREA_UPDATE_ERROR = 'AREA_UPDATE_ERROR';

export const AREA_DELETE_REQUEST = 'AREA_DELETE_REQUEST';
export const AREA_DELETE_SUCCESS = 'AREA_DELETE_SUCCESS';
export const AREA_DELETE_ERROR = 'AREA_DELETE_ERROR';

export const AREA_TRIGGER_HIGHLIGHT = 'AREA_TRIGGER_HIGHLIGHT';

export const ATTACHMENT_LIST = 'ATTACHMENT_LIST';
export const ATTACHMENT_LIST_REQUEST = 'ATTACHMENT_LIST_REQUEST';
export const ATTACHMENT_LIST_SUCCESS = 'ATTACHMENT_LIST_SUCCESS';
export const ATTACHMENT_LIST_ERROR = 'ATTACHMENT_LIST_ERROR';

export const ATTACHMENT_DETAIL_REQUEST = 'ATTACHMENT_DETAIL_REQUEST';
export const ATTACHMENT_DETAIL_SUCCESS = 'ATTACHMENT_DETAIL_SUCCESS';
export const ATTACHMENT_DETAIL_ERROR = 'ATTACHMENT_DETAIL_ERROR';

export const ATTACHMENT_NEW_REQUEST = 'ATTACHMENT_NEW_REQUEST';
export const ATTACHMENT_NEW_SUCCESS = 'ATTACHMENT_NEW_SUCCESS';
export const ATTACHMENT_NEW_ERROR = 'ATTACHMENT_NEW_ERROR';

export const ATTACHMENT_UPDATE_REQUEST = 'ATTACHMENT_UPDATE_REQUEST';
export const ATTACHMENT_UPDATE_SUCCESS = 'ATTACHMENT_UPDATE_SUCCESS';
export const ATTACHMENT_UPDATE_ERROR = 'ATTACHMENT_UPDATE_ERROR';

export const ATTACHMENT_DELETE_REQUEST = 'ATTACHMENT_DELETE_REQUEST';
export const ATTACHMENT_DELETE_SUCCESS = 'ATTACHMENT_DELETE_SUCCESS';
export const ATTACHMENT_DELETE_ERROR = 'ATTACHMENT_DELETE_ERROR';

export const ATTACHMENT_TRIGGER_HIGHLIGHT = 'ATTACHMENT_TRIGGER_HIGHLIGHT';

export const PROBLEM_LIST = 'PROBLEM_LIST';
export const PROBLEM_LIST_REQUEST = 'PROBLEM_LIST_REQUEST';
export const PROBLEM_LIST_SUCCESS = 'PROBLEM_LIST_SUCCESS';
export const PROBLEM_LIST_ERROR = 'PROBLEM_LIST_ERROR';
export const PROBLEM_TREE_VIEW = 'PROBLEM_TREE_VIEW';
export const PROBLEM_TREE_VIEW_BY_ID = 'PROBLEM_TREE_VIEW_BY_ID';

export const PROBLEM_DETAIL_REQUEST = 'PROBLEM_DETAIL_REQUEST';
export const PROBLEM_DETAIL_SUCCESS = 'PROBLEM_DETAIL_SUCCESS';
export const PROBLEM_DETAIL_ERROR = 'PROBLEM_DETAIL_ERROR';

export const PROBLEM_NEW_REQUEST = 'PROBLEM_NEW_REQUEST';
export const PROBLEM_NEW_SUCCESS = 'PROBLEM_NEW_SUCCESS';
export const PROBLEM_NEW_ERROR = 'PROBLEM_NEW_ERROR';

export const PROBLEM_UPDATE_REQUEST = 'PROBLEM_UPDATE_REQUEST';
export const PROBLEM_UPDATE_SUCCESS = 'PROBLEM_UPDATE_SUCCESS';
export const PROBLEM_UPDATE_ERROR = 'PROBLEM_UPDATE_ERROR';

export const PROBLEM_DELETE_REQUEST = 'PROBLEM_DELETE_REQUEST';
export const PROBLEM_DELETE_SUCCESS = 'PROBLEM_DELETE_SUCCESS';
export const PROBLEM_DELETE_ERROR = 'PROBLEM_DELETE_ERROR';

export const PROBLEM_TRIGGER_HIGHLIGHT = 'PROBLEM_TRIGGER_HIGHLIGHT';

export const SOLUTION_LIST = 'SOLUTION_LIST';
export const SOLUTION_LIST_REQUEST = 'SOLUTION_LIST_REQUEST';
export const SOLUTION_LIST_SUCCESS = 'SOLUTION_LIST_SUCCESS';
export const SOLUTION_LIST_ERROR = 'SOLUTION_LIST_ERROR';

export const SOLUTION_DETAIL_REQUEST = 'SOLUTION_DETAIL_REQUEST';
export const SOLUTION_DETAIL_SUCCESS = 'SOLUTION_DETAIL_SUCCESS';
export const SOLUTION_DETAIL_ERROR = 'SOLUTION_DETAIL_ERROR';

export const SOLUTION_NEW_REQUEST = 'SOLUTION_NEW_REQUEST';
export const SOLUTION_NEW_SUCCESS = 'SOLUTION_NEW_SUCCESS';
export const SOLUTION_NEW_ERROR = 'SOLUTION_NEW_ERROR';

export const SOLUTION_UPDATE_REQUEST = 'SOLUTION_UPDATE_REQUEST';
export const SOLUTION_UPDATE_SUCCESS = 'SOLUTION_UPDATE_SUCCESS';
export const SOLUTION_UPDATE_ERROR = 'SOLUTION_UPDATE_ERROR';

export const SOLUTION_DELETE_REQUEST = 'SOLUTION_DELETE_REQUEST';
export const SOLUTION_DELETE_SUCCESS = 'SOLUTION_DELETE_SUCCESS';
export const SOLUTION_DELETE_ERROR = 'SOLUTION_DELETE_ERROR';

export const SOLUTION_TRIGGER_HIGHLIGHT = 'SOLUTION_TRIGGER_HIGHLIGHT';

export const BUILDING_LIST = 'BUILDING_LIST';
export const BUILDING_LIST_REQUEST = 'BUILDING_LIST_REQUEST';
export const BUILDING_LIST_SUCCESS = 'BUILDING_LIST_SUCCESS';
export const BUILDING_LIST_ERROR = 'BUILDING_LIST_ERROR';

export const BUILDING_DETAIL_REQUEST = 'BUILDING_DETAIL_REQUEST';
export const BUILDING_DETAIL_SUCCESS = 'BUILDING_DETAIL_SUCCESS';
export const BUILDING_DETAIL_ERROR = 'BUILDING_DETAIL_ERROR';

export const BUILDING_NEW_REQUEST = 'BUILDING_NEW_REQUEST';
export const BUILDING_NEW_SUCCESS = 'BUILDING_NEW_SUCCESS';
export const BUILDING_NEW_ERROR = 'BUILDING_NEW_ERROR';

export const BUILDING_UPDATE_REQUEST = 'BUILDING_UPDATE_REQUEST';
export const BUILDING_UPDATE_SUCCESS = 'BUILDING_UPDATE_SUCCESS';
export const BUILDING_UPDATE_ERROR = 'BUILDING_UPDATE_ERROR';

export const BUILDING_DELETE_REQUEST = 'BUILDING_DELETE_REQUEST';
export const BUILDING_DELETE_SUCCESS = 'BUILDING_DELETE_SUCCESS';
export const BUILDING_DELETE_ERROR = 'BUILDING_DELETE_ERROR';

export const BUILDING_TRIGGER_HIGHLIGHT = 'BUILDING_TRIGGER_HIGHLIGHT';

export const FLOOR_LIST = 'FLOOR_LIST';
export const FLOOR_LIST_REQUEST = 'FLOOR_LIST_REQUEST';
export const FLOOR_LIST_SUCCESS = 'FLOOR_LIST_SUCCESS';
export const FLOOR_LIST_ERROR = 'FLOOR_LIST_ERROR';

export const FLOOR_DETAIL_REQUEST = 'FLOOR_DETAIL_REQUEST';
export const FLOOR_DETAIL_SUCCESS = 'FLOOR_DETAIL_SUCCESS';
export const FLOOR_DETAIL_ERROR = 'FLOOR_DETAIL_ERROR';

export const FLOOR_NEW_REQUEST = 'FLOOR_NEW_REQUEST';
export const FLOOR_NEW_SUCCESS = 'FLOOR_NEW_SUCCESS';
export const FLOOR_NEW_ERROR = 'FLOOR_NEW_ERROR';

export const FLOOR_UPDATE_REQUEST = 'FLOOR_UPDATE_REQUEST';
export const FLOOR_UPDATE_SUCCESS = 'FLOOR_UPDATE_SUCCESS';
export const FLOOR_UPDATE_ERROR = 'FLOOR_UPDATE_ERROR';

export const FLOOR_DELETE_REQUEST = 'FLOOR_DELETE_REQUEST';
export const FLOOR_DELETE_SUCCESS = 'FLOOR_DELETE_SUCCESS';
export const FLOOR_DELETE_ERROR = 'FLOOR_DELETE_ERROR';

export const FLOOR_TRIGGER_HIGHLIGHT = 'FLOOR_TRIGGER_HIGHLIGHT';

export const LINE_LIST = 'LINE_LIST';
export const LINE_LIST_REQUEST = 'LINE_LIST_REQUEST';
export const LINE_LIST_SUCCESS = 'LINE_LIST_SUCCESS';
export const LINE_LIST_ERROR = 'LINE_LIST_ERROR';

export const LINE_DETAIL_REQUEST = 'LINE_DETAIL_REQUEST';
export const LINE_DETAIL_SUCCESS = 'LINE_DETAIL_SUCCESS';
export const LINE_DETAIL_ERROR = 'LINE_DETAIL_ERROR';

export const LINE_NEW_REQUEST = 'LINE_NEW_REQUEST';
export const LINE_NEW_SUCCESS = 'LINE_NEW_SUCCESS';
export const LINE_NEW_ERROR = 'LINE_NEW_ERROR';

export const LINE_UPDATE_REQUEST = 'LINE_UPDATE_REQUEST';
export const LINE_UPDATE_SUCCESS = 'LINE_UPDATE_SUCCESS';
export const LINE_UPDATE_ERROR = 'LINE_UPDATE_ERROR';

export const LINE_DELETE_REQUEST = 'LINE_DELETE_REQUEST';
export const LINE_DELETE_SUCCESS = 'LINE_DELETE_SUCCESS';
export const LINE_DELETE_ERROR = 'LINE_DELETE_ERROR';

export const LINE_TRIGGER_HIGHLIGHT = 'LINE_TRIGGER_HIGHLIGHT';

export const TICKET_LIST = 'TICKET_LIST';
export const TICKET_LIST_UPDATE_TOTAL = 'TICKET_LIST_UPDATE_TOTAL';
export const TICKET_LIST_UPDATE_PAGE = 'TICKET_LIST_UPDATE_PAGE';
export const TICKET_LIST_UPDATE_DISPLAYED_ROW = 'TICKET_LIST_UPDATE_DISPLAYED_ROW';
export const TICKET_LIST_REQUEST = 'TICKET_LIST_REQUEST';
export const TICKET_LIST_SUCCESS = 'TICKET_LIST_SUCCESS';
export const TICKET_LIST_SEARCH_REQUEST = 'TICKET_LIST_SEARCH_REQUEST';
export const TICKET_LIST_SEARCH_SUCCESS = 'TICKET_LIST_SEARCH_SUCCESS';
export const TICKET_LIST_CHECKBOX_TOGGLE = 'TICKET_LIST_CHECKBOX_TOGGLE';
export const TICKET_LIST_CHECKBOX_ALL = 'TICKET_LIST_CHECKBOX_ALL';
export const MAINTENANCE_LIST_UPDATE_DISPLAYED_ROW = 'MAINTENANCE_LIST_UPDATE_DISPLAYED_ROW';
export const MAINTENANCE_LIST_CHECKBOX_TOGGLE = 'MAINTENANCE_LIST_CHECKBOX_TOGGLE';
export const TICKET_LIST_ERROR = 'TICKET_LIST_ERROR';
export const TICKET_LIST_SHOW_TOGGLE = 'TICKET_LIST_SHOW_TOGGLE';
export const TICKET_LIST_CLOSE_TOGGLE = 'TICKET_LIST_CLOSE_TOGGLE';

export const MAINTENANCE_LIST_SHOW_TOGGLE = 'MAINTENANCE_LIST_SHOW_TOGGLE';
export const MAINTENANCE_LIST_CLOSE_TOGGLE = 'MAINTENANCE_LIST_CLOSE_TOGGLE';

export const TICKET_DETAIL_REQUEST = 'TICKET_DETAIL_REQUEST';
export const TICKET_DETAIL_SUCCESS = 'TICKET_DETAIL_SUCCESS';
export const ASSET_DETAIL_REQUEST = 'ASSET_DETAIL_REQUEST';
export const ASSET_DETAIL_SUCCESS = 'ASSET_DETAIL_SUCCESS';
export const TICKET_DETAIL_ERROR = 'TICKET_DETAIL_ERROR';

export const TICKET_TYPE_LIST_REQUEST = 'TICKET_TYPE_LIST_REQUEST';
export const TICKET_TYPE_LIST_SUCCESS = 'TICKET_TYPE_LIST_SUCCESS';
export const TICKET_TYPE_LIST_ERROR = 'TICKET_TYPE_LIST_ERROR';

export const TICKET_NEW_ITEM_REQUEST = 'TICKET_NEW_ITEM_REQUEST';
export const TICKET_NEW_ITEM_SUCCESS = 'TICKET_NEW_ITEM_SUCCESS';
export const TICKET_NEW_ITEM_ERROR = 'TICKET_NEW_ITEM_ERROR';

export const TICKET_MAINTENANCE_ITEM_REQUEST = 'TICKET_MAINTENANCE_ITEM_REQUEST';
export const TICKET_MAINTENANCE_ITEM_SUCCESS = 'TICKET_MAINTENANCE_ITEM_SUCCESS';

export const TICKET_MAINTENANCE_HISTORY_REQUEST = 'TICKET_MAINTENANCE_HISTORY_REQUEST';
export const TICKET_MAINTENANCE_HISTORY_SUCCESS = 'TICKET_MAINTENANCE_HISTORY_SUCCESS';
export const TICKET_MAINTENANCE_HISTORY_ERROR = 'TICKET_MAINTENANCE_HISTORY_ERROR';

export const TICKET_REPAIR_HISTORY_REQUEST = 'TICKET_REPAIR_HISTORY_REQUEST';
export const TICKET_REPAIR_HISTORY_SUCCESS = 'TICKET_REPAIR_HISTORY_SUCCESS';
export const TICKET_REPAIR_HISTORY_ERROR = 'TICKET_REPAIR_HISTORY_ERROR';

export const TICKET_UPDATE_REQUEST = 'TICKET_UPDATE_REQUEST';
export const TICKET_UPDATE_SUCCESS = 'TICKET_UPDATE_SUCCESS';

export const TICKET_CANCEL_REQUEST = 'TICKET_CANCEL_REQUEST';
export const TICKET_CANCEL_SUCCESS = 'TICKET_CANCEL_SUCCESS';

export const TICKET_MACHINE_UP = 'TICKET_MACHINE_UP';
export const TICKET_MACHINE_UP_UPDATE_TOTAL = 'TICKET_MACHINE_UP_UPDATE_TOTAL';
export const TICKET_MACHINE_UP_UPDATE_PAGE = 'TICKET_MACHINE_UP_UPDATE_PAGE';
export const TICKET_MACHINE_UP_UPDATE_DISPLAYED_ROW = 'TICKET_MACHINE_UP_UPDATE_DISPLAYED_ROW';
export const TICKET_MACHINE_UP_REQUEST = 'TICKET_MACHINE_UP_REQUEST';
export const TICKET_MACHINE_UP_SUCCESS = 'TICKET_MACHINE_UP_SUCCESS';

export const TEMPLATES_LIST_REQUEST = 'TEMPLATES_LIST_REQUEST';
export const TEMPLATES_LIST_SUCCESS = 'TEMPLATES_LIST_SUCCESS';
export const MACHINE_LIST_CHECKLIST_REQUEST = 'MACHINE_LIST_CHECKLIST_REQUEST';
export const MACHINE_LIST_CHECKLIST_SUCCESS = 'MACHINE_LIST_CHECKLIST_SUCCESS';
export const MACHINE_NEW_CHECKLIST_REQUEST = 'MACHINE_NEW_CHECKLIST_REQUEST';
export const MACHINE_NEW_CHECKLIST_SUCCESS = 'MACHINE_NEW_CHECKLIST_SUCCESS';
export const CHECKLIST_DETAIL_REQUEST = 'CHECKLIST_DETAIL_REQUEST';
export const CHECKLIST_DETAIL_SUCCESS = 'CHECKLIST_DETAIL_SUCCESS';
export const UPDATE_CHECKLIST_REQUEST = 'UPDATE_CHECKLIST_REQUEST';
export const UPDATE_CHECKLIST_SUCCESS = 'UPDATE_CHECKLIST_SUCCESS';
export const POST_ASSIGN_CHECKLIST_REQUEST = 'POST_ASSIGN_CHECKLIST_REQUEST';
export const POST_ASSIGN_CHECKLIST_SUCCESS = 'POST_ASSIGN_CHECKLIST_SUCCESS';
export const POST_SAVE_CHECKLIST = 'POST_SAVE_CHECKLIST';
export const POST_SAVE_CHECKLIST_SUCCESS = 'POST_SAVE_CHECKLIST_SUCCESS';


/**Dashboard actions reducer */
export const DASHBOARD_ACTIONS = {
    DASHBOARD_LIST: 'DASHBOARD_LIST',
    DASHBOARD_LIST_TOGGLE_LOADING: 'DASHBOARD_LIST_TOGGLE_LOADING',
    DASHBOARD_MAINTENANCE: 'DASHBOARD_MAINTENANCE',
    DASHBOARD_AVG: 'DASHBOARD_AVG',
    DASHBOARD_BUILDING: 'DASHBOARD_BUILDING',
    DASHBOARD_AREA: 'DASHBOARD_AREA'
}
/**Translation actions reducer */
export const TRANSLATION_ACTIONS = {
    FETCH_CODE_TRANS: 'FETCH_CODE_TRANS',
    FETCH_TRANS_BY_CODE: 'FETCH_TRANS_BY_CODE',
}

/**Calendar actions reducer */
export const CALENDAR_ACTIONS = {
    FETCH_BY_MONTH: 'FETCH_BY_MONTH',
    FETCH_BY_DATE: 'FETCH_BY_DATE',
    GENERATE_MACHINE: 'GENERATE_MACHINE',
}

/**
 * CONFIGURATION 
 * Factory 
 * */
export const FACTORY_ACTIONS = {
    FACTORY_ASSIGNED: 'FACTORY_ASSIGNED',
    FACTORY_TREE_VIEW: 'FACTORY_TREE_VIEW',
    FACTORY_TREE_VIEW_BY_ID: 'FACTORY_TREE_VIEW_BY_ID',
    FACTORY_NEW_REQUEST: 'FACTORY_NEW_REQUEST',
    FACTORY_NEW: 'FACTORY_NEW',
    FACTORY_UPDATE_REQUEST: 'FACTORY_UPDATE_REQUEST',
    FACTORY_UPDATE: 'FACTORY_UPDATE',
    FACTORY_DELETE: 'FACTORY_DELETE',
    FACTORY_PROFILE: 'FACTORY_PROFILE',
    FACTORY_ROW_DETAIL: 'FACTORY_ROW_DETAIL',
    FACTORY_TIMEZONE: 'FACTORY_TIMEZONE',
    FACTORY_SHIFT_NEW: 'FACTORY_SHIFT_NEW',
    FACTORY_SHIFT_UPDATE_REQUEST: 'FACTORY_SHIFT_UPDATE_REQUEST',
    FACTORY_SHIFT_UPDATE: 'FACTORY_SHIFT_UPDATE',
    FACTORY_SHIFT_DELETE: 'FACTORY_SHIFT_DELETE',
}

export const USER_LIST = 'USER_LIST';
export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_ERROR = 'USER_LIST_ERROR';
export const USER_LIST_FACTORIES = 'USER_LIST_FACTORIES';

export const USER_DETAIL_REQUEST = 'USER_DETAIL_REQUEST';
export const USER_DETAIL_SUCCESS = 'USER_DETAIL_SUCCESS';
export const USER_DETAIL_ERROR = 'USER_NEW_ERROR';

export const USER_NEW_REQUEST = 'USER_NEW_REQUEST';
export const USER_NEW_SUCCESS = 'USER_NEW_SUCCESS';
export const USER_NEW_ERROR = 'USER_NEW_ERROR';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';

export const USER_TRIGGER_HIGHLIGHT = 'USER_TRIGGER_HIGHLIGHT';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_ERROR = 'USER_DELETE_ERROR';

export const USER_CHANGE_PROFILE = 'USER_CHANGE_PROFILE';

export const ROLE_LIST = 'ROLE_LIST';
export const ROLE_LIST_REQUEST = 'ROLE_LIST_REQUEST';
export const ROLE_LIST_SUCCESS = 'ROLE_LIST_SUCCESS';
export const ROLE_LIST_ERROR = 'ROLE_LIST_ERROR';
export const ROLE_LIST_FACTORIES = 'ROLE_LIST_FACTORIES';

export const ROLE_TREE_VIEW = 'ROLE_TREE_VIEW';
export const ROLE_TREE_VIEW_REQUEST = 'ROLE_TREE_VIEW_REQUEST';
export const ROLE_TREE_VIEW_SUCCESS = 'ROLE_TREE_VIEW_SUCCESS';

export const ROLE_DETAIL_REQUEST = 'ROLE_DETAIL_REQUEST';
export const ROLE_DETAIL_SUCCESS = 'ROLE_DETAIL_SUCCESS';
export const ROLE_DETAIL_ERROR = 'ROLE_NEW_ERROR';

export const ROLE_NEW_REQUEST = 'ROLE_NEW_REQUEST';
export const ROLE_NEW_SUCCESS = 'ROLE_NEW_SUCCESS';
export const ROLE_NEW_ERROR = 'ROLE_NEW_ERROR';

export const ROLE_UPDATE_REQUEST = 'ROLE_UPDATE_REQUEST';
export const ROLE_UPDATE_SUCCESS = 'ROLE_UPDATE_SUCCESS';
export const ROLE_UPDATE_ERROR = 'ROLE_UPDATE_ERROR';

export const ROLE_TRIGGER_HIGHLIGHT = 'ROLE_TRIGGER_HIGHLIGHT';

export const ROLE_DELETE_REQUEST = 'ROLE_DELETE_REQUEST';
export const ROLE_DELETE_SUCCESS = 'ROLE_DELETE_SUCCESS';
export const ROLE_DELETE_ERROR = 'ROLE_DELETE_ERROR';

export const ROLE_CHANGE_PROFILE = 'ROLE_CHANGE_PROFILE';

export const PREFERENCE_DETAIL_REQUEST = 'PREFERENCE_DETAIL_REQUEST';
export const PREFERENCE_DETAIL_SUCCESS = 'PREFERENCE_DETAIL_SUCCESS';
export const PREFERENCE_UPDATE_REQUEST = 'PREFERENCE_UPDATE_REQUEST';
export const PREFERENCE_UPDATE_SUCCESS = 'PREFERENCE_UPDATE_SUCCESS';
export const SET_TRANSLATION = 'SET_TRANSLATION';

export const LINES_REQUEST = 'LINES_REQUEST';
export const LINES_SUCCESS = 'LINES_SUCCESS';
export const LINE_AREA_REQUEST = 'LINE_AREA_REQUEST';
export const LINE_AREA_SUCCESS = 'LINE_AREA_SUCCESS';

export const CHECKLIST_REQUEST = 'CHECKLIST_REQUEST';
export const CHECKLIST_SUCCESS = 'CHECKLIST_SUCCESS';
export const CHECKLIST_ERROR = 'CHECKLIST_ERROR';

export const CHECKLIST_TEMPLATE_REQUEST = 'CHECKLIST_TEMPLATE_REQUEST';
export const CHECKLIST_TEMPLATE_SUCCESS = 'CHECKLIST_TEMPLATE_SUCCESS';
export const CHECKLIST_TEMPLATE_ERROR = 'CHECKLIST_TEMPLATE_ERROR';
export const CHECKLIST_TEMPLATE_CHECKBOX_TOGGLE = 'CHECKLIST_TEMPLATE_CHECKBOX_TOGGLE';
export const CHECKLIST_TEMPLATE_SHOW_TOGGLE = 'CHECKLIST_TEMPLATE_SHOW_TOGGLE';
export const CHECKLIST_TEMPLATE_CLOSE_TOGGLE = 'CHECKLIST_TEMPLATE_CLOSE_TOGGLE';

export const CHECKLIST_TEMPLATE_NEW_REQUEST = 'CHECKLIST_TEMPLATE_NEW_REQUEST';
export const CHECKLIST_TEMPLATE_NEW_SUCCESS = 'CHECKLIST_TEMPLATE_NEW_SUCCESS';
export const CHECKLIST_TEMPLATE_NEW_ERROR = 'CHECKLIST_TEMPLATE_NEW_ERROR';

export const CHECKLIST_TEMPLATE_EDIT_REQUEST = 'CHECKLIST_TEMPLATE_EDIT_REQUEST';
export const CHECKLIST_TEMPLATE_EDIT_SUCCESS = 'CHECKLIST_TEMPLATE_EDIT_SUCCESS';
export const CHECKLIST_TEMPLATE_EDIT_ERROR = 'CHECKLIST_TEMPLATE_EDIT_ERROR';

export const CHECKLIST_TEMPLATE_DETAIL_REQUEST = 'CHECKLIST_TEMPLATE_DETAIL_REQUEST';
export const CHECKLIST_TEMPLATE_DETAIL_SUCCESS = 'CHECKLIST_TEMPLATE_DETAIL_SUCCESS';
export const CHECKLIST_TEMPLATE_DETAIL_ERROR = 'CHECKLIST_TEMPLATE_DETAIL_ERROR';

export const CHECKLIST_TEMPLATE_UPLOAD_REQUEST = 'CHECKLIST_TEMPLATE_UPLOAD_REQUEST';
export const CHECKLIST_TEMPLATE_UPLOAD_SUCCESS = 'CHECKLIST_TEMPLATE_UPLOAD_SUCCESS';
export const CHECKLIST_TEMPLATE_UPLOAD_ERROR = 'CHECKLIST_TEMPLATE_UPLOAD_ERROR';

export const CHECKLIST_SET = 'CHECKLIST_SET';
export const CHECKLIST_DETAIL_SET = 'CHECKLIST_DETAIL_SET';

export const MACHINE_HISTORIES_REQUEST = 'MACHINE_HISTORIES_REQUEST';
export const MACHINE_HISTORIES_SUCCESS = 'MACHINE_HISTORIES_SUCCESS';
export const MACHINE_HISTORIES_ERROR = 'MACHINE_HISTORIES_ERROR';

export const MS_GET_COUNT_TOTAL = 'MS_GET_COUNT_TOTAL';
export const MS_GET_COUNT_TOTAL_SUCCESS = 'MS_GET_COUNT_SUCCESS';
export const MS_GET_LATEST_MACHINE_DISTRIBUTION = 'MS_GET_LATEST_MACHINE_DISTRIBUTION';
export const MS_GET_LATEST_MACHINE_DISTRIBUTION_SUCCESS = 'MS_GET_LATEST_MACHINE_DISTRIBUTION_SUCCESS';
export const MS_GET_LATEST_MACHINE_USAGE = 'MS_GET_LATEST_MACHINE_USAGE';
export const MS_GET_LATEST_MACHINE_USAGE_SUCCESS = 'MS_GET_LATEST_MACHINE_USAGE_SUCCESS';

export const MS_LOADING_TOGGLE = 'MS_LOADING_TOGGLE';
export const MS_DIALOG_TOGGLE = 'MS_DIALOG_TOGGLE';
export const MS_QUERY = 'MS_QUERY';

export const MS_SET_VIEW_BY_COUNT_TOTAL = 'MS_SET_VIEW_BY_COUNT_TOTAL';
export const MS_SET_VIEW_BY_LATEST_MACHINE_DISTRIBUTION = 'MS_SET_VIEW_BY_LATEST_MACHINE_DISTRIBUTION';
export const MS_SET_VIEW_BY_LATEST_MACHINE_USAGE = 'MS_SET_VIEW_BY_LATEST_MACHINE_USAGE';

export const MS_SET_ORDER_BY_LATEST_MACHINE_USAGE = 'MS_SET_ORDER_BY_LATEST_MACHINE_USAGE';
export const MS_SET_ORDER_LATEST_MACHINE_USAGE = 'MS_SET_ORDER_LATEST_MACHINE_USAGE';
export const MS_UPDATE_FETCH_DATE = 'MS_UPDATE_FETCH_DATE';

export const MS_GET_MACHINE_PERFORMANCE = 'MS_UPDATE_FETCH_DATEMS_UPDATE_FETCH_DATE';
export const MS_GET_MACHINE_PERFORMANCE_SUCCESS = 'MS_GET_MACHINE_PERFORMANCE_SUCCESS';
export const MS_SET_ORDER_MACHINE_PERFORMANCE = 'MS_SET_ORDER_MACHINE_PERFORMANCE';

