import React from 'react';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '../../components/Typography';

const Label = withStyles({
  root: {
    color: "#8C8C8C",
  }
})(Typography);

export default function ModificationInfo({ changeover }){
  return (
    <Box mt={10}>
      <Label>{'Modified by'.translate()}: {changeover.modifiedBy}</Label>
      <Label>{'Date Modified'.translate()}: {changeover.modifiedDate && changeover.modifiedDate}</Label>
      <Label>{'Created by'.translate()}: {changeover.createdBy}</Label>
      <Label>{'Date Created'.translate()}: {changeover.createdDate}</Label>
    </Box>
  )
}