export const required = value => value !== null && value !== '' ? undefined : 'Required'
export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
export const maxLength50 = maxLength(50)
export const noSpecialCharacters = value => value && !/^[A-Za-z0-9\-\s]+$/i.test(value.trim()) ? 'Must be letters only' : undefined
export const number = value => value && isNaN(Number(value)) && !/^[0-9]*$/i.test(value) ? 'Must be a number' : undefined
export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
export const minValue0 = minValue(0)
export const maxValue = max => value =>
  value && value > max ? `Max of ${max}` : undefined
export const maxValue80 = maxValue(80)
export const email = value => 
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Invalid email address' : undefined
export const alphanumeric = value => value && /[=><]/.test(value.trim()) ? 'Must be letters only' : undefined


export const validateFields = (form) => {
    let formValid = true;
    let errors = [];

    if (required(form.conditionId) !== undefined){
        formValid = false;
        errors.push({name:'conditionId',message:'Please fill up all required fields'});
    }

    if (required(form.machine) !== undefined){
        formValid = false;
        errors.push({name:'machine',message:'Please fill up all required fields'});
    }

    if (alphanumeric(form.machine) !== undefined){
        formValid = false;
        errors.push({name:'machine',message:'The following special characters are not allowed: = < >'});
    }

    if (maxLength50(form.machine) !== undefined){
        formValid = false;
        errors.push({name:'machine',message:'Cannot save machine. Maximum fields exceeded'});
    }

    if (required(form.model) !== undefined){
        formValid = false;
        errors.push({name:'model',message:'Please fill up all required fields'});
    }

    if (alphanumeric(form.model) !== undefined){
        formValid = false;
        errors.push({name:'model',message:'The following special characters are not allowed: = < >'});
    }

    if (maxLength50(form.model) !== undefined){
        formValid = false;
        errors.push({name:'model',message:'Cannot save machine. Maximum fields exceeded'});
    }

    if (required(form.macTypeId) !== undefined){
        formValid = false;
        errors.push({name:'macTypeId',message:'Please fill up all required fields'});
    }

    // if (required(form.supplierId) !== undefined){
    //     formValid = false;
    //     errors.push({name:'supplierId',message:'Please fill up all required fields'});
    // }

    if (required(form.aquisitionDt) !== undefined){
        formValid = false;
        errors.push({name:'aquisitionDt',message:'Please fill up all required fields'});
    }

    if (required(form.isRental) !== undefined){
        formValid = false;
        errors.push({name:'isRental',message:'Please fill up all required fields'});
    }

    if (required(form.macSubTypeId) !== undefined){
        formValid = false;
        errors.push({name:'macSubTypeId',message:'Please fill up all required fields'});
    }

    if (required(form.areaId) !== undefined){
        formValid = false;
        errors.push({name:'areaId',message:'Please fill up all required fields'});
    }
    
    if (required(form.buildingId) !== undefined){
        formValid = false;
        errors.push({name:'buildingId',message:'Please fill up all required fields'});
    }

    if (required(form.brandId) !== undefined){
        formValid = false;
        errors.push({name:'brandId',message:'Please fill up all required fields'});
    }

    if (required(form.motorTypeId) !== undefined){
        formValid = false;
        errors.push({name:'motorTypeId',message:'Please fill up all required fields'});
    }

    if (required(form.serialNo) !== undefined){
        formValid = false;
        errors.push({name:'serialNo',message:'Please fill up all required fields'});
    }

    if (alphanumeric(form.serialNo) !== undefined){
        formValid = false;
        errors.push({name:'serialNo',message:'The following special characters are not allowed: = < >'});
    }

    if (maxLength50(form.serialNo) !== undefined){
        formValid = false;
        errors.push({name:'serialNo',message:'Cannot save machine. Maximum fields exceeded'});
    }

    // if (required(form.assetNo) !== undefined){
    //     formValid = false;
    //     errors.push({name:'assetNo',message:'Please fill up all required fields'});
    // }

    if (alphanumeric(form.assetNo) !== undefined){
        formValid = false;
        errors.push({name:'assetNo',message:'The following special characters are not allowed: = < >'});
    }
    
    if (maxLength50(form.assetNo) !== undefined){
        formValid = false;
        errors.push({name:'assetNo',message:'Cannot save machine. Maximum fields exceeded'});
    }

    // if (required(form.rfid) !== undefined){
    //     formValid = false;
    //     errors.push({name:'rfid',message:'RFID is required'});
    // }

    if (alphanumeric(form.rfid) !== undefined){
        formValid = false;
        errors.push({name:'rfid',message:'The following special characters are not allowed: = < >'});
    }

    if (maxLength50(form.rfid) !== undefined){
        formValid = false;
        errors.push({name:'rfid',message:'Cannot save machine. Maximum fields exceeded'});
    }

    // if (required(form.attachmentId) !== undefined){
    //     formValid = false;
    //     errors.push({name:'attachmentId',message:'Please fill up all required fields'});
    // }

    if (required(form.statusId) !== undefined){
        formValid = false;
        errors.push({name:'statusId',message:'Please fill up all required fields'});
    }

    if (required(form.frequencyId) !== undefined){
        formValid = false;
        errors.push({name:'frequencyId',message:'Please fill up all required fields'});
    }

    if (required(form.frequencyQty) !== undefined){
        formValid = false;
        errors.push({name:'frequencyQty',message:'Please fill up all required fields'});
    }

    // if (required(form.lastPMDt) !== undefined){
    //     formValid = false;
    //     errors.push({name:'lastPMDt',message:'Last maintenance is required'});
    // }

    if (required(form.pmPlanDt) !== undefined){
        formValid = false;
        errors.push({name:'pmPlanDt',message:'Please fill up all required fields'});
    }

    if (required(form.station) !== undefined){
        formValid = false;
        errors.push({name:'station',message:'Please fill up all required fields'});
    }

    if (minValue0(form.station) !== undefined){
        formValid = false;
        errors.push({name:'station',message:'Minimum Value of station is 0'});
    }

    if (required(form.isActive) !== undefined){
        formValid = false;
        errors.push({name:'isActive',message:'Please fill up all required fields'});
    }

    return {
        valid: formValid,
        errors: errors
    }
}