import {
    AREA_LIST_REQUEST,
    AREA_LIST_SUCCESS,
    AREA_LIST_ERROR
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    areaList:[],
    meta: [],
    error: ''
}

const areaListReducer = (state = initialState, action) => {
    switch (action.type) {
        case AREA_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case AREA_LIST_SUCCESS:
            return {
                loading: false,
                areaList: action.payload.areaList,
                meta: action.payload.meta,
                error: ''
            }
        case AREA_LIST_ERROR:
            return {
                loading: false,
                areaList: [],
                meta: [],
                error: action.payload.message
            }
        default: 
            return state;
    }
}

export default areaListReducer;