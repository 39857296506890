import * as React from 'react';

import { 
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
  Grid,
} from '@material-ui/core';
import Button from '../../../containers/Modules/ChangeOvers/components/Button';

const Modal = withStyles({
  paper: {
    borderRadius: 0,
    height: 220,
    width: 283, 
  },
})(Dialog);

const ConfirmTitle = withStyles({
  root: (props) => ({
    color: props.textColor,
    marginTop: 20
  }),
})(({ textColor, ...props })=> <DialogTitle {...props} />);

const TextContent = withStyles({
  root: {
    height: 60,
    color: '#000000',
  },
})(DialogContentText);

class ConfirmModal extends React.Component {
  state = {
    open: false,
    options: {
      title: '',
      description: '', 
      mode: 'success', 
      positiveButtonText: '', 
      negativeButtonText: '',
      handlePositiveButtonClick: null,
    }
  }

  handleClose = () => {
    this.setState({open: false})
  }

  show(options) {
    this.setState({
      open: true,
      options: {
        ...options,
      },
    })
  }

  render() {
    const { open, options } = this.state;
    const { 
      title, 
      description, 
      mode, 
      positiveButtonText, 
      negativeButtonText,
      handlePositiveButtonClick,
    } = options;

    return (
      <Modal
        open={open}
        onClose={this.handleClose.bind(this)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ConfirmTitle 
          textColor={mode === 'error' ? '#e64747' : 'inherit'} 
          id="alert-dialog-title">
          {title}
        </ConfirmTitle>
        <DialogContent>
          <TextContent id="alert-dialog-description">
            {description}
          </TextContent>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Button 
              variant='outlined'
              style={{marginRight: 20}}
              onClick={this.handleClose.bind(this)}>
              {positiveButtonText || 'Cancel'}
            </Button>

            <Button
              variant="contained" 
              color={mode}
              onClick={() => {
                this.handleClose();
                if(handlePositiveButtonClick) handlePositiveButtonClick();
              }}>
              {negativeButtonText || 'Confirm'}
            </Button>
          </Grid>
        </DialogContent>
      </Modal>
    );
  }
}

export default ConfirmModal;