import React from 'react';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const BetaIcon = withStyles({
    root: {
        background: '#9D00FF',
        borderRadius: 3,
        fontSize: 11,
        color: '#fff',
        padding: '0px 5px',
        marginLeft: 5,
        fontWeight: 'bold',
    }
})((props) => (<Box {...props}>{'BETA'.translate()}</Box>))