import {
    ATTACHMENT_LIST_REQUEST,
    ATTACHMENT_LIST_SUCCESS,
    ATTACHMENT_LIST_ERROR,
    ATTACHMENT_DETAIL_REQUEST,
    ATTACHMENT_DETAIL_SUCCESS,
    ATTACHMENT_DETAIL_ERROR,
    ATTACHMENT_NEW_REQUEST,
    ATTACHMENT_NEW_SUCCESS,
    ATTACHMENT_NEW_ERROR,
    ATTACHMENT_UPDATE_REQUEST,
    ATTACHMENT_UPDATE_SUCCESS,
    ATTACHMENT_UPDATE_ERROR,
    ATTACHMENT_TRIGGER_HIGHLIGHT,
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    detailLoading: false,
    triggerHighlight: false,
    attachmentList:[],
    attachmentDetail: null,
    meta: [],
    error: ''
}

const attachmentListReducer = (state = initialState, action) => {
    switch (action.type) {
        case ATTACHMENT_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ATTACHMENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                attachmentList: action.payload.attachmentList,
                meta: action.payload.meta,
                error: ''
            }
        case ATTACHMENT_LIST_ERROR:
            return {
                ...state,
                loading: false,
                attachmentList: [],
                meta: [],
                error: action.payload.message
            }
        case ATTACHMENT_DETAIL_REQUEST:
            return {
                ...state,
                detailLoading: true,
                attachmentDetail: null
            }
        case ATTACHMENT_DETAIL_SUCCESS:
            return {
                ...state,
                detailLoading: true,
                attachmentDetail: action.payload.attachmentDetail
            }
        case ATTACHMENT_TRIGGER_HIGHLIGHT:
            return {
                ...state,
                triggerHighlight: action.payload
            }
        default: 
            return state;
    }
}

export default attachmentListReducer;