import React from 'react';
import CardPaper from '../components/CardPaper';
import Typography from '../components/Typography';
import {
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
  Box,
} from '@material-ui/core';
import TableCell from './MachineSummary/TableCell';
import TableHeaderCell  from './MachineSummary/TableHeaderCell';

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export default function MachineSummary({ changeover }){
  const { machines } = changeover;
  const uniqSubtypes = machines.map((machine)=> machine.machineSubType).filter(onlyUnique);

  return (
    <CardPaper>
      <Typography variant="h5">
        {'MACHINE SUMMARY'.translate()}
      </Typography>
      <Box my={1}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell>
                  {'Machine'.translate()}
                </TableHeaderCell>
                {
                  uniqSubtypes.map((subType)=> (
                    <TableHeaderCell key={subType}>
                      {subType.translate()}
                    </TableHeaderCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {'Line Setup Qty'.translate()}
                </TableCell>
                {
                  uniqSubtypes.map((subType)=> (
                    <TableCell key={subType}>
                      { machines.filter((m)=> m.machineSubType == subType).length }
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </CardPaper>
  )
}