import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getFactoryLanguage } from '../../../../../redux/actions/Translation/googleTranslationAction';
import Form from './Form';

export default function EditChangeOverType ( { changeoverType, open, onClose } ) {
  const [translations, setTranslations] = useState([]);
  const getTranslations = async () => {
    setTranslations(await getFactoryLanguage());
  }
  
  useEffect(() =>{
    getTranslations();
  },[])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"xs"}
    >
      <DialogTitle id="alert-dialog-title">
        Edit Changeover Type
      </DialogTitle>
      <DialogContent>
        <Form
          isDisabled={true}
          translations={translations}
          onClose={onClose}
          changeoverType={changeoverType}
        />
      </DialogContent>
    </Dialog>
  )
}