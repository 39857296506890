import React, { useState } from 'react';
import validate from 'validate.js';
import {
  Dialog,
  DialogContent,
  Select,
  MenuItem,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import InputLabel from '../../components/InputLabel';
import FormControl from '../../components/FormControl';
import Typography from '../../components/Typography';
import TextField from '../../components/TextField';
import Button from '../../components/Button';
import DialogActions from '../components/DialogActions';
import Attachments from './StationForm/Attachments';
import { stationRules } from '../validations';

export default function StationForm({ value, onClose, onSubmit, machineTypes, machineSubTypes }) {
  const [form, setForm] = useState(value || { machines: [] });
  const [errors, setErrors] = useState({});

  const handleSubmit = () => {
    const validationErrors = validate(form, stationRules, { fullMessages: false });
    if (validationErrors) {
      setErrors(validationErrors)
      return;
    }
    const subType = machineSubTypes.find((mc)=> mc.id == form.machineSubTypeId);
    onSubmit({
      ...form,
      machineSubType: subType.name,
    });
    onClose();
  }

  const hasSelectedMachineType = form.machineTypeId !== undefined && form.machineTypeId !== null && form.machineTypeId !== '';
  const filteredMachineSubTypes = machineSubTypes.filter(({ machineTypeId }) => machineTypeId === form.machineTypeId);

  return <Dialog
    fullWidth
    open={true}
    onClose={onClose}
    maxWidth='xs'
    aria-labelledby="form-dialog-title"
  >
    <DialogContent>
      <Box mb={1}>
        <Typography variant="h5">{'Add Station & Machine'.translate()}</Typography>
      </Box>

      <FormControl error={errors.machineTypeId}>
        <InputLabel id="machine-type-select-label" required>{'Machine Type'.translate()}</InputLabel>
        <Select
          labelId="machine-type-select-label"
          id="machine-type-select"
          value={form.machineTypeId}
          onChange={(evt)=> setForm({ ...form, machineTypeId: evt.target.value })}
        >
          {
            machineTypes.map(({ id, name })=> <MenuItem key={id} value={id}>{name}</MenuItem>)
          }
        </Select>
      </FormControl>

      <FormControl error={errors.machineSubTypeId} disabled={!form.machineTypeId}>
        <InputLabel id="machine-sub-type-select-label" required>{'Machine Sub Type'.translate()}</InputLabel>
        <Select
          labelId="machine-sub-type-select-label"
          id="machine-sub-type-select"
          value={form.machineSubTypeId}
          onChange={(evt)=> setForm({ ...form, machineSubTypeId: evt.target.value })}
          disabled={!hasSelectedMachineType}
        >
          {
            filteredMachineSubTypes.map(({ id, name })=> <MenuItem key={id} value={id}>{name}</MenuItem>)
          }
        </Select>
      </FormControl>

      <TextField
        label={'Needle Type'.translate()}
        error={errors.needleType}
        value={form.needleType}
        onChange={(evt)=> setForm({ ...form, needleType: evt.target.value })}
      />

      <TextField
        label={'Operation'.translate()}
        error={errors.operation}
        value={form.operation}
        onChange={(evt)=> setForm({ ...form, operation: evt.target.value })}
      />

      <TextField
        fullWidth
        label={'Remarks'.translate()}
        error={errors.remarks}
        value={form.remarks}
        onChange={(evt)=> setForm({ ...form, remarks: evt.target.value })}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(form.isCritical)}
            onChange={(evt)=> setForm({ ...form, isCritical: evt.target.checked })}
            color="primary"
          />
        }
        label={'Critical'.translate()}
      />

      <Attachments
        value={form.attachments}
        onChange={(attachments)=> setForm({ ...form, attachments })}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>
        {'Cancel'.translate()}
      </Button>
      <Button
        variant='contained'
        onClick={handleSubmit}
        color="primary"
      >
        {'Save'.translate()}
      </Button>
    </DialogActions>
  </Dialog>
}