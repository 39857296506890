import {
    FREQUENCY_LIST_REQUEST,
    FREQUENCY_LIST_SUCCESS,
    FREQUENCY_LIST_ERROR
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    frequencyList:[],
    meta: [],
    error: ''
}

const frequencyListReducer = (state = initialState, action) => {
    switch (action.type) {
        case FREQUENCY_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FREQUENCY_LIST_SUCCESS:
            return {
                loading: false,
                frequencyList: action.payload.frequencyList,
                meta: action.payload.meta,
                error: ''
            }
        case FREQUENCY_LIST_ERROR:
            return {
                loading: false,
                frequencyList: [],
                meta: [],
                error: action.payload.message
            }
        default: 
            return state;
    }
}

export default frequencyListReducer;