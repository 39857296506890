import React, { useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import RainBowCheckBox from '../../RainBowCheckBox';

export const Row = (props)=>{
  const [expaned, setExpanded] = useState(false);
  const { row, handleChange } = props;
  const XCheckbox = RainBowCheckBox(row.color);
  const showChild = expaned && row.rows;
  return (<>
    <TableRow>
      <TableCell size="small">
        <XCheckbox
          checked={row.isSelected}
          onChange={(e)=> handleChange(e, row)}
        />
      </TableCell>
      {
        showChild ? (
          <TableCell colSpan={6} size="small">
            <IconButton size="small" onClick={() => setExpanded(false)}>
              <KeyboardArrowUp />
            </IconButton>
            <Button onClick={() => setExpanded(false)} color="primary">
              Collape All Others
            </Button>
          </TableCell>
        ) : (
          <>
          <TableCell size="small">
            {
              row.rows ? (
                <IconButton size="small" onClick={() => setExpanded(true)}>
                  <KeyboardArrowDown />
                </IconButton>
              ) : null
            }
            {row.name}
          </TableCell>
          <TableCell size="small">{row.totalOverdueTicketsP.toFixed(1)}%</TableCell>
          <TableCell size="small">{row.avgTime.toFixed(2)}</TableCell>
          <TableCell size="small">{row.totalTimeP.toFixed(1)}%</TableCell>
          <TableCell size="small">{
            row.totalTime 
              ? row.totalTime.toFixed(2).toLocaleString()
              : 0
          }</TableCell>
          <TableCell size="small">{row.totalTicketsP.toFixed(1)}%</TableCell>
          <TableCell size="small">{row.totalTickets}</TableCell>
          </>
        )
      }
    </TableRow>
    { showChild ? row.rows.map((row, index)=>(<Row key={index} handleChange={handleChange} row={row}/>)) : null }
  </>)
}