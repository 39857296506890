import React from 'react';
import { 
    Box, 
    Table, 
    TableCell, 
    TableSortLabel, 
    Typography, 
    withStyles,
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import history from '../../../../../../../history';

export const Title = withStyles({
    root: {
        fontSize: 16,
        fontWeight: 'bold',
    },
})((props) => <Typography {...props}>{props.text}</Typography>);

export const FlexBox = withStyles({
    root: (props) => ({
        display: 'flex',
        gap: 5,
        justifyContent: props.justifyContent || 'flex-start',
        alignContent: 'center',
        alignItems: 'center',
    }),
})((props) => <Box {...props} />);

export const MuiTable = withStyles({
    root: {
        width: '100%',
        borderSpacing: '3px',
        borderCollapse: 'separate',
        marginTop: 10,
        marginBottom: 10,
        transition: 'all .5s',
        '& th': {
            background: '#EDF0F2 0% 0% no-repeat padding-box',
            color: '#000',
            fontSize: 12,
            fontWeight: 'bold',
        },
        '& th, td': { padding: 3, borderBottom: 'none' },
        '& td': { fontSize: 13 },
        '& tbody tr': { boxShadow: '0px 1px 0px 0px rgba(224, 224, 224, 1)' },
        '& tfoot td': { fontWeight: 'bold', color: '#000 !important' },
    },
})(Table);

export const TableHeaderCell = withStyles({
    root: () => ({
        padding: '3px',
        background: '#EDF0F2 0% 0% no-repeat padding-box',
        transition: 'all ease-in 1s',
    }),
    head: {
      borderRight: '1px solid white',
      borderBottom: 'none',
    }
})((props) => {
    const { active = false } = props;
    return <TableCell {...props} style={ active ? { backgroundColor: '#1585F4' } : null } />
});

export const TableCellUsage = withStyles({
    root: { fontSize: 14, fontWeight: 'bold' },
})(TableCell);

export const TableCellEllipsis = withStyles({
    root:{
        width: 150,
        display: 'block',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
})(TableCell);

export const MuiTableSortLabel = withStyles((theme) => ({
    root: {
      fontWeight: 'bold',
      fontSize: 12,
      whiteSpace: "nowrap",
      justifyContent: "space-between",
      width: '100%',
      '&$active': {
        color: '#FFFFFF'
      },
      '&$icon': {
        color: '#FFFFFF',
        fontSize: 12,
      },
    },
    active: {
      color: '#FFFFFF'
    },
    icon: {
      color: '#FFFFFF !important',
      fontSize: 14,
    },
}))(TableSortLabel);

export const BoxStatus = withStyles({
    root: (props) => ({
        backgroundColor: props.status === 'inactive' 
            ? '#A8A8A8' 
            : '#87DD00',
        borderRadius: 2,
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 11,
        padding: '1px 0',
    }),
})((props) => <Box {...props}>{props.status || 'Active'}</Box>);

export const MuiTableCellLink = withStyles({
    root: (props) => ({
        color: '#218FFA',
        fontWeight: 'bold',
        '& a': {
            color: '#218FFA',
            fontWeight: 'bold',
            textDecoration: 'none',
        },
    }),
})((props) => <TableCell {...props}>
    <Link onClick={() => 
            history.push(`/machine-detail/${props.id}`)}>
        {props.text || ''}
    </Link>
</TableCell>);