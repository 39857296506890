import { minuteToTime } from "../../../../../utils/date";
import { colors } from "./constant";

export const deserializeList = ({
    topMechanicDetails,
    otherMechanicDetails,
    totalMechanicDetails,
    mechanicTicketDetails,
})=>{
    try {

        let results = [];
        if(mechanicTicketDetails.length > 0) {
            results = topMechanicDetails.map((item)=>({
                mechanicName: item.mechanic, 
                reOpen: item.closedRepairTicketReopenPercentage,
                changeover: {
                    ticketCount: Math.ceil(item.closedChangeoverTickets),
                    totalHandlingTime: Math.ceil(item.totalChangeoverHandlingTime),
                    averageHandlingTime: Math.ceil(item.averageChangeoverHandlingTime),
                },
                maintenance: {
                    ticketCount: Math.ceil(item.completedMaintenanceTickets),
                    totalHandlingTime: Math.ceil(item.totalMaintenanceHandlingTime),
                    averageHandlingTime: Math.ceil(item.averageMaintenanceHandlingTime),
                }, 
                responseTime: {
                    ticketCount: Math.ceil(item.closedRepairTicketQty),
                    totalHandlingTime: Math.ceil(item.totalRepairResponseTime),
                    averageHandlingTime: Math.ceil(item.averageRepairResponseTime),
                },
                repair: {
                    ticketCount: Math.ceil(item.closedRepairTicketQty),
                    totalHandlingTime: Math.ceil(item.totalRepairTime),
                    averageHandlingTime: Math.ceil(item.averageRepairTime),
                },
                totalOverallHandlingTime: Math.ceil(item.totalTicketHandlingTime),
                averageOverallHandlingTime: Math.ceil(item.averageTicketHandlingTime),
            }));
            if(mechanicTicketDetails.length > 5){
                results.push({
                    mechanicName: 'Others'.translate(), 
                    reOpen: otherMechanicDetails.closedRepairTicketReopenPercentage,
                    changeover: {
                        ticketCount: Math.ceil(otherMechanicDetails.closedChangeoverTickets),
                        totalHandlingTime: Math.ceil(otherMechanicDetails.totalChangeoverHandlingTime),
                        averageHandlingTime: Math.ceil(otherMechanicDetails.averageChangeoverHandlingTime),
                    },
                    maintenance: {
                        ticketCount: Math.ceil(otherMechanicDetails.completedMaintenanceTickets),
                        totalHandlingTime: Math.ceil(otherMechanicDetails.totalMaintenanceHandlingTime),
                        averageHandlingTime: Math.ceil(otherMechanicDetails.averageMaintenanceHandlingTime),
                    }, 
                    responseTime: {
                        ticketCount: Math.ceil(otherMechanicDetails.closedRepairTicketQty),
                        totalHandlingTime: Math.ceil(otherMechanicDetails.totalRepairResponseTime),
                        averageHandlingTime: Math.ceil(otherMechanicDetails.averageRepairResponseTime),
                    },
                    repair: {
                        ticketCount: Math.ceil(otherMechanicDetails.closedRepairTicketQty),
                        totalHandlingTime: Math.ceil(otherMechanicDetails.totalRepairTime),
                        averageHandlingTime: Math.ceil(otherMechanicDetails.averageRepairTime),
                    },
                    totalOverallHandlingTime: Math.ceil(otherMechanicDetails.totalTicketHandlingTime),
                    averageOverallHandlingTime: Math.ceil(otherMechanicDetails.averageTicketHandlingTime),
                })
            }
            results.push({
                mechanicName: `${'Total Mechanic:'.translate()} ${totalMechanicDetails.countMechanic}`, 
                reOpen: totalMechanicDetails.closedRepairTicketReopenPercentage,
                changeover: {
                    ticketCount: Math.ceil(totalMechanicDetails.closedChangeoverTickets),
                    totalHandlingTime: Math.ceil(totalMechanicDetails.totalChangeoverHandlingTime),
                    averageHandlingTime: Math.ceil(totalMechanicDetails.averageChangeoverHandlingTime),
                },
                maintenance: {
                    ticketCount: Math.ceil(totalMechanicDetails.completedMaintenanceTickets),
                    totalHandlingTime: Math.ceil(totalMechanicDetails.totalMaintenanceHandlingTime),
                    averageHandlingTime: Math.ceil(totalMechanicDetails.averageMaintenanceHandlingTime),
                }, 
                responseTime: {
                    ticketCount: Math.ceil(totalMechanicDetails.closedRepairTicketQty),
                    totalHandlingTime: Math.ceil(totalMechanicDetails.totalRepairResponseTime),
                    averageHandlingTime: Math.ceil(totalMechanicDetails.averageRepairResponseTime),
                },
                repair: {
                    ticketCount: Math.ceil(totalMechanicDetails.closedRepairTicketQty),
                    totalHandlingTime: Math.ceil(totalMechanicDetails.totalRepairTime),
                    averageHandlingTime: Math.ceil(totalMechanicDetails.averageRepairTime),
                },
                totalOverallHandlingTime: Math.ceil(totalMechanicDetails.totalTicketHandlingTime),
                averageOverallHandlingTime: Math.ceil(totalMechanicDetails.averageTicketHandlingTime),
            })
        }
        return results;
    } catch (error) {
        return [];
    }
};

export const getPercent = (valueA, valueB) => ((valueA / valueB) * 100);

export const serializeToolTipForTotalTime = (row) => ({
    title: `${row.mechanicName} ${'Performance'.translate()}`,
    startDate: row.startDate, 
    endDate: row.endDate,
    header: {
        title: `Total Handling Time :`.translate(), 
        color: colors[0], 
        total: minuteToTime(row.totalOverallHandlingTime), 
    },
    list: [
        {
            title: `Total Changeover Time`.translate(), 
            color: colors[2], 
            total: minuteToTime(row.changeover.totalHandlingTime)
        },  
        {
            title: `Total Maintenance Time`.translate(), 
            color: colors[3], 
            total: minuteToTime(row.maintenance.totalHandlingTime)
        }, 
        {
            title: `Total Repair Response Time`.translate(),
            color: colors[4], 
            total: minuteToTime(row.responseTime.totalHandlingTime)
        }, 
        {
            title: `Total Repair Time`.translate(),
            color: colors[5], 
            total: minuteToTime(row.repair.totalHandlingTime)
        }
    ],
});

export const serializeToolTipForAverageTime = (row) => ({
    title: `${row.mechanicName} ${'Performance'.translate()}`,
    startDate: row.startDate, 
    endDate: row.endDate,
    header: {
        title: `Average Handling Time :`.translate(), 
        color: colors[1], 
        total: minuteToTime(row.averageOverallHandlingTime), 
    },
    list: [
        {
            title: `Average Changeover Time`.translate(), 
            color: colors[2], 
            total: minuteToTime(row.changeover.averageHandlingTime)
        }, 
        {
            title: `Average Maintenance Time`.translate(), 
            color: colors[3], 
            total: minuteToTime(row.maintenance.averageHandlingTime)
        }, 
        {
            title: `Average Repair Response Time`.translate(),
            color: colors[4], 
            total: minuteToTime(row.responseTime.averageHandlingTime)
        }, 
        {
            title: `Average Repair Time`.translate(),
            color: colors[5], 
            total: minuteToTime(row.repair.averageHandlingTime)
        }
    ],
});