import React from 'react';
import { 
    Box, 
    CircularProgress, 
    IconButton, 
    Typography 
} from '@material-ui/core';
import LoopIcon from '@material-ui/icons/Loop';
import { formatDate } from '../../../../../../utils/date';
import { useDispatch } from 'react-redux';
import { updateFetchDate } from '../../../../../../redux/actions/MachineSummary/machineSummaryAction';

export default function ButtonSyncData({ onClick, isLoading }) {
    const dispatch = useDispatch();
    const [updatedAt, setUpdatedAt] = React.useState(formatDate(new Date(), "H:mm"));
    const [updatedDt, setUpdatedDt] = React.useState(formatDate(new Date(), "dddd, DD MMM yyyy"));
    const handleOnRefreshData = () => {
        onClick();
        setUpdatedAt(formatDate(new Date(), "H:mm"));
        setUpdatedDt(formatDate(new Date(), "dddd, DD MMM yyyy"));
        dispatch(updateFetchDate(formatDate(new Date(), 'H:mm MMM D, Y')));
    }
    return (
        <Box alignItems='center' gridGap={5} display='flex'>
            <Typography style={{ color: '#1689FA', fontWeight: 'bold', fontSize: 14}}>
                {'Updated at '.translate()}{updatedAt},
            </Typography>
            <Typography style={{ color: '#515151', fontWeight: 'bold', fontSize: 14}}>
                {updatedDt}
            </Typography>
            <IconButton edge="false" onClick={handleOnRefreshData} aria-label="refresh">
                { isLoading 
                    ? <CircularProgress size={13}/> 
                    : <LoopIcon /> 
                }
            </IconButton>
        </Box>
    );
}