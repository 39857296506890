import React from 'react';
import {
  Table,
  TableBody,
  TableRow,
  withStyles,
} from '@material-ui/core';

import TableCell from './TableCell';

const TotalCell = withStyles({
  root: {
    border: 'none',
    fontWeight: 'bold',
    textAlign: 'right',
  },
})(TableCell);

export default function TotalSummary({ calendar, currentStyles }) {
  const compactedStyles = currentStyles.filter((s)=> Boolean(s));
  return <Table>
    <colgroup>
      <col style={{width:'2%'}}/>
      <col style={{width:'8%'}}/>

      <col style={{width:'11.2%'}}/>
      <col style={{width:'11.2%'}}/>
      <col style={{width:'11.2%'}}/>
      <col style={{width:'11.2%'}}/>
      <col style={{width:'11.2%'}}/>
      <col style={{width:'11.2%'}}/>
      <col style={{width:'11.2%'}}/>
      <col style={{width:'11.2%'}}/>
    </colgroup>
    <TableBody>
      <TableRow>
        <TotalCell colSpan="2">{'Total CO Requests'.translate()}</TotalCell>
        <TotalCell>{compactedStyles.length}</TotalCell>
        {calendar.map(({ date, lines }) => {
          const changeovers = lines.map((l) => l.changeovers).flat();

          return <TotalCell key={date.format('L')}>{changeovers.length}</TotalCell>;
        })}
      </TableRow>
    </TableBody>
  </Table>
}