import { makeStyles } from "@material-ui/core";

const useHistoryTableStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 198,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  paperDisabled: {
    opacity: 0.5,
  },
  table: {
    tableLayout: 'fixed',
    minWidth: 750,
  },
  hover: {},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  active: {
    color: '#70b603',
    fontWeight: '800'
  },
  inactive: {
    color: '#d9001b',
    fontWeight: '800'
  },
  notFound: {
    fontWeight: '800',
    fontSize: 'large',
  },
  blue: {
    right: '16px',
    color: '#0F75BC',
    fontWeight: '800',
    width: '160px',
    paddingLeft: '15px !important',
  },
  blueLink: {
    color: '#0F75BC',
  },
  mechanic: {
    width: '121px',
  },
  problemType: {
    width: '71px',
  },
  checkListNo: {
    width: '71px',
  },
  lpm_date: {
    width: '99px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:hover': {
      overflow: 'visible',
      whiteSpace: 'normal',
    }
  },
  solutionType: {
    width: '99px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:hover': {
      overflow: 'visible',
      whiteSpace: 'normal',
    }
  },
  reportedDt: {
    width: '128px'
  },
  grabbedDt: {
    width: '72px'
  },
  closedDt: {
    width: '123px'
  },
  downtime: {
    paddingRight: '0px',
    width: '134px',
  },
  reported: {
    color: '#d9001b',
    fontWeight: '800',
  },
  inRepair: {
    color: '#f59a23',
    fontWeight: '800',
  },
  cancelled: {
    color: '#70b603',
    fontWeight: '800',
  },
  closed: {
    color: '#70b603',
    fontWeight: '800',
  },
  columnIndex: {
    width: '160px',
    paddingLeft: '15px !important',
  },
}));

export default useHistoryTableStyle;