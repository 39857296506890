import React from 'react'
import MainPage from '../../../components/MainPage/MainPage';
import MainPageHeader from '../../../components/MainPageHeader/MainPageHeader';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import { checkPageActionAccess } from '../../../utils/helper'
import { notificationSuccess, notificationError } from '../../../redux/actions/Notification/notificationActions'
import dictionary from '../../../redux/actions/Translation/dictionary'
import setters from '../../../redux/actions/Calendar/calendarActions'
import { connect } from 'react-redux'
import { Grid, Paper, Button, Checkbox, IconButton } from '@material-ui/core'
import moment from 'moment'
import './Calendar.scss'
export default class Calendar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            // today: new Date(),
            currentDateInstance: [new Date(new Date().setMonth(new Date().getMonth() - 1)), new Date(), new Date(new Date().setMonth(new Date().getMonth() + 1))],
            currentDateIndex: 1,
            selectedDateIndex: undefined,
            selectedDate: undefined,
            selectedDateValue: undefined,
            weekname: this.returnWeek(),
            header: [
                { key: 'overdue' },
                { key: 'today' },
                { key: 'thisWeek' },
                { key: 'thisMonth' },
            ],
            ticket_timeStamp: undefined,
            clicked_date_timeStamp: undefined,
            tickets: {
                overdue: 0,
                thisMonth: 0,
                thisWeek: 0,
                today: 0,
                machines: [],
                dateToday: undefined,
            },
            machine_maintenance: [],
            machine_maintenance_checkList: [],
            machine_ticket: [],
            checkboxList: [],
            access: [],

            scrollTouched: false,
            translations: {
                ...this.convert()
            },
            translationTimestamp: undefined


        }
    }

    /**handler */
    sanitizeMonth = (month) => {
        let { JAN, FEB, MAR, APR, MAY, JUN, JULY, AUG, SEPT, OCT, NOV, DEC } = dictionary.GET()
        let months = dictionary.TRANSLATE({ JAN, FEB, MAR, APR, MAY, JUN, JULY, AUG, SEPT, OCT, NOV, DEC })
        return {
            'JANUARY': months.JAN,
            'FEBRUARY': months.FEB,
            'MARCH': months.MAR,
            'APRIL': months.APR,
            'MAY': months.MAY,
            'JUNE': months.JUN,
            'JULY': months.JULY,
            'AUGUST': months.AUG,
            'SEPTEMBER': months.SEPT,
            'OCTOBER': months.OCT,
            'NOVEMBER': months.NOV,
            'DECEMBER': months.DEC
        }[month.toUpperCase()] ?? month

    }
    returnWeek = () => {
        let { MONDAY, TUESDAY, THURSDAY, WEDNESDAY, FRIDAY, SATURDAY, SUNDAY } = dictionary.GET()
        let weeks = dictionary.TRANSLATE({ MONDAY, TUESDAY, THURSDAY, WEDNESDAY, FRIDAY, SATURDAY, SUNDAY })
        return [
            weeks.MONDAY,
            weeks.TUESDAY,
            weeks.WEDNESDAY,
            weeks.THURSDAY,
            weeks.FRIDAY,
            weeks.SATURDAY,
            weeks.SUNDAY
        ]
    }

    convert = () => {
        let { GENERATE_TICKET, MACHINES_UP_FOR_MAINTENANCE, MAINTENANCE_TICKETS, MACHINE_NO, TICKET_NO, OVERDUE, TODAY, THIS_MONTH, THIS_WEEK,
        } = dictionary.GET()
        let labels = dictionary.TRANSLATE({
            GENERATE_TICKET, MACHINES_UP_FOR_MAINTENANCE, MAINTENANCE_TICKETS, MACHINE_NO, TICKET_NO, OVERDUE, TODAY, THIS_MONTH, THIS_WEEK,
        })
        return {
            gen_ticket: labels.GENERATE_TICKET,
            mach_up_main: labels.MACHINES_UP_FOR_MAINTENANCE,
            main_tick: labels.MAINTENANCE_TICKETS,
            mach_no: labels.MACHINE_NO,
            tick_no: labels.TICKET_NO,
            header: [
                labels.OVERDUE,
                labels.TODAY,
                labels.THIS_WEEK,
                labels.THIS_MONTH
            ]
        }
    }
    getBackLink() {

        return '/maintenance';

    }

    handleScroll = ({ target: { scrollTop }, target }) => {
        let source = target.getAttribute('data-source')

        if (source == "machine_maintenance") {
            localStorage['calendarScrollPosition'] = JSON.stringify({
                ...JSON.parse(localStorage['calendarScrollPosition'] ?? null),
                machines_up_scroll: scrollTop
            })
        }
        else {
            localStorage['calendarScrollPosition'] = JSON.stringify({
                ...JSON.parse(localStorage['calendarScrollPosition'] ?? null),
                machines_ticket_scroll: scrollTop
            })
        }

        // console.log(scrollTop)
    }

    handleGenerateButton = (e) => {
        if (this.state?.checkboxList?.length == 0) e.preventDefault()
        else this.props.generateMachine(this.state?.checkboxList, this.state.selectedDate)
    }

    handleListNavigate = ({ target }) => {
        let { currentDateIndex } = this.state
        console.log(target.getAttribute('href'))
        localStorage['redirectToCalendar'] = 'true'
        localStorage['dateInstance'] = JSON.stringify({
            ...JSON.parse(localStorage['dateInstance'] ?? null),
            currentDateIndex,
            currentIntegerIndex: this.asInteger(currentDateIndex)
        })
        this.props.history.push(`${target.getAttribute('href')}`)
    }
    handleCheckbox = ({ target: { parentElement: { parentElement: secondElement }, parentElement: firstElement } }) => {
        let value = JSON.parse(secondElement.getAttribute('data-value'))
        let { checkboxList, machine_maintenance_checkList: checkListIndex } = this.state
        let index = checkboxList.findIndex(x => x.id == value?.id)

        /**if find then pop */
        if (index != -1) checkboxList.splice(index, 1)
        else checkboxList.push({ id: +value.id })
        checkListIndex[value?.index] = !checkListIndex[value?.index]
        this.setState({
            checkboxList,
            machine_maintenance_checkList: checkListIndex,
        })
        console.log(checkboxList)
    }
    handlerDateClick = ({ target: { parentElement: { parentElement: secondElement }, parentElement: firstElement }, target }) => {
        this.setState({
            checkboxList: []
        })
        let value = JSON.parse(secondElement.getAttribute('data-value') ?? firstElement.getAttribute('data-value') ?? 'null')
        let { currentDateIndex: index, currentDateInstance: instance, currentDateIndex } = this.state

        if (value?.date) {

            if (value?.count != null) {

                // localStorage['dateInstance'] = JSON.stringify({
                //     selectedBoxIndex: value.index,
                //     currentDateIndex,
                //     currentIntegerIndex: this.asInteger(currentDateIndex),
                //     currentDate: `${moment(instance[index]).format('YYYY-MM')}-${value.date}`

                // })

                this.setState({
                    selectedDateIndex: value.index,
                    selectedDateValue: value.count,
                    machine_maintenance: [],
                    machine_ticket: [],
                    selectedDate: `${moment(instance[index]).format('YYYY-MM')}-${value.date.toString().replace(/(^\d{1})$/gi, '0$1')}`,
                })
                value?.count && this.props.byDate(`${moment(instance[index]).format('YYYY-MM')}-${value.date.toString().replace(/(^\d{1})$/gi, '0$1')}`)
            }
            else {
                this.setState({
                    selectedDateIndex: value.index,
                    machine_maintenance: [],
                    machine_ticket: [],
                    selectedDateValue: undefined,
                    selectedDate: `${moment(instance[index]).format('YYYY-MM')}-${value.date.toString().replace(/(^\d{1})$/gi, '0$1')}`,
                })
            }

        }
        localStorage['dateInstance'] = JSON.stringify({
            selectedBoxIndex: value.index,
            currentDateIndex,
            currentIntegerIndex: this.asInteger(currentDateIndex),
            currentDate: `${moment(instance[index]).format('YYYY-MM')}-${value.date}`

        })
        // console.log('event', value)
    }

    handleDateToolbar = (jump) => {
        this.setState({
            checkboxList: []
        })
        let { currentDateInstance: instance, currentDateIndex: index, selectedDate, selectedDateValue } = this.state
        // console.log('handler', (index + jump == -1 || index + jump == 3) ? index : index + jump)
        let afterEffectIndex = (index + jump == -1 || index + jump == 3) ? index : index + jump
        let formatSelectedDate = (selectedDate && `${moment(instance[index + jump] ?? instance[index]).format('YYYY-MM')}-${moment(selectedDate).format('DD')}` || null) ?? `${moment(instance[index + jump] ?? instance[index]).format('YYYY-MM-DD')}`
        let lastDay = moment(`${new Date(formatSelectedDate).getFullYear()}-${new Date(formatSelectedDate).getMonth()}-${new Date(new Date(formatSelectedDate).getFullYear(), new Date(formatSelectedDate).getMonth(), 0).getDate()}`).format('YYYY-MM-DD')
        formatSelectedDate = moment(formatSelectedDate).format('YYYY-MM-DD').match(/Invalid Date/gi) != null ? lastDay : moment(formatSelectedDate).format('YYYY-MM-DD')

        if (selectedDate && selectedDateValue) {
            this.setState({
                selectedDate: formatSelectedDate,
                currentDateIndex: afterEffectIndex,
                machine_maintenance: [],
                machine_ticket: []
            })
            this.props.byDate(formatSelectedDate)
        }
        else {
            this.setState({
                selectedDate: formatSelectedDate,
                currentDateIndex: afterEffectIndex,
                machine_maintenance: [],
                machine_ticket: []
            })

        }
        localStorage['dateInstance'] = JSON.stringify({
            ...JSON.parse(localStorage['dateInstance'] ?? null),
            currentDateIndex: afterEffectIndex,
            currentIntegerIndex: this.asInteger(afterEffectIndex),
            currentDate: formatSelectedDate
        })
        console.log('afterEffect', this.asInteger(afterEffectIndex), formatSelectedDate)
        this.props.byDate(formatSelectedDate)
        this.props.byMonth(this.asInteger(afterEffectIndex))

    }
    asInteger = (index) => {
        return {
            0: -1,
            1: 0,
            2: 1
        }[index]
    }
    computeMachinesForArray = (machineArray) => {
        let machineInstance = new Array(45).fill(0)
        machineInstance.filter((x, index) => {

        })

    }
    /**render */

    renderSideBar = () => {
        let { currentDateInstance: dateInstance, currentDateIndex: index, selectedDate, machine_maintenance, machine_ticket, checkboxList, machine_maintenance_checkList } = this.state
        // console.log('selectedDate', selectedDate, moment(selectedDate ?? dateInstance[index]).format('DD-MM-YYYY'), moment(selectedDate).format('DD-MM-YYYY'),dateInstance[index])
        let currentNumericDate = moment(selectedDate ?? dateInstance[index]).format('DD-MM-YYYY').match(/Invalid Date/gi) ? moment(dateInstance[index]).format('DD-MM-YYYY') : moment(selectedDate).format('DD-MM-YYYY')
        // console.log('numericDate', currentNumericDate)
        return (
            <Paper className="panel sidebar">
                <div className="date-toolbar">
                    {index != 0 && <div className="nav-tool" ><IconButton size="small" onClick={() => this.handleDateToolbar(-1)}><ArrowLeft className="custom-icon" /></IconButton></div> || <div className="nav-tool">&nbsp;</div>}
                    <div className="date-info">{this.sanitizeMonth(moment(dateInstance[index]).format('MMMM'))} {moment(dateInstance[index]).format('YYYY')}</div>
                    {index != 2 && <div className="nav-tool" ><IconButton size="small" onClick={() => this.handleDateToolbar(1)} ><ArrowRight className="custom-icon" /></IconButton></div> || <div className="nav-tool">&nbsp;</div>}
                </div>
                <div className="numeric-date">{currentNumericDate}</div>
                <div className="tickets-list">
                    <div className="link overview" href={`/maintenance`} onClick={this.handleListNavigate} >{this.state.translations.mach_up_main}</div>
                    {(checkboxList?.length != 0 && checkPageActionAccess(this.props.access, { pageId: 9, pageAction: 'Generate Ticket' })) &&
                        <Button className="generate-ticket bg-white" onClick={this.handleGenerateButton}>{this.state.translations.gen_ticket}</Button>
                        ||
                        <Button disabled className="generate-ticket bg-white empty-data">{this.state.translations.gen_ticket}</Button>
                    }
                    {machine_maintenance?.length != 0 &&
                        <div className="table-scroll padded" data-source="machine_maintenance" onScroll={this.handleScroll}>
                            {machine_maintenance.map((x, index) => {
                                {/* console.log('checkbox',machine_maintenance_checkList[index], index, machine_maintenance_checkList) */ }
                                return (
                                    <div className="table-container">
                                        <Checkbox checked={machine_maintenance_checkList[index]} className="custom-checkbox" size="small" onChange={this.handleCheckbox} color="primary" data-value={JSON.stringify({ id: x?.machineId, index })} />
                                        <div className="link" href={`/machine-detail/${x?.machineId}`} onClick={this.handleListNavigate} >{x?.machineNo}</div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
                <div className="maintenance-list">
                    <div className="link overview" href={`/tickets`} onClick={this.handleListNavigate}>{this.state.translations.main_tick}</div>
                    <table style={{maxHeight: 300}}>
                        <thead>
                            <tr>
                                <td>{this.state.translations.mach_no}</td>
                                <td>{this.state.translations.tick_no}</td>
                            </tr>
                        </thead>
                        <tbody>
                        {machine_ticket.map((x, index) => {
                                return (
                                    <tr>
                                        <td className="link" href={`/machine-detail/${x?.machineId}`} onClick={this.handleListNavigate} ><div style={{ textAlign: 'start', paddingLeft: 12 }}>{x?.machineNo}</div></td>
                                        <td className="link" href={`/ticket-detail/${x?.ticketId}`} onClick={this.handleListNavigate} ><div style={{ textAlign: 'start', paddingLeft: 12 }}>{x?.ticketNo}</div></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </Paper>
        )
    }

    renderLocalCalendar = () => {
        let { tickets: { dateToday }, selectedDate } = this.state
        // console.log('selectedDate', selectedDate)
        selectedDate = moment(selectedDate)
        dateToday = moment(dateToday)
        let calendarInstance = {
            month: selectedDate.month() + 1 || dateToday.month() + 1 || new Date().getMonth() + 1,
            year: selectedDate.year() || dateToday.year() || new Date().getFullYear(),
            day: selectedDate.date() || dateToday.date() || 'no-data',
        }
        // console.log('after init', calendarInstance)
        let calendarIndex = {
            days_of_month: new Date(calendarInstance.year, calendarInstance.month, 0).getDate(),
            first_day_index: new Date(calendarInstance.year, calendarInstance.month - 1, 1).getDay(),
            // last_day_index: new Date(calendarInstance.year, calendarInstance.month - 1, new Date(calendarInstance.year, calendarInstance.month, 0).getDate()).getDay()
        }

        let first_index_pos = calendarIndex.first_day_index == 0 ? 6 : calendarIndex.first_day_index - 1
        let calendarBoxInstance = new Array(42).fill('boxes', first_index_pos, first_index_pos + calendarIndex.days_of_month)
        let lastFilledBoxes = calendarBoxInstance.lastIndexOf('boxes', calendarBoxInstance.length)

        // console.log(selectedDate, calendarInstance, calendarIndex, calendarBoxInstance, lastFilledBoxes)
        if (lastFilledBoxes < 35) calendarBoxInstance.length -= 7

        let row = new Array(calendarBoxInstance.length / 7).fill(1)
        let tempInc = 1
        calendarBoxInstance = calendarBoxInstance.map(x => {
            if (x == 'boxes') {
                x = tempInc
                tempInc++
            }
            return x
        })
        let machineInstance
        if (this.state.tickets.machines?.length > 0) {
            machineInstance = new Array(calendarBoxInstance.length).fill(null)
            this.state.tickets.machines.forEach(machines => {
                let year = new Date(machines?.npm).getFullYear(), 
                    month = new Date(machines?.npm).getMonth(), 
                    date = moment(machines?.npm).date(),
                    count = machines?.machines;

                let index = calendarBoxInstance.findIndex(boxInstance => boxInstance == date && (month + 1) == calendarInstance.month)
                machineInstance.fill(count, index, index + 1)

            })
        }


        // console.log('data', calendarBoxInstance, machineInstance)
        return (
            <div className="container">
                <div className="header">
                    {this.state.header.map((x, index) => {
                        return <div className="header-value">{this.state.translations.header[index]} ({this.state.tickets[x?.key]})</div>
                    })}
                </div>
                <Paper className="panel calendar">
                    <Grid container className="weekheader">
                        {this.state.weekname.map(week => {
                            return <Grid className="week" item xs>{week}</Grid>
                        })}
                    </Grid>
                    {row.map((x, rowIndex) => {
                        return (
                            <Grid container className="dateContainer">
                                {[0, 1, 2, 3, 4, 5, 6].map(y => {
                                    let currentIndex = rowIndex > 0 ? (y + 1) + (rowIndex * 7) : ((rowIndex + 1) * (y + 1))
                                    let isToday = calendarBoxInstance[currentIndex - 1] == (dateToday.date() || 'no-data') && this.state.currentDateIndex == 1
                                    {/* console.log(calendarBoxInstance[currentIndex - 1], calendarInstance.day, today.getDate(), selectedDate)                                      */ }
                                    return (
                                        <Grid item xs className={isToday ? "dateBox today" : calendarBoxInstance[currentIndex - 1] ? "dateBox" : "dateBox no-date"} data-value={JSON.stringify({ y, index: currentIndex, date: calendarBoxInstance[currentIndex - 1], count: machineInstance?.[currentIndex - 1] })} onClick={this.handlerDateClick}>
                                            <div className={calendarBoxInstance[currentIndex - 1] == calendarInstance.day ? "borderLine selected" : "borderLine"}>
                                                <div className="dateHeader">
                                                    {calendarBoxInstance[currentIndex - 1]}
                                                </div>
                                                {<div className="dateInfo">{isToday && 'Today'.translate() || ''}</div>}
                                                {<div className="dateValue">
                                                    {calendarBoxInstance[currentIndex - 1] ? machineInstance?.[currentIndex - 1] ?? '-' : ''}
                                                </div>}
                                            </div>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        )
                    })}
                </Paper>
                {this.renderSideBar()}
            </div>

        )
    }

    /** main functions */

    componentDidMount() {
        localStorage['fromCalendar'] = true
        let { selectedBoxIndex: dateIndex, currentDateIndex: monthIndex, currentIntegerIndex: integerIndex, currentDate } = JSON.parse(localStorage['dateInstance'] ?? null) ?? {}
        let { currentDateInstance: instance, currentDateIndex: instanceIndex, selectedDateIndex, selectedDate, today, access } = this.state
        let accessRoles = JSON.parse(localStorage['userAccess']?.length ?? null)

        if (accessRoles != null && access?.length != accessRoles?.length) {
            this.setState({
                access: JSON.parse(localStorage['userAccess'])
            })
            console.log('roles')
        }


        console.log(instance[monthIndex], moment(currentDate).format('YYYY-MM-DD'), selectedDate)
        if (dateIndex != selectedDateIndex) {
            this.props.byMonth(integerIndex)
            localStorage['dateInstance'] && this.props.byDate(`${moment(currentDate).format('YYYY-MM-DD')}`)
            this.setState({
                selectedDateIndex: dateIndex,
                selectedDate: new Date(currentDate),
                currentDateIndex: monthIndex,
            })
        }
        else {
            this.props.byMonth()
            this.props.byDate(`${moment(today).format('YYYY-MM-DD')}`)
        }
    }

    componentDidUpdate() {
        let scrollInstance = JSON.parse(localStorage['calendarScrollPosition'] ?? null)
        let { scrollTouched } = this.state

        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                translations: Object.assign({}, { ...this.convert() }),
                weekname: this.returnWeek()
            })
        }
        if (this.props.timeStamp != this.state.ticket_timeStamp) {
            console.log('month update', this.props)

            this.setState({
                ticket_timeStamp: this.props.timeStamp,
                tickets: {
                    ...this.props.tickets,
                }
            })
        }
        if (this.props?.clicked_timeStamp != this.state?.clicked_date_timeStamp) {
            // console.log('date update', this.props)
            let tickets = [], maintenance = [];
            this.props.clicked_date.machines.forEach(x => {
                x.ticketNo ? tickets.push(x) : maintenance.push(x)
            })
            maintenance.sort((x, y) => {
                if (x?.machineNo < y?.machineNo) return -1
                else return 1
            })
            tickets.sort((x, y) => {
                if (x?.ticketId < y?.ticketId) return 1
                else return -1
            })
            // console.log(tickets, maintenance)
            this.setState({
                clicked_date_timeStamp: this.props.clicked_timeStamp,
                machine_maintenance: maintenance,
                machine_ticket: tickets,
                checkboxList: [],
                machine_maintenance_checkList: new Array(maintenance.length).fill(false)
            })

        }

        if (scrollInstance && scrollTouched == false && document.querySelectorAll('.table-scroll')?.length > 0) {
            console.log(document.querySelectorAll('.table-scroll'), scrollInstance)

            document.querySelectorAll('.table-scroll').forEach((x, index) => {
                if (x.getAttribute('data-source') == 'machine_maintenance') {

                    document.querySelectorAll('.table-scroll')[index].scrollTop = scrollInstance?.machines_up_scroll
                }
                else document.querySelectorAll('.table-scroll')[index].scrollTop = scrollInstance?.machines_ticket_scroll
            })
            this.setState({
                ...scrollInstance,
                scrollTouched: !scrollTouched
            })
        }
    }

    componentWillUnmount() {
        // this.props.clicked_date.machines = []
        console.log('unmount', this.props.clicked_date.machines)
    }

    render() {
        return (
            <MainPage>
                <MainPageHeader title="Maintenance Calendar" search={false} back={this.getBackLink()} />
                {this.renderLocalCalendar()}
            </MainPage>
        )
    }
}
const stateToProps = state => {
    let { calendarReducer, translationReducer, authReducer } = state
    return {
        ...authReducer,
        ...calendarReducer,
        ...translationReducer
    }
}
Calendar = connect(stateToProps, setters)(Calendar)
