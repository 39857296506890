import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import GTranslateIcon from '@material-ui/icons/GTranslate';
import { GetGoogleTranslation } from '../../../../redux/actions/Translation/googleTranslationAction';

export default function InputTranslation({
  onChangeText,
  language,
  key,
  name,
  value,
  label,
  constant,
  originalValue,
}) {
  const [results, fetchingData, isAwait, setIsAwait] = GetGoogleTranslation();

  useEffect(() => {
    if (results.result) {
      setIsAwait(false); 
      onChangeText(results.result)
    }
  }, [results]);

  const TranslateIcon = (position, color, key, q, target, constant) => {
    return (
      <InputAdornment position={position}>
        <IconButton
          disabled={isAwait}
          onClick={() => {
            setIsAwait(true);
            fetchingData(q, target, key, name, constant);
          }}
        >
          {isAwait ? (
            <CircularProgress
              variant="indeterminate"
              disableShrink
              color="primary"
              size={20}
            />
          ) : (
            <GTranslateIcon className="translations-icon" />
          )}
        </IconButton>
      </InputAdornment>
    );
  }

  return (
    <TextField
      className="text-field"
      label={label}
      type="text"
      value={value}
      onChange={(e) => {
        onChangeText(e.target.value);
      }}
      fullWidth
      InputProps={{
        endAdornment: TranslateIcon(
          'end',
          'primary',
          key,
          originalValue,
          language.language,
          constant
        ),
      }}
    />
  );
}
