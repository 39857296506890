import React from "react";
import moment from "moment";
import history from "../../../../history";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import {
  checkIfApiLoading,
  checkPageActionAccess,
  checkPageAccessField,
} from "../../../../utils/helper";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  showModal,
  closeModal,
} from "../../../../redux/actions/Modal/modalActions";
import { notificationError } from "../../../../redux/actions/Notification/notificationActions";
import {
  getMachineById,
  getTemplates,
  putUpdateMachine,
} from "../../../../redux/actions/Machine/machineActions";
import {
  postNewTicket,
  getTicketTypes,
  getTicketDetail,
  putTicketDetail,
  getAllTicketsBySearch,
} from "../../../../redux/actions/Tickets/ticketActions";
import {
  getRepairTicketStatus,
  getMaintenanceTicketStatus,
} from "../../../../redux/actions/Status/StatusActions";
import { getProblems } from "../../../../redux/actions/Problem/problemActions";
import MainPage from "../../../../components/MainPage/MainPage";
import MainPageHeader from "../../../../components/MainPageHeader/MainPageHeader";
import MainPageBody from "../../../../components/MainPageBody/MainPageBody";
import MaintenanceChecklist from "../../../../components/Modal/MaintenanceChecklist/MaintenanceChecklist";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import { apiSettings } from "../../../../utils/api/CoreApi";
import Media from "./Media/Media";
import dictionary from "../../../../redux/actions/Translation/dictionary";
import "./TicketDetails.scss";
import { displayMinute } from "../../../../utils/date";
import { formatDate } from '../../../../utils/date';
import { ConfirmCancel } from "./Modal";

class TicketDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipTheme: createMuiTheme({
        overrides: {
          MuiTooltip: {
            tooltip: {
              fontSize: "1rem",
            },
          },
        },
      }),
      statusLoaded: null,
      id: null,
      ticketDetail: [],
      openConfirmCanCel: false,
      formErrors: [],
      machineDetail: [],
      assets: [],
      machineChecklist: [],
      form: {
        problemTypeId: null,
        remarks: null,
      },
      rows: [],
      ticketDetailHasBeenLoaded: false,
      languageProps: Object.assign({}, { ...this.changeLang() }),
      translationTimestamp: undefined,
    };
  }

  changeLang = () => {
    let {
      NOTIFICATIONS: {
        there_are,
        tickets_cannot_be_cancelled,
        this_ticket_cannot_be_cancelled,
        are_you_sure,
      },
      CANCEL_TICKET,
      START_REPAIR,
      REPAIRED,
      CLOSE,
      PROBLEM,
      SOLUTION,
      REMARKS,
      MACHINE_NO,
      MODEL_NO,
      MACHINE_TYPE,
      Supplier,
      DATE_OF_PURCHASE,
      MACHINE_SUB_TYPE,
      BRAND,
      MOTOR_TYPE,
      SERIAL_NO,
      ASSET_NO,
      RFID,
      STATUS,
      MAINTENANCE_FREQ,
      TOTAL_REPAIR_TIME,
      LAST_MAINTENANCE,
      NEXT_MAINTENANCE,
      MAINTENANCE_STATUS,
      STATION,
      ACTIVE,
      REPORTED_BY,
      REPORTED_ON,
      REPORTED_ON_TIME,
      GRABBED_BY,
      GRABBED_ON,
      GRABBED_ON_TIME,
      REPAIRED_ON,
      REPAIRED_BY,
      REPAIRED_ON_TIME,
      TOTAL_ELAPSED_TIME,
      TOTAL_CLOSED_TIME,
      TOTAL_DURATION,
      TOTAL_MINUTES,
      LOCATION,
      ATTACHMENT,
      CHECKLIST_NO,
      MAINTENANCE_DATE,
      RENTAL,
      REPORTED_DURATION,
      TOTAL_REPORTED_TIME,
      GENERATE_BY,
      GENERATE_ON,
      GENERATE_ON_TIME,
      CLOSED_ON,
      CLOSED_BY,
      CLOSED_ON_TIME,
      CLOSED_DURATION,
      ACTION_TAKEN_BY,
      ACTION_TAKEN_ON,
      ACTION_TAKEN_ON_TIME,
      VIEW_CHECKLIST,
      MACHINE_SUBTYPE,
      ACTION_TAKEN,
    } = dictionary.GET();
    return dictionary.TRANSLATE({
      CANCEL_TICKET,
      START_REPAIR,
      REPAIRED,
      CLOSE,
      PROBLEM,
      SOLUTION,
      REMARKS,
      MACHINE_NO,
      MODEL_NO,
      MACHINE_TYPE,
      Supplier,
      DATE_OF_PURCHASE,
      MACHINE_SUB_TYPE,
      BRAND,
      MOTOR_TYPE,
      SERIAL_NO,
      ASSET_NO,
      RFID,
      STATUS,
      MAINTENANCE_FREQ,
      TOTAL_REPAIR_TIME,
      LAST_MAINTENANCE,
      NEXT_MAINTENANCE,
      MAINTENANCE_STATUS,
      STATION,
      ACTIVE,
      REPORTED_BY,
      REPORTED_ON,
      REPORTED_ON_TIME,
      GRABBED_BY,
      GRABBED_ON,
      GRABBED_ON_TIME,
      REPAIRED_ON,
      REPAIRED_BY,
      REPAIRED_ON_TIME,
      TOTAL_ELAPSED_TIME,
      TOTAL_CLOSED_TIME,
      TOTAL_DURATION,
      TOTAL_MINUTES,
      LOCATION,
      ATTACHMENT,
      CHECKLIST_NO,
      MAINTENANCE_DATE,
      RENTAL,
      REPORTED_DURATION,
      TOTAL_REPORTED_TIME,
      GENERATE_BY,
      GENERATE_ON,
      GENERATE_ON_TIME,
      CLOSED_ON,
      CLOSED_BY,
      CLOSED_ON_TIME,
      CLOSED_DURATION,
      ACTION_TAKEN_BY,
      ACTION_TAKEN_ON,
      ACTION_TAKEN_ON_TIME,
      VIEW_CHECKLIST,
      MACHINE_SUBTYPE,
      ACTION_TAKEN,
    });
  };

  checkIfApiLoading() {
    let flag = checkIfApiLoading([
      this.props.machinesDetailLoading,
      this.props.problemLoading,
      this.props.ticketTypeLoading,
      this.props.ticketDetailLoading,
    ]);
    return flag;
  }

  resetForm() {
    let resetForm = {
      problemTypeId: null,
      remarks: null,
      ticketNo: null,
    };
    this.setState({ form: resetForm });
  }

  handleCancel = () => {
    this.resetForm();
    history.push("/machine-detail/" + this.state.id);
  };

  renderProblemItems() {
    let problemList = this.props.problemList.map((item) => {
      return (
        <MenuItem key={item.id} value={item.id}>
          {item.desc}
        </MenuItem>
      );
    });
    return problemList;
  }

  renderTicketTypeItems() {
    if (this.props.ticketTypeLoading === false) {
      let ticketTypeList = this.props.ticketTypeList.map((item) => {
        return (
          <MenuItem key={item.id} value={item.id}>
            {item.desc}
          </MenuItem>
        );
      });
      return ticketTypeList;
    }
  }

  renderChecklistItems() {
    if (this.props.machineDetail) {
      let checklist = this.props.machineChecklist.map((item) => {
        return (
          <MenuItem key={item.id} value={item.checkListId}>
            {item.templateName}
          </MenuItem>
        );
      });
      return checklist;
    }
  }

  // renderText(field){
  //     if (field === null || field === ''){
  //         return '-'
  //     }
  //     else{
  //         return field
  //     }
  // }
  renderText(field) {
    if (field === null || field === "" || field === "null") {
      return "-";
    } else {
      if (field === "ACTIVE") {
        return "AVAILABLE";
      } else {
        return field;
      }
    }
  }

  renderCurrentLocation(field) {
    if (this.props.machineDetail) {
      if (this.props.machineDetail.mfgLineId_desc) {
        return this.renderText(
          this.props.machineDetail.mfgLineId_desc +
            " - " +
            this.props.machineDetail.station
        );
      } else {
        return this.renderText(this.props.machineDetail.areaId_desc);
      }
    } else {
      return this.renderText(null);
    }
  }

  renderReportedLocation() {
    if (this.props.ticketDetail) {
      return this.renderText(this.props.ticketDetail.reportedPlace);
    }
  }

  renderProblemText(field) {
    if (field === null || field === "" || field === "null") {
      return "-";
    } else {
      if (field === "ACTIVE") {
        return "AVAILABLE";
      } else {
        return field;
      }
    }
  }

  renderTextWithCapitalization(field) {
    if (field === null || field === "" || field === "null") {
      return "-";
    } else {
      let capitalized = field
        .split(" ")
        .map(
          (item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
        )
        .join(" ");
      return capitalized;
    }
  }

  renderMaintenFreq(field) {
    if (field === null || field === "") {
      return "-";
    } else {
      return field;
    }
  }

  renderCondition(field) {
    if (field === 1) {
      return "WORKING";
    } else {
      return "IN-PROCESS";
    }
  }

  renderStatus(field) {
    if (field === 1) {
      return "AVAILABLE";
    } else {
      return "REPAIR";
    }
  }

  // renderCancelClass(status) {
  //   // USER ACCESS CHECK
  //   if (
  //     checkPageActionAccess(this.props.userAccess, {
  //       pageId: 7,
  //       pageAction: "Cancel Ticket",
  //     }) === false
  //   ) {
  //     return "ticket-action-item disabled";
  //   }

  //   if (status == "CLOSED") {
  //     return "ticket-action-item disabled";
  //   } else if (status == "REPAIRED") {
  //     return "ticket-action-item disabled";
  //   } else if (status == "CANCELLED") {
  //     return "ticket-action-item disabled";
  //   } else if (status == "COMPLETED") {
  //     return "ticket-action-item disabled";
  //   } else {
  //     return "ticket-action-item";
  //   }
  // }

  renderStartRepairClass(status) {
    // USER ACCESS CHECK
    if (
      checkPageActionAccess(this.props.userAccess, {
        pageId: 7,
        pageAction: "Start Repair",
      }) === false
    ) {
      return "ticket-action-item disabled";
    }

    if (this.props.machineDetail) {
      if (this.props.machineDetail.areaType === "FL") {
        return "ticket-action-item disabled";
      }
    }

    // if (this.props.ticketDetail){
    //     if (this.props.ticketDetail.problemType === null) {
    //         return "ticket-action-item disabled"
    //     }
    // }

    if (status == "REPORTED" || status == "SCHEDULED") {
      return "ticket-action-item";
    } else {
      return "ticket-action-item disabled";
    }
  }

  renderRepairedClass(status) {
    // USER ACCESS CHECK
    if (
      checkPageActionAccess(this.props.userAccess, {
        pageId: 7,
        pageAction: "Repaired",
      }) === false
    ) {
      return "ticket-action-item disabled";
    }

    if (this.props.machineDetail) {
      if (this.props.machineDetail.areaType === "FL") {
        return "ticket-action-item disabled";
      }
    }

    // if (this.props.ticketDetail) {
    //   if (this.props.ticketDetail.problemType === null) {
    //     return "ticket-action-item disabled";
    //   } else if (this.props.ticketDetail.solutionType === null) {
    //     return "ticket-action-item disabled";
    //   }
    // }

    if (status == "IN-REPAIR") {
      return "ticket-action-item";
    } else {
      return "ticket-action-item disabled";
    }
  }

  renderCloseClass(status) {
    // USER ACCESS CHECK
    if (
      checkPageActionAccess(this.props.userAccess, {
        pageId: 7,
        pageAction: "Close",
      }) === false
    ) {
      return "ticket-action-item disabled";
    }

    if (this.props.machineDetail) {
      if (this.props.machineDetail.areaType === "FL") {
        return "ticket-action-item disabled";
      }
    }

    // if (this.props.ticketDetail) {
    //   if (this.props.ticketDetail.problemType === null) {
    //     return "ticket-action-item disabled";
    //   } else if (this.props.ticketDetail.solutionType === null) {
    //     return "ticket-action-item disabled";
    //   }
    // }

    if (status == "REPAIRED") {
      return "ticket-action-item";
    } else {
      return "ticket-action-item disabled";
    }
  }

  // renderCancelBoolean(status) {
  //   // USER ACCESS CHECK
  //   if (
  //     checkPageActionAccess(this.props.userAccess, {
  //       pageId: 7,
  //       pageAction: "Cancel Ticket",
  //     }) === false
  //   ) {
  //     return true;
  //   }

  //   if (status == "CLOSED") {
  //     return true;
  //   } else if (status == "REPAIRED") {
  //     return true;
  //   } else if (status == "CANCELLED") {
  //     return true;
  //   } else if (status == "COMPLETED") {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  renderStartRepairBoolean(status) {
    // USER ACCESS CHECK
    if (
      checkPageActionAccess(this.props.userAccess, {
        pageId: 7,
        pageAction: "Start Repair",
      }) === false
    ) {
      return true;
    }

    if (this.props.machineDetail) {
      if (this.props.machineDetail.areaType === "FL") {
        return true;
      }
    }

    // if (this.props.ticketDetail){
    //     if (this.props.ticketDetail.problemType === null) {
    //         return true
    //     }
    // }

    if (status == "REPORTED" || status == "SCHEDULED") {
      return false;
    } else {
      return true;
    }
  }

  renderRepairedBoolean(status) {
    // USER ACCESS CHECK
    if (
      checkPageActionAccess(this.props.userAccess, {
        pageId: 7,
        pageAction: "Repaired",
      }) === false
    ) {
      return true;
    }

    if (this.props.machineDetail) {
      if (this.props.machineDetail.areaType === "FL") {
        return true;
      }
    }

    // if (this.props.ticketDetail) {
    //   if (this.props.ticketDetail.problemType === null) {
    //     return true;
    //   } else if (this.props.ticketDetail.solutionType === null) {
    //     return true;
    //   }
    // }

    if (status == "IN-REPAIR") {
      return false;
    } else {
      return true;
    }
  }

  renderCloseBoolean(status) {
    // USER ACCESS CHECK
    if (
      checkPageActionAccess(this.props.userAccess, {
        pageId: 7,
        pageAction: "Close",
      }) === false
    ) {
      return true;
    }

    if (this.props.machineDetail) {
      if (this.props.machineDetail.areaType === "FL") {
        return true;
      }
    }

    // if (this.props.ticketDetail) {
    //   if (this.props.ticketDetail.problemType === null) {
    //     return true;
    //   } else if (this.props.ticketDetail.solutionType === null) {
    //     return true;
    //   }
    // }

    if (status == "REPAIRED") {
      return false;
    } else {
      return true;
    }
  }

  renderCheckbox(check) {
    if (check === 1 || check === true) {
      return "Yes";
    } else {
      return "No";
    }
  }

  renderDate(date) {
    if (date !== null) {
      let timezone = moment.parseZone(date).format("Z");
      let dateTimeOnly =
        moment.parseZone(date).format("YYYY-MM-DD HH:mm:ss") + "+00:00";
      return moment(dateTimeOnly).utcOffset(timezone).format("DD-MM-YYYY");
    } else {
      return "-";
    }
  }

  renderMachineDate(date) {
    try {
      if (date !== null) {
        let newDate = date.substring(0, date.indexOf("T"));
        return moment.parseZone(newDate).format("DD-MM-YYYY");
      } else {
        return "-";
      }
    } catch (err) {
      if (date !== null) {
        let timezone = moment.parseZone(date).format("Z");
        let dateTimeOnly =
          moment.parseZone(date).format("YYYY-MM-DD HH:mm:ss") + "+00:00";
        return moment(dateTimeOnly).utcOffset(timezone).format("DD-MM-YYYY");
      } else {
        return "-";
      }
    }
  }

  renderTime(date) {
    if (date !== null) {
      let timezone = moment.parseZone(date).format("Z");
      let dateTimeOnly =
        moment.parseZone(date).format("YYYY-MM-DD HH:mm:ss") + "+00:00";
      return moment(dateTimeOnly).utcOffset(timezone).format("HH:mm");
    } else {
      return "-";
    }
  }

  handleFormChange = ({ target }) => {
    let tmpForm = this.state.form;
    tmpForm[target.name] = target.value;
    this.setState({ form: tmpForm });
    this.removeErrorOnChange(target.name);
  };

  removeErrorOnChange(field) {
    let errors = this.state.formErrors.filter((item) => {
      return item.name !== field;
    });
    this.setState({ formErrors: errors });
  }

  hasErrors = (field) => {
    let checkField = this.state.formErrors.filter((item) => {
      return item.name === field;
    });
    if (checkField.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  getErrors = (field) => {
    let checkField = this.state.formErrors.filter((item) => {
      return item.name === field;
    });
    let errorString = null;
    checkField.map((item) => {
      errorString += item.message + ". ";
      return "";
    });
    return errorString;
  };

  loadDefaultTickets() {
    let filters = {
      search: "",
      date: "today",
      type: "r",
      status: "all",
    };
    localStorage.setItem("ticketFilters", JSON.stringify(filters));
    this.props.getAllTicketsBySearch(
      100000,
      1,
      filters.search,
      filters.date,
      filters.type,
      filters.status
    );
    localStorage.removeItem("loadDefaultTickets");
  }

  onUnload = (e) => {
    e.preventDefault();
    localStorage.setItem("loadDefaultTickets", "true");
  };

  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload);
    if (this.props.match.params.id) {
      if (localStorage.getItem("loadDefaultTickets") === "true") {
        this.loadDefaultTickets();
      }
      this.setState({ id: this.props.match.params.id });
      this.props.getProblems();
      this.props.getTicketTypes();
      this.props.getTicketDetail(this.props.match.params.id);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentWillReceiveProps() {
    if (this.props.ticketDetail != undefined) {
      const ticketType = this.props.ticketDetail.ticketType;
      const statusLoaded = this.state.statusLoaded;
      this.setState({ ticketDetail: this.props.ticketDetail });
      if(ticketType === 'Repair'){
        if(statusLoaded !== 'Repair'){
          this.setState({ statusLoaded: "Repair" }, () => {
            this.props.getRepairTicketStatus();
          });
        }
      } else if(ticketType === 'Maintenance') {
        if(statusLoaded !== 'Maintenance'){
          this.setState({ statusLoaded: "Maintenance" }, () => {
            this.props.getMaintenanceTicketStatus();
          });
        }
      }
    }
    if (this.props.machineDetail != undefined) {
      this.setState({ machineDetail: this.props.machineDetail });
    }
    if (this.props.machineChecklist != undefined) {
      this.setState({ machineChecklist: this.props.machineChecklist });
    }
    if (this.props.ticketDetailLoading == false) {
      this.setState({
        ticketDetailHasBeenLoaded: true,
      });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.state.ticketDetailHasBeenLoaded == true) {
      const assets = this.props.assetDetail;

      const sortedAssets = assets.sort((a, b) => {
        const aExt = a.assetLink.substr(a.assetLink.length - 3);
        const bExt = b.assetLink.substr(b.assetLink.length - 3);
        if(aExt == bExt){
          return a.createdDt < b.createdDt ? 1 : -1;
        }
        return aExt == "mp4" ?  1 : -1; // show videos last
      });

      return this.setState({
        assets: sortedAssets,
        ticketDetailHasBeenLoaded: false,
      })
    }
  }

  componentDidUpdate() {
    if (
      this.props.translation &&
      this.props.translation.translations.timeStamp !=
        this.state.translationTimestamp
    ) {
      this.setState({
        translationTimestamp: this.props.translation.translations.timeStamp,
        languageProps: Object.assign({}, { ...this.changeLang() }),
      });
    }
  }

  componentWillUnmount() {}

  getTicketStatusClass() {
    if (this.props.ticketDetail) {
      if (this.props.ticketDetail.status === "REPORTED") {
        return "reported";
      } else if (this.props.ticketDetail.status === "CANCELLED") {
        return "closed";
      } else if (this.props.ticketDetail.status === "RE-OPENED") {
        return "re-opened";
      } else if (
        this.props.ticketDetail.status === "IN-REPAIR" ||
        this.props.ticketDetail.status === "IN PROGRESS" 
      ) {
        return "in-repair";
      } else {
        return "closed";
      }
    }
  }

  handleUpdateTicket = (status, newRemark = null) => {
    let statuscode = 0;
    let conditionId = 2;
    let statusIdNumber = 8;
    let statusIdEnum = null;
    if (this.props.ticketStatusList) {
      statusIdEnum = {
        cancelledRepair: this.props.ticketStatusList.filter((item) => {
          return item.status === "CANCELLED" && item.statusId === "4";
        }),
        cancelledMaintenance: this.props.ticketStatusList.filter((item) => {
          return item.status === "CANCELLED" && item.statusId === "3";
        }),
        inRepair: this.props.ticketStatusList.filter((item) => {
          return item.status === "IN-REPAIR";
        }),
        scheduled: this.props.ticketStatusList.filter((item) => {
          return item.status === "SCHEDULED";
        }),
        inProgress: this.props.ticketStatusList.filter((item) => {
          return item.status === "IN PROGRESS";
        }),
        completed: this.props.ticketStatusList.filter((item) => {
          return item.status === "COMPLETED";
        }),
        repaired: this.props.ticketStatusList.filter((item) => {
          return item.status === "REPAIRED";
        }),
        closed: this.props.ticketStatusList.filter((item) => {
          return item.status === "CLOSED";
        }),
      };
      if (status == "Cancel") {
        statuscode =
          this.props.ticketDetail.ticketType === "Repair"
            ? statusIdEnum.cancelledRepair[0].id
            : statusIdEnum.cancelledMaintenance[0].id;
        conditionId = 1;
        statusIdNumber = 3;
      } else if (status == "Start Repair") {
        statuscode = this.props.ticketDetail.ticketType === "Repair"
          ? statusIdEnum.inRepair[0].id
          : statusIdEnum.inProgress[0].id;
        conditionId = 2;
        statusIdNumber = 8;
      } else if (status == "Repaired") {
        statuscode = statusIdEnum.repaired[0].id;
        conditionId = 2;
        statusIdNumber = 8;
      } else {
        statuscode = statusIdEnum.closed[0].id;
        conditionId = 1;
        statusIdNumber = 3;
      }
      let payload = {
        tickets: [{ no: this.state.ticketDetail.ticketNo }],
        ticketType: this.props.ticketDetail.ticketType === "Repair" ? "R" : "M",
        statusId: statuscode,
        remarks: newRemark || this.props.ticketDetail.remarks,
        updatedDt: moment(),
      };
      this.props.putTicketDetail(this.state.id, payload);
    }
  };

  renderImageVideo(media1, link1) {
    return (
      <div>
        <Media media={media1} link={link1}></Media>
      </div>
    );
  }

  checkerOfImageOrVideo(media1) {
    if (media1 != undefined) {
      let check = media1.substr(media1.length - 3);
      if (check == "mp4") {
        return "video";
      } else {
        return "image";
      }
    }
  }

  renderRepairTicketDetail() {
    const { userAccess } = this.props;
    return (
      <div>
        <MuiThemeProvider theme={this.state.tooltipTheme}>
          <ConfirmCancel
            ticketNo={this.props.ticketDetail.ticketNo}
            open={this.state.openConfirmCanCel}
            onClose={() => this.setState({ openConfirmCanCel: false })}
            onSubmit={(newRemark) => {
              this.setState({ openConfirmCanCel: false }, () => {
                this.handleUpdateTicket("Cancel", newRemark);
              });
            }}
          />
          <Grid className="form-component" container spacing={1}>
            <FormControl fullWidth component="form">
              <Grid container>
                <Grid item xs={6}>
                  <div className="title-blue">
                    {this.props.ticketDetail
                      ? this.renderText(this.props.ticketDetail.ticketNo)
                      : null}
                  </div>
                </Grid>
                <Grid item xs={4} />
                <Grid item xs={2}>
                  <div
                    className={"ticket-status " + this.getTicketStatusClass()}
                  >
                    {this.props.ticketDetail
                      ? this.renderText(this.props.ticketDetail.status) ==
                        undefined
                        ? this.renderText(this.props.ticketDetail.status)
                        : this.renderText(
                            this.props.ticketDetail.status
                          ).translate()
                      : null}{" "}
                  </div>
                </Grid>
              </Grid>
              <Grid className="actions" container spacing={2}>
                <Grid item xs={6}>
                  <Grid container spacing={1}>
                    {
                      checkPageAccessField(userAccess, { page: 'cancel_ticket', pageAccess: 'FULL ACCESS' }) && (
                        <Grid item xs={3}>
                          <Button
                            disabled={(this.props.ticketDetail.status === 'CANCELLED')}
                            className={
                              (this.props.ticketDetail.status === 'CANCELLED') 
                                ? "ticket-action-item disabled" 
                                : "ticket-action-item"
                            }
                            onClick={() => this.setState({ openConfirmCanCel: true })}
                            fullWidth
                            color="primary"
                            variant="outlined"
                          >
                            {this.state.languageProps.CANCEL_TICKET}
                          </Button>
                        </Grid>
                      )
                    }
                    <Grid item xs={3}>
                      <Button
                        className={
                          this.props.ticketDetail
                            ? this.renderStartRepairClass(
                                this.props.ticketDetail.status
                              )
                            : "ticket-action-item"
                        }
                        disabled={
                          this.props.ticketDetail
                            ? this.renderStartRepairBoolean(
                                this.props.ticketDetail.status
                              )
                            : true
                        }
                        onClick={() => {
                          this.handleUpdateTicket("Start Repair");
                        }}
                        fullWidth
                        color="primary"
                        variant="outlined"
                      >
                        {this.state.languageProps.START_REPAIR}
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        className={
                          this.props.ticketDetail
                            ? this.renderRepairedClass(
                                this.props.ticketDetail.status
                              )
                            : "ticket-action-item"
                        }
                        disabled={
                          this.props.ticketDetail
                            ? this.renderRepairedBoolean(
                                this.props.ticketDetail.status
                              )
                            : true
                        }
                        onClick={() => {
                          this.handleUpdateTicket("Repaired");
                        }}
                        fullWidth
                        color="primary"
                        variant="outlined"
                      >
                        {this.state.languageProps.REPAIRED}
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        className={
                          this.props.ticketDetail
                            ? this.renderCloseClass(
                                this.props.ticketDetail.status
                              )
                            : "ticket-action-item"
                        }
                        disabled={
                          this.props.ticketDetail
                            ? this.renderCloseBoolean(
                                this.props.ticketDetail.status
                              )
                            : true
                        }
                        onClick={() => {
                          this.handleUpdateTicket("Close");
                        }}
                        fullWidth
                        color="primary"
                        variant="outlined"
                      >
                        {this.state.languageProps.CLOSE}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>

          <Grid className="form-component" container spacing={0}>
            <FormControl fullWidth component="fieldset">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormGroup>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={3}>
                        <span className="form-label required">
                          {this.state.languageProps.PROBLEM}
                        </span>
                      </Grid>
                      <Grid item xs={9}>
                        <Tooltip
                          placement="top-start"
                          title={
                            this.props.ticketDetail
                              ? this.renderProblemText(
                                  this.props.ticketDetail.problemType
                                )
                              : null
                          }
                        >
                          <span className="value-label text-ellipsis">
                            <strong>
                              {this.props.ticketDetail
                                ? this.renderProblemText(
                                    this.props.ticketDetail.problemType || "Problem unknown"
                                  )
                                : null}
                            </strong>
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={3}>
                        <span className="form-label">
                          {this.state.languageProps.SOLUTION}
                        </span>
                      </Grid>
                      <Grid item xs={9}>
                        <Tooltip
                          placement="top-start"
                          title={
                            this.props.ticketDetail
                              ? this.renderText(
                                  this.props.ticketDetail.solutionType
                                )
                              : null
                          }
                        >
                          <span className="value-label text-ellipsis">
                            <strong>
                              {this.props.ticketDetail
                                ? this.renderText(
                                    this.props.ticketDetail.solutionType
                                  )
                                : null}
                            </strong>
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={3}>
                        <span className="form-label">
                          {this.state.languageProps.REMARKS}
                        </span>
                      </Grid>
                      <Grid item xs={9}>
                        <Tooltip
                          placement="top-start"
                          title={
                            this.props.ticketDetail
                              ? this.renderText(this.props.ticketDetail.remarks)
                              : null
                          }
                        >
                          <span className="value-label text-ellipsis">
                            <strong>
                              {this.props.ticketDetail
                                ? this.renderText(
                                    this.props.ticketDetail.remarks
                                  )
                                : null}
                            </strong>
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <FormGroup>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.MACHINE_NO}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <Tooltip
                          placement="top-start"
                          title={
                            this.props.machineDetail
                              ? this.renderText(
                                  this.props.machineDetail.machine
                                )
                              : null
                          }
                        >
                          <span className="value-label text-ellipsis">
                            {this.props.machineDetail
                              ? this.renderText(
                                  this.props.machineDetail.machine
                                )
                              : null}
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.MODEL_NO}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <Tooltip
                          placement="top-start"
                          title={
                            this.props.machineDetail
                              ? this.renderText(this.props.machineDetail.model)
                              : null
                          }
                        >
                          <span className="value-label text-ellipsis">
                            {this.props.machineDetail
                              ? this.renderText(this.props.machineDetail.model)
                              : null}
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.MACHINE_TYPE}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.machineDetail
                            ? this.renderTextWithCapitalization(
                                this.props.machineDetail.macTypeId_desc
                              )
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          { this.state.languageProps.Supplier}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.machineDetail 
                            ? this.renderText(this.props.machineDetail.supplier_desc
                            ) 
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.DATE_OF_PURCHASE}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.machineDetail
                            ? formatDate(
                                this.props.machineDetail.aquisitionDt
                              )
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.RENTAL}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.machineDetail
                            ? this.renderCheckbox(
                                this.props.machineDetail.isRental
                              )
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Grid>
                <Grid item xs={3}>
                  <FormGroup>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.MACHINE_SUB_TYPE}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.machineDetail
                            ? this.renderText(
                                this.props.machineDetail.macSubTypeId_desc
                              )
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {"Reported Location"}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          <strong>
                          {this.props.ticketDetail
                              ? this.renderReportedLocation()
                              : null}
                          </strong>
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {"Current Location"}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          <strong>
                            {this.props.machineDetail
                              ? this.renderCurrentLocation()
                              : null}
                          </strong>
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.BRAND}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.machineDetail
                            ? this.renderTextWithCapitalization(
                                this.props.machineDetail.brandId_desc
                              )
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.MOTOR_TYPE}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.machineDetail
                            ? this.renderText(
                                this.props.machineDetail.motorTypeId_desc
                              )
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Grid>
                <Grid item xs={3}>
                  <FormGroup>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.SERIAL_NO}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <Tooltip
                          placement="top-start"
                          title={
                            this.props.machineDetail
                              ? this.renderText(
                                  this.props.machineDetail.serialNo
                                )
                              : null
                          }
                        >
                          <span className="value-label text-ellipsis">
                            {this.props.machineDetail
                              ? this.renderText(
                                  this.props.machineDetail.serialNo
                                )
                              : null}
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.ASSET_NO}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <Tooltip
                          placement="top-start"
                          title={
                            this.props.machineDetail
                              ? this.renderText(
                                  this.props.machineDetail.assetNo
                                )
                              : null
                          }
                        >
                          <span className="value-label text-ellipsis">
                            {this.props.machineDetail
                              ? this.renderText(
                                  this.props.machineDetail.assetNo
                                )
                              : null}
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.RFID}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <Tooltip
                          placement="top-start"
                          title={
                            this.props.machineDetail
                              ? this.renderText(this.props.machineDetail.rfid)
                              : null
                          }
                        >
                          <span className="value-label text-ellipsis">
                            {this.props.machineDetail
                              ? this.renderText(this.props.machineDetail.rfid)
                              : null}
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.ATTACHMENT}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.machineDetail
                            ? this.renderText(
                                this.props.machineDetail.attachmentId_desc
                              )
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                    {/* <Grid className="form-control" container spacing={0}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{ this.state.languageProps.CONDITION}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span className="value-label">{this.props.machineDetail ? this.renderText(this.props.machineDetail.conditionId_desc) : null}</span>
                                            </Grid>
                                        </Grid> */}
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.STATUS}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.machineDetail
                            ? this.renderText(
                                this.props.machineDetail.statusId_desc
                              )
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Grid>
                <Grid item xs={3}>
                  <FormGroup>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.MAINTENANCE_FREQ}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.machineDetail
                            ? `${this.props.machineDetail.frequencyQty} ${this.renderMaintenFreq(this.props.machineDetail.frequencyId_desc)}`
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.LAST_MAINTENANCE}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.machineDetail
                            ? formatDate(
                                this.props.machineDetail.lastPMDt
                              )
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.NEXT_MAINTENANCE}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.ticketDetail
                            ? formatDate(this.props.ticketDetail.nextMaintDate)
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.MAINTENANCE_STATUS}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        {this.props.ticketDetail &&
                          <span className={`value-label rounded-chip-${this.props.ticketDetail.maintenanceStatus === 'OVERDUE' ? 'danger' : 'success'}`}>
                            {this.props.ticketDetail.maintenanceStatus}
                          </span>
                        }
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.STATION}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.machineDetail
                            ? this.renderText(this.props.machineDetail.station)
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                    {/* <Grid className="form-control" container spacing={0}>
                                            <Grid item xs={6}>
                                                <span className="form-label">{ this.state.languageProps.ACTIVE}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span className="value-label">{this.props.machineDetail ? this.renderCheckbox(this.props.machineDetail.isActive) : null}</span>
                                            </Grid>
                                        </Grid> */}
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid className="extra-margin-top" container spacing={2}>
                <Grid item xs={3}>
                  <FormGroup>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.REPORTED_BY}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          <strong>
                            {this.props.ticketDetail
                              ? this.renderText(
                                  this.props.ticketDetail.reportedBy
                                )
                              : null}
                          </strong>
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.REPORTED_ON}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.ticketDetail
                            ? formatDate(
                                this.props.ticketDetail.reportedDt
                              )
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.REPORTED_ON_TIME}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.ticketDetail
                            ? formatDate(this.props.ticketDetail.reportedDt, 'HH:mm')
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.TOTAL_REPORTED_TIME} {`(min)`}
                        </span>
                        {/* REPORTED DURATION */}
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.ticketDetail
                            ? this.renderText(displayMinute(this.props.ticketDetail.reportedDuration))
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Grid>
                <Grid item xs={3}>
                  <FormGroup>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.GRABBED_BY}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          <strong>
                            {this.props.ticketDetail
                              ? this.renderText(
                                  this.props.ticketDetail.grabbedBy
                                )
                              : null}
                          </strong>
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.GRABBED_ON}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.ticketDetail
                            ? formatDate(this.props.ticketDetail.grabbedDt)
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.GRABBED_ON_TIME}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.ticketDetail
                            ? formatDate(this.props.ticketDetail.grabbedDt, 'HH:mm')
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.TOTAL_REPAIR_TIME} {`(min)`}
                        </span>{" "}
                        {/* IN REPAIRED TIME */}
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.ticketDetail
                            ? this.renderText(displayMinute(this.props.ticketDetail.inrepairDuration))
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Grid>
                <Grid item xs={3}>
                  <FormGroup>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.REPAIRED_BY}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          <strong>
                            {this.props.ticketDetail
                              ? this.renderText(
                                  this.props.ticketDetail.repairedBy
                                )
                              : null}
                          </strong>
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.REPAIRED_ON}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.ticketDetail
                            ? formatDate(
                                this.props.ticketDetail.repairedDt
                              )
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.REPAIRED_ON_TIME}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.ticketDetail
                            ? formatDate(
                                this.props.ticketDetail.repairedDt, 'HH:mm'
                              )
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.TOTAL_CLOSED_TIME} {`(min)`}
                        </span>
                        {/* CLOSED DURATION */}
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.ticketDetail
                            ? this.renderText(displayMinute(this.props.ticketDetail.closedDuration))
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Grid>
                <Grid item xs={3}>
                  <FormGroup>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.CLOSED_BY}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          <strong>
                            {this.props.ticketDetail
                              ? this.renderText(
                                  this.props.ticketDetail.closedBy
                                )
                              : null}
                          </strong>
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.CLOSED_ON}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.ticketDetail
                            ? formatDate(this.props.ticketDetail.closedDt)
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.CLOSED_ON_TIME}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.ticketDetail
                            ? formatDate(this.props.ticketDetail.closedDt, 'HH:mm')
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid className="form-control" container spacing={0}>
                      <Grid item xs={6}>
                        <span className="form-label">
                          {this.state.languageProps.TOTAL_MINUTES}
                        </span>{" "}
                        {/* TOTAL ELAPSED TIME */}
                      </Grid>
                      <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.ticketDetail
                            ? this.renderText(displayMinute(this.props.ticketDetail.elapsedDuration))
                            : null}
                        </span>
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Grid>
              </Grid>

              <Grid className="extra-margin-top" container spacing={0}>
                <Grid item xs={6}>
                  <FormGroup>
                    <Grid className="form-control" container spacing={1}>
                      {
                        this.state.assets.map(({ id, assetLink })=>(
                          <Grid key={id} item xs={3}>
                            {
                              this.renderImageVideo(
                                this.checkerOfImageOrVideo(assetLink),
                                assetLink
                              )
                            }
                          </Grid>
                        ))
                      }
                    </Grid>
                  </FormGroup>
                </Grid>
              </Grid>


            </FormControl>
          </Grid>
        </MuiThemeProvider>
      </div>
    );
  }

  openChecklistModal = () => {
    this.props.showModal("maintenanceChecklist");
  };

  renderMaintenanceTicketDetail() {
    const { userAccess } = this.props;
    return (
      <div>
        <Grid className="form-component" container spacing={1}>
          <FormControl fullWidth component="form">
            <Grid container>
              <Grid item xs={6}>
                <div className="title-blue">
                  {this.props.ticketDetail
                    ? this.renderText(this.props.ticketDetail.ticketNo)
                    : null}
                </div>
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={2}>
                <div className={"ticket-status " + this.getTicketStatusClass()}>
                  {this.props.ticketDetail
                    ? this.renderText(this.props.ticketDetail.status) ==
                      undefined
                      ? this.renderText(this.props.ticketDetail.status)
                      : this.renderText(
                          this.props.ticketDetail.status
                        ).translate()
                    : null}{" "}
                </div>
              </Grid>
            </Grid>
            <Grid className="actions" container spacing={0}>
              <Grid item xs={6}>
                <Grid container spacing={1}>

                    {
                      checkPageAccessField(userAccess, { page: 'cancel_ticket', pageAccess: 'FULL ACCESS' }) && (
                        <Grid item xs={3}>
                          <Button
                            className="ticket-action-item"
                            onClick={() => {
                              this.handleUpdateTicket("Cancel");
                            }}
                            fullWidth
                            color="primary"
                            variant="outlined"
                          >
                            {this.state.languageProps.CANCEL_TICKET}
                          </Button>
                        </Grid>
                      )
                    }

                  <Grid item xs={3}>
                    <Button
                      className={
                        this.props.ticketDetail
                          ? this.renderStartRepairClass(
                              this.props.ticketDetail.status
                            )
                          : "ticket-action-item disabled"
                      }
                      disabled={
                        this.props.ticketDetail
                          ? this.renderStartRepairBoolean(
                              this.props.ticketDetail.status
                            )
                          : true
                      }
                      onClick={() => {
                        this.handleUpdateTicket("Start Repair");
                      }}
                      fullWidth
                      color="primary"
                      variant="outlined"
                    >
                      {this.state.languageProps.START_REPAIR}
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      className={
                        this.props.ticketDetail
                          ? this.renderRepairedClass(
                              this.props.ticketDetail.status
                            )
                          : "ticket-action-item disabled"
                      }
                      disabled={
                        this.props.ticketDetail
                          ? this.renderRepairedBoolean(
                              this.props.ticketDetail.status
                            )
                          : true
                      }
                      onClick={() => {
                        this.handleUpdateTicket("Repaired");
                      }}
                      fullWidth
                      color="primary"
                      variant="outlined"
                    >
                      {this.state.languageProps.REPAIRED}
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      className={
                        this.props.ticketDetail
                          ? this.renderCloseClass(
                              this.props.ticketDetail.status
                            )
                          : "ticket-action-item disabled"
                      }
                      disabled={
                        this.props.ticketDetail
                          ? this.renderCloseBoolean(
                              this.props.ticketDetail.status
                            )
                          : true
                      }
                      onClick={() => {
                        this.handleUpdateTicket("Close");
                      }}
                      fullWidth
                      color="primary"
                      variant="outlined"
                    >
                      {this.state.languageProps.CLOSE}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
        <Grid className="form-component" container spacing={1}>
          <FormControl fullWidth component="form">
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <FormGroup>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span
                        onClick={this.openChecklistModal}
                        className="value-label clickable"
                      >
                        {this.state.languageProps.VIEW_CHECKLIST}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span></span>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label required">
                        {this.state.languageProps.REMARKS}
                      </span>
                    </Grid>
                    <Grid item xs={5}>
                      <Tooltip
                        placement="top-start"
                        title={
                          this.props.ticketDetail
                            ? this.renderText(this.props.ticketDetail.remarks)
                            : null
                        }
                      >
                        <div className="value-label text-ellipsis">
                          {this.props.ticketDetail
                            ? this.renderText(this.props.ticketDetail.remarks)
                            : null}
                        </div>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Grid>
              <Grid item xs={3}>
                <FormGroup>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label required">
                        {this.state.languageProps.CHECKLIST_NO}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        <strong>
                          {this.props.ticketDetail
                            ? this.renderText(
                                this.props.ticketDetail.checkListNo
                              )
                            : null}
                        </strong>
                      </span>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label required">
                        {this.state.languageProps.MAINTENANCE_DATE}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        <strong>
                          {this.props.ticketDetail
                            ? formatDate(
                                this.props.ticketDetail.pmPlanDt
                              )
                            : ""}
                        </strong>
                      </span>
                    </Grid>
                  </Grid>
                  {/* <Grid className="form-control" container spacing={0}>
                                        <Grid item xs={6}>
                                            <span className="form-label required">{this.state.languageProps.MAINTENANCE_SUB_TYPE}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <span className="value-label"><strong>{this.props.ticketDetail ? this.renderText(this.props.ticketDetail.tempType) : null}</strong></span>
                                        </Grid>
                                    </Grid> */}
                </FormGroup>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
        <Grid className="form-component" container spacing={1}>
          <FormControl fullWidth component="form">
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <FormGroup>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.MACHINE_NO}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <Tooltip
                        placement="top-start"
                        title={
                          this.props.machineDetail
                            ? this.renderText(this.props.machineDetail.machine)
                            : null
                        }
                      >
                        <span className="value-label text-ellipsis">
                          {this.props.machineDetail
                            ? this.renderText(this.props.machineDetail.machine)
                            : null}
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.MODEL_NO}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <Tooltip
                        placement="top-start"
                        title={
                          this.props.machineDetail
                            ? this.renderText(this.props.machineDetail.model)
                            : null
                        }
                      >
                        <span className="value-label text-ellipsis">
                          {this.props.machineDetail
                            ? this.renderText(this.props.machineDetail.model)
                            : null}
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.MACHINE_TYPE}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        {this.props.machineDetail
                          ? this.renderTextWithCapitalization(
                              this.props.machineDetail.macTypeId_desc
                            )
                          : null}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        { this.state.languageProps.Supplier}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                        <span className="value-label">
                          {this.props.machineDetail 
                            ? this.renderText(this.props.machineDetail.supplier_desc
                              ) 
                            : null}
                        </span>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.DATE_OF_PURCHASE}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        {this.props.machineDetail
                          ? formatDate(
                              this.props.machineDetail.aquisitionDt
                            )
                          : null}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.RENTAL}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        {this.props.machineDetail
                          ? this.renderCheckbox(
                              this.props.machineDetail.isRental
                            )
                          : null}
                      </span>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Grid>
              <Grid item xs={3}>
                <FormGroup>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.MACHINE_SUBTYPE}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        {this.props.machineDetail
                          ? this.renderText(
                              this.props.machineDetail.macSubTypeId_desc
                            )
                          : null}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {"Reported Location"}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        <strong>
                          {this.props.ticketDetail ? this.renderReportedLocation() : null}
                        </strong>
                      </span>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {"Current Location"}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        <strong>
                          {this.props.machineDetail ? this.renderCurrentLocation() : null}
                        </strong>
                      </span>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.BRAND}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        {this.props.machineDetail
                          ? this.renderTextWithCapitalization(
                              this.props.machineDetail.brandId_desc
                            )
                          : null}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.MOTOR_TYPE}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        {this.props.machineDetail
                          ? this.renderText(
                              this.props.machineDetail.motorTypeId_desc
                            )
                          : null}
                      </span>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Grid>
              <Grid item xs={3}>
                <FormGroup>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.SERIAL_NO}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <Tooltip
                        placement="top-start"
                        title={
                          this.props.machineDetail
                            ? this.renderText(this.props.machineDetail.serialNo)
                            : null
                        }
                      >
                        <span className="value-label text-ellipsis">
                          {this.props.machineDetail
                            ? this.renderText(this.props.machineDetail.serialNo)
                            : null}
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.ASSET_NO}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <Tooltip
                        placement="top-start"
                        title={
                          this.props.machineDetail
                            ? this.renderText(this.props.machineDetail.assetNo)
                            : null
                        }
                      >
                        <span className="value-label text-ellipsis">
                          {this.props.machineDetail
                            ? this.renderText(this.props.machineDetail.assetNo)
                            : null}
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.RFID}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <Tooltip
                        placement="top-start"
                        title={
                          this.props.machineDetail
                            ? this.renderText(this.props.machineDetail.rfid)
                            : null
                        }
                      >
                        <span className="value-label text-ellipsis">
                          {this.props.machineDetail
                            ? this.renderText(this.props.machineDetail.rfid)
                            : null}
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.ATTACHMENT}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        {this.props.machineDetail
                          ? this.renderText(
                              this.props.machineDetail.attachmentId_desc
                            )
                          : null}
                      </span>
                    </Grid>
                  </Grid>
                  {/* <Grid className="form-control" container spacing={0}>
                                        <Grid item xs={6}>
                                            <span className="form-label">{ this.state.languageProps.CONDITION}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <span className="value-label">{this.props.machineDetail ? this.props.machineDetail.conditionId_desc : null}</span>
                                        </Grid>
                                    </Grid> */}
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.STATUS}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        {this.props.machineDetail
                          ? this.props.machineDetail.statusId_desc
                          : null}
                      </span>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Grid>
              <Grid item xs={3}>
                <FormGroup>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.MAINTENANCE_FREQ}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                      {this.props.machineDetail
                            ? `${this.props.machineDetail.frequencyQty} ${this.renderMaintenFreq(this.props.machineDetail.frequencyId_desc)}`
                            : null}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.LAST_MAINTENANCE}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        {this.props.machineDetail
                          ? formatDate(
                              this.props.machineDetail.lastPMDt
                            )
                          : null}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.NEXT_MAINTENANCE}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        {this.props.machineDetail
                          ? formatDate(
                              this.props.ticketDetail.pmPlanDt
                            )
                          : null}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.STATION}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        {this.props.machineDetail
                          ? this.renderText(this.props.machineDetail.station)
                          : null}
                      </span>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
        <Grid className="form-component" container spacing={1}>
          <FormControl fullWidth component="form">
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <FormGroup>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.GENERATE_BY}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        <strong>
                          {this.props.ticketDetail
                            ? this.renderText(
                                this.props.ticketDetail.reportedBy
                              )
                            : null}
                        </strong>
                      </span>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.GENERATE_ON}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        {this.props.ticketDetail
                          ? formatDate(this.props.ticketDetail.reportedDt)
                          : null}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.GENERATE_ON_TIME}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        {this.props.ticketDetail
                          ? formatDate(this.props.ticketDetail.reportedDt, 'HH:mm')
                          : null}
                      </span>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Grid>
              <Grid item xs={3}>
                <FormGroup>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.GRABBED_BY}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        <strong>
                          {this.props.ticketDetail
                            ? this.renderText(this.props.ticketDetail.grabbedBy)
                            : null}
                        </strong>
                      </span>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.GRABBED_ON}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        {this.props.ticketDetail
                          ? formatDate(this.props.ticketDetail.grabbedDt)
                          : null}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.GRABBED_ON_TIME}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        {this.props.ticketDetail
                          ? formatDate(this.props.ticketDetail.grabbedDt, 'HH:mm')
                          : null}
                      </span>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Grid>
              <Grid item xs={3}>
                <FormGroup>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {'Completed by'.translate()}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        <strong>
                          {this.props.ticketDetail
                            ? this.renderText(this.props.ticketDetail.closedBy)
                            : null}
                        </strong>
                      </span>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {'Completed on'.translate()}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        {this.props.ticketDetail
                          ? formatDate(this.props.ticketDetail.closedDt)
                          : null}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {'Completed on time'.translate()}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        {this.props.ticketDetail
                          ? formatDate(this.props.ticketDetail.closedDt, 'HH:mm')
                          : null}
                      </span>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Grid>
              <Grid item xs={3}>
                <FormGroup>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.CLOSED_BY}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        <strong>
                          {this.props.ticketDetail
                            ? this.renderText(null)
                            : null}
                        </strong>
                      </span>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.CLOSED_ON}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        {this.props.ticketDetail ? formatDate(null) : null}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid className="form-control" container spacing={0}>
                    <Grid item xs={6}>
                      <span className="form-label">
                        {this.state.languageProps.CLOSED_ON_TIME}
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="value-label">
                        {this.props.ticketDetail ? formatDate(null) : null}
                      </span>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
      </div>
    );
  }

  content() {
    if (true) {
      return (
        <div>
          {this.props.ticketDetail.ticketType === "Repair"
            ? this.renderRepairTicketDetail()
            : this.renderMaintenanceTicketDetail()}
        </div>
      );
    } else {
      return null;
    }
  }

  getBackLink() {
    if (localStorage.getItem("redirectToMachineDetails") === "true") {
      return this.props.machineDetail
        ? "/machine-detail/" + this.props.machineDetail.id
        : "/tickets";
    } else if (localStorage.getItem("redirectToTickets") === "true") {
      return "/tickets";
    } else if (localStorage.getItem("redirectToMaintenance") === "true") {
      return "/maintenance";
    } else if (localStorage.getItem("redirectToCalendar") === "true") {
      return "/maintenance-calendar";
    } else {
      return "/tickets";
    }
  }

  render() {
    return (
      <MainPage className="ticket-detail">
        <MainPageHeader
          title="Tickets"
          search={false}
          back={this.getBackLink()}
        />
        <MainPageBody>{this.content()}</MainPageBody>{" "}
        <MaintenanceChecklist
          title={
            this.props.ticketDetail ? this.props.ticketDetail.checklistNo : null
          }
        />
      </MainPage>
    );
  }
}
const mapStateToProps = (state) => {
  let { translationReducer } = state;

  let apiLoading = {
    machinesDetailLoading: state.machineListReducer.viewDetailLoading,
    ticketDetailLoading: state.ticketListReducer.loadingDetail,
    machinesLoading: state.machineListReducer.loading,
    problemLoading: state.problemListReducer.loading,
    ticketTypeLoading: state.ticketListReducer.loadingType,
  };
  let data = {
    userAccess: state.authReducer.access,
    machineDetail: state.machineListReducer.machineDetail,
    ticketDetail: state.ticketListReducer.ticketDetail,
    checklistDetail: state.ticketListReducer.checklistDetail,
    assetDetail: state.ticketListReducer.assetDetail,
    ticketStatusList: state.statusListReducer.ticketStatusList,
    machineChecklist: state.machineListReducer.machineChecklist,
    machineTemplate: state.machineListReducer.machineTemplate,
    ticketTypeList: state.ticketListReducer.ticketTypes,
    problemList: state.problemListReducer.problemList,
  };
  return {
    ...apiLoading,
    ...data,
    ...translationReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllTicketsBySearch: (
      pageSize,
      currentPage,
      searchString,
      reportedDt,
      ticketType,
      status
    ) =>
      dispatch(
        getAllTicketsBySearch(
          pageSize,
          currentPage,
          searchString,
          reportedDt,
          ticketType,
          status
        )
      ),
    getTicketDetail: (id) => dispatch(getTicketDetail(id)),
    putTicketDetail: (ticketId, payload) =>
      dispatch(putTicketDetail(ticketId, payload)),
    putUpdateMachine: (id, payload) => dispatch(putUpdateMachine(id, payload)),
    getProblems: () => dispatch(getProblems()),
    getTicketTypes: () => dispatch(getTicketTypes()),
    getRepairTicketStatus: () => dispatch(getRepairTicketStatus()),
    getMaintenanceTicketStatus: () => dispatch(getMaintenanceTicketStatus()),
    postNewTicket: (payload) => dispatch(postNewTicket(payload)),
    notificationError: (message) => dispatch(notificationError(message)),
    showModal: (modal) => dispatch(showModal(modal)),
  };
};

TicketDetails = connect(mapStateToProps, mapDispatchToProps)(TicketDetails);

export default TicketDetails;
