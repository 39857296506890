import React from 'react';
import moment from 'moment';
import ToolIcon from '../../../../../assets/img/tool.PNG';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { validateFields } from './FormValidation';
import { validateProblemFields } from '../FormValidation';
import FormControl from '@material-ui/core/FormControl';
import { notificationError } from '../../../../../redux/actions/Notification/notificationActions';
import { getProblemDetail, postProblemNew, putProblemUpdate, getProblemsTreeView, deleteProblem, problemTriggerHighlight } from '../../../../../redux/actions/Problem/problemActions';
import { getSolutionDetail, postSolutionNew, putSolutionUpdate, deleteSolution } from '../../../../../redux/actions/Solution/solutionActions';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import MainPage from '../../../../../components/MainPage/MainPage';
import MainPageHeader from '../../../../../components/MainPageHeader/MainPageHeader';
import MainPageBody from '../../../../../components/MainPageBody/MainPageBody';
import { getFactoryId, checkPageAccessField } from '../../../../../utils/helper.js';
import history from '../../../../../history';
import Table from '../../../../../components/CollapsableTable/CollapseTable'
import dictionary from '../../../../../redux/actions/Translation/dictionary'
import './SolutionType.scss';
import { formatDate } from '../../../../../utils/date';

class SolutionType extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            translations: {
                ...this.convert()
            },
            translationTimestamp: undefined,
            modalSettings: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            modalContainerSettings: {
                width: '360px',
                backgroundColor: '#ffffff',
                outline: 'none',
                boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.3)',
                padding: '2px 4px 3px',
                problemConfig: {
                    padding: "30px 15px 30px 15px"
                },
                solutionConfig: {
                    padding: "30px 15px 30px 15px"
                },
                title: {
                    fontSize: '1.4rem',
                    marginBottom: '15px'
                },
                controlRow: {
                    marginBottom: '10px'
                },
                checkboxLabel: {
                    position: 'relative',
                    top: '5px',
                    fontSize: '1.2rem',
                },
                footerActions: {
                    textAlign: 'center',
                    width: '100%',
                    padding: '10px',
                    cancel: {
                        fontSize: '1.2rem',
                        borderRadius: '4px !important',
                    },
                    save: {
                        fontSize: '1.2rem',
                        background: '#1689FA',
                        color: '#fff',
                        borderRadius: '4px !important',
                    }
                }
            },
            factoryId: null,
            solutionId: null,
            detailLoaded: false,
            hasFormChanges: false,
            disablePrompt: false,
            id: null,
            form: {
                solutionType: null,
                desc1: "",
                desc2: null,
                isActive: null,
                applyToAll: 0,
                ticketTypeId: 1,
                macTypeId: null,
                macSubTypeId: null,
            },
            problemForm: {
                problemType: null,
                desc1: "",
                isActive: null,
                ticketTypeId: 1,
            },
            formErrors: [],
            modalTitle: null,
            saveLabel: null,
            closedModalFromAction: false,
            solutionModalOpen: false,
            problemModalOpen: false,
            tableState: {
                edit: ({ details: { id } }) => {
                    this.problemModalOpen(id);
                },
                remove: ({ details: { id } }) => {
                    confirmAlert({
                        title: this.state?.translations?.are_you_sure_delete_problem,
                        message: '',
                        buttons: [
                            {
                                label: this.state?.translations?.YES,
                                onClick: () => {
                                    this.props.deleteProblem(id)
                                }
                            },
                            {
                                label: this.state?.translations?.NO,
                            }
                        ],
                    });
                },
                child_remove: (ev) => {
                    let solutionTypeId = ev?.['details']?.id ?? ev?.id;
                    console.log('dummy', ev?.['details']?.id ?? ev?.id);
                    confirmAlert({
                        title: this.state?.translations?.are_you_sure_delete_solution,
                        message: '',
                        buttons: [
                            {
                                label: this.state?.translations?.YES,
                                onClick: () => {
                                    this.props.deleteSolution(solutionTypeId, this.props.match.params.id);
                                }
                            },
                            {
                                label: this.state?.translations?.NO,
                            }
                        ]
                    });

                },
                child_edit: (ev) => {
                    let solutionTypeId = ev?.['details']?.id ?? ev?.id;
                    console.log(ev);
                    this.props.getSolutionDetail(solutionTypeId);
                    this.setState({ solutionModalOpen: true, saveLabel: this.state?.translations?.SAVE, solutionId: solutionTypeId });
                    console.log('dummy', ev?.['details']?.id ?? ev?.id)
                },
                // row_click: ({ details: { id } }) => {
                //     history.push(`/configuration/solution-type/${id}`)
                // },
                tb_add: ({ details: { id, isActive } }) => {
                    if (isActive === false){
                        this.props.notificationError('Problem Type is Inactive. Cannot add Solution.');
                    }
                    else{
                        this.setState({
                            form: {
                                solutionType: null,
                                desc1: "",
                                desc2: null,
                                isActive: null,
                                applyToAll: 0,
                                ticketTypeId: 1,
                                macTypeId: null,
                                macSubTypeId: null,
                            },
                            solutionModalOpen: true,
                            saveLabel: this.state?.translations?.ADD,
                            solutionId: null
                        });
                        console.log('dummy', id)
                    }
                },
                addToolTip: this.convert().ADD_SOLUTION,
                search: false,
                parentCollapse: true,
                keys: this.initTableHeader(),
                data: [],
                loading: false,
                collapsable: true,
                levelInstance: {
                    parent: 'details',
                    child: [
                        'solutions'
                    ]
                },
                timeStamp: undefined
            }

        }
    }
    /**handler */
    initTableHeader = () => {
        let { STATUS, NAME, TYPE, DESCRIPTION } = this.convert()
        return [
            {
                name: NAME,
                childKey: 'solutionType',
                key: 'problemType',
                headerWidth: '26',
                width: '21.9'
            },
            {
                name: TYPE,
                childKey: '',
                childDefaultValue: 'Solution',
                keyDefaultValue: 'Problem',
                key: '',
                headerWidth: '9.4',
                width: '9.9'
            },
            // {
            //     name: 'Apply To All',
            //     childKey: 'applyToAll',
            //     booleanKey: ['Yes', 'No'],
            //     type: 'boolean',
            //     key: '',
            //     headerWidth: '16.2',
            //     width: '16.5'
            // },
            {
                name: DESCRIPTION,
                childKey: 'desc1',
                key: 'desc1',
                headerWidth: '20.65',
                width: '20.8'
            },
            {
                name: STATUS,
                type: 'boolean',
                key: 'isActive',
                childKey: 'isActive',
                headerWidth: '7.8',
                width: '8'
            },
            // {
            //     name: 'Updated Date',
            //     type: 'date',
            //     key: 'updatedDt',
            //     childkey: 'updatedDt',
            //     alt: 'createdDt'
            // },
        ]
    }
    convert = () => {
        let {
            ADD,
            YES,
            NO, SAVE, CANCEL,
            EDIT_SOLUTION, ADD_SOLUTION_LABEL,
            APPLY_TO_ALL_REPAIR_TYPES,
            TOOLTIPS: { ADD_SOLUTION },
            Active, Inactive,
            NOTIFICATIONS: { are_you_sure_delete_solution, are_you_sure_delete_problem, you_are_applying_all_problems ,problem_name_exists},
            STATUS, NAME, TYPE, DESCRIPTION,
            UPDATED_BY, UPDATED_DATE, CREATED_BY, CREATED_DATE, EDIT_REPAIR_TYPE,
        } = dictionary.GET()

        return dictionary.TRANSLATE({
            ADD, YES, NO, SAVE, CANCEL,
            APPLY_TO_ALL_REPAIR_TYPES,
            ADD_SOLUTION, are_you_sure_delete_solution,
            EDIT_SOLUTION, ADD_SOLUTION_LABEL,
            are_you_sure_delete_problem, you_are_applying_all_problems,problem_name_exists,
            Active, Inactive,
            STATUS, NAME, TYPE, DESCRIPTION,
            UPDATED_BY, UPDATED_DATE, CREATED_BY, CREATED_DATE, EDIT_REPAIR_TYPE
        })
    }

    handleFormChange = ({ target }) => {
        let tmpForm = this.state.form;
        tmpForm[target.name] = target.value;
        this.setState({ form: tmpForm });
        this.removeErrorOnChange(target.name);
    };

    handleFormCheckboxChange = ({ target }) => {
        let tmpForm = this.state.form;
        let value = 0;
        if (target.checked) {
            value = 1;
        }
        tmpForm[target.name] = value;
        this.setState({ form: tmpForm });
        this.removeErrorOnChange(target.name);
    };

    handleRadioButtonChange = ({ target }) => {
        let tmpForm = this.state.form;
        tmpForm[target.name] = parseInt(target.value);
        this.setState({ form: tmpForm });
        this.removeErrorOnChange(target.name);
    };



    handleProblemFormChange = ({ target }) => {
        let tmpForm = this.state.problemForm;
        tmpForm[target.name] = target.value;
        this.setState({ problemForm: tmpForm });
        this.removeErrorOnChange(target.name);
    };

    handleProblemRadioButtonChange = ({ target }) => {
        let tmpForm = this.state.problemForm;
        tmpForm[target.name] = parseInt(target.value);
        this.setState({ problemForm: tmpForm });
        this.removeErrorOnChange(target.name);
    };

    removeErrorOnChange(field) {
        let errors = this.state.formErrors.filter((item) => { return item.name !== field });
        this.setState({ formErrors: errors });
    }

    hasErrors = (field) => {
        let checkField = this.state.formErrors.filter((item) => { return item.name === field });
        if (checkField.length > 0) {
            return true
        }
        else {
            return false;
        }
    }

    getErrors = (field) => {
        let checkField = this.state.formErrors.filter((item) => { return item.name === field });
        let errorString = null;
        checkField.map((item) => {
            errorString += item.message + '. ';
            return '';
        })
        return errorString;
    }

    componentDidMount() {
        let factoryId = getFactoryId();
        if (this.props.match.params.id) {
            let id = this.props.match.params.id
            this.props.getProblemDetail(id);
            this.props.getProblemsTreeView(id);
            this.setState({ factoryId: factoryId, id: this.props.match.params.id });
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.solutionDetail !== null && nextProps.solutionDetail !== undefined && this.props.match.params.id !== null) {
            let tmpForm = {
                solutionType: nextProps.solutionDetail.solutionType,
                desc1: nextProps.solutionDetail.desc1,
                isActive: this.renderBooleanAsInteger(nextProps.solutionDetail.isActive),
                ticketTypeId: 1,
                applyToAll: this.renderBooleanAsInteger(nextProps.solutionDetail.applyToAll)
            }
            this.setState({ form: tmpForm })
        }
        if (nextProps.problemDetail !== null && nextProps.problemDetail !== undefined && this.props.match.params.id !== null) {

            let problemForm = {
                problemType: nextProps.problemDetail.problemType,
                desc1: nextProps.problemDetail.desc1,
                isActive: this.renderBooleanAsInteger(nextProps.problemDetail.isActive),
                ticketTypeId: 1,
            }
            this.setState({ problemForm: problemForm })
        }
    }

    componentDidUpdate() {
        // console.log('didUpdate', this.props, this.state)
        let { loading, problemListTreeById, problemListTreeByIdTimeStamp } = this.props
        let { loading: stateLoading, data, timeStamp } = this.state.tableState
        if (loading != stateLoading || problemListTreeById?.length != data?.length && problemListTreeById != undefined || problemListTreeByIdTimeStamp != timeStamp) {
            if (Array.isArray(problemListTreeById)) {
                let tableState = this.state.tableState;
                if (checkPageAccessField(this.props.userAccess,{pageId:23,pageAccess: 'VIEW'})){
                    tableState = {
                        add: null,
                        edit: null,
                        remove: null,
                        child_edit: null,
                        child_remove: null,
                        tb_add: null,
                    }
                }
                this.setState({
                    tableState: {
                        ...tableState,
                        data: [...problemListTreeById],
                        loading,
                        timeStamp: problemListTreeByIdTimeStamp
                    }
                })
            }
            // this.forceUpdate()
            // console.log(problemListTreeById, loading, stateLoading, data )
        }
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                translations: Object.assign({}, { ...this.convert() }),
                tableState: {
                    ...this.state.tableState,
                    keys: this.initTableHeader(),
                    timeStamp: new Date(),
                    addToolTip: this.convert().ADD_SOLUTION
                }
            })
        }
    }

    clearForm() {
        let tmpForm = {
            solutionType: null,
            desc1: "",
            isActive: null,
            applyToAll: 0,
            ticketTypeId: 1,
        }
        let problemForm = {
            problemType: null,
            desc1: "",
            isActive: null,
            ticketTypeId: 1,
            macTypeId: null,
            macSubTypeId: null
        }
        this.setState({ form: tmpForm, problemForm: problemForm, formErrors: [] });
    }



    problemModalOpen = (id = null) => {
        console.log('id', id)
        if (id !== null) {
            this.props.getProblemDetail(id);
            this.setState({ problemModalOpen: true, saveLabel: this.state.translations?.SAVE, id: id });
        }
        else {
            this.setState({ problemModalOpen: true, saveLabel: this.state.translations?.ADD, id: null, detailLoaded: false });
        }
    }

    problemAddModalOpen = () => {
        this.problemModalOpen(null);
    }

    problemUpdateModalOpen = (id) => {
        this.problemModalOpen(id);
    }

    problemModalClose = () => {
        if (this.state.hasFormChanges === true && this.state.disablePrompt === false && this.state.id !== null) {
            var flag = window.confirm('Are you sure to leave this page?');
            if (flag) {
                this.setState({ problemModalOpen: false, hasFormChanges: false, disablePrompt: false, detailLoaded: false, id: null });
                this.clearForm();
            }
        }
        else {
            this.setState({ problemModalOpen: false, hasFormChanges: false, disablePrompt: false, detailLoaded: false, id: null });
            this.clearForm();
        }
    }

    solutionModalClose = () => {
        this.setState({ solutionModalOpen: false });
        this.clearForm();
    }

    submitForm = () => {
        let payload = {
            ...this.state.form,
            factoryId: this.state.factoryId,
            problemTypeId: this.props.match.params.id,
        };
        let formValidation = validateFields(payload);
        this.setState({ formErrors: formValidation.errors });
        if (formValidation.valid) {

            if (this.state.solutionId === null) {
                payload = {
                    ...payload,
                    isActive: 1
                }
                delete payload.isActive;
                this.setState({ closedModalFromAction: false }, () => {
                    this.props.postSolutionNew(payload);

                })
            }
            else {
                payload = {
                    ...payload,
                }
                this.setState({ closedModalFromAction: false }, () => {
                    this.props.putSolutionUpdate(this.state.solutionId, payload)
                })
            }
        }
        else {
            this.props.notificationError(formValidation.errors[0].message);
        }
    }

    submitProblemForm = () => {
        let payload = {
            ...this.state.problemForm,
            factoryId: this.state.factoryId
        };
        let formValidation = validateProblemFields(payload);
        this.setState({ formErrors: formValidation.errors });
        if (formValidation.valid) {
            this.setState({ disablePrompt: true }, () => {
                if (this.props.match.params.id === null) {
                    payload = {
                        ...payload,
                        isActive: 1
                    }
                    this.setState({ closedModalFromAction: false }, () => {
                        this.props.postProblemNew(this.props.match.params.id, payload);
                        this.props.getProblemsTreeView();
                        // this.clearForm();
                    })
                }
                else {
                    this.setState({ closedModalFromAction: false }, () => {
                        this.props.putProblemUpdate(this.props.match.params.id, payload);
                        this.props.getProblemsTreeView();
                        // this.clearForm();
                    })
                }
            })
        }
        else {
            this.props.notificationError(formValidation.errors[0].message);
        }
    }

    triggerCloseModal() {
        this.solutionModalClose();
        this.problemModalClose();
        this.setState({ closedModalFromAction: true });
    }



    renderText(field) {
        if (field === null || field === '') {
            return '-'
        }
        else {
            //   return field != undefined ? field.toString().translate() : field
            return field
        }
    }

    renderDate(date) {
        if (date !== null) {
            let newDate = date.substring(0, date.indexOf('T'));
            return moment(newDate).format('DD-MM-YYYY');
        }
        else {
            return '-';
        }
    }

    renderBooleanAsInteger(bool) {
        if (bool === true || bool === "True") {
            return 1
        }
        else {
            return 0
        }
    }



    renderProblemStatusForm() {
        return (
            <div>
                <div className="space"></div>
                <div className="form-input">
                    <Grid container spacing={0}>
                        <Grid item xs={5}>
                            <FormLabel className="status" component="legend">{this.state?.translations?.STATUS}</FormLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <FormControl className="control-row" fullWidth>
                                <RadioGroup onChange={this.handleProblemRadioButtonChange} name="isActive" className="radioButton" row value={this.state.problemForm.isActive}>
                                    <FormControlLabel value={1} control={<Radio color="primary" />} label={this.state?.translations?.Active} />
                                    <FormControlLabel value={0} control={<Radio color="primary" />} label={this.state?.translations?.Inactive} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid className="statusDetails" item xs={12}>
                            <FormLabel component="legend">{this.state?.translations?.UPDATED_BY}: {this.props.problemDetail ? this.renderText(this.props.problemDetail.updatedBy) : null}</FormLabel>
                        </Grid>
                        <Grid className="statusDetails" item xs={12}>
                            <FormLabel component="legend">{this.state?.translations?.UPDATED_DATE}: {this.props.problemDetail ? formatDate(this.props.problemDetail.updatedDt) : null}</FormLabel>
                        </Grid>
                        <Grid className="statusDetails" item xs={12}>
                            <FormLabel component="legend">{this.state?.translations?.CREATED_BY}: {this.props.problemDetail ? this.renderText(this.props.problemDetail.createdBy) : null}</FormLabel>
                        </Grid>
                        <Grid className="statusDetails" item xs={12}>
                            <FormLabel component="legend">{this.state?.translations?.CREATED_DATE}: {this.props.problemDetail ? formatDate(this.props.problemDetail.createdDt) : null}</FormLabel>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    renderProblemTypeModal() {
        return (
            <div>
                {this.props.problemCloseModal && this.state.closedModalFromAction === false ? this.triggerCloseModal() : null}
                <Modal
                    className="repair-type-modal"
                    open={this.state.problemModalOpen}
                    onClose={this.state.problemModalClose}
                    style={this.state.modalSettings}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500
                    }}
                >
                    <Fade style={this.state.modalContainerSettings} in={this.state.problemModalOpen}>
                        <div style={this.state.modalContainerSettings.problemConfig}>
                            <div style={this.state.modalContainerSettings.title}>{this.props.match.params.id ? "Edit Problem".translate() : "Add Problem".translate()}</div>
                            <Grid container spacing={0}>
                                <Grid item xs>
                                    <FormControl style={this.state.modalContainerSettings.controlRow} fullWidth>
                                        <TextField className={this.state.problemForm.problemType ? 'label-green' : null} required error={this.hasErrors('problemType')} value={this.state.problemForm.problemType} onChange={this.handleProblemFormChange} name="problemType" placeholder="" label={this.state?.translations?.NAME} InputLabelProps={{ shrink: true }} />
                                    </FormControl>
                                    <FormControl style={this.state.modalContainerSettings.controlRow} fullWidth>
                                        <TextField className={this.state.problemForm.desc1 ? 'label-green' : null} error={this.hasErrors('desc1')} value={this.state.problemForm.desc1} onChange={this.handleProblemFormChange} name="desc1" placeholder="" label={this.state?.translations?.DESCRIPTION} InputLabelProps={{ shrink: true }} />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {this.props.match.params.id ? this.renderProblemStatusForm() : null}
                            <div style={this.state.modalContainerSettings.footerActions}>
                                <Grid container spacing={1}>
                                    <Grid item xs={2} />
                                    <Grid item xs={4}>
                                        <Button onClick={this.problemModalClose} style={this.state.modalContainerSettings.footerActions.cancel} fullWidth variant="outlined">{this.state?.translations?.CANCEL}</Button>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button onClick={this.submitProblemForm} style={this.state.modalContainerSettings.footerActions.save} fullWidth color="primary" variant="contained">{this.state.saveLabel}</Button>
                                    </Grid>
                                    <Grid item xs={2} />
                                </Grid>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div>
        )
    }

    setBeforeUnload = () => {
        if (this.state.hasFormChanges) {
            window.onbeforeunload = () => { return 'Are you sure to leave this page?' }
        } else {
            window.onbeforeunload = undefined
        }
    }

    componentWillUnmount() {
        if (this.state.hasFormChanges) {
            window.onbeforeunload = undefined
        }
    }

    renderStatusForm() {
        return (
            <div>
                <div className="space"></div>
                <div className="form-input">
                    <Grid container spacing={0}>
                        <Grid item xs={5}>
                            <FormLabel className="status" component="legend">{this.state?.translations?.STATUS}</FormLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <FormControl className="control-row" fullWidth>
                                <RadioGroup onChange={this.handleRadioButtonChange} name="isActive" className="radioButton" row value={this.state.form.isActive}>
                                    <FormControlLabel value={1} control={<Radio color="primary" />} label={this.state?.translations?.Active} />
                                    <FormControlLabel value={0} control={<Radio color="primary" />} label={this.state?.translations?.Inactive} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid className="statusDetails" item xs={12}>
                            <FormLabel component="legend">{this.state?.translations?.UPDATED_BY}: {this.props.solutionDetail ? this.renderText(this.props.solutionDetail.updatedBy) : null}</FormLabel>
                        </Grid>
                        <Grid className="statusDetails" item xs={12}>
                            <FormLabel component="legend">{this.state?.translations?.UPDATED_DATE}: {this.props.solutionDetail ? formatDate(this.props.solutionDetail.updatedDt) : null}</FormLabel>
                        </Grid>
                        <Grid className="statusDetails" item xs={12}>
                            <FormLabel component="legend">{this.state?.translations?.CREATED_BY}: {this.props.solutionDetail ? this.renderText(this.props.solutionDetail.createdBy) : null}</FormLabel>
                        </Grid>
                        <Grid className="statusDetails" item xs={12}>
                            <FormLabel component="legend">{this.state?.translations?.CREATED_DATE}: {this.props.solutionDetail ? formatDate(this.props.solutionDetail.createdDt) : null}</FormLabel>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    applyToAllCheckbox() {
        if (this.state.solutionId === null) {
            return (
                <Grid className="form-control" container spacing={1}>
                    <Grid item xs={1}>
                        <FormControl style={this.state.modalContainerSettings.controlRow} fullWidth>
                            <Checkbox color="primary" error={this.hasErrors('applyToAll')} value={this.state.form.applyToAll} onChange={this.handleFormCheckboxChange} name="applyToAll" placeholder="" label="Description" InputLabelProps={{ shrink: true }} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={7}>
                        <span style={this.state.modalContainerSettings.checkboxLabel}>{this.state?.translations?.APPLY_TO_ALL_REPAIR_TYPES}</span>
                    </Grid>
                </Grid>
            );
        }
        else {
            return ''
        }
    }

    handleSolutionSubmit = () => {
        if (this.state.form.applyToAll === 1) {
            confirmAlert({
                title: this.state?.translations?.you_are_applying_all_problems,
                message: '',
                buttons: [
                    {
                        label: this.state?.translations?.YES,
                        onClick: () => {
                            this.submitForm();
                        }
                    },
                    {
                        label: this.state?.translations?.NO,
                    }
                ]
            });
            // confirmAlert({
            //     customUI: ({ onClose }) => {
            //         return (
            //         <div className='custom-ui-alert-solution'>
            //             <Grid container spacing={0}>
            //                 <Grid item xs={12}>
            //                     <h1>WARNING</h1>
            //                 </Grid>
            //                 <Grid item xs={12}>
            //                     <p>You are applying a solution to all problems, are you sure to continue?</p>
            //                 </Grid>
            //                 <Grid item xs={3} />
            //                 <Grid item xs={3}>
            //                     <div className="button">
            //                         <Button  variant="contained" onClick={onClose}>Cancel</Button>
            //                     </div>
            //                 </Grid>
            //                 <Grid item xs={3}>
            //                     <div  className="button primary">
            //                         <Button variant="contained" color="primary"
            //                         onClick={() => {
            //                             this.submitForm();
            //                             onClose();
            //                         }}
            //                         >
            //                         Confirm
            //                         </Button>
            //                     </div>
            //                 </Grid>
            //                 <Grid item xs={3} />
            //             </Grid>

            //         </div>
            //         );
            //     }
            // });
        }
        else {
            this.submitForm();
        }
    }

    rendersolutionTypeModal() {
        return (
            <div>
                {this.props.closeModal && this.state.closedModalFromAction === false ? this.triggerCloseModal() : null}
                <Modal
                    className="solution-type-modal"
                    open={this.state.solutionModalOpen}
                    onClose={this.state.solutionModalClose}
                    style={this.state.modalSettings}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500
                    }}
                >
                    <Fade style={this.state.modalContainerSettings} in={this.state.solutionModalOpen}>
                        <div style={this.state.modalContainerSettings.solutionConfig}>
                            <div style={this.state.modalContainerSettings.title}>{this.state.solutionId ? this.state?.translations?.EDIT_SOLUTION : this.state?.translations?.ADD_SOLUTION_LABEL}</div>
                            <Grid container spacing={0}>
                                <Grid item xs>
                                    <FormControl style={this.state.modalContainerSettings.controlRow} fullWidth>
                                        <TextField className={this.state.form.solutionType ? 'label-green' : null} required error={this.hasErrors('solutionType')} value={this.state.form.solutionType} onChange={this.handleFormChange} name="solutionType" placeholder="" label={this.state?.translations?.NAME} InputLabelProps={{ shrink: true }} />
                                    </FormControl>
                                    <FormControl style={this.state.modalContainerSettings.controlRow} fullWidth>
                                        <TextField 
                                            required 
                                            className={this.state.form.desc1 ? 'label-green' : null} 
                                            error={this.hasErrors('desc1')} 
                                            value={this.state.form.desc1}
                                            onChange={this.handleFormChange} 
                                            name="desc1" 
                                            placeholder="" 
                                            label={this.state?.translations?.DESCRIPTION} 
                                            InputLabelProps={{ shrink: true }} />
                                    </FormControl>
                                    {this.applyToAllCheckbox()}
                                </Grid>
                            </Grid>
                            {this.state.solutionId ? this.renderStatusForm() : null}
                            <div style={this.state.modalContainerSettings.footerActions}>
                                <Grid container spacing={1}>
                                    <Grid item xs={2} />
                                    <Grid item xs={4}>
                                        <Button onClick={this.solutionModalClose} style={this.state.modalContainerSettings.footerActions.cancel} fullWidth variant="outlined">{this.state?.translations?.CANCEL}</Button>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button onClick={this.handleSolutionSubmit} style={this.state.modalContainerSettings.footerActions.save} fullWidth color="primary" variant="contained">{this.state.saveLabel}</Button>
                                    </Grid>
                                    <Grid item xs={2} />
                                </Grid>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div>
        )
    }



    getBackLink() {
        return '/configuration/repair-type';
    }

    checkProblemTrigger() {
        let {translations} = this.state

        if (this.props.triggerHighlight) {
            let errors = [];
            let formValid = false;
            errors.push({ name: 'problemType', message: translations.problem_name_exists });

            let tmpFormError = {
                valid: formValid,
                errors: errors
            }

            this.setState({ formErrors: tmpFormError.errors }, () => {
                this.props.problemTriggerHighlight(false);
            });
        }
    }

    render() {
        let {translations} = this.state

        return (
            <MainPage className='solution-type-config'>
                {this.setBeforeUnload()}
                <MainPageHeader title="Repair Types" search={false} back={this.getBackLink()} />
                {this.checkProblemTrigger()}
                <MainPageBody>
                    <div className="solution-type-config-body">
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Paper>
                                    <div className="banner-detail">
                                        <div className="banner-image">
                                            <div className="banner">
                                                {/* <img /> */}
                                            </div>
                                            <div className="problem-image">
                                                <img alt="repair" src={ToolIcon} />
                                            </div>
                                            <div className="solution-name">{this.props.problemDetail ? this.renderText(this.props.problemDetail.problemType) : null}</div>
                                        </div>
                                    </div>
                                </Paper>
                                <Paper>
                                    <div className="problem-detail">
                                        <div className="details">
                                            <Grid className="statusDetails" item xs={12}>
                                                <FormLabel component="legend">{translations.UPDATED_BY}: {this.props.problemDetail ? this.renderText(this.props.problemDetail.updatedBy) : null}</FormLabel>
                                            </Grid>
                                            <Grid className="statusDetails" item xs={12}>
                                                <FormLabel component="legend">{translations.UPDATED_DATE}: {this.props.problemDetail ? formatDate(this.props.problemDetail.updatedDt) : null}</FormLabel>
                                            </Grid>
                                            <Grid className="statusDetails" item xs={12}>
                                                <FormLabel component="legend">{translations.CREATED_BY}: {this.props.problemDetail ? this.renderText(this.props.problemDetail.createdBy) : null}</FormLabel>
                                            </Grid>
                                            <Grid className="statusDetails" item xs={12}>
                                                <FormLabel component="legend">{translations.CREATED_DATE}: {this.props.problemDetail ? formatDate(this.props.problemDetail.createdDt) : null}</FormLabel>
                                            </Grid>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={9}>
                                <Paper>
                                    <div className="solution-table">
                                        {translations.EDIT_REPAIR_TYPE}
                                    </div>
                                    <Table {...this.state.tableState} />
                                </Paper>
                            </Grid>
                        </Grid>
                        {this.rendersolutionTypeModal()}
                        {this.renderProblemTypeModal()}
                    </div>
                </MainPageBody>
            </MainPage>
        );
    }
}

const mapStateToProps = state => {
    let { translationReducer } = state
    let apiLoading = {
        loading: state.solutionListReducer.loading,
        detailLoading: state.solutionListReducer.detailLoading,
    }
    let data = {
        userAccess: state.authReducer.access,
        problemDetail: state.problemListReducer.problemDetail,
        solutionList: state.solutionListReducer.solutionList,
        solutionDetail: state.solutionListReducer.solutionDetail,
        closeModal: state.solutionListReducer.closeModal,
        problemListTreeById: state.problemListReducer.problemListTreeById,
        problemListTreeByIdTimeStamp: state.problemListReducer.problemListTreeByIdTimeStamp,
        problemDetail: state.problemListReducer.problemDetail,
        triggerHighlight: state.problemListReducer.triggerHighlight,
        problemCloseModal: state.problemListReducer.closeModal,
    }

    return {
        ...apiLoading,
        ...data,
        ...translationReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProblemsTreeView: (ev) => dispatch(getProblemsTreeView(ev)),
        getProblemDetail: (i) => dispatch(getProblemDetail(i)),
        getSolutionDetail: (i) => dispatch(getSolutionDetail(i)),
        notificationError: (message) => dispatch(notificationError(message)),
        postSolutionNew: (payload) => dispatch(postSolutionNew(payload)),
        putSolutionUpdate: (id, payload) => dispatch(putSolutionUpdate(id, payload)),
        deleteSolution: (id, problemId) => dispatch(deleteSolution(id, problemId)),
        putProblemUpdate: (id, payload) => dispatch(putProblemUpdate(id, payload)),
        deleteProblem: (id) => dispatch(deleteProblem(id)),
    }
}

SolutionType = connect(mapStateToProps, mapDispatchToProps)(SolutionType);

export default SolutionType;
