import {
    PROBLEM_LIST_REQUEST,
    PROBLEM_LIST_SUCCESS,
    PROBLEM_LIST_ERROR,
    PROBLEM_DETAIL_REQUEST,
    PROBLEM_DETAIL_SUCCESS,
    PROBLEM_DETAIL_ERROR,
    PROBLEM_NEW_REQUEST,
    PROBLEM_NEW_SUCCESS,
    PROBLEM_NEW_ERROR,
    PROBLEM_UPDATE_REQUEST,
    PROBLEM_UPDATE_SUCCESS,
    PROBLEM_UPDATE_ERROR,
    PROBLEM_TREE_VIEW,
    PROBLEM_TRIGGER_HIGHLIGHT,
    PROBLEM_TREE_VIEW_BY_ID
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    detailLoading: false,
    triggerHighlight: false,
    closeModal: false,
    problemList:[],
    problemDetail: null,
    meta: [],
    error: ''
}

const problemListReducer = (state = initialState, action) => {
    switch (action.type) {
        case PROBLEM_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case PROBLEM_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                problemList: action.payload.problemList,
                meta: action.payload.meta,
                error: ''
            }
        case PROBLEM_LIST_ERROR:
            return {
                ...state,
                loading: false,
                problemList: [],
                meta: [],
                error: action.payload.message
            }
        case PROBLEM_DETAIL_REQUEST:
            return {
                ...state,
                detailLoading: true,
            }
        case PROBLEM_DETAIL_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                problemDetail: action.payload.problemDetail
            }
        case PROBLEM_NEW_REQUEST:
            return {
                ...state,
                detailLoading: true,
                closeModal: false
            }
        case PROBLEM_NEW_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                closeModal: true
            }
        case PROBLEM_UPDATE_REQUEST:
            return {
                ...state,
                detailLoading: true,
                closeModal: false
            }
        case PROBLEM_UPDATE_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                closeModal: true
            }
        case PROBLEM_TREE_VIEW:
            return {
                ...state,
                loading: false,
                closeModal: false,
                ...action.payload
            }
        case PROBLEM_TREE_VIEW_BY_ID:
            return {
                ...state,
                loading: false,
                closeModal: false,
                ...action.payload
            }
        case PROBLEM_TRIGGER_HIGHLIGHT:
            return {
                ...state,
                triggerHighlight: action.payload,
                closeModal: false,
            }
        default: 
            return state;
    }
}

export default problemListReducer;