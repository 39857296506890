import { Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const colors = {
  READY: '#A12FD3',
  'IN PROGRESS': '#F57C00',
  NEW: '#4CAF50',
  CLOSED: '#AFAFAF',
};

export default withStyles((theme) => ({
  root: (props) => ({
    width: '100%',
    backgroundColor: colors[props.status],
    color: '#FFFFFF',
    height: 'auto',
    padding: 5,
  }),
}))(Chip);
