import {
    MODAL_SHOW,
    MODAL_CLOSE
} from '../actionTypes';
import { tokenGet, apiSettings } from '../../../utils/api/CoreApi';
 
export const showModal = (modal) => {
    return {
        type:MODAL_SHOW,
        payload: modal
    }
}
 
export const closeModal = () => {
    return {
        type:MODAL_CLOSE,
    }
}