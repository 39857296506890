import React from 'react';
import { numberWithCommas } from '../../../../../../../utils/helper';
import { useStyles } from '../../../styles';

export default function CardTotal(props) {
    const classes = useStyles();
    const data = [
        {
            "title": 'Total Machines'.translate(),
            "active": props.data.totalMachines 
                ? props.data.totalMachines.active
                : 0 ,
            "inactive": props.data.totalMachines 
                ? props.data.totalMachines.inactive
                : 0 ,
        },
        {
            "title": 'Total Factory Machines'.translate(),
            "active": props.data.totalFactoryMachines 
                ? props.data.totalFactoryMachines.active
                : 0 ,
            "inactive": props.data.totalFactoryMachines 
                ? props.data.totalFactoryMachines.inactive
                : 0 ,
        },
        {
            "title": 'Total Rental Machines'.translate(),
            "active": props.data.totalRentalMachines 
                ? props.data.totalRentalMachines.active
                : 0 ,
            "inactive": props.data.totalRentalMachines 
                ? props.data.totalRentalMachines.inactive
                : 0 ,
        },
        {
            "title": 'Machine Usage%'.translate(),
            "usage": props.data.machineUsage 
                ? props.data.machineUsage.usage
                : 0 ,
            "active": props.data.machineUsage 
                ? props.data.machineUsage.inUse
                : 0 ,
            "inactive": props.data.machineUsage 
                ? props.data.machineUsage.idle
                : 0 ,
        },
    ];

    return (
        <div className={[classes.grid, classes.grid4].join(' ')}>
            {data?.map(({ title, active, inactive, usage }) => (
                <div className={usage ? [classes.card, usage < 80 ? classes.cardDanger : classes.cardSuccess].join(' ')  : [classes.card].join(' ')}>
                    <div className={classes.cardTitle}>{title}</div>
                    <div className={classes.cardTotal}>{
                        usage ? `${usage.toFixed(2)}%` : numberWithCommas(active + inactive)
                    }</div>
                    <div className={classes.cardSubtitle}>
                        <span>{numberWithCommas(active)} {usage ? 'In Use'.translate() : 'Active'.translate()}</span>
                        <span> & </span> 
                        <span>{numberWithCommas(inactive)} {usage ? 'Idle'.translate() : 'Inactive'.translate()}</span>
                    </div>
                </div>
            ))}
        </div>
    );
}