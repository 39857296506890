import React from 'react';
import { connect } from 'react-redux';
import { closeModal, showModal } from '../../../../../../../redux/actions/Modal/modalActions';
import { useStyles } from '../../../styles';
import { fetchMachinesPerformance } from '../../api';
import { downloadMachineSummaryReport, MACHINE_SUMMARY_REPORT_TYPES } from '../excel';
import MachinePerformanceHeader from './Header';
import TableData from './TableData';

function MachinePerformance({ 
    data = [],
    pageSize = 10,
    page = 1,
    total = 0,
    orderBy,
    query,
    showModalAction,
    closeModalAction,
}) {
    const classes = useStyles();
    const handleExport = () => {
        showModalAction('excelLoading');
        fetchMachinesPerformance(query, orderBy, '', '')
            .then(({ machinesPerformance }) => {
                downloadMachineSummaryReport(MACHINE_SUMMARY_REPORT_TYPES.MachinePerformance, machinesPerformance.data);
            })
            .finally(() => {
                closeModalAction();
            });
    }
    
    return ( 
        <div className={[classes.card, classes.grid].join(' ')}>
            <MachinePerformanceHeader 
                title={'Machine Performance'.translate()} 
                onExport={handleExport} />
            <TableData
                total={total}
                pageSize={pageSize}
                page={page}
                data={data} />
        </div>
     );
}

const mapDispatchToProps = dispatch => {
    return {
        showModalAction: (modal) => dispatch(showModal(modal)),
        closeModalAction: () => dispatch(closeModal())
    }
}

export default connect(null, mapDispatchToProps)(MachinePerformance);