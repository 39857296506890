import React from 'react';
import { 
    TableCell, 
    TableContainer, 
    TableRow
} from '@material-ui/core';
import {
    LineBox,
    MuiTable,
    MuiTableSortLabel,
    TableCellEllipsis,
    TableCellUsage,
    TableHeaderCell
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { 
    getLatestMachineUsage,
    setOrderByLatestMachineUsage, 
    setOrderLatestMachineUsage, 
} from '../../../../../../../../redux/actions/MachineSummary/machineSummaryAction';

export default function TableData({ selectOptions }) {
    const dispatch = useDispatch();
    const {
        query,
        latestMachineUsage = [],
        latestMachineUsageViewBy,
        latestMachineUsageOrder,
        latestMachineUsageOrderBy,
    } = useSelector(({ machineSummaryReducer }) => machineSummaryReducer);
    
    const handleRequestSort = (property) => {
        const isAsc = latestMachineUsageOrderBy == property
            && latestMachineUsageOrder == 'asc';
        dispatch(setOrderLatestMachineUsage(isAsc ? 'desc' : 'asc'));
        dispatch(setOrderByLatestMachineUsage(property));
        dispatch(getLatestMachineUsage(
            query, 
            latestMachineUsageViewBy, 
            isAsc ? 'desc' : 'asc',
        ));
    };

    const tableHeaders = [
        { name: selectOptions[latestMachineUsageViewBy - 1], orderBy: 'name', order: false },
        { name: 'IN USE MACHINES vs. IDLE MACHINES'.translate(), orderBy: 'data', order: false },
        { name: 'USAGE %'.translate(), orderBy: 'usage', order: true },
    ];

    const getPercentageWorking = (working = 0, idle = 0) => {
        const total = working + idle;
        return (working / total) * 100;
    }

    return (
        <TableContainer>
            <MuiTable>
                <TableRow key='machineUsageTableHeader'>
                    {tableHeaders?.map((cell) => (
                        <TableHeaderCell width={cell.width} active={cell.orderBy == latestMachineUsageOrderBy}>
                            <MuiTableSortLabel
                                active={cell.orderBy == latestMachineUsageOrderBy}
                                direction={cell.orderBy == latestMachineUsageOrderBy ? latestMachineUsageOrder : 'asc'}
                                onClick={() => cell.order && handleRequestSort(cell.orderBy)} >
                                {cell.name}
                            </MuiTableSortLabel>
                        </TableHeaderCell>
                    ))}
                </TableRow>
                {latestMachineUsage?.map(({ name, inUseMachineQty, idleMachineQty, machineUsage }, key) => (
                    <TableRow key={key}>
                        <TableCellEllipsis>{name}</TableCellEllipsis>
                        <TableCell>
                            <LineBox
                                working={inUseMachineQty}
                                idle={idleMachineQty}
                                workingPercent={getPercentageWorking(inUseMachineQty, idleMachineQty)} />
                        </TableCell>
                        <TableCellUsage>{(machineUsage).toFixed(2)}%</TableCellUsage>
                    </TableRow>
                ))}
            </MuiTable>
        </TableContainer>
    );
}