import {
    CHECKLIST_REQUEST,
    CHECKLIST_SUCCESS,
    CHECKLIST_ERROR,
    CHECKLIST_TEMPLATE_REQUEST,
    CHECKLIST_TEMPLATE_SUCCESS,
    CHECKLIST_TEMPLATE_ERROR,
    CHECKLIST_TEMPLATE_DETAIL_REQUEST,
    CHECKLIST_TEMPLATE_DETAIL_SUCCESS,
    CHECKLIST_TEMPLATE_DETAIL_ERROR,
    CHECKLIST_TEMPLATE_CHECKBOX_TOGGLE,
    CHECKLIST_TEMPLATE_SHOW_TOGGLE,
    CHECKLIST_TEMPLATE_CLOSE_TOGGLE,
    CHECKLIST_SET,
    CHECKLIST_DETAIL_SET,
    MACHINE_LIST_CHECKLIST_REQUEST,
    MACHINE_LIST_CHECKLIST_SUCCESS,
    MACHINE_NEW_CHECKLIST_REQUEST,
    MACHINE_NEW_CHECKLIST_SUCCESS,
    CHECKLIST_DETAIL_REQUEST,
    CHECKLIST_DETAIL_SUCCESS,
    UPDATE_CHECKLIST_REQUEST,
    UPDATE_CHECKLIST_SUCCESS,
    POST_ASSIGN_CHECKLIST_REQUEST,
    POST_ASSIGN_CHECKLIST_SUCCESS,
    POST_SAVE_CHECKLIST,
    POST_SAVE_CHECKLIST_SUCCESS,
    
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    detailLoading: false,
    templateLoading: null,
    machineListChecklistLoading:null,
    checklistDetailLoading:null,
    postChecklistLoading:null,
    checklistUpdateLoading:null,
    postChecklistMachineLoading:null,
    loadingPostChecklist: false,
    checklist:[],
    checklistTemplates:[],
    machineListChecklist:[],
    searchString: null,
    selectedRow: {
        id: null,
        showToggle: false
    },
    checklistDetail: null,
    rows: 0,
    currentPage: 0,
    displayedRows: 0,
    checklistFilter: 11,
    taskCount:[],
    tasksDetailed:[],
    meta: [],
    error: ''
}

const checklistReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHECKLIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CHECKLIST_SUCCESS:
            return {
                ...state,
                loading: false,
                checklist: action.payload.checklist,
                meta: action.payload.meta,
                error: ''
            }
            case UPDATE_CHECKLIST_REQUEST:
                return {
                    ...state,
                    checklistUpdateLoading: true,
                }
            case UPDATE_CHECKLIST_SUCCESS:
                return {
                    ...state,
                    checklistUpdateLoading: false,
                }
                case POST_ASSIGN_CHECKLIST_REQUEST:
                    return {
                        ...state,
                        postChecklistMachineLoading: true,
                    }
                case POST_ASSIGN_CHECKLIST_SUCCESS:
                    return {
                        ...state,
                        postChecklistMachineLoading: false,
                    }
        case CHECKLIST_ERROR:
            return {
                ...state,
                loading: false,
                checklist: [],
                meta: [],
                error: action.payload.message
            }
        case CHECKLIST_TEMPLATE_REQUEST:
            return {
                ...state,
                templateLoading: true
            }
        case CHECKLIST_TEMPLATE_SUCCESS:
            return {
                ...state,
                templateLoading: false,
                checklistTemplates: action.payload.checklistTemplates,
                rows: action.payload.rows,
                searchString: action.payload.searchString,
                currentPage: 0,
                meta: action.payload.meta,
                error: ''
            }
            //neil
            case CHECKLIST_DETAIL_REQUEST:
                return {
                    ...state,
                    checklistDetailLoading: true
                }
            case CHECKLIST_DETAIL_SUCCESS:
                return {
                    ...state,
                    checklistDetailLoading: false,
                    taskCount: action.payload.taskCount,
                    tasksDetailed: action.payload.tasksDetailed,
                    checklistDetail: action.payload.checklistDetail
                }
            //neil
            case MACHINE_LIST_CHECKLIST_REQUEST:
                return {
                    ...state,
                    machineListChecklistLoading: true
                }
            case MACHINE_LIST_CHECKLIST_SUCCESS:
                return {
                    ...state,
                    machineListChecklistLoading: false,
                    machineListChecklist: action.payload.machineListChecklist,
                }
                case MACHINE_NEW_CHECKLIST_REQUEST:
                    return {
                        ...state,
                        postChecklistLoading: true,
                    }
                case MACHINE_NEW_CHECKLIST_SUCCESS:
                    return {
                        ...state,
                        postChecklistLoading: false,
                    }
            case CHECKLIST_TEMPLATE_ERROR:
                return {
                ...state,
                templateLoading: false,
                checklistTemplates: [],
                meta: [],
                error: action.payload.message
                }
        case CHECKLIST_TEMPLATE_CHECKBOX_TOGGLE:
            return {
                ...state,
                tickets: action.payload,
            }
        case CHECKLIST_TEMPLATE_SHOW_TOGGLE:
            return {
                ...state,
                selectedRow: {
                    id: action.payload.id,
                    showToggle: action.payload.flag
                }
            }
        case CHECKLIST_TEMPLATE_CLOSE_TOGGLE:
            return {
                ...state,
                selectedRow: {
                    id: null,
                    showToggle:false
                }
            }
        case CHECKLIST_TEMPLATE_DETAIL_REQUEST:
            return {
                ...state,
                detailLoading: true
            }
        case CHECKLIST_TEMPLATE_DETAIL_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                checklistDetail: action.payload
            }
        case CHECKLIST_SET:
            return {
                ...state,
                checklistTemplates: action.payload.checklistTemplates,
            }
        case CHECKLIST_DETAIL_SET:
            return {
                ...state,
                checklistDetail: action.payload.checklistDetail,
            }
        case POST_SAVE_CHECKLIST:
            return {
                ...state,
                loadingPostChecklist: true
            }
        case POST_SAVE_CHECKLIST_SUCCESS:
            return {
                ...state,
                loadingPostChecklist: false
            }
        default: 
            return state;
    }
}

export default checklistReducer;