import React from 'react';
import { TableHead as Head, TableRow } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { useState } from 'react';
import { Col, ColFilter } from '../styles';

export default function TableHeader(props) {
    const [order, setOrder] = useState(false);
    const onOrder = () => {
        props.handleOrder();
        setOrder(!order);
    }
    return (
        <Head>
            <TableRow>
                <Col width="10%">{'LINE NAME'.translate()}</Col>
                <Col width="10%">{'CO. TKTS.'.translate()}</Col>
                <Col width="10%">{'REPAIR TKTS.'.translate()}</Col>
                <Col width="60%">{'TOP 3 PROBLEM TYPE IN REPAIR TICKETS'.translate()}</Col>
                <ColFilter onClick={onOrder}>
                    {'TTL NPT MIN'.translate()}
                    {order ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                </ColFilter>
            </TableRow>
        </Head>
    )
}