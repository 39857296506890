import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { showModal } from '../../redux/actions/Modal/modalActions';
import MainTable from '../MainTable/MainTable'
import AddMaintenanceTemplate from '../Modal/AddMaintenanceTemplate/AddMaintenanceTemplate';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import './TemplateControl.scss';
import { Link } from '@material-ui/core';
import { formatDate, renderDownTime } from '../../utils/date';

class TemplateControl extends React.Component {
    constructor(props) {
        super(props);
        // this.refAddMaintenanceTemplate = React.createRef();
      }

  state = {
      showTable: false
  }

  componentDidMount(){
      console.log('Main Page Body Mounted');
  }

  toggleTable = () =>{
      this.setState({showTable:!this.state.showTable})
  }

  openAddMaintenanceModal = () => {
      this.props.showModal('addMaintenanceTemplate');
  }

  renderTableHeader(){
      let tableHeaders = this.props.header.map((item,itemIndex) => {
          return (
            <TableCell key={itemIndex}>{item}</TableCell>
          )
      });
      return tableHeaders;
  }

  renderDate(date){
      if (date !== null){
        let timezone = moment(date).format('Z');
        let dateTimeOnly = moment(date).format('YYYY-MM-DD HH:mm:ss') + '+00:00';
        return moment(dateTimeOnly).utcOffset(timezone).format('DD-MM-YYYY');
      }
      else{
          return '-';
      }
  }

  getStatusClass(field){
      if (field === 'REPORTED'){
        return ' not-working'
      }
      if (field ===  'IN-REPAIR'){
        return ' in-repair'
      }
      else{
        return ' active'
      }
  }

  renderText(field){
      if (field === null || field === ''){
          return '-'
      }
      else{
          return field
      }
  }

  renderTableBody(){
    let tableBody = null;
    if (this.props.data.length > 0){
        tableBody = this.props.data.map((item,itemIndex) => {
            if (this.props.name === 'maintenanceHistory'){
                return (
                    <TableRow key={itemIndex}>
                        <TableCell>
                            <Link to={this.props.link + '/' + item.ticketNo.linkId}>{ item.ticketNo.value }</Link>
                        </TableCell>
                        <TableCell>{ this.renderText(item.reportedBy) }</TableCell>
                        <TableCell>{ this.renderText(item.problemType) }</TableCell>
                        <TableCell>{ this.renderText(item.solutionType) }</TableCell>
                        <TableCell>{ formatDate(item.reportedDt) }</TableCell>
                        <TableCell>{ formatDate(item.repairedDt) }</TableCell>
                        <TableCell>{ formatDate(item.closedDt) }</TableCell>
                        <TableCell>{ renderDownTime(item.reportedDt, item.closedDt) }</TableCell>
                        <TableCell className={'status ' + this.getStatusClass(item.status)}>{ item.status }</TableCell>
                    </TableRow>
                )
            }
            else if (this.props.name === 'repairHistory'){
                return (
                    <TableRow key={itemIndex}>
                        <TableCell>
                            <Link to={this.props.link + '/' + item.ticketNo.linkId}>{ item.ticketNo.value }</Link>
                        </TableCell>
                        <TableCell>{ this.renderText(item.reportedBy) }</TableCell>
                        <TableCell>{ this.renderText(item.problemType) }</TableCell>
                        <TableCell>{ this.renderText(item.solutionType) }</TableCell>
                        <TableCell>{ formatDate(item.reportedDt) }</TableCell>
                        <TableCell>{ formatDate(item.repairedDt) }</TableCell>
                        <TableCell>{ formatDate(item.closedDt) }</TableCell>
                        <TableCell>{ renderDownTime(item.reportedDt, item.closedDt) }</TableCell>
                        <TableCell className={'status ' + this.getStatusClass(item.status)}>{ item.status }</TableCell>
                    </TableRow>
                )
            }
        });
    }
    return tableBody;
  }

  renderTable(){
      if (this.state.showTable){
          return (
            <Grid className="template-table" item xs={12}>
                <TableContainer>
                    <Table stickyHeader aria-label="custom sticky table"> 
                        <TableHead>
                            <TableRow>
                                { this.renderTableHeader() }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { this.renderTableBody() }
                        </TableBody>
                    </Table>
                </TableContainer>
                { this.props.data.length <= 0 && this.state.showTable === true ? <div className="no-result">{ this.props.noResultText }</div> : null }
            </Grid>
          );
      }
  }

  renderToggle(){
      if (this.state.showTable){
          return (
            <ArrowDropUpIcon className="toggle" onClick={this.toggleTable}></ArrowDropUpIcon>
          )
      }
      else{
        return (
            <ArrowDropDownIcon className="toggle" onClick={this.toggleTable}></ArrowDropDownIcon>
        )
      }
  }

  renderActions(){
      if (this.props.actionEnabled === true){
        return (
            <div>
                <Grid item xs={4}>
                    <span className="action">{'Detach'.tranlsate()}</span>
                </Grid>
                <Grid item xs={4}>
                    <span onClick={this.openAddMaintenanceModal} className="action">{'Attach'.translate()}</span>
                </Grid>
            </div>
        );
      }
      else{
          return <Grid item xs={8}></Grid>;
      }
  }

  render(){
    return (
        <div className={"template-control " + (this.state.showTable ? 'no-margin-bottom' : null) }>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <div className="template-header">
                        <Grid container>
                            <Grid item xs={5}>
                                <div className="title">{ this.props.title }</div>
                            </Grid>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={3}>
                                <Grid container spacing={1}>
                                    <Grid item xs={2}></Grid>
                                    { this.renderActions() }
                                    <Grid item xs={2}>
                                        <div className="toggle-container">
                                            { this.renderToggle() }
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                { this.renderTable() }
            </Grid>
            {/* <AddMaintenanceTemplate ref={this.refAddMaintenanceTemplate} /> */}
        </div>
    );
  }
}

const mapStateToProps = state => {
    let apiLoading = {
    }
    let data = {
    }
    
    return {
        ...apiLoading,
        ...data,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showModal: (modal) => dispatch(showModal(modal)),
    }
}

TemplateControl = connect(mapStateToProps,mapDispatchToProps)(TemplateControl);

export default TemplateControl;
