import {
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_ERROR,
    USER_DETAIL_REQUEST,
    USER_DETAIL_SUCCESS,
    USER_DETAIL_ERROR,
    USER_NEW_REQUEST,
    USER_NEW_SUCCESS,
    USER_NEW_ERROR,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_ERROR,
    USER_TRIGGER_HIGHLIGHT,
    USER_LIST_FACTORIES,
    USER_CHANGE_PROFILE,
} from '../../actions/actionTypes';
import { isSuperAdmin } from '../../../utils/helper';

const initialState = {
    loading: false,
    detailLoading: false,
    triggerHighlight: null,
    userList:[],
    userDetail: null,
    userProfile: null,
    meta: [],
    userProfileFactories: [],
    hasFormChanges: false,
    error: ''
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case USER_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                userList: action.payload.userList,
                userProfile: null,
                meta: action.payload.meta,
                error: ''
            }
        case USER_LIST_ERROR:
            return {
                ...state,
                loading: false,
                userList: [],
                meta: [],
                error: action.payload.message
            }
        case USER_DETAIL_REQUEST:
            return {
                ...state,
                detailLoading: true,
                userDetail: null,
            }
        case USER_DETAIL_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                hasFormChanges: false,
                userDetail: action.payload.userDetail,
            }
        case USER_TRIGGER_HIGHLIGHT:
            return {
                ...state,
                triggerHighlight: action.payload
            }
        case USER_LIST_FACTORIES:
            return{
                ...state,
                ...action.payload
            }
        case USER_NEW_REQUEST:
            return{
                ...state,
                hasFormChanges: false,
            }
        case USER_NEW_SUCCESS:
            return{
                ...state,
                hasFormChanges: false,
            }
        case USER_UPDATE_SUCCESS:
            return{
                ...state,
                hasFormChanges: false,
            }
        case USER_UPDATE_REQUEST:
            return{
                ...state,
                hasFormChanges: false,
            }
        case USER_CHANGE_PROFILE:
            return{
                ...state,
                userProfile: action.payload.image,
                hasFormChanges: action.payload.trigger,
            }
        default: 
            return state;
    }
}

export default userReducer;