import TableCell from './TableCell';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  root: {
    position: 'relative',
    width: 30,
    '&::before': {
      content: '""',
      borderLeft: '4px solid #C6C6C6',
      position: 'absolute',
      left: 5,
      top: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
  }
}))(TableCell);