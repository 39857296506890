import React from "react";

function MachineIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g>
        <path fill="none" d="M0 0H24V24H0z" data-name="Rectangle 2013"></path>
      </g>
      <g fill="none" data-name="Group 5535" transform="translate(-13 -202.6)">
        <g
          stroke="#3573b6"
          strokeWidth="1.6"
          data-name="Rectangle 2052"
          transform="translate(14 220)"
        >
          <rect width="22" height="5" stroke="none" rx="1"></rect>
          <rect width="20.4" height="3.4" x="0.8" y="0.8" rx="0.2"></rect>
        </g>
        <g
          stroke="#3573b6"
          strokeWidth="1.6"
          data-name="Rectangle 2053"
          transform="translate(17.4 219)"
        >
          <rect width="8" height="2.6" stroke="none" rx="1"></rect>
          <rect width="6.4" height="1" x="0.8" y="0.8" rx="0.2"></rect>
        </g>
        <g stroke="#3573b7" strokeWidth="1.6" data-name="Rectangle 2058">
          <path
            stroke="none"
            d="M1 0h3a1 1 0 011 1v3H0V1a1 1 0 011-1z"
            transform="translate(19 205.2)"
          ></path>
          <path
            d="M1 .8h3a.2.2 0 01.2.2v1.4a.8.8 0 01-.8.8H1.6a.8.8 0 01-.8-.8V1A.2.2 0 011 .8z"
            transform="translate(19 205.2)"
          ></path>
        </g>
        <g stroke="#3573b7" strokeWidth="1.6" data-name="Rectangle 4896">
          <path
            stroke="none"
            d="M0 0h5v3a1 1 0 01-1 1H1a1 1 0 01-1-1V0z"
            transform="translate(19 211)"
          ></path>
          <path
            d="M1.6.8h1.8a.8.8 0 01.8.8V3a.2.2 0 01-.2.2H1A.2.2 0 01.8 3V1.6a.8.8 0 01.8-.8z"
            transform="translate(19 211)"
          ></path>
        </g>
        <g
          stroke="#3573b7"
          strokeWidth="1.6"
          data-name="Rectangle 2057"
          transform="translate(30.8 211)"
        >
          <rect width="4" height="6" stroke="none" rx="1"></rect>
          <rect width="2.4" height="4.4" x="0.8" y="0.8" rx="0.2"></rect>
        </g>
        <g data-name="Union 1">
          <path
            d="M10.4 13a1 1 0 01-1-1V4h-8a1 1 0 01-1-1V0a1 1 0 011-1h13a1 1 0 011 1v12a1 1 0 01-1 1z"
            transform="translate(17 208.6)"
          ></path>
          <path
            fill="#3573b7"
            d="M13.8 11.4V.6H2v1.8h9v9h2.8m.6 1.6h-4a1 1 0 01-1-1V4h-8a1 1 0 01-1-1V0a1 1 0 011-1h13a1 1 0 011 1v12a1 1 0 01-1 1z"
            transform="translate(17 208.6)"
          ></path>
        </g>
        <path
          stroke="#3573b7"
          strokeLinecap="round"
          strokeWidth="1.6"
          d="M0 0L0 2.23"
          data-name="Line 32"
          transform="translate(21.5 214.774)"
        ></path>
        <path
          stroke="#3573b7"
          strokeLinecap="round"
          strokeWidth="1.6"
          d="M0 0L0 4"
          data-name="Line 33"
          transform="translate(29.4 204.4)"
        ></path>
      </g>
    </svg>
  );
}

export default MachineIcon;
