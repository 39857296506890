import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function SplitIcon(props) {
  return <SvgIcon {...props}>
    <g id="ic_seperate" transform="translate(-186 -418.5)">
      <path id="Path_1002" data-name="Path 1002" d="M0,0H6V19H0" transform="translate(206 421) rotate(90)" fill="none" stroke="#1e88e5" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <path id="Path_1003" data-name="Path 1003" d="M0,0H6V19H0" transform="translate(187 437) rotate(-90)" fill="none" stroke="#1e88e5" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <path id="Path_1004" data-name="Path 1004" d="M4.5,3,9,8H0Z" transform="translate(192 416)" fill="#1e88e5" stroke="#1e88e5" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
      <path id="Path_1005" data-name="Path 1005" d="M4.5,3,9,8H0Z" transform="translate(201 442) rotate(180)" fill="#1e88e5" stroke="#1e88e5" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    </g>
  </SvgIcon>
}