import React from 'react';
import {
  Divider,
  Box,
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';

const CustomDivider = withStyles({
  root: {
    marginBottom: '0.25rem',
    backgroundColor: '#000',
  }
})(Divider);

export default function DoubleDividers(props){
  return <Box>
    <CustomDivider/>
    <CustomDivider/>
  </Box>
};