import ExcelJS from 'exceljs';
import moment from 'moment';
import { formatDate } from '../../../../../../utils/date';
import { downloadFile } from '../../../../../../utils/excel';
import { getUsername } from '../../../../../../utils/helper';
import { selectOptions } from './CardLatest/MachineUsage/constants';

const backgroundFill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'd9d9d9' },
};

const formatNumber = number => {
  if (!number || number < 0.01) return Number(0.00);

  return Number(parseFloat(number).toFixed(2));
}

const getTitleByType = type => {
  switch (type) {
    case MACHINE_SUMMARY_REPORT_TYPES.LatestMachineDistribution:
      return 'Latest Machine Distribution';
    case MACHINE_SUMMARY_REPORT_TYPES.LatestMachineUsage:
      return 'Latest Machine Usage';
    case MACHINE_SUMMARY_REPORT_TYPES.MachinePerformance:
      return 'Machine Performance';
    default:
      return 'Line Analysis';
  }
}

const LatestMachineDistribution = {
  setupSheet: (sheet, headerLength, data) => {
    LatestMachineDistribution.renderHeaders(sheet, headerLength + 1);
    LatestMachineDistribution.renderBody(sheet, headerLength + 2, data);
  },
  renderHeaders: (sheet, startIndex) => {
    let headers = [
      'Building Name',
      'Area Name',
      'Line Name',
      'Total Machine Qty',
      'Inactive Machine Qty',
      'Active Machine Qty',
      'Total Machine%',
      'In Use Machine Qty',
      'Available Machine Qty',
      'Repair Machine Qty',
      'Maintenance Machine Qty',
      'Changeover Machine Qty',
    ];
    const headerRow = sheet.getRow(startIndex + 1);
    sheet.insertRow(startIndex + 1, headers);
    headers.forEach((hd, index) => {
      headerRow.getCell(index + 1).fill = backgroundFill;
      sheet.getColumn(index + 1).width = 20;
    })
  },

  renderBody: (sheet, startIndex, data) => {
    const totalMachine = data.reduce((total, item) => {
      const { countTotal } = item;
      return total + countTotal;
    }, 0);

    data.forEach((item, index) => {
      const {
        buildingName,
        areaName,
        lineName,
        countTotal,
        countInactive,
        countActive,
        countInuse,
        countAvailable,
        countRepairTickets,
        countMaintenanceTicket,
        countCOTickets,
      } = item;
      let columns = [
        buildingName,
        areaName,
        lineName,
        countTotal,
        countInactive,
        countActive,
        `${formatNumber(countTotal * 100 / totalMachine)}%`,
        countInuse,
        countAvailable,
        countRepairTickets,
        countMaintenanceTicket,
        countCOTickets,
      ];

      sheet.insertRow(startIndex + 1 + index, columns);
    })
  },
};

const LatestMachineUsage = {
  setupSheet: (sheet, headerLength, data) => {
    LatestMachineUsage.renderHeaders(sheet, headerLength + 1, [data.code, data.name]);
    LatestMachineUsage.renderBody(sheet, headerLength + 2, data.items);
  },

  renderHeaders: (sheet, startIndex, preHeaders) => {
    let headers = [
      ...preHeaders,
      'Total Machine Qty',
      'Inactive Machine Qty',
      'Active Machine Qty',
      'In Use Machine Qty',
      'Idle Machine Qty',
      'Machine Usage%',
      'Available Machine Qty',
      'Repair Machine Qty',
      'Maintenance Machine Qty',
      'Changeover Machine Qty',
    ];
    sheet.insertRow(startIndex + 1, headers);
    const headerRow = sheet.getRow(startIndex + 1);
    headers.forEach((hd, index) => {
      headerRow.getCell(index + 1).fill = backgroundFill;
      sheet.getColumn(index + 1).width = 35;
    })
  },

  renderBody: (sheet, startIndex, items) => {
    items.forEach(({
      activeMachineQty,
      availableMachines,
      changeoverMachineQty,
      idleMachineQty,
      inActiveMachineQty,
      inUseMachineQty,
      machineUsage,
      maintenanceMachineQty,
      name,
      code,
      repairMachineQty,
    }, index) => {
      let columns = [
        code,
        name,
        activeMachineQty + inActiveMachineQty,
        inActiveMachineQty,
        activeMachineQty,
        inUseMachineQty,
        idleMachineQty,
        `${machineUsage}%`,
        availableMachines,
        repairMachineQty,
        maintenanceMachineQty,
        changeoverMachineQty,
      ];

      sheet.insertRow(startIndex + 1 + index, columns);
    });
  },
};

const MachinePerformance = {
  setupSheet: (sheet, headerLength, data) => {
    MachinePerformance.renderHeaders(sheet, headerLength + 1);
    MachinePerformance.renderBody(sheet, headerLength + 2, data);
  },
  renderHeaders: (sheet, startIndex) => {
    let headers = [
      'Machine No.',
      'Machine Type Code',
      'Machine Sub-type Code',
      'Machine Sub-type Name',
      'Machine Brand Name',
      'Machine Supplier Name',
      'Date of Purchase/Rental',
      'Is Machine Rental?',
      'Closed Repair Ticket Qty',
      'Total NPT Minutes',
      'Completed Maintenance Ticket Qty',
      'Maintenance Overdue%',
      'Maintenance Freq Quantity',
      'Maintenance Freq Unit',
      'Current Building Name',
      'Current Area Name',
      'Current Line Name',
      'Current Station No.',
      'Inventory Age (Days)',
      'Machine Created (Days)',
      'Working Days',
      'Idle Days',
      'Machine Condition',
      'Status',
      'Machine Usage%',
    ];
    const headerRow = sheet.getRow(startIndex + 1);
    sheet.insertRow(startIndex + 1, headers);
    headers.forEach((hd, index) => {
      headerRow.getCell(index + 1).fill = backgroundFill;
      sheet.getColumn(index + 1).width = 20;
    })
  },

  renderBody: (sheet, startIndex, data) => {
    data.forEach((item, index) => {
      const {
        machineNo,
        machineTypeCode,
        machineSubTypeCode,
        machineSubTypeName,
        machineBrandName,
        supplierName,
        dateOfPurchaseOrRental,
        isMachineRental,
        countRepairTickets,
        NPTTime,
        countMaintenanceTickets,
        maintenanceOverdue,
        maintenanceFrequencyQty,
        maintenanceFrequency,
        currentBuildingName,
        currentAreaName,
        currentLineName,
        currentStationNo,
        inventoryDays,
        machineCreatedDays,
        workingDays,
        idleDays,
        machineCondition,
        status,
        machineUsage,
      } = item;
      let columns = [
        machineNo,
        machineTypeCode,
        machineSubTypeCode,
        machineSubTypeName,
        machineBrandName,
        supplierName,
        formatDate(dateOfPurchaseOrRental),
        isMachineRental,
        countRepairTickets,
        formatNumber(NPTTime),
        countMaintenanceTickets,
        formatNumber(maintenanceOverdue) + '%',
        maintenanceFrequencyQty,
        maintenanceFrequency,
        currentBuildingName,
        currentAreaName,
        currentLineName,
        currentStationNo,
        inventoryDays,
        machineCreatedDays,
        workingDays,
        idleDays,
        machineCondition,
        status,
        formatNumber(machineUsage) + '%',
      ];

      sheet.insertRow(startIndex + 1 + index, columns);
    })
  },
};

export const MACHINE_SUMMARY_REPORT_TYPES = {
  LatestMachineDistribution: 'Latest Machine Distribution',
  LatestMachineUsage: 'Latest Machine Usage',
  MachinePerformance: 'Machine Performance',
}

export const downloadMachineSummaryReport = async (type, data) => {
  const workbook = new ExcelJS.Workbook();
  const reportName = getTitleByType(type);

  if (type === MACHINE_SUMMARY_REPORT_TYPES.LatestMachineDistribution) {
    LatestMachineDistribution.setupSheet(
      getSheetByType(workbook, reportName, reportName),
      6,
      data
    );
  } else if (type === MACHINE_SUMMARY_REPORT_TYPES.LatestMachineUsage) {
    data.forEach((items, index) => {
      const type = selectOptions[index];
      LatestMachineUsage.setupSheet(
        getSheetByType(workbook, reportName, 'by ' + type),
        6,
        {
          code: type + ' Code',
          name: type + ' Name',
          items: items,
        }
      );
    });
  } else if (type === MACHINE_SUMMARY_REPORT_TYPES.MachinePerformance) {
    MachinePerformance.setupSheet(
      getSheetByType(workbook, reportName, reportName),
      6,
      data
    );
  }

  // write to a stream
  downloadFile(
    moment().format('DD-MM-YYYY') + ' LTm Machine Summary_' + reportName,
    workbook,
  );
}

const getSheetByType = (workbook, reportName, sheetName) => {
  const selectedFactoryId = localStorage.getItem('selectedFactoryId');
  const filterMachineSummary = localStorage.getItem('filterMachineSummary');
  const factoriesAssigned = JSON.parse(localStorage.getItem('factoriesAssigned')) || [];
  const factory = factoriesAssigned.find(fac => fac.factoryId == selectedFactoryId);

  const headerTitles = [
    {
      tag: 'Report Name',
      value: `LTm Machine Summary - ${reportName}`,
    },
    {
      tag: 'Factory Name',
      value: factory ? factory.name : '',
    },
    {
      tag: 'Data Filter',
      value: filterMachineSummary,
    },
    {
      tag: 'Exported Date',
      value: moment().format('YYYY-MM-DD'),
    },
    {
      tag: 'Exported Time',
      value: moment().format('hh:mm'),
    },
    {
      tag: 'Exported by',
      value: getUsername(),
    },
  ];

  const sheet = workbook.addWorksheet(sheetName);
  headerTitles.forEach((item, index) => {
    sheet.insertRow(index + 1, [item.tag, item.value]);
    sheet.getCell('A' + (index + 1)).fill = backgroundFill;
  });

  return sheet;
}