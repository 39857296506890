import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
// import { Prompt } from 'react-router';
import { confirmAlert } from 'react-confirm-alert'; // Import
import history from '../../../../history';
// import { Link } from 'react-router-dom';
import { getSystemPreference, putUpdateSystemPreference } from '../../../../redux/actions/Preference/preferenceActions';
import {
    checkIfApiLoading,
    // checkPageActionAccess, 
    // checkPageAccess, 
    checkPageAccessField
} from '../../../../utils/helper';
import { notificationError } from '../../../../redux/actions/Notification/notificationActions';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
// import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
// import SearchIcon from '@material-ui/icons/Search';
// import AddIcon from '@material-ui/icons/Add';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormLabel from '@material-ui/core/FormLabel';
import MainPage from '../../../../components/MainPage/MainPage';
import MainPageHeader from '../../../../components/MainPageHeader/MainPageHeader';
import MainPageBody from '../../../../components/MainPageBody/MainPageBody';
// import { getFactoryId } from '../../../../utils/helper.js';
import './SystemPreference.scss';
// import { configure } from '@testing-library/react';
import dictionary from '../../../../redux/actions/Translation/dictionary'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { InputLabel } from '@material-ui/core';
import InputTranslation from './InputTranslation';
import CustomFieldsForm from './ReferenceForm';
import { getFactoryLanguage } from '../../../../redux/actions/Translation/googleTranslationAction';

class SystemPreference extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            translations: {
                ...this.convert()
            },
            translationTimestamp: undefined,
            id: null,
            title: null,
            saveLabel: null,
            factoryId: null,
            hasFormChanges: false,
            disablePrompt: false,
            typingTimeout: null,
            oldFormData: null,
            refValidationError: {},
            translationErrors: {},
            referenceTranslations: {},
            form: {
                reqMachineCodeVerification: {
                    id: null,
                    value: false,
                },
                lineLeaderConfirmation: {
                    id: null,
                    value: false
                },
                allowLineLeaderReopenTicket: {
                    id: null,
                    value: false
                },
                allowedTimeToReopen: {
                    id: null,
                    value: null
                },
                emailNotification: {
                    RT1Dur: {
                        id: null,
                        value: null
                    },
                    RT1Notif: {
                        id: null,
                        value: null,
                    },
                    RT1Email1: {
                        id: null,
                        value: null,
                    },
                    RT1Email2: {
                        id: null,
                        value: null,
                    },
                    RT1Email3: {
                        id: null,
                        value: null,
                    },
                    RT2Dur: {
                        id: null,
                        value: null,
                    },
                    RT2Notif: {
                        id: null,
                        value: null,
                    },
                    RT2Email1: {
                        id: null,
                        value: null,
                    },
                    RT2Email2: {
                        id: null,
                        value: null,
                    },
                    RT2Email3: {
                        id: null,
                        value: null,
                    },
                    RT3Dur: {
                        id: null,
                        value: null,
                    },
                    RT3Notif: {
                        id: null,
                        value: null,
                    },
                    RT3Email1: {
                        id: null,
                        value: null,
                    },
                    RT3Email2: {
                        id: null,
                        value: null,
                    },
                    RT3Email3: {
                        id: null,
                        value: null,
                    },
                },
                ReqType001: {
                    id: null,
                    value: null,
                    isActive: null
                },
                ReqType002: {
                    id: null,
                    value: null,
                    isActive: null
                },
                ReqType003: {
                    id: null,
                    value: null,
                    isActive: null
                },
                ReqType004: {
                    id: null,
                    value: null,
                    isActive: null
                },
                autoGenerateMaintenanceTikcet: {
                    id: null,
                    value: false
                },
                reference: {},
            },
            formErrors: [],
            email: '',
            langTranslation: null
        }
        this.handleRefChange = this.handleRefChange.bind(this);
        
    }
    emailRef = React.createRef();
    /** handler */

    convert = () => {
        let { 
            YES, 
            NO, 
            SAVE, 
            CANCEL, 
            NOTIFICATIONS: { are_you_sure_want_cancel }, 
            EMAIL_AD_1, 
            EMAIL_AD_2, 
            EMAIL_AD_3, 
            ALLOW_TIME_MIN, 
            REPAIR_TICKET_CLOSURE, 
            LINE_LEADER_CONFIRMATION_REQUIRED, 
            ALLOW_LINE_LEADER_TO_REOPEN_TICKET, 
            ESCALATION_TIME, 
            REPORT_TICKET_MIN,
            NOTIFICATION, 
            IN_REPAIR_MIN, 
            REPAIRED_MIN, 
            LINE_LEADER_REQUEST_TYPES, 
            MAINTENANCE_TICKET, 
            AUTO_GENERATE_MAINTENANCE_TICKET, 
            CHANGE_MACHINE, 
            REQUEST_INSPECTION, 
            MOVE_MACHINE, 
            REQUEST_MAINTENANCE, 
            CUSTOM_FIELD, 
            CHANGE_FIELD_NAME,
            ORIGINAL_FIELD,
        } = dictionary.GET()
        return dictionary.TRANSLATE({ YES, NO, SAVE, CANCEL, are_you_sure_want_cancel, EMAIL_AD_1, EMAIL_AD_2, EMAIL_AD_3, ALLOW_TIME_MIN, REPAIR_TICKET_CLOSURE, LINE_LEADER_CONFIRMATION_REQUIRED, ALLOW_LINE_LEADER_TO_REOPEN_TICKET, ESCALATION_TIME, REPORT_TICKET_MIN, NOTIFICATION, IN_REPAIR_MIN, REPAIRED_MIN, LINE_LEADER_REQUEST_TYPES, MAINTENANCE_TICKET, AUTO_GENERATE_MAINTENANCE_TICKET, CHANGE_MACHINE, REQUEST_INSPECTION, MOVE_MACHINE, REQUEST_MAINTENANCE, CUSTOM_FIELD, CHANGE_FIELD_NAME, ORIGINAL_FIELD})
    }

    handleFormChange = ({ target }) => {
        let tmpForm = this.state.form;
        tmpForm[target.name] = target.value;
        this.setState({ form: tmpForm, hasFormChanges: true });
        this.removeErrorOnChange(target.name);
    };

    handleRadioButtonChange = ({ target }) => {
        let tmpForm = this.state.form;
        tmpForm[target.name] = parseInt(target.value);
        this.setState({ form: tmpForm, hasFormChanges: true });
        this.removeErrorOnChange(target.name);
    };

    checkIfApiLoading() {
        let flag = checkIfApiLoading([
            this.props.brandDetailLoading,
        ]);
        return flag;
    }

    async getTranslations() {
        try {
            const translations = await getFactoryLanguage();
            this.setState({ langTranslation: translations });
        } catch (error) {
            console.error("Error fetching translations:", error);
        }
    }
    updateReferenceTranslations = (newTranslations) => {
        this.setState({ referenceTranslations: newTranslations, hasFormChanges: true });
    };
    updateTranslationErrors = (errors) => {
        this.setState({ translationErrors: errors });
    };
    componentDidMount() {
        this.props.getSystemPreference();
        this.getTranslations();
    }
    
    componentDidUpdate(prevProps) {
        const { translation } = this.props;
        const { translations } = translation;
        const { timeStamp } = translations;
        const { translationTimestamp } = this.state;
        if (translation && timeStamp != translationTimestamp) {
            this.setState({
              translationTimestamp: timeStamp,
              translations: Object.assign({}, { ...this.convert() }),
            })
          }
    }
      

    getConfig(props, tag, type) {
        let config = props.systemPreference.filter((item) => { return item.config === tag });
        if (config.length > 0) {
            if (type === 'id') {
                return config[0].id
            }
            else if (type === 'value') {
                return config[0].value
            }
            else if (type === 'isActive') {
                return config[0].isActive
            }
            else {
                return config[0]
            }
        }
        else {
            return null;
        }
    }

    getConfigRequestTypes(props) {
        let requestTypes = props.systemPreference.filter((item) => { return item.config.indexOf('ReqType') >= 0 }).map((item) => {
            let itemRow = {
                id: item.id,
                isActive: item.isActive,
                value: item.value
            }
            return itemRow;
        });
        if (requestTypes.length > 0) {
            return requestTypes
        }
        else {
            return null;
        }
    }

    componentWillReceiveProps(nextProps) {
        let referenceData = {};
        if (nextProps.reference) {
            nextProps.reference.forEach((item) => {
                referenceData[item.constant] = item.name;
            });
        }

        let translationsData = nextProps.translations;

        if (nextProps.systemPreference !== undefined && nextProps.systemPreference !== null) {
            let requestTypes = this.getConfigRequestTypes(nextProps);
            let form = {
                reqMachineCodeVerification: {
                    id: this.getConfig(nextProps, "ReqMachineVerification", "id"),
                    value: this.getConfig(nextProps, "ReqMachineVerification", "value") === "Y" ? true : false,
                },
                lineLeaderConfirmation: {
                    id: this.getConfig(nextProps, "CloseTag", "id"),
                    value: this.getConfig(nextProps, "CloseTag", "value") === "Y" ? true : false
                },
                allowLineLeaderReopenTicket: {
                    id: this.getConfig(nextProps, "ReopenTag", "id"),
                    value: this.getConfig(nextProps, "ReopenTag", "value") === "Y" ? true : false
                },
                allowedTimeToReopen: {
                    id: this.getConfig(nextProps, "AllowedMins", "id"),
                    value: this.getConfig(nextProps, "AllowedMins", "value")
                },
                emailNotification: {
                    RT1Dur: {
                        id: this.getConfig(nextProps, "RT1Dur", "id"),
                        value: this.getConfig(nextProps, "RT1Dur", "value")
                    },
                    RT1Notif: {
                        id: this.getConfig(nextProps, "RT1Notif", "id"),
                        value: this.getConfig(nextProps, "RT1Notif", "value") === "Y" ? true : false
                    },
                    RT1Email1: {
                        id: this.getConfig(nextProps, "RT1Email1", "id"),
                        value: this.getConfig(nextProps, "RT1Email1", "value")
                    },
                    RT1Email2: {
                        id: this.getConfig(nextProps, "RT1Email2", "id"),
                        value: this.getConfig(nextProps, "RT1Email2", "value")
                    },
                    RT1Email3: {
                        id: this.getConfig(nextProps, "RT1Email3", "id"),
                        value: this.getConfig(nextProps, "RT1Email3", "value")
                    },
                    RT2Dur: {
                        id: this.getConfig(nextProps, "RT2Dur", "id"),
                        value: this.getConfig(nextProps, "RT2Dur", "value")
                    },
                    RT2Notif: {
                        id: this.getConfig(nextProps, "RT2Notif", "id"),
                        value: this.getConfig(nextProps, "RT2Notif", "value") === "Y" ? true : false
                    },
                    RT2Email1: {
                        id: this.getConfig(nextProps, "RT2Email1", "id"),
                        value: this.getConfig(nextProps, "RT2Email1", "value")
                    },
                    RT2Email2: {
                        id: this.getConfig(nextProps, "RT2Email2", "id"),
                        value: this.getConfig(nextProps, "RT2Email2", "value")
                    },
                    RT2Email3: {
                        id: this.getConfig(nextProps, "RT2Email3", "id"),
                        value: this.getConfig(nextProps, "RT2Email3", "value")
                    },
                    RT3Dur: {
                        id: this.getConfig(nextProps, "RT3Dur", "id"),
                        value: this.getConfig(nextProps, "RT3Dur", "value")
                    },
                    RT3Notif: {
                        id: this.getConfig(nextProps, "RT3Notif", "id"),
                        value: this.getConfig(nextProps, "RT3Notif", "value") === "Y" ? true : false
                    },
                    RT3Email1: {
                        id: this.getConfig(nextProps, "RT3Email1", "id"),
                        value: this.getConfig(nextProps, "RT3Email1", "value")
                    },
                    RT3Email2: {
                        id: this.getConfig(nextProps, "RT3Email2", "id"),
                        value: this.getConfig(nextProps, "RT3Email2", "value")
                    },
                    RT3Email3: {
                        id: this.getConfig(nextProps, "RT3Email3", "id"),
                        value: this.getConfig(nextProps, "RT3Email3", "value")
                    },
                },
                ReqType001: {
                    id: this.getConfig(nextProps, "ReqType001", "id"),
                    value: this.getConfig(nextProps, "ReqType001", "value"),
                    isActive: this.getConfig(nextProps, "ReqType001", "isActive"),
                },
                ReqType002: {
                    id: this.getConfig(nextProps, "ReqType002", "id"),
                    value: this.getConfig(nextProps, "ReqType002", "value"),
                    isActive: this.getConfig(nextProps, "ReqType002", "isActive"),
                },
                ReqType003: {
                    id: this.getConfig(nextProps, "ReqType003", "id"),
                    value: this.getConfig(nextProps, "ReqType003", "value"),
                    isActive: this.getConfig(nextProps, "ReqType003", "isActive"),
                },
                ReqType004: {
                    id: this.getConfig(nextProps, "ReqType004", "id"),
                    value: this.getConfig(nextProps, "ReqType004", "value"),
                    isActive: this.getConfig(nextProps, "ReqType004", "isActive"),
                },
                autoGenerateMaintenanceTikcet: {
                    id: this.getConfig(nextProps, "", "id"),
                    value: this.getConfig(nextProps, "AutoGenPM", "value") === "Y" ? true : false
                },
                reference: referenceData,
                translations: translationsData
            };
            this.setState({ form: form }, () => {
                let tmpForm = Object.assign({}, this.state.form);
                this.setState({ oldFormData: tmpForm })
            });
        }
    }

    renderBooleanAsInteger(bool) {
        if (bool === true) {
            return 1
        }
        else {
            return 0
        }
    }

    removeErrorOnChange(field) {
        let errors = this.state.formErrors.filter((item) => { return item.name !== field });
        this.setState({ formErrors: errors });
    }

    hasErrors = (field) => {
        let checkField = this.state.formErrors.filter((item) => { return item.name === field });
        if (checkField.length > 0) {
            return true
        }
        else {
            return false;
        }
    }

    renderText(field) {
        if (field === null || field === '') {
            return '-'
        }
        else {
            //   return field != undefined ? field.toString().translate() : field
            return field
        }
    }

    resetForm() {
        let tmpForm = {
            code: null,
            name: null,
            company: null,
            website: null,
            desc: null,
            isActive: 1,
        }
        this.setState({ form: tmpForm });
    }



    renderDate(date) {
        if (date !== null) {
            let newDate = date.substring(0, date.indexOf('T'));
            return moment(newDate).format('DD-MM-YYYY');
        }
        else {
            return '-';
        }
    }

    renderRequestTypes() {
        // let requestTypes = this.state.form.requestTypes.map((item) => {
        //      let itemRow = (
        //         <FormControl fullWidth>
        //             <Grid container spacing={0}>
        //                 <Grid item xs={9}>
        //                     <div className="form-label">{ item.value }</div>
        //                 </Grid>
        //                 <Grid item xs={3}>
        //                     <Switch disabled={checkPageAccessField(this.props.userAccess,{pageId:24,pageAccess: 'VIEW'})} onClick={() => this.handleRequestType(item.id)} checked={item.isActive} className="control-switch" color="primary" />
        //                 </Grid>
        //             </Grid>
        //         </FormControl>
        //      )
        //      return itemRow;
        // });
        let requestTypes = (
            <div>
                <FormControl fullWidth>
                    <Grid container spacing={0}>
                        <Grid item xs={9}>
                            <div className="form-label">{this.state.form.ReqType001.value?.toString()?.translate()}</div>
                        </Grid>
                        <Grid item xs={3}>
                            <Switch disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })} onClick={this.handleRequestType001} checked={this.state.form.ReqType001.isActive} className="control-switch" color="primary" />
                        </Grid>
                    </Grid>
                </FormControl>
                <FormControl fullWidth>
                    <Grid container spacing={0}>
                        <Grid item xs={9}>
                            <div className="form-label">{this.state.form.ReqType002.value?.toString()?.translate()}</div>
                        </Grid>
                        <Grid item xs={3}>
                            <Switch disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })} onClick={this.handleRequestType002} checked={this.state.form.ReqType002.isActive} className="control-switch" color="primary" />
                        </Grid>
                    </Grid>
                </FormControl>
                <FormControl fullWidth>
                    <Grid container spacing={0}>
                        <Grid item xs={9}>
                            <div className="form-label">{this.state.form.ReqType003.value?.toString()?.translate()}</div>
                        </Grid>
                        <Grid item xs={3}>
                            <Switch disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })} onClick={this.handleRequestType003} checked={this.state.form.ReqType003.isActive} className="control-switch" color="primary" />
                        </Grid>
                    </Grid>
                </FormControl>
                <FormControl fullWidth>
                    <Grid container spacing={0}>
                        <Grid item xs={9}>
                            <div className="form-label">{this.state.form.ReqType004.value?.toString()?.translate()}</div>
                        </Grid>
                        <Grid item xs={3}>
                            <Switch disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })} onClick={this.handleRequestType004} checked={this.state.form.ReqType004.isActive} className="control-switch" color="primary" />
                        </Grid>
                    </Grid>
                </FormControl>
            </div>
        )
        return requestTypes;
    }

    renderMaintenanceTicket() {
        const { translations } = this.state;

        return <div>
            <FormControl fullWidth>
                <Grid container spacing={0}>
                    <Grid item xs={9}>
                        <div className="form-label">{translations.AUTO_GENERATE_MAINTENANCE_TICKET}</div>
                    </Grid>
                    <Grid item xs={3}>
                        <Switch 
                            disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })} 
                            onClick={this.handleAutoGenerateMaintenanceTicket}
                            checked={this.state.form.autoGenerateMaintenanceTikcet.value}
                            className="control-switch"
                            color="primary" />
                    </Grid>
                </Grid>
            </FormControl>
        </div>;
    }

    
    

    renderTextField(labelText, value, onChangeHandler, fieldName) {
        return (
          <FormControl fullWidth>
            <InputLabel htmlFor={fieldName}>{labelText}</InputLabel>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <TextField
                  id={fieldName}
                  className="text-field"
                  label='Display Name'
                  type="text"
                  value={value}
                  onChange={(e) => onChangeHandler(e.target.value, fieldName)}
                  fullWidth
                  aria-label={`Enter ${labelText}`}
                />
              </Grid>
            </Grid>
          </FormControl>
        );
      }
      
    getErrors = (field) => {
        let checkField = this.state.formErrors.filter((item) => { return item.name === field });
        let errorString = null;
        checkField.map((item) => {
            errorString += item.message + '. ';
            return '';
        })
        return errorString;
    }

    handleLinkClick = (url) => {
        if (this.state.id) {
            history.push(url);
        }
        else {
            this.setState({ disablePrompt: true }, () => {
                history.push(url);
            })
        }
    }

    /* REQUEST TYPE HANDLER */


    handleRequestType001 = () => {
        let formData = {
            ...this.state.form,
            ReqType001: {
                ...this.state.form.ReqType001,
                isActive: !this.state.form.ReqType001.isActive
            }
        }
        this.setState({ form: formData, hasFormChanges: true }, () => {
            let char = this.state.form.ReqType001.isActive ? 'Y' : 'N';
        });
    }

    handleRequestType002 = () => {
        let formData = {
            ...this.state.form,
            ReqType002: {
                ...this.state.form.ReqType002,
                isActive: !this.state.form.ReqType002.isActive
            }
        }
        this.setState({ form: formData, hasFormChanges: true }, () => {
            let char = this.state.form.ReqType002.isActive ? 'Y' : 'N';
        });
    }

    handleRequestType003 = () => {
        let formData = {
            ...this.state.form,
            ReqType003: {
                ...this.state.form.ReqType003,
                isActive: !this.state.form.ReqType003.isActive
            }
        }
        this.setState({ form: formData, hasFormChanges: true }, () => {
            let char = this.state.form.ReqType003.isActive ? 'Y' : 'N';
        });
    }

    handleRequestType004 = () => {
        let formData = {
            ...this.state.form,
            ReqType004: {
                ...this.state.form.ReqType004,
                isActive: !this.state.form.ReqType004.isActive
            }
        }
        this.setState({ form: formData, hasFormChanges: true }, () => {
            let char = this.state.form.ReqType004.isActive ? 'Y' : 'N';
        });
    }

    resetAllowedReopenTime() {
        let formData = {
            ...this.state.form,
            allowedTimeToReopen: {
                ...this.state.form.allowedTimeToReopen,
                value: 0
            }
        }
        this.setState({ form: formData }, () => {
        });
    }

    handleReqMachineVerification = () => {
        const { form } = this.state;
        const { reqMachineCodeVerification } = form;
        const { value } = reqMachineCodeVerification;
        this.setState({
            hasFormChanges: true,
            form: {
                ...form,
                reqMachineCodeVerification: {
                    ...reqMachineCodeVerification,
                    value: !value,
                },
            },
        });
    }

    handleLineLeaderConfirmation = () => {
        let formData = {
            ...this.state.form,
            lineLeaderConfirmation: {
                ...this.state.form.lineLeaderConfirmation,
                value: !this.state.form.lineLeaderConfirmation.value
            },
        }
        if (formData.lineLeaderConfirmation.value === true) {
            formData = {
                ...formData,
                allowLineLeaderReopenTicket: {
                    id: null,
                    value: false
                },
                allowedTimeToReopen: {
                    id: null,
                    value: null
                },
            }
        }
        this.setState({ form: formData, hasFormChanges: true }, () => {
            let char = this.state.form.lineLeaderConfirmation.value ? 'Y' : 'N';
        });
    }

    handleLineLeaderAllowReopen = () => {
        let formData = {
            ...this.state.form,
            allowLineLeaderReopenTicket: {
                ...this.state.form.allowLineLeaderReopenTicket,
                value: !this.state.form.allowLineLeaderReopenTicket.value
            }
        }
        this.setState({ form: formData, hasFormChanges: true }, () => {
            let char = this.state.form.allowLineLeaderReopenTicket.value ? 'Y' : 'N';
            this.resetAllowedReopenTime();
        });
    }

    handleAllowedReopenTime = ({ target }) => {


        if (!isNaN(target.value)) {
            clearTimeout(this.state.typingTimeout);
            let typingTimeout = setTimeout(() => {
                if (isNaN(target.value)) {
                    this.props.notificationError('Numbers only!');
                }
                else if (target.value === null || target.value === "") {
                    this.props.notificationError('Please fill up required field!');
                }
                else {
                    this.setState({ form: formData, hasFormChanges: true }, () => {
                    });
                }
            }, 1000);

            let value = target.value;
            if (value === "") {
                value = 0
            }

            let formData = {
                ...this.state.form,
                allowedTimeToReopen: {
                    ...this.state.form.allowedTimeToReopen,
                    value: value
                }
            }
            this.setState({ typingTimeout: typingTimeout, form: formData });

        }
    }

    handleTicketTime = ({ target }) => {
        if (!isNaN(target.value)) {
            clearTimeout(this.state.typingTimeout);
            let typingTimeout = setTimeout(() => {
                if (isNaN(target.value)) {
                    this.props.notificationError('Numbers only!');
                }
                else if (target.value === null || target.value === "") {
                    this.props.notificationError('Please fill up required field!');
                }
                else {
                    this.setState({ form: formData, hasFormChanges: true }, () => {
                    });
                }
            }, 1000);

            let value = target.value;
            if (value === "") {
                value = 15
            }

            let formData = {
                ...this.state.form,
                emailNotification: {
                    ...this.state.form.emailNotification,
                    [target.name]: {
                        ...this.state.form.emailNotification[target.name],
                        value: value
                    }
                }
            }
            this.setState({ typingTimeout: typingTimeout, form: formData });

        }
    }

    handleNotification = ({ target }) => {
        let formData = {
            ...this.state.form,
            emailNotification: {
                ...this.state.form.emailNotification,
                [target.name]: {
                    ...this.state.form.emailNotification[target.name],
                    value: !this.state.form.emailNotification[target.name].value
                }
            }
        }
        this.setState({ form: formData, hasFormChanges: true }, () => {
            let char = this.state.form.emailNotification[target.name].value ? 'Y' : 'N';
        });
    }

    handleEmail = ({ target }) => {
        clearTimeout(this.state.typingTimeout);
        let typingTimeout = setTimeout(() => {
            this.setState({ form: formData, hasFormChanges: true }, () => {
            });
        }, 1000);

        let formData = {
            ...this.state.form,
            emailNotification: {
                ...this.state.form.emailNotification,
                [target.name]: {
                    ...this.state.form.emailNotification[target.name],
                    value: target.value
                }
            }
        }
        this.setState({ typingTimeout: typingTimeout, form: formData });
    }

    handleRequestType(id) {
        let value = null;
        let isActive = null;
        let requestTypes = this.state.form.requestTypes.map((item) => {
            let itemRow = item;
            if (item.id === id) {
                itemRow.isActive = !item.isActive;
                isActive = itemRow.isActive;
                value = itemRow.value;
            }
            return itemRow;
        })
        let formData = {
            ...this.state.form,
            requestTypes: requestTypes
        }
        this.setState({ form: formData, hasFormChanges: true }, () => {
            console.log(this.state.oldFormData);
        });
    }

    handleAutoGenerateMaintenanceTicket = () => {
        let formData = {
            ...this.state.form,
            autoGenerateMaintenanceTikcet: {
                ...this.state.form.autoGenerateMaintenanceTikcet,
                value: !this.state.form.autoGenerateMaintenanceTikcet.value
            },
        }
        this.setState({ form: formData, hasFormChanges: true });
    }

    handleRefChange(referenceName, event) {
        const { form } = this.state;
        const updatedReferenceValue = event.target.value;
        const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\|'"=]/;
        const truncatedValue = updatedReferenceValue.slice(0, 35);
        const updatedReference = {
            ...form.reference,
            [referenceName]: truncatedValue,
        };
        if (specialCharacterRegex.test(updatedReferenceValue)) {
            this.setState((prevState) => ({
                refValidationError: {
                    ...prevState.refValidationError,
                    [referenceName]: 'Special characters are not allowed.',
                },
            }));
        } else if (updatedReferenceValue.length >= 35) {
            this.setState((prevState) => ({
                refValidationError: {
                    ...prevState.refValidationError,
                    [referenceName]: 'Maximum of 35 characters.',
                },
            }));
        } else {
            this.setState((prevState) => ({
                refValidationError: {
                    ...prevState.refValidationError,
                    [referenceName]: '',
                },
            }));
        }
        const updatedForm = {
            ...form,
            reference: updatedReference,
        };
        this.setState({ form: updatedForm, hasFormChanges: true });
    }

    getRequestTypeValue(name) {
        if (name) {
            let requestType = this.state.form.requestTypes.filter((item) => { return item.config === name });
            if (requestType.length > 0) {
                return requestType[0].isActive;
            }
            else {
                return 0
            }
        }
    }

    submitForm = () => {
        const hasRefErrors = Object.values(this.state.refValidationError).some((error) => error !== '');
        const hasTransErrors = Object.values(this.state.translationErrors).some((error) => error !== '');
        if (hasRefErrors || hasTransErrors) {
            this.props.notificationError("Please input valid fields!".translate());
            return;
          }
        let payload = {
            config: {
                "ReqMachineVerification": this.state.form.reqMachineCodeVerification.value ? "Y" : "N",
                "AllowedMins": this.state.form.allowedTimeToReopen.value,
                "CloseTag": this.state.form.lineLeaderConfirmation.value ? "Y" : "N",
                "ReopenTag": this.state.form.allowLineLeaderReopenTicket.value ? "Y" : "N",
                "ReqType001": this.state.form.ReqType001.isActive ? "1" : "0",
                "ReqType002": this.state.form.ReqType002.isActive ? "1" : "0",
                "ReqType003": this.state.form.ReqType003.isActive ? "1" : "0",
                "ReqType004": this.state.form.ReqType004.isActive ? "1" : "0",
                "RT1Dur": this.state.form.emailNotification.RT1Dur.value,
                "RT1Email1": this.state.form.emailNotification.RT1Email1.value,
                "RT1Email2": this.state.form.emailNotification.RT1Email2.value,
                "RT1Email3": this.state.form.emailNotification.RT1Email3.value,
                "RT1Notif": this.state.form.emailNotification.RT1Notif.value ? "Y" : "N",
                "RT2Dur": this.state.form.emailNotification.RT2Dur.value,
                "RT2Email1": this.state.form.emailNotification.RT2Email1.value,
                "RT2Email2": this.state.form.emailNotification.RT2Email2.value,
                "RT2Email3": this.state.form.emailNotification.RT2Email3.value,
                "RT2Notif": this.state.form.emailNotification.RT2Notif.value ? "Y" : "N",
                "RT3Dur": this.state.form.emailNotification.RT3Dur.value,
                "RT3Email1": this.state.form.emailNotification.RT3Email1.value,
                "RT3Email2": this.state.form.emailNotification.RT3Email2.value,
                "RT3Email3": this.state.form.emailNotification.RT3Email3.value,
                "RT3Notif": this.state.form.emailNotification.RT3Notif.value ? "Y" : "N",
                "AutoGenPM": this.state.form.autoGenerateMaintenanceTikcet.value ? "Y" : "N",
            },
            reference: this.state.form.reference,
            translations: this.state.referenceTranslations,
        }
        this.props.putUpdateSystemPreference(payload);
        let tmpForm = Object.assign({}, this.state.form);
        this.setState({ hasFormChanges: null, oldFormData: tmpForm });
    }

    handleBlur = (event) => {
        this.emailRef.current.validate(event.target.value);
    }

    renderAllowedReopenTime() {
        let { translations } = this.state
        return (
            <FormControl fullWidth>
                <Grid container spacing={0}>
                    <Grid item xs={9}>
                        <div className="form-label">{translations.ALLOW_TIME_MIN}<span className="required">*</span></div>
                    </Grid>
                    <Grid item xs={2}>
                        <div className="settings-input">
                            <TextField
                                disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })}
                                className="preference-input" onChange={this.handleAllowedReopenTime}
                                value={this.state.form.allowedTimeToReopen.value} name="" />
                        </div>
                    </Grid>
                </Grid>
            </FormControl>
        )
    }
    renderEmail(name) {
        const { email } = this.state;
        let { translations } = this.state
        if (name === 'RT1') {
            return (
                <div>
                    <FormControl className="" fullWidth>
                        <Grid container spacing={0}>
                            <Grid item xs={1} />
                            <Grid item xs={11}>
                                <div className="email-control">
                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.submitForm}
                                        onError={errors => console.log(errors)}
                                        debounceTime={1500}
                                    >
                                        <TextValidator
                                            disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })}
                                            value={this.state.form.emailNotification.RT1Email1.value}
                                            name="RT1Email1"
                                            onChange={this.handleEmail}
                                            placeholder={translations.EMAIL_AD_1}
                                            fullWidth
                                            validators={["required", "isEmail"]}
                                            errorMessages={["this field is required", "email is not valid"]}
                                        />
                                    </ValidatorForm>
                                </div>
                            </Grid>
                        </Grid>
                    </FormControl>
                    <FormControl className="" fullWidth>
                        <Grid container spacing={0}>
                            <Grid item xs={1} />
                            <Grid item xs={11}>
                                <div className="email-control">
                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.submitForm}
                                        onError={errors => console.log(errors)}
                                        debounceTime={1500}
                                    >
                                        <TextValidator
                                            disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })}
                                            value={this.state.form.emailNotification.RT1Email2.value}
                                            name="RT1Email2"
                                            onChange={this.handleEmail}
                                            placeholder={translations.EMAIL_AD_2}
                                            fullWidth
                                            validators={["required", "isEmail"]}
                                            errorMessages={["this field is required", "email is not valid"]}
                                        />
                                    </ValidatorForm>
                                </div>
                            </Grid>
                        </Grid>
                    </FormControl>
                    <FormControl className="" fullWidth>
                        <Grid container spacing={0}>
                            <Grid item xs={1} />
                            <Grid item xs={11}>
                                <div className="email-control">
                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.submitForm}
                                        onError={errors => console.log(errors)}
                                        debounceTime={1500}
                                    >
                                        <TextValidator
                                            disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })}
                                            value={this.state.form.emailNotification.RT1Email3.value}
                                            name="RT1Email3"
                                            onChange={this.handleEmail}
                                            placeholder={translations.EMAIL_AD_2}
                                            fullWidth
                                            validators={["required", "isEmail"]}
                                            errorMessages={["this field is required", "email is not valid"]}
                                        />
                                    </ValidatorForm>
                                </div>
                            </Grid>
                        </Grid>
                    </FormControl>
                </div>
            );
        }
        else if (name === 'RT2') {
            return (
                <div>
                    <FormControl className="" fullWidth>
                        <Grid container spacing={0}>
                            <Grid item xs={1} />
                            <Grid item xs={11}>
                                <div className="email-control">
                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.submitForm}
                                        onError={errors => console.log(errors)}
                                        debounceTime={1500}
                                    >
                                        <TextValidator
                                            disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })}
                                            value={this.state.form.emailNotification.RT2Email1.value}
                                            name="RT2Email1"
                                            onChange={this.handleEmail}
                                            placeholder={translations.EMAIL_AD_1}
                                            fullWidth
                                            validators={["required", "isEmail"]}
                                            errorMessages={["this field is required", "email is not valid"]}
                                        />
                                    </ValidatorForm>
                                </div>
                            </Grid>
                        </Grid>
                    </FormControl>
                    <FormControl className="" fullWidth>
                        <Grid container spacing={0}>
                            <Grid item xs={1} />
                            <Grid item xs={11}>
                                <div className="email-control">
                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.submitForm}
                                        onError={errors => console.log(errors)}
                                        debounceTime={1500}
                                    >
                                        <TextValidator
                                            disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })}
                                            value={this.state.form.emailNotification.RT2Email2.value}
                                            name="RT2Email2"
                                            onChange={this.handleEmail}
                                            placeholder={translations.EMAIL_AD_2}
                                            fullWidth
                                            validators={["required", "isEmail"]}
                                            errorMessages={["this field is required", "email is not valid"]}
                                        />
                                    </ValidatorForm>
                                </div>
                            </Grid>
                        </Grid>
                    </FormControl>
                    <FormControl className="" fullWidth>
                        <Grid container spacing={0}>
                            <Grid item xs={1} />
                            <Grid item xs={11}>
                                <div className="email-control">
                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.submitForm}
                                        onError={errors => console.log(errors)}
                                        debounceTime={1500}
                                    >
                                        <TextValidator
                                            disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })}
                                            value={this.state.form.emailNotification.RT2Email3.value}
                                            name="RT2Email3"
                                            onChange={this.handleEmail}
                                            placeholder={translations.EMAIL_AD_3}
                                            fullWidth
                                            validators={["required", "isEmail"]}
                                            errorMessages={["this field is required", "email is not valid"]}
                                        />
                                    </ValidatorForm>
                                </div>
                            </Grid>
                        </Grid>
                    </FormControl>
                </div>
            )
        }
        else if (name === 'RT3') {
            return (
                <div>
                    <FormControl className="" fullWidth>
                        <Grid container spacing={0}>
                            <Grid item xs={1} />
                            <Grid item xs={11}>
                                <div className="email-control">
                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.submitForm}
                                        onError={errors => console.log(errors)}
                                        debounceTime={1500}
                                    >
                                        <TextValidator
                                            disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })}
                                            value={this.state.form.emailNotification.RT3Email1.value}
                                            name="RT3Email1"
                                            onChange={this.handleEmail}
                                            placeholder={translations.EMAIL_AD_1}
                                            validators={["required", "isEmail"]}
                                            errorMessages={["this field is required", "email is not valid"]}
                                            fullWidth
                                        />
                                    </ValidatorForm>
                                </div>
                            </Grid>
                        </Grid>
                    </FormControl>
                    <FormControl className="" fullWidth>
                        <Grid container spacing={0}>
                            <Grid item xs={1} />
                            <Grid item xs={11}>
                                <div className="email-control">
                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.submitForm}
                                        onError={errors => console.log(errors)}
                                        debounceTime={1500}
                                    >
                                        <TextValidator
                                            disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })}
                                            value={this.state.form.emailNotification.RT3Email2.value}
                                            name="RT3Email2"
                                            onChange={this.handleEmail}
                                            placeholder={translations.EMAIL_AD_2}
                                            validators={["required", "isEmail"]}
                                            errorMessages={["this field is required", "email is not valid"]}
                                            fullWidth
                                        />
                                    </ValidatorForm>
                                </div>
                            </Grid>
                        </Grid>
                    </FormControl>
                    <FormControl className="" fullWidth>
                        <Grid container spacing={0}>
                            <Grid item xs={1} />
                            <Grid item xs={11}>
                                <div className="email-control">
                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.submitForm}
                                        onError={errors => console.log(errors)}
                                        debounceTime={1500}
                                    >
                                        <TextValidator
                                            disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })}
                                            value={this.state.form.emailNotification.RT3Email3.value} name="RT3Email3" onChange={this.handleEmail}
                                            placeholder={translations.EMAIL_AD_3}
                                            validators={["required", "isEmail"]}
                                            errorMessages={["this field is required", "email is not valid"]}
                                            fullWidth />
                                    </ValidatorForm>
                                </div>
                            </Grid>
                        </Grid>
                    </FormControl>
                </div>
            )
        }
    }

    cancelChanges = () => {
        let { translations } = this.state
        confirmAlert({
            title: translations.are_you_sure_want_cancel,
            message: '',
            buttons: [
                {
                    label: translations.YES,
                    onClick: () => {
                        let tmpForm = {
                            ...this.state.oldFormData,
                            requestTypes: this.state.oldFormData.requestTypes
                        }
                        console.log(tmpForm);
                        this.setState({ form: tmpForm, hasFormChanges: null })
                    }
                },
                {
                    label: translations.NO,
                }
            ]
        });
    }

    content() {
        let { translations, form, refValidationError } = this.state
        const { reference } = this.props;

        return (
            <div>
                <div className="form-container">
                    <Paper className="body">
                        <div className="title">{'Repair Ticket Configurations'.translate()}</div>
                        <div className="form-input">
                        <FormControl fullWidth>
                                <Grid container spacing={0}>
                                    <Grid item xs={9}>
                                        <div className="form-label">{'Machine Code verification required'.translate()}</div>
                                        <span className='helper'>{'* If the toggle is on, the app user has to verify the machine code to grab or finish a repair ticket.'.translate()}</span>
                                        <span className='helper'>{'* If off, the app will skip the machine code verification process.'.translate()}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Switch 
                                            disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })} 
                                            onClick={this.handleReqMachineVerification} 
                                            checked={this.state.form.reqMachineCodeVerification.value} 
                                            className="control-switch" 
                                            color="primary" />
                                    </Grid>
                                </Grid>
                            </FormControl>
                            <FormControl fullWidth>
                                <Grid container spacing={0}>
                                    <Grid item xs={9}>
                                        <div className="form-label">{translations.LINE_LEADER_CONFIRMATION_REQUIRED}</div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Switch disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })} onClick={this.handleLineLeaderConfirmation} checked={this.state.form.lineLeaderConfirmation.value} className="control-switch" color="primary" />
                                    </Grid>
                                </Grid>
                            </FormControl>
                            <FormControl fullWidth>
                                <Grid container spacing={0}>
                                    <Grid item xs={9}>
                                        <div className={"form-label " + (this.state.form.lineLeaderConfirmation.value === true ? 'form-disabled' : null)}>{translations.ALLOW_LINE_LEADER_TO_REOPEN_TICKET}</div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Switch disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' }) || this.state.form.lineLeaderConfirmation.value === true} onClick={this.handleLineLeaderAllowReopen} checked={this.state.form.allowLineLeaderReopenTicket.value} className="control-switch" color="primary" />
                                    </Grid>
                                </Grid>
                            </FormControl>
                            {this.state.form.allowLineLeaderReopenTicket.value ? this.renderAllowedReopenTime() : null}
                        </div>
                    </Paper>
                    <Paper className="body">
                        <div className="title">{translations.ESCALATION_TIME}</div>
                        <div className="form-input">
                            <FormControl fullWidth>
                                <Grid container spacing={0}>
                                    <Grid item xs={9}>
                                        <div className="form-label">{translations.REPORT_TICKET_MIN}</div>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div className="settings-input">
                                            <TextField disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })} className="preference-input" value={this.state.form.emailNotification.RT1Dur.value} onChange={this.handleTicketTime} name="RT1Dur" />
                                        </div>
                                    </Grid>
                                </Grid>
                            </FormControl>
                            <div className="email-container">
                                <FormControl className="" fullWidth>
                                    <Grid container spacing={0}>
                                        <Grid item xs={1} />
                                        <Grid item xs={8}>
                                            <div className="form-label">{translations.NOTIFICATION}</div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Switch disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })} name="RT1Notif" onClick={this.handleNotification} checked={this.state.form.emailNotification.RT1Notif.value} className="control-switch" color="primary" />
                                        </Grid>
                                    </Grid>
                                </FormControl>
                                {this.state.form.emailNotification.RT1Notif.value ? this.renderEmail('RT1') : null}
                            </div>

                            <FormControl fullWidth>
                                <Grid container spacing={0}>
                                    <Grid item xs={9}>
                                        <div className="form-label">{translations.IN_REPAIR_MIN}</div>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div className="settings-input">
                                            <TextField disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })} className="preference-input" value={this.state.form.emailNotification.RT2Dur.value} onChange={this.handleTicketTime} name="RT2Dur" />
                                        </div>
                                    </Grid>
                                </Grid>
                            </FormControl>
                            <div className="email-container">
                                <FormControl className="" fullWidth>
                                    <Grid container spacing={0}>
                                        <Grid item xs={1} />
                                        <Grid item xs={8}>
                                            <div className="form-label">{translations.NOTIFICATION}</div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Switch disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })} name="RT2Notif" onClick={this.handleNotification} checked={this.state.form.emailNotification.RT2Notif.value} className="control-switch" color="primary" />
                                        </Grid>
                                    </Grid>
                                </FormControl>
                                {this.state.form.emailNotification.RT2Notif.value ? this.renderEmail('RT2') : null}
                            </div>

                            <FormControl fullWidth>
                                <Grid container spacing={0}>
                                    <Grid item xs={9}>
                                        <div className="form-label">{translations.REPAIRED_MIN}</div>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div className="settings-input">
                                            <TextField disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })} className="preference-input" value={this.state.form.emailNotification.RT3Dur.value} onChange={this.handleTicketTime} name="RT3Dur" />
                                        </div>
                                    </Grid>
                                </Grid>
                            </FormControl>
                            <div className="email-container">
                                <FormControl className="" fullWidth>
                                    <Grid container spacing={0}>
                                        <Grid item xs={1} />
                                        <Grid item xs={8}>
                                            <div className="form-label">{translations.NOTIFICATION}</div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Switch disabled={checkPageAccessField(this.props.userAccess, { pageId: 24, pageAccess: 'VIEW' })} name="RT3Notif" onClick={this.handleNotification} checked={this.state.form.emailNotification.RT3Notif.value} className="control-switch" color="primary" />
                                        </Grid>
                                    </Grid>
                                </FormControl>
                                {this.state.form.emailNotification.RT3Notif.value ? this.renderEmail('RT3') : null}
                            </div>
                        </div>
                    </Paper>
                    <Paper className="body">
                        <div className="title">{translations.LINE_LEADER_REQUEST_TYPES}</div>
                        <div className="form-input">
                            {this.renderRequestTypes()}
                        </div>
                    </Paper>
                    <Paper className="body">
                        <div className="title">{translations.MAINTENANCE_TICKET}</div>
                        <div className="form-input">
                            {this.renderMaintenanceTicket()}
                        </div>
                    </Paper>
                    <Paper className="body end">
                        <div className="title">{translations.CUSTOM_FIELD}</div>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <div className="form-label">{translations.CHANGE_FIELD_NAME}</div>
                            </Grid>
                        </Grid>
                        <div className="form-input">
                            <CustomFieldsForm
                                form={form} 
                                reference={reference}
                                translations={this.state.langTranslation}
                                refValidationError={refValidationError} 
                                handleRefChange={this.handleRefChange}
                                updateReferenceTranslations={this.updateReferenceTranslations}
                                updateTranslationErrors={this.updateTranslationErrors}
                            />
                        </div>
                        
                    </Paper>
                    <div className={"footer-actions " + (this.state.hasFormChanges ? "show" : null)}>
                        <Grid container spacing={1}>
                            <Grid item xs={5} />
                            <Grid item xs={1}>
                                {/* <Link to="/configuration/machine-config/brands"> */}
                                <Button onClick={this.cancelChanges} className="cancel" fullWidth variant="outlined">{translations.CANCEL}</Button>
                                {/* </Link> */}
                            </Grid>
                            <Grid item xs={1}>
                                <Button onClick={this.submitForm} className="save" fullWidth color="primary" variant="contained">{translations.SAVE}</Button>
                            </Grid>
                            <Grid item xs={5} />
                        </Grid>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <MainPage footer={true} className='system-preference'>
                <MainPageHeader title="System Preference" search={false} />
                <MainPageBody>
                    {this.content()}
                </MainPageBody>
            </MainPage>
        );
    }
}

const mapStateToProps = state => {
    let { translationReducer } = state
    let apiLoading = {
    }
    let data = {
        userAccess: state.authReducer.access,
        systemPreference: state.preferenceReducer.systemPreference,
        reference: state.preferenceReducer.reference,
        translations: state.preferenceReducer.translations
    }

    return {
        ...apiLoading,
        ...data,
        ...translationReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSystemPreference: () => dispatch(getSystemPreference()),
        putUpdateSystemPreference: (payload) => dispatch(putUpdateSystemPreference(payload)),
        notificationError: (message) => dispatch(notificationError(message)),
    }
}

SystemPreference = connect(mapStateToProps, mapDispatchToProps)(SystemPreference);

export default SystemPreference;
