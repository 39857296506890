import { tokenGet, tokenPost, apiSettings, tokenPut } from '../../../utils/api/CoreApi';
import axios from "axios";
import store from '../../store'
import { DASHBOARD_ACTIONS as actions } from '../actionTypes'
import Socket from 'socket.io-client'
const queryActions = {}
const componentActions = {}
let { dispatch } = store
let { apiURLTickets, apiURLTicketsSocket } = apiSettings
let io
queryActions.getDashboardList = async (factory, token) => {
    try {
        // console.log('-factoryfactoryfactory-', factory)
        // console.log('-tokentokentoken-', token)

        // let { data, status } = await axios.get(`${apiURLTickets}/api/tickets/dashboard`)
        let { data, status } = await axios.get(`${apiURLTickets}/api/tickets/dashboard/overall?factory=${factory}&accessToken=${token}`);

        // console.log('-datadata-', data)
        // console.log('-statusstatus-', status)

        data['topNptToday'].map((row, i) => {
            if (i < 5) return row
        })
        data['topNptToday'].length > 5 && (data['topNptToday'].length = 5)
        let filterData = {
            timeStamp: new Date(),
            repair: {
                reported: 0,
                inRepair: 0,
                closed: 0,
            },
            maintenance: {
                scheduled: 0,
                inProgress: 0,
                completed: 0,
            },
            changeOver: {
                open: 0,
                inProcess: 0,
                complete: 0
            },
            '5npt': {
                list: data['topNptToday']
            },
            reportedList: {
                list: data['topReportedTicketsToday'],
                avgTime: data['avgResponseTime'][0]['averageDuration'],
                limit: data['reportedTicketDuration']['value'],
                limitType: data['reportedTicketDuration']['uom']
            },
            repairList: {
                list: data['topInRepairTicketsToday'],
                avgTime: data['avgRepairTime'][0]['averageDuration'],
                limit: data['inRepairTicketDuration']['value'],
                limitType: data['inRepairTicketDuration']['uom']
            },

        }
        let filterTicketCounts = () => {

            data['ticketCounts'].forEach(x => {
                switch (x['status']) {
                    case 'reported':
                        filterData.repair.reported = x['count']
                    case 'in-repair':
                        filterData.repair.inRepair = x['count']
                    case 'repaired':
                        filterData.repair.closed = x['count']
                    case 'scheduled':
                        filterData.maintenance.scheduled = x['count']
                    case 'in-progress':
                        filterData.maintenance.inProgress = x['count']
                    case 'completed':
                        filterData.maintenance.completed = x['count']
                    default:
                        return null

                }
            })
        }
        filterTicketCounts() //filter the ticketsCount array
        dispatch({
            type: actions.DASHBOARD_LIST,
            payload: {
                tickets: filterData,
                status
            }
        })
    } catch (error) {
        console.error('dashboardAction', error)
    }
}
queryActions.getAvgTime = async (factory) => {
    let repair, response
    // let { data, status } = await axios.get(`${apiURLTickets}api/tickets/dashboard/overall?factory=${factory}&accessToken=${token}`);
    // https://ltm-dev.ltlabs.co/msv/tickets/api/tickets/avg-repair-time/overall?factory=STX
    // https://ltm-dev.ltlabs.co/msv/tickets/api/tickets/avg-response-time/overall?factory=STX
    var { data, status } = await axios.get(`${apiURLTickets}/api/tickets/avg-repair-time/overall?factory=${factory}`)
    repair = data['data'][0]['averageDuration']
    var { data, status } = await axios.get(`${apiURLTickets}/api/tickets/avg-response-time/overall?factory=${factory}`)
    response = data['data'][0]['averageDuration']
    dispatch({
        type: actions.DASHBOARD_AVG,
        payload: {
            avgTime: {
                avgTimeStamp: new Date(),
                repairAvg: repair,
                responseAvg: response
            }
        }
    })
}

queryActions.socketOn = () => {
    // io = Socket(apiURLTickets)
    // io.on('connect', ev => {
    //     console.log('connected', new Date().getMinutes(), ev)
    //     io.emit('authentication', {
    //         token: JSON.parse(localStorage['userToken']),
    //     })
    //     io.on('disconnect', ev => {
    //         console.log('reconnecting...', new Date().getMinutes(), ev)
    //         io.connect()
    //         // if(ev == 'io server disconnect'){
    //         //     io.connect()
    //         // }
    //     })
    // })
    io = Socket(apiURLTicketsSocket, 
        {
            transports: ['websocket'],
            secure: true,
            query:`token=${JSON.parse(localStorage['userToken'])}`
        })
        io.on('reconnect_attempt', () => {
          console.log('attempts')
          io.io.opts.transports = ['websocket'];
      });
    
    io.on('authenticated', ev => (console.log('authenticated', new Date().getMinutes()), queryActions.getDashboardList()))
    io.on('web_new_ticket', ev => (console.log('new_ticket', new Date().getMinutes()), queryActions.getDashboardList()))
    io.on('web_updated_ticket', ev => (console.log('updated_ticket', new Date().getMinutes()), queryActions.getDashboardList()))
    io.on('updated_changeover', ev => (console.log('updated_changeover', new Date().getMinutes()), queryActions.getDashboardList()))
}

queryActions.socketOff = () => {
    console.log('socket unmount')
    io.disconnect()
}

export default () => {

    return {
        ...queryActions
    }
}
