export default {
  id: "middle-label",
  beforeDraw: function(chart) {
    var ctx = chart.chart.ctx;
    ctx.restore();
    const texts = chart.options.label.split("\n");
    texts.forEach((text, index)=> {
      var width = chart.chart.width;
      var height = chart.chart.height;
      var fontSize = (height / (index === 0 ? 70 : 155)).toFixed(2);
      ctx.font = "bold " + fontSize / 2 + "em sans-serif";
      ctx.textBaseline = "middle";
      const metrics = ctx.measureText(text);
      const fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
      const actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
      const textY = Math.round(height / 2 + index * (actualHeight + 13));
      const textX = Math.round((width - metrics.width) / 2);
      ctx.fillText(text, textX, textY);
    })
    ctx.save();
  }
}
