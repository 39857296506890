import React from 'react';
import { connect } from 'react-redux';
import { deleteAttachment } from '../../../../../redux/actions/Attachment/attachmentActions';
import { confirmAlert } from 'react-confirm-alert';
import history from '../../../../../history';
import Table from '../../../../../components/CollapsableTable/CollapseTable'
import { getAttachments } from '../../../../../redux/actions/Attachment/attachmentActions'
import dictionary from '../../../../../redux/actions/Translation/dictionary'
import { checkPageAccessField } from '../../../../../utils/helper';
import './Attachment.scss';

class Attachment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            translations: {
                ...this.convert()
            },
            translationTimestamp: undefined,
            tableState: {
                add: () => {
                    history.push('/configuration/machine-config/attachment/new')
                },
                edit: ({ id }) => {
                    history.push(`/configuration/machine-config/attachment/edit/${id}`)
                },
                remove: ({ id }) => {
                    confirmAlert({
                        title: this.state?.translations?.are_you_sure_delete_attachment,
                        message: '',
                        buttons: [
                            {
                                label: this.state?.translations?.YES,
                                onClick: () => {
                                    this.props.deleteAttachment(id);
                                }
                            },
                            {
                                label: this.state?.translations?.NO,
                            }
                        ]
                    });
                },
                search: true,
                highlight: true,
                keys: this.initTableHeader(),
                data: [],
                loading: false
            }
        }
    }
    /**handler */
    initTableHeader = () => {
        let { STATUS, ATTACHMENT_CODE, IMAGE, DISPLAY_NAME, DESCRIPTION, UPDATED_DATE } = this.convert()
        return [
            {
                name: ATTACHMENT_CODE,
                key: 'attachment',
                tableHeaderWidth: '14%'

            },
            {
                name: DISPLAY_NAME,
                key: 'desc1',
                tableHeaderWidth: '15%'

            },
            {
                name: IMAGE,
                type: 'image',
                key: 'imgLink',
                tableHeaderWidth: '10%'

            },
            {
                name: DESCRIPTION,
                key: 'desc2',
                tableHeaderWidth: '20%'

            },
            {
                name: STATUS,
                type: 'boolean',
                key: 'isActive',
                tableHeaderWidth: '10%'

            },
            {
                name: UPDATED_DATE,
                type: 'date',
                key: 'updatedDt',
                alt: 'createdDt',
                tableHeaderWidth: '10%'

            },
        ]
    }
    convert = () => {
        let { YES, NO, NOTIFICATIONS: { are_you_sure_delete_attachment }, STATUS, ATTACHMENT_CODE, IMAGE, DISPLAY_NAME, DESCRIPTION, UPDATED_DATE } = dictionary.GET()
        return dictionary.TRANSLATE({ YES, NO, are_you_sure_delete_attachment, STATUS, ATTACHMENT_CODE, IMAGE, DISPLAY_NAME, DESCRIPTION, UPDATED_DATE })
    }
    componentDidMount() {
        // console.log(this.props)
        this.props.getAttachments(false, 'all')
    }

    componentDidUpdate() {
        // console.log('didUpdate', this.props, this.state)
        let { loading, attachmentList } = this.props
        let { loading: stateLoading, data } = this.state.tableState
        if (loading != stateLoading || attachmentList?.length != data?.length && Array.isArray(attachmentList)) {
            if(Array.isArray(attachmentList)){
                let tableState = this.state.tableState;
                if (checkPageAccessField(this.props.userAccess,{pageId:22,pageAccess: 'VIEW'})){
                    tableState = {
                        add: null,
                        edit: null,
                        remove: null
                    }
                }
                this.setState({
                    tableState: {
                        ...tableState,
                        data: [...attachmentList],
                        loading,
                        timeStamp: new Date()
                    }
                })
            }
        }
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                translations: Object.assign({}, { ...this.convert() }),
                tableState: {
                    ...this.state.tableState,
                    keys: this.initTableHeader(),
                    timeStamp: new Date()
                }
            })
        }
    }

    componentWillUnmount() {
    }

    /**render */
    render() {
        return (
            <div className="brand-body-overview">
                <Table {...this.state.tableState} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    let { attachmentListReducer,translationReducer } = state
    let apiLoading = {
    }
    let data = {
        userAccess: state.authReducer.access,
    }

    return {
        ...apiLoading,
        ...data,
        ...attachmentListReducer,
        ...translationReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // getBrands
        getAttachments: (ev, scope) => dispatch(getAttachments(ev, scope)),
        deleteAttachment: (id) => dispatch(deleteAttachment(id))
    }
}

Attachment = connect(mapStateToProps, mapDispatchToProps)(Attachment);

export default Attachment;
