import React from 'react';
import TableCell from './TableCell';
import { TableSortLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const MuiTableSortLabel = withStyles((theme) => ({
  root: {
    whiteSpace: 'nowrap',
    justifyContent: 'space-between',
  }
}))(TableSortLabel);

const TableHeaderCell = withStyles({
  root: {
    padding: 5,
    background: '#F7F8FA 0% 0% no-repeat padding-box',
  },
})(({ children, sortable, isSorting, direction, onSort, ...props }) => {
  const handleSorting = () => {
    const newDirection = direction === 'asc' ? 'desc' : 'asc';
    onSort(newDirection);
  };
  return (
    <TableCell {...props}>
      {
        sortable ? (
          <MuiTableSortLabel
            active={Boolean(isSorting)}
            direction={direction}
            onClick={() => handleSorting()}
          >
            {children}
          </MuiTableSortLabel>
        ) : (
          children
        )

      }
      
    </TableCell>
  );
});

export default TableHeaderCell;
