import React from 'react'
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '../../components/Typography';

const useStyles = makeStyles({
  imageResponsive: {
    maxWidth: '100%',
    display: 'block',
  },
  name: {
    textAlign: 'center',
  }
});

export default function Image({ src, name, ...props }) {
  const classes = useStyles();

  return (
    <Box>
      <img src={src} alt={name} className={classes.imageResponsive}/>
      {
        name && <Typography className={classes.name}>{name}</Typography>
      }
    </Box>
  )
}