import {
    NOTIFICATION_SUCCESS,
    NOTIFICATION_ERROR,
    PROBLEM_LIST,
    PROBLEM_LIST_REQUEST,
    PROBLEM_LIST_SUCCESS,
    PROBLEM_LIST_ERROR,
    PROBLEM_DETAIL_REQUEST,
    PROBLEM_DETAIL_SUCCESS,
    PROBLEM_DETAIL_ERROR,
    PROBLEM_NEW_REQUEST,
    PROBLEM_NEW_SUCCESS,
    PROBLEM_NEW_ERROR,
    PROBLEM_UPDATE_REQUEST,
    PROBLEM_UPDATE_SUCCESS,
    PROBLEM_DELETE_REQUEST,
    PROBLEM_DELETE_SUCCESS,
    PROBLEM_TREE_VIEW,
    PROBLEM_UPDATE_ERROR,
    PROBLEM_TRIGGER_HIGHLIGHT,
    PROBLEM_TREE_VIEW_BY_ID
} from '../actionTypes';
import { tokenGet, tokenPost, tokenPut, tokenDelete, apiSettings } from '../../../utils/api/CoreApi';
import history from '../../../history';

export const notificationSuccess = (message) => {
    return {
        type: NOTIFICATION_SUCCESS,
        payload: {
            message: message.translate()
        }
    }
}

export const notificationError = (message) => {
    return {
        type: NOTIFICATION_ERROR,
        payload: {
            message: message.translate()
        }
    }
}

export const problemRequest = () => {
    return {
        type: PROBLEM_LIST_REQUEST
    }
}

export const problemSuccess = problemList => {
    return {
        type: PROBLEM_LIST_SUCCESS,
        payload: problemList
    }
}

export const problemTreeView = problemList => {
    return {
        type: PROBLEM_TREE_VIEW,
        payload: problemList
    }
}

export const problemTreeViewById = problemList => {
    return {
        type: PROBLEM_TREE_VIEW_BY_ID,
        payload: problemList
    }
}

export const problemDetailRequest = () => {
    return {
        type: PROBLEM_DETAIL_REQUEST
    }
}

export const problemDetailSuccess = problemDetail => {
    return {
        type: PROBLEM_DETAIL_SUCCESS,
        payload: problemDetail
    }
}

export const problemNewRequest = () => {
    return {
        type: PROBLEM_NEW_REQUEST
    }
}

export const problemNewSuccess = problemList => {
    return {
        type: PROBLEM_NEW_SUCCESS,
        payload: problemList
    }
}

export const problemUpdateRequest = () => {
    return {
        type: PROBLEM_UPDATE_REQUEST
    }
}

export const problemUpdateSuccess = problemList => {
    return {
        type: PROBLEM_UPDATE_SUCCESS,
        payload: problemList
    }
}

export const problemDeleteRequest = () => {
    return {
        type: PROBLEM_DELETE_REQUEST
    }
}

export const problemDeleteSuccess = problemList => {
    return {
        type: PROBLEM_DELETE_SUCCESS,
        payload: problemList
    }
}


export const problemTriggerHighlight = (flag) => {
    return {
        type: PROBLEM_TRIGGER_HIGHLIGHT,
        payload: flag
    }
}

export const getProblems = (isActive = 1, currentPage = 1, pageSize = 100000) => {
    return function (dispatch) {
        dispatch(problemRequest());
        tokenGet(apiSettings.apiURLReferences + '/problemtypes?&isActive=' + isActive + '&currentPage=' + currentPage + '&pageSize=' + pageSize).then((response) => {
            let data = response.data.problems.result.map((item) => {
                let itemRow = {
                    'id': item.id,
                    'desc': item.desc1,
                }
                return itemRow;
            });
            console.log(data);
            dispatch(problemSuccess({
                problemList: data,
                meta: response.data.problems.meta
            }));
        })
            .catch((error) => {
                console.log(error);
            });
    }
}

export const getProblemsTreeView = (id, ev = undefined) => {
    return function (dispatch) {
        // dispatch(allMacSubTypeRequest());
        tokenGet(apiSettings.apiURLReferences + `/problemtypes/tv/${id ? `?problemTypeId=${id}` : ``}`).then((response) => {
            let { data } = response.data
            // console.log('tree',response)
            if(id){
                dispatch(problemTreeViewById({
                    problemListTreeById: data,
                    problemListTreeByIdTimeStamp: new Date()
                }));
                if(ev) dispatch(ev)
            }
            else {
                dispatch(problemTreeView({
                    problemListTree: data,
                    problemTimeStamp: new Date()
                }));
               if(ev) dispatch(ev)
            }
        })
            .catch((error) => {
                console.log(error);
            });

    }
}

export const getProblemDetail = (id) => {
    return function (dispatch) {
        dispatch(problemDetailRequest());
        tokenGet(apiSettings.apiURLReferences + '/problemtypes/' + id).then((response) => {
            dispatch(problemDetailSuccess({
                problemDetail: response.data.problems[0],
            }));
        })
            .catch((error) => {
                console.log(error);
            });
    }
}

export const getTicketProblems = (machineId = 1, currentPage = 1, pageSize = 100000) => {
    return function (dispatch) {
        dispatch(problemRequest());
        tokenGet(apiSettings.apiURLTickets + '/api/tickets/problem-list?machineId=' + machineId + '&currentPage=' + currentPage + '&pageSize=' + pageSize).then((response) => {
            let data = response.data.problems.allProblems.map((item) => {
                let itemRow = {
                    'id': item.problemTypeId,
                    'desc': item.desc1,
                }
                return itemRow;
            });
            dispatch(problemSuccess({
                problemList: data,
                meta: response.data.problems.meta
            }));
        })
            .catch((error) => {
                console.log(error);
            });
    }
}

export const postProblemNew = (payload) => {
    return function (dispatch) {
        dispatch(problemNewRequest());
        tokenPost(apiSettings.apiURLReferences + '/problemtypes', payload).then((response) => {
            dispatch(getProblemsTreeView(null, notificationSuccess('Successfully added problem type')));
            dispatch(problemNewSuccess({
                problem: response.data
            }));
        })
            .catch((error) => {
                if (error.message.indexOf(500) >= 0){
                    dispatch(notificationError('Problem name exists'));
                    dispatch(problemTriggerHighlight(true));
                }
                console.log(error);
            });
    }
}

export const putProblemUpdate = (id, payload, component = null) => {
    let notif = notificationSuccess('Successfully updated problem type')
    return function (dispatch) {
        dispatch(problemUpdateRequest());
        tokenPut(apiSettings.apiURLReferences + '/problemtypes/' + id, payload).then((response) => {
            if (component === 'problemList'){
                dispatch(getProblemsTreeView(null,notif));
            }
            else{
                dispatch(getProblemsTreeView(id,notif));
            }
            dispatch(getProblemDetail(id));
            dispatch(problemUpdateSuccess({
                problem: response.data
            }));
        })
            .catch((error) => {
                if (error.message.indexOf(500) >= 0){
                    dispatch(notificationError('Problem name exists'));
                    dispatch(problemTriggerHighlight(true));
                }
                console.log(error);
            });
    }
}

export const deleteProblem = (id, isAll = false) => {
    let notif = notificationSuccess('Successfully deleted problem type')
    return function (dispatch) {
        dispatch(problemDeleteRequest());
        tokenDelete(apiSettings.apiURLReferences + '/problemtypes/' + id).then((response) => {
            isAll == true ? dispatch(getProblemsTreeView(null, notif)) : dispatch(getProblemsTreeView(id, notif));
            dispatch(problemDeleteSuccess({
                problem: response.data
            }));
            history.push('/configuration/repair-type');
        })
            .catch((error) => {
                if (error.message.indexOf(500) >= 0){
                    dispatch(notificationError('Cannot delete because it is currently referenced to another record'));
                }
                console.log(error);
            });
    }
}