import moment from 'moment';

export const parseDate = (dateStr) => {
  if (!Date.parse(dateStr) || dateStr === null) return null;
  
  if (dateStr.length === 25) {
    return moment(dateStr.substring(0, 19));
  }

  return moment(dateStr);
}

export const displayMinute = (minutes) => {
    if(!minutes) return 0;

    return parseFloat(minutes.toFixed(2));
}


/**
 * Convert from minute to time
 * 
 * @param mn minute number
 * @returns 
 */
 export const minuteToTime = (mn) => {
    function pad(n, z) {
      if(String(n).length > 1) {
        return n;
      }
      z = z || 2;
      return ('00' + n).slice(-z);
    }
  
    const hours = Math.floor(mn / 60);
    const mins = Math.floor(mn - (hours * 60));
    return pad(hours) + ' hr '.translate() + pad(mins) + ' min'.translate();
  }

export const formatDate = (
    dateStr,
    format = 'YYYY-MM-DD',
    emptyString = '-'
  ) => {
    if (!Date.parse(dateStr) || dateStr === null) return emptyString;
  
    if (dateStr.length === 25 || dateStr.length === 24) {
      return moment(dateStr.substring(0, 19)).format(format);
    }
  
    return moment(dateStr).format(format);
}

export const renderDownTime = (reportedDt, closedDt) => {
  const parsedReportedDt = parseDate(reportedDt);
  const parsedClosedDt = parseDate(closedDt);
  if (parsedReportedDt && parsedClosedDt){
      let downtime = parsedClosedDt.diff(parsedReportedDt);
      let duration = moment.duration(downtime);
      let days = duration.days() * 24
      let hours = days + duration.hours()
      return hours + 'h ' + duration.minutes() + 'm';
    }
    else{
        return '-'
    }
};
