import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    alertCard: {
      width: '100%',
      maxWidth: '320px',
      padding: '30px 10px',
      borderRadius: 0,
    },
    alertTitle: {
      color: '#000',
      fontSize: '1.8rem',
    },
    alertBody: {
      fontSize: '1.2rem',
      color: '#000',
      padding: '10px 0px',
    },
    alertAction: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    alertButton: {
      color: '#fff',
      background: '#1e88e5',
      padding: '8px 40px',
      transition: '.1s all',
      '&:hover': {
        opacity: .9,
        background: '#3da4ff',
      },
    },
  });