import axios from 'axios';
import { postLogout } from '../../redux/actions/Auth/authActions';
import history from '../../history';

export const apiSettings = {
    apiURLMachines: process.env.REACT_APP_API_URL_MACHINES, // API URL FOR MACHINE
    apiURLReferences: process.env.REACT_APP_API_URL_REFERENCES, // API URL FOR REFERENCES
    apiURLLines: process.env.REACT_APP_API_URL_LINES, // API URL FOR LINES
    apiURLTickets: process.env.REACT_APP_API_URL_TICKETS, // API URL FOR TICKETS
    apiURLFiles: process.env.REACT_APP_API_URL_FILES, // API URL FOR VIEWING IMAGES AND VIDEOS
    apiURLAuth: process.env.REACT_APP_API_URL_AUTH, // API URL LOGIN
    apiURLLinesSocket: process.env.REACT_APP_API_URL_LINES_SOCKET, // API URL FOR VIEWING IMAGES AND VIDEOS
    apiURLTicketsSocket: process.env.REACT_APP_API_URL_TICKETS_SOCKET, // API URL LOGIN
    apiURLAnalytics: process.env.REACT_APP_API_URL_ANALYTICS,
    apiURLAuthGlobalAdmin: process.env.REACT_APP_API_URL_AUTH_GLOBAL_ADMIN
};

export function getToken(){
    let token = null;
    if (localStorage.getItem('paramUserToken')){
        token = JSON.parse(localStorage.getItem('paramUserToken'));
    }
    else{
        token = JSON.parse(localStorage.getItem('userToken'));
    }
    return token;
}

export function tokenGet(url, config){
    let token = getToken();
    config = {
        ...config,
        headers: {
            Authorization: "Bearer " + token,
            'language': localStorage.getItem('selectedLanguage'),
        }
    }
    return axios.get(url,config).catch((error) => {
        if (error.message.indexOf("403") >= 0){
            localStorage.setItem('forcedLogout','true');
            history.push('/logout');
        }
    });
}

export function tokenPost(url, postData){
    let token = getToken();
    postData = {
        ...postData,
    }
    let config = {
        headers: {
            Authorization: "Bearer " + token,
            'language': localStorage.getItem('selectedLanguage'),
        }
    }
    return axios.post(url,postData,config)
}

export function tokenPostUpload(url, bodyFormData){
    let token = getToken();

    return axios({
        method: 'post',
        url: url,
        data: bodyFormData,
        headers: {
            'Content-Type': 'multipart/form-data',
            "Authorization": "Bearer " + token,
            'language': localStorage.getItem('selectedLanguage'),
        }
    }).catch((error) => {
        if (error.message.indexOf("403") >= 0){
            history.push('/logout');
        }
    });
}

export function tokenPut(url, putData){
    let token = getToken();
    putData = {
        ...putData,
    }
    let config = {
        headers: {
            Authorization: "Bearer " + token,
            'language': localStorage.getItem('selectedLanguage'),
        }
    }
    return axios.put(url,putData,config)
}

export function tokenDelete(url, postData){
    let token = getToken();
    postData = {
        ...postData,
    }
    let config = {
        headers: {
            Authorization: "Bearer " + token,
            'language': localStorage.getItem('selectedLanguage'),
        }
    }
    return axios.delete(url,config)
}


export const globalAdminClient = axios.create({
  baseURL: apiSettings.apiURLAuthGlobalAdmin
});

globalAdminClient.interceptors.request.use(function (config) {
  const token = getToken();
  config.headers.Authorization = `Bearer ${token}`;
  config.headers.language = localStorage.getItem('selectedLanguage');

  return config;
});


export const referenceClient = axios.create({
  baseURL: apiSettings.apiURLReferences
});

referenceClient.interceptors.request.use(function (config) {
  const token = getToken();
  config.headers.Authorization = `Bearer ${token}`;
  config.headers.language = localStorage.getItem('selectedLanguage');

  return config;
});

export const machineClient = axios.create({
  baseURL: apiSettings.apiURLMachines
});

machineClient.interceptors.request.use(function (config) {
  const token = getToken();
  config.headers.Authorization = `Bearer ${token}`;
  config.headers.language = localStorage.getItem('selectedLanguage');

  return config;
});

export const ticketsClient = axios.create({
    baseURL: apiSettings.apiURLTickets
  });


ticketsClient.interceptors.request.use(function (config) {
  const token = getToken();
  config.headers.Authorization = `Bearer ${token}`;
  config.headers.language = localStorage.getItem('selectedLanguage');

  return config;
});


/* FOR CORE API URL, POST & PUT FUNCTIONS, UPLOAD ETC. */
