import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { 
    FlexBox, 
    ExportIcon, 
    IndicatorContainer, 
    LegendIndicator,
} from './styles';
import LineChart from '../../../../../assets/icons/LineChart';
import { BetaIcon } from '../../styles/styles';

export default function Header(props){
    return (
        <FlexBox marginBottom={3}>
            <FlexBox>
                <Typography variant='h4'>{'Overall Mechanic Performance'.translate()}</Typography>
                <BetaIcon />
            </FlexBox>
            <FlexBox>
                <IndicatorContainer>
                    <LegendIndicator color={props.colors[0]} />
                    <Typography>{'Active Mechanic'.translate()}</Typography>
                </IndicatorContainer>
                <IndicatorContainer>
                    <LegendIndicator color={props.colors[1]} />
                    <Typography>{'Idle Mechanic'.translate()}</Typography>
                </IndicatorContainer>
                <IndicatorContainer>
                    <LineChart color={props.colors[2]} />
                    <Typography>{'CO Ticket'.translate()}</Typography>
                </IndicatorContainer>
                <IndicatorContainer>
                    <LineChart color={props.colors[3]} />
                    <Typography>{'MAINT. Ticket'.translate()}</Typography>
                </IndicatorContainer>
                <IndicatorContainer>
                <LineChart color={props.colors[4]} />
                    <Typography>{'Repair Ticket'.translate()}</Typography>
                </IndicatorContainer>
            </FlexBox>
            <IconButton onClick={props.handleExport} size='small'>
                <ExportIcon />
            </IconButton>
        </FlexBox>
    )
}