import { TablePagination } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  root: {
    background: '#F7F8FA 0% 0% no-repeat padding-box',
  },
  select: {
    background: '#F7F8FA 0% 0% no-repeat padding-box',
  },
}))(TablePagination);
