import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    zIndex: '2',
    bottom: '58px',
    height: '50px',
    left: '2px',
    width: '55px',
  },
  media: {
    height: 140,
  },
  div: {
    height: '60px',
    width: '66px',
    background: '#fff',
  },
  container1: {
    height: '60px',
    width: '31px',
    backgroundColor: '#fff',
    display: 'inline-block',
    zIndex: '0',
  },
  container2: {
    height: '60px',
    width: '3px',
    display: 'inline-block',
    background: '#fff',
    zIndex: '0',
},
container3: {
    height: '60px',
    width: '32px',
    backgroundColor: '#fff',
    display: 'inline-block',
    zIndex: '0',
},
containerClicked1: {
  height: '60px',
  width: '31px',
  backgroundColor: '#02A7F0',
  display: 'inline-block',
  zIndex: '0',
},
containerClicked3: {
  height: '60px',
  width: '32px',
  backgroundColor: '#02A7F0',
  display: 'inline-block',
  zIndex: '0',
},
reported: {
    backgroundColor: '#D9001B',
    color: 'white',
    position: 'relative',
    bottom: '16px',
    right: '17px',
    width: '56px',
    textAlign: 'center',
},
inRepair: {
  backgroundColor: '#F59A23',
  color: 'white',
  position: 'relative',
  bottom: '16px',
  right: '17px',
  width: '56px',
  textAlign: 'center',
},
repaired: {
  backgroundColor: '#00DC07',
  color: 'white',
  position: 'relative',
  bottom: '16px',
  right: '17px',
  width: '56px',
  textAlign: 'center',
},
machineNumber: {
    overflow:'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    bottom: '15px',
    fontSize: '0.7rem',
    right: '11px',
    width: '50px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
},

hours: {
    position: 'relative',
    bottom: '17px',
    right: '17px',
    width: '56px',
    textAlign: 'center',
    fontWeight:'bold',
},
false: {
  position: 'relative',
  zIndex: '2',
  bottom: '37px',
  width: '31px',
  height: '10px',
  left: '16px',
  backgroundColor: '#D7D7D7',
},
empty: {
  position: 'relative',
  zIndex: '2',
  bottom: '37px',
  width: '31px',
  height: '10px',
  left: '16px',
  backgroundColor: '#797979',
},
falseContainer1: {
  height: '40px',
  width: '31px',
  backgroundColor: '#fafafa',
  display: 'inline-block',
  zIndex: '0',
},
falseContainer2: {
  height: '40px',
  width: '3px',
  display: 'inline-block',
  background: 'rgba(0, 151, 19, 0.0)',
  zIndex: '0',
},
falseContainer3: {
  height: '40px',
  width: '32px',
  backgroundColor: '#fafafa',
  display: 'inline-block',
  zIndex: '0',
},
falseDiv: {
  height: '18px',
  width: '66px',
  background: 'rgba(0, 151, 19, 0.0)',
},

//lines detail styles
linesdiv: {
  height: '60px',
  width: '66px',
  background: 'rgba(0, 151, 19, 0.0)',
},
linesroot: {
  position: 'relative',
  zIndex: '2',
  top: '10px',
  width: '67px',
  height: '90px',
  left: '6px',
},
emptylinesroot: {
  backgroundColor: '#D7D7D7',
  position: 'relative',
  zIndex: '2',
  top: '10px',
  width: '67px',
  height: '90px',
  left: '6px',
},
linesreported: {
  backgroundColor: '#D9001B',
  color: 'white',
  position: 'relative',
  width: '68px',
  right: '17px',
  bottom: '16px',
  textAlign: 'center',
},
linesinRepair: {
backgroundColor: '#F59A23',
color: 'white',
position: 'relative',
width: '68px',
right: '17px',
bottom: '16px',
textAlign: 'center',
},
linesRepaired: {
  backgroundColor: '#00DC07',
  color: 'white',
  position: 'relative',
  width: '68px',
  right: '17px',
  bottom: '16px',
  textAlign: 'center',
  },
falselinesTicket: {
  backgroundColor: '#D7D7D7',
  color: 'black',
  position: 'relative',
  width: '68px',
  right: '17px',
  bottom: '16px',
  textAlign: 'center',
  },
linesmachineNumber: {
  position: 'relative',
  fontSize: '0.7rem',
  textAlign: 'center',
  bottom: '11px',
  right: '15px',
  width: '68px',
  whiteSpace: 'nowrap',
},
lineshours: {
  position: 'relative',
  bottom: '8px',
  height: '29px',
  textAlign: 'center',
  fontWeight:'bold',
},
});

export default function MediaCard(props) {

  //create your forceUpdate hook
  const useForceUpdate = () =>{
      const [value, setValue] = React.useState(0); // integer state
      return () => setValue(value => ++value); // update the state to force render
  }

  const classes = useStyles();
  const forceUpdate = useForceUpdate();
  const hasOpenTicket = props.hasOpenTicket
  const station = props.station
  const machineNo = props.machineNo
  const ticketId = props.ticketId
  const machineId = props.machineId
  const ticketStatusId_desc = props.ticketStatusId_desc
  const emptyStation = props.emptyStation
  const lineDetail = props.lineDetail
  const mechanic = props.mechanic
  const macSubType_desc1 = props.macSubType_desc1
  const sortNumber = props.sortNumber
  const reportedDt = props.reportedDt
  const grabbedDt = props.grabbedDt
  const repairedDt = props.repairedDt
  const minutesBeforeAlarm = 1;
  const [cardClicked, setCardClicked] = React.useState(false);
  const [initialLoad, setInitialLoad] = React.useState(false);
  const [updateTime, setUpdateTime] = React.useState(false);

  const cardClick = () => {
    props.ticketDetailsOpener(ticketId, machineId)
    setCardClicked(true)
  }

  const getDateDifference = (propsParam) =>{
       
       if (propsParam === null || propsParam === ''){
         return '-';
       }
        else{
          var overdueTime = 15;
          let ticketDate = null;
          if (propsParam.ticketStatusId_desc === 'REPORTED'){
             ticketDate = propsParam.reportedDt
             overdueTime = props.listDuration.RT1Dur
          }
          else if (propsParam.ticketStatusId_desc === 'IN-REPAIR'){
            ticketDate = propsParam.grabbedDt
            overdueTime = props.listDuration.RT2Dur
          }
          else if (propsParam.ticketStatusId_desc === 'REPAIRED'){
            ticketDate = propsParam.repairedDt
            overdueTime = props.listDuration.RT3Dur
          }
          else{
            ticketDate = propsParam.reportedDt
          }
          let timezone = moment.parseZone(ticketDate).format('Z');
          let dateTimeOnly = moment.parseZone(ticketDate).format('YYYY-MM-DD HH:mm:ss') + '+00:00';
          let dateParsed = moment.parseZone(dateTimeOnly).seconds(0).milliseconds(0).utcOffset(timezone);
          let currentDate = moment().seconds(0).milliseconds(0).utcOffset(timezone);
          
          let downtime = currentDate.diff(dateParsed);
          let duration = moment.duration(downtime);

          if (duration.asMinutes() >= overdueTime){
             let lineNo = props.lineNo;
             let overdueTabs = props.overdueTabs;
             let check = overdueTabs.indexOf(lineNo);
             if (check < 0){
               props.addOverdueTab(props.lineNo);
             }
          }
          
          return formatTime(duration);
       }
  }

  const formatTime = (duration) => {
    let hoursInDay = 24;
    let hours = duration.days() * hoursInDay + duration.hours();
    let sliceIndex = hours > 99 ? -3 : -2;
    return ("0" + hours).slice((sliceIndex)) + '.' + ("0" + duration.minutes()).slice(-2);
  }

  setInterval(() => {
      if (moment().seconds() === 0){
        forceUpdate(); // when time goes 00 seconds, trigger update as data would change
      }
  },1000)

  const repairTicket = () => {
    return (
      <div className={classes.div}>
      <div className={classes.container1}></div>
      <div className={classes.container2}></div>
      <div className={classes.container3}></div>
      
    <Card className={classes.root}>
    
      <CardActionArea>
        <CardContent>
        
          <Typography className={ticketStatusId_desc == 'REPAIRED' ? classes.repaired : ticketStatusId_desc == 'REPORTED' ? classes.reported : classes.inRepair}>
            {station}
          </Typography>
          <Tooltip title={machineNo}>
          <Typography className={classes.machineNumber}>
            {machineNo}
          </Typography>
          </Tooltip>
          <Typography className={classes.hours}>
            {
              getDateDifference(props)
            }
          </Typography>
        </CardContent>
      </CardActionArea>

    </Card>

    </div>
    )
  }

  const falseTicket = () => {
    return (
      <div className={classes.falseDiv}>
        <div className={classes.falseContainer1}></div>
        <div className={classes.falseContainer2}></div>
        <div className={classes.falseContainer3}></div>
        <div className={emptyStation ? classes.empty : classes.false}>
      </div>
    </div>
    )
  }

  const linesrepairTicket = () => {
    return (
      <div className={classes.linesdiv}>
    <Card className={emptyStation ? classes.emptylinesroot : classes.linesroot}>
      <CardActionArea>
        <CardContent>
          <Typography className={emptyStation ? classes.falselinesTicket : hasOpenTicket == "false" ? classes.falselinesTicket : ticketStatusId_desc == 'REPAIRED' ? classes.linesRepaired : ticketStatusId_desc == 'REPORTED' ? classes.linesreported : classes.linesinRepair}>
            {emptyStation ? sortNumber < 10 ? '0' + sortNumber.toString() : sortNumber : station}
          </Typography>
          <Typography className={classes.linesmachineNumber}>
            {emptyStation ? 'TABLE' : machineNo}
          </Typography>
          <Typography className={classes.linesmachineNumber}>
            {emptyStation ? '      ' : macSubType_desc1}
          </Typography>
          <Typography className={classes.lineshours}>
          {emptyStation ? '      ' : mechanic}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    </div>
    )
  }

  const linesfalseTicket = () => {
    return (
      <div className={classes.falseDiv}>
      <div className={classes.falseContainer1}></div>
      <div className={classes.falseContainer2}></div>
      <div className={classes.falseContainer3}></div>
    <div className={emptyStation ? classes.empty : classes.false}>
    </div>
    </div>
    )
  }

  const lineDetailTicket = () => {
    return (
      <div>
        {hasOpenTicket == "true" ? linesrepairTicket() : linesrepairTicket()}
      </div>
    )
  }
  
  return (
    <div>
            {lineDetail == true ? lineDetailTicket() : hasOpenTicket == "true" ? repairTicket() : falseTicket()}
    </div>

  );
}
