import React, { useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Card from './Card'
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid";
import WhiteLine from './whiteline.png'
import BlueLine from './blueline.png'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Drawer from "./Drawer";
import { removeDupliacteStations } from './helpers';



const useStyles = makeStyles({
  noShadow: {
    boxShadow: 'none !important'
  },
  root: {
    // width: '100%',
    flexGrow: 1,
  },
  container: {
    maxHeight: 500,
    overflowX: 'hidden',
    overflowY: 'auto',
    display: 'inherit',
  },
  normalColumn: {
    height: 'auto',
    fontSize: 'large',
    verticalAlign: 'middle',
    margin: '0px 8px 0px 8px',
    color:'#000000',
    cursor: 'pointer',
    backgroundColor: '#F2F2F2',
    borderRadius: "0px",
    minWidth: 102,
    maxWidth: 108,
    // margin: "8px auto",
    padding: '3px',
  },
  overdueColumn: {
    height: 'auto',
    fontSize: 'large',
    verticalAlign: 'middle',
    padding: '3px',
    margin: '0px 8px 0px 8px',
    color:'#FFFFFF',
    cursor: 'pointer',
    backgroundColor: '#D9001B',
    minWidth: 102,
    maxWidth: 108,
    borderRadius: "0px"
  },
  clickedColumn: {
    height: 'auto',
    fontSize: 'large',
    verticalAlign: 'middle',
    padding: '3px',
    margin: '0px 8px 0px 8px',
    color:'#FFFFFF',
    cursor: 'pointer',
    backgroundColor: '#02A7F0',
    minWidth: 102,
    maxWidth: 108,
    borderRadius: "0px"
  },
  paper: {
    maxWidth: 100,
    margin: "auto",
    padding: "auto"
  },
});

function areEqual(prevProps, nextProps) {
if(prevProps.rowsMachines === nextProps.rowsMachines){
  return true
}else{
  return false
}

}

function StickyHeadTable(props) {
      //create your forceUpdate hook
      const useForceUpdate = () =>{
        const [value, setValue] = React.useState(0); // integer state
        return () => setValue(value => ++value); // update the state to force render
    }
  const classes = useStyles();
  const forceUpdate = useForceUpdate();
  const userAccess = JSON.parse(localStorage.getItem('userAccess'));
  const columnLines = props.columnLines; 
  const highestTicketDate = props.highestTicketDate
  const [columnTitle, setColumnTitle] = React.useState(props.columnLines);
  const [rowsMachines, setRowsMachines] = React.useState(props.rowsMachines);
  const [lineTitle, setLineTitle] = React.useState(null);
  const [lineIndex, setLineIndex] = React.useState(null);
  const [ticketId, setTicketId] = React.useState(null);  
  const [machineId, setMachineId] = React.useState(null);    
  const [drawerTrigger, setDrawerTrigger] = React.useState(false);
  const [drawerTrigger2, setDrawerTrigger2] = React.useState(false);
  const [drawerDetail, setDrawerDetail] = React.useState('');
  const [linesMachines, setLinesMachines] = React.useState([]);
  const [hideBoxes, setHideBoxes] = React.useState(false);
  const [ticketClicked, setTicketClicked] = React.useState(null);
  const [columnClicked, setColumnClicked] = React.useState(null);  

  const tableTheme = createMuiTheme({
    overrides: {
      MuiPaper:{
        root:{
          // boxShadow: 'none !important'
        }
      },
      // Style sheet name ⚛️
      MuiTableCell: {
        // Name of the rule
        root: {
          padding: '0px',
          textAlign: '-webkit-center',
          verticalAlign: 'top',
          height: '394px',
          borderBottom: 'none',
        },
        stickyHeader: {
          zIndex:'3',
          height: '36px',
          backgroundColor:'transparent',
          verticalAlign: 'middle',
          textAlign: '-webkit-center',
        },
        body: {
          backgroundColor:'#FFFFFF',
        },
      },
    },
  });







  const handleColumnChanger = (index) => {
    let newArr = [...columnLines];
    newArr[index].overdue = true;
    if(index === columnLines.length){
      setColumnTitle(newArr)
    }
  }

  const handleOpenStationDetail = (ticketId, machineId) => {
    setDrawerDetail('station')
    setLineTitle(null)
    setTicketId(ticketId)
    setMachineId(machineId)
    setLineIndex(null)
    setTicketClicked(ticketId)
    setColumnClicked(null)
    setHideBoxes(true)
    setDrawerTrigger(!drawerTrigger)
    props.disabler(true)
  }

  const openLineDrawer = (title, itemIndex) => {
    const stations = removeDupliacteStations(rowsMachines[itemIndex])
      .sort((stationA, stationB) => (
        stationA.sortNumber < stationB.sortNumber 
          ? -1 
          : stationA.sortNumber > stationB.sortNumber 
            ? 1 
            : 0
        )
      );
    setDrawerDetail('lines')
    setHideBoxes(true)
    setLineTitle(title)
    setLineIndex(itemIndex)
    setColumnClicked(title)
    setLinesMachines(stations)
    setTicketClicked(null)
    setDrawerTrigger(!drawerTrigger)
    props.disabler(true)
  }
  const handleRemoveDrawer = () => {
    setLineTitle(null)
    setLineIndex(null)
    setTicketClicked(null)
    setHideBoxes(false)
    setColumnClicked(null)
    props.disabler(false)
  }

  useEffect(() => {
    if(lineIndex !== null){
      let rowMachines1 = props.rowsMachines
      let machines = rowMachines1[lineIndex];
      setLinesMachines(machines)
    }
    if(ticketId !== null){
      setTicketId(ticketId)
      setMachineId(machineId)
    }
    setColumnTitle(props.columnLines)
    setRowsMachines(props.rowsMachines)
  }, [columnLines]);

  useEffect(() => {
    const interval = setInterval(() => {
        if (moment().seconds() === 0){
          forceUpdate(); // when time goes 00 seconds, trigger update as data would change
        }
    },1000);
    return () => clearInterval(interval);
  }, []);

  
  const getConfig = (list,tag,type) =>{
    let config = list.filter((item) => { return item.config === tag});
    if (config.length > 0){
        if (type === 'id'){
            return config[0].id
        }
        else if (type === 'value'){
            return config[0].value
        }
        else{
            return config[0]
        }
    }
    else{
        return null;
    }
}

  const getDateDifference = (ticketDate,ticketDateStatus) =>{
    var overdueTime = 15;
    let listDuration = {
        RT1Dur: getConfig(props.systemPreference,'RT1Dur','value'),
        RT2Dur: getConfig(props.systemPreference,'RT2Dur','value'),
        RT3Dur: getConfig(props.systemPreference,'RT3Dur','value'),
    }
    let timezone = moment.parseZone(ticketDate).format('Z');
    let dateTimeOnly = moment.parseZone(ticketDate).format('YYYY-MM-DD HH:mm:ss') + '+00:00';
    let dateParsed = moment.parseZone(dateTimeOnly).seconds(0).milliseconds(0).utcOffset(timezone);
    let currentDate = moment().seconds(0).milliseconds(0).utcOffset(timezone);
    let downtime = currentDate.diff(dateParsed);
    let duration = moment.duration(downtime);
    let timer = duration.asMinutes();
    if (ticketDateStatus === 'REPORTED'){
        overdueTime = listDuration.RT1Dur
    }
    else if (ticketDateStatus === 'IN-REPAIR'){
        overdueTime = listDuration.RT2Dur
    }
    else if (ticketDateStatus === 'REPAIRED'){
        overdueTime = listDuration.RT3Dur
    }
    else{
      overdueTime = 15
    }
    if (timer >= overdueTime){
      return true
     }else{
       return false
     }
}

  return (
    <div>
    <Paper className={classes.root + ' ' + classes.noShadow}>
      <TableContainer className={classes.container + " no-scroll-display"}>
      <ThemeProvider theme={tableTheme}>
        <Table stickyHeader aria-label="sticky table" style={{width:'auto', margin: 'auto'}}>
          <TableHead>
            <TableRow>
              {columnTitle.map((column, itemIndex) => {
                let ticketDate = null;
                let ticketDateStatus = null;
                let columnChanger = null;
                if (highestTicketDate[itemIndex]){
                  ticketDate = highestTicketDate[itemIndex].date
                  ticketDateStatus = highestTicketDate[itemIndex].status
                  columnChanger = getDateDifference(ticketDate,ticketDateStatus);
                }
              return (
                <TableCell
                  key={itemIndex}
                  align={'center'}
                  style={{ minWidth: '58px' }}
                >
                  {/* <Box boxShadow={3} onClick={() => openLineDrawer(column.label, itemIndex)} 
                  className={column.label === columnClicked ? classes.clickedColumn : columnChanger ? classes.overdueColumn : classes.normalColumn} style={{}}>
                  {column.label}
                </Box> */}
                    <Grid container wrap="nowrap">
                      <Grid item xs zeroMinWidth>
                        <Box style={{overflowWrap: 'break-word', padding:"10px"}} onClick={() => openLineDrawer(column.label, itemIndex)}
                        boxShadow={3}
                        className={column.label === columnClicked ? classes.clickedColumn : columnChanger ? classes.overdueColumn : classes.normalColumn}>
                          {column.label}
                        </Box>
                      </Grid>
                    </Grid>
                </TableCell>
              )
            }
              )
              
              }
            </TableRow>
          </TableHead>
          <TableBody>
                <TableRow>
                  {
                  columnLines.map((column, columnIndex) => {
                    let row = rowsMachines[columnIndex]
                    if (row !== undefined && row !== null && row !== ''){
                      if(row.length != 0){
                        let lastrow = row.length - 1
                        row[lastrow]['lastElement'] = true
                      }
                      return (
                        <TableCell key={columnIndex} style={{
                          backgroundImage: column.label === columnClicked ? `url(${BlueLine})` : `url(${WhiteLine})`,
                          backgroundSize: 'contain',
                          height:'1000px'}}>
                          {row.map((row, rowIndex) => {
                            return (              
                            <Card 
                              key={rowIndex}
                              row={row}
                              lineDetail={false}
                              cardClicked={row.ticketId === ticketClicked ? true : false}
                              hideBoxes={hideBoxes}
                              lastElement={row.lastElement == undefined ? false : true}
                              handleColumnChanger={() => handleColumnChanger(columnIndex)}
                              handleOpenStationDetail={handleOpenStationDetail}
                              ></Card>)
                          }
                          
                          )
                          
                          }
                        </TableCell>
                      );
                    }
                    else{
                       return '';
                    }
                  })}
                </TableRow>
          </TableBody>
        </Table>
        </ThemeProvider>
      </TableContainer>
    </Paper>
    <Drawer
          drawerTrigger2={drawerTrigger2}
          showDrawer={drawerTrigger}
          socketTrigger={props.socketTrigger}
           ticketId={ticketId}
           machineId={machineId}
           drawerDetail={drawerDetail}
           linesMachines={linesMachines}
           linesTitle={lineTitle === null ? '' : lineTitle}
           clearDrawerDetail={() => handleRemoveDrawer()}
        ></Drawer>
    </div>
  );
}

export default React.memo(StickyHeadTable, areEqual);
