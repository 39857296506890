import TableCell from './TableCell';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  root: {
    border: '1px solid #C6C6C6',
    width: 30,
    fontWeight: 'bold',

  }
}))(TableCell);