import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MainPage from '../../../../components/MainPage/MainPage';
import MainPageHeader from '../../../../components/MainPageHeader/MainPageHeader';
import MainPageBody from '../../../../components/MainPageBody/MainPageBody';
import { checkPageAccessField, checkPageAccess, isSuperAdmin } from '../../../../utils/helper';
import history from '../../../../history';
import UserAccess from './UserAccess/UserAccess';
import RoleAccess from './RoleAccess/RoleAccess';
import dictionary from '../../../../redux/actions/Translation/dictionary'
import './UserAccessConfig.scss';

class UserAccessConfig extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            tabEnum: [
                {
                    name: "users",
                    value: 0
                },
                {
                    name: "roles",
                    value: 1
                },
            ],
            shownTab: 0,
            configType: null,
            translations: {
                ...this.convert()
            },
            translationTimestamp: undefined,
        }
    }

    /**handler */

    convert = () => {
        let { USER_MANAGEMENT, USERS, ROLES } = dictionary.GET()
        return dictionary.TRANSLATE({ USER_MANAGEMENT, USERS, ROLES})
    }

    handleTabChange = (type) => {
        history.push('/configuration/user-manage/' + type);
        let indexValue = this.state.tabEnum.filter((item) => { return item.name === type });
        if (indexValue.length > 0) {
            this.setState({ shownTab: indexValue[0].value, configType: type })
        }
    }

    renderTabPanel() {
        if (this.state.shownTab === 0) {
            return (
                <UserAccess />
            )
        }
        else if (this.state.shownTab === 1) {
            return (
                <RoleAccess />
            )
        }
    }

    initializeTab() {
        let index = 0;
        if (this.props.match.params.config === 'users') {
            index = 0
        }
        else if (this.props.match.params.config === 'roles') {
            index = 1
        }
        this.setState({ shownTab: index });
    }

    componentDidMount() {
        this.initializeTab();
    }

    componentDidUpdate(){
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                translations: Object.assign({}, { ...this.convert() }),
            })
        }
    }
    render() {
        let {translations} = this.state
        return (
            <MainPage className='user-access-config'>
                <MainPageHeader title={translations.USER_MANAGEMENT} search={false} />
                <MainPageBody>
                    <div className="user-access-config-body">
                        <div className="title">{translations.USER_MANAGEMENT}</div>
                        <Tabs color="primary" value={this.state.shownTab} aria-label="simple tabs example">
                            <Tab disabled={checkPageAccess(this.props.userAccess, { pageId: 25 }) || checkPageAccessField(this.props.userAccess, { pageId: 25, pageAccess: 'VIEW' }) || isSuperAdmin() ? false : true} onClick={() => this.handleTabChange('users')} label={translations.USERS} />
                            <Tab disabled={checkPageAccess(this.props.userAccess, { pageId: 26 }) || checkPageAccessField(this.props.userAccess, { pageId: 26, pageAccess: 'VIEW' }) || isSuperAdmin() ? false : true} onClick={() => this.handleTabChange('roles')} label={translations.ROLES} />
                        </Tabs>
                        {this.renderTabPanel()}
                    </div>
                </MainPageBody>
            </MainPage>
        );
    }
}

const mapStateToProps = state => {
    let { translationReducer } = state
    let apiLoading = {
    }
    let data = {
        userAccess: state.authReducer.access,
    }

    return {
        ...apiLoading,
        ...data,
        ...translationReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

UserAccessConfig = connect(mapStateToProps, mapDispatchToProps)(UserAccessConfig);

export default UserAccessConfig;
