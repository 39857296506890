// https://github.com/Ariel-Rodriguez/react-hook-sticky#quick
import { getClampArea, createStyle, setInlineStyle } from 'react-hook-sticky/lib/commons';

export const stickyTop = context => {
  const { stylesCache, boundaries } = context;
  const stickyBoundary = boundaries.get('sticky');
  const clampArea = getClampArea(boundaries);

  if (!stickyBoundary || !clampArea.height) {
    return;
  }
  const minHeight =
    stickyBoundary.params.minHeight ||
    stickyBoundary.element.getBoundingClientRect().height;
  
  const elementWidth = stickyBoundary.element.getBoundingClientRect().width;
  const parentWidth = stickyBoundary.element.parentElement.getBoundingClientRect().width;

  const { activeClass } = stickyBoundary.params;
  const nextPosition = {
    maxHeight: clampArea.height,
  };

  if (clampArea.top === 0 || clampArea.top === clampArea.offsets.top) {
    nextPosition.position = 'fixed';

    if (activeClass) {
      stickyBoundary.element.classList.add(activeClass);
    }

    if (clampArea.height > minHeight) {
      nextPosition.top = 0 + clampArea.offsets.top;
    } else {
      nextPosition.maxHeight = minHeight;
      nextPosition.bottom = window.innerHeight - clampArea.bottom;
    }

    // Because the sticky element must follow parent width in case the parent has auto or a percentage width.
    nextPosition.width = elementWidth;
  } else {
    nextPosition.position = 'absolute';
    nextPosition.top = 0;

    if (activeClass) {
      stickyBoundary.element.classList.remove(activeClass);
    }
  }


  const style = createStyle(nextPosition);
  setInlineStyle(stickyBoundary, style, stylesCache);
};
