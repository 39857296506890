import { tokenGet, tokenPost, apiSettings, tokenPut } from '../../../utils/api/CoreApi';
import store from '../../store'
import { DASHBOARD_ACTIONS as actions } from '../actionTypes'
import Socket from 'socket.io-client'
import { getBuildingAreaQuery, getDashboardQuery } from '../../../utils/helper';

const queryActions = {}
const componentActions = {}
let { dispatch } = store;

let { apiURLTickets, apiURLTicketsSocket, apiURLAuthGlobalAdmin } = apiSettings;
let io
queryActions.getDashboardList = async () => {
    console.log("Here C")
    dispatch(toggleLoading());
    try {
        let buildingId = localStorage.getItem("getLineVal");
        let buildingIds = localStorage.getItem("buildID");
        let datasBuildID = ((buildingIds == null || buildingIds == "null") ? buildingId : buildingIds);

        let areaID = localStorage.getItem("getLineAreaVal");
        let areaIDs = localStorage.getItem("areaID");
        let datasAreaID = ((areaIDs == null || areaIDs == "null") ? areaID : areaIDs);

        const query = getDashboardQuery();
        const url = query
            ? `${apiURLTickets}/api/tickets/dashboard?${new URLSearchParams(query) }`
            : `${apiURLTickets}/api/tickets/dashboard`
        
        let { data, status } = await tokenGet(url);

        query 
            ? queryActions.getDashboardMaintenanceTicket(new URLSearchParams(query))
            : queryActions.getDashboardMaintenanceTicket();
        // queryActions.getDashboardMaintenanceTicket(getBuildingAreaQuery(datasBuildID, datasAreaID));

        data['topNpt'].map((row, i) => {
            if (i < 5) return row
        })
        data['topNpt'].length > 5 && (data['topNpt'].length = 5)
        let filterData = {
            timeStamp: new Date(),
            repair: {
                reported: 0,
                inRepair: 0,
                closed: 0,
            },
            repairTicketsStatus: {
                reported: 0,
                reOpened: 0,
                inRepair: 0,
                repaired: 0,
                closed: 0,
                cancelled: 0,
            },
            changeOver: {
                new: 0,
                inProgress: 0,
                ready: 0,
                readyToday: 0,
                closedToday: 0,
            },
            '5npt': {
                list: data['topNpt']
            },
            reportedList: {
                list: data['topReportedTickets'],
                avgTime: data['avgResponseTime'][0]['averageDuration'],
                limit: data['reportedTicketDuration']['value'],
                limitType: data['reportedTicketDuration']['uom']
            },
            repairList: {
                list: data['topInRepairTickets'],
                avgTime: data['avgRepairTime'][0]['averageDuration'],
                limit: data['inRepairTicketDuration']['value'],
                limitType: data['inRepairTicketDuration']['uom']
            },
        }
        const filterRepairTicketsCount = () => {
            data['ticketCountsStatus'].forEach(x => {
                switch (x['statusTicket']) {
                    case 'REPORTED':
                        filterData.repairTicketsStatus.reported = x['thisMonth']
                        break;
                    case 'RE-OPEN':
                    case 'RE-OPENED':
                        filterData.repairTicketsStatus.reOpened = x['thisMonth']
                        break;
                    case 'IN-REPAIR':
                        filterData.repairTicketsStatus.inRepair = x['thisMonth']
                        break;
                    case 'REPAIRED':
                        filterData.repairTicketsStatus.repaired = x['thisMonth']
                        break;
                    case 'CLOSED':
                        filterData.repairTicketsStatus.closed = x['today']
                        break;
                    case 'CANCELLED':
                        filterData.repairTicketsStatus.cancelled = x['today']
                        break;
                    default:
                        return null;
                }
            });
        }

        let filterTicketCounts = () => {

            data['ticketCounts'].forEach(x => {
                switch (x['status']) {
                    case 'reported':
                        filterData.repair.reported = x['count']
                        break;
                    case 'in-repair':
                        filterData.repair.inRepair = x['count']
                        break;
                    case 'repaired':
                        filterData.repair.closed = x['count']
                        break;
                    default:
                        return null

                }
            })
        }

        const filterCOCount = () => {
            data['coCountsStatus'].forEach(x => {
                switch (x['statusCO']) {
                    case 'NEW':
                        filterData.changeOver.new = x['thisMonth']
                        break;
                    case 'IN PROGRESS':
                        filterData.changeOver.inProgress = x['thisMonth']
                        break;
                    case 'READY':
                        filterData.changeOver.ready = x['thisMonth']
                        break;
                    case 'READY TODAY':
                        filterData.changeOver.readyToday = x['today']
                        break;
                    case 'CLOSED':
                        filterData.changeOver.closedToday = x['today']
                        break;
                    default:
                        return null;
                }
            });
        }

        filterTicketCounts()
        filterCOCount()
        filterRepairTicketsCount() //filter the ticketStatusCount monthly
        dispatch({
            type: actions.DASHBOARD_LIST,
            payload: {
                tickets: filterData,
                status,
            }
        })
        dispatch(toggleLoading());
    } catch (error) {
        console.error('dashboardAction', error)
        dispatch(toggleLoading());
    }
}

const toggleLoading = () => {
    return {
        type: actions.DASHBOARD_LIST_TOGGLE_LOADING,
    }
}

queryActions.getProductLTM = async () => {
    try {
        var { data } = await tokenGet(`${apiURLAuthGlobalAdmin}/useraccess?product=LTm`);
        localStorage.setItem('userRole', JSON.stringify(data.data.role));
        localStorage.setItem('userAccess', JSON.stringify(data.data.access));
        // localStorage.setItem('userAccess',JSON.stringify(response.data.access));
        dispatch({
            type: actions.DASHBOARD_LIST,
            payload: {
                access: data.data.access,
                role: data.data.role
            }
    })
        // window.location.reload();
    } catch (error) {
        console.error('getProductLTM', error)
    }
}

queryActions.postProductLTM = async () => {
    try {
        const payload = { "product": "LTm" };
        var { data } = await tokenPost(`${apiURLAuthGlobalAdmin}/user/setproduct`, payload);
        dispatch({
            type: actions.DASHBOARD_LIST,
            payload: {
                dataLTM: data
            }
        })
    } catch (error) {
        console.error('postProductLTM', error)
    }
}

queryActions.getAvgTime = async () => {
    let repair, response

    let buildingId = localStorage.getItem("getLineVal");
    let buildingIds = localStorage.getItem("buildID");
    let datasBuildID = ((buildingIds == null || buildingIds == "null") ? buildingId : buildingIds);
    
    let areaID = localStorage.getItem("getLineAreaVal");
    let areaIDs = localStorage.getItem("areaID");
    let datasAreaID = ((areaIDs == null || areaIDs == "null") ? areaID : areaIDs);
    // var { data, status } = await tokenGet(`${apiURLTickets}/api/tickets/avg-repair-time`)
    var { data, status } = await tokenGet(`${apiURLTickets}/api/tickets/avg-repair-time${getBuildingAreaQuery(datasBuildID, datasAreaID)}`)
    repair = data['data'][0]['averageDuration']

    // var { data, status } = await tokenGet(`${apiURLTickets}/api/tickets/avg-response-time`)
    var { data, status } = await tokenGet(`${apiURLTickets}/api/tickets/avg-response-time${getBuildingAreaQuery(datasBuildID, datasAreaID)}`)
    response = data['data'][0]['averageDuration']

    dispatch({
        type: actions.DASHBOARD_AVG,
        payload: {
            avgTime: {
                avgTimeStamp: new Date(),
                repairAvg: repair,
                responseAvg: response
            }
        }
    })
}


queryActions.getBuilding = async () => {
    try {
        var { data, status } = await tokenGet(`${apiURLTickets}/api/tickets/dashboard-building`)
        let building = data.data;
        // localStorage.setItem("buildID", data.data[1].id);
        let bud = {
            building: "allbuildings",
            buildingDesc: "All Buildings",
            isActive: true
        }
        building.unshift(bud);
        let buildingId = building.length > 1 && data.data[1].id;
        dispatch({
            type: actions.DASHBOARD_BUILDING,
            payload: {
                dashboardBuilding: building,
                buildingId: buildingId,
                status,
            }
        })
        localStorage.setItem("areaID", undefined);
        localStorage.setItem("getLineAreaVal", undefined);
    } catch (error) {
        console.error('dashboardAction', error)
    }
}
queryActions.updateSelectedBuilding = (buildingId) => {
    dispatch({
        type: actions.DASHBOARD_BUILDING,
        payload: { buildingId } 
    })
}
queryActions.getArea = async (buildingId = '') => {
    try {
        const { 
            data, 
            status,
        } = await tokenGet(`${apiURLTickets}/api/tickets/dashboard-area?buildingId=${buildingId}`);
        const defaultArea = {
            area: 'allareas',
            areaDesc: 'All Areas',
            areaName: 'All Areas',
            isActive: true,
        }
        dispatch({
            type: actions.DASHBOARD_AREA,
            payload: {
                dashboardArea: [defaultArea, ...data.data],
                areaId: '',
                status,
            },
        })
    } catch (error) {
        console.error('dashboardAction', error)
    }
}

queryActions.socketOn = () => {

    const query = {
        transports: ['websocket'],
        secure: false,
        query: `token=${JSON.parse(localStorage['userToken'])}`
    };
    io = Socket(apiURLTicketsSocket, query);

    io.on('reconnect_attempt', () => {
        console.log('attempts')
        io.io.opts.transports = ['websocket'];
    });

    io.on('authenticated', ev => {
        queryActions.getDashboardList();
        console.log('authenticated');
    });

    io.on('web_new_ticket', ev => {
        queryActions.getDashboardList();
        console.log('New ticket', ev);
    });
    
    io.on('web_updated_ticket', ev => {
        queryActions.getDashboardList();
        console.log('New updated ticket', ev);
    });
    
    io.on('updated_changeover', ev => {
        queryActions.getDashboardList();
        console.log('New updated changeover ticket', ev);
    });
}

queryActions.socketOff = () => {
    console.log('socket unmount')
    io.disconnect()
}

queryActions.getDashboardMaintenanceTicket = async (query = '') => {
    console.log("Here E")
    const url = `${apiURLTickets}/api/tickets/dashboard-maintenance${query ? `?${query}` : ''}`;
    try {
        const { data, status } = await tokenGet(url);
        if(!status) return false;
        const payload = { ticketsMaintenance: data.maintenanceDashboard };
        const type = actions.DASHBOARD_MAINTENANCE;
        dispatch({ type, payload });
    } catch (error) {
        console.log(error);
    }
};

export default () => {
    return {
        ...queryActions
    }
}
