import React, { useEffect } from 'react'
import { CircularProgress, IconButton, InputAdornment, TextField } from "@material-ui/core";
import GTranslateIcon from '@material-ui/icons/GTranslate';
import { useStyles } from './style';
import { GetGoogleTranslation } from '../../../../../redux/actions/Translation/googleTranslationAction'

export default function InputTranslation ({ onChangeText, language, key, name, value, label }) {
  const classes = useStyles();
  const [results, fetchingData, isAwait, setIsAwait] = GetGoogleTranslation();

  useEffect(() => {
    onChangeText(results.result);
  }, [results])

  const TranslateIcon = ( position,color, key, q, target ) =>{
    return <>
        <InputAdornment position={position}>
        <IconButton disabled={isAwait} onClick={() => { setIsAwait(true); fetchingData(q, target, key)}}>
            { isAwait ? 
            <CircularProgress variant="indeterminate" disableShrink color="primary" size={20} />
            : <GTranslateIcon className={classes.GTranslateIcon} />}
        </IconButton>
        </InputAdornment>
    </>
  }

  return <>
    <TextField
      className={classes.TextField}
      label={label}
      type="text"
      value={value}
      onChange={e => {
        onChangeText(e.target.value);
      }}
      fullWidth
      InputProps={{
        endAdornment: (TranslateIcon('end', 'primary', key, name, language.language)),
      }}
    />
  </>
}