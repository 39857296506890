import React, { useState, useEffect } from 'react';
import MainPage from '../../../components/MainPage/MainPage';
import MainPageHeader from '../../../components/MainPageHeader/MainPageHeader';
import MainPageBody from '../../../components/MainPageBody/MainPageBody';
import { Grid, Snackbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ProblemTable from './ProblemTable';
import TotalMachinesRepair from './TotalMachinesRepair';
import ReportedTimeBeforeRepair from './ReportedTimeBeforeRepair';
import RepairTime from './RepairTime';
import LineLeaderConfirmation from './LineLeaderConfirmation';
import CardPaper from './components/CardPaper';
import Filters from './Filters';
import DateRange from './DateRange';
import ProblemDonut from './components/ProblemDonut/Donut';
import TopResources from './TopResources';
import StatisticCard from "./StatisticCard";
import { 
  nornalizeProblemRows, 
  sortRows, 
  splitRows, 
  compactObject, 
  dateRangeInterval, 
  getDateRangeQueryString,
} from './helpers';
import { 
  client, 
  fetchLastestDataCronJob, 
  fetchProblemTypes, 
  saveFilters,
} from './api';
import qs from 'qs';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
  addDays,
  endOfDay,
  startOfDay
} from 'date-fns';
import LineNPT from './LineNPT';
import OverallMachanicCard from './components/OverallMechanicPerformance';
import IndividualMechanicPerformance from './components/IndividualMechanicPerformance';
import ExcelLoading from '../../../components/Modal/ExcelLoading/ExcelLoading';
import MachineBreakDown from './components/MachineBreakDown';
import { useRef } from 'react';
import { Alert } from '@material-ui/lab';
import moment from 'moment';

const useStyles = makeStyles({
  alignSelfCenter: {
    alignSelf: 'center'
  },
  pt3: {
    paddingTop: '1.5rem'
  },
})

export default function Analytics() {
  const [rows, setRows] = useState([]);
  const [originalRows, setOriginalRows] = useState([]);
  const [orderBy, setOrderBy] = useState("totalTickets");
  const [order, setOrder] = useState("desc");

  const [filters, setFilters] = useState({});
  const [dateRange, setDateRange] = useState({
    startDate: startOfDay(addDays(new Date(), -7)),
    endDate: endOfDay(new Date()),
    interval: "daily"
  });
  const [queryParam, setQueryParam] = useState({
    ...filters,
    dateRange
  });

  const [sortedRows, setSortedRows] = useState([]);
  const [lastUpdatedAt, setLastUpdatedAt] = useState(null);
  const [isCron, setIsCron] = useState(true);
  const [isCronError, setCronError] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [viewBy, setViewBy] = useState(1);

  const params = compactObject({
    ...filters,
    dateRange
  });
  
  const handleRequestSort = (property) => {
    const isAsc = orderBy == property && order == 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const toggleRows = (emitRows, checked)=>{
    let tempRows = rows;
    emitRows.forEach((row)=>{
      const index = tempRows.findIndex((r)=> r.id == row.id);
      tempRows[index].isSelected = checked;
    })

    setRows([...tempRows]);
  }

  const handleDateRangeChanged = ({ startDate, endDate })=>{
    setDateRange({
      startDate,
      endDate,
      interval: dateRangeInterval({ startDate, endDate })
    })
  }

  const requery = ()=> {
    if(isCron) return;
    setQueryParam(params);
    fetchProblemTypes({ filters: getDateRangeQueryString(params), viewBy }).then((result)=>{
      setOriginalRows(result.problemTypes.data);
      const normalizedRows = nornalizeProblemRows(result.problemTypes.data);
      setRows(normalizedRows);
    });
  }

  const getLastestDataCronJob = () => {
    setIsCron(true);
    fetchLastestDataCronJob()
      .then(({ data:{ updatedDt }, error }) => {
        if(!error && updatedDt) {
          const date = moment.utc(updatedDt).format('YYYY-MM-DD HH:mm:ss');
          setLastUpdatedAt(new Date(date));
          setIsCron(false);
          return;
        }
        setCronError(true);
        setIsCron(false);
      })
      .catch((error) => {
        setCronError(true);
        setIsCron(false);
        console.error(error);
      });
  }

  useEffect(() => {
    getLastestDataCronJob();
  }, []);

  useEffect(()=> {
    if(isCron) return;
    requery();
  }, [
    filters.buildings, 
    filters.floors, 
    filters.areas, 
    filters.lines, 
    filters.mechanics, 
    filters.statuses, 
    filters.shifts, 
    filters.machineTypes, 
    filters.machineSubTypes, 
    filters.machineBrands, 
    filters.machineSupplier,
    dateRange.startDate, 
    dateRange.endDate,
    viewBy,
    isCron,
  ]);

  useEffect(()=>{
    const splitedRows = splitRows(sortRows(rows, orderBy, order))
    setSortedRows(splitedRows);
  }, [rows, orderBy, order])

  useEffect(()=>{
    client.get("/templates").then((res)=>{
      setTemplates(res.data);
    })
  }, [])

  const handleTemplateSelected = (template)=>{
    if(template.filters){
      setFilters({
        ...template.filters,
        dateRange: null
      });
    }

    if(template.filters.dateRange){
      const { startDate, endDate } = template.filters.dateRange;
      handleDateRangeChanged({
        startDate: startOfDay(Date.parse(startDate)),
        endDate: endOfDay(Date.parse(endDate))
      });
    }
  }

  const createTemplate = (name, callback)=>{
    saveFilters({ templateName: name, filters: queryParam }, (template)=>{
      callback();
      setTemplates([
        template,
        ...templates
      ])
    })
  }

  const deleteTemplate = (template)=>{
    client.delete(`/templates/${template.id}`).then((res)=>{
      setTemplates((ts)=> ts.filter((t)=> t.id != template.id))
    });
  }

  return (
    <MainPage>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isCronError} 
        onClose={() => setCronError(false)}
        autoHideDuration={6000}>
        <Alert onClose={() => setCronError(false)} severity="error">
          <Typography variant='h6'>{'Unable to get the latest data!'.translate()}</Typography>
        </Alert>
      </Snackbar>
      <MainPageHeader title={"Analytics".translate()} search={false} />
      <MainPageBody>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <Filters
            templates={templates}
            onFilter={(fts)=> setFilters(fts)}
            onSave={(name, callback)=> createTemplate(name, callback)}
            currentFilters={filters}
          />
        </Grid>
        <Grid item xs={10}>
          <DateRange
            onSelectAllData={() => setFilters({ dateRange: filters.dateRange })}
            onRefresh={getLastestDataCronJob}
            isLoading={isCron}
            lastUpdatedAt={lastUpdatedAt}
            dateRange={dateRange}
            setDateRange={handleDateRangeChanged}
            onTemplateSelected={handleTemplateSelected}
            templates={templates}
            onDeleteTemplate={deleteTemplate}
          />
          <StatisticCard
            isCron={isCron}
            filters={queryParam}
          />

          <LineNPT
            isCron={isCron}
            dateRange={dateRange}
            filters={queryParam} />
            
          <OverallMachanicCard
            isCron={isCron}
            filters={queryParam} />
            
          <IndividualMechanicPerformance
            isCron={isCron}
            filters={queryParam} />
         

          <MachineBreakDown 
            data={sortedRows} 
            toggleRows={toggleRows}
            handleRequestSort={handleRequestSort}
            handleViewBy={setViewBy}
            order={order}
            orderBy={orderBy}
            viewBy={viewBy}
            viewType={viewBy}
            params={params}
          />

          <TopResources
            isCron={isCron}
            filters={queryParam}
          />

          {
            queryParam ? <>
              <TotalMachinesRepair
                dictionary={sortedRows}
                originalRows={originalRows}
                filters={queryParam}
                dateRange={dateRange}
                viewType={viewBy}
                isCron={isCron}
              />
              <ReportedTimeBeforeRepair
                dictionary={sortedRows}
                originalRows={originalRows}
                filters={queryParam}
                dateRange={dateRange}
                viewType={viewBy}
                isCron={isCron}
              />
              <RepairTime
                dictionary={sortedRows}
                originalRows={originalRows}
                filters={queryParam}
                dateRange={dateRange}
                viewType={viewBy}
                isCron={isCron}
              />
              <LineLeaderConfirmation
                dictionary={sortedRows}
                originalRows={originalRows}
                filters={queryParam}
                dateRange={dateRange}
                viewType={viewBy}
                isCron={isCron}
              />
            </> : null
          }
        </Grid>
      </Grid>


      </MainPageBody>
      <ExcelLoading />
    </MainPage>
  )
}
