import React from 'react';
import { 
    IconButton, 
    MenuItem, 
    Select, 
    Typography, 
} from '@material-ui/core';
import { 
    FlexBox, 
    ExportIcon,
    BootstrapInput,
    Label,
} from './styles';
import { viewsByFilters } from './constant';
import { BetaIcon } from '../../styles/styles';

export default function Header({handleExport, handleViewBy, viewBy}){
    return (
        <FlexBox marginBottom={3}>
            <FlexBox>
                <Typography variant='h4'>{'Machine Breakdown Analysis'.translate()}</Typography>
                <BetaIcon />
            </FlexBox>
            <FlexBox gap={20}>
                <FlexBox gap={10}>
                <Label>{'View By'.translate()}</Label>
                <Select
                    input={<BootstrapInput />}
                    onChange={({target: { value }}) => handleViewBy(value)} 
                    value={viewBy}>
                    {viewsByFilters.map((item, key) => (
                        <MenuItem value={key+1}>{item.translate()}</MenuItem>
                    ))}
                </Select>
                </FlexBox>
                <IconButton onClick={handleExport} size='small'>
                    <ExportIcon />
                </IconButton>
            </FlexBox>
        </FlexBox>
    )
}