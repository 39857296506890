import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import TokenExpired from '../../components/Modal/ConfirmModal/TokenExpired';
import { globalAdminClient } from "../../utils/api/CoreApi";

function GroupLink({ group, goTo }){
  const hasLink =  group.links.length > 0;
  if(!hasLink) return null;

  return <div className="link-group">
    <div className="group-header">
      {group.label.translate()}
    </div>
    {
      group.links.map((link, index)=>(
        <div
          key={index}
          onClick={() => goTo(link.urlPath)}
          className="drawer-link"
        >
          { link.menu.translate() }
        </div>
      ))
    }
  </div>
}

export default function Sidebar(props){
  const { goTo } = props;
  const [menus, setMenus] = useState([]);
  useEffect(()=>{
    globalAdminClient.get("ref/menus?product=LTm").then(({ data })=>{
      const groups = data.data.filter((m)=> !m.refId);
      const normalized = groups.map((group)=>{
        return {
          label: group.menu,
          links: data.data.filter((m)=> m.refId == group.id)
        }
      })
      setMenus(normalized)
    }).catch((error) => {
      if(error.response.status == 401){
          confirmAlert({
              customUI: ({ onClose }) => <TokenExpired onClose={onClose} />,
              closeOnClickOutside: false,
              closeOnEscape: false,
          });
      };
    })
  }, [])

  return <div className="drawer-body">
    {
      menus.map((menu, index)=>(
        <GroupLink
          key={index}
          group={menu}
          goTo={goTo}
        />
      ))
    }
  </div>
}
