import {
   CONDITION_LIST,
   CONDITION_LIST_REQUEST,
   CONDITION_LIST_SUCCESS,
   CONDITION_LIST_ERROR
} from '../actionTypes';
import { tokenGet, apiSettings } from '../../../utils/api/CoreApi';

export const conditionRequest = () => {
    return {
        type: CONDITION_LIST_REQUEST
    }
}

export const conditionListSuccess = conditions => {
    return {
        type: CONDITION_LIST_SUCCESS,
        payload: conditions
    }
}

export const getConditions = (isActive = 1, currentPage = 1, pageSize = 100000) =>{
    return function(dispatch){
        dispatch(conditionRequest());
        tokenGet(apiSettings.apiURLReferences + '/conditions?isActive='+ isActive +'&currentPage=' + currentPage +'&pageSize=' + pageSize).then((response) => {
            let data = response.data.machines.result.map((item) => {
                let itemRow = {
                    'id': item.id,
                    'condition': item.desc1
                }
                return itemRow;
            });
            dispatch(conditionListSuccess({
                conditions: data,
                meta: response.data.machines.meta
            }));
        })
        .catch((error) => {
            console.log(error);
        });
    }
}