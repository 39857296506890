import React from 'react';
import { Box } from '@material-ui/core';
import StarRounded from '@material-ui/icons/StarRounded';

export default function CriticalMark({ isActive, ...props }) {
  return <Box textAlign="center" width={1}>
    <StarRounded 
      style={{ color: isActive ? '#FFB53E' : '#D2D2D2'  }}
    />
  </Box> 
}