import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { withStyles } from "@material-ui/core/styles";

export default withStyles({
  root: {
    marginBottom: "1rem",
    '& .MuiFormLabel-root': {
      zIndex: 9,
    }
  }
  
})(({ error, ...props }) => {
  const hasError = Boolean(error);
  return <KeyboardDatePicker
    fullWidth
    error={hasError}
    helperText={error}
    {...props}
  />
});