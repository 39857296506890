import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { connect } from "react-redux";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  selected: {
    backgroundColor: "#0F75BC !important",
    color: "white",
    fontWeight: 600
  }
}));

export default function SelectedListItem(props) {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const machineListChecklist = props.machineListChecklist
  console.log('check machinelist')
  console.log(machineListChecklist)
  const handleListItemClick = (event, index) => {
    console.log('selected index is ' + index)
    props.onSelectMachine(index)
    setSelectedIndex(index);
  };

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="secondary mailbox folder">
      {machineListChecklist.map((item, itemIndex) => (
        <ListItem
        button
        key={itemIndex}
        selected={selectedIndex === item.id}
        classes={{ selected: classes.selected }}
        style={{paddingRight:'254px'}}
        onClick={(event) => handleListItemClick(event, item.id)}
        >
      <ListItemText primary={item.machine} />
        </ListItem>
        ))}
    
      </List>
    </div>
  );
}
