import React, { useState, useEffect } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import Snackbar from "@material-ui/core/Snackbar";
import Drawer from "@material-ui/core/Drawer";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Logo from "../../assets/img/ltlabs-logo.png";
// import { Offline, Online } from "react-detect-offline";
// import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import history from "../../history";
import {
  // menuDrawerOpen,
  menuDrawerClose,
} from "../../redux/actions/MenuDrawer/menuDrawerActions";
import { updateAccess, postLogout } from "../../redux/actions/Auth/authActions";
import { notificationClose } from "../../redux/actions/Notification/notificationActions";
import {
  getMachines,
  machineListUpdatePage,
} from "../../redux/actions/Machine/machineActions";
import {
  getStatus,
  getMachineStatus,
  getMachineStatusv2,
} from "../../redux/actions/Status/StatusActions";
import { getMacTypes } from "../../redux/actions/MacTypes/MacTypeActions";
import { getMacSubTypes } from "../../redux/actions/MacSubTypes/MacSubTypeActions";
import { getBrands } from "../../redux/actions/Brand/brandActions";
import { getMotorTypes } from "../../redux/actions/MotorTypes/motorTypeActions";
import {
  setters,
  checkPageAccess,
  // checkPageActionAccess,
  isSuperAdmin,
  checkPageAccessField,
  checkIfSuperAdminFactoryExist,
} from "../../utils/helper";

import { apiSettings, tokenGet } from "../../utils/api/CoreApi";
import io from "socket.io-client";
import "./App.scss";
import { PAGE_ENUMS } from '../../utils/constants';
import Sidebar from './Sidebar';

const NetworkStatus = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    var handle = setInterval(getNetwork, 5000);

    return () => {
      clearInterval(handle);
    };
  }, []);
  const getNetwork = async () => {
    await axios
      .get(window.location.origin)
      .then((res) => {
        const network = res.data;
        // console.log(network)
        setOpen(false);
      })
      .catch(function (error) {
        console.log("No Network", error);
        setOpen(true);
      });
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={5000}
      >
        <Alert variant="filled" severity="error">
          {"No internet connection".translate()}
        </Alert>
      </Snackbar>
    </div>
  );
};
class App extends React.Component {
  state = {
    offlineWarning: true,
    offlineWarningDuration: 5000,
    loading: false,
    subMenu: null,
  };
  setLoading(flag) {
    this.setState({ loading: flag });
  }

  handleOfflineWarning(flag) {
    this.setState({ offlineWarning: flag });
  }

  initializeSocket(userToken,userId){
    let socket = io(apiSettings.apiURLTicketsSocket,
      {
          transports: ['websocket'],
          upgrade: false,
          secure: true,
          query:`token=${JSON.parse(localStorage['userToken'])}`
      })
      socket.on('reconnect_attempt', () => {
        console.log('attempts')
        socket.io.opts.transports = ['websocket'];
    });
    // let socket = io(apiSettings.apiURLTickets);
    // socket.on('connect', function(){
    //    socket.emit('authentication', {
    //       userId: userId,
    //       token: userToken,
    //    });
    // });

    socket.on('logout', (data) => {
      let jsonData = JSON.parse(data);
      if (jsonData.userId === userId.toString()) {
        localStorage.setItem("forcedLogoutSession", "true");
        this.props.postLogout();
      }
    });

    // socket.on('disconnect', function(){
    //    socket.connect();
    // });
  }

  componentDidMount(){
      if (localStorage.getItem('changeFactory') === 'true'){
        localStorage.removeItem('changeFactory');
        localStorage.removeItem('dashboardQuery');
        history.push('/dashboard');
      }
      if (localStorage.getItem('userToken')){
         let userToken = JSON.parse(localStorage.getItem('userToken'));
         let username = JSON.parse(localStorage.getItem('username'));
         let tokenDecoded = jwtDecode(userToken);
         let userId = tokenDecoded.userId;

         tokenGet(`${apiSettings.apiURLAuthGlobalAdmin}/useraccess?product=LTm`)
          .then(({ data })=>{
            const userAccess = data.data.access;

            this.props.updateAccess({
              token: userToken,
              access: userAccess,
              factoryId: tokenDecoded.factoryId,
              username: username,
              userId: tokenDecoded.userId,
            });
          })
          .catch((error)=>{
            console.error('useraccess', error)
          })
         
         this.initializeSocket(userToken, userId);
      }
  }

  async componentWillMount() {
    setters();
    await this.setLoading(true);
    if (localStorage.getItem("userToken")) {
      if (checkPageAccess(this.props.userAccess, { pageId: 1 })) {
        await this.props.getMachines();
      }
    }
    await this.setLoading(false);
  }

  redirectTo(url) {
    if (window.location.pathname.indexOf(url) < 0) {
      delete localStorage["fromCalendar"];
      history.push(url);
      this.props.menuDrawerClose();
    } else {
      this.props.menuDrawerClose();
    }
  }

  toggleSubMenu = (value) => {
    if (this.state.subMenu === value) {
      this.setState({ subMenu: null });
    } else {
      this.setState({ subMenu: value });
    }
  };

  render() {
    return (
      <div className="App">
        {this.state.loading === false ? this.props.children : null}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={this.props.notification.autoHideDuration}
          open={this.props.notification.open}
          onClose={(event, reason) => {
            if (reason !== "clickaway") {
              this.props.notificationClose();
            }
          }}
        >
          <Alert
            style={{ fontWeight: "400" }}
            onClose={(event, reason) => {
              if (reason !== "clickaway") {
                this.props.notificationClose();
              }
            }}
            variant="filled"
            severity={this.props.notification.severity}
          >
            {this.props.notification.message}
          </Alert>
        </Snackbar>

        <Drawer
          anchor={"left"}
          open={this.props.drawerOpen}
          onClose={this.props.menuDrawerClose}
        >
          <div className="menu-drawer">
            <div className="drawer-header">
              <Grid container spacing={0}>
                <Grid item xs={2}>
                  <ArrowBackIcon
                    className="drawer-back"
                    onClick={this.props.menuDrawerClose}
                  />
                </Grid>
                <Grid item xs={7}>
                  <div className="logo">
                    <img alt="LTMechanic" src={Logo} />
                  </div>
                </Grid>
                <Grid item xs={3} />
              </Grid>
            </div>

            <Sidebar
              goTo={(url)=> this.redirectTo(url)}
            />
          </div>
        </Drawer>
        <NetworkStatus />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notification: state.notificationReducer,
    drawerOpen: state.menuDrawerReducer.open,
    userAccess: state.authReducer.access,
    factoryAssigned: state.factoryListReducer.factoryAssigned,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    menuDrawerClose: () => dispatch(menuDrawerClose()),
    notificationClose: () => dispatch(notificationClose()),
    updateAccess: (payload) => dispatch(updateAccess(payload)),
    postLogout: () => dispatch(postLogout()),
    getMachines: () => dispatch(getMachines()),
    getStatus: () => dispatch(getStatus()),
    getMachineStatus: () => dispatch(getMachineStatus()),
    getMachineStatusv2: () => dispatch(getMachineStatusv2()),
    getMacTypes: () => dispatch(getMacTypes()),
    getMacSubTypes: () => dispatch(getMacSubTypes()),
    getBrands: () => dispatch(getBrands()),
    getMotorTypes: () => dispatch(getMotorTypes()),
    getFilteredMachines: (statusId) =>
    dispatch(getMachines(1, 1, 10000, statusId)),
    machineListUpdatePage: (page) => dispatch(machineListUpdatePage(page)),
  };
};

App = connect(mapStateToProps, mapDispatchToProps)(App);

export default App;
// export default () => (
//   <SnackbarProvider maxSnack={3}>
//       <App />
//   </SnackbarProvider>
// );
