import {
    STATUS_LIST,
    STATUS_LIST_REQUEST,
    STATUS_LIST_SUCCESS,
    STATUS_LIST_MACHINE_SUCCESS,
    STATUS_LIST_TICKET_SUCCESS,
    STATUS_LIST_CHECKLIST_TEMPLATE_SUCCESS,
    STATUS_LIST_ERROR
 } from '../actionTypes';
 import { tokenGet, apiSettings } from '../../../utils/api/CoreApi';

 const MODULES = {
    REPAIR_TICKETS: 'RT',
    MAINTENANCE_TICKETS: 'MT',
    CHECKLIST_MAINTENANCE: 'CM',
    CHECKLIST_REPAIR: 'CR',
    MACHINE_LIST: 'ML',
}
 
 export const statusRequest = () => {
     return {
         type: STATUS_LIST_REQUEST
     }
 }
 
 export const statusListSuccess = statusList => {
     return {
         type: STATUS_LIST_SUCCESS,
         payload: statusList
     }
 }

 export const statusListMachineSuccess = statusList => {
    return {
        type: STATUS_LIST_MACHINE_SUCCESS,
        payload: statusList
    }
}

export const statusListTicketSuccess = statusList => {
   return {
       type: STATUS_LIST_TICKET_SUCCESS,
       payload: statusList
   }
}

export const statusListChecklistTemplateSuccess = statusList => {
   return {
       type: STATUS_LIST_CHECKLIST_TEMPLATE_SUCCESS,
       payload: statusList
   }
}
 
 export const getStatus = (isActive = 'all', moduleId = 'all',  currentPage = 1, pageSize = 100000) =>{
     return function(dispatch){
         dispatch(statusRequest());
         tokenGet(apiSettings.apiURLReferences + '/status?isActive='+ isActive + '&moduleId=' + moduleId +'&currentPage=' + currentPage +'&pageSize=' + pageSize).then((response) => {
             let data = response.data.machines.result.map((item) => {
                 let itemRow = {
                     'id': item.id,
                     'statusId': item.status,
                     'status': item.desc1
                 }
                 return itemRow;
             });
             dispatch(statusListSuccess({
                 statusList: data,
                 meta: response.data.machines.meta
             }));
         })
         .catch((error) => {
             console.log(error);
         });
     }
 }

 export const getMachineStatus = (isActive = 'all', currentPage = 1, pageSize = 100000) =>{
    return function(dispatch){
        dispatch(statusRequest());
        tokenGet(apiSettings.apiURLReferences + '/status/v2?isActive=' + isActive + '&module=' + MODULES.MACHINE_LIST + '&currentPage=' + currentPage + '&pageSize=' + pageSize).then((response) => {
            let data = response.data.status.result.filter((item) => {
                return (item.desc1 === 'ACTIVE' && item.status === '1') ||  
                       item.desc1 === 'AVAILABLE' ||
                       item.desc1 === 'MAINTENANCE' ||
                       item.desc1 === 'NOT AVAIL/RETIRED' ||
                       item.desc1 === 'CHANGEOVER' ||
                       item.desc1 === 'REPAIR'
            }).map((item) => {
                let itemRow = {
                    'id': item.id,
                    'statusId': item.status,
                    'status': item.desc1
                }
                return itemRow;
            });
            // let all = {
            //     id: 0,
            //     status: 'ALL'
            // }
            // data.push(all);
            // let mutatedData = data.sort((a , b) => a.id - b.id);
            dispatch(statusListMachineSuccess({
                machineStatusList: data,
            }));
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const getMachineStatusv2 = (isActive = 1, currentPage = 1, pageSize = 100000, moduleId = 'all') =>{
    return function(dispatch){
        dispatch(statusRequest());
        tokenGet(apiSettings.apiURLReferences + '/status/v2?isActive=' + isActive + '&module=ML' + '&currentPage=' + currentPage + '&pageSize=' + pageSize).then((response) => {
            let data = response.data.status.result.filter((item) => {
                return item.desc1 === 'IN USE' ||  
                       item.desc1 === 'AVAILABLE' ||
                       item.desc1 === 'MAINTENANCE' ||
                       (item.desc1 === 'INACTIVE' || item.desc1 === 'NOT AVAIL/RETIRED' || item.desc1 === 'RETIRED') ||
                       item.desc1 === 'CHANGEOVER' ||
                       item.desc1 === 'REPAIR'
            }).map((item) => {
                let itemRow = {
                    'id': item.id,
                    'statusId': item.status,
                    'status': item.desc1 != 'INACTIVE' 
                        ? item.desc1 
                        : 'N/A',
                }
                return itemRow;
            });
            // let all = {
            //     id: 0,
            //     status: 'ALL'
            // }
            // data.push(all);
            // let mutatedData = data.sort((a , b) => a.id - b.id);
            dispatch(statusListMachineSuccess({
                machineStatusList: data,
            }));
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const getRepairTicketStatus = (isActive = 'all', currentPage = 1, pageSize = 100000) =>{
    return function(dispatch){
            dispatch(statusRequest());
            tokenGet(apiSettings.apiURLReferences + '/status/v2?isActive=' + isActive + '&module='+ MODULES.REPAIR_TICKETS +'&currentPage=' + currentPage + '&pageSize=' + pageSize).then((response) => {
                let data = response.data.status.result.filter((item) => {
                    return item.desc1 === 'REPORTED' ||
                           item.desc1 === 'IN-REPAIR' ||
                           item.desc1 === 'REPAIRED' ||
                           (item.desc1 === 'CANCELLED' && item.status === "4") ||
                           item.desc1 === 'CLOSED' ||
                           item.desc1 === 'RE-OPENED'
                }).map((item) => {
                    let itemRow = {
                        'id': item.id,
                        'statusId': item.status,
                        'status': item.desc1
                    }
                return itemRow;
            });
            dispatch(statusListTicketSuccess({
                ticketStatusList: data,
            }));
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const getMaintenanceTicketStatus = (isActive = 'all', currentPage = 1, pageSize = 100000) =>{
    return function(dispatch){
            dispatch(statusRequest());
            tokenGet(apiSettings.apiURLReferences + '/status/v2?isActive=' + isActive + '&module='+ MODULES.MAINTENANCE_TICKETS +'&currentPage=' + currentPage + '&pageSize=' + pageSize).then((response) => {
                let data = response.data.status.result.filter((item) => {
                    return item.desc1 === 'SCHEDULED' ||
                           item.desc1 === 'IN PROGRESS' ||
                           (item.desc1 === 'CANCELLED' && item.status === "3") ||
                           item.desc1 === 'COMPLETED' ||
                           item.desc1 === 'CLOSED'
                }).map((item) => {
                    let itemRow = {
                        'id': item.id,
                        'statusId': item.status,
                        'status': item.desc1
                    }
                return itemRow;
            });
            dispatch(statusListTicketSuccess({
                ticketStatusList: data,
            }));
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const getChecklistTemplateStatus = () =>{
    return function(dispatch){
            dispatch(statusRequest());
            tokenGet(apiSettings.apiURLReferences + '/status/checklists').then((response) => {
                console.log('getting status')
                console.log(response.data)
                let data = response.data.checklists.map((item) => {
                    console.log('check checklist status type')
                    console.log(item)
                    let itemRow = {
                        'id': item.id,
                        'statusId': item.status,
                        'status': item.desc1
                    }
                return itemRow;
            });
            dispatch(statusListChecklistTemplateSuccess({
                checklistTemplatesStatusList: data,
            }));
        })
        .catch((error) => {
            console.log(error);
        });
    }
}