import React from "react";
import {
  createMuiTheme,
  FormControl,
  makeStyles,
  NativeSelect,
  ThemeProvider,
} from "@material-ui/core";

export default function DataSelect({
  title = "",
  items = "",
  handleChange,
  selectedId,
  filterDisabled,
}) {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <span className={classes.lineText}>{title.translate()}</span>
      <ThemeProvider theme={filterTheme}>
        <NativeSelect
          disabled={filterDisabled}
          name="data"
          value={parseInt(selectedId)}
          onChange={handleChange}
        >
          {items}
        </NativeSelect>
      </ThemeProvider>
    </FormControl>
  );
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: "inline-block",
    minWidth: 120,
  },
  selectEmpty: {
    paddingRight: "24px",
    fontWeight: "600",
    paddingLeft: "5px",
  },
  lineText: {
    paddingRight: "12px",
    position: "relative",
    top: "1px",
    fontWeight: "600",
  },
}));

const filterTheme = createMuiTheme({
  overrides: {
    MuiNativeSelect: {
      select: {
        paddingRight: "24px",
        fontWeight: "600",
        paddingLeft: "5px",
        color: "#0F75BC",
      },
      icon: {
        top: "calc(50% - 14px)",
        color: "#0F75BC",
        fontSize: "x-large",
      },
    },
  },
});
