import {
   NOTIFICATION_SUCCESS,
   NOTIFICATION_ERROR,
   PREFERENCE_DETAIL_REQUEST,
   PREFERENCE_DETAIL_SUCCESS,
   PREFERENCE_UPDATE_REQUEST,
   PREFERENCE_UPDATE_SUCCESS,
 } from '../actionTypes';
 import { getFactoryId } from '../../../utils/helper';
 import { tokenGet, tokenPost, tokenPut,tokenDelete, apiSettings } from '../../../utils/api/CoreApi';
 import history from '../../../history';

 export const notificationSuccess = (message) => {
     return {
         type: NOTIFICATION_SUCCESS,
         payload: {
             message: message.translate()
         }
     }
 }
 
 export const notificationError = (message) => {
     return {
         type: NOTIFICATION_ERROR,
         payload: {
             message: message.translate()
         }
     }
 }
 
 export const preferenceRequest = () => {
     return {
         type:PREFERENCE_DETAIL_REQUEST
     }
 }
 
 export const preferenceSuccess = preferenceList => {
     return {
         type:PREFERENCE_DETAIL_SUCCESS,
         payload: preferenceList
     }
 }
 
 export const preferenceUpdateRequest = () => {
     return {
         type:PREFERENCE_UPDATE_REQUEST
     }
 }
 
 export const preferenceUpdateSuccess = preference => {
     return {
         type:PREFERENCE_UPDATE_SUCCESS,
         payload: preference
     }
 }

 export const getSystemPreference = (id) =>{
    return function(dispatch){
        dispatch(preferenceRequest());
        tokenGet(apiSettings.apiURLReferences + '/productconfig').then((response) => {
            let userFactoryId = getFactoryId();
            let systemPreference = response.data.data.filter((item) => { return item.factoryId === userFactoryId});
            let reference = response.data.reference.filter((item) => { return item.factoryId === userFactoryId});
            const translations = {};
                reference.forEach((ref) => {
                    translations[ref.constant] = ref.translations;
                });
            dispatch(preferenceSuccess({
                systemPreference: systemPreference,
                reference: reference,
                translations: translations,
            }));
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const getSystemPreferenceReference = (id) =>{
    return function(dispatch){
        dispatch(preferenceRequest());
        tokenGet(apiSettings.apiURLReferences + '/productconfig?isRefOnly=true').then((response) => {
            let userFactoryId = getFactoryId();
            let systemPreference = response.data.data.filter((item) => { return item.factoryId === userFactoryId});
            let reference = response.data.reference.filter((item) => { return item.factoryId === userFactoryId});
            const translations = {};
                reference.forEach((ref) => {
                    translations[ref.constant] = ref.translations;
                });
            dispatch(preferenceSuccess({
                systemPreference: systemPreference,
                reference: reference,
                translations: translations,
            }));
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

 export const putUpdateSystemPreference = (payload) => {
    return function(dispatch){
        dispatch(preferenceUpdateRequest());
        tokenPut(apiSettings.apiURLReferences + "/productconfig",payload).then((response) => {
            dispatch(notificationSuccess('Successfully updated system preference'));
        })
        .catch((error) => {
            console.log(error);
        });
    }
 }