import {
   NOTIFICATION_SUCCESS,
   NOTIFICATION_ERROR,
   SOLUTION_LIST,
   SOLUTION_LIST_REQUEST,
   SOLUTION_LIST_SUCCESS,
   SOLUTION_LIST_ERROR,
   SOLUTION_DETAIL_REQUEST,
   SOLUTION_DETAIL_SUCCESS,
   SOLUTION_DETAIL_ERROR,
   SOLUTION_NEW_REQUEST,
   SOLUTION_NEW_SUCCESS,
   SOLUTION_NEW_ERROR,
   SOLUTION_UPDATE_REQUEST,
   SOLUTION_UPDATE_SUCCESS,
   SOLUTION_UPDATE_ERROR,
   SOLUTION_DELETE_REQUEST,
   SOLUTION_DELETE_SUCCESS,
   SOLUTION_DELETE_ERROR,
 } from '../actionTypes';
 import { tokenGet, tokenPost,tokenPut, tokenDelete, apiSettings } from '../../../utils/api/CoreApi';
 import { getProblemsTreeView, getProblemDetail } from '../Problem/problemActions';
 import history from '../../../history';

 export const notificationSuccess = (message) => {
    return {
        type: NOTIFICATION_SUCCESS,
        payload: {
            message: message.translate()
        }
    }
}

export const notificationError = (message) => {
    return {
        type: NOTIFICATION_ERROR,
        payload: {
            message: message.translate()
        }
    }
}
 
 export const solutionRequest = () => {
     return {
         type:SOLUTION_LIST_REQUEST
     }
 }
 
 export const solutionSuccess = solutionList => {
     return {
         type:SOLUTION_LIST_SUCCESS,
         payload: solutionList
     }
 }

 export const solutionDetailRequest = () => {
    return {
        type:SOLUTION_DETAIL_REQUEST
    }
}

export const solutionDetailSuccess = solutionDetail => {
    return {
        type:SOLUTION_DETAIL_SUCCESS,
        payload: solutionDetail
    }
}

 export const solutionNewRequest = () => {
    return {
        type:SOLUTION_NEW_REQUEST
    }
}

export const solutionNewSuccess = solutionList => {
    return {
        type:SOLUTION_NEW_SUCCESS,
        payload: solutionList
    }
}

export const solutionUpdateRequest = () => {
    return {
        type:SOLUTION_UPDATE_REQUEST
    }
}

export const solutionUpdateSuccess = solutionList => {
    return {
        type:SOLUTION_UPDATE_SUCCESS,
        payload: solutionList
    }
}
 
 export const getSolutionsByProblem = (problemTypeId = 1, isActive = 1, currentPage = 1, pageSize = 100000) =>{
     return function(dispatch){
         dispatch(solutionRequest());
         tokenGet(apiSettings.apiURLReferences + '/solutiontypes/by-problem?problemTypeId=' + problemTypeId + '&isActive='+ isActive +'&currentPage=' + currentPage +'&pageSize=' + pageSize).then((response) => {
            //  let data = response.data.solutions.result.map((item) => {
            //     let itemRow = {
            //         'id': item.id,
            //         'desc': item.desc1,
            //     }
            //     return itemRow;
            //  });
            //  console.log(data);
            console.log(response.data)
             dispatch(solutionSuccess({
                 solutionList: response.data.solutions,
                 meta: response.data.solutions.meta
             }));
         })
         .catch((error) => {
             console.log(error);
         });
     }
 }

 export const getSolutionDetail = (id) =>{
    return function(dispatch){
        dispatch(solutionDetailRequest());
        tokenGet(apiSettings.apiURLReferences + '/solutiontypes/' + id).then((response) => {
            dispatch(solutionDetailSuccess({
                solutionDetail: response.data.solutions[0],
            }));
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const postSolutionNew = (payload) =>{
    return function(dispatch){
        dispatch(solutionNewRequest());
        tokenPost(apiSettings.apiURLReferences + '/solutiontypes',payload).then((response) => {
            dispatch(getProblemsTreeView(+payload.problemTypeId));
            dispatch(getProblemDetail(+payload.problemTypeId));
            dispatch(solutionNewSuccess({
                solution: response.data
            }));
            dispatch(notificationSuccess('Successfully added solution type'));
        })
        .catch((error) => {
            if (error.message.indexOf(500) >= 0 || error.message.indexOf(422) >= 0){
                dispatch(notificationError('Solution name exists'));
            }
            console.log(error);
        });
    }
}

export const putSolutionUpdate = (id,payload) =>{
    return function(dispatch){
        dispatch(solutionUpdateRequest());
        tokenPut(apiSettings.apiURLReferences + '/solutiontypes/' + id,payload).then((response) => {
            dispatch(getProblemsTreeView(+payload.problemTypeId));
            dispatch(getProblemDetail(+payload.problemTypeId));
            dispatch(solutionUpdateSuccess({
                solution: response.data
            }));
            dispatch(notificationSuccess('Successfully updated solution type'));
        })
        .catch((error) => {
            if (error.message.indexOf(500) >= 0 || error.message.indexOf(422) >= 0){
                dispatch(notificationError('Solution name exists'));
            }
            console.log(error);
        });
    }
}

export const deleteSolution = (id,problemId) =>{
    return function(dispatch){
        tokenDelete(apiSettings.apiURLReferences + '/solutiontypes/' + id).then((response) => {
            dispatch(getProblemsTreeView(problemId));
            dispatch(getProblemDetail(problemId));
            dispatch(notificationSuccess('Successfully deleted solution type'));
        })
        .catch((error) => {
            if (error.message.indexOf(500) >= 0 || error.message.indexOf(422) >= 0){
                dispatch(notificationError('Cannot delete because it is currently referenced to another record'));
            }
            console.log(error);
        });
    }
}