import {
    MODAL_SHOW,
    MODAL_CLOSE
} from '../../actions/actionTypes';

const initialState = {
    modalShown: null,
}

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case MODAL_SHOW:
            return {
                modalShown: action.payload
            }
        case MODAL_CLOSE:
            return {
                modalShown: null
            }
        default: 
            return state;
    }
}

export default notificationReducer;