export default function CustomTooltips(tooltipModel) {
  let tooltipEl = document.getElementById('chartjs-tooltip-machine-summary');
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip-machine-summary';
    tooltipEl.innerHTML = '<div class="chartjs-tooltip-machine-summary"></div>';
    document.body.appendChild(tooltipEl);
  }
  if (tooltipModel.opacity === 0) { 
    tooltipEl.style.opacity = 0; 
    return; 
  }
  tooltipEl.classList.remove("top", "bottom", "center", "left", "right");
  tooltipEl.classList.add(tooltipModel.yAlign);
  tooltipEl.classList.add(tooltipModel.xAlign);
  const footer = tooltipModel.footer;
  if (footer) {
    const [{ datasets, datasetIndex, index, date }] = footer;
    const data = datasets[datasetIndex]['data'];
    const totalMachines = data.reduce((sum, machine) => (sum + machine), 0);
    const label = datasets[datasetIndex]['labels'][index];
    const value = datasets[datasetIndex]['data'][index];
    const valuePercent = parseFloat(value / totalMachines * 100).toFixed(1) + '%';
    const statusData = datasets[1];
    const background = datasets[datasetIndex]['backgroundColor'][index];
    const countActive = datasetIndex === 1
      ? statusData.data[0]
      : statusData.data2[0][index];

    const countInactive = datasetIndex === 1
      ? statusData.data[1]
      : statusData.data2[1][index];

    const boxElement = `
      <div class='box'>
        <h5 style='display: flex; gap: 5px; align-items: center;'>
            <span class='legend-indicator' style='background: ${background}'></span> 
            <b>${valuePercent} ${label}</b>
        </h5>
        <p style='marginBottom: 8px'>${date}</p>
        <div style='display: flex; gap: 5px; align-items: center; justify-content: space-between'>
          <h3>${'Total Machines'.translate()}</h3>
          <h3>${value}</h3>
        </div>
        <div class='break-line'></div>
        <div style='display: flex; gap: 5px; align-items: center; justify-content: space-between'>
          <div style='display: flex; gap: 5px; align-items: center'>
            <span class='legend-indicator' style='background: ${statusData.backgroundColor[0]}'></span>
            <p>${statusData.labels[0]}</p>
          </div>
          <p>${countActive}</p>
        </div>
        <div style='display: flex; gap: 5px; align-items: center; justify-content: space-between'>
          <div style='display: flex; gap: 5px; align-items: center'>
            <span class='legend-indicator' style='background: ${statusData.backgroundColor[1]}'></span>
            <p>${statusData.labels[1]}</p>
          </div>
          <p>${countInactive}</p>
        </div>
      </div>
      `;
    const tableRoot = tooltipEl.querySelector('.chartjs-tooltip-machine-summary');
    tableRoot.innerHTML = boxElement;
  }
  const position = this._chart.canvas.getBoundingClientRect();
  const { caretY, caretX } = tooltipModel;
  let left = position.left + caretX + 'px';
  let top = position.top + caretY - 20 + 'px';
  tooltipEl.style.opacity = 1;
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.left = left;
  tooltipEl.style.top = top;
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
  tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
  tooltipEl.style.pointerEvents = 'none';
};