export const dictionary = {
    GET: () => {
        return {
            ACCESS: "ACCESS",
            ACCESS_RIGHTS: "ACCESS_RIGHTS",
            ACTION_TAKEN: "ACTION_TAKEN",
            ACTION_TAKEN_BY: "ACTION_TAKEN_BY",
            ACTION_TAKEN_ON: "ACTION_TAKEN_ON",
            ACTION_TAKEN_ON_TIME: "ACTION_TAKEN_ON_TIME",
            ACTIVE: "ACTIVE",
            ADD: "ADD",
            ADDRESS: "ADDRESS",
            ADD_AREA: "ADD_AREA",
            ADD_ATTACHMENT: "ADD_ATTACHMENT",
            ADD_BRAND: "ADD_BRAND",
            ADD_BUILDING: "ADD_BUILDING",
            ADD_FACTORY: "ADD_FACTORY",
            ADD_FLOOR: "ADD_FLOOR",
            ADD_LINE: "ADD_LINE",
            ADD_MACHINE_SUBTYPE: "ADD_MACHINE_SUBTYPE",
            ADD_MACHINE_SUB_TYPE: "ADD_MACHINE_SUB_TYPE",
            ADD_MACHINE_TYPE: "ADD_MACHINE_TYPE",
            ADD_MOTOR_TYPE: "ADD_MOTOR_TYPE",
            ADD_PROBLEM: "ADD_PROBLEM",
            ADD_SOLUTION_LABEL: "ADD_SOLUTION_LABEL",
            ADD_SUPPLIER: "ADD_SUPPLIER",
            ALL: "ALL",
            ALLOW_LINE_LEADER_TO_REOPEN_TICKET: "ALLOW_LINE_LEADER_TO_REOPEN_TICKET",
            ALLOW_TIME_MIN: "ALLOW_TIME_MIN",
            APPLY_TO_ALL_REPAIR_TYPES: "APPLY_TO_ALL_REPAIR_TYPES",
            APR: "APR",
            ASSET_NO: "ASSET_NO",
            ASSIGNED: "ASSIGNED",
            ATTACHMENT: "ATTACHMENT",
            ATTACHMENT_CODE: "ATTACHMENT_CODE",
            AUG: "AUG",
            AVAILABLE: "AVAILABLE",
            Active: "Active",
            Add_Shift: "Add_Shift",
            All: "All",
            BRAND: "BRAND",
            BRAND_CODE: "BRAND_CODE",
            BROWSE: "BROWSE",
            BUILDING: "BUILDING",
            CANCEL: "CANCEL",
            CANCELLED: "CANCELLED",
            CANCELLED_BY: 'CANCELLED_BY',
            CANCELLED_TIME: 'CANCELLED_TIME',
            CANCEL_TICKET: "CANCEL_TICKET",
            CARD_ID: "CARD_ID",
            CHANGEOVER: "CHANGEOVER",
            CHANGEOVER_TYPE: "CHANGEOVER_TYPE",
            CHANGEOVER_TYPE_CODE: "CHANGEOVER_TYPE_CODE",
            CHANGE_MACHINE: "CHANGE_MACHINE",
            CHANGE_PASSWORD: "CHANGE_PASSWORD",
            CHECKLIST_NAME: "CHECKLIST_NAME",
            CHECKLIST_NO: "CHECKLIST_NO",
            CLOSE: "CLOSE",
            CLOSED: "CLOSED",
            CLOSED_BY: "CLOSED_BY",
            CLOSED_ON: "CLOSED_ON",
            CLOSED_ON_TIME: "CLOSED_ON_TIME",            
            CLOSED_DURATION: "CLOSED_DURATION",
            TOTAL_DURATION: "TOTAL_DURATION",
            TOTAL_MINUTES: "TOTAL_MINUTES",
            CODE: "CODE",
            COMPANY: "COMPANY",
            COMPANY_NAME: "COMPANY_NAME",
            COMPLETED: "COMPLETED",
            CONDITION: "CONDITION",
            COUNTRY: "COUNTRY",
            CREATED_BY: "CREATED_BY",
            CREATED_DATE: "CREATED_DATE",
            CUSTOMIZE_RANGE: "CUSTOMIZE_RANGE",
            CUSTOM_FIELD: "CUSTOM_FIELD",
            Change_Password: "Change_Password",
            Confirm_Password: "Confirm_Password",
            DATE_OF_PURCHASE: "DATE_OF_PURCHASE",
            DATE_MODIFIED: "DATE_MODIFIED",
            DATE_CREATED: "DATE_CREATED",
            DEC: "DEC",
            DESCRIPTION: "DESCRIPTION",
            DISPLAY_NAME: "DISPLAY_NAME",
            DRAFT: "DRAFT",
            DRAG_AND_DROP_FILES: "DRAG_AND_DROP_FILES",
            EDIT: "EDIT",
            EDIT_AREA: "EDIT_AREA",
            EDIT_ATTACHMENT: "EDIT_ATTACHMENT",
            EDIT_BRAND: "EDIT_BRAND",
            EDIT_BUILDING: "EDIT_BUILDING",
            EDIT_FACTORY: "EDIT_FACTORY",
            EDIT_FLOOR: "EDIT_FLOOR",
            EDIT_LINE: "EDIT_LINE",
            EDIT_MACHINE_SUBTYPE: "EDIT_MACHINE_SUBTYPE",
            EDIT_MACHINE_TYPE: "EDIT_MACHINE_TYPE",
            EDIT_MOTOR_TYPE: "EDIT_MOTOR_TYPE",
            EDIT_PROBLEM: "EDIT_PROBLEM",
            EDIT_REPAIR_TYPE: "EDIT_REPAIR_TYPE",
            EDIT_SOLUTION: "EDIT_SOLUTION",
            EDIT_SUPPLIER: "EDIT_SUPPLIER",
            EMAIL: "EMAIL",
            EMAIL_AD_1: "EMAIL_AD_1",
            EMAIL_AD_2: "EMAIL_AD_2",
            EMAIL_AD_3: "EMAIL_AD_3",
            END_TIME: "END_TIME",
            ESCALATION_TIME: "ESCALATION_TIME",
            EXPORT_TO_FILE: "EXPORT_TO_FILE",
            FACTORY_CODE: "FACTORY_CODE",
            FEB: "FEB",
            FILE_FORMAT_NOT_ALLOWED: "FILE_FORMAT_NOT_ALLOWED",
            FILE_SUCCESSFULLY_EXPORTED: "FILE_SUCCESSFULLY_EXPORTED",
            FORMAT_PNG_OR_JPG: "FORMAT_PNG_OR_JPG",
            FRIDAY: "FRIDAY",
            FULL_ACCESS: "FULL_ACCESS",
            GENERATE_BY: "GENERATE_BY",
            GENERATE_ON: "GENERATE_ON",
            GENERATE_ON_TIME: "GENERATE_ON_TIME",
            GENERATE_TICKET: "GENERATE_TICKET",
            GRABBED_BY: "GRABBED_BY",
            GRABBED_ON: "GRABBED_ON",
            GRABBED_ON_TIME: "GRABBED_ON_TIME",
            TOTAL_REPAIR_TIME:"TOTAL_REPAIR_TIME",
            IMAGE: "IMAGE",
            INACTIVE: "INACTIVE",
            IN_PROCESS: "IN_PROCESS",
            IN_PROGRESS: "IN_PROGRESS",
            IN_REPAIR: "IN_REPAIR",
            IN_REPAIR_MIN: "IN_REPAIR_MIN",
            Inactive: "Inactive",
            JAN: "JAN",
            JULY: "JULY",
            JUN: "JUN",
            LAST_ACCESS_AT: "LAST_ACCESS_AT",
            LAST_ACCESS_DATE: "LAST_ACCESS_DATE",
            LAST_MAINT: "LAST_MAINT",
            NEXT_MAINT_DATE: "NEXT_MAINT_DATE",
            MAINTENANCE_STATUS: "MAINTENANCE_STATUS",
            LAST_MAINTENANCE: "LAST_MAINTENANCE",
            LAST_REPAIR: "LAST_REPAIR",
            LAST_USED: "LAST_USED",
            LINE_LEADER_CONFIRMATION_REQUIRED: "LINE_LEADER_CONFIRMATION_REQUIRED",
            LINE_LEADER_REQUEST_TYPES: "LINE_LEADER_REQUEST_TYPES",
            MAINTENANCE_TICKET: "MAINTENANCE_TICKET",
            AUTO_GENERATE_MAINTENANCE_TICKET: "AUTO_GENERATE_MAINTENANCE_TICKET",
            CHANGE_FIELD_NAME: "CHANGE_FIELD_NAME",
            LOCATION: "LOCATION",
            LOGIN_ID: "LOGIN_ID",
            LPM: "LPM",
            LPR: "LPR",
            MACHINE: "MACHINE",
            MACHINES: "MACHINES",
            MACHINES_UP_FOR_MAINTENANCE: "MACHINES_UP_FOR_MAINTENANCE",
            Machines_Up_For_Maintenance: "Machines_Up_For_Maintenance",
            MACHINE_MASTER_DATA: "MACHINE_MASTER_DATA",
            MACHINE_NO: "MACHINE_NO",
            MACHINE_STATUS: "MACHINE_STATUS",
            MACHINE_SUBTYPE: "MACHINE_SUBTYPE",
            MACHINE_SUBTYPE_CODE: "MACHINE_SUBTYPE_CODE",
            MACHINE_SUB_TYPE: "MACHINE_SUB_TYPE",
            MACHINE_TYPE: "MACHINE_TYPE",
            MACHINE_TYPE_CODE: "MACHINE_TYPE_CODE",
            MAINTENANCE: "MAINTENANCE",
            Maintenance: "Maintenance",
            MAINTENANCE_DATE: "MAINTENANCE_DATE",
            MAINTENANCE_FREQ: "MAINTENANCE_FREQ",
            MAINTENANCE_SUB_TYPE: "MAINTENANCE_SUB_TYPE",
            MAINTENANCE_TICKETS: "MAINTENANCE_TICKETS",
            MAINTENANCE_TYPE: "MAINTENANCE_TYPE",
            MAIN_FREQ: "MAIN_FREQ",
            MAKE_ACTIVE: "MAKE_ACTIVE",
            MAKE_INACTIVE: "MAKE_INACTIVE",
            MANAGE_FACTORY: "MANAGE_FACTORY",
            MAR: "MAR",
            MAY: "MAY",
            MODEL_NO: "MODEL_NO",
            MODIFIED_BY: "MODIFIED_BY",
            MONDAY: "MONDAY",
            MOTOR_TYPE: "MOTOR_TYPE",
            MOTOR_TYPE_CODE: "MOTOR_TYPE_CODE",
            MOVE_MACHINE: "MOVE_MACHINE",
            MAXIMUM_CHAR: 'MAXIMUM_CHAR',
            NAME: "NAME",
            NEW: "NEW",
            NEXT_MAINTENANCE: "NEXT_MAINTENANCE",
            MAINTENANCE_STATUS: "MAINTENANCE_STATUS",
            NO: "NO",
            NONE: "NONE",
            NOTIFICATION: "NOTIFICATION",
            NOTIFICATIONS: {
                are_you_sure: "are_you_sure",
                are_you_sure_delete_area: "are_you_sure_delete_area",
                are_you_sure_delete_attachment: "are_you_sure_delete_attachment",
                are_you_sure_delete_brand: "are_you_sure_delete_brand",
                are_you_sure_delete_changeover_type: "are_you_sure_delete_changeover_type",
                are_you_sure_delete_building: "are_you_sure_delete_building",
                are_you_sure_delete_factory: "are_you_sure_delete_factory",
                are_you_sure_delete_floor: "are_you_sure_delete_floor",
                are_you_sure_delete_line: "are_you_sure_delete_line",
                are_you_sure_delete_machine_subtype: "are_you_sure_delete_machine_subtype",
                are_you_sure_delete_machine_type: "are_you_sure_delete_machine_type",
                are_you_sure_delete_motory_type: "are_you_sure_delete_motory_type",
                are_you_sure_delete_problem: "are_you_sure_delete_problem",
                are_you_sure_delete_solution: "are_you_sure_delete_solution",
                are_you_sure_delete_supplier: "are_you_sure_delete_supplier",
                are_you_sure_to_leave_this_page: "are_you_sure_to_leave_this_page",
                are_you_sure_want_cancel: "are_you_sure_want_cancel",
                are_you_sure_you_want_to_cancel_changes: "are_you_sure_you_want_to_cancel_changes",
                are_you_sure_you_want_to_delete_shift: "are_you_sure_you_want_to_delete_shift",
                are_you_sure_you_want_to_make_this_checklist_template_active: "are_you_sure_you_want_to_make_this_checklist_template_active",
                are_you_sure_you_want_to_make_this_checklist_template_inactive: "are_you_sure_you_want_to_make_this_checklist_template_inactive",
                brand_code_exists: "brand_code_exists",
                cannot_create_ticket_for_machine_with: "cannot_create_ticket_for_machine_with",
                card_id_exist: "card_id_exist",
                confirming_would_cancel_selected_tickets: "confirming_would_cancel_selected_tickets",
                email_exist: "email_exist",
                file_not_supported: "file_not_supported",
                machine_sub_type_code_exists: "machine_sub_type_code_exists",
                machine_type_code_exists: "machine_type_code_exists",
                machines_that_cannot_be_retired: "machines_that_cannot_be_retired",
                motor_type_code_exists: "motor_type_code_exists",
                problem_name_exists: "problem_name_exists",
                status: "status",
                there_are: "there_are",
                this_ticket_cannot_be_cancelled: "this_ticket_cannot_be_cancelled",
                tickets_cannot_be_cancelled: "tickets_cannot_be_cancelled",
                tickets_that_cannot_be_cancelled: "tickets_that_cannot_be_cancelled",
                username_exists: "username_exists",
                you_are_applying_all_problems: "you_are_applying_all_problems",
                your_account_is_in_use_on_another_device: "your_account_is_in_use_on_another_device",
            },
            NOT_AVAIL: "NOT_AVAIL",
            NOV: "NOV",
            NO_ACCESS_FOUND: "NO_ACCESS_FOUND",
            NO_RESULT: "NO_RESULT",
            OCT: "OCT",
            OKAY: "OKAY",
            OR: "OR",
            ORIGINAL_FIELD: "ORIGINAL_FIELD",
            OVERDUE: "OVERDUE",
            PLACE: "PLACE",
            PROBLEM: "PROBLEM",
            PURCHASE_DATE: "PURCHASE_DATE",
            Password: "Password",
            READY: "READY",
            REMARKS: "REMARKS",
            RENTAL: "RENTAL",
            REPAIR: "REPAIR",
            REPAIRED: "REPAIRED",
            REPAIRED_BY: "REPAIRED_BY",
            REPAIRED_MIN: "REPAIRED_MIN",
            REPAIRED_ON: "REPAIRED_ON",
            REPAIRED_ON_TIME: "REPAIRED_ON_TIME",
            TOTAL_CLOSED_TIME: "TOTAL_CLOSED_TIME",
            TOTAL_REPORTED_TIME: "TOTAL_REPORTED_TIME",
            REPAIR_TICKET_CLOSURE: "REPAIR_TICKET_CLOSURE",
            REPAIR_TYPES: "REPAIR_TYPES",
            REPORTED: "REPORTED",
            REPORTED_BY: "REPORTED_BY",
            REPORTED_ON: "REPORTED_ON",
            REPORTED_ON_TIME: "REPORTED_ON_TIME",
            REPORT_TICKET_MIN: "REPORT_TICKET_MIN",
            REPORTED_DURATION: "REPORTED_DURATION",
            IN_REPAIR_DURATION: "IN_REPAIR_DURATION",
            REQUEST_INSPECTION: "REQUEST_INSPECTION",
            REQUEST_MAINTENANCE: "REQUEST_MAINTENANCE",
            RETIRE: "RETIRE",
            RFID: "RFID",
            ROLE: "ROLE",
            ROLES: "ROLES",
            ROLE_ACCESS_AND_PERMISSIONS: "ROLE_ACCESS_AND_PERMISSIONS",
            ROLE_NAME: "ROLE_NAME",
            ROWS_PER_PAGE: "ROWS_PER_PAGE",
            RPR_PM_BY: "RPR_PM_BY",
            RPR_PM_ON: "RPR_PM_ON",
            RPR_PM_ON_TIME: "RPR_PM_ON_TIME",
            SATURDAY: "SATURDAY",
            SAVE: "SAVE",
            SCHEDULED: "SCHEDULED",
            SEARCH: "SEARCH",
            SELECT: "SELECT",
            SELECT_MACHINE: "SELECT_MACHINE",
            SEPT: "SEPT",
            SERIAL_NO: "SERIAL_NO",
            SHIFT: "SHIFT",
            SHIFT_NAME: "SHIFT_NAME",
            SHIFT_SCHEDULE: "SHIFT_SCHEDULE",
            SHORT_NAME_FOR_SYSTEM_DISPLAY: "SHORT_NAME_FOR_SYSTEM_DISPLAY",
            SOLUTION: "SOLUTION",
            START_REPAIR: "START_REPAIR",
            START_TIME: "START_TIME",
            STATION: "STATION",
            STATUS: "STATUS",
            STEPS: "STEPS",
            SUBTYPE: "SUBTYPE",
            SUNDAY: "SUNDAY",
            SPACE_NOT_ALLOW: 'SPACE_NOT_ALLOW',
            SUPERADMIN_HAS_DEFAULT_ACCESS_TO_ALL_FACTORY: "SUPERADMIN_HAS_DEFAULT_ACCESS_TO_ALL_FACTORY",
            SUPPLIER: "SUPPLIER",
            SUPPLIER_NAME: "SUPPLIER_NAME",
            Supplier: "Supplier",
            SUPPLIER_CODE: "SUPPLIER_CODE",
            TEMPLATE_TYPE: "TEMPLATE_TYPE",
            THIS_MONTH: "THIS_MONTH",
            THIS_WEEK: "THIS_WEEK",
            THURSDAY: "THURSDAY",
            TICKET_NO: "TICKET_NO",
            TICKET_STATUS: "TICKET_STATUS",
            TOTAL_ELAPSED_TIME: "TOTAL_ELAPSED_TIME",
            TICKET_TYPE: "TICKET_TYPE",
            TIMEZONE: "TIMEZONE",
            TIME_ZONE_IN: "TIME_ZONE_IN",
            TODAY: "TODAY",
            TOOLTIPS: {
                ADD_BUILDING: "ADD_BUILDING",
                ADD_SOLUTION: "ADD_SOLUTION",
                VIEWCREATEAPPROVE: "VIEWCREATEAPPROVE",
                VIEWCREATEEDIT: "VIEWCREATEEDIT",
                TOTALDURATIONMARKEDASCLOSED: "TOTALDURATIONMARKEDASCLOSED",
            },
            TUESDAY: "TUESDAY",
            TYPE: "TYPE",
            UPDATED_BY: "UPDATED_BY",
            UPDATED_DATE: "UPDATED_DATE",
            UPLOAD_FACTORY_BANNER: "UPLOAD_FACTORY_BANNER",
            UPLOAD_FACTORY_IMAGE: "UPLOAD_FACTORY_IMAGE",
            UPLOAD_USER_IMAGE: "UPLOAD_USER_IMAGE",
            USERS: "USERS",
            USER_ACCESS_RIGHT: "USER_ACCESS_RIGHT",
            USER_MANAGEMENT: "USER_MANAGEMENT",
            USER_PROFILE: "USER_PROFILE",
            Ucase_CLOSED: "Ucase_CLOSED",
            VIEW: "VIEW",
            VIEW_CHECKLIST: "VIEW_CHECKLIST",
            VIEW_INSTRUCTION: "VIEW_INSTRUCTION",
            VIEW_ONLY: "VIEW_ONLY",
            WEBSITE: "WEBSITE",
            WEDNESDAY: "WEDNESDAY",
            WORKING: "WORKING",
            YES: "YES",

            CHECKLIST_TEMPLATES: 'CHECKLIST_TEMPLATES',
            CHECKLIST_DETAILS: 'CHECKLIST_DETAILS',
            DASHBOARD: 'DASHBOARD',
            LINE_OVERVIEW: 'LINE_OVERVIEW',
            LOCATIONS: 'LOCATIONS',
            ATTACHMENTS: 'ATTACHMENTS',
            BRANDS: 'BRANDS',
            MACHINE_SUB_TYPES: 'MACHINE_SUB_TYPES',
            MACHINE_TYPES: 'MACHINE_TYPES',
            MOTOR_TYPES: 'MOTOR_TYPES',
            MACHINE_DETAILS: 'MACHINE_DETAILS',
            MACHINE_MAINTENANCE_HISTORY: 'MACHINE_MAINTENANCE_HISTORY',
            MACHINE_CHECKLIST_TEMPLATE: 'MACHINE_CHECKLIST_TEMPLATE',
            MACHINE_REPAIR_HISTORY: 'MACHINE_REPAIR_HISTORY',
            SYSTEM_PREFERENCE: 'SYSTEM_PREFERENCE',
            TICKET_DETAILS: 'TICKET_DETAILS',
            TICKET_MANAGEMENT: 'TICKET_MANAGEMENT',
            ROLE_MANAGEMENT: 'ROLE_MANAGEMENT',
        }
    },
    TRANSLATE: (keys) => {
       Object.entries(keys).forEach(x => {
           keys[x[0]] = dictionary.PHRASES(x[1])
       })

       return keys
    },
    /**
     * Transform a string into a set language
     * @param IN String to transform
     */
    PHRASES: (IN) => {
       try {
        return {
            MAXIMUM_CHAR: 'Maximum character limit is',
            SPACE_NOT_ALLOW: 'Spaces not allowed',
            ACCESS: "Access",
            ACCESS_RIGHTS: "Access Rights",
            ACTION_TAKEN: "Action taken",
            ACTION_TAKEN_BY: "Action taken by",
            ACTION_TAKEN_ON: "Action taken on",
            ACTION_TAKEN_ON_TIME: "Action taken on time",
            ACTIVE: "ACTIVE",
            ADD: "Add",
            ADDRESS: "Address",
            ADD_AREA: "Add Area",
            ADD_ATTACHMENT: "Add Attachment",
            ADD_BRAND: "Add Brand",
            ADD_BUILDING: "Add Building",
            ADD_FACTORY: "Add Factory",
            ADD_FLOOR: "Add Floor",
            ADD_LINE: "Add Line",
            ADD_MACHINE_SUBTYPE: "Add Machine Subtype",
            ADD_MACHINE_SUB_TYPE: "Add Machine Sub Type",
            ADD_MACHINE_TYPE: "Add Machine Type",
            ADD_MOTOR_TYPE: "Add Motor Type",
            ADD_PROBLEM: "Add Problem",
            ADD_SOLUTION: "Add Solution",
            ADD_SOLUTION_LABEL: "Add Solution",
            ADD_SUPPLIER: "Add Supplier",
            ALL: "ALL",
            ALLOW_LINE_LEADER_TO_REOPEN_TICKET: "Allow Line Leader to reopen ticket",
            ALLOW_TIME_MIN: "Allowed Time (mins)",
            APPLY_TO_ALL_REPAIR_TYPES: "Apply to all ACTIVE repair types",
            APR: "April",
            ASSET_NO: "Asset No",
            ASSIGNED: "Assigned",
            ATTACHMENT: "Attachment",
            ATTACHMENT_CODE: "Attachment Code",
            AUG: "August",
            AVAILABLE: "AVAILABLE",
            Active: "Active",
            Add_Shift: "Add Shift",
            All: "All",
            BRAND: "Brand",
            BRAND_CODE: "Brand Code",
            BROWSE: "Browse",
            CANCEL: "Cancel",
            CANCELLED: "CANCELLED",
            CANCELLED_BY: "Cancelled By",
            CANCELLED_TIME: "Cancelled Time",
            CANCEL_TICKET: "CANCEL TICKET",
            CARD_ID: "Card ID",
            CHANGEOVER: "CHANGEOVER",
            CHANGEOVER_TYPE: "CHANGEOVER TYPE",
            CHANGEOVER_TYPE_CODE: "CHANGEOVER TYPE CODE",
            CHANGE_MACHINE: "Change Machine",
            CHANGE_PASSWORD: "CHANGE PASSWORD",
            CHECKLIST_NAME: "Checklist Name",
            CHECKLIST_NO: "Checklist No",
            CLOSE: "CLOSE",
            CLOSED: "Closed",
            CLOSED_BY: "Closed By",
            CLOSED_ON: "Closed on",
            CLOSED_ON_TIME: "Closed Time",
            CLOSED_DURATION: "Closed Duration",
            TOTAL_DURATION: "Total Duration",
            TOTAL_MINUTES: "Total Minutes",
            CODE: "Code",
            COMPANY: "Company",
            COMPANY_NAME: "Company Name",
            COMPLETED: "COMPLETED",
            CONDITION: "Condition",
            COUNTRY: "Country",
            CREATED_BY: "Created by",
            CREATED_DATE: "Created Date",
            CUSTOMIZE_RANGE: "CUSTOMIZE RANGE",
            CUSTOM_FIELD: "Custom Fields",
            Change_Password: "Change Password",
            Confirm_Password: "Confirm Password",
            DATE_OF_PURCHASE: "Date of Purchase",
            DATE_MODIFIED: "DATE MODIFIED",
            DATE_CREATED: "DATE CREATED",
            DEC: "December",
            DESCRIPTION: "Description",
            DISPLAY_NAME: "Display Name",
            DRAFT: "DRAFT",
            DRAG_AND_DROP_FILES: "Drag & Drop Files",
            EDIT: "Edit",
            EDIT_AREA: "Edit Area",
            EDIT_ATTACHMENT: "Edit Attachment",
            EDIT_BRAND: "Edit Brand",
            EDIT_BUILDING: "Edit Building",
            EDIT_FACTORY: "Edit Factory",
            EDIT_FLOOR: "Edit Floor",
            EDIT_LINE: "Edit Line",
            EDIT_MACHINE_SUBTYPE: "Edit Machine Sub Type",
            EDIT_MACHINE_TYPE: "Edit Machine Type",
            EDIT_MOTOR_TYPE: "Edit Motor Type",
            EDIT_PROBLEM: "Edit Problem",
            EDIT_REPAIR_TYPE: "Edit Repair Type",
            EDIT_SOLUTION: "Edit Solution",
            EDIT_SUPPLIER: "Edit Supplier",
            EMAIL: "Email",
            EMAIL_AD_1: "Email Address 1",
            EMAIL_AD_2: "Email Address 2",
            EMAIL_AD_3: "Email Addresss 3",
            END_TIME: "End Time",
            ESCALATION_TIME: "Escalation Time",
            EXPORT_TO_FILE: "EXPORT TO FILE",
            FACTORY_CODE: "Factory Code",
            FEB: "February",
            FILE_FORMAT_NOT_ALLOWED: "File format not allowed",
            FILE_SUCCESSFULLY_EXPORTED: "File successfully exported",
            FORMAT_PNG_OR_JPG: "Format: .png or .jpg",
            FRIDAY: "Friday",
            FULL_ACCESS: "Full Access",
            GENERATE_BY: "Generated by",
            GENERATE_ON: "Generated on",
            GENERATE_ON_TIME: "Generated on time",
            GENERATE_TICKET: "GENERATE TICKET",
            GRABBED_BY: "Grabbed By",
            GRABBED_ON: "Grabbed on",
            GRABBED_ON_TIME: "Grabbed Time",
            TOTAL_REPAIR_TIME: "Total Repair Time",
            IMAGE: "Image",
            INACTIVE: "INACTIVE",
            IN_PROCESS: "IN-PROCESS",
            IN_PROGRESS: "IN PROGRESS",
            IN_REPAIR: "IN-REPAIR",
            IN_REPAIR_MIN: "In-Repair (mins)",
            Inactive: "Inactive",
            JAN: "January",
            JULY: "July",
            JUN: "June",
            LAST_ACCESS_AT: "Last access at",
            LAST_ACCESS_DATE: "Last Access Date",
            LAST_MAINT: "Last Maint.",
            NEXT_MAINT_DATE: "Next Maint. Date",
            MAINTENANCE_STATUS: "Maintenance Status",
            LAST_MAINTENANCE: "Last Maintenance",
            LAST_REPAIR: "Last Repair",
            LAST_USED: "Last Used",
            LINE_LEADER_CONFIRMATION_REQUIRED: "Line Leader Confirmation Required",
            LINE_LEADER_REQUEST_TYPES: "Line Leader Request Types",
            MAINTENANCE_TICKET: "Maintenance Ticket",
            AUTO_GENERATE_MAINTENANCE_TICKET: "Auto Generate Maintenance Ticket",
            CHANGE_FIELD_NAME: "Change the field name in Machine Details",
            LOCATION: "Location",
            LOGIN_ID: "Login ID",
            LPM: "LPM",
            LPR: "LPR",
            MACHINE: "Machine",
            MACHINES: "Machines",
            MACHINES_UP_FOR_MAINTENANCE: "MACHINES UP FOR MAINTENANCE",
            Machines_Up_For_Maintenance: "Machines Up For Maintenance",
            MACHINE_MASTER_DATA: "Machine Master Data",
            MACHINE_NO: "Machine No",
            MACHINE_STATUS: "Machine Status",
            MACHINE_SUBTYPE: "Machine Subtype",
            MACHINE_SUBTYPE_CODE: "Machine Sub Type Code",
            MACHINE_SUB_TYPE: "Machine Sub Type",
            MACHINE_TYPE: "Machine Type",
            MACHINE_TYPE_CODE: "Machine Type Code",
            MAINTENANCE: "MAINTENANCE",
            Maintenance: "Maintenance",
            MAINTENANCE_DATE: "Maintenance Date",
            MAINTENANCE_FREQ: "Maintenance Freq",
            MAINTENANCE_SUB_TYPE: "Maintenance Sub Type",
            MAINTENANCE_TICKETS: "MAINTENANCE TICKETS",
            MAINTENANCE_TYPE: "Maintenance Type",
            MAIN_FREQ: "Main Frequency",
            MAKE_ACTIVE: "MAKE ACTIVE",
            MAKE_INACTIVE: "MAKE INACTIVE",
            MANAGE_FACTORY: "Manage Factory",
            MAR: "March",
            MAY: "May",
            MODEL_NO: "Model No",
            MODIFIED_BY: "MODIFIED BY",
            MONDAY: "Monday",
            MOTOR_TYPE: "Motor Type",
            MOTOR_TYPE_CODE: "Motor Type Code",
            MOVE_MACHINE: "Move Machine",
            NAME: "Name",
            NEW: "New",
            NEXT_MAINTENANCE: "Next Maintenance",
            MAINTENANCE_STATUS: "Maintenance Status",
            NO: "No",
            NONE: "None",
            NOTIFICATION: "Notification",
            NOT_AVAIL: "NOT AVAIL/RETIRED",
            NOV: "November",
            NO_ACCESS_FOUND: "No Access Rights",
            NO_RESULT: "No Results Found",
            OCT: "October",
            OKAY: "Okay",
            OR: "or",
            ORIGINAL_FIELD: "Original Field",
            OVERDUE: "OVERDUE",
            PLACE: "Place",
            PROBLEM: "Problem",
            PURCHASE_DATE: "Purchase Date",
            Password: "Password",
            READY: "READY",
            REMARKS: "Remarks",
            RENTAL: "Rental",
            REPAIR: "REPAIR",
            REPAIRED: "REPAIRED",
            REPAIRED_BY: "Repaired By",
            REPAIRED_MIN: "Repaired (mins)",
            REPAIRED_ON: "Repaired on",
            REPAIRED_ON_TIME: "Repaired Time",
            TOTAL_CLOSED_TIME: "Total Closed Time",
            TOTAL_REPORTED_TIME: "Total Reported Time",
            REPAIR_TICKET_CLOSURE: "Repair Ticket Closure",
            REPAIR_TYPES: "Repair Types",
            REPORTED: "REPORTED",
            REPORTED_BY: "Reported By",
            REPORTED_ON: "Reported On",
            REPORTED_ON_TIME: "Reported Time",
            REPORTED_DURATION: "Reported Duration",
            IN_REPAIR_DURATION: "In-Repair Durations",
            REPORT_TICKET_MIN: "Report Tickets (mins)",
            REQUEST_INSPECTION: "Request Inspection",
            REQUEST_MAINTENANCE: "Request Maintenance",
            RETIRE: "RETIRE",
            RFID: "RFID",
            ROLE: "Role",
            ROLES: "Roles",
            ROLE_ACCESS_AND_PERMISSIONS: "Role Access Right & Permissions",
            ROLE_NAME: "Role Name",
            ROWS_PER_PAGE: "Rows per page",
            RPR_PM_BY: "RPR/PM By",
            RPR_PM_ON: "RPR/PM on",
            // RPR_PM_ON_TIME: "RPR/PM on time",
            RPR_PM_ON_TIME: "Repaired Time",
            SATURDAY: "Saturday",
            SAVE: "Save",
            SCHEDULED: "SCHEDULED",
            SEARCH: "Search",
            SELECT: "SELECT",
            SELECT_MACHINE: "SELECT MACHINE",
            SEPT: "September",
            SERIAL_NO: "Serial No",
            SHIFT: "Shift",
            SHIFT_NAME: "Shift Name",
            SHIFT_SCHEDULE: "Shift Schedule",
            SHORT_NAME_FOR_SYSTEM_DISPLAY: "Short name for system display",
            SOLUTION: "Solution",
            START_REPAIR: "START REPAIR",
            START_TIME: "Start Time",
            STATION: "Station",
            STATUS: "Status",
            STEPS: "Steps",
            SUBTYPE: "Subtype",
            SUNDAY: "Sunday",
            SUPERADMIN_HAS_DEFAULT_ACCESS_TO_ALL_FACTORY: "Superadmin has default access to all factory",
            SUPPLIER: "SUPPLIER",
            SUPPLIER_NAME: "Supplier Name",
            SUPPLIER_CODE: "Supplier Code",
            Supplier: "Supplier",
            TEMPLATE_TYPE: "Template Type",
            THIS_MONTH: "THIS MONTH",
            THIS_WEEK: "THIS WEEK",
            THURSDAY: "Thursday",
            TICKET_NO: "Ticket No",
            TICKET_STATUS: "Ticket Status",
            TOTAL_ELAPSED_TIME: "Total Elapsed Time",
            TICKET_TYPE: "Ticket Type",
            TIMEZONE: "Timezone",
            TIME_ZONE_IN: "Time zone in",
            TODAY: "TODAY",
            TUESDAY: "Tuesday",
            TYPE: "Type",
            UPDATED_BY: "Updated by",
            UPDATED_DATE: "Updated Date",
            UPLOAD_FACTORY_BANNER: "Upload Factory Banner",
            UPLOAD_FACTORY_IMAGE: "Upload Factory Image",
            UPLOAD_USER_IMAGE: "Upload User Image",
            USERS: "Users",
            USER_ACCESS_RIGHT: "User Access Right",
            USER_MANAGEMENT: "User Management",
            USER_PROFILE: "User Profile",
            Ucase_CLOSED: "CLOSED",
            VIEW: "View",
            VIEWCREATEAPPROVE: "View, create, edit, delete and approve.",
            VIEWCREATEEDIT: "View, create and edit.",
            VIEW_CHECKLIST: "View Checklist",
            VIEW_INSTRUCTION: "VIEW INSTRUCTION",
            VIEW_ONLY: "View Only",
            TOTALDURATIONMARKEDASCLOSED: "Total duration between ticket opened and ticket marked as closed.",
            WEBSITE: "Website",
            WEDNESDAY: "Wednesday",
            WORKING: "WORKING",
            YES: "Yes",
            are_you_sure: "Are you sure you want to cancel ticket(s)",
            are_you_sure_delete_area: "Are you sure you want to delete area?",
            are_you_sure_delete_attachment: "Are you sure you want delete attachment?",
            are_you_sure_delete_brand: "Are you sure you want delete brand?",
            are_you_sure_delete_changeover_type: "Are you sure you want delete changeover type?",
            are_you_sure_delete_building: "Are you sure you want to delete building?",
            are_you_sure_delete_factory: "Are you sure you want to delete factory?",
            are_you_sure_delete_floor: "Are you sure you want to delete floor?",
            are_you_sure_delete_line: "Are you sure you want to delete line?",
            are_you_sure_delete_machine_subtype: "Are you sure you want delete machine sub type?",
            are_you_sure_delete_machine_type: "Are you sure you want delete machine type?",
            are_you_sure_delete_motory_type: "Are you sure you want delete motor type?",
            are_you_sure_delete_problem: "Are you sure you want delete problem?",
            are_you_sure_delete_solution: "Are you sure you want to delete solution?",
            are_you_sure_delete_supplier: "Are you sure you want delete supplier?",
            are_you_sure_to_leave_this_page: "Are you sure to leave this page?",
            are_you_sure_want_cancel: "Are you sure you want to cancel? Unsave changes will be lost",
            are_you_sure_you_want_to_cancel_changes: "Are you sure you want to cancel the changes?",
            are_you_sure_you_want_to_delete_shift: "Are you sure you want to delete shift?",
            are_you_sure_you_want_to_make_this_checklist_template_active: "Are you sure you want to make this checklist template active?",
            are_you_sure_you_want_to_make_this_checklist_template_inactive: "Are you sure you want to make this checklist template inactive?",
            brand_code_exists: "Brand code exists",
            cannot_create_ticket_for_machine_with: "Cannot create ticket for machine with",
            card_id_exist: "Card ID already exists",
            confirming_would_cancel_selected_tickets: "Confirming would cancel selected tickets",
            email_exist: "Email already exists",
            file_not_supported: "File not supported",
            machine_sub_type_code_exists: "Machine sub type code exists",
            machine_type_code_exists: "Machine type code exists",
            machines_that_cannot_be_retired: " machines that cannot be retired",
            motor_type_code_exists: "Motor type code exists",
            problem_name_exists: "Problem name exists",
            status: " status",
            there_are: "There are",
            this_ticket_cannot_be_cancelled: "This ticket cannot be cancelled",
            tickets_cannot_be_cancelled: " tickets(s) cannot be cancelled",
            tickets_that_cannot_be_cancelled: " tickets that cannot be cancelled",
            username_exists: "Username exists",
            you_are_applying_all_problems: "This will apply to all ACTIVE repair types, Do you want to continue?",
            your_account_is_in_use_on_another_device: "Your account is in use on another device.",

            CHECKLIST_TEMPLATES: 'Checklist Templates',
            CHECKLIST_DETAILS: 'Checklist - Details',
            DASHBOARD: 'Dashboard',
            LINE_OVERVIEW: 'Line Overview',
            LOCATIONS: 'Locations',
            BUILDINGS: 'Building',
            ATTACHMENTS: 'Attachments',
            BRANDS: 'Brands',
            MACHINE_SUB_TYPES: 'Machine Sub Types',
            MACHINE_TYPES: 'Machine Types',
            MOTOR_TYPES: 'Motor Types',
            MACHINE_DETAILS: 'Machine - Details',
            MACHINE_MAINTENANCE_HISTORY: 'Machine - Maintenance History',
            MACHINE_CHECKLIST_TEMPLATE: 'Machine - Checklist Template',
            MACHINE_REPAIR_HISTORY: 'Machine - Repair History',
            SYSTEM_PREFERENCE: 'System Preference',
            TICKET_DETAILS: 'Ticket - Details',
            TICKET_MANAGEMENT: 'Ticket Management',
            ROLE_MANAGEMENT: 'Role Management',


            
            


            
        }[IN].translate()
       } catch (error) {
           console.log('phrase no found', IN)
       }
    }
}

export default dictionary