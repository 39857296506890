import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { 
    Container,
    DisplayDate, 
    RoleName, 
    TVModeButton,
    ContainerOptions,
} from './styles';
import { 
    apiSettings, 
    tokenGet 
} from '../../../../../utils/api/CoreApi';
import { MultipleSelection } from './../../../../../components';
import { sortRows } from '../../../Analytics/helpers.js'

function DashboardHeader({
    isLoading = false,
    toggleFullScreen,
    boardTvTitle,
    renderRoleLabel,
    date,
    getDashboardList,
}){
    const [buildingOptions, setBuildingOptions] = useState([]); 
    const [floorOptions, setFloorOptions] = useState([]); 
    const [originalFloorOptions, setOriginalFloorOptions] = useState([]);
    const [areaOptions, setAreaOptions] = useState(false);
    const [originalAreaOptions, setOriginalAreaOptions] = useState([]);
    const [selectedBuildings, setSelectedBuildings] = useState([]);
    const [selectedFloor, setSelectedFloor] = useState([]);
    const [selectedArea, setSelectedArea] = useState([]);

    const fetchOptions = async () => {
        try {
            const responses = await Promise.all([
                tokenGet(`${apiSettings.apiURLTickets}/api/tickets/dashboard-building`),
                tokenGet(`${apiSettings.apiURLTickets}/api/tickets/dashboard-floor`),
                tokenGet(`${apiSettings.apiURLTickets}/api/tickets/dashboard-floor-area`),
            ]);
            await Promise.all(responses).then((response) => {
                const responseBuildings = response[0]['data']['data'];
                const responseFloor = response[1]['data']['data'];
                const responseAreas = response[2]['data']['data'];
                const dataBuildings = responseBuildings.map(({ id, buildingDesc }) => ({ value: id, label: buildingDesc }));
                const dataFloor = responseFloor.map(({ id, floorName, buildingId }) => ({ value: id, label: floorName, buildingId }));
                const dataAreas = responseAreas.map(({ id, areaName, floorId, buildingId }) => ({ value: id, label: areaName, floorId,  buildingId }));
                setBuildingOptions(dataBuildings);
                setFloorOptions(dataFloor);
                setAreaOptions(sortRows(dataAreas, 'label', 'asc'));
                setOriginalAreaOptions(dataAreas);
                setOriginalFloorOptions(dataFloor);
                handleDefaultValue({ 
                    dataBuildings, 
                    dataFloor, 
                    dataAreas,
                });
            });
        } catch (error) {
            console.log(error);
        }
    };

    const checkMatched = (ArrayA, ArrayB) => ArrayA.some((item) => ArrayB.includes(item));
    const getQuery = async (data) => {
        try {
            let isMached = false;
            const dashboardQuery = JSON.parse(localStorage.getItem('dashboardQuery'));
            const { areaIds, buildingIds, floorIds } = dashboardQuery;
            isMached = checkMatched(buildingIds, data.buildingIds);
            isMached = checkMatched(floorIds, data.floorIds);
            isMached = checkMatched(areaIds, data.areaIds);
            if(!isMached){
                data.originalOptions =  data.originalOptions;
                localStorage.setItem('dashboardQuery', JSON.stringify(data));
                return data;
            };
            dashboardQuery.originalOptions = data.originalOptions;
            localStorage.setItem('dashboardQuery', JSON.stringify(dashboardQuery));
            return dashboardQuery;
        } catch (error) {
            data.originalOptions =  data.originalOptions;
            localStorage.setItem('dashboardQuery', JSON.stringify(data));
            return data;
        }
    }

    const getDisplayOptions = (selectedBuildingIds, data) => {
        if(
            selectedBuildingIds.length < buildingOptions.length ||
            selectedBuildingIds !== 0
        ) {
            const dashboardQuery = JSON.parse(localStorage.getItem('dashboardQuery'));
            const floorsOptionsToDisplay = data.dataFloor.filter(({ buildingId }) => selectedBuildingIds.includes(buildingId));
            const selectedFloorIds = dashboardQuery.floorIds;
            const areasOptionsToDisplay = data.dataAreas.filter(({ floorId }) => selectedFloorIds.includes(floorId));
            setFloorOptions(floorsOptionsToDisplay);
            setAreaOptions(areasOptionsToDisplay);
        }
    }

    const handleDefaultValue = async (data) => {
        const idForbuildingsFloorArea = {};
        idForbuildingsFloorArea.originalOptions = data;
        idForbuildingsFloorArea.buildingIds = data.dataBuildings.map(({ value }) => value);
        idForbuildingsFloorArea.floorIds = data.dataFloor.map(({ value }) => value);
        idForbuildingsFloorArea.areaIds = data.dataAreas.map(({ value }) => value);
        const query = await getQuery(idForbuildingsFloorArea);
        setSelectedBuildings(query.buildingIds);
        setSelectedFloor(query.floorIds);
        setSelectedArea(query.areaIds);
        getDisplayOptions(query.buildingIds, data);
    }

    const handleOnChangeBuilding = (buildingIds) => {
        setSelectedBuildings(buildingIds);
        const newFloorsOptions = originalFloorOptions.filter(({ buildingId }) => buildingIds.includes(buildingId));
        setFloorOptions(newFloorsOptions);
        handleOnChangeFloor(newFloorsOptions.map(({ value }) => value));
        rememberDashboardQuery(buildingIds, 'buildingIds');
    }

    const handleOnChangeFloor = (floorIds) => {
        const newAreasOptions = originalAreaOptions.filter(({ floorId }) => floorIds.includes(floorId));
        setAreaOptions(newAreasOptions);
        setSelectedFloor(floorIds);
        handleOnChangeArea(newAreasOptions.map(({ value }) => value));
        rememberDashboardQuery(floorIds, 'floorIds');
    }

    const handleOnChangeArea = (areaIds) => {
       rememberDashboardQuery(areaIds, 'areaIds', true);
       setSelectedArea(areaIds);
    }

    const rememberDashboardQuery = (data, key) => {
        const dashboardQuery = JSON.parse(localStorage.getItem('dashboardQuery'));
        dashboardQuery[key] = data;
        localStorage.setItem('dashboardQuery', JSON.stringify(dashboardQuery));
    }

    useEffect(() => {
        fetchOptions();
    }, []);

    React.useEffect(() => {
        getDashboardList();
    }, [selectedArea]);

    return (
        <Container>
            <ContainerOptions>
                <MultipleSelection
                    onChange={handleOnChangeBuilding}
                    selectAllLabel={'All Buildings'.translate()}
                    label='Building'
                    data={buildingOptions}
                    defaultValue={selectedBuildings}
                    isLoading={isLoading}
                />
                <MultipleSelection
                    onChange={handleOnChangeFloor}
                    selectAllLabel={'All Floors'.translate()}
                    label='Floor'
                    data={floorOptions || originalFloorOptions}
                    defaultValue={selectedFloor}
                    isLoading={isLoading}
                />
                <MultipleSelection
                    onChange={handleOnChangeArea}
                    selectAllLabel={'All Areas'.translate()}
                    label='Area'
                    data={areaOptions || originalAreaOptions}
                    defaultValue={selectedArea}
                    isLoading={isLoading}
                />
            </ContainerOptions>
            <RoleName>{`${date} - ${renderRoleLabel}`}</RoleName>
            <TVModeButton onClick={() => toggleFullScreen()}>{boardTvTitle}</TVModeButton>
        </Container>
    )
}

DashboardHeader.propTypes = {
    toggleFullScreen: PropTypes.func,
    renderRoleLabel: PropTypes.string,
    boardTvTitle: PropTypes.string,
    date: PropTypes.string,
    getDashboardList: PropTypes.func,
};

export default DashboardHeader;
