import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import Image from '../../components/Image';
import PlaceholderImage from '../../../../../../assets/img/image-placeholder.png';

const RemoveIcon = withStyles({
  root: {
    position: 'absolute',
    right: 3,
    top: 3,
  }
})(CancelIcon)

const Container = withStyles({
  root: {
    marginTop: '0.5rem',
  }
})(({ children, ...props })=> <Grid container spacing={2} {...props}>{children}</Grid>);

const Item = withStyles({
  root: {
    position: 'relative',
  }
})(Box);

export default function ImageList({ images, onRemove }){
  return (
    <Container>
      {
        images && images.map((image)=>(
          <Grid item xs={4}>
            <Item>
              <RemoveIcon
                onClick={()=>onRemove(image)}
              />
              <Image
                src={image.imgUrl || PlaceholderImage}
                name={image.name}
              />
            </Item>
          </Grid>
        ))
      }
    </Container>
  )
}