import {
    GROUP_LIST_REQUEST,
    GROUP_LIST_SUCCESS,
    GROUP_LIST_ERROR,
    GROUP_DETAIL_REQUEST,
    GROUP_DETAIL_SUCCESS,
    GROUP_DETAIL_ERROR,
    GROUP_NEW_REQUEST,
    GROUP_NEW_SUCCESS,
    GROUP_NEW_ERROR,
    GROUP_UPDATE_REQUEST,
    GROUP_UPDATE_SUCCESS,
    GROUP_UPDATE_ERROR,
    GROUP_TRIGGER_HIGHLIGHT
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    detailLoading: false,
    triggerHighlight: false,
    groupList:[],
    meta: [],
    error: ''
}

const groupListReducer = (state = initialState, action) => {
    switch (action.type) {
        case GROUP_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GROUP_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                groupList: action.payload.groupList,
                error: ''
            }
        case GROUP_TRIGGER_HIGHLIGHT:
            return {
                ...state,
                triggerHighlight: action.payload
            }
        default: 
            return state;
    }
}

export default groupListReducer;