import React from 'react';
import { connect } from 'react-redux';
import { deleteMacType } from '../../../../../redux/actions/MacTypes/MacTypeActions'
import { confirmAlert } from 'react-confirm-alert';
import history from '../../../../../history';
import Table from '../../../../../components/CollapsableTable/CollapseTable';
import { notificationError } from '../../../../../redux/actions/Notification/notificationActions';
import { getAllMacSubTypes, getAllMacSubTypesTreeView, deleteMacSubType } from '../../../../../redux/actions/MacSubTypes/MacSubTypeActions'
import dictionary from '../../../../../redux/actions/Translation/dictionary'
import { checkPageAccessField } from '../../../../../utils/helper';
import './Machine.scss';

class Machine extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            translations: {
                ...this.convert()
            },
            translationTimestamp: undefined,
            tableState: {
                add: () => {
                    history.push('/configuration/machine-config/machine-type/new')
                },
                edit: ({ details: { id } }) => {
                    history.push(`/configuration/machine-config/machine-type/edit/${id}`)
                },
                remove: ({ details: { id } }) => {
                    confirmAlert({
                        title: this.state?.translations?.are_you_sure_delete_machine_type,
                        message: '',
                        buttons: [
                            {
                                label: this.state?.translations?.YES,
                                onClick: () => {
                                    this.props.deleteMacType(id)
                                }
                            },
                            {
                                label: this.state?.translations?.NO,
                            }
                        ]
                    });
                },
                child_edit:({ id, macTypeId }) => {
                    localStorage.setItem('redirectConfigSubType', 'true');
                    history.push(`/configuration/machine-config/machine-sub-type/edit/${macTypeId}/${id}`)
                },
                child_remove: ({ id }) => {
                    confirmAlert({
                        title: this.state?.translations?.are_you_sure_delete_machine_subtype,
                        message: '',
                        buttons: [
                            {
                                label: this.state?.translations?.YES,
                                onClick: () => {
                                    this.props.deleteMacSubType(id)
                                }
                            },
                            {
                                label: this.state?.translations?.NO,
                            }
                        ]
                    });
                },
                tb_add: ({ details: { id, isActive } }) => {
                    if (isActive === false){
                        this.props.notificationError('Machine Type is Inactive. Cannot add machine sub type.')
                    }
                    else{
                        history.push(`/configuration/machine-config/machine-sub-type/new/${id}`)
                    }
                },
                search: true,
                highlight: true,
                addToolTip: 'Add Machine Subtype',
                keys: this.initTableHeader(),
                data: [],
                loading: false,
                collapsable: true,
                levelInstance: {
                    parent: 'details',
                    child: [
                        'macsubTypes'
                    ]
                },
                timeStamp: undefined
            }
        }
    }
    /**handler */
    initTableHeader = () => {
        let { STATUS, MACHINE_TYPE_CODE, DISPLAY_NAME, DESCRIPTION, UPDATED_DATE } = this.convert()
        return [
            {
                name: MACHINE_TYPE_CODE,
                childKey: 'subType',
                key: 'macType',
                width: '14.7',
                headerWidth: '16'
                // width: '122'
            },
            {
                name: DISPLAY_NAME,
                childKey: 'desc1',
                key: 'desc1',
                width: '15',
                headerWidth: '15'
                // width: '132'
            },
            {
                name: DESCRIPTION,
                childKey: 'desc2',
                key: 'desc2',
                width: '26.1',
                headerWidth: '25.5'
                // width: '230'
            },
            {
                name: STATUS,
                type: 'boolean',
                childKey: 'isActive',
                key: 'isActive',
                headerWidth: '10',
                width: '10',
                // width: '60'
            },
            {
                name: UPDATED_DATE,
                type: 'date',
                childKey: 'updatedDt',
                key: 'updatedDt',
                alt: 'createdDt',
                // width: '60'
            },
        ]
    }
    convert = () => {
        let { YES, NO, NOTIFICATIONS: { are_you_sure_delete_machine_type, are_you_sure_delete_machine_subtype }, STATUS, MACHINE_TYPE_CODE, DISPLAY_NAME, DESCRIPTION, UPDATED_DATE,ADD_MACHINE_SUBTYPE } = dictionary.GET()
        return dictionary.TRANSLATE({ YES, NO, are_you_sure_delete_machine_type, are_you_sure_delete_machine_subtype, STATUS, MACHINE_TYPE_CODE, DISPLAY_NAME, DESCRIPTION, UPDATED_DATE,ADD_MACHINE_SUBTYPE })
    }
    componentDidMount() {
        console.log(this.props)
        this.props.getAllMacSubTypesTreeView();
    }

    componentDidUpdate() {
        // console.log('didUpdate', this.props, this.state)
        let { loading, macSubTypeListTree, macSubTypeListTreeTimeStamp } = this.props
        let { loading: stateLoading, data, timeStamp } = this.state.tableState
        if (loading != stateLoading || macSubTypeListTree?.length != data?.length || macSubTypeListTreeTimeStamp != timeStamp) {

            if(Array.isArray(macSubTypeListTree)){
                let tableState = this.state.tableState;
                if (checkPageAccessField(this.props.userAccess,{pageId:19,pageAccess: 'VIEW'})){
                    tableState = {
                        add: null,
                        edit: null,
                        remove: null,
                        tb_add: null,
                        child_remove: null,
                        child_edit: null
                    }
                }
                this.setState({
                    tableState: {
                        ...tableState,
                        data: [...macSubTypeListTree],
                        loading,
                        timeStamp: macSubTypeListTreeTimeStamp
                    }
                })
            }
        }
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                translations: Object.assign({}, { ...this.convert() }),
                tableState: {
                    ...this.state.tableState,
                    keys: this.initTableHeader(),
                    addToolTip: this.convert().ADD_MACHINE_SUBTYPE,
                    timeStamp: new Date()
                }
            })
        }
    }

    componentWillUnmount() {
    }

    /**render */
    render() {
        return (
            <div className="brand-body-overview">
                <Table {...this.state.tableState} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    let { macSubTypeListReducer ,translationReducer} = state
    let apiLoading = {
    }
    let data = {
        userAccess: state.authReducer.access,
    }

    return {
        ...apiLoading,
        ...data,
        ...macSubTypeListReducer,
        ...translationReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        notificationError: (message) => dispatch(notificationError(message)),
        getAllMacSubTypesTreeView: () => dispatch(getAllMacSubTypesTreeView()),
        deleteMacType: (id) => dispatch(deleteMacType(id)),
        deleteMacSubType: (id) => dispatch(deleteMacSubType(id))
    }
}

Machine = connect(mapStateToProps, mapDispatchToProps)(Machine);

export default Machine;
