export const colors = [
    '#88B9D8', 
    '#9485E6', 
    '#C400FF', 
    '#6D93F7', 
    '#F3B05A', 
    '#6DDDA5',
];

export const legends = [
    {title: 'TTL. Handling Time', color: colors[0]},
    {title: 'AVG. Handling Time', color: colors[1]},
    {title: 'CO Time', color: colors[2]},
    {title: 'MAINT. Time', color: colors[3]},
    {title: 'Repair Response Time', color: colors[4]},
    {title: 'Repair Time', color: colors[5]},
];