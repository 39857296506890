import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { getUserToken, checkIfSuperAdmin, isSuperAdmin, checkPageActionAccess, checkPageAccessField } from '../../../../../utils/helper';
import { apiSettings } from '../../../../../utils/api/CoreApi';
import CameraIcon from '../../../../../assets/img/Icon metro-camera.svg';
import history from '../../../../../history';
import { Link } from 'react-router-dom';
import { showModal } from '../../../../../redux/actions/Modal/modalActions';
import Table from '../../../../../components/CollapsableTable/CollapseTable'
import { notificationError } from '../../../../../redux/actions/Notification/notificationActions'
import { getRoles } from '../../../../../redux/actions/Role/roleActions'
import { getRoleDetail, postNewRole, putUpdateRole, deleteRole, getRolesTreeView } from '../../../../../redux/actions/Role/roleActions';
import dictionary from '../../../../../redux/actions/Translation/dictionary'
import MainPage from '../../../../../components/MainPage/MainPage'
import MainPageHeader from '../../../../../components/MainPageHeader/MainPageHeader'
import MainPageBody from '../../../../../components/MainPageBody/MainPageBody';
import CropImage from '../../../../../components/Modal/CropImage/CropImage';
import ChangePassword from '../../../../../components/Modal/ChangePassword/ChangePassword';
import { validateFields } from './FormValidation';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import setters from '../../../../../redux/actions/Factory/factoryActions'
import { Paper, FormLabel, FormControlLabel, Radio, RadioGroup, Grid, FormControl, TextField, Select, MenuItem, Button, InputAdornment, IconButton, Input, InputLabel } from '@material-ui/core'
import './RoleAccessForm.scss';

class RoleAccessForm extends React.Component {
    constructor(props) {
        super(props)
        this.profileRef = React.createRef();
        this.state = {
            id: null,
            oldForm: null,
            trigger: false,
            dateDetails: null,
            form: {
                role: null,
                desc: null,
                isActive: null,
                pageAccess: []
            },
            loaded: false,
            uploadImage: null,
            cropSize: null,
            cropShape: null,
            cropName: null,
            cropTitle: null,
            passwordShown: null,
            confirmPasswordShown: null,
            formErrors: [],
            hasFormChanges: false,
            radioTicked: false,
            tableTitleColor: '#515151',
            translations: {
                ...this.convert()
            },
            translationTimestamp: undefined,
            tableState: {
                search: false,
                radio_tick: ({ rowValue: { pageId }, from = '', prevId = undefined }, value, ev = false) => {
                    if (checkPageActionAccess(this.props.userAccess, { pageId: 26, pageAction: 'Add' }) || checkPageActionAccess(this.props.userAccess, { pageId: 26, pageAction: 'Edit' }) || isSuperAdmin()) {
                        let { form: { pageAccess } } = this.state
                        // console.log('radio_tick', pageId, from, prevId, value, ev,  ev === true || this.state.form.hasFormChanges === true ? true : false)
                        if (Array.isArray(pageAccess)) {
                            let isIndex = pageAccess.findIndex(x => x?.['pageId'] == pageId)
                            if (isIndex == -1) {
                                pageAccess.push({
                                    pageId,
                                    access: value
                                })
                            }
                            else {
                                pageAccess[isIndex] = {
                                    pageId,
                                    access: value
                                }
                            }
                            this.setState({
                                form: {
                                    ...this.state.form,
                                    pageAccess,
                                },
                                hasFormChanges: ev === true || this.state.hasFormChanges === true ? true : false,
                                radioTicked: ev === true || this.state.radioTicked === true ? true : false,
                            })
                        }
                    }
                },
                radioArray: ['None', 'Edit', 'Full Access', 'View'],
                keys: this.initTableHeader(),
                data: [],
                parentExpand: true, // comment out if parent is not expanded
                loading: false,
                collapsable: true,
                levelInstance: {
                    parent: 'details',
                    child: [
                        'roles'
                    ]
                },
                timeStamp: undefined
            }
        }
    }
    /**handler */
    initTableHeader = () => {
        let { ACCESS_RIGHTS, NONE, VIEW_ONLY, EDIT, FULL_ACCESS, VIEWCREATEEDIT, VIEWCREATEAPPROVE, } = this.convert()
        return [
            {
                name: ACCESS_RIGHTS,
                key: 'pageCategory',
                headerWidth: '46',
                width: '45',
                childKey: 'pageCategory',
                // alt: 'Page',
            },
            {
                name: NONE,
                untouchedName: 'None',
                type: 'radio',
                key: '',
                headerWidth: '10',
                width: '11',
                childKey: 'access',
                // alt: 'Page',
            },
            {
                name: VIEW_ONLY,
                untouchedName: 'View Only',
                key: '',
                type: 'radio',
                headerWidth: '12',
                width: '11',
                childKey: 'access',
                // alt: 'Page',
            },
            {
                name: EDIT,
                untouchedName: 'Edit',
                type: 'radio',
                key: '',
                linkToolTip: VIEWCREATEEDIT,
                // headerWidth: '50',
                // width: '50',
                childKey: 'access',
                // alt: 'Page',
            },
            {
                name: FULL_ACCESS,
                untouchedName: 'Full Access',
                key: '',
                type: 'radio',
                linkToolTip: VIEWCREATEAPPROVE,
                // headerWidth: '50',
                // width: '50',
                childKey: 'access',
                // alt: 'Page',
            },
        ]
    }
    convert = () => {
        let { SAVE, CANCEL, ADD, YES, NO, TOOLTIPS: { VIEWCREATEEDIT, VIEWCREATEAPPROVE },
            NOTIFICATIONS: {
                are_you_sure_you_want_to_cancel_changes,
            },
            ACCESS_RIGHTS, NONE, VIEW_ONLY, EDIT, FULL_ACCESS, VIEW, ROLE_ACCESS_AND_PERMISSIONS, DESCRIPTION, ROLE_NAME, STATUS, Active, Inactive, UPDATED_BY, UPDATED_DATE, CREATED_BY, CREATED_DATE, ROLE, UPLOAD_USER_IMAGE, LOGIN_ID, CHANGE_PASSWORD, 
            CHECKLIST_TEMPLATES, CHECKLIST_DETAILS, DASHBOARD, LINE_OVERVIEW, LOCATIONS, ATTACHMENTS, BRANDS, MACHINE_SUB_TYPES, MACHINE_TYPES, MOTOR_TYPES, MACHINE_DETAILS, MACHINE_MAINTENANCE_HISTORY, MACHINE_CHECKLIST_TEMPLATE, MACHINE_REPAIR_HISTORY, SYSTEM_PREFERENCE, TICKET_DETAILS, TICKET_MANAGEMENT, ROLE_MANAGEMENT, MACHINES, Maintenance, REPAIR_TYPES, USER_MANAGEMENT,MACHINE_MASTER_DATA, Machines_Up_For_Maintenance 
        } = dictionary.GET()
        return dictionary.TRANSLATE({
            are_you_sure_you_want_to_cancel_changes,
            SAVE, CANCEL, ADD, YES, NO, VIEWCREATEEDIT, VIEWCREATEAPPROVE,
            ACCESS_RIGHTS, NONE, VIEW_ONLY, EDIT, FULL_ACCESS, VIEW, ROLE_ACCESS_AND_PERMISSIONS, DESCRIPTION, ROLE_NAME, STATUS, Active, Inactive, UPDATED_BY, UPDATED_DATE, CREATED_BY, CREATED_DATE, ROLE, UPLOAD_USER_IMAGE, LOGIN_ID, CHANGE_PASSWORD,  
            CHECKLIST_TEMPLATES, CHECKLIST_DETAILS, DASHBOARD, LINE_OVERVIEW, LOCATIONS, ATTACHMENTS, BRANDS, MACHINE_SUB_TYPES, MACHINE_TYPES, MOTOR_TYPES, MACHINE_DETAILS, MACHINE_MAINTENANCE_HISTORY, MACHINE_CHECKLIST_TEMPLATE, MACHINE_REPAIR_HISTORY, SYSTEM_PREFERENCE, TICKET_DETAILS, TICKET_MANAGEMENT, ROLE_MANAGEMENT, MACHINES, Maintenance, REPAIR_TYPES, USER_MANAGEMENT,MACHINE_MASTER_DATA, Machines_Up_For_Maintenance 
        })
    }

    listSanitize = (arr) => {
        let { levelInstance: { parent, child } } = this.state.tableState
        let { CHECKLIST_TEMPLATES, CHECKLIST_DETAILS, DASHBOARD, LINE_OVERVIEW, LOCATIONS, ATTACHMENTS, BRANDS, MACHINE_SUB_TYPES, MACHINE_TYPES, MOTOR_TYPES, MACHINE_DETAILS, MACHINE_MAINTENANCE_HISTORY, MACHINE_CHECKLIST_TEMPLATE, MACHINE_REPAIR_HISTORY, SYSTEM_PREFERENCE, TICKET_DETAILS, TICKET_MANAGEMENT, ROLE_MANAGEMENT, MACHINES, Maintenance, REPAIR_TYPES, USER_MANAGEMENT,MACHINE_MASTER_DATA, Machines_Up_For_Maintenance } = this.convert()
        let toReturn = [] 
        let sanitize = (value) => {
            return {
                'Checklist Templates': CHECKLIST_TEMPLATES,
                'Checklist - Details': CHECKLIST_DETAILS,
                'Dashboard': DASHBOARD,
                'Line Overview': LINE_OVERVIEW,
                'Locations': LOCATIONS,
                'Attachments': ATTACHMENTS,
                'Brands': BRANDS,
                'Machine Sub Types': MACHINE_SUB_TYPES,
                'Machine Types': MACHINE_TYPES,
                'Motor Types': MOTOR_TYPES,
                'Machines': MACHINES,
                'Machine - Details': MACHINE_DETAILS,
                'Machine - Maintenance History': MACHINE_MAINTENANCE_HISTORY,
                'Machine - Checklist Template': MACHINE_CHECKLIST_TEMPLATE,
                'Machine - Repair History': MACHINE_REPAIR_HISTORY,
                'Maintenance': Maintenance,
                'Repair Types': REPAIR_TYPES,
                'System Preference': SYSTEM_PREFERENCE,
                'Ticket - Details': TICKET_DETAILS,
                'Ticket Management': TICKET_MANAGEMENT,
                'Role Management': ROLE_MANAGEMENT,
                'User Management': USER_MANAGEMENT,
                'Machine Master Data': MACHINE_MASTER_DATA,
                'Machines Up For Maintenance': Machines_Up_For_Maintenance
            }[value] ?? value
        }

        arr.forEach((x, arrIndex) => {
            let tempObject = Object.assign({}, x)
            tempObject[parent]['pageCategory'] = sanitize(x[parent]['pageCategory'])
            x[child[0]].forEach((childAt, childIndex) => {
                tempObject[child[0]][childIndex]['pageCategory'] = sanitize(childAt['pageCategory'])
            })
            toReturn.push(tempObject)
        })

        return toReturn

    }

    componentDidMount() {
        document.body.style.background = '#f7f7fb';
        this.props.getRoles();
        if (this.props.match.params.id) {
            let id = this.props.match.params.id;
            this.setState({ id: id });
            this.props.getRoleDetail(id);
        }
        else this.props.getRolesTreeView();
    }

    componentDidUpdate() {
        // console.log('didUpdate', this.props, this.state)
        let { loading, roleListTreeView, roleListTreeViewTimeStamp } = this.props
        let { loading: stateLoading, data, timeStamp, levelInstance: { parent, child }, keys } = this.state.tableState
        if (loading != stateLoading || roleListTreeView?.length != data?.length || roleListTreeViewTimeStamp != timeStamp) {
            if (Array.isArray(roleListTreeView)) {
                if (roleListTreeView?.length > 0) {
                    let possibleKeys
                    const sanitizeSpace = (string) => {
                        return string.replace(/\s/gi, '')
                    }
                    const isExist = (toFind) => {
                        return keys.findIndex(keyI => keyI['name'] == toFind)
                    }
                    for (let x = 0; x < roleListTreeView?.length; x++) {
                        if (roleListTreeView[x][child]?.length > 0) {
                            let lastRoleChild = roleListTreeView[x][child][0]
                            possibleKeys = lastRoleChild['tableHeader']
                            break;
                        }
                    }
                    // possibleKeys.forEach(keyP => {
                    //     if (isExist(keyP) == -1) {
                    //         let checkboxKey = sanitizeSpace(keyP)
                    //         keys.push({
                    //             name: keyP,
                    //             type: 'checkbox',
                    //             // headerWidth: '45',
                    //             // width: '44',
                    //             key: '',
                    //             childKey: checkboxKey,
                    //         })
                    //     }
                    // })

                }
                let dashboardIndex = roleListTreeView.findIndex(x => x[parent]['pageCategory'] == 'Dashboard')
                if (dashboardIndex != -1) {
                    roleListTreeView[dashboardIndex][child[0]][0]['access'] = "VIEW"
                }
                localStorage['roleListTreeViewForm'] = JSON.stringify(roleListTreeView)
                this.listSanitize(roleListTreeView.slice())
                this.setState({
                    tableState: {
                        ...this.state.tableState,
                        data: [...roleListTreeView],
                        keys,
                        loading,
                        timeStamp: roleListTreeViewTimeStamp
                    }
                })
            }
        }
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                translations: Object.assign({}, { ...this.convert() }),
                tableState: {
                    ...this.state.tableState,
                    data: this.listSanitize(JSON.parse(localStorage['roleListTreeViewForm']).slice()),
                    keys: this.initTableHeader(),
                    // timeStamp: new Date()
                }
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.roleDetail != null && this.props.match.params.id && this.state.loaded === false) {
            let tmpForm = {
                ...this.state.form,
                role: nextProps.roleDetail.role,
                desc: nextProps.roleDetail.desc1,
                // pageAccess: nextProps.roleDetail.desc1,
                isActive: this.renderBooleanAsInteger(nextProps.roleDetail.isActive),
            };
            let oldData = {
                ...tmpForm
            };
            let dateDetails = {
                ...nextProps.roleDetail
            };
            this.setState({ form: tmpForm, oldForm: oldData, loaded: true, dateDetails: dateDetails });
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextState.radioTicked) {
            this.setState({
                tableTitleColor: '#4CAF50',
                radioTicked: false,
            })
        }
    }

    handleProfileUpload = () => {
        this.resetCrop();
        this.profileRef.current.click();
    }

    handleProfileFileChange = ({ target }) => {
        let {translations} = this.state
        let filename = target.files[0].name.toLowerCase();
        if (filename.indexOf('.jpg') >= 0 || filename.indexOf('.jpeg') >= 0 || filename.indexOf('.png') >= 0) {
            let base64Image = this.toBase64(target.files[0]).then((result) => { return result });
            base64Image.then((result) => {
                let state = {
                    uploadImage: result,
                    cropName: "user-profile",
                    cropTitle: translations.UPLOAD_USER_IMAGE,
                    cropShape: "round",
                    cropSize: { height: 291, width: 291 }
                }
                this.setState(state, () => {
                    this.props.showModal('cropImage');
                });
            })
        }
        else {
            this.props.notificationError('File not supported');
        }
    }

    openModal = (modalName, parentId = null, id = null) => {
        this.setState({
            modalOpen: {
                name: modalName,
                id: id,
                parentId: parentId,
                saveLabel: id ? this.state?.translations?.ADD : this.state?.translations?.SAVE,
            }
        });
    }

    closeModal = () => {
        this.setState({
            modalOpen: {
                name: null,
                id: null,
                saveLabel: null,
            }
        });
    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    resetCrop() {
        this.setState({
            uploadImage: null,
            cropSize: null,
            cropShape: null,
            cropName: null,
            cropTitle: null,
        });
        this.profileRef.current.value = null;
    }

    componentWillUnmount() {
        document.body.style.background = null;
    }

    handleFormChange = ({ target }) => {
        let tmpForm = this.state.form;
        tmpForm[target.name] = target.value;
        this.setState({ form: tmpForm, hasFormChanges: true });
        this.removeErrorOnChange(target.name);
    };

    handleRadioButtonChange = ({ target }) => {
        let tmpForm = this.state.form;
        tmpForm[target.name] = parseInt(target.value);
        this.setState({ form: tmpForm, hasFormChanges: true });
        this.removeErrorOnChange(target.name);
    };


    renderBooleanAsInteger(bool) {
        if (bool === true || bool === "True") {
            return 1
        }
        else {
            return 0
        }
    }

    renderText(field) {
        if (field === null || field === '') {
            return '-'
        }
        else {
            //   return field != undefined ? field.toString().translate() : field
            return field
        }
    }

    renderDate(date) {
        if (date !== null) {
            let timezone = moment.parseZone(date).format('Z');
            let dateTimeOnly = moment.parseZone(date).format('YYYY-MM-DD HH:mm:ss') + '+00:00';
            return moment(dateTimeOnly).utcOffset(timezone).format('DD-MM-YYYY');
        }
        else {
            return '-';
        }
    }

    handleChangePassword = () => {
        this.props.showModal('changePassword');
    }

    handleTogglePassword = () => {
        this.setState({ passwordShown: !this.state.passwordShown });
    }

    handleToggleConfirmPassword = () => {
        this.setState({ confirmPasswordShown: !this.state.confirmPasswordShown });
    }

    getImageLink(link) {
        let userToken = getUserToken();
        let fileLink = apiSettings.apiURLFiles + btoa("/api/files/by-name?path=" + link + "&token=" + userToken + '&unlinked');
        return fileLink;
    }

    renderProfileImage() {
        if (this.state.form.avatar) {
            return this.getImageLink(this.state.form.avatar);
        }
        else {
            return null
        }
    }

    renderStatusForm() {
        let { translations } = this.state
        return (
            <div>
                <Paper className="status-form end">
                    <div className="form-input">
                        <FormControl className="control-row" fullWidth>
                            <Grid container spacing={0}>
                                <Grid item xs={3}>
                                    <FormLabel className="status" component="legend">{translations.STATUS}</FormLabel>
                                </Grid>
                                <Grid item xs={8}>
                                    <RadioGroup onChange={this.handleRadioButtonChange} name="isActive" className="radioButton" row value={this.state.form.isActive}>
                                        <FormControlLabel value={1} control={<Radio disabled={checkPageActionAccess(this.props.userAccess, { pageId: 26, pageAction: 'Edit' }) === false && !isSuperAdmin()} color="primary" />} label={translations.Active} />
                                        <FormControlLabel value={0} control={<Radio disabled={checkPageActionAccess(this.props.userAccess, { pageId: 26, pageAction: 'Edit' }) === false && !isSuperAdmin()} color="primary" />} label={translations.Inactive} />
                                    </RadioGroup>
                                </Grid>
                                <Grid className="statusDetails" item xs={12}>
                                    <FormLabel component="legend">{translations.UPDATED_BY}: {this.state.dateDetails ? this.renderText(this.state.dateDetails.updatedBy) : null}</FormLabel>
                                </Grid>
                                <Grid className="statusDetails" item xs={12}>
                                    <FormLabel component="legend">{translations.UPDATED_DATE}: {this.state.dateDetails ? this.renderDate(this.state.dateDetails.updatedDt) : null}</FormLabel>
                                </Grid>
                                <Grid className="statusDetails" item xs={12}>
                                    <FormLabel component="legend">{translations.CREATED_BY}: {this.state.dateDetails ? this.renderText(this.state.dateDetails.createdBy) : null}</FormLabel>
                                </Grid>
                                <Grid className="statusDetails" item xs={12}>
                                    <FormLabel component="legend">{translations.CREATED_DATE}: {this.state.dateDetails ? this.renderDate(this.state.dateDetails.createdDt) : null}</FormLabel>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </div>
                </Paper>
            </div>
        )
    }

    removeErrorOnChange(field) {
        let errors = this.state.formErrors.filter((item) => { return item.name !== field });
        this.setState({ formErrors: errors });
    }

    hasErrors = (field) => {
        let checkField = this.state.formErrors.filter((item) => { return item.name === field });
        if (checkField.length > 0) {
            return true
        }
        else {
            return false;
        }
    }

    getErrors = (field) => {
        let checkField = this.state.formErrors.filter((item) => { return item.name === field });
        let errorString = null;
        checkField.map((item) => {
            errorString += item.message + '. ';
            return '';
        })
        return errorString;
    }



    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    resetChanges(form) {
        this.setState({
            hasFormChanges: false,
            trigger: false,
            form: form
        })
    }

    submitForm = () => {
        let payload = {
            ...this.state.form,
        };
        let formValidation = validateFields(payload);
        this.setState({ formErrors: formValidation.errors });
        if (formValidation.valid) {
            this.setState({ disablePrompt: true, trigger: false }, () => {
                if (this.state.id === null) {
                    payload = {
                        ...payload,
                        isActive: 1,
                    }
                    this.props.postNewRole(payload);
                }
                else {
                    payload = {
                        ...payload,
                    }
                    this.props.putUpdateRole(this.state.id, payload);
                    this.resetChanges(this.state.form);
                }
            });
        }
        else {
            this.props.notificationError(formValidation.errors[0].message);
        }
    }

    resetChanges(form) {
        this.setState({
            hasFormChanges: false,
            trigger: false,
            // form: form
        });
    }

    renderRoles() {
        if (this.props.roleList) {
            let roles = this.props.roleList.map((item) => {
                let itemRow = (
                    <MenuItem value={item.role}>
                        {item.role}
                    </MenuItem>
                )
                return itemRow;
            });
            return roles;
        }
        else {
            return null;
        }
    }

    renderUserInfo() {
        let {translations} = this.state
        if (this.state.id) {
            return (
                <div className="user-info">
                    <div className="small-label">{translations.LOGIN_ID}</div>
                    <div className="loginId">{this.props.userDetail ? this.props.userDetail.userName : null}</div>
                    <Link className="change-password" onClick={this.handleChangePassword}>{translations.CHANGE_PASSWORD}</Link>
                </div>
            )
        }
    }

    renderProfileBody() {
        let { translations } = this.state
        return (

            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <FormControl className="control-row" fullWidth>
                        <TextField disabled={checkPageActionAccess(this.props.userAccess, { pageId: 26, pageAction: 'Add' }) === false && checkPageActionAccess(this.props.userAccess, { pageId: 26, pageAction: 'Edit' }) === false && !isSuperAdmin()} required className={this.state.form.role ? 'label-green' : null} required error={this.hasErrors('role')} value={this.state.form.role} onChange={this.handleFormChange} name="role" label={translations.ROLE_NAME} InputLabelProps={{ shrink: true }} />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className="control-row" fullWidth>
                        <TextField disabled={checkPageActionAccess(this.props.userAccess, { pageId: 26, pageAction: 'Add' }) === false && checkPageActionAccess(this.props.userAccess, { pageId: 26, pageAction: 'Edit' }) === false && !isSuperAdmin()} className={this.state.form.desc ? 'label-green' : null} required error={this.hasErrors('desc')} value={this.state.form.desc} onChange={this.handleFormChange} name="desc" label={translations.DESCRIPTION} InputLabelProps={{ shrink: true }} />
                    </FormControl>
                </Grid>
            </Grid>
        )
    }

    cancelRedirect = () => {
        history.push('/configuration/user-manage/users');
    }

    revertChanges = () => {
        let {translations} = this.state
        confirmAlert({
            title: translations.are_you_sure_you_want_to_cancel_changes,
            message: '',
            buttons: [
                {
                    label: translations.YES,
                    onClick: () => {
                        let tmpForm = {
                            ...this.state.oldForm
                        }
                        this.setState({ form: tmpForm, hasFormChanges: null, tableTitleColor: '#515151' });
                        console.log(this.state.form);
                    }
                },
                {
                    label: translations.NO,
                }
            ]
        });
    }

    getBackLink() {
        return '/configuration/user-manage/roles'
    }

    triggerFormChange(flag) {
        if (flag === true) {
            this.setState({ hasFormChanges: true, trigger: true });
        }
        else {
            this.setState({ hasFormChanges: false, trigger: false });
        }
    }

    /**render */
    render() {
        let { translations } = this.state
        return (
            <MainPage className="role-form-config">
                {this.props.hasFormChanges === true && this.state.trigger === false ? this.triggerFormChange(true) : null}
                <MainPageHeader title="User Management" search={false} back={this.getBackLink()} />
                <MainPageBody>
                    <div className="factory-sublist-body">
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Paper className={{ "add-form": this.state.id === null, 'edit-form': this.state.id !== null }}>
                                    <div className="user-profile-form">
                                        <div className="profile-header">
                                            <Grid container spacing={0}>
                                                <Grid item xs={8}>
                                                    <div className="title">{this.state.id ? translations.EDIT : translations.ADD} {translations.ROLE}</div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className="profile-body">
                                            {this.renderProfileBody()}
                                        </div>
                                    </div>
                                </Paper>
                                {this.state.id ? this.renderStatusForm() : null}
                            </Grid>
                            <Grid item xs={9}>
                                <Paper>
                                    <div className="factory-table" style={{ color: this.state.tableTitleColor }}>
                                        {translations.ROLE_ACCESS_AND_PERMISSIONS}
                                    </div>
                                    <Table {...this.state.tableState} />
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={"footer-actions " + (this.state.hasFormChanges ? "show" : null)}>
                        <Grid container spacing={1}>
                            <Grid item xs={5} />
                            <Grid item xs={1}>
                                {/* <Link to="/configuration/machine-config/brands"> */}
                                <Button onClick={this.state.id ? this.revertChanges : this.cancelRedirect} className="cancel" fullWidth variant="outlined">{translations.CANCEL}</Button>
                                {/* </Link> */}
                            </Grid>
                            <Grid item xs={1}>
                                <Button onClick={this.submitForm} className="save" fullWidth color="primary" variant="contained">{translations.SAVE}</Button>
                            </Grid>
                            <Grid item xs={5} />
                        </Grid>
                    </div>
                    {this.state.uploadImage ? <CropImage reset={this.resetCrop} size={this.state.cropSize} cropShape={this.state.cropShape} id={this.props.match.params.id} image={this.state.uploadImage} name={this.state.cropName} title={this.state.cropTitle} /> : null}
                    {this.state.id ? <ChangePassword id={this.state.id} /> : null}
                </MainPageBody>
            </MainPage>
        );
    }
}

const mapStateToProps = state => {
    let { translationReducer, roleReducer } = state
    let apiLoading = {
    }
    let data = {
        userAccess: state.authReducer.access,
        roleList: state.roleReducer.roleList,
    }

    return {
        ...apiLoading,
        ...data,
        ...translationReducer,
        ...roleReducer,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showModal: (modal) => dispatch(showModal(modal)),
        notificationError: (message) => dispatch(notificationError(message)),
        getRoles: () => dispatch(getRoles()),
        postNewRole: (payload) => dispatch(postNewRole(payload)),
        putUpdateRole: (id, payload) => dispatch(putUpdateRole(id, payload)),
        getRoleDetail: (id) => dispatch(getRoleDetail(id)),
        getRolesTreeView: () => dispatch(getRolesTreeView()),

    }
}

RoleAccessForm = connect(mapStateToProps, mapDispatchToProps)(RoleAccessForm);

export default RoleAccessForm;
