import React from 'react';
import { Box } from '@material-ui/core';
import Typography from '../../components/Typography';
import { withStyles } from '@material-ui/core/styles';

const Text = withStyles({
  root: {
    fontWeight: 'bold',
  }
})(Typography);

const Wrapper = withStyles({
  root: {
    marginTop: '1rem',
    display: 'inline-flex',
    width: '30%',
    justifyContent: 'space-between',
  }
})(Box);

export default function Totals({ changeover }) {
  return <Wrapper>
    <Text>
      {'THE END'.translate()}
    </Text>

    <Text>
      {'TOTAL M/C'.translate()}: { changeover.machines.length }
    </Text>
  </Wrapper>
}