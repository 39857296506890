import React, { useState, useEffect, useRef } from 'react';
import { DateRangePicker } from 'react-date-range';
import { Popper, TextField, Paper, Fade } from '@material-ui/core';
import { addDays } from 'date-fns';
import BootstrapInput from '@src/components/BootstrapInput'
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { staticRanges, staticRangesAll } from './data';
import {
  isSameDay,
  endOfDay,
  startOfDay,
} from 'date-fns';
const useStyles = makeStyles({
  input: {
    width: "100%",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
})

const FORMAT = "MMM DD YYYY";
export default function DateRangeInput(props) {
  const classes = useStyles();
  const { onChange, dateRange, inputClass} = props;
  const [isOpen, setIsOpen] = useState(false);
  const [textInput, setTextInput] = useState(null);

  const handleOnTouchTap = (event)=>{
    event.preventDefault();
    setTextInput(event.currentTarget);
    setIsOpen(true);
  }

  const handleSelected = (item)=>{
    const { selection } = item;
    const endDate = selection.endDate ? endOfDay(selection.endDate || selection.startDate) : null;
    const startDate = selection.startDate ? startOfDay(selection.startDate) : null;
    onChange({
      startDate,
      endDate
    })
    setIsOpen(false);
  }


  const dateState = {
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    key: 'selection'
  }

  let value = 'All'.translate();
  if(dateRange.startDate && dateRange.endDate) {
    const selectionFormat = `${moment(dateState.startDate).format(FORMAT)} - ${moment(dateState.endDate).format(FORMAT)}`;
    const definedRange = (props.showFilterAll ? staticRangesAll : staticRanges).find((r)=> {
      const range = r.range();
      return isSameDay(range.startDate, dateState.startDate) && isSameDay(range.endDate, dateState.endDate);
    });
    value = definedRange ? `${definedRange.label.translate()} (${selectionFormat})` : selectionFormat;  
  }

  return <div>
    <BootstrapInput
      data-cy="date-range-input"
      id="input-date-picker"
      value={value}
      onClick={handleOnTouchTap}
      className={`${classes.input} ${inputClass ? inputClass : ''}`}
    />
    {
      isOpen ? (
        <div onClick={()=> setIsOpen(false)} className={classes.overlay}></div>
      ) : null
    }
    <Popper
      open={isOpen}
      anchorEl={textInput}
      placement="bottom-start"
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper>
            <DateRangePicker
              editableDateInputs={true}
              ranges={[dateState]}
              inputRanges={[]}
              onChange={item => handleSelected(item)}
              direction="vertical"
              scroll={{ enabled: true }}
              staticRanges={(props.showFilterAll ? staticRangesAll : staticRanges).map((range) => ({
                ...range,
                label: range.label.translate(),
              }))}
            />
          </Paper>
        </Fade>
      )}
    </Popper>
  </div>
}
