import {
    NOTIFICATION_SUCCESS,
    NOTIFICATION_ERROR,
    LINE_LIST,
    LINE_LIST_REQUEST,
    LINE_LIST_SUCCESS,
    LINE_LIST_ERROR,
    LINE_DETAIL_REQUEST,
    LINE_DETAIL_SUCCESS,
    LINE_DETAIL_ERROR,
    LINE_NEW_REQUEST,
    LINE_NEW_SUCCESS,
    LINE_NEW_ERROR,
    LINE_UPDATE_REQUEST,
    LINE_UPDATE_SUCCESS,
    LINE_DELETE_REQUEST,
    LINE_DELETE_SUCCESS,
    LINE_UPDATE_ERROR,
    LINE_TRIGGER_HIGHLIGHT,
} from '../actionTypes';
import { tokenGet, tokenPost, tokenPut, tokenDelete, apiSettings } from '../../../utils/api/CoreApi';
import setters from '../../actions/Factory/factoryActions'
import history from '../../../history';

export const notificationSuccess = (message) => {
    return {
        type: NOTIFICATION_SUCCESS,
        payload: {
            message: message.translate()
        }
    }
}

export const notificationError = (message) => {
    return {
        type: NOTIFICATION_ERROR,
        payload: {
            message: message.translate()
        }
    }
}

export const lineRequest = () => {
    return {
        type: LINE_LIST_REQUEST
    }
}

export const lineSuccess = lineList => {
    return {
        type: LINE_LIST_SUCCESS,
        payload: lineList
    }
}

export const lineDetailRequest = () => {
    return {
        type: LINE_DETAIL_REQUEST
    }
}

export const lineDetailSuccess = lineDetail => {
    return {
        type: LINE_DETAIL_SUCCESS,
        payload: lineDetail
    }
}

export const lineNewRequest = () => {
    return {
        type: LINE_NEW_REQUEST
    }
}

export const lineNewSuccess = lineList => {
    return {
        type: LINE_NEW_SUCCESS,
        payload: lineList
    }
}

export const lineUpdateRequest = () => {
    return {
        type: LINE_UPDATE_REQUEST
    }
}

export const lineUpdateSuccess = lineList => {
    return {
        type: LINE_UPDATE_SUCCESS,
        payload: lineList
    }
}

export const lineDeleteRequest = () => {
    return {
        type: LINE_DELETE_REQUEST
    }
}

export const lineDeleteSuccess = lineList => {
    return {
        type: LINE_DELETE_SUCCESS,
        payload: lineList
    }
}


export const lineTriggerHighlight = (flag) => {
    return {
        type: LINE_TRIGGER_HIGHLIGHT,
        payload: flag
    }
}

export const getLineDetail = (id) => {
    return function (dispatch) {
        dispatch(lineDetailRequest());
        tokenGet(apiSettings.apiURLReferences + '/lines/' + id).then((response) => {
            dispatch(lineDetailSuccess({
                lineDetail: response.data.data[0],
            }));
        })
            .catch((error) => {
                console.log(error);
            });
    }
}

export const postLineNew = (payload,factoryId) => {
    let notif = notificationSuccess('Successfully added line')
    return function (dispatch) {
        dispatch(lineNewRequest());
        tokenPost(apiSettings.apiURLReferences + '/lines', payload).then(async (response) => {
            dispatch(lineNewSuccess({
                line: response.data
            }));
            await setters().getFactoryTreeViewById(payload.factoryId, notif)
        })
        .catch((error) => {
            if (error.message.indexOf(500) >= 0){
                dispatch(notificationError('Line code exists'));
                dispatch(lineTriggerHighlight(true));
            }
            console.log(error);
        });
    }
}

export const putLineUpdate = (id, payload, factoryId) => {
    let notif  = notificationSuccess('Successfully updated line')
    return function (dispatch) {
        dispatch(lineUpdateRequest());
        tokenPut(apiSettings.apiURLReferences + '/lines/' + id, payload).then(async (response) => {
            dispatch(lineUpdateSuccess({
                line: response.data
            }));
            await setters().getFactoryTreeViewById(payload.factoryId, notif)
        })
            .catch((error) => {
                if (error.message.indexOf(500) >= 0){
                    dispatch(notificationError('Line code exists'));
                    dispatch(lineTriggerHighlight(true));
                }
                else if (error.message.indexOf(422) >= 0){
                    dispatch(notificationError("Cannot deactivate. There are machine(s) currently attached to the line"));
                }
                console.log(error);
            });
    }
}

export const deleteLine = (id,factoryId) => {
    let notif = notificationSuccess('Successfully deleted line type')
    return function (dispatch) {
        dispatch(lineDeleteRequest());
        tokenDelete(apiSettings.apiURLReferences + '/lines/' + id).then(async (response) => {
            dispatch(lineDeleteSuccess({
                line: response.data
            }));
            await setters().getFactoryTreeViewById(factoryId, notif)
        })
            .catch((error) => {
                if (error.message.indexOf(500) >= 0 || error.message.indexOf(422) >= 0){
                    dispatch(notificationError('Cannot delete because it is currently referenced to another record'));
                }
                console.log(error);
            });
    }
}
