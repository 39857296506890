import React, { Component } from "react";
import "./InventoryAuthorized.scss";

class InventoryAuthorized extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div className="anventory-authorized"></div>;
  }
}

export default InventoryAuthorized;
