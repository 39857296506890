import React from "react";
import { Box, makeStyles, Typography, withStyles } from "@material-ui/core";
import { IndicatorContainer, LegendIndicator } from "../IndividualMechanicPerformance/styles";

export const useStyles = makeStyles({
    text1: {color: "#fff", fontSize: 13, fontWeight: 'bold'},
    text2: {color: "#ccc", fontSize: 13, marginBottom: 10},
    text3: {color: "#fff", fontSize: 12},
    text4: {color: "#ccc", fontSize: 12},
    box1: {width: 230, padding: 10},
    box2: {},
    divider: {width: '100%', height: 2, background: "#fff"},
});

export const TooltipContainerIndividual = withStyles({})(Box);
export const TooltipTitleIndividual = withStyles({root: () => ({
    color: "#fff", fontSize: 13, fontWeight: 'bold'
})})((props) => <Typography {...props}>{props.title}</Typography>);
export const TooltipSubTitleIndividual = withStyles({root:() => ({
    color: "#ccc", fontSize: 13,
})})((props) => <Typography {...props}>{props.title}</Typography>);
export const TooltipListIndividual = withStyles({})(Box);
export const TooltipDividerIndividual = withStyles({root:() => ({ width: '100%', display: 'block', height: 2, background: "#fff", marginBottom: 5, })})(Box);
export const TooltipListHeaderIndividual = withStyles({root:() => ({
    display: "flex", justifyContent: 'space-between', marginTop: 10
})})((props) => (
    <Box {...props}>
        <IndicatorContainer marginLeft='unset' justifyContent="flex-start">
            <LegendIndicator color={props.color} marginLeft='unset' />
            <TooltipTitleIndividual title={props.title} />
        </IndicatorContainer>
        <TooltipTitleIndividual title={props.total} />
    </Box>
));
export const TooltipListItemIndividual = withStyles({root:() => ({
    display:'flex', justifyContent: 'space-between',
})})((props) => (
    <Box {...props}>
        <IndicatorContainer marginLeft='unset' justifyContent="flex-start">
            <LegendIndicator color={props.color} marginLeft='unset' />
            <TooltipSubTitleIndividual title={props.title} />
        </IndicatorContainer>
        <TooltipSubTitleIndividual title={props.total} />
    </Box>
));


