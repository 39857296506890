
import { machineClient } from '../../../../../utils/api/CoreApi';

export const fetchLatestMachineDistribution = async (query) => {
  const latestMachineDistribution = await machineClient.get(`/machines/summary/latest-machine-distribution?${new URLSearchParams({ ...query, viewBy: '' })}`);
  return { latestMachineDistribution }
}

export const fetchLatestMachineUsage = async (query = {}, filter = 1, orderBy = 'desc') => {
  const latestMachineUsage = await machineClient.get(`/machines/summary/machine-usage?${new URLSearchParams({
    ...query, 
    filter, 
    orderBy: orderBy.toUpperCase()
  })}`);
  return { latestMachineUsage }
}

export const fetchMachinesPerformance = async (
  query = {}, 
  orderBy = 'desc',
  page = 1,
  pageSize = 10,
) => {
  const machinesPerformance = await machineClient.get(`/machines/summary/machine-performance?${new URLSearchParams({
    ...query,
    page,
    pageSize,
    orderBy, 
  })}`);
  return { machinesPerformance }
}