
export const legends = [
    {'title': 'In Use', color: '#87DD00'},
    {'title': 'Idle', color: '#A8A8A8'},
];

export const selectOptions = [
    'Machine Type',
    'Machine Sub-type',
    'Machine Brand',
];