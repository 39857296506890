import { tokenGet, tokenPost, apiSettings, tokenPut } from '../../../utils/api/CoreApi';
import store from '../../store'
import { CALENDAR_ACTIONS as actions } from '../actionTypes'
import { notificationClose, notificationSuccess, notificationError } from '../Notification/notificationActions'
import moment from 'moment'
const queryActions = {}
const componentActions = {}
let { dispatch } = store
let { apiURLTickets } = apiSettings
queryActions.byMonth = async (index = 0) => {
    try {
        let { data, status } = await tokenGet(`${apiURLTickets}/api/tickets/maintenance-calendar?monthInterval=${index}`)

        dispatch({
            type: actions.FETCH_BY_MONTH,
            payload: {
                tickets: data,
                timeStamp: new Date(),
                status
            }
        })
    } catch (error) {
        console.error('calendarAction', error)
    }
}

queryActions.byDate = async (date) => {
    try {
        let { data, status } = await tokenGet(`${apiURLTickets}/api/tickets/maintenance-calendar-dt?pmPlanDt=${date}`)

        dispatch({
            type: actions.FETCH_BY_DATE,
            payload: {
                clicked_date: data,
                clicked_timeStamp: new Date(),
                status
            }
        })
    } catch (error) {
        console.error('calendarAction', error)
    }
}

queryActions.generateMachine = async (machines = [], date) => {
    console.log('machines', machines, date)
    try {

        let { data, status } = await tokenPost(`${apiURLTickets}/api/tickets/pm`, { machines })

        dispatch(notificationSuccess(machines?.length > 1 ? 'Successfully created ticket(s)': 'Successfully created ticket'))
        if(data) queryActions.byDate(moment(date).format('YYYY-MM-DD'))
        dispatch({
            type: actions.GENERATE_MACHINE,
            payload: {
                generated: data,
                generated_timeStamp: new Date(),
                status
            }
        })
    } catch (error) {
        let {errors} = error.response.data
        console.error('calendarAction', errors)
        if(errors?.length > 1) dispatch(notificationError('Some machines are not available for ticket generation'))
        else dispatch(notificationError(Object.entries(errors[0])[0][1]))
        queryActions.byDate(moment(date).format('YYYY-MM-DD'))
    }
}

export default () => {

    return {
        ...queryActions
    }
}
