import React from 'react';
import 'flag-icon-css/css/flag-icon.min.css';
import { connect } from "react-redux";
import { postLogout } from '../../redux/actions/Auth/authActions';
import { showModal, closeModal } from '../../redux/actions/Modal/modalActions';
import { getAllTicketsBySearch, getTicketDetail, ticketListShowToggle, ticketListCloseToggle, getMachineUpBySearch } from '../../redux/actions/Tickets/ticketActions';
import { machineListCloseToggle, machineListShowToggle, getMachineById, getMachines } from '../../redux/actions/Machine/machineActions'
import Grid from '@material-ui/core/Grid';
import { MenuItem, FormGroup, Select, Menu, Drawer, Box, List, ListItem, ClickAwayListener, Popper, Grow, Paper, MenuList, ListItemIcon, Avatar } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import history from '../../history';

import  getProductLTM  from '../../redux/actions/Dashboard/dashboardActions';
import { postLogin, postLoginNew } from '../../redux/actions/Auth/authActions';
import translations from '../../redux/actions/Translation/translationActions';
import { getFactoryAssigned } from '../../redux/actions/Factory/factoryActions';
import { getUserIdDefault, getFactoryId, getCompanyCode, getUsername } from '../../utils/helper';
import { globalAdminClient } from '../../utils/api/CoreApi';
import MenuIcon from '@material-ui/icons/Menu';
import { menuDrawerOpen } from '../../redux/actions/MenuDrawer/menuDrawerActions';
import AppsIcon from '@material-ui/icons/Apps';
import AppDrawerIcon from '../../assets/img/appdrawer.svg'
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LanguageLoading from '../Modal/LanguageLoading/LanguageLoading';
// import Avatar from 'react-avatar';
import './UserBlueHeader.scss';

const selectedLanguage = localStorage['selectedLanguage'];
const selectedFactory = localStorage['selectedFactory'];

const StyledMenu = withStyles({
    paper: {
        top: "48px !important",
        height: "auto",
        width: "184px",
        boxShadow: "0px 3px 6px #00000029",
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));

const CustomeAppbar = withStyles((theme) => ({
    root: {
        ...theme.appBar,
        backgroundColor: "#0f75bc",
        minHeight: "42px",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        appBarSpacer: theme.mixins.toolbar,
        transition: theme.transitions.create(['margin', 'width'], {
            // easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
}))(AppBar);

const CustomeToolbar = withStyles((theme) => ({
    regular: {
        height: "36px",
        minHeight: "32px",
        "@media(min-width:1024px)": {
            minHeight: "42px"
        },
        "@media(min-width:600px)": {
            minHeight: "42px"
        },
        "@media (min-width: 0px)": {
            minHeight: "42px"
        },
    },
}))(Toolbar);

const useStyles = theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        fontSize: "1.4rem",
        fontWeight: "500",
        color: "#fff"
    },
})

class UserBlueHeader extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showUserInfo: true,
            languageDropdown: false,
            selectedLanguage: localStorage['selectedLanguage'] || 'en',
            translationsCode: [...props.translation.availableCode], //en, km ,etc
            translationTimestamp: undefined,
            userId: null,
            factoryId: null,
            anchorEl: null,
            openedAppDrawer: false,
            appDrawerData: []
        }
    }
    async componentDidMount() {

        if(localStorage['selectedLanguage'] && localStorage['selectedFactory']){
            this.setState({
                selectedLanguage: localStorage['selectedLanguage'],
            });
            this.props.getTranslationsNew(
                localStorage['selectedLanguage'], 
                localStorage['selectedFactory'],
            );
        };
       
        let { location: { pathname } } = history
        
        if (pathname.match(/\/configuration\/machine-config\/machine-type/gi) || pathname.match(/\/configuration\/machine-config\/machine-sub-type/gi)) {
            //ignore
        }
        else {
            delete localStorage['collapseInstance']

        }
        this.props.getAvailableTranslation();

        let userId = getUserIdDefault();
        let factoryId = getFactoryId();
        if (userId && factoryId) {
            this.setState({ userId: userId, factoryId: factoryId }, () => {
                this.props.getFactoryAssigned();
            });
        }
        await this.props.getProductLTM();

    }

    componentWillReceiveProps() {
    }

    componentDidUpdate() {
        if (this.props.translation && this.props.translation.availableCode.length != this.state.translationsCode.length) {
            this.setState({
                translationsCode: this.props.translation.availableCode
            }, () => {
                const { translationsCode } = this.state;
                const existCode = translationsCode.some(({ code }) => code.toLowerCase() === selectedLanguage 
                    ? selectedLanguage.toLowerCase() 
                    : 'en'
                );
                if(!existCode){
                    this.setState({selectedLanguage: 'en'}, () => {
                        this.props.getTranslationsNew('en', selectedFactory);
                    });
                };
            });
        };
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
            })
        }
    }

    handleLanguageList = () => {
        this.setState({
            languageDropdown: !this.state.languageDropdown
        })
    }

    getTranslations = ({ target: { value } }) => {
        let factory = this.state.translationsCode[this.state.translationsCode.findIndex(x => x['code'].toLowerCase() == value)]['factory']
        this.setState({
            selectedLanguage: value
        })
        this.props.getTranslationsNew(value, factory, true)
    }

    renderFlag(code, country) {
        if (code === 'en') {
            return "flag-icon-us"
        }
        else {
            // let flagCode = country.toLowerCase();
            let flagCode = (country !== null && country.toLowerCase());
            return "flag-icon-" + flagCode;

        }
    }

    renderFactories() {
        if (this.props.factoryAssigned) {
            let factories = this.props.factoryAssigned.map((item) => {
                let itemRow = (
                    <MenuItem value={item.factoryId}>
                        {item.name}
                    </MenuItem>
                )
                return itemRow;
            });
            return factories;
        }
    }

    changeFactory = ({ target }) => {
        let payloadNew = {
            factoryId: target.value,
            accessToken: JSON.parse(localStorage.getItem('userToken'))
        }
        localStorage.setItem('changeFactory', 'true');
        localStorage.setItem('selectedFactoryId', target.value);
        this.props.postLoginNew(payloadNew);
    }

    renderLanguageChoices() {
        if (this.state.translationsCode) {
            console.log(this.state.translationsCode);
            let languages = this.state.translationsCode.map(row => {
                let code = row.code.toLowerCase();
                return (
                    <MenuItem key={code} value={code} >
                        <Grid container spacing={0}>
                            <Grid xs={2}>
                                <span className={"flag-dropdown flag-icon " + this.renderFlag(code, row.country)}></span>
                            </Grid>
                            <Grid xs={10}>
                                <span className="language-name" style={{ marginLeft: '10px' }}>{row.language}</span>
                            </Grid>
                        </Grid>
                    </MenuItem>
                )
            });
            return languages;
        }
        else {
            return null;
        }
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    toggleDrawer = (open) => {
     this.setState({
       ...this.state,
       openedAppDrawer: open
     });
   };


    renderAppsDrawer(){
      const { openedAppDrawer, appDrawerData } = this.state;
      console.log("appDrawerData", appDrawerData)
      return <Drawer
        anchor={'right'}
        open={openedAppDrawer}
        onClose={()=> this.toggleDrawer(false)}
      >
        <Box
          style={{ width: 100 }}
          onClick={()=> this.toggleDrawer(false)}
        >
          <List style={{ paddingTop: 0 }}>
            <ListItem
              button
              style={{backgroundColor: "#0f75bc", textAlign: "center"}}
              onClick={() => this.toggleDrawer(false)}
            >
              <img src={AppDrawerIcon} width="50%" style={{ margin: "0 auto" }}/>
            </ListItem>
            {
              appDrawerData.map((item)=>(
                <ListItem button key={item.product} onClick={() =>  window.open(item.product_url, '_blank').focus() } >
                  <Typography style={{ textAlign: "center", width: "100%" }} variant="body2">
                    <img src={item.iconpath} width="30px" height="30px" style={{ margin: "0 auto" }}/>
                    <Box textAlign="center">
                      {item.productDesc}
                    </Box>
                  </Typography>
                </ListItem>
              ))
            }
          </List>
       </Box>
      </Drawer>
    }

    openAppDrawer(){
      this.toggleDrawer(true)
      globalAdminClient.get("/app-drawer?app=ltm").then((response)=>{
        const { data } = response.data;
        this.setState({
          ...this.state,
          appDrawerData: data.products
        })
      })
    }

    renderAvatarIcon(){
      const { classes } = this.props;
      const { anchorEl } = this.state;
      const openAvatar = Boolean(anchorEl);
      const username = getUsername();

      return <>
        <IconButton onClick={(e)=> this.setState({ ...this.state, anchorEl: e.currentTarget })} className={classes.menuButton} color="inherit">
          <AccountCircleIcon fontSize="large" style={{ paddingRight: "5px" }} />
        </IconButton>
        <Popper open={openAvatar} anchorEl={anchorEl} style={{ zIndex: 99 }} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <ClickAwayListener onClickAway={()=> this.setState({ ...this.state, anchorEl: null })}>
                <Paper>
                  <MenuList autoFocusItem={openAvatar}>
                    <MenuItem>
                      <Avatar>
                        {username.charAt(0).toUpperCase()}
                      </Avatar>
                      <span style={{padding: 10}}>
                        {username}
                      </span>
                    </MenuItem>
                    <MenuItem onClick={()=> this.props.postLogout({ companyCode: getCompanyCode() })}>
                      <ListItemIcon>
                        <ExitToAppIcon fontSize="large"/>
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                    <MenuItem onClick={this.props.postLogout}>
                      <ListItemIcon>
                        <ExitToAppIcon fontSize="large"/>
                      </ListItemIcon>
                      Sign Out Company
                    </MenuItem>
                  </MenuList>
                </Paper>
              </ClickAwayListener>
            </Grow>
          )}
        </Popper>
      </>
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <CustomeAppbar position="static">
                    <CustomeToolbar>

                        {this.props.showUserInfo === false ?
                            <AppsIcon fontSize="large" style={{ marginRight: "10px" }} />
                            :
                            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                                <MenuIcon style={{ fontSize: 40 }} onClick={this.props.menuDrawerOpen} />
                            </IconButton>
                        }

                        <Typography variant="h6" className={classes.title}>
                            LTLABS MECHANIC
                        </Typography>

                        {this.props.showUserInfo === false ? '' :
                            <React.Fragment>
                                <Tooltip title="Company">
                                    <div className="factories" style={{ paddingRight: "5px" }}>
                                        <FormGroup fullWidth>
                                            <Select
                                                disabled={this.props.isLoginRequest}
                                                value={this.state.factoryId}
                                                onChange={this.changeFactory}
                                            >
                                                {this.renderFactories()}
                                            </Select>
                                        </FormGroup>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Translation">
                                    <div className="language" style={{ paddingRight: "5px" }}>
                                        <FormGroup fullWidth>
                                            <Select
                                                labelId="demo-controlled-open-select-label"
                                                id="demo-controlled-open-select"
                                                open={this.state.languageDropdown}
                                                onClose={this.handleLanguageList}
                                                onOpen={this.handleLanguageList}
                                                value={this.state.selectedLanguage}
                                                onChange={this.getTranslations}
                                            >
                                                {this.renderLanguageChoices()}
                                            </Select>
                                        </FormGroup>
                                    </div>
                                </Tooltip>

                                { this.renderAvatarIcon() }
                                <Typography style={{ paddingRight: "5px", fontSize: "1.4rem" }}>{this.props.username}</Typography>
                                <IconButton  onClick={()=> this.openAppDrawer()} className={classes.menuButton} color="inherit" aria-label="menu">
                                  <img src={AppDrawerIcon}/>
                                </IconButton>
                            </React.Fragment>
                        }
                    </CustomeToolbar>
                </CustomeAppbar>
                <LanguageLoading />
                {this.renderAppsDrawer()}
            </div>
        );
    }
}



const mapStateToProps = state => {
    let { translationReducer, authReducer } = state;
    let apiLoading = {
        isLoginRequest: authReducer.loading,
    };
    let data = {
        username: authReducer.username,
        userId: authReducer.userId,
        machineSelectedRow: state.machineListReducer.selectedRow,
        machineList: state.machineListReducer.machines,
        tickets: state.ticketListReducer.tickets,
        ticketSelectedRow: state.ticketListReducer.selectedRow,
        ticketSearch: state.ticketListReducer.searchString,
        machineUpFilter: state.ticketListReducer.machineUpFilter,
        factoryAssigned: state.factoryListReducer.factoryAssigned,
    };

    return {
        ...apiLoading,
        ...data,
        ...translationReducer

    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProductLTM:() => getProductLTM(),
        menuDrawerOpen: () => dispatch(menuDrawerOpen()),
        postLogin: (payload) => { dispatch(postLogin(payload)) },
        postLoginNew: (payload) => { dispatch(postLoginNew(payload)) },
        postLogout: (payload={}) => { dispatch(postLogout(payload)) },
        getFactoryAssigned: () => dispatch(getFactoryAssigned()),
        showModal: (modal) => dispatch(showModal(modal)),
        closeModal: () => dispatch(closeModal()),
        getFilteredMachines: (statusId, searchString) => dispatch(getMachines(1, 1, 1, 10000, statusId, searchString)),
        getMachineById: (id) => { dispatch(getMachineById(id)) },
        getTicketDetail: (id) => { dispatch(getTicketDetail(id)) },
        machineListShowToggle: (id, flag) => { dispatch(machineListShowToggle(id, flag)) },
        machineListCloseToggle: () => { dispatch(machineListCloseToggle()) },
        ticketListShowToggle: (id, flag) => { dispatch(ticketListShowToggle(id, flag)) },
        ticketListCloseToggle: (id, flag) => { dispatch(ticketListCloseToggle(id, flag)) },
        getAllTicketsBySearch: (pageSize, currentPage, searchString, reportedDt, ticketType, status) => { dispatch(getAllTicketsBySearch(pageSize, currentPage, searchString, reportedDt, ticketType, status)) },
        getMachineUpBySearch: (pageSize, currentPage, searchString, pmPlanDt) => dispatch(getMachineUpBySearch(pageSize, currentPage, searchString, pmPlanDt)),
        ...translations()
    }
}

UserBlueHeader = connect(mapStateToProps, mapDispatchToProps)(UserBlueHeader);

export default withStyles(useStyles, { withTheme: true })(UserBlueHeader);
