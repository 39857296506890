import React from 'react';
import { 
    TableBody as Body, 
    TableRow, 
    Typography, 
} from '@material-ui/core';
import {
    BlackTooltip,
    Col,
    ProblemBox,
    ProblemBoxContainer,
    ProblemBoxItem,
} from '../styles';
import LineNPTTooltip from '../../components/Tooltips/LineNPTTooltip';
import { numberWithCommas } from '../../../../../utils/helper';

export default function TableBody(props) {
    const { data, dateRange } = props;
    const { startDate, endDate } = dateRange;
    const lastRow = (i, length) => i + 1 === length;
    return (
        <Body>
            {
                data.map((line, i) => (
                    <TableRow>
                        <Col fontWeight={lastRow(i, data.length) ? 'bold' : ''}>
                            {line.lineName.translate() || 'N/A'.translate()}
                            {lastRow(i, data.length) && `: ${numberWithCommas(line.countLines)}`}
                        </Col>
                        <Col align='right'>{numberWithCommas(line.countChangeoverTickets)}</Col>
                        <Col align='right'>{numberWithCommas(line.countRepairTickets)}</Col>
                        <Col>
                            <ProblemBoxContainer>
                                {line.problemTypes.map((problem, key) => key <= 2 && (
                                    <ProblemBox>
                                        <ProblemBoxItem
                                            fontWeight="bold"
                                            color={props.colors[key]}
                                            align="center">
                                            {numberWithCommas(problem.count)}
                                        </ProblemBoxItem>
                                        <BlackTooltip placement='left' background="rgba(0, 0 , 0, .8)" title={
                                            <LineNPTTooltip
                                                index={key} 
                                                line={line}
                                                problem={problem}
                                                startDate={startDate}
                                                endDate={endDate}
                                                color={props.colors[key]} />
                                            } arrow>
                                            <ProblemBoxItem color={props.colors[key]}>
                                                <span>{problem.problemType || 'N/A'.translate()}</span>
                                            </ProblemBoxItem>
                                        </BlackTooltip>
                                    </ProblemBox>
                                ))}
                            </ProblemBoxContainer>
                        </Col>
                        <Col align='right' fontWeight={lastRow(i, data.length) ? 'bold' : ''}>
                            {numberWithCommas(line.totalNptTime ? line.totalNptTime.toFixed(2) : 0)}
                        </Col>
                    </TableRow>
                ))
            }
        </Body>
    )
}