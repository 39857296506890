import { makeStyles, createStyles, Theme, createTheme } from "@material-ui/core";

export const styles = makeStyles((Theme) =>
  createStyles({
    mainContainer: {
      [`& span[class*="MuiStepLabel-root"]`]: {
        display: "flex",
        flexDirection: "column",
      },
      [`& span[class*="MuiStepLabel-labelContainer"]`]: {
        marginTop: "10px",
      },
      [`& div[class*="MuiStepConnector-root"]`]: {
        marginTop: "-6%",
      },
    },
    textStyl: {
      fontSize: "20px",
      color: "#515151",
      marginTop: "20px",
    },
    paper: {
      position: "absolute",
      width: "44.44%",
      backgroundColor: Theme.palette.background.paper,
      boxShadow: Theme.shadows[5],
      padding: Theme.spacing(0, 4, 3),
    },
    saveContainer: {
      backgroundColor: "white",
      padding: "10px 0",
      display: "flex",
      justifyContent: "center",
      marginTop: "10px",
    },
    cancelButton: {
      marginBottom: "30px",
      minWidth: "100px",
      textAlign: "center",
      font: "normal normal medium 16px/20px Roboto",
      letterSpacing: "0.06px",
      color: "#515151",
      opacity: 1,
    },
    saveButton: {
      marginBottom: "30px",
      minWidth: "100px",
      textAlign: "center",
      font: "normal normal medium 16px/20px Roboto",
      letterSpacing: "0.06px",
      backgroundColor: "#2D96EF",
      color: "#FFFFFF",
      opacity: 1,
      "& .MuiCircularProgress-root": {
        height: "unset",
        width: "unset",
      },
      "&:hover": {
        backgroundColor: "#1e88e5"
      }
    },
    root: {
      width: "100%",
    },
    button: {
      marginRight: Theme.spacing(1),
    },
    instructions: {
      marginTop: Theme.spacing(1),
      marginBottom: Theme.spacing(1),
    },
    downloadText: {
      font: "normal normal bold 14px/12px Roboto",
      letterSpacing: "0px",
      cursor: "pointer",
      width: "fit-content",
      color: "#2D96EF",
      textTransform: "uppercase",
      opacity: 1,
      marginBottom: "14px",
      "&>span": {
        "& .MuiSvgIcon-root": {
          marginLeft: "6px",
          width: "11px",
          height: "11px",
        },
      },
      "& a": {
        textDecoration: "none",
        color: "#2D96EF",
      },
    },
    downloading: {
      color: '#808080',
      marginLeft: '20px',
      textTransform: 'none',
    },
    styleImageTitle: {
      marginBottom: "10px",
      marginTop: "25px",
      textAlign: "left",
      font: "normal normal normal 14px/21px Roboto",
      letterSpacing: "0.08px",
      color: "#000000",
      opacity: 1,
    },
    dropDescription: {
      textAlign: "center",
      font: "normal normal bold 16px/28px Roboto",
      letterSpacing: "0px",
      color: "#515151",
      opacity: 1,
    },
    orBox: {
      textAlign: "center",
      font: "normal normal normal 14px/28px Roboto",
      letterSpacing: "0px",
      color: "#515151",
      opacity: 1,
      marginBottom: "6px",
    },
    imageNote: {
      marginTop: "10px",
      textAlign: "center",
      font: "normal normal normal 14px/28px Roboto",
      letterSpacing: "0px",
      color: "#515151",
      opacity: 1,
    },
    dropIcon: {},
    browseButton: {
      margin: "0 15px",
      width: "55%",
      textAlign: "center",
      font: "normal normal medium 16px/20px Roboto",
      letterSpacing: "0.06px",
      backgroundColor: "#2D96EF",
      color: "#FFFFFF",
      height: "36px",
      textTransform: "none",
      "&:hover": {
          backgroundColor: "#1e88e5"
      }
    },
    reselectFile: {
      font: "normal normal bold 16px/14px Roboto",
      letterSpacing: "0px",
      color: "#2D96EF",
      textTransform: "uppercase",
      marginTop: "24px",
    },
    dropImagesContainer: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      border: "2px dashed rgb(112, 112, 112, 0.6)",
      borderRadius: "12px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "4.6% 8%",
    },
    filenameUpdated: {
      marginTop: '20px',
      textAlign: "center",
      font: "normal normal normal 14px/21px Roboto",
      letterSpacing: "0.08px",
      color: "#000000",
      opacity: 1,
    },
    listUpdated: {
      marginBottom: "60px",
      textAlign: "center",
      font: "normal normal normal 14px/21px Roboto",
      letterSpacing: "0.08px",
      color: "#000000",
      opacity: 1,
    },
    fileName: {
      textAlign: "center",
      font: "normal normal normal 14px/21px Roboto",
      letterSpacing: "0.08px",
      color: "#000000",
      maxWidth: "55%",
      margin: "auto",
      marginTop: "10px",
    },
    validateMessage: {
      textAlign: "center",
      margin: "auto",
      marginBottom: "34px",
      maxWidth: "70%",
      font: "normal normal normal 14px/21px Roboto",
      letterSpacing: "0.08px",
      "&>div": {
        color: "#AA1919",
      },
    },
    pleaseConfirmText: {
      textAlign: "center",
      margin: "auto",
      maxWidth: "55%",
      font: "normal normal normal 14px/21px Roboto",
      letterSpacing: "0.08px",
    },
    fileStyle: {
      font: "normal normal normal 14px/21px Roboto",
      letterSpacing: "0.08px",
      color: "#000000",
    },
    apiValidateMessage: {
      textAlign: "center",
      margin: "auto",
      marginTop: "24px",
      maxWidth: "55%",
      font: "normal normal normal 14px/21px Roboto",
      letterSpacing: "0.08px",
      color: "#AA1919",
    },
  })
);

export const stepper = makeStyles(() => ({
  root: {
    "& .MuiStepIcon-active": { color: "#2D96EF" },
  }
}));
