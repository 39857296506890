import {useCallback, useState} from 'react'
import { getFactoryId, getCompanyCode } from '../../../utils/helper'
import { tokenGet, tokenPost, apiSettings, tokenPut, getToken } from '../../../utils/api/CoreApi';

export const getFactoryLanguage = async () => {
	let { apiURLAuthGlobalAdmin } = apiSettings
	let productCode = 'LTm'
	let resData = {};
	const params = `?companyCode=${getCompanyCode()}&factoryId=${getFactoryId()}`;
	resData = await tokenGet(`${apiURLAuthGlobalAdmin}/languages${params}`)
	const { data } = resData.data;
	const languages = data.filter((language) => language.language !== 'en' )
	return languages && languages.map((language) => ({
		id: language.id,
		desc1: language.desc2,
		language: language.language,
		translatedDesc: '',
	}));
}

export const GetGoogleTranslation = () => {
	let { apiURLFiles, apiURLAuthGlobalAdmin } = apiSettings
	const [isAwait, setIsAwait] = useState(false);
	const [results, setResults] = useState({ status: false, message: '',result: '', index: 0 });
	const fetchingData = useCallback (( query, target, key ) => {
		if(query){
			const url = `${apiURLAuthGlobalAdmin}/translationapi?query=${query}&target=${target}`;
			const userToken = getToken();
			const requestOptions ={ 
					method: 'GET',
					headers: { 
							Authorization: `Bearer ${userToken}`, 
							'X-Requested-With': 'XMLHttpRequest',
							'language': localStorage.getItem('selectedLanguage'),
					}
			};
			fetch(url, requestOptions)
			.then((res) => res.json())
			.then((json) => {
					const {text} = json;
					setResults({
							status: true, 
							message: 'Successful Translated.', 
							result: text ? text : '', 
							index: key,
					});
			}).catch((error)=> console.error(error)).finally(()=> setIsAwait(false));
			return;
		}
		setResults({ status: false, message: 'Invalid value.', result: '', index: key });
		setIsAwait(false);
	},[]);
	return [results, fetchingData, isAwait, setIsAwait];
}