import React from 'react';
import Cropper from 'react-easy-crop';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { closeModal } from '../../../redux/actions/Modal/modalActions'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { validateFields } from './FormValidation';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { FormControl, Select, MenuItem, InputAdornment, IconButton, Input, InputLabel } from '@material-ui/core';
import { uploadFactoryProfile } from '../../../redux/actions/Factory/factoryActions';
import { userChangeProfile } from '../../../redux/actions/User/userActions';
import { putChangePassword } from '../../../redux/actions/Auth/authActions';
import { notificationError } from '../../../redux/actions/Notification/notificationActions';
import dictionary from '../../../redux/actions/Translation/dictionary'
import './ChangePassword.scss';
import { Hidden } from '@material-ui/core';

class ChangePassword extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      translations: {
        ...this.convert()
      },
      translationTimestamp: undefined,
      modalName: 'changePassword',
      modalSettings: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none !important'
      },
      paperSettings: {
        position: 'relative',
        outline: 'none !important',
        backgroundColor: '#fff',
        boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.3)',
        padding: '2px 10px 3px 10px',
        width: '400px',
        overflow: 'hidden',
      },
      form: {
        password: null,
        confirmPassword: null,
      },
      formErrors: [],
      passwordShown: false,
      confirmPasswordShown: false,
    }
  }

  convert = () => {
    let { SAVE, CANCEL, Change_Password,Confirm_Password,Password} = dictionary.GET()
    return dictionary.TRANSLATE({ SAVE, CANCEL, Change_Password ,Confirm_Password,Password})
  }
  componentDidMount() {
  }

  componentDidUpdate() {
    if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
      this.setState({
        translationTimestamp: this.props.translation.translations.timeStamp,
        translations: Object.assign({}, { ...this.convert() }),
      })
    }
  }
  submitForm = () => {
    let payload = {
      ...this.state.form,
      id: this.props.id
    };
    let formValidation = validateFields(payload);
    this.setState({ formErrors: formValidation.errors });
    if (formValidation.valid) {
      this.props.putChangePassword(payload);
      this.handleClose();
    }
    else {
      this.props.notificationError(formValidation.errors[0].message);
    }
  }

  handleTogglePassword = () => {
    this.setState({ passwordShown: !this.state.passwordShown });
  }

  handleToggleConfirmPassword = () => {
    this.setState({ confirmPasswordShown: !this.state.confirmPasswordShown });
  }



  removeErrorOnChange(field) {
    let errors = this.state.formErrors.filter((item) => { return item.name !== field });
    this.setState({ formErrors: errors });
  }

  hasErrors = (field) => {
    let checkField = this.state.formErrors.filter((item) => { return item.name === field });
    if (checkField.length > 0) {
      return true
    }
    else {
      return false;
    }
  }

  getErrors = (field) => {
    let checkField = this.state.formErrors.filter((item) => { return item.name === field });
    let errorString = null;
    checkField.map((item) => {
      errorString += item.message + '. ';
      return '';
    })
    return errorString;
  }

  handleClose = () => {
    let tmpForm = {
      password: null,
      confirmPassword: null
    }
    this.setState({ form: tmpForm }, () => {
      this.props.closeModal();
    })
  }

  handleFormChange = ({ target }) => {
    let tmpForm = this.state.form;
    tmpForm[target.name] = target.value;
    this.setState({ form: tmpForm, hasFormChanges: true });
    this.removeErrorOnChange(target.name);
  };

  render() {
    let {translations} = this.state
    return (
      <div>
        <Modal
          open={this.props.modalShown === this.state.modalName}
          onClose={this.props.closeModal}
          style={this.state.modalSettings}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade style={this.state.paperSettings} in={this.props.modalShown === this.state.modalName}>
            <div className='change-password-modal'>
              <div onClick={this.props.closeModal} className="close">
                <CloseIcon />
              </div>
              <div className="title">{translations.Change_Password}</div>
              <div className="password-form">
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <FormControl className="control-row" fullWidth>
                      <InputLabel required className={this.state.form.password === this.state.form.confirmPassword && this.state.form.confirmPassword !== null ? 'label-green' : null} shrink htmlFor="password">{translations.Password}</InputLabel>
                      <Input id="password" type={this.state.passwordShown ? 'text' : 'password'} required error={this.hasErrors('password')} value={this.state.form.password} onChange={this.handleFormChange} name="password" label="Password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleTogglePassword}
                            >
                              {this.state.passwordShown ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl className="control-row" fullWidth>
                      <InputLabel required className={this.state.form.password === this.state.form.confirmPassword && this.state.form.confirmPassword !== null ? 'label-green' : null} shrink htmlFor="confirmPassword">{translations.Confirm_Password}</InputLabel>
                      <Input id="confirmPassword" type={this.state.confirmPasswordShown ? 'text' : 'password'} required error={this.hasErrors('confirmPassword')} value={this.state.form.confirmPassword} onChange={this.handleFormChange} name="confirmPassword" label="Confirm Password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleToggleConfirmPassword}
                            >
                              {this.state.confirmPasswordShown ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
              <div className="button-group">
                <Grid container spacing={1}>
                  <Grid item xs={3} />
                  <Grid item xs={3}>
                    <Button className="cancel" onClick={this.handleClose} fullWidth variant="outlined">{translations.CANCEL}</Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button className="save" onClick={this.submitForm} fullWidth variant="contained" color="primary">{translations.SAVE}</Button>
                  </Grid>
                  <Grid item xs={3} />
                </Grid>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let { translationReducer } = state

  let apiLoading = {
  }
  let data = {
    modalShown: state.modalReducer.modalShown,
    factoryProfile: state.factoryListReducer.factoryProfile,
  }

  return {
    ...apiLoading,
    ...data,
    ...translationReducer
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(closeModal()),
    notificationError: (message) => dispatch(notificationError(message)),
    uploadFactoryProfile: (id, payload, file) => dispatch(uploadFactoryProfile(id, payload, file)),
    userChangeProfile: (image) => dispatch(userChangeProfile(image)),
    putChangePassword: (payload) => dispatch(putChangePassword(payload)),
  }
}

ChangePassword = connect(mapStateToProps, mapDispatchToProps)(ChangePassword);

export default ChangePassword;
