import {
    NOTIFICATION_SUCCESS,
    NOTIFICATION_ERROR,
    ROLE_LIST,
    ROLE_LIST_REQUEST,
    ROLE_LIST_SUCCESS,
    ROLE_LIST_ERROR,
    ROLE_TREE_VIEW_REQUEST,
    ROLE_TREE_VIEW_SUCCESS,
    ROLE_TREE_VIEW_ERROR,
    ROLE_DETAIL_REQUEST,
    ROLE_DETAIL_SUCCESS,
    ROLE_DETAIL_ERROR,
    ROLE_NEW_REQUEST,
    ROLE_NEW_SUCCESS,
    ROLE_NEW_ERROR,
    ROLE_UPDATE_REQUEST,
    ROLE_UPDATE_SUCCESS,
    ROLE_UPDATE_ERROR,
    ROLE_DELETE_REQUEST,
    ROLE_DELETE_SUCCESS,
    ROLE_DELETE_ERROR,
    ROLE_TRIGGER_HIGHLIGHT,
    ROLE_LIST_FACTORIES,
} from '../actionTypes';
import { tokenGet, tokenPost, tokenPut, tokenDelete, apiSettings } from '../../../utils/api/CoreApi';
import history from '../../../history';

export const notificationSuccess = (message) => {
    return {
        type: NOTIFICATION_SUCCESS,
        payload: {
            message: message.translate()
        }
    }
}

export const notificationError = (message) => {
    return {
        type: NOTIFICATION_ERROR,
        payload: {
            message: message.translate()
        }
    }
}

export const roleRequest = () => {
    return {
        type: ROLE_LIST_REQUEST
    }
}

export const roleSuccess = roleList => {
    return {
        type: ROLE_LIST_SUCCESS,
        payload: roleList
    }
}

export const roleTreeViewRequest = () => {
    return {
        type: ROLE_TREE_VIEW_REQUEST
    }
}

export const roleTreeViewSuccess = roleTreeViewList => {
    return {
        type: ROLE_TREE_VIEW_SUCCESS,
        payload: roleTreeViewList
    }
}

export const roleDetailRequest = () => {
    return {
        type: ROLE_DETAIL_REQUEST
    }
}

export const roleDetailSuccess = roleDetail => {
    return {
        type: ROLE_DETAIL_SUCCESS,
        payload: roleDetail
    }
}

export const roleNewRequest = () => {
    return {
        type: ROLE_NEW_REQUEST
    }
}

export const roleNewSuccess = role => {
    return {
        type: ROLE_NEW_SUCCESS,
        payload: role
    }
}

export const roleUpdateRequest = () => {
    return {
        type: ROLE_UPDATE_REQUEST
    }
}

export const roleUpdateSuccess = role => {
    return {
        type: ROLE_UPDATE_SUCCESS,
        payload: role
    }
}

export const roleDeleteRequest = () => {
    return {
        type: ROLE_DELETE_REQUEST
    }
}

export const roleDeleteSuccess = role => {
    return {
        type: ROLE_DELETE_SUCCESS,
        payload: role
    }
}

export const roleTriggerHighlight = flag => {
    return {
        type: ROLE_TRIGGER_HIGHLIGHT,
        payload: flag
    }
}

export const getRolesTreeView = (isActive = 'all', currentPage = 1, pageSize = 100000) => {
    return function (dispatch) {
        dispatch(roleTreeViewRequest());
        tokenGet(apiSettings.apiURLReferences + '/roles/pages?isActive=' + isActive + '&currentPage=' + currentPage + '&pageSize=' + pageSize).then((response) => {
            console.log('datas', response.data.result.result)
            let unfiltered = response.data.result.result
            let data = []
            let sanitizeSpace = (string) => {
                return string.replace(/\s/gi, '')
            }
            let guide = (access) => {
                return {
                    'FULL ACCESS': 1,
                    'NONE': 0,
                    'EDIT': 2,
                    'VIEW': 2,
                }[access]
            }
            unfiltered.forEach((x, index) => {
                let parent = 'PageCategory'
                let child = 'Page'
                const isIndex = (toFind, from, key) => {
                    let index = from.findIndex(x => x?.['details']?.[key] == toFind)
                    return index
                }
                if (x?.[parent] != undefined && unfiltered?.[index++]?.[parent] != undefined) {
                    if (isIndex(x[parent], data, 'pageCategory') == -1) {
                        data.push({
                            details: {
                                pageCategory: x[parent]
                            },
                            roles: [
                                {
                                    pageCategory: x[child],
                                    tableHeader: [x['Role']],
                                    [sanitizeSpace(x['Role'])]: guide(x['Access']),
                                    // access: x['Access'],
                                    access: 'VIEW',
                                    pageId: x['PageId'],
                                    roleId: [x['roleId']]
                                }
                            ]
                        })
                    }
                    else {
                        // console.table({ child: x[child], role: x['Role'], hasAccess: guide(x['Access']), currentDataLength: data?.length })
                        let { roles = undefined } = data[data?.length -1]
                        if (roles != undefined) {
                            let didFindChild = roles.findIndex(childInstance => {
                                return childInstance['pageCategory'] == x[child]
                            })
                            if (didFindChild == -1) {
                                roles.push({
                                    pageCategory: x[child],
                                    tableHeader: [x['Role']],
                                    // access: x['Access'],
                                    access: 'VIEW',
                                    [sanitizeSpace(x['Role'])]: guide(x['Access']),
                                    pageId: x['PageId'],
                                    roleId: [x['roleId']]
                                })
                            }
                            else{
                                let {tableHeader : roleChild = undefined, roleId} = roles[roles?.length -1]
                                let lastRoles = roles[roles?.length -1]
                                let didFindRolesChild = roleChild.findIndex(rolesChildInstance => rolesChildInstance == x['Role'])
                                if(didFindRolesChild == -1){
                                    roleChild.push(x['Role'])
                                    roleId.push(x['roleId'])
                                    lastRoles = Object.assign({}, {...lastRoles}, {[sanitizeSpace(x['Role'])]: guide(x['Access'])})
                                    roles[roles?.length -1] = lastRoles
                                }

                            }
                        }


                    }

                }

            })
            console.log('after push', data)
            dispatch(roleTreeViewSuccess({
                roleListTreeView: data,
                roleListTreeViewTimeStamp: new Date()
            }));
        })
            .catch((error) => {
                console.log(error);
            });
    }
}

export const getRoles = (filtered = true, isActive = 'all', currentPage = 1, pageSize = 100000) => {
    localStorage['filtered'] = filtered
    return function (dispatch) {
        dispatch(roleRequest());
        tokenGet(apiSettings.apiURLReferences + '/roles?isActive=' + isActive + '&currentPage=' + currentPage + '&pageSize=' + pageSize).then((response) => {
            let data
            if (localStorage['filtered'] == true) {
                data = response.data.result.map((item) => {
                    let itemRow = {
                        'id': item.id,
                        'role': item.role,
                        'desc1': item.desc1,
                        'isActive': item.isActive,
                    }
                    return itemRow;
                })
            }
            else data = response.data.result
            delete localStorage['filtered']
            dispatch(roleSuccess({
                roleList: data,
            }));
        })
            .catch((error) => {
                console.log(error);
            });
    }
}

export const getRoleList = (isActive= 'all') => {
    return function(dispatch) {
        tokenGet(apiSettings.apiURLReferences + `/roles?isActive=${isActive}`).then((response) => {
            let data = response.data.result
            dispatch({
                type: ROLE_LIST,
                payload: {
                    roleListData: data,
                    // roleListDataTimeStamp: new Date()
                }
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}
export const getRoleDetail = (id) => {
    return function (dispatch) {
        dispatch(roleDetailRequest());
        tokenGet(apiSettings.apiURLReferences + '/roles/' + id).then((response) => {
            let roleDetail = response.data.details[0];
            // let roleDetailData = {
            //     role: roleDetail.role,
            //     desc: roleDetail.desc1,
            //     isActive: roleDetail.isActive,
            // }
            dispatch(roleDetailSuccess({
                roleDetail: roleDetail
            }));

            console.log('datas', response.data.access)
            let unfiltered = response.data.access
            let data = []
            let sanitizeSpace = (string) => {
                return string.replace(/\s/gi, '')
            }
            let guide = (access) => {
                return {
                    'FULL ACCESS': 1,
                    'NONE': 0,
                    'EDIT': 2,
                    'VIEW': 2,
                }[access]
            }
            unfiltered.forEach((x, index) => {
                let parent = 'PageCategory'
                let child = 'Page'
                const isIndex = (toFind, from, key) => {
                    let index = from.findIndex(x => x?.['details']?.[key] == toFind)
                    return index
                }
                if (x?.[parent] != undefined && unfiltered?.[index++]?.[parent] != undefined) {
                    if (isIndex(x[parent], data, 'pageCategory') == -1) {
                        data.push({
                            details: {
                                pageCategory: x[parent]
                            },
                            roles: [
                                {
                                    pageCategory: x[child],
                                    tableHeader: [x['Role']],
                                    [sanitizeSpace(x['Role'])]: guide(x['Access']),
                                    access: x['Access'],
                                    pageId: x['PageId'],
                                    roleId: [x['roleId']]
                                }
                            ]
                        })
                    }
                    else {
                        // console.table({ child: x[child], role: x['Role'], hasAccess: guide(x['Access']), currentDataLength: data?.length })
                        let { roles = undefined } = data[data?.length -1]
                        if (roles != undefined) {
                            let didFindChild = roles.findIndex(childInstance => {
                                return childInstance['pageCategory'] == x[child]
                            })
                            if (didFindChild == -1) {
                                roles.push({
                                    pageCategory: x[child],
                                    tableHeader: [x['Role']],
                                    access: x['Access'],
                                    [sanitizeSpace(x['Role'])]: guide(x['Access']),
                                    pageId: x['PageId'],
                                    roleId: [x['roleId']]
                                })
                            }
                            else{
                                let {tableHeader : roleChild = undefined, roleId} = roles[roles?.length -1]
                                let lastRoles = roles[roles?.length -1]
                                let didFindRolesChild = roleChild.findIndex(rolesChildInstance => rolesChildInstance == x['Role'])
                                if(didFindRolesChild == -1){
                                    roleChild.push(x['Role'])
                                    roleId.push(x['roleId'])
                                    lastRoles = Object.assign({}, {...lastRoles}, {[sanitizeSpace(x['Role'])]: guide(x['Access'])})
                                    roles[roles?.length -1] = lastRoles
                                }

                            }
                        }


                    }

                }

            })
            console.log('after push', data)
            dispatch(roleTreeViewSuccess({
                roleListTreeView: data,
                roleListTreeViewTimeStamp: new Date()
            }));
        })
            .catch((error) => {
                console.log(error);
            });
    }
}

export const postNewRole = (payload) => {
    return function (dispatch) {
        dispatch(roleNewRequest());
        tokenPost(apiSettings.apiURLReferences + "/roles", payload).then((response) => {
            dispatch(getRoleList());
            // dispatch(getRolesTreeView());
            dispatch(notificationSuccess('Successfully added role'));
            history.push('/configuration/user-manage/roles');
        })
        .catch((error) => {
            if (error.message.indexOf(500) >= 0) {
                dispatch(notificationError('Role name exists'));
                dispatch(roleTriggerHighlight(true));
            }
            console.log(error);
        });
    }
}

export const putUpdateRole = (id, payload) => {
    return function (dispatch) {
        dispatch(roleUpdateRequest());
        tokenPut(apiSettings.apiURLReferences + "/roles/" + id, payload).then((response) => {
            dispatch(getRoleList());
            dispatch(getRoleDetail(id))
            dispatch(notificationSuccess('Successfully updated role'));
        })
            .catch((error) => {
                if (error.message.indexOf(422) >= 0) {
                    dispatch(notificationError("Cannot deactivate. Role is already in use by Users"));
                    dispatch(roleTriggerHighlight(true));
                }
                else if (error.message.indexOf(500) >= 0) {
                    dispatch(notificationError('Role name exists'));
                    dispatch(roleTriggerHighlight(true));
                }
            });
    }
}

export const deleteRole = (id) => {
    return function (dispatch) {
        dispatch(roleDeleteRequest());
        tokenDelete(apiSettings.apiURLReferences + "/roles/" + id).then((response) => {
            dispatch(getRoles(false));
            dispatch(notificationSuccess('Successfully deleted role'));
        })
            .catch((error) => {
                if (error.message.indexOf(500) >= 0) {
                    dispatch(notificationError('Cannot delete because it is currently referenced to another record'));
                }
            });
    }
}