import React, { useForm, useEffect } from 'react';
import moment from 'moment';
import history from '../../../../history'
import { validateFields } from './FormValidation';
import { checkIfApiLoading, checkPageActionAccess,checkPageAccess, getFactoryId } from '../../../../utils/helper';
import MainPage from '../../../../components/MainPage/MainPage';
import MainPageHeader from '../../../../components/MainPageHeader/MainPageHeader';
import MainPageBody from '../../../../components/MainPageBody/MainPageBody';
import TemplateControl from '../../../../components/TemplateControl/TemplateControl';
import { connect } from "react-redux";
import { inputFocusSuccess } from '../../../../redux/actions/Input/inputActions';
import { notificationError } from '../../../../redux/actions/Notification/notificationActions';
import { postMachineNewItem, putUpdateMachine, getTemplates, getMachineById } from '../../../../redux/actions/Machine/machineActions';
import { getConditions } from '../../../../redux/actions/Condition/conditionActions';
import { getMachineStatus, getStatus, getMachineStatusv2 } from '../../../../redux/actions/Status/StatusActions'
import { getMacTypes } from '../../../../redux/actions/MacTypes/MacTypeActions';
import { getMacSubTypes, getAllMacSubTypes } from '../../../../redux/actions/MacSubTypes/MacSubTypeActions';
import { getBrands } from '../../../../redux/actions/Brand/brandActions';
import { getMotorTypes } from '../../../../redux/actions/MotorTypes/motorTypeActions';
import { getSuppliers } from '../../../../redux/actions/Supplier/supplierActions';
import { getFrequencies } from '../../../../redux/actions/Frequency/frequencyActions';
import { getAreas } from '../../../../redux/actions/Area/areaActions';
import { getBuildings } from '../../../../redux/actions/Building/buildingActions';
import { getAttachments } from '../../../../redux/actions/Attachment/attachmentActions';
import { getSystemPreferenceReference } from '../../../../redux/actions/Preference/preferenceActions'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import DateMomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider,KeyboardDatePicker} from '@material-ui/pickers';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import MaintenanceTemplate from './Templates/MaintenanceTemplate';
import dictionary from '../../../../redux/actions/Translation/dictionary'
import './MachineForm.scss';
import { formatDate } from '../../../../utils/date';

class MachineForm extends React.Component {
  constructor(props){
      super(props);
      this.refMachineNo = React.createRef();
      this.refAssetNo = React.createRef();
      this.refSerialNo = React.createRef();
      this.refRFID = React.createRef();
      this.lastPMDt = React.createRef();
      this.state = {
        id: null,
        isLineReq: null,
        loadedMachineDetails: false,
        selectedMacType: false,
        refValidationError: {},
        machineDetail:[],
        statusListByLocationAndCondition: [],
        conditionListByLocation: [],
        machineChecklist:[],
        macSubTypeList:[],
        allMacSubTypeList:[],
        brandList:[],
        templates:[],
        form: {
          /* CONSTANT factoryid, module id and mfg line id, to be replaced */
          factoryId:getFactoryId(),
          moduleId:1,
          mfgLineId:null,
          /* ------------------------------------------------------------- */
          areaId:null,
          buildingId: null,
          machine:null,
          station:0,
          model:null,
          macTypeId:null,
          macSubTypeId:null,
          assetNo:null,
          brandId:null,
          motorTypeId:null,
          serialNo:null,
          supplierId:null,
          supplier_id:null,
          isRental:0,
          aquisitionDt:null,
          // pmFrequency:null,
          frequencyId:null,
          frequencyQty:null,
          pmPlanDt:null,
          statusId:null,
          conditionId:1,
          attachmentId:null,
          lastPMDt: null,
          rfid: null,
          isActive:1,
          remarks: null,
          reference: null,
        },
        formErrors: [],
        tableHeaders: [
            'TEMPLATE NAME',
            'TEMPLATE NO',
            'STEPS',
            'SUB STEPS',
            'MACHINE TYPE',
            'BRAND',
            'DATE CREATED',
            'DATE ADDED',
            'STATUS',
        ],
        tableData: [
          {
              selected: false,
              TemplateName:'Template 4',
              TemplateNo:34263576,
              Steps:5,
              SubSteps:1,
              MachineType:'All',
              Brand:'All',
              DateCreated:'08-11-2019',
              DateSubmitted:'14-11-2019',
              Status:'Active',
          },
          {
              selected: false,
              TemplateName:'Template 4',
              TemplateNo:34263576,
              Steps:5,
              SubSteps:1,
              MachineType:'All',
              Brand:'All',
              DateCreated:'08-11-2019',
              DateSubmitted:'14-11-2019',
              Status:'Active',
          },
          {
              selected: false,
              TemplateName:'Template 4',
              TemplateNo:34263576,
              Steps:5,
              SubSteps:1,
              MachineType:'All',
              Brand:'All',
              DateCreated:'08-11-2019',
              DateSubmitted:'14-11-2019',
              Status:'Active',
          },
        ],
        languageProps: Object.assign({},{...this.changeLang()}),
        translationTimestamp: undefined
    }
  }

 

  changeLang = () => {
    let {CANCEL,SAVE,IN_PROCESS,CHANGEOVER,NOT_AVAIL,MAINTENANCE,REPAIR,READY,AVAILABLE,WORKING,ALL,ADD,RETIRE,YES,NO,EXPORT_TO_FILE,MACHINE,MODEL_NO,MACHINE_TYPE,MACHINE_SUBTYPE,BRAND,MOTOR_TYPE,PURCHASE_DATE,SERIAL_NO,ASSET_NO,MAIN_FREQ,LOCATION,LPM,LPR,STATUS,CONDITION,ATTACHMENT,RENTAL,Active,RFID,Supplier,MACHINE_NO,ACTIVE,DATE_OF_PURCHASE,MAINTENANCE_FREQ,LAST_MAINTENANCE,NEXT_MAINTENANCE,MAINTENANCE_STATUS,REMARKS,NOTIFICATIONS: {there_are, machines_that_cannot_be_retired}} = dictionary.GET()
    return dictionary.TRANSLATE({CANCEL,SAVE,IN_PROCESS,CHANGEOVER,NOT_AVAIL,MAINTENANCE,REPAIR,READY,AVAILABLE,WORKING,ALL,ADD,RETIRE,YES,NO,EXPORT_TO_FILE,MACHINE,MODEL_NO,MACHINE_TYPE,MACHINE_SUBTYPE,BRAND,MOTOR_TYPE,PURCHASE_DATE,SERIAL_NO,ASSET_NO,MAIN_FREQ,LOCATION,LPM,LPR,STATUS,CONDITION,ATTACHMENT,RENTAL,Active,RFID,Supplier,MACHINE_NO,ACTIVE,DATE_OF_PURCHASE,MAINTENANCE_FREQ,LAST_MAINTENANCE,REMARKS,NEXT_MAINTENANCE,MAINTENANCE_STATUS, there_are, machines_that_cannot_be_retired})

}
  resetForm(){
      let resetForm = {
        /* CONSTANT factoryid, module id and mfg line id, to be replaced */
        factoryId:getFactoryId(),
        moduleId:1,
        mfgLineId:null,
        /* ------------------------------------------------------------- */
        areaId:null,
        machine:null,
        station:0,
        model:null,
        macTypeId:null,
        macSubTypeId:null,
        assetNo:null,
        brandId:null,
        motorTypeId:null,
        serialNo:null,
        supplierId:null,
        supplier_id:null,
        isRental:0,
        aquisitionDt:null,
        // pmFrequency:null,
        frequencyId:null,
        frequencyQty:null,
        pmPlanDt:null,
        statusId:null,
        conditionId:1,
        attachmentId:null,
        lastPMDt: null,
        rfid: null,
        isActive:1,
        remarks: null,
        reference: null
        }
      this.setState({form:resetForm})
  }

  handleCancel = () => {
      this.resetForm();
      if (this.state.id === null){
        history.push('/machines');
      }
      else{
        history.push('/machine-detail/' + this.state.id);
      }
  }

  removeErrorOnChange(field){
    let errors = this.state.formErrors.filter((item) => {return item.name !== field});
    this.setState({ formErrors: errors });
  }

  handleMacTypeChange = ({target}) => {
    this.handleFormChange({target});

    // reset macsubtypeId   
    let tmpForm = this.state.form;
    tmpForm['macSubTypeId'] = null;
    this.setState({ form: tmpForm }, () => {
        this.props.getMacSubTypes(1, target.value);
    });
  }

  handleFormLocationChange = ({ target }) => {
      let rowData = this.props.areaList.filter((item) => { return target.value === item.id  })
      if (rowData.length > 0){
        let tmpForm = this.state.form;

        tmpForm[target.name] = target.value;
        // if (rowData[0].isLineReq === true){
        //     this.setState({isLineReq: true});
        // }
        // else{
        //     tmpForm['station'] = 0;
        //     this.setState({isLineReq: false});
        // }
        this.setState({isLineReq: false});
        
        let conditionList = this.getConditionByLocation(rowData[0].areaTypeId);
        if (conditionList.length > 0){
            this.setState({conditionListByLocation: conditionList});
            tmpForm['conditionId'] = conditionList[0].id;
        }
        else{
            this.setState({statusListByLocationAndCondition: []});
            tmpForm['statusId'] = null;
        }

        let statusList = this.getStatusByLocationAndCondition(rowData[0].areaTypeId,this.state.form.conditionId);
        if (statusList.length > 0){
            this.setState({statusListByLocationAndCondition: statusList});
            tmpForm['statusId'] = statusList[0].id;
        }
        else{
            this.setState({statusListByLocationAndCondition: []});
            tmpForm['statusId'] = null;
        }
        this.setState({ form: tmpForm });
        this.removeErrorOnChange(target.name);
      }
  };

  handleValidationFrequencyQty = (event) => {
    let value = parseInt(event.target.value, 10);
    if (isNaN(value) || value < 1 || value > 999) {
      value = 1;
    }
    event.target.value = value.toString();
    return event;
  };

  handleFrequencyChange = ({ target }) => {
    let check = this.props.frequencyList.filter((item) => { return item.id === target.value });
    if (check.length > 0){
        let tmpForm = this.state.form;
        tmpForm[target.name] = target.value;
        this.setState({ form: tmpForm });
        this.removeErrorOnChange(target.name);
    }
  };

  handleFormConditionChange = ({ target }) => {
    let tmpForm = this.state.form;
    tmpForm[target.name] = target.value;
    let statusList = this.getStatusByLocationAndCondition(this.getAreaTypeId(this.state.form.areaId),target.value);
    if (statusList.length > 0){
        this.setState({statusListByLocationAndCondition: statusList});
        tmpForm['statusId'] = statusList[0].id;
    }
    else{
        this.setState({statusListByLocationAndCondition: []});
        tmpForm['statusId'] = null;
    }
    this.setState({ form: tmpForm });
    this.removeErrorOnChange(target.name);
 };

  handleFormChange = ({ target }) => {
     let tmpForm = this.state.form;
     tmpForm[target.name] = target.value;
     this.setState({ form: tmpForm });
     this.removeErrorOnChange(target.name);
  };

  handleFormTextChange = ({ target }) => {
    const trimmedValue = target.value.trim();
    let tmpForm = { ...this.state.form };
    tmpForm[target.name] = trimmedValue;
    this.setState({ form: tmpForm });
    this.removeErrorOnChange(target.name);
  };

  handleFormCheckboxChange = ({ target }) => {
     let tmpForm = this.state.form;
     let value = 0;
     if (target.checked){
         value = 1;
     }
     tmpForm[target.name] = value;
     this.setState({ form: tmpForm });
     this.removeErrorOnChange(target.name);
  };

  handleFormDateChange = (name,date) => {
    let tmpForm = this.state.form;
    if (date !== null){
        if (date.isValid() === true){
            tmpForm[name] = date;
            if (name === 'aquisitionDt' && this.state.id === null){
                tmpForm['lastPMDt'] = date;
            }
        }
        else{
            tmpForm[name] = false;
        }
    }
    else{
        tmpForm[name] = date;
    }
    this.setState({ form: tmpForm });
    this.removeErrorOnChange(name);
 };

 
sortResults(array, prop, asc) {
    try{
        array.sort(function(a, b) {
            if (asc) {
                return (a[prop].toLowerCase() > b[prop].toLowerCase()) ? 1 : ((a[prop].toLowerCase() < b[prop].toLowerCase()) ? -1 : 0);
            } else {
                return (b[prop].toLowerCase() > a[prop].toLowerCase()) ? 1 : ((b[prop].toLowerCase() < a[prop].toLowerCase()) ? -1 : 0);
            }
        });
        return array;
    }
    catch(err){
        return []
    }
}

  renderConditionItems(){
      let conditionList = this.props.conditionList.map((item) => {
        return <MenuItem key={item.id} value={item.id}>{ item.condition }</MenuItem>
      });
      return conditionList;
  }

  renderStatusItems(){
      let statusList = null;
      if (this.state.id === null){
        statusList = this.state.statusListByLocationAndCondition.map((item) => {
            return <MenuItem key={item.id} value={item.id}>{ item.status }</MenuItem>
        });
      }
      else{
        statusList = this.props.statusList.map((item) => {
            return <MenuItem key={item.id} value={item.id}>{ item.status }</MenuItem>
        });
      }
      return statusList;
  }

  renderMacTypesItems(){
    let macTypeData = this.sortResults(this.props.macTypeList,'desc',true);
    let macTypeList = macTypeData.map((item) => {
      return <MenuItem key={item.id} value={item.id}>{ item.desc }</MenuItem>
    });
    return macTypeList;
  }
  
  renderMacSubTypesItems(){
    const { macTypeId } = this.state.form;
    const subTypes = this.props.macSubTypeList.filter((item)=> item.macTypeId == macTypeId);
    let macSubTypeData = this.sortResults(subTypes, 'desc1', true);
    let macSubTypeList = macSubTypeData.map((item) => {
      return <MenuItem key={item.id} value={item.id}>{ item.desc1 }</MenuItem>
    });
    return macSubTypeList;
  }
  
  renderBrandItems(){
    let brandData = this.sortResults(this.props.brandList,'desc1',true);
    let brandList = brandData.filter((item) => { return item.isActive === true}).map((item) => {
        return <MenuItem key={item.id} value={item.id}>{ item.desc1 }</MenuItem>
    });
    return brandList;
  }
  
  renderMotorTypeItems(){
    let motorTypeData = this.sortResults(this.props.motorTypeList,'desc1',true);
    let motorTypeList = motorTypeData.map((item) => {
        return <MenuItem key={item.id} value={item.id}>{ item.desc1 }</MenuItem>
    });
    return motorTypeList;
  }
  
  renderSupplierItems(){
    let supplierData = this.sortResults(this.props.supplierList,'desc1',true);
    let supplierList = supplierData.map((item) => {
        return <MenuItem key={item.id} value={item.id}>{ item.desc1 }</MenuItem>
    });
    return supplierList;
  }
  
  renderFrequencyItems(){
    let frequencyList = this.props.frequencyList.map((item) => {
        return <MenuItem key={item.id} value={item.id}>{ item.frequency }</MenuItem>
    });
    return frequencyList;
  }

    renderAreaItems(){
        if (this.state.id === null){
            let areas = this.props.areaList.filter((item) => !item.isLineReq)
            if(this.state.form.buildingId) {
                areas = areas.filter((a)=> a.buildingId == this.state.form.buildingId);
            }

            return areas.map((item) => (
                <MenuItem key={item.id} value={item.id}>{ item.area }</MenuItem>
            ));
        }
        else{
            return this.props.areaList.map((item) => {
                return <MenuItem key={item.id} value={item.id}>{ item.area }</MenuItem>
            });
        }
    }

    renderBuildings(){
        return this.props.buildings.map((building) => {
            return <MenuItem 
                key={building.id} 
                value={building.id}
            >
                { building.name }
            </MenuItem>
        });
    }

    getBuildingIdFromArea = (areaId)=>{
        if(this.state.form.buildingId) return;
        if(!this.props.areaList) return;
        if(!areaId) return;

        const selectedArea = this.props.areaList.find((area)=> area.id == areaId);
        if(selectedArea){
            return selectedArea.buildingId
        }
    }

  renderAttachmentItems(){
    let attachmentData = this.sortResults(this.props.attachmentList,'desc1',true);
    let attachmentList = attachmentData.map((item) => {
        return <MenuItem key={item.id} value={item.id}>{ item.desc1 }</MenuItem>
    });
    return attachmentList;
  }

handleReferenceChange = (referenceName, event) => {
    const { form } = this.state;
    const updatedReferenceValue = event.target.value;
    const truncatedValue = updatedReferenceValue.slice(0, 50);
    const hasError = updatedReferenceValue.length >= 50;
    const updatedForm = {
      ...form,
      reference: {
        ...form.reference,
        [referenceName]: truncatedValue,
      },
    };
    const refValidationError = {
        ...this.state.refValidationError,
        [referenceName]: hasError ? 'Maximum of 50 characters.' : '',
    };
    this.setState({ form: updatedForm, refValidationError });
  }
  
  
  renderReferenceFieldsLeft() {
    const { form } = this.state;
    const { reference } = this.props;
    const filteredReferenceItems = reference.filter((item, index) => index % 2 === 0);

    return (
      <>
        {filteredReferenceItems.map((referenceItem) => (
          <Grid className="form-control" container spacing={1} key={referenceItem.constant}>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
                <span className="form-label" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                    {referenceItem.translations && referenceItem.translations.length > 0 ? 
                        referenceItem.translations[0].translatedDesc 
                    : 
                        referenceItem.name}
                </span>
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={(event) => this.handleReferenceChange(referenceItem.constant, event)}
                name={referenceItem.name}
                value={form.reference ? form.reference[referenceItem.constant] : ''}
                fullWidth
                variant="outlined"
                error={!!this.state.refValidationError[referenceItem.constant]}
                helperText={this.state.refValidationError[referenceItem.constant]}
              />
            </Grid>
          </Grid>
        ))}
      </>
    );
  }

  renderReferenceFieldsRight() {
    const { form } = this.state;
    const { reference } = this.props;
    const filteredReferenceItems = reference.filter((item, index) => index % 2 !== 0);

    return (
      <>
        {filteredReferenceItems.map((referenceItem) => (
          <Grid className="form-control" container spacing={1} key={referenceItem.constant}>
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
                <span className="form-label" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                    {referenceItem.translations && referenceItem.translations.length > 0 ? 
                        referenceItem.translations[0].translatedDesc 
                    : 
                        referenceItem.name}
                </span>
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={(event) => this.handleReferenceChange(referenceItem.constant, event)}
                name={referenceItem.name}
                value={form.reference ? form.reference[referenceItem.constant] : ''}
                fullWidth
                variant="outlined"
                error={!!this.state.refValidationError[referenceItem.constant]}
                helperText={this.state.refValidationError[referenceItem.constant]}
              />
            </Grid>
          </Grid>
        ))}
      </>
    );
  }

  renderBoolToInteger(flag){
      if (flag === true || flag === 1){
          return 1
      }
      else if (flag === false || flag === 0){
          return 0
      }
  }

  getStatusByLocationAndCondition(areaTypeId,conditionId){
      let statusList = this.props.machineStatusList;
      if (conditionId === 1 && areaTypeId === 3){
          return statusList.filter((item) => {return item.status === "ACTIVE"})
      }
      else if (conditionId === 1 && areaTypeId === 4){
        return statusList.filter((item) => {return item.status === "AVAILABLE"})
      }
      else if (conditionId === 1 && areaTypeId === 1){
        return statusList.filter((item) => {return item.status === "AVAILABLE"})
      }
      else if (conditionId === 2 && areaTypeId === 3){
        return statusList.filter((item) => {return item.status === "REPAIR"})
      }
      else if (conditionId === 2 && areaTypeId === 4){
        return statusList.filter((item) => {return item.status === "MAINTENANCE" || item.status === "CHANGEOVER"})
      }
      else if (conditionId === 3 && areaTypeId === 4){
        return statusList.filter((item) => {return item.status === "INACTIVE"})
      }
      else if (conditionId === 3 && areaTypeId === 1){
        return statusList.filter((item) => {return item.status === "INACTIVE"})
      }
      else if (areaTypeId === 2){
        // FOR FLOATING
        return statusList.filter((item) => {return item.status === "AVAILABLE"})
      }
      else{
          return statusList.filter((item) => {return item.status === "AVAILABLE"});
      }
  }

  

  filterBrands(brand){
    if (brand.length > 0){
        let tmpBrands = brand.filter((item) => {return item.isActive === true});
        return tmpBrands;
    }
  }

  getConditionByLocation(areaTypeId){
    let conditionList = this.props.conditionList;
      if (areaTypeId === 3){
          return conditionList.filter((item) => { return item.id === 1 || item.id === 2})
      }
      else if (areaTypeId === 1){
        return conditionList.filter((item) => { return item.id === 1 || item.id === 3})
      }
      else{
          return conditionList
      }
  }

  getAreaTypeId(areaId){
      let data = this.props.areaList.filter((item) => { return item.id === areaId});
      if (data.length > 0){
          return data[0].areaTypeId;
      }
      else{
          return null;
      }
  }

  hasErrors = (field) => {
      let checkField = this.state.formErrors.filter((item) => { return item.name === field });
      if (checkField.length > 0){
          return true
      }
      else{
          return false;
      }
  }

  getErrors = (field) => {
    let checkField = this.state.formErrors.filter((item) => { return item.name === field });
    let errorString = null;
    checkField.map((item) => {
        errorString += item.message + '. ';
        return '';
    })
    return errorString;
  }

  submitForm = () => {
      let checklistsAddon = []
      let checklistClone = this.state.machineChecklist;
      for(let i = 0; i < checklistClone.length; i++){
        checklistsAddon.push({id:checklistClone[i].id, tempTypeId:checklistClone[i].tempTypeId, createdDt:this.renderCreatedDate()})
      }
      let payload = {
          ...this.state.form,
          checklists: checklistsAddon,
          reference: this.state.form.reference,
          aquisitionDt: this.state.form.aquisitionDt ? moment(this.state.form.aquisitionDt).format('YYYY-MM-DD') : null,
          pmPlanDt: this.state.form.pmPlanDt ? moment(this.state.form.pmPlanDt).format('YYYY-MM-DD') : null,
          lastPMDt: this.state.form.lastPMDt ? moment(this.state.form.lastPMDt).format('YYYY-MM-DD') : null ,
          assetNo: this.state.form.assetNo === "" ? null : this.state.form.assetNo
      };
      let formValidation = validateFields(payload);
      this.setState({formErrors:formValidation.errors});
      if (formValidation.valid){
          if (this.state.form.lastPMDt === false){
              this.props.notificationError('Invalid Input');
              return;
          }
          else if (this.state.form.aquisitionDt === false){
              this.props.notificationError('Invalid Input');
              return;
          } 
          else if (this.state.form.pmPlanDt === false){
              this.props.notificationError('Invalid Input');
              return;
          }
          
          if (this.state.id === null){
            payload = {
                ...payload,
                createdDt: moment()
            };
            this.props.postMachineNewItem(payload);
          }
          else{
            payload = {
                ...payload,
                updatedDt: moment()
            };
            this.props.putUpdateMachine(this.state.id,payload);
          }
      }
      else{
          this.props.notificationError(formValidation.errors[0].message);
      }
  }

  checkIfApiLoading(){
    let flag = checkIfApiLoading([
        this.props.machineStatusListLoading,
        this.props.macTypeListLoading,
        this.props.brandListLoading,
        this.props.motorTypeListLoading,
        this.props.conditionListLoading,
        this.props.supplierListLoading,
        this.props.frequencyListLoading,
        this.props.areaListLoading,
        this.props.attachmentListLoading,
    ]);
    return flag;
  }

  returnStation(field){
      if (field === '' || field === null){
          return 0;
      }
      else{
          return field;
      }
  }

  updateFormDetail(){
      if (this.checkIfApiLoading() === false){
        const oldReferences = {};
        if(this.props.machineDetail && this.props.machineDetail.reference) {
            this.props.machineDetail.reference.forEach((item) => {
                oldReferences[item['constant']] = item.value;
            })
        }

        let tmpForm = {
            /* CONSTANT factoryid, module id and mfg line id, to be replaced */
            factoryId:getFactoryId(),
            moduleId:1,
            mfgLineId:this.props.machineDetail.mfgLineId,
            /* ------------------------------------------------------------- */
            areaId:this.props.machineDetail.areaId,
            buildingId: this.getBuildingIdFromArea(this.props.machineDetail.areaId),
            machine:this.props.machineDetail.machine,
            station:this.returnStation(this.props.machineDetail.station),
            model:this.props.machineDetail.model,
            macTypeId:this.props.machineDetail.macTypeId,
            macSubTypeId:this.props.machineDetail.macSubTypeId,
            assetNo:this.props.machineDetail.assetNo,
            brandId:this.props.machineDetail.brandId,
            motorTypeId:this.props.machineDetail.motorTypeId,
            serialNo:this.props.machineDetail.serialNo,
            supplierId:null,
            supplier_id:this.props.machineDetail.supplier_id,
            isRental:this.renderBoolToInteger(this.props.machineDetail.isRental),
            aquisitionDt: formatDate(this.props.machineDetail.aquisitionDt, 'YYYY-MM-DD', null),
            // pmFrequency:this.props.machineDetail.pmFrequency,
            frequencyId:this.props.machineDetail.frequencyId,
            frequencyQty:this.props.machineDetail.frequencyQty || null,
            pmPlanDt: formatDate(this.props.machineDetail.pmPlanDt, 'YYYY-MM-DD', null),
            statusId:this.props.machineDetail.statusId,
            conditionId:this.props.machineDetail.conditionId,
            attachmentId:this.props.machineDetail.attachmentId,
            lastPMDt: formatDate(this.props.machineDetail.lastPMDt, 'YYYY-MM-DD', null),
            rfid: this.props.machineDetail.rfid,
            isActive:this.renderBoolToInteger(this.props.machineDetail.isActive),
            remarks: this.props.machineDetail.remarks,
            reference: oldReferences
          }
          this.props.getMacSubTypes(1,tmpForm.macTypeId);
          this.setState({form: tmpForm});
          this.setState({loadedMachineDetails: true});
    
          let areaId = tmpForm.areaId;
          let conditionList = this.getConditionByLocation(this.getAreaTypeId(areaId));
          this.setState({conditionListByLocation: conditionList});
          let statusList = this.getStatusByLocationAndCondition(this.getAreaTypeId(areaId),tmpForm.conditionId);
          this.setState({statusListByLocationAndCondition: statusList});
      }
  }

  componentDidMount(){
      this.props.getMachineStatusv2();
      this.props.getStatus();
      this.props.getMacTypes();
      this.props.getBrands();
      this.props.getMotorTypes();
      this.props.getConditions();
      this.props.getSuppliers();
      this.props.getFrequencies();
      this.props.getBuildings();
      this.props.getAreas();
      this.props.getAttachments();
      this.props.getTemplates();
      this.props.getAllMacSubTypes();
      this.props.getSystemPreference();
      this.setState({ machineChecklist:[]})
      if (this.props.match.params.id){
          this.props.getMachineById(this.props.match.params.id);
          this.setState({id: this.props.match.params.id});
      }
  }

  handleFilterCheckList({
    isActive, 
    brandId, 
    macSubTypeId, 
    searchString,
  }){
    this.props.getTemplates(isActive, brandId, macSubTypeId, searchString);
  }

  componentWillReceiveProps(){
    
    if(this.props.allMacSubTypeList != undefined){
        this.setState({allMacSubTypeList:this.props.allMacSubTypeList})
    }
    if(this.props.macSubTypeList != undefined){
        this.setState({macSubTypeList:this.props.macSubTypeList})
    }
    if(this.props.templates != undefined){
        this.setState({templates:this.props.templates})
    }
    if(this.props.brandList != undefined){
        this.setState({brandList:this.props.brandList})
    }
    if (this.props.machineChecklist != undefined && this.props.machineDetail !== null && this.props.machineDetail !== undefined && this.state.id !== null && this.state.loadedMachineDetails === false){
        let checklistGathered = this.props.machineChecklist

        let newData = []
        for(let i = 0; i < checklistGathered.length; i++){
            newData.push({
                id:checklistGathered[i].checkListId,
                name:checklistGathered[i].templateName,
                no:checklistGathered[i].checkListNo,
                tempTypeId:checklistGathered[i].tempTypeId,
                tasksSteps:checklistGathered[i].tasksSteps,
                subTasksSteps:checklistGathered[i].subTasksSteps,
                macsubtype_desc:checklistGathered[i].macsubType,
                brand:checklistGathered[i].brand,
                createdDt:checklistGathered[i].createdDt,
                addedDt:checklistGathered[i].addedDt,
                templateStatus:checklistGathered[i].templateStatus,
                isActive:checklistGathered[i].isActive ? 'ACTIVE' : 'INACTIVE',
            })
        }
        this.setState({machineChecklist:newData})
    }
    if(this.props.machineDetail != undefined && this.props.match.params.id != null){
        this.setState({machineDetail:this.props.machineDetail})
    }else{
        this.setState({
            machineDetail:[],
        })
    }
}



  forcePushError(name,message){
      let errors = this.state.formErrors;
      errors.push({name:name,message:message})
  }

  componentDidUpdate(){
    if (this.props.inputReducer.toFocus){
        if (this.props.inputReducer.fieldName === 'machineNo'){
            this.refMachineNo.current.focus();
            this.forcePushError('machine','Machine No already exists')
            this.props.inputFocusSuccess();
        }
        else if (this.props.inputReducer.fieldName === 'serialNo'){
            if (this.refSerialNo.current !== null) {
                this.refSerialNo.current.focus();
            }
            this.forcePushError('serialNo','Serial No already exists')
            this.props.inputFocusSuccess();
        }
        else if (this.props.inputReducer.fieldName === 'assetNo'){
            this.refAssetNo.current.focus();
            this.forcePushError('asset','Asset No already exists')
            this.props.inputFocusSuccess();
        }
        else if (this.props.inputReducer.fieldName === 'rfid'){
            this.refRFID.current.focus();
            this.forcePushError('rfid','RFID already exists')
            this.props.inputFocusSuccess();
        }
    }

    if (this.props.machineDetail !== null && this.props.machineDetail !== undefined && this.state.id !== null && this.state.loadedMachineDetails === false){
        this.updateFormDetail();
    }
    if(this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp){
        this.setState({
            translationTimestamp: this.props.translation.translations.timeStamp,
            languageProps: Object.assign({},{...this.changeLang()})

        })
    }
  }

  renderDateWithMinHour(date){
    if (date !== null){
        return moment(date).format('YYYY-MM-DD HH:mm:ss.sss');
    }
    else{
        return null;
    }
}

renderCreatedDate(){
    return moment().format('YYYY-MM-DD HH:mm:ss.sss');
}

  detacher = async(rows, fulldata) => {
    let filteredChecklist = []
    const checklist = []
    if(rows.length == 1 && fulldata.length == 2){
      filteredChecklist = fulldata.filter(x => x.id != rows[0])
      checklist.push(filteredChecklist[0])
    } 
  this.setState({machineChecklist:checklist})
}

attacher = (rows, gettempid, maclength, templatesData, machineChecklistData, selectedTemplate) =>{
    const lengthOfChklist = maclength
    const checklist = []
    if(lengthOfChklist == 1){
        const checklistname = machineChecklistData[0].name;
        checklist.push(machineChecklistData[0])
    }
    
    for(let i = 0; i < rows.length; i++){
        const filteredTemplates = templatesData.filter(x => x.id == rows[i])
        checklist.push(selectedTemplate[i])
    }

    this.setState({machineChecklist:checklist})
}

renderIsActiveCheckbox(){
    if (this.state.id !== null){
        return (            
            <Grid className="form-control" container spacing={1}>
                <Grid item xs={0}>
                </Grid>
                <Grid item xs={5}>
                    <span className="form-label">{this.state.languageProps.ACTIVE}</span>
                </Grid>
                <Grid item xs={6}>
                    <FormControl>
                        <Checkbox className="checkbox-relative" color="primary" error={ this.hasErrors('isActive') } onChange={this.handleFormCheckboxChange} name="isActive" checked={this.state.form.isActive === 1}></Checkbox>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }
}

renderLastMaintenance(){
    if (this.state.id !== null){
        return (
            <Grid className="form-control" container spacing={1}>
                <Grid item xs={0}>
                </Grid>
                <Grid item xs={5}>
                    <span className="form-label">{this.state.languageProps.LAST_MAINTENANCE}</span>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <MuiPickersUtilsProvider utils={DateMomentUtils} >
                            <KeyboardDatePicker disabled={this.state.id === null} inputRef={this.lastPMDt} maxDateMessage={""} minDate={"0001-01-01"} error={ this.hasErrors('lastPMDt') || this.state.form.lastPMDt === false } onChange={(date) => { this.handleFormDateChange('lastPMDt',date) }} name="lastPMDt" className="date-input" inputVariant="outlined" value={this.state.form.lastPMDt} format="DD/MM/YYYY" />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }
}

  content(){
      return (
          <div>
                {/* <Grid container spacing={2}>
                    <Grid className="actions" item xs={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={2}>
                                <div className="action-item">
                                    <span>Edit</span>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="action-item">
                                    <span>Delete</span>
                                </div>
                            </Grid>
                            <Grid item xs={7}>
                                <div className="action-item">
                                    <span>Create Ticket</span>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> */}

                <Grid className="form-component" container spacing={1}>
                    <FormControl fullWidth component="form">
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <FormGroup>
                                    <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={1}>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <span className="form-label required">{ this.state.languageProps.MACHINE_NO}</span>
                                        </Grid>
                                        <Grid item xs={6}> 
                                            <TextField inputRef={this.refMachineNo} error={ this.hasErrors('machine') } onChange={this.handleFormTextChange} name="machine" value={this.state.form.machine} fullWidth variant="outlined" />
                                        </Grid>
                                    </Grid>
                                    <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={1}>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <span className="form-label required">{ this.state.languageProps.MODEL_NO}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField error={ this.hasErrors('model') } onChange={this.handleFormTextChange} name="model" value={this.state.form.model} fullWidth variant="outlined" />
                                        </Grid>
                                    </Grid>
                                    <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={1}>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <span className="form-label required">{this.state.languageProps.MACHINE_TYPE}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <Select error={ this.hasErrors('macTypeId') } onChange={this.handleMacTypeChange} name="macTypeId" value={this.state.form.macTypeId} variant="outlined" className="form-select">
                                                        { this.checkIfApiLoading() === false ? this.renderMacTypesItems() : null }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={1}>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <span className="form-label">{'Supplier'.translate() || this.state.languageProps.SUPPLIER}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <Select error={ this.hasErrors('supplier_id') } onChange={this.handleFormChange} name="supplier_id" value={this.state.form.supplier_id} className="form-select" placeholder="Status">
                                                    { this.checkIfApiLoading() === false ? this.renderSupplierItems() : null }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={1}>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <span className="form-label required">{this.state.languageProps.MACHINE_SUBTYPE}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <Select 
                                                    disabled={ this.state.form.macTypeId == (undefined || null ) && !this.hasErrors('macSubTypeId')} 
                                                    error={ this.hasErrors('macSubTypeId') } 
                                                    onChange={this.handleFormChange} 
                                                    name="macSubTypeId" 
                                                    value={this.state.form.macSubTypeId} 
                                                    variant="outlined" 
                                                    className="form-select"
                                                >
                                                    { this.checkIfApiLoading() === false ? this.renderMacSubTypesItems() : null }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={1}>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <span className="form-label required">{this.state.languageProps.DATE_OF_PURCHASE}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <MuiPickersUtilsProvider utils={DateMomentUtils} >
                                                    <KeyboardDatePicker maxDateMessage={""} minDate={"0001-01-01"} error={ this.hasErrors('aquisitionDt') || this.state.form.aquisitionDt === false } onChange={(date) => { this.handleFormDateChange('aquisitionDt',date) }} name="aquisitionDt" className="date-input" inputVariant="outlined" value={this.state.form.aquisitionDt} format="DD/MM/YYYY" />
                                                </MuiPickersUtilsProvider>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={1}>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <span className="form-label">{ this.state.languageProps.RENTAL}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl>
                                                <Checkbox color="primary" error={ this.hasErrors('isRental') } onChange={this.handleFormCheckboxChange} name="isRental" checked={this.state.form.isRental === 1} variant="outlined" className="checkbox-relative"></Checkbox >
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid className="form-control" container spacing={1}>
                                        { this.renderReferenceFieldsLeft() }
                                    </Grid>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={4}>
                                <FormGroup>
                                    <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={1}>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <span className="form-label required">Building</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <FormControl variant="outlined" fullWidth>
                                                    <Select 
                                                        disabled={this.state.id !== null} 
                                                        name="buildingId" 
                                                        value={this.state.form.buildingId} 
                                                        onChange={this.handleFormChange} 
                                                        className="form-select" 
                                                        placeholder="Building"
                                                        error={ this.hasErrors('buildingId') }
                                                    >
                                                        { this.checkIfApiLoading() === false ? this.renderBuildings() : null }
                                                    </Select>
                                                </FormControl>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={1}>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <span className="form-label required">{this.state.languageProps.LOCATION}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <FormControl variant="outlined" fullWidth>
                                                    <Select 
                                                        disabled={this.state.id !== null || !this.state.form.buildingId && !this.hasErrors('areaId')} 
                                                        error={ this.hasErrors('areaId') } 
                                                        onChange={this.handleFormLocationChange} 
                                                        name="areaId" 
                                                        value={this.state.form.areaId} 
                                                        className="form-select" 
                                                        placeholder="Status"
                                                    >
                                                        { this.checkIfApiLoading() === false ? this.renderAreaItems() : null }
                                                    </Select>
                                                </FormControl>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={1}>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <span className="form-label required">{ this.state.languageProps.BRAND}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <Select error={ this.hasErrors('brandId') } onChange={this.handleFormChange} name="brandId" value={this.state.form.brandId} variant="outlined" className="form-select">
                                                        { this.checkIfApiLoading() === false ? this.renderBrandItems() : null }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid className="form-control" container spacing={1}>
                                         <Grid item xs={1}>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <span className="form-label required">{this.state.languageProps.MOTOR_TYPE}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <Select error={ this.hasErrors('motorTypeId') } onChange={this.handleFormChange} name="motorTypeId" value={this.state.form.motorTypeId} variant="outlined" className="form-select">
                                                        { this.checkIfApiLoading() === false ? this.renderMotorTypeItems() : null }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={1}>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <span className="form-label required">{this.state.languageProps.SERIAL_NO}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <TextField inputRef={this.refSerialNo} error={ this.hasErrors('serialNo') } onChange={this.handleFormTextChange} name="serialNo" value={this.state.form.serialNo} variant="outlined" />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={1}>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <span className="form-label">{this.state.languageProps.ASSET_NO}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <TextField inputRef={this.refAssetNo} error={ this.hasErrors('assetNo') } onChange={this.handleFormTextChange} name="assetNo" value={this.state.form.assetNo} variant="outlined" />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={1}>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <span className="form-label">{this.state.languageProps.RFID}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <TextField inputRef={this.refRFID} error={ this.hasErrors('rfid') } onChange={this.handleFormTextChange} name="rfid" value={this.state.form.rfid} variant="outlined" />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={1}>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <span className="form-label">{this.state.languageProps.ATTACHMENT}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <Select error={ this.hasErrors('attachmentId') } onChange={this.handleFormChange} name="attachmentId" value={this.state.form.attachmentId} variant="outlined" className="form-select">
                                                        { this.checkIfApiLoading() === false ? this.renderAttachmentItems() : null }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid className="form-control" container spacing={1}>
                                        { this.renderReferenceFieldsRight() }
                                    </Grid>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={4}>
                                <FormGroup>
                                    <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={0}>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <span className="form-label required">{this.state.languageProps.MAINTENANCE_FREQ}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container spacing={1}>
                                                <Grid item md={6}>
                                                    <TextField
                                                        name='frequencyQty'
                                                        onChange={(event) => {
                                                            this.handleFormTextChange(
                                                                this.handleValidationFrequencyQty(event)
                                                            )
                                                        }}
                                                        value={this.state.form.frequencyQty}
                                                        error={this.hasErrors('frequencyQty')} 
                                                        InputProps={{ inputProps: { min: 1, max: 999 } }} 
                                                        type='number' 
                                                        variant='outlined' 
                                                        fullWidth />
                                                </Grid>
                                                <Grid item md={6}>
                                                  <FormControl fullWidth>
                                                      <Select 
                                                        error={this.hasErrors('frequencyId')} 
                                                        onChange={this.handleFrequencyChange} 
                                                        name="frequencyId" 
                                                        value={this.state.form.frequencyId} 
                                                        variant="outlined" 
                                                        className="form-select">
                                                          {this.checkIfApiLoading() === false ? this.renderFrequencyItems() : null}
                                                      </Select>
                                                  </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    { this.renderLastMaintenance() }
                                    <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={0}>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <span className="form-label required">{ this.state.languageProps.NEXT_MAINTENANCE}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <MuiPickersUtilsProvider utils={DateMomentUtils} >
                                                    <KeyboardDatePicker maxDateMessage={""} minDate={"0001-01-01"} error={ this.hasErrors('pmPlanDt') || this.state.form.pmPlanDt === false } onChange={(date) => { this.handleFormDateChange('pmPlanDt',date) }} name="pmPlanDt" className="date-input" inputVariant="outlined" value={this.state.form.pmPlanDt} format="DD/MM/YYYY" />
                                                </MuiPickersUtilsProvider>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={0}>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <span className="form-label">{ this.state.languageProps.REMARKS}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField error={ this.hasErrors('remarks') } onChange={this.handleFormChange} name="remarks" value={this.state.form.remarks} fullWidth variant="outlined" />
                                        </Grid>
                                    </Grid>
                                    {/* <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={0}>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <span className="form-label required">{'Station'.translate() || this.state.languageProps.STATION}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <TextField disabled={this.state.id !== null || this.state.isLineReq === false || this.state.isLineReq === null} error={ this.hasErrors('station') } onChange={this.handleFormChange} name="station" value={this.state.form.station} variant="outlined" />
                                            </FormControl>
                                        </Grid>
                                    </Grid> */}
                                    {/* { this.renderIsActiveCheckbox() } */}
                                    {/* <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={0}>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <span className="form-label required">{'Condition'.translate() || this.state.languageProps.CONDITION}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <Select disabled={true} error={ this.hasErrors('conditionId') } onChange={this.handleFormConditionChange} name="conditionId" value={this.state.form.conditionId} className="form-select" placeholder="Status">
                                                    { this.checkIfApiLoading() === false ? this.renderConditionItems() : null }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid> */}
                                    {/* <Grid className="form-control" container spacing={1}>
                                        <Grid item xs={0}>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <span className="form-label required">{'Status'.translate() || this.state.languageProps.STATUS}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <Select disabled={true} error={ this.hasErrors('statusId') } onChange={this.handleFormChange} variant="outlined" name="statusId" value={this.state.form.statusId} id="status" className="form-select" inputProps={{ 'aria-label': 'Without label' }}>
                                                    { this.checkIfApiLoading() === false ? this.renderStatusItems() : null }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid> */}
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </FormControl>
                </Grid>
                <MaintenanceTemplate
                    handleFilterCheckList={(value) => this.handleFilterCheckList(value)}
                    detachData={this.detacher} 
                    attachData={this.attacher.bind(this)}   
                    machineDetail={this.state.machineDetail}
                    templates={this.props.templates ? this.props.templates : this.state.templates} 
                    machineChecklist={this.state.machineChecklist}
                    brands={this.props.brandList ? this.filterBrands(this.props.brandList) : this.filterBrands(this.state.brandList)}
                    macSubTypes={this.props.allMacSubTypeList ? this.props.allMacSubTypeList : this.state.allMacSubTypeList}
                ></MaintenanceTemplate>
                {/* <TemplateControl header={ this.state.tableHeaders } title={'MAINTENANCE TEMPLATES'} data={this.state.tableData} link="/template"></TemplateControl> */}
                <div className="button-group">
                    <Button onClick={this.handleCancel} variant="contained">{this.state.languageProps.CANCEL}</Button>
                    <Button onClick={this.submitForm} variant="contained" color="primary">{this.state.languageProps.SAVE}</Button>
                </div>
          </div>
      );
  }

  render(){
    return (
        <MainPage className="machine-form">
            <MainPageHeader title="Machines" search={false} back={'/machines'} />
            <MainPageBody>
                { this.checkIfApiLoading() === false ? this.content() : null }
            </MainPageBody>
        </MainPage>
    );
  }
}

const mapStateToProps = state => {
    let {translationReducer} = state
    let apiLoading = {
        machineStatusListLoading: state.statusListReducer.loading,
        macTypeListLoading: state.macTypeListReducer.loading,
        macSubTypeListLoading: state.macSubTypeListReducer.loading,
        brandListLoading: state.brandListReducer.loading,
        motorTypeListLoading: state.motorTypeListReducer.loading,
        conditionListLoading: state.conditionListReducer.loading,
        supplierListLoading: state.supplierListReducer.loading,
        frequencyListLoading: state.frequencyListReducer.loading,
        areaListLoading: state.areaListReducer.loading,
        attachmentListLoading: state.attachmentListReducer.loading,
    }
    let data = {
        userAccess: state.authReducer.access,
        machineDetail: state.machineListReducer.machineDetail,
        machineChecklist: state.machineListReducer.machineChecklist,
        statusList: state.statusListReducer.statusList,
        machineStatusList: state.statusListReducer.machineStatusList,
        macTypeList: state.macTypeListReducer.macTypeList,
        macSubTypeList: state.macSubTypeListReducer.macSubTypeList,
        allMacSubTypeList: state.macSubTypeListReducer.allMacSubTypeList,
        brandList: state.brandListReducer.brandList,
        motorTypeList: state.motorTypeListReducer.motorTypeList,
        conditionList: state.conditionListReducer.conditions,
        supplierList: state.supplierListReducer.supplierList,
        frequencyList: state.frequencyListReducer.frequencyList,
        areaList: state.areaListReducer.areaList,
        attachmentList: state.attachmentListReducer.attachmentList,
        meta: state.machineListReducer.meta,
        error: state.machineListReducer.error,
        inputReducer: state.inputReducer,
        templates: state.machineListReducer.templates,
        buildings: state.buildingListReducer.buildingList,
        reference: state.preferenceReducer.reference
    }

    return {
        ...apiLoading,
        ...data,
        ...translationReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getMachineById: (id) => dispatch(getMachineById(id)),
        getMachineStatus: () => dispatch(getMachineStatus()),
        getMachineStatusv2: () => dispatch(getMachineStatusv2()),
        getStatus: () => dispatch(getStatus()),
        getMacTypes: () => dispatch(getMacTypes()),
        getMacSubTypes: (isActive,macTypeId) => dispatch(getMacSubTypes(isActive,macTypeId)),
        getAllMacSubTypes: () => dispatch(getAllMacSubTypes()),
        getBrands: () => dispatch(getBrands()),
        getTemplates: (isActive, brandId, macSubTypeId, searchString) => 
            dispatch(getTemplates(isActive, brandId, macSubTypeId, searchString)),
        getMotorTypes: () => dispatch(getMotorTypes()),
        getConditions: () => dispatch(getConditions()),
        getSuppliers: () => dispatch(getSuppliers()),
        getFrequencies: () => dispatch(getFrequencies()),
        getAreas: () => dispatch(getAreas()),
        getBuildings: () => dispatch(getBuildings()),
        getAttachments: () => dispatch(getAttachments()),
        getSystemPreference: () => dispatch(getSystemPreferenceReference()),
        postMachineNewItem: (payload) => dispatch(postMachineNewItem(payload)),
        putUpdateMachine: (id,payload) => dispatch(putUpdateMachine(id,payload)),
        notificationError: (message) => dispatch(notificationError(message)),
        inputFocusSuccess: () => dispatch(inputFocusSuccess()),
    }
}

MachineForm = connect(mapStateToProps,mapDispatchToProps)(MachineForm);

export default MachineForm;
