import React from 'react';
import moment from 'moment';
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'
import ReactExport from 'react-export-excel';
import { checkIfApiLoading, checkPageActionAccess, checkPageAccess, checkPageAccessField } from '../../../utils/helper';
import ExcelLoading from '../../../components/Modal/ExcelLoading/ExcelLoading';
import { showModal, closeModal } from '../../../redux/actions/Modal/modalActions';
import { notificationSuccess, notificationError } from '../../../redux/actions/Notification/notificationActions';
import { getAttachments } from '../../../redux/actions/Attachment/attachmentActions';
import { 
    getMachines, 
    getMachinesPagination, 
    machineListUpdatePage, 
    machineListCloseToggle, 
    putRetireMachine,
    uncheckMachineTableCheckboxById,
    renderLocation,
    putUnretireMachine, 
} from '../../../redux/actions/Machine/machineActions';
import { getMacTypes } from '../../../redux/actions/MacTypes/MacTypeActions';
import { getMachineStatus, getMachineStatusv2 } from '../../../redux/actions/Status/StatusActions';
import { connect } from "react-redux";
import MainPage from '../../../components/MainPage/MainPage';
import MainPageHeader from '../../../components/MainPageHeader/MainPageHeader';
import MainPageBody from '../../../components/MainPageBody/MainPageBody';
import MainTable from '../../../components/MainTable/MainTable';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import dictionary from '../../../redux/actions/Translation/dictionary'
import './Machines.scss';
import MovingModal from './MovingModal';
import { PAGE_ENUMS } from '../../../utils/constants';
import { apiSettings, tokenGet, tokenPut } from '../../../utils/api/CoreApi';
import { downloadExcel } from '../../../utils/excel';
import BulkUpload from '../../../components/Modal/BulkUpload/BulkUpload';
import { formatDate } from '../../../utils/date';
import { Divider, FormLabel } from '@material-ui/core';
import { FilterButton, FilterMenu } from '../../../components/Filters/styles';
import FilterComponent from '../../../components/Filters/FilterComponent';
import BulkAction from './Component/BulkAction';
import MachineSummary from './MachineSummary';

class Machines extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isMovingOpened: false,
            forceUpdate: null,
            pageId: 1,
            tooltipTheme: createMuiTheme({
                overrides: {
                    MuiTooltip: {
                        tooltip: {
                            fontSize: "1rem",
                        }
                    }
                }
            }),
            filterStatusId: [],
            filterIsActive: '',
            filterCounting: 0,
            dateNow: moment(),
            resetExport: false,
            tableHeader: [
                {
                    value: '#',
                    size: '15px'
                },
                {
                    value: 'MACHINE NO',
                    size: '90px'
                },
                {
                    value: 'MODEL NO',
                    size: '90px'
                },
                {
                    value: 'TYPE',
                    size: '40px'
                },
                {
                    value: 'SUB TYPE',
                    size: '70px'
                },
                {
                    value: 'BRAND',
                    size: '60px'
                },
                {
                    value: 'MOTOR TYPE',
                    size: '100px'
                },
                {
                    value: 'PURCHASE DATE',
                    size: '120px'
                },
                {
                    value: 'SERIAL NO',
                    size: '90px'
                },
                {
                    value: 'ASSET NO',
                    size: '90px'
                },
                {
                    value: 'BUILDING',
                    size: '100px'
                },
                {
                    value: 'LOCATION',
                    size: '90px'
                },
                {
                    value: 'LAST MAINT.',
                    size: '90px'
                },
                {
                    value: 'LAST REPAIR',
                    size: '90px'
                },
                {
                    value: 'M/C CONDITION',
                    size: '130px'
                },
                {
                    value: 'STATUS',
                    size: '90px'
                },
            ],
            hiddenColumns: [
                {
                    header: 'MOTOR TYPE',
                    value: 'motorTypeId_desc'
                },
                {
                    header: 'PURCHASE DATE',
                    value: 'aquisitionDt'
                },
                {
                    header: 'SERIAL NO',
                    value: 'serialNo'
                },
                {
                    header: 'ASSET NO',
                    value: 'assetNo'
                },
                {
                    header: 'BUILDING',
                    value: 'building'
                },
                {
                    header: 'LOCATION',
                    value: 'location'
                },
                {
                    header: 'LAST MAINT.',
                    value: 'lastPMDt'
                },
                {
                    header: 'LAST REPAIR',
                    value: 'lastRepairedDt'
                },
            ],
            dataSet1: [
                {
                    name: "Johson",
                    amount: 30000,
                    sex: 'M',
                    is_married: true
                },
                {
                    name: "Monika",
                    amount: 355000,
                    sex: 'F',
                    is_married: false
                },
                {
                    name: "John",
                    amount: 250000,
                    sex: 'M',
                    is_married: false
                },
                {
                    name: "Josef",
                    amount: 450500,
                    sex: 'M',
                    is_married: true
                }
            ],
            currentPage: 1,
            perPage: 10,
            languageProps: Object.assign({}, { ...this.changeLang() }),
            translationTimestamp: undefined,
            searchText: '',
            openBulkUpload: false,
            openAnchor: null,
        }
    }

    changeLang = () => {
        let { IN_PROCESS, CHANGEOVER, NOT_AVAIL, INACTIVE, MAINTENANCE, REPAIR, READY, AVAILABLE, WORKING, ALL, ADD, RETIRE, YES, NO, EXPORT_TO_FILE, MACHINE, MODEL_NO, MACHINE_TYPE, MACHINE_SUBTYPE, BRAND, MOTOR_TYPE, PURCHASE_DATE, SERIAL_NO, ASSET_NO, MAIN_FREQ, BUILDING, BUILDINGS, LOCATION, LPM, LPR, STATUS, CONDITION, ATTACHMENT, RENTAL, Active, RFID, Supplier, MACHINE_NO, ACTIVE, DATE_OF_PURCHASE, MAINTENANCE_FREQ, LAST_MAINTENANCE, NEXT_MAINTENANCE, NOTIFICATIONS: { there_are, machines_that_cannot_be_retired }, LAST_MAINT, NEXT_MAINT_DATE, MAINTENANCE_STATUS, LAST_REPAIR, MACHINE_STATUS, MAKE_INACTIVE, SUPPLIER_NAME } = dictionary.GET()

        console.log("---dictionary.GET()----", dictionary.GET());
        return dictionary.TRANSLATE({ IN_PROCESS, CHANGEOVER, NOT_AVAIL, INACTIVE, MAINTENANCE, REPAIR, READY, AVAILABLE, WORKING, ALL, ADD, RETIRE, YES, NO, EXPORT_TO_FILE, MACHINE, MODEL_NO, MACHINE_TYPE, MACHINE_SUBTYPE, BRAND, MOTOR_TYPE, PURCHASE_DATE, SERIAL_NO, ASSET_NO, MAIN_FREQ, BUILDING, BUILDINGS, LOCATION, LPM, LPR, STATUS, CONDITION, ATTACHMENT, RENTAL, Active, RFID, Supplier, MACHINE_NO, ACTIVE, DATE_OF_PURCHASE, MAINTENANCE_FREQ, LAST_MAINTENANCE, NEXT_MAINTENANCE, MAINTENANCE_STATUS, there_are, machines_that_cannot_be_retired, LAST_MAINT, LAST_REPAIR, NEXT_MAINT_DATE, MAINTENANCE_STATUS, MACHINE_STATUS, MAKE_INACTIVE, SUPPLIER_NAME })
    }

    sanitize = (value) => {
        let { languageProps } = this.state
        // console.log("---languageProps----", languageProps);
        // console.log("---value----", value);

        return {
            'WORKING': languageProps.WORKING,
            'AVAILABLE': languageProps.AVAILABLE,
            'ALL': languageProps.ALL,
            'ACTIVE': languageProps.ACTIVE,
            'READY': languageProps.READY,
            'REPAIR': languageProps.REPAIR,
            'MAINTENANCE': languageProps.MAINTENANCE,
            'INACTIVE': languageProps.INACTIVE,
            'CHANGEOVER': languageProps.CHANGEOVER,
            'IN-PROCESS': languageProps.IN_PROCESS,
        }[value] ?? value
    }
    checkIfApiLoadingForExport() {
        let flag = checkIfApiLoading([
            this.props.machinesLoading,
            this.props.machineViewLoading,
            this.props.machineStatusListLoading,
            this.props.statusListLoading,
            this.props.macTypeListLoading,
            this.props.brandListLoading,
            this.props.motorTypeListLoading,
        ]);
        return flag;
    }

    checkIfApiLoading() {
        let flag = checkIfApiLoading([
            this.props.machinesLoading,
            this.props.machineStatusListLoading,
            this.props.statusListLoading,
            this.props.macTypeListLoading,
            this.props.brandListLoading,
            this.props.motorTypeListLoading,
        ]);
        return flag;
    }

    checkIfStatusLoading() {
        let flag = checkIfApiLoading([
            this.props.statusListLoading,
            this.props.machineStatusListLoading
        ]);
        return flag;
    }

    renderMachineType(id) {
        let machine = this.props.macTypeList.filter((item) => item.id === id);
        if (machine.length > 0) {
            return machine[0].macType;
        }
    }

    renderBrand(id) {
        let brand = this.props.brandList.filter((item) => item.id === id);
        if (brand.length > 0) {
            return brand[0].brand;
        }
    }

    renderStatusClass(status) {
        if (status === 'INACTIVE') {
            return 'status not-working'
        }
        if (status === 'REPAIR') {
            return 'status in-repair'
        }
        else if (status === 'MAINTENANCE') {
            return 'status maintenance'
        }
        else {
            return 'status active'
        }
    }

    renderStatus(isActive) {
        if (isActive) return 'Active';
        return 'Inactive';
    }

    renderMotorType(id) {
        let motorType = this.props.motorTypeList.filter((item) => item.id === id);
        if (motorType.length > 0) {
            return motorType[0].motorType
        }
        else {
            return ''
        }
    }

    renderText(field) {
        if (field === null || field === '') {
            return '-'
        }
        else {
            return field
        }
    }

    renderMaintenFreq(field) {
        if (field === null || field === '') {
            return '-'
        }
        else if (field === 'yearly') {
            return 'Yearly'
        } else if (field === 'MO') {
            return 'MO'
        } else {
            return field
        }
    }


    renderTextWithCapitalization(field) {
        if (field === null || field === '' || field === 'null') {
            return '-'
        }
        else {
            let capitalized = field.split(' ').map(item =>
                item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(' ');
            return capitalized
        }
    }

    renderCheckbox(check) {
        if (check === 1 || check === true) {
            return this.state.languageProps.YES
        }
        else {
            return this.state.languageProps.NO
        }
    }

    renderLine() {
        if (this.props.machineDetail) {
            if (this.props.machineDetail.mfgLineId === null) {
                return this.props.machineDetail.areaId_desc
            }
            else {
                return this.props.machineDetail.mfgLineId_desc + ' - ' + this.props.machineDetail.station
            }
        }
    }

    handleExportToExcel = () => {
        const { filterStatusId, filterIsActive, searchText } = this.state;
        const query = {
            isActive: filterIsActive.length === 1 ? filterIsActive[0] : 'all',
            statusId: filterStatusId.includes('all') 
                || filterStatusId == '' 
                ? 'all' 
                : filterStatusId.join(','),
            searchString: searchText || 'all',
            currentPage: 1,
        };
        this.props.showModal('excelLoading');
        let title = moment().format('DD-MM-YYYY') + '_MachinesList';

        tokenGet(`${apiSettings.apiURLReferences}/productconfig?isRefOnly=true`)
        .then((response) => {
            if(response.data.reference.length > 0){
                tokenGet(`${apiSettings.apiURLMachines}/machines?${new URLSearchParams(query)}`)
                .then((response2) => {
                    this.handleDownloadExcel(title, response2.data.machines.result, response.data.reference);
                })
                .catch((error) => {
                    this.props.closeModal();
                })
            }
        })
        .catch((error) => {
            this.props.closeModal();
        })

    }

    handleDownloadExcel = (title, machines, reference) => {
        let dynamicReferenceHeader = [];
        let exportData = [];

        // Dynamic Reference header & Translate
        for (let i = 0; i < reference.length; i++) {
            if (reference[i].translations && reference[i].translations.length > 0 && reference[i].translations[0].translatedDesc != ''){
                dynamicReferenceHeader.push(reference[i].translations[0].translatedDesc);
            } else{
                dynamicReferenceHeader.push(reference[i].name);
            }
        }
        
        for (let i = 0; i < machines.length; i++) {
            let item = machines[i];
            let location = null;
            if (item.station === '' || item.station === null || item.station === '0'){
                location = item.areaId_desc;
            }
            else{
                location = item.mfgLineId_desc + ' - ' + item.station
            }

            // Dynamic Reference value
            let dynamicReferenceValue = [];
            let references = typeof(item.reference) == 'string' ? JSON.parse(`[${item.reference}]`) : null;
            for (let j = 0; j < reference.length; j++) {
                let reference = references != null ? references[j].value : '';
                dynamicReferenceValue.push(reference);
            }
            
            exportData.push([
                this.renderText(item.machine),
                this.renderText(item.model),
                this.renderText(item.macTypeId_desc),
                this.renderText(item.macSubTypeId_desc),
                this.renderText(item.brandId_desc),
                this.renderText(item.motorTypeId_desc),
                this.renderText(item.supplier_name),
                item.aquisitionDt ? moment(item.aquisitionDt).format('MM/DD/YYYY') : '-',
                item.serialNo,
                item.assetNo,
                this.renderText(item.frequencyQty),
                this.renderText(item.frequencyId_desc),
                this.renderText(item.buildingDesc),
                renderLocation(location),
                this.renderText(`${item.areaId_desc ? item.areaId_desc : '-'} / ${item.mfgLineId_desc !== null ? item.mfgLineId_desc : '-'}`),
                this.renderText( item.mfgLineId_desc !== null ? item.station : '0'),
                item.lastPMDt ? moment(item.lastPMDt).format('MM/DD/YYYY') : '-',
                item.pmPlanDt ? moment(item.pmPlanDt).format('MM/DD/YYYY') : '-',
                this.renderText(item.maintenanceCheckList),
                item.lastRepairedDt ? moment(item.lastRepairedDt).format('MM/DD/YYYY') : '-',
                this.renderText(item.repairCheckList),
                this.renderAttachment(item.attachmentId),
                this.renderCheckbox(item.isRental),
                this.renderText(item.rfid),
                this.sanitize(this.renderText(item.statusId_desc === 'INACTIVE' ? 'N/A' : item.statusId_desc)),
                this.renderStatus(item.isActive),
                this.renderText(item.remarks),
                ...dynamicReferenceValue
            ]);
        }

        downloadExcel(
            title,
            [
                this.state.languageProps.MACHINE_NO,
                this.state.languageProps.MODEL_NO,
                this.state.languageProps.MACHINE_TYPE,
                this.state.languageProps.MACHINE_SUBTYPE,
                this.state.languageProps.BRAND,
                this.state.languageProps.MOTOR_TYPE,
                this.state.languageProps.SUPPLIER_NAME,
                this.state.languageProps.PURCHASE_DATE,
                this.state.languageProps.SERIAL_NO,
                this.state.languageProps.ASSET_NO,
                'Maintenance Freq Quantity'.translate(),
                'Maintenance Freq Unit'.translate(),
                'BUILDING'.translate(),
                this.state.languageProps.LOCATION,
                'Area / Line Code'.translate(),
                'Station No.'.translate(),
                this.state.languageProps.LAST_MAINT,
                'Next Maint.'.translate(),
                'Maint. Checklist'.translate(),
                this.state.languageProps.LAST_REPAIR,
                'Repair Checklist'.translate(),
                this.state.languageProps.ATTACHMENT,
                this.state.languageProps.RENTAL,
                this.state.languageProps.RFID,
                'Machine Condition'.translate(),
                'Status'.translate(),
                'Remarks'.translate(),
                ...dynamicReferenceHeader
            ],
            exportData
        ).then(() => {
            this.props.notificationSuccess('File successfully exported');
            this.props.closeModal();
        })
    }

    checkIfChecked() {
        let check = this.props.machines.filter((item) => { return item.selected.value === true });
        if (check.length > 0) {
            return true
        }
        else {
            return false;
        }
    }

    resetRedirects() {
        localStorage.setItem('redirectToMaintenance', false);
        localStorage.setItem('redirectToTickets', false);
        localStorage.setItem('redirectToCalendar', false);
    }

    renderAttachment(id) {
        let attachment = this.props.attachmentList.filter((item) => { return item.id === id });
        if (attachment.length > 0) {
            attachment = this.renderText(attachment[0].desc1);
        }
        else {
            attachment = '-'
        }
        return attachment;
    }

    componentDidMount() {
        let page = this.props.currentPage
        if (page !== 0) {
            this.setState({ currentPage: page })
        }
        this.resetRedirects();
        if (localStorage.getItem('addedMachine') === 'true') return localStorage.setItem('addedMachine', false);
        if (localStorage.getItem('noload') === 'true') {
            if (localStorage.getItem('statusId')) {
                let statusId = localStorage.getItem('statusId');
                this.setState({ filterStatusId: statusId });
                localStorage.setItem('noload', false);
            }
        }
        this.props.getMachineStatusv2();
        this.props.getMacTypes();
        this.fetchMachines();
        this.props.getAttachments();
    }

    fetchMachines(options = {}){
     const { filterStatusId, filterIsActive } = this.state;
      this.props.getMachinesPagination(
        true,
        this.state.currentPage,
        this.state.perPage,
        filterStatusId.includes('all') 
            || filterStatusId == '' 
            ? 'all' 
            : filterStatusId.join(','),
        options.searchString,
        filterIsActive.length === 1 ? filterIsActive[0] : 'all',
      );
      this.handleFilterCounting();
    }

    handleFilterString(filterString){
      this.setState({ currentPage: 1, searchText: filterString }, ()=>{
        this.fetchMachines({
          searchString: filterString
        })
      });

    }

    componentDidUpdate(){
        if(this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp){
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                languageProps: Object.assign({}, { ...this.changeLang() })
            })
        }
    }
    componentWillUnmount() {
        localStorage.setItem('noload', 'true');
        localStorage.setItem('statusId', this.state.filterStatusId);
        // this.props.machineListCloseToggle();
    }

    refreshData(){
      window.location.reload();
    }

    handleStatusFilterChange = (data) => {
      this.setState({ filterStatusId: data , currentPage: 1 },
        ()=>{
            this.fetchMachines();
        }
      );
    }

    handleIsActiveFilterChange = (data) => {
        this.setState({ filterIsActive: data, currentPage: 1 },
            () => {
                this.fetchMachines();
            }
        );
    }

    handleFilterCounting = () => {
        let filterCounting = 0;
        this.state.filterIsActive.length > 0 && filterCounting++;
        this.state.filterStatusId.length > 0 && filterCounting++;
        this.setState({ filterCounting  });
    }

    onChangePage = (value) => {
      this.setState({
        currentPage: value
      }, ()=>{
        this.fetchMachines({
            searchString: this.state.searchText
        });
      });

    }

    onPerPageChanged = (value) => {
      this.setState({
        perPage: value
      }, ()=>{
        this.fetchMachines({
            searchString: this.state.searchText
        });
      });

    }


    getMachineUpdatedDate() {
        if (this.props.machineDetail !== null) {
            return this.props.machineDetail.updatedDt === null ? formatDate(this.props.machineDetail.createdDt) : formatDate(this.props.machineDetail.updatedDt);
        }
    }

    machineActiveTickets(machineId, callback) {
        tokenGet(apiSettings.apiURLMachines + '/machines/has-active-tk/' + machineId).then((response) => {
            callback(response);
        });
    }

    machineCancelTickets(machineId, ticketIds) {
        tokenPut(apiSettings.apiURLMachines + '/machines/cancel-ticket/' + machineId, ticketIds).then(response => {
            this.props.notificationSuccess('Successfully inactive machine(s)');
            this.props.getMachines(true, 1);
        })
    }

    renderTableData() {
        return (
            <MainTable 
                hiddenColumns={this.state.hiddenColumns} 
                tableName="machines" 
                height="100%" 
                onPerPageChanged={this.onPerPageChanged} 
                onChangePage={this.onChangePage} 
                page={this.props.currentPage} 
                rowsPerPage={this.state.perPage} 
                highlight={this.state.searchText} 
                header={this.state.tableHeader} 
                data={this.props.machines}
                meta={this.props.meta} 
                rowsPerPageOptions={[10, 25, 50, 100]} 
                link={"/machine-detail"}
            >
                <MuiThemeProvider theme={this.state.tooltipTheme}>
                    <div className="toggle-detail">
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Grid className="header-detail" container spacing={0}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={5}>
                                            <Tooltip placement="top-start" title={this.props.machineDetail ? this.renderText(this.props.machineDetail.machine) : null}>
                                                <div className="machineNo text-ellipsis title">{this.props.machineDetail ? this.renderText(this.props.machineDetail.machine) : null}</div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={2} />
                                        <Grid item xs={5}>
                                            {this.props.machineDetail ? <div className={"text-align-right " + this.renderStatusClass(this.props.machineDetail.statusId_desc)}>{this.props.machineDetail.statusId_desc == undefined ? this.props.machineDetail.statusId_desc : this.props.machineDetail.statusId_desc.translate()}</div> : null}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0}>
                                        <Grid item xs={5}>
                                            <Grid item xs={12}>
                                                <div className="yti header-label">{this.props.machineDetail ? this.renderLine() : null}</div>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2} />
                                        <Grid item xs={5}>
                                            <Grid item xs={12}>
                                                <div className="yti header-label text-align-right">{this.props.machineDetail ? this.getMachineUpdatedDate() : null}</div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0}>
                                        <Grid item xs={5}>
                                            <Grid item xs={12}>
                                                <div className="createdBy header-label">{this.props.machineDetail ? this.renderText(this.props.machineDetail.createdBy) : null}</div>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2} />
                                    </Grid>
                                </Grid>
                                <Grid className="body-detail" container spacing={0}>
                                    <Grid item xs={6}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label">{this.state.languageProps.MACHINE_NO}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Tooltip placement="top-start" title={this.props.machineDetail ? this.renderText(this.props.machineDetail.machine) : null}>
                                                            <div className="body-value text-ellipsis">{this.props.machineDetail ? this.renderText(this.props.machineDetail.machine) : null}</div>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label">{this.state.languageProps.MACHINE_TYPE}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="body-value">{this.props.machineDetail ? this.renderTextWithCapitalization(this.props.machineDetail.macTypeId_desc) : null}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label">{this.state.languageProps.MACHINE_SUBTYPE}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="body-value">{this.props.machineDetail ? this.renderText(this.props.machineDetail.macSubTypeId_desc) : null}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={0}>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label">{this.state.languageProps.ATTACHMENT}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="body-value">{this.props.machineDetail ? this.renderText(this.props.machineDetail.attachmentId_desc) : null}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label">{this.state.languageProps.RENTAL}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="body-value">{this.props.machineDetail ? this.renderCheckbox(this.props.machineDetail.isRental) : null}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {/* <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label">{ this.state.languageProps.ACTIVE}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="body-value">{this.props.machineDetail ? this.renderCheckbox(this.props.machineDetail.isActive) : null}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid className="body-detail" container spacing={0}>
                                    <Grid item xs={6}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label">{this.state.languageProps.BRAND}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="body-value">{this.props.machineDetail ? this.renderTextWithCapitalization(this.props.machineDetail.brandId_desc) : null}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label">{this.state.languageProps.MODEL_NO}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Tooltip placement="top-start" title={this.props.machineDetail ? this.renderText(this.props.machineDetail.model) : null}>
                                                            <div className="body-value text-ellipsis">{this.props.machineDetail ? this.renderText(this.props.machineDetail.model) : null}</div>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label">{this.state.languageProps.MOTOR_TYPE}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="body-value">{this.props.machineDetail ? this.props.machineDetail.motorTypeId_desc : null}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label">{this.state.languageProps.RFID}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Tooltip placement="top-start" title={this.props.machineDetail ? this.renderText(this.props.machineDetail.rfid) : null}>
                                                            <div className="body-value text-ellipsis">{this.props.machineDetail ? this.renderText(this.props.machineDetail.rfid) : null}</div>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label">{this.state.languageProps.SERIAL_NO}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Tooltip placement="top-start" title={this.props.machineDetail ? this.renderText(this.props.machineDetail.serialNo) : null}>
                                                            <div className="body-value text-ellipsis">{this.props.machineDetail ? this.renderText(this.props.machineDetail.serialNo) : null}</div>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label">{this.state.languageProps.ASSET_NO}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Tooltip placement="top-start" title={this.props.machineDetail ? this.renderText(this.props.machineDetail.assetNo) : null}>
                                                            <div className="body-value text-ellipsis">{this.props.machineDetail ? this.renderText(this.props.machineDetail.assetNo) : null}</div>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {/* new testing */}
                                            {/* <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label">{this.state.languageProps.ASSET_NO}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Tooltip placement="top-start" title={this.props.machineDetail ? this.renderText(this.props.machineDetail.assetNo) : null}>
                                                            <div className="body-value text-ellipsis">{this.props.machineDetail ? this.renderText(this.props.machineDetail.assetNo) : null}</div>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid> */}
                                            {/* end new testing */}
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid className="body-detail" container spacing={0}>
                                    <Grid item xs={6}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label">{this.state.languageProps.DATE_OF_PURCHASE}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="body-value">{this.props.machineDetail ? formatDate(this.props.machineDetail.aquisitionDt) : null}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {/* <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label">{ this.state.languageProps.SUPPLIER}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="body-value">{this.props.machineDetail ? this.renderText(this.props.machineDetail.supplierId_desc) : null}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid> */}
                                            {/* <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label">{ this.state.languageProps.CONDITION}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="body-value">{this.props.machineDetail ? this.renderText(this.props.machineDetail.conditionId_desc) : null}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label">{this.state.languageProps.MAINTENANCE_FREQ}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="body-value">{this.props.machineDetail ? this.renderMaintenFreq(this.props.machineDetail.frequencyId_desc) : null}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label">{this.state.languageProps.LAST_MAINTENANCE}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="body-value">{this.props.machineDetail ? formatDate(this.props.machineDetail.lastPMDt) : null}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="body-label">{this.state.languageProps.NEXT_MAINTENANCE}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="body-value">{this.props.machineDetail ? formatDate(this.props.machineDetail.pmPlanDt) : null}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </MuiThemeProvider>
            </MainTable>
        );
    }

    renderFilterItems() {
        let { languageProps } = this.state
        // let status = [];
        // status.push({
        //     id: 0,
        //     value: 'ALL'
        // })
        // this.props.machineStatusList.map((item) => {
        //     status.push({
        //         id: item.id,
        //         value: item.status
        //     })
        //     return '';
        // })
        let menuItems = [];
        if (this.props.machineStatusList) {
            menuItems = this.props.machineStatusList.map((item) => {
                return (
                    <MenuItem value={item.id}>{this.sanitize(item.status)}</MenuItem>
                );
            });
        }
        return menuItems;
    }

    handleActionMakeMachineAvailable = () => {
       const ids = this.props.machines
            .filter((item) => (item.selected.value && !item.selected.isActive))
            .reduce((acc, { selected }) => acc.concat(selected.id.toString()), []);
       if(ids.length == 0){
            this.props.notificationError('Please select any machine Inactive'.translate());
            return false;
       }
       confirmAlert({
        title: 'Are you sure you want to make this machine active and available'.translate() + '?',
        message: '', 
        buttons: [
            { label: 'Yes'.translate(),
                onClick: () => {
                    this.props.putUnretireMachine({ ids, updatedDt: moment() }, this.props.meta.currentPage);
                    this.props.uncheckMachineTableCheckboxById(this.props.machines, ids);
                    this.setState({ forceUpdate: null });
                },
            },
            { label: 'No'.translate() },
        ],
        });
    };

    checkStatusIfRetire(statusId) {
        if (statusId === 4 || statusId === 5 || statusId === 6) {
            return false
        }
        else {
            return true;
        }
    }

    handleActionMakeMachineInactive = () => {
        let { languageProps } = this.state
        let validate = this.props.machines.filter((item) => { return item.selected.value === true && (item.selected.isLineReq === true || item.selected.statusId === 4 || item.selected.statusId === 5 || item.selected.statusId === 6) });
        if (validate.length > 0) {
            if (validate.length === 1) {
                this.props.notificationError('Cannot inactive machine');
                let ids = [];
                ids.push(validate[0].selected.id);
                this.props.uncheckMachineTableCheckboxById(this.props.machines, ids);
                this.setState({ forceUpdate: null })
            }
            else {
                this.props.notificationError(languageProps.there_are + validate.length + languageProps.machines_that_cannot_be_retired);
                let ids = validate.map((item) => {
                    return item.selected.id
                });
                this.props.uncheckMachineTableCheckboxById(this.props.machines, ids);
                this.setState({ forceUpdate: null })
            }
            return false;
        }

        let machineId = this.props.machines.filter((item) => { return item.selected.value === true }).map((item) => {
            return item.selected.id;
        });

        if (this.props.machineDetail) {
            if (this.props.machineDetail.isLineReq === true) {
                this.props.notificationError('Cannot Inactivate machines that are still in Production Area'.translate());
                return false;
            }
            else if (this.checkStatusIfRetire(this.props.machineDetail.statusId) === false) {
                this.props.notificationError('Cannot inactive machine with ' + this.props.machineDetail.statusId_desc + ' status');
                return false;
            }
            else if (!this.props.machineDetail.isActive || this.props.machineDetail.statusId_desc === 'INACTIVE' || this.props.machineDetail.statusId_desc === 'NOT AVAIL/RETIRED' || this.props.machineDetail.statusId_desc === 'RETIRED') {
                this.props.notificationError('This machine is already retired'.translate());
                return false;
            }
            
            this.machineActiveTickets(machineId, ({ data }) => {
                if (data.machineHasTicket){
                    let ticketIds = [];
    
                    if (data.maintenanceTicket) {
                        data.maintenanceTicket.map(item => {
                            ticketIds.push(item.ticketId);
                        })
                    }
    
                    if (data.repairTicket) {
                        data.repairTicket.map(item => {
                            ticketIds.push(item.ticketId);
                        })
                    }
    
                    confirmAlert({
                        title: 'Are you sure you want to make the machine inactive? The related open repair ticket and maintenance ticket will be cancelled automatically'.translate() + '?',
                        message: '',
                        buttons: [
                            {
                                label: this.state.languageProps.YES,
                                onClick: () => {
                                    this.machineCancelTickets(machineId, { ticketIds });
                                }
                            },
                            {
                                label: this.state.languageProps.NO,
                            }
                        ]
                    });
                } else {
                    confirmAlert({
                        title: 'Are you sure you want to make this machine inactive'.translate() + '?',
                        message: '',
                        buttons: [
                            {
                                label: this.state.languageProps.YES,
                                onClick: () => {
                                    this.props.putRetireMachine({ ids: machineId, updatedDt: moment() }, this.props.meta.currentPage);
                                }
                            },
                            {
                                label: this.state.languageProps.NO,
                            }
                        ]
                    });
                }
            });
        }
    }

    preventLinkRedirect = (e) => {
        e.preventDefault();
    }

    render() {
        let { languageProps } = this.state
        return (
            <MainPage className='machines-overview'>
                <BulkUpload 
                    openBulkUpload={this.state.openBulkUpload} 
                    onFinish={() => this.fetchMachines()} 
                    handleClose={() => this.setState({ openBulkUpload: false })  } 
                    {...this.props} 
                />
                <MainPageHeader 
                    onFilter={(filterString)=> this.handleFilterString(filterString)} 
                    searchLabel={"Machines"} 
                    searchString={this.state.searchText} 
                    filters={{ statusId: this.state.filterStatusId }} 
                    name="machines"
                    title="Machines" 
                    search={true} 
                    rows={this.props.meta.count} 
                    displayedRows={this.props.displayedRows} 
                />
                <MainPageBody>
                    <Grid container justifyContent='space-between' >
                        <Grid className="actions" item xs={7}>
                            <Grid container spacing={2}>
                                <Grid container spacing={2} item xs={5}>
                                    <Grid item xs={6}>
                                        <div className={'action-item ' + (checkPageActionAccess(this.props.userAccess, { pageId: 1, pageAction: 'Add' }) ? '' : 'disabled')}>
                                            <Link onClick={checkPageActionAccess(this.props.userAccess, { pageId: 1, pageAction: 'Add' }) ? null : this.preventLinkRedirect} to="/machine-add">
                                                <Button disabled={checkPageActionAccess(this.props.userAccess, { pageId: 1, pageAction: 'Add' }) ? false : true} 
                                                    fullWidth variant="outlined" color="primary"
                                                > <span>{this.state.languageProps.ADD}</span> </Button>
                                            </Link>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className={'action-item ' + (checkPageAccessField(this.props.userAccess, { page: PAGE_ENUMS.machines, pageAccess: 'FULL ACCESS' }) ? '' : 'disabled')}>
                                            <Button
                                                disabled={checkPageAccessField(this.props.userAccess, { page: PAGE_ENUMS.machines, pageAccess: 'FULL ACCESS' }) ? false : true}
                                                fullWidth variant="outlined" color="primary"
                                                onClick={() => this.setState({openBulkUpload: true})}
                                            >
                                                {"BULK UPLOAD".translate()}
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} item xs={7}>
                                    <Grid item xs={6}>
                                        <div className={'action-item ' + (checkPageActionAccess(this.props.userAccess, { pageId: 1, pageAction: 'Export to File' }) ? '' : 'disabled')}>
                                            <Button disabled={checkPageActionAccess(this.props.userAccess, { pageId: 1, pageAction: 'Export to File' }) ? false : true} 
                                                onClick={() => checkPageActionAccess(this.props.userAccess, { pageId: 1, pageAction: 'Export to File' }) ? this.handleExportToExcel() : this.preventLinkRedirect} 
                                                fullWidth variant="outlined" color="primary">
                                                <span>{this.state.languageProps.EXPORT_TO_FILE}</span>
                                            </Button>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <BulkAction
                                            loading={this.props.machinesLoading}
                                            disabled={!this.checkIfChecked()}
                                            userAccess={this.props.userAccess}
                                            actionMove={() => this.setState({ isMovingOpened: true })} 
                                            actionInactive={this.handleActionMakeMachineInactive} 
                                            actionAvailable={this.handleActionMakeMachineAvailable} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid className="filters" item xs={5} >
                            <MachineSummary />
                            <FilterButton
                                badge={this.state.filterCounting} 
                                onClick={(e) => this.setState({ openAnchor: e.currentTarget })} />
                            <FilterMenu
                                anchorEl={this.state.openAnchor}
                                keepMounted
                                open={Boolean(this.state.openAnchor)}
                                onClose={() => this.setState({ openAnchor: null })}
                            >
                                <FormControl className='form' fullWidth >
                                    <FormLabel className='formLabel'>Filter By</FormLabel>
                                    <Divider />
                                </FormControl>
                                <FilterComponent
                                    data={[{ id: '1', name: 'Active' }, { id: '0', name: 'Inactive' }]} 
                                    onChange={this.handleIsActiveFilterChange} 
                                    keyValue='id'
                                    keyDisplay='name'
                                    firstDefault={true}
                                    title={this.state.languageProps.STATUS}
                                />
                                <FilterComponent
                                    data={this.props.machineStatusList} 
                                    onChange={this.handleStatusFilterChange} 
                                    keyValue='id'
                                    keyDisplay='status'
                                    title='Machine Condition'
                                    firstDefault={false}
                                    showingAll={true}
                                />
                            </FilterMenu>
                        </Grid>
                    </Grid>
                    {this.renderTableData()}
                </MainPageBody>
                <ExcelLoading />
                { this.state.isMovingOpened ? (
                  <MovingModal
                    open={this.state.isMovingOpened}
                    onClose={()=> this.setState({ isMovingOpened: false })}
                    machines={this.props.machines}
                    onSuccess={()=> this.props.getMachinesPagination()}
                  />
                ) : null }

            </MainPage>
        );
    }
}

const mapStateToProps = state => {
    let { translationReducer } = state
    let apiLoading = {
        machinesLoading: state.machineListReducer.loading,
        machineViewLoading: state.machineListReducer.viewDetailLoading,
        machineStatusListLoading: state.statusListReducer.machineStatusLoading,
        statusListLoading: state.statusListReducer.loading,
        macTypeListLoading: state.macTypeListReducer.loading,
        brandListLoading: state.brandListReducer.loading,
        motorTypeListLoading: state.motorTypeListReducer.loading,
        displayedRows: state.machineListReducer.displayedRows,
    }
    let data = {
        userAccess: state.authReducer.access,
        factoryId: state.authReducer.factoryId,
        searchString: state.machineListReducer.searchString,
        machineDetail: state.machineListReducer.machineDetail,
        statusList: state.statusListReducer.statusList,
        machineStatusList: state.statusListReducer.machineStatusList,
        macTypeList: state.macTypeListReducer.macTypeList,
        brandList: state.brandListReducer.brandList,
        motorTypeList: state.motorTypeListReducer.motorTypeList,
        rows: state.machineListReducer.rows,
        currentPage: state.machineListReducer.currentPage,
        machines: state.machineListReducer.machines,
        attachmentList: state.attachmentListReducer.attachmentList,
        meta: state.machineListReducer.meta,
        error: state.machineListReducer.error
    }

    return {
        ...apiLoading,
        ...data,
        ...translationReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        uncheckMachineTableCheckboxById: (machines, id) => dispatch(uncheckMachineTableCheckboxById(machines, id)),
        getMachines: (reloadCurrentPage, currentPage, statusId, searchString) => dispatch(getMachines(reloadCurrentPage, currentPage, 10, statusId, searchString)),
        getMachinesPagination: (reloadCurrentPage, currentPage, perPage, statusId, searchString, isActive) => dispatch(getMachinesPagination(reloadCurrentPage, currentPage, perPage, statusId, searchString, isActive)),
        getMachineStatus: () => dispatch(getMachineStatus()),
        getMachineStatusv2: () => dispatch(getMachineStatusv2()),
        getMacTypes: () => dispatch(getMacTypes()),
        getAttachments: () => dispatch(getAttachments()),
        getFilteredMachines: (reloadCurrentPage, currentPage, perPage, statusId, searchString) => dispatch(getMachines(reloadCurrentPage, currentPage, perPage, statusId, searchString)),
        machineListUpdatePage: (page) => dispatch(machineListUpdatePage(page)),
        machineListCloseToggle: () => dispatch(machineListCloseToggle()),
        putRetireMachine: (payload, currentPage) => dispatch(putRetireMachine(payload, null, currentPage)),
        putUnretireMachine: (payload, currentPage) => dispatch(putUnretireMachine(payload, null, currentPage)),
        notificationSuccess: (message) => dispatch(notificationSuccess(message)),
        notificationError: (message) => dispatch(notificationError(message)),
        showModal: (modal) => dispatch(showModal(modal)),
        closeModal: () => dispatch(closeModal())
    }
}

Machines = connect(mapStateToProps, mapDispatchToProps)(Machines);

export default Machines;
