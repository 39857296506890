import React, { useState, useEffect, useRef } from 'react';
import MainPage from '../../../components/MainPage/MainPage';
import PageHeader from '../../../components/PageHeader/PageHeader';
import PageBody from '../../../components/PageBody/PageBody';
import CardPaper from './components/CardPaper';
import Actions from './List/Actions';
import ListTable from './List/Table';
import Planning from './List/Planning';
import { breadcrumbs } from './List/breadcrumbs';
import { machineClient } from '../../../utils/api/CoreApi';
import { deserializeList } from './helpers';
import { CO_REQUEST_STATUSES } from '../../../constants/corequest';
import { notificationSuccess, notificationError } from '../../../redux/actions/Notification/notificationActions'
import { connect } from 'react-redux';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { getErrorResponseMessage } from '../../../utils/request';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import useChangeoverActions from './hook';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1
  },
}));

const ChangeOversList = (props) => {
  const classes = useStyles();
  const confirmModalRef = useRef();
  const [requests, setRequests] = useState([]);
  const [parameters, setParameters] = useState({
    perPage: 10,
    page: 0,
    search: null,
    sorting: {},
    filters: {
      statuses: [],
    },
  });
  const [meta, setMeta] = useState({
    totalRows: 0,
  })
  const [isShowPlanning, setIsShowPlanning] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    loadingAction,
    confirmClose,
    confirmDelete
  } = useChangeoverActions(props, confirmModalRef);

  useEffect(() => {
    requestData();
  }, [parameters.page, parameters.perPage, parameters.search, parameters.filters, parameters.sorting]);

  const requestData = () => {
    const { filters, sorting } = parameters;
    const { statuses } = filters;
    const params = {
      page: parameters.page + 1,
      pageSize: parameters.perPage,
      q: parameters.search,
      status: statuses && statuses.length > 0 ? statuses.join(',') : null,
      sorting
    }

    setLoading(true);

    machineClient
      .get("/co-requests", { params })
      .then(({ data }) => {
        const normalizedRows = deserializeList(data.result);
        setRequests(normalizedRows);
        setMeta({ ...meta, totalRows: data.meta.totalRecord });
      }).finally(() => setLoading(false))
  }

  const handleDelete = () => {
    const selectedItems = requests.filter(request => request.isSelected);
    confirmDelete(selectedItems, requestData);
  }

  const handleClose = () => {
    const selectedItems = requests.filter(request => request.isSelected);
    confirmClose(selectedItems, requestData);
  }

  return (
    <MainPage>
      <PageHeader breadcrumbs={breadcrumbs} />
      <PageBody pt={1}>
        <CardPaper>
          <Actions
            requests={requests}
            filters={parameters.filters}
            onSearch={(search) => setParameters({ ...parameters, search })}
            onFilter={(filters) => setParameters({ ...parameters, filters })}
            onPlanning={() => setIsShowPlanning(true)}
            onDelete={() => handleDelete()}
            onClose={() => handleClose()}
          />
          <ListTable
            rows={requests}
            search={parameters.search}
            onRowsChange={(rows) => setRequests(rows)}
            page={parameters.page}
            setPage={(pageNum) => setParameters({ ...parameters, page: pageNum })}
            rowsPerPage={parameters.perPage}
            setRowsPerPage={(rowsPerPage) => setParameters({ ...parameters, perPage: rowsPerPage })}
            sorting={parameters.sorting}
            setSorting={(sorting) => setParameters({ ...parameters, sorting })}
            totalRows={meta.totalRows}
          />
        </CardPaper>
      </PageBody>
      {
        isShowPlanning ? (
          <Planning
            onClose={() => setIsShowPlanning(false)}
          />
        ) : null
      }
      <Backdrop
        sx={{ color: '#fff' }}
        className={classes.backdrop}
        open={loading || loadingAction}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <ConfirmModal ref={confirmModalRef} />
    </MainPage>
  );
}

const mapStateToProps = state => ({...state.translationReducer});
const mapDispatchToProps = {
  notificationSuccess,
  notificationError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeOversList);