import React from 'react';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';

export default function Actions({ 
    onAdd, 
    onEdit, 
    onCopy, 
    onDelete, 
    ...props 
  }) {
  return <div className="actions">
    <IconButton style={{ padding: 5 }} onClick={onAdd}>
      <AddIcon style={{ color: "#1E88E5" }}/>
    </IconButton>
    <IconButton style={{ padding: 5 }} onClick={onEdit}>
      <EditIcon style={{ color: "#1E88E5" }}/>
    </IconButton>
    <IconButton style={{ padding: 5 }} onClick={onCopy}>
      <CopyIcon style={{ color: "#1E88E5" }}/>
    </IconButton>
    <IconButton style={{ padding: 5 }} onClick={onDelete}>
      <DeleteIcon style={{ color: "#1E88E5" }}/>
    </IconButton>
  </div>
}