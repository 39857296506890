import {
    AREA_LIST_REQUEST,
    AREA_LIST_SUCCESS,
    AREA_LIST_ERROR,
    AREA_DETAIL_REQUEST,
    AREA_DETAIL_SUCCESS,
    AREA_DETAIL_ERROR,
    AREA_NEW_REQUEST,
    AREA_NEW_SUCCESS,
    AREA_NEW_ERROR,
    AREA_UPDATE_REQUEST,
    AREA_UPDATE_SUCCESS,
    AREA_UPDATE_ERROR,
    AREA_TRIGGER_HIGHLIGHT,
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    detailLoading: false,
    triggerHighlight: false,
    closeModal: false,
    areaList:[],
    areaDetail: null,
    meta: [],
    error: ''
}

const areaListReducer = (state = initialState, action) => {
    switch (action.type) {
        case AREA_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case AREA_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                areaList: action.payload.areaList,
                meta: action.payload.meta,
                error: ''
            }
        case AREA_LIST_ERROR:
            return {
                ...state,
                loading: false,
                areaList: [],
                meta: [],
                error: action.payload.message
            }
        case AREA_DETAIL_REQUEST:
            return {
                ...state,
                detailLoading: true,
            }
        case AREA_DETAIL_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                areaDetail: action.payload.areaDetail
            }
        case AREA_NEW_REQUEST:
            return {
                ...state,
                detailLoading: true,
                closeModal: false
            }
        case AREA_NEW_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                closeModal: true
            }
        case AREA_UPDATE_REQUEST:
            return {
                ...state,
                detailLoading: true,
                closeModal: false
            }
        case AREA_UPDATE_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                closeModal: true
            }
        case AREA_TRIGGER_HIGHLIGHT:
            return {
                ...state,
                triggerHighlight: action.payload,
                closeModal: false,
            }
        default: 
            return state;
    }
}

export default areaListReducer;