import React from 'react';
import { Box } from '@material-ui/core';
import Typography from '../../components/Typography';
import { withStyles } from '@material-ui/core/styles';

const Label = withStyles({
  root: {
    color: "#8C8C8C",
  }
})(Typography);

const Value = withStyles({
  root: {
    fontSize: 14
  }
})(Typography);

const InfoLabel = ({ label, value })=>{
  return <Box mt={2}>
    <Label>{label}</Label>
    <Value>{value}</Value>
  </Box>
}

export default function AttributesInfo({ changeover }) {
  return <Box>
    <InfoLabel
      label={'Changeover Date'.translate() + '*'}
      value={changeover.coDateDisplay}
    />
    <InfoLabel
      label={'Style'.translate() + '*'}
      value={changeover.style}
    />
    <InfoLabel
      label={'Area'.translate() + '*'}
      value={changeover.area}
    />
    <InfoLabel
      label={'Line'.translate() + '*'}
      value={changeover.line}
    />
    <InfoLabel
      label={'Changeover Type'.translate()}
      value={changeover.type}
    />
    <InfoLabel
      label={'Changeover Checklist'.translate()}
      value={changeover.checklist}
    />
    <InfoLabel
      label={'Product Type'.translate()}
      value={changeover.productType}
    />
    <InfoLabel
      label={'Remarks'.translate()}
      value={changeover.remarks}
    />
  </Box>
}