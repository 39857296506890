import React, { forwardRef } from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


export default withStyles({
  root: {
    fontSize: 14,
    borderRadius: '4px !important', // need important because global.scss MuiButton-root border-redius is important
  },
  contained: {
    boxShadow: 'none', 
  },
  containedPrimary: {
    backgroundColor: '#1689FA',
  },
  containedError: {
    backgroundColor: '#e64747',
    color: '#FFFFFF',
    '&:hover': {
        backgroundColor: '#e64747',
    },
  },
  outlined: {
    color: "#515151",
    border: '1px solid #DFDFDF',
  },
  outlinedPrimary: {
    border: '1px solid #1E88E5',
    color: '#1E88E5'
  },
  textPrimary: {
    color: '#1E88E5'
  },
  textError: {
    color: '#FFFFFF'
  },
  outlinedError: {
    border: '1px solid #e64747',
    color: '#e64747'
  },
  textError: {
    color: '#e64747'
  }
})(forwardRef(({ children, ...props }, ref)=>{
  return <Button
    variant="outlined"
    {...props} 
    ref={ref}
  >
    {children}
  </Button>
}))
