import React from 'react';
import { Breadcrumbs, Link, Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const Container = withStyles({
  root: {
    paddingLeft: 16, 
    paddingRight: 16,
    backgroundColor: "#FFFFFF",
  }
})((props)=>(<Box {...props} py={2}/>))

export default function PageBreadcrumbs({ breadcrumbs }){
  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb">
        {
          breadcrumbs.map((item, index)=>(
            item.href ? (
              <Link key={index} color="inherit" href={item.href}>
                {item.text.translate()}
              </Link>
            ) : (
              <Typography 
                key={index} 
              >
                  {item.text.translate()}
              </Typography>
            )
          ))
        }
      </Breadcrumbs>
    </Container>
  )
}