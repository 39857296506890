import { makeStyles } from "@material-ui/core";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        backgroundColor: '#F2F2F2',
    },
    highlight:
    {
        color: '#0F75BC',
    },
    title: {
        fontSize: '1.4rem',
        fontWeight: 'bold',
        flex: '1 1 100%',
        paddingLeft: '5px',
        paddingRight: '5px'
    },
    disabled: {
        color: '#8e9498',
        padding: '13px',
    },
    undisabled: {
        padding: '13px',
    },
    arrow: {
        fontSize: '2.5rem',
        cursor: 'pointer',
    },
    ticketNoMaintenance: {
        color: '#0F75BC',
        width: '102px',
        fontWeight: '800',
        fontSize: '1rem',
        paddingRight: '0px',
        paddingLeft: '15px !important'
    },
    ticketNoRepair: {
        color: '#0F75BC',
        width: '100px',
        fontWeight: '800',
        fontSize: '1rem',
        paddingRight: '0px',
        paddingLeft: '15px !important'
    },
    mechanicMaintenance: {
        width: '85px',
        fontWeight: '600',
        fontSize: '1rem',
        paddingRight: '0px',
    },
    mechanicRepair: {
        width: '86px',
        fontWeight: '600',
        fontSize: '1rem',
        paddingRight: '0px',
    },
    problemType: {
        width: '159px',
        fontWeight: '600',
        fontSize: '1rem',
        paddingRight: '0px',
    },
    checkListNo: {
        width: '121px',
        fontWeight: '600',
        fontSize: '1rem',
        paddingRight: '0px',
    },
    lpm_date: {
        width: '133px',
        fontWeight: '600',
        fontSize: '1rem',
        paddingRight: '0px',
    },
    solutionType: {
        width: '79px',
        fontWeight: '600',
        fontSize: '1rem',
        paddingRight: '0px',
    },
    reportedDtMaintenance: {
        width: '170px',
        fontWeight: '600',
        fontSize: '1rem',
        paddingRight: '0px',
    },
    reportedDtRepair: {
        width: '163px',
        fontWeight: '600',
        fontSize: '1rem',
        paddingRight: '0px',
    },
    grabbedDtMaintenance: {
        width: '152px',
        fontWeight: '600',
        fontSize: '1rem',
        paddingRight: '0px',
    },
    grabbedDtRepair: {
        width: '183px',
        fontWeight: '600',
        fontSize: '1rem',
        paddingRight: '0px',
    },
    closedDtMaintenance: {
        width: '171px',
        fontWeight: '600',
        fontSize: '1rem',
        paddingRight: '0px',
    },
    closedDtRepair: {
        width: '146px',
        fontWeight: '600',
        fontSize: '1rem',
        paddingRight: '0px',
    },
    downtime: {
        width: '98px',
        fontWeight: '600',
        fontSize: '1rem',
        paddingRight: '0px',
    },
    status: {
        width: '94px',
        fontWeight: '600',
        fontSize: '1rem',
        paddingRight: '0px',
    },
    columnIndex: {
      width: '160px',
      paddingLeft: '15px !important',
    },
}));

export default useToolbarStyles;