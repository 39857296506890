import { viewsByFilters } from '../components/MachineBreakDown/constant';
import { colors } from '../constants';
import { 
  formatMinute, 
  getTotal, 
  getTotalAverage, 
} from '../helpers';

export const tooltips = (dataTooltips, legends, viewBy, viewType, dateRange, title) => {
    return {
        tooltips: {
            enabled: false,
            intersect: false,
            mode: "index",
            custom: function (tooltipModel) {
                var tooltipEl = document.getElementById('chartjs-4');
                const { dataPoints } = tooltipModel;
                if(dataPoints && dataPoints.length <= 0 && dataTooltips && dataTooltips.length <= 0) return false;
                let mapData = dataPoints?.reduce((__,{ xLabel }) =>
                   dataTooltips?.filter(({ label }) => label == xLabel), 
                []);
                if(!mapData) return tooltipEl.style.opacity = 0;
                const yAlign = tooltipModel.yAlign;
                const xAlign = tooltipModel.xAlign;
                const caretY = tooltipModel.caretY;
                const caretX = tooltipModel.caretX;
                if (!tooltipEl) {
                  tooltipEl = document.createElement('div');
                  tooltipEl.id = 'chartjs-4';
                  tooltipEl.innerHTML = '<div class="chartjs-4"></div>';
                  document.body.appendChild(tooltipEl);
                }
                if (tooltipModel.opacity === 0) {
                  tooltipEl.style.opacity = 0;
                  return;
                }
                tooltipEl.classList.remove("top", "bottom", "center", "left", "right");
                tooltipEl.classList.add(tooltipModel.yAlign);
                tooltipEl.classList.add(tooltipModel.xAlign);
                const [{ total, data }] = mapData;
                let label = data.length > 0 ? data[0].subLabel || data[0].label : tooltipModel.title[0];
                if (tooltipModel.body) {
                  var innerHtml = `<div>
                      <h3>${title} - ${viewsByFilters[viewType - 1].translate()}</h3>
                      <h5>${label}</h5>
                      <div class='box'>
                      <div class='title'>
                        <h3>${title}:</h3><h3>${viewBy === 'totalMachines' ? total : formatMinute(total)}</h3>  
                      </div>`;
                  data.sort((a, b) => (a[viewBy] < b[viewBy]) ? 1 : (a[viewBy] > b[viewBy]) ? -1 : 0)
                  .filter((__, key) => key < 9)
                  .map((row, key) => (
                  innerHtml += `
                    <div class='rowName'>
                      <span class='legend-indicator' style='background: ${colors[key]}'></span> 
                      <p class='name'>${row['name']}</p>
                      <p class='value'>${viewBy === 'totalMachines' ? row[viewBy] : formatMinute(row[viewBy])}</p>
                    </div>`
                  ));
                  if(data.length > 9){
                    const otherRows = data.filter((__, key) => key >= 9);
                    const otherRow = {
                      "label": otherRows.length > 1 ? 'Others'.translate() : otherRows[0].label,
                      "totalMachines": otherRows.reduce((acc, cur) => (acc + cur['totalMachines']), 0),
                      "totalTime": otherRows.reduce((acc, cur) => (acc + cur['totalTime']), 0),
                      "avgTime": otherRows.reduce((acc, cur) => (acc + cur['avgTime']), 0),
                      "color": colors[9],
                    };
                    innerHtml += `
                    <div class='rowName'>
                      <span class='legend-indicator' style='background: ${otherRow['color']}'></span> 
                      <p class='name'>${otherRow['label']}</p>
                      <p class='value'>${viewBy === 'totalMachines' ? otherRow[viewBy] : formatMinute(otherRow[viewBy])}</p>
                    </div>`;
                  }
                  innerHtml += `<div class='marginTop15'></div>`;
                  legends.map((row) => (
                    innerHtml += `
                    <div class='rowName'>
                    <h3>${row.title}${row.title !== 'Total Closed Repair Ticket Qty'.translate() ? ' (' + 'min'.translate() + ')' : ''}</h3>
                    <h3>
                    ${row.key === 'avgTime' 
                      ? getTotalAverage(data, row.key) 
                      : row.key === 'totalMachines'
                      ? parseInt(getTotal(data, row.key))
                      : getTotal(data, row.key)}
                    </h3>
                    </div>`
                  ));
                  innerHtml += `</div>`;
                  var tableRoot = tooltipEl.querySelector('.chartjs-4');
                  tableRoot.innerHTML = innerHtml;
                }
                var position = this._chart.canvas.getBoundingClientRect();
                const { width, height } = tooltipEl.getBoundingClientRect();
                const positionX = this._chart.canvas.offsetLeft;
                let space = 20;
                let left = positionX + window.pageXOffset + caretX + width / 3.0;
                if (xAlign == "left") {
                  left += width / 2 - tooltipModel.xPadding - space / 2;
                  if (yAlign == "center") {
                    left += space * 2;
                  }
                } else if (xAlign == "right") {
                  left -= width / 2;
                  if (yAlign == "center") {
                    left -= space;
                  } else {
                    left += space;
                  }
                }
                tooltipEl.style.opacity = 1;
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left = left + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - height - space + 'px';
                tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                tooltipEl.style.pointerEvents = 'none';
              }
        }
    }
}