import {
    COMPANY_LIST_REQUEST,
    COMPANY_LIST_SUCCESS,
    COMPANY_LIST_ERROR,
    COMPANY_DETAIL_REQUEST,
    COMPANY_DETAIL_SUCCESS,
    COMPANY_DETAIL_ERROR,
    COMPANY_NEW_REQUEST,
    COMPANY_NEW_SUCCESS,
    COMPANY_NEW_ERROR,
    COMPANY_UPDATE_REQUEST,
    COMPANY_UPDATE_SUCCESS,
    COMPANY_UPDATE_ERROR,
    COMPANY_TRIGGER_HIGHLIGHT
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    detailLoading: false,
    triggerHighlight: false,
    companyList:[],
    meta: [],
    error: ''
}

const companyListReducer = (state = initialState, action) => {
    switch (action.type) {
        case COMPANY_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case COMPANY_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                companyList: action.payload.companyList,
                error: ''
            }
        case COMPANY_TRIGGER_HIGHLIGHT:
            return {
                ...state,
                triggerHighlight: action.payload
            }
        default: 
            return state;
    }
}

export default companyListReducer;