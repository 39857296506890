import React from 'react';
import { IconButton } from '@material-ui/core';
import { ExportIcon } from '../../../styles';
import { FlexBox, Title } from './styles';

function MachinePerformanceHeader({ title, onExport }) {
    return ( 
        <FlexBox justifyContent='space-between'>
            <Title text={title} />
            <IconButton onClick={onExport} size='small'>
                <ExportIcon />
            </IconButton>
        </FlexBox>
    );
}

export default MachinePerformanceHeader;