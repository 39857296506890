export const removeDupliacteStations = (stations) => {
    const filteredStations= stations.reduce((acc, obj) => {
        const station = modifySubStationSortNumber(obj);
        if (station.station === undefined) {
            if (!acc[station.sortNumber]) {
                acc[station.sortNumber] = station;
            }
        } else {
            acc[station.sortNumber] = station;
        }
        return acc;
    }, {});
    return Object.values(filteredStations);
}

const modifySubStationSortNumber = (station) => {
    if (
        station.station !== undefined &&
        station.station.includes("-A") &&
        !station.sortNumber.toString().includes(".01")
    ) {
        return { ...station, sortNumber: station.sortNumber + 0.01 };
    }
    return station;
}