import React from 'react';
import { Box, Typography } from '@material-ui/core';
import {
    IndicatorContainer, 
    LegendIndicator,
} from '../../LineNPT/styles';
import { 
    maxinumumString, 
    numberWithCommas, 
} from '../../../../../utils/helper';
import { useStyles } from './styles';
import { formatDate } from '../../../../../utils/date';

export default function LineNPTTooltip(props) {
    const { line, problem, index, startDate, endDate } = props;
    const classes = useStyles();

    return (
        <Box className={classes.box1}>
            <Typography className={classes.text1}>{line.lineName} {'NPT Analysis'.translate()}</Typography>
            <Typography className={classes.text2}>
                {`${formatDate(startDate, 'MMM D, Y', false)} ${'to'.translate()} ${formatDate(endDate, 'MMM D, Y', false)}`} 
            </Typography>
            <IndicatorContainer marginLeft='unset' justifyContent="flex-start">
                <LegendIndicator color={props.color} marginLeft='unset' />
                <Typography className={classes.text1}>{`No.${index+1} Problem Type`.translate()}</Typography>
            </IndicatorContainer>
            <Box>
            <Typography className={classes.text3}>{problem.problemType}</Typography>
            <Typography className={classes.text4}>{problem.problemTypeDesc}</Typography>
            </Box>
            <Box>
            <Box display="flex" justifyContent='space-between' marginTop={1}>
                <Typography className={classes.text1}>{'Total Closed Repair Tickets'.translate()}</Typography>
                <Typography className={classes.text1}>{numberWithCommas(problem.count)}</Typography>
            </Box>
            <Box className={classes.divider} />
            <Box display="flex" justifyContent='space-between'>
                <Typography className={classes.text4}>{'Total NPT (min)'.translate()} </Typography>
                <Typography className={classes.text4}>{numberWithCommas(parseFloat(problem.nptTime).toFixed(2))}</Typography>
            </Box>
            </Box>
        </Box>
    )
}