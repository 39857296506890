import React from 'react';
import { connect } from 'react-redux';
import { deleteBrand } from '../../../../../redux/actions/Brand/brandActions';
import { confirmAlert } from 'react-confirm-alert';
import history from '../../../../../history';
import Table from '../../../../../components/CollapsableTable/CollapseTable'
import { getBrands } from '../../../../../redux/actions/Brand/brandActions'
import dictionary from '../../../../../redux/actions/Translation/dictionary'
import { checkPageAccessField } from '../../../../../utils/helper';
import './Brands.scss';

class Brands extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            translations: {
                ...this.convert()
            },
            translationTimestamp: undefined,
            tableState: {
                add: () => {
                    history.push('/configuration/machine-config/brands/new')
                },
                edit: ({ id }) => {
                    history.push(`/configuration/machine-config/brands/edit/${id}`)
                },
                remove: ({ id }) => {
                    confirmAlert({
                        title: this.state?.translations?.are_you_sure_delete_brand,
                        message: '',
                        buttons: [
                            {
                                label: this.state?.translations?.YES,
                                onClick: () => {
                                    this.props.deleteBrand(id)
                                }
                            },
                            {
                                label: this.state?.translations?.NO,
                            }
                        ]
                    });
                },
                highlight: true,
                search: true,
                keys: this.initTableHeader(),
                data: [],
                loading: false
            }
        }
    }
    /**handler */
    initTableHeader = () => {
        let {STATUS, BRAND_CODE, DISPLAY_NAME, COMPANY_NAME, WEBSITE, DESCRIPTION ,UPDATED_DATE} = this.convert()
        return  [
            {
                name: BRAND_CODE,
                key: 'brand',
                tableHeaderWidth: '10%'
            },
            {
                name: DISPLAY_NAME,
                key: 'desc1',
                tableHeaderWidth: '10%'
            },
            {
                name: COMPANY_NAME,
                key: 'companyName',
                tableHeaderWidth: '12%'
            },
            {
                name: WEBSITE,
                key: 'webSite',
                tableHeaderWidth: '10%'
            },
            {
                name: DESCRIPTION,
                key: 'desc2',
                tableHeaderWidth: '15%'
            },
            {
                name: STATUS,
                type: 'boolean',
                key: 'isActive',
                tableHeaderWidth: '10%'
            },
            {
                name: UPDATED_DATE,
                type: 'date',
                key: 'updatedDt',
                alt: 'createdDt',
                tableHeaderWidth: '10%'
            },
        ]
    }
    convert = () => {
        let {YES, NO, NOTIFICATIONS: {are_you_sure_delete_brand}, STATUS, BRAND_CODE, DISPLAY_NAME, COMPANY_NAME, WEBSITE, DESCRIPTION ,UPDATED_DATE} = dictionary.GET()
        return dictionary.TRANSLATE({YES, NO,are_you_sure_delete_brand, STATUS, BRAND_CODE, DISPLAY_NAME, COMPANY_NAME, WEBSITE, DESCRIPTION ,UPDATED_DATE})
    }
    componentDidMount() {
        this.props.getBrands(false);
    }

    componentDidUpdate() {
        // console.log('didUpdate', this.props, this.state)
        let { loading, brandList } = this.props
        let { loading: stateLoading, data } = this.state.tableState
        if (loading != stateLoading || brandList?.length != data?.length) {
            if(Array.isArray(brandList)){
                let tableState = this.state.tableState;
                if (checkPageAccessField(this.props.userAccess,{pageId:18,pageAccess: 'VIEW'})){
                    tableState = {
                        add: null,
                        edit: null,
                        remove: null
                    }
                }
                this.setState({
                    tableState: {
                        ...tableState,
                        data: [...brandList],
                        loading,
                        timeStamp: new Date()
                    }
                })
            }
        }
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                translations: Object.assign({}, { ...this.convert() }),
                tableState: {
                    ...this.state.tableState,
                    keys: this.initTableHeader(),
                    timeStamp: new Date()
                }
            })
        }
    }

    componentWillUnmount() {
    }

    /**render */
    render() {
        return (
            <div className="brand-body-overview">
                <Table {...this.state.tableState} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    let { brandListReducer, translationReducer } = state
    let apiLoading = {
    }
    let data = {
        userAccess: state.authReducer.access,
    }

    return {
        ...apiLoading,
        ...data,
        ...brandListReducer,
        ...translationReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // getBrands
        getBrands: (ev) => dispatch(getBrands(ev)),
        deleteBrand: (id, payload) => dispatch(deleteBrand(id, payload)),
    }
}

Brands = connect(mapStateToProps, mapDispatchToProps)(Brands);

export default Brands;
