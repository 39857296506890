import {
    SUPPLIER_LIST_REQUEST,
    SUPPLIER_LIST_SUCCESS,
    SUPPLIER_LIST_ERROR,
    SUPPLIER_UPDATE_SUCCESS
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    supplierList:[],
    meta: [],
    error: ''
}

const supplierListReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUPPLIER_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SUPPLIER_LIST_SUCCESS:
            return {
                loading: false,
                supplierList: action.payload.supplierList,
                meta: action.payload.meta,
                error: ''
            }
        case SUPPLIER_LIST_ERROR:
            return {
                loading: false,
                supplierList: [],
                meta: [],
                error: action.payload.message
            }
        case SUPPLIER_UPDATE_SUCCESS: 
        return {
            ...state,
            updated: action.payload.updated,
        }
        default: 
            return state;
    }
}

export default supplierListReducer;