import React, { useEffect, useState } from 'react';
import CardAccordion from './components/CardAccordion';
import BarChart from './components/BarChart';
import ViewBySelect from './components/ViewBySelect';
import { 
  normalizeBarChart, 
  kFormat, 
  normalizeDataTooltip, 
  getDateRangeQueryString,
} from './helpers';
import qs from 'qs';
import { client } from './api';
import { tooltips } from './Tooltips';

const options = [
  { value: 'totalTime', label: 'Total Time' },
  { value: 'avgTime', label: 'Average Time' }
]

export default function LineLeaderConfirmation(props){
  const { 
    originalRows, 
    dictionary, 
    dateRange, 
    filters, 
    viewType, 
    isCron,
  } = props;
  const [lineLeaderConfirmation, setLineLeaderConfirmation] = useState(null);
  const [viewBy, setViewBy] = useState('totalTime');

  useEffect(()=> {
    if(isCron) return;
    const params = { filters: getDateRangeQueryString(filters), viewBy, viewType };
    client.get("/analytics/line-leader-confirmation", { params }).then(({ data }) => {
      const result = data.filter(({ id }) => id !== null);
      setLineLeaderConfirmation(result);
    })
  }, [filters, viewBy]);

  if(!lineLeaderConfirmation){ return null };
  const lineData = [
    {label: 'Total Response Time', borderColor: '#6D93F7', key: 'total'},
    {label: 'Average Response Time', borderColor: '#EA6FDC', key: 'avg'},
  ];
  const normalizedData = normalizeBarChart(
    lineLeaderConfirmation, 
    viewBy, 
    "marked", 
    dictionary, 
    dateRange,
    lineData,
  );
  const legends = [
    {title: 'Total Confirm Time'.translate(), color: '#6D93F7', key: 'totalTime'},
    {title: 'Average Confirm Time'.translate(), color: '#EA6FDC', key: 'avgTime'},
  ];

  return <CardAccordion
    title={"Line Leader Confirmation".translate()}
    legends={legends}
    filterForm={
      <ViewBySelect value={viewBy} label={"View By".translate()} onChange={(e) => setViewBy(e.target.value)} options={options}/>
    }
  >
    <BarChart 
      optionTooltip={tooltips( 
        normalizeDataTooltip(
          lineLeaderConfirmation, 
          dateRange, 
          'marked', 
          originalRows, 
          viewBy,
        ), 
        legends, 
        viewBy, 
        viewType,
        filters.dateRange,
        'Line Leader Confirmation'.translate(),
      )}
      xTickFormat={(value, index)=> `${kFormat(value)} ${'min'.translate()}`} 
      data={normalizedData}/>
  </CardAccordion>
}
