import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux'


import authReducer from './reducers/Auth/authReducer';
import menuDrawerReducer from './reducers/MenuDrawer/menuDrawerReducer';
import notificationReducer from './reducers/Notification/notificationReducer';
import modalReducer from './reducers/Modal/ModalReducer'
import inputReducer from './reducers/Input/inputReducer';
import machineListReducer from './reducers/Machine/machineListReducer';
import conditionListReducer from './reducers/Condition/conditionListReducer';
import statusListReducer from './reducers/Status/statusListReducer';
import macTypeListReducer from './reducers/MacTypes/macTypeListReducer';
import macSubTypeListReducer from './reducers/MacSubTypes/macSubTypeListReducer';
import brandListReducer from './reducers/Brands/brandListReducer';
import changeoverTypeReducer from './reducers/ChangeoverType/changeoverListReducer'
import companyListReducer from './reducers/Company/companyListReducer';
import buildingListReducer from './reducers/Building/buildingListReducer';
import floorListReducer from './reducers/Floor/floorListReducer';
import areaConfigListReducer from './reducers/AreaConfig/areaConfigListReducer';
import lineConfigListReducer from './reducers/LineConfig/lineConfigListReducer';
import groupListReducer from './reducers/Group/groupListReducer';
import motorTypeListReducer from './reducers/MotorTypes/motorTypeListReducer';
import supplierListReducer from './reducers/Supplier/supplierListReducer';
import frequencyListReducer from './reducers/Frequency/frequencyReducer';
import areaListReducer from './reducers/Area/areaListReducer';
import attachmentListReducer from './reducers/Attachment/attachmentListReducer';
import ticketListReducer from './reducers/Ticket/ticketListReducer';
import problemListReducer from './reducers/Problem/problemListReducer';
import solutionListReducer from './reducers/Solution/solutionListReducer';
import dashboardListReducer from './reducers/Dashboard/dashboardListReducer'
import linesReducer from './reducers/Lines/linesReducer';
import translationReducer from './reducers/Translation/translationReducer'
import checklistReducer from './reducers/Checklist/checklistReducer';
import calendarReducer from './reducers/Calendar/calendarReducer'
import factoryListReducer from './reducers/Factory/factoryListReducer'
import preferenceReducer from './reducers/Preference/preferenceReducer'
import userReducer from './reducers/User/userReducer'
import roleReducer from './reducers/Role/roleReducer'
import machineSummaryReducer from './reducers/MachineSummary/machineSummaryReducer';
const rootReducer = combineReducers({
    routing: routerReducer,
    inputReducer,
    authReducer,
    menuDrawerReducer,
    notificationReducer,
    modalReducer,
    machineListReducer,
    conditionListReducer,
    statusListReducer,
    macTypeListReducer,
    macSubTypeListReducer,
    brandListReducer,
    changeoverTypeReducer,
    companyListReducer,
    buildingListReducer,
    floorListReducer,
    areaConfigListReducer,
    lineConfigListReducer,
    groupListReducer,
    motorTypeListReducer,
    supplierListReducer,
    frequencyListReducer,
    areaListReducer,
    attachmentListReducer,
    ticketListReducer,
    problemListReducer,
    solutionListReducer,
    dashboardListReducer,
    linesReducer,
    translationReducer,
    checklistReducer,
    calendarReducer,
    factoryListReducer,
    preferenceReducer,
    userReducer,
    roleReducer,
    machineSummaryReducer,
});

export default rootReducer;