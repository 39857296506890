import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './style';

export default function TokenExpired({
    onClose,
    time = '12 hours',
    urlRedirect = process.env.REDIRECT_PAGE,
}) {

    const classes = useStyles();
    const handleClose = () => {
        onClose();
        localStorage.clear();
        window.location = urlRedirect;
    }

    return (
        <Card variant='outlined' className={classes.alertCard}>
            <CardContent>
                <Typography className={classes.alertTitle}>
                    You've been logged out.
                </Typography>

                <Typography className={classes.alertBody}>
                    For your security, you have been logged out due to {time} of
                    inactivity.
                </Typography>
                <Typography className={classes.alertBody}>
                    please log back in.
                </Typography>
            </CardContent>
            <CardActions className={classes.alertAction}>
                <Button onClick={handleClose} className={classes.alertButton} variant='primary'>
                    Ok
                </Button>
            </CardActions>
        </Card>
    );
}