import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    asterisk: {
      color: "red",
    },
    helperText: {
      fontSize: '1.05rem',
      marginBottom: '5px',
    },
    formTitle: {
      font: "normal normal normal 20px/20px Roboto",
      color: "#515151",
      letterSpacing: "0.07px"
    },
    formAction: {
      display: "flex",
      justifyContent: "space-evenly",
      marginTop: "1rem",
      marginBottom: "1rem",
    }, 
    ButtonBlue: {
      borderRadius: "4px !important",
      backgroundColor: "#1689FA",
      color: "#fff",
      marginLeft: "30px !important",
      "&:hover": {
        backgroundColor: "#1689FA",
      },
    },
    TextField: {
      color: "#000000",
      ["& label.Mui-focused"]: {
        color: "#1689FA",
      },
      ["& .MuiInput-underline:after"]: {
        borderBottomColor: "#1689FA",
      },
      ["& label"]: {
        color: "#959594",
        zIndex: 1
      },
    },
    FormEdit: {
      borderRadius: "1px !important",
    },
    ButtonWhite: {
      backgroundColor: "#FFFFFF",
      color: "#515151",
      borderRadius: "4px !important",
      "&:hover": {
        backgroundColor: "#FFFFFF",
      },
    },
    radioFormControl: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "1rem",
    },
    radioGroup: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "initial",
    },
    radio: {
      '&.Mui-checked': {
        color: "#1689FA",
      },
    },
    formLabel: {
      color: "#959594",
      "&.Mui-focused": {
        color: "#959594"
      }
    },
    GTranslateIcon: {
      color: "#1689FA",
    },
  }));