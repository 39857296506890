import {
  addDays,
  endOfDay,
  startOfDay,
  addMonths,
  isSameDay,
  endOfMonth
} from 'date-fns';

const defineds = {
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  endOfTheMonth: endOfDay(endOfMonth(new Date())),
  startOf7DaysAgo:  startOfDay(addDays(new Date(), -7)),
  startOf30DaysAgo:  startOfDay(addDays(new Date(), -30)),
  startOf90DaysAgo:  startOfDay(addDays(new Date(), -90)),
  startOf12MonthsAgo:  startOfDay(addMonths(endOfMonth(new Date()), -12))
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges) {
  return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}

export const staticRangesAll = createStaticRanges([
  {
    label: 'All',
    range: () => ({
      startDate: '',
      endDate: '',
    }),
  },
  {
    label: 'Last 24 hours',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Yesterday',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },

  {
    label: 'Last 7 Days',
    range: () => ({
      startDate: defineds.startOf7DaysAgo,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Last 30 Days',
    range: () => ({
      startDate: defineds.startOf30DaysAgo,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Last 90 Days',
    range: () => ({
      startDate: defineds.startOf90DaysAgo,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Last 12 Months',
    range: () => ({
      startDate: defineds.startOf12MonthsAgo,
      endDate: defineds.endOfTheMonth,
    }),
  }
]);


export const staticRanges = createStaticRanges([
  {
    label: 'Last 24 hours',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Yesterday',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },

  {
    label: 'Last 7 Days',
    range: () => ({
      startDate: defineds.startOf7DaysAgo,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Last 30 Days',
    range: () => ({
      startDate: defineds.startOf30DaysAgo,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Last 90 Days',
    range: () => ({
      startDate: defineds.startOf90DaysAgo,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Last 12 Months',
    range: () => ({
      startDate: defineds.startOf12MonthsAgo,
      endDate: defineds.endOfTheMonth,
    }),
  }
]);
