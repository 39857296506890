import React from 'react';
import { 
    Checkbox, 
    FormControl, 
    FormControlLabel, 
    FormGroup, 
    FormLabel, 
} from '@material-ui/core';
import { useEffect } from 'react';
import { useState } from 'react';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
export default function FilterComponent({
    data = [],
    onChange,
    title = 'Status',
    keyValue = 'id',
    keyDisplay = 'name',
    color = 'primary',
    firstDefault = true,
    showingAll = false,
}) {
    const defaultValues = firstDefault
        ? data.length > 0
            ? [data[0][keyValue]]
            : []
        : [];
    const [checked, setChecked] = useState(defaultValues);
    const indeterminate = !checked.includes('all') && checked.length !== data.length && checked.length > 0;
    useEffect(() => onChange(checked), [checked]);

    const AllCheckBox = () => {
        return (
            <FormControlLabel
                key={Math.random()}
                value='all'
                control={
                    <Checkbox
                        indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon />}
                        indeterminate={indeterminate}
                        color={color}
                        checked={checked.includes('all') || indeterminate}
                        onChange={handleCheckingAll}
                    />
                }
                label='All'
                name='filter'
            />
        )
    }
    const handleChecking = (e) => {
        const value = e.target.value.toString();
        const dataChecked = checked.includes(value)
            ? checked.filter((i) => i !== value && i !== 'all') 
            : [...(checked ?? []), value];
        if (data.length === dataChecked.length) {
            if (!dataChecked.includes('all') && dataChecked.length > 0) {
                setChecked([...dataChecked, 'all']);
            } else {
                setChecked(dataChecked);
            }
        } else {
            setChecked(dataChecked.filter((id) => id !== 'all'));
        }
    }

    const handleCheckingAll = (e) => {
        const value = e.target.value.toString();
        const dataChecked = [...[...data.map((__, key) => (data[key][keyValue].toString()))], value];
        setChecked(
            checked.includes(value)
                ? []
                : dataChecked
        )
    };

    return (
        <FormControl className='form' fullWidth>
            <FormLabel className='formLabel'>{title}</FormLabel>
            <FormGroup>
                {showingAll && <AllCheckBox />}
                {data?.map((__, key) => (
                    <FormControlLabel
                        key={data[key][key]}
                        label={data[key][keyDisplay]}
                        value={data[key][keyValue].toString()}
                        control={
                            <Checkbox
                                color={color}
                                onChange={handleChecking}
                                checked={checked.includes(data[key][keyValue].toString())}
                                name='filter'
                            />
                        }
                    />
                ))}
            </FormGroup>
        </FormControl>
    )
}