import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GlobalTemplate from './globalTemplate'

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });
  
  const headCells = [
    { id: 'templateName', numeric: false, disablePadding: true, label: 'TEMPLATE NAME' },
    { id: 'checkListNo', numeric: false, disablePadding: true, label: 'TEMPLATE NO' },
    { id: 'tasksSteps', numeric: true, disablePadding: true, label: 'STEPS' },
    { id: 'subTasksSteps', numeric: true, disablePadding: false, label: 'SUB TYPE' },
    { id: 'macType', numeric: false, disablePadding: true, label: 'MACHINE TYPE' },
    { id: 'brand', numeric: false, disablePadding: true, label: 'BRAND' },
    { id: 'createdDt', numeric: false, disablePadding: true, label: 'PURCHASE DATE' },
    { id: 'addedDt', numeric: false, disablePadding: true, label: 'PURCHASE DATE' },
    { id: 'isActive', numeric: false, disablePadding: true, label: 'STATUS' },
  ];

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const rows = props.rows
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleBrand = (event) => {
      let value = event.target.value;
      rows.filter(row => row.name.includes(value)).map(filtered =>{ return filtered})
  }
  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText>
        <GlobalTemplate></GlobalTemplate>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
