export const required = value => value !== null && value !== '' ? undefined : 'Required'
export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
export const maxLength50 = maxLength(50)
export const noSpecialCharacters = value => value && !/^[A-Za-z0-9\-\s]+$/i.test(value.trim()) ? 'Must be letters only' : undefined
export const number = value => value && isNaN(Number(value)) && !/^[0-9]*$/i.test(value) ? 'Must be a number' : undefined
export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
export const minValue0 = minValue(0)
export const maxValue = max => value =>
  value && value > max ? `Max of ${max}` : undefined
export const maxValue80 = maxValue(80)
export const email = value => 
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Invalid email address' : undefined
export const alphanumeric = value => value && /[=><]/.test(value.trim()) ? 'Must be letters only' : undefined


export const validateFields = (form) => {
    let formValid = true;
    let errors = [];

    if (required(form.desc1) !== undefined){
        formValid = false;
        errors.push({name:'desc1',message:'Please fill up all required fields'});
    }

    if (alphanumeric(form.desc1) !== undefined){
        formValid = false;
        errors.push({name:'desc1',message:'The following special characters are not allowed: = < >'});
    }

    if (required(form.temptype) !== undefined){
        formValid = false;
        errors.push({name:'temptype',message:'Please fill up all required fields'});
    }

    if (form.tasks.length <= 0){
        formValid = false;
        errors.push({name:'tasks',message:'There should be at least 1 task'});
    }

    if(form.tasks.length > 0){
      form.tasks.forEach(({ task, seq: taskSeq, subtasks }) => {
        if(maxLength50(task) !== undefined){
          formValid = false;
          errors.push({
            name: 'task' + taskSeq,
            message: 'Maximum character limit is 50'.translate(),
          })
        }
        if(subtasks.length > 0){
          subtasks.forEach(({ seq: subSeq, subtask }) => {
            if(maxLength50(subtask) !== undefined){
              formValid = false;
              errors.push({
                name: `${taskSeq}-${subSeq}`,
                message: 'Maximum character limit is 50'.translate(),
              })
            }
          });
        }
      });
    }

    // if (required(form.macsubtypeId) !== undefined){
    //     formValid = false;
    //     errors.push({name:'macsubtypeId',message:'Please fill up all required fields'});
    // }
    
    // if (required(form.brandId) !== undefined){
    //     formValid = false;
    //     errors.push({name:'brandId',message:'Please fill up all required fields'});
    // }

    // if (required(form.frequencyId) !== undefined){
    //     formValid = false;
    //     errors.push({name:'frequencyId',message:'Please fill up all required fields'});
    // }

    return {
        valid: formValid,
        errors: errors
    }
}