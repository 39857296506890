import {
   SUPPLIER_LIST,
   SUPPLIER_LIST_REQUEST,
   SUPPLIER_LIST_SUCCESS,
   SUPPLIER_LIST_ERROR,
   NOTIFICATION_ERROR,
   NOTIFICATION_SUCCESS,
   SUPPLIER_NEW_REQUEST,
   SUPPLIER_NEW_SUCCESS,
   SUPPLIER_TRIGGER_HIGHLIGHT,
   SUPPLIER_UPDATE_SUCCESS,
   SUPPLIER_DELETE_REQUEST,
   SUPPLIER_DETAIL_REQUEST,
   SUPPLIER_DETAIL_SUCCESS
 } from '../actionTypes';
 import { tokenGet, apiSettings, tokenPost, tokenPut, tokenDelete } from '../../../utils/api/CoreApi';
import { getFactoryId } from '../../../utils/helper';
 
 export const supplierRequest = () => {
     return {
         type:SUPPLIER_LIST_REQUEST
     }
 }
 
 export const supplierSuccess = supplierList => {
     return {
         type:SUPPLIER_LIST_SUCCESS,
         payload: supplierList
     }
 }

 export const notificationSuccess = (message) => {
    return {
        type: NOTIFICATION_SUCCESS,
        payload: {
            message: message.translate()
        }
    }
}

export const notificationError = (message) => {
    return {
        type: NOTIFICATION_ERROR,
        payload: {
            message: message.translate()
        }
    }
}

export const supplierNewRequest = () => {
    return {
        type:SUPPLIER_NEW_REQUEST
    }
}

export const supplierNewSuccess = supplier => {
    return {
        type:SUPPLIER_NEW_SUCCESS,
        payload: supplier
    }
}

export const supplierDetailRequest = () => {
    return {
        type:SUPPLIER_DETAIL_REQUEST
    }
}

export const supplierDetailSuccess = supplier => {
    return {
        type:SUPPLIER_DETAIL_SUCCESS,
        payload: supplier
    }
}

export const setUpdatedToFalse = () => {
	return {
			type: SUPPLIER_UPDATE_SUCCESS,
			payload: {
					updated: false,
			}
	}
}

export const supplierUpdateSuccess = updated => {
	return {
			type: SUPPLIER_UPDATE_SUCCESS,
			payload: updated,
	}
}

export const supplierDeleteRequest = () => {
	return {
			type: SUPPLIER_DELETE_REQUEST,
	}
}

 
 export const getSuppliers = (isActive = 1, currentPage = 1, pageSize = 100000) =>{
     return function(dispatch){
         dispatch(supplierRequest());
         tokenGet(apiSettings.apiURLReferences + '/get-suppliers?isDeleted=0&isActive='+ isActive +'&currentPage=' + currentPage +'&pageSize=' + pageSize).then((response) => {
             let data = response.data.machines.result.map((item) => {
                let itemRow = {
                    'id': item.id,
                    'supplier': item.supplier,
                    'desc1': item.desc1,
                }
                return itemRow;
             });
             dispatch(supplierSuccess({
                 supplierList: data,
                 meta: response.data.machines.meta
             }));
         })
         .catch((error) => {
             console.log(error);
         });
     }
 }

 export const getListSupplier = (filtered = true, isDeleted = 0, pageNum = 1, perPage = 100000) =>{
    let factoryId = getFactoryId();
    let languageCode = localStorage.getItem('languageCode');
    let config = {
        headers: {
            'language': languageCode,
        }
    }

    localStorage['filtered'] = filtered
     return function(dispatch){
         dispatch(supplierRequest());
         tokenGet(apiSettings.apiURLReferences + '/get-suppliers?isDeleted='+ isDeleted + '&factoryId=' + factoryId + '&pageNum=' + pageNum + '&perPage=' + perPage, config).then((response) => {
            let data 
            if(localStorage['filtered'] === true) {
                data = response.data.machines.result.map((item) => {
                    let itemRow = {
                                'id': item.id,
                                'supplier': item.desc1
                        }
                        return itemRow;
                })
            }
            else data = response.data.machines.result
            delete localStorage['filtered']
             dispatch(supplierSuccess({
                 supplierList: data,
                 meta: response.data.machines.meta
             }));
         })
         .catch((error) => {
             console.log(error);
         });
     }
 }

 export const detailSupplier = (id) => {
    return function (dispatch) {
        dispatch(supplierDetailRequest());
        return tokenGet(apiSettings.apiURLReferences + '/get-suppliers/' + id)
            .then((response) => {
                dispatch(supplierDetailSuccess({
                    supplierDetail: response.data.supplier[0],
                }));
                return response.data.supplier[0];
            })
            .catch((error) => {
                console.log(error);
                throw error;
            });
    };
 }

 export const createSupplier = (payload) => {
    return function(dispatch) {
        dispatch(supplierNewRequest);
        tokenPost(apiSettings.apiURLReferences + "/create-suppliers", payload).then((response) => {
            console.log('respose', response)
            dispatch(getListSupplier());
            dispatch(notificationSuccess('Successfully added supplier'));
        })
        .catch((error) => {
            if (error.response.status == 500){
                    dispatch(notificationError('supplier code already exists'));
            }else{
                dispatch(notificationError('something went wrong'));
            }
    });
    }
 }

 export const updateSupplier = (payload) => {
    const { id } = payload;
    return function(dispatch) {
        tokenPut(apiSettings.apiURLReferences + '/update-suppliers/' + id, payload).then((response) => {
            dispatch(getListSupplier());
            dispatch(supplierUpdateSuccess({
                updated: true
            }))
            dispatch(notificationSuccess('Successfully updated supplier'))
        })
        .catch((error) => {
            console.log(error);
            dispatch(notificationError('something went wrong'));
        });
    }
 }

 export const deleteSupplier = (id) => {
    return function(dispatch) {
        dispatch(supplierDeleteRequest());
        tokenDelete(apiSettings.apiURLReferences + '/delete-suppliers/' + id).then((response) => {
            dispatch(getListSupplier());
            dispatch(notificationSuccess('Successfully deleted supplier'));
        })
        .catch((error) => {
            if (error.message.indexOf(500) >= 0){
                    dispatch(notificationError('Cannot delete because it is currently referenced to another record'));
            }
        });
    }
 }