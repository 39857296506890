import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { getFactoryLanguage } from "../../../../../redux/actions/Translation/googleTranslationAction";
import Form from "./Form";

export default function UpdateSupplier({ supplier, open, onClose }) {
    const [translations, setTranslations] = useState([]);
    const getTranslations = async () => {
      setTranslations(await getFactoryLanguage());
    }

    useEffect(() =>{
        getTranslations();
    },[])

    return (
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth={"xs"}
        >
          <DialogTitle id="alert-dialog-title">
            Update Supplier
          </DialogTitle>
          <DialogContent>
            <Form
              isDisabled={true}
              translations={translations}
              onClose={onClose}
              supplier={supplier}
            />
          </DialogContent>
        </Dialog>
      )

}