export const required = value => value !== null && value !== '' && value !== [] ? undefined : 'Required'
export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
export const maxLength50 = maxLength(50)
export const maxLength8 = maxLength(8)
export const noSpecialCharacters = value => value && !/^[A-Za-z0-9\-\s]+$/i.test(value.trim()) ? 'Must be letters only' : undefined
export const number = value => value && isNaN(Number(value)) && !/^[0-9]*$/i.test(value) ? 'Must be a number' : undefined
export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
export const minValue0 = minValue(0)
export const maxValue = max => value =>
  value && value > max ? `Max of ${max}` : undefined
export const maxValue80 = maxValue(80)
export const email = value => 
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Invalid email address' : undefined
export const alphanumeric = value => value && /[=><]/.test(value.trim()) ? 'Must be letters only' : undefined


export const validateFields = (form) => {
    let formValid = true;
    let errors = [];

    if (required(form.name) !== undefined){
        formValid = false;
        errors.push({name:'name',message:'Please fill up all required fields'});
    }

    if (alphanumeric(form.name) !== undefined){
        formValid = false;
        errors.push({name:'name',message:'The following special characters are not allowed: = < >'});
    }

    if (required(form.userName) !== undefined){
        formValid = false;
        errors.push({name:'userName',message:'Please fill up all required fields'});
    }

    if (alphanumeric(form.userName) !== undefined){
        formValid = false;
        errors.push({name:'userName',message:'The following special characters are not allowed: = < >'});
    }

    if (required(form.password) !== undefined){
        formValid = false;
        errors.push({name:'password',message:'Please fill up all required fields'});
    }

    if (required(form.confirmPassword) !== undefined){
        formValid = false;
        errors.push({name:'confirmPassword',message:'Please fill up all required fields'});
    }

    if (required(form.roleId) !== undefined){
        formValid = false;
        errors.push({name:'roleId',message:'Please fill up all required fields'});
    }

    // if (required(form.cardNo) !== undefined){
    //     formValid = false;
    //     errors.push({name:'cardNo',message:'Please fill up all required fields'});
    // }

    if (alphanumeric(form.cardNo) !== undefined){
        formValid = false;
        errors.push({name:'cardNo',message:'The following special characters are not allowed: = < >'});
    }

    // if (required(form.email) !== undefined){
    //     formValid = false;
    //     errors.push({name:'email',message:'Please fill up all required fields'});
    // }

    if (email(form.email) !== undefined){
        formValid = false;
        errors.push({name:'email',message:'Please provide correct email format'});
    }

    if (required(form.factory) !== undefined || form.factory.length <= 0){
        formValid = false;
        errors.push({name:'factory',message:'Please select a factory'});
    }
    
    if (required(form.building) !== undefined || form.building.length <= 0){
        formValid = false;
        errors.push({name:'building',message:'Please select a building'});
    }
    
    if (required(form.floor) !== undefined || form.floor.length <= 0){
        formValid = false;
        errors.push({name:'floor',message:'Please select a floor'});
    }
    
    if (required(form.area) !== undefined || form.area.length <= 0){
        formValid = false;
        errors.push({name:'area',message:'Please select an area'});
    }
    
    if (required(form.line) !== undefined || form.line.length <= 0){
        formValid = false;
        errors.push({name:'line',message:'Please select a line'});
    }

    if (form.password !== form.confirmPassword){
        formValid = false;
        errors.push({name:'password',message:'Password are not the same.'});
        errors.push({name:'confirmPassword',message:'Password are not the same.'});
    }

    return {
        valid: formValid,
        errors: errors
    }
}