
import { DASHBOARD_ACTIONS as actionList } from '../../actions/actionTypes'

const initializeState = {
    tickets : {
        repair: {
            reported: 0,
            inRepair: 0,
            closed: 0,
        },
        maintenance: {
            scheduled: 0,
            inProgress: 0,
            completed: 0,
        },
        changeOver: {
            open: 0,
            inProcess: 0,
            complete: 0
        },
        reportedList: {
            list: [],
            avgTime: '00:00:00'
        },
        repairList: {
            list: [],
            avgTime: '00:00:00',
        },
        '5npt': {
            list: []
        },
    },
    isLoading: false,
}

const dashboardListReduces = (state = initializeState, action) => {
    switch (action.type) {
        case actionList.DASHBOARD_LIST_TOGGLE_LOADING:
            return {
                ...state,
                isLoading: !state.isLoading,
            }
        case actionList.DASHBOARD_MAINTENANCE:
            return {
                ...state,
                ...action.payload
            }
        case actionList.DASHBOARD_LIST:
            return {
                ...state,
                ...action.payload
            }
        case actionList.DASHBOARD_AVG:
            return {
                ...state,
                ...action.payload
            }
        case actionList.DASHBOARD_BUILDING:
            return {
                ...state,
                ...action.payload
            }
        case actionList.DASHBOARD_AREA:
            return {
                ...state,
                dashboardArea: action.payload.dashboardArea,
            }
        default:
            return state
    }
}
export default dashboardListReduces