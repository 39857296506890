import React from 'react';
import { connect } from 'react-redux';
import { deleteMacType } from '../../../../../redux/actions/MacTypes/MacTypeActions'
import { confirmAlert } from 'react-confirm-alert';
import history from '../../../../../history';
import Table from '../../../../../components/FreezePaneTable/FreezePaneTable'
import { getRolesTreeView, getRoleList } from '../../../../../redux/actions/Role/roleActions'
import { checkPageAccess, checkPageActionAccess, checkPageAccessField, isSuperAdmin } from '../../../../../utils/helper'
import dictionary from '../../../../../redux/actions/Translation/dictionary'
import './RoleAccess.scss';

class RoleAccess extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            translations: {
                ...this.convert()
            },
            translationTimestamp: undefined,
            tableState: {
                add: () => {
                    history.push('/configuration/role-access');
                },
                // edit: ({ id }) => {
                //     localStorage.setItem('redirectConfigSubType', 'true');
                // },
                // remove: ({ id }) => {
                //     confirmAlert({
                //         title: 'Are you sure you want delete machine sub type?',
                //         message: '',
                //         buttons: [
                //             {
                //                 label: 'YES',
                //                 onClick: () => {
                //                 }
                //             },
                //             {
                //                 label: 'NO',
                //             }
                //         ]
                //     });
                // },
                search: true,
                highlight: true,
                checkBoxIsRead: true,
                keys: this.initTableHeader(),
                data: [],
                loading: false,
                collapsable: true,
                levelInstance: {
                    parent: 'details',
                    child: [
                        'roles'
                    ]
                },
                timeStamp: undefined
            }
        }
    }

    resetRedirect() {
        localStorage.removeItem('redirectConfigSubType');
    }

    /**handler */

    initTableHeader = () => {
        let { ACCESS_RIGHTS } = this.convert()
        return [
            {
                name: ACCESS_RIGHTS,
                key: 'pageCategory',
                // headerWidth: '26',
                // width: '25',
                childKey: 'pageCategory',
                // alt: 'Page',
            },
        ]
    }
    convert = () => {
        let { ACCESS_RIGHTS, CHECKLIST_TEMPLATES, CHECKLIST_DETAILS, DASHBOARD, LINE_OVERVIEW, LOCATIONS, ATTACHMENTS, BRANDS, MACHINE_SUB_TYPES, MACHINE_TYPES, MOTOR_TYPES, MACHINE_DETAILS, MACHINE_MAINTENANCE_HISTORY, MACHINE_CHECKLIST_TEMPLATE, MACHINE_REPAIR_HISTORY, SYSTEM_PREFERENCE, TICKET_DETAILS, TICKET_MANAGEMENT, ROLE_MANAGEMENT, MACHINES, Maintenance, REPAIR_TYPES, USER_MANAGEMENT, MACHINE_MASTER_DATA, Machines_Up_For_Maintenance } = dictionary.GET()
        return dictionary.TRANSLATE({ ACCESS_RIGHTS, CHECKLIST_TEMPLATES, CHECKLIST_DETAILS, DASHBOARD, LINE_OVERVIEW, LOCATIONS, ATTACHMENTS, BRANDS, MACHINE_SUB_TYPES, MACHINE_TYPES, MOTOR_TYPES, MACHINE_DETAILS, MACHINE_MAINTENANCE_HISTORY, MACHINE_CHECKLIST_TEMPLATE, MACHINE_REPAIR_HISTORY, SYSTEM_PREFERENCE, TICKET_DETAILS, TICKET_MANAGEMENT, ROLE_MANAGEMENT, MACHINES, Maintenance, REPAIR_TYPES, USER_MANAGEMENT, MACHINE_MASTER_DATA, Machines_Up_For_Maintenance })
    }

    listSanitize = (arr) => {
        let { levelInstance: { parent, child } } = this.state.tableState
        let { CHECKLIST_TEMPLATES, CHECKLIST_DETAILS, DASHBOARD, LINE_OVERVIEW, LOCATIONS, ATTACHMENTS, BRANDS, MACHINE_SUB_TYPES, MACHINE_TYPES, MOTOR_TYPES, MACHINE_DETAILS, MACHINE_MAINTENANCE_HISTORY, MACHINE_CHECKLIST_TEMPLATE, MACHINE_REPAIR_HISTORY, SYSTEM_PREFERENCE, TICKET_DETAILS, TICKET_MANAGEMENT, ROLE_MANAGEMENT, MACHINES, Maintenance, REPAIR_TYPES, USER_MANAGEMENT, MACHINE_MASTER_DATA, Machines_Up_For_Maintenance } = this.convert()
        let toReturn = [] 
        let sanitize = (value) => {
            return {
                'Checklist Templates': CHECKLIST_TEMPLATES,
                'Checklist - Details': CHECKLIST_DETAILS,
                'Dashboard': DASHBOARD,
                'Line Overview': LINE_OVERVIEW,
                'Locations': LOCATIONS,
                'Attachments': ATTACHMENTS,
                'Brands': BRANDS,
                'Machine Sub Types': MACHINE_SUB_TYPES,
                'Machine Types': MACHINE_TYPES,
                'Motor Types': MOTOR_TYPES,
                'Machines': MACHINES,
                'Machine - Details': MACHINE_DETAILS,
                'Machine - Maintenance History': MACHINE_MAINTENANCE_HISTORY,
                'Machine - Checklist Template': MACHINE_CHECKLIST_TEMPLATE,
                'Machine - Repair History': MACHINE_REPAIR_HISTORY,
                'Maintenance': Maintenance,
                'Repair Types': REPAIR_TYPES,
                'System Preference': SYSTEM_PREFERENCE,
                'Ticket - Details': TICKET_DETAILS,
                'Ticket Management': TICKET_MANAGEMENT,
                'Role Management': ROLE_MANAGEMENT,
                'User Management': USER_MANAGEMENT,
                'Machine Master Data': MACHINE_MASTER_DATA,
                'Machines Up For Maintenance': Machines_Up_For_Maintenance
            }[value] ?? value
        }

        arr.forEach((x, arrIndex) => {
            let tempObject = Object.assign({}, x)
            tempObject[parent]['pageCategory'] = sanitize(x[parent]['pageCategory'])
            x[child[0]].forEach((childAt, childIndex) => {
                tempObject[child[0]][childIndex]['pageCategory'] = sanitize(childAt['pageCategory'])
            })
            toReturn.push(tempObject)
        })

        return toReturn

    }


    componentDidMount() {
        this.resetRedirect();
        this.props.getRolesTreeView();
        this.props.getRoleList()
    }

    componentDidUpdate() {
        // console.log('didUpdate', this.props, this.state)
        let { loading, roleListTreeView, roleListData, roleListTreeViewTimeStamp } = this.props
        let { loading: stateLoading, data, timeStamp, levelInstance: { parent, child }, keys } = this.state.tableState
        if (loading != stateLoading || roleListTreeView?.length != data?.length || roleListTreeViewTimeStamp != timeStamp) {
            if (Array.isArray(roleListTreeView) && Array.isArray(roleListData)) {
                if (roleListTreeView?.length > 0) {
                    const sanitizeSpace = (string) => {
                        return string.replace(/\s/gi, '')
                    }
                    const isExist = (toFind) => {
                        return keys.findIndex(keyI => keyI['name'] == toFind)
                    }
                    let possibleHeader = roleListTreeView[0]['roles'][0]['tableHeader']
                    roleListTreeView.forEach((treeInstance, treeIndex) => {
                        let headerKeys = {}
                        possibleHeader.forEach(headerInstance => {
                            let lastValue = 0
                            let _indeterValue = [false, false, false]

                            treeInstance['roles'].forEach((treeChildInstance, index) => {
                                if (treeChildInstance[sanitizeSpace(headerInstance)] == 0) _indeterValue[0] = true
                                if (treeChildInstance[sanitizeSpace(headerInstance)] == 1) _indeterValue[1] = true
                                if (treeChildInstance[sanitizeSpace(headerInstance)] == 2) _indeterValue[2] = true
                                lastValue = treeChildInstance[sanitizeSpace(headerInstance)]
                            })
                            if (_indeterValue[0] == _indeterValue[1] && (_indeterValue[1] == true)) headerKeys = Object.assign(headerKeys, { [sanitizeSpace(headerInstance)]: 2 })
                            else {
                                if (_indeterValue[2] == true && _indeterValue[0] == false) headerKeys = Object.assign(headerKeys, { [sanitizeSpace(headerInstance)]: 1 })
                                else headerKeys = Object.assign(headerKeys, { [sanitizeSpace(headerInstance)]: lastValue })
                            }


                        })
                        roleListTreeView[treeIndex][parent] = Object.assign(roleListTreeView[treeIndex][parent], { ...headerKeys })
                    })
                    // roleListData.filter((item) => { return item.isActive === true }).forEach((keyP, index) => {
                    keys = [keys[0]]
                    roleListData.filter((item) => { return item.isActive === true }).forEach((keyP, index) => {
                        // if(index < 2){
                        if (isExist(keyP['role']) == -1) {
                            let checkboxKey = sanitizeSpace(keyP['role'])
                            let headerPush = '/configuration/role-access/' + keyP['id']

                            let objKey = {
                                name: keyP['role'].substr(0, 10),
                                headerLink: () => {
                                    history.push(headerPush)
                                },
                                completeName: keyP['role'],
                                type: 'checkbox',
                                // headerWidth: '10',
                                // width: '72px',
                                key: checkboxKey,
                                disable: false,
                                childKey: checkboxKey,
                            }
                            keys.push(objKey)
                        }
                        // }
                    })

                    roleListData.filter((item) => { return item.isActive === false }).forEach((keyP, index) => {
                        // if(index < 0){
                        if (isExist(keyP['role']) == -1) {
                            let checkboxKey = sanitizeSpace(keyP['role']);
                            let headerPush = null;
                            if (checkPageActionAccess(this.props.userAccess, { pageId: 26, pageAction: 'Edit' }) === false && checkPageAccessField(this.props.UserAccess, { pageId: 25, pageAccess: 'VIEW' }) === false && !isSuperAdmin) {

                            }
                            else {
                                headerPush = '/configuration/role-access/' + keyP['id']
                            }

                            let objKey = {
                                name: keyP['role'].substr(0, 10),
                                headerLink: () => {
                                    history.push(headerPush)
                                },
                                completeName: keyP['role'],
                                key: '',
                                childKey: '',
                                // headerWidth: '10',
                                // width: '72px',
                                disable: true
                            }

                            keys.push(objKey);
                        }
                        // }
                    })

                }
                let tableState = this.state.tableState;
                if (checkPageActionAccess(this.props.userAccess, { pageId: 26, pageAction: 'Add' }) === false && !isSuperAdmin()) {
                    tableState = {
                        ...tableState,
                        add: null
                    }
                }
                // roleListTreeView.splice(3)
                localStorage['roleListTreeView'] = JSON.stringify(roleListTreeView)
                this.listSanitize(roleListTreeView.slice())
                this.setState({
                    tableState: {
                        ...this.state.tableState,
                        data: [...roleListTreeView],
                        keys,
                        loading,
                        timeStamp: roleListTreeViewTimeStamp
                    }
                })
            }
        }
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            let { keys } = this.state.tableState
            keys[0] = this.initTableHeader()[0]

            const sanitizeSpace = (string) => {
                return string.replace(/\s/gi, '')
            }
            let possibleHeader = roleListTreeView[0]['roles'][0]['tableHeader']
            roleListTreeView.forEach((treeInstance, treeIndex) => {
                let headerKeys = {}
                possibleHeader.forEach(headerInstance => {
                    let lastValue = 0
                    let _indeterValue = [false, false, false]

                    treeInstance['roles'].forEach((treeChildInstance, index) => {
                        if (treeChildInstance[sanitizeSpace(headerInstance)] == 0) _indeterValue[0] = true
                        if (treeChildInstance[sanitizeSpace(headerInstance)] == 1) _indeterValue[1] = true
                        if (treeChildInstance[sanitizeSpace(headerInstance)] == 2) _indeterValue[2] = true
                        lastValue = treeChildInstance[sanitizeSpace(headerInstance)]
                    })
                    if (_indeterValue[0] == _indeterValue[1] && (_indeterValue[1] == true)) headerKeys = Object.assign(headerKeys, { [sanitizeSpace(headerInstance)]: 2 })
                    else {
                        if (_indeterValue[2] == true && _indeterValue[0] == false) headerKeys = Object.assign(headerKeys, { [sanitizeSpace(headerInstance)]: 1 })
                        else headerKeys = Object.assign(headerKeys, { [sanitizeSpace(headerInstance)]: lastValue })
                    }


                })
                roleListTreeView[treeIndex][parent] = Object.assign(roleListTreeView[treeIndex][parent], { ...headerKeys })
            })
            // roleListTreeView = this.listSanitize(roleListTreeView)
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                translations: Object.assign({}, { ...this.convert() }),
                tableState: {
                    ...this.state.tableState,
                    data: this.listSanitize(JSON.parse(localStorage['roleListTreeView']).slice()),
                    keys,
                }
            })
        }
    }

    componentWillUnmount() {
    }

    /**render */
    render() {
        return (
            <div className="subtype-body-overview">
                <Table {...this.state.tableState} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    let { roleReducer, translationReducer } = state
    let apiLoading = {
    }
    let data = {
    }

    return {
        ...apiLoading,
        ...data,
        ...roleReducer,
        ...translationReducer

    }
}

const mapDispatchToProps = dispatch => {
    return {
        getRolesTreeView: () => dispatch(getRolesTreeView()),
        getRoleList: () => dispatch(getRoleList()),
    }
}

RoleAccess = connect(mapStateToProps, mapDispatchToProps)(RoleAccess);

export default RoleAccess;
