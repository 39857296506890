import React, { useState, useEffect } from 'react';
import { Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Filter from './Filters/Filter';
import CreateTemplateModal from './components/CreateTemplateModal';
import { client } from './api';
import axios from 'axios';
import { normalizeStructures, tagFilterDisabled, normalizeFilter, compactObject } from './helpers'
import { uniqBy } from './helpers';
import { format, parse } from 'date-fns';

const useStyles = makeStyles({
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 15
  },
  headerTitle: {
    padding: 15,
  },
  resetFilters: {
    fontSize: '1.17rem'
  },
  numberOfSelected: {
    lineHeight: 2,
    color: 'grey'
  },
  paper: {
    height: "100%",
    boxShadow: "0px 3px 6px #00000029"
  },
  actions: {
    padding: 15,
  },
  saveBtn: {
    marginTop: 10
  }
})

export default function Filters(props){
  const { onFilter, onSave, currentFilters, templates } = props;
  const classes = useStyles();
  const [resources, setResources] = useState({
    buildings: [],
    floors: [],
    areas: [],
    lines: [],
    statuses: [],
    shifts: [],
    machineTypes: [],
    machineSubTypes: [],
    machineBrands: [],
    machineSupplier: [],
    mechanics: []
  })
  const [isCreatingTemplate, setIsCreatingTemplate] = useState(false);

  useEffect(()=>{
    const fetchBuildings = client.get("/structures/buildings");
    const fetchStatuses = client.get("/structures/statuses");
    const fetchMechanics = client.get("/structures/mechanics");
    const fetchMachineTypes = client.get("/structures/machineTypes");
    const fetchMachineSubTypes = client.get("/structures/machineSubTypes");
    const fetchMachineBrands = client.get("/structures/machineBrands");
    const fetchSupplier = client.get("/structures/suppliers");
    const fetchShifts = client.get("/structures/shifts");
    axios.all([
      fetchBuildings,
      fetchStatuses,
      fetchMechanics,
      fetchMachineTypes,
      fetchMachineSubTypes,
      fetchMachineBrands,
      fetchSupplier,
      fetchShifts,
    ]
    ).then((responses)=>{
      const [
        buildingRes, 
        statusesRes, 
        mechanicsRes, 
        machineTypesRes,
        machineSubTypesRes,
        machineBrandsRes,
        suppliersRes,
        shiftsRes, 
      ] = responses;
      const buildingsData = normalizeStructures(buildingRes.data);
      const statusesData = statusesRes.data.map((s)=>({ ...s, isSelected: true }));
      const mechanicsData = mechanicsRes.data.map((s)=>({ ...s, isSelected: true }));
      const machineTypesData = machineTypesRes.data.map((s)=>({ ...s, isSelected: true }));
      const machineSubTypesData = machineSubTypesRes.data.map((s)=>({ ...s, isSelected: true }));
      const machineBrandsData = machineBrandsRes.data.map((s)=>({ ...s, isSelected: true }));
      const supplierData = suppliersRes.data.map((s)=>({ ...s, isSelected: true }));
      const shiftsData = shiftsRes.data.map((s)=>{
        const startTime = s.start ? parse(s.start, "H:mm:ss", new Date()) : null;
        const endTime = s.end ? parse(s.end, "H:mm:ss", new Date()) : null;
        return {
          ...s,
          name: `${s.name} (${startTime ? format(startTime, "H:mm") : null} - ${endTime ? format(endTime, "H:mm") : null})`,
          isSelected: true
        }
      });

      setResources((rs)=>({
        ...rs,
        buildings: uniqBy(buildingsData.buildings, 'id'),
        floors: uniqBy(buildingsData.floors, 'id'),
        areas: uniqBy(buildingsData.areas, 'id'),
        lines: uniqBy(buildingsData.lines, 'id'),
        statuses: statusesData,
        mechanics: mechanicsData,
        machineTypes: machineTypesData,
        machineSubTypes: machineSubTypesData,
        machineBrands: machineBrandsData,
        machineSupplier: supplierData,
        shifts: shiftsData
      }))
    })

  }, []);

  useEffect(()=>{
    setResources((rs)=>{
      Object.keys(rs).forEach((key)=>{
        if(currentFilters[key]){
          const selected = currentFilters[key];
          rs[key] = rs[key].map((resource)=>({ ...resource, isSelected: selected.includes(resource.id) }))
        }else{
          rs[key] = rs[key].map((resource)=>({ ...resource, isSelected: true }))
        }
      })
      return rs
    })

  }, [currentFilters])


  const handleFilter = ()=>{
    let filters = {};
    Object.keys(resources).forEach((key)=>{
      filters[key] = normalizeFilter(resources[key])
    })
    onFilter(compactObject(filters));
  }

  const resetFilters = ()=>{
    setResources((rs)=>{
      let result = { ...rs };
      Object.keys(result).forEach((key) => {
        result[key] = result[key].map((item) => ({ ...item, isSelected: true }));
      });
      return result;
    });
  }

  const buildings = tagFilterDisabled(resources.buildings);
  const floors = tagFilterDisabled(resources.floors, resources.buildings);
  const areas = tagFilterDisabled(resources.areas, resources.floors);
  const lines = tagFilterDisabled(resources.lines, resources.areas);

  localStorage.setItem('analyticFilter', JSON.stringify(resources))

  return <Paper className={classes.paper}>
    <div className={classes.headerWrapper}>
      <h3 className={classes.headerTitle}>{'Your Filter'.translate()}</h3>
      <Button
        className={classes.resetFilters}
        color="primary"
        onClick={()=>resetFilters()}
      >
        {'Reset'.translate()}
      </Button>
    </div>
    <Filter
      title={"Building".translate()}
      entities={buildings}
      setEntities={(buildings)=> setResources({ ...resources, buildings })}
    />
    <Filter
      title={'Floor'.translate()}
      entities={floors}
      setEntities={(floors)=> setResources({ ...resources, floors })}
    />
    <Filter
      title={'Area'.translate()}
      entities={areas}
      setEntities={(areas)=> setResources({ ...resources, areas })}
    />
    <Filter
      title={"Line".translate()}
      entities={lines}
      setEntities={(lines)=> setResources({ ...resources, lines })}
    />
    <Filter
      title={"Mechanic".translate()}
      entities={resources.mechanics}
      setEntities={(mechanics)=> setResources({ ...resources, mechanics })}
    />
    <Filter
      title={"Machine Types".translate()}
      entities={resources.machineTypes}
      setEntities={(machineTypes)=> setResources({ ...resources, machineTypes })}
    />
    <Filter
      title={"Machine Sub-types".translate()}
      entities={resources.machineSubTypes}
      setEntities={(machineSubTypes)=> setResources({ ...resources, machineSubTypes })}
    />
    <Filter
      title={"Machine Brands".translate()}
      entities={resources.machineBrands}
      setEntities={(machineBrands)=> setResources({ ...resources, machineBrands })}
    />
    <Filter
      title={"Machine Supplier".translate()}
      entities={resources.machineSupplier}
      setEntities={(machineSupplier)=> setResources({ ...resources, machineSupplier })}
    />
    <Filter
      title={"Shifts".translate()}
      entities={resources.shifts}
      setEntities={(shifts)=> setResources({ ...resources, shifts })}
    />

    <div className={classes.actions}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={()=> handleFilter()}
      >
        {"Filter".translate()}
      </Button>

      <Button
        variant="outlined"
        color="primary"
        className={classes.saveBtn}
        fullWidth
        onClick={()=>setIsCreatingTemplate(true)}
      >
        {"Save As Template".translate()}
      </Button>
    </div>
    <CreateTemplateModal
      templates={templates}
      isOpen={isCreatingTemplate}
      onClose={()=> setIsCreatingTemplate(false)}
      onSubmit={onSave}
    />
  </Paper>
}
