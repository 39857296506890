import React, { Component } from 'react';
import history from './history';
import { checkPageAccess, checkPageActionAccess, isSuperAdmin, checkPageAccessField } from './utils/helper';
import { PAGE_ENUMS } from './utils/constants';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import App from './containers/App/App';

/* CONTAINERS */
import Login from './containers/Modules/Login/Login';
import Logout from './containers/Modules/Logout/Logout';

import Dashboard from './containers/Modules/Dashboard/Dashboard';
import DashboardOverall from './containers/Modules/Dashboard/DashboardOverall';

/* MACHINES */
import Machines from './containers/Modules/Machines/Machines';
import MachineForm from './containers/Modules/Machines/MachineForm/MachineForm';
import MachineDetail from './containers/Modules/Machines/MachineDetail/MachineDetail';
import TicketForm from './containers/Modules/Tickets/TicketForm/TicketForm';
import TicketDetails from './containers/Modules/Tickets/TicketDetails/TicketDetails';

/* TICKETS */
import Tickets from './containers/Modules/Tickets/Tickets'

/* LINE STATUS */
import LineStatus from './containers/Modules/LineStatus/LineStatus';
import LineStatusTvMode from './containers/Modules/LineStatus/LineStatusTvMode/LineStatusTvMode';

/* MAINTENANCE */
import Maintenance from './containers/Modules/Maintenance/Maintenance';
import Calendar from './containers/Modules/Calendar/Calendar'

/* CHECKLIST */
import Checklist from './containers/Modules/Checklist/Checklist';
import ChecklistForm from './containers/Modules/Checklist/ChecklistForm/ChecklistForm';
import ChecklistDetail from './containers/Modules/Checklist/ChecklistDetail/ChecklistDetail';

/* CHANGEOVER */
import ChangeOversList from './containers/Modules/ChangeOvers/List';
import NewChangeover from './containers/Modules/ChangeOvers/New';
import ChangeoverDetail from './containers/Modules/ChangeOvers/Detail';
import ChangeoverEdit from './containers/Modules/ChangeOvers/Edit';

/* CONFIGURATION */
import MachineConfig from './containers/Modules/Configuration/MachineConfig/MachineConfig';
import BrandForm from './containers/Modules/Configuration/MachineConfig/BrandForm/BrandForm';
import MotorTypeForm from './containers/Modules/Configuration/MachineConfig/MotorTypeForm/MotorTypeForm';
import MachineTypeForm from './containers/Modules/Configuration/MachineConfig/MachineTypeForm/MachineTypeForm';
import MachineSubTypeForm from './containers/Modules/Configuration/MachineConfig/MachineSubTypeForm/MachineSubTypeForm';
import AttachmentForm from './containers/Modules/Configuration/MachineConfig/AttachmentForm/AttachmentForm';
import MachineConfigHub from './containers/Modules/Configuration/MachineConfig/Hub';

import RepairTypeConfig from './containers/Modules/Configuration/RepairTypeConfig/RepairTypeConfig';
import SolutionType from './containers/Modules/Configuration/RepairTypeConfig/SolutionType/SolutionType';

import SystemPreference from './containers/Modules/Configuration/SystemPreference/SystemPreference';

import UserAccessConfig from './containers/Modules/Configuration/UserAccessConfig/UserAccessConfig';
import UserAccessProfile from './containers/Modules/Configuration/UserAccessConfig/UserAccessProfile/UserAccessProfile';
import RoleAccessForm from './containers/Modules/Configuration/UserAccessConfig/RoleAccessForm/RoleAccessForm';

/* Analytics */
import Analytics from './containers/Modules/Analytics/Analytics';

const userToken = JSON.parse(localStorage.getItem('userToken'));
const userAccess = JSON.parse(localStorage.getItem('userAccess'));

class Routes extends Component {

    state = {
        pageIdEnum: {
            machines: 1,
            machineDetail: 2,
            tickets: 6,
            ticketDetail: 7,
            lineOverview: 8,
            machinesUpForMaintenance: 9,
            dashbard: 12,
            checklistTemplates: 15,
            checklistDetail: 16,
            locations: 17,
            brands: 18,
            machineTypes: 19,
            machineSubTypes: 20,
            motorTypes: 21,
            attachments: 22,
            repairTypes: 23,
            systemPreference: 24,
            userManagement: 25,
            roleManagement: 26,
            supplierTypes: 28,
        },
        loaded: !!userToken,
        token: userToken,
        access: userAccess || []
    }

    componentWillMount() {
        if (localStorage.getItem('userToken')) {
            this.setState({
                token: JSON.parse(localStorage.getItem('userToken')),
                access: JSON.parse(localStorage.getItem('userAccess')),
                loaded: true
            })
        }
        else {
            this.setState({
                loaded: true
            });
        }
    }

    renderRoutes() {
        if (this.state.token) {
            return this.renderProtectedRoutes();
        } else {
            return this.renderUnprotectedRoutes();
        }
    }

    renderUnprotectedRoutes() {
        return (
            <Switch>
                <Route exact name="login" path="/" component={Login} />
                {/* connect with rpi global admin */}
                <Route exact name='dashboardoverall' path='/dashboard/overall' component={DashboardOverall} />

                {/* TV MODE WITH TOKENS */}
                <Route exact name='dashboard' path='/dashboard/:action/:token' component={Dashboard} />
                <Route exact name='line-status-tv-mode' path='/line-status/tv-mode/:id/:token' component={LineStatusTvMode} />

                <Redirect from="*" to="/" />
            </Switch>
        )
    }

    renderProtectedRoutes() {
        return (
            <Switch>
                {/* LOGOUT */}
                <Route exact name="logout" path="/logout" component={Logout} />

                {/* DASHBOARD */}
                <Route exact name='dashboard' path='/dashboard' component={Dashboard} />

                {/* MACHINE OVERVIEW */}
                {checkPageAccessField(this.state.access, { page: PAGE_ENUMS.machines, pageAccess: 'NONE' }) === false || checkPageAccess(this.state.access, { page: PAGE_ENUMS.machines }) ? <Route exact name='machines' path='/machines' component={Machines} /> : null}

                {/* MACHINE ADD */}
                {checkPageActionAccess(this.state.access, { page: PAGE_ENUMS.machines, pageAction: "Add" }) ? <Route exact name='machine-detail' path='/machine-add/' component={MachineForm} /> : null}

                {/* MACHINE EDIT */}
                {checkPageActionAccess(this.state.access, { page: PAGE_ENUMS.machineDetail, pageAction: 'Edit' }) ? <Route exact name='machine-detail' path='/machine-edit/:id' component={MachineForm} /> : null}

                {/* MACHINE DETAIL */}
                {checkPageAccessField(this.state.access, { page: PAGE_ENUMS.machineDetail, pageAccess: 'VIEW' }) || checkPageAccess(this.state.access, { page: PAGE_ENUMS.machineDetail }) ? <Route exact name='machine-detail' path='/machine-detail/:id' component={MachineDetail} /> : null}

                {/* CREATE TICKET */}
                {checkPageActionAccess(this.state.access, { page: PAGE_ENUMS.machineDetail, pageAction: "Create Ticket" }) ? <Route exact name='tickets' path='/create-ticket/:id' component={TicketForm} /> : null}

                {/* TICKET OVERVIEW */}
                {checkPageAccessField(this.state.access, { page: PAGE_ENUMS.tickets, pageAccess: 'NONE' }) === false || checkPageAccess(this.state.access, { page: PAGE_ENUMS.tickets }) ? <Route exact name='tickets' path='/tickets' component={Tickets} /> : null}

                {/* TICKET DETAIL */}
                {checkPageAccessField(this.state.access, { page: PAGE_ENUMS.ticketDetail, pageAccess: 'NONE' }) === false || checkPageAccess(this.state.access, { page: PAGE_ENUMS.ticketDetail }) ? <Route exact name='tickets-detail' path='/ticket-detail/:id' component={TicketDetails} /> : null}

                {/* LINE STATUS OVERVIEW */}
                {checkPageAccessField(this.state.access, { page: PAGE_ENUMS.lineOverview, pageAccess: 'NONE' }) === false ? <Route exact name='line-status' path='/line-status' component={LineStatus} /> : null}

                {/* LINE STATUS TV MODE */}
                {checkPageAccessField(this.state.access, { page: PAGE_ENUMS.lineOverview, pageAccess: 'NONE' }) === false || checkPageAccess(this.state.access, { page: PAGE_ENUMS.lineOverview }) ? <Route exact name='line-status-tv-mode' path='/line-status/tv-mode/:id' component={LineStatusTvMode} /> : null}

                {/* MACHINES UP FOR MAINTENANCE OVERVIEW */}
                {checkPageAccessField(this.state.access, { page: PAGE_ENUMS.machinesUpForMaintenance, pageAccess: 'NONE' }) === false ? <Route exact name='maintenance' path='/maintenance' component={Maintenance} /> : null}

                {/* TV MODE WITH TOKENS */}
                <Route exact name='dashboard' path='/dashboard/:action/:token' component={Dashboard} />
                <Route exact name='line-status-tv-mode' path='/line-status/tv-mode/:id/:token' component={LineStatusTvMode} />

                {/* CHECKLIST AND CALENDAR */}
                {checkPageAccessField(this.state.access, { page: PAGE_ENUMS.checklistTemplates, pageAccess: 'NONE' }) === false ? <Route exact name='checklist' path='/checklist' component={Checklist} /> : null}
                {checkPageAccessField(this.state.access, { page: PAGE_ENUMS.machinesUpForMaintenance, pageAccess: 'NONE' }) === false ? <Route exact name='maintenance-calendar' path='/maintenance-calendar' component={Calendar} /> : null}
                {checkPageAccess(this.state.access, { page: PAGE_ENUMS.checklistTemplates }) ? <Route exact name='checklist-form' path='/checklist/:action/:id?' component={ChecklistForm} /> : null}
                {checkPageAccessField(this.state.access, { page: PAGE_ENUMS.checklistDetail, pageAccess: 'NONE' }) === false ? <Route exact name='checklist-detail' path='/checklist-detail/:id' component={ChecklistDetail} /> : null}

                {/* CONFIGURATIONS  */}
                <Route exact name='configuration-machine-hub' path='/configuration/machine-config/hub' component={MachineConfigHub} />
                <Route exact name='configuration-machine' path='/configuration/machine-config/:config' component={MachineConfig} />
                {checkPageActionAccess(this.state.access, { page: PAGE_ENUMS.brands, pageAction: "Add" }) ? <Route exact name='configuration-brand-new' path='/configuration/machine-config/brands/new' component={BrandForm} /> : null}
                {checkPageActionAccess(this.state.access, { page: PAGE_ENUMS.brands, pageAction: "Edit" }) ? <Route exact name='configuration-brand-edit' path='/configuration/machine-config/brands/edit/:id' component={BrandForm} /> : null}
                {checkPageActionAccess(this.state.access, { page: PAGE_ENUMS.motorTypes, pageAction: "Add" }) ? <Route exact name='configuration-motor-type-new' path='/configuration/machine-config/motor-type/new' component={MotorTypeForm} /> : null}
                {checkPageActionAccess(this.state.access, { page: PAGE_ENUMS.motorTypes, pageAction: "Edit" }) ? <Route exact name='configuration-motor-type-edit' path='/configuration/machine-config/motor-type/edit/:id' component={MotorTypeForm} /> : null}
                {checkPageActionAccess(this.state.access, { page: PAGE_ENUMS.machineTypes, pageAction: "Add" }) ? <Route exact name='configuration-machine-type-new' path='/configuration/machine-config/machine-type/new' component={MachineTypeForm} /> : null}
                {checkPageActionAccess(this.state.access, { page: PAGE_ENUMS.machineTypes, pageAction: "Edit" }) ? <Route exact name='configuration-machine-type-edit' path='/configuration/machine-config/machine-type/edit/:id' component={MachineTypeForm} /> : null}
                {checkPageActionAccess(this.state.access, { page: PAGE_ENUMS.machineSubTypes, pageAction: "Add" }) ? <Route exact name='configuration-machine-sub-type-new' path='/configuration/machine-config/machine-sub-type/new/:machineTypeId' component={MachineSubTypeForm} /> : null}
                {checkPageActionAccess(this.state.access, { page: PAGE_ENUMS.machineSubTypes, pageAction: "Edit" }) ? <Route exact name='configuration-machine-sub-type-edit' path='/configuration/machine-config/machine-sub-type/edit/:machineTypeId/:machineSubTypeId' component={MachineSubTypeForm} /> : null}
                {checkPageActionAccess(this.state.access, { page: PAGE_ENUMS.attachments, pageAction: "Add" }) ? <Route exact name='configuration-attachment-new' path='/configuration/machine-config/attachment/new' component={AttachmentForm} /> : null}
                {checkPageActionAccess(this.state.access, { page: PAGE_ENUMS.attachments, pageAction: "Edit" }) ? <Route exact name='configuration-attachment-edit' path='/configuration/machine-config/attachment/edit/:id' component={AttachmentForm} /> : null}

                {checkPageAccess(this.state.access, { page: PAGE_ENUMS.repairTypes }) || checkPageAccessField(this.state.access, { page: PAGE_ENUMS.repairTypes, pageAccess: 'VIEW' }) ? <Route exact name='configuration-repair-type' path='/configuration/repair-type' component={RepairTypeConfig} /> : null}
                {checkPageAccess(this.state.access, { page: PAGE_ENUMS.repairTypes }) || checkPageAccessField(this.state.access, { page: PAGE_ENUMS.repairTypes, pageAccess: 'VIEW' }) ? <Route exact name='configuration-solution-type' path='/configuration/solution-type/:id' component={SolutionType} /> : null}
                {checkPageAccess(this.state.access, { page: PAGE_ENUMS.systemPreference }) || checkPageAccessField(this.state.access, { page: PAGE_ENUMS.systemPreference, pageAccess: 'NONE' }) ? <Route exact name='configuration-system-preference' path='/configuration/system-preference' component={SystemPreference} /> : null}

                {checkPageAccess(this.state.access, { pageId: this.state.pageIdEnum.userManagement }) || checkPageAccess(this.state.access, { pageId: this.state.pageIdEnum.roleManagement }) || checkPageAccessField(this.state.access, { pageId: this.state.pageIdEnum.userManagement, pageAccess: 'VIEW' }) || checkPageAccessField(this.state.access, { pageId: this.state.pageIdEnum.roleManagement, pageAccess: 'VIEW' }) || isSuperAdmin() ? <Route exact name='configuration-user-access' path='/configuration/user-manage/:config' component={UserAccessConfig} /> : null}
                {checkPageAccess(this.state.access, { pageId: this.state.pageIdEnum.userManagement }) || checkPageAccessField(this.state.access, { pageId: this.state.pageIdEnum.userManagement, pageAccess: 'VIEW' }) || isSuperAdmin() ? <Route exact name='configuration-user-access' path='/configuration/user-profile' component={UserAccessProfile} /> : null}
                {checkPageAccess(this.state.access, { pageId: this.state.pageIdEnum.userManagement }) || checkPageAccessField(this.state.access, { pageId: this.state.pageIdEnum.userManagement, pageAccess: 'VIEW' }) || isSuperAdmin() ? <Route exact name='configuration-user-access' path='/configuration/user-profile/:id' component={UserAccessProfile} /> : null}

                {checkPageAccess(this.state.access, { pageId: this.state.pageIdEnum.roleManagement }) || checkPageAccessField(this.state.access, { pageId: this.state.pageIdEnum.roleManagement, pageAccess: 'VIEW' }) || isSuperAdmin() ? <Route exact name='configuration-user-access' path='/configuration/role-access' component={RoleAccessForm} /> : null}
                {checkPageAccess(this.state.access, { pageId: this.state.pageIdEnum.roleManagement }) || checkPageAccessField(this.state.access, { pageId: this.state.pageIdEnum.roleManagement, pageAccess: 'VIEW' }) || isSuperAdmin() ? <Route exact name='configuration-user-access' path='/configuration/role-access/:id' component={RoleAccessForm} /> : null}
                {checkPageAccess(this.state.access, { page: PAGE_ENUMS.analytics }) || isSuperAdmin() ? <Route exact name='analytics' path='/analytics' component={Analytics} /> : null}
                <Route exact name='changeovers' path='/changeovers' component={ChangeOversList} />
                <Route exact name='NewChangeover' path='/changeover-add' component={NewChangeover} />
                <Route exact name='ChangeoverDetail' path='/changeover-detail/:id' component={ChangeoverDetail} />
                <Route exact name='ChangeoverEdit' path='/changeover-edit/:id' component={ChangeoverEdit} />


                {/* test components */}
                {/* <Route exact name='test-collapse-table' path='/test/collapseTable' component={CollapseTable} /> */}
                <Redirect from="*" to="/dashboard" />
            </Switch>
        )
    }

    render() {
        return (
            <Router history={history}>
                <App>
                    {this.state.loaded ? this.renderRoutes() : ''}
                </App>
            </Router>
        );
    }
}

export default Routes;
