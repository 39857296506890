import {
    NOTIFICATION_SUCCESS,
    NOTIFICATION_ERROR,
    CHANGEOVER_TYPE_NEW_REQUEST,
    CHANGEOVER_TYPE_LIST_SUCCESS,
    CHANGEOVER_TYPE_LIST_REQUEST,
    CHANGEOVER_TYPE_DELETE_REQUEST,
    CHANGEOVER_TYPE_TRIGGER_REQUEST,
    CHANGEOVER_TYPE_UPDATE_REQUEST,
    CHANGEOVER_TYPE_UPDATE_SUCCESS,
} from '../actionTypes';
import { getFactoryId } from '../../../utils/helper';
import { tokenGet, tokenPost, tokenPut,tokenDelete, apiSettings } from '../../../utils/api/CoreApi';

export const notificationSuccess = (message) => {
	return {
			type: NOTIFICATION_SUCCESS,
			payload: {
					message: message.translate(),
			}
	}
}

export const notificationError = (message) => {
	return {
			type: NOTIFICATION_ERROR,
			payload: {
					message: message.translate(),
			}
	}
}

export const changeoverTypeRequest = () => {
	return {
			type: CHANGEOVER_TYPE_LIST_REQUEST,
	}
}

export const changeoverTypeDeleteRequest = () => {
	return {
			type: CHANGEOVER_TYPE_DELETE_REQUEST,
	}
}

export const changeoverTypeNewRequest = () => {
	return {
			type: CHANGEOVER_TYPE_NEW_REQUEST,
	}
}

export const changeoverTypeSuccess = changeoverTypeList => {
	return {
			type: CHANGEOVER_TYPE_LIST_SUCCESS,
			payload: changeoverTypeList,
	}
}

export const setUpdatedToFalse = () => {
	return {
			type: CHANGEOVER_TYPE_UPDATE_SUCCESS,
			payload: {
					updated: false,
			}
	}
}

export const changeoverTypesUpdateSuccess = updated => {
	return {
			type: CHANGEOVER_TYPE_UPDATE_SUCCESS,
			payload: updated,
	}
}

export const changeoverTypeTriggerHighlight = flag => {
	return {
			type: CHANGEOVER_TYPE_TRIGGER_REQUEST,
			payload: flag,
	}
}

export const changeoverTypeUpdateRequest = () => {
	return {
			type: CHANGEOVER_TYPE_UPDATE_REQUEST,
	}
}

export const getChangeoverTypes = () =>{
	const isActive = '';
	const currentPage = '';
	const pageSize = '';
	return function(dispatch){
		dispatch(changeoverTypeRequest());
		tokenGet(apiSettings.apiURLReferences + `/changeovertypes?&isActive=${isActive}&currentPage=${currentPage}&pageSize=${pageSize}&factoryId=${getFactoryId()}` ).then((response) => {
			let data = response.data.data;
			dispatch(changeoverTypeSuccess({
				changeoverTypeList: data
					? data.map((item) => ({
						...item,
						isActive: item.isActive ? 1 : 0,
					}))
					: [],
			}));
			dispatch(changeoverTypesUpdateSuccess({
				updated: false
			}))
		})
		.catch((error) => {
			console.log(error);
			dispatch(notificationError('Cannot get list'));
		});
	}
}

export const deleteChangeoverType = (id) => {
	return function(dispatch){
			dispatch(changeoverTypeDeleteRequest());
			tokenDelete(apiSettings.apiURLReferences + "/changeovertypes/" + id).then((response) => {
					dispatch(getChangeoverTypes());
					dispatch(notificationSuccess('Successfully deleted changeover type'));
			})
			.catch((error) => {
					if (error.message.indexOf(500) >= 0){
							dispatch(notificationError('Cannot delete because it is currently referenced to another record'));
					}
			});
	}
}

export const postNewChangeoverType = (payload) => {
	return function(dispatch){
			dispatch(changeoverTypeNewRequest());
			tokenPost(apiSettings.apiURLReferences + "/changeovertypes",payload).then((response) => {
					dispatch(getChangeoverTypes());
					dispatch(notificationSuccess('Successfully added changeover type'));
			})
			.catch((error) => {
					if (error.response.status == 500){
							dispatch(notificationError('changeover type code exists'));
					}else{
						dispatch(notificationError('something went wrong'));
					}
			});
	}
}

export const putUpdateChangeoverType = (payload) => {
	const { id } = payload;
	return function(dispatch){
			tokenPut(apiSettings.apiURLReferences + "/changeovertypes/" + id, payload).then((response) => {
					console.log('Data update: ',response);
					dispatch(getChangeoverTypes());
					dispatch(changeoverTypesUpdateSuccess({
							updated: true
					}))
					dispatch(notificationSuccess('Successfully updated changeover type'));
			})
			.catch((error) => {
				console.log(error);
				dispatch(notificationError('something went wrong'));
			});
	}
}
