import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import DateSelector from './DateSelector';
import LocationsFilter from './LocationsFilter';
import StatusFilterButton from '../../components/StatusFilterButton';

const Container = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
    marginBottom: 10,
  },
})(Box);

export default function Filters({ onLocationChange, selectedDate, onDateChange, onStatusesChange }) {
  const [filters, setFilters] = useState({
    statuses: [],
  });

  const filterStatuses = (filters)=>{
    setFilters(filters)
    onStatusesChange(filters)
  }

  return (
    <Container>
      <LocationsFilter
        onChange={(filters) => onLocationChange(filters)}
      />
      <DateSelector value={selectedDate} onChange={onDateChange} />
      <StatusFilterButton
        filters={filters}
        onFilter={(filters) => filterStatuses(filters)}
      />
    </Container>
  );
}
