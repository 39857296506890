import axios from 'axios';
import { apiSettings, getToken } from '../../../utils/api/CoreApi';

export const client = axios.create({
  baseURL: apiSettings.apiURLAnalytics
});

client.interceptors.request.use(function (config) {
  const token = getToken();
  config.headers.Authorization = `Bearer ${token}`;
  config.headers.language = localStorage.getItem('selectedLanguage');
  return config;
});

export const fetchLastestDataCronJob = async () => {
  try {

    const pathUrl = 'analytics/get-latest-data';
    const { data: response } = await client.get(pathUrl);
    return response;

  } catch (error) {
    console.error(error);
  }
}

export const fetchIndividualMechanic = async (params) => {
  const individualMechanic  = await client.get('/analytics/mechanic-ticket-details?', { params });
  return { individualMechanic }
}

export const fetchMechanicOverview = async (params) => {
  const mechanicOverview = await client.get('/analytics/mechanic-overview?', { params });
  return { mechanicOverview }
}

export const fetchLineNPT = async (params) => {
  const linenpt = await client.get('/analytics/line-npt', { params });
  return { linenpt }
}

export const fetchStatistics = async ({ filters })=>{
  const params = { filters };
  const statistics = await client.get("/analytics/statistics", { params });
  return { statistics }
}

export const fetchProblemTypes = async ({ filters, viewBy })=>{
  const params = { filters, viewBy };
  const problemTypes = await client.get("/analytics/problem-types", { params });
  return { problemTypes }
}

export const fetchTopResources = async ({ filters })=>{
  const params = { filters };
  const top = await client.get("/analytics/top", { params });
  return { top }
}

export const fetchTotalMachinesRepair = async ({ filters, viewBy, viewType })=>{
  const params = { filters, viewBy, viewType };
  const totalMachines = await client.get("/analytics/total-machines-repair", { params });
  return { totalMachines }
}

export const fetchReportedTime = async ({ filters, viewBy })=>{
  const params = { filters, viewBy };
  const reportedTime = await client.get("/analytics/reported-time-before-repair", { params });
  return { reportedTime }
}

export const fetchRepairTime = async ({ filters, viewBy })=>{
  const params = { filters, viewBy };
  const repairTime = await client.get("/analytics/repair-time", { params });
  return { repairTime }
}

export const fetchConfirmation = async ({ filters, viewBy })=>{
  const params = { filters, viewBy };
  const confirmation = await client.get("/analytics/line-leader-confirmation", { params });
  return { confirmation }
}

export const saveFilters = ({ templateName, filters }, callback)=>{
  const params = {
    templateName,
    filters
  }
  client.post("templates", params).then((response)=>{
    callback(response.data);
  })
}
