import React, { useEffect } from 'react';
import { checkIfApiLoading, checkPageActionAccess,checkPageAccess } from '../../../utils/helper';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const LightTooltip = withStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 20,
  },
}))(Tooltip);

export default function MediaCard(props) {
    //create your forceUpdate hook
    const useForceUpdate = () =>{
      const [value, setValue] = React.useState(0); // integer state
      return () => setValue(value => ++value); // update the state to force render
  }
  const forceUpdate = useForceUpdate();
  const lastElement = props.lastElement
  const cardClicked = props.cardClicked
  const hideBoxes = props.hideBoxes
  const useStyles = makeStyles({
    root: {
      position: 'relative',
      zIndex: '2',
      bottom: '58px',
      height: '50px',
      left: '0px',
      width: '55px',
    },
    rootFirefox:{
      left: '16px'
    },
    media: {
      height: 140,
    },
    div: {
      height: lastElement ? '200px' : '60px',
      width: '88px',
      background: 'rgba(0, 151, 19, 0.0)',
    },
    container1: {
      height: '60px',
      width: '42px',
      backgroundColor: cardClicked ? '#02A7F0' : '#FFFFFF',
      display: 'inline-block',
      zIndex: '0',
    },
    container2: {
      height: '60px',
      width: '3px',
      display: 'inline-block',
      background: 'rgba(0, 0, 0, 0.0)',
      zIndex: '0',
  },
  container3: {
      height: '60px',
      width: '43px',
      backgroundColor: cardClicked ? '#02A7F0' : '#FFFFFF',
      display: 'inline-block',
      zIndex: '0',
  },
  containerClicked1: {
    height: '60px',
    width: '42px',
    backgroundColor: '#02A7F0',
    display: 'inline-block',
    zIndex: '0',
  },
  containerClicked3: {
    height: '60px',
    width: '43px',
    backgroundColor: '#02A7F0',
    display: 'inline-block',
    zIndex: '0',
  },
  reported: {
      backgroundColor: '#D9001B',
      color: 'white',
      position: 'relative',
      bottom: '16px',
      right: '17px',
      width: '56px',
      textAlign: 'center',
  },
  inRepair: {
    backgroundColor: '#F59A23',
    color: 'white',
    position: 'relative',
    bottom: '16px',
    right: '17px',
    width: '56px',
    textAlign: 'center',
  },
  repaired: {
    backgroundColor: '#00DC07',
    color: 'white',
    position: 'relative',
    bottom: '16px',
    right: '17px',
    width: '56px',
    textAlign: 'center',
  },
  machineNumber: {
      overflow:'hidden',
      textOverflow: 'ellipsis',
      position: 'relative',
      bottom: '15px',
      fontSize: '0.7rem',
      right: '11px',
      width: '50px',
      textAlign: 'center',
      whiteSpace:'nowrap',
  },
  
  hours: {
      position: 'relative',
      bottom: '17px',
      right: '17px',
      width: '56px',
      textAlign: 'center',
      fontWeight: 'bold',
  },
  false: {
    position: 'relative',
    zIndex: '2',
    bottom: '37px',
    width: '32px',
    height: '10px',
    left: '0px',
    backgroundColor: '#D7D7D7',
  },
  empty: {
    position: 'relative',
    zIndex: '2',
    bottom: '37px',
    width: '32px',
    height: '10px',
    left: '0px',
    backgroundColor: '#797979',
  },
  falseEmptyFirefox:{
    left: '27px'
  },
  falseContainer1: {
    height: '40px',
    width: '41px',
    backgroundColor: '#FFFFFF',
    display: 'inline-block',
    zIndex: '0',
  },
  falseContainer2: {
    height: '40px',
    width: '3px',
    display: 'inline-block',
    background: 'rgba(0, 0, 0, 0.0)',
    zIndex: '0',
  },
  falseContainer3: {
    height: '40px',
    width: '43px',
    backgroundColor: '#FFFFFF',
    display: 'inline-block',
    zIndex: '0',
  },
  falseDiv: {
    display: hideBoxes ? 'none' : 'block',
    height: lastElement ? '1000px' : '18px',
    width: '88px',
    background: 'rgba(0, 151, 19, 0.0)',
  },
  
  //lines detail styles
  linesdiv: {
    height: '60px',
    width: '66px',
    background: 'rgba(0, 151, 19, 0.0)',
  },
  linesroot: {
    position: 'relative',
    zIndex: '2',
    top: '10px',
    width: '67px',
    height: '90px',
    left: '6px',
  },
  emptylinesroot: {
    backgroundColor: '#D7D7D7',
    position: 'relative',
    zIndex: '2',
    top: '10px',
    width: '67px',
    height: '90px',
    left: '6px',
  },
  linesreported: {
    backgroundColor: '#D9001B',
    color: 'white',
    position: 'relative',
    width: '68px',
    right: '17px',
    bottom: '16px',
    textAlign: 'center',
  },
  linesinRepair: {
  backgroundColor: '#F59A23',
  color: 'white',
  position: 'relative',
  width: '68px',
  right: '17px',
  bottom: '16px',
  textAlign: 'center',
  },
  linesRepaired: {
    backgroundColor: '#00DC07',
    color: 'white',
    fontWeight:'800',
    position: 'relative',
    width: '68px',
    right: '17px',
    bottom: '16px',
    textAlign: 'center',
    },
  falselinesTicket: {
    backgroundColor: '#D7D7D7',
    color: 'black',
    position: 'relative',
    fontWeight:'800',
    width: '68px',
    right: '17px',
    bottom: '16px',
    textAlign: 'center',
    },
  linesmachineNumber: {
    position: 'relative',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight:'800',
    fontSize: '0.9rem',
    textAlign: 'center',
    bottom: '11px',
    right: '15px',
    width: '68px',
    whiteSpace:'nowrap',
  },
  linesmachineNumber2: {
    position: 'relative',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight:'800',
    fontSize: '0.7rem',
    textAlign: 'center',
    bottom: '11px',
    right: '15px',
    width: '68px',
    whiteSpace:'nowrap',
  },
  lineshours: {
    overflow:'hidden',
    width: '67px',
    right: '16px',
    textOverflow: 'ellipsis',
    position: 'relative',
    bottom: '8px',
    height: '29px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  displayNone:{
    display:'none',
  },
  });


  const isFirefox = typeof InstallTrigger !== 'undefined';
  const localTrans = {
      EMPTY: 'EMPTY'.translate(),
  }
  const userAccess = JSON.parse(localStorage.getItem('userAccess'));
  const classes = useStyles();
  const row = props.row
  const hasOpenTicket = row.hasOpenTicket
  const station = row.station
  const machineNo = row.machineNo
  const ticketId = row.ticketId
  const machineId = row.machineId
  const ticketStatusId_desc = row.ticketStatusId_desc
  const emptyStation = row.emptyStation
  const mechanic = props.mechanic
  const macSubType_desc1 = row.macSubType_desc1
  const sortNumber = row.sortNumber
  const lineDetail = props.lineDetail
  useEffect(() => {
    const interval = setInterval(() => {
        if (moment().seconds() === 0){
          forceUpdate(); // when time goes 00 seconds, trigger update as data would change
        }
    },1000);
    return () => clearInterval(interval);
  }, []);
  const cardClick = () => {
    console.log('clicking amp')
    props.handleOpenStationDetail(ticketId, machineId)
  }

const getDateDifference = () => {
  let ticketDate = null;
  if (row.ticketStatusId_desc === 'REPORTED'){
     ticketDate = row.reportedDt
  }
  else if (row.ticketStatusId_desc === 'IN-REPAIR'){
    ticketDate = row.grabbedDt
  }
  else if (row.ticketStatusId_desc === 'REPAIRED'){
    ticketDate = row.repairedDt
  }
  else{
    ticketDate = row.reportedDt
  }
  ticketDate = moment.parseZone(ticketDate);
  const durationInMinutes = moment().diff(ticketDate, 'minutes');
  const duration = moment.duration(durationInMinutes, 'minutes');

  return moment.utc(duration.asMilliseconds()).format('hh[h]: mm[m]');
}

const daysToHours = (days) => {
  let day = 24;
  let hours = days * day
  return hours;
}

  const repairTicket = () => {
    return (
      <div className={classes.div}>
      <div className={classes.container1}></div>
      <div className={classes.container2}></div>
      <div className={classes.container3}></div>
      
    <Card className={classes.root + firefoxClasses('root')}>
    
      <CardActionArea>
        <CardContent onClick={(event) => checkPageAccess(userAccess, {pageId:8}) ? cardClick() : null}>
        
          <Typography className={ticketStatusId_desc == 'REPAIRED' ? classes.repaired : ticketStatusId_desc == 'REPORTED' ? classes.reported : classes.inRepair}>
            {station}
          </Typography>
          <Tooltip title={machineNo}>
          <Typography className={classes.machineNumber}>
            {machineNo}
          </Typography>
          </Tooltip>
          <Typography className={classes.hours}>
            {
              getDateDifference()
            }
          </Typography>
        </CardContent>
      </CardActionArea>

    </Card>

    </div>
    )
  }

  const falseTicket = () => {
    return (
      <div className={classes.falseDiv}>

        <div className={classes.falseContainer2}></div>

        <div className={emptyStation ? classes.empty + firefoxClasses('falseEmpty') : classes.false + firefoxClasses('falseEmpty')}>
      </div>
    </div>
    )
  }

  const firefoxClasses = (element) => {
      if (isFirefox){
          if (element === 'root'){
            return ' ' + classes.rootFirefox
          }
          else if (element === 'falseEmpty'){
            return ' ' + classes.falseEmptyFirefox
          }
          else{
            return ''
          }
      }
      else{
        return ''
      }
  }

  const linesrepairTicket = () => {
    return (
      <div className={classes.linesdiv}>
    <Card className={emptyStation ? classes.emptylinesroot : classes.linesroot}>
      <CardActionArea>
        <CardContent>
          <Typography className={emptyStation ? classes.falselinesTicket : hasOpenTicket == "false" ? classes.falselinesTicket : ticketStatusId_desc == 'REPAIRED' ? classes.linesRepaired : ticketStatusId_desc == 'REPORTED' ? classes.linesreported : classes.linesinRepair}>
            {emptyStation ? sortNumber < 10 ? '0' + sortNumber.toString() : sortNumber : station}
          </Typography>
          <LightTooltip title={emptyStation ? 'EMPTY'.translate() : machineNo} placement='top-end' arrow={true}>
          <Typography className={classes.linesmachineNumber}>
            {emptyStation ? localTrans.EMPTY : machineNo}
          </Typography>
          </LightTooltip>
          <LightTooltip disableHoverListener={emptyStation ? true : false} title={emptyStation ? '      ' : macSubType_desc1.translate()} placement='top-end' arrow={true}>
          <Typography className={classes.linesmachineNumber2}>
            {emptyStation ? '      ' : macSubType_desc1.translate()}
          </Typography>
          </LightTooltip>
          <LightTooltip disableHoverListener={emptyStation ? true : false} title={emptyStation ? '      ' : mechanic} placement='top-end' arrow={true}>
          <Typography className={classes.lineshours}>
          {emptyStation ? '      ' : mechanic}
          </Typography>
          </LightTooltip>
        </CardContent>
      </CardActionArea>
    </Card>
    </div>
    )
  }

  const linesfalseTicket = () => {
    return (
      <div className={classes.falseDiv}>
      <div className={classes.falseContainer1}></div>
      <div className={classes.falseContainer2}></div>
      <div className={classes.falseContainer3}></div>
    <div className={emptyStation ? classes.empty : classes.false}>
    </div>
    </div>
    )
  }

  const lineDetailTicket = () => {
    return (
      <div>
        {hasOpenTicket == "true" ? linesrepairTicket() : linesrepairTicket()}
      </div>
    )
  }
  
  return (
    <div>
            {lineDetail == true ? lineDetailTicket() : hasOpenTicket == "true" ? repairTicket() : falseTicket()}
    </div>

  );
}
