import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import CardPaper from './components/CardPaper'
import { sortRows } from './helpers';
import MuiTable from './components/Table/MuiTable';
import TableHeaderCell from './components/Table/TableHeaderCell';
import MuiTableSortLabel from './components/Table/MuiTableSortLabel';

export default function TopMechanics(props){
  const [orderBy, setOrderBy] = useState("totalTime");
  const [order, setOrder] = useState("desc");

  const { rows } = props;

  const handleRequestSort = (property) => {
    const isAsc = orderBy == property && order == 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedRows = sortRows(rows, orderBy, order);

  return <CardPaper>
    <h3>{'Top 5 Mechanics'.translate()}</h3>
    <TableContainer>
      <MuiTable>
        <TableHead>
          <TableRow>
            <TableHeaderCell active={orderBy == "machineName"}>
              <MuiTableSortLabel
                active={orderBy == "machineName"}
                direction={orderBy == "machineName" ? order : 'asc'}
                onClick={()=> handleRequestSort("machineName")}
              >
                {'Mechanic'.translate()}
              </MuiTableSortLabel>
            </TableHeaderCell>
            <TableHeaderCell active={orderBy == "avgTime"}>
              <MuiTableSortLabel
                active={orderBy == "avgTime"}
                direction={orderBy == "avgTime" ? order : 'asc'}
                onClick={()=> handleRequestSort("avgTime")}
              >
                {'Avg. Time'.translate()}
              </MuiTableSortLabel>
            </TableHeaderCell>
            <TableHeaderCell active={orderBy == "totalTickets"}>
              <MuiTableSortLabel
                active={orderBy == "totalTickets"}
                direction={orderBy == "totalTickets" ? order : 'asc'}
                onClick={()=> handleRequestSort("totalTickets")}
              >
                {'TTL TKTS'.translate()}
              </MuiTableSortLabel>
            </TableHeaderCell>
            <TableHeaderCell active={orderBy == "totalTime"}>
              <MuiTableSortLabel
                active={orderBy == "totalTime"}
                direction={orderBy == "totalTime" ? order : 'asc'}
                onClick={()=> handleRequestSort("totalTime")}
              >
                {'TTL Time'.translate()}
              </MuiTableSortLabel>
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.map((row, index) => (
            <TableRow key={index}>
              <TableCell size="small">
                {row.repairedBy || "N/A"}
              </TableCell>
              <TableCell size="small">{row.avgTime}</TableCell>
              <TableCell size="small">{row.totalTickets}</TableCell>
              <TableCell size="small">{row.totalTime 
                ? row.totalTime.toLocaleString() 
                : 0
              }</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  </CardPaper>
}
