import React from 'react';
import './Dashboard.scss';
import { checkPageAccess, checkPageActionAccess, checkRoleAccess, checkPageAccessField, getRole,checkIfSuperAdminFactoryExist } from '../../../utils/helper';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom';
import { Fade, Grow } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import machineIcon from '../../../assets/img/icon-machine.png'
import Carousel, { Dots } from '@brainhubeu/react-carousel'
import { connect } from 'react-redux'
import setters from '../../../redux/actions/Dashboard/dashboardOverallActions'
import UserBlueHeader from '../../../components/UserBlueHeader/UserBlueHeader'
import '@brainhubeu/react-carousel/lib/style.css';
import translations from '../../../redux/actions/Translation/translationActions'
import moment from 'moment'


class DashboardOverall extends React.Component {
    month = moment().format('MM')
    day = moment().format('DD')
    paginateSize = 10
    widescreen
    language
    timer
    /**
     * fieldname for array callbacks
     */
    fieldNameConfig = {
        top5ticket: {
            line: 'mfgLine',
            station: 'mfgLineId',
            time: 'totalNpt',
        },
        ticketList: {
            line: 'mfgLine',
            area: 'area',
            station: 'station',
            time: 'duration',
            start: 'reportedDt',
            end: 'grabbedDt'
        }
    }
    constructor(props) {
        super(props)
        this.widescreen = window && new URL(window && window.location.href).searchParams.get('widescreen') != null ? true : false
        props.match.params && props.match.params.token && localStorage.setItem('paramUserToken',JSON.stringify(props.match.params.token))
        props.match.params && props.match.params.action && (this.widescreen = true)

        this.state = {
            // boardTitle: this.widescreen ? 'MECHANIC DASHBOARD' : 'MECHANIC',
            boardTvTitle: this.convertLang().BOARD_TV_TITLE,
            tvMode: this.widescreen,
            tvModeTransition: this.widescreen,
            transition: true,
            timeout: 500,
            isEmptyList: false,
            slideSpeed: 15000,
            slideValue: 0,
            ticketsElement: [],
            tickets: { ...this.props.tickets },
            languageProps: {
                ...this.convertLang()
            },
            translationTimestamp: undefined,
            avgTimeStamp: undefined
        }
    }
    convertLang = () => {
        return  {
            MIN:'min'.translate(),
            // ROLE: this.renderRoleLabel().translate(),
            BOARD_TV_TITLE: 'TV Mode'.translate(),
            BOARD_TV_TITLE_ON: 'Exit TV Mode'.translate(),
            REPAIR_TICKETS: 'REPAIR TICKETS'.translate(),
            REPAIR_REPORTED: 'reported'.translate(),
            REPAIR_INREPAIR: 'in repair'.translate(),
            REPAIR_CLOSED: 'closed'.translate(),
            TOPLINESNPT: 'TOP 5 LINES NPT'.translate(),
            MAINTENANCE_TICKETS: 'MAINTENANCE TICKETS'.translate(),
            MAINTENANCE_TICKETS_SCHED: 'scheduled'.translate(),
            MAINTENANCE_TICKETS_IN_PROGRESS: 'in progress'.translate(),
            MAINTENANCE_TICKETS_COMPLETE: 'complete'.translate(),
            CHANGE_OVER: 'CHANGE OVER TICKETS'.translate(),
            CHANGE_OVER_OPEN: 'open'.translate(),
            CHANGE_OVER_IN_PROCESS: 'in process'.translate(),
            CHANGE_OVER_COMPLETE: 'complete'.translate(),
            REPORTED_TICKETS: 'REPORTED TICKETS'.translate(),
            AVG_RESPONSE: 'average response time today'.translate(),
            IN_REPAIR_TICKETS: 'IN REPAIR TICKETS'.translate(),
            AVG_REPAIR: 'average repair time today'.translate(),
            LINE_OVERVIEW: 'LINE OVERVIEW'.translate(),
            MACHINES: 'MACHINES'.translate(),
            TICKET_MANAGEMENT: 'TICKET MANAGEMENT'.translate(),
            MAINTENANCE: 'MAINTENANCE'.translate()

        }
    }
    componentWillReceiveProps() {
        this.setState({
            tickets: this.props.tickets
        })
    }
    componentWillUnmount() {
        console.log('Dashboard UnMounted')
        clearInterval(this.timer)
        // this.props.socketOff()
    }
    componentDidUpdate() {
        if (this.props.tickets.timeStamp != this.state.tickets.timeStamp) {
            this.setState({
               tickets: this.props.tickets
            })
        }
        if (this.props.avgTime?.avgTimeStamp != this.state.avgTimeStamp) {
            this.setState({
                avgTimeStamp: this.props.avgTime.avgTimeStamp,
                tickets: {
                    ...this.props.tickets,
                    reportedList: {
                        ...this.state.tickets.reportedList,
                        avgTime: this.props.avgTime.responseAvg,
                    },
                    repairList: {
                        ...this.state.tickets.repairList,
                        avgTime: this.props.avgTime.repairAvg
                    }
                }
            })
        }
        if(this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp){
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                languageProps: {
                    ...this.convertLang()
                },
                boardTvTitle: this.convertLang().BOARD_TV_TITLE,
            })
        }
    }
    renderRoleLabel(){
        try{
            let role = getRole(this.state.access);
            if (role){
                if (this.state.tvMode){
                    return role.toUpperCase()+ ' DASHBOARD';
                }
                else{
                    return role.toUpperCase();
                }
            }
            else{
                if (this.state.tvMode){
                    return 'DASHBOARD';
                }
            }
        }
        catch(err){
            if (this.state.tvMode){
                return 'DASHBOARD';
            }
        }
    }
    setAccess() {
        let access = JSON.parse(localStorage.getItem('userAccess'));
        this.setState({ access: access });
    }
    preventLinkRedirect = (e) => {
        e.preventDefault();
    }
    async componentDidMount() {
        var urlParams = new URLSearchParams(window.location.search);
        let factory   = urlParams.get('factory');
        let accessToken = urlParams.get('accessToken');

        delete localStorage['fromCalendar'] 
        this.setAccess();
        this.addTimeToList()
        this.setState({
            ticketsElement: this.paginateArray(this.state.tickets.repairList.list)
        })
        // this.props.socketOn()
        await this.props.getDashboardList(factory, accessToken);
        this.setState({
            tickets: this.props.tickets
        });
        console.log('Dashboard Mounted', this.state)
    }
    paginateArray = (arg) => {
        let returnRows = (index, size) => {
            let rowSize = this.state.tickets.repairList.list.length
            let rowEnd = ((index + 1) * this.paginateSize)
            let row = this.state.tickets.repairList.list

            let rowArray = []

            for (let x = (rowEnd - this.paginateSize); x < rowEnd; x++) {
                if (row[x] == undefined) break
                rowArray.push(
                    <TableRow key={x}>
                        <TableCell className="machine-no">{x + 1}</TableCell>
                        <TableCell className="machine-name">{row['']}</TableCell>
                        <TableCell className="reported-time">
                            {row[x].time}
                        </TableCell>
                    </TableRow>
                )

            }
            return rowArray

        }
        let page = new Array(Number.parseInt(arg.length / this.paginateSize) + 1).fill()
        return page.map((pages, index) => {
            return (
                <TableContainer className={this.isTvMode("dashboard-list big-list tvMode", "dashboard-list big-list")}>
                    <Table className={this.isTvMode("big-list tvMode", "big-list")}>
                        <TableBody>
                            {
                                returnRows(index, page.length)
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        })

    }
    twoDigitText = (number) => {
        return number.toString().replace(/(^\d{1})$/gi, '0$1')
    }
    renderLoader = (size, animation, height, uneven, rowHeight) => {
        let style = {
            background: 'gray',
            height: rowHeight + 'vh'
        }
        let skeletonStyle = {
            variant: 'text',
            animation: animation
        }
        let rootStyle = {
            minHeight: height + 'vh'
        }
        this.state.tvModeTransition == true && (rootStyle['marginTop'] = '3vh')
        rootStyle.height == null && (delete rootStyle['height'])
        skeletonStyle.animation == null && (delete skeletonStyle['animation'])
        let toReturn = []
        for (let x = 0; x < size; x++) {
            toReturn.push(
                uneven ? (x / 2) % 1 == 0 ? (
                    <>
                        <Grid item xs={3}>
                            <Skeleton {...skeletonStyle} style={{ ...style }} />
                        </Grid>
                        <Grid item xs={9}>
                            <Skeleton {...skeletonStyle} style={{ ...style }} />
                        </Grid>
                    </>
                ) : (
                        <>
                            <Grid item xs={5}>
                                <Skeleton {...skeletonStyle} style={{ ...style }} />
                            </Grid>
                            <Grid item xs={7}>
                                <Skeleton {...skeletonStyle} style={{ ...style }} />
                            </Grid>
                        </>
                    )
                    :
                    <Grid item xs={12}>
                        <Skeleton {...skeletonStyle} style={{ ...style }} />
                    </Grid>
            )
        }
        return (
            <Grid style={{ ...rootStyle }} container spacing={1} >
                {toReturn}
            </Grid>
        )
    }
    convertHourMin = (stringTime) => {
        return `${stringTime.replace(/\:\d{2}\:\d{2}$/gi, '')}:${stringTime.replace(/^\d+\:/gi, '').replace(/\:\d{2}$/gi, '')}`
    }
    extractTime = (stringTime) => {
        return {
            hour: Number.parseInt(stringTime.toString().replace(/\:\d{2}\:\d{2}$/gi, '')),
            min: Number.parseInt(stringTime.toString().replace(/^\d+\:/gi, '').replace(/\:\d{2}$/gi, '')),
            sec: Number.parseInt(stringTime.toString().replace(/^\d+\:\d{2}\:/gi, '')),
        }
    }
    addTimeToList = (minutes = 1) => {
        let seconds = minutes * 60 * 1000
        seconds = 1000 // a test 
        this.timer = setInterval(() => {
            let { reportedList, repairList } = this.state.tickets
            let top5 = this.state.tickets['5npt']
            let computeNewTime = ({ hour, min, sec }, seconds) => {
                sec += seconds
                let isMinUpdated = false
                if (sec >= 60) {
                    isMinUpdated = true
                    min += 1
                    sec = 0
                    if (min >= 60) {
                        hour += 1
                        min = 0

                    }
                }
                return {
                    time: `${this.twoDigitText(hour)}:${this.twoDigitText(min)}:${this.twoDigitText(sec)}`,
                    isMinUpdated
                }
            }
            let populateAddedTime = ({ list, avgTime }, args = 'ticketList') => {
                list.filter((x, i) => {
                    let { time, isMinUpdated } = computeNewTime(this.extractTime(x[this.fieldNameConfig[args].time]), 1)
                    x[this.fieldNameConfig[args].time] = time
                    
                    var urlParams = new URLSearchParams(window.location.search);
                    let factory   = urlParams.get('factory');
                    if (isMinUpdated == true && args == 'ticketList') {
                        // console.log('new updates', avgTime)
                        // let {time} = computeNewTime(this.extractTime(avgTime),60)
                        // avgTime = time
                        this.props.getAvgTime(factory)
                    }
                    return time
                })

                let callback = {
                    avgTime,
                    list
                }

                if (args != 'ticketList') delete callback['avgTimeStamp']
                return callback
            }
            reportedList = populateAddedTime(reportedList)
            repairList = populateAddedTime(repairList)
            top5 = populateAddedTime(top5, 'top5ticket')
            this.setState({
                tickets: {
                    ...this.state.tickets,
                    reportedList: { ...this.state.tickets.reportedList, ...reportedList },
                    repairList: { ...this.state.tickets.repairList, ...repairList },
                    '5npt': { ...this.state.tickets['5npt'], ...top5 },
                }
            })
        }, seconds)
    }
    renderTicketsList = (args, size = 10, listType = 'big-list', ticketListType = 'ticketList', animation = null, height = 56.8, uneven = false, rowHeight = 3, test = false) => {
        return this.state.tickets[args].list.length > 0 && test == false ? (
            <TableContainer className={listType == 'big-list' ? this.isTvMode("dashboard-list big-list tvMode", "dashboard-list big-list") : this.isTvMode("dashboard-list small-list tvMode-small", "dashboard-list small-list")}>
                <Table className={listType == 'big-list' ? this.isTvMode("big-list tvMode", "big-list") : ''}>
                    <TableBody>
                        {
                            this.state.tickets[args].list.map((row, i) => {
                                let time = this.convertHourMin(row[this.fieldNameConfig[ticketListType].time])
                                return (
                                    <TableRow className={(row[this.fieldNameConfig.ticketList.time] && Number.parseInt(row[this.fieldNameConfig.ticketList.time].toString().replace(/^\d+\:/gi, '').replace(/\:\d{2}$/gi, '')) >= this.state.tickets[args].limit) || (row[this.fieldNameConfig.ticketList.time] && Number.parseInt(row[this.fieldNameConfig.ticketList.time].toString().replace(/\:\d{2}\:\d{2}$/gi, '')) > 0) ? "warning" : ""}>
                                        {listType == 'big-list' && <TableCell className="machine-no">{this.twoDigitText((i + 1))}</TableCell>}
                                        <TableCell className="machine-name">{`${row[this.fieldNameConfig[ticketListType].line] == null ? row[this.fieldNameConfig[ticketListType].area] : row[this.fieldNameConfig[ticketListType].line]} ${listType == 'big-list' ? (row[this.fieldNameConfig[ticketListType].line] == null ? '' : '-') : ''} ${listType == 'big-list' && row[this.fieldNameConfig[ticketListType].station] != 0 ? row[this.fieldNameConfig[ticketListType].station] : ''}`}</TableCell>
                                        {
                                            <TableCell className={listType == 'big-list' ? "reported-time" : "npt"}>
                                                {
                                                    listType == 'big-list' ? time :
                                                        <div>
                                                            <span className="time">
                                                                {
                                                                    time
                                                                }
                                                            </span> <span className="unit">{this.state.languageProps.MIN}</span>
                                                        </div>
                                                }
                                            </TableCell>
                                        }
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        ) : (
                setTimeout(() => {
                    // 0 == 0 && this.state.isEmptyList == false && (
                    this.state.tickets[args].list.length == 0 && this.state.isEmptyList == false && (
                        this.setState({
                            isEmptyList: true
                        })
                    )
                }, 10000),
                // this.renderLoader(size, false, height, uneven, rowHeight)
                this.state.isEmptyList ?
                    <TableContainer className={listType == 'big-list' ? this.isTvMode("dashboard-list big-list tvMode", "dashboard-list big-list") : this.isTvMode("dashboard-list small-list tvMode-small", "dashboard-list small-list")}>
                        <Table className={listType == 'big-list' ? this.isTvMode("big-list tvMode", "big-list") : ''}>
                            <TableBody>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : this.renderLoader(size, animation, height, uneven, rowHeight)

            )

    }
    checkURL(){
        // let url = window.location.href;
        var urlParams = new URLSearchParams(window.location.search);
        let factory   = urlParams.get('factory');
        let accessToken = urlParams.get('accessToken'); 
        return accessToken;
    }
    toggleFullScreen = () => {
        let after = 600
        if (document.fullscreenElement) {
            this.setState({
                transition: !this.state.transition
            })
            setTimeout(() => {
                this.setState({
                    // boardTitle: 'MECHANIC',
                    boardTvTitle: this.state.languageProps.BOARD_TV_TITLE,
                    tvMode: !this.state.tvMode,
                    transition: !this.state.transition,
                    tvModeTransition: !this.state.tvModeTransition
                })
                document.exitFullscreen()
            }, this.state.timeout + after)
        }
        else {
            this.setState({
                transition: !this.state.transition,
                tvMode: !this.state.tvMode,
            })
            setTimeout(() => {
                this.setState({
                    // boardTitle: 'MECHANIC DASHBOARD',
                    boardTvTitle: this.state.languageProps.BOARD_TV_TITLE_ON,
                    transition: !this.state.transition,
                    tvModeTransition: !this.state.tvModeTransition
                })
                document.documentElement.requestFullscreen()
            }, this.state.timeout + after)
        }
    }
    isTvMode = (isTrue, isFalse) => {
        return this.state.tvModeTransition ? isTrue : isFalse
    }
    handleSlideChange = value => {
        this.setState({ slideValue: value })
    }

    render() {
        return (<>
            { this.checkURL() ?
                <div className="dashboard">
                    <div className={this.isTvMode("dashboard-header header-tv", "dashboard-header")}>
                        <Fade in={this.state.transition} timeout={this.state.timeout}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <div className="dashboard-date text-align-left">
                                        {`${this.month}.${this.day}`}

                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="dashboard-title text-align-center">
                                        {this.renderRoleLabel()?.toString()?.translate() ? this.renderRoleLabel() : 'ADMIN'}
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div className={this.widescreen ? "dashboard-tv-mode hidden" : "dashboard-tv-mode text-align-right"}>
                                        <span id="fullscreen-toggle" onClick={this.toggleFullScreen}>{this.state.boardTvTitle}</span>
                                    </div>
                                </Grid>
                            </Grid>
                        </Fade>
                    </div>
                    <div className={this.isTvMode("dashboard-body body-tvMode", "dashboard-body")}>
                        <Grow in={this.state.transition} timeout={this.state.timeout + 100} className="body-container">
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Grid container spacing={2} className="column">
                                        <Grid item xs={6}>
                                            <Paper className={this.isTvMode("card tvMode-height", "card")}>
                                                <div className='title'>
                                                    {this.state.languageProps.REPAIR_TICKETS}
                                                </div>
                                                <Grid container spacing={2} className={this.isTvMode("", "inner-card")}>
                                                    <Grid item xs={4} className={this.isTvMode("ticket-count-tvMode1", "ticket-count-container")}>
                                                        <div className="ticket-count">
                                                            {this.state.tickets.repair.reported}
                                                        </div>
                                                        <div className={this.isTvMode("ticket-status tvMode", "ticket-status")}>
                                                            {this.state.languageProps.CHANGE_OVER_OPEN}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={4} className={this.isTvMode("ticket-count-tvMode2", "ticket-count-container")}>
                                                        <div className="ticket-count">
                                                            {this.state.tickets.repair.inRepair}
                                                        </div>
                                                        <div className={this.isTvMode("ticket-status tvMode", "ticket-status")}>
                                                            {this.state.languageProps.CHANGE_OVER_IN_PROCESS}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={4} className={this.isTvMode("ticket-count-tvMode3", "ticket-count-container")}>
                                                        <div className="ticket-count">
                                                            {this.state.tickets.repair.closed}
                                                        </div>
                                                        <div className={this.isTvMode("ticket-status tvMode", "ticket-status")}>
                                                            {this.state.languageProps.CHANGE_OVER_COMPLETE}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Paper className={this.isTvMode("card tvMode-height", "card")}>
                                                <div className='title'>
                                                    {this.state.languageProps.TOPLINESNPT}
                                                </div>
                                                {this.renderTicketsList('5npt', 5, 'small', 'top5ticket', null, null, true)}
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Paper className={this.isTvMode("card tvMode-height", "card")}>
                                                <div className='title'>
                                                    {this.state.languageProps.MAINTENANCE_TICKETS}
                                                </div>
                                                <Grid container spacing={2} className={this.isTvMode("", "inner-card")}>
                                                    <Grid item xs={4} className={this.isTvMode("ticket-count-tvMode1", "ticket-count-container")}>
                                                        <div className="ticket-count">{this.state.tickets.maintenance.scheduled}</div>
                                                        <div className={this.isTvMode("ticket-status tvMode", "ticket-status")}>
                                                            {this.state.languageProps.CHANGE_OVER_OPEN}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={4} className={this.isTvMode("ticket-count-tvMode2", "ticket-count-container")}>
                                                        <div className="ticket-count">{this.state.tickets.maintenance.inProgress}</div>
                                                        <div className={this.isTvMode("ticket-status tvMode", "ticket-status")}>{this.state.languageProps.CHANGE_OVER_IN_PROCESS}</div>
                                                    </Grid>
                                                    <Grid item xs={4} className={this.isTvMode("ticket-count-tvMode3", "ticket-count-container")}>
                                                        <div className="ticket-count">{this.state.tickets.maintenance.completed}</div>
                                                        <div className={this.isTvMode("ticket-status tvMode", "ticket-status")}>{this.state.languageProps.CHANGE_OVER_COMPLETE}</div>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Paper className={this.isTvMode("card tvMode-height", "card")}>
                                                <div className='title'>
                                                    {this.state.languageProps.CHANGE_OVER}
                                                </div>
                                                <Grid container spacing={2} className={this.isTvMode("", "inner-card")}>
                                                    <Grid item xs={4} className={this.isTvMode("ticket-count-tvMode1", "ticket-count-container")}>
                                                        <div className="ticket-count">{this.state.tickets.changeOver.open}</div>
                                                        <div className={this.isTvMode("ticket-status tvMode", "ticket-status")}>{this.state.languageProps.CHANGE_OVER_OPEN}</div>
                                                    </Grid>
                                                    <Grid item xs={4} className={this.isTvMode("ticket-count-tvMode2", "ticket-count-container")}>
                                                        <div className="ticket-count">{this.state.tickets.changeOver.inProcess}</div>
                                                        <div className={this.isTvMode("ticket-status tvMode", "ticket-status")}>{this.state.languageProps.CHANGE_OVER_IN_PROCESS}</div>
                                                    </Grid>
                                                    <Grid item xs={4} className={this.isTvMode("ticket-count-tvMode3", "ticket-count-container")}>
                                                        <div className="ticket-count">{this.state.tickets.changeOver.complete}</div>
                                                        <div className={this.isTvMode("ticket-status tvMode", "ticket-status")}>
                                                            {this.state.languageProps.CHANGE_OVER_COMPLETE}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={2} justify="flex-start">
                                        <Grid item xs={6}>
                                            <Paper className={this.isTvMode("card two-rows tvMode", "card two-rows")}>
                                                <div className='title'>
                                                    {this.state.languageProps.REPORTED_TICKETS} 
                                                    <span>({this.state.tickets.reportedList.list.length})</span>
                                                </div>
                                                {this.renderTicketsList('reportedList')}
                                                <Paper className={this.state.tvModeTransition ? "footer footer-tvmode" : "footer"}>
                                                    <Grid container spacing={2} className="footer-text">
                                                        <Grid item xs={8} className={this.isTvMode("footer-average", "")}>
                                                            {this.state.languageProps.AVG_RESPONSE}
                                                        </Grid>
                                                        <Grid item xs={4} className={this.isTvMode("footer-time", "")}>
                                                            <span className="time">{this.convertHourMin(this.state.tickets.reportedList.avgTime)}</span>
                                                            <span className="unit">{this.state.languageProps.MIN}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Paper className={this.isTvMode("card two-rows tvMode", "card two-rows")}>
                                                <div className='title'>
                                                    {this.state.languageProps.IN_REPAIR_TICKETS} 
                                                    <span>({this.state.tickets.repairList.list.length})</span>
                                                </div>
                                                {this.renderTicketsList('repairList')}
                                                <Paper className={this.state.tvModeTransition ? "footer footer-tvmode" : "footer"}>
                                                    <Grid container spacing={2} className="footer-text">
                                                        <Grid item xs={8} className={this.isTvMode("footer-average", "")}>
                                                            {this.state.languageProps.AVG_REPAIR}
                                                        </Grid>
                                                        <Grid item xs={4} className={this.isTvMode("footer-time", "")}>
                                                            <span className="time">
                                                                {this.convertHourMin(this.state.tickets.repairList.avgTime)}
                                                            </span>
                                                            <span className="unit">
                                                                {this.state.languageProps.MIN}
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grow>
                    </div>
                </div>
            :
                <div className="notfound"> <h3>Not Found</h3> </div>
            }
            </>
        );
    }
}

const stateToProps = state => {
    let { dashboardListReducer , translationReducer, authReducer, factoryListReducer} = state
    return {
        ...authReducer,
        ...dashboardListReducer,
        ...translationReducer,
        ...factoryListReducer,
    }
}

DashboardOverall = connect(stateToProps, setters)(DashboardOverall);
export default DashboardOverall;
