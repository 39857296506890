import React from 'react';
import { 
    Box, 
    Button, 
    makeStyles, 
    Typography, 
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/styles';
import MachineIcon from '../../../../assets/icons/MachineIcon';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

export const IndicatorContainer = withStyles({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: 10,
        marginRight: 10,
        alignItems: 'center',
        alignContent: 'center',
        gap: 5,
    },
})(Box);

export const LegendIndicator = withStyles({
    root: (props) => ({
        backgroundColor: props.color,
        width: 16,
        height: 16,
        borderRadius: 2,
        marginLeft: 4,
        marginRight: 4,
    }),
})(Box);

export const EmptyRecord = withStyles({
    root: {
        textAlign: "center",
        fontWeight: "bold" ,
        padding: "30px",
        background: "#eee",
        marginTop: 20,
        marginBottom: 20,
    },
})((props) => (
    <Typography {...props}>{props.text}</Typography>
));

export const ButtonSummary = withStyles({
    root: (props) => ({
        color: '#555',
        height: '40px',
        border: '1px solid #ddd',
        borderRadius: '4px !important',
        fontSize: 14,
        ...props,
    }),
})((props) => 
    <Button {...props}  variant='outlined' startIcon={<MachineIcon />} >
        {'SUMMARY'.translate()}
    </Button>
); 

export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export const ExportIcon= withStyles({
    root:(props) => ({
        fontSize: 32, 
        color: '#1585F4',
        ...props,
    }),
})(CloudDownloadIcon);
  
export const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: '#fff',
        boxShadow: 'none',
        paddingRight: '0 !important',
    },
    title: {
        color: '#515151',
        flex: 1,
        fontSize: 20,
    },
    dFlex: { 
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '8px',
    },
    justifyCenter: { justifyContent: 'center' },
    justifyBetween: { justifyContent: 'space-between' },
    alignContentCenter: { alignContent: 'center'},
    mbottom10: { marginBottom: 8, },
    content: { backgroundColor: '#F7F7FB',},
    grid: { display: 'grid',gap: '8px', marginTop: 8, marginBottom: 8, },
    grid2: { gridTemplateColumns: '1fr 1fr'},
    grid3: { gridTemplateColumns: '1fr 1fr 1fr'},
    grid4: { gridTemplateColumns: '1fr 1fr 1fr 1fr'},
    areaList: { 
        height: 160,
        overflowY: 'scroll', 
        '&::-webkit-scrollbar': {
            width: '0.4em',
            borderRadius: 10
        },
        '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#707070',
            borderRadius: 10
        }
    },
    card260: { 
        height: '300px !important', 
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: '0.4em',
            borderRadius: 10
        },
        '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#707070',
            borderRadius: 10
        },
    },
    card: {
        position: 'relative',
        padding: '5px 20px',
        boxShadow: '0px 3px 6px #35405229',
        background: '#ffffff 0% 0% no-repeat padding-box',
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '8px',
            height: '100%',
        },
    },
    cardTitle: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    cardTotal: {
        fontSize: 32,
        paddingTop: 5,
        paddingBottom: 5,
        fontWeight: 'bold',
    },
    cardSubtitle: {
        fontSize: 12,
    },
    cardSuccess: {
        background: '#e4f9ec',
        '&:before':{
            background: '#00c853'
        }
    },
    cardDanger: {
        background: '#fae9e9',
        '&:before':{
            background: '#d32f2f'
        }
    },
    line: {
        '& .card::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '8px',
            height: '100%',
        },
        '& .success.card': { background: '#e4f9ec'},
        '& .danger.card': { background: '#fae9e9'},
        '& .success.card::before ': { background: '#00c853'},
        '& .success.card::before ': { background: '#d32f2f'},
    },
    success: {
        '& .card': { background: '#e4f9ec'},
        '& .card::before ': { background: '#00c853'},
    }
}));