import {
    PREFERENCE_DETAIL_REQUEST,
    PREFERENCE_DETAIL_SUCCESS,
    PREFERENCE_UPDATE_REQUEST,
    PREFERENCE_UPDATE_SUCCESS,
    SET_TRANSLATION
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    systemPreference:[],
    reference:[],
    translations: {},
    error: ''
}

const preferenceReducer = (state = initialState, action) => {
    switch (action.type) {
        case PREFERENCE_DETAIL_REQUEST:
            return {
                ...state,
                loading: true
            }
        case PREFERENCE_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                systemPreference: action.payload.systemPreference,
                reference: action.payload.reference,
                translations: action.payload.translations,
                error: ''
            }
        default: 
            return state;
    }
}

export default preferenceReducer;