export const stationRules = {
  machineTypeId: {
    presence: true
  },
  machineSubTypeId: {
    presence: true
  },
  needleType: {
    length: {
      maximum: 35
    }
  },
  needleType: {
    length: {
      maximum: 35
    }
  },
  operation: {
    length: {
      maximum: 250
    }
  },
  remarks: {
    length: {
      maximum: 250
    }
  },
}


export const changeoverRules = {
  changeoverDate: {
    presence: true
  },
  style: {
    presence: true,
    length: {
      maximum: 250
    }
  },
  areaId: {
    presence: true
  },
  lineId: {
    presence: true
  },
  productType: {
    length: {
      maximum: 250
    }
  },
  machines: {
    length: {
      minimum: 1
    }
  }
}