import React from 'react';
import { TableHead as Head, TableRow } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { useState } from 'react';
import { Col, ColFilter } from '../styles';

export default function TableHeader(props) {
    const [order, setOrder] = useState(false);
    const onOrder = () => {
        props.handleOrder();
        setOrder(!order);
    }
    return (
        <Head>
            <TableRow>
                <Col width="20%">{'MECHANIC'.translate()}</Col>
                <Col align="center" width="10%">{'CO. TKTS'.translate()}</Col>
                <Col align="center" width="10%">{'MAINT. TKTS.'.translate()}</Col>
                <Col align="center" width="10%">{'REPAIR TKTS.'.translate()}</Col>
                <Col align="center" width="8%">{'RE-OPEN%.'.translate()}</Col>
                <Col>{'TOTAL HANDLING TIME.'.translate()}</Col>
                <ColFilter onClick={onOrder}>
                    {'AVERAGE HANDLING TIME'.translate()}
                    {order ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                </ColFilter>
            </TableRow>
        </Head>
    )
}