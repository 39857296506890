import React from 'react';
import { Typography } from '@material-ui/core';
import { 
    IndicatorContainer, 
    LegendIndicator, 
    useStyles,
} from '../../../../styles';
import { stringToColour } from './helper';

function AreaListing({ data = [] }) {
    const classes = useStyles();
    return ( 
        <div className={[classes.grid, classes.grid2, classes.areaList].join(' ')}>
            {data.map(({ id, name }) => (
                <IndicatorContainer>
                    <LegendIndicator color={stringToColour(`${name}-${id}`)} />
                    <Typography>{name}</Typography>
                </IndicatorContainer>
            ))}
        </div>
    );
}

export default AreaListing;