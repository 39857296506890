import {
    AUTH_LOGIN_REQUEST,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGIN_ERROR,
    AUTH_LOGOUT_REQUEST,
    AUTH_LOGOUT_SUCCESS,
    AUTH_UPDATE_ACCESS,
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    token: null,
    access: [],
    factoryId: null,
    username: null,
    userId:null,
    error: ''
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_LOGIN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case AUTH_LOGIN_SUCCESS:
            return {
                ...state,
                token: action.payload.token,
                access: action.payload.access,
                factoryId: action.payload.factoryId,
                username: action.payload.username,
                userId: action.payload.userId,
                loading: false
            }
        case AUTH_LOGIN_ERROR:
            return {
                ...state,
                loading: false
            }
        case AUTH_LOGOUT_SUCCESS:
            return {
                ...state,
                token: null,
                access: [],
                factoryId: null,
                username: null,
                userId: null,
            }
        case AUTH_UPDATE_ACCESS:
            return {
                ...state,
                token: action.payload.token,
                access: action.payload.access,
                factoryId: action.payload.factoryId,
                username: action.payload.username,
                userId: action.payload.userId,
            }
        default: 
            return state;
    }
}

export default authReducer;