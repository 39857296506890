import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { PAGE_ENUMS } from '@src/utils/constants';
import { checkPageAccess, checkPageAccessField } from "@src/utils/helper";
import history from "@src/history";

function MachineConfigHub(props){
  const { userAccess } = props;

  const getPath = ()=>{
    if (
      checkPageAccessField(userAccess, {
        page: PAGE_ENUMS.brands,
        pageAccess: "VIEW",
      }) ||
      checkPageAccess(userAccess, { page: PAGE_ENUMS.brands })
    ) {
      return "/configuration/machine-config/brands";
    } else if (
      checkPageAccessField(userAccess, {
        page: PAGE_ENUMS.machineTypes,
        pageAccess: "VIEW",
      }) ||
      checkPageAccess(userAccess, { page: PAGE_ENUMS.machineTypes })
    ) {
      return "/configuration/machine-config/machine-type";
    } else if (
      checkPageAccessField(userAccess, {
        page: PAGE_ENUMS.supplier,
        pageAccess: "VIEW",
      }) ||
      checkPageAccess(userAccess, { page: PAGE_ENUMS.supplier })
    ) {
      return "/configuration/machine-config/supplier";
    } else if (
      checkPageAccessField(userAccess, {
        page: PAGE_ENUMS.machineSubTypes,
        pageAccess: "VIEW",
      }) ||
      checkPageAccess(userAccess, { page: PAGE_ENUMS.machineSubTypes })
    ) {
      return "/configuration/machine-config/sub-types";
    } 
    else if (
      checkPageAccessField(userAccess, {
        page: PAGE_ENUMS.motorTypes,
        pageAccess: "VIEW",
      }) ||
      checkPageAccess(userAccess, { page: PAGE_ENUMS.motorTypes })
    ) {
      return "/configuration/machine-config/motor-type";
    } else if (
      checkPageAccessField(userAccess, {
        page: PAGE_ENUMS.attachments,
        pageAccess: "VIEW",
      }) ||
      checkPageAccess(userAccess, { page: PAGE_ENUMS.attachments })
    ) {
      return "/configuration/machine-config/attachment";
    }
  }

  useEffect(()=>{
    history.replace(getPath());
  }, []);

  return <div></div>
}



const mapStateToProps = (state) => {
  return {
    userAccess: state.authReducer.access
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

MachineConfigHub = connect(mapStateToProps, mapDispatchToProps)(MachineConfigHub);

export default MachineConfigHub;
