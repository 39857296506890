import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CardPaper from './CardPaper';

const useStyles = makeStyles({
  paper: {
    minHeight: 75,
    padding: '10px 10px 5px',
    marginBottom: 15,
    boxShadow: "0px 3px 6px #00000029"
  },
  fontBold: {
    fontWeight: 'bold',
  },
  dangerPaper: {
    backgroundColor: '#d32f2f1a',
    borderLeft: '3px solid #D32F2F'
  }
})

export default function MatricCard(props){
  const classes = useStyles();
  const { name, value, subValue, unitType, subUnit, type } = props;
  const wapperClass = [classes.paper, type === 'danger' ? classes.dangerPaper : null]
  return (
    <CardPaper className={wapperClass}>
      <Typography>{ name }</Typography>
      <Typography gutterBottom variant="h4" className={classes.fontBold}>
        { value 
          ? value.toLocaleString() 
          : 0 
        }
        <Typography style={{marginLeft: 3}} variant="body2" component="b" className={classes.fontBold}>
          { unitType }
        </Typography>
      </Typography>
      {
        subValue ? (
          <Typography variant="body2" component="p">{subValue.toLocaleString()} {subUnit}</Typography>
        ) : null
      }

    </CardPaper>
  );
}
