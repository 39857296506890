import { apiSettings, tokenGet } from '../../../utils/api/CoreApi';
import { formatDate } from '../../../utils/date';
import {
    MS_GET_COUNT_TOTAL_SUCCESS,
    MS_GET_LATEST_MACHINE_DISTRIBUTION_SUCCESS,
    MS_GET_LATEST_MACHINE_USAGE_SUCCESS,
    MS_LOADING_TOGGLE,
    MS_DIALOG_TOGGLE,
    MS_QUERY,
    MS_SET_VIEW_BY_LATEST_MACHINE_DISTRIBUTION,
    MS_SET_VIEW_BY_LATEST_MACHINE_USAGE,
    MS_SET_VIEW_BY_COUNT_TOTAL,
    MS_SET_ORDER_BY_LATEST_MACHINE_USAGE,
    MS_SET_ORDER_LATEST_MACHINE_USAGE,
    MS_UPDATE_FETCH_DATE,
    MS_GET_MACHINE_PERFORMANCE_SUCCESS,
    MS_SET_ORDER_MACHINE_PERFORMANCE,
} from '../../actions/actionTypes';

const BASE_URL = `${apiSettings.apiURLMachines}/machines/summary`;

export const getCountTotal = (query = {}, viewBy = 1) => {
    return function (dispatch) {
        dispatch(loadingToggle(true));
        tokenGet(`${BASE_URL}/count-total?${new URLSearchParams({ ...query, viewBy })}`)
            .then(({ data }) => {
                dispatch(countTotalSuccess(data));
                dispatch(loadingToggle(false));
            })
            .catch((error) => {
                console.log(error)
                dispatch(loadingToggle(false));
            });
    };
};

export const getLatestMachineDistribution = (query = {}, viewBy = 1) => {
    return function (dispatch) {
        dispatch(loadingToggle(true));
        tokenGet(`${BASE_URL}/latest-machine-distribution?${new URLSearchParams({ ...query, viewBy })}`)
            .then(({ data }) => {
                dispatch(updateFetchDate(formatDate(new Date(), 'H:mm MMM D, Y')));
                dispatch(latestMachineDistributionSuccess(data));
                dispatch(loadingToggle(false));
            })
            .catch((error) => {
                console.log(error)
                dispatch(loadingToggle(false));
            });
    };
};

export const getLatestMachineUsage = (query = {}, filter = 1, orderBy = 'desc') => {
    return function (dispatch) {
        dispatch(loadingToggle(true));
        tokenGet(`${BASE_URL}/machine-usage?${new URLSearchParams({ 
                ...query, 
                filter, 
                orderBy: orderBy.toUpperCase(), 
            })}`)
            .then(({ data }) => {
                dispatch(latestMachineUsageSuccess(data));
                dispatch(loadingToggle(false));
            })
            .catch((error) => {
                console.log(error)
                dispatch(loadingToggle(false));
            });
    };
};

export const getMachinePerformance = (
        query = {}, 
        orderBy = 'desc',
        page = 1,
        pageSize = 10,
    ) => {
    return function (dispatch) {
        dispatch(loadingToggle(true));
        tokenGet(`${BASE_URL}/machine-performance?${new URLSearchParams({ 
                ...query,
                page,
                pageSize,
                orderBy, 
            })}`)
            .then(({ data }) => {
                dispatch(MachinePerformanceSuccess(data));
                dispatch(loadingToggle(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(loadingToggle(false));
            });
    };
};

export const countTotalSuccess = (payload) => ({
    type: MS_GET_COUNT_TOTAL_SUCCESS, payload
});

export const latestMachineDistributionSuccess = (payload) => ({
    type: MS_GET_LATEST_MACHINE_DISTRIBUTION_SUCCESS, payload
});

export const latestMachineUsageSuccess = (payload) => ({
    type: MS_GET_LATEST_MACHINE_USAGE_SUCCESS, payload
});

export const MachinePerformanceSuccess = (payload) => ({
    type: MS_GET_MACHINE_PERFORMANCE_SUCCESS, payload
});

export const loadingToggle = (payload) => ({ type: MS_LOADING_TOGGLE, payload });
export const dialogToggle = () => ({ type: MS_DIALOG_TOGGLE });
export const setQuery = (payload) => ({ type: MS_QUERY, payload });

export const setViewByLatestMachineDistribution = (payload) => ({ 
    type: MS_SET_VIEW_BY_LATEST_MACHINE_DISTRIBUTION, payload 
});

export const updateFetchDate = (payload) => ({ 
    type: MS_UPDATE_FETCH_DATE, payload 
});

export const setViewByLatestMachineUsage = (payload) => ({ 
    type: MS_SET_VIEW_BY_LATEST_MACHINE_USAGE, payload 
});

export const setViewByCountTotal = (payload) => ({ 
    type: MS_SET_VIEW_BY_COUNT_TOTAL, payload 
});

export const setOrderByLatestMachineUsage = (payload) => ({ 
    type: MS_SET_ORDER_BY_LATEST_MACHINE_USAGE, payload 
});

export const setOrderLatestMachineUsage = (payload) => ({ 
    type: MS_SET_ORDER_LATEST_MACHINE_USAGE, payload 
});

export const setOrderMachinePerformance = (payload) => ({ 
    type: MS_SET_ORDER_MACHINE_PERFORMANCE, payload 
});