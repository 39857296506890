import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { validateProblemFields } from './FormValidation';
import FormControl from '@material-ui/core/FormControl';
import { notificationError } from '../../../../redux/actions/Notification/notificationActions';
import { getProblemDetail, postProblemNew, putProblemUpdate, getProblemsTreeView, deleteProblem, problemTriggerHighlight } from '../../../../redux/actions/Problem/problemActions';
import { confirmAlert } from 'react-confirm-alert';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import MainPage from '../../../../components/MainPage/MainPage';
import MainPageHeader from '../../../../components/MainPageHeader/MainPageHeader';
import MainPageBody from '../../../../components/MainPageBody/MainPageBody';
import { getFactoryId, checkPageAccessField } from '../../../../utils/helper.js';
import history from '../../../../history';
import Table from '../../../../components/CollapsableTable/CollapseTable'
import dictionary from '../../../../redux/actions/Translation/dictionary'
import './RepairTypeConfig.scss';
import { formatDate } from '../../../../utils/date';

class RepairTypeConfig extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            translations: {
                ...this.convert()
            },
            translationTimestamp: undefined,
            modalSettings: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            modalContainerSettings: {
                width: '360px',
                backgroundColor: '#ffffff',
                outline: 'none',
                padding: '2px 4px 3px',
                problemConfig: {
                    padding: "30px 15px 30px 15px"
                },
                title: {
                    fontSize: '1.4rem',
                    marginBottom: '15px'
                },
                controlRow: {
                    marginBottom: '10px'
                },
                footerActions: {
                    textAlign: 'center',
                    width: '100%',
                    padding: '10px',
                    cancel: {
                        fontSize: '1.2rem',
                        borderRadius: '4px !important',
                    },
                    save: {
                        fontSize: '1.2rem',
                        background: '#1689FA',
                        color: '#fff',
                        borderRadius: '4px !important',
                    }
                },
                redAsterisk: {
                    MuiFormLabel: {
                        root: {
                            color: '#4CAF50 !important',
                        }
                    }
                }
            },
            factoryId: null,
            id: null,
            detailLoaded: false,
            hasFormChanges: false,
            disablePrompt: false,
            form: {
                problemType: null,
                desc1: "",
                isActive: null,
                ticketTypeId: 1,
            },
            formErrors: [],
            modalTitle: null,
            saveLabel: null,
            closedModalFromAction: false,
            problemModalOpen: false,
            tableState: {
                add: () => {
                    this.problemAddModalOpen()
                },
                edit: ({ details: { id } }) => {
                    this.problemUpdateModalOpen(id)
                },
                remove: ({ details: { id } }) => {
                    confirmAlert({
                        title: this.state?.translations?.are_you_sure_delete_problem,
                        message: '',
                        buttons: [
                            {
                                label: this.state?.translations?.YES,
                                onClick: () => {
                                    this.props.deleteProblem(id, true)
                                }
                            },
                            {
                                label: this.state?.translations?.NO,
                            }
                        ]
                    });
                },
                row_click: ({ details: { id } }) => {
                    history.push(`/configuration/solution-type/${id}`) // commented out for this sprint for now
                },

                search: true,
                keys: this.initTableHeader(),
                highlight: true,
                data: [],
                loading: false,
                collapsable: true,
                levelInstance: {
                    parent: 'details',
                    child: [
                        'solutions'
                    ]
                },
                timeStamp: undefined,
            },
            validationCharacter: {
                problemType: { error: false, message: 'Maximum character limit is 35' },
                desc1: { error: false, message: 'Maximum character limit is 250' },
            },
        }
    }

    /**handler */
    initTableHeader = () => {
        let { STATUS, NAME, TYPE, DESCRIPTION, UPDATED_DATE } = this.convert()
        return [
            {
                name: NAME,
                childKey: 'solutionType',
                key: 'problemType',
                headerWidth: '18.2',
                width: '16.9'
            },
            {
                name: TYPE,
                childKey: '',
                childDefaultValue: 'Solution',
                keyDefaultValue: 'Problem',
                key: '',
                headerWidth: '8.4',
                width: '8.5'
            },
            // {
            //     name: 'Apply To All',
            //     childKey: 'applyToAll',
            //     booleanKey: ['Yes', 'No'],
            //     type: 'boolean',
            //     key: '',
            //     headerWidth: '13',
            //     width: '13.2'
            // },
            {
                name: DESCRIPTION,
                childKey: 'desc1',
                key: 'desc1',
                headerWidth: '20.3',
                width: '20.5'
            },
            {
                name: STATUS,
                type: 'boolean',
                key: 'isActive',
                childKey: 'isActive',
                headerWidth: '8.2',
                width: '8.3'
            },
            {
                name: UPDATED_DATE,
                type: 'date',
                key: 'updatedDt',
                childKey: 'updatedDt',
                alt: 'createdDt'
            },
        ]
    }
    convert = () => {
        let { Active,Inactive,SAVE, ADD, EDIT_PROBLEM, CANCEL, ADD_PROBLEM, YES, NO, NOTIFICATIONS: { are_you_sure_delete_problem, are_you_sure_to_leave_this_page, problem_name_exists }, STATUS, NAME, TYPE, DESCRIPTION, REPAIR_TYPES, UPDATED_BY, UPDATED_DATE, CREATED_BY, CREATED_DATE } = dictionary.GET()
        return dictionary.TRANSLATE({  Active,Inactive,SAVE, ADD, EDIT_PROBLEM, CANCEL, ADD_PROBLEM, YES, NO, are_you_sure_delete_problem, are_you_sure_to_leave_this_page, problem_name_exists, STATUS, NAME, TYPE, DESCRIPTION, REPAIR_TYPES, UPDATED_BY, UPDATED_DATE, CREATED_BY, CREATED_DATE })
    }

    handleFormChange = ({ target }) => {
        let tmpForm = this.state.form;
        tmpForm[target.name] = target.value;
        this.setState({ form: tmpForm, hasFormChanges: true });
        this.removeErrorOnChange(target.name);
    };

    handleRadioButtonChange = ({ target }) => {
        let tmpForm = this.state.form;
        tmpForm[target.name] = parseInt(target.value);
        this.setState({ form: tmpForm, hasFormChanges: true });
        this.removeErrorOnChange(target.name);
    };

    removeErrorOnChange(field) {
        let errors = this.state.formErrors.filter((item) => { return item.name !== field });
        this.setState({ formErrors: errors });
    }

    hasErrors = (field) => {
        let checkField = this.state.formErrors.filter((item) => { return item.name === field });
        if (checkField.length > 0) {
            return true
        }
        else {
            return false;
        }
    }

    getErrors = (field) => {
        let checkField = this.state.formErrors.filter((item) => { return item.name === field });
        let errorString = null;
        checkField.map((item) => {
            errorString += item.message + '. ';
            return '';
        })
        return errorString;
    }

    componentDidMount() {
        let factoryId = getFactoryId();
        this.setState({ factoryId: factoryId });
        // this.problemUpdateModalOpen(22);
        this.props.getProblemsTreeView()
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.problemDetail !== null && nextProps.problemDetail !== undefined && this.state.id !== undefined && this.state.id !== null && this.state.detailLoaded !== true) {
            let tmpForm = {
                problemType: nextProps.problemDetail.problemType,
                desc1: nextProps.problemDetail.desc1,
                isActive: this.renderBooleanAsInteger(nextProps.problemDetail.isActive),
                ticketTypeId: 1,

            }
            this.setState({ form: tmpForm, detailLoaded: true });
        }
    }

    componentDidUpdate() {
        // console.log('didUpdate', this.props, this.state)
        let { loading, problemListTree, problemTimeStamp } = this.props
        let { loading: stateLoading, data, timeStamp } = this.state.tableState
        if (loading != stateLoading || problemListTree?.length != data?.length && Array.isArray(problemListTree) && problemListTree != undefined || problemTimeStamp != timeStamp) {
            if (Array.isArray(problemListTree)) {
                let tableState = this.state.tableState;
                if (checkPageAccessField(this.props.userAccess, { pageId: 23, pageAccess: 'VIEW' })) {
                    tableState = {
                        add: null,
                        edit: null,
                        remove: null
                    }
                }
                this.setState({
                    tableState: {
                        ...tableState,
                        data: [...problemListTree],
                        loading,
                        timeStamp: problemTimeStamp
                    }
                })
            }
            // this.forceUpdate()
            // console.log(problemListTree, loading, stateLoading, data )
        }
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                translations: Object.assign({}, { ...this.convert() }),
                tableState: {
                    ...this.state.tableState,
                    keys: this.initTableHeader(),
                    timeStamp: new Date()
                }
            })
        }
    }

    clearForm() {
        let tmpForm = {
            problemType: null,
            desc1: "",
            isActive: null,
            ticketTypeId: 1,
            macTypeId: null,
            macSubTypeId: null
        }
        this.setState({ form: tmpForm, formErrors: [] });
    }

    problemModalOpen = (id = null) => {
        let { translations } = this.state
        console.log('id', id)
        if (id !== null) {
            this.props.getProblemDetail(id);
            this.setState({ problemModalOpen: true, saveLabel: translations.SAVE, id: id });
        }
        else {
            this.setState({ problemModalOpen: true, saveLabel: translations.ADD, id: null, detailLoaded: false });
        }
    }

    problemAddModalOpen = () => {
        this.problemModalOpen(null);
    }

    problemUpdateModalOpen = (id) => {
        this.problemModalOpen(id);
    }

    problemModalClose = () => {
        let { translations } = this.state
        if (this.state.hasFormChanges === true && this.state.disablePrompt === false && this.state.id !== null) {
            var flag = window.confirm(translations.are_you_sure_to_leave_this_page);
            if (flag) {
                this.setState({ problemModalOpen: false, hasFormChanges: false, disablePrompt: false, detailLoaded: false, id: null });
                this.clearForm();
            }
        }
        else {
            this.setState({ problemModalOpen: false, hasFormChanges: false, disablePrompt: false, detailLoaded: false, id: null });
            this.clearForm();
        }
    }

    submitForm = () => {
        let payload = {
            ...this.state.form,
            factoryId: this.state.factoryId
        };
        let formValidation = validateProblemFields(payload);
        console.log({ formValidation })
        this.setState({ formErrors: formValidation.errors });
        if (formValidation.valid) {
            this.setState({ disablePrompt: true }, () => {
                if (this.state.id === null) {
                    payload = {
                        ...payload,
                        isActive: 1
                    }
                    delete payload.isActive;
                    this.setState({ closedModalFromAction: false }, () => {
                        console.log("payload");
                        console.log(payload);
                        this.props.postProblemNew(payload);
                        this.props.getProblemsTreeView();
                        // this.clearForm();
                    })
                }
                else {
                    this.setState({ closedModalFromAction: false }, () => {
                        this.props.putProblemUpdate(this.state.id, payload, 'problemList');
                        this.props.getProblemsTreeView();
                        // this.clearForm();
                    })
                }
            })
        }
        else {
            this.props.notificationError(formValidation.errors[0].message);
        }
    }

    triggerCloseModal() {
        this.problemModalClose();
        this.setState({ closedModalFromAction: true });
    }

    renderText(field) {
        if (field === null || field === '') {
            return '-'
        }
        else {
            //   return field != undefined ? field.toString().translate() : field
            return field
        }
    }

    renderDate(date) {
        if (date !== null) {
            let newDate = date.substring(0, date.indexOf('T'));
            return moment(newDate).format('DD-MM-YYYY');
        }
        else {
            return '-';
        }
    }

    renderBooleanAsInteger(bool) {
        if (bool === true || bool === "True") {
            return 1
        }
        else {
            return 0
        }
    }

    renderStatusForm() {
        let { translations } = this.state
        return (
            <div>
                <div className="space"></div>
                <div className="form-input">
                    <Grid container spacing={0}>
                        <Grid item xs={5}>
                            <FormLabel className="status" component="legend">{translations.STATUS}</FormLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <FormControl className="control-row" fullWidth>
                                <RadioGroup onChange={this.handleRadioButtonChange} name="isActive" className="radioButton" row value={this.state.form.isActive}>
                                    <FormControlLabel value={1} control={<Radio color="primary" />} label={translations.Active} />
                                    <FormControlLabel value={0} control={<Radio color="primary" />} label={translations.Inactive} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid className="statusDetails" item xs={12}>
                            <FormLabel component="legend">{translations.UPDATED_BY}: {this.props.problemDetail ? this.renderText(this.props.problemDetail.updatedBy) : null}</FormLabel>
                        </Grid>
                        <Grid className="statusDetails" item xs={12}>
                            <FormLabel component="legend">{translations.UPDATED_DATE}: {this.props.problemDetail ? formatDate(this.props.problemDetail.updatedDt) : null}</FormLabel>
                        </Grid>
                        <Grid className="statusDetails" item xs={12}>
                            <FormLabel component="legend">{translations.CREATED_BY}: {this.props.problemDetail ? this.renderText(this.props.problemDetail.createdBy) : null}</FormLabel>
                        </Grid>
                        <Grid className="statusDetails" item xs={12}>
                            <FormLabel component="legend">{translations.CREATED_DATE}: {this.props.problemDetail ? formatDate(this.props.problemDetail.createdDt) : null}</FormLabel>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    renderProblemTypeModal() {
        let { translations } = this.state;
        const { validationCharacter } = this.state;
        return (
            <div>
                {this.props.closeModal && this.state.closedModalFromAction === false ? this.triggerCloseModal() : null}
                <Modal
                    className="repair-type-modal"
                    open={this.state.problemModalOpen}
                    onClose={this.state.problemModalClose}
                    style={this.state.modalSettings}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500
                    }}
                >
                    <Fade style={this.state.modalContainerSettings} in={this.state.problemModalOpen}>
                        <div style={this.state.modalContainerSettings.problemConfig}>
                            <div style={this.state.modalContainerSettings.title}>{this.state.id ? translations.EDIT_PROBLEM : translations.ADD_PROBLEM}</div>
                            <Grid container spacing={0}>
                                <Grid item xs>
                                    <FormControl style={this.state.modalContainerSettings.controlRow} fullWidth>
                                        <TextField 
                                            helperText={
                                                this.state.formErrors.find((error) => error.name === 'problemType')?.message
                                            }
                                            className={this.state.form.problemType ? 'label-green' : null} 
                                            required 
                                            error={this.hasErrors('problemType')} 
                                            value={this.state.form.problemType} 
                                            onChange={this.handleFormChange} 
                                            name="problemType" 
                                            placeholder="" 
                                            label={translations.NAME} 
                                            InputLabelProps={{ shrink: true }} />
                                    </FormControl>
                                    <FormControl style={this.state.modalContainerSettings.controlRow} fullWidth>
                                        <TextField
                                            helperText={
                                                this.state.formErrors.find((error) => error.name === 'desc1')?.message
                                            }
                                            required
                                            className={this.state.form.desc1 ? 'label-green' : null} 
                                            error={this.hasErrors('desc1')} 
                                            value={this.state.form.desc1} 
                                            onChange={this.handleFormChange} 
                                            name="desc1" 
                                            placeholder="" 
                                            label={translations.DESCRIPTION} 
                                            InputLabelProps={{ shrink: true }} />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {this.state.id ? this.renderStatusForm() : null}
                            <div style={this.state.modalContainerSettings.footerActions}>
                                <Grid container spacing={1}>
                                    <Grid item xs={2} />
                                    <Grid item xs={4}>
                                        <Button onClick={this.problemModalClose} style={this.state.modalContainerSettings.footerActions.cancel} fullWidth variant="outlined">{translations.CANCEL}</Button>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button onClick={this.submitForm} style={this.state.modalContainerSettings.footerActions.save} fullWidth color="primary" variant="contained">{this.state.saveLabel}</Button>
                                    </Grid>
                                    <Grid item xs={2} />
                                </Grid>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div>
        )
    }

    setBeforeUnload = () => {
        let { translations } = this.state
        if (this.state.hasFormChanges) {
            window.onbeforeunload = () => { return translations.are_you_sure_to_leave_this_page }
        } else {
            window.onbeforeunload = undefined
        }
    }

    componentWillUnmount() {
        if (this.state.hasFormChanges) {
            window.onbeforeunload = undefined
        }
    }

    handleLinkClick = (url) => {
        if (this.state.id) {
            history.push(url);
        }
        else {
            this.setState({ disablePrompt: true }, () => {
                history.push(url);
            })
        }
    }

    checkProblemTrigger() {
        let { translations } = this.state
        if (this.props.triggerHighlight) {
            let errors = [];
            let formValid = false;
            errors.push({ name: 'problemType', message: translations.problem_name_exists });

            let tmpFormError = {
                valid: formValid,
                errors: errors
            }

            this.setState({ formErrors: tmpFormError.errors }, () => {
                this.props.problemTriggerHighlight(false);
            });
        }
    }

    render() {
        let { translations } = this.state
        return (
            <MainPage className='repair-type-config'>
                {this.setBeforeUnload()}
                <Prompt
                    when={this.state.hasFormChanges && this.state.disablePrompt === false}
                    message={translations.are_you_sure_to_leave_this_page}
                />
                {this.checkProblemTrigger()}
                <MainPageHeader title="Repair Types" search={false} />
                <MainPageBody>
                    <div className="repair-type-config-body">
                        <div className="title">{translations.REPAIR_TYPES}</div>
                        {this.renderProblemTypeModal()}
                        <Table {...this.state.tableState} />
                    </div>
                </MainPageBody>
            </MainPage>
        );
    }
}

const mapStateToProps = state => {
    let { translationReducer } = state
    let apiLoading = {
        loading: state.problemListReducer.loading,
        detailLoading: state.problemListReducer.detailLoading,
    }
    let data = {
        userAccess: state.authReducer.access,
        problemList: state.problemListReducer.problemList,
        problemDetail: state.problemListReducer.problemDetail,
        triggerHighlight: state.problemListReducer.triggerHighlight,
        closeModal: state.problemListReducer.closeModal,
        problemListTree: state.problemListReducer.problemListTree,
        problemTimeStamp: state.problemListReducer.problemTimeStamp
    }

    return {
        ...apiLoading,
        ...data,
        ...translationReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProblemsTreeView: () => dispatch(getProblemsTreeView()),
        problemTriggerHighlight: (flag) => dispatch(problemTriggerHighlight(flag)),
        getProblemDetail: (i) => dispatch(getProblemDetail(i)),
        notificationError: (message) => dispatch(notificationError(message)),
        postProblemNew: (payload) => dispatch(postProblemNew(payload)),
        putProblemUpdate: (id, payload, component) => dispatch(putProblemUpdate(id, payload, component)),
        deleteProblem: (id, isAll) => dispatch(deleteProblem(id, isAll)),
    }
}

RepairTypeConfig = connect(mapStateToProps, mapDispatchToProps)(RepairTypeConfig);

export default RepairTypeConfig;
