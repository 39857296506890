import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { checkPageAccess } from '../../../../../utils/helper';
import useHistoryTableStyle from './historyTable.style';
import useToolbarStyles from './toolbar.style';
import { getComparator, stableSort } from '../../../../../utils/array';
import { formatDate } from '../../../../../utils/date';

const headCellsMaintenance = [
    { id: 'index', className: 'columnIndex', numeric: false, disablePadding: false, label: '#' },
    { id: 'updatedDt', className: 'reportedDt', numeric: false, disablePadding: true, label: 'DATE MODIFIED' },
    { id: 'buildingDesc', className: 'buildingDesc', numeric: false, disablePadding: true, label: 'BUILDING' },
    { id: 'areaDesc', className: 'areaDesc', numeric: false, disablePadding: true, label: 'LOCATION' },
    { id: 'updatedBy', className: 'updatedBy', numeric: false, disablePadding: true, label: 'MODIFIED BY' },
];

function EnhancedTableHeadMaintenance(props) {
    const { classes, order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    const classes1 = useToolbarStyles();

    const renderSortableHeader = (headCell) => {
        return (
            <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
            >
                {headCell.label.translate()}
                {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                ) : null}
            </TableSortLabel>
        );
    }

    return (
        <TableHead>
            <TableRow>
                {headCellsMaintenance.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align='left'
                        padding='default'
                        className={classes1[headCell.className]}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.id === 'index' ?
                            <span>{headCell.label.translate()}</span> :
                            renderSortableHeader(headCell)
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const [isCollapseOpened, setArrowMaintenance] = React.useState(props.maintenanceArrow);
    const title = props.title;
    const userAccess = JSON.parse(localStorage.getItem('userAccess'));

    const handleMaintenanceArrow = () => {
        setArrowMaintenance((prev) => {
            props.collapseMaintenance(!prev)
            return !prev;
        });
    };

    return (
        <Toolbar className={clsx(classes.root, {})}>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                {title.translate()}
            </Typography>
            {
                isCollapseOpened ? (
                    <ArrowDropUpIcon className={classes.arrow} onClick={checkPageAccess(userAccess, { pageId: 4 }) ? handleMaintenanceArrow : null}></ArrowDropUpIcon>
                ) : (
                    <ArrowDropDownIcon className={classes.arrow} onClick={checkPageAccess(userAccess, { pageId: 4 }) ? handleMaintenanceArrow : null}></ArrowDropDownIcon>
                )
            }
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const tableTheme = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                fontSize: '0.975rem',
            },
            sizeSmall: {
                padding: '6px 0px 6px 16px',
            },
            head: {
                fontWeight: '800',
            },
        },
    },
});

const MachineHistory = ({ machineHistories = [], onMachineHistoryOpened, dependCollapses }) => {
    const classes = useHistoryTableStyle();
    const [isCollapseOpened, setCollapseOpened] = useState(false);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const userAccess = JSON.parse(localStorage.getItem('userAccess'));

    useEffect(() => {
        if (dependCollapses.maintenanceChecked || dependCollapses.repairChecked) {
            setCollapseOpened(false);
        }
    }, [dependCollapses.maintenanceChecked, dependCollapses.repairChecked])

    const handleMaintenanceCollapse = () => {
        setCollapseOpened(!isCollapseOpened);
        if (!isCollapseOpened) {
            onMachineHistoryOpened();
        }
    };

    const renderDate = (date) => {
        if (date != '-') {
            let timezone = moment.parseZone(date).format('Z');
            let dateTimeOnly = moment.parseZone(date).format('YYYY-MM-DD HH:mm:ss') + '+00:00';
            return moment(dateTimeOnly).utcOffset(timezone).format('DD-MM-YYYY HH:mm');
        }
        else {
            return '-';
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const renderLastRow = () => {
        return (
            <ThemeProvider theme={tableTheme}>
                <TableRow
                    classes={{ hover: classes.hover }}
                    className={classes.tableRow}
                    tabIndex={-1}
                >
                    <TableCell 
                        colSpan={5} 
                        align="left"
                        className={classes.columnIndex}
                    >
                        {'* This table maximum shows the latest ten records'}
                    </TableCell>
                </TableRow>
            </ThemeProvider>
        );
    }

    return (
        <Paper className={classes.paper + ' ' + (checkPageAccess(userAccess, { pageId: 4 }) === false ? classes.paperDisabled : null)}>

            <EnhancedTableToolbar
                title={"MACHINE HISTORY".translate()}
                maintenanceArrow={isCollapseOpened}
                collapseMaintenance={handleMaintenanceCollapse}
            />

            <TableContainer>
                <Collapse in={isCollapseOpened}>
                    <Table
                        stickyHeader
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHeadMaintenance
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={machineHistories.length}
                        />

                        <TableBody>
                            {stableSort(machineHistories, getComparator(order, orderBy))
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <ThemeProvider theme={tableTheme}>
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                classes={{ hover: classes.hover }}
                                                className={classes.tableRow}
                                                tabIndex={-1}
                                                key={row.id}
                                            >
                                                <TableCell className={classes.columnIndex} align="left">{index + 1}</TableCell>
                                                <TableCell align="left">{formatDate(row.updatedDt)}</TableCell>
                                                <TableCell align="left">{row.buildingDesc}</TableCell>
                                                <TableCell align="left">{row.mfgLineDesc !== null ? row.mfgLineDesc + ' - ' + row.station : row.areaDesc}</TableCell>
                                                <TableCell align="left">{row.updatedBy}</TableCell>
                                            </TableRow>
                                        </ThemeProvider>
                                    );
                                })}
                            {machineHistories.length == 0 ? (
                                <TableRow>
                                    <TableCell align="center" colSpan={5} className={classes.notFound}>{'No records found'.translate()}</TableCell>
                                </TableRow>
                            ) : renderLastRow()}
                        </TableBody>
                    </Table>
                </Collapse>
            </TableContainer>
        </Paper>
    )
}

export default MachineHistory;