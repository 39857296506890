import React, { useCallback } from 'react';
import moment from 'moment';
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux';
import { validateFields } from './FormValidation';
import { Prompt } from 'react-router';
import history from '../../../../..//history';
import { Link } from 'react-router-dom';
import { notificationError } from '../../../../../redux/actions/Notification/notificationActions';
import { postUploadAttachment, postAttachmentNew, putAttachmentUpdate, getAttachmentDetail, attachmentTriggerHighlight } from '../../../../../redux/actions/Attachment/attachmentActions';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import MainPage from '../../../../../components/MainPage/MainPage';
import MainPageHeader from '../../../../../components/MainPageHeader/MainPageHeader';
import MainPageBody from '../../../../../components/MainPageBody/MainPageBody';
import { apiSettings } from '../../../../../utils/api/CoreApi';
import { getFactoryId } from '../../../../../utils/helper.js';
import PublishIcon from '@material-ui/icons/Publish';
import CancelIcon from '@material-ui/icons/Cancel';
import dictionary from '../../../../../redux/actions/Translation/dictionary'
import './AttachmentForm.scss';
import { formatDate } from '../../../../../utils/date';

class AttachmentForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            translations: {
                ...this.convert()
            },
            translationTimestamp: undefined,
            id: null,
            title: null,
            saveLabel: null,
            factoryId: null,
            fileUpload: null,
            fileUploadBase64: null,
            hasFormChanges: false,
            disablePrompt: false,
            form: {
                code: null,
                name: null,
                desc: null,
                imgLink: null,
                isActive: 1
            },
            acceptedFiles: {
                accept: 'image/jpeg, image/png,image/jpg'
            },
            formErrors: []
        }

    }

    convert = () => {
        let { NOTIFICATIONS: { are_you_sure_to_leave_this_page, motor_type_code_exists }, EDIT_ATTACHMENT, ADD_ATTACHMENT, FILE_FORMAT_NOT_ALLOWED, ATTACHMENT_CODE, MOTOR_TYPE_CODE, SAVE, ADD, CANCEL, STATUS, Active, Inactive, UPDATED_BY, UPDATED_DATE, CREATED_BY, CREATED_DATE,
            BRAND_CODE, DISPLAY_NAME, COMPANY_NAME, WEBSITE, DESCRIPTION, SHORT_NAME_FOR_SYSTEM_DISPLAY,
            DRAG_AND_DROP_FILES, OR, BROWSE, FORMAT_PNG_OR_JPG,
        } = dictionary.GET()
        return dictionary.TRANSLATE({
            are_you_sure_to_leave_this_page, motor_type_code_exists,
            EDIT_ATTACHMENT, ADD_ATTACHMENT, FILE_FORMAT_NOT_ALLOWED, ATTACHMENT_CODE, MOTOR_TYPE_CODE, SAVE, ADD, CANCEL, STATUS, Active, Inactive, UPDATED_BY, UPDATED_DATE, CREATED_BY, CREATED_DATE,
            BRAND_CODE, DISPLAY_NAME, COMPANY_NAME, WEBSITE, DESCRIPTION, SHORT_NAME_FOR_SYSTEM_DISPLAY,
            DRAG_AND_DROP_FILES, OR, BROWSE, FORMAT_PNG_OR_JPG,
        })

    }
    handleFormChange = ({ target }) => {
        let tmpForm = this.state.form;
        tmpForm[target.name] = target.value;
        this.setState({ form: tmpForm, hasFormChanges: true });
        this.removeErrorOnChange(target.name);
    };

    handleCodeFormChange = ({ target }) => {
        let value = null;
        if (target.value.indexOf(" ") >= 0) {
            value = target.value.replace(" ", "");
        }
        else {
            value = target.value;
        }
        let tmpForm = this.state.form;
        tmpForm[target.name] = value;
        this.setState({ form: tmpForm, hasFormChanges: true });
        this.removeErrorOnChange(target.name);
    };

    handleRadioButtonChange = ({ target }) => {
        let tmpForm = this.state.form;
        tmpForm[target.name] = parseInt(target.value);
        this.setState({ form: tmpForm, hasFormChanges: true });
        this.removeErrorOnChange(target.name);
    };

    renderBooleanAsInteger(bool) {
        if (bool === true || bool === "True") {
            return 1
        }
        else {
            return 0
        }
    }

    renderText(field) {
        if (field === null || field === '') {
            return '-'
        }
        else {
            //   return field != undefined ? field.toString().translate() : field
            return field
        }
    }

    renderDate(date) {
        if (date !== null) {
            let timezone = moment.parseZone(date).format('Z');
            let dateTimeOnly = moment.parseZone(date).format('YYYY-MM-DD HH:mm:ss') + '+00:00';
            return moment(dateTimeOnly).utcOffset(timezone).format('DD-MM-YYYY');
        }
        else {
            return '-';
        }
    }

    checkAttachmentTrigger() {
        let {translations} = this.state
        if (this.props.triggerHighlight) {
            let errors = [];
            let formValid = false;
            errors.push({ name: 'code', message: translations.motor_type_code_exists });

            let tmpFormError = {
                valid: formValid,
                errors: errors
            }

            this.setState({ formErrors: tmpFormError.errors }, () => {
                this.props.attachmentTriggerHighlight(false);
            });
        }
    }

    componentDidMount() {
        let {translations} = this.state
        document.body.style.backgroundColor = "#F7F7FB";
        let factoryId = getFactoryId();
        if (this.props.match.params.id) {
            this.props.getAttachmentDetail(this.props.match.params.id);
            this.setState({ title: translations.EDIT_ATTACHMENT, saveLabel: translations.SAVE, id: this.props.match.params.id, factoryId: factoryId });
        }
        else {
            this.setState({ title: translations.ADD_ATTACHMENT, saveLabel: translations.ADD, factoryId: factoryId });
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.attachmentDetail !== null && nextProps.attachmentDetail !== undefined && this.props.match.params.id !== null && this.props.match.params.id !== undefined) {
            let tmpForm = {
                code: nextProps.attachmentDetail.attachment,
                name: nextProps.attachmentDetail.desc1,
                desc: nextProps.attachmentDetail.desc2,
                imgLink: nextProps.attachmentDetail.imgLink,
                isActive: this.renderBooleanAsInteger(nextProps.attachmentDetail.isActive)
            }
            this.setState({ form: tmpForm })
        }
    }

    submitForm = () => {
        let payload = {
            ...this.state.form,
            factoryId: this.state.factoryId
        };
        let formValidation = validateFields(payload);
        this.setState({ formErrors: formValidation.errors });
        if (formValidation.valid) {
            this.setState({ disablePrompt: true }, () => {
                if (this.state.fileUpload) {
                    let fd = new FormData();
                    fd.append('file', this.state.fileUpload);
                    fd.append('str', 'stronly');

                    if (this.state.id === null) {
                        delete payload.isActive;
                        this.props.postUploadAttachment(payload, fd, 'new');
                    }
                    else {
                        this.props.postUploadAttachment(payload, fd, 'edit', this.state.id);
                    }
                }
                else {
                    let mutatedPayload = {
                        ...payload,
                        imgLink: null
                    }

                    if (this.state.id === null) {
                        delete mutatedPayload.isActive;
                        this.props.postAttachmentNew(mutatedPayload);
                    }
                    else {
                        this.props.putAttachmentUpdate(this.state.id, payload);
                    }

                }
            });
        }
        else {
            this.props.notificationError(formValidation.errors[0].message);
        }
    }

    removeErrorOnChange(field) {
        let errors = this.state.formErrors.filter((item) => { return item.name !== field });
        this.setState({ formErrors: errors });
    }

    hasErrors = (field) => {
        let checkField = this.state.formErrors.filter((item) => { return item.name === field });
        if (checkField.length > 0) {
            return true
        }
        else {
            return false;
        }
    }

    getErrors = (field) => {
        let checkField = this.state.formErrors.filter((item) => { return item.name === field });
        let errorString = null;
        checkField.map((item) => {
            errorString += item.message + '. ';
            return '';
        })
        return errorString;
    }

    renderStatusForm() {
        let {translations} = this.state
        return (
            <div>
                <div className="space"></div>
                <Paper className="body end">
                    <div className="form-input">
                        <FormControl className="control-row" fullWidth>
                            <Grid container spacing={0}>
                                <Grid item xs={5}>
                                    <FormLabel className="status" component="legend">{translations.STATUS}</FormLabel>
                                </Grid>
                                <Grid item xs={7}>
                                    <RadioGroup onChange={this.handleRadioButtonChange} name="isActive" className="radioButton" row value={this.state.form.isActive}>
                                        <FormControlLabel value={1} control={<Radio color="primary" />} label={translations.Active} />
                                        <FormControlLabel value={0} control={<Radio color="primary" />} label={translations.Inactive} />
                                    </RadioGroup>
                                </Grid>
                                <Grid className="statusDetails" item xs={12}>
                                    <FormLabel component="legend">{translations.UPDATED_BY}: {this.props.attachmentDetail ? this.renderText(this.props.attachmentDetail.updatedBy) : null}</FormLabel>
                                </Grid>
                                <Grid className="statusDetails" item xs={12}>
                                    <FormLabel component="legend">{translations.UPDATED_DATE}: {this.props.attachmentDetail ? formatDate(this.props.attachmentDetail.updatedDt) : null}</FormLabel>
                                </Grid>
                                <Grid className="statusDetails" item xs={12}>
                                    <FormLabel component="legend">{translations.CREATED_BY}: {this.props.attachmentDetail ? this.renderText(this.props.attachmentDetail.createdBy) : null}</FormLabel>
                                </Grid>
                                <Grid className="statusDetails" item xs={12}>
                                    <FormLabel component="legend">{translations.CREATED_DATE}: {this.props.attachmentDetail ? formatDate(this.props.attachmentDetail.createdDt) : null}</FormLabel>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </div>
                </Paper>
            </div>
        )
    }

    onDrop = (acceptedFiles) => {
        let {translations} = this.state
        let file = acceptedFiles[0];
        if (file.name.toLowerCase().indexOf('.jpg') > 0 || file.name.toLowerCase().indexOf('.jpeg') > 0 || file.name.toLowerCase().indexOf('.png') > 0) {
            let tmpForm = {
                ...this.state.form,
                imgLink: null
            }
            this.getImageByFile(acceptedFiles[0]);
            this.setState({ fileUpload: acceptedFiles[0], form: tmpForm, hasFormChanges: true });
        }
        else {
            this.props.notificationError(translations.FILE_FORMAT_NOT_ALLOWED);
        }
    }

    getImageByFile = (file) => {
        let component = this;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            var base64data = reader.result;
            component.setState({ fileUploadBase64: base64data });
        }
    }

    getImageByUrl(url) {
        let token = JSON.parse(localStorage.getItem('userToken'));
        return apiSettings.apiURLFiles + `/api/files/by-name?path=/files/${url}&token=${token}&unlinked`;
    }

    removeImage = () => {
        let tmpForm = {
            ...this.state.form,
            imgLink: null
        }
        this.setState({ fileUpload: null, fileUploadBase64: null, form: tmpForm })
    }

    renderFormFile = () => {
        let {translations} = this.state
        if (this.state.fileUpload) {
            // if (this.state.fileUpload.name.indexOf('.pdf') > 0 ){
            //     return (
            //         <div className="thumbnail pdf">
            //             <div className="close pdf">
            //                 <CancelIcon onClick={this.removeImage} />
            //             </div>
            //             <div className="filename">{ this.state.fileUpload.name }</div>
            //         </div>
            //     )
            // }
            // else{
            return (
                <div className="thumbnail">
                    <div className="close">
                        <CancelIcon onClick={this.removeImage} />
                    </div>
                    <img alt={this.state.fileUpload.name} src={this.state.fileUploadBase64} />
                </div>
            )
            // }
        }
        if (this.state.form.imgLink) {
            return (
                <div className="thumbnail">
                    <div className="close">
                        <CancelIcon onClick={this.removeImage} />
                    </div>
                    <img alt={""} src={this.getImageByUrl(this.state.form.imgLink)} />
                </div>
            )
        }
        else {
            return (
                <div>
                    <Dropzone onDrop={this.onDrop}>
                        {({ getRootProps, getInputProps, isDragActive }) => (
                            <div className="dropzone-container">
                                <div className="drag-drop-label">
                                    <div className="icon">
                                        <PublishIcon />
                                    </div>
                                    <div className="label">{translations.DRAG_AND_DROP_FILES}</div>
                                    <div className="or">{translations.OR}</div>
                                </div>
                                <div className="dropzone" {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Button color="primary" variant="contained">{translations.BROWSE}</Button>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                    <div className="file-format">{translations.FORMAT_PNG_OR_JPG}</div>
                </div>
            )
        }
    }

    componentDidUpdate = () => {
        let { translations } = this.state
        if (this.props.translation && this.props.translation.translations.timeStamp != this.state.translationTimestamp) {
            this.setState({
                translationTimestamp: this.props.translation.translations.timeStamp,
                translations: Object.assign({}, { ...this.convert() }),
                title: this.props.match.params.id ? this.convert().EDIT_ATTACHMENT : this.convert().ADD_ATTACHMENT,
                saveLabel: this.props.match.params.id ? this.convert().SAVE : this.convert().ADD
            })
        }
        if (this.state.hasFormChanges) {
            window.onbeforeunload = () => { return translations.are_you_sure_to_leave_this_page }
        }
        else {
            window.onbeforeunload = undefined
        }
    }

    componentWillUnmount() {
        document.body.style.backgroundColor = null;
        // this.resetForm();
        if (this.state.hasFormChanges) {
            window.onbeforeunload = undefined
        }
    }

    handleLinkClick = (url) => {
        if (this.state.id) {
            history.push(url);
        }
        else {
            this.setState({ disablePrompt: true }, () => {
                history.push(url);
            })
        }
    }


    render() {
        let {translations} = this.state
        return (
            <MainPage footer={false} className='attachment-form'>
                <Prompt
                    when={this.state.hasFormChanges && this.state.disablePrompt === false}
                    message={translations.are_you_sure_to_leave_this_page}
                />
                { this.checkAttachmentTrigger()}
                <MainPageHeader title="Attachment" search={false} />
                <MainPageBody>
                    <div className="form-container">
                        <Paper className="body">
                            <div className="title">{this.state.title}</div>
                            <div className="form-input">
                                <form autoComplete="off">
                                    <FormControl className="control-row" fullWidth>
                                        <TextField className={this.state.form.code ? 'label-green' : null} disabled={this.state.id} required error={this.hasErrors('code')} value={this.state.form.code} onChange={this.handleCodeFormChange} name="code" placeholder={translations.SHORT_NAME_FOR_SYSTEM_DISPLAY} label={translations.ATTACHMENT_CODE} InputLabelProps={{ shrink: true }} />
                                    </FormControl>
                                    <FormControl className="control-row" fullWidth>
                                        <TextField className={this.state.form.name ? 'label-green' : null} required error={this.hasErrors('name')} value={this.state.form.name} onChange={this.handleFormChange} name="name" placeholder={translations.SHORT_NAME_FOR_SYSTEM_DISPLAY} label={translations.DISPLAY_NAME} InputLabelProps={{ shrink: true }} />
                                    </FormControl>
                                    <FormControl className="control-row" fullWidth>
                                        <TextField className={this.state.form.desc ? 'label-green' : null} error={this.hasErrors('desc')} value={this.state.form.desc} onChange={this.handleFormChange} name="desc" label={translations.DESCRIPTION} InputLabelProps={{ shrink: true }} />
                                    </FormControl>
                                    {this.renderFormFile()}
                                </form>
                            </div>
                        </Paper>
                        {this.state.id ? this.renderStatusForm() : null}
                    </div>
                    <div className="footer-actions">
                        <Grid container spacing={1}>
                            <Grid item xs={5} />
                            <Grid item xs={1}>
                                {/* <Link to="/configuration/machine-config/attachment"> */}
                                <Button onClick={() => this.handleLinkClick('/configuration/machine-config/attachment')} className="cancel" fullWidth variant="outlined">{translations.CANCEL}</Button>
                                {/* </Link> */}
                            </Grid>
                            <Grid item xs={1}>
                                <Button onClick={this.submitForm} className="save" fullWidth color="primary" variant="contained">{this.state.saveLabel}</Button>
                            </Grid>
                            <Grid item xs={5} />
                        </Grid>
                    </div>
                </MainPageBody>
            </MainPage>
        );
    }
}

const mapStateToProps = state => {
    let { translationReducer } = state
    let apiLoading = {
        detailLoading: state.attachmentListReducer.detailLoading
    }
    let data = {
        attachmentDetail: state.attachmentListReducer.attachmentDetail,
        triggerHighlight: state.attachmentListReducer.triggerHighlight
    }

    return {
        ...apiLoading,
        ...data,
        ...translationReducer

    }
}

const mapDispatchToProps = dispatch => {
    return {
        notificationError: (message) => dispatch(notificationError(message)),
        attachmentTriggerHighlight: (flag) => dispatch(attachmentTriggerHighlight(flag)),
        getAttachmentDetail: (id) => dispatch(getAttachmentDetail(id)),
        postAttachmentNew: (payload) => dispatch(postAttachmentNew(payload)),
        putAttachmentUpdate: (id, payload) => dispatch(putAttachmentUpdate(id, payload)),
        postUploadAttachment: (payload, file, method, id) => dispatch(postUploadAttachment(payload, file, method, id)),
    }
}

AttachmentForm = connect(mapStateToProps, mapDispatchToProps)(AttachmentForm);

export default AttachmentForm;
