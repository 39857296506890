import React from 'react';
import { connect } from 'react-redux';
import Table from '../../../../../components/CollapsableTable/CollapseTable';
import history from '../../../../../history';
import { confirmAlert } from 'react-confirm-alert';
import { getListSupplier, detailSupplier, setUpdatedToFalse, deleteSupplier } from '../../../../../redux/actions/Supplier/supplierActions'
import dictionary from '../../../../../redux/actions/Translation/dictionary'
import { checkPageAccessField } from '../../../../../utils/helper';
import Add from './Add';
import Update from './Update';

class Supplier extends React.Component {
    constructor(prop) {
        super(prop)
        this.state = {
            isCreating: false,
            isUpdating: false,
            updatingSupplier: [],
            translations: {
                ...this.convert()
            },
            translationTimestamp: undefined,
            tableState: {
                add: () => {
                    this.setState({ isCreating: true });
                },
                edit: ( props ) => {
                    const supplierId = props.id;
                    this.props.detailSupplier(supplierId)
                        .then((supplierDetails) => {
                            this.setState({
                                updatingSupplier: supplierDetails,
                                isUpdating: true,
                            });
                        })
                        .catch((error) => {
                            console.error('Error fetching supplier details:', error);
                        });
                },
                remove:({ id }) => {
                    confirmAlert({
                        title: this.state?.translations?.are_you_sure_delete_supplier,
                        message: '',
                        buttons: [
                            {
                                label: this.state?.translations?.YES,
                                onClick: () => {
                                    this.props.deleteSupplier(id);
                                },
                            },
                            {
                                label: this.state?.translations?.NO
                            }
                        ]
                    });
                    this.props.getListSupplier(false);
                },
                search: true,
                highlight: true,
                keys: this.initTableHeader(),
                data: [],
                loading: false
            }
        }
    }

    initTableHeader = () => {
        let { STATUS, SUPPLIER_CODE, DISPLAY_NAME, DESCRIPTION, MODIFIED_BY, DATE_MODIFIED, DATE_CREATED, CREATED_BY } = this.convert()
        return [
            {
                name: SUPPLIER_CODE,
                key: 'supplier',
                tableHeaderWidth: '12%'

            },
            {
                name: DISPLAY_NAME,
                key: 'desc1',
                tableHeaderWidth: '15%'

            },
            {
                name: DESCRIPTION,
                key: 'desc2',
                tableHeaderWidth: '20%'

            },
            {
                name: STATUS,
                type: 'boolean',
                key: 'isActive',
                tableHeaderWidth: '10%'

            },
            {
                name: MODIFIED_BY,
                key: 'updatedBy',
                tableHeaderWidth: '10%',
            },
            {
                name: DATE_MODIFIED,
                type: 'date',
                key: 'updatedDt',
                tableHeaderWidth: '10%',
            },
            {
                name: CREATED_BY,
                key: 'createdBy',
                tableHeaderWidth: '10%',
            },
            {
                name: DATE_CREATED,
                type: 'date',
                key: 'createdDt',
                tableHeaderWidth: '10%',
            },
        ]
    }
    convert = () => {
        let { 
            STATUS, 
            SUPPLIER_CODE, 
            DISPLAY_NAME, 
            DESCRIPTION,
            MODIFIED_BY, 
            DATE_MODIFIED, 
            DATE_CREATED, 
            CREATED_BY, 
            YES, 
            NO, 
            NOTIFICATIONS: {are_you_sure_delete_supplier}  
        } = dictionary.GET()
        return dictionary.TRANSLATE({ 
            STATUS, 
            SUPPLIER_CODE, 
            DISPLAY_NAME, 
            DESCRIPTION, 
            MODIFIED_BY, 
            DATE_MODIFIED, 
            DATE_CREATED,
            CREATED_BY,
            YES, 
            NO, 
            are_you_sure_delete_supplier
        })
    }
    componentDidMount() {
        this.props.getListSupplier();
    }

    componentDidUpdate() {
        const { loading, supplierList, translation } = this.props;
        const { translations } = translation;
        const { timeStamp } = translations;
        const { tableState, translationTimestamp } = this.state;
        const { data } = tableState;
        const localStateLoading = tableState.loading;
        if ( loading != localStateLoading || supplierList?.length != data?.length) {
            if( Array.isArray(supplierList)){
                let localTableState = tableState;
            if ( checkPageAccessField(this.props.userAccess,{pageId:28,pageAccess: 'VIEW'})){
              localTableState = {
                add: null,
                edit: null,
                remove: null
              }
            }
            this.setState({
              tableState: {
                ...localTableState,
                data: [...supplierList],
                loading,
                timeStamp: new Date()
              },
            })
          }
        }
        if (translation && timeStamp != translationTimestamp) {
          this.setState({
            translationTimestamp: timeStamp,
            translations: Object.assign({}, { ...this.convert() }),
            tableState: {
                ...tableState,
                keys: this.initTableHeader(),
                timeStamp: new Date()
            }
          })
        }
      }

    componentWillUnmount() {
    }

    /**render */
    render() {
        return (
            <div className="supplier-body-overview">
                {this.state.isCreating && 
                     <Add
                        open={this.state.isCreating}
                        onClose={() => this.setState({isCreating: false})} 
                     />
                }
                {this.state.isUpdating && 
                    <Update
                        open={this.state.isUpdating}
                        supplier={this.state.updatingSupplier}
                        onClose={() => this.setState({isUpdating: false, isUpdated: true})}
                    />
                }
                <Table {...this.state.tableState} />
            </div>
        );
    }

}

const mapStateToProps = state => {
    let { supplierListReducer, translationReducer } = state
    let apiLoading = {
    }
    let data = {
        userAccess: state.authReducer.access,
    }

    return {
        ...apiLoading,
        ...data,
        ...supplierListReducer,
        ...translationReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setUpdatedToFalse: () => dispatch(setUpdatedToFalse()),
        getListSupplier: () => dispatch(getListSupplier()),
        detailSupplier: (supplierId) => dispatch(detailSupplier(supplierId)),
        deleteSupplier: (id, payload) => dispatch(deleteSupplier(id, payload)),
    }
}

Supplier = connect(mapStateToProps, mapDispatchToProps)(Supplier);

export default Supplier;