import React, { Fragment, useState } from 'react';
import {
  Typography,
  Tooltip,
  Link,
  Box,
  withStyles,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import { deserializeDetail } from '../../helpers';
import { machineClient } from '../../../../../utils/api/CoreApi';

const Title = withStyles({
  root: {
    color: "#1689FA",
    fontSize: 16,
    '&  a': {
      color: 'inherit'
    }
  }
})(Typography)

const Text = withStyles({
  root: {
    fontSize: 14
  }
})(Typography);

const CustomDivider = withStyles({
  root: {
    backgroundColor: "#fff",
    height: 2,
  }
})(Divider);

const JustifyWrapper = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  }
})(Box);

const CustomTooltip = withStyles({
  tooltip: {
    width: 256,
    padding: 16,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  arrow: {
    '&::before': {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    }
  },
  tooltipPlacementLeft: {
    margin: 0,
    marginRight: -10,
    marginTop: -10,
  },
  tooltipPlacementRight: {
    margin: 0,
    marginLeft: -10,
    marginTop: -10,
  }
})(Tooltip);


const countMachines = (machines) => {
  return machines.reduce((counter, machine) => {
    if(!counter[machine.machineSubType]){
      counter[machine.machineSubType] = 0;
    }
    counter[machine.machineSubType] += 1;
    return counter;
  }, {}); // 6
}

const Content = ({ changeover }) => {
  if(!changeover){
    return <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </Box>
  }

  const {
    id,
    code,
    coDate,
    style,
    type,
    countStations,
    machines,
  } = changeover;

  const criticalMachines = machines.filter((m)=> m.isCritical)
  const machinesCount = countMachines(machines);
  const criticalCount = countMachines(criticalMachines);

  return (
    <Fragment>
      <Title variant="h4">
        <Link href={`/changeover-detail/${id}`}>{code}</Link>
      </Title>
      <Text>
        {coDate.format("MMM DD, YYYY")}
      </Text>

      <Box mt={2} />

      <Text>
        Style: {style}
      </Text>
      <Text>
        Changeover Type: {type}
      </Text>
      <Text>
        Stations: {countStations}
      </Text>

      <Box mt={2} />
      <JustifyWrapper>
        <Text variant='subtitle2'>
          Total Machines:
        </Text>
        <Text variant='subtitle2'>
          { machines.length }
        </Text>
      </JustifyWrapper>

      <CustomDivider/>
      {
        Object.entries(machinesCount).map(([machineSubType, count])=>(
          <JustifyWrapper key={machineSubType}>
            <Text>
              {machineSubType}:
            </Text>
            <Text>
              {count}
            </Text>
          </JustifyWrapper>
        ))
      }


      <Box mt={2} />

      <JustifyWrapper>
        <Text variant='subtitle2'>
          Critical Machines:
        </Text>
        <Text variant='subtitle2'>
          { criticalMachines.length }
        </Text>
      </JustifyWrapper>
      <CustomDivider/>

      {
        Object.entries(criticalCount).map(([machineSubType, count])=>(
          <JustifyWrapper key={machineSubType}>
            <Text>
              {machineSubType}:
            </Text>
            <Text>
              {count}
            </Text>
          </JustifyWrapper>
        ))
      }
    </Fragment>
  );
};

export default function ChangeoverTooltip({
  open,
  onOpen,
  onClose,
  placement,
  changeover,
  children,
}) {
  const [data, setData] = useState(null);

  const handleOpen = () => {
    const hasMachines = Boolean(changeover.machines)
    if(hasMachines){
      setData(changeover)
    }else{
      machineClient.get(`/co-requests/${changeover.id}`).then(({ data })=>{
        const { corequest } = data;
        const normalizedData = deserializeDetail(corequest);
        setData(normalizedData);
      });
    }
    onOpen()
  }


  if(!changeover.id) {
    return children;
  }

  return (
    <CustomTooltip
      arrow
      interactive
      title={<Content changeover={data} />}
      onOpen={handleOpen}
      onClose={onClose}
      open={open}
      placement={placement}
    >
      {children}
    </CustomTooltip>
  );
}
