import { Grid } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import DataSelect from '../DataSelect';
export default function RenderFilter({
    buildings,
    buildingId,
    areas,
    areaId,
    totalLines,
    filterDisabled,
    handleChangeBuilding,
    handleChangeArea,
  }){
    return (
      <Grid item xs>
        <Grid container spacing={0}>
            <Grid item xs={4}></Grid>
            <Grid item xs={12}>
                <Grid className='search-container search-no-padding' container spacing={0}>
                    <Grid item xs={2} />
                    <Grid item xs={3}>
                          <DataSelect 
                                title='BUILDINGS'
                                filterDisabled={filterDisabled}
                                items={buildings.map((i, k) => <option key={k} value={i.id}> {i.name.translate()} </option>) }
                                selectedId={buildingId}
                                handleChange={handleChangeBuilding}
                              />
                    </Grid>
                    <Grid item xs={4}>
                          <DataSelect 
                            title={`${totalLines} LINES`}
                            filterDisabled={filterDisabled}
                            items={ areas?.map((i, k) => <option key={k} value={i.areaId}> {i.areaName.translate()} </option> ) }
                            selectedId={areaId}
                            handleChange={handleChangeArea}
                          />
                    </Grid>
                    <Grid item xs={2}>
                      {areaId && (
                        <Link 
                          className={'line-status-tv-mode'} 
                          to={`/line-status/tv-mode/${areaId}`}>
                            {'TV MODE'.translate()}
                        </Link>
                      )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    );
  }