import moment from 'moment';
import { formatDate } from '../../../utils/date';
import { getMaintenanceStatus } from '../../../utils/helper';
import {
    TICKET_LIST,
    TICKET_LIST_UPDATE_TOTAL,
    TICKET_LIST_UPDATE_PAGE,
    TICKET_LIST_UPDATE_DISPLAYED_ROW,
    TICKET_LIST_REQUEST,
    TICKET_LIST_SUCCESS,
    TICKET_LIST_SEARCH_REQUEST,
    TICKET_LIST_SEARCH_SUCCESS,
    TICKET_LIST_CHECKBOX_TOGGLE,
    MAINTENANCE_LIST_CHECKBOX_TOGGLE,
    TICKET_LIST_CHECKBOX_ALL,
    TICKET_LIST_ERROR,
    TICKET_LIST_SHOW_TOGGLE,
    TICKET_LIST_CLOSE_TOGGLE,
    MAINTENANCE_LIST_UPDATE_DISPLAYED_ROW,
    MAINTENANCE_LIST_SHOW_TOGGLE,
    MAINTENANCE_LIST_CLOSE_TOGGLE,
    TICKET_TYPE_LIST_REQUEST,
    TICKET_TYPE_LIST_SUCCESS,
    TICKET_TYPE_LIST_ERROR,
    TICKET_DETAIL_REQUEST,
    TICKET_DETAIL_SUCCESS,
    ASSET_DETAIL_REQUEST,
    ASSET_DETAIL_SUCCESS,
    TICKET_DETAIL_ERROR,
    TICKET_NEW_ITEM_REQUEST,
    TICKET_NEW_ITEM_SUCCESS,
    TICKET_MAINTENANCE_ITEM_REQUEST,
    TICKET_MAINTENANCE_ITEM_SUCCESS,
    TICKET_NEW_ITEM_ERROR,
    TICKET_MAINTENANCE_HISTORY_REQUEST,
    TICKET_MAINTENANCE_HISTORY_SUCCESS,
    TICKET_MAINTENANCE_HISTORY_ERROR,
    TICKET_REPAIR_HISTORY_REQUEST,
    TICKET_REPAIR_HISTORY_SUCCESS,
    TICKET_REPAIR_HISTORY_ERROR,
    TICKET_UPDATE_REQUEST,
    TICKET_UPDATE_SUCCESS,
    TICKET_CANCEL_REQUEST,
    TICKET_CANCEL_SUCCESS,
    TICKET_MACHINE_UP,
    TICKET_MACHINE_UP_UPDATE_TOTAL,
    TICKET_MACHINE_UP_UPDATE_PAGE,
    TICKET_MACHINE_UP_UPDATE_DISPLAYED_ROW,
    TICKET_MACHINE_UP_REQUEST,
    TICKET_MACHINE_UP_SUCCESS,
} from '../../actions/actionTypes';

const initialState = {
    loading: '',
    loadingHistory: false,
    loadingType: false,
    loadingMachineUp: '',
    loadingDetail: '',
    assetLoading:'',
    searchString: null,
    selectedRow: {
        id: null,
        showToggle: false
    },
    maintenanceSelectedRow: {
        id: null,
        showToggle: false
    },
    rows: 0,
    currentPage: 0,
    displayedRows: 0,
    maintenanceDisplayedRows: 0,
    tickets:[],
    machineUpList:[],
    machineUpFilter: 'today',
    machineUpCount: {
        all: 0,
        week: 0,
        month: 0,
        today: 0,
        overdue: 0,
    },
    assetDetail:[],
    checklistDetail: [],
    ticketDetail: {
        ticketTypeId: null
    },
    ticketTypes:[],
    maintenanceHistory: [],
    repairHistory: [],
    meta: [],
    error: ''
}

const ticketListReducer = (state = initialState, action) => {
    switch (action.type) {
        case TICKET_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case TICKET_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                rows: action.payload.tickets.length,
                currentPage: 0,
                tickets: action.payload.tickets,
                meta: action.payload.meta,
                error: ''
            }
        case TICKET_LIST_SEARCH_REQUEST:
            return {
                ...state,
                loading: true
            }
        case TICKET_LIST_SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                excel: action.payload.excel,
                rows: action.payload.tickets.length,
                currentPage: 0,
                tickets: action.payload.tickets,
                searchString: action.payload.searchString,
                meta: action.payload.meta,
                error: ''
            }
        case TICKET_LIST_ERROR:
            return {
                ...state,
                loading: false,
                rows: 0,
                currentPage: 0,
                tickets: [],
                meta: [],
                error: action.payload.message
            }
        case TICKET_LIST_UPDATE_TOTAL:
            return {
                ...state,
                rows: action.payload.rows,
            }
        case TICKET_LIST_UPDATE_PAGE:
            return {
                ...state,
                currentPage: action.payload,
            }
        case TICKET_LIST_UPDATE_DISPLAYED_ROW:
            return {
                ...state,
                displayedRows: action.payload,
            }
        case TICKET_NEW_ITEM_REQUEST:
            return {
                ...state,
                loading: true
            }
        case TICKET_NEW_ITEM_SUCCESS:
            return {
                ...state,
                loading: false
            }
            case TICKET_MAINTENANCE_ITEM_REQUEST:
                return {
                    ...state,
                    loading: true
                }
            case TICKET_MAINTENANCE_ITEM_SUCCESS:
                return {
                    ...state,
                    loading: false
                }
        case TICKET_MAINTENANCE_HISTORY_REQUEST:
            return {
                ...state,
                loadingHistory: true
            }
        case TICKET_MAINTENANCE_HISTORY_SUCCESS:
            return {
                ...state,
                maintenanceHistory: action.payload,
                loadingHistory: false
            }
        case TICKET_REPAIR_HISTORY_REQUEST:
            return {
                ...state,
                loadingHistory: true
            }
        case TICKET_REPAIR_HISTORY_SUCCESS:
            return {
                ...state,
                repairHistory: action.payload,
                loadingHistory: false
            }
        case TICKET_TYPE_LIST_REQUEST:
            return {
                ...state,
                loadingType: true
            }
        case TICKET_TYPE_LIST_SUCCESS:
            return {
                ...state,
                ticketTypes: action.payload.ticketTypeList,
                loadingType: false
            }
        case TICKET_DETAIL_REQUEST:
            return {
                ...state,
                loadingDetail: true
            }
        case TICKET_DETAIL_SUCCESS:
            const ticket = action.payload.tickets[0];
            return {
                ...state,
                ticketDetail: {
                    ...ticket,
                    nextMaintDate: formatDate(ticket.nextMaintDate, 'YYYY-MM-DD'),
                    maintenanceStatus: getMaintenanceStatus(ticket),
                },
                checklistDetail: action.payload.checkist,
                loadingDetail: false
            }
            case ASSET_DETAIL_REQUEST:
                return {
                    ...state,
                    assetLoading: true,
                }
            case ASSET_DETAIL_SUCCESS:
                return {
                    ...state,
                    assetDetail: action.payload,
                    assetLoading: false
                }
        case TICKET_UPDATE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case TICKET_UPDATE_SUCCESS:
            let tickets = action.payload.data;
            let data = state.tickets.map((item) => {
                let row = tickets.filter((result) => { return result.no === item.ticketNo.value });
                if (row.length > 0){    
                    return {
                        ...item,
                        'selected': {
                            ...item.selected,
                            value: false
                        },
                        'status': state.ticketDetail.status,
                        'grabbedBy': state.ticketDetail.grabbedBy,
                        'repairedBy': state.ticketDetail.repairedBy,
                        'closedBy': state.ticketDetail.closedBy,
                    }
                }
                else{
                    return {
                        ...item,
                        selected: {
                            ...item.selected,
                            value: false
                        }
                    };
                }
            })

            let excelData = state.excel.map((item) => {
                let row = tickets.filter((result) => { return result.no === item.ticketNo });
                if (row.length > 0){
                    let condition = '';
                    if (action.payload.statusId === 9 || action.payload.statusId === 15){
                        condition = 'WORKING';
                    }
                    else{
                        condition = 'IN-PROCESS';
                    }
                    return {
                        ...item,
                        'status': state.ticketDetail.status,
                        'grabbedBy': state.ticketDetail.grabbedBy,
                        'repairedBy': state.ticketDetail.repairedBy,
                        'closedBy': state.ticketDetail.closedBy,
                        'conditionId_desc': condition,
                    }
                }
                else{
                    return {
                        ...item,
                    };
                }
            })

            return {
                ...state,
                tickets: data,
                excel: excelData,
                loading: false,
            }
        case TICKET_CANCEL_REQUEST:
            return {
                ...state,
                loading: true
            }
        case TICKET_CANCEL_SUCCESS:
            let cancelTickets = action.ticketNo;
            let cancelData = state.tickets.map((item) => {
                let row = cancelTickets.filter((result) => { return result.no === item.ticketNo.value });
                if (row.length > 0){
                    return {
                        ...item,
                        'selected': {
                            ...item.selected,
                            value: false
                        },
                        'status': 'CANCELLED',
                    }
                }
                else{
                    return {
                        ...item,
                        selected: {
                            ...item.selected,
                            value: false
                        }
                    };
                }
            })

            return {
                ...state,
                tickets: cancelData,
                loading: false,
            }
        case TICKET_LIST_CHECKBOX_TOGGLE:
            return {
                ...state,
                tickets: action.payload,
            }
        case TICKET_LIST_SHOW_TOGGLE:
            return {
                ...state,
                selectedRow: {
                    id: action.payload.id,
                    showToggle: action.payload.flag
                }
            }
        case TICKET_LIST_CLOSE_TOGGLE:
            return {
                ...state,
                selectedRow: {
                    id: null,
                    showToggle:false
                }
            }
            case MAINTENANCE_LIST_UPDATE_DISPLAYED_ROW:
                return {
                    ...state,
                    maintenanceDisplayedRows: action.payload,
                }
            case MAINTENANCE_LIST_SHOW_TOGGLE:
                return {
                    ...state,
                    maintenanceSelectedRow: {
                        id: action.payload.id,
                        showToggle: action.payload.flag
                    }
                }
            case MAINTENANCE_LIST_CLOSE_TOGGLE:
                return {
                    ...state,
                    maintenanceSelectedRow: {
                        id: null,
                        showToggle:false
                    }
                }
                case MAINTENANCE_LIST_CHECKBOX_TOGGLE:
                    return {
                        ...state,
                        machineUpList: action.payload,
                    }
        case TICKET_MACHINE_UP_REQUEST:
            return {
                ...state,
                loadingMachineUp: true
            }
        case TICKET_MACHINE_UP_SUCCESS:
            return {
                ...state,
                loadingMachineUp: false,
                rows: action.payload.machineUpList.length,
                currentPage: 0,
                machineUpList: action.payload.machineUpList,
                machineUpFilter: action.payload.machineUpFilter,
                machineUpCount: action.payload.machineUpCount,
                searchString: action.payload.searchString,
                meta: action.payload.meta,
                error: ''
            }
        case TICKET_MACHINE_UP_UPDATE_PAGE:
            return {
                ...state,
                currentPage: action.payload,
            }
        case TICKET_MACHINE_UP_UPDATE_DISPLAYED_ROW:
            return {
                ...state,
                displayedRows: action.payload,
            }
        default: 
            return state;
    }
}

export default ticketListReducer;