import { TableCell } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export default withStyles({
  root: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 16,
    paddingRight: 16,
    background: '#D2D2D2 0% 0% no-repeat padding-box',
  }
})(TableCell);