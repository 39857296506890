import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { styles, stepper } from './Bulkupload.style';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import PublishIcon from '@material-ui/icons/Publish';
import { apiSettings } from '../../../utils/api/CoreApi';
import {
    Button,
    Typography,
    Stepper,
    Step,
    StepLabel,
} from '@material-ui/core';

export default function BulkUpload(props) {
    const classes = styles();
    const stepperClass = stepper();
    const { handleClose, openBulkUpload, onFinish } = props;
    const [fileName, setFileName] = React.useState('');
    const [fileData, setFileData] = React.useState('');
    const [apiError, setAPIError] = React.useState('');
    const [validateResponseData, setValidateResponseData] = React.useState('');
    const [firstStepLoader, setFirstStepLoader] = React.useState(false);
    const [secondStepLoader, setSecondStepLoader] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [isTemplateLoading, setTemplateLoading] = React.useState(false);
    const [isChangeoverLoading, setChangeoverLoading] = React.useState(false);
    const uploadButtonRef = React.useRef(0);

    useEffect(() => {
        setActiveStep(0);
        setFileName('');
        setFileData('');
        setAPIError('');
        setValidateResponseData('');
        setSkipped(new Set());
    }, [openBulkUpload]);

    const droppedFiles = (acceptedFiles, rejectedFiles) => {
        const file = acceptedFiles[0];
        if (
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'text/csv' || 
        file.type === 'application/vnd.ms-excel'
        ) {
        setFileName(file.name);
        setFileData(file);
        }
    }

    const handleDownload = async (url, fileName) => {
        const res = await axios({
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('userToken').replace(/^"(.+)"$/,'$1')}`,
            'language': localStorage.getItem('selectedLanguage'),
        },
        method: 'post',
        url: `${apiSettings}${url}`, // To Be Changed
        responseType: 'blob'
        })
    
        const blob = new Blob([res.data], {
        type: res.headers['content-type'],
        })

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${fileName}`;
        link.click();
        setChangeoverLoading(false);
        setTemplateLoading(false);
    }

    const handleDownloadChangeoverList = async () => {
        handleDownload();
    }

    const downloadChangeoverList = () => {
        setChangeoverLoading(true);
        handleDownloadChangeoverList();
    }
    
    const handleDownloadTemplate = async () => {
        handleDownload();
    }

    const downloadTemplate = () => {
        setTemplateLoading(true);
        handleDownloadTemplate();
    }
    
    const resetInput = () => {
        setFileName('');
        setFileData('');
        handleClose();
    }

    const UploadFileContentStep = () => {
        return (
            <div>
                <div className={classes.downloadText}>
                    <span onClick={downloadTemplate}>{'Download Template File'.translate()} {isTemplateLoading && <span className={classes.downloading}>{'Downloading'.translate()}...</span>}</span>
                </div>
                <div className={classes.downloadText}>
                    <span onClick={downloadChangeoverList}>{'Download Latest Changeover List'.translate()} {isChangeoverLoading && <span className={classes.downloading}>{'Downloading'.translate()}...</span>}</span>
                </div>
                <div>
                    <Dropzone onDrop={(acceptedFiles, rejectedFiles) => { droppedFiles(acceptedFiles, rejectedFiles) }} noClick>
                        {({ getRootProps, getInputProps }) => (
                        <div className={classes.dropImagesContainer} {...getRootProps()}>
                            {fileName ? ( <span className={classes.fileStyle}>{fileName}</span> ) : (
                                <>
                                    <div className={classes.dropIcon}>
                                        <PublishIcon />
                                    </div>
                                    <div className={classes.dropDescription}>
                                        {'Drag Drop Files'.translate()}
                                    </div>
                                    <div className={classes.orBox}>{'or'.translate()}</div>
                                </>
                            )}
                            <input {...getInputProps()} type='file' id='profil' style={{ display: 'none' }} ref={uploadButtonRef}
                                accept='text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                multiple
                            />
                            {fileName ? (
                                <>
                                    {apiError !== '' && (
                                        <div className={classes.apiValidateMessage}>
                                            {apiError}
                                        </div>
                                    )}
                                    <Button color='primary' size='large' variant='text' className={classes.reselectFile} onClick={() => { uploadButtonRef.current.click(); }}>
                                        {'Reselect file'.translate()}
                                    </Button>
                                </>
                            ) : (
                                <Button color='primary' size='large' variant='contained' className={classes.browseButton} onClick={() => { uploadButtonRef.current.click() }}>
                                    {'Browse'.translate()}
                                </Button>
                            )}  
                        </div>
                        )}
                    </Dropzone>
                    <div className={classes.imageNote}>
                        {'Format: .xlsx / .xls or .csv'.translate()}
                    </div>
                    <div className={classes.saveContainer}>
                        <Button variant='outlined' size='large' className={classes.cancelButton} onClick={resetInput}>
                            {'CANCEL'.translate()}
                        </Button>
                        <Button color='primary' disabled={fileName.length > 0 && !firstStepLoader ? false : true} size='large' type='submit' variant='contained' className={classes.saveButton} onClick={() => { handleValidateFile() }}>
                            {firstStepLoader ? 'Validating'.translate() : 'Validate'.translate()}
                        </Button>
                    </div>
                </div>
            </div>
        )
    };

    const ValidateFileContentStep = () => {
        return (
            <div>
                <div className={classes.fileName}>“{fileName}”</div>
                <div className={classes.validateMessage}> {'Will import'.translate()} {` ${validateResponseData.dataToInsert.length} `}{' '} {'Changeover Requests'.translate()}
                    <div>
                        {'Including'.translate()} {`  ${validateResponseData.dataToUpdate.length} `}
                        {'Existing Changeover Requests'.translate()}
                    </div>
                    <div>{'Above Information will be updated.'.translate()}</div>
                </div>
                <div className={classes.pleaseConfirmText}>{'Please Confirm'.translate()}</div>
                <div className={classes.saveContainer}>
                    <Button variant='outlined' size='large' className={classes.cancelButton} disabled={!secondStepLoader ? false : true} onClick={() => { handleClose() }}>
                        {'CANCEL'.translate()}
                    </Button>
                    <Button color='primary' size='large' type='submit' variant='contained' disabled={secondStepLoader} className={classes.saveButton} onClick={() => { handleBulkUpload() }}>
                        {secondStepLoader ? 'Uploading'.translate() : 'ok'.translate()}
                    </Button>
                </div>
            </div>
        )
    }

    const UploadSuccessFileContentStep = () => {
        return (
        <div>
            <div className={classes.filenameUpdated}>{fileName}</div>
            <div className={classes.listUpdated}>{'is uploaded successfully.'.translate()}</div>
            <div className={classes.saveContainer}>
                <Button color='primary' size='large' type='submit' variant='contained' className={classes.saveButton} onClick={() => { handleClose(); onFinish() }}>
                    {'ok'.translate()}
                </Button>
            </div>
        </div>
        );
    }

    function getStepContent(step) {
        switch (step) {
        case 0:
            return <UploadFileContentStep />;
        case 1:
            return <ValidateFileContentStep />;
        case 2:
            return <UploadSuccessFileContentStep />;
        default:
            return <UploadSuccessFileContentStep />;
        }
    }

    const steps = ['Select File', 'Validate File', 'Upload Success'];

    const isStepOptional = (step) => {
        return step === 1;
    }

    const isStepSkipped = (step) => {
        return skipped.has(step);
    }

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    }
    
    let dataUpload = new FormData();
    let uploadingData = fileData;
    dataUpload.append('machines', uploadingData); // To Be Changed
    let dataToSend = dataUpload;

    const handleValidateFile = async () => {
        setAPIError('');
        await axios
            .post(``, dataToSend, { // To Be Changed
                method: 'POST',
                headers: {
                    'Content-Type': 'text/csv',
                    Authorization:`Bearer ${localStorage.getItem('userToken').replace(/^"(.+)"$/,'$1')}`,
                },
            })
            .then((res) => {
                setValidateResponseData(res.data.result);
                handleNext();
            })
            .catch((err) => {
                if (err.response.data.message != null || err.response.data.message != undefined){
                    setAPIError(err.response.data.message);
                } else {
                    setAPIError(err.response.data.errorType);
                }
            });
    }

    const handleBulkUpload = async () => {
        await axios
        .post(``, dataToSend, {  // To Be Changed
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('userToken').replace(/^"(.+)"$/,'$1')}`,
            },
        })
        .then((res) => {
            handleNext();
        })
        .catch((err) => console.log(err));
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby='simple-dialog-title' open={openBulkUpload} fullWidth>
            <DialogContent>
                <Typography className={classes.textStyl}>{'BULK UPLOAD'.translate()}</Typography>
                <div className={classes.root}>
                    <Stepper className={stepperClass.root} activeStep={activeStep} alternativeLabel>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepOptional(index)) {
                                labelProps.optional = (
                                <Typography variant='caption'></Typography>
                                );
                            }
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label.translate()}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {getStepContent(activeStep)}
                </div>
            </DialogContent>
        </Dialog>
    )
}
