export const required = value => value !== null && value !== '' ? undefined : 'Required'
export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
export const maxLength50 = maxLength(50)
export const noSpecialCharacters = value => value && !/^[A-Za-z0-9\-\s]+$/i.test(value.trim()) ? 'Must be letters only' : undefined
export const number = value => value && isNaN(Number(value)) && !/^[0-9]*$/i.test(value) ? 'Must be a number' : undefined
export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
export const minValue0 = minValue(0)
export const maxValue = max => value =>
  value && value > max ? `Max of ${max}` : undefined
export const maxValue80 = maxValue(80)
export const email = value => 
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
  'Invalid email address' : undefined
export const alphanumeric = value => value && /[=><]/.test(value.trim()) ? 'Must be letters only' : undefined



export const validateProblemFields = (form) => {
    let formValid = true;
    let errors = [];

    if (required(form.problemType) !== undefined){
        formValid = false;
        errors.push({name:'problemType',message:'Please fill up all required fields'});
    }

    if (alphanumeric(form.problemType) !== undefined){
        formValid = false;
        errors.push({name:'problemType',message:'The following special characters are not allowed: = < >'});
    }

    if (required(form.desc1) !== undefined){
        formValid = false;
        errors.push({name:'desc1',message:'Please fill up all required fields'});
    }

    if (alphanumeric(form.desc1) !== undefined){
        formValid = false;
        errors.push({name:'desc1',message:'The following special characters are not allowed: = < >'});
    }

    if(form.problemType && form.problemType.length > 35){
      formValid = false;
      errors.push({name:'problemType',message:'Maximum character limit is 35'});
    }

    if(form.desc1 && form.desc1.length > 250){
      formValid = false;
      errors.push({name:'desc1',message:'Maximum character limit is 250'});
    }

    return {
        valid: formValid,
        errors: errors
    }
}