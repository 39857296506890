import React, { useEffect } from 'react';
import { checkIfApiLoading, checkPageActionAccess,checkPageAccess } from '../../../../../utils/helper';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import history from '../../../../../history';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import TransitionsModal from './attachModal'
import Button from '@material-ui/core/Button';
import { formatDate } from '../../../../../utils/date';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}



function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



const headCells = [
  { id: 'checkListNo', className:'checkListNo', numeric: false, disablePadding: true, label: 'TEMPLATE NO' },
  { id: 'templateName', className:'templateName', numeric: false, disablePadding: true, label: 'TEMPLATE NAME' },
  { id: 'tempType', className:'templateName', numeric: false, disablePadding: true, label: 'TEMPLATE TYPE' },
  { id: 'tasksSteps', className:'tasksSteps', numeric: true, disablePadding: true, label: 'STEPS' },
  { id: 'subTasksSteps', className:'subTasksSteps', numeric: true, disablePadding: false, label: 'SUB STEPS' },
  // { id: 'macType', className:'macType', numeric: false, disablePadding: true, label: 'MACHINE TYPE' },
  // { id: 'brand', className:'brand', numeric: false, disablePadding: true, label: 'BRAND' },
  { id: 'createdDt', className:'createdDt', numeric: false, disablePadding: true, label: 'DATE CREATED' },
  { id: 'addedDt', className:'addedDt', numeric: false, disablePadding: true, label: 'DATE ADDED' },
  { id: 'isActive', className:'isActive', numeric: false, disablePadding: true, label: 'STATUS' },
];


function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const classes1 = useToolbarStyles();
  const tableTheme2 = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiTableCell: {
        // Name of the rule
        paddingCheckbox: {
          width: '9px !important',
          padding: '0 0 0 4px',
        },
      },
    },
  });

  return (
    <TableHead>
      <ThemeProvider theme={tableTheme2}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            style={{width:'9px'}}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            padding='default'
            className={classes1[headCell.className]}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label.translate()}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
      </ThemeProvider>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: '#F2F2F2',
  },
  highlight:
    {
      color:'#0F75BC',
    },
  title: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    flex: '1 1 100%',
  },
  disabled: {
    color:'#8e9498',
    padding:'13px',
  },
  undisabled: {
    padding:'13px',
  },
  arrow: {
    fontSize: '2.5rem',
    cursor: 'pointer',
  },
  checkBoxx: {
    width: '9px !important'
  },
  checkListNo: {
    color: '#0F75BC',
    width: '41px',
    padding: '0px',
    fontSize: '1rem',
    fontWeight: '800',
  },
  templateName: {
    width: '43px',
    fontSize: '1rem',
    padding: '0px',
    fontWeight: '600',
  },
  tempType: {
    width: '43px',
    fontSize: '1rem',
    padding: '0px',
    fontWeight: '600',
  },
  tasksSteps: {
    width: '25px',
    fontSize: '1rem',
    padding: '0px',
    fontWeight: '600',
  },
  subTasksSteps: {
    width: '31px',
    fontSize: '1rem',
    padding: '0px',
    fontWeight: '600',
  },
  macType: {
    width: '38px',
    fontSize: '1rem',
    padding: '0px',
    paddingRight: '0px',
    fontWeight: '600',
  },
  brand: {
    width: '25px',
    fontSize: '1rem',
    padding: '0px',
    fontWeight: '600',
  },
  createdDt: {
    width: '36px',
    fontSize: '1rem',
    padding: '0px',
    fontWeight: '600',
  },
  addedDt: {
    width: '33px',
    fontSize: '1rem',
    padding: '0px',
    fontWeight: '600',
  },
  isActive: {
    width: '25px',
    fontSize: '1rem',
    padding: '0px',
    fontWeight: '600',
  },

}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const checkedArrow = props.checkedArrow
  const [arrow, setArrow] = React.useState(false);
  const [attachData, setAttachData] = React.useState([]);
  const { numSelected } = props;
  const templatesData = props.templates;
  const machineChecklistData = props.machineChecklist
  const machineDetailData = props.machineDetail
  const data = props.data;
  const macTempRowLength = props.macTempRL
  const brandsData = props.brands;
  const macSubTypesData = props.macSubTypes;
  const userAccess = JSON.parse(localStorage.getItem('userAccess'));
  console.log('lets check data machine check')
  console.log(machineChecklistData)
  let attaches = []
  let tempId = []
  let maclength = 0;
  const dataSetter = (test) => {
    props.setData(test)
    props.unChecked(true)
  }
  const handleTypeChange = () => {
    setArrow((prev) => !prev);
    props.collapse(true)
  };
  useEffect(() => {
    if(checkedArrow == false){
      setArrow(false);
    }
  }, [checkedArrow]);
  const handleAttachData = (data, getTempId, getMaclength) => {
    attaches = data
    tempId = getTempId
    maclength = getMaclength
    props.checked();
    props.attachData(attaches, tempId, maclength, templatesData, machineChecklistData, machineDetailData)
  }

  const handleUnchecked = (checked) => {
    if(checked){
      props.unChecked(true)
    }
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {'CHECKLIST TEMPLATE'.translate()}
        </Typography>
        <ThemeProvider theme={linkTheme}>
          <Link
        component="button"
        disabled={numSelected > 0 && checkPageActionAccess(userAccess,{pageId:3,pageAction:'Detach'}) === true ? false : true}
        className={numSelected > 0 && checkPageActionAccess(userAccess,{pageId:3,pageAction:'Detach'}) === true ? classes.undisabled : classes.disabled}
        variant="body2"
        onClick={() => {
          dataSetter(data);
        }}
      >
        {'DETACH'.translate()}
      </Link>
      <TransitionsModal
       label={"ATTACH".translate() }
       machineChecklist={machineChecklistData}
       machineDetail={machineDetailData}
       templates={templatesData} 
       unChecked={handleUnchecked} 
       macTempRL={macTempRowLength} 
       attachData={handleAttachData} 
       brands={brandsData}
       macSubTypes={macSubTypesData}>
       </TransitionsModal>
      </ThemeProvider>

        {arrow ? (
            <ArrowDropUpIcon className={classes.arrow} onClick={checkPageActionAccess(userAccess,{pageId:3,pageAction:'Detach'}) || checkPageActionAccess(userAccess,{pageId: 3, pageAction:'Attach'}) ? handleTypeChange : null}></ArrowDropUpIcon>
      ) : (
            <ArrowDropDownIcon className={classes.arrow} onClick={checkPageActionAccess(userAccess,{pageId:3,pageAction:'Detach'}) || checkPageActionAccess(userAccess,{pageId: 3, pageAction:'Attach'}) ? handleTypeChange : null}></ArrowDropDownIcon>
      )}

    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  rootDisabled: {
    opacity: 0.5
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    tableLayout: 'fixed',
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "#e0eff9"
    }
  },
  hover: {},
  selected: {},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  active: {
    color: '#70b603',
    fontWeight: '800'
  },
  inactive: {
    color: '#797979',
    fontWeight: '800'
  },
  notFound:{
    fontWeight: '800',
    fontSize: 'large',
  },
  checkListNo: {
    right: '16px',
    color:'#0F75BC',
    fontWeight: '800',
    width: '160px',
  },
  templateName: {
    width: '121px',
  },
  tasksSteps: {
    width: '71px',
  },
  subTasksSteps: {
    width: '99px'
  },
  macType: {
    width: '128px'
  },
  brand: {
    width: '72px'
  },
  createdDt: {
    width: '123px'
  },
  addedDt: {
    paddingRight: '0px',
    width: '134px',
  },
}));


  const linkTheme = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiLink: {
        // Name of the rule
        button: {
          // Some CSS
          color: '#0F75BC',
          padding: '10px',
          fontSize: 'large',
          fontWeight: 'bold',
        },
      },
    },
  });

  const tableTheme = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiTableCell: {
        // Name of the rule
        root: {
          fontSize: '0.975rem',
        },
        sizeSmall: {
          // Some CSS
          padding: '6px 0px 6px 16px',
        },
        head: {
          fontWeight: '800',
        },
        paddingCheckbox: {
          width: '9px !important',
          padding: '0 0 0 4px',
        },
        alignLeft: {
          paddingLeft: '0px !important',
        },
      },
    },
  });


export default function EnhancedTable(props) {
  const classes = useStyles();
  const checker = props.checkedSetter;
  const historyIsOpened = props.historyIsOpened;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [detachDatas, setDetachDatas] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const machineChecklistData = props.machineChecklist
  const brandsData = props.brands
  const machineDetailData = props.machineDetail
  const macSubTypesData = props.macSubTypes
  const macTempRowLength = machineChecklistData.length
  const templatesData = props.templates
  const userAccess = JSON.parse(localStorage.getItem('userAccess'));
  console.log('lets check data machine check part 2')
  console.log(machineChecklistData)

  const handleCollapse = (collapseboolean) => {
    if(collapseboolean == true){
    setChecked(prev => !prev);
    return checked
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    if(historyIsOpened){
      setChecked(false)
    }
  }, [checker]);

  useEffect(() => {
    if(checked == false){
    props.historyCollapseSetter(true);
  }else{
    props.historyCollapseSetter(false);
  }
  }, [checked]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = machineChecklistData.map((n) => n.checkListId);
      setSelected(newSelecteds);
      setDetachDatas([])
      return;
    }
    setDetachDatas(machineChecklistData)
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let filteredDetachDatas = machineChecklistData.filter(x => x.checkListId != id)


    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    setDetachDatas([]);
    if(selected.length == 0){
      setDetachDatas(machineChecklistData);
    }else if(selected.length == 1){
      setDetachDatas(filteredDetachDatas)  
    }else if(selected.length == 2){
      setDetachDatas(machineChecklistData);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleUnchecked = (checked) => {
    if(checked){
    setSelected([]);
  }
  }

  const handleChecked = () => {
    setChecked(true)
  }

const handleActive = (value) => {
  if(value){
    return 'ACTIVE'
  }else{
    return 'INACTIVE'
  }
};

const dataGetter = (value) => {
  props.detachData(selected, machineChecklistData)
}


const handleLinkClick = (id) =>{
  localStorage.setItem('redirectToMachineDetails','true');
  localStorage.setItem('redirectToMachineDetailsId',props.machineId);
  history.push('/checklist-detail/' + id);
}



  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className={classes.root  + ' ' + (checkPageActionAccess(userAccess,{pageId:3,pageAction:'Detach'}) === false && checkPageActionAccess(userAccess,{pageId: 3, pageAction:'Attach'}) === false ? classes.rootDisabled : null)}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar 
        machineChecklist={machineChecklistData} 
        templates={templatesData}
        checkedArrow={checked} 
        machineDetail={machineDetailData}
        unChecked={handleUnchecked}
        checked={handleChecked} 
        numSelected={selected.length} 
        attachData={props.attachData} 
        macTempRL={macTempRowLength} 
        checkedBox={selected.length > 0 ? true : false} 
        setData={dataGetter} 
        data={detachDatas} 
        selectedRows={selected} 
        collapse={handleCollapse}
        brands={brandsData}
        macSubTypes={macSubTypesData}/>
        
        <TableContainer>
        <Collapse in={checked}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={machineChecklistData.length}
            />
            
            <TableBody>
              {stableSort(machineChecklistData, getComparator(order, orderBy))
                .map((row, index) => {
                  const isItemSelected = isSelected(row.checkListId);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  console.log('lets check row')
                  console.log('machineChecklistData', machineChecklistData)
                  return (
                    <ThemeProvider theme={tableTheme}>
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.checkListId)}
                      role="checkbox"
                      classes={{ hover: classes.hover, selected: classes.selected }}
                      className={classes.tableRow}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.checkListId}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          className={classes.checkBoxx}
                          checked={isItemSelected}
                          color="primary"
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell className={classes.checkListNo} component="th" align="left" id={labelId} scope="row" padding="none">
                      <Link onClick={() => handleLinkClick(row.checkListId)} style={{cursor:'pointer', color:'#0F75BC'}}>
                        {row.checkListNo}
                        </Link>
                      </TableCell>
                      <TableCell className={classes.templateName} align="left">{row.templateName}</TableCell>
                      <TableCell className={classes.tempType} align="left">{row.tempType === 'CL' ? 'Repair Checklist' : 'Preventive Maintenance'}</TableCell>
                      <TableCell className={classes.tasksSteps} align="left">{row.tasksSteps}</TableCell>
                      <TableCell className={classes.subTasksSteps} align="left">{row.subTasksSteps}</TableCell>
                      {/* <TableCell className={classes.macType} align="left">{row.macsubType.translate()}</TableCell> */}
                      {/* <TableCell className={classes.brand} align="left">{row.brand}</TableCell> */}
                      <TableCell className={classes.createdDt} align="left">{formatDate(row.createdDt)}</TableCell>
                      <TableCell className={classes.addedDt} align="left">{formatDate(row.addedDt)}</TableCell>
                      <TableCell className={row.templateStatus === "ACTIVE" ? classes.active : classes.inactive} align="left">{row.templateStatus.translate()}</TableCell>
                    </TableRow>
                    </ThemeProvider>
                  );
                })}
                {machineChecklistData.length == 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={9} className={classes.notFound}>{'No records found'.translate()}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          </Collapse>
        </TableContainer>
      </Paper>
    </div>
  );
}