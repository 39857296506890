import React, { forwardRef } from 'react';
import { LinearProgress } from '@material-ui/core';
import {
  Container,
  FlexBox,
  CompletedTypography,
  PercentTypography,
  FileNameTypography,
  CancelButton,
} from './styles';
import { tokenGet } from '../../utils/api/CoreApi';
import { useHistory } from 'react-router';

const QueueState = forwardRef(({
    pageSize = 1000, 
    onCompleted,
}, ref) => {

  const [meta, setMeta] = React.useState(null);
  const [fileName, setFileName] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [completed, setCompleted] = React.useState(0);
  const history = useHistory();
  let cancel = React.useRef(false);

  React.useImperativeHandle(ref, () => ({
    getData,
  }));

  const handleOnClose = () => {
    setLoading(false);
    setCompleted(0);
    setMeta(null);
    setFileName('');
  }
  
  const handleOnCompleted = (fileName, results) => {
    cancel.current = false;
    onCompleted(fileName, results);
  };

  const handleOnCancel = () => {
    cancel.current = true;
    setLoading(false);
    setMeta(null);
    setCompleted(0);
    setFileName('');
  };

  const getData =  async (
    fileName,
    url,
    query,
  ) => {
    if(!url) return false;
    setFileName(fileName);
    setLoading(true);
    let currentPage = 1;
    let totalPages = null;
    let results = [];
    do {
      try {
        const urlQuery = new URLSearchParams({ ...query, currentPage, pageSize });
        const { data } = await tokenGet(`${url}?${urlQuery}`);
        const { tickets } =  data;
        const { meta, result } = tickets;
        results = [...results, ...result];
        totalPages = meta.pageCount;
        currentPage++;
        setCompleted((meta.currentPage / totalPages) * 100);
        setMeta(meta);
        if (cancel.current) { break };
      } catch (error) {
        console.log(error);
        break;
      }
    } while (currentPage <= totalPages);

    handleOnCompleted(fileName, results);
  };

  React.useEffect(() => {
    history.listen(() => (loading && handleOnCancel()));
  }, [loading]);

  if (!loading) return null;
  
  return (
    <div className='App'>
      <Container>
        <FlexBox>
          <FileNameTypography style={{ textAlign: 'left' }} variant='h6'>
            {fileName}.xls
          </FileNameTypography>
          {
            completed >= 100 
              ? <CancelButton onClick={handleOnClose}>{'close'.translate()}</CancelButton>
              : <CancelButton onClick={handleOnCancel}>{'cancel'.translate()}</CancelButton>
          }
        </FlexBox>
        <LinearProgress variant='determinate' value={completed} />
        <FlexBox>
          <CompletedTypography
            style={{ textAlign: 'left' }}
            variant='subtitle2'>
            {'Completed'.translate()} {
              meta 
                ? meta.currentPage != meta.pageCount 
                  ? pageSize * meta.currentPage
                  : meta.count
                : 0
            } {'of'.translate()}{' '}
            {meta?.count || 0} {'records'.translate()}
          </CompletedTypography>
          <PercentTypography style={{ textAlign: 'left' }} variant='h6'>
            {meta ? parseInt((meta.currentPage / meta.pageCount) * 100) : 0}%
          </PercentTypography>
        </FlexBox>
      </Container>
    </div>
  );
});

export default QueueState;
