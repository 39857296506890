import React from 'react';
import { Box, Select, MenuItem, FormControl, FormLabel } from '@material-ui/core';
import BootstrapInput from '@src/components/BootstrapInput';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  labelRoot: {
    lineHeight: 'unset',
    fontSize: 14,
    paddingRight: 20
  },
})

export default function ViewBySelect(props){
  const { value, label, onChange, options  } = props;
  const classes = useStyles();
  return <Box
    component="div"
    onClick={(event) => event.stopPropagation()}
    onFocus={(event) => event.stopPropagation()}
  >
    <FormLabel
      classes={{
        root: classes.labelRoot
      }}
    >
      {label}
    </FormLabel>
    <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
      <Select
        value={value}
        onChange={onChange}
        input={<BootstrapInput />}
      >
        {
          options.map((option, index) => {
            return <MenuItem key={index} value={option.value}>{option.label.translate()}</MenuItem>
          })
        }
      </Select>
    </FormControl>
  </Box>
}
