import React, { Fragment, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import SearchInput from '../../../components/SearchInput';
import Typography from '../../../components/Typography';
import ImageList from './ImageList';
import { referenceClient } from '../../../../../../utils/api/CoreApi';
import { displayDefaultImage } from '../../../../../../utils/image';

export default function Attachments({ value, onChange }){
  const [values, setValues] = useState(value || []);
  const [attachments, setAttachments] = useState([]);
  const [search, setSearch] = useState(null);

  useEffect(()=>{
    if(search == null || search == undefined || search == '') return;
    const params = {
      isActive: '1',
      search: search,
      pageSize: 10
    }
    referenceClient.get("/attachments", { params }).then(({ data })=>{
      const { result } = data.data;
      setAttachments(result.map(({ id, desc1, imgLink })=>{
        return {
          id,
          name: desc1,
          imgUrl: displayDefaultImage(imgLink),
        }
      }));
    })
  }, [search])

  const handleAdd = (value)=>{
    if(value) {
      const newValues = [...values, value];
      setValues(newValues)
      onChange(newValues);
    }
  }

  const handleRemove = (value)=>{
    const newValues = values.filter(v=>v.id !== value.id);
    setValues(newValues);
    onChange(newValues)
  }

  return (
    <Fragment>
      <Box my={1}>
        <Typography variant="h5">{'Attachments'.translate()}</Typography>
      </Box>

      <Autocomplete
        id="attachments-autocomplete"
        options={attachments.filter(att => !values.some(existAtt => att.id === existAtt.id))}
        getOptionLabel={(option) => option.name}
        inputValue={search}
        onInputChange={(evt, value) => setSearch(value)}
        onChange={(evt, value) => handleAdd(value)}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <SearchInput
              {...params}
              placeholder={'Search Attachment Name'.translate()}
            />
          </div>
        )}

      />

      <ImageList
        images={values}
        onRemove={handleRemove}
      />
    </Fragment>
  )

}
