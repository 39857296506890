import React from 'react';
import { 
    Box, 
    MenuItem, 
    Select, 
    Table, 
    TableCell,
    TableSortLabel, 
    Typography, 
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import BootstrapInput from '@src/components/BootstrapInput';

export const Header = withStyles({
    root: {
        display: 'flex',
        gap: 5,
        justifyContent: 'space-between',
        alignContent: 'center',
        alignItems: 'center',
    },
})(Box);

export const Title = withStyles({
    root: {
        fontSize: 16,
        fontWeight: 'bold',
    },
})((props) => <Typography {...props}>{props.text}</Typography>);

export const FlexBox = withStyles({
    root: {
        display: 'flex',
        gap: 5,
        justifyContent: 'flex-start',
        alignContent: 'center',
        alignItems: 'center',
    },
})((props) => <Box {...props} />);

export const CustomerSelect = ({ 
    items = [], 
    defaultValue = 0,
    label = 'View By'.translate(), 
    onChange, 
}) => (
    <FlexBox>
        <Typography fontSize={12}>{label}</Typography>
        <Select value={defaultValue} onChange={onChange} input={<BootstrapInput />}>
            {items.map((option, index) => 
                <MenuItem key={index} value={index + 1}>
                    {option.translate()}
                </MenuItem>)
            }
        </Select>
    </FlexBox>
);

export const MuiTable = withStyles({
    root: {
        width: '100%',
        borderSpacing: '5px',
        borderCollapse: 'separate',
        marginTop: 10,
        marginBottom: 10,
        transition: 'all .5s',
        '& th': {
            background: '#EDF0F2 0% 0% no-repeat padding-box',
            color: '#000',
            fontSize: 14,
            fontWeight: 'bold',
        },
        '& th, td': { padding: 5, borderBottom: 'none' },
        '& td': { fontSize: 13 },
        '& tbody tr': { boxShadow: '0px 1px 0px 0px rgba(224, 224, 224, 1)' },
        '& tfoot td': { fontWeight: 'bold', color: '#000 !important' },
    },
})(Table);

export const TableHeaderCell = withStyles({
    root: (props) => ({
        padding: '5px 5px 5px 16px',
        background: '#EDF0F2 0% 0% no-repeat padding-box',
        transition: 'all ease-in 1s',
    }),
    head: {
      borderRight: '2px solid white',
      borderBottom: 'none',
    }
})((props) => {
    const { active = false } = props;
    return <TableCell {...props} style={ active ? { backgroundColor: '#1585F4' } : null } />
});

export const TableCellUsage = withStyles({
    root: { fontSize: 14, fontWeight: 'bold' },
})(TableCell);

export const TableCellEllipsis = withStyles({
    root:{
        width: 200,
        display: 'block',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
})(TableCell);

export const LineBox = withStyles({
    root: (props) => ({
        position: 'relative',
        width: '100%',
        height: 18,
        background: '#ADADAD',
        transition: 'width 2s',
        borderRadius: 3,
        '&:before': {
            position: 'absolute',
            content: " ' " +props.working+ " ' ",
            top: 0,
            bottom: 0,
            left: 0,
            paddingLeft: 10,
            lineHeight: '18px',
            width: props.workingPercent + '%',
            height: 18,
            background: '#87DD00',
            borderRadius: 3,
            fontSize: 12,
            fontWeight: 'bold',
        },
        '&:after': {
            position: 'absolute',
            content: "' "+props.idle+"' ",
            right: 10,
            top: 0,
            bottom: 0,
            height: 18,
            fontSize: 12,
            fontWeight: 'bold',
            lineHeight: '20px',
        },
    })
})((props) => <Box {...props} />);

export const MuiTableSortLabel = withStyles((theme) => ({
    root: {
      fontWeight: 'bold',
      fontSize: 14,
      whiteSpace: "nowrap",
      justifyContent: "space-between",
      width: '100%',
      '&$active': {
        color: '#FFFFFF'
      },
      '&$icon': {
        color: '#FFFFFF',
        fontSize: 14,
      },
    },
    active: {
      color: '#FFFFFF'
    },
    icon: {
      color: '#FFFFFF !important',
      fontSize: 14,
    },
}))(TableSortLabel);
  
export const Legend = ({ 
    color = '#87DD00', 
    text = 'Legend',
    textColor = '#212121',
}) => (
    <IndicatorContainer>
        <LegendIndicator color={color} />
        <Typography style={{ color: textColor }}>{text}</Typography>
    </IndicatorContainer>
);

export const LegendIndicator = withStyles({
    root: (props) => ({
        backgroundColor: props.color,
        width: 16,
        height: 16,
        borderRadius: 2,
        marginLeft: props.marginLeft || 4,
        marginRight: props.marginRight || 4,
    }),
})(Box);
  
export const IndicatorContainer = withStyles({
    root: (props) => ({
        display: 'flex',
        justifyContent: props.justifyContent || 'space-around',
        marginLeft: props.marginLeft || 10,
        marginRight: props.marginRight || 10,
    }),
})(Box);

