import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import validate from 'validate.js';
import { createSupplier, updateSupplier } from '../../../../../redux/actions/Supplier/supplierActions.js';
import { connect } from 'react-redux';
import InputTranslation from './InputTranslation.js';
import { notificationSuccess } from '../../../../../redux/actions/ChangeoverTypes/changeoverTypeAction';
import { notificationError } from '../../../../../redux/actions/ChangeoverTypes/changeoverTypeAction';
import dictionary from '../../../../../redux/actions/Translation/dictionary';
import { useStyles  } from './style.js';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { formatDate } from '../../../../../utils/date.js';

const SupplierForm = ({
  supplier,
  translations,
  onClose,
  createSupplier,
  updateSupplier,
  showNotificationError,
  isDisabled }) => {
  const classes = useStyles();
  const id = supplier ? supplier.id : '';
  const isUpdating = Boolean(supplier);
  const [code, setCode] = useState(isUpdating ? supplier.supplier : '');
  const [name, setName] = useState(isUpdating ? supplier.desc1 : '');
  const [description, setDescription] = useState(isUpdating ? supplier.desc2 : '');
  const [isActive, setIsActive] = useState(isUpdating? supplier.isActive : true);
  const [updateTranslations, setUpdateTranslations] = useState(translations);
  const [errors, setErrors] = useState([]);
  const convert = () => {
    let { 
      ADD, 
      SAVE, 
      CANCEL, 
      MAXIMUM_CHAR,
      SPACE_NOT_ALLOW,
    } = dictionary.GET();
    return dictionary.TRANSLATE({
       SAVE, 
       ADD, 
       CANCEL, 
       MAXIMUM_CHAR,
       SPACE_NOT_ALLOW,
    });
  }
  const {
    SAVE, 
    CANCEL, 
    MAXIMUM_CHAR,
    SPACE_NOT_ALLOW,
  } = convert();

  const handleSubmit = async (evt)=>{
    evt.preventDefault();
    const values = {
      id: id,
      supplierCode: code || '',
      desc1: name || '',
      desc2: description || '',
      isActive: isUpdating ? isActive: 1,
      translations: updateTranslations,
    }
    const rules = {
      supplierCode: {
        length: {
          maximum: 30,
          message: `must be ${MAXIMUM_CHAR} 30`
        },
        format: { pattern: /^\S*$/, message: SPACE_NOT_ALLOW},
      },
      desc1: {
        length: {
          maximum: 35,
          message: `must be ${MAXIMUM_CHAR} 35`
        }
      },
      desc2: {
        length: {
          maximum: 250,
          message: `must be ${MAXIMUM_CHAR} 250`
        },
      }
    };
    const validationErrors = validate(values, rules);
    if(!validationErrors) {
      setErrors({});
      if(supplier) {
        updateSupplier(values);
      } else {
        createSupplier(values);
      }
      onClose();
    } else {
      setErrors(validationErrors);
      showNotificationError('Invalid input fields!')
    }
  }


  const SupplierInfo = ({ supplier }) => {
    if(!supplier){ return null }
    const { updatedBy, updatedDt, createdBy, createdDt } = supplier;
    return <div style={{ marginTop: "1rem", color: "gray" }}>
      { 
        updatedBy && (      
          <Typography>
            Modified by: {updatedBy}
          </Typography>
        )
      }
  
      { 
        updatedDt && (      
          <Typography>
            Date Modified : {formatDate(updatedDt)}
          </Typography>
        )
      }
  
      { 
        createdBy && (      
          <Typography>
            Created By: {createdBy}
          </Typography>
        )
      }
  
      { 
        createdDt && (      
          <Typography>
            Date Created: {formatDate(createdDt)}
          </Typography>
        )
      }
    </div>
  
  }

  return (
    <form onSubmit={handleSubmit} className={classes.FormEdit} >
      <TextField
        disabled={isDisabled}
        className={classes.TextField}
        label='Supplier Code'
        type="text"
        value={code}
        onChange={(e) => setCode(e.target.value)}
        required
        fullWidth
        error={errors.supplierCode ? true : false}
        helperText={errors?.supplierCode ? errors.supplierCode[0] : ''}
        InputLabelProps={{ classes: { asterisk: classes.asterisk }}}
        FormHelperTextProps={{ classes: { root: classes.helperText}}}
      />
      <TextField
        className={classes.TextField}
        label='Display Name'
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
        fullWidth
        error={errors.desc1 ? true : false}
        helperText={errors?.desc1 ? `Name ${errors.desc1[0].replace('Desc1', '')}` : ''}
        InputLabelProps={{ classes: { asterisk: classes.asterisk }}}
        FormHelperTextProps={{ classes: { root: classes.helperText}}}
      />
      {translations && translations.map(( language, key ) => <>
        <InputTranslation
          name={name}
          key={key}
          language={language}
          value={`${
            language.translatedDesc != '' ? 
              language.translatedDesc 
              : 
              supplier?.translations.find((translation) => translation.languageId === language.id)?.translatedDesc || ''
            }`}
          label={`Display Name (${language.desc1})`} 
          onChangeText={(text) => { 
            translations[key].translatedDesc = text;
            setUpdateTranslations([...translations]); 
          }} />
        </>)}
        <TextField
          className={classes.TextField}
          label='Description'
          type="text"
          value={description}
          onChange={(e) => {
            const MAX_DESC2_LENGTH = 250;
            const inputText = e.target.value;
            if (inputText.length <= MAX_DESC2_LENGTH) {
              setDescription(inputText);
              setErrors([]);
            } else {
              setDescription(inputText.slice(0, MAX_DESC2_LENGTH));
              setErrors({ desc2: [`Description must be Maximum character limit is ${MAX_DESC2_LENGTH}`] });
            }
          }}
          fullWidth
          error={errors.desc2 ? true : false}
          helperText={errors?.desc2 ? `${errors.desc2[0].replace('Desc2', '')}` : ''}
          FormHelperTextProps={{ classes: { root: classes.helperText} }}
        />

        { isUpdating &&
        <FormControl className={classes.radioFormControl}>
          <FormLabel className={classes.formLabel}>Status</FormLabel>
          <RadioGroup
            value={isActive}
            onChange={(evt) =>{
              setIsActive(evt.target.value === 'true');
            }
            }
            className={classes.radioGroup}
          >
            <FormControlLabel 
              value={true} 
              control={<Radio className={classes.radio} />} 
              label='Active' 
            />
            <FormControlLabel
              value={false}
              control={<Radio className={classes.radio} />}
              label='Inactive'
            />
          </RadioGroup>
          </FormControl>
        }

        <SupplierInfo supplier={supplier} />

      <div className={classes.formAction}>
        <Button onClick={onClose} variant="outlined" className={classes.ButtonWhite}>
          {CANCEL}
        </Button>
        <Button type="submit" className={classes.ButtonBlue} variant="contained">
          {SAVE}
        </Button>
      </div>
    </form>
  )
}

const mapDispatchToProps = {
  createSupplier: (payload) => createSupplier(payload),
  updateSupplier: (payload) => updateSupplier(payload),
  showNotificationSuccess: (message) => notificationSuccess(message),
  showNotificationError: (message) => notificationError(message)
}

export default connect(null, mapDispatchToProps)(SupplierForm);