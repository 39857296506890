import { colors } from "./colors";
export const options = (mechanicOverviewData) => ({
  legend: false,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        gridLines: {
          borderDash: [8, 4],
          drawTicks: false,
        },
        ticks: {
          padding: 10
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          callback: function(value, index, ticks) {
            return value.split('__')[0];
          }
        }
      },
    ],
  },
  tooltips: {
    enabled: false,
    intersect: false,
    mode: "index",
    callbacks: {
      afterBody: (ChartTooltipItem) => ({
          index: ChartTooltipItem[0].index,
          data: mechanicOverviewData,
      }),
    },
    custom: function (tooltipModel) {
      var tooltipEl = document.getElementById('chartjs-tooltip');
      const yAlign = tooltipModel.yAlign;
      const xAlign = tooltipModel.xAlign;
      const caretY = tooltipModel.caretY;
      const caretX = tooltipModel.caretX;
      const afterBody = tooltipModel.afterBody;

      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = '<div class="chartjs-tooltip"></div>';
        document.body.appendChild(tooltipEl);
      }
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }
      tooltipEl.classList.remove("top", "bottom", "center", "left", "right");
      tooltipEl.classList.add(tooltipModel.yAlign);
      tooltipEl.classList.add(tooltipModel.xAlign);
    if (tooltipModel.body) {
        var titleLines = tooltipModel.title || [];
        var currentLabel = '';
        var data = tooltipModel.dataPoints;
        var boxCountElement = "<div class='item'>";
            boxCountElement += "<div>";
        titleLines.forEach(function (title) {
          const arrTitle = title.split('__');
          currentLabel = arrTitle[0];
          let newTitle = title;
          if(arrTitle.length == 2) newTitle = arrTitle[1];
          boxCountElement += '<h3 class="title">' + 'Overall Mechanic Performance'.translate() + '</h3>';
          boxCountElement += '<h5>' + newTitle + '</h5>';
        });
        var totalMachines = parseInt(data[3].value) + parseInt(data[4].value);
        var totalTickets = parseInt(data[2].value) + parseInt(data[1].value) + parseInt(data[0].value);
        boxCountElement += "</div>";
        boxCountElement += `
                <div class='box'>
                <div style='display: flex; justify-content: space-between'><h3>${'Total Mechanic:'.translate()}</h3><h3>${totalMachines}</h3></div>
                <div class='break-line'></div>
                <div style='display: flex; justify-content: space-between'>
                  <p style='display: flex; gap: 5px; align-items: center'><span class='legend-indicator' style='background: ${colors[0]}'></span> 
                    ${'Active Mechanic'.translate()}
                  </p>
                  <p>${data[4].value}</p>
                </div>
                <div style='display: flex; justify-content: space-between'>
                  <p style='display: flex; gap: 5px; align-items: center'><span class='legend-indicator' style='background: ${colors[1]}'></span> 
                    ${'Idle Mechanic'.translate()}
                  </p>
                  <p>${data[3].value}</p>
                </div>
                </div>
                `;

          boxCountElement += `
                <div class='box'>
                <div style='display: flex; justify-content: space-between'><h3>${'Total Tickets'.translate()}:</h3><h3>${totalTickets}</h3></div>
                <div class='break-line'></div>
                <div style='display: flex; justify-content: space-between'>
                  <p style='display: flex; gap: 5px; align-items: center'>
                  <span class='line-chart co'></span> 
                    ${'Closed Changeover Tickets'.translate()}
                  </p>
                  <p>${data[0].value}</p>
                </div>
                <div style='display: flex; justify-content: space-between'>
                  <p style='display: flex; gap: 5px; align-items: center'>
                  <span class='line-chart maintenance'></span> 
                    ${'Closed Maintenance Tickets'.translate()}
                  </p>
                  <p>${data[1].value}</p>
                </div>
                <div style='display: flex; justify-content: space-between'>
                  <p style='display: flex; gap: 5px; align-items: center'><span class='line-chart repair'></span> 
                    ${'Closed Repair Tickets'.translate()}
                  </p>
                  <p>${data[2].value}</p>
                </div>
                </div>
                `;
        boxCountElement += '</div>';
        var boxListElement = `<div class='item'><h3 class="title">${'Active Mechanic List'.translate()}</h3>`;
        if(afterBody) {
          const { data } = afterBody[0];
          const mechanicData = data.find(({ label }) => label === currentLabel);
          if(mechanicData){
            const { activeMechanics } = mechanicData;
            if(activeMechanics.length > 0) {
              activeMechanics.map((name, key) => (
                boxListElement +=  `<p>${(key+1).toLocaleString()} ${name}</p>`
              ))
            };
          };
        };
        boxListElement += '</div>';
        var innerHtml = `${boxCountElement}${boxListElement}`;
        var tableRoot = tooltipEl.querySelector('.chartjs-tooltip');
        tableRoot.innerHTML = innerHtml;
      }
      var position = this._chart.canvas.getBoundingClientRect();
      const { width } = tooltipEl.getBoundingClientRect();
      const positionX = this._chart.canvas.offsetLeft;
      let left = positionX + window.pageXOffset + caretX - width / 2;
      let space = 8;
      if (xAlign === "left") {
        left += width / 2 - tooltipModel.xPadding - space / 2;
        if (yAlign === "center") {
          left += space * 2;
        }
      } else if (xAlign === "right") {
        left -= width / 2;
        if (yAlign === "center") {
          left -= space;
        } else {
          left += space;
        }
      }
      tooltipEl.style.opacity = 1;
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.left = left + 'px';
      tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
      tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
      tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
      tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
      tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
      tooltipEl.style.pointerEvents = 'none';
    }
  }
});