import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { hex2rgba } from './helpers';
import MiddleLabel from './plugins/MiddleLabel';
import Filter from './plugins/Filter';

const plugins = [
  ChartDataLabels,
  MiddleLabel,
  Filter
]

export default function ProblemDonut(props){
  const { data } = props;
  const [chart, setChart] = useState(null);

  useEffect(()=>{
    const totalTickets = data.reduce((sum, d)=>(sum + d.totalTickets), 0);
    const chartData = {
      labels: data.map((d)=> d.problemType),
      datasets: [
        {
          data: data.map((d)=> d.totalTickets),
          backgroundColor: data.map((d)=> d.isSelected ? hex2rgba(d.color, 1) : hex2rgba(d.color, 0.5)),
          borderWidth: 0
        },
      ],
    };

    const options = {
      label: `${totalTickets}\nTTL TKTS`,
      responsive: true,
      maintainAspectRatio: true,
      cutoutPercentage: 70,
      plugins: {
        legend: false,
        tooltip: false,
        datalabels: {
          color: '#ffffff',
          formatter: (value) => parseInt(value / totalTickets * 100) + '%'
        },
        filter: {
          selected: data.map((d)=> d.isSelected)
        }
      }
    };

    setChart({
      data: chartData,
      options,
    })

  }, [data])


  if(chart == null){ return null }

  return <Doughnut
    height={250}
    data={chart.data}
    options={chart.options}
    plugins={plugins}
  />
}
