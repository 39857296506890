import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import './Dashboard.scss';

export default function RepairTicketCard({ 
    title, 
    isTvMode, 
    repairTicketsStatus,
    textReported,
    textReOpened,
    textInRepair,
    textRepaired,
    textClosedToday,
    textCancelledToday,
}) {
  const item = (status, count) => (
    <Grid 
        className={isTvMode('ticket-count-container')} 
        item xs={4}>
        <div className={isTvMode(
            'ticket-count tvMode', 
            'ticket-count repair-ticket-count',
        )}>
            {count.toLocaleString()}
        </div>
        <div className={isTvMode(
            'ticket-status tvMode repair-ticket', 
            'ticket-status repair-ticket-status',
        )}>
            {status}
        </div>
    </Grid>
  );

  return (
    <Paper className={isTvMode(
        'card tvMode-height', 
        'card',
      )}>
      <div className={isTvMode(
          'title tvMode', 
          'title repair-ticket-title',
        )}>
        {title}
      </div>
      <Grid 
        container 
        spacing={2} 
        className={isTvMode(
          'repair-ticket',
          'inner-card repair-ticket',
        )}>
        {item(textReported, repairTicketsStatus?.reported || 0)}
        {item(textReOpened, repairTicketsStatus?.reOpened || 0)}
        {item(textInRepair, repairTicketsStatus?.inRepair || 0)}
        {item(textRepaired, repairTicketsStatus?.repaired || 0)}
        {item(textClosedToday, repairTicketsStatus?.closed || 0)}
        {item(textCancelledToday, repairTicketsStatus?.cancelled || 0)}
      </Grid>
    </Paper>
  );
}