import React, { useState, Fragment } from 'react';

import IconFillter from "../../../../assets/icons/filter.svg";
import IconFillterBlue from "../../../../assets/icons/filter-blue.svg";
import { makeStyles } from "@material-ui/core/styles";
import Button from './Button';
import { Menu, FormControlLabel, Checkbox } from '@material-ui/core';
import { CO_REQUEST_STATUSES } from '../../../../constants/corequest';

const useStyles = makeStyles((theme) => ({
  btn: {
    paddingLeft: "32px",
    float: "right",
    marginRight: "10px",
    height: "36px",
    backgroundImage: `url(${IconFillter})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "36px",
    backgroundPosition: "left",
    "&:hover": {
      color: "#1E88E5",
      backgroundColor: "#fff !important",
      backgroundImage: `url(${IconFillterBlue})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "36px",
      backgroundPosition: "left",
    }
  },
  badgeNumber: {
    background: "#1E88E5",
    color: "#fff",
    width: "24px",
    borderRadius: "50%",
    marginLeft: "8px",
  },
  filterLabel: {
    fontSize: "14px",
    padding: "4px 16px 8px 16px",
    margin: 0,
    fontWeight: 500,
    letterSpacing: "0.08px",
    color: "#515151",
    opacity: 1,
    borderBottom: "1px solid #E0E0E0",
    "&focus": {
      border: "none",
      boxShadow: "none",
      outline: "none",
    },
  },
  checksContainer: {
    padding: "10px 16px 1px 16px",
    color: "#868686",
  },
  checkBox: {
    padding: 0,
    fontSize: "12px",
    paddingBottom: "3px",
    paddingRight: "8px",
    paddingLeft: "8px",
  },
  formControlLabel: {
    display: 'flex',
  }
}));

export default function StatusFilterButton({ filters, onFilter }){
  const classes = useStyles();

  const [items, setItems] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);

  const isOpen = Boolean(anchorEl);

  const toggleCheck = (statusCode) => {
    const isSelected = items.includes(statusCode)
    let newItems = [...items];
    if (isSelected) {
      newItems = newItems.filter(item => item !== statusCode)
    }else{
      newItems.push(statusCode)
    }
    setItems(newItems);
    onFilter({
      ...filters,
      statuses: newItems
    })
  }

  return (
    <Fragment>
      <Button
        onClick={(evt) => setAnchorEl(evt.currentTarget)}
        className={classes.btn}
      >
        {'Filter'.translate()}
        {
          items.length > 0 && (
            <span className={classes.badgeNumber}>
              {items.length}
            </span>
          )
        }

      </Button>

      <Menu
        id="filter-menu"
        style={{ marginTop: "48px" }}
        anchorEl={anchorEl}
        keepMounted
        open={isOpen}
        onClose={() => setAnchorEl(null)}
      >
        <p className={classes.filterLabel}>{'Filter by Status'.translate()}</p>
        <div className={classes.checksContainer}>
          {Object.keys(CO_REQUEST_STATUSES).map((status) => {
            const statusCode = CO_REQUEST_STATUSES[status];
            const isSelected = items.includes(statusCode)
            return <FormControlLabel
              key={status}
              className={classes.formControlLabel}
              label={status.translate()}
              control={
                <Checkbox
                  onChange={() => toggleCheck(statusCode)}
                  className={classes.checkBox}
                  checked={isSelected}
                />
              }

            />
          })}
        </div>
      </Menu>
    </Fragment>
  )
}
