export default {
  id: "middle-label",
  beforeDraw: function(chart) {
    var ctx = chart.chart.ctx;
    ctx.restore();
    const middleLabels = chart.options.middleLabels;
    if(middleLabels){
      middleLabels.forEach((text, index)=> {
        var width = chart.chart.width;
        var height = chart.chart.height;
        if(index === 0){
          var fontSize = (height / (index === 0 ? 70 : 155)).toFixed(2);
          ctx.font = "bold " + fontSize / 2 + "em sans-serif";
          ctx.textBaseline = "middle";
          const metrics = ctx.measureText(text);
          const actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
          const textY = Math.round(height / 2.4 + index * (actualHeight + 13));
          const textX = Math.round((width - metrics.width) / 2);
          ctx.fillText(text, textX, textY);
        } else {
          var fontSize = (height / (100)).toFixed(2);
          ctx.font = fontSize / 2.3 + "em sans-serif";
          ctx.textBaseline = "middle";
          const metrics = ctx.measureText(text);
          const actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
          const textY = Math.round(height / 2.3 + index * (actualHeight + 10));
          const textX = Math.round((width - metrics.width) / 2);
          ctx.fillText(text, textX, textY);
        }
      })
      ctx.save();
    }
  }
}
