import React from 'react';
import Typography from '../components/Typography';
import Button from '../components/Button';
import SearchInput from '../components/SearchInput';
import StatusFilterButton from '../components/StatusFilterButton';
import { Box, Divider, Grid } from '@material-ui/core';
import {
  Add as AddIcon,
  CloudUpload as CloudUploadIcon,
  CalendarToday as CalendarTodayIcon,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import BulkUpload from '../../../../components/Modal/ChangeoverBulkUpload/Bulkupload';
import { escapePhrase } from '../helpers';

export default function Actions({
  requests,
  filters,
  onSearch,
  onFilter,
  onPlanning,
  onDelete,
  onClose,
  ...props
}) {
  const history = useHistory();
  const totalSelections = requests.filter(
    (request) => request.isSelected
  ).length;
  const hasSelections = totalSelections > 0;

  const [openBulkUpload, setOpenBulkUpload] = React.useState(false);
  
  return (
    <div>
      <BulkUpload 
          openBulkUpload={openBulkUpload} 
          // onFinish={() => }
          handleClose={() => setOpenBulkUpload(false)  } 
          // {...this.props} 
      />
      <Box mb={2}>
        <Typography variant="h5">{'Changeover Requests'.translate()}</Typography>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }} my={1}>
          <Box>
            <Box
              style={{
                maxWidth: 300,
                display: 'inline-flex',
                verticalAlign: 'top',
              }}
            >
              <SearchInput
                placeholder={'Search'.translate()}
                onChange={(evt) => onSearch(evt.target.value)}
              />
            </Box>

            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => history.push('/changeover-add')}
            >
              {"Add".translate()}
            </Button>

            {/* <Button color="primary" startIcon={<CloudUploadIcon />} onClick={() => setOpenBulkUpload(true)}>
              {'BULK UPLOAD'.translate()}
            </Button> */}
          </Box>

          <Button
            color="primary"
            startIcon={<CalendarTodayIcon />}
            onClick={()=> onPlanning()}
          >
            {'CHANGEOVER PLANNING'.translate()}
          </Button>

        </Box>
        <Divider />
        <Box my={1}>
          <Grid
            container
            alignItems="center"
            style={{ width: 'fit-content', display: 'inline-flex' }}
          >
            <Typography>{escapePhrase('Selected (%{0}) on this page'.translate(), [totalSelections])}</Typography>

            <Divider
              orientation="vertical"
              flexItem
              style={{ marginLeft: 10, marginRight: 10 }}
            />

            <Button 
              color="primary" 
              disabled={!hasSelections}
              onClick={()=> onClose()}
            >
              {'CLOSE CO'.translate()}
            </Button>
            <Button 
              color="primary" 
              disabled={!hasSelections}
              onClick={()=> onDelete()}
            >
              {'DELETE'.translate()}
            </Button>
          </Grid>

          <StatusFilterButton filters={filters} onFilter={onFilter} />
        </Box>
      </Box>
    </div>
  );
}
