import React from "react";
import { Doughnut } from "react-chartjs-2";
import { 
  datasetKeyProvider, 
  getDatasets, 
  getOptions,
} from "./helper";
import MiddleLabel from "./plugins/MiddleLabel";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import './plugins/styles.scss';
import { Box } from "@material-ui/core";
import { FlexBox, Legend } from "./styles";

function Chart(props) {
    const mounted = React.useRef(false);
    const [datasets, setDatasets] = React.useState(false);
    React.useEffect(() => {
      mounted.current = setTimeout(()=>{
        setDatasets(getDatasets(props.data));
      }, 500);
      return ()=> clearTimeout(mounted.current);
    }, [props.data]);
    if (!datasets) return null;
    const totalMachines = props.data?.reduce((sum, { countTotal }) => sum + countTotal, 0);
    return (
        <Box>
            <Doughnut
                data={datasets}
                options={getOptions(totalMachines)}
                plugins={[MiddleLabel, ChartDataLabels]}
                datasetKeyProvider={datasetKeyProvider}
            />
            <FlexBox justifyContent='center'>
              <Legend text={'Active Machines'.translate()} color='#6DDDA5' />
              <Legend text={'Inactive Machines'.translate()} color='#A8A8A8' />
            </FlexBox>
        </Box>
    );
}

export default Chart;