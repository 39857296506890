import {
    NOTIFICATION_SUCCESS,
    NOTIFICATION_ERROR,
    FLOOR_LIST,
    FLOOR_LIST_REQUEST,
    FLOOR_LIST_SUCCESS,
    FLOOR_LIST_ERROR,
    FLOOR_DETAIL_REQUEST,
    FLOOR_DETAIL_SUCCESS,
    FLOOR_DETAIL_ERROR,
    FLOOR_NEW_REQUEST,
    FLOOR_NEW_SUCCESS,
    FLOOR_NEW_ERROR,
    FLOOR_UPDATE_REQUEST,
    FLOOR_UPDATE_SUCCESS,
    FLOOR_DELETE_REQUEST,
    FLOOR_DELETE_SUCCESS,
    FLOOR_UPDATE_ERROR,
    FLOOR_TRIGGER_HIGHLIGHT,
} from '../actionTypes';
import { tokenGet, tokenPost, tokenPut, tokenDelete, apiSettings } from '../../../utils/api/CoreApi';
import setters from '../../actions/Factory/factoryActions'
import history from '../../../history';

export const notificationSuccess = (message) => {
    return {
        type: NOTIFICATION_SUCCESS,
        payload: {
            message: message.translate()
        }
    }
}

export const notificationError = (message) => {
    return {
        type: NOTIFICATION_ERROR,
        payload: {
            message: message.translate()
        }
    }
}

export const floorRequest = () => {
    return {
        type: FLOOR_LIST_REQUEST
    }
}

export const floorSuccess = floorList => {
    return {
        type: FLOOR_LIST_SUCCESS,
        payload: floorList
    }
}

export const floorDetailRequest = () => {
    return {
        type: FLOOR_DETAIL_REQUEST
    }
}

export const floorDetailSuccess = floorDetail => {
    return {
        type: FLOOR_DETAIL_SUCCESS,
        payload: floorDetail
    }
}

export const floorNewRequest = () => {
    return {
        type: FLOOR_NEW_REQUEST
    }
}

export const floorNewSuccess = floorList => {
    return {
        type: FLOOR_NEW_SUCCESS,
        payload: floorList
    }
}

export const floorUpdateRequest = () => {
    return {
        type: FLOOR_UPDATE_REQUEST
    }
}

export const floorUpdateSuccess = floorList => {
    return {
        type: FLOOR_UPDATE_SUCCESS,
        payload: floorList
    }
}

export const floorDeleteRequest = () => {
    return {
        type: FLOOR_DELETE_REQUEST
    }
}

export const floorDeleteSuccess = floorList => {
    return {
        type: FLOOR_DELETE_SUCCESS,
        payload: floorList
    }
}


export const floorTriggerHighlight = (flag) => {
    return {
        type: FLOOR_TRIGGER_HIGHLIGHT,
        payload: flag
    }
}   

export const getFloorDetail = (id) => {
    return function (dispatch) {
        dispatch(floorDetailRequest());
        tokenGet(apiSettings.apiURLReferences + '/floors/' + id).then((response) => {    
            dispatch(floorDetailSuccess({
                floorDetail: response.data.data[0],
            }));
        })
            .catch((error) => {
                console.log(error);
            });
    }
}

export const postFloorNew = (payload,factoryId) => {
    let notif = notificationSuccess('Successfully added floor')
    return function (dispatch) {
        dispatch(floorNewRequest());
        tokenPost(apiSettings.apiURLReferences + '/floors', payload).then(async (response) => {
            dispatch(floorNewSuccess({
                floor: response.data
            }));
            await setters().getFactoryTreeViewById(payload.factoryId, notif)
        })
        .catch((error) => {
            if (error.message.indexOf(500) >= 0){
                dispatch(notificationError('Floor code exists'));
                dispatch(floorTriggerHighlight(true));
            }
            console.log(error);
        });
    }
}

export const putFloorUpdate = (id, payload, factory) => {
    let notif = notificationSuccess('Successfully updated floor')
    return function (dispatch) {
        dispatch(floorUpdateRequest());
        tokenPut(apiSettings.apiURLReferences + '/floors/' + id, payload).then(async (response) => {
            dispatch(floorUpdateSuccess({
                floor: response.data
            }));
            await setters().getFactoryTreeViewById(payload.factoryId, notif)
        })
            .catch((error) => {
                if (error.message.indexOf(500) >= 0){
                    dispatch(notificationError('Floor code exists'));
                    dispatch(floorTriggerHighlight(true));
                }
                else if (error.message.indexOf(422) >= 0){
                    dispatch(notificationError("Cannot deactivate. There are machine(s) currently attached to the line"));
                }
                console.log(error);
            });
    }
}

export const deleteFloor = (id, factoryId) => {
    let notif = notificationSuccess('Successfully deleted floor type')
    return async function (dispatch) {
        dispatch(floorDeleteRequest());
        tokenDelete(apiSettings.apiURLReferences + '/floors/' + id).then( async (response) => {
            dispatch(floorDeleteSuccess({
                floor: response.data
            }));
            await setters().getFactoryTreeViewById(factoryId, notif)
        })
            .catch((error) => {
                if (error.message.indexOf(500) >= 0 || error.message.indexOf(422) >= 0){
                    dispatch(notificationError('Cannot delete because it is currently referenced to another record'));
                }
                console.log(error);
            });
    }
}