import React from 'react';
import Cropper from 'react-easy-crop';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { closeModal } from '../../../redux/actions/Modal/modalActions'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { checkIfSuperAdmin } from '../../../utils/helper';
import { uploadFactoryProfile } from '../../../redux/actions/Factory/factoryActions';
import { userChangeProfile, superAdminUploadUserProfile } from '../../../redux/actions/User/userActions';
import './CropImage.scss';
import { Hidden } from '@material-ui/core';

class CropImage extends React.Component {

  state = {
      modalName: 'cropImage',
      modalSettings: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none !important'
      },
      paperSettings: {
        position: 'relative',
        outline: 'none !important',
        backgroundColor: '#fff',
        boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.3)',
        padding: '2px 10px 3px 10px',
        width: '400px',
        overflow:'hidden',
      },
      image: null,
      crop: { x: 0, y: 0 },
      zoom: 2,
      aspect: 3 / 3,
      croppedAreaPixels: null
  }
 
  onCropChange = crop => {
    this.setState({ crop })
  }
 
  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({croppedAreaPixels: croppedAreaPixels});
  }
 
  onZoomChange = zoom => {
    this.setState({ zoom })
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0,0,canvas.width,canvas.height);
   
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
   
    // As Base64 string
    let base64Image = canvas.toDataURL('image/jpeg');
    return base64Image

   
    // As a blob
    // return new Promise((resolve, reject) => {
    //   canvas.toBlob(blob => {
    //     blob.name = fileName;
    //     resolve(blob);
    //   }, 'image/jpeg', 1);
    // });
  }

  componentDidMount(){
  }

  handleFileUpload = () =>{
      if (this.props.name === 'factory-profile'){
          let image = new Image();
          image.src = this.props.image;

          let croppedImage = this.getCroppedImg(image,this.state.croppedAreaPixels,'sample.jpeg');

          let fd = new FormData();
          let blobImage = this.dataURLtoFile(croppedImage,'sample.jpeg');
          fd.append('file',blobImage);
          fd.append('str', 'stronly');

          let payload = {
            ...this.props.factoryProfile,
            code: this.props.factoryProfile.factory,
            name: this.props.factoryProfile.desc1,
            desc: this.props.factoryProfile.desc2,
            timezone: this.props.factoryProfile.timeZone,
            method: 'factory-profile'
          }

          this.props.uploadFactoryProfile(this.props.id,payload,fd);
      }
      else if (this.props.name === 'factory-banner'){
        let image = new Image();
        image.src = this.props.image;

        let croppedImage = this.getCroppedImg(image,this.state.croppedAreaPixels,'sample.jpeg');

        let fd = new FormData();
        let blobImage = this.dataURLtoFile(croppedImage,'sample.jpeg');
        fd.append('file',blobImage);
        fd.append('str', 'stronly');

        let payload = {
          ...this.props.factoryProfile,
          code: this.props.factoryProfile.factory,
          name: this.props.factoryProfile.desc1,
          desc: this.props.factoryProfile.desc2,
          timezone: this.props.factoryProfile.timeZone,
          method: 'factory-banner'
        }

        this.props.uploadFactoryProfile(this.props.id,payload,fd);
      }
      
      else if (this.props.name === 'user-profile'){
        let image = new Image();
        image.src = this.props.image;

        let croppedImage = this.getCroppedImg(image,this.state.croppedAreaPixels,'sample.jpeg');

        // if (this.props.superAdmin){
        //   let fd = new FormData();
        //   let blobImage = this.dataURLtoFile(croppedImage, 'sample.jpeg');
        //   fd.append('file', blobImage);
        //   fd.append('str', 'stronly');
        //   this.props.superAdminUploadUserProfile(fd,croppedImage,this.props.username, this.props.id);
        // }
        // else{
        //   this.props.userChangeProfile({image:croppedImage, trigger:true});
        //   this.props.closeModal();
        // }

        if (this.props.id){

          let fd = new FormData();
          let blobImage = this.dataURLtoFile(croppedImage, 'sample.jpeg');
          fd.append('file', blobImage);
          fd.append('str', 'stronly');
          this.props.superAdminUploadUserProfile(fd,croppedImage,this.props.username, this.props.id);

        }
        else{
            this.props.userChangeProfile({image:croppedImage, trigger:true});
            this.props.closeModal();
        }
      }
  }

  dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
  }

  returnBase64ToFile(base64){
    var jpg = base64.split(',')[1];
    let file = new Blob([window.atob(jpg)],{type: 'image/png', encoding: 'utf-8'});
    return file;
  }

  handleSlider = (event,newValue) => {
      this.setState({zoom:newValue});
  }

  render(){
    return (
        <div>
            <Modal 
                open={this.props.modalShown === this.state.modalName} 
                onClose={this.props.closeModal}
                style={this.state.modalSettings}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout:500
                }}
            > 
                <Fade style={this.state.paperSettings} in={this.props.modalShown === this.state.modalName}>
                    <div className='crop-image-modal'>
                       <div onClick={this.props.closeModal} className="close">
                           <CloseIcon />
                       </div>
                       <div className="title">{ this.props.title }</div>
                      <div className="cropper-container">
                        <Cropper
                          image={this.props.image}
                          cropSize={this.props.size}
                          cropShape={this.props.cropShape}
                          crop={this.state.crop}
                          zoom={this.state.zoom}
                          aspect={this.state.aspect}
                          onCropChange={this.onCropChange}
                          onCropComplete={this.onCropComplete}
                          onZoomChange={this.onZoomChange}
                        />
                      </div>
                      <div className="slider-container">
                        <Slider className="blue" value={this.state.zoom} max={3} min={1} step={0.1} onChange={this.handleSlider} />
                      </div>
                      <div className="button-group">
                        <Grid container spacing={1}>
                            <Grid item xs={3} />
                            <Grid item xs={3}>
                                <Button className="cancel" onClick={this.props.closeModal} fullWidth variant="outlined">{'Cancel'.translate()}</Button>
                            </Grid>
                            <Grid item xs={3}>
                                <Button className="save" onClick={this.handleFileUpload} fullWidth variant="contained" color="primary">{'Save'.translate()}</Button>
                            </Grid>
                            <Grid item xs={3} />
                        </Grid>
                      </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
  }
}

const mapStateToProps = state => {
    let apiLoading = {
    }
    let data = {
      modalShown: state.modalReducer.modalShown,
      factoryProfile: state.factoryListReducer.factoryProfile,
      userProfile: state.userReducer.userProfile,
      userDetail: state.userReducer.userDetail,
    }
  
    return {
      ...apiLoading,
      ...data
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      closeModal: () => dispatch(closeModal()),
      uploadFactoryProfile: (id,payload,file) => dispatch(uploadFactoryProfile(id,payload,file)),
      userChangeProfile: (payload) => dispatch(userChangeProfile(payload)),
      superAdminUploadUserProfile: (image,croppedImage,username,id) => dispatch(superAdminUploadUserProfile(image,croppedImage,username,id)),
    }
  }

CropImage = connect(mapStateToProps,mapDispatchToProps)(CropImage);

export default CropImage;
