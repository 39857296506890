// this component build for new UI 
// it will replace MainPageBody component
import { Box } from '@material-ui/core';
import { Height } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';

export default withStyles({
  root: {
    backgroundColor: '#F7F7FB',
    minHeight: '80vh',
    height: 'auto',
    boxSizing: 'border-box',
    '& *': {
      boxSizing: 'border-box',
    }
  }
})(Box)