import React from 'react';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Typography from '../../components/Typography';
import Button from '../../components/Button';

const Container = withStyles({
  root: {
    minHeight: 150,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  }
})(Box)

export default function NoRecords({ onNewClicked }){
  return (
    <Container>
      <Typography>
        {'No records'.translate()}
      </Typography>
      <Button onClick={onNewClicked} variant="text" color="primary">
        {'ADD STATION & MACHINE'.translate()}
      </Button>
    </Container>
  ); 
}