import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from './Card'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles({
  table: {
   width: 65,
   margin:0,
   display: 'inline',
   paddingRight: '10px',
  },
  table2: {
    border:'none',
   },
  headCell: {
    backgroundColor:'#F2F2F2',
    cursor:'pointer',
   },
   td:{
    border:'none',
   },
   tableRow: {
     backgroundColor: '#F2F2F2',
     border:'none',
   },
   tableRowHide: {
     display: 'none'
   },
   adjustTable: {
      position: 'relative',
      top: '-15px',
   },
   displayNone: {
     display: 'none',
   }
});

const tableTheme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiTableCell: {
      // Name of the rule
      root: {
        padding: '0px',
      },
    },
  },
});


export default function SimpleTable(props) {
  const classes = useStyles();
  const linesData = props.linesData
  const totalStations = linesData.details.totalStations
  const machines = linesData.machines
  const initialRender = useRef(true);
  const drawerOpen = props.drawerOpen
  const [finalMachine, setFinalMachine] = React.useState([]);

  const checkIfNoTickets = (machines) => {
    if (machines.length > 0){
      // let check = machines.filter((item) => { return item.hasOpenTicket === "true" });
      // if (check.length <= 0){
      //     props.removeOverdueTab(props.lineNo);
      // }
      props.removeOverdueTab(props.lineNo);
    }
  }

  const getMachinesFinal = () => {
    let list = [];
    let count = 0
    let missing = []
    let sameNumber = ''
    for(let i = 0; i < machines.length; i++){
      list.push(parseInt(machines[i].station.replace(/\D/g,'')))
      let sortedNumber = parseInt(machines[i].station.replace(/\D/g,''))
      if(sortedNumber == sameNumber){
        sortedNumber = sortedNumber + 0.01
      }
      sameNumber = sortedNumber
      machines[i]['sortNumber'] = sortedNumber
      machines[i]['emptyStation'] = false
      if(i == machines.length - 1){
        count = parseInt(machines[i].station.replace(/\D/g,''))
      }
    }
    for (let i = 1; i <= count; i++) {
      if (list.indexOf(i) == -1) {
        machines.push({sortNumber: i, emptyStation: true, hasOpenTicket:"false",});
      }
    }

    let machinesSort = machines.sort((a, b) => a.sortNumber > b.sortNumber ? 1 : -1);
    let machineTickets = machinesSort.filter((item) => { return item.hasOpenTicket === 'true'});
    setFinalMachine(machineTickets);
    checkIfNoTickets(machineTickets)
  }

  useEffect(() => {
    getMachinesFinal();
    if(initialRender.current) {
        initialRender.current = false;
    }
  }, [machines]);

  const openTicketDetails = (ticketId, machineId) => {
    props.openTicketDetails(ticketId, machineId)
  }

  const displayEllipsis = (machines) =>{
    if (machines.length > 3){
       return <div className="ticket-ellipsis">...</div>
    }
  }

  
  const getConfig = (list,tag,type) =>{
    if (list){
      let config = list.filter((item) => { return item.config === tag});
      if (config.length > 0){
          if (type === 'id'){
              return config[0].id
          }
          else if (type === 'value'){
              return config[0].value
          }
          else{
              return config[0]
          }
      }
      else{
          return null;
      }
    }
}
  
  return (
    <ThemeProvider theme={tableTheme}>
      <Table className={classes.table  + ' ' + (drawerOpen === true ? classes.adjustTable : null )} aria-label="simple table">
        <TableBody>
          {finalMachine.slice(0,3).map((row) => {
            let listDuration = {
                RT1Dur: getConfig(props.systemPreference,'RT1Dur','value'),
                RT2Dur: getConfig(props.systemPreference,'RT2Dur','value'),
                RT3Dur: getConfig(props.systemPreference,'RT3Dur','value'),
            }
            return (
            <TableRow key={row.machineId } className={drawerOpen === true ? row.hasOpenTicket === "false" ? classes.displayNone : null : null}>
              <TableCell className={classes.td}>
              <Card hasOpenTicket={row.hasOpenTicket} 
              listDuration={listDuration}
              station={row.station} 
              machineNo={row.machineNo}
              ticketId={row.ticketId}
              machineId={row.machineId}
              emptyStation={row.emptyStation}
              ticketStatusId_desc={row.ticketStatusId_desc}
              ticketDetailsOpener={openTicketDetails}
              reportedDt={row.reportedDt}
              grabbedDt={row.grabbedDt}
              repairedDt={row.repairedDt}
              lineDetail={false}
              lineNo={props.lineNo}
              overdueTabs={props.overdueTabs}
              addOverdueTab={props.addOverdueTab}
              removeOverdueTab={props.removeOverdueTab}
              ></Card>
              </TableCell>
            </TableRow>
          )
        
        }
          
          )}
          { displayEllipsis(finalMachine) }
        </TableBody>
      </Table>
      </ThemeProvider>
  );
}
