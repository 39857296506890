import moment from 'moment';
import React from 'react';
import { Typography, Box, Link } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { withStyles } from '@material-ui/core/styles';
import { CircleButton } from './CircleButton';

const Container = withStyles({
  root: {
    display: 'flex',
    flex: 0.8,
  },
})(Box);

const DisplayValue = withStyles({
  root: {
    marginLeft: 10,
    marginRight: 10,
  },
})(Typography);

const TodayLink = withStyles({
  root: {
    marginLeft: 10,
    marginRight: 10,
    fontWeight: 'bold',
    fontSize: 14,
    color: "#1689FA",
  },
})(Link);


export default function DateSelector({ value, onChange }) {
  const setDateToToday = () => {
    onChange(moment());
  }
  return (
    <Container>
      <CircleButton onClick={() => onChange(moment(value).subtract(1, 'd'))}>
        <KeyboardArrowLeftIcon style={{ color: '#fff' }} />
      </CircleButton>
      <DisplayValue variant="h4">
        {value.format('ddd, MMM DD, YYYY')}
      </DisplayValue>

      <CircleButton onClick={() => onChange(moment(value).add(1, 'd'))}>
        <KeyboardArrowRightIcon style={{ color: '#fff' }} />
      </CircleButton>

      <TodayLink href="javascript:void(0);" onClick={()=> setDateToToday()}>
        {'Today'.translate()}
      </TodayLink>
    </Container>
  );
}
