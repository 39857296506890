import {
    NOTIFICATION_SUCCESS,
    NOTIFICATION_ERROR,
    INPUT_FOCUS,
    MACHINE_UPDATE_DATA,
    MACHINE_LIST,
    MACHINE_LIST_UPDATE_TOTAL,
    MACHINE_LIST_UPDATE_PAGE,
    MACHINE_LIST_UPDATE_DISPLAYED_ROW,
    MACHINE_LIST_REQUEST,
    MACHINE_LIST_SUCCESS,
    MACHINE_LIST_ERROR,
    MACHINE_LIST_SHOW_TOGGLE,
    MACHINE_LIST_CLOSE_TOGGLE,
    MACHINE_NEW_ITEM_REQUEST,
    MACHINE_NEW_ITEM_SUCCESS,
    MACHINE_NEW_ITEM_ERROR,
    MACHINE_RETIRE_REQUEST,
    MACHINE_RETIRE_SUCCESS,
    MACHINE_RETIRE_ERROR,
    MACHINE_UNRETIRE_REQUEST,
    MACHINE_UNRETIRE_SUCCESS,
    MACHINE_UNRETIRE_ERROR,
    MACHINE_UPDATE_REQUEST,
    MACHINE_UPDATE_SUCCESS,
    MACHINE_UPDATE_ERROR,
    MACHINE_VIEW_DETAIL_REQUEST,
    MACHINE_VIEW_DETAIL_SUCCESS,
    MACHINE_VIEW_DETAIL_ERROR,
    MACHINE_VIEW_TEMPLATES_SUCCESS,
    MACHINE_VIEW_TEMPLATES_REQUEST,
    TEMPLATES_LIST_REQUEST,
    TEMPLATES_LIST_SUCCESS,
    MACHINE_LIST_CHECKBOX_TOGGLE,
    MACHINE_LIST_TICKET_CREATION,
    MACHINE_HISTORIES_REQUEST,
    MACHINE_HISTORIES_SUCCESS
} from '../actionTypes';

import history from '../../../history';
import { tokenGet,tokenPost, tokenPut, apiSettings } from '../../../utils/api/CoreApi';
import { capitalize } from '../../../utils/helper';
import { BrowserRouter } from 'react-router-dom';
import moment from 'moment';

export const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
 }

export const notificationSuccess = (message) => {
    return {
        type: NOTIFICATION_SUCCESS,
        payload: {
            message: message.translate()
        }
    }
}

export const notificationError = (message) => {
    return {
        type: NOTIFICATION_ERROR,
        payload: {
            message: message.translate()
        }
    }
}

export const machineRequest = () => {
    return {
        type: MACHINE_LIST_REQUEST
    }
}

export const machineListSuccess = machines => {
    return {
        type: MACHINE_LIST_SUCCESS,
        payload: machines
    }
}

export const machineListTicketCreationSuccess = machines => {
    return {
        type: MACHINE_LIST_TICKET_CREATION,
        payload: machines
    }
}

export const machineListUpdateTotal = payload => {
    return {
        type: MACHINE_LIST_UPDATE_TOTAL,
        payload: payload
    }
}

export const machineListShowToggle = (id,flag) => {
    return {
        type: MACHINE_LIST_SHOW_TOGGLE,
        payload: {
            id: id,
            flag: flag
        }
    }
}

export const machineListCloseToggle = () => {
    return {
        type: MACHINE_LIST_CLOSE_TOGGLE,
    }
}

export const machineListUpdatePage = page => {
    return {
        type: MACHINE_LIST_UPDATE_PAGE,
        payload: page
    }
}

export const machineNewItemRequest = () => {
    return {
        type: MACHINE_NEW_ITEM_REQUEST,
    }
}

export const machineNewItemSuccess = (machine) => {
    return {
        type: MACHINE_NEW_ITEM_SUCCESS,
        payload: machine
    }
}

export const machineUpdateRequest = () => {
    return {
        type: MACHINE_UPDATE_REQUEST,
    }
}

export const machineUpdateSuccess = (machine) => {
    return {
        type: MACHINE_UPDATE_SUCCESS,
        machine: machine
    }
}

export const machineRetireRequest = () => {
    return {
        type: MACHINE_RETIRE_REQUEST,
    }
}

export const machineRetireSuccess = (id) => {
    return {
        type: MACHINE_RETIRE_SUCCESS,
        payload: id
    }
}



export const machineUnretireRequest = () => {
    return {
        type: MACHINE_UNRETIRE_REQUEST,
    }
}

export const machineUnretireSuccess = (id) => {
    return {
        type: MACHINE_UNRETIRE_SUCCESS,
        payload: id
    }
}

export const viewMachineDetailRequest = () => {
    return {
        type: MACHINE_VIEW_DETAIL_REQUEST,
    }
}

export const viewMachineDetailSucccess = (id) => {
    return {
        type: MACHINE_VIEW_DETAIL_SUCCESS,
        payload: id
    }
}

export const viewMachineTemplatesRequest = () => {
    return {
        type: MACHINE_VIEW_TEMPLATES_REQUEST,
    }
}

export const viewMachineTemplatesSucccess = machine => {
    return {
        type: MACHINE_VIEW_TEMPLATES_SUCCESS,
        payload: machine
    }
}

export const machineUpdateDisplayedRows = (rows) => {
    return {
        type: MACHINE_LIST_UPDATE_DISPLAYED_ROW,
        payload: rows
    }
}


export const inputFocus = (field) => {
    return {
        type: INPUT_FOCUS,
        payload: field
    }
}

export const templateRequest = () => {
    return {
        type: TEMPLATES_LIST_REQUEST
    }
}

export const templateListSuccess = templates => {
    return {
        type: TEMPLATES_LIST_SUCCESS,
        payload: templates
    }
}

export const toggleCheckboxSuccess = machines => {
    return {
        type: MACHINE_LIST_CHECKBOX_TOGGLE,
        payload: machines
    }
}

export const updateMachineData = machine => {
    return {
        type: MACHINE_UPDATE_DATA,
        payload: machine
    }
}

export const getMachineHistoriesAction = () => {
    return {
        type: MACHINE_HISTORIES_REQUEST,
    }
}

export const getMachineHistoriesSuccessAction = payload => {
    return {
        type: MACHINE_HISTORIES_SUCCESS,
        payload
    }
}

export const renderLocation = (field) => {
    if (field){
        let line = field.substring(0,3).toLowerCase();
        if (field === null || field === '' || field === 'null'){
            return ''
        }
        else{
            if(line == 'yti'){
                return field
            }else{
                let capitalized = field.split(' ').map(item =>
                    item.charAt(0).toUpperCase() + item.slice(1)).join(' ');
                    return capitalized
            }

        }
    }
    else{
        return '';
    }
}

export const toggleMachineTableCheckboxAll = (machines) => {
    return function(dispatch){
        let data = machines;
        let check = data.filter((item) => item.selected.value === false);
        if (check.length > 0){
            let tmpArray = data.map((item) => {
                let row = {
                    ...item,
                    selected: {
                        ...item.selected,
                        'value': true
                    }
                }
                return row;
            });
            dispatch(toggleCheckboxSuccess(tmpArray));
        }
        else{
            let tmpArray = data.map((item) => {
                let row = {
                    ...item,
                    selected: {
                        ...item.selected,
                        'value': false,
                    }
                }
                return row;
            });
            dispatch(toggleCheckboxSuccess(tmpArray));
        }
    }
}

export const toggleMachineTableCheckboxById = (machines, id) => {
    return function(dispatch){
        let data = machines;
        let rowPosition = data.map((item) => {return item.selected.id}).indexOf(id);
        if (rowPosition >= 0){
            data[rowPosition] = {
                ...data[rowPosition],
                selected: {
                    ...data[rowPosition].selected,
                    'value': !data[rowPosition].selected.value,
                }
            }
        }
        dispatch(toggleCheckboxSuccess(data));
    }
}

export const uncheckMachineTableCheckboxById = (machines,ids) => {
    return function(dispatch){
        let data = machines;
        ids.map((item) => {
            let rowPosition = data.map((item) => {return item.selected.id}).indexOf(item);
            if (rowPosition >= 0){
                data[rowPosition] = {
                    ...data[rowPosition],
                    selected: {
                        ...data[rowPosition].selected,
                        'value': false,
                    }
                }
            }
        });
        dispatch(toggleCheckboxSuccess(data));
    }
}

export const getMachines = (reloadCurrentPage = true, currentPage = 1, pageSize = 10, statusId = 'all', searchString = 'all') => {
    const startSeq = (currentPage - 1) * pageSize + 1;
    return function(dispatch){
        dispatch(machineRequest());
        let tmpSearchString = searchString;
        if (tmpSearchString === '' || tmpSearchString === null || tmpSearchString === 'all'){
            searchString = '';
            tmpSearchString = 'all';
        }
        if (statusId > 0){
            tokenGet(apiSettings.apiURLMachines + '/machines?statusId=' + statusId + '&searchString=' + tmpSearchString + '&currentPage=' + currentPage + '&pageSize=' + pageSize).then((response) => {
                let data = [];
                data = response.data.machines.result.sort((a,b) => { return a.createdDt < b.createdDt ? 1 : -1 }).map((item, itemIndex) => {
                    let location = null;
                    if (item.station === '' || item.station === null || item.station === '0'){
                        location = item.areaId_desc;
                    }
                    else{
                        location = item.mfgLineId_desc + ' - ' + item.station
                    }
                    let itemRow = {
                        'selected': {
                            'id' : item.id,
                            'value' : false,
                            'isLineReq': item.isLineReq,
                            'statusId': item.statusId
                        },
                        'seq': startSeq + itemIndex,
                        'machine': {
                            'link': '/machine-detail/' + item.id,
                            'linkId': item.id,
                            'value': item.machine,
                            'id': item.id,
                            "mfgLineId_desc": item.mfgLineId_desc,
                            "mfgLineId": item.mfgLineId,
                            "areaId_desc": item.areaId_desc
                        },
                        'model': item.model,
                        'macTypeId_desc': item.macTypeId_desc,
                        'macSubTypeId_desc': item.macSubTypeId_desc,
                        'brandId_desc': item.brandId_desc,
                        'motorTypeId_desc': item.motorTypeId_desc,
                        'aquisitionDt': item.aquisitionDt,
                        'serialNo': item.serialNo,
                        'assetNo': item.assetNo,
                        'building': item.buildingDesc,
                        'location': renderLocation(location),
                        'lastPMDt': item.lastPMDt,
                        'lastReportedDt': item.lastReportedDt,
                        'status': item.statusId_desc != 'INACTIVE' ? item.statusId_desc : 'N/A',
                        'machineCondition': item.statusId_desc == 'INACTIVE' ? 'Inactive' : 'Active',
                    }
                    return itemRow
                });

                tokenGet(apiSettings.apiURLMachines + '/machines?statusId=' + statusId + '&searchString=' + tmpSearchString + '&currentPage=1&pageSize=' + pageSize).then((responseAll) => {
                    dispatch(machineListSuccess({
                        machines: data,
                        excel: responseAll.data.machines.result,
                        searchString: searchString,
                        reloadCurrentPage: reloadCurrentPage,
                        meta: response.data.machines.meta
                    }));
                })
                .catch((error) => {
                    console.log(error);
                });
            })
            .catch((error) => {
                console.log(error);
            });
        }
        else{
            tokenGet(apiSettings.apiURLMachines + '/machines?currentPage=' + currentPage +'&pageSize=' + pageSize + '&searchString=' + tmpSearchString).then((response) => {
                let data = [];
                data = response.data.machines.result.sort((a,b) => { return a.createdDt < b.createdDt ? 1 : -1 }).map((item, itemIndex) => {
                    let location = null;
                    if (item.station === '' || item.station === null || item.station === '0'){
                        location = item.areaId_desc;
                    }
                    else{
                        location = item.mfgLineId_desc + ' - ' + item.station
                    }
                    let itemRow = {
                        'selected': {
                            'id' : item.id,
                            'value' : false,
                            'isLineReq': item.isLineReq,
                            'statusId': item.statusId
                        },
                        'seq': startSeq + itemIndex,
                        'machine': {
                            'link': '/machine-detail/' + item.id,
                            'linkId': item.id,
                            'value': item.machine,
                            'id': item.id,
                            "mfgLineId_desc": item.mfgLineId_desc,
                            "mfgLineId": item.mfgLineId,
                            "areaId_desc": item.areaId_desc
                        },
                        'model': item.model,
                        'macTypeId_desc': item.macTypeId_desc,
                        'macSubTypeId_desc': item.macSubTypeId_desc,
                        'brandId_desc': item.brandId_desc,
                        'motorTypeId_desc': item.motorTypeId_desc,
                        'aquisitionDt': item.aquisitionDt,
                        'serialNo': item.serialNo,
                        'assetNo': item.assetNo,
                        'building': item.buildingDesc,
                        'location': renderLocation(location),
                        'lastPMDt': item.lastPMDt,
                        'lastReportedDt': item.lastReportedDt,
                        'status': item.statusId_desc != 'INACTIVE' ? item.statusId_desc : 'N/A',
                        'machineCondition': item.statusId_desc == 'INACTIVE' ? 'Inactive' : 'Active',
                    }
                    return itemRow
                });
                tokenGet(apiSettings.apiURLMachines + '/machines?searchString=' + tmpSearchString + '&currentPage=1&pageSize=' + pageSize).then((responseAll) => {
                    dispatch(machineListSuccess({
                        machines: data,
                        excel: responseAll.data.machines.result,
                        searchString: searchString,
                        reloadCurrentPage: reloadCurrentPage,
                        meta: response.data.machines.meta
                    }));
                })
                .catch((error) => {
                    console.log(error);
                });
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }
}

export const getMachinesPagination = (reloadCurrentPage = true, currentPage = 1, pageSize = 10, statusId = 'all', searchString = 'all', isActive = '1') => {
    const startSeq = (currentPage - 1) * pageSize + 1;
    return function(dispatch){
        dispatch(machineRequest());
        let tmpSearchString = searchString;
        if (tmpSearchString === '' || tmpSearchString === null || tmpSearchString === 'all'){
            searchString = '';
            tmpSearchString = 'all';
        }
        const query = {
            isActive,
            statusId,
            searchString: tmpSearchString,
            currentPage,
            pageSize,
        };
        tokenGet(`${apiSettings.apiURLMachines}/machines?${new URLSearchParams(query)}`).then((response) => {
            let data = [];
            data = response.data.machines.result.sort((a,b) => { return a.createdDt < b.createdDt ? 1 : -1 }).map((item, itemIndex) => {
                let location = null;
                if (item.station === '' || item.station === null || item.station === '0'){
                    location = item.areaId_desc;
                }
                else{
                    location = item.mfgLineId_desc + ' - ' + item.station
                }
                let itemRow = {
                    'selected': {
                        'id' : item.id,
                        'value' : false,
                        'isLineReq': item.isLineReq,
                        'statusId': item.statusId,
                        'isActive': item.isActive,
                    },
                    'seq': startSeq + itemIndex,
                    'machine': {
                        'link': '/machine-detail/' + item.id,
                        'linkId': item.id,
                        'value': item.machine,
                        'id': item.id,
                        "mfgLineId_desc": item.mfgLineId_desc,
                        "mfgLineId": item.mfgLineId,
                        "areaId_desc": item.areaId_desc
                    },
                    'model': item.model,
                    'macTypeId_desc': item.macTypeId_desc,
                    'macSubTypeId_desc': item.macSubTypeId_desc,
                    'brandId_desc': item.brandId_desc,
                    'motorTypeId_desc': item.motorTypeId_desc,
                    'aquisitionDt': item.aquisitionDt,
                    'serialNo': item.serialNo,
                    'assetNo': item.assetNo,
                    'building': item.buildingDesc,
                    'location': renderLocation(location),
                    'lastPMDt': item.lastPMDt,
                    'lastRepairedDt': item.lastRepairedDt,
                    'status': item.isActive 
                        ? item.statusId_desc === 'INACTIVE' 
                            ? 'N/A' 
                            : item.statusId_desc 
                        : 'N/A',
                    'machineCondition': item.isActive ? 'Active' : 'Inactive',
                }
                return itemRow
            });

            dispatch(machineListSuccess({
                machines: data,
                searchString: searchString,
                reloadCurrentPage: reloadCurrentPage,
                meta: response.data.machines.meta
            }));
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const getMachineById = (payload) => {
    return function(dispatch){
        dispatch((viewMachineDetailRequest));
        tokenGet(apiSettings.apiURLMachines + '/machines/' + payload).then((response) => {
            dispatch(viewMachineDetailSucccess(response.data));
            // Get machine logs
            if(response.data.success) {
                dispatch(getMachineHistories(response.data.machine[0].machine));
            }

            if (localStorage.getItem('createdTicket') === 'true'){
                localStorage.setItem('createdTicket',false);
                dispatch(putUpdateMachineData(response.data.machine[0]));
            }
        }).catch((error) => {
            console.log(error);
        })
    }
}

export const getMachineTemplatesById = (payload) => {
    return function(dispatch){
        dispatch((viewMachineTemplatesRequest));
        tokenGet(apiSettings.apiURLMachines + '/machines/' + payload + '/checklists').then((response) => {
            let data = [];
            data = response.data.machine.map((item) => {
                let itemRow = {
                    'templateName': item.templateName,
                    'checkListNo': item.checkListNo,
                    'tasksSteps': item.tasksSteps,
                    'subTasksSteps': item.subTasksSteps,
                    'macType': item.macType,
                    'brand': item.brand,
                    'createdDt': item.createdDt,
                    'addedDt': item.addedDt,
                    'templateStatus': item.templateStatus,
                    'isActive': item.isActive ? 'Active' : 'InActive',
                }
                return itemRow
            });
            dispatch(viewMachineTemplatesSucccess({
                machine: data,
            }));
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const getTemplates = (isActive = 1, brandId = 'all', macSubTypeId = 'all', searchString = 'all', currentPage = 1, pageSize = 100000) => {
    return function(dispatch){
        dispatch(templateRequest());
        
            const query = {isActive, brandId, macSubTypeId, searchString, currentPage, pageSize}
            tokenGet(apiSettings.apiURLReferences + `/checklists?${new URLSearchParams(query).toString()}`).then((response) => {
                let data = [];
                data = response.data.machines.result.map((item) => {
                    let itemRow = {
                        'id': item.id,
                        'name': item.name,
                        'no': item.no,
                        'tempTypeId': item.tempTypeId,
                        'checklistType': item.checklistType,
                        'brandId': item.brandId,
                        'brand': item.brand,
                        'frequency': item.frequency,
                        'macSubTypeId': item.macSubTypeId,
                        'macsubtype': item.macsubtype,
                        'macsubtype_desc': item.macsubtype_desc,
                        'tasksSteps': item.tasksSteps,
                        'subTasksSteps': item.subTasksSteps,
                        'templateStatus': item.templateStatus,
                        'createdDt': item.createdDt,
                        'isActive': item.isActive ? 'ACTIVE' : 'INACTIVE',
                    }
                    return itemRow
                });
                let activeData = data.filter(x => x.templateStatus === 'ACTIVE')
                console.log('lets check template details')
                console.log(activeData)
                dispatch(templateListSuccess({
                    templates: activeData,
                    // meta: response.data.machines.meta
                }));
            })
            .catch((error) => {
                console.log(error);
            });

    }
}

export const postMachineNewItem = (payload) => {
    return function(dispatch){
        dispatch(machineNewItemRequest());
        tokenPost(apiSettings.apiURLMachines + '/machines', payload).then((response) => {
            if (response.data.machine.code){
                if (response.data.machine.errno === 1062){
                    dispatch(notificationError('Machine number already exists'));
                    dispatch(inputFocus('machineNo'));
                }
                else{
                    dispatch(notificationError('Cannot save machine. Error Code: ' + response.data.machine.code));
                }
            }
            else{
                let location = null;
                if (response.data.machine[0].station === '' || response.data.machine[0].station === null || response.data.machine[0].station === '0'){
                    location = response.data.machine[0].areaId_desc.toUpperCase();
                }
                else{
                    location = response.data.machine[0].mfgLineId_desc + ' - ' + response.data.machine[0].station
                }
                let newItem = {

                    'selected': {
                        'id' : response.data.machine[0].id,
                        'value' : false,
                        'isLineReq': response.data.machine[0].isLineReq,
                        'statusId': response.data.machine[0].statusId
                    },
                    seq: 1,
                    'machine': {
                        'link': '/machine-detail/' + response.data.machine[0].id,
                        'linkId': response.data.machine[0].id,
                        'value': response.data.machine[0].machine
                    },
                    'model': response.data.machine[0].model,
                    'macTypeId': response.data.machine[0].macTypeId,
                    'macSubType': response.data.machine[0].macSubTypeId_desc,
                    'brandId': response.data.machine[0].brandId,
                    'motorTypeId': response.data.machine[0].motorTypeId,
                    'aquisitionDt': response.data.machine[0].aquisitionDt,
                    'serialNo': response.data.machine[0].serialNo,
                    'assetNo': response.data.machine[0].assetNo,
                    'building': response.data.machine[0].buildingDesc,
                    'location': titleCase(location),
                    'lastPMDt': response.data.machine[0].lastPMDt,
                    'lastReportedDt': response.data.machine[0].lastReportedDt,
                    'status': response.data.machine[0].statusId_desc,
                }
                let arrayNewItem = [];
                arrayNewItem.push(newItem);
                dispatch(machineNewItemSuccess(arrayNewItem));
                dispatch(notificationSuccess('Successfully added new machine'));
                localStorage.setItem('addedMachine','true');
                history.push('/machines');
            }
        }).catch((error) => {
            let errors = error.response.data.errors;
            let shownError = false;
            console.log(errors);
            errors.map((item) => {
                if (item['machine'] && shownError === false){
                    dispatch(notificationError('Machine number already exists'));
                    dispatch(inputFocus('machineNo'));
                    shownError = true;
                }
                else if (item['serialNo'] && shownError === false){
                    dispatch(notificationError('Serial number already exists'));
                    dispatch(inputFocus('serialNo'));
                    shownError = true;
                }
                else if (item['assetNo'] && shownError === false){
                    dispatch(notificationError('Asset number already exists'));
                    dispatch(inputFocus('assetNo'));
                    shownError = true;
                }
                else if (item['rfid'] && shownError === false){
                    dispatch(notificationError('RFID already exists'));
                    dispatch(inputFocus('rfid'));
                    shownError = true;
                }
            })
        })
    }
}

export const putUpdateMachine = (id,payload) => {
    return function(dispatch){
        dispatch(machineUpdateRequest());
        tokenPut(apiSettings.apiURLMachines + '/machines/' + id, payload).then((response) => {
            if (response.data.machine.code){
                if (response.data.machine.errno === 1062){
                    dispatch(notificationError('Machine number already exists'));
                    dispatch(inputFocus('machineNo'));
                }
                else{
                    dispatch(notificationError('Cannot save machine. Error Code: ' + response.data.machine.code));
                }
            }
            else{
                let location = null;
                if (response.data.machine[0].station === '' || response.data.machine[0].station === null || response.data.machine[0].station === '0'){
                    location = response.data.machine[0].areaId_desc;
                }
                else{
                    location = response.data.machine[0].mfgLineId_desc + ' - ' + response.data.machine[0].station
                }

                let updatedItem = {

                    'selected': {
                        'id' : response.data.machine[0].id,
                        'value' : false,
                        'isLineReq': response.data.machine[0].isLineReq,
                        'statusId': response.data.machine[0].statusId
                    },
                    'machine': {
                        'link': '/machine-detail/' + response.data.machine[0].id,
                        'linkId': response.data.machine[0].id,
                        'value': response.data.machine[0].machine
                    },
                    'model': response.data.machine[0].model,
                    'macTypeId': response.data.machine[0].macTypeId,
                    'macSubType': response.data.machine[0].macSubTypeId_cd,
                    'brandId': response.data.machine[0].brandId,
                    'motorTypeId': response.data.machine[0].motorTypeId,
                    'aquisitionDt': response.data.machine[0].aquisitionDt,
                    'serialNo': response.data.machine[0].serialNo,
                    'assetNo': response.data.machine[0].assetNo,
                    'building': response.data.machine[0].buildingDesc,
                    'location': location,
                    'lastPMDt': response.data.machine[0].lastPMDt,
                    'lastReportedDt': response.data.machine[0].lastReportedDt,
                    'status': response.data.machine[0].statusId_desc,
                }
                dispatch(machineUpdateSuccess(updatedItem));
                dispatch(getMachineById(id));
                dispatch(notificationSuccess('Successfully updated machine'));
                history.push('/machine-detail/' + id);
            }
        }).catch((error) => {
            console.log(error)
            let errors = error.response.data.errors;
            let shownError = false;
            if(!errors || errors.length === 0) return false;
            errors.map((item) => {
                if (item['machine'] && shownError === false){
                    dispatch(notificationError('Machine number already exists'));
                    dispatch(inputFocus('machineNo'));
                    shownError = true;
                }
                else if (item['serialNo'] && shownError === false){
                    dispatch(notificationError('Serial number already exists'));
                    dispatch(inputFocus('serialNo'));
                    shownError = true;
                }
                else if (item['assetNo'] && shownError === false){
                    dispatch(notificationError('Asset number already exists'));
                    dispatch(inputFocus('assetNo'));
                    shownError = true;
                }
                else if (item['rfid'] && shownError === false){
                    dispatch(notificationError('RFID already exists'));
                    dispatch(inputFocus('rfid'));
                    shownError = true;
                }
            })
        })
    }
}

export const putRetireMachine = (machineIds, machineById = null, currentPage = 1) => {
    return function(dispatch){
        dispatch(machineRetireRequest());
        tokenPut(apiSettings.apiURLMachines + '/machines/retire', machineIds).then((response) => {
            if (machineById !== null){
                // dispatch(machineRetireSuccess(machineById));
                dispatch(getMachineById(machineById));
                dispatch(getMachines(false, currentPage));
            }
            else{
                dispatch(getMachines(false, currentPage));
            }
            dispatch(notificationSuccess('Successfully inactive machine(s)'));
        }).catch((error) => {
            let capitalizedError = capitalize(error.response.data.errors[0].ids);
            dispatch(notificationError(capitalizedError));
        })
    }
}

export const putUnretireMachine = (machineIds, machineById = null, currentPage = 1) => {
    return function(dispatch){
        dispatch(machineUnretireRequest());
        tokenPut(apiSettings.apiURLMachines + '/machines/unretire', machineIds).then((response) => {
            if (machineById !== null){
                // dispatch(machineUnretireSuccess(machineById));
                dispatch(getMachineById(machineById));
                dispatch(getMachines(false, currentPage));
            }
            else{
                dispatch(getMachines(false, currentPage));
            }
            dispatch(notificationSuccess('Machine is now Available'));
        }).catch((error) => {
            let capitalizedError = capitalize(error.response.data.errors[0].ids);
            dispatch(notificationError(capitalizedError));
        })
    }
}

export const getMachinesForTicketCreation = () => {
    return function(dispatch){
        tokenGet(apiSettings.apiURLTickets + '/api/tickets/machines-for-rt-creation').then((response) => {
            dispatch(machineListTicketCreationSuccess(response.data.machines));
        }).catch((error) => {
            let capitalizedError = capitalize(error.response.data.errors[0].ids);
            dispatch(notificationError(capitalizedError));
        })
    }
}

export const putUpdateMachineData = (machine) => {
    return function(dispatch){
        let location = null;
        if (machine.station === '' || machine.station === null || machine.station === '0'){
            location = machine.areaId_desc;
        }
        else{
            location = machine.mfgLineId_desc + ' - ' + machine.station
        }
        let updatedItem = {
            'selected': {
                'id' : machine.id,
                'value' : false,
                'isLineReq': machine.isLineReq,
                'statusId': machine.statusId
            },
            'machine': {
                'link': '/machine-detail/' + machine.id,
                'linkId': machine.id,
                'value': machine.machine
            },
            'model': machine.model,
            'macTypeId': machine.macTypeId,
            'macSubType': machine.macSubTypeId_desc,
            'brandId': machine.brandId,
            'motorTypeId': machine.motorTypeId,
            'aquisitionDt': machine.aquisitionDt,
            'serialNo': machine.serialNo,
            'assetNo': machine.assetNo,
            'building': machine.buildingDesc,
            'location': location,
            'lastPMDt': machine.lastPMDt,
            'lastReportedDt': machine.lastReportedDt,
            'status': machine.statusId_desc,
        }
        dispatch(updateMachineData(updatedItem));
    }
}

export const getMachineHistories = machine => {
    const encodedMachineNo = encodeURIComponent(machine);
    return function(dispatch){
        dispatch((getMachineHistoriesAction()));
        tokenGet(apiSettings.apiURLMachines + '/machines/' + encodedMachineNo + '/logs').then((response) => {
            dispatch(getMachineHistoriesSuccessAction(response.data.logs));
        }).catch((error) => {
            console.log(error);
        })
    }
}