import {
    NOTIFICATION_SUCCESS,
    NOTIFICATION_ERROR,
    AUTH_LOGIN_REQUEST,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGOUT_REQUEST,
    AUTH_LOGOUT_SUCCESS,
    AUTH_UPDATE_ACCESS,
    AUTH_LOGIN_ERROR,
} from '../actionTypes';
import history from '../../../history';
import { getMachines } from '../../../redux/actions/Machine/machineActions';
import { getStatus } from '../../../redux/actions/Status/StatusActions';
import { getMacTypes } from '../../../redux/actions/MacTypes/MacTypeActions';
import { getBrands } from '../../../redux/actions/Brand/brandActions';
import { getMotorTypes } from '../../../redux/actions/MotorTypes/motorTypeActions';
import { tokenGet, tokenPut, tokenPost, apiSettings } from '../../../utils/api/CoreApi';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

export const notificationSuccess = (message) => {
    return {
        type: NOTIFICATION_SUCCESS,
        payload: {
            message: message.translate()
        }
    }
}

export const notificationError = (message) => {
    return {
        type: NOTIFICATION_ERROR,
        payload: {
            message: message.translate()
        }
    }
}

export const loginRequest = () => {
    return {
        type: AUTH_LOGIN_REQUEST
    }
}

export const loginSuccess = payload => {
    return {
        type: AUTH_LOGIN_SUCCESS,
        payload: payload
    }
}

export const loginError = payload => {
    return {
        type: AUTH_LOGIN_ERROR,
    }
}



export const logoutSuccess = () => {
    return {
        type: AUTH_LOGOUT_SUCCESS,
    }
}


export const updateAccess = payload => {
    return {
        type: AUTH_UPDATE_ACCESS,
        payload: payload
    }
}

export const postLogin = (payload) => {
    return function (dispatch) {
        dispatch(loginRequest());
        axios.post(apiSettings.apiURLAuth + '/api/login', payload).then((response) => {
            let tokenDecoded = jwtDecode(response.data.token);
            dispatch(loginSuccess({
                token: response.data.token,
                access: response.data.access,
                factoryId: tokenDecoded.factoryId,
                username: payload.username,
                userId: tokenDecoded.userId,
            }));

            if (response.data.isSuperAdmin === true || response.data.isSuperAdmin === 'true') {
                if (localStorage.getItem('triggerSuperAdminDefault') === 'false') {

                }
                else {
                    if (process.env.REACT_APP_API_ADMIN_FACTORY_ID !== tokenDecoded.factoryId.toString()) {
                        let loadDefault = {
                            factoryId: parseInt(process.env.REACT_APP_API_ADMIN_FACTORY_ID),
                            username: payload.username,
                            password: payload.password,
                        }
                        localStorage.setItem('triggerSuperAdminDefault', 'false');
                        dispatch(postLogin(loadDefault));
                        return false;
                    }
                }
            }

            localStorage.setItem('userToken', JSON.stringify(response.data.token));
            localStorage.setItem('userAccess', JSON.stringify(response.data.access));
            localStorage.setItem('userRole', JSON.stringify(response.data.role));
            localStorage.setItem('username', JSON.stringify(payload.username));
            localStorage.setItem('userData', btoa(payload.username + "::" + payload.password));


            dispatch(getMachines());
            dispatch(getStatus());
            dispatch(getMacTypes());
            dispatch(getBrands());
            dispatch(getMotorTypes());

            localStorage.removeItem('paramUserToken');
            window.location.reload();
        })
            .catch((error) => {
                dispatch(notificationError('Invalid credentials. Please try again.'));
                dispatch(loginError());
                console.log(error);
            });
    }
}

export const postLoginNew = (payload) => {
    return function (dispatch) {
        dispatch(loginRequest());
        axios.post(apiSettings.apiURLAuthGlobalAdmin + '/auth/switch-factory', payload).then((response) => {
            localStorage.setItem('userToken', `"` + response.data.accessToken + `"`);
            localStorage.setItem('refreshToken', `"` + response.data.refreshToken + `"`);
            localStorage.removeItem('paramUserToken');
            window.location.reload();
        })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message === 'cannot switch to same Factory ID') {
                    window.location.reload();
                } else {
                    dispatch(notificationError('Invalid credentials. Please try again.'));
                    dispatch(loginError());
                }
            });
    }
}

export const postLogout = (payload = {}) => {
    return function (dispatch) {
        const { companyCode } = payload;
        if (!!!companyCode) {
            clearLoginStorage();
            window.location.href = process.env.REDIRECT_PAGE; //@todo: Will not use hard-code
            return false;
        }

        axios.post(`${apiSettings.apiURLAuthGlobalAdmin}/companylogin`, payload).then(({ data }) => {
            clearLoginStorage();
            window.location.href = data.url;
            return false;
        });
    }
}

function clearLoginStorage() {
    const keys = [
        'userToken',
        'refreshToken',
        'userAccess',
        'userRole',
        'username',
        'paramUserToken',
        'userData',
        'factoriesAssigned',
        'redirectAssigned',
        'triggerSuperAdminDefault',
        'buildID',
        'valueBulidDefaul',
        'getLineVal'
    ];
    keys.forEach((key) => localStorage.removeItem(key));
}

export const putChangePassword = (payload) => {
    return function (dispatch) {
        tokenPut(apiSettings.apiURLAuth + '/api/login/' + payload.id + '/resetpwd', payload).then((response) => {
            dispatch(notificationSuccess('Successfully changed password'));
        })
    }
}
