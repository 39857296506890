import {
    MOTOR_TYPE_LIST_REQUEST,
    MOTOR_TYPE_LIST_SUCCESS,
    MOTOR_TYPE_LIST_ERROR, 
    MOTOR_TYPE_DETAIL_REQUEST,
    MOTOR_TYPE_DETAIL_SUCCESS,
    MOTOR_TYPE_DETAIL_ERROR,
    MOTOR_TYPE_NEW_REQUEST,
    MOTOR_TYPE_NEW_SUCCESS,
    MOTOR_TYPE_NEW_ERROR,
    MOTOR_TYPE_UPDATE_REQUEST,
    MOTOR_TYPE_UPDATE_SUCCESS,
    MOTOR_TYPE_UPDATE_ERROR,
    MOTOR_TYPE_TRIGGER_HIGHLIGHT,
} from '../../actions/actionTypes';

const initialState = {
    loading: false,
    detailLoading: false,
    triggerHighlight: false,
    motorTypeList:[],
    motorTypeDetail: null,
    meta: [],
    error: ''
}

const motorTypeListReducer = (state = initialState, action) => {
    let tmpMotorTypeList = state.motorTypeList;
    switch (action.type) {
        case MOTOR_TYPE_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case MOTOR_TYPE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                motorTypeList: action.payload.motorTypeList,
                meta: action.payload.meta,
                error: ''
            }
        case MOTOR_TYPE_LIST_ERROR:
            return {
                ...state,
                loading: false,
                brandList: [],
                meta: [],
                error: action.payload.message
            }
        case MOTOR_TYPE_DETAIL_REQUEST:
            return {
                ...state,
                detailLoading: true,
                motorTypeDetail: null,
            }
        case MOTOR_TYPE_DETAIL_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                motorTypeDetail: action.payload.motorTypeDetail
            }
        case MOTOR_TYPE_NEW_SUCCESS:
            tmpMotorTypeList.push(action.payload.motorTypeList);
            return {
                ...state,
                loading: false,
                motorTypeList: tmpMotorTypeList,
                meta: action.payload.meta,
                error: ''
            }
        case MOTOR_TYPE_TRIGGER_HIGHLIGHT:
            return {
                ...state,
                triggerHighlight: action.payload
            }
        default: 
            return state;
    }
}

export default motorTypeListReducer;