import {
    NOTIFICATION_SUCCESS,
    NOTIFICATION_ERROR,
    USER_LIST,
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_ERROR,
    USER_DETAIL_REQUEST,
    USER_DETAIL_SUCCESS,
    USER_DETAIL_ERROR,
    USER_NEW_REQUEST,
    USER_NEW_SUCCESS,
    USER_NEW_ERROR,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_ERROR,
    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_ERROR,
    USER_TRIGGER_HIGHLIGHT,
    USER_CHANGE_PROFILE,
    USER_LIST_FACTORIES,
} from '../actionTypes';
import { isSuperAdmin, getFactoryId } from '../../../utils/helper';
import { closeModal } from '../../../redux/actions/Modal/modalActions';
import { postLogout } from '../../../redux/actions/Auth/authActions';
import { getFactoryTreeView } from '../../../redux/actions/Factory/factoryActions'
import { getUserId } from '../../../utils/helper';
import { tokenGet, tokenPost, tokenPostUpload, tokenPut, tokenDelete, apiSettings } from '../../../utils/api/CoreApi';
import history from '../../../history';

export const notificationSuccess = (message) => {
    return {
        type: NOTIFICATION_SUCCESS,
        payload: {
            message: message.translate()
        }
    }
}

export const notificationError = (message) => {
    return {
        type: NOTIFICATION_ERROR,
        payload: {
            message: message.translate()
        }
    }
}

export const userRequest = () => {
    return {
        type: USER_LIST_REQUEST
    }
}

export const userSuccess = userList => {
    return {
        type: USER_LIST_SUCCESS,
        payload: userList
    }
}

export const userDetailRequest = () => {
    return {
        type: USER_DETAIL_REQUEST
    }
}

export const userDetailSuccess = userDetail => {
    return {
        type: USER_DETAIL_SUCCESS,
        payload: userDetail
    }
}

export const userNewRequest = () => {
    return {
        type: USER_NEW_REQUEST
    }
}

export const userNewSuccess = user => {
    return {
        type: USER_NEW_SUCCESS,
    }
}

export const userUpdateRequest = () => {
    return {
        type: USER_UPDATE_REQUEST
    }
}

export const userUpdateSuccess = user => {
    return {
        type: USER_UPDATE_SUCCESS,
        payload: user
    }
}

export const userDeleteRequest = () => {
    return {
        type: USER_DELETE_REQUEST
    }
}

export const userDeleteSuccess = user => {
    return {
        type: USER_DELETE_SUCCESS,
        payload: user
    }
}

export const userTriggerHighlight = flag => {
    return {
        type: USER_TRIGGER_HIGHLIGHT,
        payload: flag
    }
}

export const userChangeProfile = (payload) => {
    return {
        type: USER_CHANGE_PROFILE,
        payload: payload
    }
}



export const getUsers = (filtered = true, isActive = 'all', currentPage = 1, pageSize = 100000, sortInstance = {}) => {
    localStorage['filtered'] = filtered
    return function (dispatch) {
        dispatch(userRequest());
        let { column = undefined, sort = undefined, nameSort = undefined, dateSort = undefined, searchString = undefined } = sortInstance
        let isColumn = column ? `&column=${column}` : ``
        let isSort = sort ? `&sort=${sort}` : ``
        let isNameSort = nameSort ? `&nameSort=${nameSort}` : ``
        let isDateSort = dateSort ? `&dateSort=${dateSort}` : ``
        let isSearch = searchString ? `&searchString=${searchString}` : ``
        let sortQuery = isColumn + isSort + isNameSort + isDateSort + isSearch
        tokenGet(apiSettings.apiURLReferences + '/users?isActive=' + isActive + '&currentPage=' + currentPage + '&pageSize=' + pageSize + sortQuery).then((response) => {
            let data
            if (localStorage['filtered'] == true) {
                data = response.data.data.result.map((item) => {
                    let itemRow = {
                        'id': item.id,
                        'desc1': item.desc1,
                        'isActive': item.isActive,
                    }
                    return itemRow;
                })
            }
            else data = response.data.data.result
            delete localStorage['filtered']
            if (!isSuperAdmin()){
                data = data.filter((item) => { return item.assigned !== '000'});
            }
            dispatch(userSuccess({
                userList: data,
                meta: response.data.data.meta
            }));
        })
            .catch((error) => {
                console.log(error);
            });
    }
}

export const getUserDetail = (id) => {
    return function (dispatch) {
        dispatch(userDetailRequest());
        tokenGet(apiSettings.apiURLReferences + '/users/' + id).then((response) => {
            let userDetail = response.data.user[0];
            dispatch(userDetailSuccess({
                userDetail: userDetail
            }));
        })
            .catch((error) => {
                console.log(error);
            });
    }
}

export const getFactoryByUser = (id, isTree = false) => {
    return async function (dispatch) {
        dispatch({
            type: USER_LIST_FACTORIES,
            payload: {
                userProfileFactoriesTree: null,
                userProfileFactoriesFiltered: null,
                userProfileFactoriesTreeTimeStamp: null,
                userProfileDetailsTree: null,
                status: null
            }
        });

        try {
            let { data: { factories: data, user }, status } = await tokenGet(`${apiSettings.apiURLReferences}/users/${id}`)
            if(isTree){
                let filteredData = [];
                if (isSuperAdmin()){
                    filteredData = data.filter((item) => { return item.details.code !== '000' && item.details.status === 'Active'});
                }
                else{
                    filteredData = data.filter((item) => { return item.details.code !== '000' && item.details.status === 'Active'});
                }
               
                if (filteredData.length > 0){
                    dispatch({
                        type: USER_LIST_FACTORIES,
                        payload: {
                            userProfileFactoriesTree: data,
                            userProfileFactoriesFiltered: filteredData,
                            userProfileFactoriesTreeTimeStamp: new Date(),
                            userProfileDetailsTree: user,
                            status
                        }
                    })
                }
                else{
                    dispatch(getFactoryTreeView());
                }
            }
            else{
                let filteredData = data.filter((item) => { return item.details.code !== '000'});
                if (filteredData.length > 0){
                    dispatch({
                        type: USER_LIST_FACTORIES,
                        payload: {
                            userProfileFactories: data,
                            userProfileFactoriesFiltered: filteredData,
                            userProfileFactoriesTimeStamp: new Date(),
                            userProfileDetails: user,
                            status
                        }
                    })
                }
                else{
                    dispatch(getFactoryTreeView());
                }
            }
        } catch (error) {
            console.error('userActions', error)
        }
    }
}

export const postNewUser = (payload) => {
    return function (dispatch) {
        dispatch(userNewRequest());
        tokenPost(apiSettings.apiURLAuth + '/api/login/password/hash', { password: payload.password }).then((response) => {
            payload = {
                ...payload,
                password: response.data.result
            }
            tokenPost(apiSettings.apiURLReferences + "/users", payload).then((response) => {
                dispatch(userNewSuccess());
                dispatch(notificationSuccess('Successfully added user'));
                history.push('/configuration/user-manage/users');
            })
            .catch((err) => {
                let errors = err.response.data.errors;
                if (Array.isArray(errors)){
                    if (errors[0].userName) {
                        dispatch(notificationError('Login ID exists'));
                        dispatch(userTriggerHighlight('username'));
                    }
                    else if (errors[0].email){
                        dispatch(notificationError(errors[0].email));
                        dispatch(userTriggerHighlight('email'));
                    }
                    else if (errors[0].cardNo){
                        dispatch(notificationError('Card ID already exists'));
                        dispatch(userTriggerHighlight('cardNo'));
                    }
                }
                else{
                    dispatch(notificationError(err.response.data.error));
                }
                console.log(errors);
            });
        });
    }
}

export const putUpdateUser = (id, payload) => {
    return function (dispatch) {
        dispatch(userUpdateRequest());
        tokenPut(apiSettings.apiURLReferences + "/users/avatar", payload).then((response) => {
            tokenPut(apiSettings.apiURLReferences + "/users/", payload).then((response) => {
                dispatch(getUserDetail(id));
                // dispatch(getUsers());
                dispatch(userUpdateSuccess());
                dispatch(notificationSuccess('Successfully updated user'));
                let userId = getUserId();
                let factoryId = getFactoryId();

                let userData = atob(localStorage.getItem('userData'));

                let splittedData = userData.split('::');
                let tmpData = {
                    username: splittedData[0],
                    password: splittedData[1],
                }

                if (payload.isActive === 0){
                    history.push('/configuration/user-manage/users');
                }
                let factoryCheck = payload.factory.filter((item) => { return item === factoryId });
                if (payload.userName === tmpData.username && payload.isActive === 0){
                    dispatch(postLogout());
                }
                else if (payload.userName === tmpData.username && factoryCheck.length <= 0){
                    dispatch(postLogout());
                }
            })
                .catch((err) => {
                    let errors = err.response.data.errors;
                    if (errors[0].userName) {
                        dispatch(notificationError('Login ID exists'));
                        dispatch(userTriggerHighlight('username'));
                    }
                    else if (errors[0].email){
                        dispatch(notificationError(errors[0].email));
                        dispatch(userTriggerHighlight('email'));
                    }
                    else if (errors[0].cardNo){
                        dispatch(notificationError('Card ID already exists'));
                        dispatch(userTriggerHighlight('cardNo'));
                    }
                    console.log(errors);
                });
        })
            .catch((error) => {
                console.log(error);
            });
    }
}

export const deleteUser = (id) => {
    return function (dispatch) {
        dispatch(userDeleteRequest());
        tokenDelete(apiSettings.apiURLReferences + "/users/" + id).then((response) => {
            dispatch(getUsers(false));
            dispatch(notificationSuccess('Successfully deleted user'));
        })
            .catch((error) => {
                if (error.message.indexOf(500) >= 0) {
                    dispatch(notificationError('Cannot delete because it is currently referenced to another record'));
                }
            });
    }
}

export const uploadUserProfile = (payload, files, id = null) => {
    return function (dispatch) {
        try {
            tokenPostUpload(apiSettings.apiURLFiles + '/api/files', files).then((response) => {
                let mutatedPayload = null;
                mutatedPayload = {
                    ...payload,
                    avatar: response.data.files[0].path
                }
                if (id === null) {
                    dispatch(postNewUser(mutatedPayload));
                }
                else {
                    dispatch(putUpdateUser(id, mutatedPayload));
                }
            });
        } catch (error) {
            console.error('factoryActions', error)
        }
    }
}

export const superAdminUploadUserProfile = (files,croppedImage, username, id) => {
    return function (dispatch) {
        try {
            tokenPostUpload(apiSettings.apiURLFiles + '/api/files', files).then((response) => {
                let mutatedPayload = null;
                mutatedPayload = {
                    avatar: response.data.files[0].path,
                    userName: username
                };
                tokenPut(apiSettings.apiURLReferences + "/users/avatar", mutatedPayload).then((response) => {
                    dispatch(getUserDetail(id));
                    dispatch(userChangeProfile({image:croppedImage,trigger:false}));
                    dispatch(userUpdateSuccess());
                    dispatch(notificationSuccess('Successfully updated user profile'));
                    dispatch(closeModal());
                })
                .catch((error) => {
                    console.log(error);
                });
            });
        } catch (error) {
            console.error('factoryActions', error)
        }
    }
}