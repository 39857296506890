import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Button from '@material-ui/core/Button';
import Card from './Card'
import moment from 'moment';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import './LineStatusTvMode.scss';
import Media from './Media/Media';
import { formatDate } from '../../../../utils/date';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginBottom: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: '95%',
    height: '54%',
  },
  drawerTitle: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingTop: '16px',
    paddingLeft: '16px',
    fontWeight: '500',
    fontSize: '2.3rem',
    fontFamily: 'Segoe UI',
  },
  drawerStatusRepaired: {
    display: 'flex',
    fontWeight: '500',
    fontFamily: 'Segoe UI',
    fontSize: '2.3rem',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    color: '#00DC07',
  },
  drawerStatusInRepaired: {
    display: 'flex',
    fontWeight: '500',
    fontFamily: 'Segoe UI',
    fontSize: '2.3rem',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    color: '#F59A23',
  },
  drawerStatusReported: {
    display: 'flex',
    fontWeight: '500',
    fontFamily: 'Segoe UI',
    fontSize: '2.3rem',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    color: '#D9001B',
  },
  paragQuestion: {
    display: 'flex',
    fontFamily: 'Segoe UI',
    fontSize: '1.2rem',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    color: '#aaa',
  },
  paragAnswers: {
    display: 'flex',
    fontFamily: 'Segoe UI',
    fontSize: '1.2rem',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    color: '#000',
  },
  topParag: {
    display: 'flex',
    fontFamily: 'Segoe UI',
    fontSize: '1.2rem',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingTop: '10px',
    paddingLeft: '16px',
    color: '#aaa',
  },
  topParagAnswers: {
    display: 'flex',
    fontFamily: 'Segoe UI',
    fontSize: '1.2rem',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingTop: '10px',
    paddingLeft: '16px',
    color: '#000',
  },
  drawerClose: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginBottom: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginBottom: 0,
  },
  attachment:{
    position: 'absolute',
    right: '34px',
    bottom: '9px',
  },
  lineCard:{
    display:'inline-block',
    paddingLeft: '16px',
    paddingBottom: '68px',
  },
}));

const drawerTheme = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiDrawer: {
        // Name of the rule
        paperAnchorDockedBottom: {
            border: '1px solid rgba(0, 0, 0, 0.12)',
        },
        paperAnchorBottom: {
          top: 'auto',
          left: '2%',
          right: '50%',
          bottom: '1%',
          height: 'auto',
          maxHeight: '100%',
        },
        head: {
          fontWeight: '800',
        },
      },
    },
  });

export default function PersistentDrawerLeft(props) {
  const classes = useStyles();
  const trigger = props.showDrawer
  const initialRender = useRef(true);
  const [open, setOpen] = React.useState(false);
  const [mechanic, setMechanic] = React.useState('');
  const ticketDetail = props.ticketDetail
  const machineDetail = props.machineDetail
  const assetDetail1 = props.assetDetail1
  const assetDetail2 = props.assetDetail2
  const assetDetail3 = props.assetDetail3
  const assetDetail4 = props.assetDetail4
  const linesMachines = props.linesMachines
  const linesTitle = props.linesTitle
  useEffect(() => {
    if(initialRender.current) {
        initialRender.current = false;
    } else {
        setOpen(true)
    }
  }, [trigger]);

  const handleDrawerClose = () => {
    setOpen(false);
    props.clearDrawerDetail();
  };

  const getMechanic = () => {
    if(ticketDetail.canceledBy != null){
      return ticketDetail.canceledBy
    }else if(ticketDetail.reportedBy != null){
      return ticketDetail.reportedBy
    }else if(ticketDetail.grabbedBy != null){
      return ticketDetail.grabbedBy
    }else if(ticketDetail.repairedBy != null){
      return ticketDetail.repairedBy
    }else{
      return ticketDetail.closedBy
    }
  };

  const renderDate = (date) => {
    if (date !== null){
        return moment(date).format('DD-MM-YYYY');
    }
    else{
        return '-';
    }
}

const renderTime = (date) => {
  if (date !== null){
      return moment(date).format('HH:mm');
  }
  else{
      return '-';
  }
}

const renderText = (field) => {
  if (field === null || field === ''){
      return '-'
  }
  else{
      return field
  }
}

const renderImageVideo = (media1, link1) => {
  return (
      <div>
          <Media media={media1} link={link1}></Media>
      </div>
  )
}

const checkerOfImageOrVideo = (media1) => {
  if(media1 != undefined){
  let check = media1.substr(media1.length - 3);
  if(check == 'mp4'){
      return "video"
  }else{
      return "image"
  }}
}

const renderStationDetail = () => {
  return (
    <div>
                <Grid container spacing={0}>
                <Grid container
                         direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item xs={9}>
                        <Grid item xs={12} >
                        <p className={classes.drawerTitle}>{ticketDetail.place}</p>
                        </Grid>
                        <Grid item xs={4} >
                        <p className={classes.paragAnswers}>{ticketDetail.ticketNo}</p>
                        </Grid>
                        <Grid item xs={4} >
                        <p className={classes.paragAnswers}>{renderDate(ticketDetail.reportedDt)}</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={ticketDetail.status == 'REPAIRED' ? classes.drawerStatusRepaired : ticketDetail.status == 'REPORTED' ? classes.drawerStatusReported : classes.drawerStatusInRepaired}>{ticketDetail.status}</p>
                        </Grid>
                        <Grid item xs={4} >
                        <p className={classes.paragAnswers}>{getMechanic()}</p>
                        </Grid>
                        <Grid item xs={8} >
                        <p className={classes.paragAnswers} style={{fontWeight: 'bold', paddingLeft: '55px'}}>{formatDate(ticketDetail.reportedDt, 'HH:mm')}</p>
                        </Grid>
                    </Grid>
                    <Grid container
                         direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item xs={3}>
                    <Grid item xs={11}/>
                      <Grid item xs={1} className={classes.drawerClose}>
                        <IconButton onClick={handleDrawerClose}> <CloseIcon></CloseIcon></IconButton>
                        </Grid>
                          </Grid>
                </Grid>
                <Grid container spacing={0}>
                <Grid container
                         direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item xs={9}>
                        <Grid item xs={2} >
                        <p className={classes.topParag}>Problem</p>
                        </Grid>
                        <Grid item xs={10} >
                        <p className={classes.topParagAnswers}>{renderText(ticketDetail.problemType)}</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragQuestion}>Solution</p>
                        </Grid>
                        <Grid item xs={10} >
                        <p className={classes.paragAnswers}>{renderText(ticketDetail.solutionType)}</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragQuestion}>Remarks</p>
                        </Grid>
                        <Grid item xs={10} >
                        <p className={classes.paragAnswers}>{renderText(ticketDetail.remarks)}</p>
                        </Grid>
                    </Grid> 
                </Grid>
                <Grid container spacing={0}>
                    <Grid container
                         direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item xs={9}>
                        <Grid item xs={2} >
                        <p className={classes.topParag}>Machine No.</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.topParagAnswers}>{ticketDetail.machineNo}</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.topParag}>Grabbed By</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.topParagAnswers}>{renderText(ticketDetail.grabbedBy)}</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.topParag}>Reported By</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.topParagAnswers}>{renderText(ticketDetail.reportedBy)}</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragQuestion}>Machine Type</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragAnswers}>{renderText(machineDetail.macTypeId_desc)}</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragQuestion}>Grabbed On</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragAnswers}>{renderDate(ticketDetail.grabbedDt)}</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragQuestion}>Reported On</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragAnswers}>{renderDate(ticketDetail.reportedDt)}</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragQuestion}>Machine sub type</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragAnswers}>{renderText(machineDetail.macSubTypeId_desc)}</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragQuestion}>Grabbed on Time</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragAnswers}>{formatDate(ticketDetail.grabbedDt, 'HH:mm')}</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragQuestion}>Reported on Time</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragAnswers}>{formatDate(ticketDetail.reportedDt, 'HH:mm')}</p>
                        </Grid>
                    </Grid> 
                    <Grid container
                         direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        item xs={9}>
                        <Grid item xs={2} >
                        <p className={classes.topParag}>Brand</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.topParagAnswers}>{renderText(machineDetail.brandId_desc)}</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.topParag}>Repaired By</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.topParagAnswers}>{renderText(ticketDetail.repairedBy)}</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.topParag}>Closed By</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.topParagAnswers}>{renderText(ticketDetail.closedBy)}</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragQuestion}>Model No.</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragAnswers}>{renderText(ticketDetail.modelNo)}</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragQuestion}>Repaired On</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragAnswers}>{renderDate(ticketDetail.repairedDt)}</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragQuestion}>Closed On</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragAnswers}>{renderDate(ticketDetail.closedDt)}</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragQuestion}>Motor Type</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragAnswers}>{renderText(machineDetail.motorTypeId_desc)}</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragQuestion}>Repaired on Time</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragAnswers}>{formatDate(ticketDetail.repairedDt, 'HH:mm')}</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragQuestion}>Closed on Time</p>
                        </Grid>
                        <Grid item xs={2} >
                        <p className={classes.paragAnswers}>{formatDate(ticketDetail.closedDt, 'HH:mm')}</p>
                        </Grid>
                    </Grid> 
                    <Grid container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        className={classes.attachment}
                        item xs={3}>
                                        <Grid className="form-control" container spacing={1}>
                                        {assetDetail1 != null ?
                                            (<Grid item xs={3}>
                                                {renderImageVideo(checkerOfImageOrVideo(assetDetail1), assetDetail1)}
                                            </Grid>)
                                             : null}

                                            {assetDetail2 != null ?
                                            (<Grid item xs={3}>
                                                {renderImageVideo(checkerOfImageOrVideo(assetDetail2), assetDetail2)}
                                            </Grid>)
                                             : null}

                                            {assetDetail3 != null ?
                                            (<Grid item xs={3}>
                                                {renderImageVideo(checkerOfImageOrVideo(assetDetail3), assetDetail3)}
                                            </Grid>)
                                             : null}

                                            {assetDetail4 != null ?
                                            (<Grid item xs={3}>
                                                {renderImageVideo(checkerOfImageOrVideo(assetDetail4), assetDetail4)}
                                            </Grid>)
                                             : null}
                                        </Grid>
                    </Grid>
                </Grid>
    </div>
  )
}

const renderLineDetail = () => {
  return (
    <div>
      <Grid container spacing={0}>
                    <Grid container>
                        <Grid item xs={11} >
                        <h2 className={classes.drawerTitle}>{linesTitle}</h2>
                        </Grid>
                        <Grid item xs={1} className={classes.drawerClose}>
                        <IconButton onClick={handleDrawerClose}> <CloseIcon></CloseIcon></IconButton>
                        </Grid>
                    </Grid> 
                    </Grid>
                    {linesMachines.map((row) => {
            return (
              <div className={classes.lineCard}>
              <Card
               hasOpenTicket={row.hasOpenTicket} 
              station={row.station} 
              machineNo={row.machineNo}
              ticketId={row.ticketId}
              machineId={row.machineId}
              emptyStation={row.emptyStation}
              mechanic={row.hasOpenTicket == "true" ? row.repairedBy != null ? row.repairedBy : row.grabbedBy != null ? row.grabbedBy : row.reportedBy : ''}
              ticketStatusId_desc={row.ticketStatusId_desc}
              macSubType_desc1={row.macSubType_desc1}
              sortNumber={row.sortNumber}
              lineDetail={true}></Card>
              </div>
          )
        
        }
          
          )}
    </div>
  )
}
  return (
    
    <div className={classes.root}>
      
      <CssBaseline />
        {/* <Button onClick={handleDrawerOpen}>TEST</Button> */}
        <ThemeProvider theme={drawerTheme}>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="bottom"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {props.drawerDetail == 'station' ? renderStationDetail() : renderLineDetail()}
                
      </Drawer>
      </ThemeProvider>
      
    </div>
  );
}
