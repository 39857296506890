import {
    NOTIFICATION_SUCCESS,
    NOTIFICATION_ERROR,
    NOTIFICATION_CLOSE
} from '../../actions/actionTypes';

const initialState = {
    open: false,
    autoHideDuration: 3000,
    severity: '',
    message: null
}

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATION_SUCCESS:
            return {
                ...state,
                open: true,
                severity: 'success',
                message: action.payload.message
            }
        case NOTIFICATION_ERROR:
            return {
                ...state,
                open: true,
                severity: 'error',
                message: action.payload.message
            }
        case NOTIFICATION_CLOSE:
            return {
                ...state,
                open: false,
                message: null
            }
        default: 
            return state;
    }
}

export default notificationReducer;