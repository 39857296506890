import React, { useForm, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from './close-icon2.png'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const muiAlert = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiAlert: {
      // Name of the rule
      root: {
        // Some CSS
        fontSize:'1.3rem',
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize:'1.3rem',
      }
    }
  },
});

const selectDropdown = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiSelect: {
      // Name of the rule
      icon: {
        // Some CSS
        display:'none',
      },
    },
  },
});

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'NAME' },
  { id: 'no', numeric: true, disablePadding: false, label: 'NO' },
  { id: 'macsubtype_desc', numeric: true, disablePadding: false, label: 'TYPE' },
  { id: 'brand', numeric: true, disablePadding: false, label: 'BRAND' },
  { id: 'frequency', numeric: true, disablePadding: false, label: 'MAIN FREQ' },
  { id: 'tasksSteps', numeric: true, disablePadding: false, label: '#STEPS' },
  { id: 'isActive', numeric: true, disablePadding: false, label: 'STATUS' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label.translate()}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    overflow: 'auto',
    height: '359px',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "#e0eff9"
    }
  },
  hover: {},
  selected: {},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  active: {
    color: '#70b603',
    fontWeight: '800'
  },
  inactive: {
    color: '#d9001b',
    fontWeight: '800'
  },
  search : {
    position: 'relative',
    top: '10px',
    left: '94px',
    width: '291px',
    backgroundColor: 'white',
  },
  brandDropdown :{
    position: 'relative',
    left: '55px',
    backgroundColor: '#3a27a5',
    top: '2px',
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 120
  },
  typeDropdown :{
    position: 'relative',
    left: '26px',
    backgroundColor: '#3a27a5',
    top: '2px',
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 120
  },
  brandFont: {
    color:'white',
    position: 'relative',
    top: '10px',
    left: '43px',
    fontWeight: '400',
  },
  typeFont: {
    color:'white',
    position: 'relative',
    top: '10px',
    left: '10px',
    fontWeight: '400',
  },
  blueMenu: {
    backgroundColor: '#1D5A84 !important',
    color:'white',
  },
  closeTypeIcon: {
    position: 'absolute',
    top: '12px',
    left: '-3px',
    zIndex: '4',
  },
  closeTypeIconNone: {
    display:'none'
  },
  closeBrandIcon: {
    position: 'absolute',
    top: '12px',
    left: '25px',
    zIndex: '4',
  },
  closeBrandIconNone: {
    display:'none'
  },
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const [alertOpen, setAlertOpen] = React.useState(false);
  const[alertMessage, setAlertMessage] = React.useState('');
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [tempId, setTempId] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const templatesData = props.templates
  const machineChecklistData = props.machineChecklist
  const machineDetailData = props.machineDetail
  const machineBrandValue = machineDetailData.brandId_desc != undefined || machineDetailData.brandId_desc != null ? machineDetailData.brandId_desc : "ALL";
  const machineTypeValue = machineDetailData.macSubTypeId_desc != undefined || machineDetailData.macSubTypeId_desc != null ? machineDetailData.macSubTypeId_desc : "ALL";
  const brandsData = props.brands;

  
  const macSubTypesData = props.macSubTypes;
  const [state, setState] = React.useState({
    filteredRows: props.macTempRL == 1 ? templatesData.filter(x => x.name != machineChecklistData[0].name) : templatesData,
  });
  const originalFilteredRows = props.macTempRL == 1 ? templatesData.filter(x => x.name != machineChecklistData[0].name) : templatesData;
  const [type, setType] = React.useState("");
  const [brand, setBrand] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [filters, setFilters] = React.useState({
    isActive: 1,
    brandId: 'all', 
    macSubTypeId: 'all', 
    searchString: '',
  });
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = state.filteredRows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name, gettempId) => {
    const selectedIndex = selected.indexOf(name);
    const tempIdIndex = tempId.indexOf(gettempId);
    const macTempRowLength = props.macTempRL
    const selectedTemplate = []
    let tempIdSame = false;
    let selectionLength = 0;
    if(macTempRowLength == 2){
      selectionLength = 0
    }else if(macTempRowLength == 1){
      selectionLength = 1
    }else{
      selectionLength = 2
    }
    let newSelected = [];
    let newTempId = [];


    if (selectedIndex === -1 && selected.length < selectionLength) {
      if(tempId.length == 1){

        if(tempId[0] == gettempId){
          setAlertMessage('Cannot attach template of the same type'.translate())
          setAlertOpen(true);
          if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            newTempId = newTempId.concat(tempId.slice(1))
          } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
            newTempId = newTempId.concat(tempId.slice(0, -1))
          } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
              selected.slice(0, selectedIndex),
              selected.slice(selectedIndex + 1),
            );
            newTempId = newTempId.concat(
              tempId.slice(0, tempIdIndex),
              tempId.slice(tempIdIndex + 1),
            );
          }else{
            newSelected = selected
            newTempId = tempId
          }
        }else{
          newSelected = newSelected.concat(selected, name);
          newTempId = newTempId.concat(tempId, gettempId)
        }
      }else{
        newSelected = newSelected.concat(selected, name);
        newTempId = newTempId.concat(tempId, gettempId)
      }
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newTempId = newTempId.concat(tempId.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newTempId = newTempId.concat(tempId.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newTempId = newTempId.concat(
        tempId.slice(0, tempIdIndex),
        tempId.slice(tempIdIndex + 1),
      );
    }else{
      setAlertMessage('Cannot attach more than 2 templates'.translate())
      setAlertOpen(true);
      newSelected = selected
      newTempId = tempId
    }
    setSelected((newSelected));
    setTempId((newTempId));
    for(let i = 0; i < newSelected.length; i++){
      let data = templatesData.filter(x => x.id == newSelected[i])
      selectedTemplate.push(data[0])
    }
    props.attachData(newSelected, newTempId, props.macTempRL, templatesData, machineChecklistData, selectedTemplate)
  };

  const handleBrandOnce = (value) => {
    setBrand(value)
  };

  useEffect(() => {
    props.handleFilterCheckList(filters);
  }, [filters]);

  const handleTypeOnce = (value) => {
    setType(value)
  };

  const handleTypeAll = () => {
    setType("")
  };

  const handleBrandAll = () => {
    setBrand("")
  };

  const renderTextWithCapitalization = (field) => {
    if (field === null || field === undefined){
      return '-'
    }else{
    let capitalized = field.split(' ').map(item => 
        item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(' ');
        return capitalized
      }
}

const renderMaintenFreq = (field) => {
  if (field === null || field === undefined){
      return '-'
  }
  else if(field === 'yearly'){
      return 'Yearly'
  }else if(field === 'MO'){
      return 'MO'
  }else{
      return field
  }
}


  useEffect(() => {
    if(machineBrandValue == "ALL"){
      handleBrandOnce("")
    }else{
      handleBrandOnce(machineBrandValue)
    }
    if(machineTypeValue == "ALL"){
      handleTypeOnce("")
    }else{
      handleTypeOnce(machineTypeValue)
    }
  }, []);

  const extractSelectedRow = (rows) => {
    try {
      return rows.filter(({ id }) => !machineChecklistData.map((item) => item.id).includes(id));
    } catch (error) {
      return rows;
    }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className={classes.root}>
      <FormControl className={classes.typeFont}>
      <p>{'TYPE'.translate()}</p>
      </FormControl>
      <FormControl className={classes.typeDropdown}>
      <ThemeProvider theme={type == '' ? null : selectDropdown}>
        <Select
          native
          id="checklistType"
          value={filters.macSubTypeId}
          defaultValue={filters.macSubTypeId}
          className={classes.blueMenu}
          onChange={({ target: { value } }) => 
            setFilters({...filters, macSubTypeId: value})}
        >
          <option className={classes.blueMenu} value="all"></option>
          {macSubTypesData.map((item) => (
            item?.desc1 && 
              <option 
                className={classes.blueMenu} 
                value={item.id}>
                  {renderTextWithCapitalization(item.desc1)}
              </option>
          ))
          }
        </Select>
        </ThemeProvider>
      </FormControl>
      <FormControl>
        <span className={type == '' ? classes.closeTypeIconNone : classes.closeTypeIcon} onClick={handleTypeAll}>
        <img src={CloseIcon}/>
        </span>
      </FormControl>
      <FormControl className={classes.brandFont}>
      <p>{'BRAND'.translate()}</p>
      </FormControl>
      <FormControl className={classes.brandDropdown}>
      <ThemeProvider theme={brand == '' ? null : selectDropdown}>
        <Select
          native
          id="brand"
          value={filters.brandId}
          defaultValue={filters.brandId}
          className={classes.blueMenu}
          onChange={({ target: { value } }) => 
            setFilters({...filters, brandId: value})}
        >
          <option className={classes.blueMenu} value="all"></option>
                    {brandsData.map((item) => {
            return (
              <option className={classes.blueMenu} value={item.id}>{renderTextWithCapitalization(item.desc1)}</option>
            )
          })
          }
        </Select>
        </ThemeProvider>
      </FormControl>
      <FormControl>
        <span className={brand == '' ? classes.closeBrandIconNone : classes.closeBrandIcon} onClick={handleBrandAll}>
        <img src={CloseIcon}/>
        </span>
      </FormControl>
      <TextField 
        id="standard-search" 
        className={classes.search} 
        placeholder={"Search".translate()} 
        onChange={({ target: { value } }) => 
            setFilters({...filters, searchString: value})}
        value={filters.searchString} 
        type="search" />
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={templatesData.length}
            />
            <TableBody>
              {stableSort(extractSelectedRow(templatesData), getComparator(order, orderBy))
                .map((filteredRows, index) => {
                  const isItemSelected = isSelected(filteredRows.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, filteredRows.id, filteredRows.tempTypeId)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      classes={{ hover: classes.hover, selected: classes.selected }}
                      className={classes.tableRow}
                      tabIndex={-1}
                      key={state.filteredRows.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          color="primary"
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {filteredRows.name}
                      </TableCell>
                      <TableCell align="left">{filteredRows.no}</TableCell>
                      <TableCell align="left">{filteredRows.checklistType}</TableCell>
                      <TableCell align="left">{renderTextWithCapitalization(filteredRows.brand)}</TableCell>
                      <TableCell align="left">{renderMaintenFreq(filteredRows.frequency)}</TableCell>
                      <TableCell align="left">{filteredRows.tasksSteps}</TableCell>
                      <TableCell className={filteredRows.templateStatus == "ACTIVE" ? classes.active : classes.inactive} align="left">{filteredRows.templateStatus.translate()}</TableCell>
                    </TableRow>
                  );
                })}
              {state.filteredRows.length == 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={8}>{'No records found'.translate()}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <ThemeProvider theme={muiAlert}>
          <Snackbar
            anchorOrigin={{vertical:'top',horizontal:'right'}}
            autoHideDuration={3000}
            open={ alertOpen }
            onClose={ (event,reason) => { if(reason !== 'clickaway'){ setAlertOpen(false) } } }
          >
            <Alert onClose={ (event,reason) => { if(reason !== 'clickaway'){ setAlertOpen(false) } } } variant="filled" severity={ 'error' }>
              { alertMessage }
            </Alert>
          </Snackbar>
        </ThemeProvider>
    </div>
  );
}
