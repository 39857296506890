import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { 
    getLatestMachineDistribution, 
    setViewByLatestMachineDistribution 
} from '../../../../../../../../redux/actions/MachineSummary/machineSummaryAction';
import { closeModal, showModal } from '../../../../../../../../redux/actions/Modal/modalActions';
import { 
    EmptyRecord, 
    useStyles, 
} from '../../../../styles';
import { fetchLatestMachineDistribution } from '../../../api';
import { downloadMachineSummaryReport, MACHINE_SUMMARY_REPORT_TYPES } from '../../excel';
import AreaListing from './AreaListing';
import Chart from './Chart';
import MachineDistributionHeader from './Header';

const MachineDistribution = props => {
    const {
        data
    } = props; 
    const classes = useStyles();
    const dispatch = useDispatch();
    const title = 'Latest Machine Distribution'.translate();
    const selectOptions = [ 'Building'.translate(), 'Area'.translate() ];
    const { query, latestMachineDistributionViewBy } 
        = useSelector(({ machineSummaryReducer }) => machineSummaryReducer);
    const handleOnChangeViewBy = (e) => {
        const value = e.target.value;
        dispatch(getLatestMachineDistribution(query, value));
        dispatch(setViewByLatestMachineDistribution(value));
    }

    const handleExport = () => {
        props.showModal('excelLoading');
        fetchLatestMachineDistribution(query)
            .then(({ latestMachineDistribution }) => {
                downloadMachineSummaryReport(MACHINE_SUMMARY_REPORT_TYPES.LatestMachineDistribution, latestMachineDistribution.data);
            })
            .finally(() => {
                props.closeModal();
            });
    }

    return ( 
        <div className={[classes.card, classes.card260].join(' ')}>
            <MachineDistributionHeader
                title={title}
                viewBy={latestMachineDistributionViewBy}
                onChangeViewBy={handleOnChangeViewBy}
                onExport={handleExport}
                selectOptions={selectOptions}/>
            {data.length > 0 && 
                <div className={[classes.grid, classes.grid2].join(' ')}>
                    <Chart data={data} />
                    <AreaListing data={data} />
                </div> 
            }
            {data.length === 0 && <EmptyRecord text={'No records found'.translate()} />}
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        showModal: (modal) => dispatch(showModal(modal)),
        closeModal: () => dispatch(closeModal())
    }
}

export default connect(null, mapDispatchToProps)(MachineDistribution);