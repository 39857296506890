import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Grid } from '@material-ui/core';
import MainPage from '../../../components/MainPage/MainPage';
import PageHeader from '../../../components/PageHeader/PageHeader';
import PageBody from '../../../components/PageBody/PageBody';
import InfoForm from './Form/InfoForm';
import LineSetup from './Form/LineSetup';
import PageActions from './Form/PageActions';
import { stickyTop } from './Form/utils/sticky-plugin';
import { useSticky } from "react-hook-sticky";
import validate from 'validate.js';
import { changeoverRules } from './Form/validations';
import { machineClient } from '../../../utils/api/CoreApi';
import { serializeForm, deserializeEdit } from './helpers';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

export const breadcrumbs = [
  {
    text: 'Tickets',
  },
  {
    text: 'Changeovers Requests',
    href: '/changeovers',
  },
];

const stickyConfig = {
  context: "sitcky-form",
  plugin: stickyTop,
};

function EditChangeover(props){
  const history = useHistory();
  const { id } = useParams();
  const { createBoundary } = useSticky(stickyConfig);
  const [form, setForm] = useState({ machines: [] });
  const [loaded, setLoaded] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const errors = validate(form, changeoverRules);
  const isValid = !errors;

  const cancel = () => {
    history.goBack();
  }

  useEffect(()=>{
    machineClient.get(`/co-requests/${id}`).then(({ data })=>{
      const { corequest } = data;
      const normalizedData = deserializeEdit(corequest);
      setForm(normalizedData);
      setLoaded(true);
    });
  }, [id])

  const handleSubmit = () => {
    if(!isValid) return;
    setIsloading(true);
    const data = serializeForm(form);
    machineClient.patch(`/co-requests/${form.id}`, data).then(()=>{
      history.push(`/changeover-detail/${form.id}`);
      setIsloading(true);
    }).catch((error) => {
      setIsloading(false);
    })
  }

  const handleUpdateMachines = (machines) => {
    setForm({ 
      ...form, 
      machines: machines.map((machine, key) => ({
        ...machine,
      }))
    })
  };

  if(!loaded) return null;

  return (
    <MainPage footer={false}>
      <PageHeader breadcrumbs={breadcrumbs} />
      <PageBody p={1}>
        <Grid container spacing={1}>
          <Grid style={{ position: 'relative' }} item xs={3} ref={createBoundary('outer')}>
            <InfoForm
              title={'Edit Changeover Request'.translate()}
              stickyBounday={createBoundary('sticky')}
              data={form}
              onChange={(attributes) => setForm({ ...form, ...attributes })}
            />
          </Grid> 
          <Grid item xs={9}>
            <LineSetup
              machines={form.machines}
              updateMachines={handleUpdateMachines}
            />
          </Grid>
        </Grid>
        <PageActions
          isLoading={isLoading}
          isValid={isValid}
          onSubmit={handleSubmit}
          onCancel={cancel}
        />
      </PageBody>
    </MainPage>
  );
}

const mapStateToProps = state => ({...state.translationReducer});
export default connect(mapStateToProps, null)(EditChangeover);